import React, { useState, useEffect } from "react";
import Free from "../assets/Free.svg";
import Starter from "../assets/Starter.svg";
import Professional from "../assets/Professional.svg";
import Premium from "../assets/Premium.svg";
import HorizontalLinePackage from "../assets/HorizontalLinePackage.svg";
import VerticleLinePackage from "../assets/VerticleLinePackage.svg";
import pencil from "../assets/pencil.svg";
import toolTipIcon from "../assets/toolTipIcon.svg";
import WarningTriangle from "../assets/WarningTriangle.svg";
import { fetchLookupKeyForId, fetchPriceForId } from "../Utils/fetchers";
import { resubscribeSubscription } from "../../../utils/subscriptionService";
import Tooltip from "../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../components/InfoIcon/InfoIcon";
import MiniSubscriptionSummary from "../../../components/MiniSubscriptionSummary/MiniSubscriptionSummary";

const tutorLicensesMapping = {
  BasicPackage_Free: 1,
  BasicPackage_Starter: 1,
  BasicPackage_Professional: 10,
  BasicPackage_Premium: 30,
};

const YourPlanCard = ({
  subscriptionData,
  planType,
  selectedBillingCycle,
  setPopUpTriger,
  setPopUpType,
  handleCancelSubscription,
  subscriptionV2,
  isExpired,
  siMapping,
  productPrices,
}) => {
  const [subPlanType, setSubPlanType] = useState(
    planType === 0 ? "Monthly" : "Annual"
  );
  const [packageName, setPackageName] = useState(
    subscriptionData?.basePackage.name.split("_")[1]
  );

  const [tutorsLimitToShow, setTutorsLimitToShow] = useState(0);
  const [isEditTutorLicenseEnabled, setIsEditTutorLicenseEnabled] =
    useState(true);
  const [showUpgradeIndicator, setShowUpgradeIndicator] = useState(false);

  useEffect(() => {
    setPackageName(subscriptionData?.basePackage.name.split("_")[1]);
  }, [subscriptionData]);

  useEffect(() => {
    setSubPlanType(planType === 0 ? "Monthly" : "Annual");
  }, [planType]);

  useEffect(() => {
    let newBasePackageLookupKey = "";
    const newBasePackage = siMapping.find((siMap) =>
      siMap.lookupKey.includes("BasicPackage")
    );
    if (newBasePackage.newPriceId) {
      console.log(planType);
      newBasePackageLookupKey = fetchLookupKeyForId(
        productPrices,
        newBasePackage.newPriceId,
        selectedBillingCycle === 0 ? "monthly" : "annual",
        "BasePackage"
      );
      console.log("NBP KEY", newBasePackageLookupKey);
    }
    setIsEditTutorLicenseEnabled(
      !(
        (subscriptionData?.basePackage.name.includes("Free") &&
          !newBasePackage?.newPriceId) ||
        newBasePackageLookupKey.includes("Free")
      )
    );
    const newTutorId = siMapping?.find((val) =>
      val.lookupKey.includes("Tutor")
    )?.newPriceId;
    const newBasePackageId = siMapping?.find((val) =>
      val.lookupKey.includes("BasicPackage")
    )?.newPriceId;
    if (newTutorId && newBasePackageId) {
      setShowUpgradeIndicator(true);
      const newBasePackageLookupKey = fetchLookupKeyForId(
        productPrices,
        newBasePackageId,
        selectedBillingCycle === 0 ? "monthly" : "annual",
        "BasePackage"
      );
      setTutorsLimitToShow(
        siMapping?.find((val) => val.lookupKey.includes("Tutor"))?.quantity +
          tutorLicensesMapping[
            Object.keys(tutorLicensesMapping).find((val) =>
              siMapping?.find((_val) => newBasePackageLookupKey.includes(val))
            )
          ]
      );
    } else if (
      subscriptionV2?.siMapping.find((val) => val.lookupKey.includes("Tutor"))
        ?.quantity !==
      siMapping?.find((val) => val.lookupKey.includes("Tutor"))?.quantity
    ) {
      setShowUpgradeIndicator(true);
      setTutorsLimitToShow(
        siMapping?.find((val) => val.lookupKey.includes("Tutor"))?.quantity +
          tutorLicensesMapping[
            Object.keys(tutorLicensesMapping).find((val) =>
              siMapping?.find(
                (_val) =>
                  _val.lookupKey.includes(val) &&
                  _val.lookupKey ===
                    subscriptionV2?.subscription.basePackage.name
              )
            )
          ]
      );
    } else {
      setTutorsLimitToShow(subscriptionData?.tutorLicenses.limit);
    }
  }, [subscriptionData, siMapping]);

  const getImage = (name) => {
    switch (name) {
      case "Free":
        return Free;
      case "Starter":
        return Starter;
      case "Professional":
        return Professional;
      case "Premium":
        return Premium;
      default:
        return null; // Handle the case where packageName doesn't match any image
    }
  };

  const formatDate = (mongoDBDate, formatType = 1) => {
    const dateObj = new Date(mongoDBDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const monthIndex = dateObj.getMonth();
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const dayIndex = dateObj.getDay();

    if (formatType === 1) {
      return `${monthNames[monthIndex]} ${day}, ${year}`;
    } else if (formatType === 2) {
      return `${dayNames[dayIndex]}, ${monthNames[monthIndex]} ${day}, ${year}`;
    } else {
      return "Invalid format type";
    }
  };
  const calculateDaysUntilRenewal = (startDate, endDate) => {
    // Convert start and end dates to JavaScript Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (isNaN(startDateObj) || isNaN(endDateObj)) {
      console.error("Invalid date(s) provided.");
      return "Invalid date";
    }

    // Calculate the difference in milliseconds
    const timeDifference = endDateObj.getTime() - startDateObj.getTime();

    // Convert milliseconds to days
    const daysUntilRenewal = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysUntilRenewal >= 0 ? daysUntilRenewal : "0";
  };

  const getNextYearsDay = (date) => {
    let givenDate = new Date(date);

    // Add one year to the date
    let nextYearDate = new Date(givenDate);
    nextYearDate.setFullYear(givenDate.getFullYear() + 1);

    let nextYearDateStr = nextYearDate.toISOString();
    return nextYearDateStr;
  };

  const [basepackageUpgrade, setBasePackageUpgarde] = useState(null);

  useEffect(() => {
    const basicPackageItem = siMapping.find(
      (item) => item.lookupKey.includes("BasicPackage") && item.newPriceId
    );
    if (basicPackageItem) {
      const newPriceId = basicPackageItem.newPriceId;
      const basePackageProduct = productPrices.find((item) =>
        item.product.name.includes("BasePackage")
      );
      if (basePackageProduct) {
        let matchingPrice = null;
        basePackageProduct.prices.monthly.forEach((price) => {
          if (price.id === newPriceId) {
            matchingPrice = price;
          }
        });
        if (!matchingPrice) {
          basePackageProduct.prices.annual.forEach((price) => {
            if (price.id === newPriceId) {
              matchingPrice = price;
            }
          });
        }
        if (matchingPrice) {
          setBasePackageUpgarde(matchingPrice);
        }
      }
    } else {
      setBasePackageUpgarde(null);
    }
  }, [siMapping, productPrices]);

  return (
    <div className="h-fit grid gap-[24px] w-full">
      <div className=" text-[28px] font-semibold leading-[35px] text-[#25335A]">
        Your Plan
      </div>
      <div className="grid w-full gap-[24px] border text-[#25335A] border-[#CACEDA] rounded-[12px] p-[24px]">
        <div className="basePackageDetails_changePlan flex justify-between items-center h-fit gap-[24px] ">
          {basepackageUpgrade === null ? (
            <div className="flex gap-[12px] items-center">
              <div className="h-[48px] w-[48px]  gap-[12px] flex justify-center items-center rounded-full border border-[#CACEDA]">
                <img
                  className="h-[32px] w-[32px] rounded-full "
                  src={getImage(packageName)}
                />
              </div>
              <div>
                <div className="flex gap-[calc(100vw*12/1440)] text-[20px] font-semibold">
                  <div>
                    {packageName.includes("Free") ? "Explorer" : packageName}
                    <span className="text-[#7C859C] font-normal">
                      &nbsp;{" "}
                      {subscriptionV2?.subscription?.basePackage?.name.includes(
                        "Free"
                      )
                        ? ""
                        : `(${subPlanType})`}
                    </span>
                  </div>{" "}
                  {!subscriptionV2?.subscription?.basePackage?.name.includes(
                    "Free"
                  ) && subscriptionData?.isTrialling === true ? (
                    <div className="bg-success/10 text-success-text rounded-[6px] px-[calc(100vw*12/1440)] py-[calc(100vw*6/1440)] text-[calc(100vw*16/1440)]">
                      FREE TRIAL
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-[#7C859C] text-[16px] font-normal">
                  {formatDate(subscriptionData?.currentPeriod.start, 1)} -
                  Present
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-[32px]">
              <div className="flex gap-[12px] items-center">
                <div className="h-[48px] w-[48px]  gap-[12px] flex justify-center items-center rounded-full border border-[#CACEDA]">
                  <img
                    className="h-[32px] w-[32px] rounded-full grayscale "
                    src={getImage(packageName)}
                  />
                </div>
                <div>
                  <div className="flex gap-[calc(100vw*12/1440)] text-[20px] font-medium line-through">
                    <div>
                      {packageName.includes("Free") ? "Explorer" : packageName}{" "}
                      {subscriptionV2?.subscription?.basePackage?.name.includes(
                        "Free"
                      )
                        ? ""
                        : `(${subPlanType})`}
                    </div>{" "}
                    {subscriptionData?.isTrialling === true ? (
                      <div className="bg-gray-200 text-black rounded-[6px] px-[calc(100vw*12/1440)] py-[calc(100vw*6/1440)] text-[calc(100vw*16/1440)] flex items-center">
                        FREE TRIAL
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-[#7C859C] text-[16px] font-normal line-through">
                    {formatDate(subscriptionData?.currentPeriod.start, 1)} -
                    Present
                  </div>
                </div>
              </div>

              <div className="flex gap-[12px] items-center">
                <div className="h-[48px] w-[48px]  gap-[12px] flex justify-center items-center rounded-full border border-[#CACEDA]">
                  <img
                    className="h-[32px] w-[32px] rounded-full "
                    src={getImage(basepackageUpgrade.lookup_key.split("_")[1])}
                  />
                </div>
                <div>
                  <div className="flex gap-[calc(100vw*12/1440)] text-[20px] font-medium capitalize">
                    <div>
                      {basepackageUpgrade.lookup_key.includes("Free")
                        ? "Explorer"
                        : basepackageUpgrade.lookup_key.split("_")[1]}
                      <span
                        className="text-[#7C859C]"
                        style={{
                          display: basepackageUpgrade.lookup_key.includes(
                            "Free"
                          )
                            ? "none"
                            : "inline",
                        }}
                      >
                        &nbsp;(
                        {basepackageUpgrade.recurring.interval == "month"
                          ? "Monthly"
                          : "Annual"}
                        )
                      </span>
                    </div>
                    <div
                      className="text-blue-70 cursor-pointer  underline "
                      onClick={async (e) => {
                        setPopUpTriger(true);
                        if (!isExpired) {
                          setPopUpType("change-base-pckg");
                        } else {
                          console.log("DSUB", subscriptionV2);
                          await resubscribeSubscription(
                            siMapping,
                            subscriptionV2?.subscription
                          );
                        }
                      }}
                    >
                      Edit
                    </div>
                  </div>
                  <div className="text-[#7C859C] text-[16px] font-normal">
                    Starts {formatDate(Date.now(), 1)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            <button
              className={`${
                isExpired
                  ? "bg-[#F97066] text-[#FFFFFF]"
                  : "text-[#0671E0] text-[16px] font-semibold leading-[20px] border-2 border-[#0671E0]"
              } font-medium rounded-[6px] px-[24px] py-[10px]`}
              onClick={async (e) => {
                setPopUpTriger(true);
                if (!isExpired) {
                  setPopUpType("change-base-pckg");
                } else {
                  console.log("DSUB", subscriptionV2);
                  await resubscribeSubscription(
                    siMapping,
                    subscriptionV2?.subscription
                  );
                }
              }}
            >
              {isExpired ? "Resubscribe" : " Change Plan"}
            </button>
          </div>
        </div>
        <div
          className={`${
            isExpired
              ? "bg-[#FEF3F2] px-[8px] py-[2px] gap-[6px] rounded-[4px] flex items-center justify-start"
              : ""
          } text-[16px] text-secondary-80 font-normal`}
        >
          {isExpired ? (
            <>
              <div>
                <img src={WarningTriangle} className="h-[20px] w-[20px]" />
              </div>
              <div>
                Your subscription expired on{" "}
                <span className="font-medium">
                  {formatDate(subscriptionData?.currentPeriod.end, 2)}
                </span>
                "Recharge now to continue using the benefits.
              </div>
            </>
          ) : basepackageUpgrade === null ? (
            <>
              {!subscriptionV2?.subscription?.basePackage?.name.includes(
                "Free"
              ) && subscriptionData?.isTrialling === true
                ? "Your 14-day free trial expires on "
                : isExpired
                ? "Your subscription expired on "
                : "Your subscription will automatically renew on "}
              <span className="font-medium">
                {subscriptionV2?.subscription?.basePackage?.name.includes(
                  "Free"
                ) && subscriptionData?.isTrialling === true
                  ? formatDate(
                      getNextYearsDay(subscriptionData?.currentPeriod.end),
                      2
                    )
                  : formatDate(subscriptionData?.currentPeriod.end, 2)}
              </span>
            </>
          ) : (
            <>
              Your subscription will change to{" "}
              {basepackageUpgrade.lookup_key.split("_")[1]}{" "}
              {basepackageUpgrade.recurring.interval == "month"
                ? "Monthly"
                : "Annual"}{" "}
              plan starting{" "}
              <span className="font-medium">{formatDate(Date.now(), 2)}.</span>
            </>
          )}
        </div>
        <div>
          <img src={HorizontalLinePackage} className="h-[10px]   w-fit " />
        </div>
        <div className="flex justify-around px-[36px] text-[48px] min-h-[76px] h-fit ">
          <div className="gap-[8px] text-center  ">
            <div className="flex justify-center">
              <div>
                <span className="text-[#25335A] font-semibold">
                  {subscriptionData?.tutorLicenses.used}
                </span>
                <span className="text-[#7C859C] font-semibold">
                  /{tutorsLimitToShow}
                </span>
              </div>
              <div
                className=" flex items-center"
                onClick={(e) => {
                  let newBasePackageLookupKey = "";
                  const newBasePackage = siMapping.find((siMap) =>
                    siMap.lookupKey.includes("BasicPackage")
                  );
                  if (newBasePackage.newPriceId) {
                    console.log(planType);
                    newBasePackageLookupKey = fetchLookupKeyForId(
                      productPrices,
                      newBasePackage.newPriceId,
                      selectedBillingCycle === 0 ? "monthly" : "annual",
                      "BasePackage"
                    );
                    console.log("NBP KEY", newBasePackageLookupKey);
                  }
                  if (
                    (subscriptionData?.basePackage.name.includes("Free") &&
                      !newBasePackage?.newPriceId) ||
                    newBasePackageLookupKey.includes("Free")
                  )
                    return;
                  console.log(
                    "TER",
                    subscriptionV2?.subscription.basePackage.name
                  );
                  setPopUpTriger(true);
                  setPopUpType("tut-license");
                }}
              >
                {isEditTutorLicenseEnabled ? (
                  <img src={pencil} className="h-[24px] w-[24px]" />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex gap-[4px] text-[16px] font-normal items-center">
              <div className="text-[#515C7B]">Tutor licenses used</div>
              <Tooltip
                anchorComponent={<InfoIcon />}
                tooltipStyleClass={"tooltipStyleClass"}
                tooltipContent={`Refers to the Tutor Licenses you have already "Assigned" or "Activated" for tutors on your platform out of a total number of licenses that you have either purchased or included with your subscription.`}
              />
            </div>
            {showUpgradeIndicator ? (
              <div
                className="bg-success/10 text-success-text text-[14px] font-[400] px-[12px] py-[6px] rounded-[100px] w-fit"
                style={{ margin: "0 auto", marginTop: "8px" }}
              >
                Upgrade
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <img src={VerticleLinePackage} className="h-fit w-fit " />
          </div>
          <div className="gap-[8px]  text-center">
            <div>
              <div
                className={`${
                  !basepackageUpgrade === null
                    ? "text-[#7C859C] min-h-[48px] bg-red-500"
                    : ""
                } font-medium`}
              >
                {subscriptionV2?.subscription?.basePackage?.name.includes(
                  "Free"
                ) &&
                subscriptionData?.isTrialling === true &&
                basepackageUpgrade === null
                  ? calculateDaysUntilRenewal(
                      new Date().toString(),
                      getNextYearsDay(subscriptionData?.currentPeriod.start)
                    )
                  : basepackageUpgrade === null
                  ? calculateDaysUntilRenewal(
                      new Date().toString(),
                      subscriptionData?.currentPeriod.end
                    )
                  : "-"}
              </div>
              <div className="text-[16px] font-normal text-[#7C859C] ">
                {isExpired ? (
                  <div className="flex gap-[4px]">
                    <div>
                      <img
                        src={WarningTriangle}
                        className="h-[20px] w-[20px]"
                      />
                    </div>
                    <div>Payment Overdue</div>
                  </div>
                ) : (
                  <div
                    className={`${
                      !basepackageUpgrade == null ? "text-[#7C859C] " : ""
                    }`}
                  >
                    Days until renewal
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <img src={VerticleLinePackage} className="h-fit w-fit " />
          </div>
          <div className=" gap-[8px]  text-center ">
            <div>
              <div className="font-medium">
                ${subscriptionData?.currentOutstanding.amount / 100}
              </div>
              <div className="flex gap-[4px]">
                <div className="text-[16px] font-normal text-[#515C7B] flex gap-[4px] items-center justify-start">
                  Current outstanding
                  <Tooltip
                    anchorComponent={<InfoIcon className="text-secondary-60" />}
                    tooltipContent={
                      subscriptionV2 && (
                        <MiniSubscriptionSummary
                          subscriptionStartDate={
                            subscriptionV2?.subscription.startDate
                          }
                          subscriptionEndDate={
                            subscriptionV2?.subscription.endDate
                          }
                          subscriptionBasePrice={fetchPriceForId(
                            productPrices,
                            subscriptionV2?.subscription?.basePackage.priceId,
                            subscriptionV2?.subscription?.__t ===
                              "MonthlySubscription"
                              ? "monthly"
                              : "annual",
                            "BasePackage"
                          )}
                          calendarPrice={fetchPriceForId(
                            productPrices,
                            subscriptionV2?.subscription?.sessions.priceId,
                            subscriptionV2?.subscription?.__t ===
                              "MonthlySubscription"
                              ? "monthly"
                              : "annual",
                            "Calendar"
                          )}
                          assignmentsPrice={fetchPriceForId(
                            productPrices,
                            subscriptionV2?.subscription?.assignments.priceId,
                            subscriptionV2?.subscription?.__t ===
                              "MonthlySubscription"
                              ? "monthly"
                              : "annual",
                            "Assignments"
                          )}
                          customContentPrice={fetchPriceForId(
                            productPrices,
                            subscriptionV2?.subscription?.assignments
                              .customContent.priceId,
                            subscriptionV2?.subscription?.__t ===
                              "MonthlySubscription"
                              ? "monthly"
                              : "annual",
                            "CustomContent"
                          )}
                          assignmentsPAYGPrice={(
                            subscriptionData?.usageRecords["Assignments_PAYG"]
                              ?.data[0].total_usage *
                            (subscriptionData?.usageRecords["Assignments_PAYG"]
                              ?.unit_amount /
                              100)
                          ).toFixed(2)}
                          calendarPAYGPrice={(
                            subscriptionData?.usageRecords["Calendar_PAYG"]
                              ?.data[0].total_usage *
                            (subscriptionData?.usageRecords["Calendar_PAYG"]
                              ?.unit_amount /
                              100)
                          ).toFixed(2)}
                        />
                      )
                    }
                    tooltipStyleClass={
                      "tooltipStyleClass !-left-[150px] !w-[300px] !top-[20px]"
                    }
                    tooltipTriangleStyling={"!top-[2px] !left-[5px] -z-[1]"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={HorizontalLinePackage} className="h-[10px]  w-fit " />
        </div>
        {!packageName.includes("Free") && (
          <div
            className="flex justify-center cursor-pointer"
            onClick={() => {
              handleCancelSubscription();
            }}
          >
            <span className="w-fit border-b text-[#7C859C] font-normal">
              Cancel Subscription
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default YourPlanCard;
