export default function ReminderBellIcon (props) {

	return(
		<svg width={props.width? props.width :"16"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M18.8818 8.33333C20.904 8.33333 22.5485 6.68883 22.5485 4.66667C22.5485 2.6445 20.904 1 18.8818 1C16.8596 1 15.2151 2.6445 15.2151 4.66667C15.2151 6.68883 16.8596 8.33333 18.8818 8.33333ZM18.8818 2.83333C19.8929 2.83333 20.7151 3.65558 20.7151 4.66667C20.7151 5.67775 19.8929 6.5 18.8818 6.5C17.8707 6.5 17.0485 5.67775 17.0485 4.66667C17.0485 3.65558 17.8707 2.83333 18.8818 2.83333ZM21.8811 15.8931L21.8765 15.8757V15.8748L20.4346 9.94117C19.9415 10.086 19.4217 10.1676 18.8818 10.1676C18.7865 10.1676 18.6939 10.1584 18.5995 10.1529L20.1 16.3267C20.1019 16.3331 20.1065 16.3377 20.1074 16.345H20.1037C20.1789 16.6273 20.123 16.9088 19.9488 17.1388C19.7728 17.3726 19.5125 17.5009 19.2191 17.5009H3.75037C3.47079 17.5009 3.21045 17.3753 3.0372 17.1608C2.8612 16.9408 2.7952 16.6585 2.84745 16.4165L5.00345 8.05925C5.68729 4.98292 8.37862 2.83425 11.5485 2.83425C12.257 2.83425 12.9445 2.95525 13.5945 3.16517C13.7668 2.55925 14.0381 1.99642 14.3938 1.49592C13.4964 1.17692 12.5375 1.00183 11.5485 1.00183C7.51329 1 4.08587 3.739 3.22145 7.63025L1.06545 15.9893C0.88487 16.8052 1.08195 17.6494 1.6072 18.3067C2.13245 18.9593 2.91437 19.3333 3.75037 19.3333H7.0577C7.48395 21.4224 9.3347 23 11.5485 23C13.7622 23 15.613 21.4224 16.0392 19.3333H19.22C20.0881 19.3333 20.8856 18.9364 21.4109 18.2462C21.9205 17.5742 22.0883 16.7245 21.882 15.9087C21.8811 15.9032 21.882 15.8986 21.882 15.8931H21.8811ZM11.5485 21.1667C10.355 21.1667 9.34662 20.3976 8.96712 19.3333H14.1289C13.7494 20.3976 12.741 21.1667 11.5475 21.1667H11.5485Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
		</svg>

	)

}
