import React from "react";
import { useDispatch, useSelector } from "react-redux";
import amexIcon from "../../../pages/ManageSubscription/assets/amex.svg";
import mastercardIcon from "../../../pages/ManageSubscription/assets/mastercard.svg";
import visaIcon from "../../../pages/ManageSubscription/assets/visa.svg";
import dinersIcon from "../../../pages/ManageSubscription/assets/diners.svg";
import discoverIcon from "../../../pages/ManageSubscription/assets/discover.svg";
import jcbIcon from "../../../pages/ManageSubscription/assets/jcb.svg";
import unionIcon from "../../../pages/ManageSubscription/assets/unionpay.svg";
import ActiveIndicator from "../../../components/ActiveIndicator/ActiveIndicator";
import Tooltip from "../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../components/InfoIcon/InfoIcon";
import { useMakeDefaultPaymentMutation } from "../../../app/services/subscription";
import { triggerSubscriptionUpdate } from "../../../app/slices/subscription";
import { triggerToast } from "../../../utils/toastController";

const cardBrandImageMapping = {
  amex: amexIcon,
  diners: dinersIcon,
  discover: discoverIcon,
  eftpos_au: "",
  jcb: jcbIcon,
  mastercard: mastercardIcon,
  unionpay: unionIcon,
  visa: visaIcon,
};

const PaymentMethodCard = ({
  card,
  isDefault,
  stripeCustomerId,
  isFromSettings = false,
}) => {
  const dispatch = useDispatch();
  let cardNumber = "XXXX";
  if (card.card) {
    cardNumber = card.card.last4;
  }
  let expiresOn = "";
  let month = card?.card?.exp_month;
  let year = card?.card?.exp_year;
  expiresOn = month + "/" + year;
  const [makeDefaultPayment] = useMakeDefaultPaymentMutation();

  const handleMakeCardDefault = async () => {
    const result = await makeDefaultPayment({
      customerId: stripeCustomerId,
      paymentMethodId: card.id,
    });

    if (result?.error) {
      triggerToast("Unable to set card as default", "error", {
        autoClose: 2000,
      });
      return;
    }
    dispatch(triggerSubscriptionUpdate());
    triggerToast("Default Card set successfully!", "success", {
      autoClose: 2000,
    });
  };

  return (
    <div
      className={`flex gap-[16px] p-[12px] justify-between rounded-[6px] border-[2px] ${
        isDefault
          ? "bg-blue-70/5 border-blue-70"
          : !isFromSettings &&
            "hover:ease-in transition duration-150 hover:bg-blue-70/30 hover:border-secondary-80 cursor-pointer"
      } `}
      onClick={() => {
        if (isFromSettings) return;
        if (!isDefault) {
          handleMakeCardDefault();
        }
      }}
    >
      <div className="flex gap-[9px]">
        {" "}
        <div className="w-[40px] flex justify-center items-center">
          <img
            src={cardBrandImageMapping[card?.card?.brand]}
            style={{ width: "40px", height: "25.64px", objectFit: "contain" }}
          />
        </div>
        <div>
          <div className="text-[16px] leading-[24px] text-secondary font-[400]">
            Card ending in ****{cardNumber}
          </div>
          <div className="text-[14px] leading-[21px] text-secondary-60 font-[400]">
            Expires on {expiresOn}
          </div>
        </div>
      </div>
      <div>
        {isDefault ? (
          <span className="cursor-default rounded-full h-fit text-[12px] font-[400] leading-[12px] px-[12px] py-[6px] border-[1px] border-[#40C98F] bg-success/10 text-[#40C98F]">
            Default
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PaymentMethodCard;