import React, { useEffect, useState } from "react";
import UserInfo from "./UserInfo";

const ViewSelectedUser = ({ selectedUser,attendeeStatusMapping, users }) => {


  return (
    <main className=" h-[320px]  w-full  lg:min-w-[285px] lg:w-fit  absolute top-[110%]  lg:top-[110%] left-0 lg:left-[95%] py-[12px] cursor-default px-[8px] rounded-[6px] gap-[8px] flex flex-col  overflow-y-auto  shadow-[0px_4px_18px_0px_#11167D1A] bg-white z-10 ">
      <p className=" text-[12px] leading-[12px]  text-[#25335A] font-medium ">
        Attendees ({selectedUser?.length})
      </p>
      <div className=" border-t-[1px]  w-full border-secondary-10 " />

      {selectedUser &&
        selectedUser.map((id) => {
          const user = users.find((user) => user._id === id);
          if (user ) {
            return <UserInfo key={id} user={user} id={id} action={ attendeeStatusMapping && attendeeStatusMapping[id]}  />;
          } else {
            return null;
          }
        })}
    </main>
  );
};

export default ViewSelectedUser;
