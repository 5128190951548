import { ArrowLeft } from '@mui/icons-material'
import ButtonV2 from '../../ButtonV2/ButtonV2'
import styles from './ActionArea.module.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoaderIcon from '../../LoaderIcon/LoaderIcon';

const ActionArea = ({ setIsSubscriptionUpdating, isFreeTrial, priceDetails, isFreePlanSelected, subscriptionSelectionStage, setSubscriptionSelectionStage, isProceedButtonEnabled, handleFreeClick, isFreeLoading, handleProceedToPayClick, isPaidSubscriptionLoading, subscriptionPopUpOperationMode, isContinueButtonEnabled, isSubscriptionUpdating, isAllDowngrades, handleUpdateSubscriptionClick, handleCancelUpdateSubscriptionClick, setPaymentConfirmatinTrigger,requiresFreeTrial }) => {


    const handleUpdateClick = (e) => {
        if (isFreeTrial) {
            setPaymentConfirmatinTrigger(true)
        } else {
            if (priceDetails.finalAmountDetails.currentPrice === "$0.00" || priceDetails.finalAmountDetails.currentPrice === "$0.0") {
                handleUpdateSubscriptionClick(e, setIsSubscriptionUpdating)
            } else {
                setPaymentConfirmatinTrigger(true)
            }

        }
    }

    if (subscriptionPopUpOperationMode === "new-subscription") {
        return (
            <div className={styles.container}>
                {
                    subscriptionSelectionStage === "subscription-select" ?
                        <>
                            {
                                isFreePlanSelected ?
                                    <></>
                                    :
                                    <button className={styles.freePlanCTA} onClick={(e) => handleFreeClick(e)} disabled={isFreeLoading}>
                                        {
                                            isFreeLoading ? "Setting you up ..." : "I’ll stay on the free plan"
                                        }
                                    </button>
                            }

                            {
                                isFreePlanSelected ?
                                    <div className='w-full flex items-center justify-end'>
                                        <ButtonV2 className={styles.continueButtonStyle} onClick={(e) => handleFreeClick()}>
                                            {
                                                isFreeLoading ? "Setting you up ..." : "Get Started"
                                            }
                                        </ButtonV2>
                                    </div>
                                    :
                                    <ButtonV2 disabled={isFreePlanSelected} className={styles.continueButtonStyle} onClick={(e) => setSubscriptionSelectionStage("review-pay")}>Continue</ButtonV2>
                            }

                        </>
                        : <></>
                }
                {
                    subscriptionSelectionStage === "review-pay" ?
                        <>
                            <ButtonV2 className={styles.backButtonStyle} onClick={(e) => setSubscriptionSelectionStage("subscription-select")}> <ArrowBackIcon /> Back</ButtonV2>
                            {
                                isPaidSubscriptionLoading ? <div className={styles.proceedButtonStyle}><LoaderIcon /></div>
                                    :
                                    <ButtonV2 className={styles.proceedButtonStyle} onClick={(e) => handleProceedToPayClick(e)}>
                                        Start Free Trial
                                    </ButtonV2>
                            }
                        </>
                        : <></>
                }
            </div>
        )
    } else {
        return (
            <div className={styles.containerUpdate} style={{ justifyContent: subscriptionSelectionStage === "review-pay" ? "space-between" : "" }}>
                {
                    subscriptionSelectionStage === "subscription-select" ?
                        <>
                            <button className={styles.cancelButton} onClick={(e) => handleCancelUpdateSubscriptionClick(e)}>Cancel</button>
                            <ButtonV2 className={styles.continueButtonStyle} disabled={!isContinueButtonEnabled} onClick={(e) => setSubscriptionSelectionStage("review-pay")}>Continue</ButtonV2>
                        </>
                        : <></>
                }
                {
                    subscriptionSelectionStage === "review-pay" ?
                        <>
                            <ButtonV2 className={styles.backButtonStyle} onClick={(e) => setSubscriptionSelectionStage("subscription-select")}> <ArrowBackIcon style={{ height: "16px", width: "16px" }} /> Back</ButtonV2>
                            {
                                isSubscriptionUpdating ? <div className={styles.proceedButtonStyle}><LoaderIcon /></div>
                                    :
                                    <ButtonV2 className={styles.proceedButtonStyle} onClick={(e) => handleUpdateClick(e)} disabled={!isProceedButtonEnabled}>
									   {
											requiresFreeTrial ? "Start Free Trial" : "Update Subscription"
										} 
                                    </ButtonV2>
                            }
                        </>
                        : <></>
                }
            </div>
        )

    }

}

export default ActionArea
