import { scheduleOfficeWork, updateOfficeWork } from "../../../utils/eventService";
import { triggerToast } from "../../../utils/toastController";
import { getTimeInFormat } from "./utils";

async function handleUpdateOfficeWork(allEventData) {


    const { requiredEventData } = allEventData

    let data = {
        time: {
            start: getTimeInFormat(allEventData.selectedStartTime),
            end: getTimeInFormat(allEventData.selectedEndTime),
        },
        timeZone: allEventData.selectedTimeZone,
        attendeeDetails: allEventData.isSelectedUser.map((user) => ({
            id: user,
        })),
        invoiceDetails: {
            hourlyPrice: allEventData.hourlyCost,
        },
        additionalInfo: allEventData.infoWork,
        date: allEventData.selectedDate.toISOString(),
    };

    try {
        await updateOfficeWork(requiredEventData._id, data);
        triggerToast("Office work Update successfully", "success", {
            autoClose: 3000,
        });

    } catch (error) {
        console.error("Error scheduling office work:", error);
        triggerToast("Some error occurred", "error", { autoClose: 3000 });
    }
}


export default handleUpdateOfficeWork