import React from 'react'

const ProfileIcon = ({ type }) => {
    return type !== "active" ? (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10532_18227)">
                <path d="M11.3385 0.333984C5.44812 0.333984 0.671875 5.10929 0.671875 11.0002C0.671875 16.8911 5.44765 21.6664 11.3385 21.6664C17.2299 21.6664 22.0052 16.8911 22.0052 11.0002C22.0052 5.10929 17.2299 0.333984 11.3385 0.333984ZM11.3385 3.5233C12.2742 3.52324 13.1716 3.89489 13.8333 4.55648C14.495 5.21808 14.8668 6.11543 14.8668 7.05114C14.8669 7.98684 14.4953 8.88424 13.8337 9.54593C13.1721 10.2076 12.2747 10.5794 11.339 10.5794C10.4033 10.5795 9.50591 10.2079 8.84422 9.54626C8.18254 8.88466 7.81077 7.98731 7.81071 7.0516C7.81065 6.1159 8.18229 5.2185 8.84389 4.55681C9.50549 3.89513 10.4028 3.52336 11.3385 3.5233ZM11.3362 18.8777C9.46706 18.8797 7.65885 18.213 6.23854 16.9979C6.07325 16.8569 5.9405 16.6817 5.84942 16.4844C5.75834 16.2871 5.71111 16.0724 5.71097 15.8551C5.71097 13.8793 7.31008 12.298 9.28636 12.298H13.3917C15.3684 12.298 16.9614 13.8793 16.9614 15.8551C16.9616 16.0724 16.9145 16.287 16.8235 16.4842C16.7325 16.6815 16.5997 16.8566 16.4343 16.9974C15.0141 18.213 13.2056 18.88 11.3362 18.8777Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_10532_18227">
                    <rect width="21.3333" height="21.3333" fill="white" transform="translate(0.667969 0.333984)" />
                </clipPath>
            </defs>
        </svg>

    ) :
        (<svg width="28" height="28" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_10532_18227)">
                <path d="M11.3385 0.333984C5.44812 0.333984 0.671875 5.10929 0.671875 11.0002C0.671875 16.8911 5.44765 21.6664 11.3385 21.6664C17.2299 21.6664 22.0052 16.8911 22.0052 11.0002C22.0052 5.10929 17.2299 0.333984 11.3385 0.333984ZM11.3385 3.5233C12.2742 3.52324 13.1716 3.89489 13.8333 4.55648C14.495 5.21808 14.8668 6.11543 14.8668 7.05114C14.8669 7.98684 14.4953 8.88424 13.8337 9.54593C13.1721 10.2076 12.2747 10.5794 11.339 10.5794C10.4033 10.5795 9.50591 10.2079 8.84422 9.54626C8.18254 8.88466 7.81077 7.98731 7.81071 7.0516C7.81065 6.1159 8.18229 5.2185 8.84389 4.55681C9.50549 3.89513 10.4028 3.52336 11.3385 3.5233ZM11.3362 18.8777C9.46706 18.8797 7.65885 18.213 6.23854 16.9979C6.07325 16.8569 5.9405 16.6817 5.84942 16.4844C5.75834 16.2871 5.71111 16.0724 5.71097 15.8551C5.71097 13.8793 7.31008 12.298 9.28636 12.298H13.3917C15.3684 12.298 16.9614 13.8793 16.9614 15.8551C16.9616 16.0724 16.9145 16.287 16.8235 16.4842C16.7325 16.6815 16.5997 16.8566 16.4343 16.9974C15.0141 18.213 13.2056 18.88 11.3362 18.8777Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_10532_18227">
                    <rect width="21.3333" height="21.3333" fill="white" transform="translate(0.667969 0.333984)" />
                </clipPath>
            </defs>
        </svg>
        )
}

export default ProfileIcon