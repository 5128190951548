export default function CancelFilled(props) {

	return (

<svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_38842_81730)">
      <path
        d="M8.00002 16C12.4183 16 16 12.4183 16 8.00002C16 3.58173 12.4183 0 8.00002 0C3.58173 0 0 3.58173 0 8.00002C0 12.4183 3.58173 16 8.00002 16Z"
        fill="#7C859C"
      />
      <path
        d="M11.65 11.65C11.35 11.95 10.85 11.95 10.55 11.65L7.99998 9.10002L5.45 11.65C5.15001 11.95 4.64998 11.95 4.34999 11.65C4.05 11.35 4.05 10.85 4.34999 10.55L6.89998 8.00001L4.34999 5.45003C4.05 5.15004 4.05 4.65001 4.34999 4.35002C4.64998 4.05003 5.15001 4.05003 5.45 4.35002L7.99998 6.90001L10.55 4.35002C10.85 4.05003 11.35 4.05003 11.65 4.35002C11.95 4.65001 11.95 5.15004 11.65 5.45003L9.09999 8.00001L11.65 10.55C11.95 10.85 11.95 11.35 11.65 11.65Z"
        fill="white"
      />
    </g>
    <rect x={0.5} y={0.5} width={15} height={15} rx={7.5} stroke="#7C859C" />
    <defs>
      <clipPath id="clip0_38842_81730">
        <rect width={16} height={16} rx={8} fill="white" />
      </clipPath>
    </defs>
  </svg>
	)

}
