import React from 'react'
import IconCompBinding from '../IconCompBinding/IconCompBinding'
import ClockIcon from "../CalendarSideIcons/ClockIcon";
import LocationIcon from "../CalendarSideIcons/LocationIcon";
import VidCamIcon from "../CalendarSideIcons/VidCamIcon";
import PersonIcon from "../CalendarSideIcons/PersonIcon";
import InfoIcon2 from "../CalendarSideIcons/InfoIcon2";
import ReminderBellIcon from "../CalendarSideIcons/ReminderBellIcon";
import ParagraphIcon from "../CalendarSideIcons/ParagraphIcon";
import TimeZoneSelector from '../TimeZoneSelector/TimeZoneSelector';
import ServicesSelectionSection from '../ServicesSelectionSection/ServicesSelectionSection';
import LocationSelection from '../LocationSelection/LocationSelection';
import MeetingLinkSelector from '../MeetingLinkSelector/MeetingLinkSelector';
import InfoTextArea from '../InfoTextArea/InfoTextArea';
import MainCalendarDateSelection from '../CalendarV2DateTime/MainCalendarDateSelection/MainCalendarDateSelection';
import AttendeeSelector from '../AttendeeSelectorv2/AttendeeSelector';



const StudentConsultationRequest = (props) => {
    const components = [
        {
            component: MainCalendarDateSelection,
            icon: ClockIcon,
        },
        {
            component: AttendeeSelector,
            icon: PersonIcon,
        },
        {
            component: InfoTextArea,
            icon: InfoIcon2,
            props: { height: "120px" }
        },

    ]
    return (
        <div className='md:w-[540px] w-fit'>
            <div className='flex items-start flex-col gap-10'>
                {
                    components.map((el, i) => {

                        return <IconCompBinding
                            icon={el.icon}
                            component={el.component}
                            _props={props}
                        />
                    })
                }
            </div>      
        </div>
    )
}

export default StudentConsultationRequest
