export default function ScheduleMapIcon(props) {

	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_35331_70289)">
				<mask id="mask0_35331_70289" style={{maskType: "luminance" }}maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
					<path d="M0 0.5H16V16.5H0V0.5Z" fill="white"/>
				</mask>
				<g mask="url(#mask0_35331_70289)">
					<path d="M7.19961 4.50001C7.19961 4.05819 6.84144 3.70001 6.39961 3.70001C5.95779 3.70001 5.59961 4.05819 5.59961 4.50001V6.90001C5.59961 7.11219 5.6839 7.31567 5.83392 7.4657L7.43392 9.06569C7.74635 9.37809 8.25289 9.37809 8.56529 9.06569C8.87769 8.75329 8.87769 8.24675 8.56529 7.93432L7.19961 6.56864V4.50001Z" fill="currentColor"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4496 10.8176L15.7656 15.1344C15.8399 15.2086 15.899 15.2968 15.9392 15.3938C15.9794 15.4909 16.0002 15.595 16.0002 15.7C16.0002 15.805 15.9794 15.9091 15.9392 16.0062C15.899 16.1032 15.8399 16.1914 15.7656 16.2656C15.6914 16.3402 15.6034 16.3993 15.5063 16.4397C15.4092 16.4801 15.3051 16.5008 15.2 16.5008C15.0949 16.5008 14.9908 16.4801 14.8937 16.4397C14.7966 16.3993 14.7086 16.3402 14.6344 16.2656L10.3176 11.9496C9.19896 12.8242 7.81996 13.2996 6.4 13.3C2.8712 13.3 0 10.4288 0 6.9C0 3.3712 2.8712 0.5 6.4 0.5C9.9288 0.5 12.8 3.3712 12.8 6.9C12.8001 8.32004 12.3246 9.6992 11.4496 10.8176ZM6.4 2.1C3.7528 2.1 1.6 4.2528 1.6 6.9C1.6 9.5472 3.7528 11.7 6.4 11.7C9.0472 11.7 11.2 9.5472 11.2 6.9C11.2 4.2528 9.0472 2.1 6.4 2.1Z" fill="currentColor"/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_35331_70289">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>

	)

}
