import React, { useEffect, useState } from 'react'
import ExternalLinkIcon from '../../assets/icons/AccountDropdownCard/ExternalLinkIcon'
import EditPen from '../../assets/icons/SettingsIcon/EditPen'
import { useDispatch, useSelector } from 'react-redux';
import EditInfo from './Modals/EditInfo';
import TooltipIcon from '../../assets/icons/SettingsIcon/TooltipIcon';
import UpdatePassword from './Modals/UpdatePassword';
import { Link } from 'react-router-dom';
import { augumentPhoneNumber } from '../../utils/phoneNumberUtils';
import { getFormattedDateV2 } from '../../utils/dateUtils';
import { useLazyGetPersonalDetailQuery } from '../../app/services/users';
import { updateArbitraryField, updateUserDetails } from '../../app/slices/user';
import Tooltip from '../../components/Tooltip/Tooltip';
import { getTooltipStyleClass } from '../../utils/commonlyUsedTooltipStyles';

const AccountPage = () => {

    const user = useSelector((state) => state.user);
    // const [user, setUser] = useState(null)

    const [openEditPopup, setOpenEditPopup] = useState(false)
    const [openPasswordEditPopup, setOpenPasswordEditPopup] = useState(false)
    const [fetchPersonalDetails,] = useLazyGetPersonalDetailQuery()
    const { lastUpdateDatePassword } = useSelector((state) => state.user)
    const [updatedPasswordDate, setUpdatedPasswordDate] = useState(null)
    const [openMode, setOpenMode] = useState("_self")
    const [tempUserDetails, setTempUserDetails] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        user && setTempUserDetails({
            "firstName": user.firstName,
            "lastName": user.lastName,
            "role": user.role,
            "email": user.email,
            "phone": user.phone,
            "phoneCode": user.phoneCode,
			"photo": user.photo,
			"awsLink": user.awsLink
        })
    }, [user])


    useEffect(() => { console.log("0900", tempUserDetails) }, [tempUserDetails])

    useEffect(() => {
        const mode = window.localStorage.getItem("evalloToken") ? "_blank" : "_self"
        setOpenMode(mode)
    }, [])


    useEffect(() => {
        setUpdatedPasswordDate(lastUpdateDatePassword)
    }, [lastUpdateDatePassword])



    function fetchAndSetPersonalDetails(mode) {

        fetchPersonalDetails()
            .then((res) => {
                if (!res.error) {
                    console.log("RRRED", res)
                    if (mode === "user") {
                        console.log("0900")
                        const {
                            firstName,
                            lastName,
                            _id,
                            amountToPay,
                            credits,
                            role,
                            email,
                            phone,
                            phoneCode,
                            about,
                            associatedOrg,
                            photo,
                            lastUpdateDatePassword,
                            scheduleDetails,
							userDefaults
                        } = res.data.data.user;

                        dispatch(
                            updateUserDetails({
                                firstName,
                                lastName,
                                id: _id,
                                amountToPay,
                                credits,
                                role,
                                photo,
                                timeZone: res.data?.data?.details?.timeZone
                                    ? res.data?.data?.details?.timeZone
                                    : "",
                                email,
                                phone,
                                phoneCode,
                                associatedOrg,
                                about: about ? about : "",
                                scheduleDetails,
								userDefaults
                            })
                        )
                        setTempUserDetails({
                            firstName,
                            lastName,
                            id: _id,
                            amountToPay,
                            credits,
                            role,
                            photo,
                            timeZone: res.data?.data?.details?.timeZone
                                ? res.data?.data?.details?.timeZone
                                : "",
                            email,
                            phone,
                            phoneCode,
                            associatedOrg,
                            about: about ? about : "",
                            scheduleDetails
                        })

                        return

                    }
                    updateArbitraryField({
                        lastUpdateDatePassword: res.data.data.user.lastUpdateDatePassword
                    })
                    setUpdatedPasswordDate(res.data.data.user.lastUpdateDatePassword)
                }
            })
    }

    if (!tempUserDetails) return <></>;

    return (

        <div className='px-4 xl:px-8 xl:py-9'>
            <div className=' w-full xl:w-9/12'>
                <div className='flex items-center gap-[6px]'>
                    <h2 className='font-semibold text-xl text-secondary'>Account</h2>
                    <Tooltip tooltipContent={"Access your account management and personal info settings on this page"} tooltipStyles={getTooltipStyleClass({width : "320px" })}>
                        <TooltipIcon />
                    </Tooltip>



                </div>
                <div className='pt-6 flex flex-col gap-5 md:gap-9'>
                    <div className='px-[16px] py-[16px] rounded-[12px] bg-blue-2-50 flex justify-between gap-5'>
                        <div className='min-h-[72px] min-w-[72px] max-w-[72px] max-h-[72px] rounded-full overflow-hidden'>
                            {tempUserDetails.photo ? (
                                <img
                                    className="w-full h-full object-cover"
                                    src={tempUserDetails.awsLink + tempUserDetails.photo}
                                    alt=""
                                />
                            ) : (
                                // <img className='w-full h-full object-cover' src={"https://images.pexels.com/photos/633432/pexels-photo-633432.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} alt="" />
                                <div className="flex justify-center items-center bg-blue-50 text-white h-full w-full text-3xl uppercase">
                                    {tempUserDetails?.firstName[0] + tempUserDetails?.lastName[0]}
                                </div>
                            )}
                            {/* <img className='w-full h-full object-cover' src={tempUserDetails.awsLink + tempUserDetails.photo} alt="" /> */}
                        </div>
                        <div className='w-full flex flex-col md:justify-between md:flex-row md:items-center gap-[12px] md:gap-[0px]'>
                            <div>
                                <div className='font-medium text-lg leading-[22.5px] text-secondary'>{tempUserDetails.firstName + " " + tempUserDetails.lastName}</div>
                                <div className='font-normal text-base leading-[20px] text-secondary-80 py-[6px]'>{tempUserDetails.email}</div>
                            </div>
                            <div className='h-full flex items-end'>
                                <a className='flex items-center justify-center gap-2 border border-secondary-80 rounded py-[6px] px-3 ' target={openMode} href={`https://${window.location.host}/profile/${user?.role}/${user?.id}`}> <span className='font-normal text-sm leading-[17.5px] text-secondary-80'>View Profile</span> <ExternalLinkIcon /> </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='px-4 py-2 bg-secondary-5 text-secondary rounded-t-[12px] flex justify-between items-center'>
                            <div >Basic Info</div>
                            <div className=' text-blue-70 bg-white cursor-pointer flex justify-center items-center rounded-full h-6 w-6' onClick={(e) => setOpenEditPopup(true)} ><EditPen /></div>
                        </div>
                        <div className='py-3 px-4 flex flex-col gap-3'>
                            <div className='grid grid-cols-2'>
                                <div>
                                    <div className='font-medium text-sm text-secondary'>First Name</div>
                                    <div className='font-normal text-sm text-secondary-80'>{tempUserDetails.firstName}</div>
                                </div>
                                <div>
                                    <div className='font-medium text-sm text-secondary'>Last Name</div>
                                    <div className='font-normal text-sm text-secondary-80'>{tempUserDetails.lastName}</div>
                                </div>
                            </div>
                            <div className="border-b w-full"></div>
                            <div className='grid grid-cols-1 xl:grid-cols-2 gap-3 xl:gap-0 md:0 '>
                                <div>
                                    <div className='font-medium text-sm text-secondary'>Email</div>
                                    <div className='font-normal text-sm text-secondary-80'>{tempUserDetails.email}</div>
                                </div>
                                <div className="border-b w-full xl:hidden"></div>
                                <div>
                                    <div className='font-medium text-sm text-secondary'>Phone</div>
                                    <div className='font-normal text-sm text-secondary-80'>{tempUserDetails.phoneCode && tempUserDetails.phoneCode + " "}{tempUserDetails?.phone ? augumentPhoneNumber(tempUserDetails.phone) : "N/A"}</div>
                                </div>
                            </div>
                            <div className="border-b w-full"></div>
                            <div className='grid grid-cols-2 '>
                                <div>
                                    <div className='font-medium text-sm text-secondary'>Account Type</div>
                                    <div className='font-normal text-sm text-secondary-80'>Company</div>
                                </div>
                                <div>
                                    <div className='font-medium text-sm text-secondary'>Access Level</div>
                                    <div className='font-normal text-sm text-secondary-80 capitalize'>{tempUserDetails.role}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className='px-4 py-2 bg-secondary-5 text-secondary rounded-t-[12px] flex justify-between items-center'>
                            <div >Password</div>
                            <div><button className='font-normal text-sm leading-[14px] -tracking-[1%] text-blue-70 underline underline-offset-4' onClick={(e) => setOpenPasswordEditPopup(true)}>Update Password</button></div>
                        </div>
                        <div className='py-3 px-4 flex flex-col gap-3'>
                            <div className=''>
                                <div>
                                    <div className='font-medium text-sm text-secondary'>********</div>
                                    <div className='font-normal text-sm text-secondary-80'>{!updatedPasswordDate ? "Password not updated recently" : `Updated on ${updatedPasswordDate ? getFormattedDateV2((new Date(updatedPasswordDate)).toISOString(), "dd mm, yyyy") : ""}`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {openEditPopup && <EditInfo setOpenEditPopup={setOpenEditPopup} fetchAndSetPersonalDetails={fetchAndSetPersonalDetails} tempUserDetails={tempUserDetails} setTempUserDetails={setTempUserDetails} />}
            {openPasswordEditPopup && <UpdatePassword setOpenPasswordEditPopup={setOpenPasswordEditPopup} fetchAndSetPersonalDetails={fetchAndSetPersonalDetails} />}
        </div>
    )
}

export default AccountPage
