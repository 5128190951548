import frozenEventTypes from "../../utils/eventTypes";
import BookedMeetingEditor from "../BookedMeetingEditor/BookedMeetingEditor";
import Consultation from "../EventCalendar/Consultation/Consultation";
import InternalMeeting from "../InternalMeetings/InternalMeetings";
import OfficeWorkEditor from "../OfficeWork/OfficeWorkEditor";

import SessionEditor from "../SessionEditor/SessionEditor";

function EventOperatorRenderer(props) {

	const { selectedEventType, operationMode, rendererData } = props

	switch (selectedEventType.toLowerCase()) {
		case frozenEventTypes.OFFCWORK.eventType.toLowerCase(): return <OfficeWorkEditor {...props}/>;
		case frozenEventTypes.CONSULTATION.eventType.toLowerCase(): return <Consultation {...props}/>;
		case frozenEventTypes.SESSION.eventType.toLowerCase(): return <SessionEditor {...props}/>;
		case frozenEventTypes.INTMEETING.eventType.toLowerCase(): return <InternalMeeting {...props}/>;
		case frozenEventTypes.EXTMEETING.eventType.toLowerCase() : return <BookedMeetingEditor {...props}/>
	}

}

export default EventOperatorRenderer;
