import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import { useDeletePaymentMethodMutation } from "../../../../app/services/users";
import { triggerToast } from "../../../../utils/toastController";
import { triggerSubscriptionUpdate } from "../../../../app/slices/subscription";

const DeleteCardPopUp = ({ open, setOpen, deleteThisCard }) => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();
  const handleDeleteCard = () => {
    setloading(true);
    deletePaymentMethod({
      customerId: deleteThisCard.customerId,
      paymentMethodId: deleteThisCard.paymentMethodId,
    })
      .then((data) => {
        console.log("delete payment method api response");
        console.log(data);
        setloading(false);
        triggerToast(<div>Card Deleted SuccessFully!</div>, "success", {
          autoClose: 2000,
        });
        dispatch(triggerSubscriptionUpdate());
      })
      .catch((error) => {
        console.log("Error in delete payment method api");
        console.log(error);
        setloading(false);
        triggerToast(<div>Error in deleting Card!</div>, "error", {
          autoClose: 2000,
        });
      })
      .finally(() => {
        setOpen(false);
      });
  };
  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[90%] md:w-[720px] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Delete Card</div>
        <button onClick={(e) => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="grid gap-[16px]">
        <div className="text-secondary-80 text-[18px] font-[600] text-center">
          Are you sure you want to delete this card?
        </div>
        <div className="text-secondary-80 text-[16px] font-[400] text-center">
          You will need to add the card once again when you activate a paid
          subscription.
        </div>
        <div className="flex gap-[12px] justify-center py-2">
          <div className="flex gap-[12px] w-full md:w-[60%] ">
            <div
              className={`bg-error text-white cursor-pointer basis-1/2  text-center  px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
              onClick={() => {
                handleDeleteCard();
              }}
            >
              {loading ? <LoaderIcon /> : "Delete"}
            </div>
            <div
              className="text-secondary-60 basis-1/2 text-center border-[2px] border-secondary-60 px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] cursor-pointer justify-center"
              onClick={(e) => setOpen(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </PopupContainer>
  );
};

export default DeleteCardPopUp;
