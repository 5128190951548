import styles from './PackageSelector.module.css'

const CurrentPlanIndicator = () => {
    return(
        <div className={`${styles.currentPlanIndicator}`}>
            Current Plan
        </div>
    )
}


const PackageSelectorItem = ({img, packageName, helperText, priceId, isCurrentPlan, onClick, isSelected}) => {
    return(
        <div className={`${styles.container} ${!isSelected ? styles.normal : styles.selected} ${isCurrentPlan ? styles.currentPlan : ""}`} id={priceId} onClick={(e)=>onClick(e)}>
            <div className="flex flex-col gap-[12px] items-start justify-start pointer-events-none">
                <div className='flex gap-[8px] items-center justify-start'>
                    {<img src={img} className={styles.imgPackage} />}
                    <div className={styles.title}>{packageName}</div>
                    {
                        isCurrentPlan ? <CurrentPlanIndicator />  : <></>
                    }
                </div>
                <div className={styles.helper}>
                    {helperText}
                </div>
            </div>
            {
                !isCurrentPlan ? <input type="radio" className='accent-[#0671E0] h-[18px] w-[18px] pointer-events-none' key={isSelected ? "yes" : " no"} defaultChecked={isSelected}/> : <></>
            }
            
        </div>
    )
}

export default PackageSelectorItem