import axios from "axios"
import { toast } from "react-toastify"
import { getAuthHeader } from "../../../app/constants/constants"
import { fetchOneOffPriceForId, fetchPriceForId } from "./fetchers"


function formUpdateRequest(oldType,newType,productPrices,siMapping,topUps,coupon = null) {
    let tempSiMapping = [...siMapping]

    tempSiMapping.forEach((val,idx)=>{
        let tempVal = {...val}
        if(val.lookupKey.includes("PAYG")) {
            if(val.lookupKey.includes("Calendar")){
                tempVal.newPriceId = productPrices.find((product)=>product.product.name.includes("Calendar")).prices[newType].find((price)=>price.lookup_key.includes("PAYG")).id
            }
            if(val.lookupKey.includes("Assignment")){
                tempVal.newPriceId = productPrices.find((product)=>product.product.name.includes("Assignment")).prices[newType].find((price)=>price.lookup_key.includes("PAYG")).id
            }
        }
        tempSiMapping[idx] = tempVal
    })

    const finalRequestData = []

    tempSiMapping.forEach((val,idx)=>{
        console.log("VAL**",val)
        if(val.delete){
            finalRequestData.push({
                op: "delete",
                stripeSubscriptionItemId : val.stripeSubscriptionItemId
            })
            return
        }
        if(val.newPriceId){
            finalRequestData.push({
                op: "update",
                stripeSubscriptionItemId : val.stripeSubscriptionItemId,
                newPriceId : val.newPriceId,
                ...(typeof(val.quantity)==="number") && {quantity : val.quantity}
            })
            return
        }
        if(val.lookupKey.includes("Tutor")){
            finalRequestData.push({
                op: "update",
                stripeSubscriptionItemId : val.stripeSubscriptionItemId,
                newPriceId : val.priceId,
                ...(typeof(val.quantity)==="number") && {quantity : val.quantity}
            })
            return
        }
    })


    topUps.forEach((val,idx)=>{
        finalRequestData.push({
            op : "add",
            priceId : val.priceId,
            quantity : val.quantity
        })
    })

    if(coupon) {
        finalRequestData.push({
            op : "add-coupon",
            coupon : coupon
        })
    }
    if(newType !== oldType  ) {
        finalRequestData[0].billingPeriodChangedTo = newType
    }
    console.log(JSON.stringify(finalRequestData))
    return finalRequestData
}


async function fetchInvoicePreviewForUpdate(updateRequest) {
    try{
        console.log(">>>PRPR",JSON.stringify(updateRequest))
        const res = await axios({
            method : "POST",
            url : process.env.REACT_APP_BASE_URL + `api/stripe/invoices/v2?previewProration=${true}`,
            headers : getAuthHeader(),
            data : {
                data : updateRequest
            }
        })
        return {
            amountDue : res.data.data.invoice.amount_due,
            amountRemaining : res.data.data.invoice.amount_remaining
        }
    }catch(err){
        console.log(err)
    }
}


function calculateTotalPrice(productPrices,siMapping,topUps,selectedBillingCycle,updateRequest) {

    let totalAmount = 0

    for(const request of updateRequest) {
        if(request.op === "update") {
            if(!request.stripeSubscriptionItemId) continue
            let productName = siMapping.find((val)=>val.stripeSubscriptionItemId === request.stripeSubscriptionItemId).lookupKey.split("_")[0]
            productName = productName === "BasicPackage" ? "BasePackage" : productName
            const price = fetchPriceForId(productPrices,request.newPriceId,selectedBillingCycle,productName)
            let quantity = 1
            if(typeof(request.quantity)==="number") quantity = request.quantity
            totalAmount += price * quantity
        }

        if(request.op === "add"){
            let productName = "TopUp"
            const price = fetchOneOffPriceForId(productPrices,request.priceId,productName)
            let quantity = 1
            if(typeof(request.quantity)==="number") quantity = request.quantity
            totalAmount += price * quantity
        }
    }
    console.log("TAMOUNT",totalAmount?.toFixed(0))
    return totalAmount?.toFixed(0)

}

export {
    formUpdateRequest,
    fetchInvoicePreviewForUpdate,
    calculateTotalPrice
}