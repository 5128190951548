import React from 'react'

const AssignmentIcon = ({ type }) => {
    return type !== "active" ? (

        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29019_14423)">
                <path d="M11.6772 23.0625C11.6772 23.5803 11.2576 24 10.7397 24H5.34912C3.28131 24 1.59912 22.3178 1.59912 20.25V3.75C1.59912 1.68219 3.28131 0 5.34912 0H16.8752C18.9429 0 20.6252 1.68219 20.6252 3.75V13.6875C20.6252 14.2053 20.2054 14.625 19.6877 14.625C19.1699 14.625 18.7502 14.2053 18.7502 13.6875V3.75C18.7502 2.71619 17.9091 1.875 16.8752 1.875H5.34912C4.31531 1.875 3.47412 2.71619 3.47412 3.75V20.25C3.47412 21.2838 4.31531 22.125 5.34912 22.125H10.7397C11.2576 22.125 11.6772 22.5447 11.6772 23.0625ZM22.7229 15.269C22.3019 14.9676 21.7164 15.0646 21.415 15.4854L16.7939 21.9402C16.6678 22.0865 16.5096 22.118 16.425 22.1237C16.3375 22.1294 16.1683 22.1184 16.0231 21.9802L13.0315 19.1076C12.6581 18.7491 12.0647 18.761 11.706 19.1345C11.3473 19.5081 11.3594 20.1015 11.7329 20.46L14.7274 23.3355C15.176 23.7623 15.7746 24 16.3906 24C16.4435 24 16.4966 23.9982 16.5497 23.9947C17.2206 23.95 17.847 23.6237 18.2683 23.0993C18.2791 23.0859 18.2897 23.072 18.2998 23.0579L22.9395 16.5769C23.2409 16.1559 23.144 15.5704 22.7229 15.269ZM15.9377 5.625H6.28149C5.76367 5.625 5.34399 6.04468 5.34399 6.5625C5.34399 7.08032 5.76367 7.5 6.28149 7.5H15.9377C16.4554 7.5 16.8752 7.08032 16.8752 6.5625C16.8752 6.04468 16.4554 5.625 15.9377 5.625ZM16.8752 10.3125C16.8752 9.79468 16.4554 9.375 15.9377 9.375H6.28149C5.76367 9.375 5.34399 9.79468 5.34399 10.3125C5.34399 10.8303 5.76367 11.25 6.28149 11.25H15.9377C16.4554 11.25 16.8752 10.8303 16.8752 10.3125ZM6.28149 13.125C5.76367 13.125 5.34399 13.5447 5.34399 14.0625C5.34399 14.5803 5.76367 15 6.28149 15H12.146C12.6638 15 13.0835 14.5803 13.0835 14.0625C13.0835 13.5447 12.6638 13.125 12.146 13.125H6.28149Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_29019_14423">
                    <rect width="24" height="24" fill="white" transform="translate(0.333496)" />
                </clipPath>
            </defs>
        </svg>
    ) :
        (
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_29035_1971)">
                    <path d="M13.2344 26.9062C13.2344 27.5104 12.7448 28 12.1406 28H5.85156C3.43912 28 1.47656 26.0374 1.47656 23.625V4.375C1.47656 1.96255 3.43912 0 5.85156 0H19.2987C21.7109 0 23.6737 1.96255 23.6737 4.375V15.9688C23.6737 16.5729 23.1839 17.0625 22.58 17.0625C21.9758 17.0625 21.4862 16.5729 21.4862 15.9688V4.375C21.4862 3.16888 20.5048 2.1875 19.2987 2.1875H5.85156C4.64545 2.1875 3.66406 3.16888 3.66406 4.375V23.625C3.66406 24.8311 4.64545 25.8125 5.85156 25.8125H12.1406C12.7448 25.8125 13.2344 26.3021 13.2344 26.9062ZM26.121 17.8138C25.6299 17.4622 24.9467 17.5754 24.5951 18.0663L19.2039 25.597C19.0567 25.7676 18.8721 25.8044 18.7734 25.811C18.6713 25.8176 18.4739 25.8048 18.3045 25.6435L14.8143 22.2922C14.3788 21.8739 13.6864 21.8878 13.2679 22.3236C12.8494 22.7594 12.8635 23.4518 13.2993 23.87L16.7929 27.2248C17.3163 27.7227 18.0146 28 18.7332 28C18.795 28 18.8569 27.9979 18.9189 27.9938C19.7016 27.9417 20.4324 27.561 20.924 26.9492C20.9366 26.9336 20.9489 26.9174 20.9607 26.9009L26.3737 19.3397C26.7253 18.8486 26.6123 18.1654 26.121 17.8138ZM18.205 6.5625H6.93933C6.33521 6.5625 5.84558 7.05212 5.84558 7.65625C5.84558 8.26038 6.33521 8.75 6.93933 8.75H18.205C18.8089 8.75 19.2987 8.26038 19.2987 7.65625C19.2987 7.05212 18.8089 6.5625 18.205 6.5625ZM19.2987 12.0312C19.2987 11.4271 18.8089 10.9375 18.205 10.9375H6.93933C6.33521 10.9375 5.84558 11.4271 5.84558 12.0312C5.84558 12.6354 6.33521 13.125 6.93933 13.125H18.205C18.8089 13.125 19.2987 12.6354 19.2987 12.0312ZM6.93933 15.3125C6.33521 15.3125 5.84558 15.8021 5.84558 16.4062C5.84558 17.0104 6.33521 17.5 6.93933 17.5H13.7812C14.3854 17.5 14.875 17.0104 14.875 16.4062C14.875 15.8021 14.3854 15.3125 13.7812 15.3125H6.93933Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_29035_1971">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        )
}

export default AssignmentIcon