import { useMemo } from "react"
import SmallCompleteIndicator from "../../assets/icons/SmallCompleteIndicator"
import SmallNoShowIndicator from "../../assets/icons/SmallNoShowIndicator"
import { getIconForEventType } from "./helpers/utils"


//CANCEL INDICATOR
function CancelIndicator (){
	return(
		<div className="min-w-[2px] min-h-[calc(100%-4px)] rounded-[24px] bg-[#F97066] absolute right-[5px] top-[2px]" />
	)
}

//COMPLETED INDICATOR
function CompletedIndicator() {
	return(
		<SmallCompleteIndicator className="absolute top-[0px] left-[100%] -translate-x-[75%] -translate-y-[25%] z-[10]"/>
	)
}

//NO SHOW INDICATOR
function NoShowIndicator() {
	return(
		<SmallNoShowIndicator className="absolute top-[0px] left-[100%] -translate-x-[60%] -translate-y-[45%]"/>
	)
}


//CONTAINER WITH ICONS
function ContainerWithIcon({iconInfo, customBgColor, customBgIconColor, children, containerStyles}){
	
	return(
		<div className={`w-full h-full max-w-full max-h-full overflow-hidden flex items-start justify-start rounded-[4px] min-h-[33px] ${containerStyles}`}>
			<div className="h-full flex items-center justify-center w-[24px] max-w-[24px]" style={{backgroundColor : customBgIconColor}}>
				<img src={iconInfo.icon} />
			</div>
			<div className="h-full w-[calc(100%-24px)] flex flex-col gap-[4px] px-[4px] items-center justify-center" style={{backgroundColor : customBgColor}}>
				{children}
			</div>
		</div>
	)

}

//CONTAINER WITHOUT ICONS
function ContainerWithoutIcon({containerStyles, inlineContainerStyles ,children}){
	return(
		<div className={`flex h-full w-full font-[lexend] rounded-[4px] min-h-[33px] ${containerStyles}`} style={inlineContainerStyles}>
			{children}
		</div>
	)
}



function EventCardWeek({eventType,status,studentName, tutorName, displayTextAttendee, customBgColor, customBgIconColor, icon, startTime, endTime}){

	//CARD STYLES
	const cardStyles = useMemo(()=>{
		const tempStyles = {}
		if(["pending", "tentative"].includes(status?.toLowerCase()) && eventType === "session"){
			tempStyles.container =  "flex-col pt-[6px] pb-[5px] px-[4px] gap-[4px] bg-white border-[1px] border-solid border-pending font-[lexend] relative justify-center items-center"
			tempStyles.headingStyles = "font-[500] text-[10px] leading-[10px] text-secondary-80 max-w-full overflow-hidden w-full text-left"
			tempStyles.timeStyles = "font-[300] text-[8px] leading-[8px] text-secondary-60 max-w-full overflow-hidden w-full text-left"
		} else if (["pending", "tentative"].includes(status?.toLowerCase()) && eventType !== "session"){
			tempStyles.container =  "bg-white border-[1px] border-solid border-pending font-[lexend] relative"
			tempStyles.headingStyles = "font-[500] text-[10px] leading-[10px] text-secondary-80 max-w-full overflow-hidden w-full text-left"
			tempStyles.timeStyles = "font-[300] text-[8px] leading-[8px] text-secondary-60 max-w-full overflow-hidden w-full text-left"
		} else if(status?.toLowerCase().includes("cancel")){
			tempStyles.container =  "flex-col py-[6px] px-[4px] bg-[rgba(43,43,43,0.3)] font-[lexend] items-center justify-center relative"
			tempStyles.headingStyles = "font-[500] text-[10px] leading-[10px] text-white line-through max-w-[calc(100%-6px)] text-left w-full max-w-full overflow-hidden"
 			tempStyles.timeStyles = "font-[300] leading-[8px] text-[8px] text-secondary-3 line-through max-w-[calc(100%-6px)] text-left w-full max-w-full overflow-hidden"
 		} else if(["session"].includes(eventType?.toLowerCase())){
			tempStyles.container =  "flex-col py-[6px] px-[4px]  font-[lexend] relative items-center justify-center gap-[2px]"
			if(status?.toLowerCase().includes("no") && status?.toLowerCase().includes("show")){
				tempStyles.container += " border-[1px] border-[solid] border-[#D92D20]"
			}
			tempStyles.headingStyles = "font-[500] text-[10px] leading-[10px] text-white text-left w-full max-w-full overflow-hidden"
			tempStyles.timeStyles = "font-[300] leading-[8px] text-[8px] text-secondary-3 text-left w-full max-w-full overflow-hidden"
		} else if(!["session"].includes(eventType?.toLowerCase())){
			//TO DO ON 12-09-2024
			tempStyles.headingStyles = "font-[500] text-[10px] leading-[10px] text-secondary w-full text-left  max-w-full overflow-hidden"
			tempStyles.timeStyles = "font-[300] leading-[8px] text-[8px] text-secondary w-full text-left max-w-full overflow-hidden"
		}
		return tempStyles

	},[eventType, status])


	//INLINE STYLES
	const containerInlineStyles = useMemo(()=>{
		if(status?.toLowerCase() === "pending" || ["cancel", "pending", "tentative"].includes(status?.toLowerCase()) || status?.toLowerCase().includes("cancel")){
			return {}
		} else if(["session", "consultation", "externalappointment", "internalmeeting", "officehours","officework"].includes(eventType?.toLowerCase())){
			return {backgroundColor : customBgColor}
		}
	},[eventType, status])
	

	//FOR ICON
	const renderInfo = useMemo(()=>{
		if( (status?.toLowerCase() === "pending" || ["cancel", "pending", "tentative"].includes(status?.toLowerCase()) || status?.toLowerCase().includes("cancel") ) && ["session"].includes(eventType?.toLowerCase())){
			return { 
				isIconVisible :	false,
				backgroundColor : customBgColor,
			}
		}else if( (status?.toLowerCase() === "pending" || ["cancel", "pending", "tentative"].includes(status?.toLowerCase()) || status?.toLowerCase().includes("cancel")) && !["session"].includes(eventType?.toLowerCase()) ){
			return {
				isIconVisible : true,
				backgroundColor : "white",
				iconBgColor : customBgColor,
				icon : getIconForEventType(eventType)?.icon
			}

		} else if(!["session"].includes(eventType?.toLowerCase())){
			return {
				isIconVisible : true,
				backgroundColor : customBgColor,
				iconBgColor : customBgIconColor,
				icon : getIconForEventType(eventType)?.icon
			}
		}
	},[eventType, status])


	const Renderer = renderInfo?.isIconVisible ? ContainerWithIcon : ContainerWithoutIcon

	return (
		<Renderer 
			iconInfo={{icon : renderInfo?.icon}}
			customBgColor={renderInfo?.backgroundColor}
			customBgIconColor={renderInfo?.iconBgColor}
			containerStyles={`flex h-full w-full font-[lexend] rounded-[4px] min-h-[33px] ${cardStyles.container}`}
			inlineContainerStyles={containerInlineStyles}
		>
			<div className={cardStyles.headingStyles}>
				{eventType.toLowerCase() === "session" ? `${studentName} / ${tutorName}` : `${displayTextAttendee}`}
			</div>	
			<div className={cardStyles.timeStyles}>
				{`${startTime} - ${endTime}`}
			</div>
			{["cancel"].includes(status?.toLowerCase()) && <CancelIndicator />}
			{status?.toLowerCase().includes("complete") && ["session"].includes(eventType?.toLowerCase()) && <CompletedIndicator />}
			{status?.toLowerCase().includes("no") && status?.toLowerCase().includes("show") && ["session"].includes(eventType?.toLowerCase()) && <NoShowIndicator />}
		</Renderer>
	)

}

export default EventCardWeek
