import React from 'react'

const StarIcon = ({ size }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_33301_144744)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.2795 1.91376C7.35287 1.78786 7.45796 1.68341 7.58429 1.61081C7.71063 1.53821 7.85379 1.5 7.9995 1.5C8.14521 1.5 8.28838 1.53821 8.41471 1.61081C8.54105 1.68341 8.64614 1.78786 8.7195 1.91376L10.5828 5.11242L14.2015 5.89642C14.3438 5.92735 14.4756 5.99506 14.5836 6.09278C14.6916 6.1905 14.7721 6.31483 14.8171 6.45337C14.8621 6.59191 14.87 6.73983 14.84 6.88237C14.81 7.02491 14.7432 7.1571 14.6462 7.26576L12.1795 10.0264L12.5528 13.7098C12.5676 13.8548 12.544 14.0012 12.4845 14.1342C12.4249 14.2673 12.3315 14.3824 12.2136 14.4681C12.0956 14.5538 11.9573 14.6071 11.8123 14.6226C11.6674 14.6381 11.5209 14.6153 11.3875 14.5564L7.9995 13.0631L4.6115 14.5564C4.47811 14.6153 4.33163 14.6381 4.18667 14.6226C4.04171 14.6071 3.90336 14.5538 3.78542 14.4681C3.66748 14.3824 3.57408 14.2673 3.51455 14.1342C3.45502 14.0012 3.43144 13.8548 3.44617 13.7098L3.8195 10.0264L1.35284 7.26642C1.25565 7.15777 1.18867 7.02553 1.15858 6.8829C1.12848 6.74027 1.13633 6.59224 1.18133 6.45359C1.22633 6.31494 1.30692 6.19052 1.41504 6.09276C1.52316 5.99499 1.65504 5.92729 1.7975 5.89642L5.41617 5.11242L7.2795 1.91376Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_33301_144744">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default StarIcon