import React, { useState } from "react";
import "./DateFormat.css";
import { updateDateFormat, updateUserDetails } from "../../app/slices/user";
const Dateformat = ({ isVisible, onClose, onSelectDateFormat }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const dateFormat = [" DD/MM/YYYY", "MM/DD/YYYY", " YYYY/MM/DD"];

  const filteredTimeZones = dateFormat.filter((zone) =>
    zone.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleDateFormatClick = (format) => {
    onSelectDateFormat(format);
    // dispatch(updateDateFormat({ dateFormat: value }));
  };
  // console.log("isVisible", isVisible);
  return isVisible ? (
    <div className="date-popover" onClick={(e) => e.stopPropagation()}>
      <ul className="date-time-zone-list">
        {filteredTimeZones.map((zone, index) => (
          <li
            key={index}
            className="date-time-zone-item"
            onClick={() => handleDateFormatClick(zone)}
          >
            {zone}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};
export default Dateformat;
