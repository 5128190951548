import React from 'react'

const ExternalLinkIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66602 11.8346L11.3327 5.16797" stroke="#515C7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66602 11.8346L11.3327 5.16797" stroke="black" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66602 11.8346L11.3327 5.16797" stroke="black" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66602 11.8346L11.3327 5.16797" stroke="black" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66602 5.16797H11.3327V11.8346" stroke="#515C7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66602 5.16797H11.3327V11.8346" stroke="black" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66602 5.16797H11.3327V11.8346" stroke="black" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.66602 5.16797H11.3327V11.8346" stroke="black" stroke-opacity="0.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default ExternalLinkIcon