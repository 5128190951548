import { useSelector } from 'react-redux'
import styles from './PermissionsEditor.module.css'
import LockIcon from '../../../../assets/Settings/LockIcon'
import ToggleButtonV2 from '../../../ToggleButtonV2/ToggleButtonV2'
import HorizontalDivider from '../../../HorizontalDivider/HorizontalDivider'
import { useUpdateOrgDataMutation } from '../../../../app/services/admin'
import { triggerToast } from '../../../../utils/toastController'
import { fetchAndSetOrgDetails } from '../../../../utils/orgService'
import { useState } from 'react'
import LoaderIcon from '../../../LoaderIcon/LoaderIcon'
import Tooltip from '../../../Tooltip/Tooltip'

const requiredPermissions = [
    "tutViewDetails",
    "stuParentViewDetails",
    "tutEditStudentProfile",
    "autoMapStudentsAdmin"
]

const requiredPermissionsDescriptionMapping = {
    "tutViewDetails": "If enabled, the emails and phone numbers of your clients will be hidden from your tutors. They will still be visible to the Admin.",
    "stuParentViewDetails": "If enabled, you will be able to hide the associated tutor’s email and phone number from your clients.",
    "tutEditStudentProfile": "Enable this if you want to allow tutors to make changes to associated students’ profiles.",
    "autoMapStudentsAdmin": "When enabled, all new students added to your Organization automatically map to your profile."
}

const PermissionsEditor = (props) => {

    const {organization} = useSelector((state)=>state.organization)
    const { permissions } = useSelector((state) => state.organization.organization.settings)
    const { subscriptionV2 } = useSelector((state) => state.subscription)
    const [updateOrgRequest, ] = useUpdateOrgDataMutation()
    const [isLoading, setIsLoading] = useState({
        "tutViewDetails" : false,
        "stuParentViewDetails" : false,
        "tutEditStudentProfile" : false,
        "autoMapStudentsAdmin" : false
    })


    const handleTogglePermissions = (key, idx, targetValue) => {
        const requiredFieldToBeUpdated = `settings.permissions.${idx}.choosedValue`
        const fields = {}
        fields[requiredFieldToBeUpdated] = targetValue
        setIsLoading((prev)=>{
            prev[key] = true
            return prev
        })
        updateOrgRequest({
            orgId : organization._id,
            op : "update",
            fields :fields
        }).then((res)=>{
            if(!res.error){
                triggerToast("Permission updated successfully","success",{autoClose : 3000})
                fetchAndSetOrgDetails(organization._id)
            }
            setIsLoading((prev)=>{
                prev[key] = false
                return prev
            })
        })
    }

    return (
        <div className={styles.container}>
            {
                permissions && requiredPermissions.map((key, idx) => {

                    let requiredPermissionIdx = 0
                    permissions.forEach((val, idx) => {
                        if(val.permissionActionName === key){
                            requiredPermissionIdx = idx
                            return
                        }}
                    )
                    const requiredPermission = permissions.find((val, idx) => val.permissionActionName === key)
                    const isSettingEnabled = (subscriptionV2?.subscription?.settings?.userPermissions?.isEnabled === true) ? (subscriptionV2?.subscription?.settings?.userPermissions?.restrictedPermissions.includes(key)) ? false : true : false 

                    return (
                        <>
                            <div className={styles.item}>
                                {
                                    (isSettingEnabled === false)?
                                        <div className={styles.lockIcon}>
                                            <Tooltip 
                                                anchorComponent={<LockIcon width={"11.85px"} height={"16px"} />}
                                                tooltipContent={"Upgrade your plan to access this feature"}
                                                tooltipStyleClass={"w-[180px] max-w-[320px] bg-white rounded-[12px] shadow-[0px_4px_24px_0px_#11167D26] p-3 flex flex-col items-start gap-[6px]"}
                                            />
                                            
                                        </div>
                                        : <></>
                                }

                                <h5 className={!isSettingEnabled ? styles.disabled : ""}>{requiredPermission?.name}</h5>
                                <p className={!isSettingEnabled ? styles.disabled : ""}>{requiredPermissionsDescriptionMapping[key]}</p>

                            </div>
                            <div className={`${styles.toggle} ${!isSettingEnabled ? styles.disabled : ""}`}>
                                {
                                    isLoading[key] ? 
                                    <LoaderIcon /> 
                                    :
                                    <ToggleButtonV2  isEnabled={requiredPermission?.choosedValue} setIsEnabled={(targetValue)=>handleTogglePermissions(key,requiredPermissionIdx,targetValue)}/>
                                }
                                
                            </div>
                            <div className={styles.horizontalDivider}>
                                <HorizontalDivider />
                            </div>
                        </>
                    )
                })
            }
        </div>
    )
}

export default PermissionsEditor