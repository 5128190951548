import { useEffect, useMemo, useRef, useState } from "react"
import moment from "moment-timezone";
import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import CrossIcon from "../../CrossIcon/CrossIcon"
import CustomDropdownV2 from "../../CustomDropdownV2/CustomDropdownV2"
import MoreInfoIcon from "../../MoreInfoIcon/MoreInfoIcon"
import PopupContainer from "../../PopupContainer/PopupContainer"
import Tooltip from "../../Tooltip/Tooltip"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useSelector } from "react-redux"
import { useGetAllOrgUsersQuery, useLazyGetAllOrgUsersQuery, useLazyGetAllUsersQuery, useLazyGetAssignedStudentsParentQuery } from "../../../app/services/users"
import LoaderIcon from "../../LoaderIcon/LoaderIcon"
import CalIconVariant2 from "../../CalendarSideIcons/CalIconVariant2"
import MiniCalendar from "../../CalendarV2/MiniCalendar/MiniCalendar"
import { get12HrTimeFromDate, getFormattedDateV2, roundToNearestHalfHour } from "../../../utils/dateUtils";
import DateRangeButton from "../../DateRangeButton/DateRangeButton";
import DateRangeSelector from "../../DateRangeSelector/DateRangeSelector";
import { addTimeOff, ediTimeOffInstance } from "../../../utils/timeOffService";
import { triggerToast } from "../../../utils/toastController";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import styles from "./VacationOperationPopUp.module.css"
import { EvChevronDownIcon, EvChevronUpIcon } from "../../../assets/icons/ChevronIcons";
import SearchFieldV2 from "../../SearchFieldV2/SearchFieldV2";
const _ = require("lodash")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

const MAX_WORD_COUNT_REASON = 500


function filterResults(val, idx, searchString){
	const tempArr = [val.firstName.toLowerCase(), val.lastName.toLowerCase()]
	return val.firstName.toLowerCase().includes(searchString?.toLowerCase()) || val.lastName.toLowerCase().includes(searchString.toLowerCase()) || tempArr.join(" ").includes(searchString.toLowerCase()) || tempArr.reverse().join(" ").includes(searchString.toLowerCase())
}

function getTimeOptions() {
  const times = [];
  let currentTime = 0;

  while (currentTime < 24 * 60) {
    const hours = Math.floor(currentTime / 60);
    const minutes = currentTime % 60;
    const ampm = hours >= 12 ? "PM" : "AM";
    const displayHours = (hours % 12 || 12).toString().padStart(2, "0");
    const displayMinutes =
      minutes === 0 ? "00" : minutes.toString().padStart(2, "0");
    const timeString = `${displayHours}:${displayMinutes} ${ampm}`;

    times.push(timeString);
    currentTime += 30;
  }

  return times;
};


function getHeaderText(mode) {
	switch(mode) {
		case "add" : return "Add Time Off";
		case "edit" : return "Edit Time Off";
		case "view" : return "Time Off Details";
	}
}


function isValidEntry(key, value) {
	switch(key){
		case "reason" : return ( (!value) || (value && value.split(" ").length <= MAX_WORD_COUNT_REASON));
		default : return true;
	}
}

function TimeZoneSelector({leaveDetails,setLeaveDetails}){
	
	const ddRef = useRef()
	const ddRef2 = useRef()
	const [isDDOpen, setIsDDOpen] = useState(false)
	const [searchInput, setSearchInput] = useState("")
	const timeZoneList = useMemo(()=>{
		return moment.tz.names().filter(val=>val.toLowerCase().includes(searchInput.toLowerCase()))
	},[searchInput])

	useOutsideAlerter(ddRef,()=>{
		setIsDDOpen(false)
	}, ddRef2)

	return(
		<div className="relative font-[400] text-[12px] leading-[18px] text-secondary-80 rounded-[4px] px-[12px] py-[9px] flex items-center justify-between w-full bg-[#F6F9FA] cursor-pointer "  ref={ddRef}>
			<div className="flex items-center justify-between w-full" onClick={()=>setIsDDOpen(prev=>!prev)}>
				<span>{leaveDetails.timeZone}</span>
				<button >{isDDOpen ? <EvChevronUpIcon /> : <EvChevronDownIcon/>}</button>
			</div>
			{
				isDDOpen ? 
				<div className="z-[10] absolute top-[calc(100%+10px)] w-full left-[0px] flex flex-col bg-white rounded-[6px] max-h-[220px] overflow-y-scroll shadow-[0px_4.05px_26.98px_0px_#000AFF29]">
					<div className="sticky top-[0px] py-[12px] bg-white" ref={ddRef2}>
						<SearchFieldV2 onChange={(e)=>{e.stopPropagation();setSearchInput(e.target.value);}} textFieldValue={searchInput} placeholder={"Search timezone"}/>
					</div>
					{
						timeZoneList.map(timezone => (
							<button className="text-left px-[8px] py-[8.5px] hover:bg-secondary-5 w-full hover:text-secondary" 
									onClick={()=>{setLeaveDetails(prev=>({...prev,timeZone : timezone}));setIsDDOpen(false)}}>{timezone}</button>	
						))
					}

				</div>
				: <></>
			}
		</div>
	)


}

function UserSelectDropdown(props){

	const ddRef = useRef(null)
	const [filterText, setFilterText] = useState("")
	const {selectedUser, setSelectedUser, userList, mode, role} = props

	const showDropdownButton  = useMemo(()=>{
			const allowedRoles = ["parent", "admin"]
			return allowedRoles.includes(role) && mode !== "view"
		},[role]) 
	const [dropdownTrigger, setDropdownTrigger] = useState(false)

	let dropdownProps = null
	let searchFieldProps = null
	if(showDropdownButton) {
		dropdownProps = {
			data : [
				{
					items : [...userList.filter((val,idx)=>filterResults(val,idx, filterText)).map(val => ({...val, fullName : [val.firstName, val.lastName].join(" "), clickInfo : val}))] }
			],
			dropdownItemProps : {
				useCustomKey : true,
				customKey : "fullName",
				onItemClick : (e,value) => {
					console.log("VAL", value)
					setSelectedUser(value)
					setDropdownTrigger(false)
				},
				styles : {
					itemStyleClass : "hover:bg-secondary-5 hover:text-secondary"
				}
			},
			dropdownContainerProps : {
				styleClass : "!pt-[0px]"
			}
		}
		searchFieldProps = {
			onChange : (e) => {setFilterText(e.target.value)},
			textFieldValue : filterText,
			placeholder : "Search by name",
		}
	}

	useOutsideAlerter(ddRef,()=>{
		setDropdownTrigger(false)
	})

	return (
		<div className={styles.userSelector}  ref={ddRef} style={{pointerEvents : !showDropdownButton ? "none" : "", cursor : !showDropdownButton ? "not-allowed" : ""}}>
			<div onClick={()=>setDropdownTrigger((prev)=>!prev)} className=" flex w-full items-center justify-between cursor-pointer " >
				{
					!selectedUser ? 
						<div className={styles.placeholder}>Select User*</div>
						: 
						<div className={styles.selected}>
							{[selectedUser.firstName, selectedUser.lastName].join(" ")}
						</div>
				}
				{
					showDropdownButton ? 
						<button >
							{dropdownTrigger ? <EvChevronUpIcon /> : <EvChevronDownIcon /> }
						</button>
						: <></>	
				}

			</div>
			{
				dropdownTrigger ? 
					<CustomDropdownV2 isSearchEnabled={true} {...dropdownProps} searchFieldProps={searchFieldProps}/>
					: <></>
			}	
		</div>)

}


function ButtonContainer ({onCancelClick, onSubmitClick, isLoading, isVisible, isSubmitDisabled}) {
	if(isVisible){
		return(
			<div className={styles.buttonContainer}>
				<button className={styles.cancel} onClick={(e)=>onCancelClick()}>Cancel</button>
				<button className={styles.submit} disabled={isLoading || isSubmitDisabled} onClick={(e)=>onSubmitClick()}>
					{
						isLoading ? <LoaderIcon /> : "Submit"
					}	
				</button>
			</div>
		)
	}
}


function SectionTwo ({leaveDetails, user, handleChange, mode,setLeaveDetails}) {
	
	const viewer = useSelector(state=>state.user)
	const {organization} = useSelector(state=>state.organization)

	const viewingTimezone = useMemo(()=>{
		if(viewer && organization){
			return viewer.userDefaults?.timeZone ? viewer.userDefaults?.timeZone : organization.settings.timeZone
		}
	},[viewer, organization])

	const [isRepeatDropdownOpen, setIsRepeatDropdownOpen] = useState(false)
	const [isEndDateSelectorOpen, setIsEndDateSelectorOpen] = useState(false)
	const [endDate, setEndDate] = useState(dayjs((new Date()).getTime() + (24 * 60 * 60 * 1000)))

	const timeOptions = getTimeOptions()
	const [timeDropdownTriggers, setTimeDropdownTriggers] = useState({
		startTimeTrigger : false,
		endTimeTrigger : false,
	})

	//Start & end time selected index.
	const [startTimeIdx, setStartTimeIdx] = useState()
	const [endTimeIdx, setEndTimeIdx] = useState()

	//Start & End Time Ref
	const startTimeSelectorRef = useRef(null)
	const endTimeSelectorRef = useRef(null)

	//DD -> Dropdown
	const [dateRangeDDTrigger, setDateRangeDDTrigger] = useState(false)
	
	//Refs for date buttons
	const parentRefDRS = useRef(null)
	const refDRS = useRef(null)

	//Other refs
	const repeatFequencyRef = useRef(null)
	const endDateSelectorParentRef = useRef(null)
	const endDateSelectorRef = useRef(null)

	useOutsideAlerter(refDRS,()=>{setDateRangeDDTrigger(false)},parentRefDRS)

	const keys = {
		isRecurring : "isRecurring",
		isLockedFullDay : "isLockedFullDay",
		repeastFrequency : "repeatFrequency",
		requiredDays : "requiredDays",
		recurringEndDate : "recurringEndDate",
		startTime : "startTime",
		endTime : "endTime"
	} 


	const repeatFrequency = [
		"Daily",
		"Weekly",
		"Monthly"
	]



	const monthlyValues = (new Array(31)).fill(0).map((val,idx)=>(idx+1))
	const weeklyValues = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]

	const dropdownProps = {
	
		data : [
			{
				items : repeatFrequency.map((val)=>({value : val, clickInfo : val}))
			}
		],
		dropdownItemProps : { useCustomKey : true,
			customKey : "value",
			onItemClick : (e, value) => {
				e.stopPropagation()
				setIsRepeatDropdownOpen(false)
				handleChange(keys.repeastFrequency, value.toLowerCase())
			},
			getSelectedStyle : (item) => {
				if(item.value.toLowerCase() === leaveDetails.repeatFrequency){
					return "!bg-secondary-5 !text-secondary"
				}
			},
			styles : {
				itemStyleClass : "hover:bg-secondary-5 hover:text-secondary"
			}
		},
		
	}
	
	//To seed the start time index
	useEffect(()=>{
		const selectedStartTime = get12HrTimeFromDate(leaveDetails.startDate)
		const startTimeIdx = timeOptions.findIndex(val=> val === selectedStartTime)
		setStartTimeIdx(startTimeIdx)
	
	},[])

	useOutsideAlerter(startTimeSelectorRef, ()=>{
		setTimeDropdownTriggers(prev=>({...prev, startTimeTrigger : false}))
	})
	useOutsideAlerter(endTimeSelectorRef, ()=>{
		setTimeDropdownTriggers(prev => ({...prev, endTimeTrigger : false}))
	})
	useOutsideAlerter(repeatFequencyRef, ()=>{
		setIsRepeatDropdownOpen(false)
	})
	useOutsideAlerter(endDateSelectorRef, ()=>{
		setIsEndDateSelectorOpen(false)
	}, endDateSelectorParentRef)

	
	return (
		<div className={styles.sectionTwo} style={{pointerEvents : mode === "view" ? "none" : "", cursor : mode === "view" ? "not-allowed" : ""}}>
			<>
				<TimeZoneSelector leaveDetails={leaveDetails} setLeaveDetails={setLeaveDetails}/>
				<div />
				<div className={"relative"}>
					<DateRangeButton startDate={leaveDetails.startDate} endDate={leaveDetails.endDate} onClick={(e)=> setDateRangeDDTrigger((prev)=>!prev)} ref={parentRefDRS} buttonStyle={{backgroundColor : "#F6F9FA", border : "none", color : "#515C7B"}} timeZone={leaveDetails.timeZone}/>
					{
						dateRangeDDTrigger ? 
						<DateRangeSelector startDate={leaveDetails.startDate} endDate={leaveDetails.endDate} setStartDate={(value)=>handleChange("startDate", value)} setEndDate={(value)=>handleChange("endDate", value)} ref={refDRS} timeZone={leaveDetails.timeZone}/>
						: <></>
					}
				</div>
				<div className={styles.timeSelector} style={{visibility : leaveDetails.isLockedFullDay ? "hidden" : "visible"}}>
					<div 
						className={styles.container} ref={startTimeSelectorRef} onClick={()=>{setTimeDropdownTriggers((prev)=>({...prev, startTimeTrigger : !prev.startTimeTrigger}))}}>
						<span style={{textWrap :"nowrap"}}>{get12HrTimeFromDate(leaveDetails.startDate)}</span>
						<button >
							{timeDropdownTriggers.startTimeTrigger ? <EvChevronUpIcon/> : <EvChevronDownIcon/> }
						</button>
						{
							timeDropdownTriggers.startTimeTrigger ? 
								<div
									className={`${styles.searchDropdown} ${styles.startDropdown}`}
									
								>
									{timeOptions.map((time, idx) => (
										<div
											key={time}
											className={styles.dropdownOption}
											onClick={(e) => {
												e.stopPropagation();
												setStartTimeIdx(idx)
												handleChange(keys.startTime,time); 
												setTimeDropdownTriggers((prev)=>({...prev, startTimeTrigger : false}))}
											}
										>
											<div className={styles.elementSessionsMo}>{time}</div>
										</div>
									))}
								</div>
							: <></>
						}
					</div>
					<span>-</span>
					<div className={styles.container} ref={endTimeSelectorRef} onClick={()=>{setTimeDropdownTriggers((prev)=>({...prev, endTimeTrigger : !prev.endTimeTrigger}))}} >
						<span style={{textWrap :"nowrap"}}>{get12HrTimeFromDate(leaveDetails.endDate)}</span>
						<button >
							{timeDropdownTriggers.endTimeTrigger ? <EvChevronUpIcon/> : <EvChevronDownIcon/> }
						</button>
						{
							timeDropdownTriggers.endTimeTrigger ? 
								<div
									className={`${styles.searchDropdown} ${styles.startDropdown}`}
								>
									{timeOptions.filter((val,idx)=> idx > startTimeIdx).map((time,idx) => (
										<div
											key={time}
											className={styles.dropdownOption}
											onClick={(event) => {
												event.stopPropagation();
												setEndTimeIdx(idx)
												handleChange(keys.endTime, time);
												setTimeDropdownTriggers((prev)=>({...prev, endTimeTrigger : false}))}}
										>
											<div className={styles.elementSessionsMo}>{time}</div>
										</div>
									))}
								</div>
							: <></>
						}
					</div>
				</div>
				<div className={styles.inputGroup}>
					<input type={"checkbox"} checked={leaveDetails.isLockedFullDay} onChange={(e)=>handleChange(keys.isLockedFullDay, e.target.checked)}/>
					<label>Unavailable all day</label>
				</div>
				<div className={styles.inputGroup}>
					{
						!leaveDetails.isLockedFullDay ? 
							<>
								<input type="checkbox" checked={leaveDetails.isRecurring} onChange={(e)=>handleChange(keys.isRecurring, e.target.checked)}/>
								<label>Recurring</label>
							</>
							: null 
					}
				</div>
				{leaveDetails.isRecurring && !leaveDetails.isLockedFullDay ?
				<>

						<div className={styles.inputGroupVert}>
							<span>Repeats</span>
							<div className={styles.container} ref={repeatFequencyRef} onClick={()=>setIsRepeatDropdownOpen((prev)=>!prev)}>
								<span style={{textTransform : "capitalize"}}>{leaveDetails.repeatFrequency}</span>
								<button >
									{isRepeatDropdownOpen ? <EvChevronUpIcon /> : <EvChevronDownIcon /> }
								</button>
								{
									isRepeatDropdownOpen ? 
										<CustomDropdownV2 {...dropdownProps} />
										: <></>
								}
							</div>
						</div>
						{
							leaveDetails.repeatFrequency.toLowerCase() === "monthly"? 
								<div className={`${styles.inputGroupVert} ${styles.rowSpanMonthly}`}>
									<span>Repeats on</span>
									<div className={styles.monthSelector}>
										{
											monthlyValues.map((val,idx)=>(
												<button className={`${styles.item} ${parseInt(leaveDetails.requiredDays) === val ? styles.selected : ""}`} onClick={(e)=>handleChange(keys.requiredDays,val)}>{val}</button>
											))
										}	
									</div>
								</div>
								:<></>
						}
						{
							leaveDetails.repeatFrequency.toLowerCase() === "weekly" ? 
								<div className={`${styles.inputGroupVert}`}>
									<span>Repeats on </span>	
									<div className={styles.daySelector}>
										{
											weeklyValues.map((val,idx)=>(
												<button className={`${styles.item} ${leaveDetails.requiredDays.toString().includes(val) ? styles.selected : ""}`} onClick={()=>{handleChange(keys.requiredDays,val)}}>{val[0]}</button>
											))
										}
									</div>
								</div>
								: <></>
						}
						<div className={styles.inputGroupVert} style={{marginTop : leaveDetails.repeatFrequency.toLowerCase().includes("monthly") ? "-64px" : "0px"}}>
							<span>Ends on</span>
							<div className={styles.container} ref={endDateSelectorParentRef}>
								<span className={`${leaveDetails.recurringEndDate ? "": styles.placeholderStyle}`} onClick={()=>setIsEndDateSelectorOpen((prev)=>!prev)}>{ leaveDetails.recurringEndDate ? getFormattedDateV2(leaveDetails.recurringEndDate, "dd-m-yyyy") : "Enter Date"}</span>
								<button onClick={()=>setIsEndDateSelectorOpen((prev)=>!prev)} >
									<CalIconVariant2 />
								</button>
								{
									isEndDateSelectorOpen ? 
										<div className={styles.endDateSelector} ref={endDateSelectorRef}>
											<MiniCalendar {...{value : endDate, onChange : (newValue)=> {setEndDate(newValue); handleChange(keys.recurringEndDate, newValue);setIsEndDateSelectorOpen(false)}, setValue : setEndDate}}/>
										</div>
										: <></>
								}
							</div>
						</div>
				</>
				: <></>}
			</>
		</div>
	)
}


//mode = ["add","edit"]
function VacationOperationPopUp({trigger, setTrigger, role, mode="add", defaultLeaveDetails, inheritedUser, refetchTimeOff}){

	const user = useSelector((state)=>state.user)
	const {organization } = useSelector(state=>state.organization)

	const [leaveDetails, setLeaveDetails] = useState({
			isRecurring : false,
			startDate: roundToNearestHalfHour(new Date()),
			endDate: roundToNearestHalfHour(new Date(Date.now() + (1000 * 3600 * 0.5))),
			isLockedFullDay : true,
			repeatFrequency : "weekly",
			requiredDays : "",
			dateRange : [],
			reason : "",
			recurringEndDate : "",
			timeZone : user?.userDefaults?.timeZone ? user?.userDefaults?.timeZone : organization?.settings?.timeZone
	})
	const [selectedUser, setSelectedUser] = useState(null)
	const [userList, setUserList] = useState([])

	const [getAllUsers,] = useLazyGetAllOrgUsersQuery()
	const [getAllStudents, ] = useLazyGetAssignedStudentsParentQuery()

	const [isLoading, setIsLoading] = useState(false)

	useEffect(()=>{
		if((mode === "edit" || mode === "view") && defaultLeaveDetails && inheritedUser){
			setSelectedUser(inheritedUser)
			setLeaveDetails(defaultLeaveDetails)
		}
		console.log(mode, defaultLeaveDetails)
	},[mode, defaultLeaveDetails, inheritedUser])


	useEffect(()=>{
		if(user && !["parent","admin"].includes(user?.role)){
			setSelectedUser({...user, _id : user?.id})
		}
	},[user])


	useEffect(()=>{
		if(user){
			if(user.role === "admin") {
				getAllUsers()
					.then((res)=>{
						if(!res.error){
							setUserList(res.data.data.user)
						}
					})
			} else if (user.role === "parent") {
				getAllStudents()
					.then((res)=>{
						if(!res.error){
							setUserList(res.data.data.students)
						}
					})
			}
		}
	},[user])


	function handleChange(key, value) {
		console.log("CR",leaveDetails.repeatFrequency)
		if(isValidEntry(key, value)){
			if(key === "requiredDays" && leaveDetails.repeatFrequency === "weekly"){
				const tempLeaveDetailsStr = leaveDetails.requiredDays.toString()
				if(!tempLeaveDetailsStr.includes(value)){
					if(parseInt(tempLeaveDetailsStr)<=31 && parseInt(tempLeaveDetailsStr)>=1){
						setLeaveDetails((prev)=>({...prev, [key] : value}))
					} else {
						console.log("CR",value)
						setLeaveDetails((prev)=>({...prev, [key] : prev[key]+" "+value}))
						console.log("CR",tempLeaveDetailsStr)
					}
				}else {
					let tempArr = tempLeaveDetailsStr.split(" ")
					tempArr = tempArr.filter(val => val !== value)
					const finalStr = tempArr.join(" ")
					setLeaveDetails((prev)=>({...prev, [key] : finalStr}))
				}
				return
			} else if(key === "startTime" || key === "endTime") {
				const meridian = value.split(" ").pop()
				const timePart = value.split(" ")[0]
				let [hours, minutes] = timePart.split(":").map(val => parseInt(val))
				if(meridian === "PM"){
					  if(hours !== 12) {
						hours = hours + 12
					  }
				} else {
					if(hours === 12){
						hours = 0
					}
				}
				const requiredKey = key.startsWith("start") ? "startDate" : "endDate"
				const requiredDate = leaveDetails[requiredKey]
				requiredDate.setHours(hours, minutes)
				setLeaveDetails((prev)=>({...prev, [requiredKey] : requiredDate}))		
				return
			}
			setLeaveDetails((prev)=>({...prev, [key] : value}))
		}
	}


	async function handleSubmitAdd() {
		const stString = dayjs(leaveDetails.startDate).format("YYYY-MM-DD hh:mm A")
		const endString = dayjs(leaveDetails.endDate).format("YYYY-MM-DD hh:mm A")
		let recurDtString = null
		if(leaveDetails.isRecurring && leaveDetails.recurringEndDate){
			recurDtString = dayjs(leaveDetails.recurringEndDate).format("YYYY-MM-DD hh:mm A")
		}
		console.log("STS",stString)
		console.log("STSE", endString)
		console.log("STSR", recurDtString)
		try {
			setIsLoading(true)
			const result = await addTimeOff({
				...leaveDetails,
				targetUserId : selectedUser._id,
				startDate : dayjs.tz(stString, "YYYY-MM-DD hh:mm A", leaveDetails.timeZone).toISOString(),
				endDate : dayjs.tz(endString, "YYYY-MM-DD hh:mm A", leaveDetails.timeZone).toISOString(),
				...(leaveDetails.isRecurring) && {
					recurringEndDate : dayjs.tz(recurDtString, "YYYY-MM-DD hh:mm A", leaveDetails.timeZone).toISOString(),
				},
			})
			refetchTimeOff()
			setTrigger(false)
		}catch(err){
			console.error(err)
		}finally {
			setIsLoading(false)
		}
	}

	async function handleSubmitEdit(){
		const stString = dayjs(leaveDetails.startDate).format("YYYY-MM-DD hh:mm A")
		const endString = dayjs(leaveDetails.endDate).format("YYYY-MM-DD hh:mm A")
		let recurDtString = null
		if(leaveDetails.isRecurring && leaveDetails.recurringEndDate){
			recurDtString = dayjs(leaveDetails.recurringEndDate).format("YYYY-MM-DD hh:mm A")
		}
		try {
			setIsLoading(true)
			const result = await ediTimeOffInstance(
				leaveDetails._id,
				selectedUser._id,
				_.omit({
					...leaveDetails, 
					startDate : dayjs.tz(stString, "YYYY-MM-DD hh:mm A", leaveDetails.timeZone).toISOString(),
					endDate : dayjs.tz(endString, "YYYY-MM-DD hh:mm A", leaveDetails.timeZone).toISOString(),
					...(leaveDetails.isRecurring) && {
						recurringEndDate : dayjs.tz(recurDtString, "YYYY-MM-DD hh:mm A", leaveDetails.timeZone).toISOString(),
					},
				},["_id"])
			)
			refetchTimeOff()
			setTrigger(false)
		}catch(err){
			console.error(err)
		}finally {
			setIsLoading(false)
		}
	}


	function handleSubmitController(){
		switch(mode){
			case "add" : handleSubmitAdd(); break;
			case "edit" : handleSubmitEdit(); break;
		}
	}

	useEffect(()=>{
		if(!trigger){
			setLeaveDetails({
				isRecurring : false,
				startDate: roundToNearestHalfHour(new Date()),
				endDate: roundToNearestHalfHour(new Date(Date.now() + (1000 * 3600))),
				isLockedFullDay : true,
				repeatFrequency : "weekly",
				requiredDays : "",
				dateRange : [],
				reason : "",
				recurringEndDate : "",
				timeZone : user?.userDefaults?.timeZone ? user?.userDefaults?.timeZone : organization?.settings?.timeZone
			})
			if(["parent","admin"].includes(user?.role))
				setSelectedUser(null);
		}
	},[trigger])
	
	return(
		<PopupContainer trigger={trigger} setTrigger={setTrigger} customCloseButton={true}>
			<div className={styles.container}>
				<div className={styles.headingContainer}>
					<div className={styles.header}>
						<span>{getHeaderText(mode)}</span>
						<Tooltip>
							<MoreInfoIcon />
						</Tooltip>
					</div>
					<button onClick={()=>setTrigger(false)}><CrossIcon /></button>
				</div>
				<div className={styles.operationArea}>
					<div className={styles.sectionOne}>
						<UserSelectDropdown {...{selectedUser, setSelectedUser, userList, mode, role : user?.role}}/>
						<div className={styles.descriptionContainer}>
							<textarea placeholder={"Enter Description"} value={leaveDetails.reason} onChange={(e)=>handleChange("reason",e.target.value)} disabled={mode === "view"}/>
							<span className={styles.wordCount}>{`${leaveDetails.reason ? MAX_WORD_COUNT_REASON - leaveDetails.reason.split(" ").length: "500"} Words Remaining`}</span>
						</div>
					</div>
					<div className={styles.verticalDivider}></div>
					<SectionTwo handleChange={handleChange} leaveDetails={leaveDetails} mode={mode} setLeaveDetails={setLeaveDetails}/>
				</div>
				<ButtonContainer 
					isSubmitDisabled={!selectedUser || (leaveDetails.isRecurring && !leaveDetails.recurringEndDate)} 
					isVisible={ mode !== "view"} 
					isLoading={isLoading} onSubmitClick={(e)=>handleSubmitController()}
					onCancelClick={(e)=>setTrigger(false)}
				/>
			</div>
		</PopupContainer>
	)
	
}

export default VacationOperationPopUp
