import React, { useEffect, useMemo, useState } from "react";
import styles from "./AttendeeSelector.module.css";
import "../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import Radio from "@mui/material/Radio";
import SelectStudent from "../AttendeeSubComponents/SelectStudent/SelectStudent";
import SelectTutor from "../AttendeeSubComponents/SelectTutor/SelectTutor";
import GroupSelect from "../AttendeeSubComponents/GroupSelect/GroupSelect";
import frozenEventTypes from "../../utils/eventTypes";

const AttendeeSelector = (props) => {
  const {user} = props
  const { selectedOption, setSelectedOption } = props;
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
	console.log("STUT",props.selectorTutor)
    if (
      (props.selectedStudents && props.selectedStudents.length > 0) ||
      (props.selectedTutor && props.selectedTutor?.trim() != "") ||
      (props.selectedGroup && props.selectedGroup.trim() != "")
    )
      props.setActive(true);
    else props.setActive(false);
  }, [props.selectedStudents, props.selectedTutor, props.selectedGroup]);

  console.log(props);


  //To Enable or disable editing based on the role
  const isEnabledEditing = useMemo(()=>{
		return ["admin","tutor"].includes(user?.role) || props?.origin && props?.origin === "schedule"
  },[user?.role])


  //To enable or disable the changing of tutor
  const isAllowedToChangeTutor = useMemo(()=>{
		return ["admin"].includes(user?.role) || props?.origin && props.origin === "schedule"   
  },[user?.role])

  return (
    <div className={styles.attendees} style={{pointerEvents : isEnabledEditing ?  ""  : "none"}}>
      <div className={styles.frame}>
        {props?.user &&
          props?.user?.role != "student" &&
          props?.user?.role != "parent" && (
            <div className={styles.userCategory}>
              <div className={styles.textWrapper}>This session is for:</div>
              <div className={styles.div}>
                <div className={styles.frame2} style={{ cursor: "pointer" }}>
                  <input
                    type="radio"
                    className={styles.radioButton}
                    checked={selectedOption === "student"}
                    value="student"
                    onChange={handleRadioChange}
                  />
                  <div
                    className={
                      selectedOption === "student"
                        ? styles.textWrapper2
                        : styles.textWrapper
                    }
                  >
                    Student
                  </div>
                </div>
                <div className={styles.frame2} style={{ cursor: "pointer" }}>
                  <input
                    type="radio"
                    className={styles.radioButton}
                    checked={selectedOption === "studentGroup"}
                    value="studentGroup"
                    onChange={handleRadioChange}
                  />
                  <div
                    className={
                      selectedOption === "studentGroup"
                        ? styles.textWrapper2
                        : styles.textWrapper
                    }
                  >
                    Student Group
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className={styles.frame3}>
          {selectedOption && selectedOption === "student" ? (
            <SelectStudent {...props} {...{isEnabledEditing}} />
          ) : (
            <GroupSelect {...props} {...{isEnabledEditing}} />
          )}
          <SelectTutor {...props} {...{isAllowedToChangeTutor}} />
        </div>
      </div>
    </div>
  );
};

export default AttendeeSelector;
