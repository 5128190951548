import { forwardRef, useEffect } from 'react'
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider'
import SearchFieldV2 from '../SearchFieldV2/SearchFieldV2'
import styles from './CustomDropdownV2.module.css'

function getDisplayValue(useCustomKey,item, customKey) {
	console.log("CK",useCustomKey,item, customKey)
    if(useCustomKey){
        return item[customKey]
    }else {
        return item
    }
}



const CustomDropdownV2 = forwardRef(({ isSearchEnabled, searchFieldProps, data, dropdownContainerProps,dropdownMenuProps, dropdownItemProps }, ref) => {

    useEffect(()=>{
        console.log(data)
    },[data])

	console.log(dropdownItemProps)

    return (
        <div className={`${styles.container} ${dropdownContainerProps?.styleClass}`} ref={ref}>
            {
                isSearchEnabled ?
                <SearchFieldV2 {...searchFieldProps}/>
                : <></>
            }
            {
                data?.map((section, sectionIdx) => (
                    <div className={`${styles.section} ${dropdownMenuProps?.styles?.sectionStyleClass}`}>
						{section.title ? <h3 className={`${styles.sectionHeading} ${dropdownMenuProps?.styles?.sectionHeadingStyleClass}`}>{section.title}</h3> : <></>}
                        <div className={`${styles.itemContainer} `}>
                            {
                                section.items.map((item, itemIdx) =>(
                                    <div className={`${styles.item} ${dropdownItemProps?.styles?.itemStyleClass} ${dropdownItemProps?.getSelectedStyle && dropdownItemProps?.getSelectedStyle(item)}`} onClick={(e)=>{dropdownItemProps?.onItemClick(e,item.clickInfo)}}>{getDisplayValue(dropdownItemProps?.useCustomKey,item,dropdownItemProps?.customKey)}</div>

                                ))
                            }
                        </div>
                        {
                            sectionIdx < section.length - 1 ?
                                <HorizontalDivider />
                                : <></>
                        }
                    </div>
                ))
            }
        </div>
    )
}) 

export default CustomDropdownV2
