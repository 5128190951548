import React, { useState } from 'react'
import CrossIcon from '../CrossIcon/CrossIcon'
import PlusIcon from '../../assets/icons/SettingsIcon/PlusIcon'
import SettingCrossIcon from '../../assets/icons/SettingsIcon/SettingCrossIcon'

export function SingleLineBox({ setCustomFields, customFields }) {
    return (
        <div className='font-normal disabled:bg-transparent text-sm w-[240px] outline-none text-secondary-80 pb-1 border-b border-secondary-40' >Single-line  answer text</div>
    )
}

export function MultiLineBox() {
    return (
        <>
            <div className='font-normal disabled:bg-transparent text-sm w-[240px] outline-none text-secondary-80 pb-1 border-b border-secondary-40' >Multi-line  answer text</div>
            <div className='font-normal disabled:bg-transparent text-sm w-[240px] outline-none text-secondary-80 pt-7 border-b border-secondary-40 ' ></div>
        </>
    )
}

export function SingleOrMultiSelectOrDropdown({ isDropdown, isThisActive, customFields, setCustomFields, idx, handleDataChange, section }) {
    const [values, setValues] = useState(["Option 1", "Option 2"])
    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
                {
                    customFields && customFields[section][idx]?.Values?.map((item, i) => {
                        return <div className='flex justify-between items-center gap-2'>
                            <div className='flex gap-[6px] items-center w-full xl:w-auto'>
                                {
                                    isDropdown ?
                                        <div className='font-normal text-sm text-secondary-80'>{i + 1 + "."}</div>
                                        :
                                        <div className='min-h-[18px] min-w-[18px] rounded-full border border-secondary-60'></div>
                                }
                                <input className='font-normal text-sm pb-1 text-secondary-80 border-b border-secondary-40 w-full focus:text-secondary outline-none focus:border-secondary' placeholder={item === "" ? `Option ${i + 1}` : ""}
                                    // value={customFields[section][idx].Values[i]}
                                    defaultValue={item}

                                    onChange={(e) => {
                                        // handleDataChange(idx, "Values", e.target.value, i)
                                        setCustomFields((prev) => {

                                            // const temp = [...prev[section]]
                                            const temp = prev[section].map((val) => ({
                                                ...val
                                            }))
                                            temp[idx].Values[i] = e.target.value
                                            console.log(temp)
                                            prev[section] = temp
                                            return prev
                                        })
                                    }}
                                    type="text" />
                            </div>
                            {

                                isThisActive &&
                                <div className={`text-secondary-80 cursor-pointer ${values.length <= 2 && "cursor-default text-secondary-60"}`}

                                    onClick={() => {
                                        setCustomFields((prev) => {
                                            const temp = [...prev[section]]
                                            if (temp[idx]["Values"].length > 2) {
                                                let arr = temp[idx]["Values"];
                                                arr = arr?.filter((item, idx2) => {
                                                    return idx2 !== i;
                                                });
                                                temp[idx]["Values"] = arr
                                            }
                                            prev[section] = temp
                                            return { ...prev }

                                        })
                                    }}
                                >
                                    <SettingCrossIcon />
                                </div>
                            }
                        </div>
                    })
                }
            </div>
            {
                isThisActive &&
                <div className='text-blue-70 font-normal text-[14px] leading-[14px] flex items-center gap-1 cursor-pointer'
                    onClick={(e) => {
                        setValues([...values, ""]);
                        setCustomFields((prev) => {
                            const temp = [...prev[section]]
                            temp[idx]["Values"] = [...temp[idx].Values, ""]
                            console.log(temp[idx])
                            prev[section] = temp
                            return { ...prev }
                        })
                    }}
                > <PlusIcon /> Add Option</div>
            }
        </div>
    )
}

export function DateInputSelect() {
    return (
        <div>
            <input className='border-b border-secondary-40 pb-1' type="date" />
        </div>
    )
}
