import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, getAuthHeader } from "../constants/constants";

export const orgSignupApi = createApi({
    reducerPath: "orgSignupApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        mode: "cors",
        // credentials: "include",
    }),

    endpoints: (builder) => ({
        getSubscriptionsInfo: builder.query({
            query: () => ({
                url: `/api/stripe/prices`,
                method: "GET",
                headers: getAuthHeader(),
            })
        }),
        startEmailVerification : builder.mutation({
            query : (reqBody) => ({
                url : '/api/user/email-verification/v2',
                method : "POST",
                body : reqBody
            })
        }),
        completeEmailVerification : builder.mutation({
            query : (reqBody) => ({
                url : '/api/user/email-verification/v2',
                method : "PATCH",
                body : reqBody
            })
        }),
        createNewOrg : builder.mutation(({
            query : (reqBody) => ({
                url : '/api/orgs',
                method : "POST",
                headers : {
                    "Authorization" : window.localStorage.getItem("set_password_token")
                }
            })
        }))
    })
})

export const {
    useGetSubscriptionsInfoQuery,
    useLazyGetSubscriptionsInfoQuery,
    useStartEmailVerificationMutation,
    useCompleteEmailVerificationMutation,
    useCreateNewOrgMutation
} = orgSignupApi;