import { useSelector } from 'react-redux'
import InfoIcon from '../../../components/ProductIcons/InfoIcon'
import TabSelector from '../../../components/TabSelector/TabSelector'
import styles from './AssignmentsSettings.module.css'
import ToggleButtonV2 from '../../../components/ToggleButtonV2/ToggleButtonV2'
import DropdownV2 from '../../../components/DropdownV2/DropdownV2'
import HorizontalDivider from '../../../components/HorizontalDivider/HorizontalDivider'
import RadioButtonV2 from '../../../components/RadioButtonV2/RadioButtonV2'
import { useUpdateOrgDataMutation } from '../../../app/services/admin'
import { triggerToast } from '../../../utils/toastController'
import { fetchAndSetOrgDetails } from '../../../utils/orgService'
import { useState } from 'react'
import LoaderIcon from '../../../components/LoaderIcon/LoaderIcon'
import { toast } from 'react-toastify'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { getTooltipStyleClass } from '../../../utils/commonlyUsedTooltipStyles'
import TooltipIcon from '../../../assets/icons/SettingsIcon/TooltipIcon'

const tabItems = ["Permissions"]
const requiredPermissions = [
    "tutDelTest",
    "notifyStuAssignTest",
    "notifyParentAssignTest",
    "notifyStudentBeforeDueDate",
    "notifyAdminCompleteTest",
    "notifyTutorCompleteTest",
    "stuViewCorrectAns",
    "parentViewCorrectAns",
    "parentViewPartialScoreReport"
]
const permissionActionMapping = {
    "tutDelTest": { description: "If enabled, tutors would be able to delete sent assignments. Note that this will NOT refund the assignment credit." },
    "notifyStudentBeforeDueDate": { description: "Select if you would like to send reminder emails to students for assignments that are due soon." },
    "notifyTutorCompleteTest": { description: "When enabled, tutors will receive an email notifying them about new students mapped to their profile." },
    "stuViewCorrectAns": { description: "Enabling this option would allow students to view correct answers within score reports for completed assignments." },
    "parentViewCorrectAns": { description: "Enabling this option would allow parents to view correct answers within score reports for completed assignments." },
    "parentViewPartialScoreReport": { description: "Enabling this would allow parents to view score reports for each section while the student is attempting the test." },
}
const inputTypeMappping = {
    "tutDelTest": "toggle",
    "notifyStuAssignTest": "toggle",
    "notifyParentAssignTest": "toggle",
    "notifyStudentBeforeDueDate": "dropdown",
    "notifyAdminCompleteTest": "toggle+radio",
    "notifyTutorCompleteTest": "toggle",
    "stuViewCorrectAns": "toggle",
    "parentViewCorrectAns": "toggle",
    "parentViewPartialScoreReport": "toggle"
}



const AssignmentsSettings = () => {

    const { permissions } = useSelector((state) => state.organization.organization.settings)
    const [updateOrgRequest, ] = useUpdateOrgDataMutation()
    const {organization} = useSelector((state)=>state.organization)
    const [isLoading, setIsLoading] = useState(false)
    

    
    const handlePermissionChange = (permissionActionName,targetValue,idx,requiredFieldName = null) => {
        const requiredField = !requiredFieldName ? `settings.permissions.${idx}.choosedValue` : `settings.permissions.${idx}.${requiredFieldName}` 
        const fields = {}
        fields[requiredField] = targetValue
       

       const id = toast.loading(`Updating permissions`)
       if(permissionActionName === "notifyAdminCompleteTest"){
           setIsLoading(true) 
       }
       updateOrgRequest({
        orgId : organization._id,
        op : "update",
        fields : fields 
       }).then((res)=>{
            if(!res.error){
                fetchAndSetOrgDetails(organization._id)
                    .then((res)=>{
                        toast.update(id,{isLoading : false, render : "Permission updated sucessfully.",type : "success", autoClose : 3000})
                        if(permissionActionName === "notifyAdminCompleteTest"){
                           setIsLoading(false)
                        }
                    }).catch((err)=>{
                        toast.update(id, {isLoading : false, render : "Permission updated successfully. Data fetching failed", type : "error", autoClose : 3000})
                        if(permissionActionName === "notifyAdminCompleteTest"){
                           setIsLoading(false)
                        }
                    })
            }else {
                console.log(res.error)
                toast.update(id, {isLoading : false, render : "Permission udpate failed.", type : "error", autoClose : 3000 })
                if(permissionActionName === "notifyAdminCompleteTest"){
                    setIsLoading(false)
                }

            }

       })
    }

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <h3>Assignments</h3>
                <Tooltip tooltipStyles={getTooltipStyleClass({width : "220px"})} tooltipContent={"Control all your assignments and content defaults here"}>
                    <TooltipIcon />
                </Tooltip>
                
            </div>
            <TabSelector items={tabItems} selectedIdx={0} onClick={(e) => { console.log("Clicked") }} />
            <div className={styles.heading}>
                <h2>Set default states and values for these options: </h2>
            </div>
            <div className={styles.editor}>

                {
                    requiredPermissions.map((permissionActionName, idx) => {
                        const permission = permissions?.find((val) => val.permissionActionName === permissionActionName)
                        const requiredIdx = permissions?.findIndex((val)=>val.permissionActionName === permissionActionName)
                        if (permission) {
                            return (
                                <>
                                    <div className={styles.item}>
                                        <h5>{permission.name}</h5>
                                        {
                                            permissionActionMapping[permissionActionName]?.description ?
                                                <p>{permissionActionMapping[permissionActionName]?.description}</p>
                                            : <></>
                                        }
                                        {
                                            /**Extract to a function later */
                                            inputTypeMappping[permissionActionName].includes("radio") ? 
                                                <div className={styles.radioInput}>

                                                    {
                                                        permission.entities.map((val,idx)=>(
                                                            isLoading ? <LoaderIcon /> : 
                                                            <RadioButtonV2 labelText={ val === "all_students" ? "For all students" : "Only for mapped students" } selected={val === permission.selectedEntity} customSelectedMappingType={styles.customSelectedRadioButton} customUnselectedMappingType={styles.customUnselectedRadioButton}
                                                                            onClick={()=>handlePermissionChange(permissionActionName,val,requiredIdx,"selectedEntity")}
                                                            />

                                                        ))
                                                    }

                                                </div>
                                                
                                            : <></>
                                        }
                                    </div>
                                    <div className={`${inputTypeMappping[permissionActionName].includes("toggle") ? styles.toggle : styles.dropdown } ${inputTypeMappping[permissionActionName].includes("radio") ? styles.toggle1 : ""  }`}>
                                        {   /**Extract to a function later */
                                            inputTypeMappping[permissionActionName] === "toggle" || inputTypeMappping[permissionActionName].includes("toggle") ?
                                                <ToggleButtonV2 isEnabled={permission.choosedValue} setIsEnabled={(targetValue)=>handlePermissionChange(permissionActionName,targetValue,requiredIdx)}/>
                                                :
                                                inputTypeMappping[permissionActionName] === "dropdown" || inputTypeMappping[permissionActionName].includes("dropdown")?
                                                    <DropdownV2 defaultValue={permission.choosedValue} dropdownMenuItems={permission.values.map((val)=>({displayTitle : val}))} onDropdownItemClick={(item)=>handlePermissionChange(permissionActionName,item.displayTitle,requiredIdx)} dataClassName={styles.customDropdownDataStyle}/>
                                                    :
                                                    <></>
                                        }
                                    </div>
                                    <div className={styles.horizontalDivider}>
                                        <HorizontalDivider />
                                    </div>
                                </>
                            )
                        }
                    })
                }
            </div>

        </div>
    )
}

export default AssignmentsSettings