export default function TimeIcon (props) {
	
	const {activeIcon} = props

	if(activeIcon) {
		return(
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
				<rect width="20" height="20" rx="10" fill="#0671E0" fill-opacity="0.1"/>
				<g clip-path="url(#clip0_35359_39753)">
					<path d="M10 4.5C8.91221 4.5 7.84884 4.82257 6.94437 5.42692C6.0399 6.03126 5.33495 6.89025 4.91867 7.89524C4.50238 8.90023 4.39346 10.0061 4.60568 11.073C4.8179 12.1399 5.34173 13.1199 6.11092 13.8891C6.8801 14.6583 7.86011 15.1821 8.92701 15.3943C9.9939 15.6065 11.0998 15.4976 12.1048 15.0813C13.1098 14.6651 13.9687 13.9601 14.5731 13.0556C15.1774 12.1512 15.5 11.0878 15.5 10C15.4983 8.54184 14.9183 7.14389 13.8872 6.11281C12.8561 5.08174 11.4582 4.50172 10 4.5ZM10 14.5C9.10999 14.5 8.23996 14.2361 7.49994 13.7416C6.75992 13.2471 6.18314 12.5443 5.84254 11.7221C5.50195 10.8998 5.41284 9.99501 5.58647 9.12209C5.7601 8.24918 6.18869 7.44736 6.81802 6.81802C7.44736 6.18868 8.24918 5.7601 9.1221 5.58647C9.99501 5.41283 10.8998 5.50195 11.7221 5.84254C12.5443 6.18314 13.2471 6.75991 13.7416 7.49993C14.2361 8.23996 14.5 9.10998 14.5 10C14.4985 11.193 14.024 12.3368 13.1804 13.1804C12.3368 14.024 11.193 14.4985 10 14.5Z" fill="#0671E0"/>
					<path d="M10.5 9.793V7C10.5 6.86739 10.4473 6.74021 10.3536 6.64645C10.2598 6.55268 10.1326 6.5 10 6.5C9.86739 6.5 9.74021 6.55268 9.64645 6.64645C9.55268 6.74021 9.5 6.86739 9.5 7V10C9.50003 10.1326 9.55273 10.2598 9.6465 10.3535L11.1465 11.8535C11.2408 11.9446 11.3671 11.995 11.4982 11.9938C11.6293 11.9927 11.7547 11.9401 11.8474 11.8474C11.9401 11.7547 11.9927 11.6293 11.9938 11.4982C11.995 11.3671 11.9446 11.2408 11.8535 11.1465L10.5 9.793Z" fill="#0671E0"/>
				</g>
				<defs>
					<clipPath id="clip0_35359_39753">
						<rect width="12" height="12" fill="white" transform="translate(4 4)"/>
					</clipPath>
				</defs>
			</svg>

		)
	} else {
		return(
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
				<rect width="20" height="20" rx="10" fill="#F7F8F9"/>
				<g clip-path="url(#clip0_35359_19441)">
					<path d="M10 4.5C8.91221 4.5 7.84884 4.82257 6.94437 5.42692C6.0399 6.03126 5.33495 6.89025 4.91867 7.89524C4.50238 8.90023 4.39346 10.0061 4.60568 11.073C4.8179 12.1399 5.34173 13.1199 6.11092 13.8891C6.8801 14.6583 7.86011 15.1821 8.92701 15.3943C9.9939 15.6065 11.0998 15.4976 12.1048 15.0813C13.1098 14.6651 13.9687 13.9601 14.5731 13.0556C15.1774 12.1512 15.5 11.0878 15.5 10C15.4983 8.54184 14.9183 7.14389 13.8872 6.11281C12.8561 5.08174 11.4582 4.50172 10 4.5ZM10 14.5C9.10999 14.5 8.23996 14.2361 7.49994 13.7416C6.75992 13.2471 6.18314 12.5443 5.84254 11.7221C5.50195 10.8998 5.41284 9.99501 5.58647 9.12209C5.7601 8.24918 6.18869 7.44736 6.81802 6.81802C7.44736 6.18868 8.24918 5.7601 9.1221 5.58647C9.99501 5.41283 10.8998 5.50195 11.7221 5.84254C12.5443 6.18314 13.2471 6.75991 13.7416 7.49993C14.2361 8.23996 14.5 9.10998 14.5 10C14.4985 11.193 14.024 12.3368 13.1804 13.1804C12.3368 14.024 11.193 14.4985 10 14.5Z" fill="#515C7B"/>
					<path d="M10.5 9.793V7C10.5 6.86739 10.4473 6.74021 10.3536 6.64645C10.2598 6.55268 10.1326 6.5 10 6.5C9.86739 6.5 9.74021 6.55268 9.64645 6.64645C9.55268 6.74021 9.5 6.86739 9.5 7V10C9.50003 10.1326 9.55273 10.2598 9.6465 10.3535L11.1465 11.8535C11.2408 11.9446 11.3671 11.995 11.4982 11.9938C11.6293 11.9927 11.7547 11.9401 11.8474 11.8474C11.9401 11.7547 11.9927 11.6293 11.9938 11.4982C11.995 11.3671 11.9446 11.2408 11.8535 11.1465L10.5 9.793Z" fill="#515C7B"/>
				</g>
				<defs>
					<clipPath id="clip0_35359_19441">
						<rect width="12" height="12" fill="white" transform="translate(4 4)"/>
					</clipPath>
				</defs>
			</svg>

		)
	}
}
