import { MoreHoriz, MoreVert } from '@mui/icons-material'
import React, { useEffect, useRef, useState } from 'react'
import ToggleButtonV2 from '../ToggleButtonV2/ToggleButtonV2'
import InputTypeController from './InputTypeController';

const SectionOne = ({ setCustomFields, customFields, packageName, totalQuestion, activeIndex, setActiveIndex }) => {

    useEffect(() => {
        console.log(customFields && customFields)
    }, [])
    return (
        <div className=''>
            <div className='p-4 flex flex-col gap-5'>
                <div className='flex justify-between items-center'>
                    <div className='font-medium text-base text-secondary w-[70%] border-b border-secondary-80'>Profile <span className='font-normal text-secondary-80'>(Mandatory Fields)</span></div>
                </div>
                {
                    customFields && customFields?.sectionOne?.map((item, i) => {
                        console.log(item, i)
                        return <InputTypeController isThisActive={i > 3 && activeIndex == i} setActiveIndex={setActiveIndex} customFields={customFields} setCustomFields={setCustomFields} idx={i} section={"sectionOne"} />
                    })
                }

            </div>
        </div>
    )
}

export default SectionOne