import styles from "./CalendarPermissions.module.css";
import PermissionsEditor from "./Components/PermissionsEditor/PermissionsEditor";

const CalendarPermissions = ({ props }) => {
    return (
      <div className={styles.container}>
          <h4>Set default states and values for these options:</h4>
          <PermissionsEditor />
      </div>
    )
};

export default CalendarPermissions;
