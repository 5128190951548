import React, { useState } from "react";
import { useSelector } from "react-redux";
import ButtonV2 from "../../ButtonV2/ButtonV2";
import styles from "./AddNewCardPopUp.module.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardDetailsInput from "./CardDetailsInput";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";

const AddNewCardPopUp = (props) => {
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  );
  const options = {
    mode: "setup",
    currency: "usd",
  };
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripeCustomerId = useSelector(
    (state) => state?.subscription?.stripeCustomerId
  );

  console.log(stripeCustomerId);
  return (
    <div className={styles.container}>
      <div className={styles.title}>Enter Card Details</div>
      <Elements stripe={stripePromise} options={options}>
        <CardDetailsInput
          addPaymentMethod={addPaymentMethod}
          stripeCustomerId={stripeCustomerId}
          setLoading={setLoading}
          setPopUpTriger={props.setPopUpTriger}
          setUpdateSubContinueClicked={props.setUpdateSubContinueClicked}
        />
      </Elements>
      <ButtonV2
        className={``}
        onClick={() => {
          setAddPaymentMethod(true);
        }}
      >
        {loading ? <div className='w-full flex items-center justify-center'><LoaderIcon /></div> : <>Save and Continue</> }
        
      </ButtonV2>
    </div>
  );
};

export default AddNewCardPopUp;
