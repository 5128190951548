export default function GreenTickIconStatus(props) {
	
	return(
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0ZM9.4745 4.4667L6.0095 7.9317L4.5255 6.447C4.46051 6.38196 4.38334 6.33036 4.29841 6.29515C4.21348 6.25993 4.12244 6.24179 4.0305 6.24176C3.84481 6.24169 3.6667 6.31539 3.53535 6.44665C3.404 6.57791 3.33017 6.75596 3.33011 6.94165C3.33004 7.12734 3.40374 7.30545 3.535 7.4368L5.4656 9.3674C5.53711 9.43894 5.62201 9.49569 5.71545 9.53441C5.80889 9.57313 5.90905 9.59305 6.0102 9.59305C6.11135 9.59305 6.21151 9.57313 6.30495 9.53441C6.39839 9.49569 6.48329 9.43894 6.5548 9.3674L10.465 5.4572C10.5963 5.32585 10.6701 5.1477 10.6701 4.96195C10.6701 4.7762 10.5963 4.59805 10.465 4.4667C10.3337 4.33535 10.1555 4.26156 9.96975 4.26156C9.784 4.26156 9.60585 4.33535 9.4745 4.4667Z" fill="#2BA773"/>
		</svg>

	)

}
