import React, { useEffect, useState } from 'react'
import styles from './style.module.css'
import { useEffectPreventMountExec } from '../../hooks/useEffectPreventMountExec'
import emptyCheckbox from "../../assets/checkbox/emptyCheckboxGrey.svg"
import Checkbox from "../../assets/checkbox/smallTickCheckbox.svg"
import Checkbox1 from "../../assets/checkbox/checkbox.svg"

import "../../pages/AssignedTests/assignTest.css"
export default function SCheckbox({ assignTestCheckBox, stopM, checked, onChange, name, disabled, value, checkMarkClassName, className, checkClass }) {
   const [checkG, setCheckG] = useState(checked)
   const handleClick = (e) => {
      if (disabled === true || !onChange) return
      onChange(!e)
   }

 /*
   useEffectPreventMountExec(() => {
      if (disabled === true || !onChange) return
      onChange(checkG)
   }, [checkG])
   */

   return (
      <div className={`w-[22px] h-[22px] flex justify-start  ${styles.container} ${!checked ? styles.container2 : styles.container}  ${className}  ${checkClass}`}
         onClick={(e) => {
            e.stopPropagation()
            if (!true)
               setCheckG(!checkG)
            else {
               handleClick(checked)
            }

         }}
      >
         <input checked={true} className='text-[14px] accent-blue-70' type='checkbox' name={name ? name : 'moods'} value='' readOnly />
         <img src={checked ? Checkbox : emptyCheckbox} alt="checkbox" className={`w-[17.5px] h-[17.5px] !bg-[none] ${styles.checkmark} ${checkMarkClassName} mt-[2px] text-[14px] ${assignTestCheckBox ? "assigntableCheckbox" : ""}`} />

         {/* <span className={`w-[17.5px] h-[17.5px] ${styles.checkmark} ${checkMarkClassName} mt-[2px] ${assignTestCheckBox?"assigntableCheckbox":""}`}></span> */}
      </div>
   )
}
