import React from "react";
import AvailableSelectorInput from "./AvailableSelectorInput";
import UnavailableDisaply from "./UnavailableDisaply";
import "../schedule.css";
import noUsers from "../../../assets/calendar/noUsers.svg"

const Selector = ({
  id,
  Data,
  submitData,
  setSubmitData,
  customPopupMessage,
}) => {
  return (
    <main className="  w-full border-[1px] border-[#E9EBEF] rounded-[6px] flex flex-col  flex-1 h-[334px] md:h-[320px] overflow-auto px-[8px] py-[12px] gap-[6px] scroll_cal_effect ">
      {Data["available"].length === 0 && Data["unavailable"].length === 0  ? (
        <>
         <h2 className="text-[12px] leading-[18px] font-medium text-[#25335A] pb-[6px] border-b-[1px] border-secondary-10 ">
            {id === "members"
              ? "Team Members "
              : "Students"}
          </h2>

          <div className=" flex-1   flex items-center justify-center">

            <img src={noUsers} alt=""  className="w-full" />

          </div>

        </>
      ) : (
        <>
          <h2 className="hidden md:flex text-[12px] leading-[18px] font-medium text-[#25335A] pb-[6px] border-b-[1px] border-secondary-10 ">
            {id === "members"
              ? " Available Team Members "
              : " Available Students "}
            <span>&nbsp;({Data["available"].length})</span>
          </h2>
          <h2 className=" md:hidden text-[12px] leading-[18px] font-medium text-[#25335A] pb-[6px] border-b-[1px] border-secondary-10 ">
            {id === "members" ? "Team Members " : "Students "}
            <span>&nbsp;({Data["available"].length})</span>
          </h2>

          {Data["available"].map((user) => (
            <AvailableSelectorInput
              key={user._id}
              userData={user}
              type={id}
              submitData={submitData}
              setSubmitData={setSubmitData}
              customPopupMessage={customPopupMessage}
            />
          ))}

          <h2 className=" text-[12px] leading-[18px] font-medium text-[#25335A] pb-[6px] border-b-[1px] border-secondary-10 ">
            Unavailable
          </h2>

          {Data["unavailable"].map((user) => (
            <UnavailableDisaply key={user._id} userData={user} />
          ))}
        </>
      )}
    </main>
  );
};

export default Selector;
