import React from "react";

import googlemeet from "../../../assets/calendar/google-meet.svg";
import outlook from "../../../assets/calendar/outlook.svg";
import zoom from "../../../assets/calendar/zoom.svg";
import IntegrationApps from "./Components/IntegrationApp/IntegrationApps";

const Integrations = () => {
  const IntegrationsApps = [
    {
      Key: 0,
      Name: "Google Calendar & Meet",
      Description:
        "View upcoming meetings and add events directly to your Calendar, including Google Meet details.",
      Image: googlemeet,
      toolTipContent: "Launching in next update",
    },
    {
      Key: 1,
      Name: "Outlook Calendar",
      Description:
        "View your upcoming meetings and add events to your Calendar directly.",
      Image: outlook,
      toolTipContent: "Launching in next update",
    },
    {
      Key: 2,
      Name: "Zoom",
      Description:
        "Automatically add Zoom details while scheduling sessions on Evallo.",
      Image: zoom,
      toolTipContent: "Launching next calendar update!",
    },
  ];

  return (
    <main className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[24px]">
      {IntegrationsApps.map((apps) => (
        <IntegrationApps
          key={apps.Key}
          id={apps.Key}
          name={apps.Name}
          description={apps.Description}
          image={apps.Image}
          toolTipContent={apps.toolTipContent}
        />
      ))}
    </main>
  );
};

export default Integrations;
