import React, { useState } from "react";
import DeleteIcon from "./DeleteIcon";
import TickMarkIcon from "../TickMarkIcon/TickMarkIcon";
import LoaderIcon from "../LoaderIcon/LoaderIcon";

const ApplyCoupon = ({
  coupon,
  setCoupon,
  valid,
  applyCoupon,
  discountAmount,
  subscriptionCoupon,
  removeCoupon,
  isLoading
}) => {
  function addVal(e) {
    setCouponn(e.target.value);
    console.log("akjsdb");
  }

  return (
    <div className="w-full">
      <div className="w-full m-auto flex flex-col gap-3">
        <h5 className="text-left text-[18px] leading-7 font-semibold">
          Promotion/Discount
        </h5>
        <div>
          <div className="flex items-center gap-3 w-full h-[48px]">
            <div
              htmlFor="cInput"
              className="h-full w-full border rounded-5 border-[#BEC2CE] flex items-center"
            >
              <input
                onChange={(e) => setCoupon(e.target.value)}
                className={`w-full h-full rounded-5 outline-none px-4`}
                type="text"
                name="coupon"
                id="coupon"
                placeholder="Discount or Coupon Code"
                value={coupon}
              />

              <div
                className={`${valid ? "block " : "hidden"} text-green-600 px-2`}
              >
                <TickMarkIcon />
              </div>
            </div>
            <button
              onClick={() => {
                if (valid) {
                  setCoupon("");
                  removeCoupon();
                } else {
                  if (coupon) {
                    applyCoupon();
                  }
                }
              }}
              disabled={!coupon}
              className={`h-full flex justify-center items-center text-base font-normal leading-5 ${
                !coupon ? "bg-[#9a9fa5]" : "bg-[#26435F]"
              } p-[10px] rounded-5 text-white`}
            >
              {isLoading ? <LoaderIcon /> : valid ? <DeleteIcon /> : "Apply"}
            </button>
          </div>
          {valid && subscriptionCoupon ? (
            <p className="text-left my-[6px] text-[#099A66]">
              {`Savings of $${
                discountAmount ? discountAmount.toFixed(2) : discountAmount
              } ${
                subscriptionCoupon.percentOff
                  ? "(" + subscriptionCoupon.percentOff + "%)"
                  : ""
              } applied.`}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplyCoupon;
