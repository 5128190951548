export const getModifiedProducts = (
  currentSubscription,
  scheduledDowngrade
) => {
  const changedItems = [];

  for (let i = 0; i < currentSubscription.items.length; i++) {
    const currentItem = currentSubscription.items[i];
    const downgradedItem = scheduledDowngrade.items[i];

    if (currentItem.price !== downgradedItem.price) {
      changedItems.push({
        current: currentItem,
        changed: downgradedItem,
      });
    }
  }

  return changedItems;
};

export const addMatchingPrices = (modProd, products) => {
  for (const product of products) {
    for (const category in product.prices) {
      if (Array.isArray(product.prices[category])) {
        for (const price of product.prices[category]) {
          if (price.id === modProd.price) {
            return price;
          }
        }
      }
    }
  }
  return null;
};
