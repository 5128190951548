function extractTier(lookupKey) {
  console.log(lookupKey);
  if (lookupKey.includes("PAYG")) return;
  const matchedGroups = lookupKey.match(/(Tier_[0-9])/);
  console.log(matchedGroups[0]);
  return matchedGroups[0];
}

function getCorrespondingPriceId(
  oldBillingCycle,
  selectedBillingCycle,
  productPrices,
  productName,
  id
) {
  const previousPrices = productPrices.find((val) =>
    val.product.name.includes(productName)
  ).prices[oldBillingCycle];
  const newPrices = productPrices.find((val) =>
    val.product.name.includes(productName)
  ).prices[selectedBillingCycle];

  const oldPrice = previousPrices.find((val) => val.id === id);
  console.log(">>>OLKP", oldPrice);
  const requiredTier = extractTier(oldPrice.lookup_key);

  const newPrice = newPrices.find((val) =>
    val.lookup_key.includes(requiredTier)
  );

  return newPrice.id;
}

export default getCorrespondingPriceId;
