import { useEffect, useState } from 'react'
import CrossIcon from '../../CrossIcon/CrossIcon'
import PopupContainer from '../../PopupContainer/PopupContainer'
import styles from './SubDomainOperationPopUp.module.css'
import { useSelector } from 'react-redux'
import { checkUniqueSubdomain } from '../../../utils/orgService'
import LoaderIcon from '../../LoaderIcon/LoaderIcon'

function isValidSubDomain(targetSubDomain){
    const invalidSubDomainRegex = /[^a-z0-9_\-]/
    if(invalidSubDomainRegex.test(targetSubDomain)){
        return false
    }
    return true
}

const SubDomainOperationPopUp = ({ trigger, setTrigger, subDomain, handleSaveClick, registeredSubDomain }) => {

    const [showMessage, setShowMessage] = useState(true)
    const [inputText, setInputText] = useState(subDomain)
    const [message, setMessage] = useState("")
    const [isUnique, setIsUnique] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    async function checkForUniqueSubdomain(subDomain,setShowMessage,setMessage,setIsUnique){
        try {
            const isUnique = await checkUniqueSubdomain(subDomain) 
            if(isUnique){
                setShowMessage(true)
                setMessage(`${subDomain}.evallo.org is available`)
            }else {
                setShowMessage(true)
                setMessage(`${subDomain}.evallo.org is not available. Please enter another name.`)
            }
            setIsUnique(isUnique)
        }catch(err){
            console.error(err)
        }
    }

    function debounce (func, time){
        let timer = null

        if(timer){
            clearTimeout(timer)
        }
        return (args) => {
            timer = setTimeout(()=>{
                func(...args)
            },time)
        }
    }

    const debouncedUniqueCheck = debounce(checkForUniqueSubdomain, 500) 
    
    useEffect(()=>{
        if(subDomain){
            debouncedUniqueCheck([subDomain, setShowMessage, setMessage, setIsUnique])
        }
    },[subDomain])

    const handleTextChange = (e) => {
        if (isValidSubDomain(e.target.value)) {
            setInputText(e.target.value)
            if (e.target.value.trim() === "") {
                setMessage("Sub domain name cannot be empty.")
                return;
            }
            debouncedUniqueCheck([e.target.value, setShowMessage, setMessage, setIsUnique])
        }
    }



    return (
        <PopupContainer trigger={trigger} setTrigger={setTrigger} customCloseButton={true}>
            <div className={styles.container}>
                <div className={styles.headingContainer}>
                    <div>
                        Connect sub-domain
                    </div>
                    <button onClick={(e) => setTrigger(false)}><CrossIcon /></button>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor='sub_domain_name'>Website URL</label>
                    <div className={`${styles.insertInput} `}>
                        <input type="text" id="sub_domain_name" className={`${ !isUnique ? styles.errorBorder : ""}`} placeholder='Enter the required sub-domain'  onChange={(e) => handleTextChange(e)} onKeyDown={(e) => handleKeyDown(e)} value={inputText} />
                        <div className={styles.domainIndicator}>.evallo.org</div>
                    </div>
                    {
                        showMessage ? <div className={`${styles.helperMessage} ${ !isUnique ? styles.errorFont : ""}`}>{message}</div> : <></>
                    }
                </div>
                <p className={styles.note}>
                    <span>Note:</span>&nbsp;You can add only one sub-domain. Once locked in, you won’t be able to change it for 30 days.
                </p>
                <div className={styles.actionArea}>
                    <button className={styles.cancelButton} onClick={() => setTrigger(false)}>Cancel</button>
                    <button className={styles.saveButton} onClick={()=>handleSaveClick(inputText, setIsLoading)} disabled={!isUnique || (registeredSubDomain && (subDomain?.toLowerCase() === inputText.toLowerCase() )) }>
                        {
                            isLoading ? <LoaderIcon /> : "Save" 
                        }
                    </button>
                </div>
            </div>
        </PopupContainer>
    )
}


export default SubDomainOperationPopUp