import React from "react";

const getInitials = (name) => {
  if (!name) return ""; // Return an empty string if name is undefined or null

  const nameParts = name.split(" ");
  let initials = "";

  if (nameParts.length > 1) {
    initials = nameParts[0][0] + nameParts[1][0];
  } else if (nameParts.length === 1) {
    initials = nameParts[0][0];
  }

  return initials.toUpperCase();
};

const UserAvatar = ({ profilePicture, name = "" }) => {
  return (
    <div className="flex items-center gap-[8px] bg-[#EEF6FF] rounded-[24px] py-[6px] pl-[8px] pr-[16px]">
      <div>
        {profilePicture ? (
          <img
            src={profilePicture}
            alt="Profile"
            className="rounded-full h-[24px] w-[24px] border-[1px] border-blue-70"
          />
        ) : (
          <div className="flex items-center justify-center bg-blue-30 h-[24px] w-[24px] border-[1px] border-blue-70 rounded-full text-[12px] font-[400] leading-[12px]">
            {getInitials(name)}
          </div>
        )}
      </div>
      <div className="text-[16px] font-[400] leading-[20px] text-secondary-80">
        {name}
      </div>
    </div>
  );
};

export default UserAvatar;
