/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import eyeIcon from './EyeIcon.svg'
import crossedEyeIcon from './CrossedEyeIcon.svg'
import styles from './InputPasswordV2.module.css'


const InputPasswordV2 = ({ onChange, placeholderText, id, noMatch, css }) => {

    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className={`${styles.container} ${noMatch ? styles.invalid : ""}`}>
            <input type={showPassword ? "text" : "password"} onChange={(e) => onChange ? onChange(e) : {}} placeholder={placeholderText} id={id} css={css}/>
            <button type="button" onClick={(e) => setShowPassword(!showPassword)} id="show_password_button">
               {
               showPassword ? <img src={crossedEyeIcon} className='pointer-events-none'/> : <img src={eyeIcon} className='pointer-events-none' />
               }
            </button>
        </div>
    )
}

export default InputPasswordV2
