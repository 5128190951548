import React, { useState, useContext, useEffect } from "react";
import ReviewProducts from "./ReviewProducts";
import AddCardDetails from "./AddCardDetails";
import FormStepper from "../../FormStepper/FormStepper";
import line from "./assets/line.svg";
import explorerIcon from "./assets/ExplorerIcon.svg";
import starterIcon from "./assets/StarterIcon.svg";
import professionalIcon from "./assets/ProfessionalIcon.svg";
import premiumIcon from "./assets/PremiumIcon.svg";
import arrowLeft from "./assets/arrowLeft.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrgSignupContext from "../../../context/OrgSignupContext";
import { useNavigate } from "react-router-dom";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";

const ReviewAndPay = () => {
  const {
    noOfTutors,
    productPrices,
    selectedProducts,
    setSelectedProducts,
    stripeCustId,
    paygEnabled,
    setHasRecommended,
    subscriptionCoupon,
    setSubscriptionCoupon,
  } = useContext(OrgSignupContext);
  let storedSelection = localStorage.getItem("Plan_Selection");
  let planSelected = JSON.parse(storedSelection);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [basePackagePrices, setBasePackagePrices] = useState();
  const [selectedPlanType, setSelectedPlanType] = useState(
    selectedProducts
      ? selectedProducts.selectedPlanType === "monthly"
        ? 0
        : 1
      : planSelected
      ? planSelected.selectedPlanType === "monthly"
        ? 0
        : 1
      : null
  );
  const [totalPriceAddOns, setTotalPriceAddOns] = useState(
    parseInt(localStorage.getItem("totalPriceAddOns"), 10) || 0
  );
  const [basePackagePrice, setTotalBasePackagePrice] = useState(
    selectedProducts
      ? selectedProducts.selectedProducts?.basePackage.price
      : planSelected
      ? planSelected.selectedProducts?.basePackage.price
      : null
  );
  const [basePackageIcon, setBasePackageIcon] = useState(starterIcon);
  const [basePackageTutorCount, setBasePackageTutorCount] = useState(1);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const navigate = useNavigate();
  const [discountAmount, setDiscountAmount] = useState(0);
  const basePackages = [
    {
      summaryImg: explorerIcon,
      basePackageName: "Explorer",
      tutorCount: 1,
    },
    {
      summaryImg: starterIcon,
      basePackageName: "Starter",
      tutorCount: 1,
    },
    {
      summaryImg: professionalIcon,
      basePackageName: "Professional",
      tutorCount: 10,
    },
    {
      summaryImg: premiumIcon,
      basePackageName: "Premium",
      tutorCount: 30,
    },
  ];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setHasRecommended(true);
  }, []);

  useEffect(() => {
    let sum = 0;
    if (selectedProducts) {
      // Iterate over selectedProducts and sum up their prices
      for (const productName in selectedProducts.selectedProducts) {
        const product = selectedProducts.selectedProducts[productName];
        if (product.selected) {
          const price = product.price;
          // productName === "tutors"
          //   ? product.price * noOfTutors:

          sum += price;
        }
      }
      setTotalPriceAddOns(sum);
      localStorage.setItem("totalPriceAddOns", sum.toString());
      setTotalBasePackagePrice(
        selectedProducts.selectedProducts.basePackage.price
      );

      setBasePackageIcon(
        basePackages.find(
          (pkg) =>
            pkg.basePackageName ===
            selectedProducts.selectedProducts.basePackage.name
        )?.summaryImg
      );
      setBasePackageTutorCount(
        basePackages.find(
          (pkg) =>
            pkg.basePackageName ===
            selectedProducts.selectedProducts.basePackage.name
        )?.tutorCount
      );
    }
  }, [selectedProducts]);

  const options = {
    mode: "setup",
    currency: "usd",
  };

  useEffect(() => {
    if (!selectedProducts) return;
    localStorage.setItem("Plan_Selection", JSON.stringify(selectedProducts));
  }, [selectedProducts]);

  return (
    <div className=" flex flex-col">
      <FormStepper stage={4} />
      <div className="min-h-[109px] grid gap-[24px]  justify-center">
        <div className="text-[#26435F] text-[48px]  font-semibold flex justify-center my-4 ">
          Review and Pay
        </div>
        <div className=" text-[#7C859C] text-[16px] font-medium flex justify-center items-center ">
          <button
            className="flex justify-center items-center "
            onClick={() => {
              navigate("/signup/select-plan");
            }}
          >
            <span>
              <img src={arrowLeft} className="h-[16px] w-[16px]" />
            </span>
            <span className="border-b-2 border-[#7C859C] w-fit">
              Back to Plans
            </span>
          </button>
        </div>
      </div>
      <div className="flex justify-around  gap-24 w-[1032px] mt-6 ">
        <div>
          <Elements stripe={stripePromise} options={options}>
            <AddCardDetails
              submitted={formSubmitted}
              productPrices={productPrices}
              selectedProducts={
                selectedProducts ? selectedProducts : planSelected
              }
              setSelectedProducts={setSelectedProducts}
              selectedBillingCycle={selectedPlanType}
              setSelectedBillingCycle={setSelectedPlanType}
              AddOnsPrice={totalPriceAddOns}
              basePackagePrice={basePackagePrice}
              stripeCustId={stripeCustId}
              paygEnabled={paygEnabled}
              subscriptionCoupon={subscriptionCoupon}
              setSubscriptionCoupon={setSubscriptionCoupon}
              discountAmount={discountAmount}
              setDiscountAmount={setDiscountAmount}
              setIsLoading={setIsLoading}
            />
          </Elements>
        </div>
        <img src={line} className="w-[10px] h-fit bg-red-300" />
        <div className="">
          <ReviewProducts
            setSubmit={setFormSubmitted}
            submit={formSubmitted}
            productPrices={productPrices}
            selectedPlanType={selectedPlanType}
            selectedProducts={
              selectedProducts ? selectedProducts : planSelected
            }
            setSelectedProducts={setSelectedProducts}
            AddOnsPrice={totalPriceAddOns}
            basePackagePrice={basePackagePrice}
            basePackageIcon={basePackageIcon}
            basePackageTutorCount={basePackageTutorCount}
            subscriptionCoupon={subscriptionCoupon}
            discountAmount={discountAmount}
            setDiscountAmount={setDiscountAmount}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewAndPay;
