import { toast } from 'react-toastify'
import { fetchLimitForId, fetchLookupKeyForId, fetchPriceForId } from './fetchers'
import { triggerToast } from '../../../utils/toastController'
fetchLimitForId

//Refactor all the finds for this thing
const formSummaryObject = (siMapping,setSiMapping,subscriptionV2,productPrices,selectedBillingCycle,setSelectedBillingCycle,topUps=[])=> {
    const type = selectedBillingCycle === 0 ? "monthly" : "annual"
    const oldType = subscriptionV2.subscription.__t === "MonthlySubscription" ? "monthly" : "annual"

    const basePackageSiMap = siMapping?.find((val)=>val.lookupKey.includes("BasicPackage"))
    const newBasePackageId = basePackageSiMap?.newPriceId
    let newBasePackageName = null
    if(newBasePackageId){
        newBasePackageName = fetchLookupKeyForId(productPrices,newBasePackageId,type,"BasePackage").split('_')[1]
    }

    const hideDeleteButton = (type !== oldType) || (newBasePackageName !== null && newBasePackageName.includes("Free")) || (subscriptionV2?.subscription.__t === "FreeSubscription")

    if(!_.isEqual(siMapping,subscriptionV2.siMapping)){
        const forAddons = {
            type : "add-ons",
            sectionTitle : "Product Changes",
            children : []
        }
        const forTopUps = {
            type : "top-ups",
            sectionTitle : "Top Ups",
            children : []
        }
        const forPlan = {
            type : "base-package",
            sectionTitle : "Your revised plan",
            children : []
        }

        const forTutorLicenses = {
            type : "add-ons",
            sectionTitle : "Additional Tutor Licenses",
            children : []
        }

        for(const siMap of siMapping){
            const lookupKey = siMap.lookupKey
            if(lookupKey.includes("Calendar") && siMap.newPriceId){
                const name =  "Calendar"
                forAddons.children.push({
                    title : name,
                    helperText : {
                        old : subscriptionV2.subscription.sessions.limit === -1 ? "Unlimited sessions" : subscriptionV2.subscription.sessions.limit + " sessions",
                        new : fetchLimitForId(productPrices,siMap.newPriceId,type,name) === "-1" ? "Unlimited sessions" : fetchLimitForId(productPrices,siMap.newPriceId,type,name) + " sessions",
                        billing : "Prorated charges apply from today"
                    },
                    priceText : {
                        main :"$" +  fetchPriceForId(productPrices,siMap.newPriceId,type,name),
                        old : "$" + fetchPriceForId(productPrices,siMap.priceId,oldType,name),
                        type : fetchPriceForId(productPrices,siMap.newPriceId,type !== oldType ? type : oldType,name) >= fetchPriceForId(productPrices,siMap.priceId,oldType,name) ? "upg" : "dwg",
                        helper : fetchPriceForId(productPrices,siMap.newPriceId,type !== oldType ? type : oldType,name) >= fetchPriceForId(productPrices,siMap.priceId,oldType,name) ? "Upgrade" : "Downgrade",
                        billingPeriod : selectedBillingCycle === 0 ? "/mo" : "/year",
                        oldBillingPeriod : subscriptionV2?.subscription?.__t === "MonthlySubscription" ? "/mo" : "/year"
                    },
                    metadata : {
                        idForAction : siMap.priceId
                    },
                    onDeleteClick : (e) => {
                        setSiMapping(siMapping.map((val)=>{
                            console.log("Testing")
                            console.log(e.target.id)
                            if(val.priceId === e.target.id) {
                                val.newPriceId = null
                            }
                            return val
                        }))
                        triggerToast("Changes made to your selections.","info",{autoClose : 3000})
                    },
                    hideDelete : hideDeleteButton
                })
            }

            if(lookupKey.includes("Assignments") && siMap.newPriceId){
                const name =  "Assignments"
                forAddons.children.push({
                    title : name,
                    helperText : {
                        old : subscriptionV2.subscription.assignments.limit === -1 ? "Unlimited credits" : subscriptionV2.subscription.assignments.limit + " credits",
                        new : fetchLimitForId(productPrices,siMap.newPriceId,type,name) === "-1" ? "Unlimited credits" : fetchLimitForId(productPrices,siMap.newPriceId,type,name)+ " credits",
                        billing : "Prorated charges apply from today"
                    },
                    priceText : {
                        main :"$" +  fetchPriceForId(productPrices,siMap.newPriceId,type,name),
                        old : "$" + fetchPriceForId(productPrices,siMap.priceId,oldType,name),
                        type : fetchPriceForId(productPrices,siMap.newPriceId,type !== oldType ? type : oldType,name) >= fetchPriceForId(productPrices,siMap.priceId,oldType,name) ? "upg" : "dwg",
                        helper : fetchPriceForId(productPrices,siMap.newPriceId,type !== oldType ? type : oldType,name) >= fetchPriceForId(productPrices,siMap.priceId,oldType,name) ? "Upgrade" : "Downgrade",
                        billingPeriod : selectedBillingCycle === 0 ? "/mo" : "/year",
                        oldBillingPeriod : subscriptionV2?.subscription?.__t === "MonthlySubscription" ? "/mo" : "/year"
                    },
                    metadata : {
                        idForAction : siMap.priceId
                    },
                    onDeleteClick : (e) => {
                        setSiMapping(siMapping.map((val)=>{
                            console.log("Testing")
                            console.log(e.target.id)
                            if(val.priceId === e.target.id) {
                                val.newPriceId = null
                            }
                            return val
                        }))
                        triggerToast("Changes made to your selections.","info",{autoClose : 3000})
                    },
                    hideDelete : hideDeleteButton
                })
            }

            if(lookupKey.includes("CustomContent") && siMap.newPriceId){
                const name =  "Custom Tests"
                const productName = "CustomContent"
                forAddons.children.push({
                    title : name,
                    helperText : {
                        old : subscriptionV2.subscription.assignments.customContent.limit === -1 ? "Unlimited tests" : subscriptionV2.subscription.assignments.customContent.limit + " tests",
                        new : fetchLimitForId(productPrices,siMap.newPriceId,type,productName)  === "-1" ? "Unlimited tests" : fetchLimitForId(productPrices,siMap.newPriceId,type,productName) + " tests",
                        billing : "Prorated charges apply from today"
                    },
                    priceText : {
                        main :"$" +  fetchPriceForId(productPrices,siMap.newPriceId,type,productName),
                        old : "$" + fetchPriceForId(productPrices,siMap.priceId,oldType,productName),
                        type : fetchPriceForId(productPrices,siMap.newPriceId,type !== oldType ? type : oldType,productName) >= fetchPriceForId(productPrices,siMap.priceId,oldType,productName) ? "upg" : "dwg",
                        helper : fetchPriceForId(productPrices,siMap.newPriceId,type !== oldType ? type : oldType,productName) >= fetchPriceForId(productPrices,siMap.priceId,oldType,productName) ? "Upgrade" : "Downgrade",
                        billingPeriod : selectedBillingCycle === 0 ? "/mo" : "/year",
                        oldBillingPeriod : subscriptionV2?.subscription?.__t === "MonthlySubscription" ? "/mo" : "/year"
                    },
                    metadata : {
                        idForAction : siMap.priceId
                    },
                    onDeleteClick : (e) => {
                        setSiMapping(siMapping.map((val)=>{
                            console.log("Testing")
                            console.log(e.target.id)
                            if(val.priceId === e.target.id) {
                                val.newPriceId = null
                            }
                            return val
                        }))
                        triggerToast("Changes made to your selections.","info",{autoClose : 3000})
                    },
                    hideDelete : hideDeleteButton
                })
            }
            if(lookupKey.includes("Tutor") && (siMap.newPriceId || siMap.quantity !== subscriptionV2.subscription.tutors.addOns) && ((siMap.quantity === 0 && subscriptionV2.subscription.tutors.addOns > 0) || (siMap.quantity > 0))) {
                console.log("TUTQ",siMap.quantity)
                const name = "Additional Tutor Licenses"
                const productName = "Tutor"
                const requiredNewPriceId = siMap.quantity !== subscriptionV2.subscription.tutors.addOns && !siMap.newPriceId ? siMap.priceId: siMap.newPriceId
                console.log("RPI",requiredNewPriceId)
                forTutorLicenses.children.push({
                    title : null,
                    helperText : {
                        old : subscriptionV2.subscription.tutors.addOns + " Tutor",
                        new : siMap.quantity + " Tutor",
                        billing : "Prorated charges apply from today"
                    },
                    priceText : {
                        old :"$" +  subscriptionV2.subscription.tutors.addOns * fetchPriceForId(productPrices,siMap.priceId,oldType,productName),
                        main : "$" + siMap.quantity * fetchPriceForId(productPrices,requiredNewPriceId,type,productName),
                        type : siMap.quantity * fetchPriceForId(productPrices,requiredNewPriceId,type !== oldType ? type : oldType,productName) >= subscriptionV2.subscription.tutors.addOns * fetchPriceForId(productPrices,siMap.priceId,oldType,productName) ? "upg" : "dwg",
                        helper : siMap.quantity * fetchPriceForId(productPrices,requiredNewPriceId,type !== oldType ? type : oldType,productName) >= subscriptionV2.subscription.tutors.addOns * fetchPriceForId(productPrices,siMap.priceId,oldType,productName) ? "Upgrade" : "Downgrade",
                        billingPeriod : selectedBillingCycle === 0 ? "/mo" : "/year",
                        oldBillingPeriod : subscriptionV2?.subscription?.__t === "MonthlySubscription" ? "/mo" : "/year"
                    },
                    metadata : {
                        idForAction : siMap.priceId
                    },
                    onDeleteClick : (e) => {
                        console.log("Testing")
                        console.log(e.target.id)
                        setSiMapping(siMapping.map((val)=>{
                            if(val.priceId === e.target.id) {
                                val.newPriceId = null
                            }
                            return val
                        }))
                        triggerToast("Changes made to your selections.","info",{autoClose : 3000})
                    }
                    ,
                    hideDelete : true
                })
            }

            if(lookupKey.includes("BasicPackage") && siMap.newPriceId){
              console.log("BASIC PACKAGE SUMMARY OBJECT", siMap.newPriceId)

              const months = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]

              let packageHelperTextOld = subscriptionV2.subscription.__t === "MonthlySubscription" ? "Monthly | " : "Annual | "
              let packageHelperTextNew = selectedBillingCycle === 0 ?  "Monthly | Starts " : "Annual | Starts "
              const oldSubscriptionType = subscriptionV2.subscription.__t === "MonthlySubscription" ? "monthly" : "annual"

              const oldStartDate = new Date(subscriptionV2.subscription.startDate)
              const oldEndDate = new Date(subscriptionV2.subscription.endDate)

              const newStartDate = new Date()

              packageHelperTextOld += subscriptionV2?.subscription.__t === "FreeSubscription" ?  `${months[oldStartDate.getMonth()]} ${oldStartDate.getDate()}, ${oldStartDate.getFullYear()} - ` : `${months[oldStartDate.getMonth()]} ${oldStartDate.getDate()}, ${oldStartDate.getFullYear()} - ${months[oldEndDate.getMonth()]} ${oldEndDate.getDate()}, ${oldEndDate.getFullYear()}`
              packageHelperTextNew += `${months[newStartDate.getMonth()]} ${newStartDate.getDate()}, ${newStartDate.getFullYear()}`

              forPlan.children.push({
                title : null,
                helperText : {
                  old : packageHelperTextOld,
                  new : packageHelperTextNew
                },
                packageText : {
                  old : subscriptionV2.subscription.basePackage.name.split('_')[1],
                  new : fetchLookupKeyForId(productPrices,siMap.newPriceId,type,"BasePackage").split('_')[1]
                },
                priceText : {
                  old : "$" + fetchPriceForId(productPrices,subscriptionV2.subscription.basePackage.priceId,oldSubscriptionType,"BasePackage"),
                  main : "$" + fetchPriceForId(productPrices,siMap.newPriceId,type,"BasePackage"),
                  type : fetchPriceForId(productPrices,subscriptionV2.subscription.basePackage.priceId,oldSubscriptionType,"BasePackage") < fetchPriceForId(productPrices,siMap.newPriceId,type,"BasePackage") ? "upg" : "dwg",
                  helper : fetchPriceForId(productPrices,subscriptionV2.subscription.basePackage.priceId,oldSubscriptionType,"BasePackage") < fetchPriceForId(productPrices,siMap.newPriceId,type,"BasePackage") ? "Upgrade" : "Downgrade",
                  billingPeriod : selectedBillingCycle === 0 ? "/mo" : "/year",
                  oldBillingPeriod : subscriptionV2?.subscription?.__t === "MonthlySubscription" ? "/mo" : "/year"
                },
                metadata : {
                  idForAction : siMap.priceId
                },
                onDeleteClick : (e) => {
                  console.log("Testing")
                  console.log(e.target.id)
                  setSiMapping(siMapping.map((val)=>{
                      if(val.priceId === e.target.id) {
                          val.newPriceId = null
                      }
                      return val
                  }))
                  if(type !== oldSubscriptionType) {
                    setSelectedBillingCycle(selectedBillingCycle === 0 ? 1 : 0)
                  }
                  triggerToast("Changes made to your selections.","info",{autoClose : 3000})
                  
                },
                hideDelete : hideDeleteButton

              })
              
            }
        }

        const tempSummaryObject = []
        if(forPlan.children.length>0) tempSummaryObject.push(forPlan)
        if(forTutorLicenses.children.length>0) tempSummaryObject.push(forTutorLicenses)
        if(forAddons.children.length>0) tempSummaryObject.push(forAddons)
        if(forTopUps.children.length>0) tempSummaryObject.push(forTopUps)

        return tempSummaryObject
    }
}


export default formSummaryObject
