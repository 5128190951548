import LoaderIcon from '../../LoaderIcon/LoaderIcon'
import styles from './ExplorerPlanConfirmationPopUp.module.css'

const ExplorerPlanConfirmationPopUp = (props) => {
    return(
        <>
        <div className={styles.container}>
            {/* <div className={styles.title}>Confirm your Selection</div> */}
            <div className='flex flex-col w-full items-center justify-start gap-[16px]'>
                <div className={styles.subtitle}>Go Ahead With The Explorer Plan?</div>
                <div className={styles.content}>Clicking on ‘Continue’ will start the Explorer subscription plan for your account and take you to your admin homepage. No Credit Card required.</div>
            </div>
            <div className='flex w-full items-center justify-center gap-[12px]'>
                <button className={styles.goBackButton} onClick={(e)=>props.setPopUpTrigger(false)}>Go Back</button>
                <button className={styles.continueButton} onClick={(e)=>props.handleContinueClick(e)}>
                    {
                        !props.showLoading ? 
                        "Continue" : <div className='w-full flex justify-center items-center'><LoaderIcon /></div>
                    }

                </button>
            </div>
        </div>
        </>
    )
}

export default ExplorerPlanConfirmationPopUp