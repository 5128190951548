export default function TentativeStatusIcon(){

	return(
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_34454_70852)">
				<path d="M7 0C3.13359 0 0 3.13359 0 7C0 10.8664 3.13359 14 7 14C10.8664 14 14 10.8664 14 7C14 3.13359 10.8664 0 7 0ZM3.34414 8.09375C2.73984 8.09375 2.25039 7.6043 2.25039 7C2.25039 6.3957 2.73984 5.90625 3.34414 5.90625C3.94844 5.90625 4.43789 6.3957 4.43789 7C4.43789 7.6043 3.94844 8.09375 3.34414 8.09375ZM7 8.09375C6.3957 8.09375 5.90625 7.6043 5.90625 7C5.90625 6.3957 6.3957 5.90625 7 5.90625C7.6043 5.90625 8.09375 6.3957 8.09375 7C8.09375 7.6043 7.6043 8.09375 7 8.09375ZM10.6559 8.09375C10.0516 8.09375 9.56211 7.6043 9.56211 7C9.56211 6.3957 10.0516 5.90625 10.6559 5.90625C11.2602 5.90625 11.7496 6.3957 11.7496 7C11.7496 7.6043 11.2602 8.09375 10.6559 8.09375Z" fill="#ECA10E"/>
			</g>
			<defs>
				<clipPath id="clip0_34454_70852">
					<rect width="14" height="14" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	)

}
