import { useContext, useEffect, useState } from "react";
import ButtonV2 from "../ButtonV2/ButtonV2";
import PhoneNumberInputV2 from "../PhoneNumberInputV2/PhoneNumberInputV2";
import styles from "./FormCard.module.css";
import formLogo from "./FormLogo.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import LoaderIcon from "../LoaderIcon/LoaderIcon";
import OrgSignupContext from "../../context/OrgSignupContext";
import { useGetOrganizationQuery, useLazyFetchSingleUserQuery, useLazyGetOrganizationQuery } from "../../app/services/users";
const FormCard = (props) => {

  const [fetchUserDetail, ] = useLazyFetchSingleUserQuery()
  const [fetchOrgDetail, ] = useLazyGetOrganizationQuery()
 
  const { noOfTutors } = useContext(OrgSignupContext)
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [acknowledgement, setAcknowledgement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [org, setOrg] = useState();
  const orgBackup = useSelector((state)=>state?.organization?.organization)
  const { id : userId } = useSelector((state)=>state.user)

  //To seed the formcard
  useEffect(()=>{
    const _userId = window.localStorage.getItem("userId") ?? userId
    const orgId = window.localStorage.getItem("orgId") ?? orgBackup._id

    if(userId){
      
      fetchUserDetail(_userId)
        .then((res)=>{
          if(!res.error){
            console.log(res.data.data.user)
            setUser(res.data.data.user);
            setOrg(res.data.data.orgdetails)
          }
           
        })
    }

  },[])



  const handleFormSubmit = (e) => {
    setIsLoading(true);
    console.log(e.target);
    e.preventDefault();
    const customer = {
      email: e.target.email.value ? e.target.email.value.trim() : user?.email,
      name: e.target.fullName.value
        ? e.target.fullName.value.trim()
        : user?.firstName + " " + user?.lastName,
      companyName: e.target.companyName.value
        ? e.target.companyName.value.trim()
        : org?.company,
      phoneNumber: phoneNumber ? phoneNumber : user?.phone,
      countryCode: selectedCountryCode.dial_code,
    };
    console.log(customer);

    const allData = true;

    Object.keys(customer).forEach((key) => {
      if (customer[key] === "") {
        toast.warn("All the fields are required");
        allData = false;
        return;
      }
    });

    if (allData) {
      axios
        .post(process.env.REACT_APP_BASE_URL + "api/queries", {
          query: { customer: customer },
        })
        .then((res) => {
          toast.success(
            "Query submitted successfully, our team will reach out to you soon"
          );
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error("Some error occured");
          setIsLoading(false);
        });
    }
  };

  return (
    <div className={`${styles.container} ${noOfTutors >= 16 ? noOfTutors > 20 ? styles.containerHeightForMoreTutors2 : styles.containerHeightForMoreTutors1 : ""}`}>
      <div className={styles.cardTop}>CUSTOMISED FOR YOU</div>
      <div className={styles.summary}>
        <img src={formLogo} />
        <h3>Let’s Talk!</h3>
        <div className={styles.description}>
          We create tailor-made solutions for large businesses. Fill in your
          details and we’ll get in touch!
        </div>
      </div>
      <form
        className={styles.formContainer}
        onSubmit={(e) => handleFormSubmit(e)}
      >
        <div className={styles.fieldsContainer}>
          <input
            type="text"
            placeholder="Full name*"
            name="fullName"
            defaultValue={user ? user?.firstName + " " + user?.lastName : ""}

          />
          <input
            type="text"
            placeholder="Company or business name*"
            name="companyName"
            defaultValue={org ? org?.company : ""}

          />
          <input
            type="text"
            placeholder="Email address*"
            name="email"
            defaultValue={user ? user?.email : ''}

          />
          <PhoneNumberInputV2
            placeholderText="Phone number"
            selectedCountryCode={selectedCountryCode}
            setSelectedCountryCode={setSelectedCountryCode}
            setPhoneNumberOutside={setPhoneNumber}
            defaultValue={user?.phone}
            setIsNotValid={()=>{}}
          />
        </div>

        <div className={styles.actionsContainer}>
          <button
            type="submit"
            className={styles.buttonV2}
            disabled={!acknowledgement}
          >
            {!isLoading ? (
              "Submit"
            ) : (
              <div className="flex items-center w-full justify-center">
                <LoaderIcon />
              </div>
            )}
          </button>
          <div
            className={`${styles.checkBoxArea} flex items-start justify-start gap-[0.5rem] mt-[-0.75rem]`}
          >
            <input
              type="checkbox"
              name={"ack"}
              onChange={(e) => setAcknowledgement(e.target.checked)}
            />
            <label>
              I acknowledge that by submitting this, I am agreeing to receive
              communications from Evallo.
            </label>
          </div>
        </div>

      </form>
    </div>
  );
};

export default FormCard;
