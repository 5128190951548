import { useEffect, useState } from "react"
import styles from "./PlanSelector.module.css"


const PlanIndicator = ({type}) => {
    const [style, setStyle] = useState()
    const [content, setContent] = useState()

    useEffect(()=>{
        switch (type) {
            case "current" : setStyle(styles.currentPlanIndicator);setContent("current plan"); break;
            case "recommended" : setStyle(styles.recommendedPlanIndicator); setContent("recommended");break;
        }
    },[type])

    if(!type){
        return <></>
    }

    if(style && content) {
        return(
            <div className={style}>{content}</div>
        )
    }
}

const PlanSelector = ({packageIcon, packageName,tutorNos, showBillingPeriod, isCurrentPlan, packagePrice, isRecommended, isSelected, onClick, id, disabled}) => {
    return(
        <div className={`${styles.container} ${isSelected ? styles.selected : ""}`} onClick={(e)=>onClick(e)} id={id}>
            <div className={styles.planDetails} >
                {packageIcon}
                <div className={styles.packageName}>{packageName}</div>
                <PlanIndicator type={isRecommended ? "recommended" : isCurrentPlan ? "current" : null} />
            </div>
            <div className={styles.priceDetails}>
                <div className={styles.price}>
                    {packagePrice}
                </div>
                {
                    showBillingPeriod  ?                 
                    <div className={styles.period}>
                        /mo
                    </div>: <></>
                }
            </div>
            <div className={styles.tutorDetails}>
                Includes <span>{tutorNos}</span> Tutor Licenses
            </div>
        </div>
    )
}


export default PlanSelector