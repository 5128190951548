export default function RoundLockedIcon(props) {


    if (props.small) {
        return (
            <div className="bg-white rounded-[50%] flex items-center justify-center py-[6.24px] px-[7.73px] shadow-[0px_4.05px_26.98px_0px_rgba(0,10,255,0.16)]" {...props.container} {...props}>
                <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props.imageProps}>
                    <path d="M8.60114 5.01135H8.13706H8.13694V3.37672C8.13692 1.64715 6.7298 0.23999 5.00018 0.23999C3.27066 0.23999 1.8635 1.64715 1.8635 3.37675V5.01137H1.39932C1.03238 5.01137 0.734863 5.30884 0.734863 5.6758V6.08001V10.6914V11.0955C0.734863 11.4625 1.03236 11.76 1.39932 11.76H5.00021H8.60114C8.96808 11.76 9.26555 11.4625 9.26555 11.0955V10.6914V6.08001V5.6758C9.26555 5.30882 8.96808 5.01135 8.60114 5.01135ZM5.70743 9.7708H4.293L4.54615 8.56455C4.30941 8.41389 4.15128 8.15083 4.15128 7.84942C4.15128 7.3806 4.53139 7.00054 5.00021 7.00054C5.46905 7.00054 5.84909 7.3806 5.84909 7.84942C5.84909 8.15083 5.691 8.41389 5.45429 8.5645L5.70743 9.7708ZM7.01771 5.01135H5.00021H2.98282H2.98271V3.37672C2.98271 2.26417 3.8877 1.3592 5.00021 1.3592C6.11276 1.3592 7.01771 2.26419 7.01771 3.37672V5.01135Z" fill="#0671E0" />
                </svg>

            </div>
        )

    }

    return (
        <div className="bg-white rounded-[50%] flex items-center justify-center py-[12.48px] px-[15.47px] shadow-[0px_3px_10px_0px_#00000033]" {...props.container} {...props}>
            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props.imageProps}>
                <path d="M16.2033 10.0232H15.2751H15.2749V6.75394C15.2748 3.2948 12.4606 0.480469 9.00134 0.480469C5.5423 0.480469 2.72797 3.2948 2.72797 6.75399V10.0232H1.79961C1.06573 10.0232 0.470703 10.6182 0.470703 11.3521V12.1605V21.3832V22.1916C0.470703 22.9255 1.06569 23.5205 1.79961 23.5205H9.00139H16.2033C16.9371 23.5205 17.5321 22.9255 17.5321 22.1916V21.3832V12.1605V11.3521C17.5321 10.6181 16.9371 10.0232 16.2033 10.0232ZM10.4158 19.5421H7.58698L8.09328 17.1296C7.6198 16.8283 7.30353 16.3021 7.30353 15.6993C7.30353 14.7617 8.06375 14.0016 9.00139 14.0016C9.93908 14.0016 10.6992 14.7617 10.6992 15.6993C10.6992 16.3021 10.383 16.8283 9.90955 17.1295L10.4158 19.5421ZM13.0364 10.0232H9.00139H4.96662H4.96639V6.75394C4.96639 4.52883 6.77637 2.71889 9.00139 2.71889C11.2265 2.71889 13.0364 4.52888 13.0364 6.75394V10.0232Z" fill="#0671E0" />
            </svg>
        </div>
    )
}