import CrossIcon from "../../../../../assets/icons/SettingsIcon/CrossIcon"
import PopupContainer from "../../../../../components/PopupContainer/PopupContainer"
import styles from "./DeleteColorPopup.module.css"

function DeleteColorPopup ({trigger, setTrigger, onDeleteClick, idx}) {
	
	return (
		<PopupContainer {...{trigger, setTrigger}} customCloseButton={true}>
		<div className={styles.container}>
			<div className={styles.headingContainer}>
				<div>Delete Color</div>	
				<button onClick={(e)=>setTrigger(false)}><CrossIcon /></button>
			</div>
			<div className={styles.messageContainer}>
				<div className={styles.messageHeading}>Are you sure you want to delete this color?</div>
				<div className={styles.messageDesc}>Note: In case your website is currently using this color, this action will reset it to Evallo’s default color.</div>
			</div>
			<div className={styles.buttonContainer}>
					<button className={styles.cancelButton} onClick={(e)=>setTrigger(false)}>Cancel</button>
					<button className={styles.deleteButton} onClick={(e)=>onDeleteClick(e, idx)} >Delete</button>
			</div>
		</div>
		</PopupContainer>
	)


}
 export default DeleteColorPopup
