import React from "react";
import WarningTriangle from "../assets/WarningTriangle.svg";

const RetryPaymentWarning = () => {
  return (
    <div className="bg-[#FEF3F2] px-[24px] py-[16px] gap-[10px] flex w-full rounded-[12px] justify-center">
      <div className="flex justify-center items-center">
        <img src={WarningTriangle} className="w-[40px] h-[40px]" />
      </div>
      <div className="text-[#515C7B] text-[16px] font-normal text-left flex flex-col gap-[4px] px-[2px]">
        <div>
          There was an issue processing your last payment. Please double-check
          your payment details and retry. If the problem persists, please
          contact your bank or try using a different payment method.
        </div>
        <div className="text-[#25335A] font-medium w-fit border-b border-[#25335A]">
          Retry Payment
        </div>
      </div>
    </div>
  );
};

export default RetryPaymentWarning;
