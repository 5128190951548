import React, { useState, useEffect, useContext } from "react";
import OtpInput from "../../OtpInput/OtpInput";
import { toast } from "react-toastify";
import {
  useCompleteEmailVerificationMutation,
  useStartEmailVerificationMutation,
} from "../../../app/services/orgSignup";
import OrgSignupContext from "../../../context/OrgSignupContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";
import { triggerToast } from "../../../utils/toastController";

const VerifyEmailOtp = () => {
  const navigate = useNavigate();
  const { setSignupStage } = useOutletContext();
  const [countdown, setCountDown] = useState(30);
  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(false);
  const { userEmail } = useContext(OrgSignupContext);
  const [isLoading, setIsLoading] = useState(false);
  const [startEmailVerification, startEmailVerificationResp] =
    useStartEmailVerificationMutation();
  const [completeEmailVerification, completeEmailVerificationResp] =
    useCompleteEmailVerificationMutation();

  useEffect(() => {
    setSignupStage("enterOTP");
    if (!userEmail) {
      toast.error("Unauthorized access, please follow the correct steps.", {
        position: "top-center",
        autoClose: 3000,
        onClose: () => {
          navigate("/signup/org");
        },
      }); //Navigate to signup/org here
    }
  }, []);

  useEffect(() => {
    if (countdown >= 0) {
      const interval = setInterval(() => {
        setCountDown(countdown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown]);

  const onOtpChange = (value) => {
    setOtp(value);
    setIsOtpValid(value.length === 6);
  };

  const handleResendButtonClick = (e) => {
    startEmailVerification({
      email: userEmail,
    })
      .then((res) => {
        setCountDown(30);
      })
      .catch((err) => {
        toast.error("Oops some error occured", {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  const handleCompleteVerificationClick = (e) => {
    setIsLoading(true);
    completeEmailVerification({
      email: userEmail,
      op: "verify",
      OTP: parseInt(otp),
    }).then((res) => {
      if (res.error) {
        console.log(res);
        if (res.error.status === 400) {
          toast.info("Account already verified, redirecting to login", {
            position: "top-center",
            autoClose: 3000,
            onClose: () => {
              window.history.back();
            },
          }); //Redirect to login here
        } else if (res.error.status === 401) {
          //Redirect to appropriate pages
          if (res.error.data.message === "EXPIRED") {
            navigate("../expired-otp");
          } else {
            triggerToast(
              <div className="text-secondary">
                {"Incorrect code. Please try again."}
              </div>,
              "error",
              { autoClose: 2000 }
            );
          }
        }
      } else {
        navigate("../set-new-password");
        window.localStorage.setItem("set_password_token", res.data.data.token);
      }
      setIsLoading(false);
    });
  };

  return (
    <div>
      <div className="flex items-center justify-center w-full  h-[75vh]  ">
        <div className="grid  gap-[36px] md:w-[420px] text-[#3B476B] text-center text-[16px] px-[0px] md:px-[0px]">
          <div className="gap-[8px] md:gap-[18px]  flex flex-col ">
            <h3 className="text-[20px] md:text-[32px] font-[700] text-[#26435F]">
              Check your inbox
            </h3>
            <div className="font-[400] hidden md:block">
              <div>Please enter the one-time verification code we sent to</div>
              <div className="font-[600]">{userEmail}</div>
            </div>
            <div className=" hidden md:block ">
              <div>
                If you can’t find it, please check the spam/junk folder.
              </div>
            </div>

            <div className="text-center font-[500] leading-[24px] md:hidden">
              Please enter the one-time verification code we sent to{" "}
              <span className="font-[600]">{userEmail}. </span>If you can’t find
              it, please check the spam/junk folder.
            </div>
          </div>

          <div className=" grid gap-[24px]">
            <div className="text-left font-[600] hidden md:block">
              Verification code
            </div>
            <div className="text-center font-[600]  md:hidden">
              Enter Verification code
            </div>
            <div className="">
              <OtpInput value={otp} valueLength={6} onChange={onOtpChange} />{" "}
            </div>
            <div>
              <button
                className={`w-full py-[14px] rounded-[6px] font-[600] ${
                  isOtpValid
                    ? "bg-[#0671E0] text-white"
                    : "bg-secondary-10 text-[#7c859c]"
                }`}
                disabled={!isOtpValid}
                onClick={(e) => handleCompleteVerificationClick(e)}
              >
                {!isLoading ? (
                  "Continue"
                ) : (
                  <div className="w-full flex justify-center items-center">
                    <LoaderIcon color={"white"} />
                  </div>
                )}
              </button>
            </div>
          </div>
          <div>
            Still didn't find it?{" "}
            <span className="font-medium">
              {countdown >= 1 ? (
                `Resend code in 00:${countdown}`
              ) : (
                <button
                  className="font-bold text-[#0671E0]"
                  onClick={(e) => handleResendButtonClick(e)}
                >
                  Resend code
                </button>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailOtp;