import React, { useEffect, useState } from "react";
import ManageIcon from "../../assets/icons/AccountDropdownCard/ManageIcon";
import SettingIcon from "../../assets/icons/AccountDropdownCard/SettingIcon";
import TagIcon from "../../assets/icons/AccountDropdownCard/TagIcon";
import SupportIcon from "../../assets/icons/AccountDropdownCard/SupportIcon";
import TutorialIcon from "../../assets/icons/AccountDropdownCard/TutorialIcon";
import ExitIcon from "../../assets/icons/AccountDropdownCard/ExitIcon";
import ExternalLinkIcon from "../../assets/icons/AccountDropdownCard/ExternalLinkIcon";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ProfileIcon from "../../assets/icons/AccountDropdownCard/ProfileIcon";
import { triggerUpdateSubscriptionPopUp } from "../../utils/subscriptionPopUpService";

const AccountDropdownCard = ({ persona, setLogoutModalActive, isLoggedIn, fromStage, outsideclassName,showMobileProfile, setShowMobileProfile, setIsAccountDDOpen, setIsHovering }) => {
  const user = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.user);
  const [isFixedWidth, setIsFixedWidth] = useState(false)


  useEffect(()=>{
    if(window.location.pathname.startsWith("/settings") || window.location.pathname.startsWith("/calendar")){
      setIsFixedWidth(true)
    } else {
      setIsFixedWidth(false)
    }
  },[window.location.pathname])

  useEffect(() => {
    console.log("U>>>", role);
  }, []);

  return (
    <div className={`absolute  h-full  right-0 top-0 md:top-14 flex flex-col gap-4 w-full  md:h-auto md:w-[312px] bg-white xl:rounded-xl px-4 pb-4 pt-10 xl:px-2 xl:py-[calc(100vw*(16/1440))] shadow-[0px_4.05px_26.98px_0px_#000AFF29] ${outsideclassName} ${showMobileProfile ? outsideclassName : "hidden"}`} >
      <div className="flex flex-col gap-4 items-center">
        <div className="w-[84px] h-[84px] rounded-full overflow-hidden">
          {user.photo ? (
            <img
              className="w-full h-full object-cover"
              src={user.awsLink + user.photo}
              alt=""
            />
          ) : (
            <div className="flex justify-center items-center bg-blue-50 text-white h-full w-full text-3xl uppercase">
              {user?.firstName && user.lastName && user?.firstName[0] + user?.lastName[0]}
            </div>
          )}
        </div>
        <div>
          <h1 className="font-medium text-center text-secondary text-[16px] leading-[24px] capitalize">
            {user?.firstName + " " + user?.lastName}
          </h1>
          <p className="font-normal text-[14px] leading-[21px] text-center text-secondary-60">
            {user.email}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="border-b md:mx-2"></div>
        <div>
          <Link
            to={`/profile/${role}/${user?.id}`}
            className="no-underline hover:no-underline "
          >
            <div
              className={` ${persona === "student" || persona === "parent"
                ? "hidden"
                : "block"
                }    flex gap-2 items-center mb-1 px-2 py-1 rounded-md text-secondary-80 font-normal text-[14px] leading-[21px] cursor-pointer hover:bg-[#F4F5F7]`}
            >
              <ProfileIcon />
              Profile
            </div>
          </Link>
          <button
            className="no-underline hover:no-underline hidden md:block"
            onClick={(e) => {
              triggerUpdateSubscriptionPopUp()
            }}
          >
            <div
              className={` ${role !== "admin" ? "hidden" : "block"
                } flex gap-2 items-center mb-1 px-2 py-1 rounded-md font-normal text-[14px] text-secondary-80 leading-[21px] cursor-pointer hover:bg-[#F4F5F7]`}
            >
              <ManageIcon />
              Manage Subscription
            </div>
          </button>
          <Link to="/settings" className="no-underline hover:no-underline text-secondary-80" 
                onClick={(e)=>{ 
                  if(!setIsHovering){
                    console.log("TESTINGEE")
                  }
                  if(setIsAccountDDOpen) {
                    setIsAccountDDOpen(false)
                    return;
                  }  
                  
                  if(setShowMobileProfile){
                    setShowMobileProfile(false)
                    return;
                  }
                }}
                >
            <div className="flex gap-2 items-center px-2 py-1 rounded-md font-normal text-[14px] leading-[21px] cursor-pointer hover:bg-[#F4F5F7]">
              <SettingIcon />
              Settings
            </div>
          </Link>
        </div>
        <div className="border-b md:mx-2"></div>
        <div>
          <Link
            className={` ${role !== "admin" ? "hidden" : "block"
              } no-underline hover:no-underline text-secondary-80`}
            to="//www.evallo.org/pricing"
            target="_blank"
          >
            <div className="flex gap-2 items-center mb-1 px-2 py-1 rounded-md font-normal text-[14px] leading-[21px] cursor-pointer hover:bg-[#F4F5F7]">
              <TagIcon />
              <div className="flex justify-between items-center w-full">
                Pricing
                <ExternalLinkIcon />
              </div>
            </div>
          </Link>
          <Link
            to={"//www.evallo.org/support"}
            target="_blank"
            className="no-underline hover:no-underline text-secondary-80"
          >
            <div className="flex gap-2 items-center mb-1 px-2 py-1 rounded-md font-normal text-[14px] leading-[21px] cursor-pointer hover:bg-[#F4F5F7]">
              <SupportIcon />
              <div className="flex justify-between items-center w-full">
                Support
                <ExternalLinkIcon />
              </div>
            </div>
          </Link>
          <a
            href="https://www.youtube.com/playlist?list=PLfBtUFeRMAF__fNqpgzS1YwgjLJTN1P-m"
            target="_blank"
            className="flex gap-2 items-center px-2 py-1 rounded-md font-normal text-[14px] leading-[21px] text-secondary-80 cursor-pointer hover:no-underline hover:bg-[#F4F5F7]"
          >
            <TutorialIcon />
            <div className="flex justify-between items-center w-full">
              Tutorials
              <ExternalLinkIcon />
            </div>
          </a>
        </div>
        <div className="border-b md:mx-2"></div>
        <div>
          <div
            onClick={() => isLoggedIn && setLogoutModalActive(true)}
            className="flex gap-2 items-center px-2 py-1 rounded-md font-normal text-[14px] leading-[21px] text-[#F04438] cursor-pointer hover:bg-[#F4F5F7]"
          >
            <ExitIcon />
            Sign out
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDropdownCard;
