import styles from './DropdownV2.module.css'
import { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const DropdownV2 = ({ dropdownContainerClassName, dataClassName, dropdownMenuClassName, dropdownMenuItemClassName, selectedClassName, customIcon, defaultValue, dropdownMenuItems, checkIsSelected, onDropdownItemClick, width }) => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    return (
        <div className={`${styles.dropdownContainer} ${dropdownContainerClassName}`} >
            <div className={`${styles.data} ${dataClassName}`}>{defaultValue ?? "None"}</div>
            <button onClick={(e) => setIsDropdownOpen(!isDropdownOpen)}>{customIcon ?? 
                isDropdownOpen ?
                <ExpandLessIcon /> 
                :
                <ExpandMoreIcon />
            }</button>
            {
                isDropdownOpen ?
                    <div className={`${styles.dropdownMenu} ${dropdownMenuClassName}`}>
                        {
                            dropdownMenuItems?.map((item, idx) => (
                                <div className={`${dropdownMenuItemClassName ?? styles.dropdownMenuItem} ${checkIsSelected && checkIsSelected(item) ? selectedClassName ?? styles.selected : ""}`} onClick={(e) => {setIsDropdownOpen(false);onDropdownItemClick(item)}}>{item.displayTitle}</div>
                            ))
                        }
                    </div>
                    : <></>
            }
        </div>
    )
}

export default DropdownV2