import React, { useMemo } from "react";
import frozenEventTypes from "../../utils/eventTypes";
import ClockIcon from "../CalendarSideIcons/ClockIcon";
import LocationIcon from "../CalendarSideIcons/LocationIcon";
import VidCamIcon from "../CalendarSideIcons/VidCamIcon";
import PersonIcon from "../CalendarSideIcons/PersonIcon";
import InfoIcon2 from "../CalendarSideIcons/InfoIcon2";
import ReminderBellIcon from "../CalendarSideIcons/ReminderBellIcon";
import ParagraphIcon from "../CalendarSideIcons/ParagraphIcon";
import IconCompBinding from "../IconCompBinding/IconCompBinding";
import ReaminderSelector from "../RemainderSelector/ReaminderSelector"
import MeetingLinkSelector from "../MeetingLinkSelector/MeetingLinkSelector";
import InfoTextArea from "../InfoTextArea/InfoTextArea";
import MainCalendarDateSelection from "../CalendarV2DateTime/MainCalendarDateSelection/MainCalendarDateSelection";
import MainAttendeeSelector from "../AttendeeSelector/MainAttendeeSelector/MainAttendeeSelector";
import AttendeeSelector from "../AttendeeSelectorv2/AttendeeSelector";
import SelectCoWorker from "../CoWorker/CoWorker";
import ServicesSelectionSection from "../ServicesSelectionSection/ServicesSelectionSection";
import LocationSelection from "../LocationSelection/LocationSelection";
import TimeZoneSelector from "../TimeZoneSelector/TimeZoneSelector";
import ConvertEventTypeDD from "../ConvertEventTypeDD/ConvertEventTypeDD";
import ChangeIcon from "../ConvertEventTypeDD/ChangeIcon";

// Sample components
const DateTime = ({ value }) => <div>DateTime {value}</div>;
const StudentTutor = ({ value }) => <div>StudentTutor {value}</div>;
const Location = ({ value }) => <div>Location {value}</div>;
const OnlineMeet = ({ value }) => <div>OnlineMeet {value}</div>;
const Service = ({ value }) => <div>Service {value}</div>;
const Remainder = ({ value }) => <div>Remainder {value}</div>;
const Attendees = ({ value }) => <div>Attendees {value}</div>;
const Info = ({ value }) => <div>Info {value}</div>;

const Divider = () => {
	return (
		<div className="min-w-full min-h-[1px] bg-secondary-10 -mt-[8px]"/>		
	)
}

// Icon mapping
const iconMapping = {
  dateTime: ClockIcon,
  location: LocationIcon,
  onlineMeet: VidCamIcon,
  attendees: PersonIcon,
  info: InfoIcon2,
  StudentTutor: PersonIcon,
  remainder: ReminderBellIcon,
  service: ParagraphIcon,
  swap : ChangeIcon,
  divider : Divider  
};

const eventTypeCompMapping = {
  [frozenEventTypes.SESSION.eventType.toLowerCase()]: [
    "dateTime",
    "StudentTutor",
    "location",
    "onlineMeet",
    "service",
    "remainder",
  ],
  [frozenEventTypes.CONSULTATION.eventType.toLowerCase()]: [
    "dateTime",
    "attendees",
    "location",
    "onlineMeet",
    "remainder",
  ],
  [frozenEventTypes.OFFCWORK.eventType.toLowerCase()]: ["dateTime", "attendees", "info"],
  [frozenEventTypes.INTMEETING.eventType.toLowerCase()]: [
    "dateTime",
    "attendees",
    "location",
    "onlineMeet",
    "remainder",
  ],
  [frozenEventTypes.EXTMEETING.eventType.toLowerCase()]: [
    "dateTime",
    "attendees",
    "location",
    "onlineMeet",
	"remainder",
  ],
};



const EventSidebar = (props) => {


  const compMapping = {
    dateTime: { component: MainCalendarDateSelection, icon: "dateTime" },
    location: { component: LocationSelection, icon: "location" },
    onlineMeet: { component: MeetingLinkSelector, icon: "onlineMeet" },
    attendees: { component: AttendeeSelector, icon: "attendees" },
    info: { component: InfoTextArea, icon: "info" },
    StudentTutor: { component: MainAttendeeSelector, icon: "StudentTutor" },
    remainder: { component: ReaminderSelector, icon: "remainder" },
    service: { component: ServicesSelectionSection, icon: "service" },
	changeEventType : { component : ConvertEventTypeDD, icon : "swap" },
	divider : {component : Divider, icon : "divider"}
  };

  const { externalEvent, selectedEventType } = props


	const components = useMemo(()=>{
		const tempComps = eventTypeCompMapping[selectedEventType.toLowerCase()]
		if(props.operationMode === "create-requested" && props.tempEventType.toLowerCase() === frozenEventTypes.EXTMEETING.eventType.toLowerCase()){
			return [...tempComps, "divider", "changeEventType"]
		}
		return tempComps
	},[selectedEventType, props.operationMode])

  return (
    <main className="w-full lg:w-[544px]">
      <div className="flex flex-col gap-[24px] md:gap-[46px] items-start justify-start">
        {

          externalEvent ?
            externalEvent?.map((item, index) => {
              const { component: Component, icon } = compMapping[item];
              const Icon = iconMapping[icon];
              return (
                <IconCompBinding
                  key={index}
                  component={Component}
                  icon={Icon}
                  _props={props}
				  {...(item === "divider") && {height : "1px", flexItem : "center" }}
				  {...(item === "changeEventType") && { flexItem : "center" }}
                />
              );
            })
            :
            components?.map((item, index) => {
              const { component: Component, icon } = compMapping[item];
              const Icon = iconMapping[icon];
              return (
                <IconCompBinding
                  key={index + selectedEventType}
                  component={Component}
                  icon={Icon}
                  _props={props}
				  {...(item === "divider") && {height : "1px", flexItem : "center"}}
				  {...(item === "changeEventType") && { flexItem : "center" }}
                />
              );
            })

        }
      </div>
    </main>
  );
};

export default EventSidebar;
