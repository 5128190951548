import React, { useEffect } from 'react'
import EditPen from '../../assets/icons/SettingsIcon/EditPen'
import { Link } from 'react-router-dom'

const AnnouncementCard = ({ hideEditBtn, header, subText, image, link, cta, limit, id, count, setOpenEditAnnouncementModal, setCurrentId }) => {
	useEffect(() => {
		console.log(link)
	}, [])
	return (
		<div className='w-full h-[199px] xl:h-[227px] '>
			<div className='relative w-full h-full max-h-[163px] xl:max-h-[191px] xl:max-w-[420px] '>                
				<img className='w-full h-full object-cover' src={image} alt="" />
				<div className='absolute top-0 left-0 w-full h-full flex flex-col justify-between'>
					<div className='px-3 pt-3' style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)", height: "100%" }}>
						<h1 className='font-semibold text-20 leading-[25px] text-white break-words'>{header}</h1>
						<p className='font-normal text-[16px] leading-[19px] text-white break-words'>{subText}</p>
					</div>
					<div className='px-3 pb-3'>
						{
							cta && (cta === "None" ? <></> :
								<a href={link && link.includes("http") ? link : "https://" + link} target='_blank'>
									<button className='rounded-md px-5 py-[10px] bg-blue-70 text-white font-medium text-[16px] leading-[20px]'>{cta}</button>
								</a>
							)
						}
					</div>
				</div>
			</div>
			{
				!hideEditBtn && <div className='flex justify-between items-center pt-3'>
					<div className='font-medium text-sm text-secondary '>Announcement <span>{count + "/" + limit}</span> </div>
					<div className='h-6 w-6 rounded-full bg-blue-70 text-white flex justify-center items-center cursor-pointer' onClick={(e) => {
						setCurrentId(id)
						setOpenEditAnnouncementModal(true)
					}}>
						<EditPen />
					</div>
				</div>
			}

		</div>
	)
}

export default AnnouncementCard
