import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFilter: "students",
  appliedFilters: {
    service: {},
    students: {},
    tutors: {},
    parents: {},
    groups: {},
    sessionStatuses: {},
    sessionNotes: {},
    hourlyRate: {},
    location: {},
  },
  filteredData: [],
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    clearAllFilters: (state) => {
      state.appliedFilters = initialState.appliedFilters;
    },
    clearCategoryFilters: (state, action) => {
      state.appliedFilters[action.payload] = {};
    },
    clearItemFilter: (state, action) => {
      const { filterType, id } = action.payload;
      delete state.appliedFilters[filterType][id];
    },
    applyFilterItem: (state, action) => {
      const { filterType, id, value } = action.payload;
      state.appliedFilters[filterType][id] = value;
    },
    setFilteredData: (state, action) => {
      console.log("state", state);
      console.log("action", action);
      state.filteredData = action.payload;
    },
  },
});

export const {
  setSelectedFilter,
  clearAllFilters,
  clearCategoryFilters,
  clearItemFilter,
  applyFilterItem,
  setFilteredData,
} = filtersSlice.actions;

export default filtersSlice.reducer;
