import { useEffect, useState } from "react"
import styles from "./PlanSelectionContainer.module.css"
import { useLazyGetPricesV2Query } from "../../../app/services/subscription"
import PlanSelector from "../PlanSelector/PlanSelector"
import ExplorerIcon from "../../PackageIcons/ExplorerIcon"
import StarterIcon from "../../PackageIcons/StarterIcon"
import ProfessionalIcon from "../../PackageIcons/ProfessionalIcon"
import PremiumIcon from "../../PackageIcons/PremiumIcon"
import Tooltip from "../../Tooltip/Tooltip"
import InfoIcon from "../../InfoIcon/InfoIcon"
import CustomNumberInputWithButtons from "../../CustomNumberInputWithButtons/CustomNumberInputWithButtons"
import { getBasePackageTutorLimit } from "../../../utils/subscriptionService"
import { fetchLookupKeyForId, fetchPriceForId } from "../../../pages/ManageSubscription/Utils/fetchers"
import { useSelector } from "react-redux"
import { tooltipPlansText } from "./staticData"
import { getTooltipStyleClass } from "../../../utils/commonlyUsedTooltipStyles"


function getBasePackageIcon (basePackageName) {
    switch(true){
        case basePackageName.includes("Free") : return <ExplorerIcon />;
        case basePackageName.includes("Starter") : return <StarterIcon />;
        case basePackageName.includes("Professional") : return <ProfessionalIcon />;
        case basePackageName.includes("Premium") : return <PremiumIcon />;
    }
}


const PlanSelectionContainer = ({selectedPlanType, productPrices, setSelectedPlanType, tempTotalTeamSize, setTempTotalTeamSize, tempAdditionalTutors, setTempAdditionalTutors, selectedPlanId, setSelectedPlanId, recommendedBasePackage,currentPlanId}) => {

    const [allBasePackagePrices, setAllBasePackagePrices] = useState({
        monthly : [],
        annual : []
    })
    const [pricePerAdditionalTutorLicense, setPricePerAdditionalTutorLicense]  = useState(null)
    const [freeAnnualBasePackageId, setFreeAnnualBasePackageId] = useState(null)
    const {subscriptionPopUpOperationMode} = useSelector((state)=>state.subscriptionOperation)


    //For load free base package annual
    useEffect(()=>{
        if(productPrices){
            const basePackagesAnnual = productPrices.find((product) => product.product.name.includes("BasePackageV2")).prices["annual"]
            const basePackageFreeAnnual = basePackagesAnnual.find((price)=>price.lookup_key.includes("Free"))
            setFreeAnnualBasePackageId(basePackageFreeAnnual.id)
        }
    }, productPrices)

    const handleButtonClicks = (buttonType) => {
        if(productPrices && selectedPlanId){
            let teamSize = tempTotalTeamSize
            if(buttonType === "decr" && teamSize - 1 >= 1) teamSize -= 1;
            else if(buttonType === "incr" && teamSize + 1 <= 999) teamSize += 1

            const lookupKeyForSelectedPlanId = fetchLookupKeyForId(productPrices,selectedPlanId,selectedPlanType === 0 ? "monthly" : "annual","BasePackageV2")
            if((!lookupKeyForSelectedPlanId.includes("Free") && subscriptionPopUpOperationMode === "new-subscription") || (subscriptionPopUpOperationMode === "update-subscription")){
                console.log(buttonType, teamSize)
                const basePackageTutorLimit = getBasePackageTutorLimit(lookupKeyForSelectedPlanId)
                const totalTeamSize = teamSize
                const additionalTutorLicenses = basePackageTutorLimit < totalTeamSize ? totalTeamSize - basePackageTutorLimit : 0
                setTempTotalTeamSize(totalTeamSize)
                setTempAdditionalTutors(additionalTutorLicenses)
            }
        }
    }

    const handleDirectChange = (additionalTutors) => {
        if(productPrices && selectedPlanId){
            console.log(additionalTutors)
            let teamSize = tempTotalTeamSize + additionalTutors

            const lookupKeyForSelectedPlanId = fetchLookupKeyForId(productPrices,selectedPlanId,selectedPlanType === 0 ? "monthly" : "annual","BasePackageV2")
            if(!lookupKeyForSelectedPlanId.includes("Free")){
                const basePackageTutorLimit = getBasePackageTutorLimit(lookupKeyForSelectedPlanId)
                const totalTeamSize = teamSize
                const additionalTutorLicenses = basePackageTutorLimit < totalTeamSize ? totalTeamSize - basePackageTutorLimit : 0
                setTempTotalTeamSize(totalTeamSize)
                setTempAdditionalTutors(additionalTutorLicenses)
            }
        }
    }

    useEffect(()=>{
        if(productPrices && selectedPlanId){
            const lookupKeyForSelectedPlanId = fetchLookupKeyForId(productPrices,selectedPlanId,selectedPlanType === 0 ? "monthly" : "annual","BasePackageV2")
            const basePackageName = lookupKeyForSelectedPlanId.split("_")[1]
            if(!basePackageName.includes("Free")){
                const tutorPricesForPlanType = productPrices.find((product)=>product.product.name.includes("Tutor")).prices[selectedPlanType === 0 ? "monthly" : "annual"]
                let tutorPriceForPackageType = tutorPricesForPlanType.find((val)=>val.lookup_key.includes(basePackageName)).unit_amount/(selectedPlanType === 0 ? 100 : 1200)
                tutorPriceForPackageType = Math.round(tutorPriceForPackageType)
                setPricePerAdditionalTutorLicense(tutorPriceForPackageType)

            }
        }
    },[selectedPlanId, selectedPlanType])


    //Fetch product prices
    useEffect(()=>{
        if(productPrices){
            const basePackagePrices = productPrices.find((product)=>product.product.name.includes("BasePackageV2"))
            const annualPrices = [...basePackagePrices.prices["annual"] ]
            const monthlyPrices = [...basePackagePrices.prices["monthly"]]
            setAllBasePackagePrices({
                monthly : monthlyPrices.sort((priceA,priceB)=>priceA.unit_amount - priceB.unit_amount),
                annual : annualPrices.sort((priceA,priceB)=>priceA.unit_amount - priceB.unit_amount)
            })
        }
    },[productPrices])

    const handlePackageSelection = (e) => {
        const selectedPriceId = e.target.id
        console.log(selectedPriceId)
        setSelectedPlanId(selectedPriceId)
    }


    return(
        <div className={styles.container}>
            <div className={styles.planBrowser}>
                {
                allBasePackagePrices[selectedPlanType === 0 ? "monthly" : "annual"].length > 0 ? 
                allBasePackagePrices[selectedPlanType === 0 ? "monthly" : "annual"].map((price,idx)=>(
                    <Tooltip 
                    tooltipContent={tooltipPlansText[idx]}
                    tooltipStyleClass={styles.tooltipStyleClass}
                    anchorComponent={
                        <PlanSelector 
                        showBillingPeriod={price.lookup_key.includes("Free") ? false : true}
                        packageIcon={getBasePackageIcon(price.lookup_key)}
                        packageName={price.lookup_key.includes("Free") ? "Explorer" : price.lookup_key.split("_")[1]}
                        packagePrice={selectedPlanType === 0 ? `${price.unit_amount === 0 ? "Free" : "$"+(price.unit_amount/100).toFixed(0)}` : `${price.unit_amount === 0 ? "Free" : "$"+((price.unit_amount/1200)).toFixed(1)}`}
                        tutorNos={getBasePackageTutorLimit(price.lookup_key)}
                        isCurrentPlan={subscriptionPopUpOperationMode === "update-from-free" || subscriptionPopUpOperationMode === "new-subscription" ?  idx === 0 : currentPlanId === price.id} 
                        isRecommended={subscriptionPopUpOperationMode === "new-subscription" && recommendedBasePackage?.value === price.id}
                        isSelected={subscriptionPopUpOperationMode === "new-subscription" ? (price.id === selectedPlanId && idx > 0) : (idx === 0 ? freeAnnualBasePackageId === selectedPlanId : price.id === selectedPlanId) }
                        id={idx === 0 ? freeAnnualBasePackageId : price.id}
                        onClick={handlePackageSelection}
                    />
                    }
                    />
                ))
                :
                <></>
                }
            </div>
            <div className={styles.verticalDivider}>&nbsp;</div>
            <div className={styles.tutorLicensesDetailsArea}>
                <div className={styles.col1}>Tutor Licenses included for free <span><Tooltip anchorComponent={<div className="min-w-[16px] min-h-[16px]"/>}/></span></div><div className={styles.col2}>{productPrices && selectedPlanId ? getBasePackageTutorLimit(fetchLookupKeyForId(productPrices,selectedPlanId,selectedPlanType === 0 ? "monthly" : "annual","BasePackageV2")) : "1"}</div>
                {
                    productPrices && freeAnnualBasePackageId && selectedPlanId !== freeAnnualBasePackageId ? getBasePackageTutorLimit(fetchLookupKeyForId(productPrices,selectedPlanId,selectedPlanType === 0 ? "monthly" : "annual","BasePackageV2")) < tempTotalTeamSize ? 
                    <> 
                    <div className={styles.col1}>Additional Tutor Licenses <span><Tooltip anchorComponent={<InfoIcon inherit={true}/>} tooltipContent={"Additional licenses that you can purchase to increase your tutoring team size."} tooltipStyles={getTooltipStyleClass({left : "-200px", top : "28px"})} tooltipTriangleStyles={{top : "-10px", left : "80%", transform : "rotate(90deg)"}}/></span></div><div className={styles.col2}>{productPrices && selectedPlanId ? <CustomNumberInputWithButtons defaultValue={tempAdditionalTutors} setOutsideValue={setTempAdditionalTutors} handleButtonClicks={handleButtonClicks} handleDirectChange={handleDirectChange}/>
                    : <span className="text-secondary-60 font-[400]">NA</span>}</div>
                    <div className={styles.col1}>Unit cost per additional License </div><div className={styles.col2}>{ productPrices && selectedPlanId ? <>{"$"}{pricePerAdditionalTutorLicense}<span>/mo</span></> : <span className="text-secondary-60 font-[400]">NA</span>}</div>
                    </> :<></> : <></>
                }
               

                <div className={styles.col1}>Total cost for Licenses</div><div className={styles.col2}>{ productPrices && selectedPlanId ? <>{"$"}{(tempAdditionalTutors * pricePerAdditionalTutorLicense)}<span>/mo</span></> : <>$0<span>/mo</span></>}</div>
            </div>
        </div>
    )
}


export default PlanSelectionContainer
