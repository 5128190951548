import { toast } from 'react-toastify'
import styles from '../components/GlobalTestingComponent/GlobalTestingComponent.module.css'


function triggerToast(content,type,options) {
    console.log("TOAST TRIGGERED")
    const styleClasses = [styles.container]
    switch (type) {
        case "success":
            styleClasses.push(styles.success)
        case "info":
            styleClasses.push(styles.info)
        case "warn" :
            styleClasses.push(styles.warn)
        case "error" :
            styleClasses.push(styles.error)
    }

    const styleClassString = styleClasses.join(" ")
    toast(content,{className : styleClassString, ...options, hideProgressBar : true})
}



export {
    triggerToast
}