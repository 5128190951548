import { useEffect, useState } from 'react';
import CrossIcon from '../../../../CrossIcon/CrossIcon'
import PopupContainer from '../../../../PopupContainer/PopupContainer'
import styles from './StatusEditPopup.module.css'
import AddIcon from '@mui/icons-material/Add';
import PillContainer from '../../../../PillContainer/PillContainer';
import { useUpdateOrgDataMutation } from '../../../../../app/services/admin';
import { useSelector } from 'react-redux';
import LoaderIcon from '../../../../LoaderIcon/LoaderIcon';
import { triggerToast } from '../../../../../utils/toastController';
import { fetchAndSetOrgDetails } from '../../../../../utils/orgService';
import strLengthConfig from '../../../../../utils/strLengthConfig.json'

const StatusEditPopUp = ({fetchOrgDetails, status, trigger, setTrigger, toEdit}) => {

    const [isAddButtonVisisble, setIsAddButtonVisible] = useState(false)
    const [inputText, setInputText] = useState("")
    const [tempStatus, setTempStatus] = useState([])
    const [updateOrgRequest, ] = useUpdateOrgDataMutation()
    const {organization} = useSelector((state)=>state.organization)
    const [isLoading, setIsLoading] = useState(false)
    const [isMobileScreen, setIsMobileScreen] = useState(false)
    

    useEffect(()=>{
        if(status){
            setTempStatus(status)
        }
    },[status])


    const addToStatus = (value) => {
        if(!value.includes(" "))
            setTempStatus((prev)=>[...prev,value]);
        setInputText("")
    }

    const handleTextChange = (e) => {
        if(!e.target.value.trim().includes(" ") && e.target.value.length <= strLengthConfig.status)
            setInputText(e.target.value);
        setIsAddButtonVisible(e.target.value.trim() !== "" && !e.target.value.trim().includes(" "))
    }

    const handleKeyDown = (e) =>{
        if(e.key === "Enter"){
            addToStatus(inputText)
            setInputText("")
            e.target.value = ""
        }
    }

    const handleAddButtonClick = (e) => {
        addToStatus(inputText)
    }

    const removeLeadStatus = (item,idx) => {
        setTempStatus((prev)=>prev.filter((val,_idx)=>_idx !== idx))
    }


    const handleSaveClick = (e) => {
        setIsLoading(true)
        updateOrgRequest({
            op : "update",
            orgId : organization._id,
            fields : {
                ...(toEdit==="lead-status") && {"settings.leadStatus" : tempStatus},
                ...(toEdit==="tutor-status") && {"settings.tutorStatus" : tempStatus},
            }
        }).then((res)=>{
            if(!res.error){
                fetchAndSetOrgDetails(organization._id)
                triggerToast("Status updated successfully", "success", {autoClose : 3000})
                setTrigger(false)
            } else {
                triggerToast("Oops some error occured", "error", {autoClose : 3000})
            }
            setIsLoading(false)
        })
    }

    return (
        <PopupContainer trigger={trigger} setTrigger={setTrigger} customCloseButton={true}>
            <div className={styles.container}>
                <div className={styles.headingContainer}>
                    <div>
                        {toEdit === "lead-status" ? "Lead Status" : "Tutor Status"}
                    </div>
                    <button onClick={(e) => setTrigger(false)}><CrossIcon /></button>
                </div>
                <div className={styles.inputGroup}>
                    <label htmlFor='lead_status_ip'>{toEdit === "lead-status" ? "Lead Status (Parent/Student)" : "Tutor Status"}</label>
                    <div className={styles.insertInput}>
                        <input type="text" id="lead_status_ip" placeholder='Enter status and press enter' onChange={(e)=>handleTextChange(e)} onKeyDown={(e)=>handleKeyDown(e)} value={inputText}/>
                        {
                            isAddButtonVisisble ? <button onClick={(e)=>handleAddButtonClick()}><AddIcon /></button> : <></>
                        }
                    </div>
                </div>
                <PillContainer customClass={styles.pillContainerClass} items={tempStatus.map((val)=>({displayTitle : val}))} withoutBorder={true} isCrossButtonVisible={true} onCrossButtonClick={removeLeadStatus}/>
                <p className={styles.note}>
                    <span>Note : </span>
                    Only alphabets and numbers are allowed in status tags. No spaces allowed. Existing leads using any deleted tags will revert to empty state.
                </p>
                <div className={styles.actionArea}>
                    <button className={styles.cancelButton} onClick={(e)=>setTrigger(false)}>Cancel</button>
                    <button className={styles.saveButton} disabled={isLoading} onClick={(e)=>handleSaveClick()}>
                        {isLoading ? <LoaderIcon /> : "Save"}
                    </button>
                </div>
            </div>
        </PopupContainer>
    )
}


export default StatusEditPopUp
