import React, { useState, useEffect } from "react";
import search from "./search.svg"


const getInitials = (name) => {
  if (!name) return ""; // Return an empty string if name is undefined or null

  const nameParts = name.split(" ");
  let initials = "";

  if (nameParts.length > 1) {
    initials = nameParts[0][0] + nameParts[1][0];
  } else if (nameParts.length === 1) {
    initials = nameParts[0][0];
  }

  return initials.toUpperCase();
};

const UserDropdown = ({ users, onUserSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    setFilteredUsers(
      users.filter(
        (user) =>
          user.value.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, users]);

  return (
    <div className="w-full relative grid gap-[6px]">
      <div className="w-full flex border border-secondary-10 h-[32px] rounded-[6px] font-[400] leading-[12px] text-secondary-80 gap-[4px] items-center">
        <img src={search} alt="search" className="ml-[10px]"/>
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-[90%] px-[8px] py-[12px] text-[12px] h-[32px] outline-none border-t-secondary-10 border-b-secondary-10 border-t-[1px] border-b-[1px]"
        />
      </div>

      <div className="max-h-64 grid gap-[6px] overflow-y-auto bg-white rounded">
        {filteredUsers.map((user, index) => (
          <div
            key={index}
            className="flex gap-[6px] p-[4px] items-center cursor-pointer hover:bg-gray-100"
            onClick={() => onUserSelect(user)}
          >
            <div className="flex items-center justify-center bg-blue-100 h-[24px] w-[24px] border border-blue-70 rounded-full text-xs font-medium">
              {getInitials(user.value)}
            </div>
            <div className="ml-2">
              <div className="font-[400] text-[12px] leading-[12px] text-secondary-80">
                {user.value}
              </div>
              <div className="font-[400] text-[10px] leading-[10px] text-secondary-60">
                {user.email}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserDropdown;
