import CustomHeader from "../CustomHeader/CustomHeader"


function getProps(props) {

	return {
							...props,
							showDaysOutsideCurrentMonth:true,
							disableHighlightToday:true,
							views:['month', 'day'],
							sx:{
								bgcolor: "white",
								height: "183px",
								width: "190px",
								fontFamily: "Lexend",
								padding: "0px",
								"& .MuiDayCalendar-weekDayLabel": {
									margin: "0px",
									fontFamily: "Lexend",
									height: "15px",
									padding: "0px",
									color: "#7C859C",
									fontWeight: 400,
									fontSize: "10px",
								},
								"& .MuiDayCalendar-header": {
									paddingBottom: "10px",
								},
								"& .MuiMonthCalendar-root" : {
									width : "190px",
									height : "151px",
									padding : "0px"
								}
							},
							slots:{
								calendarHeader : CustomHeader
							},
							slotProps:{
								monthButton: {
									sx: {
										fontWeight : 400,
										fontFamily : "Lexend",
										fontSize : "14px",
										color : "rgba(81, 92, 123, 1)",
										margin : "0px",
										height : "32px",
										width : "58px",
										"&.Mui-selected" : {
											fontWeight : 600,
											bgcolor : "white",
											color : "#0671E0",
											textDecoration : "underline",
											textUnderlineOffset : "5px",
											lineHeight : "21px",
											"&:hover" : {
												fontWeight : 600,
												bgcolor : "white",
												color : "#0671E0",
												textDecoration : "underline",
												lineHeight : "21px",
											},
											"&:focus":{
												fontWeight : 600,
												bgcolor : "white",
												color : "#0671E0",
												textDecoration : "underline",
												lineHeight : "21px",
											},
										}
									},
								},
								calendarHeader: {
									...props,
									sx: {
										padding: "0px 0px 0px 6px",
										margin: "0px",
										minHeight: "fit-content",
										"& .MuiPickersCalendarHeader-label": {
											fontSize: "12px",
											fontWeight: 700,
											color: "#25335A",
											margin: "0px",
											fontFamily: "Lexend",
										},
										"& .MuiSvgIcon-root": {
											height: "1rem",
											width: "1rem",
											color: "#515C7B",
										},
										"& .MuiPickersCalendarHeader-switchViewButton": {
											padding: "0px",
											color: "#25335A",
										},
										"& .MuiPickersCalendarHeader-switchViewIcon": {
											color: "#25335A",
										},
									},
								},
								day: {
									disableHighlightToday : false,
									today : true,
									sx: {
										height: "22px",
										width: "22px",
										padding: "6px 2px",
										fontSize: "10px",
										fontFamily: "Lexend",
										fontWeight: 400,
										"&:hover" : {
											bgcolor : "rgba(233, 235, 239, 1)"
										},
										"&:focus":{
											bgcolor : "rgba(6, 113, 224, 0.1)",
											color : "rgba(6, 113, 224, 1)"
										},
										"&.MuiPickersDay-today": {
											bgcolor: "#0671E0",
											border : "none",
											color: "white",
											"&:focus":{
												bgcolor: "#0671E0",
												border : "none",
												color: "white",
											},
											"&:hover":{
												bgcolor: "#0671E0",
												border : "none",
												color: "white",
											},
										},
										"&.Mui-selected" :{
											bgcolor : "rgba(6, 113, 224, 0.1)",
											color : "rgba(6, 113, 224, 1)",
											"&:focus":{
												bgcolor : "rgba(6, 113, 224, 0.1)",
												color : "rgba(6, 113, 224, 1)"
											},
											"&:hover":{
												bgcolor : "rgba(6, 113, 224, 0.1)",
												color : "rgba(6, 113, 224, 1)"
											},
										},
									},
								},
							}

	}
}

export {
	getProps
}
