import React, { useEffect, useState } from "react";
import CrossIcon from "../../../components/CrossIcon/CrossIcon";
import InputPasswordV2 from "../../../components/InputPasswordV2/InputPasswordV2";
import { toast } from "react-toastify";
import { useUpdatePasswordMutation } from "../../../app/services/admin";
import LoaderIcon from "../../../components/LoaderIcon/LoaderIcon";

const UpdatePassword = ({ setOpenPasswordEditPopup, fetchAndSetPersonalDetails }) => {
  const [passwordValidityChecklist, setPasswordValidityChecklist] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [mainPassword, setMainPassword] = useState("");
  const [secondaryPassword, setSecondaryPassword] = useState("");
  const [oldPassword, setOldPAssword] = useState("");
  const [isPasswordMatching, setIsPasswordMatching] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [updatePassword, updatePasswordResp] = useUpdatePasswordMutation();

  const handlePasswordChange = (e) => {
    switch (e.target.id) {
      case "mainPassword":
        setMainPassword(e.target.value);
        validatePassword(e.target.value);
        break;
      case "secondaryPassword":
        setSecondaryPassword(e.target.value);
        break;
      case "oldPassword":
        setOldPAssword(e.target.value);
        break;
    }
  };

  function validatePassword(password) {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    const containNumbers = /[0-9]/;
    const specialCharacterRegex = /[^A-Za-z]/;
    let tempList = passwordValidityChecklist;

    tempList[0] = password.length >= 8;
    tempList[1] = upperCaseRegex.test(password);
    tempList[2] = lowerCaseRegex.test(password);
    tempList[3] = specialCharacterRegex.test(password);
    tempList[4] = containNumbers.test(password);

    setPasswordValidityChecklist(tempList);
  }

  const updateNewPassword = async () => {
    setIsLoading(true);
    try {
      if (!isPasswordMatching) {
        toast.warn("New Pasword and Confirm Password, didn't match.");
      } else {
        validatePassword(mainPassword);

        if (passwordValidityChecklist.includes(false))
          toast.warn(
            "Password Should contain minimum 8 letters, including 1 Lowercase, 1 Uppercase, 1 Number & 1 Special Character."
          );
        else {
          const updatePasswordResp = await updatePassword({
            currentPassword: oldPassword,
            newPassword: mainPassword,
          });
          if (updatePasswordResp.error) {
            toast.warn(updatePasswordResp.error.data.message);
          } else {
            toast.success("Password updated Successfully !");
            setOpenPasswordEditPopup(false);
            fetchAndSetPersonalDetails()
          }
        }
      }
    } catch (error) {
      toast.warn("Some unkown error occured");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsPasswordMatching(mainPassword === secondaryPassword);
  }, [mainPassword, secondaryPassword]);

  return (
    <div className="w-full h-full bg-secondary/70 fixed top-0 left-0">
      <div className=" flex flex-col gap-9 m-auto bg-white w-[90%] lg:w-[468px] relative top-1/2 -translate-y-1/2 p-6 rounded-xl">
        <div>
          <div className="flex justify-between items-center py-[2px]">
            <h1 className="font-medium text-2xl leading-[36px] text-blue-90">
              Update Password
            </h1>
            <div
              className="cursor-pointer"
              onClick={(e) => setOpenPasswordEditPopup(false)}
            >
              <CrossIcon />
            </div>
          </div>
          <div className="border-b border-blue-50 pt-2"></div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-1 gap-4 ">
            <div>
              <label
                className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
                htmlFor="last_name"
              >
                Current password
              </label>
              <InputPasswordV2
                value={oldPassword}
                onChange={handlePasswordChange}
                id="oldPassword"
              />
            </div>
            <div>
              <label
                className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
                htmlFor="first_name"
              >
                New password
              </label>
              <InputPasswordV2
                value={mainPassword}
                id="mainPassword"
                onChange={handlePasswordChange}
                noMatch={secondaryPassword && !isPasswordMatching}
              />
            </div>
            <div>
              <label
                className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
                htmlFor="last_name"
              >
                Confirm new password
              </label>
              <InputPasswordV2
                value={secondaryPassword}
                id="secondaryPassword"
                onChange={handlePasswordChange}
                noMatch={secondaryPassword && !isPasswordMatching}
              />
            </div>
          </div>
        </div>
        <div>
          <div className=" w-full h-12 grid grid-cols-2 gap-3 float-right">
            <button
              disabled={isLoading}
              className="border-[2px] w-full h-full text-center outline-none box-border border-secondary-60 rounded-md px-6 py-[8px] font-semibold text-base leading-[20px] text-secondary-80"
              onClick={() => {
                setOldPAssword("");
                setMainPassword("");
                setOpenPasswordEditPopup(false); 
                setSecondaryPassword("");
              }}
            >
              Cancel
            </button>
            {!isLoading ? (
              <button
                className="rounded-md w-full h-full text-center px-6 py-[10px] font-semibold text-base leading-[20px] text-white bg-blue-70 hover:bg-blue-80"
                onClick={updateNewPassword}
              >
                Update
              </button>
            ) : (
              <div className="w-full bg-blue-70 text-white rounded-md flex justify-center items-center">
                <LoaderIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;