import TabSelector from "../TabSelector/TabSelector"
import styles from "./EventTopBar.module.css"


function MobileEventTopBar (props) {
	
	return (
		<div className={styles.mobileContainer}>
			<TabSelector {...props}/> 
		</div>
	)

}

export default MobileEventTopBar
