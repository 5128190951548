const serviceColours = [
	"#EE4C99",
	"#2F9AD7",
	"#FA7A3D",
	"#6CBC3E",
	"#EE5555",
	"#E0C244",
	"#177F45",
	"#4054B2",
	"#9855C6",
	"#3BB57A",
]

function getColourMappingForServices(services){
	
	const serviceColourMapping = {}
	
	services?.forEach((val,idx)=>{
		serviceColourMapping[val.service] = serviceColours[idx % serviceColours.length]
	})

	return serviceColourMapping

}

export { 
	serviceColours,
	getColourMappingForServices
}
