import React, { useEffect, useRef, useState } from 'react'
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import GroupDropdown from './GroupDropdown';
import StudentList from '../StudentList/StudentList';

const GroupSelect = ({groupList, selectedGroup, setSelectedGroup, studentList, studentStatusMapping, setStudentStatusMapping, selectedGroupStudents, setSelectedGroupStudents}) => {
    const [isDropDownOpen, setIsDropdownOpen] = useState(false);
    const [showSelectList, setShowSelectList] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const groupSelectorRef = useRef();
    const viewGroupStudentListRef = useRef();

    useOutsideAlerter(groupSelectorRef, () => setIsDropdownOpen(false));
    useOutsideAlerter(viewGroupStudentListRef, () => setShowSelectList(false));


    useEffect(() => {
      if (selectedGroup?.trim() !== "") {
        setSelectedStudents([]); 
    
        const findGroup = groupList?.find((group) => group._id === selectedGroup);
    
        if (findGroup) {
          const newSelectedStudents = findGroup.studentIds.map(element => {
            return studentList.find((stud) => stud._id === element);
          }).filter(Boolean);
    
          setSelectedStudents(newSelectedStudents);
		  setSelectedGroupStudents(newSelectedStudents)
        }
      }
    }, [selectedGroup, groupList, studentList]);

  
    const getGroupName = () => {
      const group = groupList && groupList.find((group) => group._id === selectedGroup);
      return group ? group.name : "Select Group*";
    };
  
    return (
      <main ref={groupSelectorRef} className="h-full w-[100%] relative ">
        <div className="relative flex justify-between items-center rounded-[6px] bg-[#F6F9FA] cursor-pointer">
          <section
            className="flex-1 w-full flex py-[8px] px-[12px] items-center justify-between gap-[2px]"
            onClick={() => setIsDropdownOpen(!isDropDownOpen)}
          >
            <p className= {`w-full flex-1 text-[14px] leading-[21px] ${selectedGroup ? " text-[#25335A]" : "text-secondary-60"}`}>
              {getGroupName()}
            </p>
            <img src="/images/down-arrow.svg" alt="" className=" w-[16px] h-[16px] md:w-[12px] md:h-[12px] " />
          </section>

  
          {isDropDownOpen && (
            <GroupDropdown
              setIsDropdownOpen={setIsDropdownOpen}
              groups={groupList}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          )}

        </div>

        <div ref={viewGroupStudentListRef} className="   w-fit  sticky top-[110%] left-[100%] !z-5000 ">

          {selectedStudents.length > 0 && selectedGroup && !isDropDownOpen  && <button className="  outline-none text-[14px] text-[#0671E0] cursor-pointer w-fit"  onClick={() => setShowSelectList(!showSelectList)}> View All</button>
          }

            {showSelectList && (
              <>
                <div  className=" absolute top-[20%] right-[5%] md:left-[50%] !z-5000 ">
                  <div
                    
                    className=" rounded-[8px] w-[285px] h-[320px] !z-5000"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <StudentList
                      selectedStudents={selectedStudents}
                      studentData={studentList}
                      studentStatusMapping={studentStatusMapping}
                      setStudentStatusMapping={setStudentStatusMapping}
                    />
                  </div>
                </div>
              </>
            )}
        </div>

      </main>
    );
}

export default GroupSelect
