import { useEffect } from 'react'
import styles from './PriceSummary.module.css'
import { useSelector } from 'react-redux'
import LoaderIcon from '../../LoaderIcon/LoaderIcon'
import { addDaysToDate, getFormattedDateV2 } from '../../../utils/dateUtils'

function getFreeTrialEndDate () {
    const todaysDate = new Date()
    const todaysDateString = todaysDate.toISOString()
    const freeTrialEndDate = new Date(addDaysToDate(todaysDateString,14))
    let freeTrialEndDateString = freeTrialEndDate.toString()
    freeTrialEndDateString = getFormattedDateV2(freeTrialEndDateString,"dd mm, yyyy")
    return freeTrialEndDateString

}


const PriceItem = ({ details, isDiscount, type, requiresFreeTrial }) => {

    const { subscriptionPopUpOperationMode } = useSelector((state) => state.subscriptionOperation)

    if (type === "final-amnt") {
        return (
            <div className={styles.finalAmount}>
                <h3 className={styles.heading}>Amount Due (today)</h3>
                <div className={styles.price}>
                    <span className={styles.current} >{details.currentPrice}</span>
                    {details.discountedPrice ? " " : ""}
                    <span className={styles.discounted}>{details.discountedPrice}</span>
                    {details.billingPeriod && <>/ {details.billingPeriod}</> }
                </div>
                <div className={styles.helper}>{subscriptionPopUpOperationMode === "new-subscription" ? "14-day Free Trial" : details.headingHelper}</div>
                <div className={styles.priceHelper}>{details.priceHelper}</div>
            </div>
        )
    } else if (type === "normal") {
        return (
            <div className={styles.priceItem}>
                <h3 className={`${styles.heading} ${isDiscount ? styles.discount : ""}`}>{details.heading}</h3>
                <div className={styles.dottedLine}>&nbsp;</div>
                <div className={`${styles.price}`}>
                    <span className={isDiscount ? styles.discount : ""}>{details.price}</span>{details.priceHelper}
                </div>
            </div>
        )
    } else if (type === "sub-cost") {
        return (
            <div className={styles.finalAmount} style={{borderTop : "1px solid #E9EBEF", paddingTop : "12px"}}>
                <h3 className={styles.heading} style={{ color: "#515C7B" }}>Subscription Cost</h3>
                <div className={styles.price}>
                    <span className={styles.current}>{details.currentPrice}</span>
                    {details.discountedPrice ? " " : ""}
                    <span className={styles.discounted}>{details.discountedPrice}</span>
                    / {details.billingPeriod}
                </div>
                <div className={styles.helper}>{subscriptionPopUpOperationMode === "new-subscription" || (subscriptionPopUpOperationMode === "update-from-free" && requiresFreeTrial )? `You will be charged on ${getFreeTrialEndDate()}` : `You will be charged on ${details.invoiceDate}`}</div>
                <div className={styles.priceHelper}>{details.priceHelper}</div>
            </div>
        )
    }
        
}


const PriceSummary = ({ priceDetails, isSelectedFreePlan, isPriceLoading }) => {
    useEffect(() => {
        console.log("PD", priceDetails)
    }, [priceDetails])

    if (isPriceLoading) {
        return (
            <div className='flex w-full items-center justify-center h-[107px]'>
                <LoaderIcon />
            </div>
        )
    }

    if (isSelectedFreePlan) {
        return <></>
    }
    if (priceDetails) {
        return (
            <div className={styles.container}>
                {
                    priceDetails?.normalDetails?.map((details) => (<PriceItem details={details} isDiscount={details?.isDiscount} type={"normal"} />))
                }
                <PriceItem details={priceDetails?.subCostDetails} type={"sub-cost"} />
                <PriceItem details={priceDetails?.finalAmountDetails} type={"final-amnt"} />
            </div>
        )
    }

}

export default PriceSummary
