import React, { useEffect } from "react";
import CrossIcon from "../../assets/Modal/cross.svg";
import primaryCancelIcon from "../../assets/icons/Groupcancel bt.svg";
import styles from "./modal.module.css";
import cancel_btn from "../../assets/icons/cancelBtn.svg";

import { useRef } from "react";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import SecondaryButton from "../Buttons/SecondaryButton";
import Loader from "../Loader";
import CancelBtn from '../../assets/icons/cancel_btn_red.svg'

export default function AssignmentTestModal({
  title,
  closeIconClass,
  titleClassName,
  modalSize,
  canceliconvisible,
  titleclass,
  body,
  cancelBtn,
  cancelBtnClassName,
  primaryBtn,
  titleInvite,
  handleClose,
  CrossIcon,
  classname,
  SaveUser,
  primaryCancel,
  otherBt,
  cancelBtnStyle,
  underline,
  crossBtn,
  crossBtn2,
  toEdit,
  alignBtn,
  wrapperClassName,
  btnClassName,
  buttonParentClassName,
  cancelIconState,
  parentClass,
  topClass,
  crossIconDisplay,
  closebuttonClass,
  lineMB,
  underlineMargin,
  showcloseIcon,
  crossBtnClassName,
  bodyScrollbarHidden
}) {
  const selectRef = useRef();
  // console.log(selectRef)
  useOutsideAlerter(selectRef, () => {
    selectRef.current = null;
    handleClose && handleClose();
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Use smooth scrolling behavior
    });
    if(bodyScrollbarHidden){
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const screenWidth = document.body.clientWidth;
  const scale = screenWidth > 0 ? screenWidth / 1920 : 0;
  return (
    <>
      {
        <div className={`${styles.modalContainer}  ${topClass ?? ""}`}>
          <div className={`${parentClass ?? ""}`}>
            <div
              ref={handleClose ? selectRef : null}
              className={`${
                modalSize ? modalSize : "w-full"
              } bg-white pt-[20px] pb-[33.34px] px-[33.33px]  rounded-lg relative ${
                classname ? classname : ""
              }`}
            >
              {/* cancel buttons */}
              {console.log(closeIconClass)}
   {   showcloseIcon &&        <div
                onClick={handleClose}
                className={`absolute top-[30px] right-[50px] cursor-pointer ${
                  crossBtn2 === true ? "hidden" : ""
                }
                            ${crossBtnClassName}
                `}
              >
                <span className={`cursor-pointer `}>
                  <img src={cancel_btn} alt="cancel_btn" />
                </span>
              </div>
}
              {/* title */}
              <div className={wrapperClassName ? wrapperClassName : ""}>
                <p
                  className={`font-semibold text-xl text-[21px] text-left text-[#26435F] 
               ${titleClassName ? titleClassName : "mb-[18px]"} `}
                >
                 <div className="flex justify-between items-center">
                 <p className={`${title==="Bulk Upload"?'w-[45vw]':'w-full'} ${titleclass} `}>{title}</p>
                 {canceliconvisible==false?null: <img src={CancelBtn} className={`w-[28px] h-[28px] cursor-pointer ${closeIconClass}`} onClick={handleClose}/>}
                 </div>
                  {titleInvite && "Are You Sure You Want to Invite "}
                  {titleInvite && (
                    <span className="text-[#FFA28D]">{titleInvite}</span>
                  )}

									{titleInvite && " Users To Join Evallo?"}
								</p>
								

                {/*  title underline */}
                {underline ? (
                  ""
                ) : (
                  <div
                    className={`h-[1.33px] w-full bg-[rgba(0,0,0,0.20)] mb-[20px] ${
                      underlineMargin ? underlineMargin : "mb-[20px]"
                    }`}
                  ></div>
                )}

                {/*  modal body */}
                <div className="w-full">{body}</div>

                {/* cancel */}
                <div className={`flex justify-center mt-8 ${buttonParentClassName}`}>
                  {cancelBtn && (
                    <SecondaryButton
                      onClick={handleClose}
                      children="Cancel"
                      className={`py-2   ${cancelBtnClassName}`}
                      type="button"
                      
                    />
                  )}
                  {SaveUser && SaveUser}
                  {otherBt ? otherBt : <></>}
                  {primaryBtn && (
                    <button
                      onClick={primaryBtn.onClick ? primaryBtn.onClick : null}
                      form={primaryBtn.form ? primaryBtn.form : null}
                      type={primaryBtn.type ? primaryBtn.type : "button"}
                      className={`w-[120px ${
                        primaryBtn.bgDanger ? "bg-[#FFA28D]" : "bg-[#FFA28D]"
                      } relative disabled:opacity-75 rounded-lg font-medium text-white cursor-pointer   ${
                        primaryBtn.className ? primaryBtn.className : ""
                      }`}
                      disabled={
                        primaryBtn?.loading === true
                          ? true
                          : primaryBtn.disabled
                      }
                      loading={primaryBtn.loading} 
                    >
                      <div className="flex w-full items-center  gap-2 justify-center">
                        <p className="text-center pt-3 pb-3 pr-1">{primaryBtn.text}</p>
                        {primaryBtn.icon && (
                          <p>
                            {primaryBtn.icon && primaryBtn?.loading === false
                              ? primaryBtn.icon
                              : ""} 
                          </p>
                        )}
                      </div>

                      {primaryBtn !== undefined &&
                        primaryBtn?.loading === true && <Loader />}
                    </button>
                  )}
                </div>

                {/* {crossBtn ? (
                  ""
                ) : (
                  <button
                    className={`absolute top-0 right-0 py-[28px] mr-[36px]`}
                    style={cancelBtnStyle}
                    onClick={handleClose}
                  >
                    <div className="w-full h-full flex justify-center items-center relative ">
                      <img
                        className={`${cancelIconState ? "block" : "hidden"}`}
                        src={
                          primaryCancel ? primaryCancelIcon : primaryCancelIcon
                        }
                        alt="close-btn"
                        onClick={handleClose}
                      />
                    </div>
                  </button>
                )} */}
              </div>
            </div>
            {/* <div className={styles.modalOverlay}></div> */}
          </div>
        </div>
      }
    </>
  );
}
