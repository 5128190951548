import React, { useEffect, useRef, useState } from 'react'
import TimeSelector from './TimeSelector'
import TimeZonePopover from '../../TimeZonePopOver/TimeZonePopover'
import { useSelector } from 'react-redux';
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector';
import DateRangeButton from '../../DateRangeButton/DateRangeButton';
import LoaderIcon from '../../LoaderIcon/LoaderIcon';




const DateTImezoneSelector = ({submitDisable, submitData, setSubmitData, requestTrigger, isSubmitLoading}) => {

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [rangePickerVisible, setRangePickerVisible] = useState(false);
  const [timeDropdownTriggers, setTimeDropdownTriggers] = useState({
    startTimeTrigger: false,
    endTimeTrigger: false,
  });
  const dateRangeRef = useRef(null);
  const timeZoneRef = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);



    const handleClickOutside = (event) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
            setRangePickerVisible(false);
        }
        if(timeZoneRef.current && !timeZoneRef.current.contains(event.target)){
          setIsPopoverVisible(false);
        }
        if(startTimeRef.current && !startTimeRef.current.contains(event.target)){
          setTimeDropdownTriggers((prev)=>({...prev , startTimeTrigger:false}));
        }
        if(endTimeRef.current && !endTimeRef.current.contains(event.target)){
          setTimeDropdownTriggers((prev)=>({...prev , endTimeTrigger:false}));
        }
    };

    useEffect(() => {
        if (rangePickerVisible || isPopoverVisible || timeDropdownTriggers.startTimeTrigger || timeDropdownTriggers.endTimeTrigger) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [rangePickerVisible,isPopoverVisible, timeDropdownTriggers]);

  const togglePopover = () => {
    setIsPopoverVisible((prev) => !prev);
    setIsDateFormatrVisible(false);
  };
  const closePopover = () => {
    setIsPopoverVisible(false);
    setIsDateFormatrVisible(false);
  };

  const handleTimeZoneSelection = (timezone) => {
    setSubmitData((prev)=>({...prev, timeZone:timezone}));
    closePopover();
  };


  return (
    <main className=' flex flex-col w-full  gap-[12px]'>

        <h3 className='text-[14px] text-secondary-80 leading-[21px] font-normal '>Enter a date and time to find available users.</h3>


        <div  className=' flex  gap-[17px] flex-col md:flex-row w-full  justify-between items-center ' >

            <section ref={dateRangeRef} className='w-full bg-[#F6F9FA] md:bg-white relative z-30 ' >
              <DateRangeButton  startDate={submitData.startDate} endDate={submitData.endDate} onClick={()=>setRangePickerVisible(!rangePickerVisible)} />

              { rangePickerVisible && <DateRangeSelector startDate={submitData.startDate} endDate={submitData.endDate} setStartDate={(val)=>{setSubmitData((prev)=>({...prev, startDate:val}))}} setEndDate={(val)=>{setSubmitData((prev)=>({...prev, endDate:val}))}} />}

            </section>

            <section className=' flex  gap-[12px] md:gap-[8px] w-full  items-center justify-start'>
              <div ref={startTimeRef} className='w-full'>
                <TimeSelector id={"startTime"} submitData={submitData} setSubmitData={setSubmitData} timeDropdownTriggers={timeDropdownTriggers} setTimeDropdownTriggers={setTimeDropdownTriggers} />
              </div>
                <span>-</span>
                <div ref={endTimeRef} className='w-full'>
                <TimeSelector id={"endTime"} submitData={submitData} setSubmitData={setSubmitData} timeDropdownTriggers={timeDropdownTriggers} setTimeDropdownTriggers={setTimeDropdownTriggers}/>
                </div>
            </section>

        </div>


        <div className=' flex w-full gap-[8px] md:gap-[12px]  '>

            <div ref={timeZoneRef} className="flex bg-[#F6F9FA] rounded-[4px] px-[12px] py-[4px] justify-between items-center flex-1 cursor-pointer relative" onClick={togglePopover}>
                  <p className="text-[12px] leading-[18px]   text-secondary-80 font-normal cursor-pointer ">{submitData.timeZone} </p>
                  <img
                    className="img"
                    src="/images/chevron-down.svg"
                    alt="Dropdown Icon"
                  />
                  <TimeZonePopover
                    isVisible={isPopoverVisible}
                    onClose={closePopover}
                    onSelectTimeZone={handleTimeZoneSelection}
                    selectedTimeZone={submitData.timeZone}
                  />
            </div>
          
            

            <button disabled={submitDisable || isSubmitLoading }  className=' bg-[#0671E0] text-white min-w-[72.2px]  max-h-[29.5px] flex items-center justify-center px-[12px] py-[6px] rounded-[4px]  text-[14px] leading-[17.5px] font-normal disabled:bg-[#0671E033] ' onClick={requestTrigger} >{isSubmitLoading ?  <LoaderIcon/> : "Submit"  }</button>

        </div>

    </main>
  )
}

export default DateTImezoneSelector