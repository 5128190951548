import React from "react";
import styles from "./MobileReferralCard.module.css";

const MobileReferralCard = ({
  item,
  filteredTests,
  handleToggle,
  handleVectorClick,
}) => {
  const printTestName = (itemId) => {
    const test = filteredTests.find((data) => data._id === itemId);
    return test ? test.value : "";
  };

  return (
    <div className={styles.referralCodeMobile}>
      <div className={styles.frame}>
        <div className={styles.div}>
          <div className={styles.christmas}>{item.code}</div>
        </div>
        <div className={styles.div}>
          <div className={styles.toggle}>
            {/* <div className={styles.knob}></div> */}
            {!item.pause ? (
              <img
                className="toggle"
                src="/images/toggle_on.svg"
                onClick={() => handleToggle(item)}
                alt="Toggle On"
              />
            ) : (
              <img
                className="knob-wrapper"
                src="/images/toggle_off.svg"
                onClick={() => handleToggle(item)}
                alt="Toggle Off"
              />
            )}
          </div>
          <img className={styles.line} src="img/line-340.svg" alt="line" />
          <img
            className={styles.editIcon}
            src="/images/Vector.svg"
            alt="edit"
            onClick={() => handleVectorClick(item)}
          />
        </div>
      </div>
      <div className={styles.frame2}>
        <div className={styles.frame3}>
          <div className={styles.textWrapper}>Assignments</div>
          <div className={styles.frame4}>
            {item.tests.slice(0, 4).map((data, indexx) => (
              <div key={indexx} className={styles.referralTag}>
                <div className={styles.testABWrapper}>
                  <p className={styles.testAB}>
                    {filteredTests.find((test) => test._id === data)?.value}
                  </p>
                </div>
              </div>
            ))}
            {item.tests.length > 4 && (
              <div className={styles.textWrapper2}>
                + {item.tests.length - 3} more
              </div>
            )}
          </div>
        </div>
        <img
          className={styles.img}
          src="/images/horizontal_line.png"
          alt="line"
        />
        <div className={styles.frame5}>
          <div className={styles.frame6}>
            <img className={styles.img2} src="/images/user-2.svg" alt="user" />
            <div className={styles.textWrapper3}>
              {" "}
              {item.assignedUser.owner}
            </div>
          </div>
          <div className={styles.frame7}>
            <img className={styles.img2} src="/images/chart.svg" alt="arrow" />
            <div className={styles.textWrapper4}>
              {" "}
              Used {item.usageDetails.length} times
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileReferralCard;
