import { useEffect } from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { useSearchParams } from "react-router-dom"
import { useGoogleLoginMutation } from "../../app/services/auth"
import { toast } from "react-toastify"

function GLogin(props){

	const [googleLogin, ] = useGoogleLoginMutation()
	const [searchParams, setSearchParams] = useSearchParams()


    const onGoogleSuccess = async (googleToken) => {
        try {
             googleLogin({
                authCode : googleToken.code
             }).then((res)=>{
                if(res.error){
                    if(res.error?.data?.message?.split(":").pop()==="TUTOR_ACCOUNT_INACTIVE"){
                        toast.warn("Your tutoring license isn’t active!",{autoClose : false})
                        setIsLoading(false)
							const reqdJSON = {
								isRedirect : true,
								isError : true,
								errorType : "TUT_INACTIVE",
								errorMessage : "Your tutoring license isn’t active!" 
							}
							window.top.postMessage(reqdJSON,"*")
							return
						}
                    console.log(res.error?.data?.message)
                    toast.error(res?.error?.data?.message)
                    if(res.error?.data?.message.startsWith("No main account") ) {
                        //navigate('/signup')
                    } else if (res.error?.data?.message.startsWith("Email not found")){
                        //Do nothing
                    }

                } else {
					const evalloToken = res.data.data.token
					const role =  res.data.data.role
					const userId =  res.data.data.userId
					console.log(`evToken${evalloToken}`,`role${role}`, `userId${userId}`)
					const reqdJSON = {
						evalloToken,
						role,
						userId,
						isRedirect : true
					}
					window.top.postMessage(reqdJSON,"*")
                }

             })
        }catch(err){
             console.log(err)
        }
     }

	const onGoogleFailure = (err) => {
		console.log("ERR",err)
		console.error(err)	
	}

     const googleSignIn = useGoogleLogin({
        onSuccess : onGoogleSuccess,
        onFailure : onGoogleFailure,
		flow : "auth-code",
		redirect_uri : "postmessage",
        clientId : "316191323811-693kdt97bn7960b9gdtscnr8e8u23r26.apps.googleusercontent.com",
    })

	useEffect(()=>{
		const redirectService = searchParams.get("redirect")
		const todo = searchParams.get("do")
		console.log("rds", redirectService)
		if(redirectService === "google" && parseInt(todo) > 0){
			console.log("IN RDS", redirectService)
			setTimeout(()=>{
			 googleSignIn()
			},500)
			return
		}
	},[searchParams.get("redirect"), searchParams.get("do")])

	return (
		<div>Test Iframe</div>
	)

}


export default GLogin
