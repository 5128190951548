function BookedMeetingEditor(props){

	return (
		<div className="flex flex-col w-full items-start justify-start gap-[12px]" >
			<div className="w-full border-b-[1px] border-b-[secondary-10] border-b-solid flex item-start justify-start h-[28px]">
				<div className="relative font-[500] text-[14px] leading-[21px] text-secondary">
					Description
					<div className="absolute min-w-full min-h-[4px] bg-blue-70 rounded-[2px_2px_0px_0px] bottom-[0px] left-[0px]" />
				</div>
			</div>
			<div className="font-[400] text-[14px] leading-[21px] text-secondary-80">
				{props.infoWork}
			</div>
		</div>
	)

}


export default BookedMeetingEditor
