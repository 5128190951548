import { useSearchParams } from 'react-router-dom'
import InfoIcon from '../../../components/ProductIcons/InfoIcon'
import TabSelector from '../../../components/TabSelector/TabSelector'
import SelectedTab from '../SelectedTab'
import Tooltip from '../../../components/Tooltip/Tooltip'
import { getTooltipStyleClass } from '../../../utils/commonlyUsedTooltipStyles'
import TooltipIcon from '../../../assets/icons/SettingsIcon/TooltipIcon'
import { useEffect, useState } from 'react'
import styles from "./CalendarSettings.module.css"

const tabs = ["Session Tags & Reconciliation","Permissions","Integrations"]

const CalendarSettings = (props) => {
    
    const [selectedTab, setSelectedTab] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()


    useEffect(()=>{
        const tab = searchParams.get("tab")
        if(tab){
            setSelectedTab(parseInt(tab))
        }
    },[searchParams])

    const handleTabSelection = (idx) => {
        setSearchParams({
            tab : idx
        })
    }

    return(
        <div className={styles.container}>
            <div className={styles.heading}>
                <h3>Calendar</h3>
                <Tooltip tooltipStyles={getTooltipStyleClass({width : "220px"})} tooltipContent={`Control all your calendar and scheduling related defaults here`}>
                    <TooltipIcon />
                </Tooltip>
            </div>
            <TabSelector items={tabs} selectedIdx={selectedTab} onClick={(idx)=>handleTabSelection(idx)} />
            <SelectedTab currentPage={"calendar"} selectedTab={selectedTab} />
        </div>
    )
}

export default CalendarSettings
