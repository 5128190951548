// src/slices/eventSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventFilteredData: [],
};

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEventFilteredData(state, action) {
      console.log("action.payload", action.payload);
      state.eventFilteredData = action.payload;
    },
  },
});

export const { setEventFilteredData } = eventSlice.actions;
export default eventSlice.reducer;
