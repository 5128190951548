import React, { useState, useEffect } from "react";
import VerticleLineProduct from "../assets/VerticleLineProduct.svg";
import HorizontalLineProduct from "../assets/HorizontalLineProduct.svg";
import PriceSelectDropDown from "./PriceSelectDropDown";
import toolTipIcon from "../assets/toolTipIcon.svg";
import Pencil from "../assets/pencil.svg";
import CompletionIndicator from "../../../components/CompletionIndicator/CompletionIndicator";
import TopUpCard from "../../../components/TopUpCard/TopUpCard";
import Tooltip from "../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../components/InfoIcon/InfoIcon";
import { fetchLookupKeyForId } from "../Utils/fetchers";
import { useSelector } from "react-redux";


const ProductCardInsights = ({
  data,
  type,
  insights,
  selectedPrice,
  siMapping,
  setSiMapping,
  topUpPrices,
  setTopUp,
  allMetrics,
  subscriptionData,
  selectedPlanType,
  productPrices
}) => {
  const [topUpQuantity, setTopUpQuantity] = useState(0);
  const [productMetrics, setProductMetrics] = useState();
  const [isEnabledEditing, setIsEnabledEditing] = useState(true)
  const {subscriptionV2} = useSelector((state)=>state.subscription)

  const formatDate = (mongoDBDate, formatType = 1) => {
    const dateObj = new Date(mongoDBDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = dateObj.getMonth();
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const shortYear = year.toString().slice(-2); // Get the last two digits of the year

    if (formatType === 1) {
      return `${day} ${monthNames[monthIndex]}-${shortYear}`;
    } else if (formatType === 2) {
      const dayNames = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = dateObj.getDay();
      return `${dayNames[dayIndex]}, ${monthNames[monthIndex]} ${day}, ${year}`;
    } else {
      return "Invalid format type";
    }
  };
  useEffect(() => {
    if (allMetrics) {
      const temp = [];
      switch (data.name) {
        case "CRM":
          temp.push(
            {
              val1: allMetrics.CRM?.activeStudents,
              val2: "/∞",
              text: "Active Students",
            },
            {
              val1: allMetrics.CRM?.studentGroups?.usage,
              val2:
                "/" +
                (allMetrics.CRM?.studentGroups?.limit === -1
                  ? "Unlimited"
                  : allMetrics.CRM?.studentGroups?.limit),
              text: "Student Groups",
            }
          );
          break;

        case "Calendar":
          temp.push(
            {
              val1: allMetrics.calendar?.totalHoursReconciled ?? 0,
              val2: null,
              text: "Hours Reconciled",
            },
            {
              val1: isNaN(
                (allMetrics.calendar?.averageSessionDuration * 60)?.toFixed(0)
              )
                ? "0"
                : (allMetrics.calendar?.averageSessionDuration * 60)?.toFixed(
                    0
                  ) + "m",
              val2: null,
              text: "Average Session Duration",
            }
          );
          break;

        case "Assignments":
          temp.push(
            {
              val1: allMetrics.assignments?.totalAssignments ?? 0,
              val2: null,
              text: "Assignments Assigned",
            },
            {
              val1: allMetrics.assignments?.avgAssignments?.toFixed(2) ?? 0,
              val2: null,
              text: "Assignments per Student",
            }
          );
          break;

        case "Custom Tests":
          temp.push(
            {
              val1:
                allMetrics.customTests?.avgQuestionsPerSection.toFixed(0) ?? 0,
              val2: null,
              text: "Avg. Number of Questions / Section",
            },
            {
              val1: allMetrics.customTests?.avgSectionsPerTest.toFixed(0) ?? 0,
              val2: null,
              text: "Avg. Number of Sections / Test",
            }
          );
          break;
      }
      setProductMetrics(temp);
    }
  }, [allMetrics]);
  const [editTier, setEditTier] = useState(false);
  const [selectedPriceName, setSelectedPriceName] = useState(null);

  useEffect(() => {
    setSelectedPriceName(
      data.priceList?.find((item) => item.value === selectedPrice[0].priceId)
        ?.name || ""
    );
  }, []);

  const getAdjustedName = (name) => {
    console.log(name);
    const numberMatch = name?.split(" ")[0].match(/\d+/);
    if (numberMatch) {
      const number = parseInt(numberMatch[0], 10);
      return selectedPlanType !== 0 ? (number / 12).toFixed(0) : number;
    }
    return name;
  };

  useEffect(()=>{
    if(siMapping && productPrices && subscriptionV2){
      const isOldBasePackageFree = subscriptionV2?.subscription?.basePackage?.name?.includes("Free")
      const basePackageSiMap = siMapping?.find((val)=>val.lookupKey.includes("BasicPackage"))
      const newBasePackageId = basePackageSiMap?.newPriceId
      let newBasePackageName = null
      if(newBasePackageId){
          newBasePackageName = fetchLookupKeyForId(productPrices,newBasePackageId,selectedPlanType === 0 ? "monthly" : "annual","BasePackage").split('_')[1]
      }
      if(newBasePackageName){
        const isNewBasePackageFree = newBasePackageName.includes("Free")
        if(isOldBasePackageFree && isNewBasePackageFree){
          setIsEnabledEditing(false)
        } else if(isOldBasePackageFree && !isNewBasePackageFree){
          setIsEnabledEditing(true)
        } else if(!isOldBasePackageFree && !isNewBasePackageFree){
          setIsEnabledEditing(true)
        } else if(!isOldBasePackageFree && isNewBasePackageFree){
          setIsEnabledEditing(false)
        }
      } else {
        setIsEnabledEditing(!isOldBasePackageFree)
      }
    }
  },[siMapping,productPrices, subscriptionV2])
  return (
    <>
      <div className="w-full min-h-[228px] h-fit rounded-[12px] p-[24px] grid gap-[24px] font-semibold text-[20px] text-[#25335A] bg-[#F8FBFD]">
        <div className="flex items-center gap-[8px]">
          <div>
            <img src={data.img} />
          </div>
          <div className="flex items-center gap-[8px] justify-start">
            {data.name}{" "}
            <Tooltip
              anchorComponent={
                <InfoIcon className="text-secondary-60"></InfoIcon>
              }
              tooltipContent={data.tooltipContent}
              tooltipStyleClass={"tooltipStyleClass"}
            />
          </div>
        </div>
        <div className="flex gap-[36px]  min-h-[120px] h-fit">
          <div className="flex gap-[24px] ">
            <div className="grid gap-[12px] ">
              <div className=" grid gap-[4px]">
                {insights ? (
                  <div className="flex items-center justify-start">
                    <span className="text-[48px] font-medium ">
                      {
                        insights.limit === -1 ? "Unlimited" : insights.remainingFromLimit === -1
                        ? "Unlimited"
                        : insights.remainingFromLimit 
                      }

                    </span>{" "}
                    <span className="text-[16px] text-[#7C859C] ">
                      /{insights.limit === -1 ? "Unlimited" : insights.limit}
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center justify-start w-[244px]">
                    <span className="text-[48px] font-medium ">Free</span>{" "}
                  </div>
                )}
                {insights ? (
                  <div className="text-[16px] text-[#7C859C] text-left  font-[400]">
                    {insights?.heading}
                  </div>
                ) : (
                  <div className="text-[16px] text-[#7C859C] w-[244px] text-left">
                    Unlimited Benefits
                  </div>
                )}
              </div>

              <div>
                <div className="flex gap-[6px] text-[16px] font-[400] pt-[10px] items-center">
                  <div className="font-[500]">
                    {data.priceList && editTier && "Select New Plan"}
                    {data.priceList && !editTier && "Current Plan"}
                  </div>{" "}
                  {data.priceList && !editTier && (
                    <img
                      onClick={() => {
                        setEditTier(true);
                      }}
                      src={Pencil}
                      className={`h-[20px] w-[20px] cursor-pointer ${isEnabledEditing ? "" : "hidden"}`}
                    />
                  )}
                </div>
                {!editTier && insights && (
                  <div className="min-w-[244px] h-[36px] font-[400] pt-[10px]">
                    {getAdjustedName(
                      selectedPriceName ? selectedPriceName : ""
                    )}
                    /mo
                    {" - "}
                    <span className="font-medium">
                      {selectedPlanType === 0
                        ? selectedPrice[0]?.price === 0
                          ? "Free"
                          : "$" + (selectedPrice[0]?.price).toFixed(0)
                        : selectedPrice[0]?.price === 0
                        ? "Free"
                        : "$" + (selectedPrice[0]?.price / 12).toFixed(0)}
                    </span>
                  </div>
                )}
              </div>
              {data.priceList && editTier && (
                <PriceSelectDropDown
                  priceList={data.priceList}
                  selectedPrice={selectedPrice}
                  siMapping={siMapping}
                  setSiMapping={setSiMapping}
                  setEditTier={setEditTier}
                  selectedPlanType={selectedPlanType}
                />
              )}
            </div>
            <div>
              <CompletionIndicator
                height={120}
                width={120}
                outerCircleColor={"gray"}
                innerCircleColor={data.color}
                percentage={insights?.percentage}
                helperText={"left"}
                mainText={
                  insights ? insights?.percentage?.toFixed(0) + "%" : 100 + "%"
                }
              />
            </div>
          </div>
          <div>
            <img
              src={VerticleLineProduct}
              className="min-h-[120px]  h-[155px] "
            />
          </div>
          <div className="grid gap-[24px]">
            <div className="flex gap-[12px] ">
              <div className="flex items-center justify-center">
                <span className="text-[36px] font-medium ">
                  {productMetrics && productMetrics[0].val1}
                </span>{" "}
                <span className="text-[24px] text-[#7C859C] ">
                  {productMetrics && productMetrics[0].val2}
                </span>
              </div>
              <div className="text-[#7C859C] text-[16px] flex items-center font-[400]">
                {productMetrics && productMetrics[0].text}
              </div>
            </div>
            <div className="flex gap-[12px] ">
              <div className="flex items-center justify-center">
                <span className="text-[36px] font-medium ">
                  {productMetrics && productMetrics[1].val1}
                </span>{" "}
                <span className="text-[24px] text-[#7C859C] ">
                  {productMetrics && productMetrics[1].val2}
                </span>
              </div>
              <div className="text-[#7C859C] text-[16px] flex items-center font-[400]">
                {productMetrics && productMetrics[1].text}
              </div>
            </div>
            {data.name === "Calendar" || data.name === "Assignments" ? (
              <div className="flex items-center justify-center text-[14px] font-normal text-[#7C859C] italic">
                {data.name === "Calendar" ? (
                  "Usage metrics shown are for current billing cycle."
                ) : data.name === "Assignments" ? (
                  "These numbers are based on lifetime usage."
                ) : (
                  <></>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/**DEPRECATED -> MAY BE USED LATER */}
      {/* {type === 0 ? (
        <div className="w-full min-h-[228px] h-fit rounded-[12px] p-[24px] grid gap-[24px] font-semibold text-[20px] text-[#25335A] bg-[#F8FBFD]">
          <div className="flex items-center gap-[8px]">
            <div>
              <img src={data.img} />
            </div>
            <div>{data.name}</div>
          </div>
          <div className="flex gap-[36px]  min-h-[120px] h-fit">
            <div className="flex gap-[24px] ">
              <div className="grid gap-[12px] ">
                <div className=" grid gap-[4px]">
                  {insights ? (
                    <div className="flex items-center justify-center">
                      <span className="text-[48px] font-medium ">
                        {insights.remainingFromLimit}
                      </span>{" "}
                      <span className="text-[16px] text-[#7C859C] ">
                        /{insights.limit}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-[244px]">
                      <span className="text-[48px] font-medium ">Free</span>{" "}
                    </div>
                  )}
                  {insights ? (
                    <div className="text-[16px] text-[#7C859C] text-center  ">
                      {insights?.heading}
                    </div>
                  ) : (
                    <div className="text-[16px] text-[#7C859C] w-[244px] text-center">
                      Unlimited Benefits
                    </div>
                  )}
                </div>
                {data.priceList && (
                  <PriceSelectDropDown
                    priceList={data.priceList}
                    selectedPrice={selectedPrice}
                    siMapping={siMapping}
                    setSiMapping={setSiMapping}
                  />
                )}
              </div>
              <div>
                <CompletionIndicator
                  height={120}
                  width={120}
                  outerCircleColor={"gray"}
                  innerCircleColor={data.color}
                  percentage={insights ? insights.percentage : 100}
                  helperText={"left"}
                  mainText={insights ? insights.percentage + "%" : 30 + "%"}
                />
              </div>
            </div>
            <div>
              <img
                src={VerticleLineProduct}
                className="min-h-[120px]  h-[148px] "
              />
            </div>
            <div className="grid gap-[24px]">
              <div className="flex gap-[12px] ">
                <div className="flex items-center justify-center">
                  <span className="text-[36px] font-medium ">11</span>{" "}
                  <span className="text-[24px] text-[#7C859C] ">/23</span>
                </div>
                <div className="text-[#7C859C] text-[16px] flex items-center">
                  Calendar Sessions
                </div>
              </div>
              <div className="flex gap-[12px] ">
                <div className="flex items-center justify-center">
                  <span className="text-[36px] font-medium ">5</span>{" "}
                  <span className="text-[24px] text-[#7C859C] ">/11</span>
                </div>
                <div className="text-[#7C859C] text-[16px] flex items-center">
                  Calendar Sessions
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full min-h-[228px] h-fit rounded-[12px] p-[24px] grid gap-[24px] font-semibold text-[20px] text-[#25335A] bg-[#F8FBFD]">
          <div className="flex items-center gap-[8px]">
            <div>
              <img src={data.img} />
            </div>
            <div>{data.name}</div>
          </div>
          <div className="flex gap-[24px] w-full min-h-[281px] h-fit">
            <div
              className={`${
                data.name === "CRM" ? "flex flex-col-reverse  " : "grid "
              }gap-[12px] h-fit`}
            >
              <div className="flex justify-center">
                <CompletionIndicator
                  height={136}
                  width={136}
                  outerCircleColor={"gray"}
                  innerCircleColor={data.color}
                  percentage={insights ? insights?.percentage : 100}
                  helperText={"left"}
                  mainText={insights ? insights?.percentage + "%" : "100%"}
                />
              </div>
              <div className="grid gap-[12px] ">
                <div className=" grid gap-[4px]">
                  {insights ? (
                    <div className="flex items-center justify-center">
                      <span className="text-[48px] font-medium ">
                        {insights?.remainingFromLimit}
                      </span>{" "}
                      <span className="text-[16px] text-[#7C859C] ">
                        /{insights?.limit}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-[244px]">
                      <span className="text-[48px] font-medium ">Free</span>{" "}
                    </div>
                  )}
                  {insights ? (
                    <div className="text-[16px] text-[#7C859C] text-center  ">
                      {insights?.heading}
                    </div>
                  ) : (
                    <div className="text-[16px] text-[#7C859C] w-[244px] text-center">
                      Unlimited Benefits
                    </div>
                  )}
                </div>
                {data.priceList && (
                  <PriceSelectDropDown
                    priceList={data?.priceList}
                    selectedPrice={selectedPrice}
                    siMapping={siMapping}
                    setSiMapping={setSiMapping}
                  />
                )}
              </div>
            </div>
            <div className="h-[20%]">
              <img
                src={VerticleLineProduct}
                className={`w-[10px] ${
                  data.name === "CRM" ? "h-[280px]" : "h-[330px]"
                } `}
              />
            </div>
            <div className="grid gap-[30px] h-fit  w-full">
              <div className=" gap-[24px] flex h-fit ">
                <div className="flex gap-[12px] w-[45%] ">
                  <div className="flex items-center justify-center">
                    <span className="text-[36px] font-medium ">11</span>{" "}
                    <span className="text-[24px] text-[#7C859C] ">/23</span>
                  </div>
                  <div className="text-[#7C859C] text-[16px]  items-center">
                    <div>Calendar</div> <div>Sessions</div>
                  </div>
                </div>
                <div className="flex gap-[12px] w-[45%]">
                  <div className="flex items-center justify-center">
                    <span className="text-[36px] font-medium ">5</span>{" "}
                    <span className="text-[24px] text-[#7C859C] ">/11</span>
                  </div>
                  <div className="text-[#7C859C] text-[16px]  items-center">
                    <div>Calendar</div> <div>Sessions</div>
                  </div>
                </div>
              </div>
              {insights ? (
                <div>
                  <img
                    src={HorizontalLineProduct}
                    className="w-full h-[10px]"
                  />
                </div>
              ) : (
                ""
              )}
              {topUpPrices[0] ? (
                <div className="grid gap-[16px]">
                  <div className="flex justify-between ">
                    <div className="flex text-[16px] gap-[4px] ">
                      <div>Select Top-ups</div>
                      <div className=" flex items-center">
                        <img src={toolTipIcon} className="h-[16px] w-[16px]" />
                      </div>
                    </div>
                    <div className="text-[16px] text-[#515C7B]">
                      Current Balance: 122 sessions
                    </div>
                  </div>
                  <div className="flex flex-col gap-[12px] p">
                    <div className="flex justify-between">
                      <input
                        type="number"
                        placeholder={`Enter no of ${topUpPrices[0].name} you need`}
                        onChange={(e) => {
                          setTopUpQuantity(e.target.value);
                        }}
                        value={topUpQuantity}
                        min={1}
                        className="w-[fit]"
                      />
                      x<div>${topUpPrices[0].amount / 100}</div>
                      <div>
                        This Top Up will cost you $
                        {(topUpPrices[0].amount / 100) * topUpQuantity}
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <button
                        onClick={() => {
                          setTopUp({
                            priceId: topUpPrices[0].priceId,
                            quantity: topUpQuantity,
                          });
                        }}
                        className="bg-[#0671E0] rounded-[8px] px-[8px] py-[2px] "
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" gap-[24px] flex h-fit ">
                  <div className="flex gap-[12px] w-[45%] ">
                    <div className="flex items-center justify-center">
                      <span className="text-[36px] font-medium ">11</span>{" "}
                      <span className="text-[24px] text-[#7C859C] ">/23</span>
                    </div>
                    <div className="text-[#7C859C] text-[16px]  items-center">
                      <div>Calendar</div> <div>Sessions</div>
                    </div>
                  </div>
                  <div className="flex gap-[12px] w-[45%]">
                    <div className="flex items-center justify-center">
                      <span className="text-[36px] font-medium ">5</span>{" "}
                      <span className="text-[24px] text-[#7C859C] ">/11</span>
                    </div>
                    <div className="text-[#7C859C] text-[16px]  items-center">
                      <div>Calendar</div> <div>Sessions</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ProductCardInsights;
