import React, { useRef, useState } from "react";

// Version 2 icons for new Navbar----------------->
import evallo_logo from "../../assets/icons/navbar-icons/version2/Evallo_logo_V2.svg";
// import Users_V2 from "../../assets/icons/navbar-icons/version2/Users_V2.svg";
import UsersIcon from "../../assets/icons/navbar-icons/version2/UsersIcon";
import CalendarIcon from "../../assets/icons/navbar-icons/version2/CalendarIcon";
import ProfileIcon from "../../assets/icons/navbar-icons/version2/ProfileIcon";
import StudentIcon from "../../assets/icons/navbar-icons/version2/StudentIcon";

// Old versions importes................>
import faQuestionCircle from "../../assets/images/Vectorqsn.svg";
import icon from "../../assets/icons/Evallo Logo.svg";
// import evallo_logo from "../../assets/icons/navbar-icons/evallo-logo.svg";
import logoutIcon from "../../assets/images/Vectorlogout new.svg";
import Dashboard1 from "../../assets/icons/navbar-icons/Dashboard_red.svg";
import Dashboard from "../../assets/icons/Dashboard_light.svg";
import DashboardDisabled from "../../assets/icons/navbar-icons/Dashboard_grey.svg";
// import UsersIcon from "../../assets/icons/crm_light.svg";
// import StudentIcon from "../../assets/icons/mdi_account-studentstudent.svg";
// import StudentIcon2 from "../../assets/icons/mdi_account-studentstudent2.svg";
import UsersIcon1 from "../../assets/icons/navbar-icons/crm_red.svg";
import UsersIconDisabled from "../../assets/icons/navbar-icons/crm_grey.svg";
import ScheduleDisabled from "../../assets/icons/navbar-icons/Calendar_grey.svg";
import AssignmentDisabled from "../../assets/icons/navbar-icons/Assignments_grey.svg";
import Content from "../../assets/icons/content-logo_light.svg";
import Content2 from "../../assets/icons/navbar-icons/contents_red.svg";
import ContentDisabled from "../../assets/icons/navbar-icons/contents_grey.svg";
import Invoice from "../../assets/images/invoice-logo.svg";
import Invoice2 from "../../assets/images/invoice-logo-red.svg";
import InvoiceDisabled from "../../assets/images/invoice-logo-grey.svg";
import Settings from "../../assets/images/Settings 1 new.svg";
import Settings1 from "../../assets/icons/navbar-icons/settings_red.png";
import Profile from "../../assets/Navbar/profile.svg";
import Profile1 from "../../assets/images/Vector (5).svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useEffect } from "react";
import Modal from "../../components/Modal/Modal";
import { updateIsLoggedIn } from "../../app/slices/user";
import { useLazyGetLogoutQuery } from "../../app/services/superAdmin";
import styles from "./navbar.module.css";
import {
  useLazyGetPersonalDetailQuery,
  useLazyGetOrganizationQuery,
} from "../../app/services/users";
import AssignmentIcon from "../../assets/icons/navbar-icons/version2/AssignmentIcon";
import ContentIcon from "../../assets/icons/navbar-icons/version2/ContentIcon";
import DownArrow from "../../assets/icons/navbar-icons/version2/DownArrow";
import SettingsIcon from "../../assets/icons/navbar-icons/version2/SettingsIcon";
import InvoiceIcon from "../../assets/icons/navbar-icons/version2/InvoiceIcon";
import AccountDropdownCard from "../AccountDropdownCard/AccountDropdownCard";
import DashboardIcon from "../../assets/icons/navbar-icons/version2/DashboardIcon";
import MenuIcon from '@mui/icons-material/Menu';
import { isWhiteLabellingComponentAllowed } from "../../utils/restrictionsService";

let tempnavdata = [
  // {
  //    icon: Dashboard,
  //    activeIcon: Dashboard1,
  //    disabledIcon: DashboardDisabled,
  //    path: "/",
  //    excludes: ["student", "parent", "tutor"],
  //    tooltip: "Dashboard",
  //    isDisabled: false,
  // },
  {
    icon: <UsersIcon />,
    activeIcon: <UsersIcon type={"active"} />,
    disabledIcon: UsersIconDisabled,
    path: "/",
    tooltip: "Users",
    isDisabled: false,
  },
  {
    icon: <CalendarIcon />,
    activeIcon: <CalendarIcon type={"active"} />,
    disabledIcon: ScheduleDisabled,
    path: "/calendar",
    tooltip: "Calendar",
    isDisabled: false,
  },
  {
    icon: <AssignmentIcon />,
    activeIcon: <AssignmentIcon type={"active"} />,
    disabledIcon: AssignmentDisabled,
    path: "/assigned-tests",
    tooltip: "Assignments",
    isDisabled: false,
  },
  {
    icon: <ContentIcon />,
    activeIcon: <ContentIcon type={"active"} />,
    disabledIcon: ContentDisabled,
    path: "/all-tests",
    tooltip: "Content",
    isDisabled: false,
  },
  // {
  //    icon: Invoice,
  //    activeIcon: Invoice2,
  //    disabledIcon: InvoiceDisabled,
  //    path: "/invoice",
  //    tooltip: "Invoicing",
  //    isDisabled: false,
  // },
  // {
  //    icon: Settings,
  //    activeIcon: Settings1,
  //    path: "/settings",
  //    excludes: ["student", "parent", "tutor"],
  //    tooltip: "Settings",
  //    isDisabled: false,
  // },
];

const parentNav = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon type={"active"} />,
    path: "/",
    tooltip: "Dashboard",
  },

  {
    icon: <AssignmentIcon />,
    activeIcon: <AssignmentIcon type={"active"} />,
    path: "/all-tests",
    tooltip: "Assignments",
  },
  {
    icon: <CalendarIcon />,
    activeIcon: <CalendarIcon type={"active"} />,
    path: "/calendar",
    tooltip: "Calendar",
  },

  {
    icon: <ProfileIcon />,
    activeIcon: <ProfileIcon type={"active"} />,
    path: "/profile",
    tooltip: "Profile",
  },
  // {
  //    icon: <SettingsIcon />,
  //    activeIcon: <SettingsIcon type={"active"} />,
  //    path: "/settings",
  //    excludes: ["student", "tutor", "admin", "superAdmin"],
  //    tooltip: "Settings",
  // },
];

const studentNav = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon type={"active"} />,
    path: "/",
    tooltip: "Dashboard",
  },

  {
    icon: <AssignmentIcon />,
    activeIcon: <AssignmentIcon type={"active"} />,
    path: "/all-tests",
    tooltip: "Assignments",
  },
  {
    icon: <CalendarIcon />,
    activeIcon: <CalendarIcon type={"active"} />,
    path: "/calendar",
    tooltip: "Calendar",
  },
  {
    icon: <ProfileIcon />,
    activeIcon: <ProfileIcon type={"active"} />,
    path: "/profile",
    tooltip: "Profile",
  },
  // {
  //    icon: <SettingsIcon />,
  //    activeIcon: <SettingsIcon type={"active"} />,
  //    path: "/settings",
  //    excludes: ["parent", "tutor", "admin", "superAdmin"],
  //    tooltip: "Settings",
  // },
];
const tutorNav = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon type={"active"} />,
    path: "/",
    tooltip: "Dashboard",
  },
  // {
  //    icon: <ProfileIcon />,
  //    activeIcon: <ProfileIcon type={"active"} />,
  //    path: "/profile",
  //    tooltip: "Profile",
  // },
  {
    icon: <CalendarIcon />,
    activeIcon: <CalendarIcon type={"active"} />,
    path: "/calendar",
    tooltip: "Calendar",
  },
  {
    icon: <AssignmentIcon />,
    activeIcon: <AssignmentIcon type={"active"} />,
    path: "/assigned-tests",
    tooltip: "Assignments",
  },
  {
    icon: <StudentIcon />,
    activeIcon: <StudentIcon type={"active"} />,
    path: "/assigned-students",
    tooltip: "Students",
  },
  // {
  //    icon: <SettingsIcon />,
  //    activeIcon: <SettingsIcon type={"active"} />,
  //    path: "/settings",
  //    excludes: ["parent", "student", "admin", "superAdmin"],
  //    tooltip: "Settings",
  // },
];
const supAdminNavData = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon type={"active"} />,
    path: "/",
    excludes: ["student", "parent", "tutor"],
    tooltip: "Dashboard",
  },
  {
    icon: <ProfileIcon />,
    activeIcon: <ProfileIcon type={"active"} />,
    path: "/all-orgs",
    excludes: ["student", "parent", "tutor"],
    tooltip: "All Orgs",
  },
  {
    icon: <SettingsIcon />,
    activeIcon: <SettingsIcon type={"active"} />,
    path: "/settings",
    excludes: ["student", "parent", "tutor"],
    tooltip: "Settings",
  },
];

const contributorNavdata = [
  {
    icon: <UsersIcon />,
    activeIcon: <UsersIcon type={"active"} />,
    disabledIcon: UsersIconDisabled,
    path: "/users",
    tooltip: "Users",
    isDisabled: false,
  },
  // {
  //    icon: <DashboardIcon />,
  //    activeIcon: <DashboardIcon type={"active"} />,
  //    path: "/",
  //    tooltip: "Dashboard",
  // },
  {
    icon: <ContentIcon />,
    activeIcon: <ContentIcon type={"active"} />,
    path: "/",
    tooltip: "Content",
  },
  {
    icon: <SettingsIcon />,
    activeIcon: <SettingsIcon type={"active"} />,
    path: "/settings",
    excludes: ["student", "parent", "tutor"],
    tooltip: "Settings",
  },
];

const managerNavData = [
  {
    icon: <DashboardIcon />,
    activeIcon: <DashboardIcon type={"active"} />,
    path: "/",
    excludes: ["student", "parent", "tutor"],
    tooltip: "Dashboard",
  },
  {
    icon: <ProfileIcon />,
    activeIcon: <ProfileIcon type={"active"} />,
    path: "/all-orgs",
    excludes: ["student", "parent", "tutor"],
    tooltip: "All Orgs",
  },
  {
    icon: <SettingsIcon />,
    activeIcon: <SettingsIcon type={"active"} />,
    path: "/settings",
    excludes: ["student", "parent", "tutor"],
    tooltip: "Settings",
  },
];

const Navbar = ({ myRef, setNavData: setNavigationData, setLoading, fromStage, setMobileNavTrigger, mobileNavTrigger, showMobileProfile, setShowMobileProfile }) => {
  const navbarRef = useRef()
  const middleRef = useRef()
  const endRef = useRef()

  const {
    hasSubscriptionExpired,
    hasExtensionsExpired,
    activeSubscriptionInfo,
    activeExtensionInfo,
    isLoaded,
  } = useSelector((state) => state.subscription);
  const [navData, setNavData] = useState(tempnavdata);
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const [logoutModalActive, setLogoutModalActive] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDashboard, setShowDashboard] = useState(
    location.pathname.includes("all-orgs") ||
      location.pathname.includes("dashboard")
      ? true
      : false
  );
  const { width } = useWindowDimensions();
  const { isLoggedIn, photo, awsLink, firstName } = useSelector(
    (state) => state.user
  );
  const [logOutApi, setLogOutApi] = useLazyGetLogoutQuery();
  const [getPersonalDetail, getPersonalDetailResp] =
    useLazyGetPersonalDetailQuery();
  const [getOrgDetails, getOrgDetailsResp] = useLazyGetOrganizationQuery();
  const { role: persona } = useSelector((state) => state.user);
  const {organization} = useSelector((state) => state.organization)
  const {subscriptionV2 } = useSelector((state) => state.subscription)

  useEffect(() => {
    if (persona === "student") {
      setNavData(studentNav);
    } else if (persona === "tutor") {
      setNavData(tutorNav);
    } else if (persona === "parent") {
      setNavData(parentNav);
    } else if (persona === "admin") {
      setNavData(tempnavdata);
    } else if (persona === "superAdmin") {
      setNavData(supAdminNavData);
    } else if (persona === "contributor") {
      setNavData(contributorNavdata);
    } else if (persona === "manager") {
      setNavData(managerNavData);
    } else {
      setNavData([]);
    }
  }, [persona]);

  const handleNavigate = (path) => {
    if (path === "/exit") {
      setLogoutModalActive(true);
    } else if (path === "/logo") {
      // window.open("https://sevensquarelearning.com/");
    } else {
      if (path === "") return;
      if (path === "/all-orgs") {
        setShowDashboard(true);
      }
      navigate(path);
    }
  };
  const [loading2, setLoading2] = useState(false);
  const logoutUser = () => {
    setLoading2(true);
    logOutApi().then((res) => {
      setLoading2(false);
      if (res?.error) {
        console.log("LGOUT", res?.error);
        alert("Something went wrong. Please try again");
        return;
      }
      console.log("Successfully logged out");
      localStorage.clear("noOfTutors")
      setLogoutModalActive(false);
      sessionStorage.clear();
      localStorage.clear("evalloToken");
      window.localStorage.removeItem("alertPreferences");
      navigate("/signin");
      dispatch(updateIsLoggedIn(false));
      window.location.reload();
    });
  };
  useEffect(() => {
    if (location.pathname.includes("/all-tests/")) {
      setActiveRoute("/all-tests");
    } else if (location.pathname.includes("/orgadmin-profile/")) {
      setActiveRoute("/all-orgs");
      console.log(activeRoute);
    } else if(window.location.pathname.startsWith("/calendar")){
		setActiveRoute("/calendar")
	}else setActiveRoute(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    if (
      !isLoggedIn &&
      (activeRoute === "/signup/user" ||
        activeRoute === "/signup" ||
        activeRoute === "/")
    ) {
      let arr = tutorNav;
      if (activeRoute === "/") arr[0].path = "/";
      setNavData(arr);
    }
  }, [activeRoute]);
  const [navTop, setNavTop] = useState(0);

  // const handleScroll = () =>{
  //    setNavTop(0);
  //    const screenWidth = window.innerWidth;
  //    const scale = screenWidth > 0 ? screenWidth / 1920 : 0;
  //    const scrollY = window.scrollY;
  //    const requiredTop = (1/(scale>1?1:scale)) * scrollY;
  //    setNavTop(requiredTop);
  // };
  // useEffect(() => {
  //    window.addEventListener('scroll', handleScroll);
  //    window.addEventListener('resize', handleScroll);
  //    window.addEventListener('wheel',handleScroll);
  //    return () => {
  //       window.removeEventListener('scroll', handleScroll);
  //       window.removeEventListener('resize', handleScroll);
  //    window.removeEventListener('wheel',handleScroll);
  //    };
  //  }, []);

  useEffect(() => {
    if (
      persona === "parent" ||
      persona === "student" ||
      persona === "tutor" ||
      persona === "contributor" ||
      persona === "superAdmin" ||
      persona === "manager"
    ) {
      setLoading(false);
      return;
    }
    if (!isLoaded) return;
    console.log("hasSubscriptionExpired--", hasSubscriptionExpired);
    console.log(
      "activeSubscriptionInfo.planName--",
      activeSubscriptionInfo.planName
    );
    if (hasSubscriptionExpired) {
      let tempnavdata = [
        // {
        //    icon: Dashboard,
        //    activeIcon: Dashboard1,
        //    disabledIcon: DashboardDisabled,
        //    path: "/",
        //    excludes: ["student", "parent", "tutor"],
        //    tooltip: "Dashboard",
        //    isDisabled: true,
        // },
        {
          icon: <UsersIcon />,
          activeIcon: <UsersIcon type={"active"} />,
          disabledIcon: UsersIconDisabled,
          path: "/users",
          tooltip: "Users",
          isDisabled: false,
        },
        {
          icon: <CalendarIcon />,
          activeIcon: <ContentIcon type={"active"} />,
          disabledIcon: ScheduleDisabled,
          path: "/calendar",
          tooltip: "Calendar",
          isDisabled: false,
        },
        {
          icon: <AssignmentIcon />,
          activeIcon: <AssignmentIcon type={"active"} />,
          disabledIcon: AssignmentDisabled,
          path: "/assigned-tests",
          tooltip: "Assignments",
          isDisabled: false,
        },
        {
          icon: <ContentIcon />,
          activeIcon: <ContentIcon type={"active"} />,
          disabledIcon: ContentDisabled,
          path: "/all-tests",
          tooltip: "Content",
          isDisabled: false,
        },
        {
          icon: <InvoiceIcon />,
          activeIcon: <InvoiceIcon type={"active"} />,
          disabledIcon: InvoiceDisabled,
          path: "/invoice",
          tooltip: "Invoicing",
          isDisabled: true,
        },
        {
          icon: <SettingsIcon />,
          activeIcon: <SettingsIcon type={"active"} />,
          path: "/settings",
          excludes: ["student", "parent", "tutor"],
          tooltip: "Settings",
          isDisabled: false,
        },
      ];
      setLoading(false);
      setNavData(tempnavdata);
      return;
    }

    if (!(hasSubscriptionExpired || activeSubscriptionInfo.planName === "")) {
      let tempnavdata = [
        // {
        //    icon: Dashboard,
        //    activeIcon: Dashboard1,
        //    disabledIcon: DashboardDisabled,
        //    path: "/",
        //    excludes: ["student", "parent", "tutor"],
        //    tooltip: "Dashboard",
        //    isDisabled: false,
        // },
        {
          icon: <UsersIcon />,
          activeIcon: <UsersIcon type={"active"} />,
          disabledIcon: UsersIconDisabled,
          path: "/users",
          tooltip: "Users",
          isDisabled: false,
        },
        {
          icon: <CalendarIcon />,
          activeIcon: <ContentIcon type={"active"} />,
          disabledIcon: ScheduleDisabled,
          path: "/calendar",
          tooltip: "Calendar",
          isDisabled: false,
        },
        {
          icon: <AssignmentIcon />,
          activeIcon: <AssignmentIcon type={"active"} />,
          disabledIcon: AssignmentDisabled,
          path: "/assigned-tests",
          tooltip: "Assignments",
          isDisabled: hasExtensionsExpired ? true : false,
        },
        {
          icon: <ContentIcon />,
          activeIcon: <ContentIcon type={"active"} />,
          disabledIcon: ContentDisabled,
          path: "/all-tests",
          tooltip: "Content",
          isDisabled: hasExtensionsExpired ? true : false,
        },
        {
          icon: <InvoiceIcon />,
          activeIcon: <InvoiceIcon type={"active"} />,
          disabledIcon: InvoiceDisabled,
          path: "/invoice",
          tooltip: "Invoicing",
          isDisabled: true,
        },
        {
          icon: <SettingsIcon />,
          activeIcon: <SettingsIcon type={"active"} />,
          path: "/settings",
          excludes: ["student", "parent", "tutor"],
          tooltip: "Settings",
          isDisabled: false,
        },
      ];
      setLoading(false);
      setNavData(tempnavdata);
    }
  }, [
    persona,
    hasSubscriptionExpired,
    activeSubscriptionInfo,
    hasExtensionsExpired,
    activeExtensionInfo,
  ]);

  function loadOrgDetails() {
    getPersonalDetail()
      .then((data) => {
        // console.log("getPersonalDetail");
        // console.log(data);
        const user = data.data.data.user;

        getOrgDetails(user.associatedOrg)
          .then((data) => {
            console.log("getOrgDetails");
            console.log(data);

            if (
              data.data &&
              data.data.customerSubscriptions &&
              data.data.customerSubscriptions.data &&
              data.data.customerSubscriptions.data.length > 0 &&
              data.data.customerSubscriptions.data.findIndex((item) => {
                if (item && item.metadata) {
                  return item.metadata.type === "extension";
                }
              }) !== -1
            ) {
              tempnavdata = [
                // {
                //    icon: Dashboard,
                //    activeIcon: Dashboard1,
                //    path: "/",
                //    excludes: ["student", "parent", "tutor"],
                //    tooltip: "Dashboard",
                //    isDisabled: false,
                // },
                {
                  icon: <UsersIcon />,
                  activeIcon: <UsersIcon type={"active"} />,
                  path: "/users",
                  tooltip: "Users",
                  isDisabled: false,
                },
                {
                  icon: <CalendarIcon />,
                  activeIcon: <ContentIcon type={"active"} />,
                  path: "/calendar",
                  tooltip: "Calendar",
                  isDisabled: false,
                },
                {
                  icon: <AssignmentIcon />,
                  activeIcon: <AssignmentIcon type={"active"} />,
                  path: "/assigned-tests",
                  tooltip: "Assignments",
                  isDisabled: false,
                },
                {
                  icon: <ContentIcon />,
                  activeIcon: <ContentIcon type={"active"} />,
                  path: "/all-tests",
                  tooltip: "Content",
                  isDisabled: false,
                },
                {
                  icon: <InvoiceIcon />,
                  activeIcon: <InvoiceIcon type={"active"} />,
                  path: "/invoice",
                  tooltip: "Invoicing",
                  isDisabled: false,
                },
                {
                  icon: <SettingsIcon />,
                  activeIcon: <SettingsIcon type={"active"} />,
                  path: "/settings",
                  excludes: ["student", "parent", "tutor"],
                  tooltip: "Settings",
                  isDisabled: false,
                },
              ];

              setNavData(tempnavdata);
            }
          })
          .catch((error) => {
            console.log("Error in getOrgDetails");
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("Error in getPersonalDetail");
        console.log(error);
      });
  }

  useEffect(() => {
    if (
      persona === "parent" ||
      persona === "student" ||
      persona === "tutor" ||
      persona === "contributor" ||
      persona === "superAdmin" ||
      persona === "manager"
    ) {
      return;
    }
    // loadOrgDetails();
  }, []);

  useEffect(() => {
    setNavigationData(navData);
  }, [navData]);

  useEffect(() => {
    console.log(awsLink + photo);
  }, []);

  const [isHovering, setIsHovering] = useState(false);

  const adjustScale = () => {
    const screenWidth = document.body.clientWidth;
    const scale = screenWidth > 0 ? screenWidth / 1920 : 0;

    if (navbarRef.current) {
      navbarRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
      navbarRef.current.style.padding = "0px"
    }
    if (middleRef.current) {
      middleRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
      navbarRef.current.style.padding = "0px"
    }
    if (endRef.current) {
      endRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
      navbarRef.current.style.padding = "0px"
    }
  }

  useEffect(() => {
    adjustScale()
    window.addEventListener("resize", adjustScale);

    return () => {
      window.removeEventListener("resize", adjustScale);
    }
  }, [])




  if (fromStage) {
    return (
      <>
        {/**EXTRACT LATER TO INDEPENDENT COMPONENTS */}
        {/**Desktop Navbar */}
        <div className="w-full bg-blue-70 h-[calc(100vw*(72/1920))] max-h-[72px] flex items-center justify-between hidden md:flex"  >
          <Link to="/" className="w-[137.08px] cursor-pointer px-[0px] design:ml-[0px]" ref={navbarRef} >
          {
           subscriptionV2 && isWhiteLabellingComponentAllowed(subscriptionV2?.subscription,"brandLogoWhite")  && organization?.isEnabledOrgWhiteLogo === true ? 
            <img src={organization.orgWhiteLogo} style={{objectFit : "contain", maxHeight : "40px", height : "40px"}}/>
            : 
            <img
              className=" w-[137.08px] h-[31.31px] inline-block"
              src={evallo_logo}
              alt="evallo_logo"
            />
          }
          </Link>

          <div className={`flex items-center justify-center text-white `} ref={middleRef}>
            {navData.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`flex gap-1 items-center ${persona === "parent" || persona === "student"
                    ? "mr-[46.66px]"
                    : persona === "tutor" || persona == "admin"
                      ? "mr-[33.33px]"
                      : persona === "superAdmin"
                        ? "mr-[50px]"
                        : "mr-[43.87px]"
                    } ${isLoggedIn && !item.isDisabled
                      ? "cursor-pointer"
                      : " cursor-default"
                    }`}
                  onClick={() => {
                    if (
                      isLoggedIn &&
                      !item.isDisabled &&
                      item.tooltip !== "Invoicing"
                    ) {
                      handleNavigate(item.path);
                    }
                    // isLoggedIn && handleNavigate(item.path)
                  }}
                >
                  {isLoggedIn && item?.path === activeRoute ? (
                    <>
                      <div className={``}>{item.activeIcon}</div>
                      <div className="">
                        <p className=" font-medium text-[18px] leading-[22.5px]">
                          {" "}
                          {item.tooltip}{" "}
                        </p>
                        <div className="bg-white h-[2.5px] rounded-sm w-full mt-1"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={``}>
                        {item.isDisabled && item.disabledIcon
                          ? item.icon
                          : item.icon}
                      </div>
                      <p
                        className={`font-medium text-base leading-5 ${item.isDisabled ? "text-[#B3BDC7] mt-0" : "mt-0"
                          } ${item.tooltip === "Invoicing" ? "opacity-50" : ""} `}
                      >
                        {" "}
                        {item.tooltip}{" "}
                      </p>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          <div
            className={`relative h-full flex items-center ${isLoggedIn ? "" : "opacity-30"
              } design:mr-[0px]`}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            ref={endRef}
          >
            <div className="flex gap-2 items-center cursor-pointer">
              <div className="flex gap-[6px] items-center text-white">
                <div className="w-7 h-7 rounded-full bg-blue-400 border overflow-hidden border-white">
                  {photo ? (
                    <img
                      className=" w-full h-full object-cover"
                      src={awsLink + photo}
                      alt=""
                    />
                  ) : (
                    <div className="h-full w-full flex justify-center items-center text-xl uppercase">
                      {firstName && firstName[0]}
                    </div>
                  )}
                </div>
                <div className="font-medium text-[20px] leading-5 capitalize">
                  {firstName ? firstName : "Account"}
                </div>
              </div>
              <div className="flex items-center">
                <DownArrow />
              </div>
            </div>
            {isHovering && isLoggedIn && (
              <AccountDropdownCard
                persona={persona}
                isLoggedIn={isLoggedIn}
                setLogoutModalActive={setLogoutModalActive}
                fromStage={true}
                outsideclassName={"hidden md:flex"}
                setIsHovering={setIsHovering}
              />
            )}
          </div>
          {logoutModalActive && (
            <Modal
              title={
                <p className="text-center">Are You Sure You Want To Log Out?</p>
              }
              titleclass="w-[100%]"
              canceliconvisible={false}
              bodyScrollbarHidden={true}
              primaryBtnClass={"!m-0 text-center"}
              modalHeadingClass={"!justify-center"}
              buttonParentClassName="justify-center !gap-[26.67px]"
              topClass="!h-[115%]"
              parentClass="flex flex-col justify-center items-center !h-[100vh]"
              alignBtn={true}
              titleClassName="leading-9 text-center whitespace-nowrap mb-[22.67px]"
              cancelBtn={true}
              crossBtn={true}
              closeIconClass={"hidden"}
              cancelBtnClassName="!w-[146px] text-[#26435F] font-medium text-base !rounded-[8px] !bg-[rgba(38,67,95,0.10)] !h-[46px] !border-transparent"
              primaryBtn={{
                text: "Logout",
                loading: loading2,
                className:
                  "text-base !w-[146px] pl-4 pr-4   !rounded-[8px] font-medium  !text-center !bg-[#FF7979] !h-[46px] ml-0 mr-0",
                onClick: logoutUser,
              }}
              handleClose={() => setLogoutModalActive(false)}
              body={<div className="mb-6"></div>}
              classname={
                "!w-[666px] mx-auto !pt-7 !pb-[33px] !rounded-[8px] px-[33.33px] !text-center scale-50 md:scale-75 lg:scale-90 2xl:scale-100"
              }
            />
          )}
        </div>
        {/**Mobile Navbar */}
        <div className="flex items-center justify-between md:hidden w-full px-[16px] py-[16px] text-white">
          <button onClick={(e) => setMobileNavTrigger(!mobileNavTrigger)}>
            <MenuIcon style={{ height: "24px", width: "24px" }} />
          </button>

          <div
            className={`relative h-full flex items-center ${isLoggedIn ? "" : "opacity-30"
              } design:mr-[0px]`}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <div className="flex gap-2 items-center cursor-pointer" onClick={(e)=>{setShowMobileProfile(!showMobileProfile); if(setMobileNavTrigger && mobileNavTrigger){setMobileNavTrigger(false)}}}>
              <div className="flex gap-[6px] items-center text-white">
                <div className="w-[28px] h-[28px] md:w-7 md:h-7 rounded-full bg-blue-400 border overflow-hidden border-white">
                  {photo ? (
                    <img
                      className=" w-full h-full object-cover"
                      src={awsLink + photo}
                      alt=""
                    />
                  ) : (
                    <div className="h-full w-full flex justify-center items-center text-xl uppercase">
                      {firstName && firstName[0]}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isHovering && isLoggedIn && (
              <AccountDropdownCard
                persona={persona}
                isLoggedIn={isLoggedIn}
                setLogoutModalActive={setLogoutModalActive}
                fromStage={true}
                outsideclassName={"hidden md:flex"}
                setIsHovering={setIsHovering}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {/* this div will take navbar's height */}
      <div
        ref={myRef}
        style={{ top: `${navTop}px`, transformOrigin: "top left" }}
        className={`flex px-[0px] bg-blue-70 h-[72px] items-center w-[1920px] 
                           sticky left-0 
                          ${persona === "superAdmin"
            ? "justify-between px-[5%]"
            : "justify-between"
          } 
                           z-[100000]
                          ${persona === "parent"
            ? "!px-[0px] !justify-center"
            : ""
          }
                          `}
      >
        <div
          className={`
                           ${persona === "superAdmin"
              ? "translate-x-[-44.16px]"
              : ""
            }
                           ${persona === "parent" ? "ml-[0px]" : ""}
                           }
                           `}
        >
          <Link to="/" className="w-[137.08px] h-[31.31px] cursor-pointer">
          {
            subscriptionV2 && isWhiteLabellingComponentAllowed(subscriptionV2?.subscription,"brandLogoWhite") && organization?.isEnabledOrgWhiteLogo === true ? 
            <img src={organization.orgWhiteLogo} style={{objectFit : "contain", maxHeight : "40px", height : "40px"}}/>
            : 
            <img
              className=" w-[137.08px] h-[31.31px] inline-block"
              src={evallo_logo}
              alt="evallo_logo"
            />
          }
          </Link>
        </div>
        <div
          className={`flex text-[#FFFFFF] font-semibold text-[17.333px] 
                             ${!isLoggedIn && "opacity-[0.3]"} ${persona === "superAdmin" ? "translate-x-[67.2px]" : ""
            }
                             ${persona === "parent"
              ? "ml-[335.94px] mr-[300.34px]"
              : ""
            }
                             `}
        >
          {navData.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`flex gap-1 items-center ${persona === "parent" || persona === "student"
                  ? "mr-[46.66px]"
                  : persona === "tutor" || persona == "admin"
                    ? "mr-[33.33px]"
                    : persona === "superAdmin"
                      ? "mr-[50px]"
                      : "mr-[43.87px]"
                  } ${isLoggedIn && !item.isDisabled
                    ? "cursor-pointer"
                    : " cursor-default"
                  }`}
                onClick={() => {
                  if (
                    isLoggedIn &&
                    !item.isDisabled &&
                    item.tooltip !== "Invoicing"
                  ) {
                    handleNavigate(item.path);
                  }
                  // isLoggedIn && handleNavigate(item.path)
                }}
              >
                {isLoggedIn && item?.path === activeRoute ? (
                  <>
                    <div className={``}>{item.activeIcon}</div>
                    <div className="">
                      <p className=" font-medium text-[18px] leading-[22.5px]">
                        {" "}
                        {item.tooltip}{" "}
                      </p>
                      <div className="bg-white h-[2.5px] rounded-sm w-full mt-1"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={``}>
                      {item.isDisabled && item.disabledIcon
                        ? item.icon
                        : item.icon}
                    </div>
                    <p
                      className={`font-medium text-base leading-5 ${item.isDisabled ? "text-[#B3BDC7] mt-0" : "mt-0"
                        } ${item.tooltip === "Invoicing" ? "opacity-50" : ""} `}
                    >
                      {" "}
                      {item.tooltip}{" "}
                    </p>
                  </>
                )}
              </div>
            );
          })}
        </div>

        <div
          className={`relative h-full flex items-center ${isLoggedIn ? "" : "opacity-30"
            }`}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <div className=" flex gap-2 items-center cursor-pointer">
            <div className="flex gap-[6px] items-center text-white">
              <div className="w-7 h-7 rounded-full bg-blue-400 border overflow-hidden border-white">
                {photo ? (
                  <img
                    className=" w-full h-full object-cover"
                    src={awsLink + photo}
                    alt=""
                  />
                ) : (
                  <div className="h-full w-full flex justify-center items-center text-xl uppercase">
                    {firstName && firstName[0]}
                  </div>
                )}
              </div>
              <div className="font-medium text-[20px] leading-5 capitalize">
                {firstName ? firstName : "Account"}
              </div>
            </div>
            <div className="flex items-center">
              <DownArrow />
            </div>
          </div>
          {isHovering && isLoggedIn && (
            <AccountDropdownCard
              persona={persona}
              isLoggedIn={isLoggedIn}
              setLogoutModalActive={setLogoutModalActive}
              outsideclassName={"hidden md:flex"}
              setIsHovering={setIsHovering}
            />
          )}
        </div>
      </div>
      {logoutModalActive && (
        <Modal
          title={
            <p className="text-center">Are You Sure You Want To Log Out?</p>
          }
          titleclass="w-[100%]"
          canceliconvisible={false}
          bodyScrollbarHidden={true}
          primaryBtnClass={"!m-0 text-center"}
          modalHeadingClass={"!justify-center"}
          buttonParentClassName="justify-center !gap-[26.67px]"
          topClass="!h-[115%]"
          parentClass="flex flex-col justify-center items-center !h-[100vh]"
          alignBtn={true}
          titleClassName="leading-9 text-center whitespace-nowrap mb-[22.67px]"
          cancelBtn={true}
          crossBtn={true}
          closeIconClass={"hidden"}
          cancelBtnClassName="!w-[146px] text-[#26435F] font-medium text-base !rounded-[8px] !bg-[rgba(38,67,95,0.10)] !h-[46px] !border-transparent"
          primaryBtn={{
            text: "Logout",
            loading: loading2,
            className:
              "text-base !w-[146px] pl-4 pr-4   !rounded-[8px] font-medium  !text-center !bg-[#FF7979] !h-[46px] ml-0 mr-0",
            onClick: logoutUser,
          }}
          handleClose={() => setLogoutModalActive(false)}
          body={<div className="mb-6"></div>}
          classname={
            "!w-[666px] mx-auto !pt-7 !pb-[33px] !rounded-[8px] px-[33.33px] !text-center scale-50 md:scale-75 lg:scale-90 2xl:scale-100"
          }
        />
      )}
    </>
  );
};

export default Navbar;
