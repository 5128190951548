import { useEffect, useMemo, useState } from "react"
import HorizontalDivider from "../../HorizontalDivider/HorizontalDivider"
import styles from "./SessionTagsSelector.module.css"

function isTagSelected(allItems, targetItem){
	if(!allItems){
		return false
	}
	let allTags = allItems.map(val=>val.toLowerCase())	
	let target = targetItem.toLowerCase()

	return allTags.includes(target)
	
}

function setSelectedTags(items,requiredTag){
	const _items = items.map((val,idx)=>val.toLowerCase())
	const tag = requiredTag.toLowerCase()
	
	return _items.includes(tag) ? _items.filter((_val)=>_val !== tag)  : [..._items, requiredTag]
}

function SessionTagsSelector({allSessionTags, defaultSessionTags, sessionTags, setSessionTags, user, organization}){
	
	const areSessionTagsVisisble = useMemo(()=>{
		if(user && ["student", "parent"].includes(user.role) && organization?.settings){
			const requiredPermission = organization.settings.permissions.find(val=> val.permissionActionName === "stuParentViewSessionTags")
			console.log("RPA", requiredPermission)
			if(requiredPermission){
				return requiredPermission.choosedValue	
			}
		}
	},[organization, organization?.settings, user])

	const isEditable = useMemo(()=>{
		return ["admin", "tutor"].includes(user?.role)
	},[user?.role])
	
	function handleTagClick(itemIdx, tag){
		setSessionTags((prev)=>(prev.map((val,idx)=>({
			...val,
			...(itemIdx === idx) && { items : setSelectedTags(val.items,tag)}
		}))))
		
	}

	useEffect(()=>{
		if(defaultSessionTags){
			setSessionTags(defaultSessionTags)	
		}else if(allSessionTags && sessionTags.length === 0) {
			setSessionTags(allSessionTags?.map((val,idx)=>({
				heading : val.heading,
				items : []
			})))
		}
	},[defaultSessionTags])

	if(areSessionTagsVisisble === false){
		return <></>
	}
	return (
		<div className={styles.outerContainer}>
			<h2> {isEditable && "Select"} Session Tags</h2>
			<div className={styles.container}>
				{
					allSessionTags?.map((item, idx)=>(
						<>
							<div className={styles.tagsContainer}>
								<h3>{item.heading}</h3>
								<div className={styles.tagDisplay} style={{pointerEvents : isEditable ? "" : "none"}}>
									{
										item?.items?.map((tag,_idx)=>(
											<button className={`${styles.tag} ${isTagSelected(sessionTags[idx]?.items,tag) ? styles.selected : styles.normal } ${!isEditable && !isTagSelected(sessionTags[idx]?.items,tag) ? "hidden" : ""}`} onClick={(e)=>handleTagClick(idx, tag)}>{tag}</button>
										))
									}
								</div>
							</div>
							{idx <= allSessionTags.length - 1 ? 
								<div className="w-full">
									<HorizontalDivider />
								</div>
								: <></>}
						</>
					))
				}
			</div>
		</div>
	)

}

export default SessionTagsSelector
