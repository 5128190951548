import { useSelector } from 'react-redux';
import styles from './ServicesTopics.module.css'
import AddIcon from '@mui/icons-material/Add';
import SettingsEditor from '../../SettingsEditor/SettingsEditor';
import { fetchAndSetOrgDetails, updateOrg } from '../../../utils/orgService';
import { triggerToast } from '../../../utils/toastController';
import { useEffect, useState } from 'react';
import ServicesOperationPopUp from './Components/ServicesOperationPopUp/ServicesOperationPopUp';
import CautionIcon from '../../CautionIcon/CautionIcon';

const ServicesTopics = () => {

    const { servicesAndSpecialization } = useSelector((state) => state.organization.organization.settings)
    const { organization } = useSelector((state) => state.organization)
    const [servicesOperationPopUpTrigger, setServicesOperationPopUpTrigger] = useState(false)
    const [servicesOperationPopUpMode, setServicesOperationPopUpMode] = useState("add")
    const [servicesDataToEdit, setServicesDataToEdit] = useState(null)
    const [onlyShowAddIcon, setOnlyShowAddIcon] = useState(false)
    const [isEnabledServices, setIsEnabledServices] = useState(true)
    const { subscriptionV2 } = useSelector((state) => state.subscription)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            console.log("Scroll started")
            setOnlyShowAddIcon(true)
        })

        window.addEventListener("scrollend", () => {
            console.log("Scroll ended")
            setOnlyShowAddIcon(false)
        })

        return () => {
            window.removeEventListener("scroll", () => { })
            window.removeEventListener("scrollend", () => { })
        }
    }, [])

    useEffect(() => {
        if (subscriptionV2 && servicesAndSpecialization) {
            const tempIsDisbled = subscriptionV2?.subscription.settings.sessionAgenda.services.limit !== -1 && subscriptionV2?.subscription.settings.sessionAgenda.services.limit <= servicesAndSpecialization?.length
            setIsEnabledServices(!tempIsDisbled)
        }
    }, [subscriptionV2, servicesAndSpecialization])

    const handleToggleClick = async (idx, targetValue, setIsLoading) => {
        try {
            setIsLoading(true)
            const requiredField = `settings.servicesAndSpecialization.${idx}.pause`
            const fields = {}
            fields[requiredField] = !servicesAndSpecialization[idx].pause
            await updateOrg(organization._id, fields)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log("ERROR", err)
        }
    }

    function handleEditButtonClick(idx) {
        setServicesDataToEdit({ ...servicesAndSpecialization[idx], idx: idx })
    }

    /**
     * 
     * @param {*} idx 
     * @param {{service : String, specialization : [String]}} targetData 
     * @param {function} setIsLoading 
     */
    async function editCurrentService(idx, targetData, setIsLoading = () => { }) {
        try {
            setIsLoading(true)
            const requiredFieldTemplate = `settings.servicesAndSpecialization.${idx}.`
            const fieldsToSend = {}
            Object.keys(targetData).forEach((key, idx) => {
                const requiredField = requiredFieldTemplate + key
                fieldsToSend[requiredField] = targetData[key]
            })
            await updateOrg(organization._id, fieldsToSend)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading(false)
            triggerToast("Services updated successfully", "success", { autoClose: 3000 })
            setServicesOperationPopUpTrigger(false)
        } catch (err) {
            setIsLoading(false)
            triggerToast("Some error occured", "error", { autoClose: 5000 })
        }
    }

    /**
     * 
     * @param {{service : String, specialization : [String]}} targetData 
     * @param {function} setIsLoading 
     */
    async function addNewService(targetData, setIsLoading = () => { }) {
        try {
            setIsLoading(true)
            const newData = [targetData, ...servicesAndSpecialization]
            const fields = {
                "settings.servicesAndSpecialization": newData
            }
            await updateOrg(organization._id, fields)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading(false)
            triggerToast("New service added successfully", "success", { autoClose: 3000 })
            setServicesOperationPopUpTrigger(false)
        } catch (err) {
            setIsLoading(false)
            triggerToast("Some error occured", "error", { autoClose: 5000 })
        }
    }

    function saveClickController(operationPopUpMode, idx, targetValue, setIsLoading) {
        if (operationPopUpMode === "edit") {
            editCurrentService(idx, targetValue, setIsLoading)
        } else if (operationPopUpMode === "add") {
            addNewService(targetValue, setIsLoading)
        }
    }


    return (
        <div className={styles.container}>
            <div className={styles.sectionOne}>
                <div className={styles.heading}>
					<h3>Services {"&"} Topics</h3>
					<span>
						Add your core offerings and associated topics.
						<a>&nbsp;Learn More</a>
					</span>
                </div>
                <div className={styles.action}>
                    <button className={styles.addButton} onClick={(e) => { setServicesOperationPopUpTrigger(true); setServicesOperationPopUpMode("add"); setServicesDataToEdit(null) }} disabled={!isEnabledServices}>
                        <AddIcon />
                        {
                            onlyShowAddIcon ?
                                null : <div className={styles.addButtonTextMobile}>Add Service</div>
                        }
                        <div className={styles.addButtonTextDesktop}>
                            Add Service
                        </div>
                    </button>
                </div>

            </div>
            {
                !isEnabledServices ?
                    <div className={styles.warningText}>
                        <CautionIcon height={"12px"} width={"14px"} fillColor="#FFC56D" exclamationColour="#25335A" />
                        <>You’ve reached the limits of your plan. Upgrade your subscription plan to add more services.</>
                    </div>

                    : <></>
            }
            <div className={styles.editorContainer}>
                {
                    servicesAndSpecialization && servicesAndSpecialization.map((item, idx) => (
                        <SettingsEditor idx={idx} isEnabled={!item.pause} heading={item.service} items={item.specialization.map((_val) => ({ displayTitle: _val }))} subHeader={"Topics"} handleToggleClick={handleToggleClick} setEditPopUpTrigger={setServicesOperationPopUpTrigger} setEditPopUpMode={setServicesOperationPopUpMode} handleEditButtonClick={handleEditButtonClick} h4CustomStyle={{maxWidth : "766px"}} />
                    ))
                }
            </div>

            {
                setServicesOperationPopUpTrigger ?
                    <ServicesOperationPopUp trigger={servicesOperationPopUpTrigger} setTrigger={setServicesOperationPopUpTrigger} popUpOperationMode={servicesOperationPopUpMode} serviceData={servicesDataToEdit} handleSaveClick={(idx, targetValue, setIsLoading) => saveClickController(servicesOperationPopUpMode, idx, targetValue, setIsLoading)} />
                    : <></>
            }
        </div>
    )
}

export default ServicesTopics
