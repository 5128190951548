import DeleteIcon from '../../DeleteIcon/DeleteIcon'
import styles from './SummaryItem.module.css'

//Summary Object Format
/* ?? is optional text
 [
    {   
        type : ["add-ons","top-ups","tutors","base-package"] //Required, add-ons->returns summary item as per Figma for add-ons
                                                        top-ups->return summary item as per Figma fo top-ups
        sectionTitle : "",
        children : [
            {   
                title : {},
                helperText : {
                    old : ?,
                    new : ,
                    billing : ,
                    oldStyle : ??,
                    newStyle : ??
                },
                //->For Package type only 
                packageText : {
                    old : 
                    new :
                }
                priceText : {
                    main : ,
                    old : ,
                    helper : ??,
                    mainFormat : ??
                    type : [upg,dwg]//upgrade & downgrade,
                    oldBillingPeriod : .
                    billingPeriod : ?? 
                },
                metadata : {
                    idForAction : //will be sent back in the event in case of onclick
                }
                onDeleteClick : callback with event argument
            }
        ]
    }
]
 */



const SummaryItem = ({ type, title, priceText, packageText,helperText, metadata, onDeleteClick, hasBorder,hideDelete, oldBillingPeriod }) => {

    if (type === "top-ups") {
        return (
            <div className={`${styles.container} ${hasBorder ? styles.hasBorder : ""}`}>
                <div className={styles.title}>
                    <div>{title}</div>
                    <button className='border-none bg-none' id={metadata.idForAction} onClick={(e) => onDeleteClick(e)}><DeleteIcon className={styles.icon} /></button>
                </div>
                <div className={styles.price}>
                    <span className={`${styles.main} ${priceText.mainFormat}`}>{priceText.main}</span>
                    {
                        priceText.billingPeriod ?
                            <span className={`${styles.secondary}`}>{priceText.billingPeriod}</span>
                            : <></>
                    }
                </div>
                <div className={styles.helper}>
                    {
                        helperText.old ?
                            <div className={`${styles.old} ${helperText.oldFormat}`}>{helperText.old}</div>
                            : <></>
                    }
                    <div className={`${styles.new} ${helperText.newFormat}`}>{helperText.new}</div>
                </div>
                {
                    priceText.helper ?
                        <div className={styles.price}>
                            <div className={styles.helper}>
                                <div className={styles[priceText.type]}>
                                    {priceText.helper}
                                </div>
                            </div>
                        </div>
                        : <></>
                }

            </div>
        )
    } else if (type === "add-ons") {
        return (
            <div className={`${styles.containerB} ${hasBorder ? styles.hasBorder : ""}`}>
                <div className={`${styles.title} ${styles.titleB}`}>
                    {title}
                    {
                        !hideDelete ? <button className='border-none bg-none' id={metadata.idForAction} onClick={(e) => onDeleteClick(e)}><DeleteIcon className={`${styles.icon} pointer-events-none`} /></button> : <></>
                    }
                    
                </div>
                <div className={`${styles.helperB}`}>
                    <div className={`${styles.old}`}>
                        {helperText.old}
                    </div>
                </div>
                <div className={`${styles.price}`}>
                    <span className={`${styles.secondary}`}>{priceText.old}{priceText.oldBillingPeriod}</span>
                </div>

                <div className={`${styles.helperB}`}>
                    <div className={`${styles.new}`}>
                        {helperText.new}
                    </div>
                </div>
                <div className={`${styles.price}`}>
                    <span className={`${styles.main}`}>{priceText.main}</span><span className={`${styles.mod}`}>{priceText.billingPeriod}</span>
                </div>

                <div className={`${styles.helper}`}>
                    <span className={`${styles.main}`}>{priceText.type === "upg" ? "Prorated charges apply from today" : "New rates apply from next billing cycle"}</span>
                </div>
                <div className={`${styles.price}`}>
                    <div className={styles.helper}>
                        <div className={styles[priceText.type]}>
                            {priceText.helper}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (type === "base-package") {
        return(
            <div className={`${styles.containerC}`}>

                <div className={`${styles.title} ${styles.titleC}`}>
                    <div>{title}</div>
                </div>

                <div className={`${styles.title} ${styles.mod}` }>
                    {packageText.old}
                </div>
                <div className={`${styles.price}`}>
                    <div className={`${styles.secondary}`}>
                        {priceText.old}{priceText.oldBillingPeriod}
                    </div>
                </div>
                <div className={`${styles.helper}`}>
                    <div className={`${styles.old}`}>
                        {helperText.old}
                    </div>
                </div>
                <div>&nbsp;</div>

                <div className='col-span-2 h-[8px]'>&nbsp;</div>
                    
                <div className={`${styles.title}`} >
                    {packageText.new}
                    <button className='border-none bg-none' id={metadata.idForAction} onClick={(e) => onDeleteClick(e)} style={{display : hideDelete ? "none" : ""}}><DeleteIcon className={styles.icon} /></button>
                </div>
                <div className={`${styles.price}`}>
                    <div className={`${styles.new}`}>
                        {priceText.main}{priceText.billingPeriod}
                    </div>
                </div>
                <div className={`${styles.helperC}`}>
                    <div className={`${styles.old}`}>
                        {helperText.new}
                    </div>
                </div>
                <div className={`${styles.price}`}>
                    <div className={styles.helper}>
                        <div className={styles[priceText.type]}>
                            {priceText.helper}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default SummaryItem
