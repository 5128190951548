import React from 'react'

const LoaderIcon = (props) => {
    return (
            <svg  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={`animate-spin ${props.className}`}>
                <mask id="mask0_28219_50429" style={{ "mask-type": " alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                    <path d="M0 10C0 9.21102 0.639593 8.57143 1.42857 8.57143C2.21755 8.57143 2.85714 9.21102 2.85714 10C2.85714 13.9449 6.05511 17.1429 10 17.1429C13.9449 17.1429 17.1429 13.9449 17.1429 10C17.1429 6.05511 13.9449 2.85714 10 2.85714C9.21102 2.85714 8.57143 2.21755 8.57143 1.42857C8.57143 0.639593 9.21102 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="currentColor" />
                </mask>
                <g mask="url(#mask0_28219_50429)">
                    <path d="M0 10C0 9.21102 0.639593 8.57143 1.42857 8.57143C2.21755 8.57143 2.85714 9.21102 2.85714 10C2.85714 13.9449 6.05511 17.1429 10 17.1429C13.9449 17.1429 17.1429 13.9449 17.1429 10C17.1429 6.05511 13.9449 2.85714 10 2.85714C9.21102 2.85714 8.57143 2.21755 8.57143 1.42857C8.57143 0.639593 9.21102 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="currentColor" />
                </g>
            </svg>
    )
}

export default LoaderIcon