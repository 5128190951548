import React from "react";
import Selector from "./Selector";

// const Members = {
//   Available: [
//     {
//       id: "a1b2c3d4e5f6g7h8i9j0",
//       name: "John Doe",
//       email: "johndoe@example.com",
//       profile: "",
//     },
//     {
//       id: "k1l2m3n4o5p6q7r8s9t0",
//       name: "Jane Smith",
//       email: "janesmith@example.com",
//       profile: "",
//     },
//     {
//       id: "u1v2w3x4y5z6a7b8c9d0",
//       name: "Michael Johnson",
//       email: "michaeljohnson@example.com",
//       profile: "",
//     },
//     {
//       id: "e1f2g3h4i5j6k7l8m9n0",
//       name: "Emily Davis",
//       email: "emilydavis@example.com",
//       profile: "",
//     },
//     {
//       id: "o1p2q3r4s5t6u7v8w9x0",
//       name: "David Brown",
//       email: "davidbrown@example.com",
//       profile: "",
//     },
//   ],

//   Unavailable: [
//     {
//       id: "y1z2a3b4c5d6e7f8g9h0",
//       name: "Robert Wilson",
//       email: "robertwilson@example.com",
//       profile: "",
//     },
//     {
//       id: "i1j2k3l4m5n6o7p8q9r0",
//       name: "Maria Garcia",
//       email: "mariagarcia@example.com",
//       profile: "",
//     },
//     {
//       id: "s1t2u3v4w5x6y7z8a9b0",
//       name: "James Martinez",
//       email: "jamesmartinez@example.com",
//       profile: "",
//     },
//     {
//       id: "c1d2e3f4g5h6i7j8k9l0",
//       name: "Linda Anderson",
//       email: "lindaanderson@example.com",
//       profile: "",
//     },
//     {
//       id: "m1n2o3p4q5r6s7t8u9v0",
//       name: "Charles Lee",
//       email: "charleslee@example.com",
//       profile: "",
//     },
//     {
//       id: "w1x2y3z4a5b6c7d8e9f0",
//       name: "Patricia Harris",
//       email: "patriciaharris@example.com",
//       profile: "",
//     },
//     {
//       id: "g1h2i3j4k5l6m7n8o9p0",
//       name: "Christopher Clark",
//       email: "christopherclark@example.com",
//       profile: "",
//     },
//     {
//       id: "q1r2s3t4u5v6w7x8y9z0",
//       name: "Barbara Lewis",
//       email: "barbaralewis@example.com",
//       profile: "",
//     },
//     {
//       id: "a1b2c3d4e5f6g7h8i9j0",
//       name: "Paul Walker",
//       email: "paulwalker@example.com",
//       profile: "",
//     },
//     {
//       id: "k1l2m3n4o5p6q7r8s9t0",
//       name: "Nancy Hall",
//       email: "nancyhall@example.com",
//       profile: "",
//     },
//   ],
// };

// const Students = {
//     Available: [
//       {
//         id: "z1x2c3v4b5n6m7k8l9j0",
//         name: "Alice Cooper",
//         email: "alicecooper@example.com",
//         profile: "",
//       },
//       {
//         id: "a1s2d3f4g5h6j7k8l9m0",
//         name: "Bob Marley",
//         email: "bobmarley@example.com",
//         profile: "",
//       },
//       {
//         id: "q1w2e3r4t5y6u7i8o9p0",
//         name: "Charlie Sheen",
//         email: "charliesheen@example.com",
//         profile: "",
//       },
//       {
//         id: "i1o2p3a4s5d6f7g8h9j0",
//         name: "Diana Prince",
//         email: "dianaprince@example.com",
//         profile: "",
//       },
//       {
//         id: "y1u2i3o4p5a6s7d8f9g0",
//         name: "Edward Cullen",
//         email: "edwardcullen@example.com",
//         profile: "",
//       },
//       {
//         id: "m1n2b3v4c5x6z7a8s9d0",
//         name: "Fiona Apple",
//         email: "fionaapple@example.com",
//         profile: "",
//       },
//       {
//         id: "k1l2j3h4g5f6d7s8a9z0",
//         name: "George Clooney",
//         email: "georgeclooney@example.com",
//         profile: "",
//       },
//       {
//         id: "r1t2y3u4i5o6p7a8s9d0",
//         name: "Hannah Montana",
//         email: "hannahmontana@example.com",
//         profile: "",
//       },
//       {
//         id: "e1r2t3y4u5i6o7p8a9s0",
//         name: "Iris West",
//         email: "iriswest@example.com",
//         profile: "",
//       },
//       {
//         id: "w1e2r3t4y5u6i7o8p9a0",
//         name: "Jack Sparrow",
//         email: "jacksparrow@example.com",
//         profile: "",
//       }
//     ],
  
//     Unavailable: [
//       {
//         id: "u1i2o3p4a5s6d7f8g9h0",
//         name: "Karen Gillan",
//         email: "karengillan@example.com",
//         profile: "",
//       },
//       {
//         id: "o1p2a3s4d5f6g7h8j9k0",
//         name: "Liam Hemsworth",
//         email: "liamhemsworth@example.com",
//         profile: "",
//       },
//       {
//         id: "n1b2v3c4x5z6a7s8d9f0",
//         name: "Mia Wasikowska",
//         email: "miawasikowska@example.com",
//         profile: "",
//       },
//       {
//         id: "t1y2u3i4o5p6a7s8d9f0",
//         name: "Noah Centineo",
//         email: "noahcentineo@example.com",
//         profile: "",
//       },
//       {
//         id: "p1o2i3u4y5t6r7e8w9q0",
//         name: "Olivia Wilde",
//         email: "oliviawilde@example.com",
//        profile: "",
//       },
//       {
//         id: "l1k2j3h4g5f6d7s8a9z0",
//         name: "Peter Parker",
//         email: "peterparker@example.com",
//        profile: "",
//       },
//       {
//         id: "x1c2v3b4n5m6a7s8d9f0",
//         name: "Quentin Tarantino",
//         email: "quentintarantino@example.com",
//        profile: "",
//       }
//     ]
//   };
  

const MemberStudentSelector = ({submitData,setSubmitData, customPopupMessage, studentsData, membersData}) => {
   return (
    <main className=" w-full flex gap-[8px] md:gap-[16px] h-full overflow-auto justify-between ">
      <Selector id={"members"} Data={membersData} submitData={submitData} setSubmitData={setSubmitData} customPopupMessage={customPopupMessage} />
      <Selector id={"students"} Data ={studentsData} submitData={submitData} setSubmitData={setSubmitData} customPopupMessage={customPopupMessage} />
    </main>
  );
};

export default MemberStudentSelector;
