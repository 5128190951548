import React, { useState, useEffect } from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import TooltipIcon from "../../../assets/icons/SettingsIcon/TooltipIcon";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";
import { useSelector } from "react-redux";
import DayAvailability from "./components/DayAvailability";
import ChangeUserModal from "./components/ChangeUserModal";
import ChangeUserPopUp from "./components/ChangeUserPopUp";
import LoaderIcon from "../../../components/LoaderIcon/LoaderIcon";
import StubbyLeftArrow from '../../../components/StubbyLeftArrow/StubbyLeftArrow'
import CpoyIcon from "../../../assets/calendarV2/CopyIcon"
import { triggerToast } from "../../../utils/toastController";
import { updateUserSchedulePreference } from "../../../utils/userService";

// Function to convert time string to milliseconds
const convertTimeToMilliseconds = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  }

  if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
};

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// Default state for days
const defaultDaysState = daysOfWeek.map((day) => ({
  day,
  availability: [{ startTime: "10:00 AM", endTime: "05:00 PM" }],
  checked: false,
}));

// Function to format time string with underscores
const formatTimeString = (timeStr) => {
  return timeStr.replace(/[:\s]/g, "_");
};

const Availability = ({handleBackClick}) => {
  const [days, setDays] = useState(defaultDaysState);
  const [openChangeUserModal, setOpenChangeUserModal] = useState(false);
  const [openChangeUserPopUp, setOpenChangeUserPopUp] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const { firstName, lastName, email, id, scheduleDetails, role } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    setSelectedUser({
      _id: id,
      value: `${firstName} ${lastName}`,
      email: email,
      role: role,
      scheduleDetails: scheduleDetails ? scheduleDetails?.preferences : null,
    });
  }, []);

  useEffect(() => {
    if (selectedUser?.scheduleDetails) {
      const initialDays = daysOfWeek.map((dayOfWeek) => {
        const foundDay = selectedUser.scheduleDetails.find(
          (day) => day.day.toLowerCase() === dayOfWeek.slice(0, 3).toLowerCase()
        );
        if (foundDay) {
          return {
            day: dayOfWeek,
            availability: foundDay.shifts.map((shift) => ({
              startTime: shift.startTime.strRep
                .replace(/_/g, ":")
                .replace(/:([APM]{2})$/, " $1"),
              endTime: shift.endTime.strRep
                .replace(/_/g, ":")
                .replace(/:([APM]{2})$/, " $1"),
            })),
            checked: true,
          };
        } else {
          return {
            day: dayOfWeek,
            availability: [{ startTime: "10:00 AM", endTime: "05:00 PM" }],
            checked: false,
          };
        }
      });
      setDays(initialDays);
    } else {
      setDays(defaultDaysState);
    }
  }, [selectedUser?.scheduleDetails]);

  const handleCopySlot = (day) => {
    const dayData = days.find((d) => d.day === day).availability;

    setDays((prevDays) =>
      prevDays.map((d) => {
        if (d.checked) {
          return { ...d, availability: [...dayData] };
        }
        return d;
      })
    );
  };

  const handleAddSlot = (day) => {
    setDays((prevDays) =>
      prevDays.map((d) =>
        d.day === day
          ? {
              ...d,
              availability: [
                ...d.availability,
                { startTime: "10:00 AM", endTime: "05:30 PM" },
              ],
            }
          : d
      )
    );
  };

  const handleDeleteSlot = (day, index) => {
    setDays((prevDays) =>
      prevDays.map((d) =>
        d.day === day
          ? { ...d, availability: d.availability.filter((_, i) => i !== index) }
          : d
      )
    );
  };

  const handleTimeChange = (day, index, type, value) => {
    setDays((prevDays) =>
      prevDays.map((d) =>
        d.day === day
          ? {
              ...d,
              availability: d.availability.map((slot, i) =>
                i === index ? { ...slot, [type]: value } : slot
              ),
            }
          : d
      )
    );
  };

  const handleCheck = (day) => {
    setDays((prevDays) =>
      prevDays.map((d) => (d.day === day ? { ...d, checked: !d.checked } : d))
    );
  };

  const resetSlotsForDay = (day) => {
    setDays((prevDays) =>
      prevDays.map((d) =>
        d.day === day
          ? {
              ...d,
              availability: [{ startTime: "10:00 AM", endTime: "05:00 PM" }],
              checked: false,
            }
          : d
      )
    );
  };

  const handleUserChange = (user) => {
    setSelectedUser(user);
  };

  const handleSaveUserSchedule = async () => {
    setLoading(true);
    const transformedSchedule = days
      .filter((day) => day.checked) // Filter only the checked days
      .map((day) => ({
        day: day.day.slice(0, 3).toLowerCase(), // Transform "Sunday" to "sun", "Monday" to "mon" and so on
        shifts: day.availability.map((availability) => ({
          startTime: {
            numRep: convertTimeToMilliseconds(availability.startTime),
            strRep: formatTimeString(availability.startTime), // Format time string with underscores
          },
          endTime: {
            numRep: convertTimeToMilliseconds(availability.endTime),
            strRep: formatTimeString(availability.endTime), // Format time string with underscores
          },
        })),
      }));

    const response = await updateUserSchedulePreference(
      selectedUser,
      transformedSchedule
    );

    if (response.data.updatedUser) {
      setLoading(false);
      triggerToast(
        <div>{selectedUser?.value}'s schedule updated Successfully!</div>,
        "success",
        {
          autoClose: 2000,
        }
      );
    } else {
      setLoading(false);
      triggerToast(
        <div>Error updating {selectedUser?.value}'s schedule</div>,
        "error",
        {
          autoClose: 3000,
        }
      );
    }
  };

  return (
    <div className="grid gap-[24px] md:gap-[36px] text-secondary md:w-full p-[5px] py-[21px] px-[16px] md:py-[36px] md:px-[24px]">
      <div className="grid gap-[24px] md:flex justify-between md:gap-[6px] font-[600] text-[20px] leading-[30px] items-center">
        <div className="grid gap-[8px]">
          <div className="flex items-center gap-[6px]">
          <button className="" onClick={(e)=>handleBackClick(e)}><StubbyLeftArrow /></button>
            <div>Availability</div>
            <Tooltip
              anchorComponent={
                <div className="mt-[2px]">
                  <TooltipIcon height={14} width={14} />
                </div>
              }
              tooltipContent="content"
              tooltipStyleClass="bg-white rounded p-[12px] leading-[18px] font-[400] text-[12px] w-[320px] -mt-[6px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"
              tooltipTriangleStyling="mt-[2px] ml-[1px]"
            />
          </div>
          <div className="font-[400] text-[16px] leading-[24px] text-secondary-60">
            {role === "admin"
              ? " Set your availability for appointment scheduling. These reflect on your time blocks and booking link."
              : "Set your availability for scheduling events."}
          </div>
        </div>
        <div className="flex items-center gap-[12px]">
          {role === "admin" && (
            <>
              <div className="hidden md:block md:relative">
                <div
                  className="flex h-[40px] border-[1px] border-blue-70 text-blue-70 cursor-pointer w-fit text-center px-[20px] py-[10px] gap-[8px] items-center font-[500] rounded-[6px] justify-center text-[16px]"
                  onClick={() => {
                    console.log("clicked!!");
                    setOpenChangeUserModal(true);
                  }}
                >
                  Change User
                </div>

                {openChangeUserModal && (
                  <ChangeUserModal
                    openModal={openChangeUserModal}
                    setOpenModal={setOpenChangeUserModal}
                    onUserChange={handleUserChange}
                  />
                )}
              </div>
              {/* change user responsive */}
              <div
                className="flex md:hidden h-[40px] border-[1px] border-blue-70 text-blue-70 cursor-pointer w-fit text-center px-[20px] py-[10px] gap-[8px] items-center font-[500] rounded-[6px] justify-center"
                onClick={() => {
                  console.log("clicked!!");
                  setOpenChangeUserPopUp(true);
                }}
              >
                Change User
              </div>
            </>
          )}
          <button
            className={`hidden md:flex ${
              selectedUser === null
                ? "pointer-events-none bg-blue-70/20 cursor-not-allowed"
                : "bg-blue-70 cursor-pointer"
            }w-full text-white text-[16px] leading-[20px] text-center px-[20px] py-[10px] gap-[8px] items-center font-[600] rounded-[6px] justify-center shadow-[0px_4.05px_12px_0px_#00000026]`}
            onClick={() => {
              handleSaveUserSchedule();
            }}
          >
            {loading ? <LoaderIcon /> : "Save"}
          </button>
        </div>
      </div>

      <div className="flex gap-[12px] items-center">
        {selectedUser?.value && (
          <>
            <div className="text-secondary font-[400] text-[16px] leading-[24px]">
              Setting Availability for
            </div>
            <UserAvatar name={selectedUser.value} />
          </>
        )}
      </div>

      <div className="grid gap-[24px] h-fit md:max-h-[75vh] md:w-full md:overflow-y-auto">
        <div className="flex px-[16px] py-[8px] w-full md:w-[60%] bg-[#F7F8F9] rounded-[6px] text-secondary text-[16px] font-[500] leading-[24px]">
          <div className="w-[32%]">Day</div>
          <div className="w-[28%]">Start Time</div>
          <div className="w-[40%]">End Time</div>
        </div>
        <div className="grid gap-[20px] w-full md:w-[60%]">
          {days.map((day) => (
            <React.Fragment key={day.day}>
              <DayAvailability
                day={day.day}
                availability={day.availability}
                checked={day.checked}
                onCheck={handleCheck}
                onAddSlot={handleAddSlot}
                onDeleteSlot={handleDeleteSlot}
                onTimeChange={handleTimeChange}
                onCopySlot={handleCopySlot}
                resetSlotsForDay={resetSlotsForDay}
              />
              <div className="min-h-[1px] bg-secondary-10"></div>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="flex items-center">
        <button
          className={`flex md:hidden ${
            selectedUser === null
              ? "pointer-events-none bg-blue-70/20 cursor-not-allowed"
              : "bg-blue-70 cursor-pointer"
          } w-full text-white text-[16px] leading-[20px] text-center px-[20px] py-[10px] gap-[8px] items-center font-[600] rounded-[6px] justify-center`}
          onClick={() => {
            handleSaveUserSchedule();
          }}
        >
          {loading ? <LoaderIcon /> : "Save"}
        </button>
      </div>

      {openChangeUserPopUp && (
        <ChangeUserPopUp
          open={openChangeUserPopUp}
          setOpen={setOpenChangeUserPopUp}
          onUserChange={handleUserChange}
        />
      )}
    </div>
  );
};

export default Availability;
