import React, { useState } from "react";
import styles from "./MobileAttendeeSelector.module.css";
import "../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import { Radio } from "@mui/material";
import SelectStudent from "../AttendeeSubComponents/SelectStudent/SelectStudent";
import MobileSelectStudentDropdown from "../AttendeeSubComponents/MobileSelectStudent/MobileSelectStudent";
import MobileSelectTutor from "../AttendeeSubComponents/MobileSelectTutor/MobileSelectTutor";
import GroupSelect from "../AttendeeSubComponents/GroupSelect/GroupSelect";

const MobileAttendeeSelector = (props) => {
  const [selectedOption, setSelectedOption] = useState("student");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className={styles.schedulingANew}>
      <div className={styles.frame}>
        <div className={styles.userCategory}>
          <div className={styles.textWrapper}>This session is for:</div>
          <div className={styles.div}>
            <div className={styles.frame2}>
              <Radio
                className={styles.radioButton}
                checked={selectedOption === "student"}
                value="student"
                onChange={handleRadioChange}
              />
              <div
                className={
                  selectedOption === "student"
                    ? styles.textWrapper2
                    : styles.textWrapper
                }
              >
                Student
              </div>
            </div>
            <div className={styles.frame2}>
              <Radio
                className={styles.radioButton}
                checked={selectedOption === "studentGroup"}
                value="studentGroup"
                onChange={handleRadioChange}
              />
              <div
                className={
                  selectedOption === "studentGroup"
                    ? styles.textWrapper2
                    : styles.textWrapper
                }
              >
                Student Group
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frame3}>
          {/* <div className={styles.inputField} onClick={toggleDropdown}>
            <div className={styles.textWrapper3}>Select Student*</div>
            <img
              className={styles.inputFieldIcon}
              src="/images/down-arrow.svg"
              alt="Input Field Icon"
            />
          </div> */}
          {selectedOption && selectedOption === "student" ? (
            <MobileSelectStudentDropdown />
          ) : (
            <GroupSelect {...props} />
          )}

          {/* <div className={styles.inputField}>
            <div className={styles.textWrapper3}>Select Tutor*</div>
            <img
              className={styles.inputFieldIcon}
              src="/images/down-arrow.svg"
              alt="Input Field Icon"
            />
          </div> */}
          <MobileSelectTutor />
        </div>
        {/* {isDropdownVisible && <MobileSelectStudentDropdown />} */}
      </div>
    </div>
  );
};

export default MobileAttendeeSelector;
