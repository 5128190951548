import { useState } from 'react'
import { getFormattedDateV2 } from '../../utils/dateUtils'
import SortArrowDownIcon from '../SortArrowIcons/SortArrowDownIcon'
import styles from './AssignTutorLicensesTable.module.css'
import SortArrowUpIcon from '../SortArrowIcons/SortArrowUpIcon'


function getSortArrowIconDependingOnState(tutorSortedStatesData,key) {
    if(tutorSortedStatesData[key]=== -1) {
        console.log("HERE")
        return(<SortArrowDownIcon className="pointer-events-none" />)
        
    } else {
        console.log("HERE 2")
        return(<SortArrowUpIcon  className="pointer-events-none" />)
    }
}


const AssignTutorLicensesTable = ({tutorData,onActivateClicked=()=>{},onDeactivateClicked=()=>{},setParentViewTutorSortedStatesData}) => {


    const [tutorSortedStatesData, setTutorSortedStatesData] = useState({
        tutorName : -1,
        studentCount : -1,
        dateAdded : -1,
        lastLogin : -1,
        licenseStatus : -1
    })

    const handleSortArrowClicked = (e) => {

        const tempTutorSortedStatesData = {...tutorSortedStatesData}
        tempTutorSortedStatesData[e.target.id] = tempTutorSortedStatesData[e.target.id] === -1 ? 1 : -1
        setTutorSortedStatesData(tempTutorSortedStatesData)
        setParentViewTutorSortedStatesData(tempTutorSortedStatesData)
    }


    return(
        <div className={styles.container}>
            <table>
                    <tr className={styles.tableHeaderRow}>
                        <td>S.No</td>
                        <td><div className='flex gap-[8px] items-center justify-center'><>Tutor Name <button id="tutorName" onClick={(e)=>handleSortArrowClicked(e)}>{getSortArrowIconDependingOnState(tutorSortedStatesData,"tutorName")}</button></></div></td>
                        <td><div className='flex gap-[8px] items-center justify-center'><>Students <button id="studentCount" onClick={(e)=>handleSortArrowClicked(e)}>{getSortArrowIconDependingOnState(tutorSortedStatesData,"studentCount")}</button></></div></td>
                        <td><div className='flex gap-[8px] items-center justify-center'><>Date Added <button id="dateAdded" onClick={(e)=>handleSortArrowClicked(e)}>{getSortArrowIconDependingOnState(tutorSortedStatesData,"dateAdded")}</button></></div></td>
                        <td><div className='flex gap-[8px] items-center justify-center'><>Last Login <button id="lastLogin" onClick={(e)=>handleSortArrowClicked(e)}>{getSortArrowIconDependingOnState(tutorSortedStatesData,"lastLogin")}</button></></div></td>
                        <td><div className='flex gap-[8px] items-center justify-center'><>License Status<button id="licenseStatus" onClick={(e)=>handleSortArrowClicked(e)}>{getSortArrowIconDependingOnState(tutorSortedStatesData,"licenseStatus")}</button></></div></td>
                    </tr>
                    <tbody>
                        {
                            tutorData?.map((val,idx)=>{
                                return(
                                    <tr className={`${styles.tableBodyRow} ${val.isActive ? styles.active : styles.inactive}`} key={"asTutorLicense_"+idx}>
                                    <td>{idx+1 < 10 ? "0"+(idx+1) : idx+1}</td>
                                    <td>{val.name}</td>
                                    <td>{val.studentCount}</td>
                                    <td>{getFormattedDateV2(val.createdAt, "dd mm, yyyy")}</td>
                                    <td>{val.lastLogin ? getFormattedDateV2(val.lastLogin, "dd mm, yyyy") : "-"}</td>
                                    <td>
                                        <div className='flex gap-[8px]'>
                                            <div className={`${val.isActive ? styles.active : styles.inactive} flex gap-[8px] items-center justify-center w-full`}>
                                                {val.isActive ? 
                                                            <div className='px-[25px] py-[8px] rounded-[100px] bg-green-10 font-[400] text-success border-[1px] border-green-70 text-[15px]'>Active</div> : 
                                                            <div className='px-[17.5px] py-[8px] rounded-[100px] bg-white !text-secondary-20 !border-[1px] !text-[15px] !font-[400] !border-secondary-20'>Inactive</div>}
                                                {val.isActive ? <button className='bg-transparent underline text-blue-70' onClick={(e)=>onDeactivateClicked(e)} id={val._id}>Deactivate</button> : <button className='bg-transparent underline text-blue-70' onClick={(e)=>onActivateClicked(e)} id={val._id}>Activate</button> }
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                )
                            })
                        }
                    </tbody>
            </table>
            
        </div>
    )
}


export default AssignTutorLicensesTable