import { useContext, useEffect, useState } from 'react'
import ArrowIconLeftV2 from '../../ArrowIconLeftV2/ArrowIconLeftV2'
import ArrowIconRightV2 from '../../ArrowIconRightV2/ArrowIconRightV2'
import ButtonV2 from '../../ButtonV2/ButtonV2'
import styles from './OTPExpiredScreen.module.css'
import wrongIndicator from './WrongIndicator.svg'
import { useOutletContext } from 'react-router-dom'
import OrgSignupContext from '../../../context/OrgSignupContext'
import { useStartEmailVerificationMutation } from '../../../app/services/orgSignup'
import { useNavigate } from 'react-router-dom'

const OTPExpiredScreen = (props) => {

    const { userEmail, setUserEmail } = useContext(OrgSignupContext)
    const { setSignupStage } = useOutletContext()
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [startEmailVerification, startEmailVerificationResp] = useStartEmailVerificationMutation()
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setSignupStage("emailNotVerified");
        const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        setIsEmailValid(emailFormat.test(userEmail))
    }, [])

    const handleFormChange = (e) => {
        if (e.target.name === "email") {
            const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            setIsEmailValid(emailFormat.test(e.target.value.trim()))
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        const email = e.target.email.value.trim()
        setIsLoading(true)
        setUserEmail(email)
        startEmailVerification(({ email: email }))
            .then((res) => {
                if (res.error) {
                    if (res.error.status === 400) {
                        toast.info("Account already verified please login directly or reset password to access your account", { autoClose: 5000 })
                    }
                } else {
                    navigate("../verify-email")
                }
                setIsLoading(false)
            })
    }




    return (
        <div className={styles.container}>
            <img src={wrongIndicator} className={styles.wrongIndicator} />
            <div className={styles.messageContainer}>
                <h3>Your one-time code has expired</h3>
                <div>Please confirm the email address to resend the verification code. </div>
            </div>
            <form className={styles.verifyForm} onChange={(e) => handleFormChange(e)} onSubmit={(e) => handleFormSubmit(e)}>
                <input type="email" className={styles.emailInput} name="email" placeholder="Enter your email address" defaultValue={userEmail} key={userEmail ? "yes" : "no"} />
                <ButtonV2 disabled={!isEmailValid}>
                    {
                        !isLoading ?
                            <div className={styles.verifyButtonContent}>
                                <div>Verify Email</div>
                            </div> :
                            <>Loading ...</>
                    }

                </ButtonV2>
            </form>
            <button className={styles.backButton} onClick={(e)=>navigate("/signup/org")}>
                <div className={styles.content}>
                    <div className={styles.arrowIcon}><ArrowIconLeftV2 /></div>
                    <div>Back to signup</div>
                </div>
            </button>
        </div>
    )
}

export default OTPExpiredScreen