import React, { useEffect, useState } from "react";
import { triggerToast } from "../../../utils/toastController";
import SummaryPriceSelectDropdown from "./SummaryPriceSelectDropdown";

const ProductSummaryCard = ({
  name,
  img,
  priceList,
  title,
  selectedProducts,
  setSelectedProducts,
  edit,
  item,
  noOfProducts,
  selectedPlanType,
}) => {
  const [sortedPriceList, setSortedPriceList] = useState(null);

  useEffect(() => {
    if (!priceList) return;
    setSortedPriceList(
      [...priceList].sort((a, b) => a.unit_amount - b.unit_amount)
    );
  }, [priceList]);

  const handleChange = (selectedProductId) => {
    const selectedProduct = priceList.find(
      (price) => price.value === selectedProductId
    );
    if (selectedProduct) {
      setSelectedProducts(() => ({
        ...selectedProducts.selectedProducts,
        selectedProducts: {
          ...selectedProducts.selectedProducts,
          [name]: {
            ...selectedProducts.selectedProducts[name],
            price: selectedProduct.unit_amount / 100,
            selected: true,
            value: selectedProductId,
          },
        },
      }));
    }
  };

  const getAdjustedName = (name) => {
    const numberMatch = name.match(/\d+/);
    if (numberMatch) {
      const number = parseInt(numberMatch[0], 10);
      return selectedPlanType !== 0 ? (number / 12).toFixed(0) : number;
    }
    return name;
  };

  return (
    <div
      className={`flex gap-5 py-[12px] ${
        item === noOfProducts ? "" : "border-b"
      }`}
    >
      <img src={img} className="w-[36px] h-[36px] flex justify-center" />
      <div className="w-full">
        <div>
          <div className="text-left text-[16px] text-secondary-80 font-medium">
            {name}
          </div>
        </div>
        <div className="flex justify-between text-[14px] font-normal text-secondary-60">
          <div>
            {edit ? (
              priceList ? (
                <SummaryPriceSelectDropdown
                  priceList={sortedPriceList}
                  selectedPrice={
                    selectedProducts.selectedProducts[name].value || ""
                  }
                  onChange={handleChange}
                  disabled={!edit}
                  selectedPlanType={selectedPlanType}
                  title={title}
                />
              ) : (
                "Unlimited student accounts"
              )
            ) : (
              <div className="selectedTier">
                {priceList
                  ? selectedProducts?.selectedProducts[name]?.selected &&
                    getAdjustedName(
                      priceList
                        .find(
                          (price) =>
                            price.value ===
                            selectedProducts?.selectedProducts[name]?.value
                        )
                        ?.name.split(" ")[0]
                    ) +
                      " " +
                      title
                  : "Unlimited student accounts"}
              </div>
            )}
          </div>
          <div>
            {name === "CRM" ||
            selectedProducts.selectedProducts[name].price === 0
              ? "Free"
              : selectedProducts.selectedProducts[name].selected
              ? `$${
                  selectedPlanType === 0
                    ? selectedProducts.selectedProducts[name].price
                    : (
                        selectedProducts.selectedProducts[name].price / 12
                      ).toFixed(0)
                }`
              : "$0.00"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSummaryCard;
