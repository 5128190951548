import React, { useState, useEffect } from "react";
import ApplyCoupon from "../../ApplyCouponInput/ApplyCoupon";
import { useLazyCouponValidityQuery } from "../../../app/services/subscription";
import { triggerToast } from "../../../utils/toastController";

const ApplyCouponContainer = ({
  subscriptionCoupon,
  setSubscriptionCoupon,
  discountAmount,
  setDiscountAmount,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [checkCouponValidity, respCheckCouponValidity] =
    useLazyCouponValidityQuery();
  const [coupon, setCoupon] = useState("");
  const [validCoupon, setValidCoupon] = useState(false);

  const applyCouponToSubscription = async () => {
    let response = "";
    try {
      setIsLoading(true)
      response = await checkCouponValidity({
        couponName: coupon,
      });
      if (response?.data?.coupon?.valid) {
        setValidCoupon(true);
        let amount_off = response.data.coupon.amount_off
          ? response.data.coupon.amount_off
          : null;
        let percent_off = response.data.coupon.percent_off
          ? response.data.coupon.percent_off
          : null;
        setSubscriptionCoupon({
          type: amount_off ? "amount" : "percentage",
          couponId: response.data.coupon.id,
          name: response.data.coupon.name,
          amountOff: amount_off,
          percentOff: percent_off,
        });
        setIsLoading(false)
      } else {
        setIsLoading(false)
        triggerToast("Invalid coupon code", "error", { autoClose: 3000 });
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false)
      triggerToast("Invalid coupon code", "error", { autoClose: 3000 });
    }

    if (response.status == "fail") {
      console.log(response.message);
      triggerToast("Invalid coupon code", "error", { autoClose: 3000 });

      return;
    }
  };

  const removeCoupon = () => {
    setSubscriptionCoupon(null);
    setDiscountAmount(null);
    setValidCoupon(false);
    console.log(discountAmount);
    console.log(subscriptionCoupon);
  };
  return (
    <div className="w-full">
      <ApplyCoupon
        coupon={coupon}
        setCoupon={setCoupon}
        valid={validCoupon}
        setValid={setValidCoupon}
        applyCoupon={applyCouponToSubscription}
        discountAmount={discountAmount}
        subscriptionCoupon={subscriptionCoupon}
        removeCoupon={removeCoupon}
        isLoading={isLoading}
      />
      <div
        className="grid gap-[8px] bg-[#0671E0]/10
  text-[14px] text-left p-[12px] my-3 rounded-[6px] "
      >
        <span className="text-blue font-[500]">
          Note:{" "}
          <span className="text-secondary-80 font-[400]">
            Your card won’t be charged until your trial period ends. Read our
            <span className="text-blue-70 font-[600] decoration-none">
              &nbsp;<a href="https://www.evallo.org/tou">Terms of Use</a>
            </span>{" "}
            and{" "}
            <span className="text-blue-70 font-[600] decoration-none">
              <a href="https://www.evallo.org/privacy-policy">Privacy Policy</a>
            </span>
            .
          </span>
        </span>
      </div>
    </div>
  );
};

export default ApplyCouponContainer;
