import { useEffect, useState } from "react";
import EventTopBar from "../EventTopBar/EventTopBar";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import styles from "./EventOperator.module.css";
import frozenEventTypes from "../../utils/eventTypes";
import EventOperatorRenderer from "./EventOperatorRenderer";
import ArrowIconLeftV2 from "../ArrowIconLeftV2/ArrowIconLeftV2";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { useSelector } from "react-redux";
import EventSidebar from "../EventSidebar/EventSidebar";
import VerticalDivider from "../VerticalDivider/VerticalDivider";
import EventSubmission from "../EventSubmisson/EventSubmission";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import { scheduleMapperSetter, setEventData } from "./helpers/setters";
import RequestActionPopup from "../RequestAction/RequestActionPopup";
import DeclineRequestPopUp from "../DeclineRequestPopUp/DeclineRequestPopUp";
import { fetchAllEventRequests, updateEventRequest } from "../../utils/eventService";
import { fetchAndSetAllEventRequests } from "../../pages/CalendarV2/helpers/fetchers";
import { triggerToast } from "../../utils/toastController";


dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);


function EventOperator(props) {
  const {
    operationMode,
    requiredEventType,
    isEditable,
    requiredEventData,
    setTrigger,
    user,
    userTypesAllowedToViewInvoiceDetails,
    selectedEventType,
    setSelectedEventType,
    studentList,
    tutorList,
    groupList,
    isScheduleTrigger,
    setIsScheduleTrigger,
		setAllEventRequests,
		setAllowedEventTypes,
  } = props;

  const [requestPopupInfo, setRequestPopupInfo] = useState({
		trigger : false,
		eventRequestId : "",
		eventRequestDetails : null,
		purpose : ""
	})
  const [selectedDay, setSelectedDay] = useState([]);
  const [frequency, setFrequency] = useState("Weekly");
  const [selectedDays, setSelectedDays] = useState(["S"]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [endDate, setEndDate] = useState();
  const [selectedStartTime, setSelectedStartTime] = useState("07:00 PM");
  const [selectedEndTime, setSelectedEndTime] = useState("08:00 PM");
  const [isRecurring, setIsRecurring] = useState(false);
  const [selectedOption, setSelectedOption] = useState("student");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedTutor, setSelectedTutor] = useState("");
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [isPartialSession, setIsPartialSession] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState("America/New_York");
  const [service, setService] = useState();
  const [topic, setTopic] = useState();
  const [selectedStatus, setSelectedStatus] = useState("SCH");
  const [sessionNotes, setSessionNotes] = useState({
    clientNotes: {
      note: "",
      date: "",
    },
    internalNotes: {
      note: "",
      date: "",
    },
  });
  const [whiteboardLink, setWhiteboardLink] = useState();
  const [sessionTags, setSessionTags] = useState([]);
  const { sessionTags: allSessionTags } = useSelector(
    (state) => state.organization.organization.settings
  );
  const [meetingLink, setMeetingLink] = useState();
  const [location, setLocation] = useState();
  const [isRemainder, setIsRemainder] = useState("");
  const [sendRemindersTo, setSendRemindersTo] = useState({
    student: true,
    parent: true,
    tutor: false,
    attendees: true,
  });
  const [isSelectedUser, setIsSelectedUser] = useState([]);
  const [agendaNotes, setAgendaNotes] = useState({note : "", date: new Date()});
  const [detailsOfWork, setDetailsOfWork] = useState("");
  const [hourlyCost, setHourlyCost] = useState();
  const [sessionCost, setSessionCost] = useState();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [studentStatusMapping, setStudentStatusMapping] = useState({});
  const [attendeeStatusMapping, setAttendeeStatusMapping] = useState({});
  const [selectedMonthlyRecurringDate, setSelectedMonthlyRecurringDate] =
    useState(1);
  //For student session rating
  const [studentSessionRating, setStudentSessionRating] = useState({
    mode: "create",
    rating: null,
  });
  //student session feedback (rating) fetched state
    const [studentSessionFeedback, setStudentSessionFeedback] = useState(null);

  //Redux states
  const { subscriptionV2 } = useSelector((state) => state.subscription);
  const { organization } = useSelector((state) => state.organization);

  const [rendererData, setRendererData] = useState();
  const [infoWork, setInfoWork] = useState("");
  const [studentNotes, setStudentNotes] = useState({})
  const [selectedGroupStudents, setSelectedGroupStudents] = useState([])

  //Required Props
  const eventTopBarProps = {
    setAllowedEventTypes,
    selectedEventType,
    setSelectedEventType,
    operationMode,
    setTrigger,
    subscriptionV2,
    user,
  };

  const rendererProps = {
    selectedDay,
    setSelectedDay,
    frequency,
    setFrequency,
    selectedDays,
    setSelectedDays,
    selectedDate,
    setSelectedDate,
    endDate,
    setEndDate,
    selectedStartTime,
    setSelectedStartTime,
    selectedEndTime,
    setSelectedEndTime,
    isRecurring,
    setIsRecurring,
    isRescheduled,
    setIsRescheduled,
    isPartialSession,
    setIsPartialSession,
    selectedTimeZone,
    setSelectedTimeZone,
    selectedStudents,
    setSelectedStudents,
    service,
    setService,
    topic,
    setTopic,
    selectedStatus,
    setSelectedStatus,
    whiteboardLink,
    setWhiteboardLink,
    allSessionTags,
    sessionTags,
    setSessionTags,
    sessionNotes,
    setSessionNotes,
    meetingLink,
    setMeetingLink,
    location,
    setLocation,
    isRemainder,
    setIsRemainder,
    sendRemindersTo,
    setSendRemindersTo,
    isSelectedUser,
    setIsSelectedUser,
    agendaNotes,
    setAgendaNotes,
    hourlyCost,
    setHourlyCost,
    sessionCost,
    setSessionCost,
    selectedTutor,
    setSelectedTutor,
    selectedOption,
    setSelectedOption,
    selectedGroup,
    setSelectedGroup,
    infoWork,
    setInfoWork,
    studentStatusMapping,
    setStudentStatusMapping,
    attendeeStatusMapping,
    setAttendeeStatusMapping,
    selectedMonthlyRecurringDate,
    setSelectedMonthlyRecurringDate,
    studentSessionRating,
    setStudentSessionRating,
    studentSessionFeedback, setStudentSessionFeedback,
	studentNotes, setStudentNotes,
	selectedGroupStudents, setSelectedGroupStudents
  };

  // Setting default reaminder
  useEffect(() => {
    if (
      organization &&
      organization?.settings &&
      organization?.settings?.permissions &&
      organization?.settings?.permissions?.length > 5
    ) {
      let reaminderDuration = organization?.settings?.permissions[5]?.choosedValue;
		
      if (reaminderDuration){
		if(typeof(reaminderDuration) === "number"){
			reaminderDuration = `${reaminderDuration}`
		}
        reaminderDuration === 0
          ? setIsRemainder("")
          : reaminderDuration === 1
          ? setIsRemainder("1 hour")
          : setIsRemainder(reaminderDuration?.startsWith("Don't") ? "None"  : `${reaminderDuration} hours`);
	   }
    }
  }, [organization]);

  //Set selected event type in case of edit mode
  useEffect(() => {
    if (
      (operationMode === "edit" || operationMode === "create-requested") &&
      requiredEventType &&
      requiredEventData
    ) {
      setSelectedEventType(requiredEventType);
      setEventData(requiredEventData, rendererProps);
    }
  }, [operationMode, requiredEventType, requiredEventData]);

  //Update the selected students status mapping
  useEffect(() => {
    if (operationMode === "create") {
      setStudentStatusMapping(studentStatusMapping);
    }
  }, [selectedStudents, operationMode]);

  //Update the date if its coming from calendar
  useEffect(() => {
    if (props.selectedDateFromCalendar && operationMode === "create") {
      const requiredFormat = "hh:mm A";
      setSelectedDate(props.selectedDateFromCalendar);
      const startTimeStr = dayjs(props.selectedDateFromCalendar).format(
        requiredFormat
      );
      const endTimeStr = dayjs(props.selectedDateFromCalendar)
        .add(1, "hour")
        .format(requiredFormat);
      setSelectedStartTime(startTimeStr);
      setSelectedEndTime(endTimeStr);
    }
  }, [props.selectedDateFromCalendar, operationMode]);

  // Update data on basis od schedule mapper data
  useEffect(() => {
    if (isScheduleTrigger === true) scheduleMapperSetter(rendererProps, props);
  }, [setIsScheduleTrigger, studentList]);

  //Set the tutor to the current tutor who is viewing the thing
  useEffect(() => {
    if (user?.role === "tutor") {
      setSelectedTutor(user?.id);
    }
  }, [user?.role]);

	async function updateActionStatus(eventRequestId, action){
		try {

			const res = await updateEventRequest(eventRequestId, {
				"targetUserDetails.actionStatus" : "frozen"
			})
			fetchAndSetAllEventRequests(fetchAllEventRequests,props.setAllEventRequests)
			setRequestPopupInfo({
				trigger : false,
				eventRequestId : ""
			})
			setTrigger(false)

		}catch(err){
			triggerToast("Error occured while updating the status.")
		}
	}
  return (
    <div className={styles.container}>
      {/* Navbar section */}
      <div className={styles.row1}>
        <button className={styles.backButton} onClick={() => setTrigger(false)}>
          <ChevronLeft /> Back
        </button>
        <EventTopBar {...eventTopBarProps} />
        <div className={styles.horizontalRule}>
          <HorizontalDivider />
        </div>
      </div>

      <div className="overflow-auto h-full w-full flex flex-col justify-between gap-[24px]">
        {/* Sidebar and content section */}
        <div className="flex  px-[16px] flex-1  md:px-[36px] md:overflow-y-auto flex-col lg:flex-row  justify-start items-start gap-[24px] xl:gap-[36px] ">
          <section className="w-full">
            <EventSidebar
              selectedEventType={selectedEventType}
              {...rendererProps}
              {...props}
            />
          </section>

          <div className=" h-full hidden lg:flex">
            <VerticalDivider />
          </div>
          <div className="w-full lg:hidden">
            <HorizontalDivider />
          </div>

          <section className="w-full ">
            <EventOperatorRenderer {...rendererProps} {...props} />
          </section>
        </div>

        {/* Bottom Submit section */}
        <div className=" px-[36px]">
          <HorizontalDivider />
          <EventSubmission
            selectedEventType={selectedEventType}
            {...{
              operationMode: props.operationMode,
              setOperationMode: props.setOperationMode,
            }}
            {...props}
            {...rendererProps}
			{...{...requestPopupInfo, setRequestPopupInfo}}
          />
        </div>
      </div>
			<DeclineRequestPopUp 
				trigger={requestPopupInfo.trigger && requestPopupInfo.purpose === "confirm-decline"} 
				setTrigger={()=>{setRequestPopupInfo(prev=>({...prev, trigger : !prev.trigger, purpose : ""}))}}  
				handleDeclineClick={()=>updateActionStatus(requestPopupInfo.eventRequestId, "frozen")}/>
    </div>
  );
}

export default EventOperator;
