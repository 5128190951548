import React from 'react'

const ActiveCircleWithBar = () => {
    return (
        <svg width="176" height="16" viewBox="0 0 176 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="7" width="160" height="2" rx="1" fill="#0671E0" />
            <circle cx="168" cy="8" r="8" fill="#0671E0" />
        </svg>

    )
}

export default ActiveCircleWithBar