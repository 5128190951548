import React, { useEffect, useState } from "react";
import "./ReferralCode.css";
import { CircularProgress } from "@mui/material";
import PopupModelV2 from "../../components/PopupModelV2/PopupModelV2";
import { useLazyGetSettingsQuery } from "../../app/services/session";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, getAuthHeader } from "../../app/constants/constants";
import axios from "axios";
import { useUpdateOrgSettingMutation } from "../../app/services/settings";
import { updateOrganizationSettings } from "../../app/slices/organization";
import LoaderNew from "../../components/Loader/LoaderNew";
import LoaderIcon from "../../components/LoaderIcon/LoaderIcon";
import { fetchAndSetOrgDetails } from "../../utils/orgService";
import Tooltip from "../../components/Tooltip/Tooltip";
import MoreInfoIcon from "../../components/MoreInfoIcon/MoreInfoIcon";
import { getTooltipStyleClass } from "../../utils/commonlyUsedTooltipStyles";
import TooltipIcon from "../../assets/icons/SettingsIcon/TooltipIcon";

const ReferralCode = () => {
  const dispatch = useDispatch();
  const [updateSetting, updateSettingResp] = useUpdateOrgSettingMutation();

  const [showPopup, setShowPopup] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(true);
  const [settingsData, setSettingsData] = useState({});
  const { organization } = useSelector((state) => state.organization);
  const { subscriptionV2 } = useSelector((state) => state.subscription);

  const [filteredTests, setFilteredTests] = useState([]);
  const [allTestData, setAllTestData] = useState([]);
  const [popupData, setPopupData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTests = () => {
    axios
      .get(`${BASE_URL}api/test`, { headers: getAuthHeader() })
      .then((res) => {
        if (res.data.data.test) {
          let arr = res.data.data.test.map((item) => {
            return {
              _id: item._id,
              value: item.testName,
            };
          });
          setAllTestData(arr);
          setFilteredTests(arr);
        }
      });
  };

  const [getSettings, getSettingsResp] = useLazyGetSettingsQuery();

  const handleCreateNew = () => {
    setShowPopup(true);
  };
  const handleClose = () => {
    setShowPopup(false);
    setPopupData(null);
  };
  const handleToggle = (item) => {
    let updated = settingsData.subscriptionCode.map((subscription) => {
      if (item._id === subscription._id) {
        return { ...item, pause: !item.pause };
      } else {
        return { ...subscription };
      }
    });
    let updatedSetting = {
      subscriptionCode: updated,
    };
    setIsToggleOn(!isToggleOn);
    updateAndFetchsettings(updatedSetting);
  };
  const updateAndFetchsettings = (updatedSetting) => {
    if (!organization || !settingsData || !updatedSetting) return;
    const settings = {
      ...settingsData,
      ...updatedSetting,
    };
    const body = {
      settings,
    };
    updateSetting(body)
      .then((res) => {
        setSettingsData(res.data.data.updatedOrg.settings);
        dispatch(updateOrganizationSettings(res.data.data.updatedOrg.settings));
      })
      .catch((err) => {
        alert("Error Occured!");
        //console.log("err", err);
      });
  };

  const fetchSettings = (newSettingsData = null) => {
    // fetchAndSetOrgDetails(organization?._id);
    if (newSettingsData) {
      console.log("newSettingsData", newSettingsData);
      setSettingsData(organization?.settings);
    } else {
      setSettingsData(organization?.settings);
    }
    setIsLoading(false);
  };
  const handleVectorClick = (item) => {
    console.log("item", item);
    setPopupData(item);
    setShowPopup(true);
  };
  useEffect(() => {
    fetchSettings();
    fetchTests();
    // fetchAndSetOrgDetails(organization?._id);
  }, [organization]);
  // console.log("settingsData", settingsData.subscriptionCode);
  // console.log("allTestData", allTestData);
  // console.log("filteredTests", filteredTests);
  console.log("organization", organization);

  const printTestName = (itemId) => {
    const test = filteredTests.find((data) => data._id === itemId);
    return test ? test.value : "";
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-100vh">
          <LoaderIcon />
        </div>
      ) : (
        <>
          <div className="main-container-referral-elements">
            <div className="custom-referral-elements">
              <div className="referral-frame">
                <div className="referral-div">
                  <div className="referral-frame-2">
                    <div className="referral-frame-3">
                      <div className="referral-text-wrapper flex items-center justify-start gap-[6px]">
                        Referral Codes
                        <Tooltip tooltipContent={"Referral codes allow your users to seamlessly refer your services to their network, while also allowing you to track their activity via unique codes associated with those users. You can choose to associate these referral codes with a particular user and what assignments should show up automatically after their referrals sign up using that code."} tooltipStyles={getTooltipStyleClass({width : "320px",textWrap : "wrap"})}>
                          <TooltipIcon />
                        </Tooltip>
                      </div>

                    </div>
                    <p className="keep-track-of-your">
                      <span className="span">
                        Keep track of your leads via custom referral codes.{" "}
                      </span>
                      <span className="referral-text-wrapper-2 hidden">
                        Learn more.
                      </span>
                    </p>
                  </div>
                  <button onClick={handleCreateNew} className="button" disabled={subscriptionV2?.subscription?.settings?.referralCodes.limit !== -1 && (settingsData?.subscriptionCode?.length >= subscriptionV2?.subscription?.settings?.referralCodes.limit )}>
                    <img className="img" src="/images/plus.svg" />
                    <div className="verify-email">Create new</div>
                  </button>
                </div>
                <div className="referral-frame-4">
                  {settingsData &&
                    settingsData?.subscriptionCode &&
                    settingsData?.subscriptionCode?.map((item, index) => {
                      return (
                        <div key={index} className="referral-code">
                          <div className="referral-frame-5">
                            <div className="referral-frame-6">
                              <div className="referral-text-wrapper-3">
                                {item.code}
                              </div>
                            </div>
                            <div className="referral-frame-6">
                              {!item.pause ? (
                                <img
                                  className="toggle"
                                  src="/images/toggle_on.svg"
                                  onClick={() => handleToggle(item)}
                                  alt="Toggle On"
                                />
                              ) : (
                                <img
                                  className="knob-wrapper"
                                  src="/images/toggle_off.svg"
                                  onClick={() => handleToggle(item)}
                                  alt="Toggle Off"
                                />
                              )}
                              <img
                                className="line"
                                src="/images/line-340.svg"
                                alt="Line"
                              />
                              <img
                                className="img"
                                src="/images/Vector.svg"
                                alt="Vector"
                                onClick={() => handleVectorClick(item)}
                              />
                            </div>
                          </div>
                          <div className="referral-frame-7">
                            <div className="referral-frame-8">
                              <div className="referral-text-wrapper-4">
                                Assignments
                              </div>
                              <div className="referral-frame-9">
                                {item.tests.slice(0, 4).map((data, indexx) => (
                                  <div key={indexx} className="referral-tag">
                                    <div className="test-AB-wrapper">
                                      <p className="test-AB">
                                        {printTestName(data)}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                                {item.tests.length > 4 && (
                                  <div className="referral-text-wrapper-5">
                                    + {item.tests.length - 3} more
                                  </div>
                                )}
                              </div>
                            </div>
                            <img
                              className="line-2"
                              src="/images/horizontal_line.png"
                              alt="Horizontal Line"
                            />

                            <div className="referral-frame-10">
                              <div className="referral-frame-11">
                                <img
                                  className="img-2"
                                  src="/images/user-2.svg"
                                  alt="User Icon"
                                />
                                <div className="referral-text-wrapper-6">
                                  {item.assignedUser.owner}
                                </div>
                              </div>
                              <div className="referral-frame-12">
                                <img
                                  className="img-2"
                                  src="/images/chart.svg"
                                  alt="Chart Icon"
                                />
                                <div className="referral-text-wrapper-7">
                                  Used {item.usageDetails.length} times
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showPopup && (
        <PopupModelV2
          isOpen={showPopup}
          onClose={handleClose}
          updateSettingsData={fetchSettings}
          data={popupData}
        />
      )}
    </>
  );
};

export default ReferralCode;

