const ResponsiveSubscriptionTable = ({ header, body }) => {
  return (
    <div className="flex flex-col w-full gap-[24px]">
      {body?.map((row, idx) => (
        <div
          key={idx}
          className="grid gap-[10px] border border-[#CACEDA] rounded-[12px] p-[16px]"
        >
          {row.data.map((val, colIdx) => (
            <div key={colIdx} className="flex">
              <div className="text-secondary text-[14px] font-[500] w-[60%]">
                {colIdx === 4 ? "Overage charges" : header[colIdx].text}
              </div>
              <div
                className={`text-secondary-80 text-[14px] font-[400] w-[40%] ${
                  !row.status && colIdx > 0 ? "text-secondary-80" : "  "
                }`}
              >
                {val}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ResponsiveSubscriptionTable;
