import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { useEffect } from "react"
import styles from "./CustomMonthHeader.module.css"


function CustomMonthHeader (props) {
	
	const {currentMonth, onMonthChange, value, setValue} = props
	
	function handleYearChangeClick(type) {
		onMonthChange(currentMonth[type === "prev" ? "subtract" : "add"](1, "year", type === "prev" ? "right" : "left"))
		setValue(currentMonth[type === "prev" ? "subtract" : "add"](1, "year", type === "prev" ? "right" : "left"))
		console.log(value.add(1,"year"))
	}

	console.log(currentMonth)
	console.log(15,value)

	return (
		
		<div className={styles.container}>
			<button onClick={(e)=>handleYearChangeClick("prev")}><ChevronLeft style={{height : "20px", width : "20px"}}/> </button>
			<span className={styles.yearContainer}>{currentMonth.$y}</span>
			<button onClick={(e)=>handleYearChangeClick("next")}><ChevronRight style={{height : "20px", width : "20px"}}/> </button>	
		</div>

	)

}


export default CustomMonthHeader
