import { Tooltip } from '@mui/material'
import React from 'react'
import InvoiceDetailsInput from '../InvoiceDetailsInput/InvoiceDetailsInput'
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon'

const OfficeWorkEditor = ({ hourlyCost, setHourlyCost, user, userTypesAllowedToViewInvoiceDetails }) => {


	if (userTypesAllowedToViewInvoiceDetails.includes(user.role)) {
		return (
			<div>
				<div>
					<div className='flex gap-2 font-medium text-sm text-secondary'>
						<span>Payroll</span>
						<Tooltip>
							<MoreInfoIcon />
						</Tooltip>
					</div>
					<div className='pt-[8px]'>
						<InvoiceDetailsInput placeholder={"Hourly Cost"} value={hourlyCost} setValue={setHourlyCost} />
					</div>
				</div>
			</div>
		)

	}
}

export default OfficeWorkEditor
