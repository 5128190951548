import React, { useState } from "react";
import SearchEventMapper from "./SearchEventMapper/SearchEventMapper";

const eventData = [
  {
    _id: "23567643212345",
    eventName: "Stacey N. / Timothy W.",
    eventType: "Session",
    eventDate: "April 21, 2024",
    eventStartTime: "11:00am",
    eventEndTime: "11:45am",
  },
  {
    _id: "23567643212346",
    eventName: "John D. / Mary S.",
    eventType: "Internal",
    eventDate: "May 12, 2024",
    eventStartTime: "10:00am",
    eventEndTime: "10:45am",
  },
  {
    _id: "23567643212349",
    eventName: "Nancy G. / Steve H.",
    eventType: "Appointment",
    eventDate: "March 09, 2024",
    eventStartTime: "09:00am",
    eventEndTime: "09:45am",
  },
  {
    _id: "23567643212347",
    eventName: "Paul B. / Lisa M.",
    eventType: "Consultation",
    eventDate: "June 15, 2024",
    eventStartTime: "02:00pm",
    eventEndTime: "02:45pm",
  },
  {
    _id: "23567643212348",
    eventName: "Kevin R. / Angela T.",
    eventType: "Consultation",
    eventDate: "July 20, 2024",
    eventStartTime: "01:00pm",
    eventEndTime: "01:45pm",
  },
  {
    _id: "23567643212350",
    eventName: "Sophia K. / William J.",
    eventType: "Internal",
    eventDate: "September 02, 2024",
    eventStartTime: "04:00pm",
    eventEndTime: "04:45pm",
  },
  {
    _id: "23567643212351",
    eventName: "Emily L. / Michael P.",
    eventType: "Consultation",
    eventDate: "October 18, 2024",
    eventStartTime: "03:00pm",
    eventEndTime: "03:45pm",
  },
  {
    _id: "23567643212352",
    eventName: "Jessica M. / Robert C.",
    eventType: "Appointment",
    eventDate: "November 30, 2024",
    eventStartTime: "12:00pm",
    eventEndTime: "12:45pm",
  },
  {
    _id: "23567643212353",
    eventName: "Rachel D. / Henry F.",
    eventType: "Session",
    eventDate: "August 25, 2024",
    eventStartTime: "08:00am",
    eventEndTime: "08:45am",
  },
  {
    _id: "23567643212354",
    eventName: "Julia W. / Andrew E.",
    eventType: "Internal",
    eventDate: "December 05, 2024",
    eventStartTime: "05:00pm",
    eventEndTime: "05:45pm",
  },
];

const SearchEvents = ({_eventData}) => {
  const [isViewAll, setIsViewAll] = useState(false);

  return (
    <main className=" w-full  flex flex-col gap-y-[20px]   ">
      <h2 className=" text-[12px] leading-[12px] text-secondary-60 pl-[12px] ">EVENTS</h2>

      <SearchEventMapper eventData={_eventData} isViewAll={isViewAll} setIsViewAll={setIsViewAll}/>

      {_eventData && _eventData.length > 4 && (
        <button className=" text-[14px] w-fit leading-[14px] text-[#0671E0] outline-none  cursor-pointer" onClick={()=> setIsViewAll(!isViewAll)}>
          {isViewAll ? "View Less" : "View All"}
        </button>
      )}
    </main>
  );
};

export default SearchEvents;
