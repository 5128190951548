import React, { useState, useRef, useEffect } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const timeSlots = Array.from({ length: 48 }, (_, i) => {
  const hour = Math.floor(i / 2);
  const minutes = i % 2 === 0 ? "00" : "30";
  const period = hour < 12 ? "AM" : "PM";
  const twelveHour = hour % 12 === 0 ? 12 : hour % 12;
  return `${String(twelveHour).padStart(2, "0")}:${minutes} ${period}`;
});

const TimeSelect = ({ value, onChange, placeholder }) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const dropDownRef = useRef(null);

  useEffect(() => {
    // Add event listener for outside click
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleSelect = (item) => {
    setSelectedValue(item);
    onChange(item);
    setOpen(false);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className="relative" ref={dropDownRef}>
      <div
        className={`md:px-[12px] py-[13px] rounded-[6px] w-full flex items-center border max-h-[36px] overflow-y-hidden ${
          open ? "border-blue-70" : "border-[#BEC2CE]"
        } w-full outline-none cursor-pointer`}
        onClick={() => setOpen(!open)}
        tabIndex={0}
      >
        <div
          className={`flex-grow text-center ${selectedValue ? "text-secondary" : "text-[#BEC2CE]"}`}
        >
          {selectedValue ? selectedValue : placeholder}
        </div>
        <div>
          {open ? (
            <KeyboardArrowUpOutlinedIcon />
          ) : (
            <KeyboardArrowDownOutlinedIcon />
          )}
        </div>
      </div>
      {open && (
        <div className="z-[5] absolute mt-2 rounded-[6px] shadow-md bg-[#FFFFFF] w-full max-h-[180px] overflow-y-auto grid gap-[4px]">
          {timeSlots.map((item, index) => (
            <div
              key={index}
              className="rounded-[6px] text-[14px]  font-medium p-[12px] hover:bg-[#F4F5F7] cursor-pointer"
              onClick={() => handleSelect(item)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TimeSelect;
