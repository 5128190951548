export default function CalIconVariant2(props){
	return(
		<svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_37658_56618)">
				<path d="M12.6667 1.83333H12V1.16667C12 0.989856 11.9298 0.820286 11.8047 0.695262C11.6797 0.570238 11.5101 0.5 11.3333 0.5C11.1565 0.5 10.987 0.570238 10.8619 0.695262C10.7369 0.820286 10.6667 0.989856 10.6667 1.16667V1.83333H5.33333V1.16667C5.33333 0.989856 5.2631 0.820286 5.13807 0.695262C5.01305 0.570238 4.84348 0.5 4.66667 0.5C4.48986 0.5 4.32029 0.570238 4.19526 0.695262C4.07024 0.820286 4 0.989856 4 1.16667V1.83333H3.33333C2.4496 1.83439 1.60237 2.18592 0.97748 2.81081C0.352588 3.43571 0.00105857 4.28294 0 5.16667L0 13.1667C0.00105857 14.0504 0.352588 14.8976 0.97748 15.5225C1.60237 16.1474 2.4496 16.4989 3.33333 16.5H12.6667C13.5504 16.4989 14.3976 16.1474 15.0225 15.5225C15.6474 14.8976 15.9989 14.0504 16 13.1667V5.16667C15.9989 4.28294 15.6474 3.43571 15.0225 2.81081C14.3976 2.18592 13.5504 1.83439 12.6667 1.83333ZM1.33333 5.16667C1.33333 4.63623 1.54405 4.12753 1.91912 3.75245C2.29419 3.37738 2.8029 3.16667 3.33333 3.16667H12.6667C13.1971 3.16667 13.7058 3.37738 14.0809 3.75245C14.456 4.12753 14.6667 4.63623 14.6667 5.16667V5.83333H1.33333V5.16667ZM12.6667 15.1667H3.33333C2.8029 15.1667 2.29419 14.956 1.91912 14.5809C1.54405 14.2058 1.33333 13.6971 1.33333 13.1667V7.16667H14.6667V13.1667C14.6667 13.6971 14.456 14.2058 14.0809 14.5809C13.7058 14.956 13.1971 15.1667 12.6667 15.1667Z" fill={props.currentColor ? "currentColor" : "#515C7B"}/>
				<path d="M8 11.5C8.55228 11.5 9 11.0523 9 10.5C9 9.94772 8.55228 9.5 8 9.5C7.44772 9.5 7 9.94772 7 10.5C7 11.0523 7.44772 11.5 8 11.5Z" fill={props.currentColor ? "currentColor" : "#515C7B"}/>
				<path d="M4.66797 11.5C5.22025 11.5 5.66797 11.0523 5.66797 10.5C5.66797 9.94772 5.22025 9.5 4.66797 9.5C4.11568 9.5 3.66797 9.94772 3.66797 10.5C3.66797 11.0523 4.11568 11.5 4.66797 11.5Z" fill={props.currentColor ? "currentColor" : "#515C7B"}/>
				<path d="M11.332 11.5C11.8843 11.5 12.332 11.0523 12.332 10.5C12.332 9.94772 11.8843 9.5 11.332 9.5C10.7797 9.5 10.332 9.94772 10.332 10.5C10.332 11.0523 10.7797 11.5 11.332 11.5Z" fill={props.currentColor ? "currentColor" : "#515C7B"}/>
			</g>
			<defs>
				<clipPath id="clip0_37658_56618">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>

	)
}
