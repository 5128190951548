import { Link } from "react-router-dom"
import SidebarItem from "../SidebarItem/SidebarItem"
import styles from "./SectionStyles.module.css"
import { useSelector } from "react-redux"


function Section3 ({activeLink, onElementClick, config, componentProps}) {

	const { role } = useSelector(state => state.user)


	return (
		<div className={styles.section3container}>
			{
				config?.section3.map((item, idx)=>(
					<SidebarItem className={`${styles.sectionItem} ${activeLink === item.elLink ? styles.sectionItemActive : ""}`} onClick = {()=>{onElementClick(idx, item)}} to={item.elLink} isLink={item.isLink}>
						
						{item.icon}
						{ item.displayName === "Time Off" &&  ['parent', 'student'].includes(role) ? "Vacation" :  item.displayName}
						{item.component && item.component(componentProps[idx].main)}
					</SidebarItem>	
				))
			}
		</div>
	)
}


export default Section3
