/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import InputPasswordV2 from '../InputPasswordV2/InputPasswordV2'
import TickMarkIcon from '../TickMarkIcon/TickMarkIcon'
import styles from './ResetPasswordScreen.module.css'
import CrossIcon from '../CrossIcon/CrossIcon'
import ButtonV2 from '../ButtonV2/ButtonV2'
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom'
import verifiedLogo from './VerifiedLogo.svg'
import { useLazyGetSingleUserQuery } from '../../app/services/users'
import { useChangePasswordMutation } from '../../app/services/auth'
import { toast } from 'react-toastify'
import LoaderIcon from '../LoaderIcon/LoaderIcon'
import { css } from '@emotion/react'

const ResetPasswordScreen = (props) => {

    const navigate = useNavigate()
    const { setSignupStage, primaryCTAStyles } = useOutletContext()
    const [passwordValidityChecklist, setPasswordValidityChecklist] = useState([false, false, false, false])
    const [mainPassword, setMainPassword] = useState(null)
    const [secondaryPassword, setSecondaryPassword] = useState(null)
    const [isPasswordMatching, setIsPasswordMatching] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    
    const [searchParams, setSearchParams] = useSearchParams()
    const userId = searchParams.get("userid")
    const token = searchParams.get("token")

    const [getDetails, getDetailsResp] = useLazyGetSingleUserQuery()
    const [changePassword, changePasswordResp] = useChangePasswordMutation()

    function validatePassword(password) {
        const upperCaseRegex = /[A-Z]/
        const lowerCaseRegex = /[a-z]/
        const specialCharacterRegex = /[^A-Za-z]/
        let tempList = passwordValidityChecklist

        tempList[0] = password.length >= 8
        tempList[1] = upperCaseRegex.test(password)
        tempList[2] = lowerCaseRegex.test(password)
        tempList[3] = specialCharacterRegex.test(password)

        setPasswordValidityChecklist(tempList)

    }

    useEffect(() => {
        setSignupStage("setPassword")
    }, [])

    useEffect(() => {
        setIsPasswordMatching(mainPassword === secondaryPassword)
    }, [mainPassword, secondaryPassword])


    useEffect(()=>{
        if(!userId) return;
        getDetails(userId).then((res)=>{
            if(res.error){
                toast.error(res.error.data.message)
            }else {
                if(res.data?.user){
                    if(!res.data.user.isVerified ) {
                        toast.warn("Please verify your account first", {autoClose : 1500, onClose : navigate('/signin')})
                    }
                }
            }
        })
    },[token,userId])

    const handlePasswordChange = (e) => {
        switch (e.target.id) {
            case "mainPassword": setMainPassword(e.target.value); validatePassword(e.target.value); break;
            case "secondaryPassword": setSecondaryPassword(e.target.value); break;
        }
    }


    const handleSaveClick = (e) => {
        e.preventDefault()
        setIsLoading(true)
        changePassword({
            userId : userId, 
            body : { 
                password : mainPassword, 
                token : token
            }
        }).then((res)=>{
            if(res.error){
                toast.error(res.error.data.message)
            } else {
                toast.success("Password reset successfull",{
                    autoClose : 500,
                    onClose : () => {
                        navigate("../reset-success?state=success")
                    }
                })
            }
        })
    }

    return (
        <div className={styles.container}>
            {
                props.isEmailVerified ?
                    <div className={styles.verifiedMessage}>
                        <img src={verifiedLogo} />
                        <div>You have successfully verified your email.</div>
                    </div>
                    : <></>
            }
            <h3>Set a new password</h3>
            <div className={styles.passwordInputContainer}>

                <InputPasswordV2 placeholderText={"Enter your password"} id="mainPassword" onChange={handlePasswordChange} />
                <InputPasswordV2 placeholderText={"Confirm your password"} id="secondaryPassword" onChange={handlePasswordChange} />

                {
                    secondaryPassword ? !isPasswordMatching ? <div className={styles.mismatchingPasswords}>The passwords don't match. Please recheck.</div> : <></> : <></>
                }
            </div>
            <div className={styles.passwordValidator}>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[0] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[0] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[0] ? styles.itemText : styles.itemTextIncorrect}>At least 8 characters</div>
                </div>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[1] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[1] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[1] ? styles.itemText : styles.itemTextIncorrect}>One uppercase character</div>
                </div>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[2] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[2] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[2] ? styles.itemText : styles.itemTextIncorrect}>One lowercase character</div>
                </div>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[3] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[3] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[3] ? styles.itemText : styles.itemTextIncorrect}>One number, symbol or space character</div>
                </div>
            </div>
            <ButtonV2 disabled={!(isPasswordMatching && passwordValidityChecklist.reduce((prev, curr) => prev || curr, false))} onClick={(e)=>handleSaveClick(e)} {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.bg})}}>
                {!isLoading ?
                    <div className={styles.buttonContent}>
                        <div>Save</div>
                    </div> 
                    : 
                    <div className='flex justify-center items-center w-full'><LoaderIcon /></div>
                }

            </ButtonV2>
        </div>
    )
}

export default ResetPasswordScreen
