import { useState, useEffect } from "react";
import { useSelector } from "react-redux"
import ButtonV2 from "../../../../components/ButtonV2/ButtonV2";
import CrossIcon from "../../../../components/CrossIcon/CrossIcon";
import styles from "./CustomColor.module.css"
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import { fetchAndSetOrgDetails, updateOrg } from "../../../../utils/orgService";
import DeleteColorPopup from "./DeleteColorPopup/DeleteColorPopup";
import ColorSelector from "./ColorSelector/ColorSelector";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import ColorPreviewer from "./ColorPreviewer";
import SelectColorPopup from "./SelectColorPopup/SelectColorPopup";
import LockedStateOverlayVariant2 from "../../../../components/LockedStateOverlayVariant2/LockedStateOverlayVariant2";
import { isWhiteLabellingComponentAllowed } from "../../../../utils/restrictionsService";
extend([namesPlugin]);
const _ = require("lodash")

function idScreenVW(screenWidth, setter){
	if(setter){
		setter(screenWidth)
	}
}

function ColorItem ({color, idx,onClick, onDeleteClick}) {
	return(
		<div className={styles.colorItemContainer} onClick={(e)=>onClick(e,idx)}>
			<div className={styles.inner}>
				<div className={!color.isPrimary ? styles.colorIndicator : styles.colorIndicatorSelected} style={{backgroundColor : color.isPrimary ? "white": color.hexCode}}>
				{
					color.isPrimary ? 
					<div className={styles.colorDot} style={{backgroundColor : color.hexCode}}>&nbsp;</div>
					: <>&nbsp;</>
				}
				</div>	
				<span>{color.hexCode}</span>
			</div>
			<button onClick={(e)=>onDeleteClick(e,idx)}><CrossIcon height={"16px"} width={"16px"}/></button>
		</div>
	)
}


function CustomColor(props) {

	const {organization} = useSelector((state)=>state.organization)
	const {subscriptionV2} = useSelector((state)=>state.subscription)

	const [url, setUrl]	 = useState()
	const [tempPrimaryColor, setTempPrimaryColor] = useState()
	const [colorCollection, setColorCollection] = useState()
	const [changesDetected, setChangesDetected] = useState(false)

	const [deletePopUpTrigger, setDeletePropUpTrigger] = useState(false)
	const [idxToDelete, setIdxToDelete] = useState()

	const [colorPickerTrigger, setColorPickerTrigger] = useState(false)
	const [colorPickerColor, setColorPickerColor] = useState("#94E5D2")

	const [screenWidth, setScreenWidth] = useState()
	

	//Setup useEffects
	useEffect(()=>{
		idScreenVW(window.innerWidth, setScreenWidth)		
		window.addEventListener("resize",()=>{
			idScreenVW(window.innerWidth, setScreenWidth)		
		})

		window.removeEventListener("resize", ()=>{
			idScreenVW(window.innerWidth, setScreenWidth)		
		})
	},[])


	useEffect(() => {
		setUrl(
			organization?.customSubDomain?.registeredSubDomain
				? `https://${organization?.customSubDomain.registeredSubDomain}.evallo.org/signup/user?orgName=${organization?.company}`
				: `${process.env.REACT_APP_FE_URL}/signup/user?orgName=${organization?.company}`
		);
	}, [organization?.company, organization?.customSubDomain]);


	useEffect(()=>{
		if(organization && organization.customColorScheme){
			setTempPrimaryColor(organization.customColorScheme.primary)	
			setColorCollection(organization.customColorScheme.collection)
		}
	},[organization])


	useEffect(()=>{
		if(organization && organization.customColorScheme) {
			const savedPrimary = organization.customColorScheme.primary
			const savedCollection = organization.customColorScheme.collection
			console.log("Triggered")
			setChangesDetected(
				(savedPrimary !== tempPrimaryColor ) ||  _.isEqual(savedCollection, colorCollection) === false
			)
		}
	},[tempPrimaryColor, colorCollection])

	//Function to make a colour the new primary colour
	function makePrimary(idx) {
		const tempPrimary = colorCollection[idx].hexCode
		const tempCollection = colorCollection.map((val,_idx)=>({
			...val,
			isPrimary : _idx === idx		
		}))
		setTempPrimaryColor(tempPrimary)
		setColorCollection(tempCollection)
	}

	function onDeleteClick(e, idx) {
		e.stopPropagation()
		setDeletePropUpTrigger(true)
		setIdxToDelete(idx)
	}

	async function handleDelete(){
		/*
		 *
		const id = toast.loading("Saving Changes")
		try {
			let fields = {}
			if(tempPrimaryColor === colorCollection[idxToDelete].hexCode){
				fields["customColorScheme.primary"] = ""
			}
			fields["customColorScheme.collection"] = colorCollection.filter((val,idx)=>idx !== idxToDelete)
			await updateOrg(organization._id, fields)
			setIdxToDelete(null)
			await fetchAndSetOrgDetails(organization._id)
			setDeletePropUpTrigger(false)
			toast.update(id,{render : "Changes saved successfully",type : "success", isLoading : false, autoClose : 3000})
		}catch(err){
			toast.update(id,{render : "Some error occured",type : "error", isLoading : false, autoClose : 3000})
		}
		 * */
		if(tempPrimaryColor === colorCollection[idxToDelete].hexCode){
			setTempPrimaryColor("")
		}
		setColorCollection((prev)=>(prev.filter((val,idx)=>idx !== idxToDelete)))
		setIdxToDelete(null)
		setDeletePropUpTrigger(false)
	}

	async function handleAdd(){
		console.log("ADD TRIG")
		const tempColor = colorPickerColor.startsWith("rgba") ? colord(colorPickerColor).toHex() : colorPickerColor
		setColorPickerTrigger(false)
		const tempCollection = colorCollection.map((val)=>({...val}))
		tempCollection.push({hexCode : tempColor.toUpperCase()})
		setColorCollection([...tempCollection])
	}
	

	async function saveChanges(){
		const id = toast.loading("Saving Changes")
		try {
			await updateOrg(organization._id, {
				"customColorScheme.primary" : tempPrimaryColor,
				"customColorScheme.collection" : colorCollection
			})
			await fetchAndSetOrgDetails(organization._id)
			toast.update(id,{render : "Changes saved successfully",type : "success", isLoading : false, autoClose : 3000})
		}catch(err){
			toast.update(id,{render : "Some error occured",type : "error", isLoading : false, autoClose : 3000})
		}

	}



	return(
		<div className={styles.container}>


			<div className={styles.container1}>
				<div className={styles.heading}>
					<h3>Color Theme</h3>
				</div>
				<div className={styles.description}>
					Your website’s look, including header, buttons and other interactive elements, will be customised as per the selected color.
				</div>
				<div className={styles.previewContainer}>
					<a
						className={styles.frame2}
						href={url}
						target="_blank"
						style={{ cursor: "pointer" }}
					>
						<div className={styles.textWrapper2}>Preview Signup</div>
						<img className={styles.img} src="/images/expand.svg" alt="Expand" />
					</a>
				</div>
			</div>
			<div className={styles.wrapper}>
				{
					(subscriptionV2 && isWhiteLabellingComponentAllowed(subscriptionV2.subscription, "customColor")) === false? <LockedStateOverlayVariant2 outerContainerStyle={{border : "none"}}/> : <></>
				}
<div className={styles.container2}>
				<div className={styles.mobileHeader}>Select color</div>
				<div className={styles["container2parent"]}>
					<div className={styles.colorsContainer}>
						{
							colorCollection?.map((color,idx)=>(
								<ColorItem color={color} idx={idx} 
									onClick={(e,idx)=>{e.stopPropagation();makePrimary(idx)}}
									onDeleteClick={onDeleteClick}
								/>
							))	
						}
						<div className={styles.btnContainer}>

							<button onClick={()=>{setColorPickerTrigger(!colorPickerTrigger)}}className={styles.addNewButton} disabled={colorCollection?.length >= 3}><AddIcon /><span>Add New Color</span></button>
							{
								colorPickerTrigger && screenWidth >= 992 ? 
									<ColorSelector color={colorPickerColor} setColor={setColorPickerColor} onAddClick={handleAdd} setTrigger={setColorPickerTrigger}/>
									: <></>
							}

						</div>
				</div>
				</div>
				<div className="w-full min-h-[1px] max-h-[1px] md:max-h-fit md:max-w-[1px] bg-secondary-10 md:self-stretch">&nbsp;</div>
				<div className={styles.previewContainerT}>
					<h3>Preview</h3>
					<p>This shows how your website’s header will look, including other interactive elements.</p>
				</div>
				<div className={styles.prevContainer} style={{color : colorPickerTrigger ? colorPickerColor : (tempPrimaryColor ? tempPrimaryColor :  "#0671E0")}}>
					<ColorPreviewer />	
				</div>
			</div>
			<div className={styles.container3}>
				<ButtonV2 style={{width : "fit-content", padding : "14px 24px"}} disabled={!changesDetected} onClick={(e)=>saveChanges()}>Save Changes</ButtonV2>
			</div>
			</div>
			
			<DeleteColorPopup trigger={deletePopUpTrigger} setTrigger={setDeletePropUpTrigger} onDeleteClick={handleDelete}/>
			<SelectColorPopup trigger={colorPickerTrigger} setTrigger={setColorPickerTrigger} color={colorPickerColor} setColor={setColorPickerColor} onAddClick={handleAdd}/>
		</div>
	)

}

export default CustomColor
