// TimeDateSettings.jsx
import React, { useEffect, useState } from "react";

import "./MobileView.css";
import { Box, Button } from "@mui/material";
import TimeZonePopover from "../../../components/TimeZonePopOver/TimeZonePopover";
import Dateformat from "../../../components/DateFormat/Dateformat";
import { useSelector, useDispatch } from "react-redux";
import { useUpdateOrgSettingMutation } from "../../../app/services/settings";
import { updateOrganizationSettings } from "../../../app/slices/organization";
import { updateDateFormat, updateUserDetails } from "../../../app/slices/user";

const MobileViewDateTimeSetting = () => {
  const dispatch = useDispatch();

  const { organization } = useSelector((state) => state.organization);
  const [updateSetting, updateSettingResp] = useUpdateOrgSettingMutation();

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isDateFormatrVisible, setIsDateFormatrVisible] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState();
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  const togglePopover = () => {
    setIsPopoverVisible((prev) => !prev);
    setIsDateFormatrVisible(false);
  };
  const closePopover = () => {
    setIsPopoverVisible(false);
    setIsDateFormatrVisible(false);
  };
  const toggleDatePopover = () => {
    setIsDateFormatrVisible((prev) => !prev);
  };
  const handleTimeZoneSelection = (timezone) => {
    setSelectedTimeZone(timezone);
    setHasChanges(true);

    closePopover();
  };
  const closeDatePopover = () => {
    setIsDateFormatrVisible(false);
  };
  const handleDateFormatSelection = (format) => {
    console.log("format", format);
    setSelectedFormat(format.toUpperCase());
    setHasChanges(true);
    closeDatePopover();
  };

  const handleSave = () => {
    const settings = {
      ...organization?.settings,
      timeZone: selectedTimeZone,
      dateFormat: selectedFormat.toLowerCase(),
    };

    const body = { settings };
    console.log("settings", settings);
    console.log("body", body);
    updateSetting(body)
      .then((res) => {
        console.log("res data", res.data.data.updatedOrg.settings);
        dispatch(updateOrganizationSettings(res.data.data.updatedOrg.settings));
        dispatch(
          updateDateFormat({ dateFormat: selectedFormat.toLowerCase() })
        );
      })
      .catch((err) => {
        console.log("err", err);
      });

    setHasChanges(false);
  };

  useEffect(() => {
    if (organization?.settings) {
      setSelectedTimeZone(organization.settings.timeZone);
      setSelectedFormat(organization.settings.dateFormat?.toUpperCase() || "");
    }
  }, [organization]);
  return (
    <div className="mobile-date-frame-main-container">
      <div className="mobile-date-frame">
        <div className="mobile-date-custom-elements">
          <div className="mobile-date-div">
            <div className="mobile-date-div-2">
              <div className="mobile-date-text-wrapper-2">Time and Date</div>

              <img
                className="mobile-date-img"
                src="/images/input_field_icon.jpg"
                alt="Time and Date"
              />
            </div>
            {/* <div className="mobile-date-default-date-format">
              <div className="mobile-date-div-3">
                <div className="mobile-date-sub-section-header">
                  <div className="mobile-date-div-4">
                    <div className="mobile-date-text-wrapper-3">
                      Default Time Zones
                    </div>
                    <div className="mobile-date-edit-icon-wrapper">
                      <img
                        className="mobile-date-edit-icon"
                        src="/images/Vector.svg"
                        alt="Edit"
                      />
                    </div>
                  </div>
                </div>
                <div className="mobile-date-div-5">
                  <p className="mobile-date-p">
                    This is the time zone that will be applied for all of {"{"}
                    Organisation_Name{"}"}’s users while scheduling sessions on
                    Calendar.
                  </p>
                  <div className="mobile-date-div-6">
                    <span className="mobile-date-text-wrapper-4">
                      Set the default time zone for {"{"}Organisation_Name{"}"}
                    </span>
                    <div
                      className="mobile-date-input-field"
                      onClick={togglePopover}
                    >
                      <div className="mobile-date-text-wrapper-5">
                        {selectedTimeZone}{" "}
                      </div>
                      <img
                        className="mobile-date-input-field-icon"
                        src="/images/chevron-down.svg"
                        alt="Input Field Icon"
                      />
                      <TimeZonePopover
                        isVisible={isPopoverVisible}
                        onClose={closePopover}
                        onSelectTimeZone={handleTimeZoneSelection}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="mobile-date-default-date-format">
              <div className="mobile-date-div-3">
                <div className="mobile-date-sub-section-header">
                  <div className="mobile-date-div-7">
                    <div className="mobile-date-text-wrapper-3">
                      Default Time Zones
                    </div>
                  </div>
                  <div
                    className="mobile-date-edit-icon-wrapper"
                    onClick={togglePopover}
                  >
                    <img
                      className="mobile-date-edit-icon"
                      src="/images/Vector.svg"
                      alt="Edit Icon 2"
                    />
                  </div>
                </div>
                <div className="mobile-date-div-5">
                  <p className="mobile-date-p">
                    This is the time zone that will be applied for all of {organization?.company}'s users while scheduling sessions on
                    Calendar.
                  </p>
                  <div className="mobile-date-div-6">
                    <span className="mobile-date-text-wrapper-4">
                      Set the default time zone for {organization?.company}
                    </span>
                    <div
                      className="mobile-date-input-field"
                      onClick={togglePopover}
                    >
                      <div className="mobile-date-text-wrapper-5">
                        {selectedTimeZone}{" "}
                      </div>
                      <img
                        className="mobile-date-input-field-icon"
                        src="/images/chevron-down.svg"
                        alt="Input Field Icon"
                      />
                      <TimeZonePopover
                        isVisible={isPopoverVisible}
                        onClose={closePopover}
                        onSelectTimeZone={handleTimeZoneSelection}
                        selectedTimeZone={selectedTimeZone}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-date-default-date-format">
              <div className="mobile-date-div-3">
                <div className="mobile-date-sub-section-header">
                  <div className="mobile-date-div-7">
                    <div className="mobile-date-text-wrapper-3">
                      Default Date Format
                    </div>
                  </div>
                  <div
                    className="mobile-date-edit-icon-wrapper"
                    onClick={toggleDatePopover}
                  >
                    <img
                      className="mobile-date-edit-icon"
                      src="/images/Vector.svg"
                      alt="Edit Icon 2"
                    />
                  </div>
                </div>
                <div className="mobile-date-div-5">
                  <p className="mobile-date-p">
                    This is the date format that all of {organization?.company}’s users will see throughout the platform.
                  </p>
                  <div className="mobile-date-div-6">
                    <span className="mobile-date-text-wrapper-4">
                      Set the default date format for {organization?.company}
                    </span>
                    <div
                      className="mobile-date-input-field"
                      onClick={toggleDatePopover}
                    >
                      <div className="mobile-date-text-wrapper-5">
                        {selectedFormat}
                      </div>
                      <img
                        className="mobile-date-input-field-icon"
                        src="/images/chevron-down.svg"
                        alt="Input Field Icon"
                      />
                      <Dateformat
                        isVisible={isDateFormatrVisible}
                        onClose={closeDatePopover}
                        onSelectDateFormat={handleDateFormatSelection}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={handleSave}
            disabled={!hasChanges}
            className="mobile-date-saveButton"
          >
            Save
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default MobileViewDateTimeSetting;