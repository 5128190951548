import styles from "./Sidebar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { sidebarContent } from "./sidebarContent";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import HorizontalDivider from "../../../components/HorizontalDivider/HorizontalDivider";
import CrossIcon from "../../../components/CrossIcon/CrossIcon";
import AccountDropdownCard from "../../../components/AccountDropdownCard/AccountDropdownCard";

const MenuOperationIcon = ({ isOpen }) => {
  if (isOpen) {
    return <KeyboardArrowUpIcon style={{ height: "16px", width: "16px" }} />;
  } else {
    return <NavigateNextIcon style={{ height: "16px", width: "16px" }} />;
  }
};

const SelectedIndicator = ({ isSelected }) => {
  if (isSelected) {
    return <div className={styles.blueSelectedIcon}>&nbsp;</div>;
  } else {
    return <></>;
  }
};

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen,isAccountDDOpen, setIsAccountDDOpen  }) => {
  
  //To keep track of open menus & selected level 1 menus
  const [menuOpenMapping, setMenuOpenMapping] = useState(null);
  const [isSelectedMapping, setIsSelectedMapping] = useState(null);

  //To keep track of leve 2 selected menu
  const [subNavSelectedMapping, setSubNavSelectedMapping] = useState(null);

  //To keep track of link & the corresponding title, mainly to be used for direct navigation
  const [titleLinkMapping, setTitleLinkMapping] = useState(null);


  //To seed the data in links
  useEffect(() => {
    if (sidebarContent) {
      const tempMenuOpenMapping = {};
      const tempIsSelectedMapping = {};
      const tempSubNavSelectedMapping = {};
      const tempTitleLinkMapping = {};

      sidebarContent.forEach((content, idx) => {
        content.children.forEach((val, idx) => {
          tempMenuOpenMapping[val.title] = false;
          tempIsSelectedMapping[val.title] = false;
          tempTitleLinkMapping[val.link ?? val.refLink] = val.title;

          val.children.forEach((subNav, idx) => {
            tempSubNavSelectedMapping[subNav.title] = false;
            tempTitleLinkMapping[subNav.link] = subNav.title;
          });
        });
      });
      Object.keys(tempIsSelectedMapping).forEach((key, idx) => {
        if (idx === 0) tempIsSelectedMapping[key] = true;
      });
      setMenuOpenMapping(tempMenuOpenMapping);
      setIsSelectedMapping(tempIsSelectedMapping);
      setSubNavSelectedMapping(tempSubNavSelectedMapping);
      setTitleLinkMapping(tempTitleLinkMapping);
    }
  }, [sidebarContent]);

  //To display selected links when navigated to directly
  useEffect(() => {
    if (
      menuOpenMapping &&
      isSelectedMapping &&
      subNavSelectedMapping &&
      titleLinkMapping
    ) {
      let subPaths = window.location.pathname.split("/");
      subPaths = subPaths.slice(2);

      if (subPaths.length === 1) {
        const tempIsSelectedMapping = { ...isSelectedMapping };
        Object.keys(tempIsSelectedMapping).forEach((key, idx) => {
          if (tempIsSelectedMapping[key]) {
            tempIsSelectedMapping[key] = false;
          }
        });
        tempIsSelectedMapping[titleLinkMapping[subPaths[0]]] = true;
        setIsSelectedMapping(tempIsSelectedMapping);
      } else if (subPaths.length === 2) {
        const tempIsSelectedMapping = { ...isSelectedMapping };
        const tempMenuOpenMapping = { ...menuOpenMapping };
        const tempSubNavSelectedMapping = { ...subNavSelectedMapping };
        const link = subPaths.join("/");

        Object.keys(tempIsSelectedMapping).forEach((key, idx) => {
          if (tempIsSelectedMapping[key]) {
            tempIsSelectedMapping[key] = false;
          }
        });
        Object.keys(tempSubNavSelectedMapping).forEach((key, idx) => {
          if (tempSubNavSelectedMapping[key]) {
            tempSubNavSelectedMapping[key] = false;
          }
        });

        tempMenuOpenMapping[titleLinkMapping[subPaths[0]]] = true;
        tempIsSelectedMapping[titleLinkMapping[subPaths[0]]] = true;
        tempSubNavSelectedMapping[titleLinkMapping[link]] = true;

        setMenuOpenMapping(tempMenuOpenMapping);
        setIsSelectedMapping(tempIsSelectedMapping);
        setSubNavSelectedMapping(tempSubNavSelectedMapping);
      } else if (subPaths.length === 0) {
        const tempIsSelectedMapping = { ...isSelectedMapping };
        Object.keys(tempIsSelectedMapping).forEach((key, idx) => {
          if (tempIsSelectedMapping[key]) {
            tempIsSelectedMapping[key] = false;
          }
        });
        tempIsSelectedMapping["Account"] = true;
        setIsSelectedMapping(tempIsSelectedMapping);
      }
    }
  }, [window.location.pathname, titleLinkMapping]);

  //Click related handling
  const handleLinkClick = (item, parent) => {
    const menuName = item.title;
    if (Object.keys(menuOpenMapping).includes(menuName)) {
      const tempMenuOpenMapping = { ...menuOpenMapping };
      const tempIsSelectedMapping = { ...isSelectedMapping };
      const tempSubNavSelectedMapping = { ...subNavSelectedMapping };

      tempMenuOpenMapping[menuName] = !tempMenuOpenMapping[menuName];
      setMenuOpenMapping(tempMenuOpenMapping);

      if (item.children && item.children.length === 0) {
        Object.keys(tempIsSelectedMapping).forEach((key, idx) => {
          if (tempIsSelectedMapping[key]) {
            tempIsSelectedMapping[key] = false;
          }
        });
        Object.keys(tempSubNavSelectedMapping).forEach((key, idx) => {
          if (tempSubNavSelectedMapping[key]) {
            tempSubNavSelectedMapping[key] = false;
          }
        });
        tempIsSelectedMapping[menuName] = true;
        setIsSelectedMapping(tempIsSelectedMapping);
        setSubNavSelectedMapping(tempSubNavSelectedMapping);
      }
    }

    if (item.link) {
      if (Object.keys(subNavSelectedMapping).includes(item.title)) {
        console.log(parent);
        const tempSubNavSelectedMapping = { ...subNavSelectedMapping };
        const tempIsSelectedMapping = { ...isSelectedMapping };

        if (parent.children && parent.children.length > 0) {
          Object.keys(tempIsSelectedMapping).forEach((key, idx) => {
            if (tempIsSelectedMapping[key]) {
              tempIsSelectedMapping[key] = false;
            }
          });
          Object.keys(tempSubNavSelectedMapping).forEach((key, idx) => {
            if (tempSubNavSelectedMapping[key]) {
              tempSubNavSelectedMapping[key] = false;
            }
          });
          tempIsSelectedMapping[parent.title] = true;
          setIsSelectedMapping(tempIsSelectedMapping);
          setSubNavSelectedMapping(tempSubNavSelectedMapping);
        }

        tempSubNavSelectedMapping[item.title] = true;
        setSubNavSelectedMapping(tempSubNavSelectedMapping);
        setIsAccountDDOpen(false)
      }
      setIsSidebarOpen(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={styles.topHeading}>
        <h2>Settings</h2>
        <button
          onClick={(e) => {
            setIsSidebarOpen(false);
            setIsAccountDDOpen(true);
          }}
        >
          <CrossIcon />
        </button>
      </div>
      {sidebarContent.map((item, idx) => (
        <div className={styles.sectionContainer}>
          <div className={`${styles.sectionTitle}`}>{item.sectionTitle}</div>
          {item.children.map((val) => (
            <div className={styles.innerContainer}>
              <Link
                to={val.link || "#"}
                className={`${styles.navItem} ${
                  isSelectedMapping && isSelectedMapping[val.title]
                    ? styles.selected
                    : ""
                } ${val.toFill ? styles.target : ""} ${
                  val.toStroke ? styles.target2 : ""
                }`}
                onClick={(e) => handleLinkClick(val)}
                {...{ ...(val.link && { to: val.link }) }}
              >
                <>{val.icon}</>
                <div>{val.title}</div>
                {val.children.length > 0 ? (
                  <div className={styles.furtherActionButton}>
                    <MenuOperationIcon
                      isOpen={
                        menuOpenMapping &&
                        Object.keys(menuOpenMapping).includes(val.title) &&
                        menuOpenMapping[val.title]
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
                <SelectedIndicator
                  isSelected={isSelectedMapping && isSelectedMapping[val.title]}
                />
              </Link>
              <div className={styles.navContainer}>
                {menuOpenMapping && menuOpenMapping[val.title] ? (
                  val.children.map((subNav) => (
                    <Link
                    //   to={subNav.link || "#"}
                      className={`${styles.subItem} ${
                        subNavSelectedMapping &&
                        subNavSelectedMapping[subNav.title]
                          ? styles.subItemSelected
                          : ""
                      }`}
                      {...{ ...(subNav.link && { to: subNav.link }) }}
                      onClick={(e) => handleLinkClick(subNav, val)}
                    >
                      {subNav.title}
                    </Link>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
          <div className={styles.horizontalDivider}>
            <HorizontalDivider />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
