import { isEqual } from "lodash"
import { updateBookedMeeting } from "../../../utils/eventService"
import { triggerToast } from "../../../utils/toastController"
import { getTimeInFormat } from "./utils"
import dayjs from "dayjs"

function getTimedelta(reminderString) {
	if(!reminderString || reminderString.trim() === "" || reminderString.trim().toLowerCase() === "none") {
		return 0
	}
	const reminderStringPart = reminderString.trim().split(" ")
	const seconds = reminderStringPart[1].toLowerCase().includes("minutes") ? 60 : 3600
	return parseInt(reminderStringPart[0]) * seconds * 1000
}


async function bookedMeetingUpdater(allEventData,updateFutureSessions){

	const {requiredEventData} = allEventData

	const editedTime = {
		start : getTimeInFormat(allEventData.selectedStartTime), 
		end: getTimeInFormat(allEventData.selectedEndTime)
	}
	const isTimeEqual = isEqual(requiredEventData.time,editedTime)
	const isDateEqual = allEventData.selectedDate.format('DD/MM/YYYY') === dayjs(requiredEventData.date).format('DD/MM/YYYY')

	const fields = {
		...(isDateEqual === false) && { date : allEventData.selectedDate.toISOString()},
		...(isTimeEqual === false) && { time : editedTime},
		timeZone : allEventData.selectedTimeZone,
		reminder : {
			isEnabled : allEventData.isRemainder.trim() !== "" && allEventData.isRemainder.trim().toLowerCase() !== "none",
			targetUserTypes : Object.keys(allEventData.sendRemindersTo).filter((key)=>allEventData.sendRemindersTo[key]===true && key !== "attendees"),
			timeDelta : getTimedelta(allEventData.isRemainder)
		},
		...(allEventData.isSelectedUser.length > 0 && {
			attendeeDetails: allEventData.isSelectedUser?.map((user) => ({
				id: user,
			})),
		}),
		location: allEventData.location,
		meetingLinkDetails: {
			link: allEventData.meetingLink,
			provider: "Any",
		}
	}

	try {
		const res = await updateBookedMeeting(requiredEventData._id, fields)
		triggerToast("Booked meeting updated successfully", "success", {
			autoClose: 3000,
		});
	}catch(err){
		console.error(err)	
		triggerToast("Booked meeting updation failed.", "error", {
			autoClose: 3000,
		});
	}


}


export default bookedMeetingUpdater
