import React from 'react'

const StudentIcon = ({ type }) => {
    return type !== "active" ? (
        <svg width="24" height="24" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5612 7.94832C14.5612 9.91277 12.9701 11.5039 11.0056 11.5039C9.0412 11.5039 7.45009 9.91277 7.45009 7.94832L7.54786 7.11277L4.78342 5.7261L11.0056 2.61499L17.2279 5.7261V10.1705H16.339V6.17055L14.4634 7.11277L14.5612 7.94832ZM11.0056 13.2817C14.9345 13.2817 18.1168 14.8728 18.1168 16.8372V18.615H3.89453V16.8372C3.89453 14.8728 7.07675 13.2817 11.0056 13.2817Z" fill="white" />
        </svg>
    ) :
        (<svg width="28" height="28" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5612 7.94832C14.5612 9.91277 12.9701 11.5039 11.0056 11.5039C9.0412 11.5039 7.45009 9.91277 7.45009 7.94832L7.54786 7.11277L4.78342 5.7261L11.0056 2.61499L17.2279 5.7261V10.1705H16.339V6.17055L14.4634 7.11277L14.5612 7.94832ZM11.0056 13.2817C14.9345 13.2817 18.1168 14.8728 18.1168 16.8372V18.615H3.89453V16.8372C3.89453 14.8728 7.07675 13.2817 11.0056 13.2817Z" fill="white" />
        </svg>
        )
}

export default StudentIcon