import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  useLazyGetPersonalDetailQuery,
  useLazyGetOrganizationQuery,
  useLazyGetAllUsersQuery,
  useBulkChangeUserStatusMutation,
  useLazyGetAllTutorsForOrgQuery,
} from "../../../../app/services/users";
import { fetchTutorsForTable } from "../../../../utils/tutorService";
import OrangeCheckbox from "../../../../components/CCheckbox/OrangeCheckbox";
import InfoIcon from "../../../../components/InfoIcon/InfoIcon";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import AssignTutorLicensesTable from "../../../../components/AssignTutorLicensesTable/AssignTutorLicensesTable";
import ResponsiveAssignTutorLicensesTable from "../../../../components/AssignTutorLicensesTable/ResponsiveAssignTutorLicensesTable";
import InputSearch from "../../../../components/InputSearch/InputSearch";
import PaginationV2 from "../../../../components/PaginationV2/PaginationV2";
import PageIndicatorV2 from "../../../../components/PaginationV2/PageIndicatorV2";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { fetchAndSetOrgDetails, updateOrg } from "../../../../utils/orgService";
import { triggerToast } from "../../../../utils/toastController";

const TutorLicenses = () => {
  const [allTutorsOfOrg, setAllTutorsOfOrg] = useState([]);
  const [tutorSearchKeyWords, setTutorSearchKeywords] = useState(null);
  const [selectedPageTutorsTable, setSelectedPageTutorsTable] = useState(1);
  const [isEnableAutoFillActive, setIsEnableAutoFillActive] = useState(true);
  const [isNotifyTutorActive, setIsNotifyTutorActive] = useState(true);
  const [tutorSortStatesData, setTutorSortStatesData] = useState({
    tutorName: -1,
    studentCount: -1,
    dateAdded: -1,
    lastLogin: -1,
    licenseStatus: -1,
  });
  const [getTutorsReq] = useLazyGetAllTutorsForOrgQuery();
  const [bulkChangeUser] = useBulkChangeUserStatusMutation();
  const { subscriptionV2, oldSubscriptionExists } = useSelector(
    (state) => state.subscription
  );
  const {organization} = useSelector((state)=>state.organization)

  useEffect(() => {
    fetchTutorsForTable(
      tutorSortStatesData,
      tutorSearchKeyWords,
      setAllTutorsOfOrg,
      getTutorsReq
    );
  }, [tutorSortStatesData, tutorSearchKeyWords]);

  useEffect(() => {
    if (localStorage.getItem("notifyActive") === "true") {
      setIsNotifyTutorActive(true);
    } else if (localStorage.getItem("notifyActive") === "false") {
      setIsNotifyTutorActive(false);
    }
  }, [localStorage.getItem("notifyActive")]);

  return (
    <div className="w-full">
      <div className="grid w-[100%] rounded-[15px]  h-fit gap-[14px]">
        <div className="text-secondary-80 text-[16px] font-[400]">
          Tutors with an “Active” license status will be able to login to their
          Evallo account within your organization. Click on ‘Manage
          Subscription’ to purchase additional Tutor Licenses.
        </div>
        <div
          className={`flex flex-col justify-start items-start w-full gap-[24px] `}
        >
          <div className="flex flex-col items-start flex-wrap gap-[24px] h-auto w-full">
            <div className="w-full flex flex-col-reverse justify-start gap-[12px] md:flex-row md:justify-between items-center border-secondary-20">
              <InputSearch
                onChange={(e) => setTutorSearchKeywords(e.target.value.trim())}
                placeholder={"Search by tutor name"}
                inputContainerClassName={
                  "!bg-white w-full border-[1px] border-secondary-20 rounded-[5px] text-secondary flex flex-row-reverse justify-between !px-[0px] gap-[20px] !pr-[158px]"
                }
                inputClassName={
                  "!bg-white w-full  rounded-[5px] text-secondary placeholder:!text-secondary-80"
                }
                IconSearch={true}
              />
              <div className="flex w-full  md:w-fit items-center">
                <div className="text-secondary-80 font-[400] text-[20px] whitespace-nowrap">
                  <div>
                    Used Tutor Licenses :{" "}
                    <span className="font-[500] text-secondary">
                      {allTutorsOfOrg.filter((val) => val.isActive).length}
                    </span>{" "}
                    / <span>{subscriptionV2?.subscription.tutors.limit}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:w-full">
              <AssignTutorLicensesTable
                tutorData={allTutorsOfOrg.slice(
                  (selectedPageTutorsTable - 1) * 10,
                  (selectedPageTutorsTable - 1) * 10 + 10
                )}
                setParentViewTutorSortedStatesData={setTutorSortStatesData}
                onActivateClicked={(e) => {
                  if (
                    subscriptionV2 &&
                    allTutorsOfOrg.filter((val) => val.isActive).length >=
                      subscriptionV2?.subscription.tutors.limit
                  ) {
                    toast.error(
                      "All tutor licenses have been used please extend your limits or deactive other tutors to continue."
                    );
                    return;
                  }
                  const tutorIds = allTutorsOfOrg
                    .filter((val) => val.isActive === true)
                    .map((val) => val._id);
                  tutorIds.push(e.target.id);
                  bulkChangeUser({ tutorIds: tutorIds }).then((res) => {
                    if (res.error) {
                      console.log(res.error);
                    } else {
                      fetchTutorsForTable(
                        tutorSortStatesData,
                        tutorSearchKeyWords,
                        setAllTutorsOfOrg,
                        getTutorsReq
                      );
                    }
                  });
                }}
                onDeactivateClicked={(e) => {
                  let deactivateTutors = allTutorsOfOrg
                    .filter((val) => val.isActive === false)
                    .map((val) => val._id);
                  deactivateTutors.push(e.target.id);
                  let tutorIds = allTutorsOfOrg
                    .filter((val) => !deactivateTutors.includes(val._id))
                    .map((val) => val._id);
                  bulkChangeUser({ tutorIds: tutorIds }).then((res) => {
                    if (res.error) {
                      console.log(res.error);
                    } else {
                      fetchTutorsForTable(
                        tutorSortStatesData,
                        tutorSearchKeyWords,
                        setAllTutorsOfOrg,
                        getTutorsReq
                      );
                    }
                  });
                }}
              />
            </div>
            <div className="w-full md:hidden">
              <ResponsiveAssignTutorLicensesTable
                tutorData={allTutorsOfOrg.slice(
                  (selectedPageTutorsTable - 1) * 10,
                  (selectedPageTutorsTable - 1) * 10 + 10
                )}
                setParentViewTutorSortedStatesData={setTutorSortStatesData}
                onActivateClicked={(e) => {
                  if (
                    subscriptionV2 &&
                    allTutorsOfOrg.filter((val) => val.isActive).length >=
                      subscriptionV2?.subscription.tutors.limit
                  ) {
                    toast.error(
                      "All tutor licenses have been used please extend your limits or deactive other tutors to continue."
                    );
                    return;
                  }
                  const tutorIds = allTutorsOfOrg
                    .filter((val) => val.isActive === true)
                    .map((val) => val._id);
                  tutorIds.push(e.target.id);
                  bulkChangeUser({ tutorIds: tutorIds }).then((res) => {
                    if (res.error) {
                      console.log(res.error);
                    } else {
                      fetchTutorsForTable(
                        tutorSortStatesData,
                        tutorSearchKeyWords,
                        setAllTutorsOfOrg,
                        getTutorsReq
                      );
                    }
                  });
                }}
                onDeactivateClicked={(e) => {
                  let deactivateTutors = allTutorsOfOrg
                    .filter((val) => val.isActive === false)
                    .map((val) => val._id);
                  deactivateTutors.push(e.target.id);
                  let tutorIds = allTutorsOfOrg
                    .filter((val) => !deactivateTutors.includes(val._id))
                    .map((val) => val._id);
                  bulkChangeUser({ tutorIds: tutorIds }).then((res) => {
                    if (res.error) {
                      console.log(res.error);
                    } else {
                      fetchTutorsForTable(
                        tutorSortStatesData,
                        tutorSearchKeyWords,
                        setAllTutorsOfOrg,
                        getTutorsReq
                      );
                    }
                  });
                }}
              />
            </div>

            <div className="flex flex-col-reverse gap-[10px] md:flex-row md:justify-between w-full">
              <div className="grid gap-[4px]">
                <div className="flex">
                  <OrangeCheckbox
                    className="h-[16px] w-[16px] hover:cursor-pointer"
                    checked={organization && organization.tutorManagement?.notifyTutorOnAccountStatusChange}
                    onChange={(newVal) => {
						updateOrg(organization._id,
									{"tutorManagement.notifyTutorOnAccountStatusChange" : newVal})
												.then((res)=>{
													triggerToast("Update successfull", "success",{autoClose : 3000})
													fetchAndSetOrgDetails(organization._id)
												})
												.catch((err)=>{
													triggerToast("Update failed", "error", {autoClose : 3000})
												})
                    }}
                  />
                  <div className="ml-1 font-[400] leading-[19.5px] text-secondary-80 text-[14px] w-fit">
                    Notify tutors when their account status is changed by an
                    Admin
                  </div>
                </div>

                <div className="flex items-center">
                  <OrangeCheckbox
                    className="h-[16px] w-[16px] hover:cursor-pointer"
                    checked={organization && organization.tutorManagement?.isAutofillEnabled}
                    onChange={(newVal) => {
						updateOrg(organization._id,
									{"tutorManagement.isAutofillEnabled" : newVal})
												.then((res)=>{
													triggerToast("Update successfull", "success",{autoClose : 3000})
													fetchAndSetOrgDetails(organization._id)
												})
												.catch((err)=>{
													triggerToast("Update failed", "error", {autoClose : 3000})
												})
                    }}
                  />
                  <div className="ml-1 font-[400]  text-secondary-80 text-[14px]">
                    Enable autofill when spots are empty
                  </div>
                </div>
              </div>

              {allTutorsOfOrg.length > 10 ? (
                <div className="flex w-full md:w-[35%] justify-center md:justify-between items-center ps-[10px]">
                  <span className="hidden md:block">
                    <PageIndicatorV2
                      recordStartNo={
                        (selectedPageTutorsTable - 1) * 10 === 0
                          ? 1
                          : (selectedPageTutorsTable - 1) * 10
                      }
                      recordEndNo={(selectedPageTutorsTable - 1) * 10 + 10}
                      totalRecordsCount={allTutorsOfOrg?.length}
                    />
                  </span>
                  <PaginationV2
                    pageSize={10}
                    totalDataCount={allTutorsOfOrg.length}
                    previousButton={{
                      content: (
                        <>
                          <ChevronLeft />
                        </>
                      ),
                      className:
                        "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                    }}
                    nextButton={{
                      content: (
                        <>
                          <ChevronRight />
                        </>
                      ),
                      className:
                        "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                    }}
                    helperButtonClassName={
                      "text-secondary-60 font-[400] font-[calc(100vw*16/1440)] "
                    }
                    selectedHelperButtonClassName={
                      "text-blue-70 font-[600] font-[calc(100vw*16/1440)]"
                    }
                    selectedPage={selectedPageTutorsTable}
                    setSelectedPage={setSelectedPageTutorsTable}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorLicenses;
