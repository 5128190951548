import { useEffect, useState } from 'react';
import styles from './SessionTags.module.css'
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import SettingsEditor from '../../SettingsEditor/SettingsEditor';
import { fetchAndSetOrgDetails, updateOrg } from '../../../utils/orgService';
import SessionTagsOperationPopUp from './Components/SessionTagsOperationPopUp/SessionTagsOperationPopUp';
import ServicesOperationPopUp from '../ServicesTopics/Components/ServicesOperationPopUp/ServicesOperationPopUp';
import { triggerToast } from '../../../utils/toastController';

const SessionTags = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [onlyShowAddIcon, setOnlyShowAddIcon] = useState(false)
    const { sessionTags } = useSelector((state) => state.organization.organization.settings)
    const { organization } = useSelector((state) => state.organization)
    const [sessionTagsOperationPopUpTrigger, setSessionTagsOperationPopUpTrigger] = useState(false)
    const [sessionTagsOperationPopUpMode, setSessionTagsOperationPopUpMode] = useState("add")
    const [tempSessionData, setTempSessionData] = useState(null)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            console.log("Scroll started")
            setOnlyShowAddIcon(true)
        })

        window.addEventListener("scrollend", () => {
            console.log("Scroll ended")
            setOnlyShowAddIcon(false)
        })

        return () => {
            window.removeEventListener("scroll", () => { })
            window.removeEventListener("scrollend", () => { })
        }
    }, [])

    useEffect(() => {
        console.log(tempSessionData)
    }, [tempSessionData])

    const handleEditButtonClick = (idx) => {
        setTempSessionData({
            ...sessionTags[idx],
            idx : idx
        })
        console.log("TESTING 42")
    }

    const handleToggleClick = async (idx, targetValue, setIsLoading) => {
        try {
            setIsLoading(true)
            const requiredField = `settings.sessionTags.${idx}.pause`
            const fields = {}
            fields[requiredField] = !sessionTags[idx].pause
            await updateOrg(organization._id, fields)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log("ERROR", err)
        }
    }


    /**
     * 
     * @param {*} idx 
     * @param {{heading : String, items : [String]}} targetData 
     * @param {function} setIsLoading 
     */
    async function editCurrentTag(idx, targetData, setIsLoading = () => { }) {
        try {
            setIsLoading(true)
            const requiredFieldTemplate = `settings.sessionTags.${idx}.`
            const fieldsToSend = {}
            Object.keys(targetData).forEach((key, idx) => {
                const requiredField = requiredFieldTemplate + key
                fieldsToSend[requiredField] = targetData[key]
            })
            await updateOrg(organization._id, fieldsToSend)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading(false)
            triggerToast("Services updated successfully", "success", { autoClose: 3000 })
            setSessionTagsOperationPopUpTrigger(false)
        } catch (err) {
            setIsLoading(false)
            triggerToast("Some error occured", "error", { autoClose: 5000 })
        }
    }

    /**
     * 
     * @param {{heading : String, items : [String]}} targetData 
     * @param {function} setIsLoading 
     */
    async function addNewTag(targetData, setIsLoading = () => { }) {
        try {
            setIsLoading(true)
            const newData = [targetData, ...sessionTags]
            const fields = {
                "settings.sessionTags": newData
            }
            await updateOrg(organization._id, fields)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading(false)
            triggerToast("New service added successfully", "success", { autoClose: 3000 })
            setSessionTagsOperationPopUpTrigger(false)
        } catch (err) {
            setIsLoading(false)
            triggerToast("Some error occured", "error", { autoClose: 5000 })
        }
    }



    function saveClickController(operationPopUpMode, idx, targetValue, setIsLoading) {
        if (operationPopUpMode === "edit") {
            editCurrentTag(idx, targetValue, setIsLoading)
        } else if (operationPopUpMode === "add") {
            addNewTag(targetValue, setIsLoading)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.sectionOne}>
                <div className={styles.heading}>
                    <>Add session tags to categorize your calendar sessions better.</>
                    <a>Learn More</a>
                </div>
                <div className={styles.action}>
                    <button className={styles.addButton} onClick={(e) => { setSessionTagsOperationPopUpTrigger(true); setSessionTagsOperationPopUpMode("add"); setTempSessionData(null) }}>
                        <AddIcon style={{ height: "14px", width: "14px" }} />
                        {
                            onlyShowAddIcon ?
                                null : <div className={styles.addButtonTextMobile}>Add New</div>
                        }
                        <div className={styles.addButtonTextDesktop}>
                            Add New
                        </div>
                    </button>
                </div>
            </div>
            <div className={styles.mainArea}>
                {
                    sessionTags?.map((item, idx) => (
                        <SettingsEditor items={item.items.map((val) => ({ displayTitle: val }))}
                            idx={idx}
                            heading={item.heading}
                            isEnabled={item.pause === false}
                            handleToggleClick={handleToggleClick}
                            handleEditButtonClick={handleEditButtonClick}
                            setEditPopUpTrigger={setSessionTagsOperationPopUpTrigger}
                            setEditPopUpMode={setSessionTagsOperationPopUpMode}
                            customClassPillContainer={styles.pillAreaContainer}
                            showMoreMode={true}
                            h4CustomStyle={{maxWidth : "calc( ( 100vw - 364px ) / 2 - 24px - 100px"}}
                        />

                    ))
                }
            </div>
            {
                sessionTagsOperationPopUpTrigger ?
                    <SessionTagsOperationPopUp trigger={sessionTagsOperationPopUpTrigger} setTrigger={setSessionTagsOperationPopUpTrigger}
                        popUpOperationMode={sessionTagsOperationPopUpMode} sessionData={tempSessionData}
                        handleSaveClick={(idx, targetValue, setIsLoading)=>saveClickController(sessionTagsOperationPopUpMode, idx, targetValue, setIsLoading)}
                    />
                    : <></>
            }
        </div>
    )
}



export default SessionTags