import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./lineChart.css"
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeScale,
  Tooltip,
  Legend
  // {
  //   id: 'uniqueid5', //typescript crashes without id
  //   afterDraw: function (chart, easing) {
  //     if (chart.tooltip._active && chart.tooltip._active.length) {
  //       const activePoint = chart.tooltip._active[0];
  //       const ctx = chart.ctx;
  //       const x = activePoint.element.x;
  //       const topY = chart.scales.y.top;
  //       const bottomY = chart.scales.y.bottom;
  //       ctx.save();
  //       ctx.beginPath();
  //       ctx.moveTo(x, topY);
  //       ctx.lineTo(x, bottomY);
  //       ctx.lineWidth = 1;
  //       ctx.strokeStyle = '#e23fa9';
  //       ctx.stroke();
  //       ctx.restore();
  //     }
  //   }
  // }
);
ChartJS.register(zoomPlugin);



const iniOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
    },
    x: {
      grid: {
        display: true,
      },
      ticks: {
        stepSize: 5,
        color: "#517CA8",
        fontFamily: 'Lexend',
        family: 'Lexend',
        font: {
          color: "#517CA8",
          family: "Lexend",
        }
        // callback: function (value, index, ticks) {
        //    // return tempsubjects[index]
        //    return index
        // }
      },
    },
    y: {
      // grid: {
      //   display: false
      // }
      // min: 100,

      title: {
        display: true,
        text: "Scores",
        color: "#24A3D9",
        fontFamily: "Lexend",
        font: {
          weight: 600,
          size: 20,
        },
      },
      ticks: {
        stepSize: 5,
        maxTicksLimit: 6,
        color: "#517CA8",
        fontFamily: 'Inter',
        family: 'Inter',
        font: {
          weight: 400,
          size: 15,
          color: "#517CA8",
          family: "Lexend",
        }
        // callback: function (value, index, ticks) {
        //    return '' + value;
        // }
      },
    },
  },
  elements: {
    line: {
      tension: 0.4, // smooth lines
    },
  },
  plugins: {
    // tooltip: {
    //   enabled: false
    // },
    legend: {
      display: false,
      position: "top",
      align: "start",

      labels: {
        usePointStyle: true,
        font: {
          size: 14,
          family: "Lexend",
        },
        marginBottom: 10,
        labelSpacing: 100,
        boxWidth: 120,
        pointStyle: "circle",
        radius: 7,
        padding: 5,
      },
      title: {
        padding: 20

      }
    },
  },
  layout: {
    padding: {
      top: 100,
      bottom: 50,
    },
  },
};

const backgroundColors = [
  "rgba(77, 51, 233, 0.6)",
  "rgba(218, 51, 233, 0.6)",
  "rgba(51, 233, 146, 0.6)",
  "rgba(51, 189, 233, 0.6)",
  "#F1848A",
  "rgba(192, 36, 89, 0.6)",
  "rgba(12, 163, 46, 0.6)",
  "rgba(31, 123, 240, 0.6)",
  "rgba(55, 97, 234, 0.6)",
  "rgba(234, 98, 15, 0.6)",
  "rgba(17, 185, 79, 0.6)",
  "rgba(84, 202, 27, 0.6)",
  "rgba(85, 172, 14, 0.6)",
  "rgba(43, 175, 52, 0.6)",
  "rgba(19, 82, 221, 0.6)",
  "rgba(255, 215, 51, 0.6)",
  "rgba(216, 52, 6, 0.6)",
  "rgba(28, 74, 53, 0.6)",
  "rgba(96, 26, 222, 0.6)",
  "rgba(96, 26, 222, 0.6)",
  "rgba(96, 26, 222, 0.6)",
];
ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const tempdata = [{ x: 5, y: 100, r: 0 }];
const dummy = {
  label: "",
  data: [{ x: 0, y: 0, r: 0 }],
  backgroundColor: "rgba(255, 99, 132, 0)",
};

const data1 = {
  datasets: [
    { ...dummy },
    {
      label: "",
      data: tempdata,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

export default function Chart({ scoreProgression,scroller, subjecttypewidth, dashboard,chartClassName }) {
  const [options, setOptions] = useState(iniOptions);
  const [chartData, setChartData] = useState([]);
  const [currentConcepts, setCurrentConcepts] = useState([]);
  const [linechartwidth, setlinechartwidth] = useState('1540px')
  const [canvasWidth, setCanvasWidth] = useState(600);
  const [data, setData] = useState(data1);
  const [show, setShow] = useState(true);
  const chartRef = useRef(null);
  const [labelofcanvas, setlabelofcanvas] = useState([])

  const { role: persona, id } = useSelector((state) => state.user);

  // const checkIfKeyExists = (concepts) => {
  //    concepts
  // }
  useEffect(() => {
    if (scoreProgression) {
      setChartData(scoreProgression);
    }
  }, [scoreProgression]);

  const getColor = (idx, len) => {
    let index = idx;
    if (idx > backgroundColors?.length) {
      index = index % len;
    }
    // //console.log(index);
    return backgroundColors[index] !== undefined
      ? backgroundColors[index]
      : backgroundColors[0];
  };
  useEffect(() => {
    if (chartData.length === 0) {
      setData({ datasets: [] });

      return;
    }
    const datasets = [];
    let dates = [""];

    const labels = [""];
    let checkIfKeyExists = {};
    for (let cid = 0; cid < chartData?.length; cid++) {
      const score = [
        ...Object.keys(chartData[cid]?.score).map((key) => {
          return key;
        }),
      ];
      const subjects = chartData[cid]?.subjects;
      const concepts = [];
      for (let sid = 0; sid < subjects?.length; sid++) {
        concepts.push(subjects[sid].name);
      }
      const testName = chartData[cid]?.testName;
      const date = new Date(chartData[cid]?.date).toDateString();

      //  //console.log('concepts', concepts)

      labels.push(`${testName}`);

      dates.push(date);
      // console.log(dates,'sdl,');
      for (let sid = 0; sid < subjects?.length; sid++) {
        const x = testName;
        const scale = subjects[sid]?.scoreScale;
        const y = chartData[cid].score[scale];
        if (!checkIfKeyExists.hasOwnProperty(subjects[sid]?.name)) {
          datasets.push({
            label: concepts[sid],
            fill: false,
            borderColor: getColor(sid, subjects?.length),
            data: [
              { x: 0, y: 0, r: 0, label: concepts[sid] },
              { x, y, r: subjects[sid]?.no_of_correct, label: concepts[sid] },
            ],
            backgroundColor: getColor(sid, subjects?.length),
          });
          checkIfKeyExists[subjects[sid]?.name] = datasets?.length - 1;
        } else {
          let iddd = checkIfKeyExists[subjects[sid]?.name];
          datasets[iddd] = {
            ...datasets[iddd],
            data: [
              ...datasets[iddd]?.data,
              { x, y, r: subjects[sid]?.no_of_correct, label: concepts[sid] },
            ],
          };
        }
      }
    }
    setlabelofcanvas(datasets)
    setData({
      labels,
      datasets: datasets,
    });
    setlinechartwidth(`${labels?.length*250<1540?1540:labels?.length*250}px`)
    setOptions((prev) => {
      return {
        ...prev,
        // plugins: {
        //   ...prev.plugins,
        //   tooltip: {
        //     ...prev.plugins.tooltip,
        //     callbacks: {
        //       label: function (context, c, d) {
        //         return `  ${"Time "} : ${context.parsed.y / 60} minutes`;
        //       },
        //     },
        //   },
        // },
        scales: {
          ...prev.scales,
          x: {
            ...prev.scales.x,
            ticks: {
              ...prev.scales.x.ticks,

              callback: function (value, index, ticks) {
                // console.log(dates,index,labels,'dhkll');
                const value2 = `${labels[index]}|${dates[index]}`;
                const lines = value2?.split("|");
                return index === 0 ? "" : lines;
                // return index === 0 ? "" : `${labels[index]} \n ${dates[index]}`;
              },
            },
            font: {
              size: 12, // Adjust the font size as needed
            },
          },
        },
      };
    });

  }, [chartData]);
  const [sss, setsss] = useState(0)
  useEffect(() => {
    setsss(`w-[${data?.labels?.length * 315 < 1550 ? '1550px' : data?.labels?.length * 315 + 'px'}]`)


  }, [chartData, scoreProgression])

  function removeAlphaFromRGBA(rgbaColor) {
    // Match RGBA format using regular expression
    const match = rgbaColor.match(/rgba?\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/);

    if (match) {
      // Extract RGB values
      const [_, red, green, blue, alpha] = match;

      // Convert values to integers and create the new RGB format
      const rgbColor = `rgb(${parseInt(red)}, ${parseInt(green)}, ${parseInt(blue)})`;

      return rgbColor;
    }

    // Return original color if the format is not recognized
    return rgbaColor;
  }
  function pp(){
    return linechartwidth
  }

  return (
    data !== undefined && (
      <div className={`wrapper w-full  min-w-2/3 ${scroller==true?'canvas-scroller-3':'canvas-scroller-2 '} mb-[10px] relative overflow-x-auto`}>
        <div className={`flex flex-row absolute left-[45px] top-[40px] ${subjecttypewidth !== undefined ? 'w-[1250px]' : 'w-[755px]'} font-[lexend] scroll-hide overflow-x-auto gap-[40px]`}>
          {
            labelofcanvas?.map((it) => {
              return (
                <div className="flex items-center">
                  <div style={{ backgroundColor: `${removeAlphaFromRGBA(it.backgroundColor)}` }} className={`h-[6px] w-[6px] !bg-opacity-100 rounded-full mr-[20px] `}>
                  </div>

                  <p style={{ color: `${removeAlphaFromRGBA(it.backgroundColor)}` }} className="font-normal whitespace-nowrap text-[14px] ">{it.label}</p>
                </div>
              )
            })
          }
        </div>
        <div>
          <p className="text-[20px] font-semibold text-[#24A3D9] bottom-4 left-[45%] right[50%] absolute">Assignments</p>
        </div>
        <div style={{ width: linechartwidth }} className={`wrapper ${dashboard!==false?'h-[425px]':' h-[537.5px]'} canvasWidthFull ${chartClassName ? chartClassName : ''}`}>
          <Line
            ref={chartRef}
            options={options}
            data={data}
            height={200}
            width={canvasWidth}
          />{" "}
        </div>
      </div>
    )
  );
}