import React, { useEffect, useState } from "react";
import "./MobileViewReferral.css";
import PopupModelV2 from "../PopupModelV2/PopupModelV2";
import { useLazyGetSettingsQuery } from "../../app/services/session";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, getAuthHeader } from "../../app/constants/constants";
import axios from "axios";
import { useUpdateOrgSettingMutation } from "../../app/services/settings";
import { updateOrganizationSettings } from "../../app/slices/organization";
import MobileReferralCard from "../MobileReferralCard/MobileReferralCard";

const MobileViewReferrals = () => {
  const dispatch = useDispatch();
  const [updateSetting, updateSettingResp] = useUpdateOrgSettingMutation();

  const [showPopup, setShowPopup] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(true);
  const [settingsData, setSettingsData] = useState({});
  const { organization } = useSelector((state) => state.organization);
  const { subscriptionV2 } = useSelector((state) => state.subscription);

  const [filteredTests, setFilteredTests] = useState([]);
  const [allTestData, setAllTestData] = useState([]);
  const [popupData, setPopupData] = useState(null);

  const printTestName = (itemId) => {
    const test = filteredTests.find((data) => data._id === itemId);
    return test ? test.value : "";
  };

  const fetchTests = () => {
    axios
      .get(`${BASE_URL}api/test`, { headers: getAuthHeader() })
      .then((res) => {
        if (res.data.data.test) {
          let arr = res.data.data.test.map((item) => {
            return {
              _id: item._id,
              value: item.testName,
            };
          });
          setAllTestData(arr);
          setFilteredTests(arr);
        }
      });
  };

  const [getSettings, getSettingsResp] = useLazyGetSettingsQuery();

  const handleCreateNew = () => {
    setShowPopup(true);
  };
  const handleClose = () => {
    setShowPopup(false);
    setPopupData(null);
  };
  const handleToggle = (item) => {
    let updated = settingsData.subscriptionCode.map((subscription) => {
      if (item._id === subscription._id) {
        return { ...item, pause: !item.pause };
      } else {
        return { ...subscription };
      }
    });
    let updatedSetting = {
      subscriptionCode: updated,
    };
    setIsToggleOn(!isToggleOn);
    updateAndFetchsettings(updatedSetting);
  };
  const updateAndFetchsettings = (updatedSetting) => {
    if (!organization || !settingsData || !updatedSetting) return;
    const settings = {
      ...settingsData,
      ...updatedSetting,
    };
    const body = {
      settings,
    };
    updateSetting(body)
      .then((res) => {
        setSettingsData(res.data.data.updatedOrg.settings);
        dispatch(updateOrganizationSettings(res.data.data.updatedOrg.settings));
      })
      .catch((err) => {
        alert("Error Occured!");
        //console.log("err", err);
      });
  };

  const fetchSettings = (newSettingsData = null) => {
    getSettings().then((res) => {
      if (newSettingsData) {
        setSettingsData(newSettingsData);
      } else {
        setSettingsData(organization?.settings);
      }
    });
  };
  const handleVectorClick = (item) => {
    console.log("item", item);
    setPopupData(item);
    setShowPopup(true);
  };
  useEffect(() => {
    fetchSettings();
    fetchTests();
  }, [organization]);

  return (
    <>
      <div>
        <div className="referral-mobile-custom-elements">
          <div className="referral-mobile-frame">
            <div className="referral-mobile-div">
              <div className="referral-mobile-frame-2">
                <div className="referral-mobile-text-wrapper-2">
                  Referral Codes
                </div>
                <img
                  className="referral-mobile-img"
                  src="/images/input_field_icon.jpg"
                />
              </div>
              <p className="referral-mobile-give-special-access">
                <span className="referral-mobile-span">
                  Give special access to students and parents via custom
                  referral codes.{" "}
                </span>
                <span className="referral-mobile-text-wrapper-3">
                  Learn more.
                </span>
              </p>
              <button
                onClick={handleCreateNew}
                className="referral-mobile-button"
              >
                <img className="referral-mobile-img" src="/images/plus.svg" />
                <div className="referral-mobile-verify-email">Create new</div>
              </button>
            </div>

            {settingsData?.subscriptionCode &&
              settingsData?.subscriptionCode?.map((item, index) => {
                return (
                  <div key={index}>
                    <MobileReferralCard
                      key={index}
                      item={item}
                      filteredTests={filteredTests}
                      handleToggle={handleToggle}
                      handleVectorClick={handleVectorClick}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {showPopup && (
        <PopupModelV2
          isOpen={showPopup}
          onClose={handleClose}
          updateSettingsData={fetchSettings}
          data={popupData}
        />
      )}
    </>
  );
};

export default MobileViewReferrals;
