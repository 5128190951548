import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  useCreateIntentMutation,
  useFinishSetupMutation,
  useMakeDefaultPaymentMutation,
} from "../../../app/services/subscription";
import { useDispatch, useSelector } from "react-redux";
import { triggerToast } from "../../../utils/toastController";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";
import { fetchPaymentMethodsForCustomer } from "../../../utils/subscriptionService";
import {
  updateDefaultPaymentMethodId,
  triggerSubscriptionUpdate,
} from "../../../app/slices/subscription";

const AddNewCard = ({ setAddNewCard, isFromSettings = false }) => {
  const elements = useElements();
  const stripe = useStripe();
  const { stripeCustomerId } = useSelector(
    (state) => state?.organization?.organization
  );
  const [createIntentForPayment, createIntentForPaymentResp] =
    useCreateIntentMutation();
  const [finishSetupForPayment, finishSetupForPaymentResp] =
    useFinishSetupMutation();
  const [makeDefaultPayment] = useMakeDefaultPaymentMutation();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handlePaymentMethod = async () => {
    console.log("inside set up payment");
    console.log(stripeCustomerId);
    if (
      !stripe ||
      stripeCustomerId === undefined ||
      stripeCustomerId === "" ||
      stripeCustomerId === null
    ) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      setLoading(false);
      return;
    }

    // Create the SetupIntent and obtain clientSecret
    const res = await createIntentForPayment({
      customer_id: stripeCustomerId,
    });

    console.log("add new payment");

    console.log("response from create_intent");
    console.log(res);

    const { client_secret: clientSecret } = res.data;
    // const { client_secret: clientSecret } = await res.json();
    console.log(clientSecret);
    // Confirm the SetupIntent using the details collected by the Payment Element
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: "http://localhost:3000/settings?tab=3",
      },
    });

    console.log("setupIntent");
    console.log(setupIntent);

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      triggerToast(
        "Oops! Seems like the card info you added has some issue. Please check and try again.",
        "error",
        { autoClose: 5000 }
      );
      handleError(error);
	  setLoading(false)
      setUpdateSubContinueClicked(false);
    } else {
      const post = await finishSetupForPayment({
        setupintent: setupIntent?.id,
        // subscriptions: [
        //   chosenSubscriptionObjectFromAPI,
        //   [...chosenExtentionObjectsFromAPI],
        // ],
      });

      console.log("response from finish_setup");
      console.log(post);

      if (post) {
        triggerToast("Payment method added successfully", "success", {
          autoClose: 1000,
        });
        if (isFromSettings) {
          dispatch(triggerSubscriptionUpdate());
          setLoading(false);
          setAddNewCard(false);
          return;
        }
        if (!isFromSettings) {
          fetchPaymentMethodsForCustomer({ stripeCustomerId: stripeCustomerId })
            .then((res) => {
              console.log(">>>RED", res.data);
              const latestCard = res.data[0];
              makeDefaultPayment({
                customerId: stripeCustomerId,
                paymentMethodId: latestCard.id,
              }).then((res) => {
                setLoading(false);
                setAddNewCard(false);
                if (res.error) {
                  triggerToast(
                    "Making default payment method failed",
                    "error",
                    {
                      autoClose: 5000,
                    }
                  );
                } else {
                  dispatch(updateDefaultPaymentMethodId(latestCard.id));
                }
              });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              setAddNewCard(false);
            });
        }
      }
    }
  };

  return (
    <div className="grid gap-[24px] w-full">
      <PaymentElement />
      <div
        className={`flex gap-[12px] ${
          isFromSettings ? "justify-between" : "justify-end"
        }`}
      >
        <button
          className={`${
            isFromSettings ? "basis-1/2" : ""
          } border-secondary-60 text-secondary-60 border-[1px] py-[10px] px-[20px] rounded-[6px]`}
          onClick={() => {
            setAddNewCard(false);
          }}
        >
          Cancel
        </button>
        <button
          className={`${
            isFromSettings ? "basis-1/2" : "min-w-[110px]"
          } bg-blue-70 py-[10px] px-[20px] rounded-[6px] flex justify-center text-center text-white `}
          onClick={() => {
            handlePaymentMethod();
          }}
        >
          {loading ? <LoaderIcon /> : isFromSettings ? "Add" : "Add New"}
        </button>
      </div>
    </div>
  );
};

export default AddNewCard;
