import styles from './ButtonV2.module.css'


const ButtonV2 = (props) => {
    return (
        <button {...props} className={`${styles.buttonV2} ${props.className}`} ></button>
    )
}

export default ButtonV2
