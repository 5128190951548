import { useCallback, useRef, useState } from "react"
import { EvChevronDownIcon, EvChevronUpIcon } from "../../assets/icons/ChevronIcons"
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import frozenEventTypes from "../../utils/eventTypes"
import MoreInfoIcon from "../MoreInfoIcon/MoreInfoIcon"
import ChangeIcon from "./ChangeIcon"


const EVENT_LIST = [
	{
		eventType : frozenEventTypes.EXTMEETING.eventType,
		displayValue : frozenEventTypes.EXTMEETING.displayValue
	},
	{
		eventType : frozenEventTypes.SESSION.eventType,
		displayValue : frozenEventTypes.SESSION.displayValue
	},
	{
		eventType : frozenEventTypes.CONSULTATION.eventType,
		displayValue : frozenEventTypes.CONSULTATION.displayValue	
	}
	
]

function ConvertEventTypeDD({selectedEventType, setSelectedEventType}){

	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [boundayCross, setBoundaryCross] = useState(false);

	const ref = useCallback((node) => {
		console.log(node);
		if (node) {
			const dropdownRect = node?.getBoundingClientRect();
			if (dropdownRect.bottom + 150 > window.innerHeight) {
				setBoundaryCross(true);
			} else {
				setBoundaryCross(false);
			}
		}
	});

	
	const dropdownRef = useRef()
	useOutsideAlerter(dropdownRef,()=>{
		setIsDropdownOpen(false)
	})	

	return (
		<div className="w-full flex items-center justify-start gap-[24px] -mt-[8px]" ref={ref}>
			<div className="flex items-center justify-start gap-[8px] font-[400] text-[14px] leading-[21px] text-secondary-80">
				<span>Change event type</span>	
				<MoreInfoIcon />
			</div>
			<div className="py-[7.5px] px-[12px] bg-secondary-190 rounded-[6px] text-secondary-60 font-[400] text-[14px] leading-[21px] w-[240px] flex items-center justify-between relative cursor-pointer" ref={dropdownRef} onClick={()=>setIsDropdownOpen(prev=>!prev)}>
				<span>{"Booked Meeting"}</span>	
				<button>{isDropdownOpen ? <EvChevronUpIcon /> : <EvChevronDownIcon /> }</button>	
				{
					isDropdownOpen ? 
						<div className={`absolute ${!boundayCross ? "top-[calc(100%+10px)]" : "bottom-[calc(100%+10px)]" } left-[0px] py-[6px] w-full bg-white rounded-[6px] shadow-[0px_4.05px_26.98px_0px_#000AFF29] flex flex-col items-start justify-start`}>
							{
								EVENT_LIST.map((eventType, idx)=>(
									<button className="w-full py-[9px] px-[8px] text-secondary text-left font-[400] text-[14px] leading-[14px] hover:bg-secondary-5" onClick={()=>setSelectedEventType(eventType.eventType)}>{eventType.displayValue}</button>
								))
							}

						</div>

					: <></>
				}
			</div>
		</div>
	
	)

}


export default ConvertEventTypeDD
