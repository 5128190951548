import React from 'react'
import styles from './style2.module.css'
import emptyCheckbox from "../../assets/checkbox/Emptycheckbox.svg"
import BlueBorderEmptyCheckbox from "../../assets/checkbox/BlueBorderEmptyCheckbox.svg"
import Checkbox from "../../assets/checkbox/checkbox.svg"
import disabledCheckedCheckbox from './disabledCheckedCheckbox.svg'

export default function CCheckbox({ checked, className, onChange, name, disabled, value, checkBoxClassName, customSize, customTickIconSize, blueEmptyCheckbox }) {

   const handleClick = () => {
      if (disabled === true || !onChange) return
      onChange(value)
   }

   return (
      <div className={`${customSize ? customSize : "w-[22.5px] h-[16px]"} ml-[5px]  ${styles.container} ${className}`}
         onClick={handleClick} >
         <input checked={checked} type='checkbox' name={name ? name : 'moods'} value='' onChange={(e) => e.target.value} />
         {
            disabled ? <img src={checked ?  disabledCheckedCheckbox : emptyCheckbox} alt="checkbox" className={`${styles.checkmark} ${styles.checkedDisabled} ${customTickIconSize ? customTickIconSize : "w-[16px] h-[16px]"} ${checkBoxClassName}`} /> : <>

               {
                  blueEmptyCheckbox ?
                     <img src={checked ? Checkbox : BlueBorderEmptyCheckbox} alt="checkbox" className={`${styles.checkmark}  ${customTickIconSize ? customTickIconSize : "w-[16px] h-[16px]"} ${checkBoxClassName}`} />

                     :
                     <img src={checked ? Checkbox : emptyCheckbox} alt="checkbox" className={`${styles.checkmark}  ${customTickIconSize ? customTickIconSize : "w-[16px] h-[16px]"} ${checkBoxClassName}`} />
               }
            </>
         }
         {/* <span className={`${styles.checkmark}  ${customTickIconSize ? customTickIconSize : "w-[16px] h-[16px]"} ${checkBoxClassName}`}></span> */}
      </div>
   )
}