import frozenEventStatus from "../../utils/eventStatus";
import frozenEventTypes from "../../utils/eventTypes";
import ClockIcon from "../CalendarSideIcons/ClockIcon";
import { GCalIcon } from "../CalendarSideIcons/GCalIcon";
import InfoIcon2 from "../CalendarSideIcons/InfoIcon2";
import LocationIcon from "../CalendarSideIcons/LocationIcon";
import PersonIcon from "../CalendarSideIcons/PersonIcon";
import EventStatusTag from "../EventStatusTag/EventStatusTag";
import styles from "./EventCardDetails.module.css";

const fieldIdentifierMapping = {
  students: "Students:",
  tutor: "Tutor:",
  service: "Service:",
  dateTime: <ClockIcon />,
  location: <LocationIcon />,
  onlineMeet: <GCalIcon />,
  attendees: <PersonIcon />,
  info: <InfoIcon2 />,
};

const eventTypeFieldsMapping = {
  [frozenEventTypes.CONSULTATION.eventType.toLowerCase()]: [
    "attendees",
    "dateTime",
    "location",
    "onlineMeet",
  ],
  [frozenEventTypes.SESSION.eventType.toLowerCase()]: [
    "dateTime",
    "students",
    "tutor",
    "service",
    "location",
    "onlineMeet",
  ],
  [frozenEventTypes.OFFCWORK.eventType.toLowerCase()]: ["attendees", "dateTime", "info"],
  [frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]: ["attendees", "dateTime", "info"],
  [frozenEventTypes.INTMEETING.eventType.toLowerCase()]: [
    "attendees",
    "dateTime",
    "location",
    "onlineMeet",
  ],
  [frozenEventTypes.EXTMEETING.eventType.toLowerCase()]: [
    "attendees",
    "dateTime",
    "location",
    "onlineMeet",
  ],
};

function formatFieldValue(field, fieldValue) {
  if (Array.isArray(fieldValue)) {
    // Format lists (students and attendees) as a comma-separated string
    return fieldValue.join(", ");
  }
  return fieldValue;
}

function EventCardDetailsItem({ fieldIdMapping, requiredField, eventData }) {
  const label = fieldIdMapping[requiredField];
  const value = formatFieldValue(requiredField, eventData[requiredField]);

  return (
    <div className={`${styles.detailsContainer} ${styles[requiredField]}`}>
      <span className={styles.fieldId}>{label} </span>
      {requiredField === "onlineMeet" ? (
        <a
          className={styles.fieldValue}
          style={{ textDecoration: "underline" }}
          href={eventData[requiredField]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      ) : (
        <span className={styles.fieldValue}>{value}</span>
      )}
    </div>
  );
}

function EventCardDetails({ eventData }) {

  const { eventType, status } = eventData;

  console.log("TEST 86", eventType, status)

  return (
    <div className={styles.container}>
      <EventStatusTag status={status === "" ? frozenEventStatus.SCH.status : status} />
      {eventTypeFieldsMapping[eventType.toLowerCase()].map((field, idx) => (
        <EventCardDetailsItem
          key={idx}
          fieldIdMapping={fieldIdentifierMapping}
          requiredField={field}
          eventData={eventData}
        />
      ))}
    </div>
  );
}

export default EventCardDetails;
