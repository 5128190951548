import React from 'react'

const WarningIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96602 2.09713L1.16012 12.1525C1.05524 12.3341 1.00002 12.5401 1 12.7498C0.999981 12.9595 1.05517 13.1655 1.16001 13.3471C1.26485 13.5287 1.41565 13.6795 1.59726 13.7844C1.77886 13.8892 1.98487 13.9444 2.19456 13.9444H13.8054C14.0151 13.9444 14.2211 13.8892 14.4027 13.7844C14.5843 13.6795 14.7352 13.5287 14.84 13.3471C14.9448 13.1655 15 12.9595 15 12.7498C15 12.5401 14.9448 12.3341 14.8399 12.1525L9.0346 2.09713C8.92977 1.91558 8.77901 1.76482 8.59745 1.66C8.4159 1.55518 8.20995 1.5 8.00031 1.5C7.79067 1.5 7.58472 1.55518 7.40317 1.66C7.22161 1.76482 7.07085 1.91558 6.96602 2.09713Z" fill="#FFC56D" />
            <path d="M8.07659 5.19824H7.92514C7.55227 5.19824 7.25 5.50051 7.25 5.87338V9.10042C7.25 9.47329 7.55227 9.77556 7.92514 9.77556H8.07659C8.44946 9.77556 8.75173 9.47329 8.75173 9.10042V5.87338C8.75173 5.50051 8.44946 5.19824 8.07659 5.19824Z" fill="#25335A" />
            <path d="M8.00086 12.3342C8.41555 12.3342 8.75173 11.9981 8.75173 11.5834C8.75173 11.1687 8.41555 10.8325 8.00086 10.8325C7.58617 10.8325 7.25 11.1687 7.25 11.5834C7.25 11.9981 7.58617 12.3342 8.00086 12.3342Z" fill="#25335A" />
        </svg>

    )
}

export default WarningIcon