const frozenEventStatus = Object.freeze({
	
	CANCEL : {
		status : "cancelled",
		displayValue : "Canceled",
		secondaryDisplayValue : "Cancellation",
		statusDisplayValue : "Canceled"
	},
	PENDING : {
		status : "pending",
		displayValue : "Tentative",
		statusDisplayValue : "Tentative"
	},
	SCH : {
		status : "scheduled",
		displayValue : "Scheduled",
		statusDisplayValue : "Scheduled"
	},
	COMPLETED : {
		status : "completed",
		displayValue : "Completed",
		statusDisplayValue : "Completed"
	},
	NOSHOW : {
		status : "noShow",
		displayValue : "No Show",
		statusDisplayValue : "No Show"
	},
	RESCH : {
		status : "rescheduled",
		displayValue : "Rescheduled",
		secondaryDisplayValue :  "Reschedule",
		statusDisplayValue : "Rescheduled"
	},
	TENTATIVE : {
		status : "tentative",
		displayValue : "Tentative",
		secondaryDisplayValue :  "Tentative",
		statusDisplayValue : "Tentative"
	}

})


export default frozenEventStatus
