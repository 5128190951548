import "../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import React, { useEffect, useState } from "react";
import styles from "./CalendarAnalyticsTableView.module.css";
import { getAllEvents } from "../../utils/eventService";
import { useSelector } from "react-redux";
import { transformEvents } from "../../utils/eventUtils";
import Pagination from "@mui/material/Pagination";
import PageIndicatorV2 from "../PaginationV2/PageIndicatorV2";
import PaginationV2 from "../PaginationV2/PaginationV2";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Popover, Tooltip } from "@mui/material";
import ViewAttendees from "../ViewAttendees/ViewAttendees";
import { handleDetailsClick } from "../EventCardBottom/clickHandlers";
import ViewStudent from "../ViewStudent/ViewStudent";
import TentativeStatusIcon from "../../assets/icons/TentativeStatusIcon";
import frozenEventTypes from "../../utils/eventTypes";

const sessionStatusMapping = {
  scheduled: ["scheduled", "SCH", "Scheduled", "SCHEDULED", "sch"].map((val) =>
    val.toLowerCase()
  ),
  completed: ["COMPLETED", "completed"].map((val) => val.toLowerCase()),
  noShow: ["NOSHOW", "no-show", "noshow", "noShow", "Noshow"].map((val) =>
    val.toLowerCase()
  ),
  noshow: ["NOSHOW", "no-show", "noshow", "noShow", "Noshow"].map((val) =>
    val.toLowerCase()
  ),
  cancelled: ["CANCEL", "cancel", "cancelled", "canceled"].map((val) =>
    val.toLowerCase()
  ),
};

function getBgColor(statusText) {
 
	if(typeof(statusText) !== "string"){
		return "#DCF2FF";
	}

  switch (statusText?.toLowerCase()) {
    case "CANCEL".toLowerCase():
      return "#F4F5F7";
    case "NOSHOW".toLowerCase():
      return "#FEF3F2";
    case "COMPLETED".toLowerCase():
      return "#F0FFF9";
	case "sch" :
		return "#DCF2FF";
	case "scheduled" :
		return "#DCF2FF";
	case "schedule" :
		return "#DCF2FF";
	case "tentative" : 
		return "#FFF4DF"
  }
}

function getTextColor(statusText) {
	if(typeof(statusText) !== "string"){
		return "#1D6B98";
	}
  switch (statusText?.toLowerCase()) {
    case "CANCEL".toLowerCase():
      return "rgba(124, 133, 156, 1)";
    case "NOSHOW".toLowerCase():
      return "rgba(180, 35, 24, 0.8)";
    case "COMPLETED".toLowerCase():
      return "rgba(43, 167, 115, 1)";
	case "sch".toLowerCase() :
		return "#1D6B98";
	case "scheduled".toLowerCase() :
		return "#1D6B98";
	case "schedule".toLowerCase() :
		return "#1D6B98";
	case "tentative" : 
		return "#BB800D"
  }
}

const CalendarAnalyticsTableView = (props) => {
  console.log("props props", props);
  const [allEvents, setAllEvents] = useState([]);
  const awsLink = "https://evallo-test.s3.amazonaws.com/";
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const filteredData = useSelector((state) => state.filters.filteredData);


  const user = useSelector(state=>state.user)

  const eventFilteredData = useSelector(
    (state) => state?.events?.eventFilteredData
  );

  const { startDate, endDate } = useSelector((state) => state.dateRange);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredAttendees, setHoveredAttendees] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ top: rect.bottom, left: rect.left });
    setAnchorEl(event.currentTarget);
  };
  console.log("startDate", startDate);
  console.log("endDAte", endDate);
  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  function calculateDuration(startTime, endTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const diffInMs = endDate - startDate;

    const diffInMins = Math.floor(diffInMs / (1000 * 60));

    const hours = Math.floor(diffInMins / 60);
    const minutes = diffInMins % 60;

    let durationStr = "";
    if (hours > 0) {
      durationStr += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    if (minutes > 0) {
      durationStr += `${minutes} min${minutes > 1 ? "s" : ""}`;
    }

    return durationStr.trim() || "0 mins";
  }

  function formatSessionStatus(status) {
    switch (status) {
      case "SCH":
        return "Scheduled";
      case "COMPLETED":
        return "Completed";
      case "CANCEL":
        return "Cancelled";
      case "NOSHOW":
        return "No Show";
      case "pending":
        return "Scheduled";
      default:
        return typeof status === "string"
          ? status?.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
          : "Scheduled";
    }
  }
  function getStatusIcon(status) {
    if (typeof status !== "string") {
      return "/images/scheduled.svg";
    }

    switch (status?.toLowerCase()) {
      case "scheduled":
        return "/images/scheduled.svg";
      case "SCH":
        return "/images/scheduled.svg";
      case "sch":
        return "/images/scheduled.svg";
      case "COMPLETED":
        return "/images/completed.svg";
      case "completed":
        return "/images/completed.svg";
      case "CANCEL":
        return "/images/cancel.svg";
      case "NOSHOW":
        return "/images/disconnect.svg";
      case "noshow":
        return "/images/disconnect.svg";
      case "cancel":
        return "/images/cancel.svg";
      case "cancelled":
        return "/images/cancel.svg";
      case "tentative":
        return  "/images/tentativeicon.svg";
      case "pending":
        return  "/images/scheduled.svg";
      default:
        return "test";
    }
  }
  console.log("eventFilteredData", eventFilteredData);

  const matchesStatus = (sessionStatus, selectedStatusKeys) => {
    const lowerCaseStatus = sessionStatus.toLowerCase();

    return selectedStatusKeys.some((statusKey) => {
      const mappedStatuses = sessionStatusMapping[statusKey.toLowerCase()];
      return mappedStatuses && mappedStatuses.includes(lowerCaseStatus);
    });
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
		let requiredUserIds = []
		if(user && ["tutor","parent","student"].includes(user.role)){
			if(user.role === "parent"){
				requiredUserIds = [...user.associatedStudents]		
			}
			requiredUserIds.push(user.id)
		}
        const events = await getAllEvents(requiredUserIds, user);
        let filteredEvents = events;

        if (props.selectedEventKey) {
          const lowerCaseKey = frozenEventTypes[props.selectedEventKey].eventType.toLowerCase();
          console.log("filteredEvents", lowerCaseKey);
          filteredEvents = events.filter((event) =>
            event.extendedProps.eventType.toLowerCase().includes(lowerCaseKey)
          );
          console.log("filteredEvents", filteredEvents);
        }

        if (props.selectedStatusKeys && props.selectedStatusKeys.length > 0) {
          filteredEvents = filteredEvents.filter((event) =>
            matchesStatus(
              event.extendedProps.sessionStatus,
              props.selectedStatusKeys
            )
          );
        }
        console.log("filteredEvents2", filteredEvents);

        setAllEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (filteredData && filteredData.length > 0) {
      const transformedFilteredData = transformEvents(filteredData);
      let filteredTransformedEvents = transformedFilteredData;

      if (props.selectedEventKey) {
        const lowerCaseKey = props.selectedEventKey.toLowerCase();
        filteredTransformedEvents = transformedFilteredData.filter((event) =>
          event.extendedProps.eventType.toLowerCase().includes(lowerCaseKey)
        );
      }
      if (props.selectedStatusKeys && props.selectedStatusKeys.length > 0) {
        filteredTransformedEvents = filteredTransformedEvents.filter((event) =>
          matchesStatus(
            event.extendedProps.sessionStatus,
            props.selectedStatusKeys
          )
        );
      }
      console.log("filteredEvents3", filteredTransformedEvents);

      setAllEvents(filteredTransformedEvents);
    } else {
      fetchEvents();
    }
  }, [filteredData, props.selectedEventKey, props.selectedStatusKeys]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedData = allEvents.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );
  console.log("paginatedData", paginatedData);
  console.log("props.selectedEventKey", props.selectedEventKey);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "260px" }}>
      <div className={styles.frame}>
        <table style={{ width: "100%" }}>
          <thead>
            <div className={styles.div}>
              <tr className={styles.navbar}>
                <div className={styles.div2}>
                  <div className={styles.textWrapper}>Attendees</div>
                  <img
                    className={styles.down}
                    src="/images/down.svg"
                    alt="Down Arrow"
                  />
                </div>
                <div className={styles.div3}>
                  <div className={styles.textWrapper}>Date &amp; Time</div>
                  <img
                    className={styles.down}
                    src="/images/down.svg"
                    alt="Down Arrow"
                  />
                </div>
                <div className={styles.textWrapper2}>Duration</div>
                <div className={styles.textWrapper3}>Status</div>
                <div className={styles.textWrapper}>Rating</div>
                <div className={styles.textWrapper4}>Description</div>
                <div className={styles.text}></div>
              </tr>

              <div style={{}}>
                {" "}
                <div className={styles.div4}>
                  {paginatedData?.map((row) => {
                    if (props.selectedEventKey === "SESSION") {
                      return (
                        <>
                          <div className={styles.reportRow}>
                            <div className={styles.div5}>
                              {row.extendedProps.studentIds
                                .slice(0, 4)
                                .map((attendee, index) => {
                                  const hasPhoto =
                                    attendee.photo &&
                                    attendee.photo.trim() !== "";
                                  const initials = attendee.firstName.charAt(0);
                                  const tooltipContent = (
                                    <div className={styles.tooltipContent}>
                                      <ViewAttendees row={row} />
                                    </div>
                                  );
                                  return hasPhoto ? (
                                    <Tooltip
                                      key={index}
                                      title={tooltipContent}
                                      placement="right-start"
                                      // arrow
                                      // enterDelay={500} // Delay before the tooltip appears
                                      // leaveDelay={200} // Delay before the tooltip disappears
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [, 50],
                                            },
                                          },
                                        ],
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            bgcolor: "transparent",
                                          },
                                        },
                                      }}
                                    >
                                      <img
                                        alt={attendee.firstName}
                                        src={`${awsLink}${attendee.photo}`}
                                        className={styles.div6}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      key={index}
                                      title={tooltipContent}
                                      placement="right-start"
                                      // arrow
                                      enterDelay={500}
                                      leaveDelay={200}
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [, 60],
                                            },
                                          },
                                        ],
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            // maxWidth: 250,
                                            bgcolor: "transparent",
                                            // boxShadow: 3,
                                          },
                                        },
                                      }}
                                    >
                                      <div className={styles.avatarPlaceholder}>
                                        {initials.toUpperCase()}
                                      </div>
                                    </Tooltip>
                                  );
                                })}

                              {row.extendedProps.studentIds.length > 4 && (
                                <div className={styles.textWrapper5}>
                                  +{row.extendedProps.studentIds.length - 4}
                                </div>
                              )}
                            </div>

                            <div className={styles.div3}>
                              <div className={styles.textWrapper6}>
                                {new Date(
                                  row.extendedProps.date
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </div>
                              <img
                                className={styles.line}
                                src="/images/line-340.svg"
                                alt="Line"
                              />
                              <div className={styles.textWrapper6}>
                                {" "}
                                {new Date(
                                  row.extendedProps.date
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}
                              </div>
                            </div>

                            <div className={styles.textWrapper7}>
                              {calculateDuration(row.start, row.end)}
                            </div>

                            <div className={styles.eventStatusWrapper}>
                              <div
                                className={styles.eventStatus}
                                style={{
                                  backgroundColor: getBgColor(
                                    row.extendedProps.sessionStatus
                                  ),
                                }}
                              >
                                <img
                                  className={styles.img}
                                  src={getStatusIcon(
                                    row.extendedProps.sessionStatus ||
                                      "cancelled"
                                  )}
                                  alt={formatSessionStatus(
                                    row.extendedProps.sessionStatus
                                  )}
                                />
                                <div
                                  className={styles.cancelled}
                                  style={{
                                    color: getTextColor(
                                      row.extendedProps.sessionStatus
                                    ),
                                  }}
                                >
                                  {formatSessionStatus(
                                    row.extendedProps.sessionStatus
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className={styles.div10}>
                              <div className={styles.textWrapper6}>4.0</div>
                              <img
                                className={styles.starLine}
                                src="/images/star.svg"
                                alt="Star"
                              />
                            </div>
                            <p className={styles.textWrapper8}>
                              {row.extendedProps.internalNotes?.note}{" "}
                            </p>
                            <div
                              className={styles.textWrapper9}
                              onClick={() =>
                                handleDetailsClick(row.extendedProps, props)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              Details
                            </div>
                          </div>
                          <div className={styles.line2} />
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div className={styles.reportRow}>
                            <div className={styles.div5}>
                              {row.extendedProps.attendees
                                .slice(0, 4)
                                .map((attendee, index) => {
                                  const hasPhoto =
                                    attendee.photo &&
                                    attendee.photo.trim() !== "";
                                  const initials = attendee.firstName.charAt(0);
                                  const tooltipContent = (
                                    <div className={styles.tooltipContent}>
                                      <ViewStudent
                                        row={row?.extendedProps?.attendees}
                                      />
                                    </div>
                                  );
                                  return hasPhoto ? (
                                    <Tooltip
                                      key={index}
                                      title={tooltipContent}
                                      placement="right-start"
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [, 50],
                                            },
                                          },
                                        ],
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            bgcolor: "transparent",
                                          },
                                        },
                                      }}
                                    >
                                      <img
                                        alt={attendee.firstName}
                                        src={`${awsLink}${attendee.photo}`}
                                        className={styles.div6}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      key={index}
                                      title={tooltipContent}
                                      placement="right-start"
                                      // arrow
                                      enterDelay={500}
                                      leaveDelay={200}
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [, 60],
                                            },
                                          },
                                        ],
                                      }}
                                      componentsProps={{
                                        tooltip: {
                                          sx: {
                                            // maxWidth: 250,
                                            bgcolor: "transparent",
                                            // boxShadow: 3,
                                          },
                                        },
                                      }}
                                    >
                                      <div className={styles.avatarPlaceholder}>
                                        {initials.toUpperCase()}
                                      </div>
                                    </Tooltip>
                                  );
                                })}

                              {row.extendedProps.attendees.length > 4 && (
                                <div className={styles.textWrapper5}>
                                  +{row.extendedProps.attendees.length - 4}
                                </div>
                              )}
                            </div>

                            <div className={styles.div3}>
                              <div className={styles.textWrapper6}>
                                {new Date(
                                  row.extendedProps.date
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </div>
                              <img
                                className={styles.line}
                                src="/images/line-340.svg"
                                alt="Line"
                              />
                              <div className={styles.textWrapper6}>
                                {" "}
                                {new Date(
                                  row.extendedProps.date
                                ).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: true,
                                })}
                              </div>
                            </div>

                            <div className={styles.textWrapper7}>
                              {calculateDuration(row.start, row.end)}
                            </div>

                            <div className={styles.eventStatusWrapper}>
                              <div
                                className={styles.eventStatus}
                                style={{
                                  backgroundColor: getBgColor(
                                    row.extendedProps.sessionStatus
                                  ),
                                }}
                              >
                                <img
                                  className={styles.img}
                                  src={getStatusIcon(
                                    row.extendedProps.sessionStatus ||
                                      "sch"
                                  )}
                                  alt={formatSessionStatus(
                                    row.extendedProps.sessionStatus
                                  )}
                                />
                                <div
                                  className={styles.cancelled}
                                  style={{
                                    color: getTextColor(
                                      row.extendedProps.sessionStatus
                                    ),
                                  }}
                                >
                                  {formatSessionStatus(
                                    row.extendedProps.sessionStatus
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className={styles.div10}>
                              <div className={styles.textWrapper6}>4.0</div>
                              <img
                                className={styles.starLine}
                                src="/images/star.svg"
                                alt="Star"
                              />
                            </div>
                            <p className={styles.textWrapper8}>
                              {row.extendedProps.internalNotes?.note}{" "}
                            </p>
                            <div
                              className={styles.textWrapper9}
                              onClick={() =>
                                handleDetailsClick(row.extendedProps, props)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              Details
                            </div>
                          </div>
                          <div className={styles.line2} />
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </thead>
        </table>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 0",
          width: "100%",
          marginTop: "150px",
          position: "relative",
        }}
      >
        <PageIndicatorV2
          recordStartNo={page === 1 ? page : (page - 1) * 10}
          recordEndNo={
            allEvents.length < page * 10 ? allEvents.length : page * 10
          }
          totalRecordsCount={allEvents.length}
        />
        <PaginationV2
          pageSize={10}
          totalDataCount={allEvents.length}
          previousButton={{
            content: (
              <>
                <ChevronLeft />
              </>
            ),
            className:
              "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
          }}
          nextButton={{
            content: (
              <>
                <ChevronRight />
              </>
            ),
            className:
              "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
          }}
          helperButtonClassName={
            "text-secondary-60 font-[400] font-[calc(100vw*16/1440)] "
          }
          selectedHelperButtonClassName={
            "text-blue-70 font-[600] font-[calc(100vw*16/1440)]"
          }
          selectedPage={page}
          setSelectedPage={setPage}
        />
      </div>
    </div>
  );
};

export default CalendarAnalyticsTableView;
