import React from 'react'

const CalendarIcon = ({ type }) => {
    return type !== "active" ? (

        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.6665 10H21.6665M7.6665 3V5M17.6665 3V5M6.8665 21H18.4665C19.5866 21 20.1467 21 20.5745 20.782C20.9508 20.5903 21.2568 20.2843 21.4485 19.908C21.6665 19.4802 21.6665 18.9201 21.6665 17.8V8.2C21.6665 7.07989 21.6665 6.51984 21.4485 6.09202C21.2568 5.71569 20.9508 5.40973 20.5745 5.21799C20.1467 5 19.5866 5 18.4665 5H6.8665C5.7464 5 5.18634 5 4.75852 5.21799C4.38219 5.40973 4.07623 5.71569 3.88449 6.09202C3.6665 6.51984 3.6665 7.07989 3.6665 8.2V17.8C3.6665 18.9201 3.6665 19.4802 3.88449 19.908C4.07623 20.2843 4.38219 20.5903 4.75852 20.782C5.18634 21 5.74639 21 6.8665 21Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
        :
        (
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_29035_3803)">
                    <path d="M3.5 11.6667H24.5M8.16667 3.5V5.83333M19.8333 3.5V5.83333M7.23333 24.5H20.7667C22.0734 24.5 22.7269 24.5 23.226 24.2457C23.665 24.022 24.022 23.665 24.2457 23.226C24.5 22.7269 24.5 22.0734 24.5 20.7667V9.56667C24.5 8.25987 24.5 7.60648 24.2457 7.10736C24.022 6.6683 23.665 6.31135 23.226 6.08765C22.7269 5.83333 22.0734 5.83333 20.7667 5.83333H7.23333C5.92655 5.83333 5.27315 5.83333 4.77402 6.08765C4.33497 6.31135 3.97802 6.6683 3.75432 7.10736C3.5 7.60648 3.5 8.25987 3.5 9.56667V20.7667C3.5 22.0734 3.5 22.7269 3.75432 23.226C3.97802 23.665 4.33497 24.022 4.77402 24.2457C5.27315 24.5 5.92654 24.5 7.23333 24.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_29035_3803">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        )
}

export default CalendarIcon