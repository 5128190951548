import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import frozenEventTypes from "../../utils/eventTypes";
import consultationIcon from "../../assets/calendarV2/ConsultationIcon.svg";
import groupIcon from "../../assets/calendarV2/peopleGroup.svg";
import settingIconGreen from "../../assets/calendarV2/SettingIcon.svg";
import calendarCheckIcon from "../../assets/calendarV2/calendarCheck.svg";

function formatName(name) {

  if(!name) return ""
  const nameParts = name.split(' ');
  
  if (nameParts.length === 1) {
    return nameParts[0]; // Single part name
  }
  
  const firstName = nameParts.slice(0, -1).join(' '); // Join all but the last part for the first name
  const lastName = nameParts[nameParts.length - 1]; // Last part as the last name
  
  return `${firstName} ${lastName.charAt(0)}.`; // Full first name and abbreviated last name
}


function EventHeaderRenderer({ eventType, eventData }) {
	console.log("ED",eventData)
  const { students, tutor } = eventData;

  const renderSessionHeader = () => {
	console.log("TEST_STUD",students)
    const studentName = students.length > 0 ? students[0] : "";
    const formattedStudentName = formatName(studentName);
    const formattedTutorName = formatName(tutor);
    return (
      <div className="flex items-center gap-[6px] text-secondary font-500 text-[14px]">
        {formattedStudentName} / {formattedTutorName}
      </div>
    );
  };

  switch (eventType.toLowerCase()) {
    case frozenEventTypes.OFFCWORK.eventType.toLowerCase():
      return (
        <div className="flex items-center gap-[6px] text-[#9BCA7E]">
          <img
            className="h-[14px] w-[14px] object-contain xl:h-auto xl:w-auto"
            src={settingIconGreen}
            alt="Office Work Icon"
          />
          Office Work
        </div>
      );
    case frozenEventTypes.OFFCHOURS.eventType.toLowerCase():
      return (
        <div className="flex items-center gap-[6px] text-[#9BCA7E]">
          <img
            className="h-[14px] w-[14px] object-contain xl:h-auto xl:w-auto"
            src={settingIconGreen}
            alt="Office Work Icon"
          />
          Office Work
        </div>
      );
    case frozenEventTypes.CONSULTATION.eventType.toLowerCase():
      return (
        <div className="flex items-center gap-[6px] text-[#559B9B]">
          <img
            className="h-[14px] w-[14px] object-contain xl:h-auto xl:w-auto"
            src={consultationIcon}
            alt="Consultation Icon"
          />
          Consultation
        </div>
      );
    case frozenEventTypes.SESSION.eventType.toLowerCase():
      return renderSessionHeader();
    case frozenEventTypes.INTMEETING.eventType.toLowerCase():
      return (
        <div className="flex items-center gap-[6px] text-[#82848B]">
          <img
            className="h-[14px] w-[14px] object-contain xl:h-auto xl:w-auto"
            src={groupIcon}
            alt="Internal Meeting Icon"
          />
          Internal Meeting
        </div>
      );
    case frozenEventTypes.EXTMEETING.eventType.toLowerCase():
      return (
        <div className="flex items-center gap-[6px] text-[#BB7ECA]">
          <img
            className="h-[14px] w-[14px] object-contain xl:h-auto xl:w-auto"
            src={calendarCheckIcon}
            alt="Booked Meeting Icon"
          />
          Booked Meeting
        </div>
      );
    default:
      return null;
  }
}

const EventCardHeader = ({ eventType, eventData }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="font-medium text-[12px] leading-[18px] xl:text-[14px] xl:leading-[21px] text-secondary">
        <EventHeaderRenderer eventType={eventType} eventData={eventData} />
      </div>
      <div>
        <CloseIcon fontSize="small" cursor={"pointer"} />
      </div>
    </div>
  );
};

export default EventCardHeader;
