export default function getRequiredUserIdsBasedOnRole(user){
	let reqUserIds = null
	let {role} = user
	if(role === "student" || role === "tutor") {
		reqUserIds = [user.id]
	}else if (role === "parent"){
		reqUserIds = user.associatedStudents
	}
	return reqUserIds
}
