import React, { useState } from "react";
import SearchIcon from "../../SearchIcon/SearchIcon";
import DropDownList from "./DropDownList";

const AttendeeDropDown = ({ users, selectedUser, setSelectedUser,selectedEventType }) => {
  const [isSearchText, setIsSearchText] = useState("");

  console.log(selectedEventType)

  return (
    <main className=" absolute top-[110%] left-0 w-full  max-h-[320px] cursor-default  z-10 rounded-[6px]  py-[12px] px-[8px]  flex flex-col  gap-[6px] bg-white shadow-[0px_4px_18px_0px_#11167D1A] overflow-y-auto ">
      <section className="  py-[12px] px-[8px] border-[1px] border-secondary-10  rounded-[6px]  w-full flex justify-between items-center gap-[8px] ">
        <SearchIcon />
        <input
          type="text"
          placeholder="Search"
          className=" flex-1  text-[12px] leading-[12px]  text-secondary-60 outline-none "
          onChange={(e) => setIsSearchText(e.target.value)}
        />
      </section>

      {users && users.map(({ _id, email, firstName, lastName, profile, role }) => {

    const matchesEventType = ["internalMeeting", "officeWork"].includes(selectedEventType);
    const matchesRole = ["admin", "tutor", "contributor"].includes(role);
    const matchesSearchText = 
      (firstName && firstName.toLowerCase().includes(isSearchText.toLowerCase())) ||
      (lastName && lastName.toLowerCase().includes(isSearchText.toLowerCase())) ||
      (email && email.toLowerCase().includes(isSearchText.toLowerCase()));

    if (matchesEventType) {
      if (matchesRole && matchesSearchText) {
        return (
          <DropDownList
            key={_id}
            email={email}
            firstName={firstName}
            lastName={lastName}
            id={_id}
            profile={profile}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        );
      }
    } else {
      if (matchesSearchText) {
        return (
          <DropDownList
            key={_id}
            email={email}
            firstName={firstName}
            lastName={lastName}
            id={_id}
            profile={profile}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        );
      }
    }

    return null;
  })}

    </main>
  );
};

export default AttendeeDropDown;
