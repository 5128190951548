import React, { useState } from "react";
import ForgotPassword from "../Frames/ForgotPassword";
import cuate from "../../assets/signup/cuate.svg";

import styles from "./Login.module.css";

export default function ForgotPasswordScreen() {
  const [isPasswordForgot, setIsPasswordForgot] = useState(false);
  const [resetPasswordActive, setResetPasswordActive] = useState(false);
  const [loginActive, setLoginActive] = useState(true);

  const setActiveFrame = (func) => {
    setIsPasswordForgot(false);
    setResetPasswordActive(false);
    setLoginActive(false);
    func(true);
  };



  const props = { setActiveFrame, setResetPasswordActive };

  return (
    <div className={"w-[1920px] flex justify-center items-center"}>
      <div className="flex flex-col items-center justify-center">
      <img
          src={cuate}
          alt="rocket"
          className="h-[121.217px] w-[193.336px] mt-[21px]"
        />
        <div className={`${styles.formBgShadow} mt-[13.78px] mb-6 !w-[980px] !h-[430px] !pt-[30px] flex items-center bg-white rounded-[10px] pb-[40px] px-[66px] shadow-[5px_5px_93.33px_0px_rgba(166,166,166,0.25)]`}>

          <ForgotPassword
            {...props}
          />

        </div>
      </div>
    </div>
  );
}
