export default function LeafIcon(props) {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_32520_73075)">
                <path d="M13.5902 0.907828C13.274 0.591661 12.8382 0.449911 12.3932 0.515828C10.2762 0.835494 6.25124 1.84699 3.07674 5.03083C2.18016 5.92975 1.69832 7.17458 1.75491 8.44508C1.79691 9.38833 2.12066 10.2651 2.67949 10.9948L0.170574 13.5043C-0.0575091 13.7324 -0.0575091 14.1011 0.170574 14.3292C0.284324 14.4429 0.433658 14.5001 0.582991 14.5001C0.732324 14.5001 0.881658 14.4429 0.995408 14.3292L3.50607 11.8185C4.30116 12.4392 5.25782 12.7542 6.22732 12.7542C7.45757 12.7542 8.70007 12.2624 9.62932 11.3028C12.8044 8.02333 13.7302 3.77083 13.981 2.09491C14.0469 1.65449 13.9046 1.22224 13.5907 0.908411L13.5902 0.907828ZM2.92099 8.39316C2.87899 7.44816 3.23716 6.52299 3.90332 5.8545C6.84099 2.90749 10.593 1.96774 12.5682 1.66966C12.5816 1.66733 12.6936 1.64691 12.7665 1.73266C12.7933 1.76416 12.8417 1.82483 12.8277 1.92108C12.7227 2.62108 12.4906 3.80291 12.0052 5.16558H9.71682C8.93807 5.16558 8.20541 5.4695 7.65474 6.02016L3.51716 10.1577C3.15957 9.64208 2.95074 9.03833 2.92157 8.39258L2.92099 8.39316ZM10.6338 8.08341H7.24174L8.47899 6.84616C8.80507 6.52008 9.25599 6.33341 9.71624 6.33341H11.5397C11.286 6.90741 10.9879 7.49658 10.6338 8.08341ZM8.79166 10.4914C7.58007 11.7432 5.67841 11.9287 4.34374 10.9814L6.07507 9.25008H9.84632C9.52782 9.67475 9.17899 10.0912 8.79166 10.4914Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_32520_73075">
                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}