export default function ColorPreviewer(props){
	


	return(
<>
<svg width="560" height="371" viewBox="0 0 560 371" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden md:block">
<rect x="0.5" y="0.5" width="559" height="369.811" rx="11.5" fill="white"/>
<rect width="560" height="67.6322" fill="currentColor"/>
<rect x="17.5" y="20.8145" width="56" height="26" rx="4" fill="white"/>
<rect width="52" height="7" transform="translate(109.5 30.3145)" fill="#E9EBEF"/>
<rect width="52" height="7" transform="translate(181.5 30.3145)" fill="#E9EBEF"/>
<rect width="52" height="7" transform="translate(253.5 30.3145)" fill="#E9EBEF"/>
<ellipse cx="522.667" cy="33.8153" rx="17.5" ry="17.4911" fill="white"/>
<rect width="140" height="7" transform="translate(36.1665 106.113)" fill="#E9EBEF"/>
<rect width="140" height="7" transform="translate(36.1665 126.113)" fill="#E9EBEF"/>
<rect width="91" height="7" transform="translate(36.1665 146.113)" fill="#E9EBEF"/>
<rect x="36.1665" y="173.113" width="91" height="32" rx="4" fill="currentColor"/>
<path d="M68.9105 195.113V185.833H65.7105V183.913H74.2865V185.833H70.9905V195.113H68.9105ZM78.333 195.273C77.4477 195.273 76.6637 195.087 75.981 194.713C75.309 194.34 74.781 193.833 74.397 193.193C74.0237 192.543 73.837 191.801 73.837 190.969C73.837 190.308 73.9437 189.705 74.157 189.161C74.3703 188.617 74.6637 188.148 75.037 187.753C75.421 187.348 75.8743 187.039 76.397 186.825C76.9303 186.601 77.5117 186.489 78.141 186.489C78.6957 186.489 79.213 186.596 79.693 186.809C80.173 187.023 80.589 187.316 80.941 187.689C81.293 188.052 81.5597 188.489 81.741 189.001C81.933 189.503 82.0237 190.052 82.013 190.649L81.997 191.337H75.165L74.797 189.993H80.349L80.093 190.265V189.913C80.061 189.583 79.9543 189.295 79.773 189.049C79.5917 188.793 79.357 188.596 79.069 188.457C78.7917 188.308 78.4823 188.233 78.141 188.233C77.6183 188.233 77.1757 188.335 76.813 188.537C76.461 188.74 76.1943 189.033 76.013 189.417C75.8317 189.791 75.741 190.26 75.741 190.825C75.741 191.369 75.853 191.844 76.077 192.249C76.3117 192.655 76.637 192.969 77.053 193.193C77.4797 193.407 77.9757 193.513 78.541 193.513C78.9357 193.513 79.2983 193.449 79.629 193.321C79.9597 193.193 80.317 192.964 80.701 192.633L81.677 193.993C81.389 194.26 81.0583 194.489 80.685 194.681C80.3223 194.863 79.9383 195.007 79.533 195.113C79.1277 195.22 78.7277 195.273 78.333 195.273ZM89.1651 195.113L86.7651 191.865L86.3171 191.257L82.8451 186.681H85.2771L87.6131 189.849L88.1091 190.521L91.5491 195.113H89.1651ZM82.8291 195.113L86.1731 190.633L87.2931 191.977L85.1491 195.113H82.8291ZM88.1091 191.177L87.0371 189.849L88.9731 186.681H91.2931L88.1091 191.177ZM93.8711 195.113V184.537H95.8391V195.113H93.8711ZM92.2071 188.505V186.681H97.6791V188.505H92.2071Z" fill="white"/>
<rect width="140" height="7" transform="translate(36.1665 244.875)" fill="#E9EBEF"/>
<rect width="91" height="7" transform="translate(36.1665 264.875)" fill="#E9EBEF"/>
<rect x="36.1665" y="291.875" width="91" height="32" rx="4" fill="currentColor"/>
<path d="M68.9105 313.875V304.595H65.7105V302.675H74.2865V304.595H70.9905V313.875H68.9105ZM78.333 314.035C77.4477 314.035 76.6637 313.848 75.981 313.475C75.309 313.102 74.781 312.595 74.397 311.955C74.0237 311.304 73.837 310.563 73.837 309.731C73.837 309.07 73.9437 308.467 74.157 307.923C74.3703 307.379 74.6637 306.91 75.037 306.515C75.421 306.11 75.8743 305.8 76.397 305.587C76.9303 305.363 77.5117 305.251 78.141 305.251C78.6957 305.251 79.213 305.358 79.693 305.571C80.173 305.784 80.589 306.078 80.941 306.451C81.293 306.814 81.5597 307.251 81.741 307.763C81.933 308.264 82.0237 308.814 82.013 309.411L81.997 310.099H75.165L74.797 308.755H80.349L80.093 309.027V308.675C80.061 308.344 79.9543 308.056 79.773 307.811C79.5917 307.555 79.357 307.358 79.069 307.219C78.7917 307.07 78.4823 306.995 78.141 306.995C77.6183 306.995 77.1757 307.096 76.813 307.299C76.461 307.502 76.1943 307.795 76.013 308.179C75.8317 308.552 75.741 309.022 75.741 309.587C75.741 310.131 75.853 310.606 76.077 311.011C76.3117 311.416 76.637 311.731 77.053 311.955C77.4797 312.168 77.9757 312.275 78.541 312.275C78.9357 312.275 79.2983 312.211 79.629 312.083C79.9597 311.955 80.317 311.726 80.701 311.395L81.677 312.755C81.389 313.022 81.0583 313.251 80.685 313.443C80.3223 313.624 79.9383 313.768 79.533 313.875C79.1277 313.982 78.7277 314.035 78.333 314.035ZM89.1651 313.875L86.7651 310.627L86.3171 310.019L82.8451 305.443H85.2771L87.6131 308.611L88.1091 309.283L91.5491 313.875H89.1651ZM82.8291 313.875L86.1731 309.395L87.2931 310.739L85.1491 313.875H82.8291ZM88.1091 309.939L87.0371 308.611L88.9731 305.443H91.2931L88.1091 309.939ZM93.8711 313.875V303.299H95.8391V313.875H93.8711ZM92.2071 307.267V305.443H97.6791V307.267H92.2071Z" fill="white"/>
</svg>

<svg width="356" height="205" viewBox="0 0 358 205" fill="none" xmlns="http://www.w3.org/2000/svg" className="md:hidden">
<rect x="0.5" y="0.5" width="357" height="204" rx="11.5" fill="white"/>

<rect width="140" height="7" transform="translate(20 80)" fill="#E9EBEF"/>
<rect width="140" height="7" transform="translate(20 100)" fill="#E9EBEF"/>
<rect width="91" height="7" transform="translate(20 120)" fill="#E9EBEF"/>
<rect x="20" y="147" width="91" height="32" rx="4" fill="currentColor"/>
<path d="M52.744 169V159.72H49.544V157.8H58.12V159.72H54.824V169H52.744ZM62.1665 169.16C61.2812 169.16 60.4972 168.973 59.8145 168.6C59.1425 168.227 58.6145 167.72 58.2305 167.08C57.8572 166.429 57.6705 165.688 57.6705 164.856C57.6705 164.195 57.7772 163.592 57.9905 163.048C58.2038 162.504 58.4972 162.035 58.8705 161.64C59.2545 161.235 59.7078 160.925 60.2305 160.712C60.7638 160.488 61.3452 160.376 61.9745 160.376C62.5292 160.376 63.0465 160.483 63.5265 160.696C64.0065 160.909 64.4225 161.203 64.7745 161.576C65.1265 161.939 65.3932 162.376 65.5745 162.888C65.7665 163.389 65.8572 163.939 65.8465 164.536L65.8305 165.224H58.9985L58.6305 163.88H64.1825L63.9265 164.152V163.8C63.8945 163.469 63.7878 163.181 63.6065 162.936C63.4252 162.68 63.1905 162.483 62.9025 162.344C62.6252 162.195 62.3158 162.12 61.9745 162.12C61.4518 162.12 61.0092 162.221 60.6465 162.424C60.2945 162.627 60.0278 162.92 59.8465 163.304C59.6652 163.677 59.5745 164.147 59.5745 164.712C59.5745 165.256 59.6865 165.731 59.9105 166.136C60.1452 166.541 60.4705 166.856 60.8865 167.08C61.3132 167.293 61.8092 167.4 62.3745 167.4C62.7692 167.4 63.1318 167.336 63.4625 167.208C63.7932 167.08 64.1505 166.851 64.5345 166.52L65.5105 167.88C65.2225 168.147 64.8918 168.376 64.5185 168.568C64.1558 168.749 63.7718 168.893 63.3665 169C62.9612 169.107 62.5612 169.16 62.1665 169.16ZM72.9986 169L70.5986 165.752L70.1506 165.144L66.6786 160.568H69.1106L71.4466 163.736L71.9426 164.408L75.3826 169H72.9986ZM66.6626 169L70.0066 164.52L71.1266 165.864L68.9826 169H66.6626ZM71.9426 165.064L70.8706 163.736L72.8066 160.568H75.1266L71.9426 165.064ZM77.7046 169V158.424H79.6726V169H77.7046ZM76.0406 162.392V160.568H81.5126V162.392H76.0406Z" fill="white"/>
<rect width="358" height="56" fill="currentColor"/>
<path d="M15 28H33" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 22H33" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 34H33" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="332" cy="28" r="14" fill="white"/>
</svg>

</>
	)

}
