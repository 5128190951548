import styles from './RadioButtonV2.module.css'


const RadioButtonV2 = ({selected, onClick, labelText, disabled, customSelectedMappingType, customUnselectedMappingType}) => {
    return (
        <div className={`flex items-center justify-start gap-2.5 ${selected ? styles.selectedMappingType : styles.unselectedMappingType} ${selected ? customSelectedMappingType : customUnselectedMappingType}`} onClick={onClick} style={{pointerEvents : disabled ? "none" : "" }}>
            <input type="radio" defaultChecked={selected} key={selected ? "yes" : "no"} className='accent-blue-70 cursor-pointer w-[18px] h-[18px] disabled:accent-secondary-60 disabled:bg-white' disabled={disabled}/>
            <label>{labelText}</ label>
        </div>
    )
}


export default RadioButtonV2