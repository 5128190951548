export default function CalendarAnalyticIcon(props){


	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_35331_70298)">
				<g clip-path="url(#clip1_35331_70298)">
					<path d="M14.4004 5.3H11.2004C11.0602 5.3 11.1252 5.25563 11 5.3V1.7C11 1.03831 10.2621 0.5 9.60039 0.5H6.40039C5.7387 0.5 5 1.03831 5 1.7V9.3C4.87481 9.25562 4.94058 9.3 4.80039 9.3H1.60039C0.938703 9.3 0.400391 9.83831 0.400391 10.5V16.1C0.400391 16.3209 0.579484 16.5 0.800391 16.5H15.2004C15.4213 16.5 15.6004 16.3209 15.6004 16.1V6.5C15.6004 5.83831 15.0621 5.3 14.4004 5.3ZM1.5 11C1.5 10.7794 1.77944 10.5 2 10.5H4.5C4.72056 10.5 5 10.7794 5 11V15.5H1.5V11ZM6 2C6 1.77944 6.17983 1.5 6.40039 1.5H9.60039C9.82095 1.5 10 1.77944 10 2V15.5H6V2ZM14.5 15.5H11V7C11 6.77944 11.2794 6.5 11.5 6.5H14C14.2206 6.5 14.5 6.77944 14.5 7V15.5Z" fill="currentColor"/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_35331_70298">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
				<clipPath id="clip1_35331_70298">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>

	)

}
