import Close from '@mui/icons-material/Close'
import React from 'react'
import CrossIcon from '../../../assets/calendar/crossIcon.svg';

const AttendeeDeselect = ({firstName,lastName,selectedUser, setSelectedUser, id, type, userRole}) => {

    const addRemoveUser = () => {
        const userExists = selectedUser.some((member) => member === id);
        setSelectedUser((prev) =>
          userExists ? prev.filter((member) => member !== id) : [...prev, id]
        );
      };
    

  return (
    <main className='  bg-[#E8F3FF] px-[8px]  py-[4px] rounded-[4px] flex  gap-[6px] items-center justify-between  w-fit cursor-default '  onClick={(e)=>{type != "viewOnly" &&  e.stopPropagation()}}>
        
        <p className=' w-fit text-[12px] leading-[18px]  text-[#003266] '>{firstName} { lastName && lastName.charAt(0).toUpperCase()}.</p>
        <img src={CrossIcon} alt=""  className= {` ${type && type ==  "viewOnly" || userRole === "student" ?"hidden" :"flex" } cursor-pointer`}  onClick={addRemoveUser} />
        
 </main>
  )
}

export default AttendeeDeselect