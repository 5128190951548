import React, { forwardRef, useEffect, useState } from "react";
import styles from "./CalendarFilterPopup.module.css";
import "./CalendarFilterPopup.styleguide.css";
import "../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetAllOrgUsersQuery,
  useLazyGetAllStudentGroupsForOrgQuery,
  useLazyGetAllUsersQuery,
  useLazyGetParentsForTutorQuery,
  useLazyGetParentTutorsQuery,
} from "../../app/services/users";
import { handleFilters } from "../../utils/filterService";
import { setFilteredData } from "../../app/slices/filterSlice";

const filterOptions = [
  { id: "service", label: "Service", selected: false },
  { id: "students", label: "Students", selected: true }, // Default selected
  { id: "tutors", label: "Tutors", selected: false },
  { id: "parents", label: "Parents", selected: false },
  { id: "groups", label: "Groups", selected: false },
  { id: "sessionStatuses", label: "Session Statuses", selected: false },
  { id: "sessionNotes", label: "Session Notes", selected: false },
  { id: "hourlyRate", label: "Hourly Rate", selected: false },
  { id: "location", label: "Location", selected: false },
];

const filterOptionsMapping = {
  service: "Service",
  students: "Students", // Default selected
  tutors: "Tutors",
  parents: "Parents",
  groups: "Groups",
  sessionStatuses: "Session Statuses",
  sessionNotes: "Session Notes",
  hourlyRate: "Hourly Rate",
  location: "Location",
};

const CalendarFilterPopup = forwardRef(
  (
    {
      showFilters,
      setShowFilters,
      externalContainerStyles,
      appliedFilters,
      setAppliedFilters,
      filters,
      setFilters,
      selectedFilter,
      setSelectedFilter,
      selectedItems,
      setSelectedItems,
      settings,
      setSettings,
    },
    ref
  ) => {
    const { organization } = useSelector((state) => state.organization);
    const user = useSelector((state) => state.user);
	const { role } = useSelector((state)=>state.user)

    const dispatch = useDispatch();
    const [fetchUsers, fetchUsersResp] = useLazyGetAllUsersQuery();
    const [getAllUsers, setAllUsers] = useLazyGetAllOrgUsersQuery();
    const [getAllStudentGroupsForOrg, getAllStudentGroupsForOrgResp] =
      useLazyGetAllStudentGroupsForOrgQuery();
    const [searchTerm, setSearchTerm] = useState("");
    const [specializations, setSpecializations] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleFilterClick = (id) => {
      setFilters(
        filters.map((filter) =>
          filter.id === id
            ? { ...filter, selected: true }
            : { ...filter, selected: false }
        )
      );
      setSelectedFilter(id);
      setSelectAll(false);
      setSelectedItems((prev) => ({
        ...prev,
        [id]: prev[id] || {},
      }));
      setSearchTerm("");
    };
    const handleSelectAll = () => {
      const items = getListItems();
      const allSelected = items.every(
        (item) => appliedFilters[selectedFilter][item._id]
      );
      setAppliedFilters((prev) => ({
        ...prev,
        [selectedFilter]: items.reduce((acc, item) => {
          acc[item._id] = !allSelected ? item.value : null;
          return acc;
        }, {}),
      }));
      setSelectAll(!selectAll);
      setSelectedItems((prev) => ({
        ...prev,
        [selectedFilter]: items.reduce((acc, item) => {
          acc[item._id] = !selectAll;
          return acc;
        }, {}),
      }));
    };
    // console.log("seleceted items", selectedItems);
    // console.log("seleceted filters", selectedFilter);

    const handleItemClick = (id) => {
      console.log("id", id);
      const items = getListItems();
      // Find the item based on whether id is a string or an object ID
      const item = items.find((item) => {
        if (typeof item === "string") {
          return item === id;
        }
        return item._id === id;
      });
      console.log("item", item);

      setAppliedFilters((prev) => {
        const newState = { ...prev[selectedFilter] };
        if (newState[id]) {
          delete newState[id];
        } else {
          // Handle case for different types of items
          newState[id] = item && typeof item === "object" ? item.value : id;
        }
        return {
          ...prev,
          [selectedFilter]: newState,
        };
      });

      setSelectedItems((prev) => ({
        ...prev,
        [selectedFilter]: {
          ...prev[selectedFilter],
          [id]: !prev[selectedFilter][id],
        },
      }));
    };

    // console.log("applied filters", appliedFilters);

    const getListItems = () => {
      return settings[selectedFilter] || [];
    };
    const handleClearAll = () => {
      setAppliedFilters({
        service: {},
        students: {},
        tutors: {},
        parents: {},
        groups: {},
        sessionStatuses: {},
        sessionNotes: {},
        hourlyRate: {},
        location: {},
      });
      setSelectedItems({
        service: {},
        students: {},
        tutors: {},
        parents: {},
        groups: {},
        sessionStatuses: {},
        sessionNotes: {},
        hourlyRate: {},
        location: {},
      });
      setSelectAll(false);
    };
    const handleClearCategory = (filterType) => {
      setAppliedFilters((prev) => ({
        ...prev,
        [filterType]: {},
      }));
      setSelectedItems((prev) => ({
        ...prev,
        [filterType]: {},
      }));
    };
    const handleClearItem = (filterType, id) => {
      setAppliedFilters((prev) => {
        const newState = { ...prev };
        delete newState[filterType][id];
        return newState;
      });
      setSelectedItems((prev) => {
        const newState = { ...prev };
        delete newState[filterType][id];
        return newState;
      });
    };

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };
    const getFilteredItems = () => {
      const items = getListItems();
      const { service, students, tutors, parents, groups, ...rest } = settings;

      // console.log("items", items);
      if (!searchTerm) {
        return items;
      }
      // if (service) {
      //   return items.map((value) => value);
      // }
      // if (groups) {
      //   return items.filter((item) =>
      //     item.name.toLowerCase().includes(searchTerm.toLowerCase())
      //   );
      // } else
      const filteredItems = items.filter((item) => {
        if (typeof item === "string") {
          return item.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return item.value.toLowerCase().includes(searchTerm.toLowerCase());
      });
      return filteredItems;
    };
    const hasFilters = Object.values(appliedFilters).some(
      (filter) => Object.keys(filter).length > 0
    );
    console.log("hasFilters", hasFilters);
    const formatFilters = (filters) => {
      return {
        students: Object.keys(filters.students),
        tutors: Object.keys(filters.tutors),
        parents: Object.keys(filters.parents),
        groups: Object.keys(filters.groups),
        sessionStatuses: Object.keys(filters.sessionStatuses),
        sessionNotes: Object.keys(filters.sessionNotes),
        hourlyRate: Object.keys(filters.hourlyRate),
        location: Object.keys(filters.location),
        service: Object.keys(filters.service),
      };
    };
    const submitFilters = async () => {
      const formattedFilters = formatFilters(appliedFilters);
	  	
		let requiredUserIds = []
		if(user && ["tutor","parent","student"].includes(user.role)){
			if(user.role === "parent"){
				requiredUserIds = [...user.associatedStudents]		
			}
			requiredUserIds.push(user.id)
		}

      const data = await handleFilters(formattedFilters, requiredUserIds);
      if (data.success == true) {
        console.log("filtered data", data);
        dispatch(setFilteredData(data.data.allEvents));
        setShowFilters(false);
      }
    };

    const fetchStudentGroups = () => {
      getAllStudentGroupsForOrg({ orgId: organization._id }).then((res) => {
        let _studentGroups = res?.data?.data?.groups;
        setSettings((prevSettings) => ({
          ...prevSettings,
          groups: _studentGroups,
        }));
      });
    };
	

	const [getParentTutors,] = useLazyGetParentTutorsQuery()
    const fetchTutors = () => {
		let urlParams = `?role=tutor&role=admin`;
	    if(user?.role === "tutor"){
			urlParams+= `&_id=${user?.id}`
		}else if(user?.role === "student"){
			urlParams += `&assiginedStudents=${user?.id}`
		}else if(user?.role === "parent"){
			getParentTutors({id : user?.id})
				.then((res)=>{
					if(res.data){
						const tutors = res.data.tutors
						let _tutors = []
						tutors.forEach(tutor=>{
							const tutorIds = _tutors.map(val=>val._id)
							if(!tutorIds.includes(tutor._id)){
								_tutors.push(tutor)
							}
						})
						setSettings((prevSettings) => ({
								...prevSettings,
								tutors: _tutors.map(val=>({
									_id : val._id,
									value : val.firstName + " " + val.lastName,
									email : val.email 
								})),
						 }));
					}
				})
			return
		}

      fetchUsers(urlParams).then((res) => {
        console.log("res", res);
        if (!res?.data?.data?.user) return;
        let data = res.data.data.user.map((item) => {
          const { firstName, lastName } = item;
          return {
            _id: item._id,
            value: `${firstName} ${lastName}`,
            email: item.email ? item.email : "-",
          };
        });
        // setAllTutors(data);
        setSettings((prevSettings) => ({
          ...prevSettings,
          tutors: data,
        }));
      });
    };

	const [getParentsForTutor,] = useLazyGetParentsForTutorQuery()
    const fetchParents = () => {
      let urlParams = `?role=parent`;
	  
	  if(role === "student"){
			urlParams += `&assiginedStudents=${user?.id}`
	  }else if(role === "parent"){
			urlParams += `&_id=${user?.id}`
			console.log(urlParams)
	  } else if(role === "tutor"){
			getParentsForTutor(user?.id)
				.then((res)=>{
					if(res.data){
						setSettings((prevSettings) => ({
							...prevSettings,
							parents: res.data.parents.map(val=>({
								_id : val._id,
								value : `${val.firstName} ${val.lastName}`,
								email : val.email ? val.email : "-"
							})),
						}));
					}
				})
			return ;
		}
	

      fetchUsers(urlParams).then((res) => {
        console.log("res", res);
        if (!res?.data?.data?.user) return;
        let data = res.data.data.user.map((item) => {
          const { firstName, lastName } = item;
          return {
            _id: item._id,
            value: `${firstName} ${lastName}`,
            email: item.email ? item.email : "-",
          };
        });
        // setAllTutors(data);
        setSettings((prevSettings) => ({
          ...prevSettings,
          parents: data,
        }));
      });
    };
    const fetchStudents = () => {
	
	  let urlParams = ""
	  if(user?.role === "student"){
	    urlParams = `?role=student&_id=${user.id}`;
	  }else if(user?.role === "parent"){
		urlParams = `?role=student&associatedParent=${user.id}`
	  }else if(user?.role === "tutor"){
		urlParams = `?role=student&assiginedTutors=${user.id}`
	  }else if(user?.role === "admin"){
		urlParams = `?role=student`
	  }

      fetchUsers(urlParams).then((res) => {
        console.log("res", res);
        if (!res?.data?.data?.user) return;
        let data = res.data.data.user.map((item) => {
          const { firstName, lastName } = item;
          return {
            _id: item._id,
            value: `${firstName} ${lastName}`,
            email: item.email ? item.email : "-",
          };
        });
        // setAllTutors(data);
        setSettings((prevSettings) => ({
          ...prevSettings,
          students: data,
        }));
      });
    };

    useEffect(() => {
      if (!organization.settings.servicesAndSpecialization) return;
      const services = organization.settings.servicesAndSpecialization.map(
        (service) => service.service
      );
      console.log("services in org", services);
      setSettings((prevSettings) => ({
        ...prevSettings,
        service: services,
      }));
      fetchTutors();
      fetchStudents();
      fetchParents();
      fetchStudentGroups();
    }, [organization]);
    console.log("organization in calendar", organization);
    console.log("settings", settings);
    return (
      <div
        className={styles.filtersPopup}
        ref={ref}
        style={externalContainerStyles}
      >
        <div className={styles.overlap}>
          <div className={styles.filterDimensions}>
            <div className={styles.textWrapper}>Filters</div>
            <img
              className={styles.line}
              src="/images/line.svg"
              alt="Line separator"
            />

            <div className={styles.frame}>
              {filters.map((filter, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      filter.selected ? styles.frame2 : styles.divWrapper
                    } ${styles.filterItem}`}
                    onClick={() => handleFilterClick(filter.id)}
                  >
                    <p
                      className={
                        filter.selected ? styles.textWrapper2 : styles.div
                      }
                    >
                      {filter.label}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <img
            className={styles.img}
            src="/images/vertical_line.svg"
            alt="Vertical line"
          />
          <img
            className={styles.line2}
            src="/images/vertical_line2.svg"
            alt="Vertical line"
          />
          <div className={styles.overlapGroup}>
            <div className={styles.filterDropdown}>
              <div className={styles.search}>
                <img
                  className={styles.shapeSearch}
                  src="/images/search.svg"
                  alt="Search icon"
                />
                <input
                  className={styles.searchStudents}
                  placeholder={`Search ${filterOptionsMapping[
                    selectedFilter
                  ].toLowerCase()}`}
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className={styles.frame3}>
                <div className={styles.frame4}>
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <div className={styles.frame5}>
                    <div className={styles.textWrapper3}>Select All</div>
                  </div>
                </div>
                <img
                  className={styles.line}
                  src="/images/line.svg"
                  alt="Line separator"
                />
                <div className={styles.scrollableContainer}>
                  <div className={styles.frame3}>
                    {getFilteredItems().map((item, index) => {
                      const isString = typeof item === "string";
                      const isObject = item && typeof item === "object";
                      const hasName = isObject && item.name;
                      const hasValue = isObject && item.value;
                      return (
                        <div
                          className={styles.frame4}
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() =>
                            handleItemClick(
                              isString ? item : item._id || item.id
                            )
                          }
                        >
                          <input
                            className={styles.checkbox}
                            type="checkbox"
                            // checked={!!selectedItems[selectedFilter][item.id]}
                            checked={
                              isString
                                ? !!selectedItems[selectedFilter][item]
                                : !!selectedItems[selectedFilter][
                                    item._id || item.id
                                  ]
                            }
                            // onChange={() => handleItemClick(item.id)}
                            // onChange={() =>
                            //   handleItemClick(
                            //     isString ? item : item._id || item.id
                            //   )
                            // }
                          />{" "}
                          {/* <div className={styles.frame5}>
                          <div className={styles.textWrapper4}>{item.name}</div>
                          <div className={styles.textWrapper5}>
                            {item.email}
                          </div>
                        </div> */}
                          <div className={styles.frame5}>
                            <div className={styles.textWrapper4}>
                              {isString
                                ? item
                                : hasName
                                ? item.name
                                : hasValue
                                ? item.value
                                : ""}
                            </div>
                            <div className={styles.textWrapper5}>
                              {isString ? "" : item.email}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frame6}>
              <div className={styles.frame7}>
                <input className={styles.checkbox} type="checkbox" />
                <p className={styles.p}>Save these as my default filters</p>
              </div>
              <div className={styles.frame8}>
                <button
                  className={styles.button}
                  onClick={() => setShowFilters(false)}
                >
                  <div className={styles.verifyEmail}>Cancel</div>
                </button>
                <button
                  className={styles.verifyEmailWrapper}
                  onClick={submitFilters}
                >
                  <div className={styles.verifyEmail2}>Apply</div>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.frame9}>
            <div className={styles.frame10}>
              <div className={styles.textWrapper7}>
                {
                  Object.keys(selectedItems).filter((filterType) =>
                    Object.values(selectedItems[filterType]).some(
                      (selected) => selected
                    )
                  ).length
                }{" "}
                filters selected
              </div>
              <div className={styles.textWrapper8} onClick={handleClearAll}>
                Clear all
              </div>
            </div>
            <img
              className={styles.line3}
              src="/images/line.svg"
              alt="Line separator"
            />
            <div className={styles.frame11}>
              {Object.entries(appliedFilters).map(
                ([filterType, selectedItems]) => {
                  console.log(
                    "filterType, selectedItems",
                    filterType,
                    selectedItems
                  );
                  return Object.keys(selectedItems).length > 0 ? (
                    <div className={styles.frame12} key={filterType}>
                      <div className={styles.frame13}>
                        <div className={styles.textWrapper9}>
                          {
                            filterOptions.find(
                              (filter) => filter.id === filterType
                            )?.label
                          }
                        </div>
                        <div
                          className={styles.textWrapper10}
                          onClick={() => handleClearCategory(filterType)}
                        >
                          Clear
                        </div>
                      </div>
                      <div className={styles.frame14}>
                        {Object.entries(selectedItems).map(([id, name]) => (
                          <div className={styles.frame13} key={id}>
                            <div className={styles.textWrapper11}>{name}</div>
                            <img
                              className={styles.close}
                              src="/images/close_circle.svg"
                              alt="Close icon"
                              onClick={() => handleClearItem(filterType, id)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null;
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default CalendarFilterPopup;
