import React from "react";
import SearchEventBar from "../SearchEventBar/SearchEventBar";

const SearchEventMapper = ({ eventData, isViewAll, setIsViewAll }) => {
  return (
    <main className="flex w-full flex-col gap-y-[12px] max-h-[560px] overflow-y-scroll ">
      {eventData &&
        eventData
          .slice(0, isViewAll ? eventData?.length : 4 )
          .map((event) => <SearchEventBar key={event._id} eventData={event} />)}
    </main>
  );
};

export default SearchEventMapper;
