const EditIcon = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_29112_8589)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4207 2.1051C13.0456 1.73016 12.537 1.51953 12.0067 1.51953C11.4764 1.51953 10.9677 1.73016 10.5927 2.1051L10.1214 2.5771L13.4214 5.8771L13.892 5.40577C14.0778 5.22005 14.2251 4.99956 14.3256 4.75688C14.4262 4.51421 14.4779 4.25411 14.4779 3.99144C14.4779 3.72877 14.4262 3.46867 14.3256 3.22599C14.2251 2.98332 14.0778 2.76283 13.892 2.5771L13.4207 2.1051ZM12.478 6.81977L9.17802 3.51977L3.11802 9.58044C2.98541 9.71307 2.89277 9.88033 2.85068 10.0631L2.16468 13.0331C2.13912 13.1434 2.14206 13.2583 2.1732 13.3671C2.20435 13.4759 2.26269 13.575 2.34272 13.6551C2.42276 13.7351 2.52185 13.7934 2.63066 13.8246C2.73947 13.8557 2.85442 13.8587 2.96468 13.8331L5.93535 13.1478C6.11789 13.1056 6.2849 13.0129 6.41735 12.8804L12.478 6.81977Z" fill="#0671E0" />
            </g>
            <defs>
                <clipPath id="clip0_29112_8589">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default EditIcon