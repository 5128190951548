const abbrMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function getFormattedDateV2(dateString, format) {
  const date = new Date(dateString)
  switch (format) {
    case "dd mm, yyyy":
      return `${date.getDate()} ${abbrMonths[date.getMonth()]}, ${date.getFullYear()}`
    case "mm dd, yyyy":
      return `${abbrMonths[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    case "mm/dd/yyyy":
      return `${abbrMonths[date.getMonth()]}/${date.getDate()}/${date.getFullYear()}`
    case "mm-dd-yyyy":
      return `${abbrMonths[date.getMonth()]}-${date.getDate()}-${date.getFullYear()}`
    case "dd-mm-yyyy":
      return `${date.getDate()}-${abbrMonths[date.getMonth()]}-${date.getFullYear()}`
    case "m-dd-yyyy":
      return `${parseInt(date.getMonth()) + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}-${parseInt(date.getDate()) < 10 ? "0" + date.getDate() : date.getDate()}-${date.getFullYear()}`
    case "dd-m-yyyy":
      return `${parseInt(date.getDate()) < 10 ? "0" + date.getDate() : date.getDate()}-${parseInt(date.getMonth()) + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1}-${date.getFullYear()}`
    case "mm dd":
      return `${abbrMonths[date.getMonth()]} ${date.getDate()}`
  }
}

function addDaysToDate(dateString, noOfDays) {
  const date = new Date(dateString);
  const targetDate = date.setDate(date.getDate() + noOfDays);
  return targetDate;
}

function getTargetTimeZoneTimeString12Hr(targetTimeZone, timeStampString) {
  try {
    if (!timeStampString || !targetTimeZone) return;
    const intl = new Intl.DateTimeFormat("en-US", {
      timeZone: targetTimeZone,
      timeStyle: "short",
    });
    return intl.format(new Date(timeStampString));
  } catch (err) {
    console.error(err);
  }
}

function get12HrTimeFromDate(date, timeZone) {
  const tempArr = new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
    hour12: true,
    ...(timeZone && { timezone: timeZone }),
  }).formatToParts(date);
  const tempHours =
    parseInt(tempArr[0].value) < 10 ? "0" + tempArr[0].value : tempArr[0].value;
  const requiredString = [
    [tempHours, tempArr[2].value].join(":"),
    tempArr.slice(-1)[0].value,
  ].join(" ");
  return requiredString;
}

function convertToMinutes(time) {
  const [timeStr, modifier] = time.split(' ');
  let [hours, minutes] = timeStr.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  }
  if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  return hours * 60 + minutes;
}

function calculateTimeDifference(startTime, endTime) {
  const startMinutes = convertToMinutes(startTime);
  const endMinutes = convertToMinutes(endTime);

  return endMinutes - startMinutes;
}

function roundToNearestHalfHour(date){
	
	const currentMinute = date.getMinutes()
	if( currentMinute <= 30 ){
		date.setMinutes(0)
	} else {
		date.setMinutes(0)
		date.setMinutes(60)
	}
	
	return date
}

export {
  getFormattedDateV2,
  addDaysToDate,
  getTargetTimeZoneTimeString12Hr,
  get12HrTimeFromDate,
  calculateTimeDifference,
	roundToNearestHalfHour
};
