import axios from "axios"
import { triggerToast } from "./toastController"
import { getAuthHeader } from "../app/constants/constants"

async function createNewAnnouncement(announcementDetails, orgId) {
    console.log(announcementDetails, orgId)
    try {
        const res = await axios({
            method: "POST",
            url: process.env.REACT_APP_BASE_URL + `api/user/org/createAnnouncement/${orgId}`,
            data: announcementDetails,
            headers: getAuthHeader()

        })
        console.log(res)
    } catch (err) {
        console.log(err)
        triggerToast(err.message)
    }
}

export { createNewAnnouncement }