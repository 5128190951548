import React from 'react';

const ConnectButtons = ({disabled, isConnected}) => {
  return (
    <button 
      disabled={disabled} 
      className={`px-[12px] py-[6px] border rounded-[4px] ${isConnected ? "border-[#7C859C] text-[#7C859C]": "border-[#0671E0] text-[#0671E0]" }  text-[14px] leading-[17.5px] font-normal ${!disabled && !isConnected  && 'hover:text-white hover:bg-[#0671E0]'} disabled:border-[#0671E033] disabled:text-[#0671E033]`}
    >
      {isConnected ? "Disconnect" : "Connect"}
    </button>
  );
}

export default ConnectButtons;
