const MoreInfoIcon = (props) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M7.9987 15.1673C11.6806 15.1673 14.6654 12.1825 14.6654 8.50065C14.6654 4.81875 11.6806 1.83398 7.9987 1.83398C4.3168 1.83398 1.33203 4.81875 1.33203 8.50065C1.33203 12.1825 4.3168 15.1673 7.9987 15.1673Z" stroke={props.currentColor ? "currentColor" : "#ADB2C0" } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M8 11.1667V8.5" stroke={props.currentColor ? "currentColor" : "#ADB2C0" } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M8 5.83398H8.00667" stroke={props.currentColor ? "currentColor" : "#ADB2C0" } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>

	)
}

export default MoreInfoIcon
