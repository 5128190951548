export default function ClockIcon(props) {

	return(
		<svg width={props.width? props.width :"16"} height={props.height? props.width :"16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_33049_41960)">
				<path d="M8.00065 14.6693C11.6825 14.6693 14.6673 11.6845 14.6673 8.0026C14.6673 4.32071 11.6825 1.33594 8.00065 1.33594C4.31875 1.33594 1.33398 4.32071 1.33398 8.0026C1.33398 11.6845 4.31875 14.6693 8.00065 14.6693Z" stroke={props.inherit ? props.inherit  : "#7C859C"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 4V8L10.6667 9.33333" stroke={props.inherit ? props.inherit : "#7C859C" } stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</g>
			<defs>
				<clipPath id="clip0_33049_41960">
					<rect width="16" height="16" fill="white"/>
				</clipPath>
			</defs>
		</svg>

	)

}
