const _ = require('lodash')


const fetchLimitForId = (productPrices,id,selectedBillingCycle,productName) => {
    let requiredProduct = productPrices.find((val)=>val.product.name.includes(productName)).prices[selectedBillingCycle]
    let requiredPrice = requiredProduct.find((val)=>val.id===id)

    if(!requiredPrice) {
        requiredProduct =  productPrices.find((val)=>val.product.name.includes(productName)).prices[selectedBillingCycle === "monthly" ? "annual" : "monthly"]
        requiredPrice = requiredProduct.find((val)=>val.id===id)
    }

    return requiredPrice.metadata.limit
}

const fetchPriceForId = (productPrices,id,selectedBillingCycle,productName) => {

    let requiredProduct = productPrices.find((val)=>val.product.name.includes(productName)).prices[selectedBillingCycle]
    let requiredPrice = requiredProduct.find((val)=>val.id===id)

    if(!requiredPrice) {
        requiredProduct =  productPrices.find((val)=>val.product.name.includes(productName)).prices[selectedBillingCycle === "monthly" ? "annual" : "monthly"]
        requiredPrice = requiredProduct.find((val)=>val.id===id)
    }

    return requiredPrice.unit_amount/100
}


const fetchLookupKeyForId = (productPrices,id,selectedBillingCycle,productName) => {

  let requiredProduct = productPrices.find((val)=>val.product.name.includes(productName)).prices[selectedBillingCycle]
  let requiredPrice = requiredProduct.find((val)=>val.id===id)

  if(!requiredPrice) {
      requiredProduct =  productPrices.find((val)=>val.product.name.includes(productName)).prices[selectedBillingCycle === "monthly" ? "annual" : "monthly"]
      requiredPrice = requiredProduct.find((val)=>val.id===id)
  }

  return requiredPrice.lookup_key
}

const fetchOneOffPriceForId = (productPrices,id,productName) => {
    let requiredProduct = productPrices.find((val)=>val.product.name.includes(productName)).prices.oneOff
    let requiredPrice = requiredProduct.find((val)=>val.id === id)

    return requiredPrice.unit_amount/100
}


const fetchTutorPriceForPackage = (productPrices,selectedPlanType,basePackageName) => {
    const tutorPrices = productPrices.find((product)=>product.product.name.includes("Tutor")).prices[selectedPlanType]
    const requiredTutorPrice = tutorPrices.find((price)=>price.lookup_key.includes(basePackageName))
    return requiredTutorPrice
}


export {
    fetchLimitForId,
    fetchPriceForId,
    fetchLookupKeyForId,
    fetchOneOffPriceForId,
    fetchTutorPriceForPackage
}