import React, { useMemo } from "react";
import Yes from "../../../assets/calendar/tick.svg"
import No from "../../../assets/calendar/cross.svg"

const colors = [
  "#0BA5DC",
  "#E8AB11",
  "#573030",
  "#183943",
  "#E81160",
  "#4937C7",
  "#094513",
  "#717577",
  "#82B915",
  "#099870",
];

const UserInfo = ({ user, id, action }) => {
  
    const assignedColor = useMemo(() => {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  }, [id, colors]);


  return (
    <main
      id={id}
      className="w-full gap-[6px] justify-between flex items-center p-[4px] rounded-[4px] hover:bg-[#F7F8F9] cursor-default"
    >
      <div className= {`flex  items-center justify-between flex-1 gap-[6px] ${action == "maybe" && "opacity-50" } `}>
        {user && user.profile ? (
          <img
            src={user && user.profile}
            alt="profile"
            className="w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full"
          />
        ) : (
          <div
            className="text-[12px] leading-[12px] text-white w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full flex items-center justify-center"
            style={{ backgroundColor: assignedColor }}
          >
            {user && user.firstName.charAt(0).toUpperCase()}
          </div>
        )}

        <section className="flex flex-col gap-[3px] flex-1  h-fit">
          <h4 className="text-[10px] md:text-[12px] leading-[10px] md:leading-[14px] font-medium text-secondary-80">
            {user && user.firstName} {user && user.lastName}
          </h4>
          <p className="text-[8px] md:text-[10px] leading-[8px] md:leading-[12px] font-normal flex-nowrap whitespace-nowrap text-secondary-60 break-all overflow-hidden w-full max-w-full">
            {user.email}
          </p>
        </section>
      </div>

      {action && action != "pending" &&  <div className=" py-[10px] px-[8px]  w-[44px] h-[16px] flex items-center justify-center text-[12px] leading-[12px] text-secondary-60 "> {action == "maybe" &&  "Maybe"} {action == "yes"  && <img src={Yes}  className="w-[16px]  h-[16px] whitespace-nowrap " /> } {action =="no" && <img src={No} />} </div>
      }

    </main>
  );
};

export default UserInfo;
