export default function HorizontalThickLine(props) {
  return (
    <svg
      width="760"
      height="1"
      {...props}
      viewBox="0 0 760 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="0.5" x2="760" y2="0.5" stroke={props.color} />
    </svg>
  );
}
