import React, { useState } from "react";
import InputField from "../../components/InputField/inputField";
import EmailIcon from "../../assets/images/email.png";
import Logo from "../../assets/images/evallo-logo.png";
import Passwordicon from "../../assets/form/password.svg";
import { useForgotPasswordMutation } from "../../app/services/auth";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useEffect } from "react";

export default function ForgotPassword() {
  const emailValidate = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [email, setEmail] = useState("");
  const [forgotPassword] = useForgotPasswordMutation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    forgotPassword({ email: email }).then((res) => {
      setLoading(false);
      if (res.error) {
        console.log(res.error);
        triggerToast(res.error.data.message, "error", { autoClose: 3000 });
        return;
      }
      console.log(res.data);
      triggerToast(<div>Password reset link sent to {email}</div>, "info", {
        autoClose: 3000,
      });
    });
  };

  return (
    <div className={` ${loading && "cursor-wait"} self-stretch w-full`}>
      <div className="flex justify-center items-center mb-[31.87px]">
        <img src={Logo} className="w-[131.25px]" />
      </div>

      <form onSubmit={handleSubmit} className="text-[#26435F] font-medium">
        <p className="text-primary font-normal text-[18.667px] text-center mb-5">
          Reset Password
        </p>

        <div className="h-[1px] bg-[#EBEBEB] mb-[30.33px] w-[670px] mx-auto"></div>

        <p className="text-26435F font-normal leading-[26.444px] text-[18.667px] text-center mb-[30px] max-w-[573px] mx-auto">
          Please enter the email address used for your Evallo account. We will
          send a verification link to reset the password.
        </p>
        <div className="max-w-[500px] mx-auto">
          <InputField
            Icon={EmailIcon}
            placeholder="Enter your email address here"
            parentClassName="mb-[30px]"
            biggerText={true}
            labelClassname="hidden"
            label="Email Address"
            iconClassName="mr-2.5"
            iconSize="medium"
            removeResponsive={true}
            inputClassName="bg-transparent placeholder:font-light !text-lg"
            inputContainerClassName="hover:border-[#FFA28D] !pl-[14px] border-[0.936px] !text-lg border-[#D0D5DD]  h-[49px]  rounded-[6px] w-full"
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className="relative flex justify-center">
            <input
              disabled={loading === true ? true : !emailValidate.test(email)}
              className={`w-full h-[50px] max-w-[337.5px] relative bg-primary disabled:bg-primary  rounded-[5px] text-white text-[17.5px] ${
                loading ? "opacity-60 pointer-events-none" : ""
              }`}
              // onClick={() => setActiveFrame(setResetPasswordActive)}
              // onClick={() => handleSubmit()}
              type="submit"
              value="Submit for Verification Link"
            />
            {loading && <Loader />}
          </div>

          {/* <p
               className="text-secondary text-xs relative font-semibold ml-2 mt-2 cursor-pointer lg:inline-block hidden"
               onClick={() => setLoginActive(true)}
            >
               Send Link
               {
                  true &&
                  <Loader />
               }
            </p> */}
        </div>
      </form>
    </div>
  );
}
