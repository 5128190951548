import styles from './DividerPill.module.css'


const DividerPill = (props) => {
    return(
        <div className={styles.dividerPill}>
            &nbsp;
        </div>
    )
}

export default DividerPill