import { Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import frozenEventTypes from "../../utils/eventTypes";
import CalendarAnalyticsTableView from "../CalendarAnalyticsTableView/CalendarAnalyticsTableView";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import MoreInfoIcon from "../MoreInfoIcon/MoreInfoIcon";
import StubbyLeftArrow from "../StubbyLeftArrow/StubbyLeftArrow";
import styles from "./CalendarAnalytics.module.css";

import RevenueCost from "./RevenueCost/RevenueCost";
import SectionOne from "./SectionOne/SectionOne";

function CalendarAnalytics(props) {
  const [selectedEventKey, setSelectedEventKey] = useState("SESSION");
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [selectedTutorIds, setSelectedTutorIds] = useState([]);
  const [selectedStatusKeys, setSelectedStatusKeys] = useState([]);

  //For filters
  const filterRef = useRef(null);
  const filterRefParent = useRef(null);
  const [showFilters, setShowFilters] = useState(false);

  const user = useSelector(state=>state.user);
  const { studentList, tutorList, groupList, handleBackClick } = props;

  const sectionProps = {
    selectedEventKey,
    setSelectedEventKey,
    selectedStudentIds,
    setSelectedStudentIds,
    selectedTutorIds,
    setSelectedTutorIds,
    selectedStatusKeys,
    setSelectedStatusKeys,
    showFilters,
    setShowFilters,
    filterRef,
    filterRefParent,
  };
  const sections = [SectionOne, RevenueCost, CalendarAnalyticsTableView];

  useOutsideAlerter(
    filterRef,
    () => {
      setShowFilters(false);
    },
    filterRefParent
  );

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.header}>
          <button
            className={styles.desktopBackButton}
            onClick={(e) => handleBackClick(e)}
          >
            <StubbyLeftArrow />
          </button>
          <div className={styles.subHeader}>
            <h2>Calendar Analytics</h2>
            <Tooltip>
              {" "}
              <MoreInfoIcon />
            </Tooltip>
          </div>
        </div>
      </div>

      {sections.map((Section, idx) => (
        <>
		  {
			idx === 1 && (user?.role === "student" || user?.role === "parent" || user?.role === "tutor") ? 
			<></>
			:
			<>
			<Section {...sectionProps} {...{ message: idx }} {...props} />
			{idx < sections.length - 1 && <HorizontalDivider />}
			</>
		  } 
        </>
      ))}
    </div>
  );
}

export default CalendarAnalytics;
