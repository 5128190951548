import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlertOnPaymentFailedDowngrade } from "../../utils/alertService";
import { getFormattedDateV2 } from "../../utils/dateUtils";
import CautionIcon from "../CautionIcon/CautionIcon";
import { useLazySubscriptionInsightsQuery } from "../../app/services/subscription";
import { triggerUpdateSubscriptionPopUp } from "../../utils/subscriptionPopUpService";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../CrossIcon/CrossIcon";
import { updateArbitraryField } from "../../app/slices/user";


function CalAlert (props) {
	
	const dispatch = useDispatch()
	const {showCalAlert} = useSelector((state)=>state.user)
	
	function handleButtonClick() {
		dispatch(updateArbitraryField({showCalAlert : false}))	
	}

	useEffect(()=>{console.log("SCA", showCalAlert)},[showCalAlert])
	
	return(<></>)

	return(
		<div className="flex relative bg-general py-[7px] justify-center w-full items-center text-secondary-80 text-[calc(100vw*(14/1920))] design:text-[14px]" style={{ fontSize: props.fromStage ? "" : "14px", display : showCalAlert ? "" : "none" }} >
			Legacy calendar will be replaced by new calendar features. To learn more,&nbsp;<a href="https://docs.google.com/document/d/1D6D_1q1hx2SCWT6QunYfxB7s5SzysgIgvFiR7srhwac/edit?usp=sharing" target="_blank">click here</a>.	
			<button  className="absolute top-[50%] right-[20px] -translate-y-[50%]" onClick={(e)=>{handleButtonClick()}}><CrossIcon height={"14px"} width={"14px"}/></button>
		</div>
	)
}

export default function AlertContainer(props) {
  
  const navigate = useNavigate()
  const [alerts, setAlerts] = useState([<CalAlert {...props}/>]);
  const { subscriptionV2 } = useSelector((state) => state.subscription);
  const { isLoggedIn, role } = useSelector((state) => state.user);
  const { organization } = useSelector((state) => state.organization);
  const [getSubscriptionInsights,] = useLazySubscriptionInsightsQuery()
	


  useEffect(() => {
    if (subscriptionV2 && isLoggedIn && role === "admin" && organization) {

    }
  }, [subscriptionV2, isLoggedIn, role, organization])




  useEffect(() => {
    if (
      isLoggedIn &&
      subscriptionV2 &&
      organization.settings &&
      role === "admin"
    ) {
      (async () => {
            let tempAlerts = [];
        try {
          if (
            !subscriptionV2?.subscription?.basePackage?.name.includes("Free")
          ) {
            const daysBetweenSubscriptionStartDateAndEndDate = Math.floor(
              Math.abs(
                new Date(subscriptionV2?.subscription.startDate).getTime() -
                new Date(subscriptionV2?.subscription.endDate).getTime()
              ) /
              (24 * 60 * 60 * 1000)
            );
            console.log(
              "TIME",
              Math.floor(
                Math.abs(
                  new Date(subscriptionV2?.subscription.startDate).getTime() -
                  new Date(subscriptionV2?.subscription.endDate).getTime()
                ) /
                (24 * 60 * 60 * 1000)
              )
            );
            console.log(daysBetweenSubscriptionStartDateAndEndDate);

            const freeTrialAlert =
              daysBetweenSubscriptionStartDateAndEndDate === 14 ? (
                <div className="flex bg-general py-[7px] justify-center w-full items-center text-secondary-80 text-[calc(100vw*(14/1920))] design:text-[14px]" style={{ fontSize: props.fromStage ? "" : "14px" }}>
                  <>
                    <CautionIcon className="text-error-40 pr-[6px]" />
                  </>
                  Your free trial for{" "}
                  {subscriptionV2?.subscription.basePackage.name.split("_")[1]}{" "}
                  plan ends in{" "}
                  {(
                    Math.abs(
                      new Date(subscriptionV2?.subscription.endDate).getTime() -
                      new Date().getTime()
                    ) /
                    (24 * 60 * 60 * 1000)
                  ).toFixed(0)}{" "}
                  days. Your billing cycle starts on{" "}
                  {getFormattedDateV2(
                    subscriptionV2?.subscription.endDate,
                    "dd mm, yyyy"
                  )}
                  .
                </div>
              ) : (
                <></>
              );
            const res = await getSubscriptionInsights()
            if (!res.error) {
              console.log(typeof (subscriptionV2?.subscription.endDate))
              const cancellationDetails = res.data.data.cancellationDetails
              if (cancellationDetails && cancellationDetails.cancelAtPeriodEnd) {
                tempAlerts.push(
                  <div className="flex bg-general py-[7px] justify-center w-full items-center text-secondary-80 text-[calc(100vw*(14/1920))] design:text-[14px]" style={{ fontSize: props.fromStage ? "" : "14px" }}>
                    <>
                      <CautionIcon className="text-error-40 pr-[6px]" />
                    </>
                    Your {subscriptionV2?.subscription.basePackage.name.split("_")[1] === "Free" ? "Explorer" : subscriptionV2?.subscription.basePackage.name.split("_")[1]} plan will deactivate on {getFormattedDateV2((new Date(subscriptionV2?.subscription?.endDate).toISOString()), "dd mm, yyyy")} due to cancellation.&nbsp;<button className="text-blue-70" onClick={(e) => { navigate('/settings/manage-subscription') }}>Renew you subscription</button>&nbsp;today to avoid losing access to advanced features.
                  </div>
                )
              }
            }
            tempAlerts.push(freeTrialAlert);	
            setAlerts(prev=>[prev[0],freeTrialAlert]);
          }
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [isLoggedIn, subscriptionV2, organization, role]);
	


  return (
    <div className="flex flex-col items-center justify-start gap-[0px] w-full">
      {alerts?.map((alert, idx) => (
        <>{alert}</>
      ))}
    </div>
  );
}
