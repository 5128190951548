import React from 'react'

const PictureIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_31545_113718)">
                <path d="M20 3.45508C20.5304 3.45508 21.0391 3.66579 21.4142 4.04086C21.7893 4.41594 22 4.92465 22 5.45508V19.4551C22 19.9855 21.7893 20.4942 21.4142 20.8693C21.0391 21.2444 20.5304 21.4551 20 21.4551H4C3.46957 21.4551 2.96086 21.2444 2.58579 20.8693C2.21071 20.4942 2 19.9855 2 19.4551V5.45508C2 4.92465 2.21071 4.41594 2.58579 4.04086C2.96086 3.66579 3.46957 3.45508 4 3.45508H20ZM20 5.45508H4V15.5551L8.995 10.5611C9.11108 10.445 9.24889 10.3529 9.40057 10.29C9.55225 10.2272 9.71482 10.1948 9.879 10.1948C10.0432 10.1948 10.2058 10.2272 10.3574 10.29C10.5091 10.3529 10.6469 10.445 10.763 10.5611L14.828 14.6271L16.066 13.3891C16.1821 13.273 16.3199 13.1809 16.4716 13.118C16.6232 13.0552 16.7858 13.0228 16.95 13.0228C17.1142 13.0228 17.2768 13.0552 17.4284 13.118C17.5801 13.1809 17.7179 13.273 17.834 13.3891L20 15.5561V5.45508ZM15.5 7.45508C15.8978 7.45508 16.2794 7.61311 16.5607 7.89442C16.842 8.17572 17 8.55725 17 8.95508C17 9.3529 16.842 9.73443 16.5607 10.0157C16.2794 10.297 15.8978 10.4551 15.5 10.4551C15.1022 10.4551 14.7206 10.297 14.4393 10.0157C14.158 9.73443 14 9.3529 14 8.95508C14 8.55725 14.158 8.17572 14.4393 7.89442C14.7206 7.61311 15.1022 7.45508 15.5 7.45508Z" fill="#515C7B" />
            </g>
            <defs>
                <clipPath id="clip0_31545_113718">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.455078)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PictureIcon