import React, { useState, useEffect } from "react";
import CRM from "./assets/CRM.svg";
import Assignment from "./assets/Assignments.svg";
import Calendar from "./assets/Calendar.svg";
import CustomTest from "./assets/CustomContent.svg";
import ProductSummaryCard from "./ProductSummaryCard";
import Pencil from "./assets//pencil.svg";
import DotLineDot from "./assets//DotLineDot.svg";
import DotLineDotTutor from "./assets//DotLineDotTutor.svg";
import { toast } from "react-toastify";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";
import { triggerToast } from "../../../utils/toastController";

const ReviewProducts = (props) => {
  const [editProducts, setEditProducts] = useState(false);
  const [total, setTotal] = useState(null);

  const Products = [
    {
      name: "CRM",
      title: "Unlimited students accounts",
      img: CRM,
      priceList: null,
    },
    {
      name: "Calendar",
      title:
        props.selectedPlanType === 0
          ? "sessions per month"
          : " sessions per month",
      img: Calendar,
      priceList: null,
    },
    {
      name: "Assignments",
      title:
        props.selectedPlanType === 0
          ? "credits per month"
          : "credits per month",
      img: Assignment,
      priceList: null,
    },
    {
      name: "Custom Tests",
      title: "custom tests",
      img: CustomTest,
    },
  ];

  function getDateInFuture() {
    // Get today's date
    let currentDate = new Date();

    // Add 14 days to the current date
    currentDate.setDate(currentDate.getDate() + 14);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let month = monthNames[currentDate.getMonth()];

    // Get day
    let day = currentDate.getDate();

    // Get year
    let year = currentDate.getFullYear();

    // Format the date
    let formattedDate = `${month} ${day}, ${year}`;

    // Return the formatted date
    return formattedDate;
  }

  const [augmentedProducts, setAugmentedProducts] = useState(null);

  useEffect(() => {
    if (props.productPrices) {
      const tempProductPrices = [...props.productPrices];

      const requiredProductsMapping = {
        Calendar: { productName: "CalendarV2" },
        "Custom Tests": { productName: "CustomContentV2" },
        Assignments: { productName: "AssignmentsV2" },
      };

      Products.forEach((product, _idx) => {
        if (_idx === 0) return;
        const requiredProduct = tempProductPrices.find(
          (_product, __idx) =>
            _product.product.name ===
            requiredProductsMapping[product.name].productName
        );
        const tempPriceList = {};
        for (const planType in requiredProduct.prices) {
          tempPriceList[planType] = [];
          const prices = requiredProduct.prices[planType];
          const tempPrices = [];
          prices.forEach((price) => {
            const tempPrice = {
              ...price,
              name: `${
                price.metadata.limit === "-1"
                  ? "Unlimited"
                  : price.metadata.limit
              } nos.- \$${Math.round(price.unit_amount / 100)}`,
              value: price.id,
            };
            tempPrices.push(tempPrice);
          });
          tempPriceList[planType] = tempPrices;
        }
        product.priceList = tempPriceList[
          props.selectedPlanType === 0
            ? "monthly"
            : props.selectedPlanType === 1
            ? "annual"
            : ""
        ].filter((price, __idx) => !price.lookup_key.includes("PAYG"));
      });

      const tempProducts = [...Products];
      setAugmentedProducts([...tempProducts]);
    }
  }, [props.productPrices, props.selectedPlanType]);

  useEffect(() => {
    if (props.subscriptionCoupon?.amountOff) {
      props.setDiscountAmount(
        props.AddOnsPrice +
          props.basePackagePrice -
          props.subscriptionCoupon.amountOff
      );
    } else if (props.subscriptionCoupon?.percentOff) {
      props.setDiscountAmount(
        (props.AddOnsPrice + props.basePackagePrice) *
          (1 - props.subscriptionCoupon.percentOff / 100)
      );
    } else {
      props.setDiscountAmount(0);
    }
  }, [props.subscriptionCoupon, props.AddOnsPrice, props.basePackagePrice]);

  useEffect(() => {
    if (props.discountAmount) {
      if (props.selectedProducts.selectedProducts.tutors.selected) {
        if (
          props.discountAmount <
          props.AddOnsPrice +
            props.basePackagePrice +
            props.selectedProducts.selectedProducts.tutors.price *
              props.selectedProducts.selectedProducts.tutors.quantity
        ) {
          setTotal(
            props.AddOnsPrice +
              props.basePackagePrice +
              props.selectedProducts.selectedProducts.tutors.price *
                props.selectedProducts.selectedProducts.tutors.quantity -
              props.discountAmount
          );
        } else {
          toast.error("Cannot apply this coupon");
        }
      }
      if (props.discountAmount < props.AddOnsPrice + props.basePackagePrice) {
        setTotal(
          props.AddOnsPrice + props.basePackagePrice - props.discountAmount
        );
      } else {
        toast.error("Cannot apply this coupon");
      }
    }
    if (!props.discountAmount) {
      setTotal(null);
    }
  }, [props.discountAmount]);

  return (
    <div className=" w-[420px]  grid gap-[24px]">
      <div className="text-[24px] text-blue-90 font-semibold text-left">
        Order Summary
      </div>
      <div
        className={`flex w-full gap-4 text-[#515C7B] ${
          props.selectedProducts.selectedProducts.tutors.selected
            ? "h-[150px]"
            : "h-[110px]"
        }`}
      >
        <div className="flex justify-center items-center h-[fit]">
          <img
            src={
              props.selectedProducts.selectedProducts.tutors.selected
                ? DotLineDotTutor
                : DotLineDot
            }
            className={` ${
              props.selectedProducts.selectedProducts.tutors.selected
                ? "h-[130px]"
                : "h-[86px] "
            } w-[12px] `}
          />
        </div>
        <div className="w-full  flex flex-col justify-center  ">
          <div className="  flex flex-col h-full justify-around ">
            <div className=" grid gap-0.5 ">
              <div className="flex justify-between   ">
                <div className="flex gap-2  ">
                  <div className="flex justify-center items-center  border border-[#7C859C]  rounded-full">
                    <img
                      src={props.basePackageIcon}
                      className="h-[15px] w-[18px]  rounded-full"
                    />{" "}
                  </div>
                  <div className="text-[16px] font-medium text-[#26435F]">
                    {props.selectedProducts.selectedProducts.basePackage.name}
                  </div>{" "}
                </div>
                <div className="text-[16px] font-medium">
                  $
                  {props.selectedProducts.selectedProducts.tutors.selected
                    ? props.selectedProducts.selectedProducts.tutors.price *
                        props.selectedProducts.selectedProducts.tutors
                          .quantity +
                      props.basePackagePrice
                    : props.basePackagePrice}
                </div>
              </div>
              {props.selectedProducts.selectedProducts.tutors.selected ? (
                <>
                  <div className="text-[14px] flex justify-between ">
                    <div>
                      Base Price(includes {props.basePackageTutorCount} Tutor
                      Licenses)
                    </div>{" "}
                    <div> ${props.basePackagePrice}</div>
                  </div>
                  <div className="text-[14px] flex justify-between text-[#7C859C]">
                    <div>
                      ${props.selectedProducts.selectedProducts.tutors.price}{" "}
                      per additional Tutor Licenses x{" "}
                      {props.selectedProducts.selectedProducts.tutors.quantity}
                    </div>{" "}
                    <div>
                      $
                      {props.selectedProducts.selectedProducts.tutors.price *
                        props.selectedProducts.selectedProducts.tutors.quantity}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="text-left text-[14px] font-normal">
                Trial starts today
              </div>
            </div>
            <div className="text-left ">
              <div className="text-[16px] font-medium text-[#7C859C]">
                {getDateInFuture()}
              </div>
              <div className="text-left text-[14px] font-normal">
                Billing starts
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b-2  "></div>

      <div className="text-[18px] text-[#3B476B] font-semibold text-left  flex gap-[6px] cursor-pointer">
        <span>Products Included</span>
        <span className="cursor-pointer">
          {editProducts ? (
            <div
              className="text-[#0671E0] text-[16px] font-medium  border-b-2 border-[#0671E0] cursor-pointer"
              onClick={() => {
                setEditProducts(false);
                triggerToast("Changes saved.", "success", { autoClose: 1500 });
              }}
            >
              Save
            </div>
          ) : (
            <img
              src={Pencil}
              className="w-[16px] h-[16px] mt-0.5 cursor-pointer "
              onClick={() => {
                setEditProducts(true);
              }}
            />
          )}
        </span>
      </div>
      <div className="grid gap-2 min-h-[100px]">
        {augmentedProducts?.map((product, index) =>
          props.selectedProducts.selectedProducts[product.name] ? (
            <ProductSummaryCard
              key={index}
              name={product.name}
              img={product.img}
              priceList={product.priceList}
              title={product.title}
              selectedProducts={props.selectedProducts}
              setSelectedProducts={props.setSelectedProducts}
              edit={editProducts}
              item={index}
              noOfProducts={Products.length - 1}
              selectedPlanType={props.selectedPlanType}
            />
          ) : null
        )}
      </div>

      <div className="border-b-2 "></div>
      <div className="w-full gap-2  flex flex-col">
        <div className="flex justify-between text-[16px] font-normal">
          <div>
            {props.selectedProducts.selectedProducts.basePackage.name} Plan
          </div>
          <div>
            {props.selectedProducts.selectedProducts.tutors.selected
              ? props.selectedPlanType === 0
                ? `$${(
                    props.selectedProducts.selectedProducts.tutors.price *
                      props.selectedProducts.selectedProducts.tutors.quantity +
                    props.basePackagePrice
                  ).toFixed(0)}`
                : `$${(
                    (props.selectedProducts.selectedProducts.tutors.price *
                      props.selectedProducts.selectedProducts.tutors.quantity +
                      props.basePackagePrice) /
                    12
                  ).toFixed(0)}`
              : props.selectedPlanType === 0
              ? `$${props.basePackagePrice.toFixed(0)}`
              : `$${(props.basePackagePrice / 12).toFixed(0)}`}
            <span className="text-secondary-60">
              {props.selectedPlanType === 0 ? "/mo" : "/mo x 12"}
            </span>
          </div>
        </div>
        <div className="flex justify-between text-[16px] font-normal">
          <div>Products Included</div>
          <div>
            {props.selectedPlanType === 0
              ? `$${props.AddOnsPrice.toFixed(0)}`
              : `$${(props.AddOnsPrice / 12).toFixed(0)}`}
            <span className="text-secondary-60">
              {props.selectedPlanType === 0 ? "/mo" : "/mo x 12"}
            </span>
          </div>
        </div>{" "}
        {props.subscriptionCoupon?.couponId && props.discountAmount && (
          <div className="flex justify-between text-[16px] font-normal">
            <div>Item Total </div>
            <div>
              {props.selectedProducts.selectedProducts.tutors.selected
                ? props.selectedPlanType === 0
                  ? (
                      props.selectedProducts.selectedProducts.tutors.price *
                        props.selectedProducts.selectedProducts.tutors
                          .quantity +
                      props.basePackagePrice +
                      props.AddOnsPrice
                    ).toFixed(0)
                  : (
                      (props.selectedProducts.selectedProducts.tutors.price *
                        props.selectedProducts.selectedProducts.tutors
                          .quantity +
                        props.basePackagePrice +
                        props.AddOnsPrice) /
                      12
                    ).toFixed(0)
                : props.selectedPlanType === 0
                ? `$${(props.basePackagePrice + props.AddOnsPrice).toFixed(0)}`
                : `$${(
                    props.basePackagePrice / 12 +
                    props.AddOnsPrice / 12
                  ).toFixed(0)}`}
              <span className="text-secondary-60">
                {props.selectedPlanType === 0 ? "/mo" : "/mo x 12"}
              </span>
            </div>
          </div>
        )}
        {props.subscriptionCoupon?.couponId && props.discountAmount && (
          <div className="flex justify-between text-[16px] font-normal text-[#099A66]">
            <div>Discount ({props.subscriptionCoupon.name})</div>
            <div>
              -
              {props.discountAmount && props.selectedPlanType === 0
                ? props.discountAmount
                : (props.discountAmount / 12).toFixed(0)}
              {props.selectedPlanType === 0 ? "/mo" : "/mo x 12"}
            </div>
          </div>
        )}
      </div>
      <div className="border-b"></div>
      <div className="flex justify-between text-[18px] font-semibold">
        <div>Final Amount</div>
        <div>
          $
          {total
            ? total
            : props.selectedProducts.selectedProducts.tutors.selected
            ? props.selectedProducts.selectedProducts.tutors.price *
                props.selectedProducts.selectedProducts.tutors.quantity +
              props.basePackagePrice +
              props.AddOnsPrice
            : props.AddOnsPrice + props.basePackagePrice}
          <span className="text-secondary-60">
            {props.selectedPlanType === 0 ? "/mo" : "/year"}
          </span>
        </div>
      </div>
      <button
        className="bg-[#0671E0] font-semibold p-3 rounded text-[#FFFFFF]"
        onClick={(e) => {
          if (!editProducts) {
            props.setSubmit(!props.submit);
          } else {
            triggerToast("Save your changes before proceeding", "error", {
              autoClose: 3000,
            });
          }
        }}
      >
        {!props.isLoading ? (
          "Start your 14-day free trial"
        ) : (
          <div className="flex justify-center items-center w-full">
            <LoaderIcon />
          </div>
        )}
      </button>
      <div className="text-[16px] -mt-3">
        You can change or cancel your plan at any time.
      </div>
    </div>
  );
};

export default ReviewProducts;
