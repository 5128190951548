import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "./MobileCalendarDateTime.module.css";
import "../../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { generateTimeOptions } from "../CalendarDateTimeSelector/CalendarDateTimeSelector";
import { timeZones } from "../CalendarDateTimeSelector/CalendarDateTimeSelector";
import { timeOptions } from "../CalendarDateTimeSelector/CalendarDateTimeSelector";
import RecurringSession from "../../CalendarRecurringComponent/RecurringSession/RecurringSession";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const MobileCalendarDateTime = ({
  eventType,
  selectedDay,
  setSelectedDay,
  frequency,
  setFrequency,
  selectedDays,
  setSelectedDays,
  selectedDate,
  setSelectedDate,
  endDate,
  setEndDate,
  selectedStartTime,
  setSelectedStartTime,
  selectedEndTime,
  setSelectedEndTime,
  isRecurring,
  setIsRecurring,
  isRescheduled,
  setIsRescheduled,
  isPartialSession,
  setIsPartialSession,
  selectedTimeZone,
  setSelectedTimeZone,
  user
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
  const [isEndsOnDatePickerOpen, setIsEndsOnDatePickerOpen] = useState(false);
  const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);
  const [isTimeZonePickerOpen, setIsTimeZonePickerOpen] = useState(false);
  const [isRepeatsOpen, setIsRepeatsOpen] = useState(false);

  const timeZoneRef = useRef(null);
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);

  const startTimeRef = useRef();
  const lastTimeRef = useRef();
  const calendarRef = useRef();

  useOutsideAlerter(startTimeRef, () => setIsStartTimePickerOpen(false));
  useOutsideAlerter(lastTimeRef, () => setIsEndTimePickerOpen(false));
  useOutsideAlerter(calendarRef, () => setIsDatePickerOpen(false));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsDatePickerOpen(false);
  };
  const handleOptionClick = (event, time, type) => {
    event.stopPropagation();
    handleTimeChange(time, type);
  };
  const handleTimeChange = (time, type) => {
    if (type === "start") {
      setSelectedStartTime(time);
      setIsStartTimePickerOpen(false);
    } else if (type === "end") {
      setSelectedEndTime(time);
      setIsEndTimePickerOpen(false);
    }
  };
  const filteredTimeZones = timeZones.filter((zone) =>
    zone.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleCheckboxChange = (event, checkboxType) => {
    if (checkboxType === "recurring") {
      setIsRecurring(event.target.checked);
      setIsRescheduled(false);
      setIsPartialSession(false);
    } else if (checkboxType === "rescheduled") {
      setIsRescheduled(event.target.checked);
      setIsPartialSession(false);
      setIsRecurring(false);
    } else if (checkboxType === "partialSession") {
      setIsPartialSession(event.target.checked);
      setIsRecurring(false);
      setIsRescheduled(false);
    }
  };
  const handleClickOutsideTimeZone = (event) => {
    if (timeZoneRef.current && !timeZoneRef.current.contains(event.target)) {
      setIsTimeZonePickerOpen(false);
    }
  };

  const handleTimeZoneChange = (timeZone) => {
    setSelectedTimeZone(timeZone);
    setIsTimeZonePickerOpen(false);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndsOnDatePickerOpen(false);
  };
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsStartTimePickerOpen(false);
    }
  };
  const handleDayClick = (day) => {
    setSelectedDay(day);
  };
  const handleClickOutside2 = (event) => {
    if (modalRef2.current && !modalRef2.current.contains(event.target)) {
      setIsEndTimePickerOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideTimeZone);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideTimeZone);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);
  //To Enable or disable editing based on the role
  const isEnabledEditing = useMemo(() => {

    return ["admin", "tutor"].includes(user?.role)

  }, [user?.role])
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.mobileCalendarDateTime} style={{ pointerEvents: isEnabledEditing ? "" : "none" }}>
        <div className={styles.div}>
          <div className={styles.frame2}>
            <div
              ref={calendarRef}
              className={styles.date}
              onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
            >
              <div className={styles.textWrapper}>
                {" "}
                {selectedDate.format("MMM D, YYYY")}
              </div>
              <img
                className={styles.inputFieldIcon}
                src="/images/calendar.svg"
                alt="Icon"
              />

              {isDatePickerOpen && (
                <div className={styles.datePickerContainer}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={handleDateChange}
                    onClose={() => setIsDatePickerOpen(false)}
                    //   {...props}
                    showDaysOutsideCurrentMonth={true}
                    disableHighlightToday={true}
                    views={["month", "day"]}
                    sx={{
                      bgcolor: "white",
                      height: "199px",
                      width: "190px",
                      borderRadius: 6,
                      fontFamily: "Lexend",
                      padding: "0px",
                      "& .MuiDayCalendar-weekDayLabel": {
                        margin: "0px",
                        fontFamily: "Lexend",
                        height: "15px",
                        padding: "0px",
                        color: "#7C859C",
                        fontWeight: 400,
                        fontSize: "10px",
                      },
                      "& .MuiDayCalendar-header": {
                        paddingBottom: "10px",
                      },
                      "& .MuiMonthCalendar-root": {
                        width: "190px",
                        height: "151px",
                        padding: "0px",
                      },
                    }}
                    //   slots={{
                    //     calendarHeader: CustomHeader,
                    //   }}
                    slotProps={{
                      monthButton: {
                        sx: {
                          fontWeight: 400,
                          fontFamily: "Lexend",
                          fontSize: "14px",
                          color: "rgba(81, 92, 123, 1)",
                          margin: "0px",
                          height: "32px",
                          width: "58px",
                          "&.Mui-selected": {
                            fontWeight: 600,
                            bgcolor: "white",
                            color: "#0671E0",
                            textDecoration: "underline",
                            textUnderlineOffset: "5px",
                            lineHeight: "21px",
                            "&:hover": {
                              fontWeight: 600,
                              bgcolor: "white",
                              color: "#0671E0",
                              textDecoration: "underline",
                              lineHeight: "21px",
                            },
                            "&:focus": {
                              fontWeight: 600,
                              bgcolor: "white",
                              color: "#0671E0",
                              textDecoration: "underline",
                              lineHeight: "21px",
                            },
                          },
                        },
                      },
                      calendarHeader: {
                        //   ...props,
                        sx: {
                          padding: "0px 0px 0px 6px",
                          margin: "0px",
                          minHeight: "fit-content",
                          "& .MuiPickersCalendarHeader-label": {
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#25335A",
                            margin: "0px",
                            fontFamily: "Lexend",
                          },
                          "& .MuiSvgIcon-root": {
                            height: "1rem",
                            width: "1rem",
                            color: "#515C7B",
                          },
                          "& .MuiPickersCalendarHeader-switchViewButton": {
                            padding: "0px",
                            color: "#25335A",
                          },
                          "& .MuiPickersCalendarHeader-switchViewIcon": {
                            color: "#25335A",
                          },
                        },
                      },
                      day: {
                        disableHighlightToday: false,
                        today: true,
                        sx: {
                          height: "22px",
                          width: "22px",
                          padding: "6px 2px",
                          fontSize: "10px",
                          fontFamily: "Lexend",
                          fontWeight: 400,
                          "&:hover": {
                            bgcolor: "rgba(233, 235, 239, 1)",
                          },
                          "&:focus": {
                            bgcolor: "rgba(6, 113, 224, 0.1)",
                            color: "rgba(6, 113, 224, 1)",
                          },
                          "&.MuiPickersDay-today": {
                            bgcolor: "#0671E0",
                            border: "none",
                            color: "white",
                          },
                          "&.Mui-selected": {
                            bgcolor: "rgba(6, 113, 224, 0.1)",
                            color: "rgba(6, 113, 224, 1)",
                            "&:focus": {
                              bgcolor: "rgba(6, 113, 224, 0.1)",
                              color: "rgba(6, 113, 224, 1)",
                            },
                            "&:hover": {
                              bgcolor: "rgba(6, 113, 224, 0.1)",
                              color: "rgba(6, 113, 224, 1)",
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>

            <div className={styles.frame3}>
              <div
                ref={startTimeRef}
                className={styles.frame4}
                onClick={() => setIsStartTimePickerOpen(!isStartTimePickerOpen)}
              >
                <div className={styles.textWrapper2}>{selectedStartTime}</div>
                {
                  selectedStartTime ?
                    <img
                      className={styles.img}
                      src="/images/down-arrow-dark.svg"
                      alt="Icon"
                    />
                    :
                    <img
                      className={styles.img}
                      src="/images/down-arrow-dark.svg"
                      alt="Icon"
                    />
                }

                {isStartTimePickerOpen && (
                  <div
                    className={`${styles.searchDropdown} ${styles.startDropdown}`}
                  >
                    {timeOptions.map((time) => (
                      <div
                        key={time}
                        className={styles.dropdownOption}
                        onClick={(event) =>
                          handleOptionClick(event, time, "start")
                        }
                      >
                        <div className={styles.elementSessionsMo}>{time}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.time}>
                <div className={styles.textWrapper3}>-</div>
              </div>
              <div
                ref={lastTimeRef}
                className={styles.frame5}
                onClick={() => setIsEndTimePickerOpen(!isEndTimePickerOpen)}
              >
                <div className={styles.textWrapper4}>{selectedEndTime}</div>
                {
                  selectedEndTime ?
                    <img
                      className={styles.img}
                      src="/images/down-arrow-dark.svg"
                      alt="Icon"
                    />
                    :
                    <img
                      className={styles.img}
                      src="/images/down-arrow-dark.svg"
                      alt="Icon"
                    />
                }

                {isEndTimePickerOpen && (
                  <div
                    className={`${styles.searchDropdown} ${styles.endDropdown}`}
                  >
                    {timeOptions.map((time) => (
                      <div
                        key={time}
                        className={styles.dropdownOption}
                        onClick={(event) =>
                          handleOptionClick(event, time, "end")
                        }
                      >
                        <div className={styles.elementSessionsMo}>{time}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.timeZone}>
            <p
              className={styles.GMTEastern}
              onClick={() => setIsTimeZonePickerOpen(!isTimeZonePickerOpen)}
            >
              {selectedTimeZone}{" "}
            </p>
            {
              selectedTimeZone ?
                <img
                  className={styles.img}
                  src="/images/down-arrow-dark.svg"
                  alt="Icon"
                  onClick={() => setIsTimeZonePickerOpen(!isTimeZonePickerOpen)}
                />
                :
                <img
                  className={styles.img}
                  src="/images/down-arrow-dark.svg"
                  alt="Icon"
                  onClick={() => setIsTimeZonePickerOpen(!isTimeZonePickerOpen)}
                />
            }
          </div>
          {isTimeZonePickerOpen && (
            <div
              ref={timeZoneRef}
              className={
                isRecurring
                  ? styles.timeZoneDropdownV2
                  : styles.timeZoneDropdown
              }
            >
              <div className="search-box-container pl-2 pr-2">
                <img
                  style={{ marginLeft: 5 }}
                  src="/images/search.svg"
                  alt="search"
                />
                <input
                  type="text"
                  className="search-box p-3"
                  placeholder="Search by place"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              {filteredTimeZones.map((timeZone) => (
                <div
                  key={timeZone}
                  className={styles.timeZoneOption}
                  onClick={() => handleTimeZoneChange(timeZone)}
                >
                  {timeZone}
                </div>
              ))}
            </div>
          )}
          <div
            className={`${styles.frame6} ${eventType === "officeWork" ? "!hidden" : ""
              }`}
          >
            <div className={styles.recurring}>
              <div className={styles.checkboxFill}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  checked={isRecurring}
                  onChange={(event) => handleCheckboxChange(event, "recurring")}
                />
                <div className={styles.textWrapper5}>Recurring</div>
              </div>
              {isRecurring && (
                <RecurringSession
                  isRepeatsOpen={isRepeatsOpen}
                  setIsRepeatsOpen={setIsRepeatsOpen}
                  frequency={frequency}
                  setFrequency={setFrequency}
                  isEndsOnDatePickerOpen={isEndsOnDatePickerOpen}
                  setIsEndsOnDatePickerOpen={setIsEndsOnDatePickerOpen}
                  endDate={endDate}
                  setSelectedDate={setSelectedDate}
                  handleEndDateChange={handleEndDateChange}
                  selectedDay={selectedDay}
                  handleDayClick={handleDayClick}
                />
              )}
            </div>
            <div className={styles.frame7}>
              <div className={styles.checkboxFill2}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  checked={isRescheduled}
                  onChange={(event) =>
                    handleCheckboxChange(event, "rescheduled")
                  }
                />
                <div className={styles.textWrapper5}>Rescheduled</div>
              </div>
              <div className={styles.checkboxFill2}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  checked={isPartialSession}
                  onChange={(event) =>
                    handleCheckboxChange(event, "partialSession")
                  }
                />
                <div className={styles.textWrapper5}>Partial Session</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default MobileCalendarDateTime;
