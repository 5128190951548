import React from "react";
import { useDispatch } from "react-redux";
import { toggleSubscriptionPopUpTrigger } from "../../../app/slices/subscriptionOperation";

const UnsavedChanges = ({setConfirmCloseTrigger}) => {

  const dispatch = useDispatch()

  return (
    <div className="h-[752px] w-full flex items-center justify-center">
      <div className="w-[720px] text-secondary  flex flex-col justify-center items-center gap-[36px] px-[24px]">
        <div className="text-[20px] font-[500]">You have unsaved changes!</div>
        <div className="text-[16px] font-[400] text-secondary-80 text-center">
          Note: If you close this without saving the changes, all values will be
          reset to their previous states.
        </div>
        <div className="flex gap-[12px]">
          <div>
            <button className="bg-blue-70 px-[24px] py-[10px] rounded-[6px] text-white min-w-[174px] h-[48px]" onClick={(e)=>setConfirmCloseTrigger(false)}>
              Go Back
            </button>
          </div>
          <div>
            <button className="border-[2px] border-secondary-60 px-[24px] py-[10px] rounded-[6px] text-secondary-60 min-w-[174px] h-[48px]" 
            onClick={(e)=>{
              dispatch(toggleSubscriptionPopUpTrigger(false))
              setConfirmCloseTrigger(false)
            }}>
              I Understand
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsavedChanges;
