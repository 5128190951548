import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import CompletionIndicator from "../CompletionIndicator/CompletionIndicator";
import CalendarChevronNav from "../CalendarChevronNav/CalendarChevronNav";
import CalendarBookingLinkCopy from "../CalendarBookingLinkCopy/CalendarBookingLinkCopy";
import SettingCog from "../../assets/calendarV2/SettingCog";
import AddIcon from "@mui/icons-material/Add";
import CalenderModal from "../CalendarModal/CalenderModal";
import CalendarSetting from "../CalendarSettings/CalendarSetting";
import PopupContainer from "../PopupContainer/PopupContainer";
import { ChevronLeft } from "@mui/icons-material";
import EventTopBar from "../EventTopBar/EventTopBar";
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import EventRequestPopup from "../StudentRequestPopup/EventRequestPopup";
import { useLazySubscriptionInsightsQuery } from "../../app/services/subscription";
import { triggerUpdateSubscriptionPopUp } from "../../utils/subscriptionPopUpService";

const CalendarTopBar = (props) => {
  const {
    user,
    setIsActive,
    isActive,
    view = "week",
    setView,
    date,
    setDate,
    operationMode,
    setOperationMode,
    calRef,
    modifyDateOnClick,
    selectedTimezone,
    setSelectedTimezone,
    isActiveRequestEventPopUp,
    setIsActiveRequestEventPopUp,
  } = props;
  const navigate = useNavigate();
  const [calSettingsTrigger, setCalSettingsTrigger] = useState(false);
  const [newEventTrigger, setNewEventTrigger] = useState(true);
  const [sessionPercentage, setSessionPercentage] = useState(null);
  const [indicatorColor, setIndicatorColor] = useState("#2BA773");
  const [indicatorColorOuter, setIndicatorColorOuter] = useState("#2BA773");
  const [sessionInsight, setSessionInsight] = useState({
    limit: "",
    usage: "",
  });
  const [getSubscriptionInsights, respGetSubscriptionInsights] =
    useLazySubscriptionInsightsQuery();

  function calculatePercentage(remainingFromLimit, limit) {
    if (limit === 0) {
      return 0;
    }
    if (remainingFromLimit === 0) {
      return 0;
    }
    const remainingPercentage = (remainingFromLimit / limit) * 100;
    return Math.max(0, Math.min(100, remainingPercentage));
  }

  const getInsights = async () => {
    try {
      const res2 = await getSubscriptionInsights();
      console.log("insightsss data", res2);
      if (!res2.error) {
        console.log("insightsss", res2.data.data);
        let data = res2.data.data;
        let percent = calculatePercentage(
          data?.limits.sessions.base - data?.usage.sessions.base,
          data?.limits.sessions.base
        );
        setSessionInsight({
          limit: data?.limits.sessions.base,
          usage: data?.usage.sessions.base,
        });
        let percent1 = 28;
        console.log("percent", percent);
        // Update the indicator color based on percentage
        if (percent >= 70) {
          setIndicatorColor("#2BA773"); // Green
          setIndicatorColorOuter("#2BA77340");
        } else if (percent >= 30 && percent < 70) {
          setIndicatorColor("#F8C202"); // Yellow
          setIndicatorColorOuter("#F8C20240");
        } else if (percent < 30) {
          setIndicatorColor("#D92D20"); // Red
          setIndicatorColorOuter("#D92D2040");
        }
        setSessionPercentage(percent);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInsights();
  }, []);

  return (
    <div className="flex justify-between p-2 items-center">
      <div className="flex gap-[24px] items-center">
        <CalendarChevronNav
          {...{ date, setDate, selectedView: view, calRef, modifyDateOnClick }}
        />
        <div className="flex gap-[8px] items-center justify-between">
          <button
            className={`${
              view === "week"
                ? "bg-blue-100 text-blue-70"
                : "bg-secondary-2 text-secondary-60"
            } px-[12px] py-[2px] rounded-[6px] text-[14px] leading-[21px] font-400`}
            onClick={() => {
              setView("week");
              if (calRef.current) {
                const calAPI = calRef.current.getApi();
                calAPI.changeView("timeGridWeek");
              }
            }}
          >
            Week
          </button>
          <button
            className={`${
              view === "month"
                ? "bg-blue-100 text-blue-70"
                : "bg-secondary-2 text-secondary-60"
            } px-[12px] py-[2px] rounded-[6px] text-[14px] leading-[21px] font-400`}
            onClick={() => {
              setView("month");
              if (calRef.current) {
                const calAPI = calRef.current.getApi();
                calAPI.changeView("dayGridMonth");
              }
            }}
          >
            Month
          </button>
          <button
            className={`${
              view === "year"
                ? "bg-blue-100 text-blue-70"
                : "bg-secondary-2 text-secondary-60"
            } px-[12px] py-[2px] rounded-[6px] text-[14px] leading-[21px] font-400`}
            onClick={() => {
              setView("year");
              if (calRef.current) {
                const calAPI = calRef.current.getApi();
                calAPI.changeView("multiMonth");
              }
            }}
          >
            Year
          </button>
        </div>
      </div>
      <div className="flex gap-[24px] items-center">
        <Tooltip
          title={
            <div className="bg-white  w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26] font-[lexend]">
              <div className="font-normal text-[12px] leading-[18px] text-secondary-70 cursor-default">
                {sessionInsight.usage}/{sessionInsight.limit} event credits
                remaining.
              </div>
              <button
                className="font-normal text-[12px] leading-[18px] text-blue-70 text-left"
                onClick={() => {
                  triggerUpdateSubscriptionPopUp();
                }}
              >
                Manage Subscription
              </button>
            </div>
          }
          componentsProps={{ tooltip: { sx: { bgcolor: "transparent" } } }}
        >
          <div className={`flex  items-center ${user?.role !== "admin" ? "hidden" : ""}`}>
            <CompletionIndicator
              height={20}
              width={20}
              innerStrokeWidth={"16px"}
              outerStrokeWidth={"16px"}
              outerCircleColor={indicatorColorOuter}
              innerCircleColor={indicatorColor}
              percentage={sessionPercentage}
              helperText={""}
              mainText={""}
            />
          </div>
        </Tooltip>
        <button
          onClick={() => {
            setCalSettingsTrigger((prev) => !prev);
          }}
        >
          <SettingCog />
        </button>
		{
			["admin","tutor"].includes(user?.role) && 
				<CalendarBookingLinkCopy {...props} />
		}
        <button
          className="bg-blue-70 rounded-[6px] text-white px-[20px] py-[10px] flex items-center justify-start gap-[8px] h-[40px]"
          onClick={() => {
            if (user?.role === "admin" || user?.role === "tutor") {
              props.setSelectedDate(null);
              setIsActive(!isActive);
              setOperationMode("create");
            } else if (user?.role === "student" || user?.role === "parent") {
              props.setSelectedDate(null);
              setIsActiveRequestEventPopUp((prev) => !prev);
              setOperationMode("create");
            }
          }}
        >
          <AddIcon style={{ height: "16px", width: "16px" }} /> New Event
        </button>
      </div>
      {
        calSettingsTrigger &&
        <CalenderModal 
						heading={"Settings"} 
						dividerColor={"text-secondary-10"} 
						setActive={setCalSettingsTrigger} 
						children={<CalendarSetting setActive={setCalSettingsTrigger} persona={user?.role} />} 
		/>
      }

    </div>
  );
};

export default CalendarTopBar;
