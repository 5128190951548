import React, { useEffect, useState } from "react";
import styles from "./ViewStudent.module.css";
import "../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";

const ViewStudent = ({ row }) => {
  const awsLink = "https://evallo-test.s3.amazonaws.com/";
  //   console.log("row", awsLink + row?.extendedProps?._tutor[0]?.photo);

  return (
    <div className={styles.viewAttendees}>
      <div className={styles.searchDropdown}>
        <div className={styles.students2}>
          <div className={styles.textWrapper}>Attendees ({row.length})</div>
          <img className={styles.line} src="img/line-479-2.svg" alt="line" />
          <div className={styles.frame}>
            {row.map((stud, index) => {
              return (
                <div className={styles.person}>
                  <div className={styles.frame4}>
                    {/* <div className={styles.textWrapper4}>A</div> */}
                    {stud?.photo ? (
                      <img
                        alt={stud.firstName}
                        src={`${awsLink}${stud.photo}`}
                        className={styles.div6}
                      />
                    ) : (
                      <>
                        {" "}
                        <div className={styles.div}>
                          {stud.firstName.charAt(0)}
                        </div>
                      </>
                    )}
                  </div>
                  <div className={styles.frame2}>
                    <div className={styles.textWrapper2}>
                      {stud.firstName + " " + stud.lastName}
                    </div>
                    <div className={styles.textWrapper3}>{stud.email}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewStudent;
