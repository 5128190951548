import styles from './RequestConfirmationScreen.module.css'
import ArrowIconLeftV2 from '../../ArrowIconLeftV2/ArrowIconLeftV2'
import { Link, useOutletContext, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const RequestConfirmationScreen = (props) =>{

    const {setSignupStage} = useOutletContext()
    const [searchParams,setSearchParams] = useSearchParams()
    const [email, setEmail] = useState("")

    useEffect(()=>{setSignupStage(null)},[])

    useEffect(()=>{setEmail(searchParams.get("email"))},[searchParams.get("email")])

    return(
        <div className={styles.container}>
            <h3>Check your email</h3>
            <div className={styles.messageContainer}>
                <div>We’ve sent an email to <span>{email}</span> with instructions to reset your password.</div>
                <div>If you don’t find the email in your inbox, please check your spam folder. </div>
            </div>
            <Link className={styles.backButton} to="/signin">
                <div className={styles.content}>
                    <div><ArrowIconLeftV2 /></div>
                    <div>Back to login</div>
                </div>
            </Link>
        </div>
    )
}

export default RequestConfirmationScreen