import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, getAuthHeader } from "../constants/constants";

export const authServicesApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),

  endpoints: (builder) => ({
    signupUser: builder.mutation({
      query: (body) => ({
        url: `/api/user/orgSignupNew`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    googleSignUp: builder.mutation({
      query: (body) => ({
        url: `/api/user/googleSignup`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    googleLogin: builder.mutation({
      query: (body) => ({
        url: `/api/user/googleLogin`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    signup: builder.mutation({
      query: (body) => ({
        url: `/api/user/signup`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    addUserDetails: builder.mutation({
      query: (payload) => ({
        url: `/api/user/adddetails/${payload.userId}`,
        method: "POST",
        body: payload.body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    setPassword: builder.mutation({
      query: (payload) => ({
        url: `/api/user/verify/${payload.userId}`,
        method: "POST",
        body: payload.body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    loginUser: builder.mutation({
      query: (body) => ({
        url: `/api/user/login`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: `/api/user/resetpassword`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (payload) => ({
        url: `/api/user/changepassword/${payload.userId}`,
        method: "POST",
        body: payload.body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    updatePassword: builder.mutation({
      query: (payload) => ({
        url: `/api/user/superadmin/updatePassword`,
        method: "PATCH",
        body: payload.body,
        headers: getAuthHeader(),
      }),
    }),
    microsoftLogin: builder.mutation({
      query: (body) => ({
        url: `/api/user/microsoftLogin`,
        method: "POST",
        body: body,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
  }),
});

export const {
  useSignupUserMutation,
  useAddUserDetailsMutation,
  useSetPasswordMutation,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useSignupMutation,
  useUpdatePasswordMutation,
  useGoogleSignUpMutation,
  useGoogleLoginMutation,
  useMicrosoftLoginMutation,
} = authServicesApi;
