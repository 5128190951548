import React from 'react'
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical,faPen,faAngleDown,faAngleUp,faCalculator} from '@fortawesome/free-solid-svg-icons';

export default function Navbar({testHeaderDetails,secnd,setCountDown,showannotate,setshowannotate,details,handleSubmitSection,calculator_check,annotation_check,sectionDetails,cal,setCal,show_ann,setshow_ann, isUnlimited}) {
  const [seconds, setSeconds] = useState(secnd)
  const [hide, sethide] = useState(false)
  const [isDescOpen, setIsDescOpen] = useState(false);

  useEffect(() => {
    if (seconds <= 0 && !isUnlimited) {
      return handleSubmitSection();
    }

    let interval = null
    let timerWorker = null


    if(typeof(Worker) !== "undefined"){
      if(timerWorker) return;
      timerWorker = new Worker('/testTimerWorker.js')
      setCountDown(seconds)
      timerWorker.postMessage(seconds)
      timerWorker.onmessage = (e)=>{
        //Not required here
        const remainingTime = parseInt(e.data)
        setSeconds(prevSeconds => prevSeconds - 1)
        if(remainingTime <= 0){
          timerWorker.terminate()
        }
      }
    }else {
      console.log("NOT WINDOW WORKER")
      setCountDown(seconds);
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    }

    return () => {clearInterval(interval); timerWorker?.terminate()};
  }, [seconds, isUnlimited]);


    const minutes = Math.floor(seconds / 60);
    const second = seconds % 60;
    const [tog,setTog] =useState(false)
    
    // const {cal,setCal} = props
    let handleDesc= ()=>
    {
          setTog(!tog)
          setIsDescOpen(prevState => !prevState);
    }
    let w = {
        width :"80%",
        height :"80%",
        top:"93px"
    }
    let handleCal =()=>
    {
      setCal(!cal)
    }

  const handleAnnotate = ()=>{
    if(show_ann){
      setshowannotate(!showannotate)  
    }else{
      alert('MAKE A SELECTION FIRST, Select some text then press annotate')
    }
    
  }
  return (
    <div className='relative z-5000'>
      <nav className='sticky top-0 border-dashed border-b-3 border-black w-[1920px] text-xl flex justify-between py-4 px-12 left-0 right-0 navb'>
        <div className='flex w-1/3 flex-col items-start'>
        <div>
            <p className="font-bold">{sectionDetails?.name?sectionDetails.name:'loading...'}</p>
        </div>
        {/* <div className='align-middle text-sm pt-2' > */}
      <button onClick={handleDesc} className="text-[15px] pt-2">
        Directions
      <FontAwesomeIcon
        icon={isDescOpen ? faAngleUp : faAngleDown}
        className='ml-3'
        />
        </button>
    {/* </div> */}
        {
        tog?<div style={w} className='z-1000 text-base absolute bg-white shadow-lg shadow-gray-400 left-15 p-8 min-h-[730px]'>
        {/* <div className='relative'> */}
          <div className='notch'></div>
          <p>{details}</p>
        {/* </div> */}
      </div>      
      :null
        }
        </div>
        {testHeaderDetails.duration!=='Unlimited' &&<div className='flex w-1/3 flex-col justify-center items-center'>
          {hide?null:  <div className='' >
                <p className="font-bold" >{minutes<10?'0'+minutes:minutes}:{second<10?'0'+second:second}</p>
            </div>}
            <div className=' text-sm pt-2  ' >
                <button className='border rounded-xl border-black hover:border-1 px-3' onClick={()=>sethide(!hide)} >{hide?'Show':'Hide'}</button>
            </div>
        </div>}
        <div  className=' flex cursor-pointer w-1/3 justify-end'>
        {calculator_check?<div className='flex flex-col h-full items-center justify-center cursor-pointer' onClick={handleCal} >       
            <FontAwesomeIcon icon={faCalculator} className='' />
                <p className="pt-1 font-bold">Calculator</p>
            </div>
            :null}
            {annotation_check?
            <div className='flex cursor-pointer flex-col items-center h-full justify-center px-3 ' onClick={handleAnnotate}>
            <FontAwesomeIcon icon={faPen} className='' />  
            <p className="pt-1 font-bold">Annotate</p>
            </div>
            :null}
            {/* <div className='flex flex-col items-center h-full justify-center '>
            <FontAwesomeIcon className='' icon={faEllipsisVertical} />
            <button>More</button>
            </div> */}
        </div>
            <FontAwesomeIcon icon="fa-sharp fa-regular fa-circle-ellipsis-vertical" style={{color: "#202122",}} />
      </nav>
     {/* <hr /> */}
    </div>
  )
}
