import React, { Suspense, lazy, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { RequireAuth } from "./PrivateRoute";
import Footer from "../components/Footer/Footer";
import Layout from "../pages/Layout/Layout";
import Layout2 from "../pages/Layout/Layout2";
import SubscriptionAndExtensionModal from "../pages/Frames/SubscriptionAndExtensionModal/SubscriptionAndExtensionModal";
import {
  useLazyGetAuthQuery,
  useLazyGetOrganizationQuery,
  useLazyGetPersonalDetailQuery,
} from "../app/services/users";
import {
  closeModal as closeSubscriptionAndExtensionModal,
  openModal as openSubscriptionAndExtensionModal,
  openSubscriptionPanelInRenewProductMode,
} from "../app/slices/subscriptionUI";
import {
  triggerSubscriptionUpdate,
  updateActiveExtensionInfo,
  updateActiveSubscriptionInfo,
  updatePaymentMethods,
  updateStripeCustomerId,
  updateSubscriptionsInfoFromAPI,
  updateHasSubscriptionExpired,
  updateDefaultPaymentMethodId,
  updateHasExtensionExpired,
  updateIsLoaded,
} from "../app/slices/subscription";
import { useLazyGetSubscriptionsInfoQuery } from "../app/services/orgSignup";
import Loader from "../components/Loader";
import Stage from "../pages/Stage/Stage";
import OrgSignupV2 from "../pages/OrgSignupV2/OrgSignupV2";
import LoginV2 from "../pages/LoginV2/LoginV2";
import ForgotPasswordV2 from "../pages/ForgotPasswordV2/ForgotPasswordV2";
import ResetPasswordScreen from "../components/ResetPasswordScreen/ResetPasswordScreen";
import ManageSubscription from "../pages/ManageSubscription/ManageSubscription";
import { toast } from "react-toastify";
import axios from "axios";
import { getAuthHeader } from "../app/constants/constants";
import LoaderIcon from "../components/LoaderIcon/LoaderIcon";
import GlobalTestingComponent from "../components/GlobalTestingComponent/GlobalTestingComponent";
import StudentDashboard from "../pages/StudentDashboard/StudentDashboard";
import TutorDashboard from "../pages/TutorDashboard/TutorDashboard";
import ParentDashboard from "../pages/ParentDashboard/ParentDashboard";
import AccountPage from "../pages/Account/AccountPage";
import SettingsV2 from "../pages/SettingsV2/SettingsV2";
import YourBusiness from "../pages/SettingsV2/YourBusiness/YourBusiness";
import Announcements from "../pages/SettingsV2/Announcements/Announcements";
import ProductSettings from "../pages/ProductSettings/ProductSettings";
import CustomSignup from "../pages/CustomSignup/CustomSignup";
import PaymentMethods from "../pages/SettingsV2/PaymentMethods/PaymentMethods";
import ManageSubscriptionSettings from "../pages/SettingsV2/ManageSubscription/ManageSubscriptionSettings";
import AssignmentsSettings from "../pages/ProductSettings/AssignmentsSettings/AssignmentsSettings";
import CalendarSettings from "../pages/ProductSettings/CalendarSettings/CalendarSettings";
import DateTimeSettings from "../pages/Preferences/Datetime/DateTimeSettings";
import ReferralCode from "../pages/Referrals/ReferralCode";
import DateTime from "../pages/SettingsV2/PreferenceDateTime/DateTime";
import Referral from "../pages/SettingsV2/PreferenceReferralCode/ReferralCode";
import DomainIntegration from "../pages/ProductSettings/CustomSettings/DomainIntegration/DomainIntegration";
import SubdomainSignup from "../pages/SubdomainSignup/SubdomainSignup";
import CustomColor from "../pages/ProductSettings/CustomSettings/CustomColor/CustomColor";
import ServicesTopics from "../components/ProductSettings/ServicesTopics/ServicesTopics";
import GLogin from "../pages/GLogin/GLogin";
import { document } from "postcss";
import { useLazyOrganizationDetailsQuery } from "../app/services/organization";
import CalendarV2 from "../pages/CalendarV2/CalendarV2";

const AllTests = lazy(() => import("../pages/AllTests/AllTests"));
const AssignedTests = lazy(() =>
  import("../pages/AssignedTests/AssignedTests")
);
const Calendar = lazy(() => import("../pages/Calendar/Calendar"));
const Home = lazy(() => import("../pages/Home/Home"));
const Login = lazy(() => import("../pages/Login/Login"));
const ForgotPassword = lazy(() =>
  import("../pages/ForgotPassword/ForgotPassword")
);
const Signup = lazy(() => import("../pages/Signup/Signup"));
const StudentProfile = lazy(() =>
  import("../pages/Profiles/StudentProfile/StudentProfile")
);
const TestDetail = lazy(() => import("../pages/TestDetail/TestDetail"));
const Users = lazy(() => import("../pages/Users/users"));
const SetPassword = lazy(() =>
  import("../pages/Frames/SetPassword/SetPassword")
);
const Ledger = lazy(() => import("../pages/Ledger/Ledger"));
const StartTest = lazy(() => import("../pages/StartTest/StartTest"));
const AssignedStudents = lazy(() =>
  import("../pages/AssignedStudents/assignedStudents")
);
const ParentProfile = lazy(() =>
  import("../pages/Profiles/ParentProfile/ParentProfile")
);
const TutorProfile = lazy(() => import("../pages/Profiles/Tutor/TutorProfile"));
const Invoice = lazy(() => import("../pages/Invoice/Invoice"));
const StudentReport = lazy(() =>
  import("../pages/StudentReport/StudentReport")
);
const AssignedTutors = lazy(() =>
  import("../pages/AssignedTutors/AssignedTutors")
);
const SuperadminDashboard = lazy(() =>
  import("../pages/SuperadminDashboard/SuperadminDashboard")
);
const UserSignup = lazy(() => import("../pages/UserSignup/Signup"));
const Dashboard = lazy(() => import("../pages/AdminDashboard/Dashboard"));
const AdminContent = lazy(() => import("../pages/AdminContent/AdminContent"));
const AllOrgs = lazy(() =>
  import("../pages/SuperadminDashboard/components/AllOrgs/AllOrgs")
);

const Settings = lazy(() => import("../pages/Settings/Settings"));
const SuperAdminSettings = lazy(() =>
  import("../pages/Settings/SuperAdminSettings")
);
const SuperAdminProfile = lazy(() =>
  import("../pages/SuperadminDashboard/components/About/About")
);
const EmailVerify = lazy(() =>
  import("../pages/Settings/Tabs/AccountOverview/EmailVerify")
);
const StudentSettings = lazy(() =>
  import("../pages/Settings/Tabs/AccountOverview/studentSettings")
);
const ContributorSettings = lazy(() =>
  import("../pages/Settings/ContributorSettings")
);
const TestPage = lazy(() => import("../pages/DsatTestPage/TestPage"));
const AnnotatorComponent = lazy(() => import("../components/annotate"));
const Testinstruction_2 = lazy(() =>
  import("../components/TestItem/testinstruction_2")
);
const AdminPortal = lazy(() =>
  import("../pages/SuperadminDashboard/components/About/AdminPortal")
);
const OrgAdminSignup = lazy(() =>
  import("../pages/OrgAdminSignup/OrgAdminSignup")
);
const BrandSetting = lazy(() => import("../pages/BrandSetting/BrandSetting"));

const MeetingBookings =  lazy(()=> import("../pages/MeetingBookings/MeetingBookings") );

// Lazy-load your components

//  layout page
const AppRoutes = () => {
  const [testing, setTesting] = useState(false);
  const [orgData] = useLazyOrganizationDetailsQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {organization} = useSelector((state)=>state.organization)
  const { isLoggedIn, fetchData } = useSelector((state) => state.user);
  const {
    isActive: isSubscriptionAndExtensionModalActive,
    isInUpdateMode: isSubscriptionAndExtensionModalInUpateMode,
    isInRenewProductMode: isSubscriptionAndExtensionModalInRenewProductMode,
    openSubscriptionPanel,
    openExtensionsPanel,
  } = useSelector((state) => state.subscriptionUI);
  const {
    subscriptionsInfoFromAPI,
    activeSubscriptionInfo,
    activeExtensionInfo,
    subscriptionUpdateTrigger,
    hasSubscriptionExpired,
  } = useSelector((state) => state.subscription);
  const { role: persona } = useSelector((state) => state.user);
  const [isOrgAdmin, SetIsOrgAdmin] = useState(false);
  // const [isSubscriptionAndExtensionModalActive, SetIsSubscriptionAndExtensionModalActive] = useState(false);
  const [getPersonalDetail, getPersonalDetailResp] =
    useLazyGetPersonalDetailQuery();
  const [getOrgDetails, getOrgDetailsResp] = useLazyGetOrganizationQuery();
  const [getSubscriptionsInfo, getSubscriptionsInfoResp] =
    useLazyGetSubscriptionsInfoQuery();
  const [navData, setNavData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { subscriptionV2, oldSubscriptionExists } = useSelector(
    (state) => state.subscription
  );
  const { isSignupProcessComplete } = useSelector(
    (state) => state.subscriptionOperation
  );
  const [subdomainExists, setSubdomainExists ] = useState();



  function fetchSubscriptionsInfo() {
    getSubscriptionsInfo()
      .then((res) => {
        if (res.error) return;
        if (res.data.data) {
          let subscriptions = res.data.data;
          // subscriptions = subscriptions.filter(item => item.recurring.interval !== 'year')
          // console.log('subs--', res.data.data);
          dispatch(updateSubscriptionsInfoFromAPI(subscriptions));
        }
        // SetSubscriptionsInfoFromAPI(res.data.data);
      })
      .catch((error) => {
        console.error("Error while fetching subscriptions info");
        console.error(error);
      });
  }

  const renderDashboard = () => {
    switch (persona) {
      case "student":
        return <StudentDashboard />;
      case "tutor":
        return <TutorDashboard />;
      case "parent":
        return <ParentDashboard />;
      case "admin":
        return <Users />;
      case "superAdmin":
      case "manager":
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <SuperadminDashboard />
          </Suspense>
        );
      default:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Home />
          </Suspense>
        );
    }
  };

  useEffect(() => {
    if (
      persona === "parent" ||
      persona === "student" ||
      persona === "tutor" ||
      persona === "contributor" ||
      persona === "superAdmin" ||
      persona === "manager"
    ) {
      SetIsOrgAdmin(false);
      return;
    }
    console.log("Testing");
    SetIsOrgAdmin(true);
  }, [persona]);

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        try {
          const res = await axios({
            method: "GET",
            url:
              process.env.REACT_APP_BASE_URL +
              "api/orgs/subscription?siMapping=true",
            headers: getAuthHeader(),
          });
          const subscriptionData = res.data.data;

          if (window.localStorage.getItem("evalloToken")) {
            window.localStorage.setItem(
              "subscriptionData",
              JSON.stringify(subscriptionData)
            );
          } else {
            window.sessionStorage.setItem(
              "subscriptionData",
              JSON.stringify(subscriptionData)
            );
          }
        } catch (err) {
          // toast.error("Oops some error occured");
          console.log(err);
        }
      })();
    }
  }, [isLoggedIn]);

   // Org Find with sub-domain name
   useEffect(() => {
    const { hostname } = new URL(window.location.href);
    const subdomain = hostname.split(".")[0];
    orgData({ subdomain: subdomain, page:"signup" }).then((res) => {
      if (res.error) {
        console.log(res.error);
        return;
      }

      if (res.data.data.OrgData && res.data.data.OrgData.customSubDomain.isActive) setSubdomainExists(true);
      else  setSubdomainExists(false);
    });
  }, [window.location.href]);


  useEffect(() => {
    if (
      persona === "parent" ||
      persona === "student" ||
      persona === "tutor" ||
      persona === "contributor" ||
      persona === "superAdmin" ||
      persona === "manager"
    ) {
      return;
    }

    if (subscriptionsInfoFromAPI && subscriptionsInfoFromAPI.length > 0) return;
    fetchSubscriptionsInfo();
  }, [persona, subscriptionsInfoFromAPI]);

  function loadOrgDetails() {
    getPersonalDetail()
      .then((data) => {
        if (data.error) {
          return console.log("error", data.error);
        }
        const user = data.data.data.user;

        getOrgDetails(user.associatedOrg)
          .then((data) => {
            if (
              data?.data?.stripeCustomerDetails?.invoice_settings
                ?.default_payment_method
            ) {
              dispatch(
                updateDefaultPaymentMethodId(
                  data.data.stripeCustomerDetails.invoice_settings
                    .default_payment_method
                )
              );
            }

            if (data?.data?.stripeCustomerDetails?.id) {
              dispatch(
                updateStripeCustomerId(data.data.stripeCustomerDetails.id)
              );
            }

            if (data?.data?.stripeCustomerPaymentDetails) {
              dispatch(
                updatePaymentMethods(
                  data.data.stripeCustomerPaymentDetails.data
                )
              );
              // SetPaymentMethods(data.data.stripCustomerPaymentDetails.data);
            }

            if (data?.data?.customerSubscriptions?.data?.length === 0) {
              dispatch(openSubscriptionAndExtensionModal());
            } else {
              dispatch(closeSubscriptionAndExtensionModal());
            }

            let foundSubscription = false;
            if (
              data?.data?.customerSubscriptions?.data?.length > 0 &&
              subscriptionsInfoFromAPI?.length > 0
            ) {
              let subsriptions = data.data.customerSubscriptions.data;
              let products = [...subsriptions];

              // let subscriptions = [...products].filter(item => item.metadata.type === "default")
              // let activeSubscription = subscriptions.find(item => item.status === 'active')
              // if(activeSubscription){
              //   products = products.filter(item => {
              //     let subscriptionIds = subscriptions.map(i => i.id)
              //     if(item.id === activeSubscription.id) return true
              //     else if (subscriptionIds.includes(item.id)) return false
              //     return true
              //   })
              // }
              products = products.sort(
                (a, b) => new Date(a.created) - new Date(b.created)
              );
              let activeSub;
              let hasActiveSubscription = false;
              let status = products.filter(
                (product) => product.metadata.type !== "extension"
              );
              status = products.map((product) => product.status);
              if (status.includes("trialing") || status.includes("active")) {
                hasActiveSubscription = true;
              }

              //  const arr = products.filter(item => item.metadata.type !== "extension")
              //  console.log('arr---', arr);
              //  const trialSub = arr.find(item => item.status === 'trialing')
              //  if(trialSub){

              //  }
              // console.log('subscriptionsInfoFromAPI---', subscriptionsInfoFromAPI);
              const todayDate = new Date();
              for (let i = 0; i < 0; i++) {
                let planId = products[i].plan.id;
                activeSub = subscriptionsInfoFromAPI.find(
                  (item) => item.id === planId
                );
                if (products[i].metadata.type === "extension") {
                  let productQuantity = 0;
                  if (activeSub.lookup_key === "p1") {
                    productQuantity = 100;
                  }
                  if (activeSub.lookup_key === "p2") {
                    productQuantity = 400;
                  }
                  if (activeSub.lookup_key === "p3") {
                    productQuantity = 1500;
                  }
                  if (activeSub.lookup_key === "p4") {
                    productQuantity = Infinity;
                  }
                  if (activeSub.lookup_key === "p5") {
                    productQuantity = 4800;
                  }

                  const expiryDate = new Date(
                    products[i].current_period_end * 1000
                  );
                  const isCancelled =
                    products[i].canceled_at === null ||
                    products[i].canceled_at === undefined
                      ? false
                      : true;
                  let hasExpired = expiryDate < todayDate;
                  if (
                    products[i].cancel_at_period_end === false &&
                    products[i].status === "canceled"
                  ) {
                    hasExpired = true;
                  }
                  dispatch(updateHasExtensionExpired(hasExpired));
                  dispatch(
                    updateActiveExtensionInfo({
                      planName: "Assignments",
                      planDisplayName: "Assignments",
                      productQuantity: productQuantity,
                      packageName: activeSub.lookup_key,
                      currency: products[i].currency,
                      startDate: products[i].start_date * 1000, //new Date(products[i].start_date * 1000),
                      autoRenewalDate: products[i].current_period_end * 1000, //new Date(products[i].current_period_end * 1000),
                      expiryDate: products[i].current_period_end * 1000, //expiryDate,
                      subscriptionPricePerMonth: products[i].plan.amount / 100,
                      monthlyCostAfterDiscount: products[i].plan.amount / 100,
                      freeTrialExpiryDate: products[i].trial_end * 1000, //new Date(products[i].trial_end * 1000),
                      hasExpired: hasExpired,
                      isCancelled: isCancelled,
                      priceObject: products[i].items?.data[0]?.price,
                      subscriptionId: products[i].id,
                      canceled_at: products[i].canceled_at
                        ? products[i].canceled_at * 1000
                        : 0,
                      status: products[i].status,
                      cancel_at_period_end: products[i].cancel_at_period_end,
                    })
                  );

                  continue;
                }

                foundSubscription = true;
                const expiryDate = new Date(
                  products[i].current_period_end * 1000
                );
                const isCancelled =
                  products[i].canceled_at === null ||
                  products[i].canceled_at === undefined
                    ? false
                    : true;
                const subscriptionPricePerMonth = activeSub.unit_amount / 100;
                let monthlyCostAfterDiscount = subscriptionPricePerMonth;
                if (products[i]?.discount?.coupon) {
                  monthlyCostAfterDiscount =
                    subscriptionPricePerMonth -
                    (products[i]?.discount?.coupon?.percent_off / 100.0) *
                      subscriptionPricePerMonth;
                }

                if (expiryDate < todayDate) {
                  // if (!hasActiveSubscription) navigate("/settings");
                  // dispatch(openSubscriptionPanelInRenewProductMode());
                  // dispatch(updateHasSubscriptionExpired(true));
                  continue;
                }

                console.log("UPDATING----", activeSub.product.name);
                let hasExpired = expiryDate < todayDate;
                if (
                  products[i].cancel_at_period_end === false &&
                  products[i].status === "canceled"
                ) {
                  hasExpired = true;
                }
                dispatch(updateHasSubscriptionExpired(hasExpired));
                dispatch(
                  updateActiveSubscriptionInfo({
                    planName: activeSub.product.name,
                    planDisplayName: activeSub.product.name,
                    activeTutorsAllowed: products[i].metadata.active_tutors,
                    currency: activeSub.currency,
                    subscriptionPricePerMonth: subscriptionPricePerMonth, //activeSub.unit_amount / 100,
                    monthlyCostAfterDiscount: monthlyCostAfterDiscount, //activeSub.unit_amount / 100,
                    startDate: products[i].start_date * 1000, //new Date(products[i].start_date * 1000),
                    autoRenewalDate: products[i].current_period_end * 1000, //new Date(products[i].current_period_end * 1000),
                    expiryDate: products[i].current_period_end * 1000, //expiryDate,
                    freeTrialExpiryDate: products[i].trial_end * 1000, //new Date(products[i].trial_end * 1000),
                    hasExpired: hasExpired,
                    isCancelled: isCancelled,
                    priceObject: products[i].items?.data[0]?.price,
                    subscriptionId: products[i].id,
                    canceled_at: products[i].canceled_at
                      ? products[i].canceled_at * 1000
                      : 0,
                    status: products[i].status,
                    cancel_at_period_end: products[i].cancel_at_period_end,
                  })
                );
              }
            }
            dispatch(updateIsLoaded());
            /* if(!foundSubscription) {
              dispatch(openSubscriptionAndExtensionModal());
            } else {
              dispatch(closeSubscriptionAndExtensionModal());
            } */
          })
          .catch((error) => {
            console.log("Error in getOrgDetails");
            dispatch(updateIsLoaded());
            console.log(error);
          });
      })
      .catch((error) => {
        console.log("Error in getPersonalDetail");
        dispatch(updateIsLoaded());
        console.log(error);
      });
  }

  // useEffect(() => {
  //   if (isSubscriptionAndExtensionModalActive) {
  //     const va = document.getElementById("bodyy").style.transform;
  //     if (va) {
  //       const scaleValue = parseFloat(va.match(/scale\(([^)]+)\)/)[1]);
  //       const target = document.getElementById("extensionmodal");
  //       target.style.transform = `scale(${scaleValue})`;
  //     }
  //   }
  // }, [isSubscriptionAndExtensionModalActive]);

  useEffect(() => {
    if (
      persona === "parent" ||
      persona === "student" ||
      persona === "tutor" ||
      persona === "contributor" ||
      persona === "superAdmin" ||
      persona === "manager"
    ) {
      return;
    }

    loadOrgDetails();
  }, [
    persona,
    subscriptionsInfoFromAPI,
    subscriptionUpdateTrigger,
    subscriptionV2,
    fetchData,
  ]);

  const location = useLocation();

  useEffect(() => {
    if (
      isLoggedIn &&
      persona === "admin" &&
      location.pathname !== "/settings" &&
      loading === false
    ) {
      // console.log('navData', navData, isLoggedIn);
      const currentLocation = location.pathname.split("?");
      const item = navData.find((item) => item.path === location.pathname);
      console.log("currentLocation", currentLocation);
      console.log("item", item);
      if (item) {
        if (item.isDisabled) {
          // window.location.href = "/settings";
        }
      }
    }
  }, [navData, location.pathname, isLoggedIn, loading]);

  //To change the allowed route to manage subscription & settings whenever the subscriptionV2 is inactive
  useEffect(() => {
    if (
      subscriptionV2?.subscription?.isActive === false &&
      !oldSubscriptionExists
    ) {
      if (
        window.location.pathname !== "/settings" &&
        window.location.pathname !== "/manage-subscription"
      ) {
        navigate("/settings");
      }
    }
  }, [subscriptionV2, location, oldSubscriptionExists]);

	
  useEffect(()=>{
		const domain = ".evallo.org"
		if(![`app${domain}`,`test${domain}`,`localhost:3000`].includes(window.location.host) && subscriptionV2 && subscriptionV2.subscription.basePackage.name.includes("Premium") && organization){
			window.document.title = organization.company
			console.log("OC", organization.company)
		}
  },[window.location.host, subscriptionV2, organization])

  if (!testing) {
    return (
      <>
        <Layout2 setNavData={setNavData} setLoading={setLoading}>
          <Routes>
            {/* <Route
              path="/"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {isLoggedIn ? <Users /> : <Login />}
                </Suspense>
              }
            /> */}
			<Route path='/oauth' element={<GLogin />}/>
            {isLoggedIn &&
            (subscriptionV2 ||
              persona === "superAdmin" ||
              persona === "manager" ||
              oldSubscriptionExists ||
              isSignupProcessComplete) ? (
              <Route
                path="/"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <RequireAuth isLoggedIn={isLoggedIn}>
                      {renderDashboard()}
                    </RequireAuth>
                  </Suspense>
                }
              />
            ) : (

              subdomainExists && subdomainExists === true ?

              <>
               <Route index element={
										<Suspense fallback={<div>Loading...</div>}>
											<UserSignup />
										</Suspense>
				} />
                <Route path="signup" element={
										<Suspense fallback={<div>Loading...</div>}>
											<UserSignup />
										</Suspense>
										}/>
               <Route path="/" element={<Stage />}>
                <Route path="signin/*" element={<LoginV2 />} />
              </Route>
              </>
               :  
              <Route path="/" element={<Stage />}>
                <Route index element={<OrgSignupV2 />} />
                <Route path="signup/*" element={<OrgSignupV2 />} />
                <Route path="signin/*" element={<LoginV2 />} />
              </Route>
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route
              path="/forgot-password"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <ForgotPassword />
                </Suspense>
              }
            />

            {/*  layout routes */}
            <Route
              path="/Layout"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Layout />
                </RequireAuth>
              }
            />
            {/* 
            <Route
              path="/signup"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <OrgAdminSignup />
                </Suspense>
              }
            /> */}

            <Route path="/admin-portal" element={<AdminPortal />} />

            
            <Route path="/signup/user" element={<UserSignup />} />
         

            <Route
              path="/"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  {persona === "superAdmin" || persona === "manager" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <SuperadminDashboard />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Home />
                    </Suspense>
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/all-orgs"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AllOrgs />
                </Suspense>
              }
            />
            <Route
              path="/verify-email"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <EmailVerify />
                </Suspense>
              }
            />
            <Route
              path="/orgadmin-profile/:id"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <SuperAdminProfile />
                </Suspense>
              }
            />
            <Route
              path="/users"
              element={
                <RequireAuth
                  isLoggedIn={isLoggedIn}
                  navItem={navData.find((item) => item.path === "/users")}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <Users />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/invoice"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Invoice />
                  </Suspense>
                </RequireAuth>
              }
            />
            {/* <Route
            path="/assigned-tutors"
            element={
              <RequireAuth isLoggedIn={isLoggedIn}>
                <AssignedTutors />
              </RequireAuth>
            }
          /> */}

            <Route
              path="/calendar/*"
              element={
                <RequireAuth
                  isLoggedIn={isLoggedIn}
                  navItem={navData.find((item) => item.path.split("/")[0].includes("calendar"))}
                >
                  <Suspense fallback={<div>Loading...</div>}>
						          <CalendarV2 />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/calendar/edit/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Calendar />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route 
            path="/calendar/bookings"
            element={
                <Suspense fallback={<div>Loading...</div>}>
                <MeetingBookings/>
                </Suspense>
            }
            />

            {/* <Route path="/calendar/:persona" element={<Calendar />} /> */}
            <Route
              path="/assigned-tests"
              element={
                <RequireAuth
                  isLoggedIn={isLoggedIn}
                  navItem={navData.find(
                    (item) => item.path === "/assigned-tests"
                  )}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <AssignedTests />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/set-password"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <SetPassword />
                </Suspense>
              }
            />
            <Route
              path="/reset-password"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <SetPassword resetPassword={true} />
                </Suspense>
              }
            />
            {/* <Route
                 path="/assigned-tests/:id/:assignedTestId/report"
                 element={
                    <RequireAuth isLoggedIn={isLoggedIn}>
                       <StudentReport />
                    </RequireAuth>
                 }
              /> */}
            <Route
              path="/assigned-tests/:id/:assignedTestId/report"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <StudentReport />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/assigned-tests/:id/:assignedTestId/report/:studentId"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <StudentReport />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/all-tests"
              element={
                <RequireAuth
                  isLoggedIn={isLoggedIn}
                  navItem={navData.find((item) => item.path === "/all-tests")}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <AllTests />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/all-tests/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <TestDetail />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  {persona === "parent" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ParentProfile isOwn={true} />
                    </Suspense>
                  ) : persona === "student" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <StudentProfile isOwn={true} />
                    </Suspense>
                  ) : persona === "tutor" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <TutorProfile isOwn={true} />
                    </Suspense>
                  ) : (
                    <></>
                  )}
                </RequireAuth>
              }
            />
            {/* <Route path="/profile" element={
                 persona === 'parent' ? <ParentProfile isOwn={true} /> : persona === 'student' ? <StudentProfile isOwn={true} /> : persona === 'tutor' ? <TutorProfile isOwn={true} /> : <></>
              } /> */}

            <Route
              path="/profile/student/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <StudentProfile />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/profile/parent/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <ParentProfile />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/profile/tutor/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <TutorProfile />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/profile/admin/:id"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <TutorProfile />
                  </Suspense>
                </RequireAuth>
              }
            />

            <Route
              path="/ledger"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Ledger />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/testpage/:id/:assignedTestId"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <TestPage />
                  </Suspense>
                </RequireAuth>
              }
            />

            <Route
              path="/settings"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  {persona === "superAdmin" || persona === "manager" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <SuperAdminSettings />
                    </Suspense>
                  ) : persona === "student" ||
                    persona === "parent" ||
                    persona === "tutor" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <StudentSettings />
                    </Suspense>
                  ) : persona === "contributor" ? (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ContributorSettings />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div>Loading...</div>}>
                      {/* <Settings /> */}
                      <SettingsV2 />
                    </Suspense>
                  )}
                </RequireAuth>
              }
            >
              {persona === "admin" ? (
                <>
                  <Route index element={<AccountPage />} />
                  <Route path="test" element={<>Testing</>} />
                  <Route path="test-2" element={<>Test 2</>} />

                  <Route path="account" element={<AccountPage />} />
                  <Route path="business" element={<YourBusiness />} />
                  <Route path="referral-management" element={<Referral />} />

                  <Route path="preferences">
                    <Route index element={<>Time And Date</>} />
                    <Route path="time-date" element={<DateTime />} />
                    <Route path="services-topics" element={<ServicesTopics />} />
                  </Route>

                  <Route path="crm" element={<ProductSettings />} />
                  <Route path="calendar" element={<CalendarSettings />} />
                  <Route
                    path="assignments"
                    element={
                      <>
                        <AssignmentsSettings />
                      </>
                    }
                  />

                  <Route path="white-labeling">
                    <Route index element={<>Custom brand settings</>} />
                    <Route
                      path="brand-elements"
                      element={
                        <Suspense
                          fallback={
                            <div className="flex items-center justify-center min-h-screen">
                              <LoaderIcon />
                            </div>
                          }
                        >
                          <BrandSetting />
                        </Suspense>
                      }
                    />
                    <Route
                      path="color-theme"
                      element={<CustomColor />}
                    />
                    <Route
                      path="domain-integration"
                      element={<DomainIntegration />}
                    />
                    <Route
                      path="student-parent-signup"
                      element={<CustomSignup />}
                    />
                  </Route>
                  <Route path="announcements" element={<Announcements />} />

                  <Route
                    path="manage-subscription"
                    element={<ManageSubscriptionSettings />}
                  />
                  <Route path="payment-methods" element={<PaymentMethods />} />
                </>
              ) : (
                <></>
              )}
            </Route>
            <Route
              path="/assigned-students"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <AssignedStudents />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/all-tests/start-section/:id/:assignedTestId"
              element={
                <RequireAuth isLoggedIn={isLoggedIn}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <StartTest />
                  </Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="/ll"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AnnotatorComponent />
                </Suspense>
              }
            />
            <Route
              path="/adminDashboard"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Dashboard />
                </Suspense>
              }
            ></Route>
            <Route
              path="/adminContent"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AdminContent />
                </Suspense>
              }
            ></Route>
            <Route
              path="manage-subscription"
              element={
                <Suspense
                  fallback={
                    <div className="flex w-full items-center justify-center">
                      <LoaderIcon />
                    </div>
                  }
                >
                  <ManageSubscription />
                </Suspense>
              }
            ></Route>
            <Route
              path="test-route"
              element={
                <div className="h-[100vh] w-full flex flex-col">
                  <GlobalTestingComponent />
                </div>
              }
            ></Route>
          </Routes>
        </Layout2>
        {/* <Footer /> */}
        {/* </BrowserRouter> */}
        {/* {isSubscriptionAndExtensionModalActive && isOrgAdmin ? (
          <div
            className="fixed bg-[#00000080] top-0 hide-scroll overflow-y-auto bottom-0 w-full flex items-center justify-center z-[10000000] "
            style={{ position: "fixed" }}
          >
            <SubscriptionAndExtensionModal
              className="relative top-[50px] w-[1400px] h-[900px]"
              updateSubscriptionMode={
                isSubscriptionAndExtensionModalInUpateMode
              }
              renewProductMode={
                isSubscriptionAndExtensionModalInRenewProductMode
              }
              openSubscriptionModal={openSubscriptionPanel}
              openExtensionsModal={openExtensionsPanel}
              activeSubscriptionName={activeSubscriptionInfo?.planName}
              activeExtensionPackageName={activeExtensionInfo?.packageName}
              OnSubscriptionAddedSuccessfully={() => {
                // SetIsSubscriptionAndExtensionModalActive(false);
                dispatch(triggerSubscriptionUpdate());
                dispatch(closeSubscriptionAndExtensionModal());
              }}
              OnCancelClicked={() => {
                // SetIsSubscriptionAndExtensionModalActive(false);
                dispatch(closeSubscriptionAndExtensionModal());
              }}
            />
          </div>
        ) : (
          <></>
        )} */}
      </>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Stage />}>
          <Route path="signup/*" element={<OrgSignupV2 />}></Route>
          <Route path="signin/*" element={<LoginV2 />}></Route>
          <Route path="test-route" element={<AccountPage />}></Route>
        </Route>
      </Routes>
    );
  }
};

export default AppRoutes;
