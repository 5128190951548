import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import CopyIconCal from "../../assets/calendarV2/CopyIconCal"
import { getColourMappingForServices } from "../../utils/serviceColours"
import { EvChevronDownIcon, EvChevronUpIcon } from '../../assets/icons/ChevronIcons';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { toast } from 'react-toastify';

const iconStyles = { height: "16px", width: "16px" }

function ServicesSelectionSection({ service, topic, operationMode, setService, setTopic, whiteboardLink, setWhiteboardLink, setActive, placeholderService, placeholderTopic, showWhiteboardLink = true , user}) {

	const { servicesAndSpecialization } = useSelector((state) => state.organization.organization.settings)
	const [dropdownStates, setDropdownStates] = useState({
		serviceOpen: false,
		topicOpen: false
	})
	const [isTextFieldActive, setIsTextFieldActive] = useState(false)

	//To Enable or disable editing based on the role
	const isEnabledEditing = useMemo(()=>{
		return ["admin","tutor"].includes(user?.role)
	},[user?.role])



	useEffect(()=>{
		if(isEnabledEditing === true && operationMode &&  ["create-requested", "create"].includes(operationMode) &&  user?.userDefaults?.service !== "" ) setService(user?.userDefaults?.service);
		if(isEnabledEditing === true &&  operationMode && ["create-requested", "create"].includes(operationMode) && user?.userDefaults?.topic !== "" ) setTopic(user?.userDefaults?.topic);
	
	  },[user,isEnabledEditing])


	const styles = {
		outerContainer: "relative rounded-[6px] bg-[#F6F9FA] cursor-pointer px-[12px] py-[7.5px] flex items-center justify-between text-secondary-60",
		inputField: "text-[14px] leading-[21px] placeholder:text-secondary-60 placeholder:font-[400] text-secondary-80 font-[500] bg-transparent w-[calc(100%-28px)] focus:outline-none",
		dropdownButton: "flex items-center justify-start gap-[6px] font-[lexend] text-[14px] leading-[18px] text-secondary py-[7px] px-[8px] hover:bg-secondary-5 w-full",
		selectedIndicator: "flex items-center justify-start gap-[6px] font-[lexend] text-[14px] leading-[18px] text-secondary w-full",
		dropdownMenuContainer: 'absolute  max-h-[180px] h-fit overflow-y-auto bg-white flex flex-col gap-[4px] items-start justify-start w-full rounded-[6px] shadow-[0px_4px_18px_0px_#11167D1A] py-[4px] top-[110%] left-0 z-[5]'
	}

	const serviceOpenRef = useRef();
	const topicOpenRef = useRef();
	useOutsideAlerter(serviceOpenRef, () => setDropdownStates((prev) => ({ ...prev, serviceOpen: false })));
	useOutsideAlerter(topicOpenRef, () => setDropdownStates((prev) => ({ ...prev, topicOpen: false })));



	const serviceColorMapping = useMemo(() => {
		return getColourMappingForServices(servicesAndSpecialization)
	}, [servicesAndSpecialization])

	useEffect(() => {
		if (service) {
			setActive && setActive(true)
			return
		}
		setActive && setActive(isTextFieldActive || dropdownStates.topicOpen || dropdownStates.serviceOpen)
	}, [dropdownStates, isTextFieldActive, service])

	return (
		<div className='max-w-full grid grid-rows-[repeat(2,auto)] grid-cols-[repeat(2,calc((100%-16px)/2))] gap-y-[12px] gap-x-[16px] items-center'>
			<div className={`${styles.outerContainer}`} ref={serviceOpenRef} style={{pointerEvents : isEnabledEditing ? "" : "none" }}>
				<div className='  w-full flex items-center justify-between  ' onClick={() => setDropdownStates((prev) => ({ ...prev, serviceOpen: !prev.serviceOpen }))}>

					{
						service ?
							<div className={`${styles.selectedIndicator}`}>
								<span className='min-w-[14px] min-h-[14px] rounded-[50%]' style={{ backgroundColor: serviceColorMapping[service] }}></span>
								<span>{service}</span>
							</div>
							:
							<span>{placeholderService ?? "Select Service*"}</span>
					}
					<button className={`${service !== "" ? "text-secondary-80" : "text-secondary-60"}`} style={{display : isEnabledEditing ?  "" : "none"}}>
						{
							dropdownStates.serviceOpen ? <EvChevronUpIcon inherit={true} /> : <EvChevronDownIcon inherit={true} />
						}
					</button>
				</div>
				{
					dropdownStates.serviceOpen && <div className={styles.dropdownMenuContainer}>
						{
							servicesAndSpecialization?.map(({ service: _service, items }, idx) => (
								<button className={`${styles.dropdownButton}`}
									onClick={() => {
										setService(_service);
										setDropdownStates((prev) => ({ ...prev, serviceOpen: false }));
									}}>
									<span className='min-w-[14px] min-h-[14px] rounded-[50%]' style={{ backgroundColor: serviceColorMapping[_service] }}></span>
									<span>{_service}</span>
								</button>
							))
						}
					</div>
				}
			</div>
			<div className={`${styles.outerContainer}`} ref={topicOpenRef} style={{pointerEvents : isEnabledEditing ? "" : "none" }}>
				<div className='w-full flex items-center justify-between ' onClick={() => setDropdownStates((prev) => ({ ...prev, topicOpen: service && !prev.topicOpen }))} >
					{
						topic ?
							<div className={`${styles.selectedIndicator}`}>
								<span>{topic}</span>
							</div>
							:
							<span>{placeholderTopic ?? "Select Topic"}</span>
					}
					<button className={`${topic !== "" ? "text-secondary-80" : "text-secondary-60"}`} style={{display : isEnabledEditing ?  "" : "none"}}>
						{
							dropdownStates.topicOpen ? <EvChevronUpIcon inherit={true} /> : <EvChevronDownIcon inherit={true} />}
					</button>

				</div>
				{
					dropdownStates.topicOpen && <div className={styles.dropdownMenuContainer}>
						{
							servicesAndSpecialization?.find((val, idx) => val.service.toLowerCase() === service?.toLowerCase()).specialization.map((item, idx) => (
								<button
									className={`${styles.dropdownButton}`}
									onClick={() => {
										setTopic(item);
										setDropdownStates((prev) => ({ ...prev, topicOpen: false }));
									}}>{item}</button>
							))
						}
					</div>
				}
			</div>
			{
				showWhiteboardLink &&
				<div className={`${styles.outerContainer} col-span-2`}>
					<input type="text" className={`${styles.inputField}`} placeholder="Whiteboard Link" value={whiteboardLink} onChange={(e) => setWhiteboardLink(e.target.value)} onFocus={() => setIsTextFieldActive(true)} onBlur={() => setIsTextFieldActive(false)} disabled={!isEnabledEditing}/>
					<button className='hover:bg-secondary-5' onClick={() => { if (!whiteboardLink) { return; }; navigator.clipboard.writeText(whiteboardLink); toast.success("Whiteboard link copied to clipboard !!", { autoClose: 3000 }) }}><CopyIconCal /></button>
				</div>
			}
		</div>
	)

}


export default ServicesSelectionSection
