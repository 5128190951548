const requiredProductMapping = {
  Calendar: { productName: "CalendarV2" },
  "Custom Tests": { productName: "CustomContentV2" },
  Assignments: { productName: "AssignmentsV2" },
};

function extractTier(lookupKey) {
  const matchedGroups = lookupKey?.match(/(Tier_[0-9])/);
  return matchedGroups[0];
}

function getConvertedPlanType(
  productPrices,
  selectedPlanType,
  selectedProducts
) {

  const correspondingProduct = {...selectedProducts};
  console.log(">>>CP", correspondingProduct);

  Object.keys(selectedProducts).forEach((key) => {
    if (!requiredProductMapping[key] || !selectedProducts[key].value) return;
    
    const prices = productPrices.find(
      (val, idx) => val.product.name === requiredProductMapping[key].productName
    ).prices[selectedPlanType === 0 ? "monthly" : "annual"];

    const pastPrices = productPrices.find(
      (val, idx) => val.product.name === requiredProductMapping[key].productName
    ).prices[selectedPlanType === 0 ? "annual" : "monthly"];

    console.log(">>>KEY",selectedProducts[key].value,key)
    console.log(">>CURRENT PRICES", prices)
    console.log(">>PAST PRICES", pastPrices)

    console.log(">>>LKP",pastPrices.find((val, idx) => val.id === selectedProducts[key].value)?.lookup_key)
    const currentTier = extractTier(
      pastPrices.find((val, idx) => val.id === selectedProducts[key].value)?.lookup_key
    );
    const correspondingPrice = prices.find((val, idx) =>
      val?.lookup_key?.includes(currentTier)
    );

    correspondingProduct[key] = {
      ...selectedProducts[key],
      value: correspondingPrice.id,
      price: correspondingPrice.unit_amount / 100,
    };
  });

  return correspondingProduct;
}

export default getConvertedPlanType;
