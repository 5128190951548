import styles from './AccountingSection.module.css'


/*
item format ->{
    mainText : ,
    amountText : ,
    isTotalPrice : ??,
    isDiscount : ??,
}
*/
const AccountingSection = ({items}) => {
    return(
        <div className={styles.container}>
        {
            items.map((item,idx)=>(
                <>
                <div className={`${styles.mainText} ${item.isDiscount ? styles.discountView: ""}`}>{item.mainText}</div>
                <div className={`${styles.amountText} ${item.isDiscount ? styles.discountView: ""} ${item.isFinalPrice ? styles.finalPriceView: ""}`}>{item.amountText}</div>
                </>
            ))
        }
        </div>
    )
}

export default AccountingSection