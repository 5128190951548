import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ReferralCode from "../../Referrals/ReferralCode";
import MobileViewReferrals from "../../../components/MobileReferral/MobileViewReferral";

const Referral = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("isMobile", isMobile);
  return <div>{!isMobile ? <ReferralCode /> : <MobileViewReferrals />}</div>;
};

export default Referral;
