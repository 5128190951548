import frozenEventStatus from "../../../utils/eventStatus";
import frozenEventTypes from "../../../utils/eventTypes"
import ConsultationWhite from "../../../assets/calendarV2/ConsultationWhite.svg";
import ConsultationWhiteSmall from "../../../assets/calendarV2/ConsultationWhiteSmall.svg";
import officeWorkWhite from "../../../assets/calendarV2/officeWorkWhite.svg";
import officeWorkWhiteSmall from "../../../assets/calendarV2/officeWorkWhiteSmall.svg";
import InternalMeetingWhite from "../../../assets/calendarV2/InternalMeetingWhite.svg";
import InternalMeetingWhiteSmall from "../../../assets/calendarV2/InternalMeetingWhiteSmall.svg";
import ExternalMeetingWhite from "../../../assets/calendarV2/ExternalMeetingWhite.svg";
import ExternalMeetingWhiteSmall from "../../../assets/calendarV2/ExternalMeetingWhiteSmall.svg";

//This function reflects the whole session's status for the user who is viewing
function getStatusV2(eventType, extendedProps, eventData, user){
	let tempStatus = null
	switch(eventType.toLowerCase()){
		case frozenEventTypes.SESSION.eventType.toLowerCase():
			tempStatus = extendedProps.status;
			break;
		case frozenEventTypes.CONSULTATION.eventType.toLowerCase():
		case frozenEventTypes.INTMEETING.eventType.toLowerCase():
		case frozenEventTypes.OFFCHOURS.eventType.toLowerCase():
		case frozenEventTypes.OFFCWORK.eventType.toLowerCase():
		case frozenEventTypes.EXTMEETING.eventType.toLowerCase():
			if(extendedProps.isEventRequest){
				tempStatus = extendedProps.status
				break;
			}
			const startTS = (new Date(eventData.start)).getTime()
			const endTS = (new Date(eventData.end)).getTime()
			const todayTS = (new Date()).getTime()
			if(endTS < todayTS){
				tempStatus = frozenEventStatus.COMPLETED.status
			} else {
				tempStatus = frozenEventStatus.SCH.status
			}
			break;
		default : 
			tempStatus = extendedProps.status
	}
	return tempStatus
}


//This function reflects the individual student's status for the whole session if the student or the mapped parent is viewing the same
function getStatus(eventType, extendedProps, eventData, user){
	let tempStatus = null
	switch (eventType){
		case frozenEventTypes.SESSION.eventType :
			if(user?.role === "admin" || user?.role === "tutor")
				tempStatus = extendedProps.status;
			else if(user?.role === "student"){
				if(extendedProps.__t){
					const studentStatusMapping = extendedProps.studentStatusMapping.find(val=>val.id === user.id);
					tempStatus = (studentStatusMapping && studentStatusMapping?.status === "none")	? extendedProps.status : studentStatusMapping?.status
				} else {
					tempStatus = extendedProps.status
				}
			}
			else if(user?.role === "parent"){
				if(extendedProps.__t){
					const studentStatusMapping = extendedProps.studentStatusMapping.find(val=>user?.associatedStudents?.includes(val.id));
					tempStatus = studentStatusMapping?.status === "none"	? extendedProps.status : studentStatusMapping?.status
				} else {
					tempStatus = extendedProps.status
				}
			}
			break;
		case frozenEventTypes.CONSULTATION.eventType : 
			if(extendedProps.isEventRequest){
				tempStatus = extendedProps.status
				break;
			}else if((user?.role === "admin" || user?.role === "tutor")){
				const startTS = (new Date(eventData.start)).getTime()
				const endTS = (new Date(eventData.end)).getTime()
				const todayTS = (new Date()).getTime()
				if(endTS < todayTS){
					tempStatus = frozenEventStatus.COMPLETED.status
				} else {
					tempStatus = frozenEventStatus.SCH.status
				}
				break;
			} else if(user?.role === "student" || user?.role === "parent"){
				const requiredAttendeeDetail = extendedProps.attendeeDetails.find(val=> ( (val.id === user?.id) || user?.associatedStudents?.includes(val.id)))
				if(requiredAttendeeDetail?.status === "cancelled"){
					tempStatus = frozenEventStatus.CANCEL.status
				}else {
					const startTS = (new Date(eventData.start)).getTime()
					const endTS = (new Date(eventData.end)).getTime()
					const todayTS = (new Date()).getTime()
					if(endTS < todayTS){
						tempStatus = frozenEventStatus.COMPLETED.status
					} else {
						tempStatus = frozenEventStatus.SCH.status
					}
				}
			}
			break;
		case "internalMeeting" : 
			if(extendedProps.isEventRequest){
				tempStatus = extendedProps.status
				break;
			}else if((user?.role === "admin" || user?.role === "tutor")){
				const startTS = (new Date(eventData.start)).getTime()
				const endTS = (new Date(eventData.end)).getTime()
				const todayTS = (new Date()).getTime()
				if(endTS < todayTS){
					tempStatus = frozenEventStatus.COMPLETED.status
				} else {
					tempStatus = frozenEventStatus.SCH.status
				}
				break;
			} else if(user?.role === "student" || user?.role === "parent"){
				const requiredAttendeeDetail = extendedProps.attendeeDetails.find(val=> ( (val.id === user?.id) || user?.associatedStudents?.includes(val.id)))
				if(requiredAttendeeDetail?.status === "cancelled"){
					tempStatus = frozenEventStatus.CANCEL.status
				}else {
					const startTS = (new Date(eventData.start)).getTime()
					const endTS = (new Date(eventData.end)).getTime()
					const todayTS = (new Date()).getTime()
					if(endTS < todayTS){
						tempStatus = frozenEventStatus.COMPLETED.status
					} else {
						tempStatus = frozenEventStatus.SCH.status
					}
				}
			}
			break;
		default : 
			tempStatus = extendedProps.status 
			break;
	}

	return tempStatus
}


  function getIconForEventType (eventType) {
    switch (eventType.toLowerCase()) {
      case frozenEventTypes.OFFCWORK.eventType.toLowerCase():
        return {
          icon: officeWorkWhite,
          bgColor: "#9BCA7E",
          iconSmall: officeWorkWhiteSmall,
        };
      case frozenEventTypes.CONSULTATION.eventType.toLowerCase():
        return {
          icon: ConsultationWhite,
          bgColor: "#559B9B",
          iconSmall: ConsultationWhiteSmall,
        };
      case frozenEventTypes.INTMEETING.eventType.toLowerCase():
        return {
          icon: InternalMeetingWhite,
          bgColor: "#82848B",
          iconSmall: InternalMeetingWhiteSmall,
        };
      case frozenEventTypes.EXTMEETING.eventType.toLowerCase():
        return {
          icon: ExternalMeetingWhite,
          bgColor: "#BB7ECA",
          iconSmall: ExternalMeetingWhiteSmall,
        };
      default:
        return { icon: null, bgColor: null };
    }
  };

export {
	getStatus,
	getIconForEventType,
	getStatusV2
}
