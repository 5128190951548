import React, { useEffect, useState } from 'react'
import EventSidebar from '../EventSidebar/EventSidebar'
import IconCompBinding from '../IconCompBinding/IconCompBinding'
import ClockIcon from "../CalendarSideIcons/ClockIcon";
import LocationIcon from "../CalendarSideIcons/LocationIcon";
import VidCamIcon from "../CalendarSideIcons/VidCamIcon";
import PersonIcon from "../CalendarSideIcons/PersonIcon";
import InfoIcon2 from "../CalendarSideIcons/InfoIcon2";
import ReminderBellIcon from "../CalendarSideIcons/ReminderBellIcon";
import ParagraphIcon from "../CalendarSideIcons/ParagraphIcon";
import TimeZoneSelector from '../TimeZoneSelector/TimeZoneSelector';
import ServicesSelectionSection from '../ServicesSelectionSection/ServicesSelectionSection';
import LocationSelection from '../LocationSelection/LocationSelection';
import MeetingLinkSelector from '../MeetingLinkSelector/MeetingLinkSelector';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetPersonalDetailQuery, useUpdateUserAccountMutation } from '../../app/services/users';
import { updateUserDetails } from '../../app/slices/user';
import { triggerToast } from '../../utils/toastController';
import { Link } from 'react-router-dom';
import LoaderIcon from '../LoaderIcon/LoaderIcon';


const CalendarSetting = (props) => {

    const user = useSelector((state) => state.user);
	const dispatch = useDispatch()
    const [userDefaults, setUserDefault] = useState({})
    const [loading, setLoading] = useState(false)
    const [userDetails, userDetailsStatus] = useLazyGetPersonalDetailQuery();
    const [updateAccount, updateAccountStatus] = useUpdateUserAccountMutation();
	const [count, setCount] = useState(0)
	const [fetchPersonalDetails,] = useLazyGetPersonalDetailQuery()

    function fetchAndSetPersonalDetails(mode) {

        fetchPersonalDetails()
            .then((res) => {
                if (!res.error) {
                    console.log("RRRED", res)
                    if (mode === "user") {
                        console.log("0900")
                        const {
                            firstName,
                            lastName,
                            _id,
                            amountToPay,
                            credits,
                            role,
                            email,
                            phone,
                            phoneCode,
                            about,
                            associatedOrg,
                            photo,
                            lastUpdateDatePassword,
                            scheduleDetails,
							userDefaults
                        } = res.data.data.user;

                        dispatch(
                            updateUserDetails({
                                firstName,
                                lastName,
                                id: _id,
                                amountToPay,
                                credits,
                                role,
                                photo,
                                timeZone: res.data?.data?.details?.timeZone
                                    ? res.data?.data?.details?.timeZone
                                    : "",
                                email,
                                phone,
                                phoneCode,
                                associatedOrg,
                                about: about ? about : "",
                                scheduleDetails,
								userDefaults
                            })
                        )
                        setTempUserDetails({
                            firstName,
                            lastName,
                            id: _id,
                            amountToPay,
                            credits,
                            role,
                            photo,
                            timeZone: res.data?.data?.details?.timeZone
                                ? res.data?.data?.details?.timeZone
                                : "",
                            email,
                            phone,
                            phoneCode,
                            associatedOrg,
                            about: about ? about : "",
                            scheduleDetails
                        })

                        return

                    }
                    updateArbitraryField({
                        lastUpdateDatePassword: res.data.data.user.lastUpdateDatePassword,
                    })
                    setUpdatedPasswordDate(res.data.data.user.lastUpdateDatePassword)
                }
            })
    }
	useEffect(()=>{setCount(1)},[])

    useEffect(() => {
        console.log(user)
        setUserDefault(user.userDefaults)
        // console.log(userDefaults)
    }, [])


    useEffect(() => {
        console.log(userDefaults)
        // console.log(userDefaults)
    }, [userDefaults])


    const personaEvents = {
        admin: [
            {
                component: TimeZoneSelector,
                icon: ClockIcon,
                props: {
                    timeZone: userDefaults.timeZone,
                    setTimeZone: (newTimeZone) => { setUserDefault((prev) => ({ ...prev, timeZone: newTimeZone })) },
                    user:user,
                    origin:"setting",
                }
            },
            {
                component: ServicesSelectionSection,
                icon: ParagraphIcon,
                props: {
                    showWhiteboardLink: false,
                    placeholderService: "Default Service",
                    placeholderTopic: "Default Topic",
                    service: userDefaults.service,
                    setService: (newService) => { setUserDefault((prev) => ({ ...prev, service: newService, topic : "" })) },
                    topic: userDefaults.topic,
                    setTopic: (newTopic) => { setUserDefault((prev) => ({ ...prev, topic: newTopic })) },
                    user:user,
                }
            },
            {
                component: LocationSelection,
                icon: LocationIcon,
                props: {
                    location: userDefaults.location,
                    setLocation: (newLocation) => { setUserDefault((prev) => ({ ...prev, location: newLocation })) },
                    user:user,
                }
            },
            {
                component: MeetingLinkSelector,
                icon: VidCamIcon,
                props: {
                    hideButtons: true,
                    placeholder: "Default Meeting Link",
                    meetingLink: userDefaults.meetingLink,
                    setMeetingLink: (newMLink) => { setUserDefault((prev) => ({ ...prev, meetingLink: newMLink })) },
                    user:user,
                }
            },
        ],
        tutor: [
            {
                component: TimeZoneSelector,
                icon: ClockIcon,
                props: {
                    timeZone: userDefaults.timeZone,
                    setTimeZone: (newTimeZone) => { setUserDefault((prev) => ({ ...prev, timeZone: newTimeZone })) },
                    user:user,
                    origin:"setting",
                }
            },
            {
                component: ServicesSelectionSection,
                icon: ParagraphIcon,
                props: {
                    showWhiteboardLink: false,
                    placeholderService: "Default Service",
                    placeholderTopic: "Default Topic",
                    service: userDefaults.service,
                    setService: (newService) => { setUserDefault((prev) => ({ ...prev, service: newService })) },
                    topic: userDefaults.topic,
                    setTopic: (newTopic) => { setUserDefault((prev) => ({ ...prev, topic: newTopic })) },
                    user:user,
                }
            },
            {
                component: LocationSelection,
                icon: LocationIcon,
                props: {
                    location: userDefaults.location,
                    setLocation: (newLocation) => { setUserDefault((prev) => ({ ...prev, location: newLocation })) },
                    user:user,
                    
                }
            },
            {
                component: MeetingLinkSelector,
                icon: VidCamIcon,
                props: {
                    hideButtons: true,
                    meetingLink: userDefaults.meetingLink,
                    setMeetingLink: (newMLink) => { setUserDefault((prev) => ({ ...prev, meetingLink: newMLink })) },
                    user:user,
                }
            },
        ],
        student: [
            {
                component: TimeZoneSelector,
                icon: ClockIcon,
                props: {
                    timeZone: userDefaults.timeZone,
                    setTimeZone: (newTimeZone) => { setUserDefault((prev) => ({ ...prev, timeZone: newTimeZone })) },
                    origin:"setting",
                }
            },
            {
                component: LocationSelection,
                icon: LocationIcon,
                props: {
                    location: userDefaults.location,
                    setLocation: (newLocation) => { setUserDefault((prev) => ({ ...prev, location: newLocation })) },
                    origin:"setting",
                }
            },
        ],
        parent: [
            {
                component: TimeZoneSelector,
                icon: ClockIcon,
                props: {
                    timeZone: userDefaults.timeZone,
                    setTimeZone: (newTimeZone) => { setUserDefault((prev) => ({ ...prev, timeZone: newTimeZone })) },
                    origin:"setting",
                }
            },
            {
                component: LocationSelection,
                icon: LocationIcon,
                props: {
                    location: userDefaults.location,
                    setLocation: (newLocation) => { setUserDefault((prev) => ({ ...prev, location: newLocation })) },
                    origin:"setting",
                }
            },
        ]
    }

    const updateUserAccount = async () => {
        setLoading(true)
        try {
            let userDefaultsData = { ...userDefaults };
            let reqBody = { userDefaults: userDefaultsData }
            console.log(reqBody)
            reqBody.onSaveButtonClicked = true
            delete reqBody["_id"];
            delete reqBody["email"];
            updateAccount(reqBody)
                .then((res) => {
                    setLoading(false);
                    if (res?.error) {
                        triggerToast("Somthing went wrong!", "error", { autoClose: 3000 });
                    }
                    if (res?.data) {
                        triggerToast("Info updated successfully", "success", { autoClose: 3000 });
                    }
                    console.log(res);
                    fetchAndSetPersonalDetails("user")
					props.setActive(false)

                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err?.message);
                });
            triggerToast("Settings updated successfully", "success", { autoClose: 3000 });
            // props.setActive(false)
            // updateUserDetails({ id: user.id, fields: { about: tempUserDetails.about } })
        } catch (e) {
            console.error(e?.response?.data?.message);
            triggerToast(e?.response?.data?.message, "error", { autoClose: 3000 });
        }
    };


    console.log(props.persona)


    return (
        <div className='md:w-[540px] w-fit'>

            <div className='flex items-start flex-col gap-10'>
                {
                    personaEvents[props.persona].map((el, i) => {
                        console.log(props)
                        return <IconCompBinding
                            icon={el.icon}
                            component={el.component}
                            _props={el.props}
                        />
                    })
                }
            </div>
            <div className='flex flex-col md:flex-row justify-center md:justify-between gap-9 items-center md:pt-4 pt-9 md:border-t md:mt-6 border-secondary-10 '>
                <Link to={"/settings"}><button className='text-[14px] leading-[14px] font-normal text-blue-70' >Go to Advanced Settings</button></Link>
                <button className='bg-blue-70 px-5 py-[10px] rounded-md text-white text-[16px] leading-[20px] font-medium' onClick={() => updateUserAccount()}>{loading ? <LoaderIcon /> : "Save"}</button>
            </div>
        </div>
    )
}

export default CalendarSetting
