import { useEffect, useState } from "react";
import TabSelector from "../../../components/TabSelector/TabSelector";
import SelectedTab from "./components/SelectedTab";
import { useSearchParams } from "react-router-dom";
import { triggerUpdateSubscriptionPopUp } from "../../../utils/subscriptionPopUpService";

const tabMapping = ["Subscription", "Usage Metrics", "Tutor Licenses"];

const ManageSubscriptionSettings = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabSelectionClick = (idx) => {
    setSearchParams({
      tab: idx,
    });
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      const tab = parseInt(searchParams.get("tab"));
      setSelectedTab(tab);
    } else {
      setSearchParams({
        tab: 0,
      });
    }
  }, [searchParams]);

  return (
    <div className="w-[100%] h-[100%] flex flex-col gap-[24px] py-[32px] px-[16px] md:px-[36px]">
      <div className="text-secondary-30 flex items-center gap-[6px]">
        <div className="flex justify-between items-center w-full">
          <div className="text-secondary text-[20px] font-[600] w-fit text-left">
            Your Evallo Subscription
          </div>
          <div
            className={` hidden md:flex bg-blue-70 text-white cursor-pointer w-fit  text-center px-[20px] py-[10px]  gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
            onClick={() => {
              triggerUpdateSubscriptionPopUp();
            }}
          >
            Manage Subscription
          </div>
        </div>
      </div>
      <TabSelector
        {...{
          items: tabMapping,
          selectedIdx: selectedTab,
          onClick: handleTabSelectionClick,
        }}
      />
      <SelectedTab
        selectedTab={selectedTab}
        setSearchParams={setSearchParams}
      />
    </div>
  );
};

export default ManageSubscriptionSettings;
