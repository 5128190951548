import React from "react";
import styles from "./SliderSelectV2.module.css";

const SliderSelectV2 = ({ min, max, value, setValue, disabled }) => {
  const handleChange = (event) => {
    setValue(parseInt(event.target.value));
  };

  // Calculate the size of each slot
  const slotSize = (max - min) / 3;

  // Determine the slot for the current value
  let slot;
  if (value <= min + slotSize) {
    slot = 1;
  } else if (value >= max - slotSize) {
    slot = 3;
  } else {
    slot = 2;
  }

  // Calculate the left offset for the thumb number based on the slot
  let thumbNumLeftOffset;
  if (slot === 1) {
    thumbNumLeftOffset = "5px";
  } else if (slot === 2) {
    thumbNumLeftOffset = 0;
  } else {
    thumbNumLeftOffset = "-5px";
  }

  const leftPosition =
    value === min
      ? 0
      : value === max
      ? "calc(100% - 20px)"
      : `${((value - min) / (max - min)) * 100}%`;

  const trackColor = disabled ? "#BEC2CE" : "#0671E0";
  const thumbColor = disabled ? "text-gray-400" : "text-blue-70";

  const trackStyle = {
    background: `linear-gradient(to right, ${trackColor} 0%, ${trackColor} ${
      ((value - min) / (max - min)) * 100
    }%, #BEC2CE ${((value - min) / (max - min)) * 100}%, #BEC2CE 100%)`,
  };

  const minColor = disabled
    ? "text-gray-400"
    : value === min
    ? "text-blue-500"
    : "text-gray-400";
  const maxColor = disabled
    ? "text-gray-400"
    : value === max
    ? "text-blue-500"
    : "text-gray-400";

  return (
    <div className="relative flex items-center mt-4">
      <span className={`absolute left-0 top-2 ${minColor}`}>{min}</span>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        className={`${
          styles.sliderThumb
        } w-full h-px rounded-full outline-none ${
          disabled ? styles.disabled : ""
        }`}
        style={{
          ...trackStyle,
          transition: "all 0.2s ease",
        }}
      />
      <span className={`absolute right-0 top-2 ${maxColor}`}>{max}</span>

      {value !== min && value !== max && value <= 30 && (
        <span
          className={`thumb-num absolute top-7 transform -translate-x-1/2 -translate-y-full ${thumbColor}`}
          style={{ left: leftPosition, marginLeft: thumbNumLeftOffset }}
        >
          {value}
        </span>
      )}
    </div>
  );
};

export default SliderSelectV2;
