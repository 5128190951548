import React from 'react'
import CalendarIcon from './CalendarIcon'
import PriceListCard from '../PriceListCard/PriceListCard'
import FeatureModule from '../FeatureModule/FeatureModule'

const ServiceModule = ({ serviceIcon, serviceTitle, serviceHelper, featureList, serviceName, monthlyPlan, annualPlan, monthlyPayg,idx }) => {
    console.log(">>>SM", serviceName)

    return (

        <div className='flex justify-center items-center m-[0px] p-[0px]'>
            <div className='w-[29vw] 2.5xl:w-[418px] p-[calc(100vw*(48/1440))] 2.5xl:p-[48px] rounded-[calc(100vw*(16/1440))] 2.5xl:rounded-[16px] bg-[#F8FBFD]'>
                <div className=''>
                    <div className='flex flex-col items-start justify-start gap-[12px]'>
                        <div className='flex flex-row  gap-[calc(100vw*15/1440)] 2xl:gap-[15px] items-center justify-start'>
                            {serviceIcon}
                            <h1 className="text-[calc(100vw*(32/1440))] 2.5xl:text-[32px] font-semibold leading-[calc(100vw*(40/1440))] 2.5xl:leading-[40px] text-[#26435F]">{serviceTitle}</h1>
                        </div>
                        <div className='text-secondary-80 text-[calc(100vw*18/1440)] 2xl:text-[18px] w-full text-left'>{serviceHelper}</div>
                    </div>
                    <div className='mt-[32px]'>
                        {/* <FeatureModule featureList={featureList} /> */}
                        {/*                    Pricing ========================== */}
                        <PriceListCard annualPlan={annualPlan} monthlyPlan={monthlyPlan} monthlyPayg={monthlyPayg} serviceName={serviceName} idx={idx}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceModule