import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  id: "",
  firstName: "",
  lastName: "",
  credits: 0,
  amountToPay: 0,
  timeZone: "",
  role: "",
  email: "",
  phone: "",
  awsLink: "",
  associatedOrg: "",
  selectedStudent: {},
  associatedStudents: [],
  fetchData: false,
  lastUpdateDatePassword: null,
  userDefaults: {
    timeZone: "",
    service: "",
    topic: "",
    location: "",
    meetingLink: ""
  },
  showCalAlert : false
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    updateTimeZone: (state, { payload }) => {
      state.timeZone = payload.timeZone;
    },
    updateAwsLink: (state, { payload }) => {
      state.awsLink = payload;
    },
    updateUserDetails: (state, { payload }) => {
      console.log("UPDATING 0900", payload);
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.id = payload.id;
      state.amountToPay = payload.amountToPay;
      state.credits = payload.credits;
      state.timeZone = payload.timeZone ? payload.timeZone : "";
      state.role = payload.role;
      state.email = payload.email;
      state.phone = payload.phone;
      state.phoneCode = payload.phoneCode;
      state.associatedOrg = payload.associatedOrg;
      // state.dateFormat = payload.dateFormat;
      state.photo = payload.photo;
      state.lastUpdateDatePassword = payload.lastUpdateDatePassword;
      state.company = payload.company;
      state.scheduleDetails = payload.scheduleDetails;
      state.associatedStudents = payload.associatedStudents;
      state.userDefaults = {
        timeZone: payload.userDefaults?.timeZone || "",
        service: payload.userDefaults?.service || "",
        topic: payload.userDefaults?.topic || "",
        location: payload.userDefaults?.location || "",
        meetingLink: payload.userDefaults?.meetingLink || ""
      };
	  state.showCalAlert = true
    },
    updateSelectedStudent: (state, { payload }) => {
      state.selectedStudent = payload;
    },
    updatePhoto: (state, { payload }) => {
      state.photo = payload;
    },
    updateDateFormat: (state, { payload }) => {
      state.dateFormat = payload.dateFormat;
    },
    updateFetchData: (state, { payload }) => {
      state.fetchData = payload;
    },
    updateArbitraryField: (state, { payload }) => {
      Object.keys(payload).forEach((key, idx) => {
        state[key] = payload[key];
      });
    },
  },
});

export const {
  updateIsLoggedIn,
  updateUserDetails,
  updateTimeZone,
  updateAwsLink,
  updateSelectedStudent,
  updatePhoto,
  updateDateFormat,
  updateFetchData,
  updateArbitraryField,
} = user.actions;
export default user.reducer;
