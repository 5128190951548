const ReceiptIcon = (props) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0924 29.55C11.3224 28.23 13.1974 28.335 14.2774 29.775L15.7925 31.8C17.0075 33.405 18.9725 33.405 20.1875 31.8L21.7025 29.775C22.7825 28.335 24.6575 28.23 25.8875 29.55C28.5575 32.4 30.7325 31.455 30.7325 27.465V10.56C30.7325 4.515 29.3225 3 23.6525 3H12.3124C6.64242 3 5.23242 4.515 5.23242 10.56V27.45C5.24742 31.455 7.43741 32.385 10.0924 29.55Z" stroke="#25335A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.875 15H22.125" stroke="#25335A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default ReceiptIcon