import CrossIcon from "../../../assets/icons/SettingsIcon/CrossIcon"
import LoaderIcon from "../../LoaderIcon/LoaderIcon"
import PopupContainer from "../../PopupContainer/PopupContainer"
import styles from "./DeleteLeaveVacationPopUp.module.css"

function DeleteLeaveVacationPopUp ({trigger, setTrigger, onDeleteClick,  leaveDetails, isLoading}) {
	
	return (
		<PopupContainer {...{trigger, setTrigger}} customCloseButton={true}>
		<div className={styles.container}>
			<div className={styles.headingContainer}>
				<div>Delete Time Off</div>	
				<button onClick={(e)=>setTrigger(false)}><CrossIcon /></button>
			</div>
			<div className={styles.messageContainer}>
				<div className={styles.messageHeading}>Are you sure you want to delete this time off?</div>
				<div className={styles.messageDesc}>Note: This action cannot be reversed.</div>
			</div>
			<div className={styles.buttonContainer}>
					<button className={styles.cancelButton} onClick={(e)=>setTrigger(false)}>Back</button>
					<button className={styles.deleteButton} onClick={(e)=>onDeleteClick(leaveDetails._id, leaveDetails.targetUser.id)}>
						{ isLoading ? <LoaderIcon /> : "Delete" }
					</button>
			</div>
		</div>
		</PopupContainer>
	)


}
 export default DeleteLeaveVacationPopUp


