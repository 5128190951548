import React from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../components/ProductIcons/InfoIcon";
import TooltipIcon from "../../../assets/icons/SettingsIcon/TooltipIcon";

const InfoDisplayCard = ({ title, info, link, tooltipContent }) => {
  return (
    <div className="grid gap-[2px]">
      <label className="text-secondary text-[14px] leading-[21px] font-[500] flex gap-[6px] items-center">
        {title}
        {tooltipContent && (
          <span>
            <Tooltip
              anchorComponent={
                <div className="mt-[2px]">
                  <TooltipIcon height={14} width={14} />
                </div>
              }
              tooltipContent={tooltipContent}
              tooltipStyleClass="bg-white rounded p-[12px] leading-[18px] font-[400] text-[12px] w-[320px] -mt-[8px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"
              tooltipTriangleStyling="-mt-[1px] ml-[1px]"
            />
          </span>
        )}
      </label>
      <label className="text-secondary-80 text-[14px] leading-[21px] font-[400] overflow-hidden text-ellipsis whitespace-nowrap">
        {link ? (
          <a
            href={link}
            target="_blank"
            className="text-secondary-80 text-[14px] leading-[21px] font-[400]"
            style={{ textDecoration: "none" }}
          >
            {info}
          </a>
        ) : (
          info
        )}
      </label>
    </div>
  );
};

export default InfoDisplayCard;
