import React, { useState } from "react";
import styles from "./Weekly.module.css";
import "../../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import { generateDays } from "../../CalendarV2DateTime/CalendarDateTimeSelector/CalendarDateTimeSelector";

const Weekly = ({ selectedDay, handleDayClick }) => {
  const [selectedDayIndex, setSelectedDayIndex] = useState(null);

  const generateDays = (selectedDay, onDayClick) => {
    const daysOfWeek = [
      { id: 101, day: "S", shortDay : "Sun" },
      { id: 102, day: "M" , shortDay : "Mon" },
      { id: 103, day: "T" , shortDay : "Tue" },
      { id: 104, day: "W" , shortDay : "Wed" },
      { id: 105, day: "T" , shortDay : "Thu" },
      { id: 106, day: "F" , shortDay : "Fri" },
      { id: 107, day: "S" , shortDay : "Sat" },
    ];
    return daysOfWeek.map((day, index) => (
      <div
        key={day.id}
        className={`${styles.repeatDivWrapper} ${
          selectedDay.includes(daysOfWeek[index].shortDay) ? styles.selected : ""
        }`}
        onClick={() => onDayClick(index, daysOfWeek[index])}
      >
        <div className={styles.repeatTextWrapper5}>{day.day}</div>
      </div>
    ));
  };
  const selectedDays =
    selectedDayIndex !== null
      ? ["S", "M", "T", "W", "T", "F", "S"][selectedDayIndex]
      : null;
  return (
    <div className={styles.repeatDiv3}>
      <div className={styles.repeatTextWrapper}>Repeats on</div>
      <div className={styles.repeatDiv4}>
        {/* <div className={styles.repeatDivWrapper}>
          <div className={styles.repeatTextWrapper3}>S</div>
        </div>
        <div className={styles.repeatDivWrapper2}>
          <div className={styles.repeatTextWrapper4}>M</div>
        </div>
        <div className={styles.repeatDivWrapper}>
          <div className={styles.repeatTextWrapper5}>T</div>
        </div>
        <div className={styles.repeatDivWrapper}>
          <div className={styles.repeatTextWrapper6}>W</div>
        </div>
        <div className={styles.repeatDivWrapper}>
          <div className={styles.repeatTextWrapper5}>T</div>
        </div>
        <div className={styles.repeatDivWrapper}>
          <div className={styles.repeatTextWrapper7}>F</div>
        </div>
        <div className={styles.repeatDivWrapper}>
          <div className={styles.repeatTextWrapper7}>S</div>
        </div> */}
        {generateDays(selectedDay, handleDayClick)}
      </div>
    </div>
  );
};

export default Weekly;
