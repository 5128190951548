import axios from "axios";
import { getAuthHeader } from "../app/constants/constants";
import {
  updateSubscriptionV2,
  updateOldSubscriptionExists,
  updatePaymentMethods,
} from "../app/slices/subscription";
import { store } from "../app/store";
import organization from "../app/slices/organization";
import {
  fetchLookupKeyForId,
  fetchTutorPriceForPackage,
} from "../pages/ManageSubscription/Utils/fetchers";
import { updateInitialTeamSize } from "../app/slices/subscriptionOperation";
import { triggerToast } from "./toastController";
import { updateFetchData } from "../app/slices/user";
import { changeTutorStatus } from "./tutorService";

function getBasePackageTutorLimit(basePackageName) {
  switch (true) {
    case basePackageName.includes("Free"):
      return 1;
    case basePackageName.includes("Starter"):
      return 1;
    case basePackageName.includes("Professional"):
      return 10;
    case basePackageName.includes("Premium"):
      return 30;
  }
}

/**
 *
 * @param {*} selectedProducts
 * @param {*} basePackageId
 * @param {*} productPrices
 * @param {Enumerator} selectedPlanType ["annual","monthly"]
 */
function createSubscriptionItems(
  selectedProducts,
  basePackageId,
  productPrices,
  selectedPlanType,
  additionalTutorNos
) {
  const subscriptionItems = [];
  const basePackageLookupKey = fetchLookupKeyForId(
    productPrices,
    basePackageId,
    selectedPlanType,
    "BasePackageV2"
  );
  const basePackageName = basePackageLookupKey.split("_")[1];

  //Add the base package to subscription items
  subscriptionItems.push({
    value: basePackageId,
    selected: true,
    name: basePackageName,
  });

  //Add other products to subscription items except tutors
  Object.keys(selectedProducts).forEach((key, idx) => {
    if (key !== "CRM" && key !== "tutors") {
      const product = selectedProducts[key];
      subscriptionItems.push({
        value: product.value,
        selected: true,
        name: key,
      });
    }
  });

  //Add tutors to the products
  const requiredTutorPriceId = fetchTutorPriceForPackage(
    productPrices,
    selectedPlanType,
    basePackageName
  );
  subscriptionItems.push({
    value: requiredTutorPriceId.id,
    selected: true,
    name: "Tutors",
    quantity: additionalTutorNos,
  });

  return subscriptionItems;
}

async function checkForOldSubscriptions(organization) {
  try {
    const res = await axios({
      url:
        process.env.REACT_APP_BASE_URL +
        "api/stripe/subscriptions1" +
        "/" +
        organization.stripeCustomerId,
      method: "GET",
      headers: getAuthHeader(),
    });
    console.log("OLDSUBCHECK", res.data.data);
    if (res?.data?.data.length > 0) {
      store.dispatch(updateOldSubscriptionExists(true));
    }
    if (res?.data?.data.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log("ERR");
    console.log(err);
  }
}

async function fetchAndSetSubscription(organization, set=true) {
  console.log(
    ">>>SV_START",
    process.env.REACT_APP_BASE_URL + "api/orgs/subscription?siMapping=true"
  );

  try {
    const res = await axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL + "api/orgs/subscription?siMapping=true",
      headers: getAuthHeader(),
    });
    const subscriptionData = res.data.data;
    console.log(">>>SD", subscriptionData);
	if(!set){
			return subscriptionData
	}

    if (window.localStorage.getItem("evalloToken")) {
      window.localStorage.setItem(
        "subscriptionData",
        JSON.stringify(subscriptionData)
      );
    } else {
      window.sessionStorage.setItem(
        "subscriptionData",
        JSON.stringify(subscriptionData)
      );
    }
    store.dispatch(updateSubscriptionV2(subscriptionData));
    store.dispatch(
      updateInitialTeamSize(subscriptionData.subscription.tutors.limit)
    );
    console.log(">>>SV_END", subscriptionData);
  } catch (err) {
    if (err.response.status === 404) {
      console.log("No new subscription for this organisation");
      checkForOldSubscriptions(organization);
    }
  }
}

async function resubscribeSubscription(
  siMapping,
  subscription,
  discountCoupon = null
) {
  console.log("SUB", subscription);
  try {
    const res = await axios({
      url: process.env.REACT_APP_BASE_URL + "api/stripe/prices/v2",
      method: "GET",
    });

    console.log("DONE");
    const { prices, inactivePrices } = res.data.data;
    const allPricesFlattened = [];
    for (const product of prices) {
      console.log(product);
      for (const key of Object.keys(product.prices)) {
        for (const _price of product.prices[key]) {
          allPricesFlattened.push(_price);
        }
      }
    }

    const newPrices = [];
    for (const siMap of siMapping) {
      let price = allPricesFlattened.find(
        (price) => price.lookup_key === siMap.lookupKey
      );
      if (!price) {
        const inactivePriceOldLookupKey = inactivePrices
          .find((price, idx) => price.lookup_key === siMap.lookupKey)
          ?.lookup_key.split("_Not_Used")[0];
        price = allPricesFlattened.find(
          (price) => price.lookup_key === inactivePriceOldLookupKey
        );
      }
      newPrices.push({
        value: price.id,
        selected: true,
        ...(typeof siMap.quantity === "number" &&
          !siMap.lookupKey.includes("PAYG") && { quantity: siMap.quantity }),
      });
    }

    console.log("TESRTING", newPrices);

    const archiveSubscriptionRes = await axios({
      url: process.env.REACT_APP_BASE_URL + "api/stripe/subscriptions/v2",
      method: "DELETE",
      headers: getAuthHeader(),
    });

    const newSubscription = await axios({
      url: process.env.REACT_APP_BASE_URL + "api/stripe/subscriptions/v2",
      method: "POST",
      data: {
        items: newPrices,
        isFreeTrial: false,
        planType:
          subscription.__t === "MonthlySubscription" ? "monthly" : "annual",
        isEnabledPAYG:
          subscription.__t === "MonthlySubscription" ? true : false,
        couponId: discountCoupon?.id,
      },
      headers: getAuthHeader(),
    });
    console.log("DONE");
  } catch (err) {
    console.log(err);
  }
}

/**
 * @function fetches customer details & returns the payment methods attached or not also updates the store.
 * @param {*} organization
 * @returns
 */
async function fetchPaymentMethodsForCustomer(organization) {
  try {
    const res = await axios({
      url:
        process.env.REACT_APP_BASE_URL +
        "api/stripe/customer?customerId=" +
        organization.stripeCustomerId,
      method: "GET",
      headers: getAuthHeader(),
    });
    console.log(res.data.paymentMethods);
    if (res?.data?.paymentMethods) {
      store.dispatch(updatePaymentMethods(res.data.paymentMethods.data));
    }
    return res.data.paymentMethods;
  } catch (err) {
    console.log(err);
  }
}

async function fetchArchivedSubscriptionsForOrg() {
  try {
    const res = await axios({
      url: process.env.REACT_APP_BASE_URL + "api/orgs/archivedSubscription",
      method: "GET",
      headers: getAuthHeader(),
    });
    console.log(res.data.data.archivedSubscriptions);
    return res.data.data.archivedSubscriptions;
  } catch (err) {
    console.log(err);
  }
}

function isSiMappingEqual(reference, siMapping) {
  console.log(reference, siMapping);
  if(!reference) return false;
  if (reference.length !== siMapping.length) {
    return false;
  }
  let isEqual = true;
  //Check when no new price id is present
  for (let idx = 0; idx < reference.length; idx++) {
    if (siMapping[idx].lookupKey.includes("Tutor")) {
      if (siMapping[idx].quantity !== reference[idx].quantity) {
        isEqual = false;
        break;
      }
    }
    if (siMapping[idx].newPriceId) {
      isEqual = false;
      break;
    }
  }
  if (isEqual) {
    return true;
  } 
   //Check when new price id is present
  for (let idx = 0; idx < reference.length; idx++) {
    console.log(
      reference[idx].lookupKey,
      siMapping[idx].newPriceId,
      reference[idx].priceId
    );
    if (
      siMapping[idx].newPriceId &&
      siMapping[idx].newPriceId !== reference[idx].priceId
    ) {
      return false;
    }
    if (
      siMapping[idx].quantity &&
      siMapping[idx].quantity !== reference[idx].quantity
    ) {
      return false;
    }
  }
  if(!isEqual){ return false}
  return true;
}

async function updateSubscription(
  updateRequestForSub,
  updateOrgRequest,
  organization
) {
  try {
    const res = await axios({
      method: "PATCH",
      url:
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_SUBSCRIPTION_UPDATE_API,
      data: {
        data: updateRequestForSub,
      },
      headers: getAuthHeader(),
    });

    triggerToast("Your subscription has updated successfully.", "success", {
      autoClose: 5000,
    });

    updateOrgRequest({
      orgId: organization._id,
      op: "update",
      fields: {
        isEnabledPAYG: false,
      },
    }).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        console.log(res);
      }
    });
  } catch (err) {
    triggerToast(err?.response?.data?.message, "error", { autoClose: 3000 });
  }
}

async function createFreeSubscription(
  freeBasePackageId,
  freeProducts,
  updateOrgRequest,
  setIsFreeLoading,
  createNewSubscription
) {
  try {
    const createSubscriptionRequestBody = {
      items: [],
      isFreeTrial: true,
      planType: "annual",
      isEnabledPAYG: true,
      //For testing remove after testing
      isFreePlan : true
    };
    const subscriptionItems = [];
    //To insert base package
    subscriptionItems.push({
      value: freeBasePackageId,
      selected: true,
      name: "BasePackage",
    });
    //To insert other products
    Object.keys(freeProducts).forEach((key, idx) => {
      if (key !== "CRM") {
        const product = freeProducts[key];
        subscriptionItems.push({
          value: product.value,
          selected: true,
          name: key,
          ...(key === "tutors" && { quantity: product.quantity }),
        });
      }
    });
    createSubscriptionRequestBody.items = subscriptionItems;
    setIsFreeLoading(true);
    //To create a new free subscription
    let subscriptionCreationResponse = await createNewSubscription(
      createSubscriptionRequestBody
    );
    if (subscriptionCreationResponse.error) {
      triggerToast(subscriptionCreationResponse.error.data?.message, "error", {
        autoClose: 3000,
      });
      setIsFreeLoading(false);
    } else {
      updateOrgRequest({
        fields: {
          isEnabledPAYG: false,
        },
        orgId:
          window.localStorage.getItem("orgId") ??
          window.sessionStorage.getItem("orgId"),
        op: "update",
      }).then(async (res) => {
        if (res.error) {
          console.log("SOME ERROR OCCURED", res.error);
        } else {
          triggerToast("You are all setup", "success", { autoClose: 3000 });
          await fetchAndSetSubscription();
          store.dispatch(updateFetchData(true));
          setIsFreeLoading(false);
        }
      });
    }
  } catch (err) {
    console.log(err);
    triggerToast("Oops some error occured", "error", { autoClose: 3000 });
    setIsFreeLoading(false);
  }
}

async function undoCancelSubscription() {
  try {
    const res = await axios({
      url: process.env.REACT_APP_BASE_URL + "api/stripe/subscriptions/v2",
      method: "PATCH",
      data: { data: [{ op: "undo-cancel" }] },
      headers: getAuthHeader(),
    });

    return res;
  } catch (error) {
    console.error("Error undoing subscription cancellation", error);
    throw error;
  }
}

async function undoSubscriptionDownGrades() {
  const res = await axios({
    url: process.env.REACT_APP_BASE_URL + "api/stripe/subscriptions/v2",
    method: "PATCH",
    data: { data: [{ op: "undo-downgrades" }] },
    headers: getAuthHeader(),
  });

  return res;
}

async function updateTutorLicenses(organization,fetchAllUsersQuery) {
	console.log("TUT UPDT")
  try {
	const subscriptionV2 = await fetchAndSetSubscription(organization, false)
    const res = await fetchAllUsersQuery("?role=tutor&role=admin");
    if (!res.error) {
      const allTutors = res.data.data.user;
      //Admin is considered here as its included in the licenses
      const countOfActiveTutors = allTutors.filter(
        (user) => user.userStatus === "active"
      ).length;
      const tutorLimit = subscriptionV2?.subscription?.tutors?.limit;
      console.log(countOfActiveTutors, tutorLimit);
      if (tutorLimit < countOfActiveTutors) {
        //Admins are not included here as we only need to deactive tutors
        const tutorIdsToDeassign = allTutors
          .filter(
            (user) => user.userStatus === "active" && user.role === "tutor"
          )
          .slice(tutorLimit - 1)
          .map((user) => user._id);
        await changeTutorStatus(tutorIdsToDeassign, "inactive");
      }
    }
  } catch (err) {
    console.log(err);
  }
}


async function deleteSubscription(){

	try {
		const apiURL = process.env.REACT_APP_BASE_URL + "api/stripe/subscriptions/v2"
		const res = await axios({
			url : apiURL,
			method : "delete",
			headers : getAuthHeader()
		})
	}catch(err){
		console.err(err)
	}

}

export {
  fetchAndSetSubscription,
  resubscribeSubscription,
  fetchPaymentMethodsForCustomer,
  fetchArchivedSubscriptionsForOrg,
  checkForOldSubscriptions,
  getBasePackageTutorLimit,
  createSubscriptionItems,
  isSiMappingEqual,
  updateSubscription,
  createFreeSubscription,
  undoCancelSubscription,
  undoSubscriptionDownGrades,
  updateTutorLicenses,
  deleteSubscription
};
