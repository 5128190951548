import React from 'react'

const LocationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_35236_123892)">
                <path d="M11.9592 24.008L11.2621 23.4105C10.3011 22.6058 1.91016 15.3591 1.91016 10.0583C1.91016 4.50839 6.40927 0.00927734 11.9592 0.00927734C17.5091 0.00927734 22.0082 4.50839 22.0082 10.0583C22.0082 15.3592 13.6172 22.6059 12.6602 23.4145L11.9592 24.008ZM11.9592 2.18217C7.61137 2.18709 4.08802 5.71045 4.08309 10.0582C4.08309 13.3883 9.24553 18.7081 11.9592 21.1429C14.6729 18.7071 19.8352 13.3843 19.8352 10.0582C19.8303 5.71045 16.307 2.18714 11.9592 2.18217Z" fill="#7C859C" />
                <path d="M11.9599 14.0414C9.75997 14.0414 7.97656 12.258 7.97656 10.0581C7.97656 7.85811 9.75997 6.07471 11.9599 6.07471C14.1598 6.07471 15.9432 7.85811 15.9432 10.0581C15.9432 12.258 14.1599 14.0414 11.9599 14.0414ZM11.9599 8.06633C10.8599 8.06633 9.96823 8.95804 9.96823 10.058C9.96823 11.158 10.8599 12.0497 11.9599 12.0497C13.0599 12.0497 13.9516 11.158 13.9516 10.058C13.9516 8.95804 13.0599 8.06633 11.9599 8.06633Z" fill="#7C859C" />
            </g>
            <defs>
                <clipPath id="clip0_35236_123892">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default LocationIcon