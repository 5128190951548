import CrossIcon from "../../../../../assets/icons/SettingsIcon/CrossIcon"
import PopupContainer from "../../../../../components/PopupContainer/PopupContainer"
import ColorSelector from "../ColorSelector/ColorSelector"
import styles from "./SelectColorPopup.module.css"


function SelectColorPopup({trigger, setTrigger, color, setColor, onAddClick}) {
	
	return(
		<PopupContainer {...{trigger, setTrigger}} customCloseButton={true} customOuterContainer={styles.popupOuterContainer} customInnerContainer={styles.popupInnerContainer}>
		<div className={styles.container}>
			<div className={styles.headingContainer}>
				<div>Add New Color</div>	
				<button onClick={(e)=>setTrigger(false)}><CrossIcon/></button>
			</div>
			<ColorSelector color={color} setColor={setColor} trigger={trigger} onAddClick={onAddClick} />
		</div>
		
		</PopupContainer> 
	)

}


export default SelectColorPopup
