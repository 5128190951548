import React, { useState } from "react";
import BackBtn from "../Buttons/Back";
import PrimaryButton from "../Buttons/PrimaryButton";
import { TestDetail } from "../TestDetail/TestDetail";
import Modal from "../Modal/Modal";
import Warning from "../../assets/images/warning.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMonthName } from "../../utils/utils";
import AssignmentTestModal from "../Modal/AssignmentTestModal";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

const Testinstruction_2 = ({
  setloader,
  setstarttestindex,
  starttestindex,
  testHeaderDetails,
  desc,
  timer,
  loader,
  setisntructionpage,
  testStarted,
  subjects,
  completedSectionIds,
  activeSection,
  testMultiple
}) => {
  const formatDate2= (value)=>{
    const [ day, month, year] = value.split("-");
    const monthName = getMonthName(month-1);
    
    const formattedDate = `${day}` + "/" + `${month}` + `/` + `${year}`;
    return formattedDate
   }
  const [popup, setpopup] = useState(false);
  const navigate = useNavigate();
  const { organization } = useSelector((state) => state.organization);
  const actual_timer=parseFloat(testHeaderDetails.duration)*desc[starttestindex]?.timer 

  function closeinstruct() {
    setpopup(false);
    setisntructionpage(false);
  }
  const tasksWithCompletion = subjects.map((task) => ({
    ...task,
    completed: completedSectionIds.includes(task._id) ? true : false,
  }));
  //console.log({tasksWithCompletion,testHeaderDetails});
  function convertDecimalTimeToMMSS(decimalTime) {
    const minutes = Math.floor(decimalTime);
    const seconds = Math.round((decimalTime % 1) * 60);
  
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <>
      <div className="w-[1920px] flex justify-center items-center">
      <div className="w-[1600px] relative">
        <p className="text-[#24A3D9] ml-8 !mt-[50px] text-[20px] font-normal">
          <BreadCrumb args={[ testHeaderDetails.testName]}/>
        </p>
        <p className="text-[#26435F] ml-8 text-[20px] font-bold mb-[41px] mt-[31px] ">
          {testHeaderDetails.testName}
        </p>

        <div className="flex  ml-4 w-full text-sm px-4  mt-2">
          <div className="w-[25%] flex  flex-col items-start">
            <div className=" flex mb-3  items-center w-full">
              <div className="inline-block w-1/2   font-[500] text-[20px] text-secondary-80 ">
             
                Student Name
              </div>

              <div className="inline-block w/2 font-light  text-[20px] text-secondary-80">
              {testHeaderDetails?.name?.length>18?testHeaderDetails?.name.substring(0,15)+' ...':testHeaderDetails?.name}
              </div>
            </div>



            <div className=" flex mb-3 items-center w-full">
              <p className="inline-block w-1/2   font-[500] text-[20px] text-secondary-80">
                {" "}
                Date Assigned{" "}
              </p>
           
              <p className="inline-block font-light  w-1/2 text-[20px] text-secondary-80">
                {formatDate2(testHeaderDetails.dateAssigned)}
              </p>
            </div>
            <div className=" flex mb-3 items-center w-full">
              <p className="inline-block w-1/2  font-[500] text-[20px] text-secondary-80">
                {" "}
                Duration{" "}
              </p>

              <p className="inline-block font-light w-1/2  text-[20px] text-secondary-80">
              {testMultiple === 0 ? 'Unlimited' : testMultiple === 1 ? 'Regular' : `${testMultiple}x` }
              </p>
            </div>
          </div>
          <div className="w-[25%] flex  flex-col items-start ">
            <div className="flex mb-3 w-full">
              <p className="inline-block w-1/2  font-[500] text-[20px] text-secondary-80">
                {" "}
                Due on{" "}
              </p>

              <p className="inline-block w-1/2 font-light  text-[20px] text-secondary-80">
                {testHeaderDetails.dueDate ? formatDate2(testHeaderDetails.dueDate) : "-"}
              </p>
            </div>
            <div className=" flex mb-3 w-full">
              <p className="inline-block w-1/2  font-[500] text-[20px] text-secondary-80">
                {" "}
                Started on{" "}
              </p>

              <p className="inline-block w-1/2 font-light  text-[20px] text-secondary-80">
                {testHeaderDetails.startedOn
                  ? formatDate2(testHeaderDetails.startedOn)
                  : "-"}
              </p>
            </div>
            <div className=" flex mb-3 w-full">
              <p className="inline-block w-1/2  font-[500] text-[20px] text-secondary-80">
                {" "}
                Completed on{" "}
              </p>

              <p className="inline-block w-[160px] font-light  text-[20px] text-secondary-80">
              {testHeaderDetails.completedOn
                  ? testHeaderDetails.completedOn
                  : "-"}
              </p>
            </div>
          </div>


          <div className="w-[50%] flex flex-col mb-3 gap-[10px]  ">
              <p className="inline-block w-64 mr-4 font-[500] text-[20px] text-secondary-80">
                {" "}
                Instruction from tutor{" "}
              </p>

              <p className=" font-light  text-[20px] text-secondary-80 break-all leading-[31.25px]">
              {testHeaderDetails.instruction?.length
                  ? testHeaderDetails.instruction
                  : "-"}
              </p>
          </div>
        </div>

        <div>
          <div className="flex relative w-fit flex-row gap-2 mx-4 mt-[53.75px] overflow-x-auto">
            {tasksWithCompletion.map((item, idx) => {
              return (
                <div key={idx} className="relative">  
                <PrimaryButton
                  roundedClass="rounded-0"
                  children={item?.name}
                  onClick={() => {
                    setstarttestindex(idx);
                  }}
                  className={` leading-[50px] px-[17.5px] mr-2 rounded-0 text-[17.5px] font-normal w-fit !bg-transparent
                            ${!item.completed ? "text-[#26435F]" : ""} ${
                    idx === starttestindex
                      ? "text-[#0671E0] font-medium"
                      : ""
                  } disabled:opacity-40`}
                  disabled={item.completed}
                />              
                   <div style={{borderRadius:"3.75px 3.75px 0px 0px",background:idx === starttestindex?"#0671E0":"transparent"}} className="absolute h-[3.75px] left-0 bottom-0 right-0"></div>
              </div>
              );
            })}
            <div className="bg-secondary-30 absolute bottom-[0px] z-10 h-[1px] w-full"></div>
          </div>
          <div className="bg-secondary-2  p-[30px] m-4  mt-[41px] relative min-h-[435px] flex flex-col justify-between rounded-[6px] shadow-[0px_0px_2.5px_0px_#00000040]">
            <div className="bg-blue-70 absolute top-7 right-7 rounded w-[128.75px] h-[62.342px]  text-center ">
              <p className="text-[15px] text-[#FFFFFF] font-medium pt-[6px]">Time</p>
              <p className="text-[25px] text-[#FFFFFF] font-bold mt-[-7px]">
                {testHeaderDetails.duration=='Unlimited'?'Unlimited': testHeaderDetails.duration=='Regular'?desc[starttestindex]?.timer+":00" :convertDecimalTimeToMMSS(actual_timer)}
              </p>
            </div>
            <div className="">
              <p className="text-secondary text-[17.5px] font-[700] mb-[20px]">
                Section Instructions:
              </p>
              <p className="text-secondary text-[17.5px] font-[500]">
                Time: {testHeaderDetails.duration=='Unlimited'?'Unlimited':testHeaderDetails.duration=='Regular'?desc[starttestindex]?.timer + ":00" :convertDecimalTimeToMMSS(actual_timer)} minutes
              </p>
              <p className="text-secondary-80 text-[17.5px] pt-1 font-normal">
                {desc[starttestindex]?.description}
              </p>
            </div>

            <div className="flex items-start flex-col mt-16 relative">
              <div className="flex flex-row justify-start items-center bg-[#FF696133] pt-1 pb-1 pl-5 pr-5 rounded-20 mb-[30.5px]">
                <img src={Warning} />
                <p className="text-red-500 text-[15px] font-medium mt-0">Warning</p>
                <p className="text-[#FF6961] text-[15px] font-light mt-0">
                  : Once Started, you will not be able to pause the timer.
                </p>
              </div>
              <PrimaryButton
                children="Start Section"
                className="font-semibold h-[45px] w-[171px] !text-[20px] text-[#FFFFFF] text-center  "
                onClick={() => {
                  setpopup(true);
                }}
              />

              {/* <PrimaryButton children='Start Section' className='w-[300px] h-[60px] text-[21px]' onClick={handleStartTest} /> */}
            </div>
          </div>
          {popup && (
            <div className="max-w-1/2 relative flex justify-center items-center h-[100vh]">
              {" "}
                 <AssignmentTestModal
            classname="max-w-[599px] h-[167px] mx-auto rounded-[8px]"
            closeIconClass="hidden"
            parentClass="flex flex-col items-center !mb-[375px] !h-full pt-[16rem]"
            topClass={"!h-[100%] !mt-0 !overflow-hidden"}
            modalHeadingClass="!justify-center text-center"
            bodyScrollbarHidden={true}
            title="Note that the time will begin as soon as you start this section. make sure that you have everything set up."
            titleClassName="m-0 !text-[20px] !font-normal text-center"
            underline={true}
            cancelIconState={false}
            primaryBtn={{
              text: "Okay",
              className: "bg-[#FF7979] w-[146.67px] h-[46.67px] flex-shrink-0 !ml-0 !text-[16px] !font-medium",
              onClick: closeinstruct,
            }}
            handleClose={() => setpopup(false)}
          />
            </div>
          )}
        </div>
      </div>
      </div>
    </>
  );
};

export default Testinstruction_2;
