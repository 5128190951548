import React, { useEffect, useState } from 'react'
import { useEffectPreventMountExec } from '../../hooks/useEffectPreventMountExec';
import CheckboxOrangeIcon from "../../assets/icons/check-box-orange.svg";
import CheckboxGreyIcon from "../../assets/icons/check-box-grey.svg";

export default function OrangeCheckbox({ stopM, checked, onChange, name, disabled, value, checkMarkClassName, className }) {
   const [checkG, setCheckG] = useState(checked)


   const handleClick = (e) => {
      if (disabled === true || !onChange) return
      onChange(!e)
   }
   
useEffectPreventMountExec(()=>{
    if(disabled === true || !onChange) return
    onChange(checkG)
},[checkG])
   return (
      <img
        src={checked ? CheckboxOrangeIcon : CheckboxGreyIcon}
        className={`h-[22.18px] w-[22.18px] ${className}`}

        onClick={(e) => {
            e.stopPropagation()
            if (!true)
               setCheckG(!checkG)
            else {
               handleClick(checked)
            }

         }}
      />
   )
}
