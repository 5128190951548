export const Interest = [
  "Basketball",
  "Soccer",
  "American Football",
  "Running",
  "Yoga",
  "Bowling",
  "Tennis",
  "Cricket",
  "F1 Racing",
  "Rock Climbing",
  "Boxing",
  "Trekking",
  "Sketching",
  "Painting",
  "Digital Art",
  "Writing ",
  "Reading",
  "Video Games",
  "Travelling",
  "Board Games ",
  "Blogging",
  "Podcasts",
  "Youtube",
  "Volunteering",
  "Socializing",
  "Singing",
  "Dancing",
  "Listening Music",
  "Guitar",
  "Violin",
  "Drums",
  "Piano",
  "Upskilling",
  "Movies",
  "TV Shows",
  "Anime",
  "Comics",
  "Cooking",
];


export const qualities = [
    "Confident",
    "Honest",
    "Brave",
    "Empathetic",
    "Loyal",
    "Hardworking",
    "Energetic",
    "Independent",
    "Responsible",
    "Goal-Oriented",
    "Humble",
    "Friendly",
    "No-Nonsense",
    "Messy",
    "Curious",
    "Humorous",
    "Laid Back"
  ];


  export const commonSubjects = [
    "Mathematics",
    "Science",
    "English",
    "History",
    "Geography",
    "Social Studies",
    "Physics",
    "Chemistry",
    "Biology",
    "Physical Education",
    "Art",
    "Music",
    "Literature",
    "Computer Science",
    "Spanish",
    "French",
    "German",
    "Chinese",
    "Japanese",
    "Arabic",
    "Economics",
    "Psychology",
    "Sociology",
    "Political Science",
    "Environmental Science",
    "Astronomy",
    "Philosophy",
    "Drama",
    "Journalism",
    "Media Studies",
    "Film Studies",
    "Health Education",
    "Law",
    "Criminology",
    "Engineering",
    "Statistics",
    "Calculus",
    "Algebra",
    "Geometry",
    "Trigonometry",
    "Linear Algebra",
    "Literature",
    "World History",
    "U.S. History",
    "European History",
    "Asian History",
    "Theology",
    "Ethics",
    "Music Theory",
    "Music History",
    "Jazz Studies",
    "Photography",
    "Graphic Design",
    "Web Design",
    "Entrepreneurship",
    "Space Exploration",
    "Meteorology",
    "Zoology",
    "Botany",
    "Political Philosophy",
    "Public Health",
    "Global Studies",
    "Biochemistry",
    "Medicine",
    "Dentistry",
    "Veterinary Medicine",
    "Physical Therapy",
    "Mathematical Physics",
    "Quantum Mechanics",
    "Cognitive Science",
    "Machine Learning",
    "Data Science",
    "Blockchain Technology",
    "Renewable Energy",
    "Sustainable Agriculture",
    "Environmental Engineering",
    "Renewable Energy",
    "Astrophysics",
    "String Theory",
    "Quantum Computing",
    "Biotechnology",
    "Agricultural Science",
    "Cultural Anthropology",
    "Urban Planning",
    "Fashion Design",
    "Robotics Engineering",
    "Sports Medicine",
    "Artificial Intelligence",
    "Public Policy",
    "Game Design",
    "Digital Marketing",
    "Human Rights",
    "Criminal Law",
    "International Relations",
    "Peace and Conflict Studies",
    "Geopolitics",
    "Animation",
    "Epidemiology",
    "Clinical Psychology",
    "Space Science",
    "Art History",
    "Neuropsychology",
    "Fashion Marketing",
    "Political Economy",
    "Quantum Chemistry",
  ];


  export const backgroundColors = [
    "rgba(77, 51, 233, 0.6)",
    "rgba(218, 51, 233, 0.6)",
    "rgba(51, 233, 146, 0.6)",
    "rgba(51, 189, 233, 0.6)",
    "#F1848A",
    "rgba(255, 215, 51, 0.6)",
    "rgba(216, 52, 6, 0.6)",
    "rgba(28, 74, 53, 0.6)",
    "rgba(96, 26, 222, 0.6)",
    "#56A8CC8A",
    "#D37D4A8A",
    "#6D52358A",
    "#F58A4B8A",
    "#8B95C98A",
    "#64C7CC8A",
    "#5E6FCC8A",
    "#9A5ECC8A",
    "#CC5E9D8A",
    "#E64C858A",
    "#3B3B3B8A",
    "#5E7D7E8A",
    "#C0C0C08A",
    "#7FFFD48A",
    "#E41B178A",
    "#00FFFF8A",
    "#FF00FF8A",
    "#00FF008A",
    "#FFFF008A",
    "#8000808A",
    "#FF45008A",
    "#0080008A",
    "#0000FF8A",
    "#8080808A",
    "#FFD7008A",
    "#ADFF2F8A",
    "#6B8E238A",
    "#32CD328A",
    "#8A2BE28A",
    "#BDB76B8A",
    "#8B45138A",
    "#CD5C5C8A",
    "#6969698A",
    "#FF14938A",
    "#20B2AA8A",
    "#8000008A",
    "#00FF7F8A",
    "#FFD7008A",
    "#C715858A",
    "#4682B48A",
    "#6A5ACD8A",
    "#D2691E8A",
  ];
  