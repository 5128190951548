const DeleteIcon = (props) => {
    return (
        <div {...props}>
            <svg className={props.className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 4H3.33333H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.6668 3.9987V13.332C12.6668 13.6857 12.5264 14.0248 12.2763 14.2748C12.0263 14.5249 11.6871 14.6654 11.3335 14.6654H4.66683C4.31321 14.6654 3.97407 14.5249 3.72402 14.2748C3.47397 14.0248 3.3335 13.6857 3.3335 13.332V3.9987M5.3335 3.9987V2.66536C5.3335 2.31174 5.47397 1.9726 5.72402 1.72256C5.97407 1.47251 6.31321 1.33203 6.66683 1.33203H9.3335C9.68712 1.33203 10.0263 1.47251 10.2763 1.72256C10.5264 1.9726 10.6668 2.31174 10.6668 2.66536V3.9987" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.6665 7.33203V11.332" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.3335 7.33203V11.332" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>
    )
}

export default DeleteIcon