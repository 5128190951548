import { style } from '@mui/system'
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon'
import StubbyLeftArrow from '../StubbyLeftArrow/StubbyLeftArrow'
import Tooltip from '../Tooltip/Tooltip'
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import styles from './EventRequests.module.css'
import DownArrowIcon from '../DownArrowIcon/DownArrowIcon'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import frozenEventTypes from '../../utils/eventTypes'
import frozenEventStatus from '../../utils/eventStatus'
import { useEffect, useRef, useState } from 'react'
import useOutsideAlerter from '../../hooks/useOutsideAlerter'
import EventRequestsCard from '../EventRequestsCard/EventRequestsCard'
import DeclineRequestPopUp from '../DeclineRequestPopUp/DeclineRequestPopUp'
import { triggerToast } from '../../utils/toastController'
import { deleteEventRequest, fetchAllEventRequests, updateAttendeeStatus, updateEventRequest } from '../../utils/eventService'
import { fetchAndSetAllEventRequests } from '../../pages/CalendarV2/helpers/fetchers'
import RequestActionPopup from '../RequestAction/RequestActionPopup'
import PopupContainer from '../PopupContainer/PopupContainer'
import EventRequestPopup from '../StudentRequestPopup/EventRequestPopup'
import CancelEventPopUp from '../CancelEventPopUp/CancelEventPopUp'


const dummyAllEvents = [
	{
		eventDetails: {
			eventType: frozenEventTypes.SESSION.eventType,
			date: (new Date()).toISOString(),
			time: {
				start: {
					time: "09:00",
					timeType: "AM"
				},
				end: {
					time: "09:45",
					timeType: "AM"
				}
			}
		},
		user: {
			firstName: "Jimmy",
			lastName: "John",
			email: "jimmy_john@mail.com"
		},
		requestDetails: {
			requestStatus: frozenEventStatus.CANCEL.status,
			requestedDate: (new Date()).toISOString(),
			otherInfo: "Event request for a new session."
		}
	}, {
		eventDetails: {
			eventType: frozenEventTypes.CONSULTATION.eventType,
			date: (new Date()).toISOString(),
			time: {
				start: {
					time: "09:00",
					timeType: "AM"
				},
				end: {
					time: "09:45",
					timeType: "AM"
				}
			}
		},
		user: {
			firstName: "John",
			lastName: "Henry",
			email: "johnHenry@mail.com",
			awsLink: "https://evallo-test.s3.amazonaws.com/",
			photo: ""
		},
		requestDetails: {
			requestStatus: frozenEventStatus.RESCH.status,
			requestedDate: (new Date()).toISOString(),
		}
	}, {
		eventDetails: {
			eventType: frozenEventTypes.CONSULTATION.eventType,
			date: (new Date()).toISOString(),
			time: {
				start: {
					time: "09:00",
					timeType: "AM"
				},
				end: {
					time: "11:45",
					timeType: "AM"
				}
			}
		},
		user: {
			firstName: "Sam",
			lastName: "G",
			email: "samg@mail.com",
			awsLink: "",
			photo: ""
		},
		requestDetails: {
			requestedDate: (new Date()).toISOString(),
		}
	}, {
		eventDetails: {
			eventType: frozenEventTypes.OFFCWORK.eventType,
			date: (new Date()).toISOString(),
			time: {
				start: {
					time: "09:00",
					timeType: "AM"
				},
				end: {
					time: "09:45",
					timeType: "AM"
				}
			}
		},
		user: {
			firstName: "Jack",
			lastName: "M",
			email: "jackm@mail.com",
			awsLink: "https://evallo-test.s3.amazonaws.com/",
			photo: ""
		},
		requestDetails: {
			requestedDate: (new Date()).toISOString(),
		}
	}
]


const valueList = [
	{value:"all", displayValue:"All"},
	{value : "externalappointment", displayValue : "Appointment"},
	{value :"session", displayValue: "Session"},
	{value :"consultation", displayValue: "Consultation"},
	{value :"cancel", displayValue: "Cancellation"},
	{value :"Reschedule", displayValue: "Reschedule"},
	{value :"internalmeeting", displayValue: "Internal Meeting"},
	{value :"officehours", displayValue: "Office Work"}
]


function SessionTypeDropdown({ valueList, selectedValue, setSelectedValue, isActive, setIsActive }) {

	const iconStyles = { height: "16px", width: "16px"}
	const dropdownMenuRef = useRef();
	useOutsideAlerter(dropdownMenuRef, ()=>setIsActive(false));

	const handleDropdownButtonClick = (value, setIsActive, setSelectedValue) => {
		setSelectedValue(value)
		setIsActive(false)
	}


	return (
		<div className=' relative' ref={dropdownMenuRef} >
			<div className=" flex items-center justify-center py-[7.5px] cursor-pointer rounded-[24px] px-[12px] gap-[10px] border-[1px] border-secondary-10 "  onClick={() => setIsActive(!isActive)} >
				<p className='text-[14px] leading-[21px] text-secondary-80 '>Request Type</p>
				<button  className='' >
					{
						!isActive ? <ExpandMore style={iconStyles} /> : <ExpandLess style={iconStyles} />
					}
				</button>
			</div>

			{
				isActive ?
					<div className="absolute rounded-[6px]  py-[6px] flex flex-col bg-white w-full top-[110%] shadow-[0px_4px_18px_0px_#11167D1A] ">
						{
						valueList.map(({ displayValue, value }, idx) => (
							<button onClick={() => handleDropdownButtonClick(value, setIsActive, setSelectedValue)} className={ ` ${value === selectedValue ? "font-semibold": ""}  py-[9px] px-[8px] text-[14px] leading-[14px] text-[#25335A] hover:bg-secondary-5 text-left `}>{displayValue}</button>
						))
						}
					</div>
				: <></>
			}

		</div>
	)

}



function EventRequests(props) {

	const { allEventRequests, setAllEventRequests,  handleBackClick, user , setRequiredEventData, setIsActive, setIsActiveRequestEventPopUp, setOperationMode, setSelectedEventType, selectedEventType, tempEventType, setTempEventType } = props
	//For filtering
	const [requiredEvents, setRequiredEvents] = useState([])
	const [selectedValue, setSelectedValue] = useState("all")
	const [isDropdownActive, setIsDropdownActive] = useState(false)
	const [requestPopupInfo, setRequestPopupInfo] = useState({
			trigger : false,
			eventRequestId : "",
			eventRequestDetails : null,
			purpose : ""
	})
	const [cancelEventPopupInfo, setCancelEventPopupInfo] = useState({
			trigger : false,
			targetEventId : "",
			targetUserIds : [],
			eventRequestId : ""
	})



	//Collection of props
	const dropdownProps = {
		valueList: valueList,
		isActive: isDropdownActive,
		setIsActive: setIsDropdownActive,
		selectedValue: selectedValue,
		setSelectedValue: setSelectedValue
	}

	
	async function updateActionStatus(eventRequestId, action){
		try {
			
			const res = await updateEventRequest(eventRequestId, {
				"targetUserDetails.actionStatus" : "frozen"
			})
			fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)
			setRequestPopupInfo({
				trigger : false,
				eventRequestId : ""
			})

		}catch(err){
			triggerToast("Error occured while updating the status.")
		}
	}

	async function handleDeleteClick(eventRequestId){
		try {
			
			const res = await deleteEventRequest(eventRequestId)
			fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)

		}catch(err){
			triggerToast("Error occured while deleting the request.")
		}

	}


	async function handleActionClick(eventRequest, eventDetails){
		const externalUsers = ["student", "parent"]
		const internalUsers = ["admin", "tutor"]

		if(externalUsers.includes(user?.role)){
			setRequestPopupInfo({
				trigger : true,
				eventRequestId : eventRequest._id,
				eventRequestDetails : eventRequest,
				purpose : ["cancel", "reschedule"].includes(eventRequest.requestType) ? "edit-status-change-request" : "edit-new-event-request"
			})
		} else if(internalUsers.includes(user?.role)) {
			console.log( "ERE", eventRequest.requestType)
			setSelectedEventType(
				eventRequest.requestType !== "schedule" ? 
					eventDetails.__t ? eventDetails.__t.toLowerCase() : "session"
					:
					eventRequest.requestedEventDetails.eventType
			)
			setRequiredEventData({
				...(["reschedule"].includes(eventRequest.requestType)) &&	{ 
					...eventDetails, isEditingRequest : true, eventRequestId : eventRequest._id 
				},
				...(eventRequest.requestType === "schedule") && { 
					isEditingRequest : true, 
					eventRequestId : eventRequest._id,
					date : eventRequest.requestedEventDetails.date,
					time : eventRequest.requestedEventDetails.time,
					timeZone : eventRequest.requestedEventDetails.timeZone,
					...(eventRequest.requestedEventDetails.eventType === "session") && {
						studentIds : eventRequest.requestedEventDetails.studentIds,
						tutorId : eventRequest.requestedEventDetails.tutorId
					},
					...(eventRequest.requestedEventDetails.eventType !== "session") && {
						attendeeDetails : eventRequest.requestedEventDetails.attendeeDetails
					},
					...(["session","consultation"].includes(eventRequest.requestedEventDetails.eventType)) && {
						clientNotes : {
							note : eventRequest.additionalInfo,
						},
						internalNotes : {
							note : ""
						}
					},
					...(["externalappointment"].includes(eventRequest.requestedEventDetails.eventType)) && {
						additionalInfo : eventRequest.additionalInfo,
						studentIds : eventRequest.requestedEventDetails.attendees.filter(val=>val.role === "student"),
						tutorId : eventRequest.requestedEventDetails.attendees.find(val=>val.role === "tutor" || val.role === "admin")?._id,
						...(eventRequest.requestedEventDetails.attendees.find(val=>val.role === "tutor" || val.role === "admin")) && {
							tutorName : eventRequest.requestedEventDetails.attendees.find(val=>val.role === "tutor" || val.role === "admin").firstName + " " + eventRequest.requestedEventDetails.attendees.find(val=>val.role === "tutor" || val.role === "admin").lastName,
							_tutor : eventRequest.requestedEventDetails.attendees.find(val=>val.role === "tutor" || val.role === "admin")						
						}

					}
				}
			})
			
			if(["schedule", "reschedule"].includes(eventRequest.requestType)){
				setIsActive(true)
				setOperationMode(eventRequest.requestType === "schedule" ? "create-requested" : "edit" )
				setTempEventType(eventRequest.requestedEventDetails.eventType)
			}
			else if(eventRequest.requestType === "invite" && [ frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()].includes(eventRequest.requestedEventDetails.eventType)){
				
				try {
					
					await updateActionStatus(eventRequest._id, "frozen");
					await updateAttendeeStatus(
						eventRequest.eventId,
						user?.id,
						"yes",
					  );
				} catch (error) {
					
					console.log(error)
				}
			}
			else {

				setCancelEventPopupInfo(prev=>({
					trigger : true,
					eventRequestId : eventRequest._id,	
					targetEventId : eventRequest.eventId,
					...(eventRequest.requestor.role === "student") && {targetUserIds : [eventRequest.requestorId] },
					...(eventRequest.requestor.role === "parent") && {
						targetUserIds : [...eventRequest.requestor.assiginedStudents].filter(val=>[...eventDetails.studentIds,{_id : eventDetails.studentId}].map(_val=>_val._id).includes(val))
					}
				}))
			}
		}
	}

	//Setting the required events for the component & filtering as well
	useEffect(() => {
		if (allEventRequests) {
			setRequiredEvents(allEventRequests)
		}
	}, [allEventRequests])

	return (
		<div className={styles.container}>
			<button className={styles.backButton} onClick={(e) => handleBackClick(e)}><ChevronLeft /> Back</button>
			<div className={styles.heading}>
				<div className={styles.header}>
					<button className={styles.desktopBackButton} onClick={(e) => handleBackClick(e)}><StubbyLeftArrow /></button>
					<div className={styles.subHeader}>
						<h2>Event Requests</h2>
						<Tooltip> <MoreInfoIcon /> </Tooltip>
					</div>
				</div>
				<p className={styles.desc}>
				</p>
				<SessionTypeDropdown {...dropdownProps} />
			</div>
			<div className={styles.cardsContainer}>
				{
					requiredEvents?.map((item) => {

						if( selectedValue === "all" || item?.requestType.toLowerCase() === selectedValue?.toLowerCase() ||  (item?.requestedEventDetails?.eventType.toLowerCase() === selectedValue.toLowerCase() ) ){

							return <EventRequestsCard 
									user={user} 
									requestor={item.requestor} 
									eventDetails={item.eventDetails} 
									requestDetails={item.requestedEventDetails} 
									requestType={item.requestType} 
									additionalInfo={item.additionalInfo} 
									agendatNotes={item.agendaNotes}
									handleDeleteClick={()=>handleDeleteClick(item._id)} 
									handleDeclineClick={()=>{setRequestPopupInfo({trigger : true, eventRequestId : item._id, eventRequestDetails: item, purpose : "confirm-decline"})}} 
									handleActionClick={() => handleActionClick(item,item.eventDetails) }/>
						}
						else return null;
						
					})
				}
			</div>

			<DeclineRequestPopUp 
				trigger={requestPopupInfo.trigger && requestPopupInfo.purpose === "confirm-decline"} 
				setTrigger={()=>{setRequestPopupInfo(prev=>({...prev, trigger : !prev.trigger, purpose : ""}))}}  
				handleDeclineClick={()=> {updateActionStatus(requestPopupInfo.eventRequestId,"frozen");
					if( requestPopupInfo.eventRequestDetails.requestType === "invite" && [ frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()].includes(requestPopupInfo.eventRequestDetails.requestedEventDetails.eventType.toLowerCase())){
						console.log("asdfbgfASDFBGFDS",requestPopupInfo.eventRequestId, user.id)
						   updateAttendeeStatus(
							requestPopupInfo.eventRequestDetails.eventId,
							user?.id,
							"no",
						  );
					}
				  }}/>
			
			<CancelEventPopUp 
				trigger={cancelEventPopupInfo.trigger}
				setTrigger={()=>setCancelEventPopupInfo(prev=>({...prev, trigger : !prev.trigger}))}
				targetEventId={cancelEventPopupInfo.targetEventId}				
				targetUserIds={cancelEventPopupInfo.targetUserIds}
				setAllEventRequests={setAllEventRequests}
				eventRequestId={cancelEventPopupInfo.eventRequestId}
			/>

			<PopupContainer trigger={requestPopupInfo.trigger && requestPopupInfo.purpose === "edit-status-change-request"} setTrigger={()=>setRequestPopupInfo(prev=>({...prev, trigger : !prev.trigger, purpose : ""}))} customCloseButton={true} >
				<RequestActionPopup 
					requestType={requestPopupInfo.eventRequestDetails?.requestType}
					setTrigger={()=>setRequestPopupInfo(prev=>({...prev, trigger : !prev.trigger, purpose : ""}))} 
					headerName={ requestPopupInfo.eventRequestDetails?.requestType === "cancel" ? "Cancel Event" : "Reschedule Event"} 
					placeholderText={  requestPopupInfo.eventRequestDetails?.requestType === "cancel" ? "Briefly describe the reason for requesting cancellation" : "Briefly describe the reason for rescheduling along with a suitable date for rescheduling the session."}
					noteText={requestPopupInfo.eventRequestDetails?.requestType === "cancel" ? "Your admin/tutor would need to approve this cancellation request." : "Your admin/tutor would need to approve this reschedule request."}
					eventId={requestPopupInfo.eventRequestDetails?._id}
					eventType={selectedEventType}
					setAllEventRequests={setAllEventRequests}
					isUpdating={true}
					eventRequestDetails={requestPopupInfo.eventRequestDetails}
				/>
			</PopupContainer>

            <PopupContainer
              trigger={requestPopupInfo.trigger && requestPopupInfo.purpose === "edit-new-event-request"}
			  setTrigger={()=>setRequestPopupInfo(prev=>({...prev, trigger : !prev.trigger, purpose : ""}))}
              customCloseButton={true}
            >
              <EventRequestPopup
                {...props}
				{...{
					setTrigger:()=>setRequestPopupInfo(prev=>({...prev, trigger : !prev.trigger, purpose : ""}))
				}}
				eventRequest={requestPopupInfo.eventRequestDetails}
				eventRequestId={requestPopupInfo.eventRequestId}
				isEditing={true}
              />
            </PopupContainer>

		</div>
	)

}
export default EventRequests
