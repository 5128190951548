import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLazySubscriptionInsightsQuery } from "../../../../app/services/subscription";
import YourPlanSubscription from "./YourPlanSubscription";
import CancelSubscriptionPopUp from "./CancelSubscriptionPopUp";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";

const calculateDaysUntilRenewal = (startDate, endDate) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  if (isNaN(startDateObj) || isNaN(endDateObj)) {
    console.error("Invalid date(s) provided.");
    return "Invalid date";
  }

  // Calculate the difference in milliseconds
  const timeDifference = endDateObj.getTime() - startDateObj.getTime();

  // Convert milliseconds to days
  const daysUntilRenewal = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysUntilRenewal >= 0 ? daysUntilRenewal : "0";
};

const getNextYearsDay = (date) => {
  let givenDate = new Date(date);

  // Add one year to the date
  let nextYearDate = new Date(givenDate);
  nextYearDate.setFullYear(givenDate.getFullYear() + 1);

  let nextYearDateStr = nextYearDate.toISOString();
  return nextYearDateStr;
};

const Subscription = () => {
  const { subscriptionV2 } = useSelector((state) => state.subscription);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionCost, setSubscriptionCost] = useState(null);
  const [openCancelSub, setOpenCancelSub] = useState(false);
  const [productTotal, setProductTotal] = useState(0);
  const [planType, setPlanType] = useState(0);
  const [getSubscriptionInsights, respGetSubscriptionInsights] =
    useLazySubscriptionInsightsQuery();
  const [packageName, setPackageName] = useState(
    subscriptionData?.basePackage?.name.split("_")[1]
  );
  const [isLoading, setIsLoading] = useState(true);

  const productInsights = {
    Calendar: {
      insights: {
        remainingFromLimit: null,
        limit: null,
        heading: "sessions remaining",
        percentage: null,
      },
    },
    Assignments: {
      insights: {
        remainingFromLimit: null,
        limit: null,
        heading: "credits remaining",
        percentage: null,
      },
    },
    "Custom Tests": {
      insights: {
        remainingFromLimit: null,
        limit: null,
        heading: "tests remaining",
        percentage: null,
      },
    },
  };

  const getInsights = async () => {
    try {
      const res2 = await getSubscriptionInsights();
      if (!res2.error) {
        setSubscriptionData(res2.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInsights();
  }, []);

  useEffect(() => {
    setPlanType(
      subscriptionV2?.subscription?.__t === "MonthlySubscription" ? 0 : 1
    );
  }, [subscriptionV2?.subscription]);

  useEffect(() => {
    setPackageName(subscriptionData?.basePackage?.name.split("_")[1]);
  }, [subscriptionData?.basePackage]);

  useEffect(() => {
	
	if(!subscriptionV2?.subscription.__t === "FreeSubscription"){
		setSubscriptionCost({
		  basePackage: subscriptionV2?.siMapping.find((item) =>
			item.lookupKey.includes("BasicPackage")
		  ).price,
		  products: {
			crm: 0,
			assignments: subscriptionV2?.siMapping.find((item) =>
			  item.lookupKey.includes("Assignments")
			).price,
			calendar: subscriptionV2?.siMapping.find((item) =>
			  item.lookupKey.includes("Calendar")
			).price,
			customContent: subscriptionV2?.siMapping.find((item) =>
			  item.lookupKey.includes("CustomContent")
			).price,
		  },
		  tutorLicenses: {
			price: subscriptionV2?.siMapping.find((item) =>
			  item.lookupKey.includes("Tutor")
			).price,
			quantity: subscriptionV2?.siMapping.find((item) =>
			  item.lookupKey.includes("Tutor")
			).quantity,
		  },
		});

	}else {

		setSubscriptionCost({
		  basePackage: 0.0,
		  products: {
			crm: 0,
			assignments: 0.0,
			calendar: 0.0,
			customContent: 0.0,
		  },
		  tutorLicenses: {
			price: 0.0,
			quantity: 0,
		  },
		});
	}
  }, [subscriptionV2?.siMapping]);
	

  useEffect(() => {
    if (!subscriptionCost?.products) return;
    setProductTotal(
      subscriptionCost.products.assignments +
        subscriptionCost.products.customContent +
        subscriptionCost.products.calendar
    );
  }, [subscriptionCost?.products]);

  return (
    <div className="grid gap-[16px]">
      {isLoading ? (
        <div className="flex justify-center items-center h-[40vh]">
          <LoaderIcon />
        </div>
      ) : (
        <div className="grid gap-[16px]">
          <div className="grid gap-[16px]">
            <div className="text-secondary text-[16px] font-[500] leading-[24px]">
              Current Plan
            </div>
            {subscriptionData &&
              subscriptionCost?.products &&
              (subscriptionV2?.stripeSubscription || subscriptionV2?.subscription.__t === "FreeSubscription")&& (
                <YourPlanSubscription
                  packageName={packageName}
                  subscriptionV2={subscriptionV2}
                  subscriptionData={subscriptionData}
                  subscriptionCost={subscriptionCost}
                  planType={planType}
                  stripeSubscription={subscriptionV2?.stripeSubscription}
                />
              )}
          </div>
          <div></div>
          <div className="grid gap-[16px]">
            <div className="text-secondary text-[16px] font-[500] leading-[24px]">
              Your subscription cost includes
            </div>
            <div className="flex flex-col md:flex-row gap-[16px] md:gap-[64px]">
              <div>
                {subscriptionCost?.basePackage ? (
                  <>
                    <div className="text-secondary-80 text-[14px] font-[400]">
                      {packageName?.includes("Free") ? "Explorer" : packageName}
                      &nbsp;Plan
                      {subscriptionV2?.subscription?.basePackage.name.includes(
                        "Free"
                      )
                        ? ""
                        : `(${planType === 0 ? "Monthly" : "Annual"})`}
                    </div>
                    <div>
                      <span className="text-secondary font-[500] text-[18px]">
                        $
                        {planType === 0
                          ? subscriptionCost?.basePackage
                          : (subscriptionCost?.basePackage / 12).toFixed(1)}
                      </span>
                      /mo
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="text-secondary-80 text-[14px] font-[400]">
                      Explorer Plan 
                    </div>
                    <div>
                      <span className="text-secondary font-[500] text-[18px]">
                        $0.0
                      </span>
                      /mo
                    </div>
                  </>
                )}
              </div>
              <div className="border-b md:border-r border-secondary-10 min-w-[100%] md:min-w-0 md:min-h-[30px]"></div>
              <div>
                {subscriptionCost?.products && (
                  <>
                    <div className="text-secondary-80 text-[14px] font-[400]">
                      Tutor Licenses
                    </div>
                    <div>
                      <span className="text-secondary font-[500] text-[18px]">
                        ${" "}
                        {planType === 0
                          ? subscriptionCost?.tutorLicenses.price *
                            subscriptionCost?.tutorLicenses.quantity
                          : (
                              (subscriptionCost?.tutorLicenses.price *
                                subscriptionCost?.tutorLicenses.quantity) /
                              12
                            ).toFixed(1)}
                      </span>
                      /mo for &nbsp;
                      <span className="text-secondary font-[500] text-[18px]">
                        {subscriptionCost?.tutorLicenses.quantity}
                      </span>
                      &nbsp; Licenses
                    </div>
                  </>
                )}
              </div>
              <div className="border-b md:border-r border-secondary-10 min-w-[100%] md:min-w-0 md:min-h-[30px]"></div>
              <div>
                {subscriptionCost?.products && (
                  <>
                    <div className="text-secondary-80 text-[14px] font-[400]">
                      Product add-ons
                    </div>
                    <div>
                      <span className="text-secondary font-[500] text-[18px]">
                        $
                        {planType === 0
                          ? productTotal
                          : (productTotal / 12).toFixed(1)}
                      </span>
                      /mo for &nbsp;
                      <span className="text-secondary font-[500] text-[18px]">
                        3
                      </span>
                      &nbsp; products
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div></div>
          <div className="text-[14px] md:text-[16px] font-[400] w-full md:w-[75%] text-secondary-60">
            *Subscription cost includes all purchases on top of the base plan,
            such as additional Tutor Licenses or additional product quantities.
            For a detailed breakdown of features across all plans, please visit
            our
            <span className="text-blue-70 cursor-pointer">
              <a href="https://www.evallo.org/pricing" target="_blank">
                &nbsp;pricing page.
              </a>
            </span>
          </div>
          <div></div>
						{subscriptionV2?.subscription.__t !== "FreeSubscription" ? 
							<>

								{!subscriptionV2?.stripeSubscription?.cancel_at_period_end ===
									true && (
										<div
											className="text-secondary-60 text-[14px] font-[400] w-fit border-b pb-[1px]  border-secondary-60 cursor-pointer"
											onClick={() => {
												setOpenCancelSub(true);
											}}
										>
											Cancel Subscription
										</div>
									)}
							</>
							: <></>}
						{openCancelSub && (
							<CancelSubscriptionPopUp
								open={openCancelSub}
								setOpen={setOpenCancelSub}
							/>
						)}
        </div>
      )}
    </div>
  );
};

export default Subscription;
