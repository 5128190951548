import React, { useState, useRef, useEffect } from "react";
import styles from "./SelectStudent.module.css";
import Radio from "@mui/material/Radio";
import "../../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import Checkbox from "@mui/material/Checkbox";
import { Avatar } from "@mui/material";
import { Close } from "@mui/icons-material";
import StudentList from "../StudentList/StudentList";

const studentList = [
  {
    name: "Alex Stannis",
    email: "alexsample@gmail.com",
    color: "#8B4513",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },

  {
    name: "Stanford Bennett",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Stan Anderson",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },
  {
    name: "Maria Stanhope",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/noshow.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },

  {
    name: "John Stanbury",
    email: "alexsample@gmail.com",
    color: "#8B4513",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/skipped.svg",
    action: "Do not invite",
  },
  {
    name: "Jessica Stanmore",
    email: "alexsample@gmail.com",
    color: "#8B4513",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Mark Stancliff",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Kristen Stanton",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Stan Anderson",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },
  {
    name: "Jessica Stanmore",
    email: "alexsample@gmail.com",
    color: "#8B4513",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Mark Stancliff",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Kristen Stanton",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Stan Anderson",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },
  {
    name: "Kristen Stanton",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Stan Anderson",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },
  {
    name: "Kristen Stanton",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Stan Anderson",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },
];
const SelectStudent = (props) => {
  const {
    selectedStudents,
    setSelectedStudents,
    studentList,
    parentStundentList,
    isEnabledEditing,
  } = props;
  const [open, setOpen] = useState(false);
  const [showStudents, setShowStudents] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);
  const modalRef = useRef(null);

  console.log(parentStundentList, isEnabledEditing)

  const [showSelectList, setShowSelectList] = useState(false);
  const handleOutsideModalClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleViewAllClick();
    }
  };
  const handleViewAllClick = () => {
    setShowSelectList(!showSelectList);
  };
  const handleToggle = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);

    props?.user &&
      props?.user?.role != "student" &&
      setShowStudents(!showStudents);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelect = (student) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(student)
        ? prevSelected.filter((s) => s._id !== student._id)
        : [...prevSelected, student]
    );
  };
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      toggleRef.current &&
      !toggleRef.current.contains(event.target)
    ) {
      setOpen(false);
      setShowStudents(true);
    }
  };
  const filteredStudents = studentList.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);
  console.log("show students", showStudents);
  useEffect(() => {
    if (showSelectList) {
      document.addEventListener("mousedown", handleOutsideModalClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideModalClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideModalClick);
    };
  }, [showSelectList]);
  console.log("showSelectList", showSelectList);
  console.log("selectedStudents", selectedStudents);

  return (
    <>
      <div className={styles.container}>
        <div
          className={`${styles.inputField} ${
            !showStudents && selectedStudents.length > 0
              ? styles.selected
              : styles.inputField
          }`}
          onClick={handleToggle}
          ref={toggleRef}
        >
          <div
            className={` flex items-center gap-[5px] justify-start cursor-default  w-full flex-1 `}
          >
            {props?.user && props?.user?.role != "student" && (
              <div
                className={`${styles.selectedStudentsWrapper} ${
                  showStudents && selectedStudents.length > 5 ? styles.more : ""
                }`}
              >
                {!showStudents &&
                  selectedStudents.map((student, index) => (
                    <div
                      key={index}
                      className={styles.studentNameTag}
                      onClick={(e) =>
                        !showStudents ? e.stopPropagation() : ""
                      }
                    >
                      <div className={styles.studentName}>
                        {student.name.split(" ")[0]}{" "}
                        {student.name.split(" ")[1][0]}.
                      </div>

                      <Close
                        sx={{
                          color: "#25335A",
                          fontSize: 14,
                          cursor: "pointer",
                          ml: 2,
                        }}
                        onClick={() => handleSelect(student)}
                      />
                    </div>
                  ))}
              </div>
            )}
            {selectedStudents.length === 0 ? (
              <div className={styles.textWrapper}>Select Student*</div>
            ) : (
              showStudents && (
                <div
                  className=" flex items-center justify-center gap-2  w-full mr-5 "
                  style={{
                    justifyContent:
                      selectedStudents.length > 5 ? "space-between" : "start",
                  }}
                >
                  {showStudents &&
                    selectedStudents.slice(0, 5).map((student, index) => (
                      <div key={index} className={styles.studentNameTag}>
						{
							student.name && 
								<div className={styles.studentName}>
								  {student.name.split(" ")[0]}{" "}
								  {student.name.split(" ")[1][0]}.
								</div>
						}
						{
							!student.name &&
								<div className={styles.studentName}>
								  {student.firstName}{" "}
								  {student.lastName}.
								</div>
						}
                      </div>
                    ))}
                  {showStudents && selectedStudents.length > 5 && (
                    <div className={styles.moreCount}>
                      +{selectedStudents.length - 5} more
                    </div>
                  )}
                </div>
              )
            )}
          </div>

          {props?.user && props?.user?.role != "student" && (
            <img
              className={styles.inputFieldIcon}
              src="/images/down-arrow.svg"
              alt="Input Field Icon"
              style={{
                display: isEnabledEditing ? "" : "none",
              }}
            />
          )}
        </div>
        {props?.user &&
          props?.user?.role != "student" &&
          props?.user?.role != "parent" &&
          showStudents &&
          selectedStudents.length > 0 && (
            <>
              <div
                className={styles.viewAll}
                style={{
                  textAlign: "right",
                  padding: 3,
                  cursor: "pointer",
                  display: isEnabledEditing ? "" : "none",
                }}
                onClick={handleViewAllClick}
              >
                View All
              </div>
            </>
          )}

        {props?.user && props?.user?.role != "student" && open && (
          <div className={styles.studentDropdown} ref={dropdownRef}>
            <div className={styles.searchDropdown}>
              <div className={styles.searchCalendar}>
                <img className={styles.shapeSearch} src="/images/search.svg" />
                <input
                  className={styles.searchInput}
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
              {filteredStudents.map((student, index) => (
                <div
                  key={index}
                  className={styles.person}
                  onClick={() => handleSelect(student)}
                >
                  <input
                    className={styles.checkbox}
                    type="checkbox"
                    checked={selectedStudents
                      .map((val) => val._id)
                      .includes(student._id)}
                    //   onChange={() => handleSelect(student)}
                  />{" "}
                  <Avatar
                    alt={student.name.toUpperCase()}
                    src={student.awsLink + student.photo}
                    sx={{
                      width: 24,
                      height: 24,
                      bgcolor: student.color,
                      fontSize: "0.6rem",
                    }}
                  />{" "}
                  <div className={styles.div}>
                    <div className={styles.textWrapper2}>{student.name}</div>
                    <div className={styles.textWrapper3}>{student.email}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {showSelectList && (
        <>
          <div className={styles.modalOverlay} onClick={handleViewAllClick}>
            <div
              ref={modalRef}
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <StudentList
                selectedStudents={selectedStudents}
                studentData={
                  props?.user && props?.user?.role === "parent"
                    ? parentStundentList
                    : studentList
                }
                studentStatusMapping={props.studentStatusMapping}
                setStudentStatusMapping={props.setStudentStatusMapping}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SelectStudent;
