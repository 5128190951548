import React, { useEffect, useState } from "react";
import CRM from "./CRM.svg";
import Assignment from "./Assignments.svg";
import Calendar from "./Calendar.svg";
import CustomTest from "./CustomContent.svg";
import ProductCard from "./ProductCard";
import { useLazyGetPricesV2Query } from "../../app/services/subscription";
import getConvertedPlanType from "./planTypeConverter";
const colors = {
  CRM: "#2792CD",
  Calendar: " #EE778C",
  Assignment: "#8D54D7",
  CustomContent: "#17A289",
};

const ProductCardDisplay = ({
  productPrices,
  selectedPlanType = 0,
  selectedProducts,
  setSelectedProducts,
  idx,
}) => {
  const [selectedCalendar, setSelectedCalendar] = useState(true);
  const [selectedPriceCalendar, setSelectedPriceCalendar] = useState(30);
  const [selectedAssignment, setSelectedAssignment] = useState(true);
  const [selectedPriceAssignment, setSelectedPriceAssignment] = useState(0);
  const [selectedCustomTest, setSelectedCustomTest] = useState(true);
  const [selectedPriceCustomTest, setSelectedPriceCustomTest] = useState(8);

  useEffect(() => {
    console.log(selectedPriceAssignment);
  }, [
    selectedPriceAssignment,
    selectedPriceCalendar,
    selectedPriceCustomTest,
    selectedCalendar,
  ]);
  //Static products
  const Products = [
    {
      name: "CRM",
      title: "Unlimited",
      priceList: null,
      img: CRM,
      selected: true,
      setSelected: () => {
        console.log(">>CRM TRIGGERED");
      },
      selectedPrice: 0,
      setSelectedPrice: () => {},
      color: "#2793cd",
    },
    {
      name: "Calendar",
      title: selectedPlanType === 0 ? "Sessions/mo" : "Sessions/mo",
      priceList: [
        { name: "1500 nos.- $30", value: 30 },
        { name: "1500 nos.- $30", value: 30 },
      ],
      img: Calendar,
      selected: selectedCalendar,
      setSelected: setSelectedCalendar,
      selectedPrice: selectedPriceCalendar,
      setSelectedPrice: setSelectedPriceCalendar,
      color: "#EE778C",
    },
    {
      name: "Assignments",
      title: selectedPlanType === 0 ? "Credits/mo" : "Credits/mo",
      priceList: [{ name: "15 nos.- $0", value: 0 }],
      img: Assignment,
      selected: selectedAssignment,
      setSelected: setSelectedAssignment,
      selectedPrice: selectedPriceAssignment,
      setSelectedPrice: setSelectedPriceAssignment,
      color: "#8D54D7",
    },
    {
      name: "Custom Tests",
      title: selectedPlanType === 0 ? "Tests/mo" : "Tests/mo",
      priceList: [{ name: "Unlimited - $8", value: 8 }],
      img: CustomTest,
      selected: selectedCustomTest,
      setSelected: setSelectedCustomTest,
      selectedPrice: selectedPriceCustomTest,
      setSelectedPrice: setSelectedPriceCustomTest,
      color: "#17A289",
    },
  ];

  const [augmentedProducts, setAugmentedProducts] = useState(null);

  useEffect(() => {
    if (!productPrices) return;

    const tempProductPrices = [...productPrices];
    const requiredProductsMapping = {
      Calendar: { productName: "CalendarV2" },
      "Custom Tests": { productName: "CustomContentV2" },
      Assignments: { productName: "AssignmentsV2" },
    };
    console.log(">>>PRODUCT PRICES", productPrices);

    Products.forEach((product, _idx) => {
      if (_idx === 0) return;
      const requiredProduct = tempProductPrices.find(
        (_product, __idx) =>
          _product.product.name ===
          requiredProductsMapping[product.name].productName
      );
      const tempPriceList = {};
      for (const planType in requiredProduct.prices) {
        tempPriceList[planType] = [];
        const prices = requiredProduct.prices[planType];
        const tempPrices = [];
        prices.forEach((price) => {
          const tempPrice = {
            ...price,
            name: `${
              price.metadata.limit === "-1" ? "Unlimited" : price.metadata.limit
            } nos.- \$${Math.floor(price.unit_amount / 100)}`,
            value: price.id,
          };
          tempPrices.push(tempPrice);
        });
        tempPriceList[planType] = tempPrices;
      }
      product.priceList = tempPriceList[
        selectedPlanType === 0
          ? "monthly"
          : selectedPlanType === 1
          ? "annual"
          : ""
      ].filter((price, __idx) => !price.lookup_key.includes("PAYG"));
    });

    const tempProducts = [...Products];
    setAugmentedProducts([...tempProducts]);

    // Convert to recommended selection if nothing is selected
    const __package = selectedProducts[idx];
    for (const product in __package) {
      if (__package[product].default === false) {
        console.log(">>>RETURN");
        return;
      }
    }

    const tempSelectedProducts = [...selectedProducts];
    let requiredProducts = tempSelectedProducts[idx];
    requiredProducts = {
      ...requiredProducts,
      Calendar: {
        price:
          Products.find((product) => product.name === "Calendar").priceList[0]
            .unit_amount / 100,
        value: Products.find((product) => product.name === "Calendar")
          .priceList[0].id,
        selected: false,
        default: true,
      },
      Assignments: {
        price:
          Products.find((product) => product.name === "Assignments")
            .priceList[0].unit_amount / 100,
        value: Products.find((product) => product.name === "Assignments")
          .priceList[0].id,
        selected: false,
        default: true,
      },
      "Custom Tests": {
        price:
          Products.find((product) => product.name === "Custom Tests")
            .priceList[0].unit_amount / 100,
        value: Products.find((product) => product.name === "Custom Tests")
          .priceList[0].id,
        selected: false,
        default: true,
      },
    };
    tempSelectedProducts[idx] = requiredProducts;
    setSelectedProducts(tempSelectedProducts);
  }, [productPrices, selectedPlanType]);

  useEffect(() => {
    console.log(">>>SP", selectedProducts);
  }, [selectedProducts]);

  return (
    <div className="grid grid-cols-2 gap-[20px]">
      {augmentedProducts?.map((product, index) => (
        <ProductCard
          key={index}
          name={product.name}
          img={product.img}
          title={product.title}
          priceList={product.priceList}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          color={product.color}
          idx={idx}
          selectedPlanType={selectedPlanType}
        />
      ))}
    </div>
  );
};

export default ProductCardDisplay;
