import axios from "axios";
import { getAuthHeader } from "../app/constants/constants";

async function updateUserSchedulePreference(user, schedule) {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/user/schedule/preference`,
      {
        user: user,
        schedule: schedule,
      },
      { headers: getAuthHeader() }
    );
    console.log(res);
    return res;
  } catch (error) {
    console.error(`Error updating user schedule for ID ${user._id}:`, error);
  }
}


async function getTimeSlotInfo(orgId, userId, startDate, targetTimezone, duration){

	try {
		const res = await axios({
			method : "GET",
			url : (new URL(`${process.env.REACT_APP_BASE_URL}api/users/v2/meeting-time-slots?targetTimezone=${targetTimezone}&startDate=${startDate}&userId=${userId}&orgId=${orgId}&duration=${duration}`)).href
		})
		return res.data.data.availableSlots

	}catch(err){
		console.error(`Error updating user schedule for ID ${user._id}:`, err);
		throw err
	}

}

export { 
	updateUserSchedulePreference,
	getTimeSlotInfo
};
