export default function NextArrowRoundIcon(props) {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<rect width="16" height="16" rx="8" fill="#0671E0"/>
			<path d="M4.5 8H11.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
			<path d="M8 4.5L11.5 8L8 11.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>

	)
}
