import React, { useEffect, useState } from "react";
import {
  getModifiedProducts,
  addMatchingPrices,
} from "./ModificationDetailsHelper";

const formatDateFromEpoch = (stripeDate, formatType = 1) => {
  const timestampInSeconds = parseInt(stripeDate, 10); // Parse the Stripe date as an integer
  const dateObj = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const monthIndex = dateObj.getMonth();

  if (formatType === 1) {
    return `${monthNames[monthIndex]} ${day}, ${year}`;
  } else if (formatType === 2) {
    const dayIndex = dateObj.getDay();
    const dayName = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][dayIndex];
    return `${dayName}, ${monthNames[monthIndex]} ${day}, ${year}`;
  } else {
    return "Invalid format type";
  }
};

const getPriceName = (name, price) => {
  if (name.includes("BasicPackage")) {
    return name.split("_")[1];
  } else if (name.includes("Tutor")) {
    return price.quantity + " Licenses";
  } else if (name.includes("Assignments")) {
    return price.priceDetails.metadata.limit + " Credits";
  } else if (name.includes("Calendar")) {
    if (price.priceDetails.metadata.limit == -1) {
      return "Unlimited Credits";
    } else {
      return price.priceDetails.metadata.limit + " Credits";
    }
  } else if (name.includes("Custom")) {
    if (price.priceDetails.metadata.limit == -1) {
      return "Unlimited Custom Tests";
    } else {
      return price.priceDetails.metadata.limit + " Custom Tests";
    }
  } else {
    return name.split("_")[0];
  }
};

const getProductName = (name) => {
  if (name.includes("BasicPackage")) {
    return "Subscription Plan";
  } else if (name.includes("Tutor")) {
    return "Tutor Licenses";
  } else if (name.includes("Custom")) {
    return "Custom Tests";
  } else {
    return name.split("_")[0];
  }
};

const SubscriptionModificationDetails = ({
  phases,
  stripeSubscription,
  productPrices,
  planType,
}) => {
  const [modifiedProducts, setModifiedProducts] = useState([]);

  useEffect(() => {
    if (!productPrices) return;
    const modifications = getModifiedProducts(phases[0], phases[1]);

    // Add matching prices to the modifications
    const modificationsWithPrices = modifications.map((modification) => ({
      current: {
        ...modification.current,
        priceDetails: addMatchingPrices(modification.current, productPrices),
      },
      changed: {
        ...modification.changed,
        priceDetails: addMatchingPrices(modification.changed, productPrices),
      },
    }));

    // Sorting modificationsWithPrices for "BasicPackage"
    modificationsWithPrices.sort((a, b) => {
      const keyA = a.current.priceDetails.lookup_key.includes("BasicPackage")
        ? 0
        : 1;
      const keyB = b.current.priceDetails.lookup_key.includes("BasicPackage")
        ? 0
        : 1;
      return keyA - keyB;
    });

    setModifiedProducts(modificationsWithPrices);
  }, [phases, productPrices, planType]);

  return (
    <div className="grid gap-[12px] cursor-default">
      <div className="grid gap-[6px]">
        {" "}
        <div className="text-[14px] text-secondary font-[500]">
          Subscription Changes
        </div>
        <div className="border-b border-secondary-10"></div>
        <div className="text-secondary-60 text-[12px] font-[400]">
          Changes Made on:{" "}
          <span className="text-[12px] text-secondary font-[400] cursor-default">
            {" "}
            {formatDateFromEpoch(stripeSubscription?.schedule.created, 1)}
          </span>
        </div>
        <div className="text-secondary-60 text-[12px] font-[400]">
          Effective from:{" "}
          <span className="text-[12px] text-secondary font-[400] cursor-default">
            {formatDateFromEpoch(
              stripeSubscription?.schedule.current_phase.end_date,
              1
            )}
          </span>
        </div>
        <div className="border-b border-secondary-10"></div>
        <div className="text-[12px] text-secondary">Changes:</div>
        <div className="grid gap-[8px]">
          {modifiedProducts.map((modification, index) => (
            <div key={index} className=" gird gap-[2px]">
              <div className="text-[12px] text-secondary font-[400]">
                {getProductName(modification.current.priceDetails.lookup_key)}
              </div>
              <div className="text-secondary-60 text-[12px] font-[400]">
                {modification.current && modification.changed && (
                  <div className="grid ">
                    <div>
                      Current:{" "}
                      {getPriceName(
                        modification.current?.priceDetails?.lookup_key,
                        modification.current
                      )}{" "}
                      (
                      {modification.current?.priceDetails?.unit_amount === 0
                        ? "Free"
                        : planType === 0
                        ? "$" +
                          modification.current?.priceDetails?.unit_amount /
                            100 +
                          "/mo"
                        : "$" + (
                            modification.current?.priceDetails?.unit_amount /
                              100 /
                              12
                          ).toFixed(1) + "/mo"}
                      )
                    </div>
                    <div>
                      Revised:{" "}
                      {getPriceName(
                        modification.changed?.priceDetails?.lookup_key,
                        modification.changed
                      )}{" "}
                      (
                      {modification.changed?.priceDetails?.unit_amount === 0
                        ? "Free"
                        : planType === 0
                        ? "$" +
                          modification.changed?.priceDetails?.unit_amount /
                            100 +
                          "/mo "
                        : "$" +(
                            modification.changed?.priceDetails?.unit_amount /
                              100 /
                              12
                          ).toFixed(1) + "/mo "}
                      )
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionModificationDetails;
