import React, { useEffect, useState } from 'react'
import CrossIcon from '../CrossIcon/CrossIcon'
import { useSelector } from 'react-redux';
import LoaderIcon from '../LoaderIcon/LoaderIcon';
import { toast } from 'react-toastify';
import { createEventRequest, fetchAllEventRequests, updateEventRequest } from '../../utils/eventService';
import { triggerToast } from '../../utils/toastController';
import { fetchAndSetAllEventRequests } from '../../pages/CalendarV2/helpers/fetchers';

const RequestActionPopup = ({ requestType,headerName, placeholderText, setTrigger, noteText, eventId, eventType, setAllEventRequests, isUpdating, eventRequestDetails  }) => {

  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [reasonText, setReasonText] = useState("");

  const submitRequest = async()=>{
		
		setIsLoading(true);
		if(reasonText?.trim() === ""){ 
			toast.warn("Please briefly describe the reason.")
			return;
		}

		if(isUpdating){
			try {
				const res = await updateEventRequest(eventRequestDetails._id,{
					"additionalInfo" : reasonText
				})
				triggerToast("Event request updated.", "success", {autoClose : 3000})
				fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)
			}catch(err){
				console.error(err)
			}finally{
				setIsLoading(false)
				setTrigger(false)
			}
			return 
		}


		try {
			const fields ={
				eventId: eventId,
				requestType: requestType,
				additionalInfo: reasonText,
				requestedEventDetails : {
					eventType : eventType ? eventType : "session",
				}
			}
			const resData = await createEventRequest(fields)
			triggerToast("Event request sent.", "success", {autoClose : 3000})
			fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)
		} catch (error) {
			console.error(error);
		}finally {
			setIsLoading(false)
			setTrigger(false)
		}
  }


 useEffect(()=>{
	if(isUpdating && eventRequestDetails){
		setReasonText(eventRequestDetails.additionalInfo)	
	}
 },[isUpdating, eventRequestDetails])



  return (
    <main className=' w-full md:w-[640px] h-fit bg-white flex flex-col gap-[36px] '>

        {/* Top navbar */}
        <section  className=' w-full  flex justify-between items-center pb-[10px] border-b-[1px] border-b-[#45A0FF] ' >
            <p  className='  text-[24px] leading-[36px] text-[#00468E] font-medium '>{headerName}</p>
            <div className='cursor-pointer' onClick={() => setTrigger(false)}>
              <CrossIcon />
            </div>
        </section>

        {/* TextBox area section */}
        <textarea value={reasonText} className='resize-none py-[14px] px-[16px] outline-none rounded-[6px] border-[1px] h-[96px] border-secondary-20 text-[16px] leading-[24px] text-secondary-60 font-normal ' placeholder={placeholderText} onChange={(e)=> setReasonText(e.target.value)} ></textarea>

        {/* Note Area */}
        <p className=' text-[16px] leading-[24px] font-medium text-secondary-80 '>Note: <span className=' text-secondary-60 !font-normal ' >{noteText}</span></p>

        {/* Submit and Cancel Button Section */}

        <section className='  w-full justify-between md:justify-end items-center  flex gap-[8px]  md:gap-[6px]' >
          <button disabled={isLoading} className=' py-[10px] px-[24px]   w-full md:w-fit  border-[2px] border-secondary-60 rounded-[6px] outline-none text-[16px] leading-[20px] font-semibold text-secondary-60' onClick={()=> setTrigger(false)} >Cancel</button>
          <button disabled={isLoading}  className=' py-[10px] px-[24px] w-full md:w-fit flex items-center min-w-[118.15px] justify-center  bg-[#0671E0] border-[2px] border-[#0671E0] rounded-[6px] text-[16px] outline-none leading-[20px] font-semibold text-white ' onClick={submitRequest} >{isLoading ?  <LoaderIcon/> : isUpdating ? "Save Changes"  : "Submit" } </button>
        </section>


    </main>
  )
}

export default RequestActionPopup

