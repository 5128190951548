import { fetchAllEventRequests, scheduleOfficeWork, updateEventRequest } from "../../../utils/eventService";
import { triggerToast } from "../../../utils/toastController";
import { fetchAndSetAllEventRequests } from "./fetchers";
import { getTimeInFormat } from "./utils";

async function handleScheduleOfficeWork({
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedTimeZone,
    isSelectedUser,
    hourlyCost,
    infoWork,
    studentList,
	isEditingRequest,
	eventRequestId,
	setAllEventRequests
}) {

    console.log(">>>>>>Officr")

    let data = {
        time: {
            start: getTimeInFormat(selectedStartTime),
            end: getTimeInFormat(selectedEndTime),
        },
        timeZone: selectedTimeZone,
        attendeeDetails: isSelectedUser.map((user) => ({
            id: user,
        })),
        invoiceDetails: {
            hourlyPrice: hourlyCost,
        },
        additionalInfo: infoWork,
        date: selectedDate.toISOString(),
    };

    try {
        await scheduleOfficeWork(data);
        triggerToast("Office work scheduled successfully", "success", {
            autoClose: 3000,
        });
		if(isEditingRequest){
			const res = await updateEventRequest(eventRequestId, {
				"targetUserDetails.actionStatus" : "frozen"
			})
			fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)
		}

    } catch (error) {
        console.error("Error scheduling office work:", error);
        triggerToast("Some error occurred", "error", { autoClose: 3000 });
    }
}


export default handleScheduleOfficeWork
