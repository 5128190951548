import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RevenueCostCard from "./RevenueCostCard";
import { getAllEvents } from "../../../utils/eventService";
import { transformEvents } from "../../../utils/eventUtils";
// import { sessionStatusMapping } from "../SectionOne/SectionOne";

const sessionStatusMapping = {
  scheduled: ["scheduled", "SCH", "Scheduled", "SCHEDULED", "sch"].map((val) =>
    val.toLowerCase()
  ),
  completed: ["COMPLETED", "completed"].map((val) => val.toLowerCase()),
  noShow: ["NOSHOW", "no-show", "noshow", "noShow", "Noshow"].map((val) =>
    val.toLowerCase()
  ),
  cancelled: ["CANCEL", "cancel", "cancelled", "canceled"].map((val) =>
    val.toLowerCase()
  ),
};

const RevenueCost = (props) => {
  console.log("props in revenue", props);
  const user = useSelector((state) => state.user);
  const filteredData = useSelector((state) => state.filters.filteredData);
  const [allEvents, setAllEvents] = useState([]);
  const [projectedRevenue, setProjectedRevenue] = useState(0);
  const [projectedCost, setProjectedCost] = useState(0);
  const [completedCost, setCompletedCost] = useState(0);
  const [leakedCost, setLeakedCost] = useState(0);
  const tutorRevenue = [
    {
      _id: "3432fdsqwdf",
      name: "Projected income",
      amount: "",
      amountColor: "#25335A",
      growthPercentage: "12%",
      bgColor: "#FFF8D4",
    },
    {
      _id: "3432fdsqwdf",
      name: "Income Earned",
      amount: "",
      amountColor: "#25335A",
      growthPercentage: "12%",
      bgColor: "#EDFAF7",
    },
    {
      _id: "3432fdsqwdf",
      name: "Leaked Income",
      amount: "",
      amountColor: "#25335A",
      growthPercentage: "12%",
      bgColor: "#F4F4F4",
    },
  ];

  const nonTutorRevenue = [
    {
      _id: "3432fdsqwdf",
      name: "Projected Revenue",
      amount: "",
      amountColor: "#25335A",
      growthPercentage: "12%",
      bgColor: "#FFF8D4",
    },
    {
      _id: "3432fdsqwdf",
      name: "Projected Cost",
      amount: "",
      amountColor: "#25335A",
      growthPercentage: "12%",
      bgColor: "#EDFAF7",
    },
    {
      _id: "3432fdsqwdf",
      name: "Completed Revenue",
      amount: "",
      amountColor: "#F97066",
      growthPercentage: "12%",
      bgColor: "#FEF3F2",
    },
    {
      _id: "3432fdsqwdf",
      name: "Leaked Revenue",
      amount: "",
      amountColor: "#25335A",
      growthPercentage: "12%",
      bgColor: "#F4F4F4",
    },
  ];

  const [revenue, setRevenue] = useState(
    user.role === "tutor" ? tutorRevenue : nonTutorRevenue
  );

  const convertTo24HourFormat = (time, timeType) => {
    let [hours, minutes] = time.split(":").map(Number);
    if (timeType === "PM" && hours !== 12) {
      hours += 12;
    } else if (timeType === "AM" && hours === 12) {
      hours = 0;
    }
    return hours + minutes / 60;
  };

  const calculateTotalHours = (startTime, endTime) => {
    const startHour = convertTo24HourFormat(startTime.time, startTime.timeType);
    const endHour = convertTo24HourFormat(endTime.time, endTime.timeType);
    return endHour - startHour;
  };

  const calculateProjections = (events) => {
    let totalProjectedRevenue = 0;
    let totalProjectedCost = 0;
    let totalCompletedCost = 0;
    let totalLeakedCost = 0;

    events.forEach((event) => {
      const { time, invoiceDetails, sessionStatus } = event.extendedProps;
      console.log("sessionStatus", sessionStatus);
      if (invoiceDetails) {
        const totalHours = calculateTotalHours(time.start, time.end);

        if (
          [
            "SCH",
            "COMPLETED",
            "NOSHOW",
            "scheduled",
            "Cancelled",
            "CANCELED",
          ].includes(sessionStatus) &&
          invoiceDetails.hourlyPrice &&
          invoiceDetails.tutorCost
        ) {
          totalProjectedRevenue += invoiceDetails.hourlyPrice * totalHours;
          totalProjectedCost += invoiceDetails.tutorCost * totalHours;
        }

        // Add to totalCompletedCost for sessionStatus: COMPLETED, noshow
        if (
          ["COMPLETED", "NOSHOW"].includes(sessionStatus) &&
          invoiceDetails.hourlyPrice &&
          invoiceDetails.tutorCost
        ) {
          totalCompletedCost += invoiceDetails.tutorCost * totalHours;
        }

        // Add to totalLeakedCost for sessionStatus: CANCEL
        if (
          sessionStatus === "CANCEL" &&
          invoiceDetails.hourlyPrice &&
          invoiceDetails.tutorCost
        ) {
          totalLeakedCost += invoiceDetails.tutorCost * totalHours;
        }

        // Logging for debugging purposes
        console.log("totalProjectedRevenue", totalProjectedRevenue);
        console.log("totalProjectedCost", totalProjectedCost);
        console.log("totalCompletedCost", totalCompletedCost);
        console.log("totalLeakedCost", totalLeakedCost);
      }
    });

    setProjectedRevenue(totalProjectedRevenue);
    setProjectedCost(totalProjectedCost);
    setCompletedCost(totalCompletedCost); // Assuming you have a setter for completed cost
    setLeakedCost(totalLeakedCost); // Assuming you have a setter for leaked cost
  };

  const updateRevenue = () => {
    setRevenue((prevRevenue) =>
      prevRevenue.map((item, index) => {
        if (index === 0) {
          return { ...item, amount: `$${projectedRevenue.toFixed(2)}` };
        }
        return item;
      })
    );
  };

  const matchesStatus = (sessionStatus, selectedStatusKeys) => {
    const lowerCaseStatus = sessionStatus.toLowerCase();

    return selectedStatusKeys.some((statusKey) => {
      const mappedStatuses = sessionStatusMapping[statusKey.toLowerCase()];
      return mappedStatuses && mappedStatuses.includes(lowerCaseStatus);
    });
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
		let requiredUserIds = []
		if(user && ["tutor","parent","student"].includes(user.role)){
			if(user.role === "parent"){
				requiredUserIds = [...user.associatedStudents]		
			}
			requiredUserIds.push(user.id)
		}
		const events = await getAllEvents(requiredUserIds, user);
        let filteredEvents = events;
        if (props.selectedEventKey) {
          console.log("inside event", props.selectedStatusKeys);
          const lowerCaseKey = props.selectedEventKey.toLowerCase();
          filteredEvents = events.filter((event) =>
            event.extendedProps.eventType.toLowerCase().includes(lowerCaseKey)
          );
        }
        console.log("filteredEvents1", filteredEvents);

        if (props.selectedStatusKeys && props.selectedStatusKeys.length > 0) {
          filteredEvents = filteredEvents.filter((event) =>
            matchesStatus(
              event.extendedProps.sessionStatus,
              props.selectedStatusKeys
            )
          );
        }
        console.log("filteredEvents2", filteredEvents);
        setAllEvents(filteredEvents);
        calculateProjections(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (filteredData && filteredData.length > 0) {
      const transformedFilteredData = transformEvents(filteredData);
      let filteredTransformedEvents = transformedFilteredData;

      if (props.selectedEventKey) {
        const lowerCaseKey = props.selectedEventKey.toLowerCase();
        filteredTransformedEvents = transformedFilteredData.filter((event) =>
          event.extendedProps.eventType.toLowerCase().includes(lowerCaseKey)
        );
      }

      if (props.selectedStatusKeys && props.selectedStatusKeys.length > 0) {
        filteredTransformedEvents = filteredTransformedEvents.filter((event) =>
          matchesStatus(
            event.extendedProps.sessionStatus,
            props.selectedStatusKeys
          )
        );
      }
      setAllEvents(filteredTransformedEvents);
      calculateProjections(filteredTransformedEvents);
      if (projectedRevenue > 0 || projectedCost > 0) {
        updateRevenue();
      }
    } else {
      fetchEvents();
    }
  }, [
    filteredData,
    props.selectedEventKey,
    props.selectedStatusKeys,
    projectedRevenue,
    projectedCost,
  ]);

  // console.log("paginatedData1", allEvents);
  // console.log("projectedRevenue", projectedRevenue);
  // console.log("revuene", revenue);
  return (
    <main className=" w-full flex justify-between  overflow-x-auto max-w-[100%] gap-[8px] h-[74px] ">
      {revenue.map((data, idx) => (
        <RevenueCostCard
          key={data._id}
          details={{
            ...data,
            amount:
              idx === 0
                ? projectedRevenue
                : idx === 1
                ? projectedCost
                : idx === 2
                ? completedCost
                : idx === 3
                ? leakedCost
                : "",
          }}
        />
      ))}
    </main>
  );
};

export default RevenueCost;
