import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ApiTable from "./ApiTAble";
import { TableHeader } from "./TableHeader";
import { TableHeaderNew } from "./tableHeaderObj";
import TableItem from "./tableItem";
import Loader from "../Loader";
import styles from "./styles.module.css";
import LatestSignUpTableItem from "./LatestSignUpTableItem";
import Pagination from "../../pages/SuperadminDashboard/Table/Pagination";
import { useNavigate, Link } from "react-router-dom";
import AssignedTests from "../../pages/AssignedTests/AssignedTests";
import PaginationV2 from "../PaginationV2/PaginationV2";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import PageIndicatorV2 from "../PaginationV2/PageIndicatorV2";
export default function Table(props) {
  const navigate = useNavigate();
  const {
	showPageIndicator,
    showPartialScoreReport,
    tableclass,
    beforetable_class,
    noArrow,
    dataFor,
    answer,
    persona,
    isChecked,
    selectedId2,
    testtype,
    belowboxwidth,
    extratableitem,
    setSelectedId2,
    data,
    setCurrentPageNo,
    tableHeaders,
    alloverclass,
    maxPageSize,
    onClick,
    hidePagination,
    current_usertype,
    setMaxPageSize,
    excludes,
    total_pages,
    awsLink,
    handleAllOrgRefetch,
    isCallingApi,
    belowBoxinsidebox,
    headerObject,
    extraData,
    changePageAfterUpdate,
    isTickBoxInsideTableChecked,
    handleCheckboxChange,
    loading,
    AdminLatestSignUp,
    headerWidth,
    belowBoxLink,
    belowBox,
    belowBoxHeight,
    belowBoxText,
    belowBoxIcon,
    noScrollbar,
    dummyRowStarClients,
    setAllAssignedTests,
    setFilteredTests,
    setAllTestsForStudentTest,
    setfilteredTestsForStudentTest,
    widthFullTable,
    theadWidth,
    customScrollBarClass,
    signupTable,
    tableClass,
    indexclass,
    dateformat,
    tableWidth,
    tBodyClassName,
    adminTable,
    tutorStudentTable,
    showonlyrightpaginationpart,
    dummyrowsneeded,
    auxiliaryData,
    onViewQuestionClicked,
    subscriptionV2,
    setEventModalTrigger
  } = props;

  const [dummy, setDummy] = useState([]);
  // console.log(data,'plpoo');
  const [tableData, setTableData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const dataLength = data?.length > 30 ? 30 : data?.length;
  const [sorted, setSorted] = useState(false);
  useEffect(() => {
    let arr = [];
    let noOfkeys;
    if (headerObject) noOfkeys = Object.keys(tableHeaders)?.length;
    else noOfkeys = tableHeaders?.length;
    arr.length = noOfkeys;
    for (let i = 0; i < maxPageSize - tableData?.length; i++) {
      let curr = [];
      for (let j = 0; j < noOfkeys; j++) {
        curr.push(" Dummy ");
      }
      arr.push(curr);
    }
    setDummy(arr);
  }, [tableData]);
  useEffect(() => {
    if (changePageAfterUpdate === false) return;
    if (hidePagination === true) {
      setTableData(data);
    } else {
      const temp = data?.slice(0, maxPageSize);

      setTableData(temp);
      setSorted(temp);
      // setCurrentPage(1);
    }
  }, [data, maxPageSize, data?.length]);

  const sorting = () => {};
  useEffect(() => {
    // console.log("currentPage", currentPage);
    // setCurrentPageNo(currentPage);
    if (hidePagination === true) return;
    const temp = data?.slice(
      (currentPage - 1) * maxPageSize,
      (currentPage - 1) * maxPageSize + maxPageSize
    );
    setTableData(temp);
  }, [currentPage, data]);
  if (isCallingApi) return <ApiTable noArrow={noArrow} {...props} />;
  return (
    <div className="min-w-full">
      <div
        className={`${
          signupTable ? "!overflow-visible" : ""
        } ${beforetable_class} ${
          noScrollbar
            ? ` lg:overflow-x-auto ${
                customScrollBarClass ?? "scrollbar-content custom-scroller-2 "
              } scroll-m-1 ${styles.noOverflow} ${styles.noWidthScrollbar}`
            : `overflow-x-auto ${
                customScrollBarClass ?? "scrollbar-content custom-scroller-2"
              } scroll-m-1`
        }`}
      >
        <table
          className={`${
            dataFor === "testsDetailQuestions" ? "styles.customTable" : ""
          } ${tutorStudentTable ? styles.customTable : "customTable"}
                            ${
                              dataFor === "tutorFeedback"
                                ? "!bg-[#00000000] pl-[1px] pr-[1px] !mt-0"
                                : ""
                            }
                            ${
                              dataFor === "serviceRates"
                                ? "!bg-[#00000000] pl-[1.5px] pr-[1.5px]"
                                : ""
                            }
        mb-[4px] text-center whitespace-nowrap ${
          widthFullTable === true ? "w-full" : ""
        } ${tableClass ?? ""} ${
            adminTable &&
            `customTable mb-[4px] text-center whitespace-nowrap ${styles.customTable} `
          }`}
        >
          <thead
            className={` whitespace-nowrap bg-secondary-10 ${
              theadWidth ?? "w-[1601px] rounded-5"
            }  ${
              adminTable &&
              " h-[50px] overflow-y-hidden box-content customTable mb-[4px] text-center whitespace-nowrap"
            }
                             ${
                               dataFor === "tutorFeedback"
                                 ? "!w-[700px] !h-[50px] !rounded-[5px]"
                                 : ""
                             }
          ${adminTable && styles.customTable}`}
          >
            <tr
              className={`whitespace-nowrap bg-secondary-10 text-secondary rounded-5
                            ${
                              dataFor === "tutorFeedback"
                                ? "!rounded-[5px] !h-[50px] overflow-visible"
                                : ""
                            }
            `}
            >
              {tableHeaders.map((item, idx) => {
                return headerObject === true ? (
                  <React.Fragment key={idx}>
                    <TableHeaderNew
                      noArrow={noArrow}
                      header={item}
                      dataFor={dataFor}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment key={idx}>
                    <TableHeader
                      noArrow={noArrow}
                      key={idx}
                      header={item}
                      onClick={sorting}
                      setSorted={setSorted}
                      dataFor={dataFor}
                      headerWidth={headerWidth}
                      answer={true}
                    />
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>

          <tbody
            className={`whitespace-nowrap ${styles.tBody} ${
              tutorStudentTable
                ? `customTable mb-[4px] text-center !bg-transparent bg-white ${styles.customTable}`
                : ""
            } 
                             ${tBodyClassName}
          `}
          >
            {loading ? (
              <div
                className={`absolute w-full min-h-[100px] flex justify-center items-center`}
              >
                <div>
                  <Loader size="medium" />
                </div>
              </div>
            ) : (
              tableData?.map((item, idx) => {
                return AdminLatestSignUp ? (
                  <React.Fragment key={idx}>
                    <LatestSignUpTableItem
                      dataFor={dataFor}
                      selectedId2={selectedId2}
                      setSelectedId2={setSelectedId2}
                      item={item}
                      key={idx}
                      excludes={excludes}
                      onClick={onClick}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment key={idx}>
                    <TableItem
                      persona={persona}
                      showPartialScoreReport={showPartialScoreReport}
                      testtype={testtype}
                      extratableitem={extratableitem}
                      index={idx}
                      dataFor={dataFor}
                      selectedId2={selectedId2}
                      handleAllOrgRefetch={handleAllOrgRefetch}
                      setSelectedId2={setSelectedId2}
                      item={item}
                      key={idx}
                      excludes={excludes}
                      onClick={onClick}
                      awsLink={awsLink}
                      setAllAssignedTests={setAllAssignedTests}
                      setFilteredTests={setFilteredTests}
                      setAllTestsForStudentTest={setAllTestsForStudentTest}
                      setfilteredTestsForStudentTest={
                        setfilteredTestsForStudentTest
                      }
                      dateformat={dateformat}
                      onViewQuestionClicked={onViewQuestionClicked}
                      subscriptionV2={subscriptionV2}
                      setEventModalTrigger={setEventModalTrigger}
                    />
                  </React.Fragment>
                );
              })
            )}
            {(!tableData || (tableData && tableData.length === 0)) &&
              !belowBox &&
              dummyrowsneeded &&
              dummy.map((it, iti) => {
                return (
                  <tr
                    key={iti}
                    className="bg-white leading-8 shadow-[0px_0px_2px_rgba(0,0,0,0.25)] text-[17.5px] "
                  >
                    {it.map((d, di) => {
                      return (
                        <td
                          key={di}
                          className="opacity-0 text-[17.5px] px-[10px] min-w-14 py-4 first:rounded-l-[5.333px] last:rounded-r-[5.333px] "
                        >
                          {d}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            {/* {console.log(dummyRowStarClients)} */}
            {(!tableData || (tableData && tableData.length === 0)) &&
              dummyRowStarClients &&
              dummyrowsneeded &&
              dummyRowStarClients.map((it, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-transparent shadow-[0px_0px_2px_rgba(0,0,0,0.25)] text-[17.5px] text-[#517CA8] font-normal leading-[21px]"
                  >
                    <td className="opacity-1 text-[17.5px] px-[10px] !pt-[18.5px] !pb-[19.5px] min-w-14 py-4 ">
                      {it?.service}
                    </td>
                    <td className="opacity-1 text-[17.5px] px-[10px] !pt-[18.5px] !pb-[19.5px] min-w-14 py-4 ">
                      {it?.actively_using}
                    </td>
                    <td className="opacity-1 text-[17.5px] px-[10px] !pt-[18.5px] !pb-[19.5px] min-w-14 py-4 ">
                      {it?.total_used}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {/* <p>{data.length}{maxPageSize}</p> */}
        {belowBox && data && data.length < maxPageSize && data.length > 0 ? (
          <div
            className={`${belowBoxHeight} ${belowboxwidth} rows-box-shadow bg-white mt-[6px] rounded-5 flex items-center justify-center w-full z-50`}
          >
            <Link
              to={`/${belowBoxLink}`}
              className={`inline-block hover:no-underline hover:text-white rounded-[5.33px] bg-[#FFA28D] text-[#FFF] font-semibold py-[10px] ${
                signupTable
                  ? "pl-[15.5px] pr-[16.5px]"
                  : "pr-[16.5px] pl-[15.5px]"
              } text-base`}
            >
              {belowBoxText}
            </Link>
            <img className="inline-block pl-2" src={belowBoxIcon} alt="" />
          </div>
        ) : null}
      </div>

      {belowBox && data && !data.length ? (
        <div
          className={`${belowBoxHeight} ${belowboxwidth} bg-white mt-[6px] rounded-5 flex items-center justify-center w-full`}
        >
          <button
            onClick={() => navigate(`/${belowBoxLink}`)}
            className={`inline-block rounded-[5.33px] bg-[#FFA28D] text-[#FFF] font-semibold py-[10px] ${
              signupTable
                ? "pl-[15.5px] pr-[16.5px]"
                : "pr-[16.5px] pl-[15.5px]"
            } text-base`}
          >
            {belowBoxText}
          </button>
          <img className="inline-block pl-2" src={belowBoxIcon} alt="" />
        </div>
      ) : null}

      {!hidePagination ? (
        <div className="flex justify-between px-1 items-center" style={{ justifyContent : showPageIndicator ? "flex-end" : "", gap : showPageIndicator ? "48px" : ""}}>
          {!showonlyrightpaginationpart ? (
            <p className="text-secondary-60 text-xs">
              Showing {tableData?.length} of {data?.length}
            </p>
          ) : (
            <p className="text-secondary-60 text-xs"></p>
          )}
		{/*
          <Pagination
            indexclass={indexclass}
            currentPage={currentPage}
            setCurrentPageNo={setCurrentPageNo}
            alloverclass={alloverclass}
            setCurrentPage={setCurrentPage}
            totalPages={
              isCallingApi ? total_pages : Math.ceil(data?.length / maxPageSize)
            }
          /> */}
		{
			showPageIndicator ? 

		<PageIndicatorV2 recordStartNo={(currentPage === 1 ? currentPage : (currentPage - 1) * 10)}  recordEndNo={data.length < currentPage * 10 ? data.length : currentPage * 10 } totalRecordsCount={data.length } />

			: <></>
		}
		<PaginationV2 
                    pageSize={1}
                    totalDataCount={isCallingApi ? total_pages : Math.ceil(data.length / maxPageSize)}
                    previousButton={{
                      content: (
                        <>
                          <ChevronLeft />
                        </>
                      ),
                      className:
                        "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                    }}
                    nextButton={{
                      content: (
                        <>
                          <ChevronRight />
                        </>
                      ),
                      className:
                        "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                    }}
                    helperButtonClassName={
                      "text-secondary-60 font-[400] font-[calc(100vw*16/1440)] "
                    }
                    selectedHelperButtonClassName={
                      "text-blue-70 font-[600] font-[calc(100vw*16/1440)]"
                    }
                    selectedPage={currentPage}
                    setSelectedPage={setCurrentPage}
		/>
        </div>
      ) : (
        <div
          className={`flex grid-cols- justify-center items-center ${
            loading ? "mt-7" : ""
          } `}
        >
          <aside></aside>
          {!hidePagination && (
            <ReactPaginate
              className="table-pagination-container flex justify-center mt-5"
              pageClassName={`flex justify-center items-center w-[38.12px] h-[38.12px] border border-primary rounded-full mr-5 cursor-pointer
            ${"text-primary"}`}
              activeClassName={`${styles["active-pagination"]}`}
              breakLabel="..."
              onPageChange={(val) => {
                setCurrentPage(val.selected + 1);
              }}
              pageRangeDisplayed={3}
              pageCount={
                isCallingApi
                  ? total_pages
                  : Math.ceil(data?.length / maxPageSize)
              }
              previousClassName="hidden"
              nextClassName="hidden"
              renderOnZeroPageCount={null}
              pageLinkClassName="w-full h-full flex justify-center items-center"
            />
          )}
        </div>
      )}
    </div>
  );
}
