import React, { useEffect, useRef, useState } from 'react'

import styles from "./learnerBlocker.module.css"
import vector from "./stepsForSP.svg"
import { Link, useOutletContext } from 'react-router-dom'
import CopyIcon from './CopyIcon'
import TickMarkIcon from '../../components/TickMarkIcon/TickMarkIcon'

const LearnerBlocker = () => {

    const { setSignupStage } = useOutletContext()
    const [copied, setCopied] = useState(false)

    const inputRef = useRef(null);
    useEffect(() => { setSignupStage("initial") }, [])

    return (
        <div className={styles.learner_container}>
            <div className={styles.left_info}>
                <h1>Evallo is an invite-only platform for individuals.</h1>
                <div>Currently, we accept students only via an invite by tutoring businesses registered on Evallo. Please ask your tutor or education consultant to invite you using the steps on the right. <br /> <br />
                    If you’re already invited, check your inbox for an invitation link to create your account.<br />
                    <div className={styles.mobile_hide}> <br /> Here all by yourself? You can share the link below with your existing tutor to bring them onboard Evallo.</div></div>
                <div className={`${styles.copy_div} ${styles.mobile_hide}`}>
                    <input ref={inputRef} disabled type="text" defaultValue={"https://evallo.org"} />
                    <button onClick={() => { navigator.clipboard.writeText(inputRef.current.value); setCopied(true) }} className={styles.got_it_btn} style={{ opacity: "1", background: "#0671E0" }}> {copied ? "Copied!" : `Copy`} {copied ? <TickMarkIcon /> : <CopyIcon />} </button>
                </div>
                <div className={styles.for_mobile}>
                    <div className=""> Here all by yourself? Share the link below with your existing tutor to bring them onboard Evallo.</div>
                    <div className={`${styles.copy_div}`}>
                        <input ref={inputRef} disabled type="text" defaultValue={"https://evallo.org"} />
                        <button onClick={() => { navigator.clipboard.writeText(inputRef.current.value); setCopied(true) }} className={styles.got_it_btn} style={{ opacity: "1", background: "#0671E0" }}> {copied ? "Copied!" : `Copy`} {copied ? <TickMarkIcon /> : <CopyIcon />} </button>
                    </div>
                </div>

                <div className={styles.mobile_sign}>
                    Have an existing account?{" "}
                    <Link to="/signup/student-parent-info">Sign in</Link>
                </div>
            </div>


            <div>
                <div className={styles.right_info}>
                    <img src={vector} alt="" />
                </div>
            </div>
        </div>
    )
}

export default LearnerBlocker