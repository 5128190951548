import React, { useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { triggerToast } from "../../../../utils/toastController";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import { fetchAndSetOrgDetails } from "../../../../utils/orgService";

const AddLogoPopUp = ({ open, setOpen, updateLogo, orgId, setLogoUrl }) => {
  const [uploading, setUploading] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const inpuRef = useRef();

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
    setFileUrl(URL.createObjectURL(file));
  };

  const handleSave = () => {
    if (!logoFile) {
      triggerToast(<div>Invalid file.</div>, "error", { autoClose: 2000 });
      return;
    }
    let size = logoFile.size / 1024;
    size = size / 1024;
    if (size > 2) {
      triggerToast(<div>File is larger than 2MB!</div>, "error", {
        autoClose: 3000,
      });
      return;
    }
    const formData = new FormData();
    formData.append("photos", logoFile);
    formData.append("updatefieldName", "orgBussinessLogo");
    setUploading(true);
    updateLogo({ formData: formData, id: orgId }).then((res) => {
      setUploading(false);
      if (res.error) {
        triggerToast(<div>{res.error.message}</div>, "error", {
          autoClose: 3000,
        });
        return;
      }
      setLogoUrl(URL.createObjectURL(logoFile));
      triggerToast(<div>Logo updated Successfully!</div>, "success", {
        autoClose: 3000,
      });
      fetchAndSetOrgDetails(orgId)
      setOpen(false);
    });
  };

  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[90%] md:w-[468px] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Add Logo</div>
        <button onClick={(e) => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>

      <div className="w-[100%]  h-[240px] bg-[#EBF1F7] rounded-[12px] flex justify-center items-center">
        <div className=" w-full h-[50%] flex flex-col gap-[12px] justify-center items-center">
          {fileUrl ? (
            <img
              src={fileUrl}
              className="h-[100px] max-w-[358px] object-contain"
              alt="orgDefaultLogo"
            />
          ) : (
            <div
              className="bg-blue-70 w-fit  text-[16px] px-[12px] py-[4px] flex gap-[4px] items-center text-white rounded-[4px] cursor-pointer"
              onClick={() => inpuRef.current.click()}
            >
              <FileUploadOutlinedIcon
                style={{
                  color: "#FFFFFF",
                  height: "16px",
                  width: "16px",
                }}
              />
              <div>Upload Image</div>
              <input
                className="hidden"
                type="file"
                ref={inpuRef}
                accept="image/*"
                disabled={uploading}
                onChange={(e) => handleLogoChange(e)}
              />
            </div>
          )}

          <label className="text-secondary-60 font-[400] text-[14px] leading-[17.5px] text-center ">
            {logoFile ? (
              <div>{logoFile.name}</div>
            ) : (
              <>
                <div>PNG or JPG. Max height: 512 px.</div>{" "}
                <div>Max size 2 MB.</div>
              </>
            )}
          </label>
          {fileUrl && (
            <div
              className="text-secondary-80 text-[16px] leading-[24px] border-b border-secondary-80 cursor-pointer"
              onClick={() => {
                setFileUrl(null);
                setLogoFile(null);
              }}
            >
              Delete
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-[12px]">
        <div
          className="text-secondary-60 basis-1/2 text-center border-[2px] border-secondary-60 px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] cursor-pointer justify-center"
          onClick={(e) => setOpen(false)}
        >
          Cancel
        </div>
        <div
          className={`${
            !logoFile
              ? "bg-blue-70/40 text-white/40 cursor-not-allowed"
              : "bg-blue-70 text-white cursor-pointer"
          } basis-1/2  text-center  px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
          onClick={() => {
            {
              logoFile && handleSave();
            }
          }}
        >
          {uploading ? <LoaderIcon /> : "Save"}
        </div>
      </div>
    </PopupContainer>
  );
};

export default AddLogoPopUp;
