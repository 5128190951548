import React, { useEffect, useRef, useState } from 'react'
import ToggleButtonV2 from '../ToggleButtonV2/ToggleButtonV2'
import { MoreHoriz } from '@mui/icons-material'
import { DateInputSelect, MultiLineBox, SingleLineBox, SingleOrMultiSelect, SingleOrMultiSelectOrDropdown } from './InputTypeOptions';
import CircleArrowIcon from '../../assets/icons/SettingsIcon/CircleArrowIcon';

function SwitchQuestionCard({ selectedQT, data }) {
    console.log("Somthing changed")
    switch (selectedQT) {
        case "Single-line": return <SingleLineBox />;
        case "Paragraph": return <MultiLineBox />;
        case "Checkboxes": return <SingleOrMultiSelectOrDropdown {...data} />;
        case "RadioButton": return <SingleOrMultiSelectOrDropdown  {...data} />;
        case "Dropdown": return <SingleOrMultiSelectOrDropdown isDropdown={true} {...data} />;
        case "Date": return <DateInputSelect />;
        default: return <SingleLineBox />;
    }
}
// setCustomFields={setCustomFields} customFields={customFields} idx={idx} handleDataChange={handleDataChange} isThisActive={isThisActive}

const InputTypeController = ({ isThisActive, questionText, answerType, section, setCustomFields, customFields, idx, setActiveIndex, totalQuestion, packageName }) => {
    const [value, setValue] = useState('');
    const [openQuestionMenu, setOpenQuestionMenu] = useState(false)
    const textAreaRef = useRef(null);
    const menuRef = useRef(null)



    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
        }
    }, [value]);

    // useEffect(() => {
    //     console.log(customFields && customFields)
    // }, [customFields])

    const handleDataChange = (idx, key, targetValue, idx2) => {

        setCustomFields((prev) => {


            const temp = prev[section].map((val) => ({
                ...val
            }))
            console.log(temp)
            if (idx2) {
                console.log(temp[idx][key][idx2])
                temp[idx][key][idx2] = targetValue
                prev[section] = temp
                return prev
            }

            if (key === "isDisabled" || key === "isRequired") {
                temp[idx][key] = !temp[idx][key]
                prev[section] = temp
                return { ...prev }

            }
            else if (key === "dataType") {
                temp[idx][key] = targetValue
                prev[section] = temp
                return { ...prev }
            } else {
                temp[idx][key] = targetValue
                prev[section] = temp
                return prev

            }

        })

    };

    const handleChange = (e) => {
        setValue(e.target.value)
    }
    const closeOpenMenus = (e) => {
        console.log("Hello")
        if (openQuestionMenu && !menuRef.current?.contains(e.target)) {
            setOpenQuestionMenu(false)
        }
    }

    const handleDeleteQuestion = (index) => {
        setCustomFields((prev) => {
            let temp = [...prev[section]]
            temp = temp?.filter((item, idx2) => {
                return idx2 !== index;
            });
            prev[section] = temp
            return { ...prev }
        })
    }

    const swapQuestionCard = (index, side) => {
        setCustomFields((prev) => {
            let temp = [...prev[section]]
            let tempQuestion = temp[index]
            if (section === "sectionTwo") {
                if (index > 4 && side === "up") {
                    temp[index] = temp[index - 1]
                    temp[index - 1] = tempQuestion
                }
                if (index !== temp.length - 1 && index > 3 && side === "down") {
                    temp[index] = temp[index + 1]
                    temp[index + 1] = tempQuestion
                }
            } else {
                if (side === "up" && index !== 0) {
                    temp[index] = temp[index - 1]
                    temp[index - 1] = tempQuestion
                }
                if (side === "down" && index !== temp.length - 1) {
                    temp[index] = temp[index + 1]
                    temp[index + 1] = tempQuestion
                }
            }
            prev[section] = temp
            return { ...prev }
        })
    }

    const duplicateQuestion = (index) => {
        if ((packageName === "Starter" && totalQuestion === 3) || (packageName === "Professional" && totalQuestion === 10) || (packageName === "Free")) {
            return;
        }
        setCustomFields((prev) => {
            let temp = prev[section]
            temp.splice(index, 0, temp[index])
            prev[section] = temp
            return { ...prev }
        })
    }

    const moveQuestionToOtherSection = (idx) => {

        setCustomFields((prev) => {
            let temp = prev[section]
            const question = temp.splice(idx, 1)[0];

            if (section === "sectionThree") {
                customFields["sectionTwo"].push(question)
            } else if (section === "sectionTwo") {
                customFields["sectionThree"].push(question)
            }

            prev[section] = temp
            return { ...prev }

        })

    }

    document.addEventListener("mousedown", closeOpenMenus)

    return (
        <div className={`cursor-pointer bg-white rounded-[6px] p-6 flex flex-col ${isThisActive ? "gap-6 xl:gap-9 border-l-[6px] border-blue-70" : "gap-4 xl:gap-6"} ${section === "sectionOne" && idx > 3 ? "cursor-pointer" : ""}`} onClick={(e) => setActiveIndex(idx)}>
            <div className={`flex flex-col xl:flex-row items-start gap-6 xl:gap-4 ${customFields && customFields?.[section][idx]["isDisabled"] && "pointer-events-none opacity-60"} ${section === "sectionTwo" && idx < 4 || section === "sectionOne" ? "pointer-events-none" : ""}`}>
                <textarea
                    className={`font-normal text-base outline-none placeholder:text-secondary text-secondary pb-1 w-full ${isThisActive ? "border-b bg-secondary-5 pl-2" : "w-full xl:w-[400px]"} border-secondary-60 resize-none overflow-y-hidden disabled:bg-transparent`} rows={1} ref={textAreaRef} onInput={handleChange} disabled={!isThisActive}
                    value={customFields && customFields?.[section][idx]?.questionText} placeholder={questionText ? questionText : 'Enter Question Text'}
                    onChange={(e) => handleDataChange(idx, "questionText", e.target.value)}
                ></textarea>
                <select name="" id="" className={`${isThisActive ? "block" : "hidden"} cursor-pointer`} value={customFields && customFields?.[section][idx]?.dataType} onChange={(e) => handleDataChange(idx, "dataType", e.target.value)} >
                    <option className='cursor-pointer' value="Single-line">Single-Line Text</option>
                    <option className='cursor-pointer' value="Paragraph">Paragraph Text</option>
                    <option className='cursor-pointer' value="RadioButton">Single-Select</option>
                    <option className='cursor-pointer' value="Checkboxes">Multi-Select</option>
                    <option className='cursor-pointer' value="Dropdown">Dropdown</option>
                    <option className='cursor-pointer' value="Date">Date</option>
                </select>
            </div>
            <div className='flex flex-col gap-5'>
                <div className={`${customFields && customFields?.[section][idx]["isDisabled"] || section === "sectionOne" && "pointer-events-none"} w-full xl:w-[400px]`}>
                    <SwitchQuestionCard selectedQT={customFields && customFields?.[section][idx].dataType} data={{ setCustomFields, customFields, idx, handleDataChange, isThisActive, section }} />
                </div>
                {
                    isThisActive || section === "one" ?
                        <>
                            <div className='w-full border-b'></div>
                            <div className='flex justify-between items-center gap-4'>
                                <div className={` ${customFields && customFields?.[section][idx]["isDisabled"] === true ? "text-blue-70" : "text-red-400"} underline underline-offset-2 font-normal text-[14px] leading-[17.5px]`}>
                                    {section === "sectionOne" ? "" : <div className={` ${section === "sectionTwo" && idx > 3 ? "hidden" : section === "sectionThree" ? "hidden" : "block"} cursor-pointer`} onClick={() => handleDataChange(idx, "isDisabled")} >{customFields && customFields?.[section][idx]["isDisabled"] ? "Enable Question" : "Disable Question"}</div>}
                                </div>
                                <div className={`flex items-center gap-[10px] ${customFields && customFields?.[section][idx]["isDisabled"] && "pointer-events-none opacity-60"}`}>
                                    <div className={`flex gap-3 items-center pr-[14px] ${section === "sectionTwo" && idx < 4 ? "hidden" : section === "sectionOne" ? "hidden" : "flex"}`}>
                                        <div className='rotate-180 cursor-pointer' onClick={(e) => { swapQuestionCard(idx, "up") }}>
                                            <CircleArrowIcon />
                                        </div>
                                        <div className='cursor-pointer' onClick={(e) => { swapQuestionCard(idx, "down") }}>
                                            <CircleArrowIcon />
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-[6px]' >
                                        <ToggleButtonV2 customContainer={"cursor-pointer"} setIsEnabled={(targetValue) => {
                                            handleDataChange(idx, "isRequired", targetValue)
                                        }} isEnabled={customFields && customFields?.[section][idx]?.isRequired} /> Required
                                    </div>
                                    <div className='min-h-full border-l border-secondary-20'>&nbsp;</div>
                                    <div className='relative' ref={menuRef}>
                                        <MoreHoriz onClick={(e) => section === "sectionTwo" && idx < 4 ? null : section === "sectionOne" ? null : setOpenQuestionMenu(true)} className={`${section === "sectionTwo" && idx < 4 ? null : section === "sectionOne" ? null : "cursor-pointer"}`} />
                                        {
                                            openQuestionMenu && <div className='py-3 shadow-[0px_4.05px_26.98px_0px_#000AFF29] rounded-md absolute right-0 bottom-[30px] bg-white w-[180px]'>
                                                <div className='font-normal text-[16px] leading-[16px] text-secondary p-3 hover:bg-secondary-5 cursor-pointer' onClick={() => duplicateQuestion(idx)}>Duplicate question</div>
                                                <div className='font-normal text-[16px] leading-[16px] text-red-400 p-3 hover:bg-secondary-5 cursor-pointer' onClick={() => handleDeleteQuestion(idx)}>Delete question</div>
                                                {packageName === "Premium" && <div className='font-normal text-[16px] leading-[16px] text-secondary p-3 hover:bg-secondary-5 cursor-pointer' onClick={() => moveQuestionToOtherSection(idx)}>{section === "sectionThree" ? "Move to Section 2" : section === "sectionTwo" ? "Move to Section 3" : ""}</div>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ""
                }
            </div>
        </div>
    )
}

export default InputTypeController
