import React, { useState } from "react";
import EventCardHeader from "../../components/EventCardHeader/EventCardHeader";
import EventCardBottom from "../../components/EventCardBottom/EventCardBottom";
import frozenEventTypes from "../../utils/eventTypes";
import EventCardDetails from "../../components/EventCardDetails/EventCardDetails";
import frozenEventStatus from "../../utils/eventStatus";
import HorizontalDivider from "../../components/HorizontalDivider/HorizontalDivider";
import DeclineRequestPopUp from "../../components/DeclineRequestPopUp/DeclineRequestPopUp";
import {
  fetchAllEventRequests,
  updateEventRequest,
} from "../../utils/eventService";
import { fetchAndSetAllEventRequests } from "../CalendarV2/helpers/fetchers";
import { triggerToast } from "../../utils/toastController";

/*
 *
    "Cancelled": cancelledTagIcon,
    "Pending": pendingIcon,
    "Scheduled": scheduledIcon,
    "Completed": completedIcon,
    "No Show": noShowIcon
 *
 * */

const dummyEventData = {
  eventType: frozenEventTypes.SESSION.eventType,
  status: frozenEventStatus.PENDING.status,
  students: "Carloz Alcaraz, Janik Sinner, De Minaur",
  tutor: "Novak Djokovic",
  service: "Tennis Coaching",
  dateTime: "Monday, Jun 24 ● 11:30am - 12pm",
  location: "Online",
  onlineMeet: "https://meet.google.com/fzx-tst-dev",
  attendees:
    "Andy Murray, Rafa Nadal, Roger Federer, Roger Federer, Roger Federer",
  info: "Splendid session of coaching for all the new generation of players",
};

const EventDetailsCard = ({
  userId,
  eventData,
  persona,
  parentProps,
  extendedProps,
  requestPopupInfo,
  setRequestPopupInfo,
  requestInfoDetails,
  setRequestInfoDetails,
}) => {
  console.log(eventData);
  console.log(extendedProps);
  console.log(userId, extendedProps?.initiatorDetails?.id)
  return (
    <div className="">
      <div className="w-[196px]  md:w-[300px] shadow-[0px_4px_24px_0px_#11167D26] rounded-xl p-3 flex flex-col gap-[12px] md:gap-[16px] bg-white z-[10000] font-[lexend]">
        <EventCardHeader
          eventType={eventData.eventType}
          eventData={eventData}
        />
        <HorizontalDivider />
        <EventCardDetails eventData={eventData} />
        <HorizontalDivider />
        <EventCardBottom
          userType={((frozenEventTypes.OFFCWORK.eventType.toLowerCase() === extendedProps.eventType.toLowerCase()) && (extendedProps?.initiatorDetails?.id === userId)) ? "scheduler" : "attendee"}
          eventType={eventData.eventType}
          persona={persona}
          status={eventData.status}
          parentProps={parentProps}
          extendedProps={extendedProps}
          {...{
            requestPopupInfo,
            setRequestPopupInfo,
            requestInfoDetails,
            setRequestInfoDetails,
          }}
        />
      </div>
    </div>
  );
};

export default EventDetailsCard;
