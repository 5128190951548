import { fetchAllEventRequests, scheduleSession, updateEventRequest } from "../../../utils/eventService"
import { triggerToast } from "../../../utils/toastController"
import { fetchAndSetAllEventRequests } from "./fetchers"
import { getTimeInFormat } from "./utils"

function getTimedelta(reminderString) {
	if(!reminderString || reminderString.trim() === "" || reminderString.trim().toLowerCase() === "none") {
		return 0
	}
	const reminderStringPart = reminderString.trim().split(" ")
	const seconds = reminderStringPart[1].toLowerCase().includes("minutes") ? 60 : 3600
	return parseInt(reminderStringPart[0]) * seconds * 1000
}

function getStudentStatusMapping(studentIds){
	const tempObj = {}
	studentIds.forEach((id,idx)=>{
		tempObj[id] = "none"
	})
	return tempObj
}

async function sessionScheduler({studentNotes, selectedDay, frequency, selectedDays, selectedDate, endDate, selectedStartTime, selectedEndTime, isRecurring, isRescheduled, isPartialSession, selectedTimeZone, selectedStudents, service, topic, selectedStatus, whiteboardLink, sessionTags, sessionNotes, meetingLink, location, isRemainder, sendRemindersTo, isSelectedUser, agendaNotes, hourlyCost, sessionCost, selectedTutor, selectedOption, selectedGroup, detailsOfWork, studentStatusMapping,selectedMonthlyRecurringDate, setSelectedMonthlyRecurringDate, isEditingRequest, eventRequestId, setAllEventRequests }){
		
	console.log("SD",selectedDate)
	//Right now only for single event
	const requestData = {
		date : selectedDate.toDate().toISOString(),
		timeZone : selectedTimeZone,
		time : {
			start : getTimeInFormat(selectedStartTime),
			end  : getTimeInFormat(selectedEndTime),					
		},
		isRecurring : isRecurring,
		...(isRecurring) && {
			repeatFrequency : frequency.toLowerCase(),
			...(frequency.toLowerCase() === "weekly" || frequency.toLowerCase() === "bi-weekly") && { selectedDays : selectedDay.map(val=>val.toLowerCase()) },
			...(frequency.toLowerCase() === "monthly") && { selectedDate : parseInt(selectedMonthlyRecurringDate) },
			endDate : endDate
		},
		service : service,
		specialization : topic,
		sessionTags : sessionTags,
		sessionStatus : selectedStatus,
		partialSession : isPartialSession,
		whiteboardLink : whiteboardLink,
		location : location,
		meetingLinkDetails : { 
			link : meetingLink,
			provider : "Any"
		},
		clientNotes : sessionNotes.clientNotes,
		internalNotes : sessionNotes.internalNotes,
		studentNotes : Object.entries(studentNotes).map(([key,value],idx)=>({
			id : key,
			notes : value
		})),
		resheduling : isRescheduled,
		invoiceDetails : {
			hourlyPrice : hourlyCost,
			tutorCost : sessionCost
		},
		reminder : {
			isRemainder,
			isEnabled : isRemainder.trim() !== "" && isRemainder.trim().toLowerCase() !== "none",
			targetUserTypes : Object.keys(sendRemindersTo).filter((key)=>sendRemindersTo[key]===true && key !== "attendees"),
			timeDelta : getTimedelta(isRemainder)
		},
		...(selectedOption === "student") && {
			studentIds : selectedStudents.map((val)=>val._id),
			sessionFor : "students"
		},
		...(selectedOption === "studentGroup") && {
			groupId : selectedGroup,
			sessionFor : "group"
		},
		sessionFor : selectedOption === "student" ? "students" : "group",
		studentStatusMapping : studentStatusMapping,
		tutorId : selectedTutor

	}
	console.log(selectedOption,requestData)	
	try {
		await scheduleSession(requestData)
		triggerToast("Session scheduled successfully", "success", {autoClose : 3000})
		if(isEditingRequest){
			const res = await updateEventRequest(eventRequestId, {
				"targetUserDetails.actionStatus" : "frozen"
			})
			fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)
		}
	}catch(err){
		triggerToast("Some error occured","error",{autoClose : 3000})
	}

}


export default sessionScheduler
