import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function getTimeOptions() {
  const times = [];
  let currentTime = 0;

  while (currentTime < 24 * 60) {
    const hours = Math.floor(currentTime / 60);
    const minutes = currentTime % 60;
    const ampm = hours >= 12 ? "PM" : "AM";
    const displayHours = (hours % 12 || 12).toString().padStart(2, "0");
    const displayMinutes =
      minutes === 0 ? "00" : minutes.toString().padStart(2, "0");
    const timeString = `${displayHours}:${displayMinutes} ${ampm}`;

    times.push(timeString);
    currentTime += 60;
  }

  return times;
}

const TimeSelector = ({ id, submitData, setSubmitData, timeDropdownTriggers, setTimeDropdownTriggers }) => {

  const timeOptions = getTimeOptions();

  const submitTime = (time) => {
    if (id === "startTime") {

      if ( submitData.endTime.trim() === "" || submitData.endTime.trim() != "" && isTimeEarlier(time, submitData.endTime)) {
        const startTimeIndex =  timeOptions.indexOf(time);
        const newEndTime =  timeOptions[startTimeIndex+1] ? timeOptions[startTimeIndex+1]  : timeOptions[0];
        setSubmitData((prev) => ({
          ...prev,
          startTime: time,
          endTime: newEndTime, 
        }));
      } else {
        setSubmitData((prev) => ({ ...prev, startTime: time }));
      }
    } else {
      setSubmitData((prev) => ({ ...prev, endTime: time }));
    }
  };

  const isTimeEarlier = (time1, time2) => {
    const [hours1, minutes1, period1] = parseTime(time1);
    const [hours2, minutes2, period2] = parseTime(time2);
    console.log(hours1, minutes1, period1, hours2, minutes2, period2 );

    // Convert to 24-hour format
    const time1InMinutes = (hours1 % 12 + (period1 === "PM" ? 12 : 0)) * 60 + minutes1;
    const time2InMinutes = (hours2 % 12 + (period2 === "PM" ? 12 : 0)) * 60 + minutes2;

    console.log(time1InMinutes , time2InMinutes)

    return time1InMinutes >= time2InMinutes;
  };

  const parseTime = (time) => {
    const [timePart, period] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
    return [hours, minutes, period];
  };

  const filterTimeOptions = (options) => {
    if (id === "endTime" && submitData.startTime) {
      const startTimeIndex = options.indexOf(submitData.startTime);
      return options.slice(startTimeIndex + 1);
    }
    return options;
  };

  return (
    <main  id={id} className="w-full rounded-[6px] bg-[#F6F9FA] cursor-pointer text-[14px] text-secondary-80 leading-[21px] font-normal"
      onClick={() => {
        id === "startTime"
          ? setTimeDropdownTriggers((prev) => ({
              ...prev,
              startTimeTrigger: !prev.startTimeTrigger,
            }))
          : setTimeDropdownTriggers((prev) => ({
              ...prev,
              endTimeTrigger: !prev.endTimeTrigger,
            }));
      }}
    >
      <div className="flex items-center justify-between w-full relative">
        <span className="pl-[12px] py-[7.5px]">
          {id === "startTime"
            ? submitData.startTime
              ? submitData.startTime
              : "Select"
            : submitData.endTime
            ? submitData.endTime
            : "Select"}
        </span>
        <button className="pr-[12px] py-[7.5px]">
          {id === "startTime" ? (
            timeDropdownTriggers.startTimeTrigger ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )
          ) : timeDropdownTriggers.endTimeTrigger ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </button>

        {(id=="startTime" ?  timeDropdownTriggers.startTimeTrigger : 
          timeDropdownTriggers.endTimeTrigger) && (
          <div
            className="absolute flex flex-col w-full gap-[4px] max-h-[220px] overflow-y-auto bg-white rounded-[4px] z-5000 p-[6px] top-[calc(100%+5px)] left-0 scroll_cal_effect border-[1px] border-[#11167D26]"
            style={{ boxShadow: "0px 4px 18px 0px #11167D1A" }}
          >
            {filterTimeOptions(timeOptions).map((time) => (
              <div
                key={time}
                className="flex h-[32px] items-center gap-[12px] px-[8px] py-[12px] w-full bg-white hover:bg-zinc-100 cursor-pointer"
                onClick={() => submitTime(time)}
              >
                <div className="text-[14px] leading-[14px]">{time}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </main>
  );
};

export default TimeSelector;
