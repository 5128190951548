import CrossIcon from '../CrossIcon/CrossIcon'
import SearchIcon from '../SearchIcon/SearchIcon'
import styles from './SearchFieldV2.module.css'



const SearchFieldV2 = ({ isCrossButtonVisible, onChange, textFieldValue, onCrossClick, placeholder="Search", onKeyDown }) => {

    return (
        <div className={`${styles.container}`}>
            <SearchIcon />
            <input type="text" placeholder={placeholder} value={textFieldValue} onChange={(e)=>onChange(e)} {...(onKeyDown) && {onKeyDown}}/>
            {
                isCrossButtonVisible ?
                    <button className={styles.crossButton} onClick={(e)=>onCrossClick(e)}>
                        <CrossIcon height="16px" width="16px"/>
                    </button>
                    :
                    <></>
            }
        </div>
    )
}


export default SearchFieldV2
