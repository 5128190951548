import countryCodes from './countryCode.json'

const augumentPhoneNumber = (phoneNumber) => {
    if(!phoneNumber){
      return ""
    }
    const newPhoneNumber = new Array(12)
    phoneNumber.split("").forEach((ch,idx)=>{
      if(idx <=2) {
        newPhoneNumber[idx] = ch
      } else if(idx >=3 && idx<=5){
        newPhoneNumber[idx+1] = ch
      } else {
        newPhoneNumber[idx+2] = ch
      }
    })
    console.log(newPhoneNumber)
    newPhoneNumber[3] = "-"
    newPhoneNumber[7] = "-"
  
    return newPhoneNumber.join("")
};

const getPhoneCodeForDialCode = (dialCode) => {

    if(dialCode === 1 || dialCode == "+1" || !dialCode){
        return "US"
    }
    return countryCodes.find((val)=>val.dial_code === dialCode)?.code
}

export {
    augumentPhoneNumber,
    getPhoneCodeForDialCode
}