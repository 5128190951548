import React, { useState, useEffect, useRef, useMemo } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EventAction from "../EventAction/EventAction";
import {
  EvChevronDownIcon,
  EvChevronUpIcon,
} from "../../assets/icons/ChevronIcons";
import frozenEventTypes from "../../utils/eventTypes";
import schedulerController from "../../pages/CalendarV2/helpers/schedulers";
import { updateController } from "../../pages/CalendarV2/helpers/updaters";
import deleteController from "../../pages/CalendarV2/helpers/deleters";
import validatorController from "../../pages/CalendarV2/helpers/validators";
const _ = require("lodash");
import { useSelector } from "react-redux";
import PopupContainer from "../PopupContainer/PopupContainer";
import RequestActionPopup from "../RequestAction/RequestActionPopup";
import {
  createSessionRating,
  updateSessionRating,
} from "../../utils/eventService";
import { triggerToast } from "../../utils/toastController";
import { updateAttendeeStatus } from "../../utils/eventService";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat"; // ES 2015
import CautionIconLarge from "./CautionIconLarge";
import LoaderIcon from "../LoaderIcon/LoaderIcon";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const EditActions = (props) => {
  const {
    operationMode: mode = "edit",
    setOperationMode,
    handleDelete,
    handleUpdate,
    handleUserStatusChange,
    eventActionResponse,
    selectedEventType,
    isRecurring,
    requiredEventData,
    studentSessionRating,
    studentSessionFeedback,
    organization,
    setTrigger
  } = props;
  console.log("in submit", props);
  const [isLoading, setIsLoading] = useState(false)
  const tutorId = requiredEventData?.tutorId;
  const _id = requiredEventData?._id;
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  const [requestInfoDetails, setRequestInfoDetails] = useState({
    type: "cancel",
    trigger: false,
  });

  const user = useSelector((state) => state.user);
  // "student","parent",

  // Create a ref for the popup element
  const popupRef = useRef(null);

  //******** FOR PERMISSIONS ************

  const isDeleteAllowed = useMemo(() => {
    if (user && ["tutor"].includes(user.role) && organization.settings) {
      const requiredPermission = organization.settings.permissions.find(
        (val) => val.permissionActionName === "tutDelSession"
      );
      return requiredPermission?.choosedValue;
    }
  }, [organization?.settings, user]);

  //*************************************

  //***** FOR YES NO MAYBE ********
  console.log("__PROPS__", props);
  const [selectedReponse, setSelectedResponse] = useState();

  useEffect(() => {
    if (mode === "edit") {
      if (props.selectedEventType === "session") {
        setSelectedResponse(props.requiredEventData?.tutorStatus);
      } else if (props.selectedEventType === "consultation" || props.selectedEventType.toLowerCase() === frozenEventTypes.OFFCWORK.eventType.toLowerCase() || props.selectedEventType.toLowerCase() === frozenEventTypes.INTMEETING.eventType.toLowerCase()) {
        if (props.user) {
          const attDetails = props.requiredEventData.attendeeDetails.find(
            (val) => val.id === props.user.id
          );
          console.log("ATTFER", attDetails);
          if (attDetails) setSelectedResponse(attDetails.status);
        }
      }
    }
  }, [
    mode,
    props.requiredEventData?.tutorStatus,
    props.requiredEventData?.attendeeDetails,
    props.user,
  ]);

  async function handleUpdateStatusClick(value) {
    try {
      setSelectedResponse(value);
	  console.log("REQS", props.requiredEventData)
      const res = await updateAttendeeStatus(
        props.requiredEventData.isEditingRequest ? props.requiredEventData.eventId : props.requiredEventData._id,
        props.user?.id,
        value
      );
      triggerToast("Attendee status updated successfully.", "success", {
        autoClose: 3000,
      });
      props.setTrigger(false)
    } catch (err) {
      console.error(err);
      triggerToast("Error occured while updating the status", "error", {
        autoClose: 3000,
      });
    }
  }
  //*******************************

  //************ To display the message for a submitted request **************

  const eventRequestMessage = useMemo(() => {
    if (
      props.user &&
      ["student", "parent"].includes(props.user?.role) &&
      props.requiredEventData &&
      props.allEventRequests
    ) {
      console.log("PAER", props.allEventRequests, props.requiredEventData._id);
      const eventRequest = props.allEventRequests.find(
        (eventRequest) =>
          eventRequest.requestorId === props.user?.id &&
          eventRequest.eventId === props.requiredEventData._id &&
          ["cancel", "reschedule"].includes(eventRequest.requestType)
      );

      if (eventRequest) {
        const requiredTimezone = props.user.userDefaults?.timeZone
          ? props.user.userDefaults?.timeZone
          : dayjs.tz.guess();
        const date = dayjs(eventRequest.requestedEventDetails.requestedOn)
          .tz(requiredTimezone)
          .format("DD MMMM, YY");
        const messageString = `You have requested to ${eventRequest.requestType} this session on ${date}. The request needs to be approved by the tutor.`;

        return messageString;
      }
    }
  }, [props.user, props.requiredEventData, props.allEventRequests]);

  //*******************************

  // Handle click outside the popup to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        console.log("testing");
        setShowDeletePopup(false);
        setShowUpdatePopup(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePopupToggle = (type) => {
    if (type === "delete") {
      console.log("TEST");
      setShowDeletePopup(!showDeletePopup);
      setShowUpdatePopup(false);
    } else if (type === "update") {
      setShowUpdatePopup(!showUpdatePopup);
      setShowDeletePopup(false);
    }
  };

  const createStudentSessionFeedback = async () => {
    try {
      console.log("in create feed back");
      const res = await createSessionRating({
        tutorId: tutorId,
        sessionId: _id,
        rating: studentSessionRating.rating,
      });
      console.log(res);
      triggerToast("Session feedback successfull!", "success", {
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error fetching session rating:", error);
    }
  };

  const updateStudentSessionFeedback = async () => {
    try {
      const res = await updateSessionRating({
        feedbackID: studentSessionFeedback?._id,
        rating: studentSessionRating.rating,
      });
      console.log(res);
      triggerToast("Session feedback updated!", "success", {
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error fetching session rating:", error);
    }
  };

  const handleStudentSessionFeedback = () => {
    console.log("in feed back action");
    console.log(studentSessionRating);
    if (studentSessionRating.mode === "create") {
      createStudentSessionFeedback();
    } else if (studentSessionRating.mode === "edit") {
      updateStudentSessionFeedback();
    }
  };

  console.log("TT", showDeletePopup);



  const onScheduleClick = async () => {
    setIsLoading(true)
    try {
      await schedulerController(selectedEventType)(
        _.omit(
          {
            ...props,
            isEditingRequest: props.requiredEventData?.isEditingRequest,
            eventRequestId: props.requiredEventData?.eventRequestId,
          },
          ["selectedEventType", "eventType"]
        )
      );
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
    setTrigger(false)
  }


  const onUpdateClick = async (updateFutureSessions = false) => {
    setIsLoading(true)
    try {
      await updateController(selectedEventType)(props, updateFutureSessions);
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
    setTrigger(false)
  }

  const onDeleteClick = async (deleteFutureSessions = false) => {
    setIsLoading(true)
    try {
      await deleteController(selectedEventType)(
        props.requiredEventData._id,
        deleteFutureSessions
      )
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
    setTrigger(false)
  }


  const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
  const isAllowedToViewUpdateActions = useMemo(()=>{
		if(mode === "edit" && requiredEventData && nonSessionTypes.includes(selectedEventType?.toLowerCase())){
			if(requiredEventData.isEditingRequest){
				return requiredEventData.eventRequestor._id === user?.id
			}
			return requiredEventData.initiatorDetails.id === user?.id
		}
		return false
  },[user?.role])

  console.log("INTOFC", nonSessionTypes.includes(selectedEventType?.toLowerCase()) && !isAllowedToViewUpdateActions)

  return (
    <div
      className={`flex ${mode === "edit" ? "md-justify-between" : "md:justify-end"
        } items-center  border-t-[1px] border-secondary-10 w-[100%] py-[16px]`}
    >
      {mode === "create" || mode === "create-requested" || mode === "create-scheduled"? (
        <button
          className="w-full md:w-fit bg-blue-70 text-[16px] text-white text-center justify-center flex items-center px-[20px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer disabled:opacity-30"
          onClick={onScheduleClick}
          disabled={validatorController(selectedEventType)(props) || isLoading}
        >
			{
				isLoading ? <LoaderIcon /> : "Schedule"
			}
        </button>
      ) : mode === "edit" && props.requiredEventData?.recurring ? (
        user.role === "student" || user.role === "parent" ? (
          user.role === "student" &&
            props.requiredEventType === "session" &&
            props.requiredEventData?.status === "completed" ? (
            <div className="grid md:flex md:justify-end gap-[18px] w-full">
              <button
                className="w-full md:w-fit bg-blue-70 text-[16px] text-white text-center justify-end flex items-center px-[20px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer disabled:opacity-30"
                onClick={() => {
                  handleStudentSessionFeedback();
                }}
                disabled={studentSessionRating.rating === null}
              >
                Save
              </button>
            </div>
          ) : eventRequestMessage ? (
            <div className="grid md:flex md:justify-start items-center gap-[12px] w-full">
              <CautionIconLarge />
              <div className="text-secondary-80 font-[lexend] font-[400] text-[14px] leading-[12px]">
                {eventRequestMessage}
              </div>
            </div>
          ) : (
            <div className="grid md:flex md:justify-end gap-[18px] w-full">
              <div className="grid md:flex gap-[8px]  w-full md:w-fit">
                <button
                  className={`bg-secondary-60 w-full md:w-auto text-[16px] text-white text-center flex justify-center items-center pl-[12px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer `}
                  onClick={() =>
                    setRequestInfoDetails({ type: "cancel", trigger: true })
                  }
                >
                  Request Cancellation
                </button>
                <button
                  className={`bg-white md:bg-blue-70 w-full md:w-auto  text-[16px] text-blue-70 md:text-white border border-blue-70 md:border-none text-center justify-center flex items-center pl-[12px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer`}
                  onClick={() =>
                    setRequestInfoDetails({ type: "reschedule", trigger: true })
                  }
                >
                  Request Reschedule
                </button>
              </div>{" "}
            </div>
          )
        ) : (
          <div className="grid md:flex md:justify-between gap-[18px] w-full">
            <div className=" flex items-center w-full md:w-[25%]">
              {(props.requiredEventData?.tutorId === props.user?.id ||
                props.requiredEventData?.attendeeDetails
                  ?.map((val) => val.id)
                  .includes(props.user?.id)) && (
                  <EventAction
                    response={selectedReponse}
                    props={props}
                    setResponse={(val) => {
                      handleUpdateStatusClick(val);
                    }}
                  />
                )}
            </div>
            <div className="grid md:flex gap-[8px]  w-full md:w-fit">
              <div
                className={`${showDeletePopup ? "bg-error-40/30" : "bg-error-40"
                  } w-full md:w-auto  text-[16px] text-white text-center flex justify-center items-center pl-[20px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer 
					${isDeleteAllowed === false ? "hidden" : ""} ${(nonSessionTypes.includes(selectedEventType.toLowerCase()) && !isAllowedToViewUpdateActions) ? "hidden" : ""}
				`}
                onClick={() => handlePopupToggle("delete")}
                style={{ zIndex: 4 }}
                disabled={isLoading}
              >
                <DeleteOutlineOutlinedIcon
                  style={{ height: "16px", width: "16px" }}
                />{" "}
                {isLoading ? <LoaderIcon /> : <>Delete</>}
                {showDeletePopup ? (
                  <EvChevronDownIcon inherit={true} />
                ) : (
                  <EvChevronUpIcon inherit={"true"} />
                )}
                {showDeletePopup && (
                  <div
                    ref={popupRef}
                    className="fixed bottom-[110px] w-full md:w-fit md:bottom-[0px] md:right-[140px] p-2 md:p-4  md:h-[172px]"
                  >
                    <div className="flex flex-col gap-[10px]">
                      <button
                        className="bg-error-40 text-white px-4 py-2 rounded-md"
                        onClick={() => onDeleteClick(false)}
                        disabled={isLoading}
                      >
                        {isLoading ? <LoaderIcon /> : "Delete Current"}
                      </button>
                      <button
                        className="bg-error-40 text-white px-4 py-2 rounded-md"
                        onClick={() => onDeleteClick(true)}
                        disabled={isLoading}
                      >
                        {isLoading ? <LoaderIcon /> : "Delete Current and Future"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${showUpdatePopup
                  ? "bg-white md:bg-blue-70/30"
                  : "bg-white md:bg-blue-70"
                  } ${validatorController(selectedEventType)(props)
                    ? "pointer-events-none opacity-30"
                    : ""
                  } w-full md:w-auto  text-[16px] text-blue-70 md:text-white border border-blue-70 md:border-none text-center justify-center flex items-center pl-[20px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer ${(nonSessionTypes.includes(selectedEventType.toLowerCase()) && !isAllowedToViewUpdateActions) ? "hidden" : ""}`}
                onClick={() => handlePopupToggle("update")}
                disabled={validatorController(selectedEventType)(props) || isLoading}
              >
                {isLoading ? <LoaderIcon /> : "Update "}

                {showUpdatePopup ? (
                  <EvChevronDownIcon inherit={true} />
                ) : (
                  <EvChevronUpIcon inherit={"true"} />
                )}
                {showUpdatePopup && (
                  <div
                    ref={popupRef}
                    className="fixed bottom-[20px] w-full md:w-fit md:bottom-[10px] md:right-[20px] p-2 md:p-4  md:h-[172px]"
                  >
                    <div className="flex flex-col gap-[10px]">
                      <button
                        className="bg-blue-70 text-white px-[20px] py-[10px] rounded-md"
                        onClick={() => onUpdateClick(false)}
                        disabled={isLoading}
                      >
                        {isLoading ? <LoaderIcon /> : "Update Current"}
                      </button>
                      <button
                        className="bg-blue-70 text-white px-[20px] py-[10px] rounded-md"
                        onClick={() => onUpdateClick(true)}
                        disabled={isLoading}
                      >
                        {isLoading ? <LoaderIcon /> : "Update Current and Future"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      ) : user.role === "student" || user.role === "parent" ? (
        user.role === "student" &&
          props.requiredEventType === "session" &&
          props.requiredEventData?.status === "completed" ? (
          <div className="grid md:flex md:justify-end gap-[18px] w-full">
            <button
              className="w-full md:w-fit bg-blue-70 text-[16px] text-white text-center justify-end flex items-center px-[20px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer disabled:opacity-30"
              onClick={() => {
                handleStudentSessionFeedback();
              }}
              disabled={studentSessionRating.rating === null}
            >
              Save
            </button>
          </div>
        ) : eventRequestMessage ? (
          <div className="grid md:flex md:justify-start items-center gap-[12px] w-full">
            <CautionIconLarge />
            <div className="text-secondary-80 font-[lexend] font-[400] text-[14px] leading-[12px]">
              {eventRequestMessage}
            </div>
          </div>
        ) : (
          <div className="grid md:flex md:justify-end gap-[18px] w-full">
            <div className="grid md:flex gap-[8px]  w-full md:w-fit">
              <button
                className={`bg-secondary-60 w-full md:w-auto text-[16px] text-white text-center flex justify-center items-center pl-[12px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer `}
                onClick={() =>
                  setRequestInfoDetails({ type: "cancel", trigger: true })
                }
              >
                Request Cancellation
              </button>
              <button
                className={`bg-white md:bg-blue-70 w-full md:w-auto  text-[16px] text-blue-70 md:text-white border border-blue-70 md:border-none text-center justify-center flex items-center pl-[12px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer`}
                onClick={() =>
                  setRequestInfoDetails({ type: "reschedule", trigger: true })
                }
              >
                Request Reschedule
              </button>
            </div>{" "}
          </div>
        )
      ) : (
        <div className="grid md:flex md:justify-between gap-[18px]  w-full">
          <div className=" flex items-center w-full md:w-[25%]">
            {(props.requiredEventData?.tutorId === props.user?.id ||
              props.requiredEventData?.attendeeDetails
                ?.map((val) => val.id)
                .includes(props.user?.id)) && (
                <EventAction
                  response={selectedReponse}
                  props={props}
                  setResponse={(val) => {
                    handleUpdateStatusClick(val);
                  }}
                />
              )}
          </div>
          <div className="grid md:flex gap-[8px]  w-full md:w-fit">
            <button
              className={`bg-error-40 w-full md:w-auto  text-[16px] text-white text-center flex justify-center items-center pl-[20px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer 
				${isDeleteAllowed === false ? "hidden" : ""} ${(nonSessionTypes.includes(selectedEventType.toLowerCase()) && !isAllowedToViewUpdateActions) ? "hidden" : ""}
			  `}
              onClick={() => onDeleteClick(false)}
              style={{ zIndex: 4 }}
              disabled={isLoading}
            >

              <DeleteOutlineOutlinedIcon
                style={{ height: "16px", width: "16px" }}
              />{" "}
              {isLoading ? <LoaderIcon /> : <>Delete</>}
            </button>
            <button
              className={`bg-white md:bg-blue-70 w-full md:w-auto  text-[16px] text-blue-70 md:text-white border border-blue-70 md:border-none text-center justify-center flex items-center pl-[20px] pr-[12px] py-[10px] rounded-[6px] gap-[6px] cursor-pointer ${(nonSessionTypes.includes(selectedEventType.toLowerCase()) && !isAllowedToViewUpdateActions) ? "hidden" : ""}`}
              onClick={() => onUpdateClick(false)}
              disabled={validatorController(selectedEventType)(props) || isLoading}
            >
              {
                isLoading ? <LoaderIcon /> : "Update"
              }

            </button>
          </div>
        </div>
      )}
      <PopupContainer
        trigger={requestInfoDetails?.trigger}
        setTrigger={() =>
          setRequestInfoDetails((prev) => ({ ...prev, trigger: !prev.trigger }))
        }
        customCloseButton={true}
      >
        <RequestActionPopup
          requestType={requestInfoDetails?.type}
          setTrigger={() =>
            setRequestInfoDetails((prev) => ({
              ...prev,
              trigger: !prev.trigger,
            }))
          }
          headerName={
            requestInfoDetails.type === "cancel"
              ? "Cancel Event"
              : "Reschedule Event"
          }
          placeholderText={
            requestInfoDetails.type === "cancel"
              ? "Briefly describe the reason for requesting cancellation"
              : "Briefly describe the reason for rescheduling along with a suitable date for rescheduling the session."
          }
          noteText={
            requestInfoDetails.type === "cancel"
              ? "Your admin/tutor would need to approve this cancellation request."
              : "Your admin/tutor would need to approve this reschedule request."
          }
          eventId={props?.requiredEventData?._id}
          eventType={selectedEventType}
          setAllEventRequests={props.setAllEventRequests}
        />
      </PopupContainer>
    </div>
  );
};

const SaveBtn = () => {
  return (
    <section className="py-[16px] flex w-full items-center justify-end">
      <button className="bg-[#0671E0] text-white py-[10px] px-[20px] rounded-[6px] text-[16px] leading-[20px] font-medium">
        Save
      </button>
    </section>
  );
};
const SubmitTemp = () => {
  return (
    <section className="py-[16px] flex w-full items-center justify-end">
      <button className="bg-[#0671E0] text-white py-[10px] px-[20px] rounded-[6px] text-[16px] leading-[20px] font-medium">
        Save
      </button>
    </section>
  );
};

const RequestActions = (props) => {

  const { operationMode: mode } = props
  const [isLoading, setIsLoading] = useState(false)

  function handleDeclineClick() {
    props.setRequestPopupInfo({ trigger: true, eventRequestId: props.requiredEventData.eventRequestId, purpose: "confirm-decline" })

  }

  const onScheduleClick = async () => {
    setIsLoading(true)
    try {
      await schedulerController(props.selectedEventType)(
        _.omit(
          {
            ...props,
            isEditingRequest: props.requiredEventData?.isEditingRequest,
            eventRequestId: props.requiredEventData?.eventRequestId,
          },
          ["selectedEventType", "eventType"]
        )
      );
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
    props.setTrigger(false)
  }

  return (
    <div className={`flex ${mode === "edit" ? "md-justify-between" : "md:justify-end"} items-center  border-t-[1px] border-secondary-10 w-[100%] py-[16px]`}>
      <div className="flex items-center justify-end gap-[16px]">
        <button className="text-white font-[500] py-[10px] px-[20px] rounded-[6px] bg-secondary-60" onClick={handleDeclineClick}>Decline Request</button>
        <button className="text-white font-[500] py-[10px] px-[20px] rounded-[6px] bg-blue-70"
          onClick={onScheduleClick}
          disabled={validatorController(props.selectedEventType.toLowerCase())(props) || isLoading}
        >
          {isLoading ? <LoaderIcon /> : "Accept & Update"}
        </button>
      </div>
    </div>
  )

}

const EventSubmission = (props) => {
  if (props.tempEventType.toLowerCase() === frozenEventTypes.EXTMEETING.eventType.toLowerCase() && props.operationMode === "create-requested") {
    return (
      <RequestActions {...props} />
    )
  }
  return <EditActions {...props} />;
};

export default EventSubmission;
