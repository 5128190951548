import frozenEventTypes from "../../utils/eventTypes";
import frozenEventStatus from "../../utils/eventStatus";
import { getColourMappingForServices } from '../../utils/serviceColours';

// Helper function to get a random event type
const getRandomEventType = () => {
    const eventTypes = Object.values(frozenEventTypes);
    return eventTypes[Math.floor(Math.random() * eventTypes.length)];
};

// Helper function to get a random tutor name
const getRandomTutorName = () => {
    const tutors = [
        "John Doe", "Jane Smith", "Michael Brown", "Emily Davis",
        "Chris Johnson", "Sarah Wilson", "James Taylor", "Laura Martinez",
        "Daniel Lee", "Olivia White", "Matthew Harris", "Sophia Clark",
        "David Lewis", "Emma Walker", "Andrew Robinson", "Isabella Hall"
    ];
    return tutors[Math.floor(Math.random() * tutors.length)];
};

// Helper function to get 10% opacity color
const getTransparentColor = (hex, opacity = 0.1) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Dummy event data generation
export const generateDummyEvents = () => {
    const events = [];
    const startDate = new Date("2024-08-19T00:00:00");
    const endDate = new Date("2024-09-08T23:59:59");

    const services = [
        "DSAT Tutoring",
        "ACT Tutoring",
        "Math Tutoring",
        "Comprehension Tutoring",
        "Physics Tutoring",
        "Spanish Tutoring",
    ];

    const attendeesList = [
        "Alice Johnson", "Bob Smith", "Charlie Brown", "David Wilson",
        "Eva Martinez", "Frank Harris", "Grace Lee", "Hannah Young",
        "Ian Walker", "Jack Taylor", "Karen Anderson", "Laura Scott",
        "Michael Adams", "Nina Clark", "Oliver White", "Paula King", "Quincy Davis"
    ];

    // Event colors based on types
    const eventColors = {
        [frozenEventTypes.OFFCWORK.eventType]: "#9BCA7E",
        [frozenEventTypes.CONSULTATION.eventType]: "#559B9B",
        [frozenEventTypes.INTMEETING.eventType]: "#82848B",
        [frozenEventTypes.EXTMEETING.eventType]: "#BB7ECA",
    };

    // Get color mapping for services
    const serviceColorMapping = getColourMappingForServices(
        services.map((service) => ({ service }))
    );

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        let numberOfEvents = Math.floor(Math.random() * 3) + 1; // 1 to 3 events per day

        for (let i = 0; i < numberOfEvents; i++) {
            // Generate a start time between 2:00 AM (2 hours) and 10:00 PM (22 hours)
            const startHour = Math.floor(Math.random() * (22 - 2)) + 2; // Random hour from 2 to 21
            const startMinute = Math.random() < 0.5 ? 0 : 30;

            const startTime = new Date(currentDate);
            startTime.setHours(startHour, startMinute, 0);

            // Generate a duration between 1 hour and 3 hours
            const minDuration = 1; // Minimum duration in hours
            const maxDuration = 3; // Max duration in hours
            const durationHours = Math.random() * (maxDuration - minDuration) + minDuration;
            const endTime = new Date(startTime);
            endTime.setHours(startTime.getHours() + Math.floor(durationHours));
            endTime.setMinutes(startTime.getMinutes() + Math.floor((durationHours % 1) * 60));

            // Ensure the event does not exceed 10:00 PM
            if (endTime.getHours() >= 22) { // If end time is after 10:00 PM
                endTime.setHours(21, 59, 59); // Set to 9:59:59 PM
            }

            const randomService = services[Math.floor(Math.random() * services.length)];

            // Randomly select 1 to 5 attendees
            const numberOfAttendees = Math.floor(Math.random() * 5) + 1;
            const randomAttendees = [];
            for (let j = 0; j < numberOfAttendees; j++) {
                const randomAttendee = attendeesList[Math.floor(Math.random() * attendeesList.length)];
                if (!randomAttendees.includes(randomAttendee)) {
                    randomAttendees.push(randomAttendee);
                }
            }

            // Get a random event type
            const randomEventType = getRandomEventType();

            // Determine background color
            let backgroundColor = serviceColorMapping[randomService];
            if (randomEventType.eventType !== frozenEventTypes.SESSION.eventType) {
                backgroundColor = getTransparentColor(eventColors[randomEventType.eventType] || "#ffffff", 0.1);
            }

            events.push({
                title: `${randomService} - ${randomAttendees.join(', ')}`,
                start: startTime.toISOString(),
                end: endTime.toISOString(),
                extendedProps: {
                    attendees: randomAttendees,
                    note: "Focus on different sections",
                    eventType: randomEventType.eventType,
                    status: frozenEventStatus.PENDING.status,
                    tutor: getRandomTutorName(),
                    service: randomService,
                    location: "Online",
                    onlineMeet: "https://meet.google.com/fzx-tst-dev",
                    info: "Bring necessary materials for the session",
                },
                backgroundColor: backgroundColor,
            });
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return events;
};
