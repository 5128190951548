import HorizontalDivider from "../HorizontalDivider/HorizontalDivider"
import styles from "./CalendarSidebar.module.css"


function CalendarSidebar({ sections , hideSection2Divider, hideSection2DividerForRole}) {

	return (
		<div className={styles.container}>
			{
				sections.map((section, idx) => (
					<>
						{section}
						{idx < sections.length - 1 ?
								idx === 0 && (hideSection2Divider || hideSection2DividerForRole) ?
								<></> :
								<div className={styles.horizontalRule}>
									<HorizontalDivider />
								</div>
							: <></>
						}
					</>
				))
			}
		</div>
	)

}

export default CalendarSidebar
