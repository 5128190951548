import {
  faBookmark,
  faSquare,
  faXmark,
  faAngleUp,
  faAngleDown,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import Modal from "../../components/Modal/Modal";

export default function Foot(props) {
  // const {next,prev  ,ind,s,data ,set} =props
  const {
    next,
    prev,
    mark,
    name,
    i,
    s,
    data,
    sectionDetails,
    sectionindex,
    set,
    answers,
    handleSubmitSection,
    tog2,
    toggle2,
    markreview,
  } = props;

  const [toggle, setToggle] = useState(false);
  const [btnloader, setbtnloader] = useState(false);

  const questionNavigateRef = useRef(null);

  function set_btnloader() {
    setbtnloader(true);
  }

  let click = (e) => {
    if (e.target.innerText == 1) {
      set(1);
    }
    set(e.target.innerText - 1);
    console.log(e.target.innerText);
  };

  let click2 = (e) => {
    tog2();
    if (e.target.innerText == 1) {
      set(1);
    }
    set(e.target.innerText - 1);
    console.log(e.target.innerText);
  };

  useEffect(() => {
    if (toggle && toggle2) {
      setToggle(false);
    }
  }, [toggle2]);

  let tog = () => {
    setToggle(!toggle);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.classList.contains("questionNavigatePopUpBtn") &&
      questionNavigateRef.current &&
      !questionNavigateRef.current.contains(event.target)
    ) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (toggle) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggle]);

  return (
    <>
      {toggle2 ? (
        <section className="bg-white absolute top-[10%] px-12 pt-[32px] h-[100%] w-full">
          <h3 className="text-center text-lg font-semibold">
            Section {" " + sectionindex + " "} :
            {" " + (sectionDetails?.name ? sectionDetails.name : "loading...")}{" "}
          </h3>
          <div className="py-3 my-3 flex text-center align-middle justify-evenly border border-gray border-y-2">
            <div>
              <FontAwesomeIcon
                icon={faSquare}
                color="white"
                className="border-black border rounded mr-2"
              />
              <span>Unanswered</span>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faBookmark}
                color="yellow"
                className="mr-2"
              />
              <span>For review</span>
            </div>
          </div>
          <div className="pt-8 flex justify-center">
            {data.map((e, ind) => {
              return (
                <span
                  key={ind}
                  onClick={click2}
                  className={`text-black font-semibold border cursor-pointer border-dotted border-black py-2 px-3 mx-2 ${
                    answers[ind].ResponseAnswer !== ""
                      ? "text-white bg-blue-600"
                      : ""
                  } ${markreview[ind]?.review && "bg-yellow-400"}`}
                >
                  {ind + 1}
                </span>
              );
            })}
          </div>
        </section>
      ) : null}

      <div>
        {toggle ? (
          <div className="question_navigate absolute w-full flex bottom-[110px] left-0 justify-center items-center">
            <section
              ref={questionNavigateRef}
              className="bg-white shadow-md shadow-gray-400 px-12 py-4 h-[70%] w-[30%] border rounded"
            >
              <h3 className="text-center text-lg py-3 font-semibold">
                Section {" " + sectionindex + " "} :{" "}
                {" " +
                  (sectionDetails?.name
                    ? sectionDetails.name
                    : "loading...")}{" "}
              </h3>
              <div className="py-3 my-3 flex text-center flex-wrap align-middle justify-evenly border-t-3 border-b-3 border-grey">
                <div>
                  <FontAwesomeIcon
                    icon={faLocationPin}
                    color="black"
                    className="border-black rounded mr-2"
                  />
                  <span>Current</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSquare}
                    color="white"
                    className="border-black border rounded mr-2"
                  />
                  <span>Unanswered</span>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faBookmark}
                    color="Yellow"
                    className="mr-2"
                  />
                  <span>For review</span>
                </div>
              </div>
              <div className="py-4 flex gap-5 flex-wrap">
                {data?.map((e, ind) => {
                  return (
                    <div className="flex flex-col  w-[30px] h-[35px] justify-end ">
                      {i === ind + 1 && (
                        <FontAwesomeIcon
                          icon={faLocationPin}
                          color="black"
                          className="my-[2px]"
                        />
                      )}

                      <span
                        key={ind}
                        onClick={click}
                        className={`relative text-center  text-black font-semibold cursor-pointer border border-dotted border-black h-[25px]  px-2   ${
                          !i === ind + 1 ? "mt-[15px]" : "mt-0"
                        }${
                          i === ind + 1 && answers[ind].ResponseAnswer == ""
                            ? "text-secondary"
                            : answers[ind].ResponseAnswer !== "" &&
                              i === ind + 1
                            ? "text-white bg-blue-600 "
                            : answers[ind].ResponseAnswer !== ""
                            ? "text-white bg-blue-600 "
                            : ""
                        } ${markreview[ind]?.review && "bg-[#FFFF00]"}`}
                      >
                        {mark[i + 1] ? (
                          <FontAwesomeIcon
                            icon={faBookmark}
                            className="absolute top-0 text-red-700"
                          />
                        ) : null}
                        {ind + 1}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-center items-center">
                <div
                  className="left-[500px] text-blue-700 w-[300px] cursor-pointer font-semibold flex justify-center items-center mx-16 px-6 border border-blue-700 mt-8 py-1 rounded-2xl"
                  onClick={() => {
                    tog();
                    tog2();
                  }}
                >
                  <button> Go to review page </button>
                </div>
              </div>
            </section>
          </div>
        ) : null}

        <footer className="w-[1920px] absolute bg-white left-0 right-0 bottom-0 flex justify-between px-12 pt-6 pb-8 border-dashed border-t-3 border-black">
          <div className="flex justify-start items-center w-1/3">
            <h2 className="text-xl font-semibold">{name}</h2>
          </div>

          {toggle2 ? null : (
            <div className="flex justify-center items-center w-1/3">
              <button
                className="bg-black text-white rounded-lg w-48 relative py-2 questionNavigatePopUpBtn"
                onClick={tog}
              >
                Question {i} of {s}{" "}
                <FontAwesomeIcon
                  icon={toggle ? faAngleUp : faAngleDown}
                  className="ml-3"
                />
              </button>
            </div>
          )}
          <div className="flex flex-row justify-end items-center w-1/3">
            {i > 1 ? (
              <button
                className="bg-blue-700 rounded-full py-2 px-6 text-white font-semibold mx-2"
                onClick={toggle2 ? tog2 : prev}
              >
                Back
              </button>
            ) : null}

            <button
              className="bg-blue-700 rounded-full py-2 px-6 text-white font-semibold"
              onClick={
                !toggle2 ? (i === s ? set_btnloader : next) : set_btnloader
              }
            >
              {toggle2 ? "Submit" : i === s ? "Submit" : "Next"}
            </button>
          </div>
        </footer>
      </div>

      {btnloader ? (
        <div className="max-w-1/2 relative flex justify-center items-center h-full">
          <Modal
            topClass={"!h-[115%]"}
            classname="w-[700px] mx-auto mt-[14rem]"
            title="Are you sure you want to Submit this section?"
            crossBtn2={true}
            showcloseIcon={false}
            crossBtnClassName={"!hidden"}
            canceliconvisible={false}
            titleClassName="flex justify-center w-full font-[400] text-secondary"
            cancelBtn={true}
            cancelBtnClassName={`w-[146.67px] h-[46.67px] flex justify-center items-center font-medium rounded-[8px] text-[#FF7979] border border-[#FF7979] bg-white`}
            primaryBtn={{
              text: "Yes",
              className:
                "mt-0 bg-success text-white w-[146.67px] h-[46.67px] ml-0",
              onClick: handleSubmitSection,
            }}
            underline={true}
            handleClose={() => setbtnloader(false)}
          />
        </div>
      ) : null}
    </>
  );
}
