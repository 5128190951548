import React, { useEffect, useState } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const AddressDisplayCard = ({ address, isEditing, onEdit, onDelete }) => {
  const [formattedAddress, setFormattedAddress] = useState("");

  useEffect(() => {
    const { formattedAddress } = formatAddress(address);
    setFormattedAddress(formattedAddress);
  }, [address]);

  const handleEditClick = () => {
    onEdit();
  };

  const handleDeleteClick = () => {
    onDelete();
  };

  return (
    <div className="info-display-card flex flex-col gap-2 p-4 ">
      <div className="flex justify-between items-center w-full  pb-[2px] border-b-[1px] border-secondary-10">
        <div className="flex items-center gap-2">
          <LocationOnOutlinedIcon fontSize="small" />
          <span>{formattedAddress}</span>
          {address.isPrimary && (
            <span className="cursor-default rounded-full h-fit text-[12px] font-[400] leading-[18px] px-[10px] py-[2px] bg-success/10 text-success">
              Primary
            </span>
          )}
        </div>
        {isEditing && (
          <div className="flex gap-2">
            <button onClick={handleEditClick} className="text-blue-700">
              Edit
            </button>
            <button onClick={handleDeleteClick} className="text-[#FF7979]">
              Delete
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const formatAddress = (fullAddress) => {
  const { address, city, state, country, zip } = fullAddress;
  const parts = [address, city, state, zip, country].filter(Boolean);
  return {
    formattedAddress: parts.join(", "),
  };
};

export default AddressDisplayCard;
