import React from 'react'

const SettingsIcon = ({ type }) => {
    return type !== "active" ? (
        <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11041_35979)">
                <path d="M21.0317 13.1751L19.0708 11.6418C19.0957 11.4053 19.1171 11.1289 19.1171 10.8364C19.1171 10.544 19.0966 10.2676 19.0708 10.0311L21.0335 8.4969C21.398 8.2089 21.4975 7.69778 21.2655 7.27203L19.2264 3.74399C19.0086 3.34578 18.5313 3.14132 18.0468 3.31999L15.7331 4.24886C15.2895 3.92886 14.8246 3.65865 14.3437 3.44265L13.9917 0.988446C13.9339 0.522654 13.5286 0.170654 13.0495 0.170654H8.96064C8.48151 0.170654 8.07709 0.522654 8.02018 0.981321L7.6673 3.44445C7.20151 3.65424 6.74376 3.9209 6.28063 4.25065L3.96063 3.31911C3.52593 3.15111 3.00593 3.33953 2.78993 3.73599L0.748176 7.26845C0.507301 7.67557 0.606843 8.20445 0.979301 8.49957L2.94018 10.0329C2.90905 10.3324 2.89397 10.5929 2.89397 10.8374C2.89397 11.0818 2.90909 11.3422 2.94018 11.6427L0.97751 13.1769C0.613051 13.4658 0.514385 13.9769 0.746385 14.4018L2.78551 17.9298C3.0033 18.3271 3.47618 18.5333 3.96505 18.3538L6.27884 17.4249C6.72151 17.744 7.18638 18.0142 7.6673 18.2311L8.0193 20.6844C8.07705 21.152 8.48151 21.504 8.96151 21.504H13.0504C13.5295 21.504 13.9348 21.152 13.9917 20.6933L14.3446 18.2311C14.8104 18.0204 15.2673 17.7547 15.7313 17.424L18.0513 18.3555C18.1623 18.399 18.2805 18.4213 18.3997 18.4213C18.7446 18.4213 19.0619 18.2329 19.2219 17.9395L21.2699 14.3929C21.4975 13.9769 21.3979 13.4658 21.0317 13.1751ZM11.0051 14.3929C9.04418 14.3929 7.44951 12.7982 7.44951 10.8373C7.44951 8.87645 9.04418 7.28178 11.0051 7.28178C12.9659 7.28178 14.5606 8.87645 14.5606 10.8373C14.5606 12.7982 12.9659 14.3929 11.0051 14.3929Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_11041_35979">
                    <rect width="21.3333" height="21.3333" fill="white" transform="translate(0.335938 0.170898)" />
                </clipPath>
            </defs>
        </svg>

    ) :
        (
            <svg width="28" height="28" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_11041_35979)">
                    <path d="M21.0317 13.1751L19.0708 11.6418C19.0957 11.4053 19.1171 11.1289 19.1171 10.8364C19.1171 10.544 19.0966 10.2676 19.0708 10.0311L21.0335 8.4969C21.398 8.2089 21.4975 7.69778 21.2655 7.27203L19.2264 3.74399C19.0086 3.34578 18.5313 3.14132 18.0468 3.31999L15.7331 4.24886C15.2895 3.92886 14.8246 3.65865 14.3437 3.44265L13.9917 0.988446C13.9339 0.522654 13.5286 0.170654 13.0495 0.170654H8.96064C8.48151 0.170654 8.07709 0.522654 8.02018 0.981321L7.6673 3.44445C7.20151 3.65424 6.74376 3.9209 6.28063 4.25065L3.96063 3.31911C3.52593 3.15111 3.00593 3.33953 2.78993 3.73599L0.748176 7.26845C0.507301 7.67557 0.606843 8.20445 0.979301 8.49957L2.94018 10.0329C2.90905 10.3324 2.89397 10.5929 2.89397 10.8374C2.89397 11.0818 2.90909 11.3422 2.94018 11.6427L0.97751 13.1769C0.613051 13.4658 0.514385 13.9769 0.746385 14.4018L2.78551 17.9298C3.0033 18.3271 3.47618 18.5333 3.96505 18.3538L6.27884 17.4249C6.72151 17.744 7.18638 18.0142 7.6673 18.2311L8.0193 20.6844C8.07705 21.152 8.48151 21.504 8.96151 21.504H13.0504C13.5295 21.504 13.9348 21.152 13.9917 20.6933L14.3446 18.2311C14.8104 18.0204 15.2673 17.7547 15.7313 17.424L18.0513 18.3555C18.1623 18.399 18.2805 18.4213 18.3997 18.4213C18.7446 18.4213 19.0619 18.2329 19.2219 17.9395L21.2699 14.3929C21.4975 13.9769 21.3979 13.4658 21.0317 13.1751ZM11.0051 14.3929C9.04418 14.3929 7.44951 12.7982 7.44951 10.8373C7.44951 8.87645 9.04418 7.28178 11.0051 7.28178C12.9659 7.28178 14.5606 8.87645 14.5606 10.8373C14.5606 12.7982 12.9659 14.3929 11.0051 14.3929Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_11041_35979">
                        <rect width="21.3333" height="21.3333" fill="white" transform="translate(0.335938 0.170898)" />
                    </clipPath>
                </defs>
            </svg>

        )
}

export default SettingsIcon