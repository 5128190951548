import React from "react";
import MoreInfoIcon from "../../MoreInfoIcon/MoreInfoIcon";
import { Tooltip } from "@mui/material";
import ArrowUp from "../../../assets/calendarV2/ArrowUpGreen.svg";
import { useSelector } from "react-redux";

const RevenueCostCard = ({ details }) => {
  const user = useSelector((state) => state.user);

  console.log(details);
  return (
    <main
      className="w-full  rounded-[6px] flex  min-w-[200px]  flex-col gap-[8px] p-[12px] "
      style={{ backgroundColor: details && details.bgColor }}
    >
      <section className=" flex w-fit gap-[6px] items-center  justify-center ">
        <p className=" text-secondary-80 text-[14px] leading-[21px] font-normal ">
          {" "}
          {details && details.name && details.name}
        </p>
        <Tooltip>
          {" "}
          <MoreInfoIcon />{" "}
        </Tooltip>
      </section>

      <section className=" w-full items-center flex justify-between">
        <p
          className=" text-[18px] font-medium leading-[18px]"
          style={{
            color: details && details.amountColor && details.amountColor,
          }}
        >
          ${details && details.amount && details.amount}
        </p>

        <div className=" flex items-center justify-center  gap-[2px] hidden">
          <img src={ArrowUp} alt="arrow" />
          <span className=" text-[#099A66] text-[14px]  leading-[21px] ">
            {details && details.growthPercentage && details.growthPercentage}
          </span>
        </div>
      </section>
    </main>
  );
};

export default RevenueCostCard;
