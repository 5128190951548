import React from 'react'

const DustbinIcon = (props) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M2 4.45508H3.33333H14" stroke={props.inherit ? "currentColor" : "#F97066"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.6663 4.45475V13.7881C12.6663 14.1417 12.5259 14.4808 12.2758 14.7309C12.0258 14.9809 11.6866 15.1214 11.333 15.1214H4.66634C4.31272 15.1214 3.97358 14.9809 3.72353 14.7309C3.47348 14.4808 3.33301 14.1417 3.33301 13.7881V4.45475M5.33301 4.45475V3.12142C5.33301 2.7678 5.47348 2.42866 5.72353 2.17861C5.97358 1.92856 6.31272 1.78809 6.66634 1.78809H9.33301C9.68663 1.78809 10.0258 1.92856 10.2758 2.17861C10.5259 2.42866 10.6663 2.7678 10.6663 3.12142V4.45475" stroke="#F97066" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.66699 7.78809V11.7881" stroke="#F97066" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.33301 7.78809V11.7881" stroke="#F97066" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default DustbinIcon
