export default function CrossIcon (props) {

	return(

		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_34032_59750)">
				<path d="M7.00002 14C10.866 14 14 10.866 14 7.00002C14 3.13401 10.866 0 7.00002 0C3.13401 0 0 3.13401 0 7.00002C0 10.866 3.13401 14 7.00002 14Z" fill={props.color ?? "currentColor" } id="pathOne"/>
				<path d="M14 6.99998C14 10.85 10.85 14 7.00001 14C4.76877 14 2.80002 12.9937 1.53125 11.375C2.7125 12.3375 4.24376 12.9063 5.90624 12.9063C9.75626 12.9063 12.9063 9.75626 12.9063 5.90624C12.9063 4.24376 12.3375 2.7125 11.375 1.53125C12.9938 2.79996 14 4.76871 14 6.99998Z" fill={props.color ?? "currentColor" } id="pathTwo"/>
				<path d="M10.1937 10.1937C9.93124 10.4562 9.49372 10.4562 9.23123 10.1937L6.99999 7.96249L4.76875 10.1937C4.50626 10.4562 4.06873 10.4562 3.80624 10.1937C3.54375 9.93124 3.54375 9.49372 3.80624 9.23123L6.03748 6.99999L3.80624 4.76875C3.54375 4.50626 3.54375 4.06873 3.80624 3.80624C4.06873 3.54375 4.50626 3.54375 4.76875 3.80624L6.99999 6.03748L9.23123 3.80624C9.49372 3.54375 9.93124 3.54375 10.1937 3.80624C10.4562 4.06873 10.4562 4.50626 10.1937 4.76875L7.96249 6.99999L10.1937 9.23123C10.4562 9.49372 10.4562 9.93121 10.1937 10.1937Z" fill="white"/>
			</g>
			<defs>
				<clipPath id="clip0_34032_59750">
					<rect width="14" height="14" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	)

}
