import ButtonV2 from '../ButtonV2/ButtonV2'
import CrossIcon from '../CrossIcon/CrossIcon'
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon'
import TickMarkIcon from '../TickMarkIcon/TickMarkIcon'
import ToggleButtonV2 from '../ToggleButtonV2/ToggleButtonV2'
import styles from './PackageCard.module.css'
import horizontalLineIcon from './horizontalDividerLine.svg'
import verticalLineIcon from './verticalDividerLine.svg'
import ArrowIconLeftV2 from '../ArrowIconLeftV2/ArrowIconLeftV2'
import ProductCardDisplay from '../ProductCard/ProductCardDisplay'
import { useEffect } from 'react'
import Tooltip from '../Tooltip/Tooltip'
import InfoIcon from '../InfoIcon/InfoIcon'

const tutorLicensesMapping = {
    Explorer: 1,
    Starter: 1,
    Professional: 10,
    Premium: 30,
};


const additionalTutorLicensesPricingMapping = {
    monthly : {
        Starter: 1,
        Professional: 2,
        Premium: 3, 
    },
    annual : {
        Starter: 10,
        Professional: 20,
        Premium: 30,
    }
}
  

function dynamicString(productName, data) {
    switch (productName) {
        case "Calendar": return <div>Calendar - <span className='font-[700]'>{data}&nbsp;</span>sessions</div>;
        case "Assignments": return <div>Assignments - <span className='font-[700]'>{data}&nbsp;</span>credits</div>;
    }
}

function getToolTipDynamicString(str,data){
    const arr = str.split("{X}")
    arr.splice(1,0,data)
    return arr.join("")
}

function fetchLimitForPriceId(productPrices, productName, priceId, selectedPlanType) {
    if (!productPrices) {
        return
    } else {
        const product = productPrices.find((product) => product.product.name.includes(productName))
        const prices = product.prices[selectedPlanType === 0 ? "monthly" : "annual"]
        console.log(prices)
        console.log(productName, priceId, selectedPlanType)
        const price = prices.find((price) => price.id === priceId)
        if (!price) return;
        return price.metadata.limit === "-1" ? "unlimited" : price.metadata.limit
    }
}

//Additional Detials format [{name : "", details : [""]}]
const PackageCard = ({ isExpanded, setIsExpanded, summaryImg, isRecommended, basePackageName, basePackageDescription, price, packageType, buttonContent, onCTAButtonClick, additionalDetails, idx, onBackButtonClick, isEnabledPAYG, setIsEnabledPAYG, showPAYG, productPrices, selectedPlanType, selectedProducts, setSelectedProducts, calculatedPrice, setNoOfTutorsForPackage, displayPricesProducts, handleProceedButtonClicked, extraTutors, setPaygEnabled, handleStartClickForFreePackage, noOfTutors }) => {

    useEffect(() => {
        console.log(">>>PACKAGE CARD", productPrices)
    }, [productPrices])



    if (!isExpanded) {
        return (
            <div className={`${styles.container} ${!isRecommended ? "" : styles.recommendedContainer} ${noOfTutors >=16 ? noOfTutors > 20 ? styles.containerHeightForMoreTutors2 : styles.containerHeightForMoreTutors1 :  "" }`} id={`${basePackageName}_container`}>
                <div className={`${styles.recommendedPackageContainer} ${!isRecommended ? styles.notRecommended : styles.recommended}`}>BEST FOR {noOfTutors} TUTORS</div>
                <div className={styles.packageDetailsContainer}>
                    <div className={styles.summary}>
                        <img src={summaryImg} className={styles.img}/>
                        <h3>{basePackageName}</h3>
                        <div className={styles.basePackageDescription}>{basePackageDescription}</div>
                        <div className={styles.priceContainer}>
                            <h1>{calculatedPrice[basePackageName] ? Math.abs(calculatedPrice[basePackageName]) === 0 ? "Free" : `\$${calculatedPrice[basePackageName]}` : price}</h1>
                            <div className={styles.priceDetails} style={{ display: displayPricesProducts[idx] === 0 ? "none" : "" }}>
                                <div>{packageType === "annual" ? "billed annually" : "billed monthly"}</div>
                                <div>{packageType === "annual" ? <><span>${displayPricesProducts[idx]}</span>/month</> : <><span>${displayPricesProducts[idx]}</span>/month</>}</div>
                            </div>
                        </div>
                    </div>
                    <ButtonV2 className={isRecommended ? `${styles.recommendedButton}` : `${styles.normalButton}`} onClick={(e) => onCTAButtonClick(e)} id={basePackageName + "_" + idx}>{buttonContent}</ButtonV2>
                    <div className={styles.additionalDetails}>
                        {
                            additionalDetails.map((detail) => {
                                const productName = detail.name

                                const isProductSelected = productName === "basePackage" ? true : selectedProducts[idx][productName].selected

                                const limitData = productName !== "basePackage" && selectedProducts[idx][productName].value ? fetchLimitForPriceId(productPrices, productName, selectedProducts[idx][productName].value, selectedPlanType) : null
                                const displayString = limitData ? dynamicString(productName, limitData) : null

                                return (
                                    detail.details.map((aDetail, _idx) => (
                                        <div className={`${styles.item}`} >
                                            <div className={`${isProductSelected ? styles.itemImageSelected : styles.itemImageDeselected}`}>
                                                {isProductSelected ? <TickMarkIcon /> : <CrossIcon />}
                                            </div>
                                            <div className={`${isProductSelected ? styles.itemSelected : styles.itemDeselected}`}>
                                                {displayString ? displayString : aDetail}
                                            </div>
                                            {
                                                detail.tooltipText ?
                                                    <Tooltip anchorComponent={<InfoIcon />} 
                                                            tooltipStyleClass={basePackageName === "Premium" ? styles.tooltipStylingPremium : styles.tooltipStyling}
                                                            tooltipTriangleStyling={basePackageName === "Premium" ? styles.tooltipTrianglePremium : ""}
                                                            tooltipContent={isProductSelected ? limitData ? getToolTipDynamicString(detail.tooltipText[_idx],limitData) :  detail.tooltipText[_idx] : `Choose "${productName}" under the Products section to enable this feature.`} />
                                                    : <></>
                                            }
                                        </div>
                                    ))
                                )
                            })
                        }
                        <button className={styles.viewAllButton}><a href="#price_comparator_table">View All</a></button>
                    </div>
                    {
                        showPAYG ?
                            <div className={`${styles.sectionPAYG}`}>
                                {isExpanded ? <></> : <img src={horizontalLineIcon} />}
                                <div className='flex justify-between items-center gap-[1rem] w-full'>
                                    <div className='flex flex-col justify-start align-start gap-[4px]'>
                                        <div className={`${styles.heading}`}>Pay as you go</div>
                                        <div className={`${styles.helperText}`}>Applies after you exceed usage limits</div>
                                    </div>
                                    <ToggleButtonV2 setIsEnabled={setIsEnabledPAYG} isEnabled={isEnabledPAYG} onClick={setPaygEnabled} />
                                </div>
                            </div> :
                            <>
                                <div className={`${styles.sectionPAYG} ${styles.hidePAYG}`}>
                                    <div className='h-[2px] w-full'>&nbsp;</div>
                                    <div className='flex justify-between items-center gap-[1rem] w-full' >
                                        <div className='flex flex-col justify-start align-start gap-[4px]' >
                                            <div className={`${styles.heading}`} style={{ color: "white", content: "" }}>Pay as you go</div>
                                            <div className={`${styles.helperText}`} style={{ color: "white", content: "" }}>Applies after you exceed usage limits</div>
                                        </div>
                                        <div className='h-[16px] w-[36px]'>&nbsp;</div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className={`${styles.expandedContainer} ${!isRecommended ? "" : styles.recommendedContainer} ${noOfTutors >=16 ? noOfTutors > 20 ? styles.containerHeightForMoreTutors2 : styles.containerHeightForMoreTutors1 :  "" }`} id={`${basePackageName}_expanded_container`}>
                <div className={`${styles.recommendedPackageContainer} ${!isRecommended ? styles.notRecommended : styles.recommended}`}>BEST FOR YOU</div>
                <div className={`${styles.innerWrapper}`}>
                    <div className={styles.packageDetailsContainer}>
                        <div className={styles.summary}>
                            <img src={summaryImg} className={styles.img}/>
                            <h3>{basePackageName}</h3>
                            <div className={styles.basePackageDescription}>{basePackageDescription}</div>
                            <div className={styles.priceContainer}>
                                <h1>{calculatedPrice[basePackageName] ? `\$${calculatedPrice[basePackageName]}` : price}</h1>
                                <div className={styles.priceDetails}>
                                    <div>{packageType === "annual" ? "billed annually" : "billed monthly"}</div>
                                    <div>{packageType === "annual" ? <><span>${displayPricesProducts[idx]}</span>/month</> : <><span>${displayPricesProducts[idx]}</span>/month</>}</div>
                                </div>
                            </div>
                        </div>
                        {
                            idx > 0 ? 
                            <div className={styles.basePackagePriceDetails}>
                               <> Base Price of Plan  </> <div>${Math.round((productPrices?.find((product)=>product.product.name.includes("BasePackage"))?.prices[selectedPlanType === 0 ? "monthly" : "annual"]?.find((price)=>price.lookup_key.includes(basePackageName))?.unit_amount)/(selectedPlanType === 0 ? 100 : 1200)).toFixed(0)}/mo</div>
                            </div>
                             : 
                             <></>
                        }

                        <div className={styles.additionalDetails}>
                            {
                                additionalDetails.map((detail) => {
                                    const productName = detail.name

                                    const isProductSelected = productName === "basePackage" ? true : selectedProducts[idx][productName].selected

                                    const limitData = productName !== "basePackage" && selectedProducts[idx][productName].value ? fetchLimitForPriceId(productPrices, productName, selectedProducts[idx][productName].value, selectedPlanType) : null
                                    const displayString = limitData ? dynamicString(productName, limitData) : null

                                    return (
                                        detail.details.map((aDetail,_idx) => (
                                            <div className={`${styles.item}`} >
                                                <div className={`${isProductSelected ? styles.itemImageSelected : styles.itemImageDeselected}`}>
                                                    {isProductSelected ? <TickMarkIcon /> : <CrossIcon />}
                                                </div>
                                                <div className={`${isProductSelected ? styles.itemSelected : styles.itemDeselected}`}>
                                                    {displayString ? displayString : aDetail}
                                                </div>
                                                {
                                                    detail.tooltipText ?
                                                        <Tooltip anchorComponent={<InfoIcon />} tooltipStyleClass={styles.tooltipStyling} tooltipContent={isProductSelected ? limitData ? getToolTipDynamicString(detail.tooltipText[_idx],limitData) :  detail.tooltipText[_idx] : `Choose "${productName}" under the Products section to enable this feature.`} />
                                                        : <></>
                                                }
                                            </div>
                                        ))
                                    )
                                })
                            }
                            <button className={styles.viewAllButton}><a href="#price_comparator_table">View All</a></button>
                        </div>
                        {/* ${noOfTutors >=16 ? noOfTutors > 20 ? "84px" : "74px" :  "64px" } */}
                        <div className={`h-[calc(100vw*0/1440)] 2.5xl:hidden w-full -mt-[14px]`}>&nbsp;</div>
                        <div className={`${styles.sectionPAYG}`}>
                            {
                                showPAYG ?
                                    <>
                                        {isExpanded ? <></> : <img src={horizontalLineIcon} />}
                                        <div className='flex justify-between items-center gap-[1rem] w-full'>
                                            <div className='flex flex-col justify-start align-start gap-[4px]'>
                                                <div className={`${styles.heading}`}>Pay as you go</div>
                                                <div className={`${styles.helperText}`}>Applies after you exceed usage limits</div>
                                            </div>
                                            <ToggleButtonV2 setIsEnabled={setIsEnabledPAYG} isEnabled={isEnabledPAYG} />
                                        </div>
                                    </>
                                    : <>
                                        <div className='h-[2px] w-full'>&nbsp;</div>
                                        <div className='flex justify-between items-center gap-[1rem] w-full' style={{ color: "white", content: "" }}>
                                            <div className='flex flex-col justify-start align-start gap-[4px]' style={{ color: "white", content: "" }}>
                                                <div className={`${styles.heading}`} style={{ color: "white", content: "" }}>Pay as you go</div>
                                                <div className={`${styles.helperText}`} style={{ color: "white", content: "" }}>Applies after you exceed usage limits</div>
                                            </div>
                                            <div className='h-[18px] w-[36px]'>&nbsp;</div>
                                        </div></>
                            }

                        </div>
                    </div>
                    <img src={verticalLineIcon} />
                    <div className={styles.productSelectionContainer}>
                        <div className='flex flex-col justify-start items-start gap-[1.5rem] w-full m-0 p-0'>
                            <div className={styles.heading}>Products included</div>
                            <div className={styles.productsDesc}>{selectedPlanType === 0 ? "Product quantities shown below will reset automatically every month." : "Product quantities shown below will be added cumulatively for the entire year."}</div>
                            <div className={styles.tutorAmountSelector}>
                                <div className='flex justify-start items-center gap-[0.25rem]'> <div>Additional Tutor Licenses</div> <button><Tooltip anchorComponent={<MoreInfoIcon />} tooltipContent={`The ${basePackageName} plan includes ${tutorLicensesMapping[basePackageName]} number of Tutor Licenses. You can get additional Tutor Licenses for this package at the rate of \$${additionalTutorLicensesPricingMapping[selectedPlanType===0 ? "monthly" : "annual"][basePackageName]} per license.`} tooltipStyleClass={basePackageName === "Premium" ? styles.tooltipStylingPremium : styles.tooltipStyling} tooltipTriangleStyling={basePackageName === "Premium" ? styles.tooltipTrianglePremium: ""}/></button> </div>
                                <div className='text-[calc(100vw*16/1440)] 2.5xl:text-[16px] font-[400] text-right text-secondary-80'>{extraTutors[idx]}</div>
                            </div>
                            <ProductCardDisplay productPrices={productPrices} selectedPlanType={selectedPlanType} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} idx={idx} />
                        </div>
                        <div className={styles.actionArea}>
                            <button className={styles.backButton} onClick={(e) => onBackButtonClick()}>
                                <div className={styles.content}>
                                    <div><ArrowIconLeftV2 /></div>
                                    <div>Back</div>
                                </div>
                            </button>
                            <ButtonV2 style={{ width: "180px" }} id={`${selectedPlanType === 0 ? "proceed-monthly_" + idx : "proceed-annual_" + idx}`} onClick={(e) => handleProceedButtonClicked(e)}>Proceed</ButtonV2>
                        </div>
                    </div>
                </div>
            </div>

        )

    }


}


export default PackageCard