import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import PopupContainer from '../../../../PopupContainer/PopupContainer'
import styles from './SessionTagsOperationPopUp.module.css'
import CrossIcon from '../../../../CrossIcon/CrossIcon'
import PillContainer from '../../../../PillContainer/PillContainer';
import LoaderIcon from '../../../../LoaderIcon/LoaderIcon';
import AddTextInput from '../../../../AddTextInput/AddTextInput';
import strLengthConfig from '../../../../../utils/strLengthConfig.json'


const SessionTagsOperationPopUp = ({ trigger, setTrigger, sessionData, handleSaveClick, popUpOperationMode }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [heading, setHeading] = useState("Add Session Tag")
    const [operationMode, setOperationMode] = useState("add")
    const [tempSessionData, setTempSessionData] = useState(null)
    const [tempSessionTagName, setTempSessionTagName] = useState(null)

    useEffect(() => {
        if (popUpOperationMode === "edit") {
            setHeading("Edit Session Tag")
        } else {
            setHeading("Add Session Tag")
        }
    }, [popUpOperationMode])

    useEffect(() => {
        if (sessionData) {
            setTempSessionData({ ...sessionData })
            setTempSessionTagName(sessionData.heading)
        } else {
            setTempSessionData({
                heading: "",
                items: []
            })
            setTempSessionTagName("")
        }


    }, [sessionData, trigger])

    useEffect(()=>{
        if(tempSessionData){
            setTempSessionData((prev) => ({ ...prev, heading : tempSessionTagName }))
        }
    },[tempSessionTagName])

    return (
        <PopupContainer trigger={trigger} setTrigger={setTrigger} customCloseButton={true}>
            <div className={styles.container}>
                <div className={styles.headingContainer}>
                    <>{heading}</>
                    <button onClick={(e) => {setTrigger(false)}}><CrossIcon /></button>
                </div>
                <div className={styles.inputGroup}>
                    <label>Session Heading*</label>
                    <input type="text" className={styles.insertInput} placeholder="Enter service name"  value={tempSessionTagName} onChange={(e)=>{if(e.target.value.length <= strLengthConfig.headings){setTempSessionTagName(e.target.value)}}}  />
                </div>
                <div className={styles.inputGroup}>
                    <label>Add Tags <span className='text-secondary-80 font-[14px]'>(optional)</span> </label>
                    <AddTextInput validationFunction={(targetValue)=>{return targetValue.length <= strLengthConfig.tags}} allowSpaces={true} values={tempSessionData?.heading} handleAddToInput={(targetValue)=>{setTempSessionData((prev)=>({...prev, items : [targetValue, ...prev.items]}))}}/>
                </div>

                <PillContainer customClass={styles.pillContainerClass} items={tempSessionData?.items.map(val => ({ displayTitle: val }))} withoutBorder={true} isCrossButtonVisible={true} onCrossButtonClick={(item, idx) => { setTempSessionData((prev) => ({ ...prev, items: prev.items.filter((val, _idx) => _idx !== idx) })) }} />

                <div className={styles.actionArea}>
                    <button className={styles.cancelButton} onClick={(e) => setTrigger(false)}>Cancel</button>
                    {
                        isLoading ? 
                            <div className={styles.saveButton}><LoaderIcon /> </div>
                            :
                            <button className={styles.saveButton} onClick={(e) => handleSaveClick(tempSessionData?.idx, tempSessionData, setIsLoading)} disabled={tempSessionData?.heading.trim() === ""}>Save</button>

                    }
                </div>
            </div>

        </PopupContainer>
    )
}


export default SessionTagsOperationPopUp 