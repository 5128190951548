import React, { useState } from "react";
import styles from "./RecurringSession.module.css";
import "../../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import { frequencyOptions } from "../../CalendarV2DateTime/CalendarDateTimeSelector/CalendarDateTimeSelector";
import Weekly from "../Weekly/Weekly";
import Month from "../Month/Month";
import DatePickerEndsOn from "../Date/DatePicker";

const RecurringSession = ({
  isRepeatsOpen,
  setIsRepeatsOpen,
  frequency,
  setFrequency,
  isEndsOnDatePickerOpen,
  setIsEndsOnDatePickerOpen,
  endDate,
  setSelectedDate,
  handleEndDateChange,
  selectedDay,
  handleDayClick,
}) => {
  return (
    <div className={styles.component}>
      <div className={styles.frame}>
        <div className={styles.div}>
          <div className={styles.frame2}>
            <div className={styles.frame3}>
              <div className={styles.textWrapper}>Repeats</div>
              <div
                className={styles.disabledUserWrapper}
                onClick={() => setIsRepeatsOpen(!isRepeatsOpen)}
              >
                <div className={styles.disabledUser}>
                  <div className={styles.textWrapper2}>{frequency}</div>
                  <img
                    className={styles.inputFieldIcon}
                    src="/images/down-arrow.svg"
                    alt="Dropdown icon"
                  />
                </div>
              </div>
              {isRepeatsOpen && (
                <>
                  <div
                    className={`${styles.repeatDropdown} ${styles.repeatStartDropdown}`}
                  >
                    {frequencyOptions.map((time) => (
                      <div
                        key={time}
                        className={styles.dropdownOption}
                        onClick={() => {
                          setFrequency(time);
                          setIsRepeatsOpen(!isRepeatsOpen);
                        }}
                      >
                        <div className={styles.elementSessionsMo}>{time}</div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {frequency == "Weekly" && (
              <Weekly
                selectedDay={selectedDay}
                handleDayClick={handleDayClick}
              />
            )}
            {frequency == "Monthly" && <Month />}
            {/* <div className={styles.frame4}>
              <div className={styles.textWrapper3}>Repeats on</div>
              <div className={styles.frame5}>
                {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
                  <div
                    key={index}
                    className={`${styles.divWrapper} ${
                      selectedDay === index ? styles.selectedDay : ""
                    }`}
                    onClick={() => handleDayClick(index)}
                  >
                    <div className={styles[`textWrapper${index + 4}`]}>
                      {day}
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          <div
            className={styles.inputFieldHeader}
            onClick={() => setIsEndsOnDatePickerOpen(!isEndsOnDatePickerOpen)}
          >
            <div className={styles.frame8}>
              <div className={styles.textWrapper9}>Ends on*</div>
            </div>
            <div className={styles.inputField}>
              <div className={styles.textWrapper10}>
                {endDate.format("MMM D, YYYY")}
              </div>
              <img
                className={styles.img}
                src="/images/calendar.svg"
                alt="Input field icon"
              />
            </div>
          </div>
          {isEndsOnDatePickerOpen && (
            <DatePickerEndsOn
              isEndsOnDatePickerOpen={isEndsOnDatePickerOpen}
              setIsEndsOnDatePickerOpen={setIsEndsOnDatePickerOpen}
              endDate={endDate}
              setSelectedDate={setSelectedDate}
              handleEndDateChange={handleEndDateChange}
              frequency={frequency}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RecurringSession;
