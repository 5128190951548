import React, { useEffect, useRef, useState } from "react";
import DownArrowIcon from "../../DownArrowIcon/DownArrowIcon";
import TutorDropdown from "./TutorDropdown";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const tutors = [
  {
    _id: "kji9uvb29v3kj3c2",
    firstName: "FirstName1",
    lastName: "LastName1",
    email: "user1@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "f6q9n2nv8bh3e1g1",
    firstName: "FirstName2",
    lastName: "LastName2",
    email: "user2@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "bv6k2vm0bn2r7s9s",
    firstName: "FirstName3",
    lastName: "LastName3",
    email: "user3@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "lk12nb3v8jc2m9n4",
    firstName: "FirstName4",
    lastName: "LastName4",
    email: "user4@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "9b2m4vn8cj2e7s1k",
    firstName: "FirstName5",
    lastName: "LastName5",
    email: "user5@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "s8h9n1v3b7f2g5j2",
    firstName: "FirstName6",
    lastName: "LastName6",
    email: "user6@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "m9v0c4bn3f2r8l2j",
    firstName: "FirstName7",
    lastName: "LastName7",
    email: "user7@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "t8h9n1b3c6f2e9v1",
    firstName: "FirstName8",
    lastName: "LastName8",
    email: "user8@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "f7v8m2n1b3c4l9k2",
    firstName: "FirstName9",
    lastName: "LastName9",
    email: "user9@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "n3b5m7v8c1j2h9l3",
    firstName: "FirstName10",
    lastName: "LastName10",
    email: "user10@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "k8j9n1b2c4m7f6g3",
    firstName: "FirstName11",
    lastName: "LastName11",
    email: "user11@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "v3c5n7m9b2h1k4l6",
    firstName: "FirstName12",
    lastName: "LastName12",
    email: "user12@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "m7v8b9n2c4f1h3l5",
    firstName: "FirstName13",
    lastName: "LastName13",
    email: "user13@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "c5n3v1b2m7h9j4l8",
    firstName: "FirstName14",
    lastName: "LastName14",
    email: "user14@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
  {
    _id: "h8n7v2b1m3f9k4l6",
    firstName: "FirstName15",
    lastName: "LastName15",
    email: "user15@example.com",
    profile: "",
    bgColor: "#43cefe",
  },
];

const SelectTutor = ({tutorList: tutors, selectedTutor, setSelectedTutor,isAllowedToChangeTutor,customTutorList, user,selectedStudents,operationMode, requiredEventData}) => {
    const [isDropDownOpen, setIsDropdownOpen] = useState(false);
    const tutorSelectorRef = useRef();
    useOutsideAlerter(tutorSelectorRef, () => setIsDropdownOpen(false));

    console.log(customTutorList)
	console.log("SST", requiredEventData)
  
    const getUserName = () => {
      const tutor =  tutors && tutors.find((tutor) => tutor._id === selectedTutor);
	  let tutorName = null
      tutorName =  tutor ? `${tutor.firstName} ${tutor.lastName}` : "Select Tutor*";
		if(requiredEventData && requiredEventData.tutorName && tutorName === "Select Tutor*"){
			tutorName =  requiredEventData.tutorName
		}

		return tutorName
    };


    useEffect(()=>{
      if(isAllowedToChangeTutor === true && operationMode && ["create"].includes(operationMode) &&  ["admin", "tutor"].includes(user?.role)){
        const findAdminTutor = tutors.find((tutor)=> tutor._id === user.id);
        if(findAdminTutor) setSelectedTutor( findAdminTutor._id)
      
      } 
  
    },[user,isAllowedToChangeTutor])


    console.log(isAllowedToChangeTutor, tutors, operationMode, user.role)


  
    return (
      <main ref={tutorSelectorRef} className="w-full h-full relative" style={{pointerEvents  : !isAllowedToChangeTutor ?  "none" : ""}}>
        <div className="relative flex justify-between items-center rounded-[6px] bg-[#F6F9FA] cursor-pointer">
          <section
            className="flex-1 w-full flex py-[8px] px-[12px] items-center justify-between gap-[2px]"
            onClick={() => setIsDropdownOpen(!isDropDownOpen)}
          >
            <p className= {`w-full flex-1 text-[14px] leading-[21px] ${selectedTutor ? " text-[#25335A]" : "text-secondary-60"}`}>
              {getUserName()}
            </p>
            <img src="/images/down-arrow.svg" alt="" className="w-[12px] h-[12px] " style={{visibility : !isAllowedToChangeTutor ? "hidden" : "visible" }}/>
          </section>
  
          {isDropDownOpen && (
            <TutorDropdown
              setIsDropdownOpen = {setIsDropdownOpen}
              selectedStudents ={selectedStudents}
              userRole ={user?.role}
              tutors={ user && user?.role != "student" && user?.role !="parent" ? tutors : customTutorList}
              selectedTutor={selectedTutor}
              setSelectedTutor={setSelectedTutor}
            />
          )}
        </div>
      </main>
    );
  };
  
  export default SelectTutor;
