import axios from "axios";
import { getAuthHeader } from "../app/constants/constants";
import { transformEvents } from "./eventUtils";

async function scheduleConsultation(data) {
  console.log("consult data", data);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/events/consultation`,
      {
        consultationData: data,
      },
      { headers: getAuthHeader() }
    );
    console.log(res);
    return res;
  } catch (error) {
    console.error(`Error scheduling consultation:`, error);
    throw error;
  }
}

async function scheduleInternalMeeting(data) {
  console.log("internal data", data);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/events/internalMeeting`,
      {
        InternalMeetingData: data,
      },
      { headers: getAuthHeader() }
    );
    console.log(res);
    return res;
  } catch (error) {
    console.error(`Error scheduling internal meeting:`, error); 
    throw error;
  }
}


async function updateConsultation(
  consultationId,
  data,
  updateAllFuture = false
) {
  try {
    const payload = {
      updateData: data,
      updateAllFuture: updateAllFuture,
    };

    const res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/events/consultation/${consultationId}`,
      payload,
      { headers: getAuthHeader() }
    );

    console.log(res);
    return res;
  } catch (error) {
    console.error(`Error updating consultation:`, error);
    throw error;
  }
}

async function updateInternalMeeting(
  internalMeetingId,
  data,
  updateAllFuture = false
) {
  try {
    const payload = {
      updateData: data,
      updateAllFuture: updateAllFuture,
    };

    const res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/events/internalMeeting/${internalMeetingId}`,
      payload,
      { headers: getAuthHeader() }
    );

    console.log(res);
    return res;
  } catch (error) {
    console.error(`Error updating internal meeting:`, error);
    throw error;
  }
}
async function deleteConsultation(consultationId, deleteAllFuture = false) {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}api/events/consultation/${consultationId}`,
      {
        data: { deleteAllFuture },
        headers: getAuthHeader(),
      }
    );
    console.log(res);
    return res.data;
  } catch (error) {
    console.error(`Error deleting consultation:`, error);
    throw error;
  }
}

async function deleteInternalMeeting(internalMeetingId, deleteAllFuture = false) {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}api/events/internalMeeting/${internalMeetingId}`,
      {
        data: { deleteAllFuture },
        headers: getAuthHeader(),
      }
    );
    console.log(res);
    return res.data;
  } catch (error) {
    console.error(`Error deleting internal meeting:`, error);
    throw error;
  }
}


async function getConsultationsByInitiator(initiatorId) {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/events/consultation/initiator/${initiatorId}`,
      { headers: getAuthHeader() }
    );
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(`Error fetching consultations for initiator:`, error);
    throw error;
  }
}

async function getConsultationsByAttendee(attendeeId) {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/events/consultation/attendee/${attendeeId}`,
      { headers: getAuthHeader() }
    );
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(`Error fetching consultations for attendee:`, error);
    throw error;
  }
}

async function getConsultationDetails(consultationId) {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/events/consultation/${consultationId}`,
      { headers: getAuthHeader() }
    );
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(`Error fetching consultation details:`, error);
    throw error;
  }
}

async function updateUserStatus(consultationId, userId, status) {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BASE_URL}api/events/consultation/status`,
      {
        consultationId,
        userId,
        status,
      },
      { headers: getAuthHeader() }
    );
    console.log("Response:", res);
    return res.data;
  } catch (error) {
    console.error("Error updating consultation:", error);
    throw error;
  }
}

async function scheduleSession(sessionData) {
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}api/events/session`,
      data: { ...sessionData },
      headers: getAuthHeader(),
    });
    return res.data;
  } catch (err) {
    console.error("Session scheduling error", err);
    throw err;
  }
}

async function updateSession(fields, targetSessionId, updateAllFutureSessions) {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}api/events/session`,
      data: {
        targetSessionId,
        fields,
        updateAllFutureSessions,
      },
      headers: getAuthHeader(),
    });
    return res.data;
  } catch (err) {
    console.error("Session updating error", err);
    throw err;
  }
}

async function deleteSessions(targetSessionId, deleteFutureSessions) {
  try {
    const res = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_BASE_URL}api/events/session/${targetSessionId}/${deleteFutureSessions}`,
      headers: getAuthHeader(),
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
}

async function getAllEvents(requiredUserIds, user) {
  try {
    let url = `${process.env.REACT_APP_BASE_URL}api/events`;
    if (requiredUserIds && requiredUserIds.length > 0) {
      let searchQueryParts = requiredUserIds.map(
        (userId) => `userIds[]=${userId}`
      );
      url += `?${searchQueryParts.join("&")}`;
    }

    const res = await axios({
      method: "GET",
      url: url,
      headers: getAuthHeader(),
    });

    return transformEvents(res.data.data.allEvents, user);
  } catch (err) {
    console.error("Error while fetching all events.", err);
    throw err;
  }
}

async function getAvailableUnavailableScheduleMapper(data) {
  try {
    const queryParams = new URLSearchParams(data).toString();
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}api/events/scheduleAvailableUnavailable?${queryParams}`,
      headers: getAuthHeader(),
    });

    return res.data;
  } catch (err) {
    console.error("Error while fetching data.", err);
    throw err;
  }
}


async function createEventRequest(fields, eventId = null) {
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}api/events/requests`,
      data: {
        fields: fields,
      },
      headers: getAuthHeader(),
    });
    return res.data;
  } catch (err) {
    console.error("Error while creating an event request.", err);
    throw err;
  }
}

async function deleteEventRequest(eventRequestId) {
  try {
    const res = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_BASE_URL}api/events/requests/${eventRequestId}`,
      headers: getAuthHeader(),
    });
    return res;
  } catch (err) {
    console.error("Error while deleting an event request.", err);
    throw err;
  }
}

async function updateEventRequest(eventRequestId, fields) {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}api/events/requests`,
      data: {
        eventRequestId,
        fields,
      },
      headers: getAuthHeader(),
    });
    return res.data;
  } catch (err) {
    console.error("Error while updating and event request", err);
    throw err;
  }
}

async function fetchAllEventRequests() {
  try {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}api/events/requests`,
      headers: getAuthHeader(),
    });
    return res.data.data.eventRequests;
  } catch (err) {
    console.error("Error while fetching events", err);
    throw err;
  }
}

async function createSessionRating({ tutorId, sessionId, rating }) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/feedback`,
      {
        tutorId: tutorId,
        sessionId: sessionId,
        rating: rating,
      },
      { headers: getAuthHeader() }
    );
    console.log(res);
    return res;
  } catch (error) {
    console.error(`Error in rating session:`, error);
    throw error;
  }
}

async function updateSessionRating({ feedbackID, rating }) {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/feedback/update/${feedbackID}`,
      {
        rating,
        viewed: true,
      },
      {
        headers: getAuthHeader(),
      }
    );

    console.log("Updated feedback response:", res.data);
    return res.data;
  } catch (error) {
    console.error(`Error updating feedback:`, error);
    throw error;
  }
}

async function fetchSessionRating(sessionId) {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/feedback/${sessionId}/student`,
      {
        headers: getAuthHeader(),
      }
    );

    return res;
  } catch (error) {
    console.error(`Error fetching session rating:`, error);
    throw error;
  }
}

async function updateAttendeeStatus(targetEventId, targetUserId, targetUserStatus) {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}api/events/status`,
      headers: getAuthHeader(),
      data: {
        targetUserId: targetUserId,
        targetEventId: targetEventId,
        fields: {
          targetStatus: targetUserStatus,
        }
      }
    })
    return res
  } catch (err) {
    console.error("Error while updating individual attendee status")
    throw err
  }
}


async function createEventRequestForBookedMeeting(data) {

  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}api/events/booked-meeting-request`,
      data: data
    })
    return res
  } catch (err) {
    console.error("Error occured while scheduling a event request for booked ", err)
    throw err
  }

}


async function createBookedMeeting(data) {

  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}api/events/booked-meeting`,
      data: {
        fields: data
      },
      headers: getAuthHeader()
    })
    return res.data.data

  } catch (err) {
    console.error("Error occured while scheduling an event.")
  }

}

async function updateBookedMeeting(targetEventId, fields) {

  try {
    const res = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}api/events/booked-meeting`,
      data: {
        targetId: targetEventId,
        fields: fields
      },
      headers: getAuthHeader()
    })
    return res.data.data

  } catch (err) {
    console.error("Error occured while scheduling an event.")
  }

}

async function deleteBookedMeeting(targetEventId) {

  try {
    const res = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_BASE_URL}api/events/booked-meeting/${targetEventId}`,
      headers: getAuthHeader()
    })
    return res.data.data

  } catch (err) {
    console.error("Error occured while deleting an event.")
  }

}

async function scheduleOfficeWork(data) {
  console.log("Off Work Data", data);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/events/officeHours`,
      {
        data,
      },
      { headers: getAuthHeader() }
    );
    console.log(res);
    return res;
  } catch (error) {
    console.error(`Error scheduling office work:`, error);
    throw error;
  }
}

async function updateOfficeWork(targetEventId, data) {
  console.log(">>>>>>Officr", data)

  try {
    const res = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_BASE_URL}api/events/officeHours/${targetEventId}`,
      data,
      headers: getAuthHeader()
    })
    return res.data.data

  } catch (err) {
    console.error("Error occured while scheduling an event.")
  }

}

async function deleteOfficeHours(targetEventId) {

  try {
    const res = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_BASE_URL}api/events/officeHours/${targetEventId}`,
      headers: getAuthHeader()
    })
    return res.data.data

  } catch (err) {
    console.error("Error occured while deleting an event.")
  }

}

export {
  scheduleConsultation,
  updateConsultation,
  deleteConsultation,
  getConsultationsByInitiator,
  getConsultationsByAttendee,
  getConsultationDetails,
  updateUserStatus,
  scheduleSession,
  getAllEvents,
  updateSession,
  getAvailableUnavailableScheduleMapper,
  deleteSessions,
  createEventRequest,
  deleteEventRequest,
  updateEventRequest,
  fetchAllEventRequests,
  createSessionRating,
  updateSessionRating,
  fetchSessionRating,
  updateAttendeeStatus,
  createEventRequestForBookedMeeting,
  createBookedMeeting,
  updateBookedMeeting,
  deleteBookedMeeting,
  scheduleOfficeWork,
  updateOfficeWork,
  deleteOfficeHours,
  scheduleInternalMeeting,
  updateInternalMeeting,
  deleteInternalMeeting
};
