import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import { useEffect, useMemo, useState } from 'react'
import { getTooltipStyleClass } from '../../utils/commonlyUsedTooltipStyles'
import { getFormattedDateV2 } from '../../utils/dateUtils'
import frozenEventStatus from '../../utils/eventStatus'
import frozenEventTypes from '../../utils/eventTypes'
import CalIconVariant2 from '../CalendarSideIcons/CalIconVariant2'
import ClockIcon from '../CalendarSideIcons/ClockIcon'
import AppointmentIndicatorBg from '../EventIndicators/AppointmentIndicatorBg'
import ConsultationIndicatorBg from '../EventIndicators/ConsultationIndicatorBg'
import InternalMeetingBg from '../EventIndicators/InternalMeetingIndicatorBg'
import OfficeWorkIndicatorBg from '../EventIndicators/OfficeWorkIndicatorBg'
import SessionIndicatorBg from '../EventIndicators/SessionIndicattorBg'
import EventStatusTag from '../EventStatusTag/EventStatusTag'
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon'
import Tooltip from '../Tooltip/Tooltip'
import { Tooltip as MUITooltip } from "@mui/material";
import styles from './EventRequestsCard.module.css'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

//Utils functions ==> Extract out later
function buttonConstructor(eventDetails, user, requestDetails, onClick) {
	const buttonProps = {
		content: "",
		className: "",
		onClick: onClick
	}

	if (user.role === "parent" || user.role === "student") {
		buttonProps.content = "Edit"
		buttonProps.className = styles.acceptStyle
	}

	if([frozenEventTypes.SESSION.eventType,frozenEventTypes.CONSULTATION.eventType, frozenEventTypes.INTMEETING.eventType.toLowerCase(),frozenEventTypes.OFFCHOURS.eventType.toLowerCase()].includes(eventDetails.__t ? eventDetails.__t.toLowerCase() : "session" ) && (user.role === "admin" || user.role === "tutor")) {
		if(requestDetails.requestStatus === "cancel") {
			buttonProps.content = "Cancel Event"
			buttonProps.className = styles.cancelStyle
		} else if (requestDetails.requestStatus === "reschedule") {
			buttonProps.content = "Reschedule"
			buttonProps.className = styles.acceptStyle
		} else {
			buttonProps.content = "Accept"
			buttonProps.className = styles.acceptStyle
		}
	}

	return <button {...buttonProps}>{buttonProps.content}</button>
}

function getDateTimeWording(requestStatus, requestedDate, eventDetails) {
	const wordMapping = {
		[frozenEventTypes.OFFCWORK.eventType]: "Added",
		[frozenEventTypes.EXTMEETING.eventType]: "Booked",
		[frozenEventTypes.INTMEETING.eventType]: "Scheduled",
		[frozenEventTypes.SESSION.eventType]: "Booked",
		[frozenEventTypes.CONSULTATION.eventType]: "Scheduled"
	}

	const statusMapping = {
		"cancel": "Requested",
		"reschedule": "Requested"
	}


	const tempArr = []
	tempArr.push(
		statusMapping[requestStatus] ?? wordMapping[eventDetails.__t?.toLowerCase() ? eventDetails.__t?.toLowerCase() : "session"]
	)
	tempArr.push("on")
	tempArr.push(getFormattedDateV2((new Date(requestedDate)).toISOString(), "dd mm, yyyy"))

	return tempArr.join(" ")
}

//Helper components
//Extract this one out later
function ProfileDetailsContainer({ user, requestor }) {
	return (
		<div className={styles.profileDetailsContainer}>
			{
				requestor.photo ?
					<img src={user.awsLink + requestor.photo} />
					:
					<div className={styles.placeholder}>{requestor.firstName[0].toUpperCase()}{requestor.lastName[0].toUpperCase()}</div>
			}
			<h4>{[requestor.firstName, requestor.lastName].join(" ")}</h4>
			<span className={styles.email}>{requestor.email}</span>
		</div>
	)
}


function TimeIndicator({ startTime, endTime, timeZone }) {

	const [duration, setDuration] = useState()

	useEffect(() => {
		if (startTime && endTime) {
			console.log("STE", startTime)
			console.log("STEE", endTime)
			const templateTimeStr = (new Date()).toLocaleString("en-US")
			const [datePart] = templateTimeStr.split(",")
			const startTimeArr = [datePart, " " + startTime.time + " " + startTime.timeType]
			const endTimeArr = [datePart, " " + endTime.time + " " + endTime.timeType]
			const startDateMS = (new Date(startTimeArr.join(","))).getTime()
			const endDateMS = (new Date(endTimeArr.join(","))).getTime()
			const delta = endDateMS - startDateMS
			const hours = Math.floor((delta) / (1000 * 3600))
			const minutes = ((delta) % (1000 * 3600) / (1000 * 60))

			const hourStr = hours > 0 ? `${hours}h` : ""
			const minStr = minutes > 0 ? `${minutes}m` : ""

			setDuration([hourStr, minStr].join(' '))

		}
	}, [startTime, endTime])

	return (
		<div className={styles.timeIndicatorContainer}>
			<ClockIcon />
			<span className={styles.duration}>{duration}</span>
		</div>
	)
}




function RequestDetailsContainer({ requestDetails, eventDetails, requestType, requestor, user, additionalInfo }) {

	const { requestedOn, eventType } = requestDetails


	const requiredDateTimeDetails = useMemo(() => {

		if (requestType !== "schedule") {
			return ({
				date: eventDetails.date,
				time: eventDetails.time
			})
		} else {
			return ({
				date: requestDetails.date,
				time: requestDetails.time,
			})
		}
	}, [eventDetails, requestDetails])


	const requiredTooltipContent = useMemo(() => {
		const internalUsers = ["admin", "tutor"]
		const externalUsers = ["student", "parent"]
		let noteToDisplay = null
		if (internalUsers.includes(user?.role) && internalUsers.includes(requestor?.role)) {
			noteToDisplay = eventDetails?.agendaNotes?.note ? eventDetails?.agendaNotes?.note : eventDetails?.internalNotes?.note
		} else if (externalUsers.includes(requestor?.role)) {
			noteToDisplay = additionalInfo
		}

		return (

			<div className="flex flex-col items-start justify-start gap-[6px] font-[lexend] p-[12px] w-[240px]">
				<h3 className="font-[400] leading-[21px] text-[14px] text-secondary">Description</h3>
				<p className="font-[400] leading-[15px] text-[10px] text-secondary-70">{noteToDisplay}</p>
			</div>

		)


	}, [user, requestor, eventDetails, requestDetails])


	const eventTypeIndicatorIconMapping = {
    [frozenEventTypes.OFFCWORK.eventType.toLowerCase()]: <OfficeWorkIndicatorBg />,
		[frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]: <OfficeWorkIndicatorBg />,
		[frozenEventTypes.EXTMEETING.eventType ]: <AppointmentIndicatorBg />,
		[frozenEventTypes.EXTMEETING.eventType.toLowerCase() ]: <AppointmentIndicatorBg />,
		[frozenEventTypes.INTMEETING.eventType.toLowerCase()] : <InternalMeetingBg /> ,
		[frozenEventTypes.SESSION.eventType] : <SessionIndicatorBg />,
		[frozenEventTypes.CONSULTATION.eventType] : <ConsultationIndicatorBg />
	}

	const statusEventTypes  = [
		frozenEventTypes.OFFCWORK.eventType.toLowerCase(),
		frozenEventTypes.INTMEETING.eventType.toLowerCase(),
		frozenEventTypes.EXTMEETING.eventType
	]



	return (
		<div className={styles.requestDetailsContainer}>
			<div className={styles.containerOne}>
				{
					statusEventTypes.includes(eventDetails.__t ? eventDetails.__t.toLowerCase() : "session") ?
						eventTypeIndicatorIconMapping[eventDetails.__t ? eventDetails.__t.toLowerCase() : "session"] :
						["cancel", "reschedule"].includes(requestType) ? <EventStatusTag status={requestType} showDisplayValue={"secondary"} />
							: eventTypeIndicatorIconMapping[requestType === "schedule" ? requestDetails.eventType : eventDetails.__t ? eventDetails.__t.toLowerCase() : "session"]
				}
			</div>
			<div className={styles.containerTwo}>
				<span style={{ paddingRight: "8px" }}>&bull;</span>{getDateTimeWording(requestType, requestedOn, eventDetails ?? { __t: eventType, status: requestType })}
			</div>
			<div className={styles.containerThree}>
				<div style={{ color: "#7C859C" }}>
					<CalIconVariant2 currentColor={true} />
				</div>
				<div className={styles.dateTimeDetails}>
					{`${getFormattedDateV2((new Date(requiredDateTimeDetails.date).toISOString()), "dd mm, yyyy")}, ${requiredDateTimeDetails.time.start.time} ${requiredDateTimeDetails.time.start.timeType} - ${requiredDateTimeDetails.time.end.time} ${requiredDateTimeDetails.time.end.timeType}`}
				</div>
				<MUITooltip
					title={requiredTooltipContent}
					slotProps={{
						tooltip: {
							sx: {
								backgroundColor: "white",
								color: "#636D89",
								fontFamily: "Lexend",
								fontWeight: "400",
								fontSize: "10px",
								padding: "0px",
								boxShadow: "0px 4.05px 26.98px 0px #000AFF29",
								borderRadius: "12px",
								"& .MuiTooltip-arrow": {
									color: "white"
								}
							},
						}
					}}
					arrow
				>
					<button>
						<MoreInfoIcon />
					</button>
				</MUITooltip>
			</div>
		</div>
	)

}


function EventRequestsCard(props) {

	const { eventDetails, user, requestDetails, requestor, requestType, additionalInfo, handleDeleteClick, handleDeclineClick, handleActionClick, agendaNotes } = props

	const requiredDateTime = useMemo(() => {

		if (requestType === "schedule") {
			return ({
				time: requestDetails.time,
				date: requestDetails.date
			})
		} else {
			return ({
				time: eventDetails.time,
				date: eventDetails.date
			})

		}
	}, [requestDetails, eventDetails, requestType])

	return (
		<div className={styles.container}>
			<div className={styles.sectionOne}>
				<ProfileDetailsContainer user={user} requestor={requestor} />
				<TimeIndicator startTime={requiredDateTime?.time.start} endTime={requiredDateTime?.time.end}
					timezone={user.userDefaults?.timeZone ? user.userDefault?.timeZone : dayjs.tz.guess()} />
			</div>
			<div className={styles.sectionTwo}>
				<RequestDetailsContainer
					requestDetails={requestDetails}
					eventDetails={eventDetails}
					requestType={requestType}
					requestor={requestor}
					user={user}
					additionalInfo={additionalInfo}
				/>
			</div>
			<div className={styles.sectionThree}>
				{buttonConstructor(eventDetails, user, { requestStatus: requestType }, handleActionClick)}
				{
					(user?.role === "admin" || user?.role === "tutor") &&
					<button className={styles.declineStyle} onClick={handleDeclineClick}>
						Decline
					</button>
				}
				{
					(user?.role === "student" || user?.role === "parent") &&
					<button className={styles.cancelStyle} onClick={handleDeleteClick}>
						Delete
					</button>
				}
			</div>
		</div>
	)

}


export default EventRequestsCard
