export default function AvailabilityIcons (props) {

	return(
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_35331_19373)">
				<path d="M11.875 1.75H11.25V1.125C11.25 0.779813 10.9702 0.5 10.625 0.5C10.2798 0.5 10 0.779813 10 1.125V1.75H5V1.125C5 0.779813 4.72019 0.5 4.375 0.5C4.02981 0.5 3.75 0.779813 3.75 1.125V1.75H3.125C1.40188 1.75 0 3.15188 0 4.875V12.375C0 14.0981 1.40188 15.5 3.125 15.5H6.14697C6.49213 15.5 6.77197 15.2202 6.77197 14.875C6.77197 14.5298 6.49213 14.25 6.14697 14.25H3.125C2.09113 14.25 1.25 13.4089 1.25 12.375V6.75H14.375C14.7202 6.75 15 6.47019 15 6.125V4.875C15 3.15188 13.5982 1.75 11.875 1.75ZM13.75 5.5H1.25V4.875C1.25 3.84113 2.09113 3 3.125 3H3.75V3.625C3.75 3.97019 4.02981 4.25 4.375 4.25C4.72019 4.25 5 3.97019 5 3.625V3H10V3.625C10 3.97019 10.2798 4.25 10.625 4.25C10.9702 4.25 11.25 3.97019 11.25 3.625V3H11.875C12.9089 3 13.75 3.84113 13.75 4.875V5.5Z" fill="currentColor"/>
				<path d="M3.125 9.25C3.47018 9.25 3.75 8.97018 3.75 8.625C3.75 8.27982 3.47018 8 3.125 8C2.77982 8 2.5 8.27982 2.5 8.625C2.5 8.97018 2.77982 9.25 3.125 9.25Z" fill="currentColor"/>
				<path d="M5.625 9.25C5.97018 9.25 6.25 8.97018 6.25 8.625C6.25 8.27982 5.97018 8 5.625 8C5.27982 8 5 8.27982 5 8.625C5 8.97018 5.27982 9.25 5.625 9.25Z" fill="currentColor"/>
				<path d="M3.125 11.75C3.47018 11.75 3.75 11.4702 3.75 11.125C3.75 10.7798 3.47018 10.5 3.125 10.5C2.77982 10.5 2.5 10.7798 2.5 11.125C2.5 11.4702 2.77982 11.75 3.125 11.75Z" fill="currentColor"/>
				<path d="M5.625 11.75C5.97018 11.75 6.25 11.4702 6.25 11.125C6.25 10.7798 5.97018 10.5 5.625 10.5C5.27982 10.5 5 10.7798 5 11.125C5 11.4702 5.27982 11.75 5.625 11.75Z" fill="currentColor"/>
				<path d="M11.625 7.75C9.21263 7.75 7.25 9.71263 7.25 12.125C7.25 14.5374 9.21263 16.5 11.625 16.5C14.0374 16.5 16 14.5374 16 12.125C16 9.71263 14.0374 7.75 11.625 7.75ZM11.625 15.25C9.90184 15.25 8.5 13.8481 8.5 12.125C8.5 10.4019 9.90184 9 11.625 9C13.3482 9 14.75 10.4019 14.75 12.125C14.75 13.8481 13.3482 15.25 11.625 15.25Z" fill="currentColor"/>
				<path d="M12.875 11.5H12.25V10.875C12.25 10.5298 11.9702 10.25 11.625 10.25C11.2798 10.25 11 10.5298 11 10.875V12.125C11 12.4702 11.2798 12.75 11.625 12.75H12.875C13.2202 12.75 13.5 12.4702 13.5 12.125C13.5 11.7798 13.2202 11.5 12.875 11.5Z" fill="currentColor"/>
			</g>
			<defs>
				<clipPath id="clip0_35331_19373">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>

	)

}
