import { useSelector } from 'react-redux'
import styles from './TutorStatus.module.css'
import LockIcon from '../../../../../assets/Settings/LockIcon'
import { useEffect, useState } from 'react'
import DropdownV2 from '../../../../DropdownV2/DropdownV2'
import { useUpdateOrgDataMutation } from '../../../../../app/services/admin'
import { fetchAndSetOrgDetails } from '../../../../../utils/orgService'
import HorizontalDivider from '../../../../HorizontalDivider/HorizontalDivider'
import PillContainer from '../../../../PillContainer/PillContainer'
import EditIcon from '../../../../EditIcon/EditIcon'
import MoreInfoIcon from '../../../../MoreInfoIcon/MoreInfoIcon'
import ButtonV2 from '../../../../ButtonV2/ButtonV2'
import AddIcon from '@mui/icons-material/Add';
import StatusEditPopUp from '../StatusEditPopup/StatusEditPopup'
import LockedStateOverlay from '../LockedStateOverlay/LockedStateOverlay'
import Tooltip from '../../../../Tooltip/Tooltip'
import { getTooltipStyleClass } from '../../../../../utils/commonlyUsedTooltipStyles'
import TooltipIcon from '../../../../../assets/icons/SettingsIcon/TooltipIcon'

const TutorStatus = () => {

    const { subscriptionV2 } = useSelector((state) => state.subscription)
    const [customTutorStatus, setCustomTutorStatus] = useState(true)
    const { organization } = useSelector((state) => state.organization)
    const { defaultLeadStatus, defaultTutorStatus } = useSelector((state) => state.organization.organization.settings)
    const { tutorStatus } = useSelector((state) => state.organization.organization.settings)
    const [updateOrgRequest,] = useUpdateOrgDataMutation()
    const [tutorStatusEditTrigger, setTutorStatusEditTrigger] = useState(false)

    useEffect(() => {
        if (subscriptionV2?.subscription) {
            setCustomTutorStatus(subscriptionV2?.subscription?.settings?.customTutorStatus)
        }
    }, [subscriptionV2])

    const editDefaultTutorStatus = (item) => {
        updateOrgRequest({
            op: "update",
            orgId: organization._id,
            fields: {
                "settings.defaultTutorStatus": item.displayTitle
            }
        })
            .then((res) => {
                if (!res.error) {
                    fetchAndSetOrgDetails(organization._id)
                }
            })
    }


    return (
        <div className={styles.container}>
            <div className={styles.row1}>
                {
                    customTutorStatus?.isEnabled === false ?
                        <Tooltip
                            anchorComponent={<LockIcon width={"16px"} height={"16px"} />}
                            tooltipContent={"Upgrade your plan to access this feature."}
                            tooltipStyleClass={"w-[180px] max-w-[320px] bg-white rounded-[12px] shadow-[0px_4px_24px_0px_#11167D26] p-3 flex flex-col items-start gap-[6px] text-secondary-80"}
                        />
                        : <></>
                }
                <h3>Tutor Statuses</h3>
                <Tooltip tooltipStyles={getTooltipStyleClass({width : "220px"})} tooltipContent={`Tutor Statuses can be used to manage and keep track of your tutors and their availability. You can create your own tutor tagging system by adding various tags below. For example, "Applied", "Interviewd", "Under Training", "Working" and "Former" can be some of the tags that you use to manage your tutors. Note that these would reflect on the CRM as well as on the tutor profiles. If you delete a tag, make sure it is not attached to any user.`}><TooltipIcon /></Tooltip>
                
                {
                    customTutorStatus?.isEnabled === false ? <></> : <button className='pl-[10px]' onClick={(e) => setTutorStatusEditTrigger(true)}><EditIcon /></button>
                }
            </div>
            <div className={styles.row2col1}>
                <p>Select the default status to be assigned to new tutors who join.</p>
            </div>
            <div className={styles.row2col2}>
                <DropdownV2
                    defaultValue={defaultTutorStatus}
                    dropdownMenuItems={tutorStatus && [{ displayTitle: "None" }, ...tutorStatus.map((val) => ({ displayTitle: val }))]}
                    onDropdownItemClick={editDefaultTutorStatus}
                />
            </div>
            <div className={styles.horizontalDivider}>
                <HorizontalDivider />
            </div>
            <div className={styles.row3}>
                <div className={styles.col1}>
                    <PillContainer items={tutorStatus?.map((val) => ({ displayTitle: val }))}>
                        <ButtonV2 className={styles.addNewButtonStyle} onClick={(e) => setTutorStatusEditTrigger(true)}><AddIcon style={{ height: "16px", width: "16px" }} /> Add New</ButtonV2>
                    </PillContainer>
                    {
                        subscriptionV2?.subscription?.settings?.customTutorStatus.isEnabled === false ?
                            <LockedStateOverlay />
                            : <></>
                    }
                </div>
            </div>
            {
                tutorStatusEditTrigger ? <StatusEditPopUp status={tutorStatus} toEdit={"tutor-status"} setTrigger={setTutorStatusEditTrigger} trigger={tutorStatusEditTrigger} /> : <></>
            }
        </div>
    )
}

export default TutorStatus