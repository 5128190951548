import React from 'react'

const LockIcon = ({ width, height }) => {
    return (
        <svg width={width ? width : "20"} height={height ? height : "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.2515 8.28361H15.4458H15.4456V5.44572C15.4455 2.44299 13.0026 0 9.9998 0C6.99715 0 4.55416 2.44299 4.55416 5.44576V8.28365H3.74829C3.11125 8.28365 2.59473 8.80009 2.59473 9.43717V10.1389V18.1447V18.8464C2.59473 19.4836 3.11121 20 3.74829 20H9.99984H16.2515C16.8885 20 17.4049 19.4836 17.4049 18.8464V18.1447V10.1389V9.43717C17.4049 8.80005 16.8885 8.28361 16.2515 8.28361ZM11.2277 16.5466H8.77205L9.21155 14.4524C8.80054 14.1908 8.526 13.7341 8.526 13.2108C8.526 12.3969 9.18591 11.7371 9.99984 11.7371C10.8138 11.7371 11.4736 12.3969 11.4736 13.2108C11.4736 13.7341 11.1991 14.1908 10.7882 14.4523L11.2277 16.5466ZM13.5024 8.28361H9.99984H6.49744H6.49723V5.44572C6.49723 3.5142 8.0684 1.94307 9.99984 1.94307C11.9314 1.94307 13.5024 3.51424 13.5024 5.44572V8.28361Z" fill="#0671E0" />
        </svg>

    )
}

export default LockIcon