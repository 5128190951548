/** @jsxImportSource @emotion/react */
import logoV2 from "./logoV2.svg";
import styles from "./AuthTopbar.module.css";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { isWhiteLabellingComponentAllowed } from "../../utils/restrictionsService";
import { css } from "@emotion/react";

const AuthTopbar = (props) => {
  const { signupStage, primaryCTAStyles } = props;
  const { OrganizationData, subscription } = props;
  const navigate = useNavigate();
  const [showMobileBackButton, setShowMobileBackButton] = useState(false);
  const [navigationLocation, setNavigationLocation] = useState("/");
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    switch (window.location.pathname) {
      case "/signup/student-parent-info":
        setNavigationLocation("/signup");
        setShowMobileBackButton(true);
        break;
      case "/signup/org":
        setNavigationLocation("/signup");
        setShowMobileBackButton(true);
        setShowLogo(true);
        break;
      case "/signup/verify-email":
        setNavigationLocation("/signup/org");
        setShowMobileBackButton(true);
        break;
      case "/signup/set-new-password":
        setNavigationLocation("/signup/org");
        setShowMobileBackButton(true);
        setShowLogo(false);
        break;
      case "/signup/expired-otp":
        setNavigationLocation("/signup");
        setShowMobileBackButton(true);
        setShowLogo(false);
        break;
      case "/signin/forgot-password":
        setNavigationLocation("/signup");
        setShowMobileBackButton(true);
        setShowLogo(false);
        break;
      case "/signin/reset-success":
        setNavigationLocation("/signin");
        setShowMobileBackButton(true);
        setShowLogo(false);
        break;
      case "/signin/reset-password":
        setNavigationLocation("/signin");
        setShowMobileBackButton(true);
        setShowLogo(false);
        break;
      default:
        setNavigationLocation("/signup");
        setShowMobileBackButton(false);
        setShowLogo(true);
    }
  }, [window.location.pathname]);

  console.log(OrganizationData);
  return (
    <>
      <div
        className={styles.mobileTopBarContainer}
        style={{ paddingTop: !showLogo ? "8px" : "" }}
      >
        {showLogo ? (
          <Link to="/signup">
            <img
              src={
                subscription && isWhiteLabellingComponentAllowed(subscription,"brandLogo") && OrganizationData?.orgBussinessLogo && (OrganizationData != "none")
                  ? OrganizationData?.orgBussinessLogo
                  : logoV2
              }
              alt="Evallo logo"
              className={"max-h-[40px] object-contain"}
            />
          </Link>
        ) : (
          <></>
        )}

        {showMobileBackButton ? (
          <button
            className={styles.mobileBackButton}
            onClick={() => navigate(navigationLocation)}
          >
            <ArrowBackIcon />
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.topbarContainer}>
        <section className=" flex items-center justify-between gap-[16px]">
          {OrganizationData && (
            <Link to="/signup">
              <img
                src={
                  subscription && isWhiteLabellingComponentAllowed(subscription,"brandLogo") && OrganizationData?.orgBussinessLogo && OrganizationData?.isEnabledOrgBusinessLogo == true
                    ? OrganizationData?.orgBussinessLogo
                    : logoV2
                }
                alt="Evallo logo"
				className={"max-h-[40px] object-contain"}
              />
            </Link>
          )}
          <a
            className="text-[16px] font-normal leading-[24px] text-secondary-80 hover:text-secondary-80 hover:no-underline"
            href={subscription && isWhiteLabellingComponentAllowed(subscription,"website") && OrganizationData?.website && OrganizationData?.isEnabledWebsite == true  ?  (OrganizationData.website.startsWith("http")? OrganizationData.website :  `https://${OrganizationData.website}`) : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {subscription && isWhiteLabellingComponentAllowed(subscription,"website") && OrganizationData?.website && OrganizationData?.isEnabledWebsite == true
              ?  ( OrganizationData.website.startsWith("http")? new URL(OrganizationData.website).hostname : new URL(`https://${OrganizationData.website}`).hostname )   
              : ""}
          </a>
        </section>
        {signupStage === "initial" ? (
          <div>
            Have an existing account?{" "}
            <Link to="signin" className={styles.signInAnchor} {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.bg})}}>
              Sign In
            </Link>
          </div>
        ) : (
          <></>
        )}
        {signupStage === "emailVerification" ? (
          <div>
            Entered incorrect email?{" "}
            <Link className={styles.anchor} {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.text})}}>Edit email</Link>
          </div>
        ) : (
          <></>
        )}
        {signupStage === "infoCollection" ? (
          <div>
            Have queries or need help?{" "}
            <a className={styles.anchor} href="https://evallo.org/support" target="_blank" {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.text})}}>
              Let's talk
            </a>
          </div>
        ) : (
          <></>
        )}
        {signupStage === "signin" || signupStage === "resetPassword" ? (
          <div>
            Don't have an account?
            <Link
              to="/signup"
              className=" ml-2 text-white px-[12px] py-[6px] bg-whiteLabelled  rounded-[4px] hover:text-white hover:no-underline "
			  {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.bg})}}
            >
              Sign up
            </Link>
          </div>
        ) : (
          <></>
        )}
        {signupStage === "enterOTP" ? (
          <div>
            Entered incorrect email?{" "}
            <Link to="/signup/org" className={styles.anchor} {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.text})}}>
              Edit email
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default AuthTopbar;
