import { useEffect, useRef, useState } from 'react'
import useOutsideAlerter from '../../../hooks/useOutsideAlerter'
import DateRangeButton from '../../DateRangeButton/DateRangeButton'
import DateRangeSelector from '../../DateRangeSelector/DateRangeSelector'
import DownloadIcon from '../../DownloadIcon/DownloadIcon'
import SearchFieldV2 from '../../SearchFieldV2/SearchFieldV2'
import styles from './ActionArea.module.css'


function ActionArea({startDate, endDate, setStartDate, setEndDate, role, searchFieldProps, handleDownloadButtonClick}) {

	const [dateSelectorTrigger, setDateSelectorTrigger] = useState(false)
	const isShownSearchForRole = ["parent","admin"].includes(role)
	const parentRefDRS = useRef(null)
	const refDRS = useRef(null)
	
	function handleDateButtonClick () {
		setDateSelectorTrigger(!dateSelectorTrigger)
	}

	useEffect(()=>{
		console.log("FSD",startDate)
		console.log("FSDE",endDate)
	},[startDate, endDate])

	useOutsideAlerter(refDRS,()=>{setDateSelectorTrigger(false)},parentRefDRS)

	return(
		<div className={`${styles.container} ${isShownSearchForRole ? "" : styles.dontShowSearch}`}>
			<div className={styles.col1} style={{display : !isShownSearchForRole ? "none" : ""}}><SearchFieldV2 {...searchFieldProps}/></div>
			<div className={styles.col2}>
				<DateRangeButton {...{startDate, endDate}} onClick={handleDateButtonClick} ref={parentRefDRS}/>
				{
					dateSelectorTrigger ? 
						<DateRangeSelector {...{startDate, endDate, setStartDate, setEndDate}} ref={refDRS}/>
					: <></>
	 			}
			</div>
			<div className={styles.col3}>&nbsp;</div>
			<button className={styles.downloadButton} onClick={()=>{handleDownloadButtonClick()}}>
				<DownloadIcon />
				<span className={styles.content}>Download</span>	
			</button>
		</div>
	)

}


export default ActionArea
