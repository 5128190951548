import dayjs from "dayjs"
import { isEqual } from "lodash"
import { fetchAllEventRequests, updateEventRequest, updateSession } from "../../../utils/eventService"
import { triggerToast } from "../../../utils/toastController"
import { fetchAndSetAllEventRequests } from "./fetchers"
import { getTimeInFormat } from "./utils"


function getTimedelta(reminderString) {
	if(!reminderString || reminderString.trim() === "" || reminderString.trim().toLowerCase() === "none") {
		return 0
	}
	const reminderStringPart = reminderString.trim().split(" ")
	const seconds = reminderStringPart[1].toLowerCase().includes("minutes") ? 60 : 3600
	return parseInt(reminderStringPart[0]) * seconds * 1000
}

async function sessionUpdater(allEventData,updateFutureSessions){
	const {requiredEventData} = allEventData
	const rschfields = ["date", "endDate", "time", "day", "selectedDate", "repeatFrequency"]

	const editedTime = {
		start : getTimeInFormat(allEventData.selectedStartTime), 
		end: getTimeInFormat(allEventData.selectedEndTime)
	}


	const isDateEqual = allEventData.selectedDate.format('DD/MM/YYYY') === dayjs(requiredEventData.date).format('DD/MM/YYYY')
	
	const isTimeEqual = isEqual(requiredEventData.time,editedTime)
	const sendEndDate = (allEventData.isRecurring && requiredEventData.recurring) || (allEventData.isRecurring && !requiredEventData.recurring)

	let isEndDateEqual = null
	let isRepeatFrequencyEqual = null
	let isDaysEqual = null
	let isSelectedDateEqual = null
	if(sendEndDate) {
		isEndDateEqual = allEventData.endDate.tz(allEventData.selectedTimeZone).format('DD/MM/YYYY') === dayjs(requiredEventData.endDate).tz(requiredEventData.timeZone).format('DD/MM/YYYY')
		isRepeatFrequencyEqual = allEventData.frequency?.toLowerCase() === requiredEventData.repeatFrequency?.toLowerCase()
		if(allEventData.frequency.toLowerCase() === "weekly" || allEventData.frequency.toLowerCase() === "bi-weekly" ) {
			isDaysEqual = isEqual(allEventData.selectedDay.sort(),requiredEventData.day.sort())	
		} else if(allEventData.frequency.toLowerCase() === "monthly") {
			isSelectedDateEqual = parseInt(allEventData.selectedMonthlyRecurringDate) === requiredEventData.selectedDate
		} 
	}

	

	const fields = {
		...(isDateEqual === false) && { date : allEventData.selectedDate.toISOString()},
		...(isTimeEqual === false) && { time : editedTime},
		...(isEndDateEqual === false) && {endDate : allEventData.endDate.toISOString()},
		...(isRepeatFrequencyEqual === false) && {repeatFrequency : allEventData.frequency?.toLowerCase()},
		...(isDaysEqual === false) && { selectedDays : allEventData.selectedDay},
		...(isSelectedDateEqual === false) && { selectedDate : allEventData.selectedMonthlyRecurringDate },
		timeZone : allEventData.selectedTimeZone,
		isRecurring : allEventData.isRecurring,
		service : allEventData.service,
		specialization : allEventData.topic,
		sessionTags : allEventData.sessionTags,
		sessionStatus : allEventData.selectedStatus,
		partialSession : allEventData.isPartialSession,
		whiteboardLink : allEventData.whiteboardLink,
		location : allEventData.location,
		meetingLinkDetails : { 
			link : allEventData.meetingLink,
			provider : "Any"
		},
		clientNotes : allEventData.sessionNotes.clientNotes,
		internalNotes : allEventData.sessionNotes.internalNotes,
		studentNotes : Object.entries(allEventData.studentNotes).map(([key,value])=>({
			id : key,
			notes : value
		})),
		resheduling : allEventData.isRescheduled,
		invoiceDetails : {
			hourlyPrice : allEventData.hourlyCost,
			tutorCost : allEventData.sessionCost
		},
		reminder : {
			isEnabled : allEventData.isRemainder.trim() !== "" && allEventData.isRemainder.trim().toLowerCase() !== "none",
			targetUserTypes : Object.keys(allEventData.sendRemindersTo).filter((key)=>allEventData.sendRemindersTo[key]===true && key !== "attendees"),
			timeDelta : getTimedelta(allEventData.isRemainder)
		},
		...(allEventData.selectedOption === "student") && {
			studentIds : allEventData.selectedStudents.map((val)=>val._id),
			sessionFor : "students"
		},
		...(allEventData.selectedOption === "studentGroup") && {
			groupId : allEventData.selectedGroup,
			sessionFor : "group"
		},
		sessionFor : allEventData.selectedOption === "student" ? "students" : "group",
		studentStatusMapping : allEventData.studentStatusMapping,
		tutorId : allEventData.selectedTutor
	}
	
	//console.log("SES_UPDT", fields)
	//return 
	try {
		const res = await updateSession(fields,requiredEventData._id, updateFutureSessions)
		triggerToast("Session updated successfully","success",{autoClose : 3000})
		if(requiredEventData.isEditingRequest){
			const res = await updateEventRequest(requiredEventData.eventRequestId, {
				"targetUserDetails.actionStatus" : "frozen"
			})
			console.log("AED",allEventData)
			fetchAndSetAllEventRequests(fetchAllEventRequests,allEventData.setAllEventRequests)
		}
	}catch(err){
		console.error(err)
		triggerToast("Error occured while updating sessions","error",{autoClose : 3000})
	}

}


export { sessionUpdater }
