import React, { useEffect, useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridWeek from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import EventDetailsCard from "../../pages/EventDetailsCard/EventDetailsCard";
import ConsultationWhite from "../../assets/calendarV2/ConsultationWhite.svg";
import ConsultationWhiteSmall from "../../assets/calendarV2/ConsultationWhiteSmall.svg";
import officeWorkWhite from "../../assets/calendarV2/officeWorkWhite.svg";
import officeWorkWhiteSmall from "../../assets/calendarV2/officeWorkWhiteSmall.svg";
import InternalMeetingWhite from "../../assets/calendarV2/InternalMeetingWhite.svg";
import InternalMeetingWhiteSmall from "../../assets/calendarV2/InternalMeetingWhiteSmall.svg";
import ExternalMeetingWhite from "../../assets/calendarV2/ExternalMeetingWhite.svg";
import ExternalMeetingWhiteSmall from "../../assets/calendarV2/ExternalMeetingWhiteSmall.svg";
import frozenEventTypes from "../../utils/eventTypes";
import { generateDummyEvents } from "./CalendarDummyData";
import "./FullCalendarV2.css";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { getColourMappingForServices } from "../../utils/serviceColours";
import { USER_ACTION_STATUS_MAPPING } from "../../utils/eventUtils";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import { handleDateClick, handleEventClick } from "./helpers/clickHandlers";
import { getStatus, getStatusV2} from "./helpers/utils";
import DeclineRequestPopUp from "../DeclineRequestPopUp/DeclineRequestPopUp";
import { fetchAllEventRequests, updateEventRequest } from "../../utils/eventService";
import { fetchAndSetAllEventRequests } from "../../pages/CalendarV2/helpers/fetchers";
import PopupContainer from "../PopupContainer/PopupContainer";
import RequestActionPopup from "../RequestAction/RequestActionPopup";
import { EVENT_CARD_STYLES } from "./helpers/cardStyles";
import frozenEventStatus from "../../utils/eventStatus";
import EventCardWeek from "./EventCardWeek";

const truncateString = (str, maxLength) => {
  return str?.length > maxLength ? str?.substring(0, maxLength) + "..." : str;
};

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const events = generateDummyEvents();



const FullCalendarV2 = (props) => {

  const { user, view, organization } = props;
  const { userDefaults, id } = useSelector((state) => state.user);
  const allEvents = useMemo(() => {

	if(props.allEvents && props.leaveDetails && props.allEventRequests){
			return [
				...props.allEvents.filter(val=>{
					if(["session", "consultation", "externalappointment"].includes(val.extendedProps.eventType.toLowerCase())){
						return true
					}else {
						return !props.allEventRequests.filter(_val=>_val.showOnCalendar).map(_val=>_val.eventId).includes(val.extendedProps._id)
					}
				}),
				...props.allEventRequests?.filter(val => val.showOnCalendar),
				...props.leaveDetails?.filter(val => val.user._id === user.id)
			]
	} else {
		return []
	}

  }, [props.allEvents, props.allEventRequests, props.leaveDetails])


  const [requestPopupInfo, setRequestPopupInfo] = useState({
    trigger: false,
    eventRequestId: "",
    eventRequestDetails: null,
    purpose: ""
  })

  const [requestInfoDetails, setRequestInfoDetails] = useState({
    type: "cancel",
    trigger: false
  })




  const plugins = useMemo(() => {
    if (view === "month" || view === "year") {
      return [dayGridPlugin, interactionPlugin, momentTimezonePlugin];
    } else {
      return [timeGridPlugin, interactionPlugin, momentTimezonePlugin];
    }
  }, [view]);

  const { servicesAndSpecialization } = useSelector(
    (state) => state.organization.organization.settings
  );
  const serviceColours = useMemo(() => {
    if (servicesAndSpecialization) {
      return getColourMappingForServices(servicesAndSpecialization);
    } else {
      return [];
    }
  }, [servicesAndSpecialization]);

  const formatDate = (date) => {
    const options = { weekday: "short", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatName = (name) => {
    if (!name || name === "" || typeof name !== "string") return "N/A";
    const parts = name.split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[1].charAt(0)}.`;
    }
    return name;
  };

  const getIconForEventType = (eventType) => {
    switch (eventType.toLowerCase()) {
      case frozenEventTypes.OFFCWORK.eventType:
        return {
          icon: officeWorkWhite,
          bgColor: "#9BCA7E",
          iconSmall: officeWorkWhiteSmall,
        };
      case frozenEventTypes.CONSULTATION.eventType:
        return {
          icon: ConsultationWhite,
          bgColor: "#559B9B",
          iconSmall: ConsultationWhiteSmall,
        };
      case frozenEventTypes.INTMEETING.eventType.toLowerCase():
        return {
          icon: InternalMeetingWhite,
          bgColor: "#82848B",
          iconSmall: InternalMeetingWhiteSmall,
        };
      case frozenEventTypes.EXTMEETING.eventType.toLowerCase():
        return {
          icon: ExternalMeetingWhite,
          bgColor: "#BB7ECA",
          iconSmall: ExternalMeetingWhiteSmall,
        };
      default:
        return {
          icon: officeWorkWhite,
          bgColor: "#9BCA7E",
          iconSmall: officeWorkWhiteSmall,
        };
    }
  };
  // Function to convert hex color or RGB to RGBA
  const convertToRGBA = (color, opacity) => {
    let rgbaColor;

    // If color is in hex format
    if (color?.startsWith("#")) {
      const hex = color?.replace("#", "");
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    // If color is already in rgb format
    else if (color?.startsWith("rgb")) {
      rgbaColor = color?.replace("rgb", "rgba").replace(")", `, ${opacity})`);
    }

    return rgbaColor;
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const { extendedProps } = event;
	
	

	if(eventInfo.event._def.ui.display === "background"){
		console.log("ERRR", eventInfo)
		return <div className="h-full w-full bg-[repeating-linear-gradient(55deg,#F3F3F3,#F3F3F3_4px,#BEC2CE_4px,#BEC2CE_6px);] rounded-[4px] pointer-events-none"></div>
	}
	console.log("evinfo", extendedProps)

    let {
      attendees: _attendees,
      tutor,
      eventType,
      studentIds,
      studentName: _studentName,
    } = extendedProps;
    let displayTextAttendee =
      _attendees?.map((attendee) =>
        formatName([attendee.firstName, attendee.lastName].join(" "))
      )[0] + ["", "+", _attendees?.length - 1].join(" ");

    // Determine student and tutor names
    const studentName =
      _attendees && _attendees?.length > 0
        ? formatName(_attendees[0])
        : studentIds[0]
          ? [studentIds[0].firstName, studentIds[0].lastName[0] + "."].join(" ") +
          (studentIds.length > 1
            ? ["", "+", studentIds.length - 1].join(" ")
            : "")
          : formatName(_studentName);
    const tutorName =
      eventType === frozenEventTypes.SESSION.eventType ? extendedProps.tutorName : formatName(tutor) || "N/A";

    // Format start and end times
    const startTime = dayjs.tz(event.start).tz(userDefaults?.timeZone ? userDefaults?.timeZone : dayjs.tz.guess()).format("hh:mm A");
    const endTime = dayjs.tz(event.end).tz(userDefaults?.timeZone ? userDefaults?.timeZone : dayjs.tz.guess()).format("hh:mm A");


    console.log(id, userDefaults)

    const eventData = {
      eventType: extendedProps.eventType,
      status: getStatusV2(eventType, extendedProps, event, user),
      students:
        extendedProps.studentIds?.length > 0
          ? extendedProps.studentIds
            .map((student) => [student.firstName, student.lastName].join(" "))
          : [extendedProps.studentName],
      tutor: extendedProps.tutorName,
      service: extendedProps.service,
      dateTime: `${dayjs.tz(event.start).tz(userDefaults?.timeZone ? userDefaults?.timeZone : dayjs.tz.guess()).format("dddd, MMM DD")} ● ${startTime} - ${endTime}`,
      location: typeof (extendedProps.location) === "string" ? extendedProps.location : extendedProps.location?.address,
      onlineMeet: extendedProps.onlineMeet ?? extendedProps.meetingLink,
      ...(extendedProps.eventType !== frozenEventTypes.SESSION.eventType && {
        attendees: extendedProps.attendees.map((attendee) =>
          [attendee.firstName, attendee.lastName].join(" ")
        ),
      }),
      info: extendedProps?.requestedEventDetails?.additionalInfo ?? extendedProps?.agendaNotes?.note,
    };

    const { icon, bgColor, iconSmall } = getIconForEventType(eventType);

    console.log("TEST_STATUS", eventType,  frozenEventTypes.INTMEETING.eventType.toLowerCase());
    // Render event differently based on event type


    if (eventType === frozenEventTypes.SESSION.eventType) {
      // For session events, use the event's background color
      return (
        <Tooltip
          title={<EventDetailsCard eventData={eventData} persona={user?.role} extendedProps={extendedProps} parentProps={props} {...{ requestPopupInfo, setRequestPopupInfo, requestInfoDetails, setRequestInfoDetails }} />}
          placement={"right"}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "transparent",
                cursor: "pointer",
                padding: "0px",
                margin: "0px",
              },
            },
          }}
        >
          {view === "month" ? (
            <div
              className={`event-container-month`}
              style={{
                backgroundColor:
                  serviceColours[extendedProps.service] ?? "#0671E0",
              }}
            >
              <div className="event-title font-[500] text-[8px] pl-[4px] text-white">
                {studentName} / {tutorName}
              </div>
            </div>
          ) : (
			<div className="h-full w-full cursor-pointer">
			 <EventCardWeek 
				eventType={extendedProps.eventType}
				status={extendedProps.status}
				studentName={truncateString(studentName,16)}
				tutorName={truncateString(tutorName,16)}
				displayTextAttendee={displayTextAttendee}
				customBgColor={serviceColours[extendedProps.service] ?? "#0671E0"}
				startTime={startTime}
				endTime={endTime}
			  />
			  </div>
          )}

        </Tooltip>
      );
    } else {
      // For other event types, use a flex layout with an icon and specific background color
      return (
        <Tooltip
          title={<EventDetailsCard eventData={eventData} persona={user?.role} userId={id} extendedProps={extendedProps} parentProps={props} {...{ requestPopupInfo, setRequestPopupInfo, requestInfoDetails, setRequestInfoDetails }} />}
          placement={"right"}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "transparent",
                cursor: "pointer",
                padding: "0px",
                margin: "0px",
              },
            },
          }}
        >
          {view === "month" ? (
            <div
              className="event-container-other-month flex items-center justify-center cursor-pointer w-full"
              style={{ backgroundColor: convertToRGBA(bgColor, 0.1) }}
            >
              <div
                className="px-[6px] flex items-center justify-center h-full"
                style={{ backgroundColor: bgColor }}
              >
                <img
                  className="event-icon h-[15px] w-[15px] object-contain xl:h-auto xl:w-auto"
                  src={iconSmall}
                  alt={`${eventType} Icon`}
                />
              </div>
              <div className="event-details-other flex-1">
                <div className="event-title font-[500] text-[8px] text-secondary pl-[4px]">
                  {displayTextAttendee}
                </div>
              </div>
            </div>
          ) : (
			<div className="h-full w-full cursor-pointer">
			 <EventCardWeek 
				eventType={extendedProps.eventType}
				status={extendedProps.status}
				studentName={truncateString(studentName,16)}
				tutorName={truncateString(tutorName,16)}
				displayTextAttendee={displayTextAttendee}
				customBgColor={convertToRGBA(bgColor,0.1)}
				customBgIconColor={bgColor}
				startTime={startTime}
				endTime={endTime}
			  />
			  </div>
          )}
        </Tooltip>
      );
    }

  };

  useEffect(() => {
    if (props.calRef.current) {
      const nodes = window.document.getElementsByClassName(
        "fc-timegrid-axis-frame"
      );
      if (nodes && nodes.length > 0) {
        nodes[0].style =
          "display : flex; align-items : center; justify-content : center; height : 100%;";
        nodes[0].innerHTML = `
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49967 14.6666C12.1816 14.6666 15.1663 11.6818 15.1663 7.99992C15.1663 4.31802 12.1816 1.33325 8.49967 1.33325C4.81778 1.33325 1.83301 4.31802 1.83301 7.99992C1.83301 11.6818 4.81778 14.6666 8.49967 14.6666Z" stroke="#515C7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.5 4V8L11.1667 9.33333" stroke="#515C7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
      }
    }
  }, [props.calRef.current]);

  //---------CLICK HANDLERS-----------

  //---------END CLICK HANDLERS---------

  const dayHeaderContent = (args) => {

    const { date } = args;
    const today = new Date();
    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    const isCurrentWeekday = date.getDay() === today.getDay();

    // Determined format based on view
    let formattedDate;
    switch (view) {
      case "month": // This is the view for the month
        formattedDate = date.toLocaleDateString("en-US", { weekday: "short" });
        break;
      case "week": // This is the view for the week
        formattedDate = formatDate(date); // Use your existing formatDate function
        break;
      case "year": // This is the view for the year
        formattedDate = date.toLocaleDateString("en-US", { weekday: "narrow" }); // No header for the year view
        break;
      default:
        formattedDate = formatDate(date); // Default to week format
    }

    return (
      <div className={`custom-day-header ${((isToday || isCurrentWeekday) && view !== "year") ? "is-today" : ""}`}>
        {formattedDate}
      </div>
    );
  };

  const [currentTimezone, setCurrentTimezone] = useState();

  useEffect(() => {
    console.log("TZ CHANGE")
    setCurrentTimezone(
      userDefaults.timeZone ? userDefaults.timeZone : dayjs.tz.guess()
    );
  }, [userDefaults.timeZone])

  const EventDot = () => {
    return (
      <div className="h-1 w-1 bg-blue-70 rounded-full m-auto">
        &nbsp;
      </div>
    )
  }

  const GoToButton = () => {
    return (
      <div>
        <button className="font-normal text-[12px] leading-[18px] text-blue-70">Go to this date</button>
      </div>
    )
  }

  async function updateActionStatus(eventRequestId, action) {
    try {

      const res = await updateEventRequest(eventRequestId, {
        "targetUserDetails.actionStatus": "frozen"
      })
      fetchAndSetAllEventRequests(fetchAllEventRequests, props.setAllEventRequests)
      setRequestPopupInfo({
        trigger: false,
        eventRequestId: ""
      })

    } catch (err) {
      triggerToast("Error occured while updating the status.")
    }
  }


  console.log("RIID", requestInfoDetails)

  return (
    <div className="calendar-container">


      {
        currentTimezone ?
          <FullCalendar
            ref={props.calRef}
            views={{
              multiMonth: {
                type: 'multiMonth',
                moreLinkContent: <EventDot />,
                duration: { months: 12 },
                multiMonthMaxColumns: 4,
                multiMonthMinWidth: 226,
                eventMaxStack: 3,
                dayMaxEvents: 3,
                dayMaxEventRows: 3
              }
            }}
            plugins={
              [
                momentTimezonePlugin,
                dayGridPlugin,
                timeGridPlugin,
                multiMonthPlugin,
                interactionPlugin,
              ]
            }
			dayMaxEvents={4}
			slotDuration={"00:30:00"}
            weekends={true}
            events={allEvents}
            headerToolbar={false}
            firstDay={1}
            height="100%" /* Ensure it takes up the full height of its parent */
            contentHeight="auto" /* Auto adjust content height */
            allDaySlot={false}
            eventContent={renderEventContent}
            dayHeaderContent={dayHeaderContent}
            slotEventOverlap={false}
            stickyHeaderDates={true}
            dateClick={(data) => handleDateClick(data, props)}
            eventClick={(eventInfo) => handleEventClick(eventInfo, props)}
            initialView={"timeGridWeek"}
            timeZone={currentTimezone}
			eventMinHeight={35}
          />

          : <></>
      }
      <DeclineRequestPopUp
        trigger={requestPopupInfo.trigger && requestPopupInfo.purpose === "confirm-decline"}
        setTrigger={() => { setRequestPopupInfo(prev => ({ ...prev, trigger: !prev.trigger, purpose: "" })) }}
        handleDeclineClick={() => updateActionStatus(requestPopupInfo.eventRequestId, "frozen")}
      />

      <PopupContainer trigger={requestInfoDetails?.trigger} setTrigger={() => setRequestInfoDetails(prev => ({ ...prev, trigger: !prev.trigger }))} customCloseButton={true} >
        <RequestActionPopup
          requestType={requestInfoDetails?.type}
          setTrigger={() => setRequestInfoDetails(prev => ({ ...prev, trigger: !prev.trigger }))}
          headerName={requestInfoDetails.type === "cancel" ? "Cancel Event" : "Reschedule Event"}
          placeholderText={requestInfoDetails.type === "cancel" ? "Briefly describe the reason for requesting cancellation" : "Briefly describe the reason for rescheduling along with a suitable date for rescheduling the session."}
          noteText={requestInfoDetails.type === "cancel" ? "Your admin/tutor would need to approve this cancellation request." : "Your admin/tutor would need to approve this reschedule request."}
          eventId={requestInfoDetails.eventId}
          eventType={requestInfoDetails.eventType}
          setAllEventRequests={props.setAllEventRequests}
        />
      </PopupContainer>
    </div>
  );
};

export default FullCalendarV2;
