import React, { useEffect, useState, useRef } from "react";
import ArrowDown from "../assets/ArrowDown.svg";

const PriceSelectDropDown = ({
  priceList,
  selectedPrice,
  siMapping,
  setSiMapping,
  setEditTier,
  selectedPlanType,
  selectionDisabled,
  title,
  setNumberOfUnits,
}) => {
  const [selectedPriceName, setSelectedPriceName] = useState("");
  const [open, setOpen] = useState(false);
  const dropDownRef = useRef(null);

  useEffect(() => {
    if (!siMapping) return;
    let index = siMapping.findIndex(
      (item) =>
        item.lookupKey.includes(selectedPrice.lookupKey) &&
        !item.lookupKey.includes("PAYG")
    );

    const updatedSiMapping = [...siMapping];

    if (
      updatedSiMapping[index].hasOwnProperty("newPriceId") &&
      updatedSiMapping[index].newPriceId === null
    ) {
      let priceName =
        priceList.find((item) => item.value === selectedPrice.value)?.name ||
        "";
      setSelectedPriceName(priceName);
      let name = getAdjustedName(priceName?.split(" ")[0]);

      setNumberOfUnits(name);
    }
  }, [siMapping, selectedPrice, priceList]);

  useEffect(() => {
    let priceName =
      priceList.find((item) => item.value === selectedPrice.value)?.name || "";
    setSelectedPriceName(priceName);
    let name = getAdjustedName(priceName?.split(" ")[0]);

    setNumberOfUnits(name);
    // Add event listener for outside click
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [priceList, selectedPrice]);

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  // Sorting priceList by unit_amount in ascending order
  const sortedPriceList = [...priceList].sort(
    (a, b) => a.unit_amount - b.unit_amount
  );
  const getAdjustedName = (name) => {
    const numberMatch = name.match(/\d+/);
    if (numberMatch) {
      const number = parseInt(numberMatch[0], 10);
      return number;
    }
    return name;
  };

  return (
    <div className="relative " ref={dropDownRef}>
      <div
        // border-[#7C859C]
        className={`text-[14px] flex w-full h-[30px] rounded-[6px] bg-[#FFFFFF] border  ${
          selectionDisabled
            ? " border-[#7C859C]  cursor-not-allowed text-secondary-70"
            : "border-blue-70"
        }  gap-[12px] px-[6px] py-[8px] justify-between items-center`}
        onClick={() => {
          if (selectionDisabled) {
            return;
          }
          setOpen(!open);
        }}
      >
        {}
        <div className="">
          {getAdjustedName(selectedPriceName?.split(" ")[0])} {title}
          {title !== "Custom Tests"
            ? selectedPlanType === 0
              ? "/mo"
              : "/yr"
            : ""}
          {/* {" - "}
          <span className="font-medium">
            {selectedPlanType === 0
              ? selectedPrice?.price === 0
                ? "Free"
                : "$" + selectedPrice?.price?.toFixed(0)
              : selectedPrice?.price === 0
              ? "Free"
              : "$" + (selectedPrice?.price / 12)?.toFixed(0)}
          </span> */}
        </div>{" "}
        <div>
          <img
            src={ArrowDown}
            className={`h-[20px] w-[20px]" alt="Arrow Down`}
          />
        </div>
      </div>
      {open && (
        <div className="z-[5] absolute mt-2 rounded-[6px] shadow-md bg-[#FFFFFF]  w-full h-fit grid gap-[4px] ">
          {sortedPriceList.map((price, index) => (
            <div
              key={index}
              className="rounded-[6px] text-[14px] font-medium p-[12px] hover:bg-[#F4F5F7] cursor-pointer"
              onClick={(e) => {
                setSiMapping(price.value);
                // let newPriceId =
                //   price.value !== selectedPrice.value ? price.value : null;

                // if (newPriceId) {
                //   setSelectedPriceName(price.name);
                //   let index = siMapping.findIndex(
                //     (item) =>
                //       item.lookupKey.includes(selectedPrice.lookupKey) &&
                //       !item.lookupKey.includes("PAYG")
                //   );
                //   const updatedSiMapping = [...siMapping];
                //   updatedSiMapping[index] = {
                //     ...updatedSiMapping[index],
                //     newPriceId,
                //   }; // Update the value at the found index
                //   setSiMapping(updatedSiMapping); // Set the updated array as the new state
                // } else {
                //   setSelectedPriceName(price.name);
                //   let index = siMapping.findIndex(
                //     (item) =>
                //       item.lookupKey.includes(selectedPrice.lookupKey) &&
                //       !item.lookupKey.includes("PAYG")
                //   );

                //   const updatedSiMapping = [...siMapping];

                //   if (updatedSiMapping[index].hasOwnProperty("newPriceId")) {
                //     delete updatedSiMapping[index].newPriceId; // Remove the newPriceId property
                //     setSiMapping(updatedSiMapping);
                //   }
                // }

                setOpen(false);
                setEditTier(false);
              }}
            >
              {getAdjustedName(price?.name?.split(" ")[0])}{" "}
              <span className="font-[200]">{title} for </span>
              <span className="font-medium">
                {selectedPlanType === 0
                  ? price?.unit_amount == 0
                    ? "Free"
                    : "$" + (price?.unit_amount / 100)?.toFixed(0)
                  : price?.unit_amount == 0
                  ? "Free"
                  : "$" + (price?.unit_amount / 100 / 12)?.toFixed(0)}
              </span>
              <span className="font-[200]">
                {price?.unit_amount !== 0 ? "/mo" : ""}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PriceSelectDropDown;
