import React, { useState } from "react";
import SearchIcon from "../../SearchIcon/SearchIcon";
import GroupDropDownList from "./GroupDropDownList";

const GroupDropdown = ({groups, selectedGroup, setSelectedGroup, setIsDropdownOpen}) => {
  const [isSearchText, setIsSearchText] = useState("");

  console.log(groups);
  return (
    <main className=" absolute top-[110%] left-0 w-full  max-h-[320px] cursor-default  z-10 rounded-[6px]  py-[12px] px-[8px]  flex flex-col  gap-[6px] bg-white shadow-[0px_4px_18px_0px_#11167D1A] overflow-y-auto ">
      <section className="  py-[12px] px-[8px] border-[1px] border-secondary-10  rounded-[6px]  w-full flex justify-between items-center gap-[8px] ">
        <SearchIcon />
        <input
          type="text"
          placeholder="Search"
          className=" flex-1  text-[12px] leading-[12px]  text-secondary-60 outline-none "
          onChange={(e) => setIsSearchText(e.target.value)}
        />
      </section>

      {groups &&
        groups.map((group) => {
          if (
            (group.name && group.name.toLowerCase().includes(isSearchText.toLowerCase())) 
          ) {
            return (
              <GroupDropDownList
                key={group._id}
                name={group.name}
                id={group._id}
                bgColor={group.bgColor}
                selectedgroup={selectedGroup}
                setSelectedgroup={setSelectedGroup}
                setIsDropdownOpen={setIsDropdownOpen}
              />
            );
          } else {
            return null;
          }
        })}
    </main>
  );
};

export default GroupDropdown;
