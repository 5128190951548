import { Link } from 'react-router-dom'
import { triggerUpdateSubscriptionPopUp } from '../../utils/subscriptionPopUpService'
import RoundLockedIcon from '../RoundLockedIcon/RoundLockedIcon'
import styles from './LockedStateOverlayVariant2.module.css'


const LockedStateOverlayVariant2 = ({outerContainerStyle}) => {
    return (
        <div className={styles.container} style={outerContainerStyle}>
            <div className={styles.innerContainer}>
                <div className={styles.roundIconDesktop}>
                    <RoundLockedIcon style={{ height: "48px", width: "48px" }} />
                </div>
                <div className={styles.roundIconMobile}>
                    <RoundLockedIcon style={{ height: "48px", width: "48px" }}/>
                </div>
                <div className={styles.desktopTitle}>This feature is not available with the current subscription plan of the organisation.</div>
                <div className={styles.mobileTitle}>Upgrade to a paid plan to unlock this feature.</div>
                <Link onClick={(e)=>triggerUpdateSubscriptionPopUp()} className={styles.button}>Manage Subscription</Link>
            </div>
        </div>
    )
}


export default LockedStateOverlayVariant2
