import { toggleSubscriptionPopUpTrigger, updateSubscriptionOperationMode } from "../app/slices/subscriptionOperation";
import { store } from "../app/store";

const triggerUpdateSubscriptionPopUp = () => {
    //Fetch subscription v2 from store to open the manage subscription pop-up depending on 
    // free package -> if free -> create-subscription else -> update-subcription
    const subscriptionV2 = store.getState().subscription.subscriptionV2
    const isFreeSubscription = subscriptionV2?.subscription.__t === "FreeSubscription"
    const subscriptionOperationPopUpMode = isFreeSubscription ? "update-from-free" : "update-subscription"
    const subscriptionOperationPopUpTrigger = true
    store.dispatch(updateSubscriptionOperationMode(subscriptionOperationPopUpMode))
    store.dispatch(toggleSubscriptionPopUpTrigger(subscriptionOperationPopUpTrigger))
}


export  {
    triggerUpdateSubscriptionPopUp
}
