import { useEffect, useMemo, useState } from 'react'
import InfoIcon from '../../components/ProductIcons/InfoIcon'
import Tooltip from '../../components/Tooltip/Tooltip'
import styles from './ProductSettings.module.css'
import TabSelector from '../../components/TabSelector/TabSelector'
import LeadStatus from '../../components/ProductSettings/UserManagement/Components/LeadStatus/LeadStatus'
import UserManagement from '../Settings/Tabs/UserManagement/UserManagement'
import SelectedTab from './SelectedTab'
import { useSearchParams } from 'react-router-dom'
import { getTooltipStyleClass } from '../../utils/commonlyUsedTooltipStyles'
import TooltipIcon from '../../assets/icons/SettingsIcon/TooltipIcon'

const tabMapping = ["User Management","Permissions"]


const ProductSettings = (props) => {

    const [selectedTab, setSelectedTab] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()

    const handleTabSelectionClick = (idx) => {
        setSearchParams({
            tab : idx
        })
    }

    useEffect(()=>{
        if(searchParams.get("tab")) {
            const tab = parseInt(searchParams.get("tab"))
            setSelectedTab(tab)
        } else {
            setSearchParams({
                tab : 0
            })
        }
    },[searchParams])

    return(
        <div className={styles.container}>
            <div className={styles.heading}>
                <h3>CRM</h3> <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"Control your user management settings on this page"} tooltipStyles={getTooltipStyleClass({width : "220px"})}/>
            </div>
            <TabSelector {...{items : tabMapping, selectedIdx : selectedTab, onClick : handleTabSelectionClick}}/>
            <SelectedTab currentPage={"crm"} selectedTab={selectedTab} setSearchParams={setSearchParams}/>
        </div>
    )
}


export default ProductSettings