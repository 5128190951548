import OAuthButton from "../../components/OAuthButton/OAuthButton";
import googleLogo from "./GoogleLogo.svg";
import msLogo from "./MSLogo.svg";
import dividerLine from "./DividerLine.svg";
import styles from "./OrgSignupV2.module.css";
import wrongIndicator from "./WrongIndicator.svg";
import ButtonV2 from "../../components/ButtonV2/ButtonV2";
import ArrowIconRightV2 from "../../components/ArrowIconRightV2/ArrowIconRightV2";
import ArrowIconLeftV2 from "../../components/ArrowIconLeftV2/ArrowIconLeftV2";
import InitalScreen from "../../components/OrgSignupV2/InitialScreen/InitialScreen";
import OTPExpiredScreen from "../../components/OrgSignupV2/OTPExpiredScreen/OTPExpiredScreen";
import SetPasswordScreen from "../../components/OrgSignupV2/SetPasswordScreen/SetPasswordScreen";
import ChoosePlanScreen from "../../components/OrgSignupV2/ChoosePlanScreen/ChoosePlanScreen";
import OrgDetailsV2 from "../../components/OrgSignupV2/OrgDetailsV2/OrgDetailsV2";
import VerifyEmailOtp from "../../components/OrgSignupV2/VerifyEmailOtp/VerifyEmailOtp";
import ReviewAndPay from "../../components/OrgSignupV2/ReviewAndPay/ReviewAndPay";
import ServiceModule from "../../components/ServiceModuleCard/ServiceModule";
import CalendarIcon from "../../components/ServiceModuleCard/CalendarIcon";
import AccountType from "../AccountType/AccountType";
import { Route, Routes } from "react-router-dom";
import LearnerBlocker from "../LearnerBlocker.jsx/LearnerBlocker";
import { useState, useEffect } from "react";
import OrgSignupContext from "../../context/OrgSignupContext";
import { useLazyGetPricesV2Query } from "../../app/services/subscription";
import getRecommendedPackageWithProducts from "../../utils/package_recommender";
import { ToastContainer } from "react-toastify";
import UnpurchasedPage from "../UnpurchasedStateScreens/UnpurchasedPage";
import { useDispatch } from "react-redux";
import { updateInitialTeamSize } from "../../app/slices/subscriptionOperation";


const OrgSignupV2 = (props) => {
  const dispatch = useDispatch()
  const [registerationAs, setRegistrationAs] = useState("Company");
  const [noOfTutors, setNoOfTutors] = useState(null);
  const [productPrices, setProductPrices] = useState(null);
  const [fetchPrices, fetchPricesResp] = useLazyGetPricesV2Query();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [stripeCustId, setStripeCustId] = useState(null);
  const [paygEnabled, setPaygEnabled] = useState(true);
  const [formDetails, setFormDetails] = useState({
    company: "",
    firstName: "",
    lastName: "",
    phoneCode: null,
    phone: "",
  });
  const [multiselectNoOfTutors, setMultiselectNoOfTutors] = useState(
    new Array(4).fill(0)
  );
  const [hasRecommended, setHasRecommended] = useState(false);
  const [recommendedIdx, setRecommendedIdx] = useState(null);

  const [multiselectProducts, setMultiselectProducts] = useState(
    new Array(4).fill({
      CRM: { price: 0, value: "", selected: true },
      Calendar: { price: "", value: "", selected: false, default: true },
      Assignments: { price: "", value: "", selected: false, default: true },
      "Custom Tests": { price: "", value: "", selected: false, default: true },
    })
  );

  const [subscriptionCoupon, setSubscriptionCoupon] = useState(null);

  //Store no of tutors in local storage incase of reload
  useEffect(()=>{
    if(noOfTutors >0){
      window.localStorage.setItem("noOfTutors",noOfTutors)
      dispatch(updateInitialTeamSize(noOfTutors))
    }
  }, [noOfTutors])

  //Fetch the prices from the API
  useEffect(() => {
    fetchPrices()
      .then((res) => {
        const prices = res.data.data.prices;
        setProductPrices(prices);
      })
      .catch((err) => {
        console.log("ERROR");
      });
  }, []);

  useEffect(() => {
    const email = window.localStorage.getItem("userEmail")
      ? window.localStorage.getItem("userEmail")
      : window.sessionStorage.getItem("userEmail");
    if (email) {
      setUserEmail(email);
    }
  }, []);

  useEffect(() => {
    console.log(">>>Prods Selected", selectedProducts);
  }, [selectedProducts]);

  return (
    <div className={styles.pageContainer}>
      {/* <InitalScreen /> */}
      {/* <OTPExpiredScreen /> */}
      {/* <SetPasswordScreen /> */}
      {/* <ChoosePlanScreen /> */}
      {/* <OrgDetailsV2 /> */}
      {/* <ServiceModule CalendarIcon={<CalendarIcon />} serviceTitle={"Calendar"} /> */}
      {/* <AccountType /> */}
      <OrgSignupContext.Provider
        value={{
          registerationAs,
          setRegistrationAs,
          noOfTutors,
          setNoOfTutors,
          productPrices,
          selectedProducts,
          setSelectedProducts,
          userEmail,
          setUserEmail,
          stripeCustId,
          setStripeCustId,
          setPaygEnabled,
          paygEnabled,
          formDetails,
          setFormDetails,
          multiselectNoOfTutors,
          setMultiselectNoOfTutors,
          multiselectProducts,
          setMultiselectProducts,
          hasRecommended,
          setHasRecommended,
          recommendedIdx,
          setRecommendedIdx,
          subscriptionCoupon,
          setSubscriptionCoupon,
        }}
      >
        <Routes>
          <Route index element={<AccountType />}></Route>
          <Route
            path="student-parent-info"
            element={<LearnerBlocker />}
          ></Route>
          <Route path="org" element={<InitalScreen />}></Route>
          <Route path="select-plan" element={<ChoosePlanScreen />}></Route>
          <Route path="verify-email" element={<VerifyEmailOtp />}></Route>
          {userEmail ? (
            <Route path="expired-otp" element={<OTPExpiredScreen />}></Route>
          ) : null}
          <Route
            path="set-new-password"
            element={<SetPasswordScreen />}
          ></Route>
          <Route path="org-details" element={<OrgDetailsV2 />}></Route>

          <Route path="review" element={<ReviewAndPay />}></Route>

          {/* <Route path="test-route" element={<PersonalInfo />}></Route > */}
        </Routes>
      </OrgSignupContext.Provider>
    </div>
  );
};

export default OrgSignupV2;
