import React from "react";
import AttendeeDeselect from "./AttendeeDeselect";

const AttendeeList = ({
  selectedUser,
  setSelectedUser,
  isDropDownOpen,
  users,
  userRole,
}) => {
  return (
    <main className=" w-full flex-1 flex  gap-[5px]  h-full cursor-pointer">
      <div className=" max-h-[88px] overflow-y-auto  w-full ">
        {isDropDownOpen ? (
          <section className=" flex  gap-[5px] items-center justify-start flex-wrap ">
            {selectedUser.map((id) => {
              const user = users.find((user) => user._id === id);

              if (user) {
                return (
                  <AttendeeDeselect
                    key={id}
                    userRole ={userRole}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    id={id}
                  />
                );
              } else return null;
            })}
          </section>
        ) : (
          <section className= {`flex  gap-[5px] items-center  ${selectedUser?.length > 5 ? "justify-evenly" : "justify-start"}  flex-wrap` }  >
            {selectedUser &&
              selectedUser.slice(0, 5).map((id) => {
                const user = users.find((user) => user._id === id);

                if (user) {
                  return (
                    <AttendeeDeselect
                      key={id}
                      userRole ={user?.role}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      type={"viewOnly"}
                      id={id}
                    />
                  );
                } else return null;
              })}

            {selectedUser && selectedUser.length > 5 && (
              <p className=" text-[12px] leading-[18px] text-[#003266]">
                +{selectedUser && selectedUser.length - 5} more
              </p>
            )}
          </section>
        )}
      </div>
    </main>
  );
};

export default AttendeeList;
