import { useRef } from "react"
import { Link } from "react-router-dom"
import useOutsideAlerter from "../../../hooks/useOutsideAlerter"

function Button (props) {
	
	return (
		<button {...props}>
			{props.children}
		</button>
	)

}

function UserDisplayImage({profile, name, bgColor}){
	return (
		<>
			{ 
			profile ? 
			<img src={profile} className="h-[24px] w-[24px] rounded-full object-cover"/>
			: 
			<div className="text-uppercase min-h-[24px] min-w-[24px] rounded-full flex items-center justify-center text-white font-[lexend] font-[400] text-[12px] leading-[12px]" style={{backgroundColor : bgColor}}>{name[0]}</div>
			}
		</>
	)
}

function UserDetails({name, email}) {
	return (
		<div className="flex flex-col gap-[2px] items-start justify-start max-w-[143px] text-wrap overflow-wrap break-all">
			<span className="text-capitalize font-[lexend] text-[12px] leading-[12px] font-[400] text-secondary-80 max-w-full">{name}</span>
			<span className="font-[lexend] font-[400] text-[10px] leading-[10px] text-secondary-60 max-w-full">{email}</span>
		</div>
	)
}

function UserSelectDropdown({allUserList, selectedUserId, setSelectedUserId, isItemLink, itemLink, onClick, trigger, setTrigger}){
	
	const RenderedComponent = isItemLink ? Link : Button
	const ref = useRef(null)


	return (
		<div className="absolute top-[calc(100%+4px)] rounded-[6px] bg-white py-[12px] px-[8px] z-[10] flex flex-col items-start justify-start gap-[6px] max-h-[322px] overflow-y-auto shadow-[0px_4px_18px_0px_#11167D1A] w-[193px] min-w-[193px] max-w-[193px]" ref={ref}>
			{
				allUserList.map((user, idx)=>{
					const {email, firstName, lastName, name,  profile, bgColor } = user
					return (
						<RenderedComponent 
							{...(isItemLink) && {to:itemLink}}
							className="flex flex-row items-center justify-start gap-[6px] p-[4px] hover:bg-secondary-5 !hover:no-underline !no-underline w-full rounded-[6px]"
							onCLick={(e)=>onClick(e,user)}
						>
							<UserDisplayImage {...{profile,name, bgColor}} />
							<UserDetails {...{name, email}} />
						</RenderedComponent>
					)
				})
			}
		</div>
	)

}

export default UserSelectDropdown
