import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bubble } from "react-chartjs-2";
import { backgroundColors } from "./staticData";
import { useSelector } from "react-redux";

const iniOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      title: {
        color: "#24A3D9",
        display: false,
        text: "Concepts",
        font: {
          weight: 600,
          size: 20,
        },
      },
      ticks: {
        stepSize: 5,
        // callback: function (value, index, ticks) {
        //    // return tempsubjects[index]
        //    return index
        // }
        color: "#517CA8",
        fontFamily: 'Lexend',
        family: 'Lexend',
        padding:30,
        font: {
          weight: 400,
          size: 15,
          color: "#517CA8",
          family: "Lexend",
        },
      },
    },
    y: {
      beginAtZero: true,
      // grid: {
      //   display: false
      // }
      // min: 100,

      title: {
        display: true,
        text: "Time Taken (seconds)",
        color: "#24A3D9",
        margin:30,
        font: {
          weight: 600,
          size: 20,
        },
      },
      ticks: {
        stepSize: 5,
        maxTicksLimit: 6,
        color: "#517CA8",
        fontFamily: 'Lexend',
        family: 'Lexend',
        font: {
          weight: 400,
          size: 15,
          color: "#517CA8",
          family: "Lexend",
        },
        // callback: function (value, index, ticks) {
        //    return '' + value;
        // }
      },
    },
  },
  layout: {
    padding: {
      top: 50,
      bottom:40
    },
  },
};


ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const tempdata = [{ x: 5, y: 100, r: 0 }];
const dummy = {
  label: "",
  data: [{ x: 0, y: 0, r: 0 }],
  backgroundColor: "rgba(255, 99, 132, 0)",
};

const data1 = {
  datasets: [
    { ...dummy },
    {
      label: "",
      data: tempdata,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

export default function StudentBubbleChart({
  score,
  time,
  accuracy,
  subjects,
  selectedSubject,
  scroller,
  parentdashboard
}) {
  const [options, setOptions] = useState(iniOptions);
  const [chartwidth, setchartwidth] = useState('1540px')
  const [canvasWidth, setCanvasWidth] = useState(500);
  const [data, setData] = useState(data1);

  const chartRef = useRef(null);
  const [flag, setflag] = useState(0)
  useEffect(() => {
    if(!flag){
      
      setTimeout(() => {
        setflag(1)
      }, 1000);
    } 
    else{
      if (subjects.length === 0) {
        setData({ datasets: [] });
  
        return;
      }
      const curr = subjects?.find((item) => item?.subject === selectedSubject);
      if (curr === undefined || !curr?.subject) {
        setData({ datasets: [] });
        return;
      }
      if (time) {
        handleTimeChart(curr);
      } else if (accuracy) {
        handleAccuracyChart(curr);
      }
    }
  }, [flag])
  const { role: persona, id } = useSelector((state) => state.user);

  const getColor = (idx, len) => {
    let index = idx;
    if (idx > len - 1) {
      index = index % len;
    }
    // //console.log(index);
    return backgroundColors[index] !== undefined
      ? backgroundColors[index]
      : backgroundColors[0];
  };
  const handleTimeChart = (current) => {
    if (!current.timeManagement || current?.timeManagement?.length === 0)
      return;
    let timeArr = current.timeManagement;
    let concepts = current?.timeManagement?.map((it) => it?.conceptName);
    const datasets = [];
    console.log('curr?.avgTimeTaken', timeArr);
    timeArr?.map((curr, id) => {
      const x = (id + 1) * 5;
      const y = curr?.avgTimeTaken>0 ? curr?.avgTimeTaken   : 0;

      let getValue = curr?.total;

      let radius = Math.round(getValue);
      // //console.log(totalConcept, percent);
      if (radius < 5) {
        radius = 5;
      } else if (radius > 40) {
        radius = 40;
      }

      datasets.push({
        label: concepts[id],
        // data: [{ x, y: percent, r: 20 }],
        data: [{ x, y, r: radius, label: concepts[id] }],
        backgroundColor: getColor(id, timeArr?.length),
      });
    });
    datasets.push({
      label:'',
      // data: [{ x, y: percent, r: 20 }],
      data: [{ x:5*(timeArr?.length+1), y:0, r: 0, label: '' }],
      backgroundColor: '#FFF',
    });
    console.log("first",datasets)

    setData({
      datasets: datasets,
    });
    setchartwidth(`${datasets?.length*237<1540?1540:datasets?.length*237}px`)
    setOptions((prev) => ({
      ...prev,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context, c, d) {
              return `  ${"Time "} : ${(context.parsed.y )} seconds`;
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          padding: 10,
          titleAlign: "right",
        },
        legend: {
          display: false,
        },
      },

      scales: {
        ...prev.scales,
        x: {
          ...prev.scales.x,
          ticks: {
            ...prev.scales.x.ticks,
            callback: function (value, index, ticks) {
              return concepts[index - 1]
            },
          },
        },
        y: {
          ...prev.scales.y,
          title: {
            ...prev.scales.y.title,
            text: accuracy
              ? "Accuracy (%)"
              : score
              ? "Score"
              : "Time Taken (seconds)",
          },
        },
      },
    }));
    //console.log("first",datasets)
  };
  useEffect(() => {
    if (subjects.length === 0) {
      setData({ datasets: [] });

      return;
    }
    const curr = subjects.find((item) => item.subject === selectedSubject);
    if (curr === undefined || !curr?.subject) {
      setData({ datasets: [] });
      return;
    }
    if (time) {
      handleTimeChart(curr);
    } else if (accuracy) {
      handleAccuracyChart(curr);
    }
    //  //console.log('Shycurr', curr)
  }, [selectedSubject]);
  const handleAccuracyChart = (current) => {
    console.log(current,'oiunj');
    if (!current.concepts || current?.concepts?.length === 0) return;
    let timeArr = current.concepts;
    let concepts = current?.concepts?.map((it) => it?.conceptName);
    const datasets = [];
    console.log(timeArr,'rfbhrgb');

    timeArr?.map((curr, id) => {
      const x = (id + 1) * 5;
      const y = curr?.accuracy ? (curr?.accuracy * 100)?.toFixed(2) : 0;

      let getValue = curr?.total;

      let radius = Math.round(getValue);
      // //console.log(totalConcept, percent);
      if (radius < 5) {
        radius = 5;
      } else if (radius > 40) {
        radius = 40;
      }

      datasets.push({
        label: concepts[id],
        // data: [{ x, y: percent, r: 20 }],
        data: [{ x, y, r: radius, label: concepts[id] }],
        backgroundColor: getColor(id, timeArr?.length),
      });
    });
    datasets.push({
      label:'',
      // data: [{ x, y: percent, r: 20 }],
      data: [{ x:5*(timeArr?.length+1), y:0, r: 0, label: '' }],
      backgroundColor: '#FFF',
    });
    // console.log("firstss",datasets)

    setData({
      datasets: datasets,
    });
    setchartwidth(`${datasets?.length*237<1540?1540:datasets?.length*237}px`)
    setOptions((prev) => ({
      ...prev,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context, c, d) {
              if (accuracy) {
                return `  ${"Accuracy "} : ${context.parsed.y?.toFixed(2)} %`;
              }
            },
          },
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          padding: 10,
          titleAlign: "right",
        },
        legend: {
          display: false,
        },
      },

      scales: {
        ...prev.scales,
        x: {
          ...prev.scales.x,
          ticks: {
            ...prev.scales.x.ticks,
            callback: function (value, index, ticks) {
              return concepts[index - 1]
            },
          },
        },
        y: {
          ...prev.scales.y,

          max: 100,
          ticks: {
            stepSize: 20, // Adjust as needed
          },
          title: {
            ...prev.scales.y.title,
            text: accuracy
              ? "Accuracy (%)"
              : score
              ? "Score"
              : "Time Taken (seconds)",
          },
        },
      },
    }));
  };
  // console.log("spframe3", {options, data,subjects,selectedSubject });

  return (
    data !== undefined && (
      <div className={`${scroller==true?'canvas-scroller-3':'canvas-scroller-2 pb-[33px]'}  overflow-x-auto ${parentdashboard==true?'w-[1010px]':'w-[1540px]'}`}>
      <div style={{ width: chartwidth }} className={`wrapper canvas-scroller-2 h-[511px] canvasWidthFull min-h-full h ${parentdashboard==true?'pt-[35px]':'min-w-[1540px]'} flex items-center justify-center relative`}>
        <Bubble
          ref={chartRef}
          options={options}
          data={data}
          height={200}
          width={canvasWidth}
        />

        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="87"
          viewBox="0 0 10 87"
          fill="none"
          className="absolute z-10 left-0 ms-12"
        >
          <path
            d="M5.72319 0.808058C5.47911 0.56398 5.08339 0.56398 4.83931 0.808058L0.861829 4.78554C0.617752 5.02961 0.617752 5.42534 0.861829 5.66942C1.10591 5.9135 1.50164 5.9135 1.74571 5.66942L5.28125 2.13388L8.81679 5.66942C9.06086 5.9135 9.45659 5.9135 9.70067 5.66942C9.94475 5.42534 9.94475 5.02961 9.70067 4.78554L5.72319 0.808058ZM4.65625 1.25L4.65625 86.6159H5.90625L5.90625 1.25H4.65625Z"
            fill="#24A3D9"
          />
        </svg> */}
      </div>
      </div>
    )
  );
}
