import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  useCreateIntentMutation,
  useFinishSetupMutation,
  useCreateNewSubscriptionMutation,
} from "../../../app/services/subscription";
import { createSubscriptionItems, fetchAndSetSubscription } from "../../../utils/subscriptionService";
import { triggerToast } from "../../../utils/toastController";
import { updateFetchData } from "../../../app/slices/user";
import { useUpdateOrgDataMutation } from "../../../app/services/admin";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetOrganizationQuery, useLazyGetPersonalDetailQuery } from "../../../app/services/users";

const AddCardDetails = ({ submitted, setIsLoading, subscriptionCoupon,   selectedBasePackageId,
  selectedProducts, productPrices, additionalTutorNos, selectedPlanType, setIsPaymentSuccessfull }) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  // const { stripeCustomerId, _id } = useSelector(
  //   (state) => state?.organization?.organization
  // );
  const [loading, setLoading] = useState(false);
  const [createIntentForPayment, createIntentForPaymentResp] =
    useCreateIntentMutation();
  const [finishSetupForPayment, finishSetupForPaymentResp] =
    useFinishSetupMutation();
  const [isPaymentProcessOnGoing, SetIsPaymentProcessOnGoing] = useState(false);
  const [createNewSubscription, createNewSubscriptionResp] =
    useCreateNewSubscriptionMutation();
  const [updateOrgDetail] = useUpdateOrgDataMutation();
  const [count, setCount] = useState(0)
  const [getUserDetails,] = useLazyGetPersonalDetailQuery()
  const [getOrgDetails, getOrgDetailsResp] = useLazyGetOrganizationQuery();
  useEffect(()=>{
    setCount(count+1)
  },[])

  useEffect(() => {
      const submitForm = async () => {
        await handleSubmit();
      };
      submitForm();
  }, [submitted]);

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    if(count === 0) return;

    SetIsPaymentProcessOnGoing(true);
    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log(submitError);
      SetIsPaymentProcessOnGoing(false);
      setIsLoading(false);

      return;
    }

    //-------Deprecated
    let stripeCustIdFromOrg = "";
    // if no stripe cust Id, fetch org details for stripeCustId

    const userDetails = await getUserDetails();
    if (userDetails.error) {
      triggerToast(userDetails.error.data.message, "error", {
        autoClose: 3000,
      });

      return;
    } else {
      const user = userDetails.data.data.user;
      const orgDetails = await getOrgDetails(user.associatedOrg);
      if (orgDetails.error) {
        triggerToast(orgDetails.error.data.message, "error", {
          autoClose: 3000,
        });

        return;
      } else {
        stripeCustIdFromOrg = orgDetails.data.organisation.stripeCustomerId;
      }
    }

    // Create the SetupIntent and obtain clientSecret
    const res = await createIntentForPayment({
      customer_id: stripeCustIdFromOrg,
    });

    console.log("response from create_intent");
    console.log("SETUP INTENT RESPONSE", res);

    const { client_secret: clientSecret } = res.data;

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://stackblitz-starters-vzyaus.stackblitz.io/payment",
      },
    });

    console.log("setupIntent");
    console.log(setupIntent);

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      triggerToast(
        "Oops! Seems like the card info you added has some issue. Please check and try again.",
        "error",
        { autoClose: 5000 }
      );
      //setIsLoading(false);
      console.log(submitError);
    } else {
      const post = await finishSetupForPayment({
        setupintent: setupIntent?.id,
      });

      if (post) {
        console.log("Setup intent successful");

        updateOrgDetail({
          op: "update",
          orgId:  
            window.localStorage.getItem("orgId") ??
            window.sessionStorage.getItem("orgId"),
          fields: {
            isEnabledPAYG: true,
          },
        }).then((res) => console.log(res));
        //create new subscription

        //Sample request
        // {
        // 	items : [{price : , value : , selected :, quantity : --> Wherever applicable }],
        // 	isFreeTrial : [true, false],
        //  planType : [annual, monthly],
        //	isPAYGEnabled : [true, false],
        //  couponId: "id"
        // }

        let body = {
          items: [],
          isFreeTrial: true,
          planType: selectedPlanType,
          isEnabledPAYG: true,
          couponId: subscriptionCoupon?.couponId,
        };

        console.log(selectedProducts,selectedBasePackageId,productPrices,selectedPlanType,additionalTutorNos)
        body.items = createSubscriptionItems(selectedProducts,selectedBasePackageId,productPrices,selectedPlanType,additionalTutorNos)

        console.log("sub body----", body);

        let subscription = await createNewSubscription(body);
        if (!subscription.error) {
          triggerToast(
            "Success! Redirecting to your Evallo dashboard.",
            "success",
            { autoClose: 3000 }
          );
          await fetchAndSetSubscription();
          dispatch(updateFetchData(true));
          navigate("/");
          setIsPaymentSuccessfull(true)
          setIsLoading(false);
        } else {
          toast.error("Subscription creation failed");
          setIsLoading(false);
        }

        if (!subscription.data.success) {
        } else {
        }
      }
    }
  };
  return (
    <div className="w-full">
      <div>
        <div className="text-[18px] mb-3 text-[#3B476B] font-semibold text-left h-[23px] flex gap-[6px] ">
          Card Details
        </div>
        <PaymentElement />
      </div>
    </div>
  );
};

export default AddCardDetails;
