import styles from './EventTopBar.module.css'
import frozenEventTypes from '../../utils/eventTypes'
import TabSelector from '../TabSelector/TabSelector';
import DesktopEventTopbar from './DesktopEventTopBar';
import MobileEventTopBar from './MobileEventTopBar';
import { getProps } from './helpers';
import { useMemo } from 'react';
const _ = require("lodash")

const externalUserEventsToOmit = ["EXTMEETING", "INTMEETING", "OFFCWORK", "OFFCHRS"]
const internalUsers = ["admin", "tutor"]
const externalUsers = ["student", "parent"]


function EventTopBar({ selectedEventType, setSelectedEventType, operationMode, subscriptionV2, setTrigger, user, organization, setAllowedEventTypes }) {
	const eventKeysToOmit = useMemo(()=>{
		if(externalUsers.includes(user?.role)){
			return [...externalUserEventsToOmit, "EXTMEETING", "OFFCWORK","OFFCHOURS","OFFCWORK"]
		} else if(internalUsers.includes(user?.role)){
			let keysToOmit = ["OFFCHOURS"]
			if(operationMode === "create" || operationMode === "create-scheduled"){
				keysToOmit.push("EXTMEETING")
			}
			//Later move this to CALENDAR V2
			if (["tutor"].includes(user?.role) && organization?.settings && operationMode === "create") {
				const requiredPermissions = organization?.settings.permissions.filter(val => ["tutCreateSession", "tutCreateConsultations"].includes(val.permissionActionName))
				requiredPermissions.forEach(val => {
					if (val.permissionActionName.includes("Session") && val.choosedValue === false) {
						keysToOmit.push("SESSION")
					}
					if (val.permissionActionName.includes("Consultation") && val.choosedValue === false) {
						keysToOmit.push("CONSULTATION")
					}
				})
			}
			setAllowedEventTypes(Object.keys(frozenEventTypes).filter(key => !["EXTMEETING", ...keysToOmit].includes(key)))
			return [...keysToOmit]
		}
	}, [user?.role, organization?.settings, operationMode])

	const mobileTopBarProps = getProps("mobile")(_.omit(frozenEventTypes, eventKeysToOmit), selectedEventType, setSelectedEventType, operationMode, subscriptionV2)
	const desktopTopBarProps = getProps("desktop")(_.omit(frozenEventTypes, eventKeysToOmit), selectedEventType, setSelectedEventType, operationMode, subscriptionV2, setTrigger)

	return (

		<>
			<DesktopEventTopbar {...desktopTopBarProps} />
			<MobileEventTopBar {...mobileTopBarProps} />
		</>

	)

}

export default EventTopBar
