import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useMemo, useRef, useState } from "react"
import frozenEventStatus from "../../utils/eventStatus"
import TripleDotIcon from "../CalendarSideIcons/TripleDotIcon"
import CustomDropdownV2 from "../CustomDropdownV2/CustomDropdownV2"
import styles from "./SessionEditor.module.css"
import SessionTagsSelector from "./SessionTagsSelector/SessionTagsSelector"
import ClockIcon from '../CalendarSideIcons/ClockIcon';
import GreenTickIcon from '../QuestionPopUp/GreenTickIcon';
import ClockIconStatus from '../../assets/calendarV2/ClockIconStatus';
import GreenTickIconStatus from '../../assets/calendarV2/GreenTickIconStatus';
import NoShowIconStatus from '../../assets/calendarV2/NoShowIconStatus';
import CrossIcon from '../../assets/calendarV2/CrossIcon';
import AddNewIcon from '../CalendarSideIcons/AddNewIcon';
import TabSelector from '../TabSelector/TabSelector';
import { Tooltip } from '@mui/material';
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon';
import { useSelector } from 'react-redux';
import CopyIconCal from '../../assets/calendarV2/CopyIconCal';
import { getColourMappingForServices } from '../../utils/serviceColours';
import { toast } from 'react-toastify';
import ServicesSelectionSection from '../ServicesSelectionSection/ServicesSelectionSection';
import NotesEditor from '../NotesEditor/NotesEditor';
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';
import InvoiceDetailsInput from '../InvoiceDetailsInput/InvoiceDetailsInput';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { calculateTimeDifference } from '../../utils/dateUtils';
import TentativeStatusIcon from '../../assets/icons/TentativeStatusIcon';

const iconStyles = { height: "16px", width: "16px" }

const statusIconMapping = {
	SCH: <ClockIconStatus />,
	COMPLETED: <GreenTickIconStatus />,
	NOSHOW: <NoShowIconStatus />,
	CANCEL: <CrossIcon color={"#7C859C"} />,
	TENTATIVE : <TentativeStatusIcon />
}

function StatusSelector({ selectedStatus, setSelectedStatus, user }) {

	console.log("SSStatus", selectedStatus)

	const [isDropdownOpen, setIsDropdownOpen] = useState(false)

	const statusRef = useRef();
	useOutsideAlerter(statusRef, () => setIsDropdownOpen(false));

	//To Enable or disable editing based on the role
	const isEnabledEditing = useMemo(() => {

		return ["admin", "tutor"].includes(user?.role)

	}, [user?.role])

	const dropdownButtonClass = "flex items-center justify-start gap-[6px] font-[lexend] text-[12px] leading-[18px] text-secondary py-[7px] px-[8px] w-full hover:bg-secondary-5 hover:text-secondary"
	const buttonsContent = [
		{
			value: frozenEventStatus.SCH.status,
			content:
				<>
					{statusIconMapping.SCH}
					<span>{frozenEventStatus.SCH.statusDisplayValue}</span>
				</>
		},
		{
			value: frozenEventStatus.TENTATIVE.status,
			content:
				<>
					{statusIconMapping.TENTATIVE}
					<span>{frozenEventStatus.TENTATIVE.statusDisplayValue}</span>
				</>
		},
		{
			value: frozenEventStatus.COMPLETED.status,
			content:
				<>
					{statusIconMapping.COMPLETED}
					<span>{frozenEventStatus.COMPLETED.statusDisplayValue}</span>
				</>
		},
		{
			value: frozenEventStatus.NOSHOW.status,
			content:
				<>
					{statusIconMapping.NOSHOW}
					<span>{frozenEventStatus.NOSHOW.statusDisplayValue}</span>
				</>
		},
		{
			value: frozenEventStatus.CANCEL.status,
			content:
				<>
					{statusIconMapping.CANCEL}
					<span>{frozenEventStatus.CANCEL.statusDisplayValue}</span>
				</>
		},
	]

	useEffect(() => {
	}, [])

	return (
		<div className="flex items-center justify-start gap-[24px]">
			<TripleDotIcon />
			<div ref={statusRef} className="w-[240px] relative flex items-center justify-between  rounded-[6px] font-[lexend] font-[400] text-[14px] leading-[21px] bg-secondary-190">
				<div className=' flex w-full justify-between cursor-pointer px-[12px] py-[7.5px] ' onClick={(e) => setIsDropdownOpen((prev) => !prev)} style={{ pointerEvents: isEnabledEditing ? "" : "none" }}>
					<div className={`${selectedStatus ? "text-secondary" : "text-secondary-60"} flex items-center justify-start gap-[4px]`}>
						{selectedStatus ? <>{statusIconMapping[selectedStatus]}{frozenEventStatus[selectedStatus].statusDisplayValue}</> : "Select Session Status"}
					</div>
					<button style={{ display: isEnabledEditing ? "" : "none" }}>
						{
							isDropdownOpen ? <ExpandLessIcon style={iconStyles} /> : <ExpandMoreIcon style={iconStyles} />
						}
					</button>
				</div>
				{
					isDropdownOpen ?
						<div className='absolute bg-white flex flex-col gap-[4px] items-start justify-start w-full rounded-[6px] shadow-[0px_4px_18px_0px_#11167D1A] py-[4px] top-[110%] left-0'>
							{
								buttonsContent.map((item, idx) => (
									<button className={dropdownButtonClass} onClick={() => { setIsDropdownOpen(false); setSelectedStatus(Object.keys(frozenEventStatus).map((key, idx) => ({ ...frozenEventStatus[key], key: key })).find(val => val.status === item.value).key) }}>{item.content}</button>
								))
							}
						</div>
						: <></>
				}
			</div>
		</div>
	)

}





function InputDetailsOutput({ value, label }) {
	return (
		<div className='flex flex-col gap-[2px] items-start justify-start text-secondary-80 '>
			<h6 className='text-[12px] leading-[18px] font-[500]'>{label}</h6>
			<p className='text-[16px] leading-[24px] font-[500]'>${value}</p>
		</div>
	)
}




function InvoicingDetails({ hourlyCost, setHourlyCost, sessionCost, setSessionCost, selectedStartTime, selectedEndTime }) {

	const [totalRevenue, setTotalRevenue] = useState(0)
	const [totalSessionCost, setTotalSessionCost] = useState(0)

	function calculateSessionCost(startTime, endTime, ratePerHour) {
		const differenceInMinutes = calculateTimeDifference(startTime, endTime);
		const hours = differenceInMinutes / 60;
		const cost = hours * ratePerHour;

		return cost;
	}

	useEffect(() => {
		if (selectedStartTime && selectedEndTime && hourlyCost !== undefined) {
			const totalRevenueCost = calculateSessionCost(selectedStartTime, selectedEndTime, hourlyCost)
			setTotalRevenue(totalRevenueCost)
		}
	}, [selectedEndTime, hourlyCost])

	useEffect(() => {
		if (selectedStartTime && selectedEndTime && sessionCost !== undefined) {
			const totalSessionRevenueCost = calculateSessionCost(selectedStartTime, selectedEndTime, sessionCost)
			setTotalSessionCost(totalSessionRevenueCost)
		}
	}, [selectedEndTime, sessionCost])



	// totalRevenue = useMemo(() => {
	// 	costCalcuator(hourlyCost)
	// }, [hourlyCost])

	return (
		<div className='flex flex-col justify-start items-start gap-[24px]'>
			<div className='flex flex-col justify-start items-start gap-[8px]'>
				<div className='flex flex-row items-start justify-start gap-[6px]'>
					<span className='font-[lexend] text-[14px] font-[500] leading-[21px]'>Invoicing and Payroll</span>
					<Tooltip>
						<span><MoreInfoIcon /></span>
					</Tooltip>
				</div>
				<div className='flex items-center justify-start gap-[16px]'>
					<InvoiceDetailsInput placeholder={"Hourly Price"} value={hourlyCost} setValue={setHourlyCost} />
					<InvoiceDetailsInput placeholder={"Tutor Cost"} value={sessionCost} setValue={setSessionCost} />
				</div>
			</div>
			<div className='flex items-center justify-start gap-[24px]'>
				<InputDetailsOutput value={totalRevenue} label={"Session Revenue"} />
				<div className='min-h-[24px] min-w-[1px] bg-secondary-20' />
				<InputDetailsOutput value={totalSessionCost} label={"Session Cost"} />
			</div>
		</div>
	)
}


function SessionEditor(props) {



	return (
		<div className={styles.container}>
			<StatusSelector {...props} />
			<SessionTagsSelector {...props} />
			<NotesEditor {...props}{...{ selectedEventType : props.selectedEventType}} />
			{
				props.userTypesAllowedToViewInvoiceDetails.includes(props?.user?.role)
				&&
				<>
					<HorizontalDivider />
					<InvoicingDetails {...props} />
				</>
			}
		</div>
	)
}

export default SessionEditor
