import { useEffect, useState } from "react";
import FolderTab from "../../FolderTab/FolderTab";
import PlanSelectionContainer from "../PlanSelectionContainer/PlanSelectionContainer";
import styles from "./SubscriptionFolder.module.css";
import { useLazyGetPricesV2Query } from "../../../app/services/subscription";
import ProductSelectionContainer from "../ProductSelectionContainer/ProductSelectionContainer";
const selectedBgColourFolderTab = "#EBF1F7";
const normalBgColourFolderTab = "white";
import ReviewAndPayContainer from "../ReviewAndPayContainer/ReviewAndPayContainer";

const BlueDot = () => {
  return <div className={styles.blueDot}>&nbsp;</div>;
};

const SubscriptionFolder = ({
  isProductsVisited,
  setIsProductsVisited,
  selectedTab,
  setSelectedTab,
  selectedPlanType,
  setSelectedPlanType,
  tempAdditionalTutors,
  setTempAdditionalTutors,
  selectedPlanId,
  setSelectedPlanId,
  productPrices,
  tempTotalTeamSize,
  setTempTotalTeamSize,
  recommendedBasePackage,
  freeAnnualBasePackageId,
  selectedProducts,
  selectedFreeProducts,
  setSelectedProducts,
  currentPlanId
}) => {
  const handleFolderClick = (e) => {
    const clickedTab = e.target.id;
    setSelectedTab(clickedTab === "products" ? 1 : 0);
    if (clickedTab === "products") {
      setIsProductsVisited(true);
    }
  };

  console.log(selectedPlanType, selectedPlanId, currentPlanId)

  return (
    <div className={styles.container}>
      <div className={styles.comparatorArea}>
        <a href="https://evallo.org/pricing" target="_blank">
          Compare all plans
        </a>
      </div>
      <div className={styles.folderTabs}>
        <FolderTab
          selectedBackgroundColour={selectedBgColourFolderTab}
          normalBackgroundColour={normalBgColourFolderTab}
          isSelected={selectedTab === 0}
          id={"plans"}
          onClick={handleFolderClick}
        >
          <div
            className={
              selectedTab === 0
                ? styles.selectedFolderTabContent
                : styles.normalFolderTabContent
            }
          >
            Plans
          </div>
        </FolderTab>

        <FolderTab
          selectedBackgroundColour={selectedBgColourFolderTab}
          normalBackgroundColour={normalBgColourFolderTab}
          isSelected={selectedTab === 1}
          id={"products"}
          onClick={handleFolderClick}
        >
          <div
            className={
              selectedTab === 1
                ? styles.selectedFolderTabContent
                : styles.normalFolderTabContent
            }
          >
            Products
            {!isProductsVisited ? <BlueDot /> : <></>}
          </div>
        </FolderTab>
      </div>
      <div className={selectedTab === 0 ? styles.folderContainerPlans : styles.folderContainerProducts}>
        {/** Plan container & products container go here */}
        {selectedTab === 0 ? (
          <PlanSelectionContainer
            {...{
              productPrices: productPrices,
              selectedPlanType,
              selectedPlanType,
              tempAdditionalTutors,
              setTempAdditionalTutors,
              selectedPlanId,
              setSelectedPlanId,
              tempTotalTeamSize,
              setTempTotalTeamSize,
              recommendedBasePackage,
              currentPlanId
            }}
          />
        ) : (
          <ProductSelectionContainer
            productPrices={productPrices}
            selectedPlanType={selectedPlanType}
            selectedPlanId={selectedPlanId}
            freeAnnualBasePackageId={freeAnnualBasePackageId}
            selectedProducts={selectedProducts}
            selectedFreeProducts={selectedFreeProducts}
            setSelectedProducts={setSelectedProducts}
          />
          // <ReviewAndPayContainer />
        )}
      </div>
    </div>
  );
};

export default SubscriptionFolder;
