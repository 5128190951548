import { useEffect, useState } from "react"

const delta = (num1, num2) => {
    return Math.abs(num1 - num2)
}

const HelperBox = ({ count, selectedCount, onClick,helperButtonClassName, selectedHelperButtonClassName,id }) => {
    return (
        <button className={`${count === selectedCount ? selectedHelperButtonClassName : helperButtonClassName}`} id={id} onClick={(e) => onClick(e)}>{count < 10 ? "0" + count : count}</button>
    )
}

function buttonGenerator(limit,helperButtonClassName,selectedHelperButtonClassName, selectedPage, handleHoverButtonClick){
	
	const buttons = []
	for(let i=0;i<limit;i++){
		buttons.push(
			<HelperBox count={i+1} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} id={`hoverButton_${i+1}`} onClick={(e)=>handleHoverButtonClick(e)}/>

		)
	}
	return buttons

}

/**
 * 
 * @param {{totalDataCount,pageSize,{content,className},{content,className},helperButtonClassName,selectedHelperButtonClassName,selectedPage,setSelectedPage}} param0 
 * @returns ReactFragment
 */
const PaginationV2 = ({ totalDataCount, pageSize, previousButton, nextButton, helperButtonClassName, selectedHelperButtonClassName,selectedPage, setSelectedPage, styleForContainer }) => {

    const [pageCount, setPageCount] = useState(0)
    const [_selectedPage, _setSelectedPage] = useState(selectedPage ?? 1)

    useEffect(() => {
        setPageCount(Math.ceil(totalDataCount / pageSize))
    }, [totalDataCount])

    useEffect(()=>{
        if(setSelectedPage)
            setSelectedPage(_selectedPage);
    },[_selectedPage])

    useEffect(()=>{
        if(selectedPage !== _selectedPage){
            _setSelectedPage(selectedPage)
        }
    },[selectedPage])

    const handleHoverButtonClick = (e) => {
        console.log(e.target.id)
        const pageToBeSelected = parseInt(e.target.id.split("_").pop())
        console.log(pageToBeSelected)
        _setSelectedPage(pageToBeSelected)
    }


    if (pageCount <= pageSize) {
        let tempArray = (new Array(pageCount)).fill(0)
        tempArray = tempArray.map((val, idx) => { return idx + 1 })
        console.log(tempArray)
        return (
            <>
                <div className="flex items-center justify-center gap-[12px]">
                    <button className={`${previousButton?.className}`} onClick={(e)=>_setSelectedPage(_selectedPage-1)} disabled={_selectedPage === 1}>{previousButton?.content ?? "Prev"}</button>
                    {
                    tempArray.map((val, idx) => (
                        <HelperBox count={val} onClick={(e)=>handleHoverButtonClick(e)} id={`hoverButton_${val}`} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}}/>
                    ))
                    }
                    <button className={`${nextButton?.className}`} onClick={(e)=>_setSelectedPage(_selectedPage+1)} disabled={_selectedPage === pageCount}>{nextButton?.content ?? "Next"}</button>
                </div>
            </>
        )

    } else if(pageCount <= 4){
	return(	<div className="flex items-center justify-center gap-[12px]" style={styleForContainer}>

			<button className={`${previousButton?.className}`} onClick={(e)=>_setSelectedPage(_selectedPage-1)} disabled={_selectedPage === 1}>{previousButton?.content ?? "Prev"}</button>
				{
					buttonGenerator(pageCount,helperButtonClassName,selectedHelperButtonClassName, selectedPage, handleHoverButtonClick).map((val)=>val)
				}
			<button className={`${nextButton?.className}`} onClick={(e)=>_setSelectedPage(_selectedPage+1)} disabled={_selectedPage === pageCount}>{nextButton?.content ?? "Next"}</button>
		</div>)
	} else {
        return (
            <>
                <div className="flex items-center justify-center gap-[12px]" style={styleForContainer}>

                    <button className={`${previousButton?.className}`} onClick={(e)=>_setSelectedPage(_selectedPage-1)} disabled={_selectedPage === 1}>{previousButton?.content ?? "Prev"}</button>

                    <HelperBox count={1} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} id={"hoverButton_1"} onClick={(e)=>handleHoverButtonClick(e)}/>
                    {
                        (delta(1, _selectedPage) > 2) && (pageCount > pageSize) ? <HelperBox id={"elispsis_1"} count={"..."} onClick={(e) => _setSelectedPage(_selectedPage - 2)} helperButtonClassName={helperButtonClassName} /> : <></>
                    }
                    {
                        pageCount === _selectedPage ? <HelperBox count={_selectedPage - 2} id={`hoverButton_${_selectedPage - 2}`}{...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} onClick={(e)=>handleHoverButtonClick(e)}/> : <></>
                    }
                    {
                        (delta(_selectedPage, 1)) > 1 ? <HelperBox count={_selectedPage - 1} id={`hoverButton_${_selectedPage - 1}`} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} onClick={(e)=>handleHoverButtonClick(e)}/> : <> <HelperBox count={2} id={`hoverButton_${2}`}{...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} onClick={(e)=>handleHoverButtonClick(e)}/></>
                    }
                    {   (delta(_selectedPage, 1) >= 2) && _selectedPage !== pageCount ? <HelperBox count={_selectedPage} id={`hoverButton_${_selectedPage}`} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} onClick={(e)=>handleHoverButtonClick(e)}/> : <></>}

                    {
                        delta(pageCount, _selectedPage) > 1 ? _selectedPage === 1 ? <HelperBox count={3} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} id={`hoverButton_${3}`} onClick={(e)=>handleHoverButtonClick(e)}/> : _selectedPage === pageCount ? <HelperBox count={pageCount - 1} id={`hoverButton_${pageCount-1}`} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} onClick={(e)=>handleHoverButtonClick(e)}/> : <><HelperBox count={_selectedPage + 1} id={`hoverButton_${_selectedPage + 1}`} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} onClick={(e)=>handleHoverButtonClick(e)}/> </> : <></>
                    }

                    {
                        (delta(pageCount, _selectedPage) > 2) && (pageCount > pageSize) ? <HelperBox id={"elipsis_2"} count={"..."} onClick={(e) => _setSelectedPage(_selectedPage + 2)} helperButtonClassName={helperButtonClassName}/> : <></>
                    }

                    <HelperBox count={pageCount} {...{helperButtonClassName,selectedHelperButtonClassName,selectedCount : selectedPage}} id={`hoverButton_${pageCount}`} onClick={(e)=>handleHoverButtonClick(e)}/>
                    <button className={`${nextButton?.className}`} onClick={(e)=>_setSelectedPage(_selectedPage+1)} disabled={_selectedPage === pageCount}>{nextButton?.content ?? "Next"}</button>
                </div>
            </>

        )
    }


}


export default PaginationV2
