import { useEffect, useState } from 'react';
import styles from './LeadStatus.module.css'
import { displayName } from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../../../../Tooltip/Tooltip';
import InfoIcon from '../../../../ProductIcons/InfoIcon';
import ToggleButtonV2 from '../../../../ToggleButtonV2/ToggleButtonV2';
import EditIcon from '../../../../EditIcon/EditIcon';
import CrossIcon from '../../../../CrossIcon/CrossIcon';
import { useUpdateOrgDataMutation } from '../../../../../app/services/admin';
import { triggerToast } from '../../../../../utils/toastController';
import { useLazyGetOrganizationQuery } from '../../../../../app/services/users';
import { updateOrganization } from '../../../../../app/slices/organization';
import LoaderIcon from '../../../../LoaderIcon/LoaderIcon';
import ButtonV2 from '../../../../ButtonV2/ButtonV2';
import AddIcon from '@mui/icons-material/Add';
import PillContainer from '../../../../PillContainer/PillContainer';
import LockIcon from '../../../../../assets/Settings/LockIcon';
import LockedStateOverlay from '../LockedStateOverlay/LockedStateOverlay';
import DropdownV2 from '../../../../DropdownV2/DropdownV2';
import StatusEditPopUp from '../StatusEditPopup/StatusEditPopup';
import { getTooltipStyleClass } from '../../../../../utils/commonlyUsedTooltipStyles';
import TooltipIcon from '../../../../../assets/icons/SettingsIcon/TooltipIcon';

const dummyLeadStatus = [
    {
        displayTitle: "Sample 1"
    },
    {
        displayTitle: "Sample 2"
    },
    {
        displayTitle: "Sample 3"
    }
]


const getStatusOptionsArray = (leadStatus, superAdminLeadStatus) => {

    let tempArr = ["None", ...leadStatus]
    if(superAdminLeadStatus.isEnabled){
        tempArr = tempArr.concat(superAdminLeadStatus.items)
    }
    return tempArr
}


const LeadStatus = (props) => {

    const dispatch = useDispatch()
    const { leadStatus, superAdminLeadStatus, defaultLeadStatus } = useSelector((state) => state.organization.organization.settings)
    const { organization } = useSelector((state) => state.organization)
    const { subscriptionV2 } = useSelector((state) => state.subscription)
    const [updateOrgRequest,] = useUpdateOrgDataMutation()
    const [fetchOrganization,] = useLazyGetOrganizationQuery()
    const [isLoading, setIsLoading] = useState(false)

    //Popup triggers
    const [editLeadStatusPopUpTrigger, setEditLeadStatusPopUpTrigger] = useState(false)

    //Make this a utils function
    function fetchOrgDetails(setIsLoading) {
        fetchOrganization(organization._id)
            .then((res) => {
                if (!res.error) {
                    dispatch(updateOrganization(res.data.organisation))
                }
                if (setIsLoading) {
                    setIsLoading(false)
                }
            })
    }


    const toggleLeadStatusAvailability = (isSuperAdminLeadStatusEnabled) => {
        setIsLoading(true)
        const toggleReqBody = {
            op: "update",
            orgId: organization._id,
            fields: {
                "settings.superAdminLeadStatus.isEnabled": isSuperAdminLeadStatusEnabled
            }
        }
        updateOrgRequest(toggleReqBody)
            .then((res) => {
                if (res.error) {
                    triggerToast("Oops an error occured", "error", { autoClose: 3000 })
                    setIsLoading(false)
                } else {
                    fetchOrgDetails(setIsLoading)

                }
            })
    }


    const setDefaultLeadStatus = (leadStatusItem) => {
        updateOrgRequest({
            op: "update",
            orgId: organization._id,
            fields: {
                "settings.defaultLeadStatus.externalUser": leadStatusItem.displayTitle
            }
        })
            .then((res) => {
                if (!res.error) {
                    fetchOrgDetails()
                }
            })
    }


    return (
        <div className={styles.container}>
            <div className={styles.row1}>
                <h3>Lead Statuses</h3>
            </div>
            <div className={styles.row2col1}>
                <p>Select the default status to be assigned to new parents/students who join.</p>
            </div>
            <div className={styles.row2col2}>
                <DropdownV2
                    dropdownMenuItems={leadStatus && superAdminLeadStatus && getStatusOptionsArray(leadStatus, superAdminLeadStatus).map((val) => ({ displayTitle: val }))}
                    onDropdownItemClick={setDefaultLeadStatus}
                    defaultValue={defaultLeadStatus?.externalUser ? defaultLeadStatus.externalUser : null}
                    checkIsSelected={(item) => {
                        if (defaultLeadStatus && defaultLeadStatus.externalUser) {
                            if (item.displayTitle === defaultLeadStatus.externalUser) {
                                return true
                            }
                        }
                    }}
                />
            </div>


            <div className={styles.row3}>
                <div className={styles.verticalDivider} />
                <div className={styles.heading1}>
                    <h4>Default Available</h4>
                    <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"Lead statuses allow you to manage your customer pipeline and track their relationship with your business. We have provided a few default lead statuses that you might find useful to get started with. You can enable or disable them using the toggle. Note that if these statuses are turned off, they will stop reflecting in the CRM and on parent/student profiles."} tooltipStyles={getTooltipStyleClass({width : "320px"})}/>
                    <div className={styles.action}>
                        {
                            isLoading ? <LoaderIcon /> :
                                <ToggleButtonV2 isEnabled={superAdminLeadStatus?.isEnabled} setIsEnabled={(isSuperAdminLeadStatusEnabled) => toggleLeadStatusAvailability(isSuperAdminLeadStatusEnabled)} />
                        }
                    </div>
                </div>

                <div className={styles.heading2}>
                    {
                        subscriptionV2?.subscription?.settings?.customLeadStatus.isEnabled === false ?

                            <Tooltip
                                anchorComponent={<LockIcon width={"16px"} height={"16px"} />}
                                tooltipContent={"Upgrade your plan to access this feature."}
                                tooltipStyleClass={"w-[180px] max-w-[320px] bg-white rounded-[12px] shadow-[0px_4px_24px_0px_#11167D26] p-3 flex flex-col items-start gap-[6px] text-secondary-80"}
                            />

                            : <></>
                    }
                    <h4>Customizable Statuses</h4>
                    <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"Lead statuses allow you to manage your customer pipeline and track their relationship with your business. Depending on your susbcription plan, you can create your own lead statuses and customize the pipeline management. Note that if these statuses are deleted, they will stop reflecting in the CRM and on parent/student profiles."} tooltipStyles={getTooltipStyleClass({width : "220px"})}/>
                    {
                        subscriptionV2?.subscription?.settings?.customLeadStatus.isEnabled === true ?
                            <div className={styles.action}><button onClick={(e) => setEditLeadStatusPopUpTrigger(true)}><EditIcon /></button></div>
                            : <></>
                    }

                </div>

                <div className={styles.container1}>
                    <PillContainer items={superAdminLeadStatus?.items?.map((val) => ({ displayTitle: val }))} />
                </div>
                <div className={styles.container2}>
                    <PillContainer items={leadStatus?.length > 0 ? leadStatus?.map((val) => ({ displayTitle: val })) : dummyLeadStatus} >
                        <ButtonV2 className={styles.addNewButtonStyle} onClick={(e) => setEditLeadStatusPopUpTrigger(true)}><AddIcon style={{ height: "16px", width: "16px" }} /> Add New</ButtonV2>
                    </PillContainer>
                    {
                        subscriptionV2?.subscription?.settings?.customLeadStatus.isEnabled === false ?
                            <LockedStateOverlay />
                            : <></>
                    }
                </div>
            </div>
            {
                editLeadStatusPopUpTrigger ? <StatusEditPopUp fetchOrgDetails={fetchOrgDetails} status={leadStatus} trigger={editLeadStatusPopUpTrigger} setTrigger={setEditLeadStatusPopUpTrigger} toEdit={"lead-status"} /> : <></>
            }

        </div>
    )
}

export default LeadStatus