import React from 'react'

const DashboardIcon = ({ type }) => {
    return type !== "active" ? (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Dashboard 1" clip-path="url(#clip0_9896_39385)">
                <g id="Group">
                    <path id="Vector" d="M8.22217 0.498047H1.5555C0.69775 0.498047 0 1.1958 0 2.05355V6.05355C0 6.91146 0.69775 7.60921 1.5555 7.60921H8.22217C9.08008 7.60921 9.77783 6.91146 9.77783 6.05355V2.05355C9.77783 1.1958 9.08008 0.498047 8.22217 0.498047ZM8.22217 9.38688H1.5555C0.69775 9.38688 0 10.0846 0 10.9425V20.2759C0 21.1336 0.69775 21.8314 1.5555 21.8314H8.22217C9.08008 21.8314 9.77783 21.1336 9.77783 20.2759V10.9425C9.77783 10.0846 9.08008 9.38688 8.22217 9.38688ZM19.7778 14.7202H13.1112C12.2533 14.7202 11.5555 15.418 11.5555 16.2759V20.2759C11.5555 21.1336 12.2533 21.8314 13.1112 21.8314H19.7778C20.6356 21.8314 21.3333 21.1336 21.3333 20.2759V16.2759C21.3333 15.418 20.6356 14.7202 19.7778 14.7202ZM19.7778 0.498047H13.1112C12.2533 0.498047 11.5555 1.1958 11.5555 2.05355V11.3869C11.5555 12.2448 12.2533 12.9425 13.1112 12.9425H19.7778C20.6356 12.9425 21.3333 12.2448 21.3333 11.3869V2.05355C21.3333 1.1958 20.6356 0.498047 19.7778 0.498047Z" fill="white" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_9896_39385">
                    <rect width="21.3333" height="21.3333" fill="white" transform="translate(0 0.498047)" />
                </clipPath>
            </defs>
        </svg>

    ) :
        (
            <svg width="26" height="26" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Dashboard 1" clip-path="url(#clip0_9896_39385)">
                    <g id="Group">
                        <path id="Vector" d="M8.22217 0.498047H1.5555C0.69775 0.498047 0 1.1958 0 2.05355V6.05355C0 6.91146 0.69775 7.60921 1.5555 7.60921H8.22217C9.08008 7.60921 9.77783 6.91146 9.77783 6.05355V2.05355C9.77783 1.1958 9.08008 0.498047 8.22217 0.498047ZM8.22217 9.38688H1.5555C0.69775 9.38688 0 10.0846 0 10.9425V20.2759C0 21.1336 0.69775 21.8314 1.5555 21.8314H8.22217C9.08008 21.8314 9.77783 21.1336 9.77783 20.2759V10.9425C9.77783 10.0846 9.08008 9.38688 8.22217 9.38688ZM19.7778 14.7202H13.1112C12.2533 14.7202 11.5555 15.418 11.5555 16.2759V20.2759C11.5555 21.1336 12.2533 21.8314 13.1112 21.8314H19.7778C20.6356 21.8314 21.3333 21.1336 21.3333 20.2759V16.2759C21.3333 15.418 20.6356 14.7202 19.7778 14.7202ZM19.7778 0.498047H13.1112C12.2533 0.498047 11.5555 1.1958 11.5555 2.05355V11.3869C11.5555 12.2448 12.2533 12.9425 13.1112 12.9425H19.7778C20.6356 12.9425 21.3333 12.2448 21.3333 11.3869V2.05355C21.3333 1.1958 20.6356 0.498047 19.7778 0.498047Z" fill="white" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_9896_39385">
                        <rect width="21.3333" height="21.3333" fill="white" transform="translate(0 0.498047)" />
                    </clipPath>
                </defs>
            </svg>

        )
}

export default DashboardIcon