import React, { useState, useEffect } from "react";
import { Tooltip as MUITooltip} from "@mui/material";
import CRM from "../../../ManageSubscription/assets/CRM.svg";
import Assignments from "../../../ManageSubscription/assets/Assignments.svg";
import Calendar from "../../../ManageSubscription/assets/Calendar.svg";
import CustomContent from "../../../ManageSubscription/assets/CustomContent.svg";
import { useLazySubscriptionInsightsQuery } from "../../../../app/services/subscription";
import InsightCard from "./InsightCard";
import { useSelector, useDispatch } from "react-redux";
import SubscriptionTable from "../../../../components/SubscriptionTable/SubscriptionTable";
import ToggleButtonV2 from "../../../../components/ToggleButtonV2/ToggleButtonV2";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../../components/InfoIcon/InfoIcon";
import { useUpdateOrgDataMutation } from "../../../../app/services/admin";
import { updateOrganization } from "../../../../app/slices/organization";
import { configurePAYGTableData } from "../../../ManageSubscription/Utils/configureTableData";
import DisablePayGPopUp from "./DisablePayGPopUp";
import { triggerToast } from "../../../../utils/toastController";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import ResponsiveSubscriptionTable from "../../../../components/SubscriptionTable/ResponsiveSubscriptionTable";
import { getTooltipStyleClass } from "../../../../utils/commonlyUsedTooltipStyles";

const productInsights = {
  CRM: {
    insights: {
      remainingFromLimit: null,
      limit: null,
      heading: "Unlimited benefits",
      percentage: null,
    },
  },
  Calendar: {
    insights: {
      remainingFromLimit: null,
      limit: null,
      heading: "sessions remaining",
      percentage: null,
    },
  },
  Assignments: {
    insights: {
      remainingFromLimit: null,
      limit: null,
      heading: "credits remaining",
      percentage: null,
    },
  },
  Custom: {
    insights: {
      remainingFromLimit: null,
      limit: null,
      heading: "tests remaining",
      percentage: null,
    },
  },
};

const products = ["CRM", "Calendar", "Assignments", "Custom"];

function calculatePercentage(remainingFromLimit, limit) {
  if (limit === 0) {
    return 0;
  }
  if (remainingFromLimit === 0) {
    return 0;
  }
  const remainingPercentage = (remainingFromLimit / limit) * 100;
  return Math.max(0, Math.min(100, remainingPercentage));
}

const formatDate = (mongoDBDate, formatType = 1) => {
  const dateObj = new Date(mongoDBDate);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthIndex = dateObj.getMonth();
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const dayIndex = dateObj.getDay();

  if (formatType === 1) {
    return `${monthNames[monthIndex]} ${day}, ${year}`;
  } else if (formatType === 2) {
    return `${dayNames[dayIndex]}, ${monthNames[monthIndex]} ${day}, ${year}`;
  } else {
    return "Invalid format type";
  }
};

const UsageMetrics = () => {
  const dispatch = useDispatch();
  const { isEnabledPAYG, _id: orgId } = useSelector(
    (state) => state.organization.organization
  );
  const { subscriptionV2 } = useSelector((state) => state.subscription);
  const [_isEnabledPAYG, _setIsEnabledPAYG] = useState(isEnabledPAYG);
  const [paygTableData, setPaygTableData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [insightData, setInsightData] = useState(productInsights);
  const [updateOrgData] = useUpdateOrgDataMutation();
  const [getSubscriptionInsights, respGetSubscriptionInsights] =
    useLazySubscriptionInsightsQuery();
  const [openDisablePaygPopup, setOpenDisablePaygPopup] = useState(false);
  const [isToggleTriggered, setIsToggleTriggered] = useState(false);
  const [planType, setPlanType] = useState(
    subscriptionV2?.subscription?.__t === "MonthlySubscription" ? 0 : 1
  );
  const [isLoading, setIsLoading] = useState(true);

  const getInsights = async () => {
    try {
      const res2 = await getSubscriptionInsights();
      if (!res2.error) {
        console.log(res2.data.data);
        setSubscriptionData(res2.data.data);
		if(!subscriptionV2?.subscription.__t === "FreeSubscription"){
			setPaygTableData(
			  configurePAYGTableData(
				res2.data.data.usage,
				res2.data.data.usageRecords
			  )
			);

		}
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInsights();
  }, []);

  useEffect(() => {
    if (!subscriptionData) return;

    const updatedInsightsData = Object.entries(productInsights).map(
      ([key, value]) => {
        if (key === "Calendar") {
          return {
            [key]: {
              ...value,
              insights: {
                name: "Calendar",
                tooltipContent: `You have chosen ${
                  subscriptionData?.limits.sessions.base === -1
                    ? "Unlimited"
                    : subscriptionData?.limits.sessions.base
                } Calendar sessions to be included with your subscription.`,
                remainingFromLimit:
                  subscriptionData?.limits.sessions.base -
                  subscriptionData?.usage.sessions.base,
                limit: subscriptionData?.limits.sessions.base,
                heading: "Sessions remaining",
                percentage: calculatePercentage(
                  subscriptionData?.limits.sessions.base -
                    subscriptionData?.usage.sessions.base,
                  subscriptionData?.limits.sessions.base
                ),
                img: Calendar,
                color: "#EE778C",
              },
            },
          };
        } else if (key === "Assignments") {
          return {
            [key]: {
              ...value,
              insights: {
                name: "Assignments",
                tooltipContent: `You have chosen ${subscriptionData?.limits.assignments.base} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.`,
                remainingFromLimit:
                  subscriptionData?.limits.assignments.base -
                  subscriptionData?.usage.assignments.base,
                limit: subscriptionData?.limits.assignments.base,
                heading: "Tests remaining",
                percentage: calculatePercentage(
                  subscriptionData?.limits.assignments.base -
                    subscriptionData?.usage.assignments.base,
                  subscriptionData?.limits.assignments.base
                ),
                img: Assignments,
                color: "#8D54D7",
              },
            },
          };
        } else if (key === "Custom") {
          return {
            [key]: {
              ...value,
              insights: {
                name: "Custom Tests",
                tooltipContent: `Refers to custom tests and material added by you in the "Content" tab to be used exclusively for your account.`,
                remainingFromLimit:
                  subscriptionData?.limits.customContent.base -
                  subscriptionData?.usage.customContent.base,
                limit: subscriptionData?.limits.customContent.base,
                heading: "Content remaining",
                percentage: calculatePercentage(
                  subscriptionData?.limits.customContent.base -
                    subscriptionData?.usage.customContent.base,
                  subscriptionData?.limits.customContent.base
                ),
                img: CustomContent,
                color: "#17A289",
              },
            },
          };
        } else if (key === "CRM") {
          return {
            [key]: {
              ...value,
              insights: {
                name: "CRM",
                tooltipContent:
                  "Includes unlimited student and parent accounts, along with students groups as per the package chosen. Other features include advanced filters, bulk-user management, data export, profiles, notes, and tutor mapping.",
                img: CRM,
                color: "#2793cd",
                remainingFromLimit: null,
                limit: -1,
                heading: "Unlimited benefits",
                percentage: 100,
                students: subscriptionData?.metrics.CRM?.activeStudents,
                groups: subscriptionData?.metrics.CRM?.studentGroups?.usage,
              },
            },
          };
        }
      }
    );

    setInsightData(Object.assign({}, ...updatedInsightsData));
  }, [subscriptionData]);

  //Update PAYG in the subscription
  useEffect(() => {
    if (!isToggleTriggered) return;

    updateOrgData({
      op: "update",
      orgId: orgId,
      fields: {
        isEnabledPAYG: _isEnabledPAYG,
      },
    }).then((res) => {
      if (res.error) {
        toast.error("Update failed");
        console.log(res.error);
      } else {
        dispatch(updateOrganization(res.data.data.org));
        if (_isEnabledPAYG) {
          triggerToast(
            "Pay As You Go is now enabled for your subscription!",
            "success",
            { autoClose: 3000 }
          );
        } else {
          triggerToast(
            "Pay As You Go is now disabled for your subscription!",
            "error",
            { autoClose: 3000 }
          );
        }
      }
    });
  }, [_isEnabledPAYG]);

  return (
    <div className="grid gap-[24px]">
      {isLoading ? (
        <div className="flex justify-center items-center h-[40vh]">
          <LoaderIcon />
        </div>
      ) : (
        <>
          <div className="text-secondary-80 text-[16px] font-[400]">
            Click on ‘Manage Subscription’ to view or edit product limits.
          </div>
          <div className="grid md:flex gap-[16px] md:flex-wrap w-[100%]">
            {products?.map((product) => (
              <div className="w-full md:w-[30%]" key={product}>
                {insightData[product]?.insights.limit && (
                  <InsightCard
                    insights={
                      insightData[product]
                        ? insightData[product]?.insights
                        : null
                    }
                    price={Array.isArray(subscriptionV2?.siMapping) ? subscriptionV2?.siMapping?.map((item) =>
                      item.lookupKey.includes(product) &&
                      !item.lookupKey.includes("PAYG")
                        ? item.price.toFixed(0)
                        : null
                    ) : ["0"]}
                    studentGroupLimit={
                      subscriptionV2?.subscription.studentGroups.limit
                    }
                    selectedPlanType={planType}
                  />
                )}
              </div>
            ))}
          </div>
          {subscriptionData?.currentPeriod?.start && (
            <div className="text-secondary-60 text-[14px] font-[500] italic">
              All usage metrics are for the current billing cycle:{" "}
              {formatDate(subscriptionData?.currentPeriod?.start, 1)} to
              present.
            </div>
          )}
          {(subscriptionV2?.subscription.__t === "MonthlySubscription" ||
            subscriptionV2?.subscription.__t === "AnnualSubscription") &&
          !subscriptionV2?.subscription?.basePackage.name.includes("Free") ? (
            <div className="flex flex-col gap-[2.25rem] justify-start items-start w-full">
              <div className="w-full flex justify-between items-center">
                <div className={` flex items-center gap-[8px] justify-start`}>
                  <>Pay As You Go</>
				  <MUITooltip
						title={<div style={getTooltipStyleClass()}>
										PAYG starts after you have exhausted your billing period
										usage limits for various products offered by Evallo,
										such as Assignment Credits or Calendar Sessions. <br />{" "}
										Each product has its own PAYG pricing, and it will only
										be activated once you cross the threshold set by your
										package.
							   </div>}
						componentsProps={{tooltip : {sx : {bgcolor : "transparent"}}}}
					>
					<div>
					  <InfoIcon className="text-secondary-60 w-[16px] h-[16px]" />
					</div>
				  </MUITooltip>
                </div>
                <ToggleButtonV2
                  customContainer={`h-[24px] w-[40px] rounded-full p-[2px]`}
                  customSwitch={`h-[20px] w-[20px]`}
                  isEnabled={isEnabledPAYG}
                  setIsEnabled={
                    !isEnabledPAYG
                      ? () => {
                          _setIsEnabledPAYG(true);
                          setIsToggleTriggered(true);
                        }
                      : () => {
                          setOpenDisablePaygPopup(true);
                          setIsToggleTriggered(true);
                        }
                  }
                />
              </div>
              {paygTableData &&
              (subscriptionV2?.subscription.__t === "MonthlySubscription" ||
                subscriptionV2?.subscription.__t === "AnnualSubscription") &&
              !subscriptionV2?.subscription?.basePackage.name.includes(
                "Free"
              ) ? (
                <>
                  <div className="hidden md:block md:w-full">
                    <SubscriptionTable {...paygTableData} />
                  </div>
                  <div className="w-full  md:hidden">
                    <ResponsiveSubscriptionTable {...paygTableData} />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {openDisablePaygPopup && (
            <DisablePayGPopUp
              _setIsEnabledPAYG={_setIsEnabledPAYG}
              setOpen={(val) => {
                setOpenDisablePaygPopup(val);
              }}
              open={openDisablePaygPopup}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UsageMetrics;
