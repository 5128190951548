import LeadStatus from './Components/LeadStatus/LeadStatus'
import TutorStatus from './Components/TutorStatus/TutorStatus'
import styles from './UserManagement.module.css'


const UserManagement = () => {
    return (
        <div className={styles.container}>
            <LeadStatus />
            <TutorStatus />
        </div>
    )
}

export default UserManagement