import React, { useState } from "react";

const EventAction = ({ response, setResponse }) => {


  return (
    <div className="grid md:flex items-center text-secondary text-[16px] gap-[12px] w-full md:w-fit">
      <div>Confirm Invitation</div>
      <div className="flex items-center justify-start gap-[6px]">
        <div
          className={`${response === "yes" ? "bg-success text-white" : "bg-secondary-10"
            } px-[12px] py-[2px] rounded-[6px] cursor-pointer`}
          onClick={() => {
            setResponse("yes");
          }}
        >
          Yes
        </div>
        <div
          className={`${response === "no" ? "bg-success text-white" : "bg-secondary-10"
            } px-[12px] py-[2px] rounded-[6px] cursor-pointer`}
          onClick={() => {
            setResponse("no");
          }}
        >
          No
        </div>
        <div
          className={`${response === "maybe" ? "bg-success text-white" : "bg-secondary-10"
            } px-[12px] py-[2px] rounded-[6px] cursor-pointer`}
          onClick={() => {
            setResponse("maybe");
          }}
        >
          Maybe
        </div>
      </div>
    </div>
  );
};

export default EventAction;
