import styles from './CustomNumberInputWithButtons.module.css'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const CustomNumberInputWithButtons = ({defaultValue, setOutsideValue, leftButtonStyleClass, rightButtonStyleClass, containerStyleClass, inputFieldStyleClass, handleButtonClicks, handleDirectChange}) => {

    const handleButtonClick = (e) => {
        handleButtonClicks(e.target.id)
    }

    const handleOnChange = (e) => {
        e.preventDefault()
        if(e.target.value){
            const value = parseInt(e.target.value)
            if(value >= 1 && value <=999){
                handleDirectChange(value)
            }
        }
        
    }

    return(
        <div className={`${containerStyleClass ?? styles.container}`} key={defaultValue}>
            <button id="decr" onClick={(e)=>handleButtonClick(e)} className={`${leftButtonStyleClass}`}><RemoveIcon style={{width : "12px", height: "12px", pointerEvents : "none"}}/></button>
            <input type="number" defaultValue={defaultValue} onChange={(e)=>handleOnChange(e)} className={`${inputFieldStyleClass}`} />
            <button id="incr" onClick={(e)=>handleButtonClick(e)} className={`${rightButtonStyleClass}`}><AddIcon style={{width : "12px", height: "12px", pointerEvents : "none"}} /></button>
        </div>
    )
}


export default CustomNumberInputWithButtons