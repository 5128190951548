import CrossIcon from "../CrossIcon/CrossIcon"
import PopupContainer from "../PopupContainer/PopupContainer"

function DeclineRequestPopUp({trigger, setTrigger, handleDeclineClick}){
	
	return(
		<PopupContainer {...{trigger, setTrigger}} customCloseButton={true}>

			<div className='w-full md:w-[640px] h-fit bg-white flex flex-col gap-[36px] '>

				{/* Top navbar */}
				<div  className='w-full  flex justify-between items-center pb-[10px] border-b-[1px] border-b-[#45A0FF]' >
					<p  className='  text-[24px] leading-[36px] text-[#00468E] font-medium '>Decline Request</p>
					<div className='cursor-pointer' onClick={() => setTrigger(false)}>
						<CrossIcon />
					</div>
				</div>
				<div className="w-full flex flex-col items-center justify-start gap-[12px]">
					<h3 className="text-secondary-80 font-[500] text-[20px] leading-[30px] items-center">Are you sure you want to decline this event request?</h3>
					<p className="text-secondary-70 font-[400] text-[16px] leading-[24px] text-center">Note: This action cannot be reversed. An email will be sent to the requestor.</p>
				</div>
				<div className="w-full flex items-center justify-center gap-[12px]">
					<button className="py-[14px] px-[67px] bg-white border-secondary-60 border-[2px] border-[solid] text-secondary-60 rounded-[6px] font-[600] leading-[20px] text-[16px]" onClick={()=>{setTrigger(false)}}>Back</button>
					<button className="py-[14px] px-[39px] bg-[#F97066] rounded-[6px] text-white font-[600] text-[16px] leading-[20px]"onClick={handleDeclineClick}>Yes, Decline</button>
				</div>
			</div>
		</PopupContainer>
	)

}


export default DeclineRequestPopUp
