import { useSelector } from 'react-redux'
import styles from './BreadCrumb.module.css'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalBackButton from '../GlobalBackButton/GlobalBackButton';

function getPathSuffix (args=[]) {
    console.log(">>>PNAME",window.location.pathname)
    switch(true) {
        case window.location.pathname === "/manage-subscription" : return ["My Account", "Manage Subscription"];
        case window.location.pathname === "/assigned-tests" : return ["Assignments"];
        case /^\/assigned-tests.*\/report.*/.test(window.location.pathname) : return ["Assignments","Report"];
        case window.location.pathname === "/calendar" : return ["Schedule"];
        case window.location.pathname === "/all-tests" : return ["Content"];
        case /^\/all-tests\/.*/.test(window.location.pathname) : return ["Content",...args];
        case window.location.pathname === "/settings" : return ["Settings"];
        case window.location.pathname === "/users" : return ["Users"];
        case /^\/testpage\/.*/.test(window.location.pathname) : return ["Assignments",...args];
        case window.location.pathname === "/" : return ["Users"];
    }
}


const BreadCrumb = (props) => {

    const {firstName, lastName,id} = useSelector((state)=>state.user)
    const {company} = useSelector((state)=>state.organization.organization)

    const [breadCrumbConstituentsArray, setBreadCrumbConstituentsArray]  = useState()

    useEffect(()=>{
        setBreadCrumbConstituentsArray([
            <Link to="/users">{company}</Link>,
            <Link to="/users">{firstName} {lastName}</Link>,
            ...getPathSuffix(props.args ?? [])?.map((val)=>(
                <Link>{val}</Link>
            ))
        ])
    },[company,firstName,lastName,props])

    return(
        <div className={styles.breadCrumbText}>
            {
                window.localStorage.getItem("ev_link") && window.location.pathname !== "/" ?  <><GlobalBackButton /> <div className='px-[calc(100vw*16/1440)]'>/</div></>: <></>
            }
           
            
        {
            breadCrumbConstituentsArray?.map((val,idx)=>(
                <div className={`${idx === breadCrumbConstituentsArray.length - 1 ? styles.lastComponent : ""} ${idx === breadCrumbConstituentsArray.length - 2 ? styles.secondLastComponent : "" }`}>
                {val}
                {
                    idx< breadCrumbConstituentsArray.length - 1 ? " > " : ""
                }
                </div>
            ))
        }
        </div>
    )
}

export default BreadCrumb


