import dayjs from "dayjs";
import { isEqual } from "lodash";
import { fetchAllEventRequests, updateConsultation, updateEventRequest } from "../../../utils/eventService";
import { triggerToast } from "../../../utils/toastController";
import { fetchAndSetAllEventRequests } from "./fetchers";
import { getTimeInFormat } from "./utils";

function getTimedelta(reminderString) {
  if (
    !reminderString ||
    reminderString.trim() === "" ||
    reminderString.trim().toLowerCase() === "none"
  ) {
    return 0;
  }
  const reminderStringPart = reminderString.trim().split(" ");
  const seconds = reminderStringPart[1].toLowerCase().includes("minutes")
    ? 60
    : 3600;
  return parseInt(reminderStringPart[0]) * seconds * 1000;
}

async function handleUpdateConsultation(
  allEventData,
  updateFutureConsultations
) {
  const { requiredEventData } = allEventData;

  console.log("updateData consult", allEventData);

  const editedTime = {
    time: {
      start: getTimeInFormat(allEventData.selectedStartTime),
      end: getTimeInFormat(allEventData.selectedEndTime),
    },
  };

  // Compare fields
  const isDateEqual =
    dayjs(allEventData.selectedDate).format("DD/MM/YYYY") ===
    dayjs(requiredEventData.date).format("DD/MM/YYYY");
  const isTimeEqual = isEqual(requiredEventData.time, editedTime);
  const sendEndDate =
    (allEventData.isRecurring && requiredEventData.recurring) ||
    (allEventData.isRecurring && !requiredEventData.recurring);

  let isEndDateEqual = null;
  let isRepeatFrequencyEqual = null;
  let isDaysEqual = null;
  let isSelectedDateEqual = null;

  console.log(allEventData.frequency, requiredEventData.repeatFrequency);

  console.log(
    allEventData.frequency?.toLowerCase() ===
      requiredEventData.repeatFrequency?.toLowerCase()
  );

  if (sendEndDate) {
    isEndDateEqual = (dayjs(allEventData.endDate).format("DD/MM/YYYY") === dayjs(requiredEventData.dateRange[1]).format("DD/MM/YYYY")) || (allEventData.endDate?.tz(allEventData.selectedTimeZone).format('DD/MM/YYYY') === dayjs(requiredEventData.endDate)?.tz(requiredEventData.timeZone).format('DD/MM/YYYY')) ;
    isRepeatFrequencyEqual =
      requiredEventData.repeatFrequency !== undefined &&
      allEventData.frequency?.toLowerCase() ===
        requiredEventData.repeatFrequency.toLowerCase();
    if (
      allEventData.frequency.toLowerCase() === "weekly" ||
      allEventData.frequency.toLowerCase() === "bi-weekly"
    ) {
      isDaysEqual = isEqual(
        allEventData.selectedDay,
        requiredEventData.requiredDays
      );
    } else if (allEventData.frequency.toLowerCase() === "monthly") {
      isSelectedDateEqual =
        parseInt(allEventData.selectedMonthlyRecurringDate) ===
        requiredEventData.selectedDate;
    }
  }

  // Construct fields to update based on changes
  const fieldsToUpdate = {
    ...(isDateEqual === false && {
      date: allEventData.selectedDate?.toISOString(),
    }),
    ...(isTimeEqual === false && { ...editedTime }),
    ...((isEndDateEqual === false || isRepeatFrequencyEqual === false) && {
      dateRange: [
        allEventData.selectedDate?.toISOString(),
        allEventData.endDate?.toISOString(),
      ],
	  endDate : allEventData?.endDate?.toISOString()
    }),
    ...(isRepeatFrequencyEqual === false && {
      repeatFrequency: allEventData.frequency?.toLowerCase(),
    }),
    ...(isDaysEqual === false && {
      requiredDays: allEventData.selectedDay.map((val) => val.toLowerCase()),
    }),
    ...(isSelectedDateEqual === false && {
      selectedDate: parseInt(allEventData.selectedMonthlyRecurringDate),
    }),
    timeZone: allEventData.selectedTimeZone,
    recurring: allEventData.isRecurring,
    sessionStatus: allEventData.selectedStatus,
    whiteboardLink: allEventData.whiteboardLink,
    location: allEventData.location,
    meetingLinkDetails: {
      link: allEventData.meetingLink,
      provider: "Any",
    },
    clientNotes: allEventData.sessionNotes.clientNotes,
    internalNotes: allEventData.sessionNotes.internalNotes,
    rescheduling: allEventData.isRescheduled,
    reminder: {
      isEnabled:
        allEventData.isRemainder.trim() !== "" &&
        allEventData.isRemainder.trim().toLowerCase() !== "none",
      targetUserTypes: Object.keys(allEventData.sendRemindersTo).filter(
        (key) =>
          allEventData.sendRemindersTo[key] === true && key !== "attendees"
      ),
      timeDelta: getTimedelta(allEventData.isRemainder),
    },
    ...(allEventData.isSelectedUser.length > 0 && {
      attendeeDetails: allEventData.isSelectedUser?.map((user) => ({
        id: user,
      })),
    }),
  };

  console.log(fieldsToUpdate);
  try {
    const response = await updateConsultation(
      requiredEventData._id,
      fieldsToUpdate,
      updateFutureConsultations
    );
    triggerToast("Consultation updated successfully", "success", {
      autoClose: 3000,
    });
		if(requiredEventData.isEditingRequest){
			const res = await updateEventRequest(requiredEventData.eventRequestId, {
				"targetUserDetails.actionStatus" : "frozen"
			})
			console.log("AED",allEventData)
			fetchAndSetAllEventRequests(fetchAllEventRequests,allEventData.setAllEventRequests)
		}
  } catch (error) {
    console.error("Error updating consultation:", error);
    triggerToast("Some error occurred", "error", { autoClose: 3000 });
  }
}


export default handleUpdateConsultation;

// Uncomment and refactor if needed

// export const handleUserStatusChange = async (consultationId, userId, status) => {
//   try {
//     const response = await updateUserStatus(consultationId, userId, status);
//     triggerToast("User status updated successfully", "success", { autoClose: 3000 });
//   } catch (error) {
//     console.error("Error updating user status:", error);
//     triggerToast("Some error occurred", "error", { autoClose: 3000 });
//   }
// };
