import { forwardRef } from 'react'
import styles from './SelectPill.module.css'

//Selects one out the given three buttons at a time
const SelectPill = forwardRef(({selectables,selected,onClick},ref) => {

    return(
        <div className={styles.container} ref={ref}>
            {
                selectables.map((selectable,idx)=>{return(
                    <button key={idx+100} id={'selectable_' + idx} className={`${selected === idx ? styles.selected : ""}`} onClick={(e)=>onClick(e)}>{selectable}</button>
                )})
            }
        </div>
    )
})

export default SelectPill