import React, { useEffect, useState } from "react";
import styles from "./ViewAttendees.module.css";
import "../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";

const ViewAttendees = ({ row }) => {
  const awsLink = "https://evallo-test.s3.amazonaws.com/";
  console.log("row", awsLink + row?.extendedProps?._tutor[0]?.photo);

  return (
    <div className={styles.viewAttendees}>
      <div className={styles.searchDropdown}>
        <div className={styles.students}>
          <div className={styles.textWrapper}>Tutor</div>
          <img className={styles.line} src="img/line-479.svg" alt="line" />
          <div className={styles.frame}>
            <div className={styles.person}>
              <div className={styles.divWrapper}>
                {row?.extendedProps?._tutor[0]?.photo ? (
                  <img
                    alt={row.extendedProps._tutor[0].firstName}
                    src={`${awsLink}${row.extendedProps._tutor[0].photo}`}
                    className={styles.div6}
                  />
                ) : (
                  <>
                    {" "}
                    <div className={styles.div}>
                      {row.extendedProps._tutor[0].firstName.charAt(0)}
                    </div>
                  </>
                )}
              </div>
              <div className={styles.frame2}>
                <div className={styles.textWrapper2}>
                  {row.extendedProps._tutor[0].firstName +
                    " " +
                    row.extendedProps._tutor[0].lastName}
                </div>
                <div className={styles.textWrapper3}>
                  {row.extendedProps._tutor[0].email}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.students2}>
          <div className={styles.textWrapper}>
            Students ({row.extendedProps.studentIds.length})
          </div>
          <img className={styles.line} src="img/line-479-2.svg" alt="line" />
          <div className={styles.frame}>
            {row.extendedProps.studentIds.map((stud, index) => {
              return (
                <div className={styles.person}>
					<div className={styles.divWrapper}>
						{stud.photo ? (
							<img
								alt={stud.firstName}
								src={`${awsLink}${stud.photo}`}
								className={styles.div6}
							/>
						) : (
								<>
									{" "}
									<div className={styles.div}>
										{stud.firstName.charAt(0)}
									</div>
								</>
							)}
					</div>
                  <div className={styles.frame2}>
                    <div className={styles.textWrapper2}>
                      {stud.firstName + " " + stud.lastName}
                    </div>
                    <div className={styles.textWrapper3}>{stud.email}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAttendees;
