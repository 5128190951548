import { useEffect } from 'react'
import CrossIcon from '../CrossIcon/CrossIcon'
import styles from './EventTopBar.module.css'
import { getProps } from './helpers'



function DesktopEventTopbar ({keys, displayValues, onClick, selectedKey, extraComponent, setTrigger}) {
	
	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>
				{
					keys?.map((key,idx)=>(
						<button className={selectedKey === key ? styles.activeButton : styles.normalButton} onClick={()=>onClick(key,idx)}>
							{displayValues[idx]}
							{extraComponent ? extraComponent[idx] : <></>}
						</button>
					))
				}
			</div>
			<button className={styles.closeButton}  onClick={()=>setTrigger(false)}>
				<CrossIcon />
			</button>
		</div>
	)

}



export default DesktopEventTopbar
