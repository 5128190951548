import React, { useEffect, useState, useRef } from "react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const CustomDropDown = ({
  maxHeight,
  list,
  selectedValue,
  setSelectedValue,
  selectionDisabled = false,
  placeholder,
  listIsArray = false,
  activeBackground
}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const dropDownRef = useRef(null);

  useEffect(() => {
    // Add event listener for outside click
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setSearchInput("");
    } else if (event.key === "Backspace") {
      setSearchInput(searchInput.slice(0, -1));
    } else if (event.key.length === 1) {
      setSearchInput(searchInput + event.key);
    }
  };

  const filteredList = list.filter((item) =>
    item.displayValue?.toLowerCase().includes(searchInput?.toLowerCase())
  );

  useEffect(() => {
    console.log(selectedValue)
  }, [selectedValue])

  return (
    <div className="relative" ref={dropDownRef}>
      <div
        className={`px-[16px] py-[12px] rounded-[6px] flex justify-between border max-h-[50px] overflow-y-hidden ${open ? "border-blue-70" : "border-[#BEC2CE]"
          } w-full outline-none`}
        onClick={() => {
          if (selectionDisabled) return;
          setOpen(!open);
          setSearchInput(""); // Reset search input on open
        }}
        onKeyDown={handleKeyDown}
        tabIndex={0} // Make div focusable to capture keyboard events
      >
        <div className={`${selectedValue ? "text-secondary" : "text-[#BEC2CE]"}`}>
          {selectedValue ? selectedValue : placeholder}
        </div>
        <div className="cursor-pointer">
          {open ? (
            <KeyboardArrowUpOutlinedIcon />
          ) : (
            <KeyboardArrowDownOutlinedIcon />
          )}
        </div>
      </div>
      {open && (
        <div className={`z-[5] absolute mt-2 rounded-[6px] shadow-md bg-[#FFFFFF] w-full ${maxHeight ? maxHeight : "max-h-[180px]"} overflow-y-auto grid gap-[4px]`}>
          {!listIsArray
            ? filteredList.map((item, index) => (
              <div
                key={index}
                className={`rounded-[6px] text-[14px] font-medium p-[12px] hover:bg-[#F4F5F7] cursor-pointer ${activeBackground && selectedValue === item.displayValue ? "bg-[#F4F5F7]" : ""}`}
                onClick={() => {
                  setSelectedValue(item.value);
                  setOpen(false);
                  setSearchInput("");
                }}
                value={item.value}
              >
                {item.displayValue}
              </div>
            ))
            : list.map((item, index) => (
              <div
                key={index}
                className={`rounded-[6px] text-[14px] font-medium p-[12px] hover:bg-[#F4F5F7] cursor-pointer ${activeBackground && selectedValue === item ? "bg-[#F4F5F7]" : ""}`}
                onClick={() => {
                  setSelectedValue(item);
                  setOpen(false);
                  setSearchInput("");
                }}
                value={item}
              >
                {item}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
