import React, { useEffect, useMemo, useState } from "react";
import frozenEventTypes from "../../utils/eventTypes";

const InternalMeeting = ({agendaNotes, setAgendaNotes, requiredEventData, selectedEventType, user, operationMode}) => {
  const [isLimit, setIsLimit] = useState(500);

  const wordCount = (text) => {
    return text?.trim().split(/\s+/).length -1;
  };

  const handleChange = (e) => {
    const newAgenda = e.target.value;
    const currentWordCount = wordCount(newAgenda);
    if (currentWordCount <= 500) {
      setAgendaNotes((prev)=> ({...prev, note: newAgenda}));
    }  
  };

  useEffect(() => {
    const currentWordCount = wordCount(agendaNotes?.note);
    setIsLimit(500 - currentWordCount);
  }, [agendaNotes]);

  const isEnabledEditing = useMemo(()=>{
		const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
		if(operationMode === "edit" && requiredEventData && nonSessionTypes.includes(selectedEventType?.toLowerCase())){
			if(requiredEventData.isEditingRequest){
				return requiredEventData.eventRequestor._id === user?.id
			}
			return requiredEventData.initiatorDetails.id === user?.id
		}
		return ["admin","tutor"].includes(user?.role) || origin && origin === "setting"
  },[user?.role])

  return (
    <main className="w-full h-fit flex flex-col gap-[12px]">
      {/* Agenda top section */}
      <section className="md:border-b-[1px] border-secondary-10 flex flex-col items-start justify-start gap-[4px]">
        <p className="text-[14px] leading-[21px] text-[#25335A] font-medium">
          Agenda
        </p>
        <div className="hidden md:block w-[54px] bg-[#0671E0] h-[3px] rounded-tl-[2px] rounded-tr-[2px]" />
      </section>

      {/* Notes section */}
	  { 
		isEnabledEditing && 
					<section className="w-full h-full px-[12px] py-[8px] bg-[#FBFBFC] md:bg-[#F6F9FA] rounded-[6px] flex flex-col gap-2">
						<textarea
							placeholder="Add notes for attendees."
							className="resize-none outline-none bg-transparent placeholder:text-secondary-60 text-secondary-60 text-[14px] leading-[21px] font-normal min-h-[100px] max-h-[200px]"
							onChange={handleChange}
							value={agendaNotes?.note}
						></textarea>

						<p className="md:flex w-full text-secondary-60 text-[12px] leading-[12px] items-end justify-end hidden">
							{isLimit} words remaining
						</p>
					</section>
	  }
	  {
		!isEnabledEditing && 
				<div className="flex flex-col items-start justify-start gap-[12px]">
					{agendaNotes?.note}
				</div>
	  }
    </main>
  );
};

export default InternalMeeting;
