import { useEffect, useState } from 'react'
import InitialLoginScreen from '../../components/LoginV2/InitialLoginScreen/InitialLoginScreen'
import styles from './LoginV2.module.css'
import ResetPasswordScreen from '../../components/ResetPasswordScreen/ResetPasswordScreen'
import { Routes, Route } from 'react-router-dom'
import ForgotPasswordScreen from '../ForgotPassword/ForgotPassword'
import ForgotPasswordV2 from '../ForgotPasswordV2/ForgotPasswordV2'
import RequestResetPasswordScreen from '../../components/ForgotPasswordV2/RequestPasswordResetScreen/RequestResetPasswordScreen'
import { ToastContainer } from 'react-toastify'
import ConfirmPasswordResetScreen from '../../components/ForgotPasswordV2/ConfirmPasswordResetScreen/ConfirmPasswordResetScreen'
import RequestConfirmationScreen from '../../components/ForgotPasswordV2/RequestConfirmationScreen/RequestConfirmationScreen'

const LoginV2 = (props) => {



    return(
        <div className={styles.pageContainer}>
            <Routes>
                <Route index element={<InitialLoginScreen />}></Route>
                <Route path="forgot-password" element={<RequestResetPasswordScreen/>}></Route>
                <Route path="reset-password" element={<ResetPasswordScreen />}></Route>
                <Route path="request-success" element={<RequestConfirmationScreen/>}></Route>
                <Route path="reset-success" element={<ConfirmPasswordResetScreen/>}></Route>
            </Routes>
        </div>
    )
}

export default LoginV2