import React, { useEffect, useMemo, useRef, useState } from "react";
import DownArrowIcon from "../DownArrowIcon/DownArrowIcon";
import AttendeeDropDown from "./AttendeeDropDown/AttendeeDropDown";
import { useLazyGetAllUsersQuery } from "../../app/services/users";
import { toast } from "react-toastify";
import AttendeeList from "./AttendeeList/AttendeeList";
import ViewSelectedUser from "./ViewSelectedUser/ViewSelectedUser";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import frozenEventTypes from "../../utils/eventTypes";

const AttendeeSelector = ({ setActive, isSelectedUser, setIsSelectedUser, attendeeStatusMapping, user, attendeeList, origin, selectedEventType, operationMode, requiredEventData }) => {
  const [isDropDownOpen, setIsDropdownOpen] = useState(false);
  const [fetchAllUsers] = useLazyGetAllUsersQuery();
  const [isUsers, setIsUsers] = useState([]);
  const [isViewOpen, setisViewOpen] = useState(false);
  const mainRef = useRef();
  const [prevEventType, setPrevEventType] = useState(null);


  const isEnabledEditing = useMemo(() => {
		const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
		if(operationMode === "edit" && requiredEventData && nonSessionTypes.includes(selectedEventType?.toLowerCase())){
			if(requiredEventData.isEditingRequest){
				return requiredEventData.eventRequestor._id === user?.id
			}
			return requiredEventData.initiatorDetails.id === user?.id
		}
		return ["admin", "tutor"].includes(user?.role) || origin && origin === "schedule"
  }, [user?.role])

  const handleClickOutside = (event) => {
    if (mainRef.current && !mainRef.current.contains(event.target)) {
      if (isDropDownOpen === true) setIsDropdownOpen(false);
      if (isViewOpen === true) setisViewOpen(false);
    }
  };


  useEffect(() => {

    if (["internalMeeting", "officeWork", "officeHours"].includes(selectedEventType)) {
      setIsSelectedUser([])
    }

  }, [selectedEventType])


  useEffect(() => {
    if (isDropDownOpen || isViewOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropDownOpen, isViewOpen]);

  useEffect(() => {
    const getUsers = async () => {
      let urlParams = `?role=tutor&role=admin&role=student&role=parent`;
      await fetchAllUsers(urlParams)
        .then((res) => {
          if (res.data && res.data.data.user) {
            setIsUsers(res.data.data.user);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong.");
        });
    };
    getUsers();
  }, []);

  useEffect(() => {
    if (isSelectedUser && isSelectedUser.length > 0) setActive(true);
    else setActive(false);
  }, [isSelectedUser]);


  useEffect(() => {
    if (isEnabledEditing === true && operationMode && ["create"].includes(operationMode) && ["admin", "tutor"].includes(user?.role)) {
      setIsSelectedUser((prev) => [user.id]);
    }

  }, [user, isEnabledEditing])



  return (
    <main ref={mainRef} className="  w-full h-full relative ">
      <div className="   relative flex justify-between items-center  rounded-[6px]  bg-[#F6F9FA] cursor-pointer" style={{ pointerEvents: isEnabledEditing ? "" : "none" }}>
        {isSelectedUser?.length > 0 ? (
          <section className=" flex-1 w-full flex py-[7.5px] px-[12px]  cursor-pointer  items-center justify-between gap-[2px]" onClick={(e) => setIsDropdownOpen(!isDropDownOpen)} >
            <AttendeeList
              userRole={user?.role}
              selectedUser={isSelectedUser}
              setSelectedUser={setIsSelectedUser}
              isDropDownOpen={isDropDownOpen}
              users={isUsers}
            />

            {user?.role != "student" &&
              <div className="cursor-pointer" style={{ display: isEnabledEditing ? "" : "none" }}>
                {
                  isSelectedUser ?
                    <DownArrowIcon
                      color="#000000"
                      onClick={() => { setIsDropdownOpen(!isDropDownOpen); isViewOpen === true && setisViewOpen(false) }}
                    />
                    :
                    <DownArrowIcon
                      color="#7C859C"
                      onClick={() => { setIsDropdownOpen(!isDropDownOpen); isViewOpen === true && setisViewOpen(false) }}
                    />
                }
              </div>
            }
          </section>
        ) : (
          <section
            className=" flex-1 w-full flex  py-[8px] px-[12px]  items-center justify-between gap-[2px]"
            onClick={() => setIsDropdownOpen(!isDropDownOpen)}
          >
            <p className=" w-full flex-1 text-[14px] leading-[21px]  text-secondary-60 ">
              Add Attendee(s)*
            </p>


            {user?.role != "student" && <DownArrowIcon color="#7C859C" />}
          </section>
        )}

        {user?.role != "student" && isDropDownOpen && (
          <AttendeeDropDown
            users={user?.role === "parent" ? attendeeList : isUsers}
            selectedUser={isSelectedUser}
            setSelectedUser={setIsSelectedUser}
            selectedEventType={selectedEventType}
          />
        )}
        {isViewOpen && (
          <ViewSelectedUser
            selectedUser={isSelectedUser}
            attendeeStatusMapping={attendeeStatusMapping}
            users={isUsers}
          />
        )}
      </div>

      {user?.role != "student" && user?.role != "parent" && isSelectedUser && isSelectedUser.length > 0 && (
        <div className=" w-full  flex items-end justify-end  " style={{ display: isEnabledEditing ? "" : "none" }}>
          <div className=" w-full flex-1 h-[27px]" onClick={() => setisViewOpen(false)} ></div>
          <button
            className=" outline-none text-[14px] text-[#0671E0] mt-[6px] "
            onClick={() => { setisViewOpen(!isViewOpen); isDropDownOpen === true && setIsDropdownOpen(false) }}
          >
            View All
          </button>
        </div>
      )}
    </main>
  );
};

export default AttendeeSelector;
