export default function LocationIcon(props) {

	return(
		<svg width={props.width? props.width :"16"} height={props.height? props.width :"16"}  viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_33049_41969)">
				<path d="M14 6.66406C14 11.3307 8 15.3307 8 15.3307C8 15.3307 2 11.3307 2 6.66406C2 5.07276 2.63214 3.54664 3.75736 2.42142C4.88258 1.2962 6.4087 0.664062 8 0.664062C9.5913 0.664063 11.1174 1.2962 12.2426 2.42142C13.3679 3.54664 14 5.07276 14 6.66406Z"stroke={props.inherit ? props.inherit  : "#7C859C"}stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				<path d="M8 8.66406C9.10457 8.66406 10 7.76863 10 6.66406C10 5.55949 9.10457 4.66406 8 4.66406C6.89543 4.66406 6 5.55949 6 6.66406C6 7.76863 6.89543 8.66406 8 8.66406Z"stroke={props.inherit ? props.inherit  : "#7C859C"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</g>
			<defs>
				<clipPath id="clip0_33049_41969">
					<rect width="16" height="16" fill="white"/>
				</clipPath>
			</defs>
		</svg>

	)

}
