export default function CustomTestIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4813 2.125H10.5613C9.85431 2.125 9.28125 2.69808 9.28125 3.405V5.325C9.28125 6.03192 9.85431 6.60499 10.5613 6.60499H12.4813C13.1882 6.60499 13.7613 6.03192 13.7613 5.325V3.405C13.7613 2.69808 13.1882 2.125 12.4813 2.125Z"
        stroke="#17A289"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.43828 2.125H3.51828C2.81136 2.125 2.23828 2.69808 2.23828 3.405V5.325C2.23828 6.03192 2.81136 6.60499 3.51828 6.60499H5.43828C6.14521 6.60499 6.71828 6.03192 6.71828 5.325V3.405C6.71828 2.69808 6.14521 2.125 5.43828 2.125Z"
        stroke="#17A289"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4813 9.16504H10.5613C9.85431 9.16504 9.28125 9.7381 9.28125 10.445V12.365C9.28125 13.072 9.85431 13.645 10.5613 13.645H12.4813C13.1882 13.645 13.7613 13.072 13.7613 12.365V10.445C13.7613 9.7381 13.1882 9.16504 12.4813 9.16504Z"
        stroke="#17A289"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.43828 9.16504H3.51828C2.81136 9.16504 2.23828 9.7381 2.23828 10.445V12.365C2.23828 13.072 2.81136 13.645 3.51828 13.645H5.43828C6.14521 13.645 6.71828 13.072 6.71828 12.365V10.445C6.71828 9.7381 6.14521 9.16504 5.43828 9.16504Z"
        stroke="#17A289"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
