import ArrowIconLeftV2 from "../../components/ArrowIconLeftV2/ArrowIconLeftV2"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function SettingsBackButton ({setIsSidebarOpen})  {
    return(
        <button className="flex items-center justify-start text-blue-70 font-[500] text-[16px] gap-[4px]" onClick={(e) => setIsSidebarOpen(true)}>
            <ChevronLeftIcon />
           Settings
        </button>
    )
}

export default SettingsBackButton