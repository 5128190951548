import { Tooltip } from "@mui/material";
import LockIcon from "../../assets/Settings/LockIcon";
import { getTooltipStyleClass } from "../../utils/commonlyUsedTooltipStyles";
import { isWhiteLabellingComponentAllowed } from "../../utils/restrictionsService";


function LockIconWithTooltip(){

	const testTitleComponent = <div style={getTooltipStyleClass()}>Upgrade your package to access this feature.</div>

	return (
		<Tooltip 
			title={testTitleComponent}
			componentsProps={{tooltip : {sx : {bgcolor : "transparent"}}}}
		>
			<div>
				<LockIcon {...{height : "16px", width : "16px"}}/>
			</div>
		</Tooltip>
	)
}

//Required helper functions
function filterKeys(operationMode){
	if(operationMode === "edit" || operationMode === "create-requested" )
		return (requiredKey, key,) => key === requiredKey;
	return (requiredKey, key,) => true
}


function getRequiredKeys(keyMapping, selectedValue, operationMode){
	return Object.keys(keyMapping).filter((key,idx)=>filterKeys(operationMode)(selectedValue.toLowerCase(), keyMapping[key].eventType.toLowerCase()))
}

function getMobileTopBarProps(keyMapping, selectedValue, setSelectedValue, operationMode, subscriptionV2){
	
	return {
		items : getRequiredKeys(keyMapping,selectedValue,operationMode).map((key,idx)=>(
		<>
			{keyMapping[key].displayValue}
			{subscriptionV2 && !isWhiteLabellingComponentAllowed(subscriptionV2?.subscription,keyMapping[key].eventType) ? <LockIconWithTooltip /> : null}
		</>
		)),
		selectedIdx : getRequiredKeys(keyMapping,selectedValue,operationMode).findIndex((key, idx)=>keyMapping[key].eventType === selectedValue),
		onClick : (idx) => {
			const tempKeys = getRequiredKeys(keyMapping,selectedValue,operationMode) 
			const reqdMapping = [] 
			tempKeys.forEach((key)=>{
				reqdMapping.push(keyMapping[key])
			})
			setSelectedValue(reqdMapping[idx].eventType)	
		}

	}

}


function getDesktopTopBarProps(keyMapping, selectedValue, setSelectedValue, operationMode, subscriptionV2, setTrigger){

	return {
		displayValues : getRequiredKeys(keyMapping,selectedValue,operationMode).map((key)=>keyMapping[key].displayValue),
		keys : getRequiredKeys(keyMapping,selectedValue,operationMode).map((key)=>keyMapping[key].eventType),
		onClick : (eventType, idx)=>setSelectedValue(eventType),
		setTrigger:setTrigger,
		selectedKey : selectedValue,
		...(subscriptionV2) && {
			extraComponent : getRequiredKeys(keyMapping,selectedValue,operationMode).map((key, idx)=>{
				const component = keyMapping[key].eventType
				return !isWhiteLabellingComponentAllowed(subscriptionV2?.subscription,component) ? <LockIconWithTooltip /> : null
			})}	
	}

}


function getProps(targetDevice){

	switch(targetDevice){
		case "desktop" : return getDesktopTopBarProps;
		case "mobile" : return getMobileTopBarProps;
	}
}



export {
	getProps
}
