import frozenEventTypes from "../../../utils/eventTypes";
import sessionScheduler from "./sessionScheduler";
import handleScheduleConsultation from "./consultationScheduler";
import { bookedMeetingScheduler } from "./bmScheduler";
import { scheduleOfficeWork } from "../../../utils/eventService";
import handleScheduleOfficeWork from "./officeWorkScheduler";
import handleScheduleInternalMeeting from "./InternalMettingScheduler";
//If these get too big extract out to a different file


// function officeWorkScheduler() {
//   console.log("officeWork");
// }

function schedulerController(eventType) {
  switch (eventType.toLowerCase()) {
    case frozenEventTypes.SESSION.eventType.toLowerCase():
      return sessionScheduler;
    case frozenEventTypes.INTMEETING.eventType.toLowerCase():
      return handleScheduleInternalMeeting;
    case frozenEventTypes.OFFCWORK.eventType.toLowerCase():
      return handleScheduleOfficeWork;
    case frozenEventTypes.CONSULTATION.eventType.toLowerCase():
      return handleScheduleConsultation;
    case frozenEventTypes.EXTMEETING.eventType.toLowerCase():
      return bookedMeetingScheduler;
  }
}

export default schedulerController;
