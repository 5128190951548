import React from 'react'

const ContentIcon = ({ type }) => {
    return type !== "active" ? (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29019_849)">
                <path d="M18.72 3.1875H15.84C14.7796 3.1875 13.92 4.04711 13.92 5.1075V7.9875C13.92 9.04789 14.7796 9.90749 15.84 9.90749H18.72C19.7805 9.90749 20.64 9.04789 20.64 7.9875V5.1075C20.64 4.04711 19.7805 3.1875 18.72 3.1875Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.15999 3.1875H5.27999C4.2196 3.1875 3.35999 4.04711 3.35999 5.1075V7.9875C3.35999 9.04789 4.2196 9.90749 5.27999 9.90749H8.15999C9.22037 9.90749 10.08 9.04789 10.08 7.9875V5.1075C10.08 4.04711 9.22037 3.1875 8.15999 3.1875Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.72 13.7471H15.84C14.7796 13.7471 13.92 14.6067 13.92 15.6671V18.5471C13.92 19.6075 14.7796 20.4671 15.84 20.4671H18.72C19.7805 20.4671 20.64 19.6075 20.64 18.5471V15.6671C20.64 14.6067 19.7805 13.7471 18.72 13.7471Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.15999 13.7471H5.27999C4.2196 13.7471 3.35999 14.6067 3.35999 15.6671V18.5471C3.35999 19.6075 4.2196 20.4671 5.27999 20.4671H8.15999C9.22037 20.4671 10.08 19.6075 10.08 18.5471V15.6671C10.08 14.6067 9.22037 13.7471 8.15999 13.7471Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_29019_849">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
        :
        (
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_29035_1656)">
                    <path d="M21.84 3.71875H18.48C17.2428 3.71875 16.24 4.72163 16.24 5.95875V9.31875C16.24 10.5559 17.2428 11.5587 18.48 11.5587H21.84C23.0771 11.5587 24.08 10.5559 24.08 9.31875V5.95875C24.08 4.72163 23.0771 3.71875 21.84 3.71875Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.52004 3.71875H6.16004C4.92293 3.71875 3.92004 4.72163 3.92004 5.95875V9.31875C3.92004 10.5559 4.92293 11.5587 6.16004 11.5587H9.52004C10.7572 11.5587 11.76 10.5559 11.76 9.31875V5.95875C11.76 4.72163 10.7572 3.71875 9.52004 3.71875Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M21.84 16.0381H18.48C17.2428 16.0381 16.24 17.0409 16.24 18.2781V21.6381C16.24 22.8752 17.2428 23.8781 18.48 23.8781H21.84C23.0771 23.8781 24.08 22.8752 24.08 21.6381V18.2781C24.08 17.0409 23.0771 16.0381 21.84 16.0381Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.52004 16.0381H6.16004C4.92293 16.0381 3.92004 17.0409 3.92004 18.2781V21.6381C3.92004 22.8752 4.92293 23.8781 6.16004 23.8781H9.52004C10.7572 23.8781 11.76 22.8752 11.76 21.6381V18.2781C11.76 17.0409 10.7572 16.0381 9.52004 16.0381Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_29035_1656">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
}

export default ContentIcon