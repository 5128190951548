import React, { useEffect, useMemo } from "react";
import GoogleMeetIcon from "../../assets/calendarV2/Google_Meet_icon.svg";
import ZoomMeetIcon from "../../assets/calendarV2/zoom_icon.svg";
import MSTeamsIcon from "../../assets/calendarV2/Microsoft_Office_Teams.svg";
import CopyIconCal from "../../assets/calendarV2/CopyIconCal";
import { Tooltip } from "@mui/material";
import frozenEventTypes from "../../utils/eventTypes";

let dummyLinks = {
  google: "https://meet.google.com/bui-ehky-rjg",
  outlook: "https://meet.google.com/eyw-ahhp-ymv",
  zoom: "https://meet.google.com/ubp-rdgp-hcf",
};

const styles = {
  outerContainer:
    "relative rounded-[6px] bg-[#F6F9FA] px-[12px] py-[7.5px] flex items-center justify-between text-secondary-60",
  inputField:
    "text-[14px] leading-[21px] placeholder:text-secondary-60 placeholder:font-[400] text-secondary-80 font-[500] bg-transparent w-[calc(100%-28px)] focus:outline-none",
};
const MeetingLinkSelector = ({
  meetingLink,
  setMeetingLink,
  setActive,
  hideButtons,
  placeholder,
  user,
  operationMode,
  requiredEventData,
  selectedEventType
}) => {
  console.log(hideButtons);

  //To Enable or disable editing based on the role
  const isEnabledEditing = useMemo(() => {
		const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
		if(operationMode === "edit" && requiredEventData && nonSessionTypes.includes(selectedEventType?.toLowerCase())){
			if(requiredEventData.isEditingRequest){
				return requiredEventData.eventRequestor._id === user?.id
			}
			return requiredEventData.initiatorDetails.id === user?.id
		}

		return ["admin", "tutor"].includes(user?.role);
  }, [user?.role]);


  
  useEffect(()=>{
    if(isEnabledEditing === true && operationMode && ["create-requested", "create"].includes(operationMode) && user?.userDefaults?.meetingLink !== "" ) setMeetingLink(user?.userDefaults?.meetingLink)
  },[user,isEnabledEditing])


  async function createCalendarEvent() {
    const MsAccessToken = window.localStorage.getItem("msToken");
    if (!MsAccessToken) {
      console.error("Microsoft Access Token is missing");
      return;
    }

    const eventDetails = {
      subject: "Project Kickoff Meeting",
      body: {
        contentType: "HTML",
        content: "We will be discussing the project kickoff details.",
      },
      start: {
        dateTime: "2024-08-25T10:00:00",
        timeZone: "UTC",
      },
      end: {
        dateTime: "2024-08-25T11:00:00",
        timeZone: "UTC",
      },
      location: {
        displayName: "Conference Room A",
      },
      attendees: [
        {
          emailAddress: {
            address: "john.doe@example.com",
            name: "John Doe",
          },
          type: "required",
        },
        {
          emailAddress: {
            address: "jane.smith@example.com",
            name: "Jane Smith",
          },
          type: "optional",
        },
      ],
      allowNewTimeProposals: true,
      isOnlineMeeting: true,
      onlineMeetingProvider: "teamsForBusiness",
    };

    try {
      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${MsAccessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventDetails),
        }
      );

      if (response.ok) {
        const event = await response.json();
        console.log("Event created successfully:", event);
      } else {
        const errorResponse = await response.json();
        console.error("Error creating event:", errorResponse);
      }
    } catch (error) {
      console.error("Error during event creation:", error);
    }
  }

  const createGoogleMeetLink = async () => {
    const event = {
      summary: "New Meeting", // Event title
      description: "Meeting with team", // Event description
      start: {
        dateTime: "2024-09-01T09:00:00-07:00", // Start date/time in RFC3339 format
        timeZone: "America/Los_Angeles", // Time zone
      },
      end: {
        dateTime: "2024-09-01T10:00:00-07:00", // End date/time in RFC3339 format
        timeZone: "America/Los_Angeles", // Time zone
      },
      conferenceData: {
        createRequest: {
          requestId: "sample123", // Unique ID for the request
          conferenceSolutionKey: {
            type: "hangoutsMeet", // Use Hangouts Meet for Google Meet
          },
        },
      },
      attendees: [
        { email: "example1@gmail.com" }, // Optional: Add attendees
        { email: "example2@gmail.com" },
      ],
    };
    const googleAccessToken = window.localStorage.getItem("googleToken");
    if (!googleAccessToken) {
      console.error("Google Access Token is missing");
      return;
    }

    try {
      const response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${googleAccessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(event),
        }
      );

      const data = await response.json();
      if (response.ok) {
        // Extract and use the Google Meet link
        const meetLink = data.hangoutLink;
        setMeetingLink(meetLink);
      } else {
        console.error("Error creating event", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (meetingLink && meetingLink.trim() != "") setActive(true);
    else setActive(false);
  }, [meetingLink]);

  return (
    <div className="flex gap-2 flex-col xl:flex-row xl:items-center w-full ">
      <div className="rounded-md px-3 py-[7.5px] bg-[#F6F9FA] flex justify-between items-center w-full">
        <input
          type="text"
          className={`${styles.inputField}`}
          placeholder={placeholder ? placeholder : "Meeting Link"}
          value={meetingLink}
          onChange={(e) => setMeetingLink(e.target.value)}
          onFocus={() => setActive(true)}
          disabled={!isEnabledEditing}
        />
        <button
          className="hover:bg-secondary-5"
          onClick={() => {
            if (!meetingLink) {
              return;
            }
            navigator.clipboard.writeText(meetingLink);
            toast.success("Link copied to clipboard !!", { autoClose: 3000 });
          }}
        >
          <CopyIconCal />
        </button>
      </div>
      <div className={`flex gap-2 ${hideButtons ? "hidden" : "flex"}`}>
        <Tooltip
          title={
            <div className="bg-white  w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26] font-[lexend]">
              <div className="font-normal text-[12px] leading-[18px] text-secondary-70 cursor-default">
				Launching in next update
              </div>
            </div>
          }
          componentsProps={{ tooltip: { sx: { bgcolor: "transparent" } } }}
        >
          <button
            className="xl:w-10 w-fit px-3 box-border gap-[6px] h-9 rounded-md border flex justify-center items-center cursor-pointer border-[#E9EBEF] shadow-[0px_2px_8px_0px_#11167D1A]"
            onClick={() => {
              //createGoogleMeetLink()
              //setMeetingLink({ link: dummyLinks.google, provider: "Google" });
            }}
          >
            <span className="xl:hidden text-blue-70">Meet</span>
            <img src={GoogleMeetIcon} alt="" />
          </button>
        </Tooltip>
        <Tooltip
          title={
            <div className="bg-white  w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26] font-[lexend]">
              <div className="font-normal text-[12px] leading-[18px] text-secondary-70 cursor-default">
                Launching in next update
              </div>
            </div>
          }
          componentsProps={{ tooltip: { sx: { bgcolor: "transparent" } } }}
        >
          <button
            className="xl:w-10 w-fit px-3 box-border gap-[6px] h-9 rounded-md border flex justify-center items-center cursor-pointer border-[#E9EBEF] shadow-[0px_2px_8px_0px_#11167D1A]"
            onClick={() => {
              //setMeetingLink({ link: dummyLinks.zoom, provider: "Zoom" });
            }}
          >
            <span className="xl:hidden text-blue-70">Zoom</span>
            <img src={ZoomMeetIcon} alt="" />
          </button>
        </Tooltip>
        <Tooltip
          title={
            <div className="bg-white  w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26] font-[lexend]">
              <div className="font-normal text-[12px] leading-[18px] text-secondary-70 cursor-default">
                Launching next calendar update!
              </div>
            </div>
          }
          componentsProps={{ tooltip: { sx: { bgcolor: "transparent" } } }}
        >
          <button
            className="xl:w-10 w-fit px-3 box-border gap-[6px] h-9 rounded-md border flex justify-center items-center cursor-pointer border-[#E9EBEF] shadow-[0px_2px_8px_0px_#11167D1A]"
            onClick={() => {
              //createCalendarEvent();
              // setMeetingLink({
              //   link: dummyLinks.outlook,
              //   provider: "MS Teams",
              // });
            }}
          >
            <span className="xl:hidden text-blue-70">Teams</span>
            <img src={MSTeamsIcon} alt="" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default MeetingLinkSelector;
