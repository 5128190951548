import React, { useContext, useEffect } from "react";
import styles from "./accountType.module.css";

import individual from "./icons/individual_people.svg";
import for_org from "./icons/group_people.svg";
import { Link, useOutletContext } from "react-router-dom";
import OrgSignupContext from "../../context/OrgSignupContext";

const AccountType = () => {
  const { setRegistrationAs } = useContext(OrgSignupContext);

  const { setSignupStage } = useOutletContext();
  useEffect(() => {
    setSignupStage("initial");
  }, []);

  return (
    <div className={styles.user_type_container}>
      <h1><span>New here?</span> Create a Business Account.</h1>
      <div className={styles.option_container}>
        <Link onClick={(e) => setRegistrationAs("Individual")} to="/signup/org">
          <div className={styles.options}>
            <div>
              <img src={individual} alt="" />
            </div>
            <div>
              <h2>Individual Tutor</h2>
              <p>
                If you are a freelancer or a <br /> solopreneur providing
                services.
              </p>
            </div>
          </div>
        </Link>
        <Link onClick={(e) => setRegistrationAs("Company")} to="/signup/org">
          <div className={styles.options}>
            <div>
              <img src={for_org} alt="" />
            </div>
            <div>
              <h2>Tutoring Business</h2>
              <p>
                If you have a tutoring company with <br /> other tutors in your
                team.
              </p>
            </div>
          </div>
        </Link>
      </div>
      <div className={styles.last_option}>
        <p>
          Looking to signup as a{" "}
          <span className="font-[600] !text-secondary-90">Student</span> or{" "}
          <span className="font-[600] !text-secondary-90">Parent</span>?<br />{" "}
          <Link to="/signup/student-parent-info">
            <span className="font-[500] !text-blue-70">Click here</span>
          </Link>{" "}
          to learn more.
        </p>

        <p>
          Have an existing account?{" "}
          <Link to="/signin">
            <span className="font-[500] !text-blue-70">Sign in</span>
          </Link>
        </p>
      </div>
      <div>
        <div className={styles.for_mobile}>
          Signing up as a Student or Parent?
          <br />
          <Link to="/signup/student-parent-info">learn more.</Link>
        </div>
      </div>
    </div>
  );
};

export default AccountType;
