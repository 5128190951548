import { Link } from 'react-router-dom'
import ButtonV2 from '../ButtonV2/ButtonV2'
import styles from './SupportCornerSub.module.css'
Link


const SupportCornerSub = (props) => {

    return(
        <div className={styles.container}>
            <div className={styles.mainText}>Questions?</div>
            <div className={styles.helperText}>Our support team is ready to help.</div>
            <a className={styles.supportButton} href="https://evallo.org/support" target="_blank">Contact Support</a>
        </div>
    )

}

export default SupportCornerSub