import React from 'react'

const CopyClipboard = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_31893_129061)">
                <path d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z" stroke="#0671E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.1665 12.4993H3.33317C2.89114 12.4993 2.46722 12.3238 2.15466 12.0112C1.8421 11.6986 1.6665 11.2747 1.6665 10.8327V3.33268C1.6665 2.89065 1.8421 2.46673 2.15466 2.15417C2.46722 1.84161 2.89114 1.66602 3.33317 1.66602H10.8332C11.2752 1.66602 11.6991 1.84161 12.0117 2.15417C12.3242 2.46673 12.4998 2.89065 12.4998 3.33268V4.16602" stroke="#0671E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_31893_129061">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CopyClipboard