/** @jsxImportSource @emotion/react */
import styles from "./InitialLoginScreen.module.css";
import OAuthButton from "../../OAuthButton/OAuthButton";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import msLogo from "./MSLogo.svg";
import googleLogo from "./GoogleLogo.svg";
import dividerLine from "./DividerLine.svg";
import InputPasswordV2 from "../../InputPasswordV2/InputPasswordV2";
import ButtonV2 from "../../ButtonV2/ButtonV2";
import { Link } from "react-router-dom";
import {
  useGoogleLoginMutation,
  useLoginUserMutation,
  useMicrosoftLoginMutation,
} from "../../../app/services/auth";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";
import { toast } from "react-toastify";
import { updateIsLoggedIn } from "../../../app/slices/user";
import { updateOrganization } from "../../../app/slices/organization";
import { useDispatch } from "react-redux";
import {
  useLazyGetPersonalDetailQuery,
  useLazyGetUserDetailQuery,
} from "../../../app/services/users";
import { useLazyGetOrganizationQuery } from "../../../app/services/users";
import axios from "axios";
import { getAuthHeader } from "../../../app/constants/constants";
import {
  checkForOldSubscriptions,
  fetchAndSetSubscription,
} from "../../../utils/subscriptionService";
import { useGoogleLogin } from "@react-oauth/google";
import { useMsal } from "@azure/msal-react";
import { css } from "@emotion/react";

function constructValidIFrameURL(showIFrame, service = "google") {
  let baseURL = "";
  switch (process.env.REACT_APP_FE_ENV) {
    case "development":
      baseURL = "http://localhost:3000";
      break;
    case "test":
      baseURL = "http://localhost:3000";
      break;
    case "production":
      baseURL = "https://app.evallo.org";
      break;
  }

  const subURL = `/oauth?redirect=${service}&do=${showIFrame}`;

  const completeURL = new URL(subURL, baseURL);
  console.log("::URL", completeURL);
  return completeURL;
}

const InitialLoginScreen = (props) => {
  const navigate = useNavigate();
  const {
    setSignupStage,
    OrganizationData,
    dummySubscription,
    primaryCTAStyles,
  } = useOutletContext();

  const testClass = css({
    ...primaryCTAStyles?.bg,
  });

  const [email, setEmail] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [password, setPassword] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [rememberUser, setRememberUser] = useState(false);
  const [loginReq, loginReqResp] = useLoginUserMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isIFrameLoading, setIsIFrameLoading] = useState(true);
  const [getUserDetails, getUserDetailsResp] = useLazyGetPersonalDetailQuery();
  const [getOrgDetails, getOrgDetailsResp] = useLazyGetOrganizationQuery();
  const [googleLogin] = useGoogleLoginMutation();
  const [microsoftLogin] = useMicrosoftLoginMutation();
  const [showIframe, setShowIframe] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    setSignupStage("signin");
  }, []);

  const handleFormChange = (e) => {
    switch (e.target.id) {
      case "email":
        setEmail(e.target.value.trim());
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };
  useEffect(() => {
    if (
      window.localStorage.getItem("evalloToken") ||
      window.sessionStorage.getItem("evalloToken")
    ) {
      fetchOtherDetails();
    }
  }, []);

  useEffect(() => {
    const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    setIsButtonEnabled(emailFormat.test(email) && password?.length >= 1);
    setIsEmailValid(emailFormat.test(email));
  }, [email, password]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const email = e.target.email.value.trim();
    loginReq({
      email: email,
      password: password,
    }).then(async (res) => {
      if (res.error) {
        console.log(res.error);
        if (
          res.error?.data?.message?.split(":").pop() ===
          "TUTOR_ACCOUNT_INACTIVE"
        ) {
          toast.warn("Your tutoring license isn’t active!", {
            autoClose: false,
          });
          setIsLoading(false);
          return;
        }
        console.log(res.error?.data?.message);
        toast.error(res?.error?.data?.message);
        setIsLoading(false);
        if (res.error?.data?.message.startsWith("No main account")) {
          //navigate('/signup')
        } else if (res.error?.data?.message.startsWith("Email not found")) {
          //Do nothing
        }
      } else {
        // if (res.data.data.subDomainCallbackDetails?.callbackURL) {
        //   let toAddToURL = null;
        //   let tempURL = res.data.data.subDomainCallbackDetails.callbackURL;
        //   if (rememberUser) {
        //     toAddToURL += "&rememberUser=true";
        //   }
        //   if (toAddToURL) {
        //     tempURL += toAddToURL;
        //   }
        //   window.location.replace(tempURL);
        //   return;
        // }
        if (rememberUser) {
          window.localStorage.setItem("evalloToken", res.data.data.token);
          window.localStorage.setItem("role", res.data.data.role);
          window.localStorage.setItem("userId", res.data.data.userId);
          window.localStorage.setItem("orgId", res.data.data.associatedOrg);
        } else {
          window.sessionStorage.setItem("evalloToken", res.data.data.token);
          window.sessionStorage.setItem("role", res.data.data.role);
          window.sessionStorage.setItem("userId", res.data.data.userId);
          window.sessionStorage.setItem("orgId", res.data.data.associatedOrg);
        }

        if (res.data.data.role !== "superAdmin") {
          fetchAndSetSubscription();
        }

        toast.success("Logged In successfully");
        dispatch(updateIsLoggedIn(true));
        if (
          res.data.data.role === "superAdmin" ||
          res.data.data.role === "manager"
        ) {
          navigate("/");
        }
        setTimeout(async () => {
          if (
            res.data.data.role !== "superAdmin" &&
            res.data.data.role !== "manager"
          ) {
            await fetchOtherDetails();
          }
          setIsLoading(false);
        }, 5000);
      }
    });
  };

  const onGoogleSuccess = async (googleToken) => {
    try {
      googleLogin({
        authCode: googleToken.code,
      }).then((res) => {
        if (res.error) {
          if (
            res.error?.data?.message?.split(":").pop() ===
            "TUTOR_ACCOUNT_INACTIVE"
          ) {
            toast.warn("Your tutoring license isn’t active!", {
              autoClose: false,
            });
            setIsLoading(false);
            return;
          }
          console.log(res.error?.data?.message);
          toast.error(res?.error?.data?.message);
          if (res.error?.data?.message.startsWith("No main account")) {
            //navigate('/signup')
          } else if (res.error?.data?.message.startsWith("Email not found")) {
            //Do nothing
          }
        } else {
          // if (res.data.data.subDomainCallbackDetails) {
          //   let toAddToURL = null;
          //   let tempURL = res.data.data.subDomainCallbackDetails.callbackURL;
          //   if (rememberUser) {
          //     toAddToURL += "&rememberUser=true";
          //   }
          //   if (toAddToURL) {
          //     tempURL += toAddToURL;
          //   }
          //   window.location.replace(tempURL);
          //   return;
          // }
          if (rememberUser) {
            window.localStorage.setItem("evalloToken", res.data.data.token);
            window.localStorage.setItem("googleToken", googleToken.code);
            window.localStorage.setItem("role", res.data.data.role);
            window.localStorage.setItem("userId", res.data.data.userId);
          } else {
            window.sessionStorage.setItem("evalloToken", res.data.data.token);
            window.localStorage.setItem("googleToken", googleToken.code);
            window.sessionStorage.setItem("role", res.data.data.role);
            window.sessionStorage.setItem("userId", res.data.data.userId);
          }

          if (
            res.data.data.role !== "superAdmin" ||
            res.data.data.role === "manager"
          ) {
            fetchAndSetSubscription();
          }

          toast.success("Logged In successfully");
          dispatch(updateIsLoggedIn(true));
          setTimeout(async () => {
            if (
              res.data.data.role !== "superAdmin" &&
              res.data.data.role !== "manager"
            ) {
              await fetchOtherDetails();
            }
            setIsLoading(false);
          }, 5000);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onGoogleFailure = (res) => {
    console.log(res);
  };

  const onGoogleSuccessCode = (code) => {
    console.log(code);
    try {
      googleLogin({
        authCode: code.code,
      })
        .then((res) => {
          alert("Login successfull");
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const googleSignIn = useGoogleLogin({
    onSuccess: onGoogleSuccess,
    onFailure: onGoogleFailure,
    flow: "auth-code",
    redirect_uri: "postmessage",
    clientId:
      "316191323811-693kdt97bn7960b9gdtscnr8e8u23r26.apps.googleusercontent.com",
  });

  async function fetchOtherDetails() {
    const role =
      window.localStorage.getItem("role") ??
      window.sessionStorage.getItem("role");
    const userId = window.localStorage.getItem("userId")
      ? window.localStorage.getItem("userId")
      : window.sessionStorage.getItem("userId");
    try {
      const userDetails = await getUserDetails();
      if (userDetails.error) {
        console.log(userDetails.error);
        return;
      } else {
        const user = userDetails.data.data.user;
        const orgDetails = await getOrgDetails(user.associatedOrg);
        if (orgDetails.error) {
          console.log(orgDetails.error);
          return;
        } else {
          console.log(orgDetails.data.organisation);
          if (orgDetails.data.organisation) {
            if (!orgDetails.data.organisation.hasSubscription) {
              const oldSub = await checkForOldSubscriptions(
                orgDetails.data.organisation
              );
              if (rememberUser) {
                window.localStorage.setItem(
                  "userEmail",
                  userDetails.data.data.user.email
                );
                window.localStorage.setItem(
                  "orgId",
                  orgDetails.data.organisation._id
                );
              } else {
                window.sessionStorage.setItem(
                  "userEmail",
                  userDetails.data.data.user.email
                );
                window.sessionStorage.setItem(
                  "orgId",
                  orgDetails.data.organisation._id
                );
              }
              if (role !== "admin") {
                navigate("/");
                return;
              } else {
                if (oldSub) {
                  navigate("/");
                  return;
                }
                navigate("/signup/org-details");
              }

              return;
            } else {
              if (rememberUser) {
                window.localStorage.setItem(
                  "userEmail",
                  userDetails.data.data.user.email
                );
                window.localStorage.setItem(
                  "orgId",
                  orgDetails.data.organisation._id
                );
              } else {
                window.sessionStorage.setItem(
                  "userEmail",
                  userDetails.data.data.user.email
                );
                window.sessionStorage.setItem(
                  "orgId",
                  orgDetails.data.organisation._id
                );
              }
              if (role !== "admin") {
                navigate("/");
                return;
              } else {
                navigate("/");
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function handleGoogleLoginClick() {
    const domain = ".evallo.org";
    if (
      ![`app${domain}`, `test${domain}`, `localhost:3000`].includes(
        window.location.host
      )
    ) {
      console.log("TESTLOGIN");
      setShowIframe((prev) => prev + 1);
    } else {
      googleSignIn();
    }
  }

  useEffect(() => {
    window.onmessage = (event) => {
      console.log("RDS ED", event.data);
      if (event.data && event.data.isRedirect) {
        const requiredStorage = rememberUser
          ? "localStorage"
          : "sessionStorage";
        window[requiredStorage].setItem("evalloToken", event.data.evalloToken);
        window[requiredStorage].setItem("role", event.data.role);
        window[requiredStorage].setItem("userId", event.data.userId);
        dispatch(updateIsLoggedIn(true));
        fetchOtherDetails();
      } else if (event.data && event.data.isRedirect && event.data.isError) {
        toast.warn(event.data.errorMessage);
      }
    };
  }, []);

  function handleMicrosoftLoginClick() {
    const domain = ".evallo.org";
    if (
      ![`app${domain}`, `test${domain}`, `localhost:3000`].includes(
        window.location.host
      )
    ) {
      console.log("TESTLOGIN");
      setShowIframe((prev) => prev + 1);
    } else {
      outlookSignIn();
    }
  }

  const { instance, accounts } = useMsal();

  const outlookSignIn = async () => {
    try {
      const response = await instance.loginPopup();

      if (response) {
        const microsoftToken = response.accessToken;

        microsoftLogin({
          accessToken: microsoftToken,
        }).then((res) => {
          if (res.error) {
            if (
              res.error?.data?.message?.split(":").pop() ===
              "TUTOR_ACCOUNT_INACTIVE"
            ) {
              toast.warn("Your tutoring license isn’t active!", {
                autoClose: false,
              });
              setIsLoading(false);
              return;
            }
            toast.error(res?.error?.data?.message);
            return;
          }

          // Handle successful response
          if (res.data.data.subDomainCallbackDetails?.callbackURL) {
            let tempURL = res.data.data.subDomainCallbackDetails.callbackURL;
            if (rememberUser) {
              tempURL += "&rememberUser=true";
            }
            window.location.replace(tempURL);
            return;
          }

          // Set tokens and user information
          if (rememberUser) {
            window.localStorage.setItem("evalloToken", res.data.data.token);
            window.localStorage.setItem("msToken", microsoftToken);
            window.localStorage.setItem("role", res.data.data.role);
            window.localStorage.setItem("userId", res.data.data.userId);
          } else {
            window.sessionStorage.setItem("evalloToken", res.data.data.token);
            window.localStorage.setItem("msToken", microsoftToken);
            window.sessionStorage.setItem("role", res.data.data.role);
            window.sessionStorage.setItem("userId", res.data.data.userId);
          }

          // Handle subscriptions if needed
          if (
            res.data.data.role !== "superAdmin" ||
            res.data.data.role === "manager"
          ) {
            fetchAndSetSubscription();
          }

          // Update the UI and navigate as needed
          toast.success("Logged In successfully");
          dispatch(updateIsLoggedIn(true));
          if (
            res.data.data.role === "superAdmin" ||
            res.data.data.role === "manager"
          ) {
            navigate("/");
          } else {
            setTimeout(async () => {
              await fetchOtherDetails();
              setIsLoading(false);
            }, 5000);
          }
        });
      }
    } catch (error) {
      console.error("Microsoft login failed", error);
      toast.error("An error occurred during login.");
    }
  };

  return (
    <div className={styles.container}>
      <h3>Sign in</h3>
      <div className={styles.buttonContainer}>
        <OAuthButton onClick={() => handleGoogleLoginClick()}>
          <img src={googleLogo} />
          <div>
            <span className={styles.buttonText}>
              {isIFrameLoading ? <LoaderIcon /> : "Continue with Google"}
            </span>
          </div>
        </OAuthButton>
        <OAuthButton onClick={() => handleMicrosoftLoginClick()}>
          <img src={msLogo} />{" "}
          <div>
            {isIFrameLoading ? <LoaderIcon /> : "Continue with Outlook"}
          </div>
        </OAuthButton>{" "}
      </div>
      <div className={styles.divider}>
        <img src={dividerLine} />
        <div>or</div>
        <img src={dividerLine} />
      </div>
      <form
        className={styles.loginForm}
        onChange={(e) => handleFormChange(e)}
        onSubmit={(e) => handleFormSubmit(e)}
      >
        <input
          type="email"
          className={styles.emailInput}
          name="email"
          placeholder="Enter your email address"
          id="email"
        />
        {isEmailValid === false && email ? (
          <div className={styles.invalidEmailMessage}>
            <div>Please enter a valid email address</div>
          </div>
        ) : (
          <></>
        )}
        <InputPasswordV2
          placeholderText={"Password"}
          id={"password"}
          {...(primaryCTAStyles && {
            css: css({ ...primaryCTAStyles.border }),
          })}
        />

        <div className="m-[-0.25rem] flex flex-row-reverse items-center w-full">
          <Link
            className={styles.forgotPasswordButton}
            to="forgot-password"
            {...(primaryCTAStyles && {
              css: css({ ...primaryCTAStyles.text }),
            })}
          >
            Forgot Password ?
          </Link>
        </div>
        <div className="flex flex-row items-center w-full justify gap-[0.25rem]">
          <input
            type="checkbox"
            {...(primaryCTAStyles && {
              css: css({ ...primaryCTAStyles.accent }),
            })}
            onChange={(e) => setRememberUser(e.target.checked)}
          />
          <label>Remember me</label>
        </div>
        <ButtonV2
          disabled={!isButtonEnabled}
          className={`flex flex-row justify-center !text-[16px] `}
          {...(primaryCTAStyles && { css: css({ ...primaryCTAStyles.bg }) })}
        >
          {!isLoading ? (
            "Sign in"
          ) : (
            <div className="flex justify-center items-center w-full">
              <LoaderIcon />
            </div>
          )}
        </ButtonV2>
      </form>
      <div
        className={`${styles.signupCTA} ${
          primaryCTAStyles?.text
            ? `!text-[${primaryCTAStyles?.text.color}]`
            : ""
        }`}
      >
        Don't have an account?{" "}
        <Link
          to="/signup"
          {...(primaryCTAStyles && { css: css({ ...primaryCTAStyles.text }) })}
        >
          {" "}
          Sign up
        </Link>
      </div>
      <iframe
        onLoad={() => {
          setIsIFrameLoading(false);
        }}
        src={constructValidIFrameURL(showIframe, "google")?.href}
        style={{
          position: "fixed",
          left: "0px",
          top: "0px",
          height: "0px",
          width: "0px",
        }}
      />
      <iframe
        onLoad={() => {
          setIsIFrameLoading(false);
        }}
        src={constructValidIFrameURL(showIframe, "microsoft")?.href}
        style={{
          position: "fixed",
          left: "0px",
          top: "0px",
          height: "0px",
          width: "0px",
        }}
      />
    </div>
  );
};

export default InitialLoginScreen;
