const tooltipStyleTemplate = {
    padding: "12px",
    backgroundColor: "white",
    fontFamily: "Lexend",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
    color: "#636D89",
    borderRadius: "12px",
    boxShadow: "0px 4px 24px 0px #11167D26",
    width: "240px"
}


function getTooltipStyleClass(args){

    return {...tooltipStyleTemplate, ...args}

}


export {
    getTooltipStyleClass
}