import React, { useState, useEffect } from "react";
import CompletionIndicator from "../../../../components/CompletionIndicator/CompletionIndicator";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../../components/InfoIcon/InfoIcon";

const InsightCard = ({
  insights,
  price = 0,
  selectedPlanType = 1,
  studentGroupLimit,
}) => {
  return (
    <>
      <div className="w-full min-h-[225px] h-fit rounded-[12px] p-[16px] grid gap-[20px] font-semibold text-[20px] text-secondary bg-secondary-5">
        <div className="flex items-center gap-[8px]">
          <div>
            <img src={insights.img} />
          </div>
          <div className="flex items-center  text-[16px] gap-[8px] justify-start">
            {insights.name}{" "}
            <Tooltip
              anchorComponent={
                <InfoIcon className="text-secondary-60"></InfoIcon>
              }
              tooltipContent={insights.tooltipContent}
              tooltipStyleClass={"tooltipStyleClass"}
            />
          </div>
        </div>
        <div className="flex h-fit justify-between w-full">
          <div className="grid gap-[16px]  w-[80%] ">
            <div className="grid gap-0">
              {insights.name !== "CRM" ? (
                <div className="flex items-center justify-start">
                  <div
                    className={`${
                      insights.limit === -1 ? "text-[18px]" : "text-[32px]"
                    } font-medium`}
                  >
                    {insights.limit === -1
                      ? "Unlimited"
                      : insights.remainingFromLimit === -1
                      ? "Unlimited"
                      : insights.remainingFromLimit}
                  </div>
                  <div
                    className={`${
                      insights.limit === -1 ? "text-[14px]" : "text-[16px]"
                    } text-[#7C859C]`}
                  >
                    /{insights.limit === -1 ? "Unlimited" : insights.limit}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-start">
                  <div className="text-[32px] font-medium">Free</div>
                </div>
              )}
              {insights ? (
                <div className="text-[14px] text-[#7C859C] text-left font-[400] mt-0">
                  {insights?.heading}
                </div>
              ) : (
                <div className="text-[14px] text-[#7C859C] text-left font-[400] mt-0">
                  Unlimited Benefits
                </div>
              )}
            </div>

            <div>
              <div className="flex gap-[6px] text-[14px] text-secondary font-[400]  items-center">
                {insights.name !== "CRM" ? (
                  <div className="font-[500]">Current Plan</div>
                ) : (
                  <div className="text-[12px] flex gap-[8px]">
                    <div className="font-[500]">
                      {insights.students}/&#8734;
                    </div>
                    <div className="text-secondary-80  font-[400] ml-[4px]">
                      Active Students
                    </div>
                  </div>
                )}
              </div>
              {insights.name !== "CRM" ? (
                <div
                  className={`${
                    insights.limit === -1 ? "text-[14px]" : "text-[14px]"
                  } font-[400] text-secondary-80 `}
                >
                  {insights.limit === -1 ? "Unlimited" : insights.limit}
                  {selectedPlanType == 0 ? "/mo" : "/year"}
                  {" - "}${price}
                </div>
              ) : (
                <div className="text-[12px] flex gap-[8px]">
                  <div className="font-[500]">
                    {insights.groups}/
                    {studentGroupLimit === -1 ? "∞" : studentGroupLimit}
                  </div>
                  <div className="text-secondary-80 font-[400]">
                    Student Groups
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=" flex  items-center">
            <CompletionIndicator
              height={84}
              width={84}
              outerCircleColor={"gray"}
              innerCircleColor={insights.color}
              percentage={insights?.percentage}
              helperText={"left"}
              mainText={
                insights ? insights?.percentage?.toFixed(0) + "%" : 100 + "%"
              }
            />
          </div>

          <div>
            {/* <img
              src={VerticleLineProduct}
              className="min-h-[120px]  h-[155px] "
            /> */}
          </div>
          {/* <div className="grid gap-[24px]">
            <div className="flex gap-[12px] ">
              <div className="flex items-center justify-center">
                <span className="text-[36px] font-medium ">
                  {productMetrics && productMetrics[0].val1}
                </span>{" "}
                <span className="text-[24px] text-[#7C859C] ">
                  {productMetrics && productMetrics[0].val2}
                </span>
              </div>
              <div className="text-[#7C859C] text-[16px] flex items-center font-[400]">
                {productMetrics && productMetrics[0].text}
              </div>
            </div>
            <div className="flex gap-[12px] ">
              <div className="flex items-center justify-center">
                <span className="text-[36px] font-medium ">
                  {productMetrics && productMetrics[1].val1}
                </span>{" "}
                <span className="text-[24px] text-[#7C859C] ">
                  {productMetrics && productMetrics[1].val2}
                </span>
              </div>
              <div className="text-[#7C859C] text-[16px] flex items-center font-[400]">
                {productMetrics && productMetrics[1].text}
              </div>
            </div>
            {data.name === "Calendar" || data.name === "Assignments" ? (
              <div className="flex items-center justify-center text-[14px] font-normal text-[#7C859C] italic">
                {data.name === "Calendar" ? (
                  "Usage metrics shown are for current billing cycle."
                ) : data.name === "Assignments" ? (
                  "These numbers are based on lifetime usage."
                ) : (
                  <></>
                )}
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default InsightCard;
