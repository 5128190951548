import styles from "./GlobalTestingComponent.module.css";
import Availability from "../../pages/CalendarV2/Availability/Availability";

const GlobalTestingComponent = (props) => {
  return (
    <div className="w-full">
      <div className="flex ">
        <div className=" hidden md:block md:basis-1/5"></div>
        <div className="w-full md:basis-4/5 ">
          <Availability />
        </div>
      </div>
    </div>
  );
};

export default GlobalTestingComponent;
