import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import { Country, State, City } from "country-state-city";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import CustomDropDown from "./components/CustomDropDown";

const inputCheckboxStyling = {
  display: "block",
  height: "20px",
  width: "20px",
};

const BusinessAddressPopUp = ({
  open,
  setOpen,
  editIndex,
  setAllAddresses,
  allAddresses,
  setIsEditingAddresses = null,
  saveAddressChanges,
}) => {
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [currentAddress, setCurrentAddress] = useState({
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    isPrimary: false,
  });

  useEffect(() => {
    const countryData = Country.getAllCountries().map((country) => ({
      value: country.name,
      displayValue: country.name,
    }));
    setCountryList(countryData);
  }, []);

  useEffect(() => {
    if (editIndex >= 0 && allAddresses.length > 0) {
      setCurrentAddress(
        allAddresses[editIndex] || {
          address: "",
          country: "",
          state: "",
          city: "",
          zip: "",
          isPrimary: false,
        }
      );
    } else {
      setCurrentAddress({
        address: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        isPrimary: false,
      });
    }
  }, [allAddresses, editIndex]);

  useEffect(() => {
    if (currentAddress.country) {
      const selectedCountry = Country.getAllCountries().find(
        (country) => country.name === currentAddress.country
      );
      if (selectedCountry) {
        const statesData = State.getStatesOfCountry(
          selectedCountry.isoCode
        ).map((state) => ({
          value: state.name,
          displayValue: state.name,
        }));
        setStateList(statesData);
        setCityList([]);
      }
    } else {
      setStateList([]);
      setCityList([]);
    }
  }, [currentAddress.country]);

  useEffect(() => {
    if (currentAddress.state && currentAddress.country) {
      const selectedCountry = Country.getAllCountries().find(
        (country) => country.name === currentAddress.country
      );
      if (selectedCountry) {
        const selectedState = State.getStatesOfCountry(
          selectedCountry.isoCode
        ).find((state) => state.name === currentAddress.state);
        if (selectedState) {
          const citiesData = City.getCitiesOfState(
            selectedState.countryCode,
            selectedState.isoCode
          ).map((city) => ({
            value: city.name,
            displayValue: city.name,
          }));
          setCityList(citiesData);
        }
      }
    } else {
      setCityList([]);
    }
  }, [currentAddress.state, currentAddress.country]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePrimaryChange = () => {
    setCurrentAddress((prev) => ({
      ...prev,
      isPrimary: !prev.isPrimary,
    }));
  };

  const handleSave = () => {
    setLoading(true);
    let updatedAddresses = [...allAddresses];

    if (currentAddress.isPrimary) {
      updatedAddresses = updatedAddresses.map((addr) => ({
        ...addr,
        isPrimary: false,
      }));
    }

    if (editIndex >= 0) {
      updatedAddresses[editIndex] = currentAddress;
    } else {
      updatedAddresses.push(currentAddress);
    }
    setAllAddresses(updatedAddresses);
    saveAddressChanges(updatedAddresses);
    setLoading(false);
    setOpen(false);
    if (setIsEditingAddresses) setIsEditingAddresses(false);
  };

  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[90%] md:w-[468px] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Business Address</div>
        <button onClick={() => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="grid gap-[16px]">
        <div>
          <label
            className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
            htmlFor="street_building"
          >
            Street & Building
          </label>
          <input
            className="px-[16px] py-[14px] rounded-[6px] border border-[#BEC2CE] focus:border-blue-70 w-full outline-none"
            type="text"
            placeholder="Street"
            id="street_building"
            name="address"
            value={currentAddress.address || ""}
            onChange={handleChange}
          />
        </div>
        <div>
          <label
            className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
            htmlFor="country"
          >
            Country
          </label>
          <CustomDropDown
            list={countryList}
            placeholder={"Country"}
            selectedValue={currentAddress.country || ""}
            setSelectedValue={(val) => {
              setCurrentAddress((prev) => ({
                ...prev,
                country: val,
                state: "",
                city: "",
              }));
            }}
          />
        </div>
        <div>
          <label
            className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
            htmlFor="state"
          >
            State
          </label>
          <CustomDropDown
            list={stateList}
            placeholder={"State"}
            selectedValue={currentAddress.state || ""}
            setSelectedValue={(val) => {
              setCurrentAddress((prev) => ({
                ...prev,
                state: val,
                city: "",
              }));
            }}
          />
        </div>
        <div className="flex gap-[12px]">
          <div className="basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="city"
            >
              City
            </label>
            <CustomDropDown
              list={cityList}
              placeholder={"City"}
              selectedValue={currentAddress.city || ""}
              setSelectedValue={(val) => {
                setCurrentAddress((prev) => ({
                  ...prev,
                  city: val,
                }));
              }}
            />
          </div>
          <div className="basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="zip_code"
            >
              Pin Code
            </label>
            <input
              className="px-[16px] py-[14px] rounded-[6px] border border-[#BEC2CE] focus:border-blue-70 w-full outline-none"
              type="number"
              placeholder="Zip Code"
              id="zip_code"
              name="zip"
              value={currentAddress.zip || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex gap-[4px] h-[24px] items-center">
          <div>
            <input
              style={inputCheckboxStyling}
              type="checkbox"
              checked={currentAddress.isPrimary || false}
              onChange={handlePrimaryChange}
            />{" "}
          </div>
          <label
            className="float-left font-medium text-sm leading-[21px] text-secondary pb-1"
            htmlFor="is_primary"
          >
            Update this as my primary location
          </label>
        </div>
      </div>
      <div className="flex gap-[12px]">
        <div
          className="text-secondary-60 basis-1/2 text-center border-[2px] border-secondary-60 px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] cursor-pointer justify-center"
          onClick={() => setOpen(false)}
        >
          Cancel
        </div>
        <div
          className={`bg-blue-70 text-white cursor-pointer basis-1/2 text-center px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] justify-center`}
          onClick={handleSave}
        >
          {loading ? <LoaderIcon /> : "Save"}
        </div>
      </div>
    </PopupContainer>
  );
};

export default BusinessAddressPopUp;
