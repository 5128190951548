import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { getProps } from './helperProps';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale)

dayjs.updateLocale("en",{
	weekStart : 1
})


function MiniCalendar(props) {

	const calendarProps = getProps(props)

	return (

		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateCalendar {...calendarProps} />
		</LocalizationProvider>

	)

}


export default MiniCalendar
