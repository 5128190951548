import { Outlet } from 'react-router-dom'
import styles from './SettingsV2.module.css'
import Sidebar from './Sidebar/Sidebar'
import { useState } from 'react'
import SettingsBackButton from './SettingsBackButton'
import AccountDropdownCard from '../../components/AccountDropdownCard/AccountDropdownCard'


const SettingsV2 = (props) => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(true)
    const [isAccountDDOpen, setIsAccountDDOpen] = useState(false)

    return (
        <div className={styles.container}>
            <div className={`${styles.sidebarContainer} ${isSidebarOpen ? styles.sidebarContainerMobileShow : styles.sidebarContainerMobileHide}`}>
                <Sidebar {...{ isSidebarOpen, setIsSidebarOpen, isAccountDDOpen, setIsAccountDDOpen }} />
            </div>
            <div className={styles.containerContainer}>
                <div className={styles.backButtonContainer}>
                    <SettingsBackButton {...{ setIsSidebarOpen }} />
                </div>
                <div className={styles.contentContainerMobile}>
                    {
                        !isSidebarOpen && isAccountDDOpen ?
                            <AccountDropdownCard outsideclassName={"md:hidden z-[1]"} showMobileProfile={true} setIsAccountDDOpen={setIsAccountDDOpen}/>
                            : <></>
                    }
                    {!isSidebarOpen && <Outlet />}
                </div>
                <div className={styles.contentContainerDesktop}>
                    <Outlet />
                </div>

            </div>
        </div>
    )
}

export default SettingsV2