export default function LeaveIcon (props) {

	return(
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_35331_28213)">
				<path d="M15.4992 8.00021H9.166C8.89001 8.00021 8.66602 7.77622 8.66602 7.50023C8.66602 7.22424 8.89001 7.00024 9.166 7.00024H15.4992C15.7752 7.00024 15.9992 7.22424 15.9992 7.50023C15.9992 7.77622 15.7752 8.00021 15.4992 8.00021Z" fill="currentColor"/>
				<path d="M12.9999 10.5003C12.8718 10.5003 12.7439 10.4516 12.6465 10.3537C12.4512 10.1582 12.4512 9.8416 12.6465 9.64629L14.7931 7.49975L12.6465 5.35309C12.4512 5.15779 12.4512 4.84115 12.6465 4.64584C12.8419 4.45041 13.1585 4.45041 13.3539 4.64584L15.8538 7.14576C16.0491 7.34107 16.0491 7.65771 15.8538 7.85301L13.3539 10.3529C13.2558 10.4516 13.1279 10.5003 12.9999 10.5003Z" fill="currentColor"/>
				<path d="M5.33321 16.5002C5.19051 16.5002 5.05514 16.4802 4.91989 16.4382L0.90793 15.1016C0.362049 14.9109 0 14.4023 0 13.8337V1.83406C0 1.09874 0.598004 0.500732 1.33333 0.500732C1.47591 0.500732 1.61128 0.520751 1.74665 0.562742L5.75849 1.89937C6.30449 2.09004 6.66642 2.59869 6.66642 3.16727V15.1669C6.66642 15.9022 6.06854 16.5002 5.33321 16.5002ZM1.33333 1.5007C1.14999 1.5007 0.999969 1.65072 0.999969 1.83406V13.8337C0.999969 13.9757 1.0953 14.1076 1.23128 14.155L5.22445 15.4856C5.25313 15.4949 5.29048 15.5003 5.33321 15.5003C5.51655 15.5003 5.66645 15.3502 5.66645 15.1669V3.16727C5.66645 3.02531 5.57111 2.89336 5.43513 2.846L1.44197 1.51535C1.41329 1.50607 1.37593 1.5007 1.33333 1.5007Z" fill="currentColor"/>
				<path d="M10.1669 5.8337C9.89095 5.8337 9.66696 5.6097 9.66696 5.33371V2.3338C9.66696 1.87447 9.29307 1.50046 8.83373 1.50046H1.33397C1.05798 1.50046 0.833984 1.27646 0.833984 1.00047C0.833984 0.72448 1.05798 0.500488 1.33397 0.500488H8.83373C9.84506 0.500488 10.6669 1.32248 10.6669 2.3338V5.33371C10.6669 5.6097 10.4429 5.8337 10.1669 5.8337Z" fill="currentColor"/>
				<path d="M8.83266 14.5H6.166C5.89001 14.5 5.66602 14.276 5.66602 14C5.66602 13.724 5.89001 13.5 6.166 13.5H8.83266C9.292 13.5 9.66589 13.126 9.66589 12.6666V9.66673C9.66589 9.39074 9.88988 9.16675 10.1659 9.16675C10.4419 9.16675 10.6659 9.39074 10.6659 9.66673V12.6666C10.6659 13.678 9.84399 14.5 8.83266 14.5Z" fill="currentColor"/>
			</g>
			<defs>
				<clipPath id="clip0_35331_28213">
					<rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>
	)

}
