import React, { useEffect, useMemo } from "react";
import frozenEventTypes from "../../utils/eventTypes";

const InfoTextArea = (props) => {

  useEffect(() => {
    if (props.infoWork && props.infoWork.trim() != "") props.setActive(true);
    else props.setActive(false);
  }, [props.infoWork]);
  
	const isEnabledEditing = useMemo(()=>{
		const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
		if(props.operationMode === "edit" && props.requiredEventData && nonSessionTypes.includes(props.selectedEventType?.toLowerCase())){
			if(props.requiredEventData.isEditingRequest){
				return props.requiredEventData.eventRequestor._id === props.user?.id
			}
			return props.requiredEventData.initiatorDetails.id === props.user?.id
		}
		return ["admin","tutor"].includes(props.user?.role) || props.origin && props.origin === "schedule"
  },[props.user?.role])


	console.log("POOO",props.origin)


	if(!isEnabledEditing){
		return	(
			<div className="flex flex-col items-start justify-start gap-[12px]">
				{props?.infoWork}
			</div>
		)
	}

  return (
    <div>
      <textarea
        className={`md:w-[496px] md:h[${props.height ? props.height : "240px"}] w-full min-h-[120px] py-[7.5px] outline-none px-3 rounded-[6px] bg-[#F6F9FA] font-normal text-[14px] leading-[21px] text-secondary placeholder:text-secondary-60`}
        value={props.infoWork}
        placeholder = { props?.user &&  props?.user?.role != "student" && props?.user?.role != "parent" ?  "Details of work" : "Any additional details " }
        onChange={(e) => props.setInfoWork(e.target.value)}
      />
    </div>
  );
};

export default InfoTextArea;
