import styles from "./InitialScreen.module.css";
import OAuthButton from "../../OAuthButton/OAuthButton";
import ButtonV2 from "../../ButtonV2/ButtonV2";
import dividerLine from "./DividerLine.svg";
import googleLogo from "./GoogleLogo.svg";
import msLogo from "./MSLogo.svg";
import ArrowIconLeftV2 from "../../ArrowIconLeftV2/ArrowIconLeftV2";
import ArrowIconRightV2 from "../../ArrowIconRightV2/ArrowIconRightV2";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  useCompleteEmailVerificationMutation,
  useStartEmailVerificationMutation,
} from "../../../app/services/orgSignup";
import OrgSignupContext from "../../../context/OrgSignupContext";
import { toast } from "react-toastify";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { triggerToast } from "../../../utils/toastController";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const InitalScreen = (props) => {
  const { setSignupStage } = useOutletContext();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [startEmailVerification, startEmailVerificationResp] =
    useStartEmailVerificationMutation();
  const { userEmail, setUserEmail, registerationAs } =
    useContext(OrgSignupContext);
  const [isLoading, setIsLoading] = useState(false);
  const [completeEmailVerification] = useCompleteEmailVerificationMutation();

  const navigate = useNavigate();

  const handleFormChange = (e) => {
    if (e.target.name === "email") {
      const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      setIsEmailValid(emailFormat.test(e.target.value.trim()));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value.trim();
    setIsLoading(true);
    setUserEmail(email);
    startEmailVerification({
      email: email,
      registrationAs: registerationAs,
    }).then((res) => {
      if (res.error) {
        if (res.error.status === 400) {
          triggerToast(
            <div>
              An account with this email already exists.{" "}
              <Link to="/signin">Click here</Link> to go to the login-screen.
            </div>,
            "error",
            { autoClose: false }
          );
        }
      } else {
        navigate("../verify-email");
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setSignupStage("initial");
  }, []);

  const onGoogleSuccess = async (googleToken) => {
    try {
      const resAfterVerif = await completeEmailVerification({
        accessToken: googleToken.access_token,
        oauth: true,
        mode: "google",
        op: "verify",
      });

      if (resAfterVerif.error) {
        if (resAfterVerif.error.status === 400) {
          toast.info("Account already verified, redirecting to login", {
            position: "top-center",
            autoClose: 3000,
            onClose: () => {
              navigate("/signin");
            },
          }); //Redirect to login here
        } else if (resAfterVerif.error.status === 401) {
          //Redirect to appropriate pages
          if (resAfterVerif.error.data.message === "EXPIRED") {
            navigate("../expired-otp");
          } else {
            toast.error("Invalid credentials, please try again", {
              position: "top-center",
              autoClose: 3000,
            });
          }
        }
      }
      if (!resAfterVerif.error) {
        setUserEmail(resAfterVerif.data.data.email);
        window.localStorage.setItem(
          "set_password_token",
          resAfterVerif.data.data.token
        );
        navigate("../set-new-password");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onGoogleFailure = (res) => {
    console.log(res);
  };

  const googleSignIn = useGoogleLogin({
    onSuccess: onGoogleSuccess,
    onFailure: onGoogleFailure,
    clientId:
      "316191323811-693kdt97bn7960b9gdtscnr8e8u23r26.apps.googleusercontent.com",
  //   scope:
  //     "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/meetings.space.created",
  //
   });

  const { instance, accounts } = useMsal();

  const onMicrosoftSuccess = async () => {
    if (accounts.length === 0) {
      // Prompt the user to sign in if no account is active
      try {
        await instance.loginPopup({
          scopes: [
            "User.Read",
            "Calendars.ReadWrite.Shared",
            "OnlineMeetings.ReadWrite",
          ],
        });
      } catch (error) {
        console.error("Login error:", error);
        return;
      }
    }

    // Ensure there's at least one account available
    if (accounts.length === 0) {
      console.error("No accounts available.");
      return;
    }

    const activeAccount = accounts[0];
    instance.setActiveAccount(activeAccount);

    try {
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: [
          "User.Read",
          "Calendars.ReadWrite.Shared",
          "OnlineMeetings.ReadWrite",
        ],
        account: activeAccount,
      });

      const resAfterVerif = await completeEmailVerification({
        accessToken: tokenResponse.accessToken,
        oauth: true,
        mode: "microsoft",
        op: "verify",
      });

      if (resAfterVerif.error) {
        if (resAfterVerif.error.status === 400) {
          toast.info("Account already verified, redirecting to login", {
            position: "top-center",
            autoClose: 3000,
            onClose: () => {
              navigate("/signin");
            },
          });
        } else if (resAfterVerif.error.status === 401) {
          if (resAfterVerif.error.data.message === "EXPIRED") {
            navigate("../expired-otp");
          } else {
            toast.error("Invalid credentials, please try again", {
              position: "top-center",
              autoClose: 3000,
            });
          }
        }
      } else {
        setUserEmail(resAfterVerif.data.data.email);
        window.localStorage.setItem(
          "set_password_token",
          resAfterVerif.data.data.token
        );
        navigate("../set-new-password");
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const interactiveTokenResponse = await instance.acquireTokenPopup({
            scopes: [
              "User.Read",
              // "Calendars.ReadWrite.Shared",
              // "OnlineMeetings.ReadWrite",
            ],
          });

          const resAfterVerif = await completeEmailVerification({
            accessToken: interactiveTokenResponse.accessToken,
            oauth: true,
            mode: "microsoft",
            op: "verify",
          });

          if (resAfterVerif.error) {
            if (resAfterVerif.error.status === 400) {
              toast.info("Account already verified, redirecting to login", {
                position: "top-center",
                autoClose: 3000,
                onClose: () => {
                  navigate("/signin");
                },
              });
            } else if (resAfterVerif.error.status === 401) {
              if (resAfterVerif.error.data.message === "EXPIRED") {
                navigate("../expired-otp");
              } else {
                toast.error("Invalid credentials, please try again", {
                  position: "top-center",
                  autoClose: 3000,
                });
              }
            }
          } else {
            setUserEmail(resAfterVerif.data.data.email);
            window.localStorage.setItem(
              "set_password_token",
              resAfterVerif.data.data.token
            );
            navigate("../set-new-password");
          }
        } catch (interactiveError) {
          console.error(
            "Interactive token acquisition error:",
            interactiveError
          );
        }
      } else {
        console.error("Token acquisition error:", error);
      }
    }
  };

  const outlookSignIn = () => {
    instance
      .loginPopup({
        scopes: [
          "User.Read",
          "Calendars.ReadWrite.Shared",
          "OnlineMeetings.ReadWrite",
        ],
      })
      .then(onMicrosoftSuccess)
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className={styles.container}>
      <h3>Let's create your account</h3>
      <div className={styles.buttonContainer}>
        <OAuthButton onClick={() => googleSignIn()}>
          <img src={googleLogo} className="pointer-events-none" />{" "}
          <div className="pointer-events-none">
            <span className={styles.buttonText}>Continue with</span> Google
          </div>
        </OAuthButton>
        <OAuthButton onClick={() => outlookSignIn()}>
          <img src={msLogo} className="pointer-events-none" />{" "}
          <div className="pointer-events-none">
            <span className={styles.buttonText}>Continue with </span> Outlook
          </div>
        </OAuthButton>
      </div>
      <div className={styles.divider}>
        <img src={dividerLine} />
        <div>or</div>
        <img src={dividerLine} />
      </div>
      <form
        className={styles.verifyForm}
        onSubmit={(e) => {
          handleFormSubmit(e);
        }}
      >
        <input
          type="text"
          className={styles.emailInput}
          name="email"
          placeholder="Enter your email address"
          onChange={(e) => handleFormChange(e)}
        />
        <ButtonV2 disabled={!isEmailValid}>
          {!isLoading ? (
            <div className={styles.verifyButtonContent}>
              <div>Verify Email</div>
              <div>
                <ArrowIconRightV2 />
              </div>
            </div>
          ) : (
            <div className="w-full flex justify-center items-center">
              <LoaderIcon color={"white"} />
            </div>
          )}
        </ButtonV2>
      </form>
      <div className={styles.signinCTA}>
        Have an existing account? <Link to="/signin">Sign In</Link>
      </div>
      <div className={styles.disclaimer}>
        By continuing, you confirm that you have read, understood and agree to
        our <a href="https://evallo.org/tou">Terms of Use</a> and{" "}
        <a href="https://evallo.org/privacy-policy">Privacy Policy</a>
      </div>
      <button
        className={styles.backButton}
        onClick={(e) => window.history.back()}
      >
        <div className={styles.content}>
          <div>
            <ArrowIconLeftV2 />
          </div>
          <div>Back</div>
        </div>
      </button>
    </div>
  );
};

export default InitalScreen;
