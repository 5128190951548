import frozenEventTypes from "../../../utils/eventTypes";
import { triggerToast } from "../../../utils/toastController";
//If these get too big extract out to a different file

// ---- START SESSION VALIDATOR -----
function sessionValidator(eventData) {
  let isValid = true;

  if (!eventData.service || !eventData.selectedStatus) {
    isValid = false;
  }
  if (!eventData.selectedTutor) {
    isValid = false;
  }
  if (
    eventData.selectedOption === "student" &&
    (!eventData.selectedStudents || eventData.selectedStudents.length === 0)
  ) {
    isValid = false;
  }
  if (eventData.selectedOption === "studentGroup" && !eventData.selectedGroup) {
    isValid = false;
  }
  if (eventData.isRecurring) {
    if (!eventData.endDate) {
      isValid = false;
    }
    if (
      (eventData.frequency.toLowerCase() === "weekly" ||
        eventData.frequency.toLowerCase() === "bi-weekly") &&
      eventData.selectedDay.length === 0
    ) {
      isValid = false;
    }
    if (
      eventData.frequency.toLowerCase() === "monthly" &&
      !eventData.selectedMonthlyRecurringDate
    ) {
      isValid = false;
    }
  }
  console.log("VC", isValid);
  return isValid === false;
}
// ---- END SESSION VALIDATOR --------

function consultationValidator(eventData) {
  let isValid = true;

  if (!eventData.selectedDate) {
    isValid = false;
  }
  if (!eventData.isSelectedUser.length > 0) {
    isValid = false;
  }
  if (eventData.isRecurring) {
    if (!eventData.endDate) {
      isValid = false;
    }
    if (
      (eventData.frequency.toLowerCase() === "weekly" ||
        eventData.frequency.toLowerCase() === "bi-weekly") &&
      eventData.selectedDay.length === 0
    ) {
      isValid = false;
    }
    if (
      eventData.frequency.toLowerCase() === "monthly" &&
      !eventData.selectedMonthlyRecurringDate
    ) {
      isValid = false;
    }
  }
  console.log("VC", isValid);
  return isValid === false;
}

function internalMeetingValidator(eventData) {}

function officeHoursValidator(eventData) {}


function bookedMeetingValidator(props){
	
	let isValid = true

	if(props.isSelecteUser?.length < 1){
		isValid = false
	}

	return isValid === false

}

function validatorController(eventType) {
  switch (eventType) {
    case frozenEventTypes.SESSION.eventType:
      return sessionValidator;
    case frozenEventTypes.INTMEETING.eventType:
      return internalMeetingValidator;
    case frozenEventTypes.OFFCWORK.eventType:
      return officeHoursValidator;
    case frozenEventTypes.CONSULTATION.eventType:
      return consultationValidator;
	case frozenEventTypes.EXTMEETING.eventType.toLowerCase() : 
		return bookedMeetingValidator;
	default : 
		return ()=>{}
  }
}

export default validatorController;
