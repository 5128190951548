import React, { useState, useEffect, useRef } from "react";
import YourPlanCard from "./components/YourPlanCard";
import ProductCardInsights from "./components/ProductCardInsights";
import CRM from "./assets/CRM.svg";
import Assignments from "./assets/Assignments.svg";
import Calendar from "./assets/Calendar.svg";
import CustomContent from "./assets/CustomContent.svg";
import { useLazyGetPricesV2Query } from "../../app/services/subscription";
import AnnualPackagePromotion from "./components/AnnualPackagePromotion";
import ActiveIndicator from "../../components/ActiveIndicator/ActiveIndicator";
import HorizontalDivider from "../../components/HorizontalDivider/HorizontalDivider";
import ReceiptIcon from "../../components/ReceiptIcon/ReceiptIcon";
import SubscriptionTable from "../../components/SubscriptionTable/SubscriptionTable";
import SummaryCornerSub from "../../components/SummaryCornerSub/SummaryCornerSub";
import SupportCornerSub from "../../components/SupportCornerSub/SupportCornerSub";
import ToggleButtonV2 from "../../components/ToggleButtonV2/ToggleButtonV2";
import styles from "./ManageSubscription.module.css";
import PopupContainer from "../../components/PopupContainer/PopupContainer";
import CancelSubPopUp from "../../components/PopUpsSubs/CancelSubPopUp/CancelSubPopUp";
import TutorLicensePopUp from "../../components/PopUpsSubs/TutorLicensesPopUp/TutorLicensePopUp";
import BasePackageChangePopUp from "../../components/PopUpsSubs/BasePackageChangePopUp/BasePackageChangePopUp";
import DisablePayGPopUp from "../../components/PopUpsSubs/DisablePayGPopUp/DisablePayGPopUp";
import { useLazySubscriptionInsightsQuery } from "../../app/services/subscription";
import { fetchAndSetSubscription } from "../../utils/subscriptionService";
import explorerIcon from "../../components/OrgSignupV2/ChoosePlanScreen/ExplorerIcon.svg";
import starterIcon from "../../components/OrgSignupV2/ChoosePlanScreen/StarterIcon.svg";
import professionalIcon from "../../components/OrgSignupV2/ChoosePlanScreen/ProfessionalIcon.svg";
import premiumIcon from "../../components/OrgSignupV2/ChoosePlanScreen/PremiumIcon.svg";
import { useSelector } from "react-redux";
import formSummaryObject from "./Utils/formSummaryObject";
import getCorrespondingPriceId from "./Utils/getCorrespondingProducts";
import {
  calculateTotalPrice,
  fetchInvoicePreviewForUpdate,
  formUpdateRequest,
} from "./Utils/updateRequestForSub";
import {
  configurePAYGTableData,
  configureSubscriptionTableData,
} from "./Utils/configureTableData";
import { useUpdateOrgDataMutation } from "../../app/services/admin";
import { toast } from "react-toastify";
import organization from "../../app/slices/organization";
import RetryPaymentWarning from "./components/RetryPaymentWarning";
const _ = require("lodash");
import LoaderIcon from "../../components/LoaderIcon/LoaderIcon";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import PaginationV2 from "../../components/PaginationV2/PaginationV2";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import PageIndicatorV2 from "../../components/PaginationV2/PageIndicatorV2";
import AddNewCardPopUp from "../../components/PopUpsSubs/AddNewCardPopUp/AddNewCardPopUp";
import { useSearchParams } from "react-router-dom";
import { triggerToast } from "../../utils/toastController";
import { fetchLookupKeyForId } from "./Utils/fetchers";
import Tooltip from "../../components/Tooltip/Tooltip";
import InfoIcon from "../../components/InfoIcon/InfoIcon";
import UpdateSubConfirmPopUp from "../../components/PopUpsSubs/UpdateSubConfirmPopUp/UpdateSubConfirmPopUp";
import { useLazyGetAllUsersQuery } from "../../app/services/users";
import { changeTutorStatus } from "../../utils/tutorService";

//For persistent siMapping
function fetchTempSiMapping() {
  const siMapping = window.localStorage.getItem("siMapping");
  if (siMapping) {
    return JSON.parse(siMapping);
  } else {
    return null;
  }
}

const cardBrands = [
  "amex",
  "diners",
  "discover",
  "eftpos_au",
  "jcb",
  "mastercard",
  "unionpay",
  "visa",
];

const __paygTableData = {
  header: [
    { text: "Product", format: "!text-left" },
    { text: "Status" },
    { text: "Started on" },
    { text: "Overage usage" },
    {
      text: (
        <div className="flex items-center justify-center">Overage charges</div>
      ),
    },
    { text: "Amount" },
  ],
  body: [
    {
      data: [
        "Calendar",
        <ActiveIndicator isActive={true} />,
        "Apr 11, 2024",
        "17 sessions",
        "3ć/session",
        <div className="font-medium">$1.20</div>,
      ],
      status: true,
    },
    {
      data: [
        "Assignments",
        "Inactive",
        "Apr 11, 2024",
        "17 sessions",
        "3ć/session",
        "$1.20",
      ],
      status: false,
    },
  ],
  columns: 6,
  gridConfigClass: "grid-cols-[repeat(6,auto)]",
  gridHorizontalDividerClass: "col-span-6",
};
const __transactionsTableData = {
  header: [
    { text: "Invoice Id", format: "!text-left" },
    { text: "Amount", format: "!text-left" },
    { text: "Paid on", format: "text-center" },
    { text: "Payment Mode", format: "text-center" },
    { text: "Invoice", format: "text-center" },
  ],
  body: [
    {
      data: [
        "Starter-Monthly Renewal",
        "$23.80",
        "Apr 11, 2024",
        "****8297",
        <button className={styles.downloadButton}>Download</button>,
      ],
      status: true,
    },
    {
      data: [
        "$23.80",
        "Starter-Monthly Renewal",
        "Apr 11, 2024",
        "****8297",
        <button className={styles.downloadButton}>Download</button>,
      ],
      status: true,
    },
  ],
  columns: 6,
  gridConfigClass: "grid-cols-[repeat(5,auto)]",
  gridHorizontalDividerClass: "col-span-5",
};

function getPopUpForType(popUpType, data) {
  switch (popUpType) {
    case "cancel-sub":
      return <CancelSubPopUp setPopUpTriger={data.setPopUpTriger} />;
    case "tut-license":
      return (
        <TutorLicensePopUp
          {...data}
          extraTutors={2}
          baseTutors={10}
          pricePerTutor={2}
          period={"mo"}
          oldPrice={"$10"}
          newPrice={"$20"}
          oldPriceHelper={"for 10 Tutor Licenses"}
          newPriceHelper={"for 12 Tutor Licenses"}
        />
      );
    case "change-base-pckg":
      return (
        <BasePackageChangePopUp
          extraTutors={2}
          selectedBillingCycle={0}
          {...data}
        />
      );
    case "disable-payg":
      return (
        <DisablePayGPopUp
          limitExhaused={data.limitExhaused}
          _setIsEnabledPAYG={data._setIsEnabledPAYG}
          setPopUpTriger={data.setPopUpTriger}
        />
      );
    case "add-new-card":
      return <AddNewCardPopUp setPopUpTriger={data.setPopUpTriger} setUpdateSubContinueClicked={data.setUpdateSubContinueClicked} />;
    case "update-sub" :
      return <UpdateSubConfirmPopUp  setPopUpTriger={data.setPopUpTriger} handleContinueClick = {(e) => {data.setUpdateSubContinueClicked(true);data.setPopUpTriger(false);} }/>;
  }
}

const ManageSubscription = (props) => {
  const [count, setCount] = useState(false);

  const [fetchPrices, fetchPricesResp] = useLazyGetPricesV2Query();
  const [productPrices, setProductPrices] = useState(null);
  const [subscriptionInsights, setSubscriptionInsights] = useState(null);
  const [augmentedProducts, setAugmentedProducts] = useState(null);
  const [popUpTrigger, setPopUpTriger] = useState(true);

  const [billingCycleChangedFromOutside, setBillingCycleChangedFromOutside] = useState(false)

  const [popUpType, setPopUpType] = useState(null);
  const [planType, setPlanType] = useState(0);
  const [basePackages, setBasePackages] = useState();
  const [selectedBillingCycle, setSelectedBillingCycle] = useState(0);
  const { subscriptionV2 } = useSelector((state) => state.subscription);
  const { isEnabledPAYG, _id: orgId } = useSelector(
    (state) => state.organization.organization
  );
  const [siMapping, setSiMapping] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);

  const [summaryObject, setSummaryObject] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [validCoupon, setValidCoupon] = useState(false);
  const [subscriptionCoupon, setSubscriptionCoupon] = useState();

  const [subscriptionTableData, setSubscriptionTableData] = useState(null);
  const [paygTableData, setPaygTableData] = useState(null);

  const [_isEnabledPAYG, _setIsEnabledPAYG] = useState(false);
  const [fromToggle, _setIsFromToggle] = useState(false);
  const [updateOrgData] = useUpdateOrgDataMutation();
  const [updateRequest, setUpdateRequest] = useState();
  const [selectPrice, setSelectPrice] = useState({
    Assignments: null,
    Calendar: null,
    CustomContent: null,
  });

  const [isExpiredSubscription, setIsExpiredSubscription] = useState(
    subscriptionV2?.subscription?.isActive
  );

  const [isLoading, setIsLoading] = useState(true);

  const [selectedPageSubscriptionTable, setSelectedPageSubscriptionTable] =
    useState(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const[ updateButtonClicked, setUpdateButtonClicked] = useState(false)

  const [updateSubContinueClicked, setUpdateSubContinueClicked] = useState(false)
  const [fetchAllUsersQuery, ] = useLazyGetAllUsersQuery()

  useEffect(() => {
    if (!subscriptionV2) return;

    setIsExpiredSubscription(!subscriptionV2.subscription?.isActive);
  }, [subscriptionV2?.subscription]);

  //To auto deassign tutors
  useEffect(()=>{
    if(!subscriptionV2?.subscription) return;
    (async()=>{
      try {
        const res = await fetchAllUsersQuery("?role=tutor&role=admin")
        if(!res.error){
          const allTutors = res.data.data.user
          //Admin is considered here as its included in the licenses
          const countOfActiveTutors = allTutors.filter((user)=>user.userStatus === "active").length
          const tutorLimit = subscriptionV2?.subscription?.tutors?.limit
          console.log(countOfActiveTutors,tutorLimit)
          if(tutorLimit < countOfActiveTutors){
              //Admins are not included here as we only need to deactive tutors
              const tutorIdsToDeassign = allTutors.filter((user)=>user.userStatus === "active" && user.role === "tutor").slice(tutorLimit - 1).map((user)=>user._id)
              await changeTutorStatus(tutorIdsToDeassign, "inactive")
          }
          setTimeout(async ()=>{
            try {
              const res2 = await getSubscriptionInsights()
              if(!res2.error)  {
                console.log("I>>>SIGHTS",res2);
                setSubscriptionInsights(res2.data.data);
                setSubscriptionTableData(
                  configureSubscriptionTableData(res2.data.data.invoices)
                );
                setPaygTableData(
                  configurePAYGTableData(
                    res2.data.data.usage,
                    res2.data.data.usageRecords
                  )
                );
              }
            }catch(err){
              console.log(err)
            }
          },5000)

        }
      }catch(err){
        console.log(err)
      }
    })()
  },[subscriptionV2?.subscription])

  useEffect(() => {
    if (searchParams.get("purchaseTutors") === "true") {
      setPopUpType("tut-license");
    }
  }, [searchParams]);

  const Products = [
    {
      name: "CRM",
      tooltipContent: "Includes unlimited student and parent accounts, along with students groups as per the package chosen. Other features include advanced filters, bulk-user management, data export, profiles, notes, and tutor mapping.",
      lookupKey: "CRM",
      title: "Unlimited Benefits",
      priceList: null,
      img: CRM,
      color: "#2793cd",
    },
    {
      name: "Calendar",
      tooltipContent: "You have chosen {X} Calendar sessions to be included with your subscription.",
      lookupKey: "Calendar",
      title: "Sessions/mo",
      priceList: [
        { name: "1500 nos.- $30", value: 30 },
        { name: "1500 nos.- $30", value: 30 },
      ],
      img: Calendar,
      color: "#EE778C",
      priceId: "price_1P5kREBaNMStIONGqNTt1bhO",
    },
    {
      name: "Assignments",
      tooltipContent: "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
      lookupKey: "Assignments",
      title: "Credits/mo",
      priceList: [{ name: "15 nos.- $0", value: 0 }],
      img: Assignments,
      color: "#8D54D7",
      priceId: "price_1P4fGeBaNMStIONGoT9gwN9M",
    },
    {
      name: "Custom Tests",
      tooltipContent: `Refers to custom tests and material added by you in the "Content" tab to be used exclusively for your account.`,
      lookupKey: "CustomContent",
      title: "Tests/mo",
      priceList: [{ name: "Unlimited - $8", value: 8 }],
      img: CustomContent,
      color: "#17A289",
      priceId: "price_1P5kWABaNMStIONGlzgTUdGU",
    },
  ];
  const productInsights = {
    Calendar: {
      insights: {
        remainingFromLimit: null,
        limit: null,
        heading: "Tests remaining",
        percentage: null,
      },
    },
    Assignments: {
      insights: {
        remainingFromLimit: null,
        limit: null,
        heading: "Tests remaining",
        percentage: null,
      },
    },
    "Custom Tests": {
      insights: {
        remainingFromLimit: null,
        limit: null,
        heading: "Content remaining",
        percentage: null,
      },
    },
  };

  const [getSubscriptionInsights, respGetSubscriptionInsights] =
    useLazySubscriptionInsightsQuery();

  const refContainer1 = useRef()
  const refContainer2 = useRef()

  useEffect(() => {
    setCount(1);
  }, []);

  useEffect(()=>{
      if (!_.isEqual(siMapping,subscriptionV2?.siMapping) && !updateButtonClicked) {
          window.onbeforeunload = function() {
              return true;
          };
      }
  
      return () => {
          window.onbeforeunload = null;
      };
  },[siMapping,subscriptionV2,updateButtonClicked])


  useEffect(() => {
    if (refContainer1.current) {
      document.getElementById("container1").addEventListener("scroll", () => {
        setTimeout(() => {
          document.getElementById("container1").classList.remove(`${styles.hideScrollBar}`)
          document.getElementById("container1").classList.add(`${styles.showScrollBar}`)
        }, 0)
      })

      document.getElementById("container1").addEventListener("scrollend", () => {
        setTimeout(() => {
          document.getElementById("container1").classList.remove(`${styles.showScrollBar}`)
          document.getElementById("container1").classList.add(`${styles.hideScrollBar}`)
        }, 5000)
      })
    }
  }, [refContainer1.current])


  useEffect(() => {
    if (refContainer2.current) {
      document.getElementById("container2").addEventListener("scroll", () => {
        setTimeout(() => {
          document.getElementById("container2").classList.remove(`${styles.hideScrollBar}`)
          document.getElementById("container2").classList.add(`${styles.showScrollBar}`)
        }, 0)

      })

      document.getElementById("container2").addEventListener("scrollend", () => {
        setTimeout(() => {
          document.getElementById("container2").classList.remove(`${styles.showScrollBar}`)
          document.getElementById("container2").classList.add(`${styles.hideScrollBar}`)
        }, 5000)
      })
    }
  }, [refContainer2.current])

  useEffect(() => {
    _setIsEnabledPAYG(isEnabledPAYG);
  }, [isEnabledPAYG]);

  useEffect(() => {
    if (!subscriptionV2) return;
    console.log("Sbs", subscriptionV2.subscription.__t);
    setSelectedBillingCycle(
      subscriptionV2.subscription.__t === "MonthlySubscription" ? 0 : 1
    );
    setPlanType(
      subscriptionV2.subscription.__t === "MonthlySubscription" ? 0 : 1
    );
  }, [subscriptionV2]);

  //Fetch the prices from the API
  useEffect(() => {
    fetchPrices()
      .then((res) => {
        const prices = res.data.data.prices;
        setProductPrices(prices);
        fetchAndSetSubscription();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //fetch Insights from API
  useEffect(() => {
    getSubscriptionInsights()
      .then((res) => {
        setSubscriptionInsights(res.data.data);
        setSubscriptionTableData(
          configureSubscriptionTableData(res.data.data.invoices)
        );
        setPaygTableData(
          configurePAYGTableData(
            res.data.data.usage,
            res.data.data.usageRecords
          )
        );
      })
      .catch((err) => {
        console.log("insights error", err);
      });
  }, []);

  const [insightsData, setInsightsData] = useState(productInsights);

  useEffect(() => {
    if (!subscriptionInsights) return;
    console.log(">>>INSIGHTS", subscriptionInsights);

    const updatedInsightsData = Object.entries(productInsights).map(
      ([key, value]) => {
        if (key === "Calendar") {
          return {
            [key]: {
              ...value,
              insights: {
                remainingFromLimit:
                  subscriptionInsights?.limits.sessions.base -
                  subscriptionInsights?.usage.sessions.base,
                limit: subscriptionInsights?.limits.sessions.base,
                heading: "Sessions remaining",
                percentage: calculatePercentage(
                  subscriptionInsights?.limits.sessions.base -
                  subscriptionInsights?.usage.sessions.base,
                  subscriptionInsights?.limits.sessions.base
                ),
              },
            },
          };
        } else if (key === "Assignments") {
          return {
            [key]: {
              ...value,
              insights: {
                remainingFromLimit:
                  subscriptionInsights?.limits.assignments.base -
                  subscriptionInsights?.usage.assignments.base,
                limit: subscriptionInsights?.limits.assignments.base,
                heading: "Tests remaining",
                percentage: calculatePercentage(
                  subscriptionInsights?.limits.assignments.base -
                  subscriptionInsights?.usage.assignments.base,
                  subscriptionInsights?.limits.assignments.base
                ),
              },
            },
          };
        } else if (key === "Custom Tests") {
          return {
            [key]: {
              ...value,
              insights: {
                remainingFromLimit:
                  subscriptionInsights?.limits.customContent.base -
                  subscriptionInsights?.usage.customContent.base,
                limit: subscriptionInsights?.limits.customContent.base,
                heading: "Content remaining",
                percentage: calculatePercentage(
                  subscriptionInsights?.limits.customContent.base -
                  subscriptionInsights?.usage.customContent.base,
                  subscriptionInsights?.limits.customContent.base
                ),
              },
            },
          };
        } else {
          return { [key]: value };
        }
      }
    );

    setInsightsData(Object.assign({}, ...updatedInsightsData));
  }, [subscriptionInsights]);

  //Set up all the basePackages that will be offered
  useEffect(() => {
    if (!productPrices) return;
    if (!subscriptionV2) return;
    const product = productPrices.find((product) =>
      product.product.name.includes("BasePackage")
    );

    const annualPackages = product.prices.annual;
    const monthlyPackages = product.prices.monthly;

    const packages = {
      monthly: [...monthlyPackages],
      annual: [...annualPackages],
    };

    const tempPackages = {
      monthly: [null, null, null, null],
      annual: [null, null, null, null],
    };

    Object.keys(packages).forEach((key) => {
      packages[key].forEach((val) => {
        const tempVal = { ...val };
        if (tempVal.lookup_key.includes("Starter")) {
          tempVal.img = starterIcon;
          tempVal.packageName = "Starter";
          tempVal.helperText = `Starting at per \$${key === "monthly"
            ? val.unit_amount / 100
            : (val.unit_amount / (12 * 100)).toFixed(2)
            } per month`;
          tempPackages[key][1] = tempVal;
        } else if (tempVal.lookup_key.includes("Premium")) {
          tempVal.img = premiumIcon;
          tempVal.packageName = "Premium";
          tempVal.helperText = `Starting at per  \$${key === "monthly"
            ? val.unit_amount / 100
            : (val.unit_amount / (12 * 100)).toFixed(2)
            } per month`;
          tempPackages[key][3] = tempVal;
        } else if (tempVal.lookup_key.includes("Professional")) {
          tempVal.img = professionalIcon;
          tempVal.packageName = "Professional";
          tempVal.helperText = `Starting at per  \$${key === "monthly"
            ? val.unit_amount / 100
            : (val.unit_amount / (12 * 100)).toFixed(2)
            } per month`;
          tempPackages[key][2] = tempVal;
        } else {
          tempVal.img = explorerIcon;
          tempVal.packageName = "Explorer";
          tempVal.helperText = "Lifetime Free";
          tempPackages[key][0] = tempVal;
        }

        if (
          tempVal.lookup_key === subscriptionV2.subscription.basePackage.name
        ) {
          tempVal.isCurrentPlan = true;
        }

        // tempPackages[key].push(tempVal);
      });
    });

    setBasePackages(tempPackages);
  }, [productPrices, subscriptionV2]);

  //Testing
  useEffect(() => {
    console.log(">>>SUB", subscriptionV2);
    if (subscriptionV2) {
      setSiMapping(subscriptionV2.siMapping);
    }
  }, [subscriptionV2]);

  useEffect(() => {
    if (!productPrices) return;

    const tempProductPrices = [...productPrices];
    const requiredProductsMapping = {
      Calendar: { productName: "CalendarV2" },
      "Custom Tests": { productName: "CustomContentV2" },
      Assignments: { productName: "AssignmentsV2" },
    };
    console.log(">>>PRODUCT PRICES", productPrices);

    Products.forEach((product, _idx) => {
      if (_idx === 0) return;
      const requiredProduct = tempProductPrices.find(
        (_product, __idx) =>
          _product.product.name ===
          requiredProductsMapping[product.name].productName
      );
      const tempPriceList = {};
      for (const planType in requiredProduct.prices) {
        tempPriceList[planType] = [];
        const prices = requiredProduct.prices[planType];
        const tempPrices = [];
        prices.forEach((price) => {
          const tempPrice = {
            ...price,
            name: `${price.metadata.limit === "-1" ? "Unlimited" : price.metadata.limit
              } nos.- \$${Math.round(price.unit_amount / 100)}`,
            value: price.id,
          };
          tempPrices.push(tempPrice);
        });
        tempPriceList[planType] = tempPrices;
      }
      product.priceList = tempPriceList[
        planType === 0 ? "monthly" : planType === 1 ? "annual" : ""
      ].filter((price, __idx) => !price.lookup_key.includes("PAYG"));

      if (product.name === "Assignments" || product.name === "Calendar") {
        let tempTooltipContent = product.tooltipContent.split("{X}")
        if (product.name === "Assignments") {
          tempTooltipContent[0] += subscriptionV2?.subscription.assignments.limit
        } else if (product.name === "Calendar") {
          tempTooltipContent[0] += subscriptionV2?.subscription.sessions.limit
        }
        tempTooltipContent = tempTooltipContent.join("")
        product.tooltipContent = tempTooltipContent
      }
    });

    const tempProducts = [...Products];
    setAugmentedProducts([...tempProducts]);
  }, [productPrices, planType, subscriptionV2]);

  function calculatePercentage(remainingFromLimit, limit) {
    if (limit === 0) {
      return 0;
    }
    if (remainingFromLimit === 0) {
      return 0;
    }
    const remainingPercentage = (remainingFromLimit / limit) * 100;
    return Math.max(0, Math.min(100, remainingPercentage));
  }

  //Form the summary object on the change of different products
  useEffect(() => {
    if (!siMapping || !subscriptionV2) return;
    const tempSummaryObject = formSummaryObject(
      siMapping,
      setSiMapping,
      subscriptionV2,
      productPrices,
      selectedBillingCycle,
      setSelectedBillingCycle
    );
    console.log(">>>TSO", tempSummaryObject);
    setSummaryObject(
      tempSummaryObject?.length === 0 ? null : tempSummaryObject
    );
  }, [siMapping, subscriptionV2]);

  useEffect(() => {
    console.log("Changed", siMapping);
  }, [siMapping]);

  //Update PAYG in the subscription
  useEffect(() => {
    if (!fromToggle) return;
    updateOrgData({
      op: "update",
      orgId: orgId,
      fields: {
        isEnabledPAYG: _isEnabledPAYG,
      },
    }).then((res) => {
      if (res.error) {
        toast.error("Update failed");
        console.log(res.error);
      } else {
        console.log("ORG UPDATE PAYG DONE");
        if (_isEnabledPAYG) {
          triggerToast(
            "Pay As You Go is now enabled for your subscription!",
            "success",
            { autoClose: 3000 }
          );
        } else {
          triggerToast(
            "Pay As You Go is now disabled for your subscription!",
            "error",
            { autoClose: 3000 }
          );
        }
      }
    });
  }, [_isEnabledPAYG]);

  //Forms udpate request for the upcoming invoice as well as the actual subscription update
  useEffect(() => {
    console.log(">>>REQ SENT");
    if (!siMapping || subscriptionV2?.isActive === false) return;
    (async () => {
      try {
        //Add for edge case of subscription from monthly to annual & vice versa to remove & add PAYG respectively
        const type = selectedBillingCycle === 0 ? "monthly" : "annual";
        const oldType =
          subscriptionV2.subscription.__t === "MonthlySubscription"
            ? "monthly"
            : "annual";
        const _updateRequest = formUpdateRequest(
          oldType,
          type,
          productPrices,
          siMapping,
          [],
          validCoupon ? subscriptionCoupon.couponId : null
        );
        const _invoiceData = await fetchInvoicePreviewForUpdate(_updateRequest);
        const totalAmount = calculateTotalPrice(
          productPrices,
          siMapping,
          [],
          type,
          _updateRequest
        );
        _invoiceData.totalAmount = totalAmount;
        setInvoiceData(_invoiceData);
        setUpdateRequest(_updateRequest);
      } catch (err) {
        console.log(err);
      }
    })();
    console.log(">>>", siMapping);
  }, [siMapping, coupon, validCoupon]);

  // for corresponding annual package
  const [correspondingAnnualBasePackage, setCorrespondingAnnualBasePackage] =
    useState("");
  useEffect(() => {
    if (!basePackages) return;
    setCorrespondingAnnualBasePackage(
      planType == 0 &&
      basePackages?.annual.filter((item) => {
        if (
          item.lookup_key.includes(
            subscriptionV2.subscription.basePackage.name.split("_")[1]
          )
        )
          return item;
      })
    );
  }, [basePackages, subscriptionV2]);

  useEffect(() => {
    setIsLoading(true);
    if (
      !subscriptionV2 ||
      !productPrices ||
      !subscriptionInsights ||
      !siMapping
    ) {
      return;
    }

    setIsLoading(false);
  }, [subscriptionV2, productPrices, subscriptionInsights]);

  if (!subscriptionInsights)
    return (
      <div className="w-full h-[100vh] flex items-center justify-center text-blue-70">
        <LoaderIcon className="h-[48px] w-[48px]" />
      </div>
    );

  return (
    <div className={styles.pageContainer}>
      {!popUpType ? (
        <></>
      ) : (
        <PopupContainer trigger={popUpTrigger} setTrigger={setPopUpTriger}>
          {subscriptionV2 && basePackages && siMapping ? (
            getPopUpForType(popUpType, {
              setPopUpTriger,
              productPrices,
              basePackages: basePackages,
              selectedBillingCycle,
              setSelectedBillingCycle,
              siMapping,
              setSiMapping,
              subscription: subscriptionV2,
              _setIsEnabledPAYG,
              setUpdateSubContinueClicked,
              setBillingCycleChangedFromOutside,
              limitExhaused:
                subscriptionV2?.subscription?.usage?.assignments.metered > 0 ||
                subscriptionV2?.subscription?.usage?.sessions.metered > 0,
            })
          ) : (
            <></>
          )}
        </PopupContainer>
      )}

      <BreadCrumb />
      <div
        className={`flex flex-col items-start justify-start gap-[5.5rem] w-full h-[100vh] overflow-y-auto px-[24px] ${styles.container1}`}
        id="container1"
        ref={refContainer1}
      >
        {/* <div>Bread crumbs</div> */}
        {isExpiredSubscription ? (
          <div className="flex flex-col gap-[2.25rem] justify-start items-start w-full">
            <RetryPaymentWarning />
          </div>
        ) : (
          <></>
        )}
          {/* <AnnualPackagePromotion
            subscriptionV2={subscriptionV2}
            annualBasePackage={correspondingAnnualBasePackage[0]}
            setSelectedBillingCycle={setSelectedBillingCycle}
            selectedBillingCycle={selectedBillingCycle}
            siMapping={siMapping}
            setSiMapping={setSiMapping}
            getCorrespondingPriceId={getCorrespondingPriceId}
            productPrices={productPrices}
            basePackages={basePackages}
            billingCycleChangedFromOutside={billingCycleChangedFromOutside}
          /> */}

        <div className="flex flex-col gap-[2.25rem] justify-start items-start w-full">
          {subscriptionV2 ? (
            <YourPlanCard
              subscriptionV2={subscriptionV2}
              subscriptionData={subscriptionInsights}
              planType={planType}
              setPopUpTriger={setPopUpTriger}
              setPopUpType={setPopUpType}
              isExpired={subscriptionV2?.subscription.isActive === false}
              siMapping={siMapping}
              productPrices={productPrices}
              selectedBillingCycle={selectedBillingCycle}
              handleCancelSubscription={(e) => {
                setPopUpType("cancel-sub");
                setPopUpTriger(true);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col gap-[2.25rem] justify-start items-start w-full">
          <div className="text-[calc(100vw*28/1440)] font-semibold text-[#25335A]">
            Products
          </div>
          {siMapping &&
            augmentedProducts?.map((product, index) => (
              <ProductCardInsights
                data={product}
                type={planType}
                key={index}
                insights={
                  insightsData[product.name]
                    ? insightsData[product.name].insights
                    : null
                }
                selectedPrice={siMapping.filter((val) => {
                  if (
                    val.lookupKey.includes(product.lookupKey) &&
                    !val.lookupKey.includes("PAYG")
                  ) {
                    return val;
                  }
                })}
                siMapping={siMapping}
                setSiMapping={setSiMapping}
                allMetrics={subscriptionInsights.metrics}
                subscriptionData={subscriptionInsights}
                selectedPlanType={selectedBillingCycle}
                productPrices={productPrices}
              />
            ))}
        </div>
        {(subscriptionV2?.subscription.__t === "MonthlySubscription" || subscriptionV2?.subscription.__t === "AnnualSubscription") && !(subscriptionV2?.subscription?.basePackage.name.includes("Free")) ? (
          <div className="flex flex-col gap-[2.25rem] justify-start items-start w-full">
            <div className="w-full flex justify-between items-center">
              <div className={`${styles.title} flex items-center gap-[8px] justify-start`}><>Pay As You Go</> <Tooltip anchorComponent={<InfoIcon className="text-secondary-60 w-[16px] h-[16px]" />} tooltipStyleClass={"tooltipStyleClass !w-[350px]"} tooltipContent={<>PAYG starts after you have exhausted your billing period usage limits for various products offered by Evallo, such as Assignment Credits or Calendar Sessions. <br /> Each product has its own PAYG pricing, and it will only be activated once you cross the threshold set by your package.</>} /></div>
              <ToggleButtonV2
                customContainer={styles.toggleButtonContainer}
                customSwitch={styles.toggleButtonSwitch}
                isEnabled={_isEnabledPAYG}
                setIsEnabled={
                  !_isEnabledPAYG
                    ? (isEnabledPAYG) => {
                      _setIsEnabledPAYG(isEnabledPAYG);
                      _setIsFromToggle(true);
                    }
                    : () => {
                      setPopUpTriger(true);
                      setPopUpType("disable-payg");
                      _setIsFromToggle(true);
                    }
                }
              />
            </div>
            {paygTableData &&
              (subscriptionV2?.subscription.__t === "MonthlySubscription" || subscriptionV2?.subscription.__t === "AnnualSubscription") && !(subscriptionV2?.subscription?.basePackage.name.includes("Free")) ? (
              <SubscriptionTable {...paygTableData} />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )
        } {" "}
        <div className="flex flex-col gap-[24px] w-full items-start justify-start">
          <div className="text-[calc(100vw*28/1440)] font-semibold text-[#25335A]">
            Payment Summary
          </div>
          <div className="flex flex-col items-start justify-start gap-[2.25rem] border-[1px] border-[#CACEDA] rounded-[12px] px-[1.5rem] py-[1.5rem] w-full">
            <div className="flex justify-start gap-[0.75rem] items-center">
              <ReceiptIcon />
              <div className={styles.title}>Recent Transactions</div>
            </div>
            {subscriptionTableData ? (
              <>
                <SubscriptionTable
                  {...subscriptionTableData}
                  body={subscriptionTableData.body.slice(
                    (selectedPageSubscriptionTable - 1) * 4,
                    (selectedPageSubscriptionTable - 1) * 4 + 4
                  )}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          {Math.round(subscriptionTableData?.body.length / 4) > 1 ? (
            <div className="w-full flex justify-between items-center -mt-[12px]">
              <PageIndicatorV2
                recordStartNo={
                  (selectedPageSubscriptionTable - 1) * 4 === 0
                    ? 1
                    : (selectedPageSubscriptionTable - 1) * 4
                }
                recordEndNo={(selectedPageSubscriptionTable - 1) * 4 + 4}
                totalRecordsCount={subscriptionTableData?.body.length}
              />
              <PaginationV2
                totalDataCount={subscriptionTableData?.body.length}
                pageSize={4}
                previousButton={{
                  content: (
                    <>
                      <ChevronLeft />
                    </>
                  ),
                  className:
                    "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                }}
                nextButton={{
                  content: (
                    <>
                      <ChevronRight />
                    </>
                  ),
                  className:
                    "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                }}
                helperButtonClassName={
                  "text-secondary-60 font-[400] font-[calc(100vw*16/1440)] "
                }
                selectedHelperButtonClassName={
                  "text-blue-70 font-[600] font-[calc(100vw*16/1440)]"
                }
                selectedPage={selectedPageSubscriptionTable}
                setSelectedPage={setSelectedPageSubscriptionTable}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div >
      <div
        className={`h-[100vh] overflow-y-auto w-full px-[24px] ${styles.container2}`}
        id="container2"
        ref={refContainer2}
      >
        <div className="flex flex-col items-end justify-start gap-[1.5rem] w-full">
          {/* <div className={styles.topLevelHeading}>Payment Summary</div> */}
          <div
            className={`flex flex-col items-center justify-start gap-[1.5rem] px-[1.5rem] w-full`}
          >
            <SummaryCornerSub
              summaryObject={summaryObject}
              invoiceData={invoiceData}
              coupon={coupon}
              setCoupon={setCoupon}
              validCoupon={validCoupon}
              setValidCoupon={setValidCoupon}
              {...{ subscriptionCoupon, setSubscriptionCoupon }}
              updateRequest={updateRequest}
              setPopUpType={setPopUpType}
              setPopUpTriger={setPopUpTriger}
              _setIsEnabledPAYG={_setIsEnabledPAYG}
              setUpdateButtonClicked={setUpdateButtonClicked}
              updateSubContinueClicked={updateSubContinueClicked}
            />
            <HorizontalDivider />
            <SupportCornerSub />
          </div>
        </div>
      </div>
    </div >
  );
};

export default ManageSubscription;