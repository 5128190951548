export default function ReferralIcon(props) {

	return(
		<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_36239_7026)">
				<path d="M11.0408 4.54463C11.0408 2.31441 9.22627 0.5 6.99615 0.5C4.76593 0.5 2.95152 2.31441 2.95152 4.54463C2.95152 5.92603 3.64783 7.14774 4.70761 7.87769C3.71811 8.21916 2.81107 8.78323 2.04907 9.54523C0.727707 10.8666 0 12.6235 0 14.4922H1.09311C1.09311 11.2373 3.74118 8.58926 6.99615 8.58926C9.22627 8.58926 11.0408 6.77486 11.0408 4.54463ZM6.99615 7.49615C5.36867 7.49615 4.04463 6.17212 4.04463 4.54463C4.04463 2.91714 5.36867 1.59311 6.99615 1.59311C8.62354 1.59311 9.94757 2.91714 9.94757 4.54463C9.94757 6.17212 8.62354 7.49615 6.99615 7.49615ZM14 10.5569L11.4272 13.1297L10.6542 12.3567L11.9348 11.0762H10.9867C9.67702 11.0762 8.6105 12.1418 8.60944 13.4515L8.60847 14.4926L7.51537 14.4918L7.51622 13.4506C7.51782 11.5385 9.07471 9.98294 10.9867 9.98294H11.8801L10.6542 8.75706L11.4272 7.98418L14 10.5569Z" fill="currentColor"/>
			</g>
			<defs>
				<clipPath id="clip0_36239_7026">
					<rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
				</clipPath>
			</defs>
		</svg>


	)

}
