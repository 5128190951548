import { useEffect, useState } from 'react'
import styles from './PaymentConfirmation.module.css'
import LoaderIcon from '../../LoaderIcon/LoaderIcon'
import { getFormattedDateV2 } from '../../../utils/dateUtils'

const PaymentConfirmation = ({trigger, setTrigger, handleUpdateSubscriptionClick, subscriptionV2, requiresFreeTrial}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isTrialling, setIsTrailling] = useState(false)
    const [message, setMessage] = useState("On continuing, your saved payment method will be charged immediately.")

    useEffect(()=>{
        if(subscriptionV2){
            const daysBetweenSubscriptionStartDateAndEndDate = Math.floor(
                Math.abs(
                  new Date(subscriptionV2?.subscription.startDate).getTime() -
                  new Date(subscriptionV2?.subscription.endDate).getTime()
                ) /
                (24 * 60 * 60 * 1000)
              );
            if(daysBetweenSubscriptionStartDateAndEndDate === 14 || requiresFreeTrial) {
                setIsTrailling(true)
                setMessage(`No amount due today. Your saved card will be charged at the end of your free trial on ${getFormattedDateV2(subscriptionV2?.subscription.endDate,'mm dd, yyyy')}`)
            }
            
        }
    },[subscriptionV2])

    return(
        <div className={styles.container}>
            <p className={styles.heading}>
                Are you sure you want to proceed with the changes?
            </p>
            <p className={styles.helper}>
                {message}
            </p>
            <div className={styles.actionArea}>
                <button className={styles.backButton} onClick={(e)=>setTrigger(false)}>Back</button>
                <button className={styles.continueButton} onClick={(e)=>handleUpdateSubscriptionClick(e,setIsLoading)}>
                    {
                        isLoading ? <LoaderIcon /> : "Yes, continue"
                    }
                </button>
            </div>
        </div>
    )
}


export default PaymentConfirmation
