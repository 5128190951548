import React, { useRef } from 'react'
import { useEffect, useState } from 'react';
import Desmos from 'desmos'
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export default function Calculator(props) {

  const [isExpanded, setIsExpanded] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({x : 0, y:0})
  const calcRef = useRef(null)

  useEffect(() => {
    var elt = document.getElementById('calculator');
    var calculator = Desmos.GraphingCalculator(elt, { autosize: true });
    calculator.updateSettings({ fontSize: Desmos.FontSizes.SMALL });
  }, [])

  useEffect(()=>{
    console.log("CHN",position)
    if(calcRef.current){
      calcRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`
    }
  },[position])

  const handleDrag = (e) => {
    if(isDragging){
      setPosition({
        x: position.x + e.movementX,
        y: position.y + e.movementY
      })
    }

  }

  useEffect(()=>{
    console.log("IS DRAGGING",isDragging)
  },[isDragging])



  return (
    <div className='fixed top-[130px] left-[40px] z-[5000] shadow-[0px_4px_24px_0px_#11167D26]' ref={calcRef}>
      <div className='w-full flex justify-between items-center py-[8px] px-[6px] bg-black text-white text-[20px]' onMouseMove={(e)=>handleDrag(e)} onMouseDown={(e)=>setIsDragging(true)} onMouseUp={(e)=>setIsDragging(false)} onMouseLeave={(e)=>setIsDragging(false)}>
        <div>Calculator</div>
        <button style={{width : "90px"}} className={`rounded-[12px] ${isDragging ? "bg-secondary-20 text-secondary" : ""}`}><DragIndicatorIcon className='rotate-[90deg] pointer-events-none' /></button>
        <div className='flex justify-end items-center text-white gap-[8px]'>
          <button onClick={(e) => setIsExpanded(!isExpanded)}>{!isExpanded ? <OpenInFullIcon /> : <CloseFullscreenIcon />}</button>
          <button onClick={(e) => { if (props.setCal) { props.setCal(false) } }}><CloseIcon /></button>
        </div>
      </div>
      <div id="calculator" style={{ width: `${isExpanded ? "900px" : "500px"}`, height: `${isExpanded ? "630px" : "630px"}` }}></div>
    </div>
  )
}
