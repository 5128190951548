import { useRef, useState, useEffect, useContext } from "react";
import SelectPill from "../../SelectPill/SelectPill";
import styles from "./ChoosePlanScreen.module.css";
import tooltipTriangle from "./tooltipTriangle.svg";

import explorerIcon from "./ExplorerIcon.svg";
import starterIcon from "./StarterIcon.svg";
import professionalIcon from "./ProfessionalIcon.svg";
import premiumIcon from "./PremiumIcon.svg";

import PackageCard from "../../PackageCard/PackageCard";
import ArrowIconLeftV2 from "../../ArrowIconLeftV2/ArrowIconLeftV2";
import FormCard from "../../FormCard/FormCard";
import pricingFeatures from "../../../utils/pricing_features.json";
import PriceComparator from "../../PriceComparator/PriceComparator";
import {
  useCreateNewSubscriptionMutation,
  useLazyGetBasePackageDetailsV2Query,
  useLazyGetPricesV2Query,
} from "../../../app/services/subscription";
import ServiceModule from "../../ServiceModuleCard/ServiceModule";
import CalendarIcon from "../../ServiceModuleCard/CalendarIcon";
import FormStepper from "../../FormStepper/FormStepper";

import OrgSignupContext from "../../../context/OrgSignupContext";
import AssignmentIcon from "../../ServiceModuleCard/AssignmentIcon";
import CustomTestsIcon from "../../ServiceModuleCard/CustomTestsIcon";
import getRecommendedPackageWithProducts from "../../../utils/package_recommender";
import getConvertedPlanType from "../../ProductCard/planTypeConverter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loadFreeTier } from "../../../utils/utils";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateFetchData, updateIsLoggedIn } from "../../../app/slices/user";
import { fetchAndSetSubscription } from "../../../utils/subscriptionService";
import { updateOrganization } from "../../../app/slices/organization";
import { useUpdateOrgDataMutation } from "../../../app/services/admin";
import HorizontalDivider from "../../HorizontalDivider/HorizontalDivider";
import PopupContainer from "../../PopupContainer/PopupContainer";
import ExplorerPlanConfirmationPopUp from "../ExplorerPlanConfirmationPopUp/ExplorerPlanConfirmationPopUp";

const aPackage = {
  summaryImg: starterIcon,
  isRecommended: true,
  basePackageName: "Starter",
  basePackageDescription:
    "Scale your tutoring business with workflows and customisations.",
  price: "$10",
  packageType: "monthly",
  buttonContent: "Start free trial",
  additionalDetails: [
    {
      name: "basePackage",
      details: [
        "Unlimited students",
        "Admin Dashboard (Full)",
        "Student Groups - Unlimited",
        "White Labelling - Limited",
      ],
    },
    { name: "sessions", details: ["Calendar Analytics", "3 referral codes"] },
  ],
  selectedProducts: ["basePackage", "sessions"],
  normalPrice: "$58",
};

const packageTutorAmountMapping = [
  { name: "Free", amount: 1 },
  { name: "Starter", amount: 1 },
  { name: "Professional", amount: 10 },
  { name: "Premium", amount: 30 },
]

const ChoosePlanScreen = (props) => {
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  //Configure the monthly & annual packages to be displayed in cards
  const studentGroups = pricingFeatures.find((feature) =>
    feature.feature_heading.includes("Student Groups")
  );
  const referralCodes = pricingFeatures.find(
    (feature) => feature.feature_heading === "Referral Codes"
  );
  const whiteLabelling = pricingFeatures.find(
    (feature) => feature.feature_heading === "White Labeling"
  );

  const {
    noOfTutors,
    setNoOfTutors,
    productPrices,
    selectedProducts,
    setSelectedProducts,
    setPaygEnabled,
    paygEnabled,
    recommendedIdx,
    setRecommendedIdx,
  } = useContext(OrgSignupContext);

  const [explorerPlanPopUpTrigger, setExplorerPlanPopUpTrigger] = useState(false)
  const [createNewSubscription] = useCreateNewSubscriptionMutation();
  const [selectedPlanType, setSelectedPlanType] = useState(1);
  const selectPillRef = useRef(null);
  const tooltipRef = useRef(null);
  const [areCardsExpanded, setAreCardsExpanded] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [explorerContinueClicked, setExplorerContinueClicked] = useState(false)
  const [monthlyPackages, setMonthlyPackages] = useState(null);
  const [annualPackages, setAnnualPackages] = useState(null);
  const [planTypeChangedByUser, setPlanTypeChangedByUser] = useState(false)

  const [selectedBasePackage, setSelectedBasePackage] = useState(0);
  const [calculatedPrice, setCalculatedPrice] = useState({
    Explorer: 0,
    Starter: 0,
    Professional: 0,
    Premium: 0,
  });

  const { multiselectProducts, setMultiselectProducts } =
    useContext(OrgSignupContext);
  const { hasRecommended, setHasRecommended } = useContext(OrgSignupContext);

  const { multiselectNoOfTutors, setMultiselectNoOfTutors } =
    useContext(OrgSignupContext);
  const [displayPricesProducts, setDisplayPricesProducts] = useState(
    new Array(4).fill(0)
  );
  const [updateOrgRequest,] = useUpdateOrgDataMutation()
  const triggerExplorerPopUp = (e) => {
    setExplorerPlanPopUpTrigger(true)
  }
  const {fetchData} = useSelector((state)=>state.user)
  const dispatch = useDispatch()

  //Load all of this from the API eventually.
  const _monthlyPackages = [
    {
      summaryImg: explorerIcon,
      isRecommended: false,
      basePackageName: "Explorer",
      _CTAButtonText: "Get started",
      basePackageDescription: (
        <>
          Experience powerful features for free.
          <span className="text-[#0671E0]">
            &nbsp;Credit Card NOT required.
          </span>{" "}
        </>
      ),
      price: "Free",
      packageType: "annual",
      buttonContent: "Get started",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            "Includes 1 Tutor Licenses",
            "Unlimited students / parents",
            <>Student groups Allowed - <span className="font-[700]">10</span></>,
            <>Calendar - <span className="font-[700]">1200</span> sessions</>,
            <>Assignments - <span className="font-[700]">180</span> credits</>,
            <><span className="font-[700]">2</span>&nbsp;Full-length DSAT®</>,
          ],
          tooltipText: [
            "Your lifetime free subscription comes with the ability to create one tutor profile that can be mapped with the students you onboard.",
            "You can invite and/or store client profiles for students and parents without any restrictions.",
            "Club students within groups to save time with assignments, scheduling, invoicing, etc.",
            "Your free subscription comes with 1200 sessions that can be reconciled on the Calendar (you need to enable the product to use this feature).",
            'Your free subscription comes with 180 Assignments that can be sent to the student for practice. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you. Note that you need to enable the "Assignments" product to access this feature.',
            'Your free subscription comes with 2 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
          ],
        },
      ],
      selectedProducts: ["basePackage", "sessions"],
      normalPrice: "$58",
      onCTAButtonClick: null,
      showPAYG: false,
    },
    {
      summaryImg: starterIcon,
      isRecommended: false,
      basePackageName: "Starter",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for freelance tutors and small businesses. Credit Card required.",
      price: "$10",
      packageType: "monthly",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>Tutors licenses included-<span className="font-[700]">1</span></>,
            "Unlimited CRM features",
            <><span className="font-[700]">5</span>&nbsp;Full-length DSAT®</>,
            "White labeling (limited)",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 1 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 5 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "Calendar", "Assignments"],
      normalPrice: "$58",
      onCTAButtonClick: onCTAClick,
      showPAYG: true,
    },
    {
      summaryImg: professionalIcon,
      isRecommended: false,
      basePackageName: "Professional",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for SMBs looking to scale up. Credit Card required.",
      price: "$10",
      packageType: "monthly",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>Tutors licenses included-<span className="font-[700]">10</span></>,
            "Unlimited CRM features",
            <><span className="font-[700]">10</span>&nbsp;Full-length DSAT®</>,
            "White labeling",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 10 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 10 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "Calendar", "Assignments"],
      normalPrice: "$58",
      onCTAButtonClick: onCTAClick,
      showPAYG: true,
    },
    {
      summaryImg: premiumIcon,
      isRecommended: false,
      basePackageName: "Premium",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for larger businesses and enterprises. Credit Card required.",
      price: "$10",
      packageType: "monthly",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>Tutors licenses included-<span className="font-[700]">30</span></>,
            "Unlimited CRM features",
            <><span className="font-[700]">15</span>&nbsp;Full-length DSAT®</>,
            "White labeling",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 30 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 15 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "Calendar", "Assignments"],
      normalPrice: "$58",
      onCTAButtonClick: onCTAClick,
      showPAYG: true,
    },
  ];
  //Load all of this from the API eventually.
  const _annualPackages = [
    {
      summaryImg: explorerIcon,
      isRecommended: false,
      basePackageName: "Explorer",
      _CTAButtonText: "Get started",
      basePackageDescription: (
        <>
          Experience powerful features for free.
          <span className="text-[#0671E0]">
            &nbsp;Credit Card NOT required.
          </span>{" "}
        </>
      ),
      price: "Free",
      packageType: "annual",
      buttonContent: "Get started",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            "Includes 1 Tutor Licenses",
            "Unlimited students / parents",
            <>Student groups Allowed - <span className="font-[700]">10</span></>,
            <>Calendar - <span className="font-[700]">1200</span> sessions</>,
            <>Assignments - <span className="font-[700]">180</span> credits</>,
            <><span className="font-[700]">2</span>&nbsp;Full-length DSAT®</>,
          ],
          tooltipText: [
            "Your lifetime free subscription comes with the ability to create one tutor profile that can be mapped with the students you onboard.",
            "You can invite and/or store client profiles for students and parents without any restrictions.",
            "Club students within groups to save time with assignments, scheduling, invoicing, etc.",
            "Your free subscription comes with 1200 sessions that can be reconciled on the Calendar (you need to enable the product to use this feature).",
            'Your free subscription comes with 180 Assignments that can be sent to the student for practice. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you. Note that you need to enable the "Assignments" product to access this feature.',
            'Your free subscription comes with 2 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
          ],
        },
      ],
      selectedProducts: ["basePackage", "sessions"],
      normalPrice: "$58",
      onCTAButtonClick: null,
      showPAYG: false,
    },
    {
      summaryImg: starterIcon,
      isRecommended: false,
      basePackageName: "Starter",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for freelance tutors and small businesses. Credit Card required.",
      price: "$10",
      packageType: "annual",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>Tutors licenses included-<span className="font-[700]">1</span></>,
            "Unlimited CRM features",
            <><span className="font-[700]">5</span>&nbsp;Full-length DSAT®</>,
            "White labeling (limited)",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 1 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 5 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "sessions"],
      normalPrice: "$58",
      onCTAButtonClick: onCTAClick,
      showPAYG: true,
    },
    {
      summaryImg: professionalIcon,
      isRecommended: false,
      basePackageName: "Professional",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for SMBs looking to scale up. Credit Card required.",
      price: "$10",
      packageType: "annual",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>Tutors licenses included-<span className="font-[700] px-[0px]">10</span></>,
            "Unlimited CRM features",
            <><span className="font-[700]">10</span>&nbsp;Full-length DSAT®</>,
            "White labeling",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 10 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 10 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "sessions"],
      normalPrice: "$58",
      onCTAButtonClick: onCTAClick,
      showPAYG: true,
    },
    {
      summaryImg: premiumIcon,
      isRecommended: false,
      basePackageName: "Premium",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for larger businesses and enterprises. Credit Card required.",
      price: "$10",
      packageType: "annual",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>Tutors licenses included-<span className="font-[700]">30</span></>,
            "Unlimited CRM features",
            <><span className="font-[700]">15</span>&nbsp;Full-length DSAT®</>,
            "White labeling",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 30 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 15 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "sessions"],
      normalPrice: "$58",
      onCTAButtonClick: onCTAClick,
      showPAYG: true,
    },
  ];

  const [productFeatures, setProductFeatures] = useState([
    {
      serviceIcon: <CalendarIcon className="h-[calc(100vw*32/1440)] w-[calc(100vw*32/1440)] 2.5xl:h-[32px] 2.5xl:w-[32px]" />,
      serviceTitle: <div className="text-[calc(100vw*24/1440)] text-[#EE778C] 2.5xl:text-[24px] font-[600]">Calendar</div>,
      serviceHelper: "Seamlessly schedule sessions for students and student groups.",
      serviceName: "CalendarV2",
      monthlyPlan: [],
      annualPlan: [],
      monthlyPayg: "",
      featureList: [
        {
          title: "Session Notes",
          desc: "Add client notes and internal notes for each session that you record on the Calendar.",
        },
        {
          title: "Recurring Sessions",
          desc: "Schedule and manage recurring sessions without any hassles.",
        },
        {
          title: "Session Tags",
          desc: "Custom tags that help you quickly record important details about a session.",
        },
        {
          title: "Calendar Analytics",
          desc: "Stats based on user-type, service provided, session status, hours tutored, and more!",
        },
        {
          title: "Multiple Calendar Views",
          desc: "Switch your Calendar view between Weekly, Monthly and Yearly.",
        },
        {
          title: "Universal Time Zones",
          desc: "Switch between time zones and automatically adjust your Calendar view.",
        },
        {
          title: "Color Coding",
          desc: "Sessions are color coded based on properties such as status, service, tutor, student.",
        },
        {
          title: "User-specific Views",
          desc: "Search for specific users and view their calendar.",
        },
        {
          title: "Bulk Edit & Delete",
          desc: "Edit or delete recurring sessions in bulk.",
        },
      ],
    },
    {
      serviceIcon: <AssignmentIcon className="h-[calc(100vw*32/1440)] w-[calc(100vw*32/1440)] 2.5xl:h-[32px] 2.5xl:w-[32px]" />,
      serviceTitle: <div className="text-[calc(100vw*24/1440)] text-[#8D54D7] 2.5xl:text-[24px] font-[600]">Assignments</div>,
      serviceHelper: "Practice tests, drills, question banks, and more, added by Evallo or you.",
      serviceName: "AssignmentsV2",
      featureList: [
        {
          title: "Free DSAT® (full-length tests)",
          desc: "Your subscription comes with free upto 15 digital SAT® created by Evallo.",
        },
        {
          title: "Instant Score Reports",
          desc: "Generate instant score reports as the students attempt any Assignments.",
        },
        {
          title: "Timer Control",
          desc: "Control the test timer from Regular, 1.25x, 1.5x, 2x and Unlimited.",
        },
        {
          title: "Due Dates",
          desc: "Set due dates for all assignments created for students.",
        },
        {
          title: "Test-specific Instructions",
          desc: "Add custom instructions before a student attempts an assignment.",
        },
        {
          title: "Progress Tracking",
          desc: "Track how your students' performances are progressing over a period of time.",
        },
        {
          title: "Conceptual Statistics",
          desc: "Get detailed statistics such as time taken per concept, average percentile, and top conceptual weaknesses among your students.",
        },
        {
          title: "Time Tracking (per question)",
          desc: "See the time taken per question by the student as they attempt a test.",
        },

      ],
    },
    {
      serviceIcon: <CustomTestsIcon className="h-[calc(100vw*32/1440)] w-[calc(100vw*32/1440)] 2.5xl:h-[32px] 2.5xl:w-[32px]" />,
      serviceTitle: <div className="text-[calc(100vw*24/1440)] text-[#17A289] 2.5xl:text-[24px] font-[600]">Custom Tests</div>,
      serviceHelper: "Set up your brand identity and give your clients a premium experience.",
      serviceName: "CustomContentV2",
      featureList: [
        {
          title: "Calendar Analytics",
          desc: "Insights on attendance, performance and trends!",
        },
        {
          title: "Calendar Analytics",
          desc: "Insights on attendance,dcscs sdcsdcsdc performance and trends!",
        },
        {
          title: "Calendar Analytics",
          desc: "Insights on attendance,sdcsdc performance and trends!",
        },
        {
          title: "Calendar Analytics",
          desc: "Insights on attendance, performance and trends!",
        },
        {
          title: "Calendar Analytics",
          desc: "Insights on attendance,qwddd performance and trends!",
        },
        {
          title: "Calendar Analytics",
          desc: "Insights on attendance, performance and trends!",
        },
        {
          title: "Calendar Analytics",
          desc: "Insights on attendance, performance and trends!",
        },
      ],
    },
  ]);


  function extractPlans(productName, idx) {
    const calendarV2Product = productPrices?.find(
      (product) => product.product.name === productName
    );

    if (calendarV2Product) {
      const { monthly, annual } = calendarV2Product.prices;

      // Prepare monthly prices object
      const monthlyPrices = monthly.map((price) => ({
        limit: price.metadata?.limit,
        unitPrice: price.unit_amount / 100,
      }));

      const annualPrices = annual.map((price) => ({
        limit: price.metadata?.limit,
        unitPrice: price.unit_amount / 100,
      }));

      const mPayg = monthly.filter(
        (el) =>
          el.lookup_key == "Calendar_PAYG" ||
          el.lookup_key == "Assignments_PAYG"
      );

      console.log(">>> PYG", mPayg);

      setProductFeatures((prev) => {
        const newState = [...prev];
        newState[idx].monthlyPlan = monthlyPrices
          .sort((a, b) => a.unitPrice - b.unitPrice)
          .filter((item) => item.limit !== undefined);
        newState[idx].annualPlan = annualPrices
          .sort((a, b) => a.unitPrice - b.unitPrice)
          .filter((item) => item.limit !== undefined);
        newState[idx].monthlyPayg = mPayg[0]?.unit_amount || "";
        return newState;
      });

      console.log(">>>M CPS", productFeatures);
    } else {
      console.log("Product 'CalendarV2' not found.");
    }
  }


  //Load no of tutors from localstorage in case of the first load
  useEffect(()=>{
    if(!noOfTutors){
      let persistentNoOfTutors = window.localStorage.getItem("noOfTutors")
      if(persistentNoOfTutors){
        persistentNoOfTutors = parseInt(persistentNoOfTutors)
        setNoOfTutors(persistentNoOfTutors)
      }
    }
  },[])

  useEffect(() => {
    // console.log(">>>CPS", productPrices)
    // _monthlyPackages.forEach(configurePackageDetails);
    // _annualPackages.forEach(configurePackageDetails);

    setMonthlyPackages(_monthlyPackages);
    setAnnualPackages(_annualPackages);

    productFeatures.forEach((el, i) => {
      extractPlans(el.serviceName, i);
    });
  }, [productPrices]);

  useEffect(() => {
    console.log(">>>MSP", multiselectProducts);
  }, [multiselectProducts]);

  //Setup recommended products
  useEffect(() => {
    console.log("NO_OF_TUT",noOfTutors)
    if(noOfTutors === 0 || !noOfTutors){
      console.log("NO OF TUTORS ZERO")
      return
    }
    if (!noOfTutors || noOfTutors === 0) return;
    if (!productPrices) return;
    if (!monthlyPackages || !annualPackages) return;
    if (noOfTutors > 30) return;

    //To show recommended product
    const recommendedPackageWithProductsMonthly =
      getRecommendedPackageWithProducts(noOfTutors, 0, productPrices);
    const recommendedPackageWithProductsAnnual =
      getRecommendedPackageWithProducts(noOfTutors, 1, productPrices);

    const packages = ["Explorer", "Starter", "Professional", "Premium"];
    const recommendedPackageIndex = packages.findIndex(
      (val) => val === recommendedPackageWithProductsMonthly.basePackage.name
    );

    const productToSelect =
      selectedPlanType === 0
        ? recommendedPackageWithProductsMonthly
        : recommendedPackageWithProductsAnnual;

    const tempMonthlyPackages = monthlyPackages;
    tempMonthlyPackages[
      monthlyPackages.findIndex(
        (val, idx) =>
          val.basePackageName ===
          recommendedPackageWithProductsMonthly.basePackage.name
      )
    ].isRecommended = true;

    tempMonthlyPackages.forEach((val, idx) => {
      if (idx !== recommendedPackageIndex) {
        val.isRecommended = false
      }
    })

    const tempAnnualPackages = annualPackages;
    tempAnnualPackages[
      annualPackages.findIndex(
        (val, idx) =>
          val.basePackageName ===
          recommendedPackageWithProductsAnnual.basePackage.name
      )
    ].isRecommended = true;

    tempAnnualPackages.forEach((val, idx) => {
      if (idx !== recommendedPackageIndex) {
        val.isRecommended = false
      }
    })

    setMonthlyPackages(tempMonthlyPackages);
    setAnnualPackages(tempAnnualPackages);

    //To calculate prices & setup default selected product -> Right now the order is fixed so doing index based setting for different packages
    const tempSelectedProducts = [...multiselectProducts];
    console.log(">>>TEST!", tempSelectedProducts);
    tempSelectedProducts.forEach((val, idx) => {
      if (idx === 0) {
        tempSelectedProducts[idx] = loadFreeTier(productPrices, 1, tempSelectedProducts[idx])
        console.log("FREE TIER LOADED")
        console.log(tempSelectedProducts[idx])
        return
      }
      console.log(">>IDX", idx)
      val.CRM = productToSelect["CRM"]
      val.Assignments = productToSelect["Assignments"]
      val["Custom Tests"] = productToSelect["Custom Tests"]
      val.Calendar = productToSelect["Calendar"]
    });
    console.log(">>>TEST!", tempSelectedProducts);
    setMultiselectProducts([...tempSelectedProducts]);
    //Set no of tutors to be displayed in the packageCard
    const tempNoOfTutors = [...multiselectNoOfTutors];
    tempNoOfTutors[recommendedPackageIndex] =
      recommendedPackageWithProductsMonthly.tutors.extraTutors;
    console.log(
      ">>>TUT",
      recommendedPackageWithProductsMonthly.tutors.extraTutors,
      noOfTutors
    );
    //Put the extra tutors for every package
    tempNoOfTutors.forEach((val, idx) => {
      console.log("TESTING,^^^123")
      const extraTutors = packageTutorAmountMapping[idx].amount <= noOfTutors ? Math.abs(packageTutorAmountMapping[idx].amount - noOfTutors) : 0
      if (idx === 0) {
        tempNoOfTutors[idx] = 0
      } else {
        tempNoOfTutors[idx] = extraTutors
      }
    })
    setMultiselectNoOfTutors(tempNoOfTutors);
    setRecommendedIdx(recommendedPackageIndex);
  }, [annualPackages, noOfTutors]);

  useEffect(() => {
    console.log(">>>MP", monthlyPackages);
  }, [monthlyPackages]);
  //END configure pacakges


  //For changing the plantype when price changes
  useEffect(() => {
    console.log(">>>SP", selectedPlanType)
    if (!planTypeChangedByUser && selectedPlanType === 1) return;
    if (!productPrices) return;
    console.log(">>>TEST", [...multiselectProducts]);
    const tempSelectedProducts = [...multiselectProducts];
    multiselectProducts.forEach((val, idx) => {
      if(idx === 0) return;
      console.log(">>>CPT0", val, selectedPlanType, idx);
      const res = getConvertedPlanType(productPrices, selectedPlanType, val);
      console.log(">>>RES", res);
      if (res) {
        tempSelectedProducts[idx] = res;
      }
    });
    console.log(">>TSP", tempSelectedProducts);
    setMultiselectProducts(tempSelectedProducts);
  }, [selectedPlanType]);

  //For annual, monthly plan selection
  function handleSelectableClick(e) {
    const idx = parseInt(e.target.id.split("selectable_").pop());
    console.log("SP", idx)
    setSelectedPlanType(idx);
    setPlanTypeChangedByUser(true)
  }

  function onCTAClick(e) {
    const idx = parseInt(e.target.id.split("_").pop());
    const tempArray = Array(4).fill(false);
    tempArray[idx] = true;
    setAreCardsExpanded(tempArray);
    setSelectedBasePackage(idx);
  }

  function collapseAll() {
    setAreCardsExpanded(Array(4).fill(false));
  }
  //END CTA BUTTON Click

  //Fetch prices for tutors
  function getPricesForTutors(basePackageName, _selectedPlanType = 0) {
    if (!productPrices) return 0;
    if (basePackageName === "Explorer") return 0;
    const tutorProducts = productPrices.find(
      (val) => val.product.name === "TutorsV2"
    );
    const tutorProductPrices =
      _selectedPlanType === 0
        ? tutorProducts.prices.monthly
        : tutorProducts.prices.annual;
    console.log(">>TPP", basePackageName);
    const requiredPrice = tutorProductPrices.find((val) =>
      val.lookup_key.includes(basePackageName)
    );
    return requiredPrice.unit_amount / 100;
  }

  function calculatePricesForAllPackages(packageIdx) {
    if (packageIdx == 0) return [0, 0, 0];
    if (!productPrices) return [0, 0, 0];

    let sum = 0;
    let productsSum = 0;
    let basePackagePrice = 0;

    for (const product in multiselectProducts[packageIdx]) {
      if (multiselectProducts[packageIdx][product].selected) {
        console.log(
          ">>>PRICE",
          typeof multiselectProducts[packageIdx][product].price
        );
        sum += parseInt(multiselectProducts[packageIdx][product].price);
      }
    }

    productsSum = sum;

    if (selectedPlanType == 0) {
      const product = productPrices.find(
        (val) => val.product.name === "BasePackageV2"
      );
      const prices = product.prices.monthly;
      console.log(">>>PRICES", prices);
      const requiredPrice = prices.find((price) =>
        price.lookup_key.includes(
          _monthlyPackages[packageIdx].basePackageName === "Explorer"
            ? "Free"
            : _monthlyPackages[packageIdx].basePackageName
        )
      );
      const amount = requiredPrice.unit_amount / 100;
      sum += parseInt(amount);
      basePackagePrice = parseInt(amount);
    } else {
      const product = productPrices.find(
        (val) => val.product.name === "BasePackageV2"
      );
      const prices = product.prices.annual;
      const requiredPrice = prices.find((price) =>
        price.lookup_key.includes(
          _annualPackages[packageIdx].basePackageName === "Explorer"
            ? "Free"
            : _annualPackages[packageIdx].basePackageName
        )
      );
      const amount = requiredPrice.unit_amount / 100;
      sum += parseInt(amount);
      basePackagePrice = parseFloat((parseInt(amount) / 12).toFixed(2));
    }
    return [sum, productsSum, basePackagePrice];
  }

  //IMP Formula for no of tutors is (multiselectNoOfTutors - limit that is present in the package)
  function calculateDisplayPrice(
    packageIdx,
    _selectedPlanType,
    productsSum,
    basePackagePrice
  ) {
    if (packageIdx === 0) return 0;
    const tutorPrice =
      getPricesForTutors(
        _monthlyPackages[packageIdx].basePackageName,
        _selectedPlanType
      ) * multiselectNoOfTutors[packageIdx];
    return _selectedPlanType === 0
      ? Math.round((tutorPrice + basePackagePrice) * 2 + 1.5 * productsSum)
      : Math.round(
        parseFloat(
          (
            (tutorPrice / 12 + basePackagePrice) * 2 +
            (1.5 * productsSum) / 12
          ).toFixed(2)
        )
      );
  }

  //Calculate price dynamically
  useEffect(() => {
    const tempCalculatedPrice = { ...calculatedPrice };
    const tempDisplayPrices = [...displayPricesProducts];
    Object.keys(tempCalculatedPrice).forEach((key, idx) => {
      console.log(">>KEY", key);
      tempCalculatedPrice[key] =
        selectedPlanType === 0
          ? calculatePricesForAllPackages(idx)[0]
          : parseFloat(Math.round((calculatePricesForAllPackages(idx)[0] / 12)).toFixed(2));
      tempCalculatedPrice[key] +=
        selectedPlanType === 0
          ? getPricesForTutors(
            _monthlyPackages[idx].basePackageName,
            selectedPlanType
          ) * multiselectNoOfTutors[idx]
          : (getPricesForTutors(
            _monthlyPackages[idx].basePackageName,
            selectedPlanType
          ) *
            multiselectNoOfTutors[idx]) /
          12;
      tempCalculatedPrice[key] = Math.round(
        parseFloat(tempCalculatedPrice[key].toFixed(2))
      );
      tempDisplayPrices[idx] = calculateDisplayPrice(
        idx,
        selectedPlanType,
        calculatePricesForAllPackages(idx)[1],
        calculatePricesForAllPackages(idx)[2]
      );
    });
    setCalculatedPrice(tempCalculatedPrice);
    setDisplayPricesProducts([...tempDisplayPrices]);
  }, [
    multiselectProducts,
    selectedProducts,
    selectedBasePackage,
    selectedPlanType,
    productPrices,
    multiselectNoOfTutors,
    noOfTutors
  ]);

  //Handle change of no of tutors for package
  const handleChangeNoOfTutorsForPackage = (e) => {
    const idx = parseInt(e.target.id.split("_").pop());
    const tempNoOfTutors = [...multiselectNoOfTutors];
    tempNoOfTutors[idx] = e.target.value;
    setMultiselectNoOfTutors([...tempNoOfTutors]);
  };

  //Handle proceed button Clicked
  const handleProceedButtonClicked = (e) => {
    const idx = parseInt(e.target.id.split("_").pop());

    const tempTutorPrice = productPrices
      .find((val) => val.product.name === "TutorsV2")
      .prices[selectedPlanType === 0 ? "monthly" : "annual"].find((_val) =>
        _val.lookup_key.includes(_monthlyPackages[idx].basePackageName)
      );
    const unitPriceTutor = getPricesForTutors(
      _monthlyPackages[idx].basePackageName,
      selectedPlanType
    );

    const basePackageProducts = productPrices.find(
      (val) => val.product.name === "BasePackageV2"
    );
    const basePackage = basePackageProducts.prices[
      selectedPlanType === 0 ? "monthly" : "annual"
    ].find((price) =>
      price.lookup_key.includes(_monthlyPackages[idx].basePackageName)
    );

    setSelectedProducts({
      selectedProducts: {
        ...multiselectProducts[idx],
        tutors: {
          price: unitPriceTutor,
          value: tempTutorPrice.id,
          default: multiselectNoOfTutors[idx] !== 0,
          selected: unitPriceTutor * multiselectNoOfTutors[idx] !== 0,
          quantity: multiselectNoOfTutors[idx],
        },
        basePackage: {
          price: basePackage.unit_amount / 100,
          value: basePackage.id,
          lookup_key: basePackage.lookup_key,
          name: _monthlyPackages[idx].basePackageName,
        },
      },
      selectedPlanType: selectedPlanType === 0 ? "monthly" : "annual",
    });
    let selection = {
      selectedProducts: {
        ...multiselectProducts[idx],
        tutors: {
          price: unitPriceTutor,
          value: tempTutorPrice.id,
          default: multiselectNoOfTutors[idx] !== 0,
          selected: unitPriceTutor * multiselectNoOfTutors[idx] !== 0,
          quantity: multiselectNoOfTutors[idx],
        },
        basePackage: {
          price: basePackage.unit_amount / 100,
          value: basePackage.id,
          lookup_key: basePackage.lookup_key,
          name: _monthlyPackages[idx].basePackageName,
        },
      },
      selectedPlanType: selectedPlanType === 0 ? "monthly" : "annual",
    };

    console.log(">>>SEL", selection)
    localStorage.setItem("Plan_Selection", JSON.stringify(selection));
    navigate("/signup/review");
  };

  //For CTA button click to expand the card & collapse others
  const onCTAClickFree = (e) => {
    const idx = 0;

    const tempTutorPrice = productPrices
      .find((val) => val.product.name === "TutorsV2")
      .prices["annual"].find((_val) =>
        _val.lookup_key.includes("Starter")
      );

    const unitPriceTutor = getPricesForTutors(
      _monthlyPackages[idx].basePackageName,
      selectedPlanType
    );

    const basePackageProducts = productPrices.find(
      (val) => val.product.name === "BasePackageV2"
    );
    const basePackage = basePackageProducts.prices["annual"].find((price) => price.lookup_key.includes("Free"));

    const tempItems = [{
      price: basePackage.unit_amount / 100,
      value: basePackage.id,
      default: false,
      selected: true,
      lookup_key: basePackage.lookup_key
    }]

    console.log(">>>MSPI", multiselectProducts[idx])
    Object.keys(multiselectProducts[idx]).forEach((key) => {
      if (key === "CRM" || key === "basePackage") return;
      const item = multiselectProducts[idx][key]
      tempItems.push({
        price: item.price,
        value: item.value,
        selected: item.selected,
        default: false,
        name: key
      })
    })

    tempItems.push({
      price: unitPriceTutor,
      value: tempTutorPrice.id,
      quantity: 0,
      selected: true,
      default: false,
      name: "tutors",
    })

    console.log("TEMP", tempItems)

    createNewSubscription({
      isFreeTrial: true,
      isEnabledPAYG: true,
      planType: "annual",
      items: tempItems
    }).then(async (res) => {
      if (!res.error) {
        toast.success("Welcome to evallo")
        dispatch(updateIsLoggedIn(true))
        await fetchAndSetSubscription()
        updateOrgRequest({
          fields: {
            isEnabledPAYG: false
          },
          orgId: window.localStorage.getItem("orgId"),
          op: "update"
        }).then((res) => {
          if (res.eror) {
            console.log("ERROR", res.error)
          } else {
            console.log("success")
            dispatch(updateFetchData(true))
            navigate("/")
          }
        })

      } else {
        setExplorerContinueClicked(false)
        toast.warn("Some odd error occured.");

      }
    });
  }

  useEffect(() => {
    console.log(">>>DP", displayPricesProducts);
  }, [displayPricesProducts]);

  if (!productPrices) {
    return <></>;
  } else {
    return (
      <div
        className={styles.container}
        id="choose_plan_container"
        onClick={(e) => {
          if (e.target.id === "choose_plan_container") {
            collapseAll();
          }
        }}
      >
        <FormStepper stage={3} />
        <h4>Choose your Plan</h4>
        <button
          className={styles.backButton}
          onClick={(e) => { navigate('/signup/org-details') }}
        >
          <div className={styles.content}>
            <div>
              <ArrowIconLeftV2 />
            </div>
            <div>Back</div>
          </div>
        </button>
        <div className={styles.globalControls}>

          <div className={styles.tutorControls}>
            <label>Total number of tutors</label>
            <input type="number" onChange={(e) => {if(e.target.value && parseInt(e.target.value) > 0)setNoOfTutors(parseInt(e.target.value))}} defaultValue={noOfTutors} />
          </div>
          <div className={styles.priceTypeContainer}>
            <SelectPill
              selectables={["Monthly",
                <div className="flex items-center justify-start gap-[8px] pointer-events-none">Annual
                  <div className={styles.tooltipContainer} >
                    <div className={styles.tooltip}>Save 15%</div>
                  </div></div>
              ]}
              onClick={handleSelectableClick}
              selected={selectedPlanType}
              ref={selectPillRef}
            />
          </div>

        </div>

        <div
          className={`${styles.packageDisplay} ${areCardsExpanded.includes(true) ? "" : `${styles.hideScrollbar} ${styles.packageDisplayExpanded}`}`}
          id="package_display_container"
          onClick={(e) => {
            if (e.target.id === "package_display_container") {
              collapseAll();
            }
          }}
        >
          {noOfTutors > 30 && monthlyPackages && annualPackages ? (
            <>
              {selectedPlanType === 0 ? (
                <PackageCard
                  {...monthlyPackages[3]}
                  idx={3}
                  isExpanded={areCardsExpanded[3]}
                  onBackButtonClick={collapseAll}
                  isEnabledPAYG={paygEnabled}
                  setIsEnabledPAYG={setPaygEnabled}
                  productPrices={productPrices}
                  selectedPlanType={selectedPlanType}
                  selectedProducts={multiselectProducts}
                  setSelectedProducts={setMultiselectProducts}
                  calculatedPrice={calculatedPrice}
                  setNoOfTutorsForPackage={handleChangeNoOfTutorsForPackage}
                  displayPricesProducts={displayPricesProducts}
                  handleProceedButtonClicked={handleProceedButtonClicked}
                  extraTutors={multiselectNoOfTutors}
                  noOfTutors={noOfTutors}
                />
              ) : (
                <PackageCard
                  {...annualPackages[3]}
                  idx={3}
                  isExpanded={areCardsExpanded[3]}
                  onBackButtonClick={collapseAll}
                  productPrices={productPrices}
                  selectedPlanType={selectedPlanType}
                  selectedProducts={multiselectProducts}
                  setSelectedProducts={setMultiselectProducts}
                  calculatedPrice={calculatedPrice}
                  setNoOfTutorsForPackage={handleChangeNoOfTutorsForPackage}
                  displayPricesProducts={displayPricesProducts}
                  handleProceedButtonClicked={handleProceedButtonClicked}
                  extraTutors={multiselectNoOfTutors}
                  noOfTutors={noOfTutors}
                />
              )}
              <FormCard />
            </>
          ) : (
            <></>
          )}

          {monthlyPackages && annualPackages && noOfTutors <= 30 ? (
            <>
              {selectedPlanType == 0 ? (
                monthlyPackages?.map((aPackage, idx) => (
                  <PackageCard
                    {...aPackage}
                    idx={idx}
                    isExpanded={areCardsExpanded[idx]}
                    onBackButtonClick={collapseAll}
                    isEnabledPAYG={paygEnabled}
                    setIsEnabledPAYG={setPaygEnabled}
                    productPrices={productPrices}
                    selectedPlanType={selectedPlanType}
                    selectedProducts={multiselectProducts}
                    setSelectedProducts={setMultiselectProducts}
                    calculatedPrice={calculatedPrice}
                    setNoOfTutorsForPackage={handleChangeNoOfTutorsForPackage}
                    displayPricesProducts={displayPricesProducts}
                    handleProceedButtonClicked={handleProceedButtonClicked}
                    extraTutors={multiselectNoOfTutors}
                    noOfTutors={noOfTutors}
                    {...(idx === 0) && { onCTAButtonClick: (e) => triggerExplorerPopUp(true) }}
                    {...(hasRecommended && {
                      isRecommended: recommendedIdx === idx,
                    })}
                  />
                ))
              ) : (
                <></>
              )}
              {selectedPlanType == 1 ? (
                annualPackages?.map((aPackage, idx) => (
                  <PackageCard
                    {...aPackage}
                    isExpanded={areCardsExpanded[idx]}
                    idx={idx}
                    onBackButtonClick={collapseAll}
                    isEnabledPAYG={paygEnabled}
                    setIsEnabledPAYG={setPaygEnabled}
                    productPrices={productPrices}
                    selectedPlanType={selectedPlanType}
                    selectedProducts={multiselectProducts}
                    setSelectedProducts={setMultiselectProducts}
                    calculatedPrice={calculatedPrice}
                    setNoOfTutorsForPackage={handleChangeNoOfTutorsForPackage}
                    displayPricesProducts={displayPricesProducts}
                    handleProceedButtonClicked={handleProceedButtonClicked}
                    extraTutors={multiselectNoOfTutors}
                    noOfTutors={noOfTutors}
                    {...(idx === 0) && { onCTAButtonClick: (e) => triggerExplorerPopUp(true) }}
                    {...(hasRecommended && {
                      isRecommended: recommendedIdx === idx,
                    })}
                  />
                ))
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="w-full pt-[84px] pb-[84px] w-[88vw] 2.5xl:w-[1320px]">
          <HorizontalDivider className="w-full" />
        </div>
        <PriceComparator
          packageInfo={_monthlyPackages}
          setAreCardsExpanded={setAreCardsExpanded}
        />
        <div className="text-primary-blue font-[600] text-[calc(100vw*48/1440)] 2.5xl:text-[48px] text-left w-full w-[88vw] 2.5xl:w-[1320px] pt-[72px]" id="prod_dep_feature">Pricing for product bundles</div>
        <div className="flex items-center justify-center gap-[33px] 2.5xl:gap-[cal(100vw*33/1440)] w-[88vw] 2.5xl:w-[1320px] pt-[32px]">
          {productFeatures.map((product,idx) => (
            <ServiceModule {...product} idx={idx} />
          ))}
        </div>
          <PopupContainer trigger={explorerPlanPopUpTrigger} setTrigger={setExplorerPlanPopUpTrigger}>
            {
              explorerPlanPopUpTrigger ? <ExplorerPlanConfirmationPopUp setPopUpTrigger={setExplorerPlanPopUpTrigger} handleContinueClick={(e)=>{setExplorerContinueClicked(true);onCTAClickFree(e)}} showLoading={explorerContinueClicked}/>: <></>
            }
          </PopupContainer>
      </div>
    );
  }
};

export default ChoosePlanScreen;
