import React from "react";
import EmptyPaymentMethod from "../../../../components/ProductIcons/EmptyPaymentMethod";
import AddIcon from "@mui/icons-material/Add";

const NoPaymentMethod = ({ openAddNewCard }) => {
  return (
    <div className="flex flex-col justify-center items-center min-h-[588px] gap-[10px] ">
      <div className=" md:p-[24px] gap-[24px] flex flex-col justify-center items-center">
        <div className="w-[284px] md:w-[840px]">
          <EmptyPaymentMethod width="100%" />
        </div>
        <div className="text-[32px] leading-[48px] font-[700] text-center">
          No Saved cards!
        </div>
        <div className="text-[16px] leading-[24px] text-center">
          You haven’t added a payment method yet. Add your credit card now.
        </div>
        <div>
          <div
            className={`bg-blue-70 text-white cursor-pointer  text-center  px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
            onClick={() => {
              openAddNewCard();
            }}
          >
            <AddIcon /> Add Card
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPaymentMethod;
