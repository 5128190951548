import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CloseIcon from "@mui/icons-material/Close";
import { triggerToast } from "../../../../utils/toastController";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import AddNewCard from "../../../../components/SubscriptionOperationPopup/ReviewAndPayContainer/AddNewCard";

const AddNewCardPopUp = ({ open, setOpen }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const options = {
    mode: "setup",
    currency: "usd",
  };

  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[90%] md:w-[468px] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Add Card</div>
        <button onClick={(e) => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>
      <Elements stripe={stripePromise} options={options}>
        <AddNewCard setAddNewCard={setOpen} isFromSettings={true} />
      </Elements>
    </PopupContainer>
  );
};

export default AddNewCardPopUp;
