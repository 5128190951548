export default function ParagraphIcon (props) {

	return (
		<svg width={props.width? props.width :"16"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M7 5C6.73478 5 6.48043 4.89464 6.29289 4.70711C6.10536 4.51957 6 4.26522 6 4C6 3.73478 6.10536 3.48043 6.29289 3.29289C6.48043 3.10536 6.73478 3 7 3H22C22.2652 3 22.5196 3.10536 22.7071 3.29289C22.8946 3.48043 23 3.73478 23 4C23 4.26522 22.8946 4.51957 22.7071 4.70711C22.5196 4.89464 22.2652 5 22 5H7Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			<path d="M7 10C6.73478 10 6.48043 9.89464 6.29289 9.70711C6.10536 9.51957 6 9.26522 6 9C6 8.73478 6.10536 8.48043 6.29289 8.29289C6.48043 8.10536 6.73478 8 7 8H16C16.2652 8 16.5196 8.10536 16.7071 8.29289C16.8946 8.48043 17 8.73478 17 9C17 9.26522 16.8946 9.51957 16.7071 9.70711C16.5196 9.89464 16.2652 10 16 10H7Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			<path d="M7 16C6.73478 16 6.48043 15.8946 6.29289 15.7071C6.10536 15.5196 6 15.2652 6 15C6 14.7348 6.10536 14.4804 6.29289 14.2929C6.48043 14.1054 6.73478 14 7 14H22C22.2652 14 22.5196 14.1054 22.7071 14.2929C22.8946 14.4804 23 14.7348 23 15C23 15.2652 22.8946 15.5196 22.7071 15.7071C22.5196 15.8946 22.2652 16 22 16H7Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			<path d="M7 21C6.73478 21 6.48043 20.8946 6.29289 20.7071C6.10536 20.5196 6 20.2652 6 20C6 19.7348 6.10536 19.4804 6.29289 19.2929C6.48043 19.1054 6.73478 19 7 19H16C16.2652 19 16.5196 19.1054 16.7071 19.2929C16.8946 19.4804 17 19.7348 17 20C17 20.2652 16.8946 20.5196 16.7071 20.7071C16.5196 20.8946 16.2652 21 16 21H7Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			<path d="M3 5.5C3.82843 5.5 4.5 4.82843 4.5 4C4.5 3.17157 3.82843 2.5 3 2.5C2.17157 2.5 1.5 3.17157 1.5 4C1.5 4.82843 2.17157 5.5 3 5.5Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			<path d="M3 10.5C3.82843 10.5 4.5 9.82843 4.5 9C4.5 8.17157 3.82843 7.5 3 7.5C2.17157 7.5 1.5 8.17157 1.5 9C1.5 9.82843 2.17157 10.5 3 10.5Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			<path d="M3 16.5C3.82843 16.5 4.5 15.8284 4.5 15C4.5 14.1716 3.82843 13.5 3 13.5C2.17157 13.5 1.5 14.1716 1.5 15C1.5 15.8284 2.17157 16.5 3 16.5Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			<path d="M3 21.5C3.82843 21.5 4.5 20.8284 4.5 20C4.5 19.1716 3.82843 18.5 3 18.5C2.17157 18.5 1.5 19.1716 1.5 20C1.5 20.8284 2.17157 21.5 3 21.5Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
		</svg>

	)

}
