import React, { useEffect, useState } from 'react'
import CrossIcon from '../CrossIcon/CrossIcon';
import StudentSessionEvent from './StudentSessionEvent';
import StudentConsultationRequest from './StudentConsultationRequest';
import dayjs from 'dayjs';
import { studentParentEventSetter } from '../EventOperator/helpers/setters';
import LoaderIcon from '../LoaderIcon/LoaderIcon';
import { toast } from 'react-toastify';
import { createEventRequest, fetchAllEventRequests, updateEventRequest } from '../../utils/eventService';
import { triggerToast } from '../../utils/toastController';
import { fetchAndSetAllEventRequests } from '../../pages/CalendarV2/helpers/fetchers';
const tabItems = ["Session", "Consultation"]

function EventComponent(props) {
    switch (props?.activeTab) {
        case "Session": return <StudentSessionEvent {...props} />;
        case "Consultation": return <StudentConsultationRequest {...props} />;
    }
}

const EventRequestPopup = (props) => {
    const [activeTab, setActiveTab] = useState("Session")
    const [isLoading, setIsLoading] = useState(false);

    const [selectedStartTime, setSelectedStartTime] = useState("07:00 PM"); 
	const [selectedEndTime, setSelectedEndTime] = useState("08:00 PM");
    const [selectedOption, setSelectedOption] = useState("student");
    const [infoWork, setInfoWork] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState("")
    const [selectedTimeZone, setSelectedTimeZone] = useState(
		"America/New_York"
	);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [isSelectedUser, setIsSelectedUser] = useState([]);
    const [isRecurring, setIsRecurring] = useState(false);
    const [customTutorList, setCustomTutorList] = useState([]);
    const [attendeeList, setAttendeeList] = useState([]);
    const [parentStundentList, setParentStudentList] = useState([]);
    const [origin, setOrigin] = useState("schedule");

    const renderedProps ={
        activeTab, setActiveTab,
        isSelectedUser, setIsSelectedUser,
        selectedStartTime, setSelectedStartTime,
        selectedEndTime, setSelectedEndTime,
        selectedOption, setSelectedOption,
        infoWork, setInfoWork,
        selectedStudents, setSelectedStudents,
        selectedTutor, setSelectedTutor,
        selectedTimeZone, setSelectedTimeZone,
        selectedDate, setSelectedDate,
        isRecurring, setIsRecurring,
        customTutorList, setCustomTutorList,
        attendeeList, setAttendeeList,
        parentStundentList, setParentStudentList,
        origin, setOrigin
    }
    
    
    useEffect(()=>{
		if(props.isEditing) return;
        studentParentEventSetter(props, renderedProps);
    },[props.isEditing])


	useEffect(()=>{
		console.log("TTER")
		if(props.selectedDateFromCalendar){
			const requiredFormat = "hh:mm A"
			setSelectedDate(props.selectedDateFromCalendar)
			const startTimeStr = dayjs(props.selectedDateFromCalendar).format(requiredFormat)
			const endTimeStr = dayjs(props.selectedDateFromCalendar).add(1, "hour").format(requiredFormat)
			setSelectedStartTime(startTimeStr)
			setSelectedEndTime(endTimeStr)
		}
	},[props.selectedDateFromCalendar])

	useEffect(()=>{
		if(props.isEditing){
			setSelectedDate(dayjs(props.eventRequest.requestedEventDetails.date))
			setSelectedTimeZone(props.eventRequest.requestedEventDetails.timeZone)
			setSelectedStartTime([props.eventRequest.requestedEventDetails.time.start.time, props.eventRequest.requestedEventDetails.time.start.timeType].join(" "))
			setSelectedEndTime([props.eventRequest.requestedEventDetails.time.end.time, props.eventRequest.requestedEventDetails.time.end.timeType].join(" "))
			setSelectedStudents(props.eventRequest.requestedEventDetails.studentIds)
			setAttendeeList(props.eventRequest.requestedEventDetails.attendees)
			setInfoWork(props.eventRequest.additionalInfo)
			setActiveTab(props.eventRequest.requestedEventDetails.eventType === "session" ? "Session" :  "Consultation")
			setSelectedTutor(props.eventRequest.requestedEventDetails.tutorId)
			setIsSelectedUser(props.eventRequest.requestedEventDetails.attendeeDetails?.map(val=>val.id))
		}
	},[props.isEditing])


	useEffect(()=>{
		if(props.user?.role === "student"){

		} 
	},[props.user])


	console.log("ATTDER",isSelectedUser)


    const submitFunction = async()=>{

        setIsLoading(true);

        try {

            if(activeTab === "Session"){

				const sendData ={
					requestedEventDetails:{
						date :selectedDate,
						timeZone: selectedTimeZone,
						time:{
							start:{
								time: selectedStartTime.split(" ")[0] ,
								timeType:selectedStartTime.split(" ")[1], 
							},
							end:{
								time: selectedEndTime.split(" ")[0] ,
								timeType:selectedEndTime.split(" ")[1],

							}
						},  
						studentIds: selectedStudents,
						tutorId: selectedTutor,
						eventType :"session",
						...(props.isEditing) && {requestedOn : props.eventRequest.requestedEventDetails.requestedOn ?? (new Date()).toISOString()}
					},
					additionalInfo: infoWork,
					requestType:"schedule",
				}
                console.log(sendData);
				
				if(props.isEditing){
					await updateEventRequest(props.eventRequestId, sendData).then((res)=>{
						triggerToast("Event Request updated.", "success", {autoClose:3000});
						fetchAndSetAllEventRequests(fetchAllEventRequests,props.setAllEventRequests)
					}).catch((error)=>{
						console.log(error)
						triggerToast("Something went wrong.", "error", {autoClose:3000});
					}). finally(()=>{
						setIsLoading(false);
					})
					return
				}
                
                await createEventRequest(sendData).then((res)=>{
                    triggerToast("Event Request sent.", "success", {autoClose:3000});
					fetchAndSetAllEventRequests(fetchAllEventRequests,props.setAllEventRequests)
                }).catch((error)=>{
                    console.log(error)
                    triggerToast("Something went wrong.", "error", {autoClose:3000});
                }). finally(()=>{
                    setIsLoading(false);
                })


            }

            else{

                const sendData ={
					requestedEventDetails:{
						date :selectedDate,
						timeZone: selectedTimeZone,
						time:{
							start:{
								time: selectedStartTime.split(" ")[0] ,
								timeType:selectedStartTime.split(" ")[1], 
							},
							end:{
								time: selectedEndTime.split(" ")[0] ,
								timeType:selectedEndTime.split(" ")[1],
							}
						},  
						attendeeDetails: isSelectedUser.map((userId)=>{
							return {id: userId}
						}) ,
						eventType :"consultation",
						...(props.isEditing) && {requestedOn : props.eventRequest.requestedEventDetails.requestedOn ?? (new Date()).toISOString()}
					},
                    additionalInfo: infoWork,
                    requestType:"schedule",
                }
                console.log(sendData);

				if(props.isEditing){
					await updateEventRequest(props.eventRequestId,sendData).then((res)=>{
						triggerToast("Event Request updated.", "success", {autoClose:3000});
						fetchAndSetAllEventRequests(fetchAllEventRequests,props.setAllEventRequests)
					}).catch((error)=>{
						console.log(error)
						triggerToast("Something went wrong.", "error", {autoClose:3000});
					}). finally(()=>{
						setIsLoading(false);
					})
					return
				}
                
                await createEventRequest(sendData).then((res)=>{
                    triggerToast("Event Request sent.", "success", {autoClose:3000});
					fetchAndSetAllEventRequests(fetchAllEventRequests,props.setAllEventRequests)
                    console.log(res);
                }).catch((error)=>{
                    console.log(error)
                    triggerToast("Something went wrong.", "error", {autoClose:3000});

                }). finally(()=>{
                    setIsLoading(false);
                })

            }

        } catch (error) {
            console.log(error)
            triggerToast("Something went wrong.", "error", {autoClose:3000});
                 
        }finally{
            setIsLoading(false)
        }

		props.setTrigger(false)
    }



    return (
        <main className='md:w-[572px] min-w-full'>

            {/* Top haeder section */}
            <section className='flex justify-between items-center'>
                <div className='flex items-center gap-3'>
                    {
                        tabItems.map((el, i) => {
                            return <button className={`font-normal text-[16px] leading-[24px] px-3 py-1 rounded-[6px] ${activeTab === el ? "text-blue-70 bg-blue-10" : "text-secondary-80"} ${props.isEditing && el !== activeTab ? "hidden" : "" }`} onClick={() => setActiveTab(el)}>{el}</button>
                        })
                    }
                </div>
                <div className='cursor-pointer' onClick={() => props?.setTrigger(false)}>
                    <CrossIcon />
                </div>
            </section>
            
            <div className='border-b h-4'>&nbsp;</div>


            {/* Componenet Rendering section */}
            <section className='px-3 pt-6'>
                <EventComponent {...props} {...renderedProps} />
            </section>


            {/* Submit section */}
            <section className='flex flex-col justify-center md:flex-row md:justify-end gap-9 items-center md:pt-4 pt-9 md:border-t md:mt-6 border-secondary-10 '>
                <button disabled={
					activeTab === "Session" ?  
					((selectedStudents?.length < 1) || !selectedTutor || (infoWork?.trim() === ""))	
					: 
					((isSelectedUser?.length < 1) || (infoWork?.trim() === ""))	
				} className='bg-blue-70  px-5 py-[10px] rounded-md text-white text-[16px] leading-[20px] flex items-center justify-center min-w-[164.45px] font-medium' onClick={submitFunction} > {isLoading ? <LoaderIcon/> : props.isEditing ? "Save Changes" : "Submit Request"}</button>
            </section>
        </main>
    )
}

export default EventRequestPopup
