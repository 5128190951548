import styles from './FolderTab.module.css'


const FolderTab = ({children, selectedBackgroundColour, normalBackgroundColour, isSelected, onClick, id}) => {
    return(
        <div className={isSelected ? `${styles.containerSelected}` : `${styles.containerNormal}`} style={{backgroundColor : isSelected ? selectedBackgroundColour : normalBackgroundColour}} onClick={(e)=>onClick(e)} id={id}>
            {children}
        </div>
    )
}

export default FolderTab