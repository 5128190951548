import frozenEventTypes from "../../../utils/eventTypes";
import { sessionUpdater } from "./sessionUpdater";
import handleUpdateConsultation from "./consultationUpdater";
import handleUpdateInternalMeeting from "./internalMeetingUpdater"
import bookedMeetingUpdater from "./bookedMeetingUpdater";
import handleUpdateOfficeWork from "./officeWorkUpdater";


function officeWorkUpdater(allEventData, updateFutureSessions) {
  console.log("OFFICE WORK UPDATER", updateFutureSessions);
}

function updateController(eventType) {
  switch (eventType.toLowerCase()) {
    case frozenEventTypes.SESSION.eventType.toLowerCase():
      return sessionUpdater;
    case frozenEventTypes.INTMEETING.eventType.toLowerCase():
      return handleUpdateInternalMeeting;
    case frozenEventTypes.OFFCWORK.eventType.toLowerCase():
      return handleUpdateOfficeWork;
    case frozenEventTypes.CONSULTATION.eventType.toLowerCase():
      return handleUpdateConsultation;
    case frozenEventTypes.EXTMEETING.eventType.toLowerCase():
      return bookedMeetingUpdater;
  }
}

export { updateController };
