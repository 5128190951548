import { Link } from "react-router-dom"
import amexIcon from '../assets/amex.svg'
import mastercardIcon from '../assets/mastercard.svg'
import visaIcon from '../assets/visa.svg'
import dinersIcon from "../assets/diners.svg"
import discoverIcon from "../assets/discover.svg"
import jcbIcon from "../assets/jcb.svg"
import unionIcon from '../assets/unionpay.svg'
import { fetchPriceForId } from "./fetchers"
import ActiveIndicator from "../../../components/ActiveIndicator/ActiveIndicator"
import Tooltip from "../../../components/Tooltip/Tooltip"
import InfoIcon from "../../../components/InfoIcon/InfoIcon"

const months = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"]
const cardBrandImageMapping = { 
    "amex" : amexIcon, 
    "diners" : dinersIcon, 
    "discover" : discoverIcon, 
    "eftpos_au" : "", 
    "jcb" : jcbIcon, 
    "mastercard" : mastercardIcon, 
    "unionpay" : unionIcon, 
    "visa" : visaIcon
}

function configureSubscriptionTableData(invoices) {
    const tableData = {}
    const tableHeader = [
        { text: "Invoice Number", format: "!text-left" },
        { text: "Description", format: "!text-left" },
        { text: "Paid on", format: "text-center" },
        { text: "Payment Mode", format: "text-center" },
        { text: "Invoice", format: "text-center" },
      ]
    const tableBody = []

    invoices.forEach((invoice,idx)=>{
        if(!invoice.paymentMethod && idx === 0) return;
        const date = new Date(invoice.paidOn * 1000)
        const dateString = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
        tableBody.push({
            data : [
                invoice.id,
                `\$ ${(invoice.amount/100).toFixed(2)}`,
                dateString,
                <div className="flex flex-row-reverse items-center justify-center gap-[4px] ">{`${invoice.paymentMethod ? "****" : "-" }${invoice.paymentMethod?.card.last4 ?? ""}`} <img src={cardBrandImageMapping[invoice.paymentMethod?.card?.brand]} className="h-[24px] w-[39px] object-contain"/> </div>,
                <a href={invoice.pdfURL} target="_blank" rel={"external"}>Download</a>
            ],
            status : invoice.status === "paid"
        })
    })

    tableData.header = tableHeader
    tableData.body = tableBody
    tableData.gridConfigClass = "grid-cols-[repeat(5,auto)]",
    tableData.gridHorizontalDividerClass = "col-span-5"

    console.log("*****",tableData)
    return tableData
}


function configurePAYGTableData(usage, usageRecords){
    const tableData = {}
    const tableHeader = [
        { text: "Product", format: "!text-left" },
        { text: "Status" },
        { text: "Started on" },
        { text: "Overage usage" },
        {
          text: (
            <div className="flex items-center justify-center gap-[6px]"><>Overage charges</> <Tooltip anchorComponent={<InfoIcon className="text-secondary-30"/>} tooltipStyleClass={"tooltipStyleClass !-left-[50px] !top-[20px]"} tooltipContent={"Charges levied per additional usage of the respective product's unit."} tooltipTriangleStyling={"!left-[10px] !top-[2px]"}/></div>
          ),
        },
        { text: "Amount" },
    ]
    const tableBody = []

    //Setup for assignments
    const usageRecordAssignments = usageRecords["Assignments_PAYG"] ?? usageRecords["Assignments_PAYG_Annual"]
    const usageAssignments = usage.assignments.metered
    const isActiveAssignments = usageRecordAssignments.data[0].total_usage > 0
    const startedDateAssignments = isActiveAssignments ? (new Date(usageRecordAssignments.data[0].period.start * 1000)) : null
    const assignmentUnitAmount = usageRecordAssignments.unit_amount
    const assignmentsUnitAmountText = `${usageRecordAssignments.unit_amount}¢/credit`
    const totalPriceIncurredAssignments =  isActiveAssignments ? `\$ ${(usageRecordAssignments.data[0].total_usage * assignmentUnitAmount/100).toFixed(2)}` : "-"

    tableBody.push({
        data : [
            "Assignments",
            isActiveAssignments ?  <ActiveIndicator isActive={true} /> : "Inactive",
            isActiveAssignments ? `${months[startedDateAssignments.getMonth()]} ${startedDateAssignments.getDate()}, ${startedDateAssignments.getFullYear()}` : "-",
            `${usageRecordAssignments.data[0].total_usage} credits`,
            assignmentsUnitAmountText,
            <div className="font-medium">{totalPriceIncurredAssignments}</div>,
        ],
        status : isActiveAssignments
    })

    const calendaryPAYGKey = Object.keys(usageRecords).find((key)=>key.includes("Calendar"))
    //Setup for calendar
    const usageRecordCalendar = usageRecords[calendaryPAYGKey]
    const usageCalendar = usage.assignments.metered
    const isActiveCalendar = usageRecordCalendar?.data[0]?.total_usage > 0
    const startedDateCalendar = isActiveCalendar ? (new Date(usageRecordCalendar.data[0].period.start * 1000)) : null
    const calendarUnitAmount = usageRecordCalendar.unit_amount
    const calendarUnitAmountText = `${usageRecordCalendar.unit_amount}¢/session`
    const totalPriceIncurredCalendar = isActiveCalendar ?  `\$ ${(usageRecordCalendar.data[0].total_usage * calendarUnitAmount/100).toFixed(2)}` : "-"

    tableBody.push({
        data : [
            "Calendar",
            isActiveCalendar ? <ActiveIndicator isActive={true} /> : "Inactive",
            isActiveCalendar ?`${months[startedDateCalendar.getMonth()]} ${startedDateCalendar.getDate()}, ${startedDateCalendar.getFullYear()}`  : "-",
            `${usageRecordCalendar.data[0].total_usage} sessions`,
            calendarUnitAmountText,
            <div className="font-medium">{totalPriceIncurredCalendar}</div>,
        ],
        status : isActiveCalendar
    })

    tableData.header = tableHeader
    tableData.body = tableBody
    tableData.gridConfigClass = "grid-cols-[repeat(6,auto)]",
    tableData.gridHorizontalDividerClass = "col-span-6"

    console.log(">>>PAYG DATA",tableBody)

    return tableData
}


export {
    configureSubscriptionTableData,
    configurePAYGTableData
}