import subscription from "../../../app/slices/subscription"
import { getPriceForAdditionalTutorLicenses } from "../../../utils/utils"
import { getBasePackageDetails } from "../../../components/SubscriptionOperationPopup/Utils/helpers"
import formSummaryObject from "./formSummaryObject"



const formSummaryObjectFromSubscription = (subscriptionV2, productPrices, selectedPlanType, selectedProducts, selectedPlanId, additionalTutors) => {

	const tempSiMapping = []
	const requiredProducts = [
		"Assignments", "Custom Tests", "Calendar", "BasePackage", "Tutors"
	]
	const oldPlanType = 1 
	const newPlanType = selectedPlanType === 0 ? "monthly" : "annual"
    const [basePackageLookupKey, basePackagePlanName, basePackagePrice] = getBasePackageDetails(productPrices, selectedPlanId, newPlanType)

	for(const product of requiredProducts){
		let key = ""
		if(product === "Assignments") {
			key = "assignments"	
			tempSiMapping.push({
				lookupKey : "Assignments",
				newPriceId  : selectedProducts[product].value,
				priceId : subscriptionV2?.subscription[key].priceId
			})
		} else if (product === "Custom Tests"){
			key = "customContent"
			tempSiMapping.push({
				lookupKey : "CustomContent",
				newPriceId : selectedProducts[product].value,
				priceId : subscriptionV2?.subscription.assignments[key].priceId
			})
		} else if (product === "Calendar")	{
			key = "sessions"
			tempSiMapping.push({
				lookupKey : "Calendar",
				newPriceId : selectedProducts[product].value,
				priceId : subscriptionV2?.subscription[key].priceId
			})
		} else if (product === "BasePackage"){
			tempSiMapping.push({
				lookupKey : "BasicPackage",
				newPriceId : selectedPlanId,
				priceId : subscriptionV2?.subscription.basePackage.priceId
			})
		} else if (product === "Tutors"){
			tempSiMapping.push({
				lookupKey : "Tutor",
				newPriceId : getPriceForAdditionalTutorLicenses(productPrices, newPlanType, basePackagePlanName).id,
				priceId : getPriceForAdditionalTutorLicenses(productPrices, "annual", "Starter").id,
				quantity : additionalTutors
			})
		}
	}
	
	console.log(formSummaryObject(tempSiMapping.filter((val)=> val.priceId !== val.newPriceId), ()=>{},subscriptionV2, productPrices, selectedPlanType, ()=>{},[]))
	return formSummaryObject(tempSiMapping.filter((val)=> val.priceId !== val.newPriceId), ()=>{},subscriptionV2, productPrices, selectedPlanType, ()=>{},[])

}

export {
	formSummaryObjectFromSubscription
}
