import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import LocationIcon from "../../assets/calendarV2/LocationIcon";
import { EvChevronDownIcon, EvChevronUpIcon } from "../../assets/icons/ChevronIcons";
import frozenEventTypes from "../../utils/eventTypes";
import OnlineGlobeIcon from "../CalendarSideIcons/OnlineGlobeIcon";

const formatAddress = (fullAddress) => {
  if (fullAddress === "online") {
    return "Online"
  }
  const { address, city, state, country, zip } = fullAddress;
  const parts = [address, city, state, zip, country].filter(Boolean);
  return parts.join(", ");
};

const LocationSelection = ({ setLocation, location, setActive, user, origin , operationMode, requiredEventData, selectedEventType }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const { addresses } = useSelector(
    (state) => state.organization?.organization
  );


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (location && ((typeof(location)==="string" && location.trim() !=="" ) || location?.address)) setActive(true);
    else setActive(false);
  }, [location])


  console.log(addresses)


  const handleSelectLocation = (item) => {
    setOpenDropdown(false);
    setLocation(item);
  };

  //To Enable or disable editing based on the role
  const isEnabledEditing = useMemo(()=>{
		const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
		if(operationMode === "edit" && requiredEventData && nonSessionTypes.includes(selectedEventType?.toLowerCase())){
			if(requiredEventData.isEditingRequest){
				console.log("REQ", requiredEventData)
				return requiredEventData.eventRequestor._id === user?.id
			}
			return requiredEventData.initiatorDetails.id === user?.id
		}
		return ["admin","tutor"].includes(user?.role) || origin && origin === "setting"
  },[user?.role])

  useEffect(()=>{
    if(isEnabledEditing === true && operationMode && ["create"].includes(operationMode) && user?.userDefaults?.location !== "" ) setLocation(user?.userDefaults?.location)

  },[user,isEnabledEditing])

  console.log(operationMode)


  return (
    <div className="flex items-center justify-between gap-[24px] w-full min-h-[45px]" style={{pointerEvents : isEnabledEditing ? "" : "none"}}>
      <div className="grid relative w-full" ref={dropdownRef}>
        <div
          className={`w-full px-[12px] py-[7.5px] rounded-[6px] flex justify-between items-center leading-[21px] font-normal text-[14px] text-gray-600 cursor-pointer bg-[#F6F9FA] ${openDropdown ? "border border-blue-70" : ""
            }`}
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <div className={`text-[14px] ${!location ? "text-secondary-60" : "text-secondary-80"}`}>{location ? formatAddress(location) : "Select Location"}</div>
          <div className={`${location !== "" ? "text-secondary-80" : "text-secondary-60"}`} style={{display : isEnabledEditing ? "" : "none"}}>
            {openDropdown ? <EvChevronUpIcon inherit={true} /> : <EvChevronDownIcon inherit={true} />}
          </div>
        </div>
        {openDropdown && (
          <div className="absolute top-11 w-full max-h-64 grid gap-[6px] overflow-y-auto bg-white shadow-[0px_4px_18px_0px_#11167D1A] rounded">
            {/* Option for "Online" */}
            <div
              className="flex items-center gap-[6px] cursor-pointer hover:bg-secondary-10 py-1 px-3 border-b border-secondary-10"
              onClick={() => handleSelectLocation("online")}
            >
              <OnlineGlobeIcon />
              <span className="font-[400] text-[16px] leading-[16px] text-secondary-80 max-h-[38px] p-1 overflow-y-hidden">
                Online
              </span>
            </div>

            {addresses && addresses.length > 0 ? (
              addresses.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center gap-[6px] cursor-pointer hover:bg-secondary-10 p-1"
                  onClick={() => handleSelectLocation(item)}
                >
                  <span>
                    <LocationIcon />
                  </span>
                  <span className="font-[400] text-[16px] leading-[16px] text-secondary-80 max-h-[38px] p-1 overflow-y-hidden">
                    {formatAddress(item)}
                  </span>
                  {item.isPrimary && (
                    <span className="cursor-default rounded-full h-fit text-[12px] font-[400] leading-[18px] px-[10px] py-[2px] bg-success/10 text-success">
                      Primary
                    </span>
                  )}
                </div>
              ))
            ) : (
              <div className="px-3 mb-2" >No addresses available</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationSelection;
