import CatIcon from '../../../../assets/icons/SettingsIcon/CatIcon'
import CatMobileIcon from '../../../../assets/icons/SettingsIcon/CatMobileIcon'
import styles from './DomainIntegrationEmptyState.module.css'


const DomainIntegrationEmptyState = (props) => {

    return (
        <div className={styles.container} >
            {props.children}
            <div className={styles.catImageMobile}>
                <CatMobileIcon />
            </div>
            <div className={styles.catImageDesktop}>
                <CatIcon />
            </div>
            <div className={styles.innerContainer}>
                <h3>
                    No Sub-domain Added
                </h3>
                <p>
                    You haven’t connected your custom sub-domain yet.
                </p>
            </div>
            <button onClick={() => props.onClick()}>Connect Now</button>
        </div>
    )

}


export default DomainIntegrationEmptyState