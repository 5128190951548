import React, { useState } from "react";
import CopyIcon from "../../assets/calendarV2/CopyIcon";
import AttachmentIcon from "../../assets/calendarV2/AttachmentIcon";
import GoToIcon from "../../assets/calendarV2/GoToIcon";
import { triggerToast } from "../../utils/toastController";
import { toast } from "react-toastify";


function getCalendarBookingURL(user){
	const calendarBookingLink = `/calendar/bookings?id=${user.id}`
	const { origin } = window.location
	const url = new URL(origin+calendarBookingLink)
	return url
}

const CalendarBookingLinkCopy = (props) => {
 
	const {user} = props
	const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
	

  const handleOnClick = () => {
		window.open(getCalendarBookingURL(user))
  };

  return (
    <button
      className="flex h-[36px] gap-[8px] items-center justify-between rounded-[24px] px-[16px] py-[2px]
       bg-[#FFEDAC] text-[#7C560D] leading-[14px] font-400"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        handleOnClick();
      }}
    >
      {isHovered ? (
        <>
          <span 
			className="bg-[#D28D0A] h-[24px] w-[24px] rounded-[24px] flex items-center justify-center" 
			onClick={(e)=>{e.stopPropagation();navigator.clipboard.writeText(getCalendarBookingURL(user)); toast.success("Link Copied", {autoClose : 3000});}}
		  >
            <CopyIcon />
          </span>
          <span className="flex">
            Booking Link <GoToIcon />
          </span>
        </>
      ) : (
        <>
          <span className="h-[24px] w-[24px] rounded-[24px] flex items-center justify-center">
            <AttachmentIcon />
          </span>
          <span className="flex">
            Booking Link <GoToIcon />
          </span>
        </>
      )}
    </button>
  );
};

export default CalendarBookingLinkCopy;
