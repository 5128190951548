import React, { useState, useEffect } from "react";
import PriceSelectDropDown from "../../../pages/ManageSubscription/components/PriceSelectDropDown";
import HorizontalThinLine from "../../ProductIcons/HorizontalThinLine";
import InfoIcon from "../../ProductIcons/InfoIcon";
import Tooltip from "../../Tooltip/Tooltip";
const ProductSelector = ({
  product,
  selectedProducts,
  siMapping,
  setSiMapping,
  selectedPlanType,
  productIcon,
  selectionDisabled,
  idx,
}) => {
  const [editTier, setEditTier] = useState(false);
  const [numberOfUnits, setNumberOfUnits] = useState();

  console.log("selected tier", selectedProducts);

  const handlePriceChange = (value) => {
    let tempSelectedProducts = { ...selectedProducts };
    tempSelectedProducts[product.name] = {
      ...tempSelectedProducts[product.name],
      price:
        product.priceList.find((val) => val.id === value).unit_amount / 100,
      value,
      default: false,
    };

    setSiMapping(tempSelectedProducts);
  };


  useEffect(()=>{
    console.log(selectedProducts)
  },[selectedProducts])

  const getTootlTipContent = () => {
    switch (product.name) {
      case "CRM":
        return "The CRM is included for free with each subscription of Evallo. You get access to unlimited student and parent accounts, client management, advanced profile filters, student groups, custom tags, leads pipeline, customer profiles, dashboards, and more!";
      case "Calendar":
        return `You have chosen ${numberOfUnits} Calendar sessions to be included with your subscription. The calendar comes with flexible scheduling, recurring sessions, time blocks, tags and notes, statuses, and many more features!`;
      case "Assignments":
        return `You have chosen ${numberOfUnits} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you. One credit is used every time you send a new assignment to a student.`;
      case "Custom Tests":
        return `You have chosen ${numberOfUnits} Custom Test credits to be included with your subscription. These credits allow you to upload new material (such as practice tests, drills, worksheets, etc.) to your account, giving you the freedom to build your own curriculum.`;
      default:
        return "tool tip";
    }
  };
  return (
    <div>
      <div className="flex justify-between gap-[12px] text-[16px] items-center min-h-[30px] text-secondary ">
        {productIcon}
        <div
          className={`w-[30%] font-[400]`}
          // style={{ color: `${product.color}` }}
        >
          {product.name}
        </div>
        <div className=" w-[35%]">
          {product.priceList ? (
            <div className="flex justify-between items-center">
              <div className="w-[90%]">
                <PriceSelectDropDown
                  priceList={product.priceList}
                  title={product.title}
                  selectedPrice={selectedProducts[product.name]}
                  siMapping={siMapping}
                  setSiMapping={handlePriceChange}
                  setEditTier={setEditTier}
                  selectedPlanType={selectedPlanType}
                  selectionDisabled={selectionDisabled}
                  setNumberOfUnits={setNumberOfUnits}
                />
              </div>

              <Tooltip
                anchorComponent={
                  <div className="">
                    <InfoIcon />
                  </div>
                }
                tooltipContent={
                  selectionDisabled
                    ? "Product limits cannot be changed in Explorer plan. To increase limits, upgrade your plan."
                    : getTootlTipContent()
                }
                tooltipStyleClass="bg-white rounded p-[12px] leading-[18px]  font-[400] text-[12px] w-[240px] -mt-[8px] text-secondary-70 shadow-slate-50"
                tooltipTriangleStyling=""
              />
            </div>
          ) : (
            <span className="text-[14px]">Unlimited Student profiles</span>
          )}
        </div>
        <div className="w-[30%] mr-[5%] flex items-center  justify-end">
          <span className="font-[400]">
            {selectedProducts[product.name].price !== 0
              ? selectedPlanType === 0
                ? `$${selectedProducts[product.name]?.price.toFixed(0)}`
                : `$${(selectedProducts[product.name]?.price / 12)?.toFixed(0)}`
              : "Free"}
          </span>
          {selectedProducts[product.name].price !== 0 && (
            <span className="text-secondary-60 text-[12px]">/mo</span>
          )}
        </div>
      </div>
      <div className="mt-[12px] ">{idx !== 3 && <HorizontalThinLine />}</div>
    </div>
  );
};

export default ProductSelector;
