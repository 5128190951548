import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PaymentMethodCard from "../../../../components/SubscriptionOperationPopup/ReviewAndPayContainer/PaymentMethodCard";
import EditCardPopUp from "./EditCardPopUp";
import { useMakeDefaultPaymentMutation } from "../../../../app/services/subscription";
import { triggerSubscriptionUpdate } from "../../../../app/slices/subscription";
import { triggerToast } from "../../../../utils/toastController";

const CardContainer = ({ item, setOpenDelete, setDeleteThisCard }) => {
  const dispatch = useDispatch();
  const {
    defaultPaymentMethodId,
    stripeCustomerId,
    paymentMethods,
    subscriptionV2,
  } = useSelector((state) => state.subscription);
  const [openEditCardPopUp, setEditCardPopUp] = useState(false);
  const [makeDefaultPayment] = useMakeDefaultPaymentMutation();
  const [isCardDefault, setIsCardDefault] = useState(
    defaultPaymentMethodId === item?.id
  );
  const popupRef = useRef(null);

  const handleMakeCardDefault = async () => {
    const result = await makeDefaultPayment({
      customerId: stripeCustomerId,
      paymentMethodId: item.id,
    });

    if (result?.error) {
      triggerToast("Unable to set card as default", "error", {
        autoClose: 2000,
      });
      return;
    }
    dispatch(triggerSubscriptionUpdate());
    triggerToast("Default Card set successfully!", "success", {
      autoClose: 2000,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setEditCardPopUp(false);
      }
    };

    if (openEditCardPopUp) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openEditCardPopUp]);

  useEffect(() => {
    setIsCardDefault(defaultPaymentMethodId === item?.id);
    console.log("default card", defaultPaymentMethodId);
  }, [defaultPaymentMethodId]);

  return (
    <div className="w-full md:w-[480px] flex justify-between  h-fit">
      <div className="w-[90%] md:w-[75%] h-fit ">
        <PaymentMethodCard
          card={item}
          isDefault={isCardDefault}
          isFromSettings={true}
        />
      </div>

      {paymentMethods.length === 1 && isCardDefault &&
      !subscriptionV2?.subscription?.basePackage?.name?.includes("Free") ? (
        ""
      ) : (
        <div
          className="flex justify-center items-center cursor-pointer relative"
          onClick={() => {
            setEditCardPopUp((prev) => !prev);
          }}
        >
          <div className="md:hidden">
            <MoreVertIcon />
          </div>
          <div className="hidden md:block">
            <MoreHorizIcon />
          </div>

          {openEditCardPopUp && (
            <div ref={popupRef} className="">
              <EditCardPopUp
                makeDefault={handleMakeCardDefault}
                isDefault={isCardDefault}
                setOpenDelete={(val) => {
                  setOpenDelete(val);
                }}
                setDeleteThisCard={() => {
                  setDeleteThisCard({
                    customerId: item.customer,
                    paymentMethodId: item.id,
                  });
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardContainer;
