import React, { useState, useEffect, useMemo } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  useCreateIntentMutation,
  useFinishSetupMutation,
  useCreateNewSubscriptionMutation,
  useLazyCouponValidityQuery,
} from "../../../app/services/subscription";
import {
  useLazyGetPersonalDetailQuery,
  useLazyGetOrganizationQuery,
} from "../../../app/services/users";
import getConvertedPlanType from "../../ProductCard/planTypeConverter";
import ApplyCoupon from "../../ApplyCouponInput/ApplyCoupon";
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
import { toast } from "react-toastify";
import { useUpdateOrganizationDetailMutation } from "../../../app/services/organization";
import { useUpdateOrgDataMutation } from "../../../app/services/admin";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";
import { fetchAndSetSubscription } from "../../../utils/subscriptionService";
import { triggerToast } from "../../../utils/toastController";
import { useDispatch } from "react-redux";
import { updateFetchData } from "../../../app/slices/user";

const AddCardDetails = ({
  submitted,
  productPrices,
  selectedProducts,
  setSelectedProducts,
  selectedBillingCycle,
  setSelectedBillingCycle,
  AddOnsPrice,
  basePackagePrice,
  stripeCustId,
  paygEnabled,
  subscriptionCoupon,
  setSubscriptionCoupon,
  discountAmount,
  setDiscountAmount,
  setIsLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createIntentForPayment, createIntentForPaymentResp] =
    useCreateIntentMutation();
  const [finishSetupForPayment, finishSetupForPaymentResp] =
    useFinishSetupMutation();
  const [isPaymentProcessOnGoing, SetIsPaymentProcessOnGoing] = useState(false);
  const [createNewSubscription, createNewSubscriptionResp] =
    useCreateNewSubscriptionMutation();
  const [getUserDetails, getUserDetailsResp] = useLazyGetPersonalDetailQuery();
  const [getOrgDetails, getOrgDetailsResp] = useLazyGetOrganizationQuery();
  const [checkCouponValidity, respCheckCouponValidity] =
    useLazyCouponValidityQuery();
  const [coupon, setCoupon] = useState("");
  const [validCoupon, setValidCoupon] = useState(false);
  const [updateOrgDetail] = useUpdateOrgDataMutation();

  useEffect(() => {
    if (count === 0) return;
    if (!productPrices) return;

    const res = getConvertedPlanType(
      productPrices,
      selectedBillingCycle,
      selectedProducts.selectedProducts
    );

    if (res) {
      const tutorProducts = productPrices.find(
        (val) => val.product.name === "TutorsV2"
      );
      const tutorProductPrices =
        selectedBillingCycle === 0
          ? tutorProducts.prices.monthly
          : tutorProducts.prices.annual;

      const requiredPrice = tutorProductPrices.find((val) =>
        val.lookup_key.includes(
          selectedProducts.selectedProducts.basePackage.name
        )
      );

      // destrcut tutor
      res.tutors = {
        ...res.tutors,
        value: requiredPrice.id,
        price: requiredPrice.unit_amount / 100,
      };
      const basePackageProductPrice = productPrices
        .find((val) => val.product.name === "BasePackageV2")
        .prices[selectedBillingCycle === 0 ? "monthly" : "annual"].find((val) =>
          val.lookup_key.includes(
            selectedProducts.selectedProducts.basePackage.name
          )
        );
      //destruct basePackage
      res.basePackage = {
        ...res.basePackage,
        lookup_key: basePackageProductPrice.lookup_key,
        value: basePackageProductPrice.id,
        price: basePackageProductPrice.unit_amount / 100,
      };

      setSelectedProducts({
        selectedPlanType: selectedBillingCycle === 0 ? "monthly" : "annual",
        selectedProducts: res,
      });
    }
  }, [selectedBillingCycle]);

  useEffect(() => {
    const submitForm = async () => {
      await handleSubmit();
    };
    submitForm();
    setCount(count + 1);
  }, [submitted]);

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    SetIsPaymentProcessOnGoing(true);
    setIsLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log(submitError);
      SetIsPaymentProcessOnGoing(false);
      setIsLoading(false);

      return;
    }
    let stripeCustIdFromOrg = "";
    // if no stripe cust Id, fetch org details for stripeCustId
    if (!stripeCustId) {
      const userDetails = await getUserDetails();
      if (userDetails.error) {
        triggerToast(userDetails.error.data.message, "error", {
          autoClose: 3000,
        });

        return;
      } else {
        const user = userDetails.data.data.user;
        const orgDetails = await getOrgDetails(user.associatedOrg);
        if (orgDetails.error) {
          triggerToast(orgDetails.error.data.message, "error", {
            autoClose: 3000,
          });

          return;
        } else {
          stripeCustIdFromOrg = orgDetails.data.organisation.stripeCustomerId;
        }
      }
    }

    // Create the SetupIntent and obtain clientSecret
    const res = await createIntentForPayment({
      customer_id: stripeCustId ? stripeCustId : stripeCustIdFromOrg,
    });

    console.log("response from create_intent");
    console.log("SETUP INTENT RESPONSE", res);

    const { client_secret: clientSecret } = res.data;

    // Confirm the SetupIntent using the details collected by the Payment Element
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://stackblitz-starters-vzyaus.stackblitz.io/payment",
      },
    });

    console.log("setupIntent");
    console.log(setupIntent);

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      triggerToast(
        "Oops! Seems like the card info you added has some issue. Please check and try again.",
        "error",
        { autoClose: 5000 }
      );
      setIsLoading(false);
      console.log(submitError);
    } else {
      const post = await finishSetupForPayment({
        setupintent: setupIntent?.id,
      });

      if (post) {
        console.log("Setup intent successful");

        updateOrgDetail({
          op: "update",
          orgId:
            window.localStorage.getItem("orgId") ??
            window.sessionStorage.getItem("orgId"),
          fields: {
            isEnabledPAYG: paygEnabled,
          },
        }).then((res) => console.log(res));
        //create new subscription

        //Sample request
        // {
        // 	items : [{price : , value : , selected :, quantity : --> Wherever applicable }],
        // 	isFreeTrial : [true, false],
        //  planType : [annual, monthly],
        //	isPAYGEnabled : [true, false],
        //  couponId: "id"
        // }

        let body = {
          items: [],
          isFreeTrial: true,
          planType: selectedBillingCycle === 0 ? "monthly" : "annual",
          isEnabledPAYG: true,
          couponId: subscriptionCoupon?.couponId,
        };

        for (const [key, value] of Object.entries(
          selectedProducts.selectedProducts
        )) {
          if (key !== "CRM" && key !== "basePackage") {
            const item = {
              price: value.price,
              value: value.value,
              selected: value.selected,
              default: value.default || false,
              name: key,
            };
            if (key === "tutors" && value.quantity !== undefined) {
              item.quantity = value.quantity;
            }
            body.items.push(item);
          }
        }
        if (selectedProducts.selectedProducts.basePackage) {
          const basePackageItem = {
            price: selectedProducts.selectedProducts.basePackage.price,
            value: selectedProducts.selectedProducts.basePackage.value,
            lookup_key:
              selectedProducts.selectedProducts.basePackage.lookup_key,
            name: selectedProducts.selectedProducts.basePackage.name,
          };
          body.items.push(basePackageItem);
        }

        console.log(body);

        let subscription = await createNewSubscription(body);
        if (!subscription.error) {
          triggerToast(
            "Success! Redirecting to your Evallo dashboard.",
            "success",
            { autoClose: 3000 }
          );
          await fetchAndSetSubscription();
          dispatch(updateFetchData(true));
          navigate("/");

          setIsLoading(false);
        } else {
          toast.error("Subscription creation failed");
          setIsLoading(false);
        }

        if (!subscription.data.success) {
        } else {
        }
      }
    }
  };

  const applyCouponToSubscription = async () => {
    let response = "";
    try {
      response = await checkCouponValidity({
        couponName: coupon,
      });
      if (response?.data?.coupon?.valid) {
        setValidCoupon(true);
        let amount_off = response.data.coupon.amount_off
          ? response.data.coupon.amount_off
          : null;
        let percent_off = response.data.coupon.percent_off
          ? response.data.coupon.percent_off
          : null;
        setSubscriptionCoupon({
          couponId: response.data.coupon.id,
          name: response.data.coupon.name,
          amountOff: amount_off,
          percentOff: percent_off,
        });
      } else {
        triggerToast("Invalid coupon code", "error", { autoClose: 3000 });
      }
    } catch (err) {
      console.log(err);
      triggerToast("Invalid coupon code", "error", { autoClose: 3000 });
    }

    if (response.status == "fail") {
      console.log(response.message);
      triggerToast("Invalid coupon code", "error", { autoClose: 3000 });

      return;
    }
  };

  const removeCoupon = () => {
    setSubscriptionCoupon(null);
    setDiscountAmount(null);
    setValidCoupon(false);
    console.log(discountAmount);
    console.log(subscriptionCoupon);
  };

  return (
    <div className=" w-[420px]  grid gap-[24px]">
      <div className="text-[24px] text-blue-90 font-semibold text-left">
        Pay using card
      </div>
      <div className="grid gap-[12px]">
        <div className="text-[18px] text-[#3B476B] font-semibold text-left h-[23px] flex gap-[6px] ">
          Billing Cycle
        </div>
        <div className="gap-[12px] flex w-full  justify-between text-left">
          <div
            className={`px-4 py-3  border-2 rounded-xl gap-[5px] w-full cursor-pointer ${
              selectedBillingCycle === 0
                ? "bg-[#0671E0]/10 border-[#0671E0]"
                : ""
            }`}
            onClick={() => {
              setSelectedBillingCycle(0);
            }}
          >
            <div className="font-semibold text-[16px] text-[#0671E0]">
              Monthly
            </div>
            <div className="text-[#7C859C] text-[14px]">
              {selectedBillingCycle === 1
                ? `$${(((AddOnsPrice + basePackagePrice) * 1.15) / 12).toFixed(
                    2
                  )} per month`
                : `$${(AddOnsPrice + basePackagePrice).toFixed(2)} per month`}
            </div>
          </div>
          <div
            className={`px-4 py-3  border-2 rounded-xl gap-[5px] w-full cursor-pointer ${
              selectedBillingCycle === 1
                ? "bg-[#0671E0]/10 border-[#0671E0]"
                : ""
            }`}
            onClick={() => {
              setSelectedBillingCycle(1);
            }}
          >
            <div className="flex  gap-[6px]">
              <div className="font-semibold text-[16px] text-[#0671E0]">
                Yearly
              </div>
              <div className="px-2 py-0.5 bg-[#0671E0] text-[#FFFFFF] text-[10px] font-medium">
                Save 15%
              </div>
            </div>

            <div className="text-[#7C859C] text-[14px]">
              {selectedBillingCycle === 0
                ? `$${((AddOnsPrice + basePackagePrice) * 0.85).toFixed(
                    2
                  )} per month`
                : `$${((AddOnsPrice + basePackagePrice) / 12).toFixed(
                    2
                  )} per month`}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="text-[18px] mb-2 text-[#3B476B] font-semibold text-left h-[23px] flex gap-[6px] ">
          Card Details
        </div>
        <PaymentElement />
      </div>
      <ApplyCoupon
        coupon={coupon}
        setCoupon={setCoupon}
        valid={validCoupon}
        setValid={setValidCoupon}
        applyCoupon={applyCouponToSubscription}
        discountAmount={discountAmount}
        subscriptionCoupon={subscriptionCoupon}
        removeCoupon={removeCoupon}
      />
      <div
        className="grid gap-[8px] bg-[#0671E0]/10
  text-[14px] text-left p-[16px] border-2 rounded-[6px] "
      >
        <div className="text-blue font-[500]">Note</div>
        <div className="text-secondary-80 font-[400]">
          Your card won’t be charged until your trial period ends. Read our
          <span className="text-blue-70 font-[600] decoration-none">
            &nbsp;<a href="https://www.evallo.org/tou">Terms of Use</a>
          </span>{" "}
          and{" "}
          <span className="text-blue-70 font-[600] decoration-none">
            <a href="https://www.evallo.org/privacy-policy">Privacy Policy</a>
          </span>
          .
        </div>
      </div>
    </div>
  );
};

export default AddCardDetails;
