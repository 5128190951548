import { Phone } from "@mui/icons-material";
import styles from "./PhoneNumberInputV2.module.css";
import countryCodes from "../../utils/countryCode.json";
import countryFlags from "../../utils/country-flag.json";
import { useEffect, useState, useRef } from "react";
import DownArrowIcon from "../DownArrowIcon/DownArrowIcon";

const PhoneNumberInputV2 = ({
  useDefaultValue,
  value,
  setSelectedCountryCode,
  selectedCountryCode,
  onChange,
  placeholderText,
  id,
  defaultValue,
  setPhoneNumberOutside,
  isNotValid,
  setIsNotValid,
  disabled,
  containerStyleClass,
  inputStyleClass,
  dropdownItemStyleClass,
  dropdownToggleButtonStyleClass,
  countryCodeStyleClass
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [fromKeyDown, setFromKeyDown] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const phoneCodeDropdownRef = useRef(null);
  const [count, setCount] = useState(0)


  useEffect(()=>{setCount(count+1)},[])

  useEffect(() => {
    if (setSelectedCountryCode) {
      if (!selectedCountryCode) setSelectedCountryCode(countryCodes[230]);
    }
  }, [selectedCountryCode]);

  useEffect(() => {
    if(count === 0) return;
    setIsNotValid(
      (phoneNumber.length !== 10 && phoneNumber !== "") ||
      /[^0-9]/.test(phoneNumber)
    );
    if (setPhoneNumberOutside) {
      setPhoneNumberOutside(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (!phoneCodeDropdownRef.current) return;
    const handleClickOutside = (event) => {
      if (
        phoneCodeDropdownRef.current &&
        !phoneCodeDropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [phoneCodeDropdownRef.current]);

  const augumentPhoneNumber = (e) => {
    if (
      (e.target.value.length === 3 || e.target.value.length === 7) &&
      !fromKeyDown
    ) {
      e.target.value += "-";
    }
    setPhoneNumber(e.target.value.split("-").join(""));
  };

  const deleteChar = (e) => {
    if (e.key === "Backspace") {
      setFromKeyDown(true);
      if (e.target.value.length === 4 || e.target.value.length === 8) {
        e.target.value = e.target.value.slice(0, -1);
      }
    } else {
      setFromKeyDown(false);
    }
  };

  const handleDropdownItemClick = (e) => {
    const code = e.target.id.split("_").pop();
    setSelectedCountryCode(countryCodes.find((val) => val.code === code));
  };

  return (
    <div
      className={`${styles.container} ${containerStyleClass} ${isFocused ? styles.focused : ""} ${isNotValid ? styles.invalidNumber : ""
        }`}
    >
      <button
        id="ignore"
        type="button"
        className={`${styles.dropdown} ${dropdownToggleButtonStyleClass}`}
        onClick={() => setShowDropdown(!showDropdown)}
        onKeyDown={(e) => {
          if (e.key === "Backspace") {
            setFilter("");
          }
          if (/^[a-zA-Z0-9]$/.test(e.key)) {
            setFilter(filter + e.key);
          }
        }}
      >
        <div>
          <img
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedCountryCode
                ? selectedCountryCode?.code
                : countryCodes[230].code
              }.svg`}
            className={styles.flagImg}
          />
        </div>
        <div className={`${"text-primary-blue"} ${countryCodeStyleClass}`}>
          {selectedCountryCode
            ? selectedCountryCode?.dial_code
            : countryCodes[230].dial_code}
        </div>
        <DownArrowIcon className="text-primary-blue" />
        {showDropdown && (
          <div className={styles.dropdownList} ref={phoneCodeDropdownRef}>
            {countryCodes
              .filter(
                (val) =>
                  val.name.toLowerCase().includes(filter?.toLowerCase()) ||
                  val.code.toLowerCase().includes(filter?.toLowerCase()) ||
                  val.dial_code.includes(filter)
              )
              .map((val) => (
                <div
                  key={val.code}
                  className={`${styles.item} ${dropdownItemStyleClass}`}
                  id={`country_code_${val.code}`}
                  onClick={(e) => handleDropdownItemClick(e)}
                >
                  <div className="pointer-events-none">
                    <img
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${val.code}.svg`}
                      className={styles.flagImg}
                    />
                  </div>
                  <div className="w-9/12 pointer-events-none">{val.name}</div>
                  <div className="pointer-events-none">
                    {"(" + val.dial_code + ")"}
                  </div>
                </div>
              ))}
          </div>
        )}
      </button>
      <input
        type="tel"
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
          setPhoneNumber(e.target.value);
          augumentPhoneNumber(e);
        }}
        placeholder={placeholderText}
        id={id}
        disabled={disabled}
        className={`${styles.phoneNumberInput} ${inputStyleClass}`}
        onKeyDown={(e) => deleteChar(e)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        defaultValue={defaultValue}
      />
      
    </div>
  );
};

export default PhoneNumberInputV2;
