import React, { useEffect, useRef, useState } from "react";
import "./TimeZonePopover.css";
import { Search } from "@mui/icons-material";
import moment from "moment-timezone";

const TimeZonePopover = ({
  isVisible,
  onClose,
  onSelectTimeZone,
  selectedTimeZone,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const timeZones = moment.tz.names();

  const filteredTimeZones = timeZones.filter((zone) =>
    zone.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleTimeZoneClick = (zone) => {
    onSelectTimeZone(zone);
  };
  console.log("selectedTimeZone", selectedTimeZone);

  return isVisible ? (
    <div className="popover" onClick={(e) => e.stopPropagation()}>
      <div className="search-box-container">
        <img style={{ marginLeft: 5 }} src="/images/search.svg" alt="search" />
        <input
          type="text"
          className="search-box"
          placeholder="Search by place"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <ul className="time-zone-list">
        {filteredTimeZones.map((zone, index) => (
          <li
            key={index}
            className={`time-zone-item ${
              zone === selectedTimeZone ? "selected" : ""
            }`}
            onClick={() => handleTimeZoneClick(zone)}
          >
            {zone}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default TimeZonePopover;
