import styles from './OAuthButton.module.css'

const OAuthButton = (props) => {

    return (
        <button {...props} className={`${styles.buttonStyle} ${props.className}`} >{props.children}</button>
    )

}


export default OAuthButton