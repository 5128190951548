import React, { useState, useEffect } from "react";
import Free from "../../../ManageSubscription/assets/Free.svg";
import Starter from "../../../ManageSubscription/assets/Starter.svg";
import Professional from "../../../ManageSubscription/assets/Professional.svg";
import Premium from "../../../ManageSubscription/assets/Premium.svg";
import UndoCancelSubscriptionPopUp from "./UndoCancelSubscriptionPopUp";
import UndoSubscriptionChangesPopUp from "./UndoSubscriptionChangesPopUp";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import { useLazyGetPricesV2Query } from "../../../../app/services/subscription";
import SubscriptionModificationDetails from "./SubscriptionModificationDetails";

const formatDate = (mongoDBDate, formatType = 1) => {
  const dateObj = new Date(mongoDBDate);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthIndex = dateObj.getMonth();
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const dayIndex = dateObj.getDay();

  if (formatType === 1) {
    return `${monthNames[monthIndex]} ${day}, ${year}`;
  } else if (formatType === 2) {
    return `${dayNames[dayIndex]}, ${monthNames[monthIndex]} ${day}, ${year}`;
  } else {
    return "Invalid format type";
  }
};
const getImage = (name) => {
  switch (name) {
    case "Free":
      return Free;
    case "Starter":
      return Starter;
    case "Professional":
      return Professional;
    case "Premium":
      return Premium;
    default:
      return null; // Handle the case where packageName doesn't match any image
  }
};

const YourPlanSubscription = ({
  packageName,
  subscriptionV2,
  subscriptionData,
  subscriptionCost,
  planType,
  stripeSubscription,
}) => {
  const [totalCost, setTotalCost] = useState();
  const [productPrices, setProductPrices] = useState(null);
  const [getPricesQuery] = useLazyGetPricesV2Query();
  const [openUndoCancelSubscription, setOpenUndoCancelSubscription] =
    useState(false);
  const [openUndoSubscriptionChanges, setOpenUndoSubscriptionChanges] =
    useState(false);

  //Fetch product prices
  useEffect(() => {
    (async () => {
      try {
        const res = await getPricesQuery();
        const _productPrices = res.data.data.prices;
        setProductPrices(_productPrices);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (!subscriptionCost) return;
    let tutorCost =
      subscriptionCost?.tutorLicenses.price *
      subscriptionCost?.tutorLicenses.quantity;
    setTotalCost(
      tutorCost +
        subscriptionCost?.basePackage +
        subscriptionCost?.products.assignments +
        subscriptionCost?.products.customContent +
        subscriptionCost?.products.calendar
    );
  }, [subscriptionCost?.products]);

  return (
    <div className="grid gap-[16px]">
      <div className="basePackageDetails_changePlan flex justify-between items-center h-fit gap-[24px] ">
        <div className="flex gap-[12px] items-center">
          <div className="h-[48px] w-[48px]  gap-[12px] flex justify-center items-center rounded-full border border-[#CACEDA]">
            <img
              className="h-[35px] w-[35px] rounded-full"
              src={getImage(packageName)}
            />
          </div>
          <div>
            <div className="flex gap-[calc(100vw*12/1440)] text-[20px] font-semibold">
              <div className="text-secondary">
                {packageName?.includes("Free") ? "Explorer" : packageName}
                <span className="text-secondary-80 font-normal">
                  {subscriptionV2?.subscription?.basePackage?.name.includes(
                    "Free"
                  )
                    ? ""
                    : `(${subscriptionV2?.subscription.__t !== "FreeSubscription" ? planType === 0 ? "Monthly" : "Annual" : ""})`}
                  &nbsp; |
                </span>
                &nbsp;{" "}
                <span className="text-secondary">
                  ${planType === 0 ? totalCost : (totalCost / 12).toFixed(1)}
                  /mo*
                </span>
              </div>{" "}
              {!subscriptionV2?.subscription?.basePackage?.name.includes(
                "Free"
              ) && subscriptionData?.isTrialling === true ? (
                <div className="hidden bg-success/10 text-success-text rounded-[6px] px-[calc(100vw*12/1440)] py-[calc(100vw*6/1440)] text-[calc(100vw*16/1440)]">
                  FREE TRIAL
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="text-[#7C859C] text-[16px] font-normal">
              {formatDate(subscriptionData?.currentPeriod?.start, 1)} - Present
            </div>
          </div>
        </div>
      </div>
      <div className="text-secondary-80 text-[16px] font-[400]">
        {subscriptionV2?.subscription?.basePackage?.name.includes("Free") ? (
          "Your subscription plan has lifetime access"
        ) : (
          <div className="flex gap-[24px]">
            <span>

              { stripeSubscription?.cancel_at_period_end === true
                ? "Your current subscription will be cancelled wef" 
                : subscriptionData?.isTrialling === true
                ? "Your Free trail ends on"
                : stripeSubscription?.schedule?.id
                ? "Your subscription plan will be modified wef"
                : "Your plan auto-renews on"}

              <span className="text-secondary font-[500]">
                &nbsp;
                {formatDate(subscriptionData?.currentPeriod?.end, 1)}
              </span>
            </span>

            {stripeSubscription?.schedule?.id && (
              <Tooltip
                anchorComponent={
                  <span
                    className="text-[16px] cursor-pointer font-[400] text-secondary-80 underline "
                    onClick={() => {
                      //setOpenUndoCancelSubscription(true);
                    }}
                  >
                    Details
                  </span>
                }
                tooltipContent={
                  <SubscriptionModificationDetails
                    phases={stripeSubscription?.schedule.phases}
                    stripeSubscription={stripeSubscription}
                    productPrices={productPrices}
                    planType={planType}
                  />
                }
                tooltipStyleClass="bg-white rounded p-[12px] leading-[18px] -ml-[180px] font-[400] text-[12px] w-[320px] mt-[25px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"
                tooltipTriangleStyling="hidden"
              />
            )}

            {stripeSubscription?.cancel_at_period_end === true ? (
              <span
                className="text-[16px] font-[400] text-error-40 underline cursor-pointer"
                onClick={() => setOpenUndoCancelSubscription(true)}
              >
                Undo
              </span>
            ) : (
              stripeSubscription?.schedule?.id && (
                <span
                  className="text-[16px] font-[400] text-error-40 underline cursor-pointer"
                  onClick={() => setOpenUndoSubscriptionChanges(true)}
                >
                  Undo
                </span>
              )
            )}
          </div>
        )}
      </div>
      {openUndoCancelSubscription && (
        <UndoCancelSubscriptionPopUp
          open={openUndoCancelSubscription}
          setOpen={(val) => {
            setOpenUndoCancelSubscription(val);
          }}
        />
      )}
      {openUndoSubscriptionChanges && (
        <UndoSubscriptionChangesPopUp
          open={openUndoSubscriptionChanges}
          setOpen={(val) => {
            setOpenUndoSubscriptionChanges(val);
          }}
        />
      )}
    </div>
  );
};

export default YourPlanSubscription;
