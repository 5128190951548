import React, { useState } from 'react'

const PriceListCard = ({ monthlyPlan, annualPlan, monthlyPayg, serviceName,idx }) => {

    console.log(">>> PL", monthlyPlan)

    const [planType, setPlanType] = useState("monthly")

    return (
        <div className='flex'>
            <div className='w-[calc(100vw*(322/1440))] 2.5xl:w-[322px]'>
                <div className='w-full flex flex-col gap-[calc(100vw*(24/1440))] 2.5xl:gap-[24px]'>
                    <div className='flex justify-between'>
                        <div className='flex items-center gap-[calc(100vw*(6/1440))] 2.5xl:gap-[6px]'>
                            <input onClick={() => setPlanType("monthly")} checked={planType === "monthly" ? true : false} className='h-[18px] w-[18px] accent-[#0671E0]' type="radio" id='monthly' />
                            <label className='font-medium text-[calc(100vw*(18/1440))] leading-[calc(100vw*(22.5/1440))] 2.5xl:text-[18px] 2.5xl:leading-[22.5px] text-[#26435F]' htmlFor="monthly">Monthly</label>
                        </div>
                        <div className='flex items-center gap-[calc(100vw*(6/1440))] 2.5xl:gap-[6px]'>
                            <input onClick={() => setPlanType("annual")} checked={planType === "annual" ? true : false} className='h-[18px] w-[18px] accent-[#0671E0]' type="radio" id=' annual' />
                            <label className='font-medium text-[calc(100vw*(18/1440))] leading-[calc(100vw*(22.5/1440))] 2.5xl:text-[18px] 2.5xl:leading-[22.5px] text-[#26435F]' htmlFor="annual">Annual</label>
                            <span className='font-medium text-[calc(100vw*(14/1440))] 2.5xl:text-[14px] leading-[calc(100vw*(14/1440))] 2.5xl:lading-[14px] bg-[#0671E0] text-white px-[calc(100vw*(8/1440))] 2.5xl:px-[8px] py-[calc(100vw*(6/1440))] 2.5xl:py-[6px] rounded-[2px]'>SAVE 15%</span>
                        </div>
                    </div>
                    <div className='flex items-center justify-between'>
                        <div className='font-semibold text-[calc(100vw*(16/1440))] 2.5xl:text-[16px] leading-[calc(100vw*(20/1440))] 2.5xl:leading-[20px] text-[#26435F]'>
                            {serviceName === "AssignmentsV2" ? "Assignment Credits" : serviceName === "CalendarV2" ? "No. of Sessions" : serviceName === "CustomContentV2" ? "Test Uploads" : ""}
                            <span className='font-normal text-[calc(100vw*(16/1440))] 2.5xl:text-[16px]'> {serviceName === "CustomContentV2" ? "(usage limits)" : planType === "monthly" ? "(per month)" : "(per year)"} </span>
                        </div>
                        <div className='font-semibold text-[calc(100vw*(16/1440))] 2.5xl:text-[16px] leading-[calc(100vw*(20/1440))] 2.5xl:leading-[20px] text-[#26435F]'>Amount</div>
                    </div>
                    <div>
                        <div className='flex flex-col gap-[calc(100vw*(8/1440))] 2.5xl:gap-[8px]'>
                            {
                                planType == "annual" ? annualPlan?.map((el, i) => {
                                    return <div key={i} className={`flex justify-between items-center pb-[calc(100vw*(8/1440))] 2.5xl:pb-[8px] ${i < 3 ? "border-b border-dashed" : ""} font-normal text-[calc(100vw*(16/1440))] 2.5xl:text-[16px] leading-[calc(100vw*(20/1440))] 2.5xl:leading-[20px] text[#515C7B]`}>
                                        <div>{el.limit == -1 ? "Unlimited" : el.limit}</div>
                                        <div> <strike className='text-[#7C859C]'>${el.unitPrice}</strike> ${Math.round(el.unitPrice * .85).toFixed(0)}</div>

                                    </div>
                                })
                                    :
                                    monthlyPlan?.map((el, i) => {
                                        return <div key={i} className={`flex justify-between items-center pb-[calc(100vw*(8/1440))] 2.5xl:pb-[8px] ${i < 3 ? "border-b border-dashed" : ""} font-normal text-[calc(100vw*(16/1440))] 2.5xl:text-[16px] leading-[calc(100vw*(20/1440))] 2.5xl:leading-[20px] text[#515C7B]`}>
                                            <div>{el.limit == -1 ? "Unlimited" : el.limit}</div>
                                            <div>${el.unitPrice}</div>
                                        </div>
                                    })

                            }

                        </div>
                    </div>
                    <div className={`w-full border-b border-[#CACEDA]`}></div>
                    <div>
                        <div className={` ${planType === "annual" ? "text-[#26435F]" : "text-[#26435F]"} text-left font-semibold text-[calc(100vw*(16/1440))] 2.5xl:text-[16px] leading-[calc(100vw*(20/1440))] 2.5xl:leading-[20px]`}>
                            {serviceName === "CustomContentV2" ? "Usage Limit" : "PAYG"}
                            {serviceName === "CustomContentV2" ? "" : <span className='font-normal'> (Optional)</span>}
                           
                        </div>
                        <div className='mt-[12px]'>
                            <p className={` ${planType === "annual" ? "text-[#515C7B]" : "text-[#515C7B]"} font-normal text-left text-[calc(100vw*(16/1440))] 2.5xl:text-[16px] leading-[calc(100vw*(20/1440))] 2.5xl:leading-[20px]]`} >
                               {serviceName === "CustomContentV2" ? "You can upload custom tests up to the limits mentioned above." :  `Additional charge of ${monthlyPayg}¢/session once you exhaust your usage limits.`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PriceListCard