import styles from './SmallDevicesBlockerScreen.module.css'
import smallScreensBlockerIcon from '../../assets/BlockerImages/SmallScreenBlocker.svg'
import evalloLogoBlue from "../../assets/icons/navbar-icons/version2/Evallo_logo_V2_blue.svg";
import Navbar from '../Navbar/Navbar';
import { useEffect, useState } from 'react';
import MobileNavDrawer from '../MobileNavDrawer/MobileNavDrawer';
import AccountDropdownCard from '../AccountDropdownCard/AccountDropdownCard';


const SmallDevicesBlockerScreen = ({isLoggedIn}) =>{

    const [navigationData, setNavigationData] = useState([])
    const [mobileNavTrigger, setMobileNavTrigger] = useState(false)
    const [showMobileProfile, setShowMobileProfile] = useState(false)

    useEffect(()=>{
        return ()=>{
            setMobileNavTrigger(false)
        }
    },[])


    return(
        <div className={styles.container}>
            <div className={styles.row1} style={{backgroundColor : isLoggedIn ? "#0671E0" : "white", padding : isLoggedIn ? "0px" : ""}}>
                {
                    !isLoggedIn ? <img src={evalloLogoBlue} style={{height : "32px",width : "37px"}} /> 
                    : 
                    <Navbar fromStage={true} setLoading={()=>{}} setNavData={setNavigationData} {...{mobileNavTrigger, setMobileNavTrigger}} {...{showMobileProfile, setShowMobileProfile}} ></Navbar>
                }
                
            </div>
            <div className={styles.row2}>
                <MobileNavDrawer trigger={mobileNavTrigger} setTrigger={setMobileNavTrigger}/>
				<AccountDropdownCard outsideclassName={"md:hidden"} showMobileProfile={showMobileProfile}/>
                <div className={styles.contentContainer}>
                    
                    <img src={smallScreensBlockerIcon} />
                    <h3>This Works Better On Desktop!</h3>
                    <div className={styles.descriptionContainer}>
                        <div>It looks like you’re accessing Evallo from a phone or tablet device. For the best experience, please continue from a desktop device.</div>
                        <div>Visit <a href='https://evallo.org' target='_blank'>www.evallo.org</a> to learn more.</div>
                    </div>
                </div>
            </div>
        </div>
    )   
}

export default SmallDevicesBlockerScreen

