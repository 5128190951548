import React from "react";
import frozenEventStatus from "../../utils/eventStatus";
import frozenEventTypes from "../../utils/eventTypes";
import SessionRatingEventCard from "../SessionRating/SessionRatingEventCard";
import {
  handleDeclineClick,
  handleDeleteRequestClick,
  handleDetailsClick,
  handleEditActionCTAClick,
  handleExternalUsersRequestClick,
} from "./clickHandlers";

const buttonObj = {
  [frozenEventTypes.SESSION.eventType]: {
    admin: {
      SCH: ["Edit", "Reschedule", "Cancel"],
      sch: ["Edit", "Reschedule", "Cancel"],
      scheduled: ["Edit", "Reschedule", "Cancel"],
      pending: ["Details", "Accept", "Decline"],
      tentative: ["Details"],
      Tentative: ["Details"],
      TENTATIVE: ["Details"],
      canceled: ["Delete"],
      cancel: ["Delete"],
      cancelled: ["Delete"],
	  completed : ["Details"],
	  "no-show" : ["Details"],
	  "noshow" : ["Details"],
	  "NOSHOW" : ["Details"],
	  "NOSHOW" : ["Details"],
    },
    tutor: {
      sch: ["Edit", "Reschedule", "Cancel"],
      SCH: ["Edit", "Reschedule", "Cancel"],
      scheduled: ["Edit", "Reschedule", "Cancel"],
      pending: ["Details", "Accept", "Decline"],
      tentative: ["Details"],
      Tentative: ["Details"],
      TENTATIVE: ["Details"],
      canceled: ["Delete"],
      cancel: ["Delete"],
      cancelled: ["Delete"],
	  completed : ["Details"],	
	  "no-show" : ["Details"],
	  "noshow" : ["Details"],
	  "NOSHOW" : ["Details"],
	  "NOSHOW" : ["Details"],
    },
    student: {
      sch: ["Details", "Reschedule", "Request Cancellation"],
      SCH: ["Details", "Reschedule", "Request Cancellation"],
      Scheduled: ["Details", "Reschedule", "Request Cancellation"],
      scheduled: ["Details", "Reschedule", "Request Cancellation"],
      pending: ["Delete Request"],
      completed: ["Rating"],
      COMP: ["Rating"],
      comp: ["Rating"],
      canceled: ["Details"],
      cancelled: ["Details"],
      tentative: ["Details"],
      Tentative: ["Details"],
      TENTATIVE: ["Details"],
      "No Show": ["No Show"],
      "no-show": ["No Show"],
	  "no-show" : ["Details"],
	  "noshow" : ["Details"],
	  "NOSHOW" : ["Details"],
	  "NOSHOW" : ["Details"],
    },
    parent: {
      sch: ["Details", "Reschedule", "Request Cancellation"],
      SCH: ["Details", "Reschedule", "Request Cancellation"],
      Scheduled: ["Details", "Reschedule", "Request Cancellation"],
      scheduled: ["Details", "Reschedule", "Request Cancellation"],
      pending: ["Delete Request"],
      Completed: ["Details"],
      completed: ["Details"],
      tentative: ["Details"],
      Tentative: ["Details"],
      TENTATIVE: ["Details"],
      COMP: ["Details"],
      comp: ["Details"],
      canceled: ["Details"],
      "No Show": ["No Show"],
      "no-show": ["No Show"],
	  "no-show" : ["Details"],
	  "noshow" : ["Details"],
	  "NOSHOW" : ["Details"],
	  "NOSHOW" : ["Details"],
    },
  },
  [frozenEventTypes.CONSULTATION.eventType]: {
    admin: {
      sch: ["Edit", "Reschedule", "Cancel"],
      scheduled: ["Edit", "Reschedule", "Cancel"],
      pending: ["Details", "Accept", "Decline"],
      Canceled: ["Delete"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
    tutor: {
      sch: ["Edit", "Reschedule", "Cancel"],
      scheduled: ["Edit", "Reschedule", "Cancel"],
      pending: ["Details", "Accept", "Decline"],
      Canceled: ["Delete"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
    student: {
      sch: ["Details", "Reschedule", "Request Cancellation"],
      scheduled: ["Details", "Reschedule", "Request Cancellation"],
      pending: ["Delete Request"],
      completed: ["Details"],
      canceled: ["Details"],
      cancelled: ["Details"],
      cancel: ["Details"],
      "No Show": ["No Show"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
    parent: {
      sch: ["Details", "Reschedule", "Request Cancellation"],
      scheduled: ["Details", "Reschedule", "Request Cancellation"],
      pending: ["Delete Request"],
      completed: ["Details"],
      canceled: ["Details"],
      cancelled: ["Details"],
      cancel: ["Details"],
      "No Show": ["No Show"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
  },
  [frozenEventTypes.INTMEETING.eventType.toLowerCase()]: {
    admin: {
      sch: ["Edit", "Reschedule", "Cancel"],
      scheduled: ["Edit", "Reschedule", "Cancel"],
      pending: ["Details", "Accept", "Decline"],
      Canceled: ["Delete"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
    tutor: {
      sch: ["Edit", "Reschedule", "Cancel"],
      scheduled: ["Edit", "Reschedule", "Cancel"],
      pending: ["Details", "Accept", "Decline"],
      Canceled: ["Delete"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
    student: {
      sch: ["Details", "Reschedule", "Request Cancellation"],
      scheduled: ["Details", "Reschedule", "Request Cancellation"],
      pending: ["Delete Request"],
      completed: ["Details"],
      canceled: ["Details"],
      cancelled: ["Details"],
	  cancel : ["Details"],
      "No Show": ["No Show"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
    parent: {
      sch: ["Details", "Reschedule", "Request Cancellation"],
      scheduled: ["Details", "Reschedule", "Request Cancellation"],
      pending: ["Delete Request"],
      completed: ["Details"],
      canceled: ["Details"],
      cancelled: ["Details"],
	  cancel : ["Details"],
      "No Show": ["No Show"],
      [frozenEventStatus.COMPLETED.status]: ["Details"],
    },
  },
  [frozenEventTypes.EXTMEETING.eventType.toLowerCase()]: {
    admin: {
      Scheduled: ["Edit", "Reschedule", "Cancel"],
      "sch": ["Edit", "Reschedule", "Cancel"],
      "scheduled": ["Edit", "Reschedule", "Cancel"],
      "schedule": ["Edit", "Reschedule", "Cancel"],
      Pending: ["Details", "Accept", "Decline"],
      pending: ["Details", "Accept", "Decline"],
      Completed: ["Details"],
      Canceled: ["Delete"],
    },
    tutor: {
      Scheduled: ["Edit", "Reschedule", "Cancel"],
      "sch": ["Edit", "Reschedule", "Cancel"],
      "scheduled": ["Edit", "Reschedule", "Cancel"],
      Pending: ["Details", "Accept", "Decline"],
      pending: ["Details", "Accept", "Decline"],
      Completed: ["Details"],
      Canceled: ["Delete"],
    },
    student: {
      Scheduled: ["Details", "Reschedule", "Request Cancellation"],
      scheduled: ["Details", "Reschedule", "Request Cancellation"],
      pending: ["Delete Request"],
      canceled: ["Delete"],
      cancelled: ["Delete"],
      Completed: ["Details"],
    },
    scheduler: {
      admin: {
        Scheduled: ["Edit", "Reschedule", "Cancel"],
        Pending: ["Details", "Accept", "Decline"],
        Completed: ["Details"],
        Canceled: ["Delete"],
      },
      tutor: {
        Scheduled: ["Edit", "Reschedule", "Cancel"],
        Pending: ["Details", "Accept", "Decline"],
        Completed: ["Details"],
        Canceled: ["Delete"],
      },
    },
    attendee: {
      Scheduled: ["Details", "Reschedule", "Request Cancellation"],
      Pending: ["Delete Request"],
      Canceled: ["Delete"],
      Completed: ["Details"],
    },
  },
  [frozenEventTypes.INTMEETING.eventType.toLowerCase()]: {
    scheduler: {
	  scheduled : ["Details", "Reschedule", "Cancel"],
	  completed : ["Details"],
    },
    attendee: {
	  pending: ["Details", "Accept", "Decline"],
      admin: ["Details", "Accept", "Decline"],
      tutor: ["Details", "Accept", "Decline"],
	  scheduled : ["Details"],
      completed: ["Details"],
      Canceled: ["Delete"],
    },
  },
  [frozenEventTypes.OFFCWORK.eventType.toLowerCase()]: {
    scheduler: {
	  scheduled : ["Details", "Reschedule", "Cancel"],
	  completed : ["Details"],
		
	},
    attendee: {
	  pending: ["Details", "Accept", "Decline"],
      admin: ["Details", "Accept", "Decline"],
      tutor: ["Details", "Accept", "Decline"],
	  scheduled : ["Details"],
      completed: ["Details"],
      Canceled: ["Delete"],
    },
  },
  [frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]: {
    scheduler: {
	  scheduled : ["Details", "Reschedule", "Cancel"],
	  completed : ["Details"],
	},
    attendee: {
	  pending: ["Details", "Accept", "Decline"],
      admin: ["Details", "Accept", "Decline"],
      tutor: ["Details", "Accept", "Decline"],
	  scheduled : ["Details"],
      completed: ["Details"],
      Canceled: ["Delete"],
    },
  },

  // "EventType"
};

function EventButton({
  buttonName,
  extendedProps,
  parentProps,
  requestPopupInfo,
  setRequestPopupInfo,
  requestInfoDetails,
  setRequestInfoDetails,
}) {
  const editActionCTAClick = ["parent", "student"].includes(
    parentProps?.user?.role
  )
    ? handleExternalUsersRequestClick
    : handleEditActionCTAClick;

  console.log(buttonName);

  switch (buttonName) {
    case "Edit":
    case "Details":
      return (
        <button
          className={`text-blue-70 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() => handleDetailsClick(extendedProps, parentProps)}
        >
          {" "}
          {buttonName}{" "}
        </button>
      );

    case "Reschedule":
      return (
        <button
          className={`text-blue-70 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() =>
            editActionCTAClick(
              extendedProps,
              parentProps,
              "reschedule",
              setRequestInfoDetails
            )
          }
        >
          {" "}
          {buttonName}{" "}
        </button>
      );

    case "Accept":
      return (
        <button
          className={`text-blue-70 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() => editActionCTAClick(extendedProps, parentProps)}
        >
          {" "}
          {buttonName}{" "}
        </button>
      );
    case "Cancel":
      return (
        <button
          className={`text-error-40 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() =>
            editActionCTAClick(
              extendedProps,
              parentProps,
              "cancel",
              setRequestInfoDetails
            )
          }
        >
          {" "}
          {buttonName}{" "}
        </button>
      );

    case "Decline":
      return (
        <button
          className={`text-error-40 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() =>
            handleDeclineClick(
              extendedProps.eventRequestId,
              setRequestPopupInfo
            )
          }
        >
          {" "}
          {buttonName}{" "}
        </button>
      );
    case "Delete":
      return (
        <button
          className={`text-error-40 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() => handleEditActionCTAClick(extendedProps, parentProps)}
        >
          {" "}
          {buttonName}{" "}
        </button>
      );

    case "Request Cancellation":
      return (
        <button
          className={`text-error-40 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() =>
            editActionCTAClick(
              extendedProps,
              parentProps,
              "cancel",
              setRequestInfoDetails
            )
          }
        >
          {" "}
          {buttonName}{" "}
        </button>
      );

    case "Delete Request":
      return (
        <button
          className={`text-error-40 font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px]`}
          onClick={() => handleDeleteRequestClick(extendedProps, parentProps)}
        >
          {" "}
          {buttonName}{" "}
        </button>
      );

    case "Rating":
      return <SessionRatingEventCard {...extendedProps} />
  }
}

const EventCardBottom = ({
  status,
  persona,
  eventType,
  userType,
  extendedProps,
  parentProps,
  requestPopupInfo,
  setRequestPopupInfo,
  requestInfoDetails,
  setRequestInfoDetails,
}) => {
  
  console.log(persona,eventType, userType, status)
 
  return (
    <div className="flex items-center gap-3">
      {eventType === frozenEventTypes.SESSION.eventType ||
        eventType === frozenEventTypes.CONSULTATION.eventType  || eventType.toLowerCase() === "externalappointment".toLowerCase()? (
        <>
          {buttonObj[eventType.toLowerCase()][persona][status]?.map((el, i) => {
            return (
              <EventButton
                buttonName={el}
                extendedProps={extendedProps}
                parentProps={parentProps}
                {...{
                  requestPopupInfo,
                  setRequestPopupInfo,
                  requestInfoDetails,
                  setRequestInfoDetails,
                }}
              />
            );
          })}
        </>
      ) : eventType.toLowerCase() === "internalMeeting".toLowerCase() ? (
        <>
          {buttonObj[eventType.toLowerCase()][userType][status]?.map((el, i) => {
            return <EventButton 
					buttonName={el}
					extendedProps={extendedProps}
					parentProps={parentProps}
					{...{
						requestPopupInfo,
						setRequestPopupInfo,
						requestInfoDetails,
						setRequestInfoDetails,
					}}
					/>;
          })}
        </>
      ) : (
        <>
          {buttonObj[eventType.toLowerCase()][userType][status]?.map((el, i) => {
            return <EventButton
              buttonName={el}
              extendedProps={extendedProps}
              parentProps={parentProps}
              {...{
                requestPopupInfo,
                setRequestPopupInfo,
                requestInfoDetails,
                setRequestInfoDetails,
              }}
            />;
          })}
        </>
      )}
    </div>
  );
};

export default EventCardBottom;
