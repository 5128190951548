import React, { useEffect, useState } from 'react'
import styles from "./EventStatusTag.module.css"
import CrossIcon from "../../assets/calendarV2/CrossIcon.js"
import pendingIcon from "../../assets/calendarV2/PendingIcon.svg"
import scheduledIcon from "../../assets/calendarV2/ScheduledClock.svg"
import completedIcon from "../../assets/calendarV2/GreenCheck.svg"
import noShowIcon from "../../assets/calendarV2/NoShowIcon.svg"
import rschIcon from "../../assets/calendarV2/RescheduleIcon.svg"
import frozenEventStatus from '../../utils/eventStatus'


const iconType = {
	"cancel" : <CrossIcon />,
    [frozenEventStatus.CANCEL.status.toLowerCase()]: <CrossIcon />,
	[frozenEventStatus.PENDING.status.toLowerCase()]: pendingIcon,
	[frozenEventStatus.TENTATIVE.status.toLowerCase()]: pendingIcon,
	[frozenEventStatus.SCH.status.toLowerCase()]: scheduledIcon,
	"sch": scheduledIcon,
	[frozenEventStatus.COMPLETED.status.toLowerCase()]: completedIcon,
	[frozenEventStatus.NOSHOW.status.toLowerCase()]: noShowIcon,
	[frozenEventStatus.RESCH.status.toLowerCase()] : rschIcon,
	"reschedule" : rschIcon,
	"cancel" : <CrossIcon />
}

const getStyles = (status, showDisplayValue) => {

	if(frozenEventStatus.CANCEL.status.toLowerCase().includes(status)) {
		switch(showDisplayValue) {
			case "secondary" : return `text-[rgba(217,45,32,0.7)] bg-[rgba(249,112,102,0.05)] ${styles.cancelTag}`;
			default : return "text-secondary-60 bg-secondary-5" ;
		}
	} else if(frozenEventStatus.PENDING.status.toLowerCase().includes(status)){
		return "text-[#BB800D] bg-[#FFF4DF]" 
	} else if(frozenEventStatus.COMPLETED.status.toLowerCase().includes(status)){
		return "text-[#2BA773] bg-[#F0FFF9]" 
	} else if(frozenEventStatus.SCH.status.toLowerCase().includes(status)){
		return "text-[#1D6B98] bg-[#DCF2FF]" 
	} else if(frozenEventStatus.NOSHOW.status.toLowerCase().includes(status)){
		return  "text-[#B42318CC] bg-[#FEF3F2]"
	} else if(frozenEventStatus.RESCH.status.toLowerCase().includes(status)){
		return "text-[#2792CD] bg-[#2792CD0D]"
	}else if(frozenEventStatus.TENTATIVE.status.toLowerCase().includes(status)){
		return "text-[#BB800D] bg-[#FFF4DF]" 
	}

}


const EventStatusTag = ({ status, showDisplayValue }) => {

	const [statusDisplayValue, setStatusDisplayValue] = useState("")
	
	useEffect(()=>{
		console.log("CARD_STATUS", status)
		const reqdKey = Object.keys(frozenEventStatus).find((key,idx)=>frozenEventStatus[key].status?.toLowerCase().includes(status?.toLowerCase()))
		if(!showDisplayValue){
			setStatusDisplayValue(frozenEventStatus[reqdKey]?.displayValue)
		} else {
			setStatusDisplayValue(frozenEventStatus[reqdKey][`${showDisplayValue}DisplayValue`])
		}
	},[status, showDisplayValue])
	

    return (
        <div className={`px-[6px] py-1 rounded-20 flex items-center gap-1 w-fit font-normal text-[10px] leading-[15px] md:text-[12px] md:leading-[18px] ${getStyles(status?.toLowerCase(), showDisplayValue)}`}>
			{ typeof(iconType[status?.toLowerCase()]) === "string" ? <img className='h-3 w-3 xl:h-auto xl:w-auto object-contain' src={iconType[status?.toLowerCase()]} alt="" /> : iconType[status?.toLowerCase()]} 
            {statusDisplayValue}
        </div>
    )
}

export default EventStatusTag
