// ProductCard.js
import React, { useEffect } from "react";
import TickCircle from "./TickCircle.svg";
import PadlockCircle from "./PadlockCircle.svg";
import styles from "./ProductCard.module.css";
import PriceSelectDropDown from "./PriceSelectDropDown";

const ProductCard = (props) => {
  const { idx } = props;

  useEffect(() => {
    console.log(`>>>${props.name} STATE CHANGED`);
  }, [props.selectedPrice]);

  const handlePriceChange = (value) => {
    const _multiselectProducts = props.selectedProducts;
    const selectedProducts = props.selectedProducts[props.idx];
    const tempSelectedProducts = { ...selectedProducts };
    tempSelectedProducts[props.name] = {
      ...tempSelectedProducts[props.name],
      price: props.priceList.find((val) => val.id === value).unit_amount / 100,
      value,
      default: false,
    };
    _multiselectProducts[idx] = tempSelectedProducts;
    props.setSelectedProducts([..._multiselectProducts]);
  };

  return (
    <div
      className={`${props.name === "CRM" ? "pointer-events-none" : ""} ${
        props.name === "Custom Tests"
          ? !props.selectedProducts[idx]["Assignments"].selected
            ? "pointer-events-none"
            : ""
          : ""
      }`}
    >
      <div
        className={`cursor-pointer p-[calc(100vw*12/1440)] 2.5xl:p-[12px] relative h-[calc(100vw*148/1440)] w-[calc(100vw*148/1440)] 2.5xl:h-[148px] 2.5xl:w-[148px] rounded-xl border-2 flex flex-col justify-between bg-white ${
          props.selectedProducts[idx][props.name].selected ? "" : ""
        }`}
        id="productCardContainer"
        onClick={(e) => {
          if (e.target.id === "invalidIdFunctionalityRemoved") {
            const _multiselectProducts = props.selectedProducts;
            const selectedProducts = props.selectedProducts[props.idx];
            const tempSelectedProducts = { ...selectedProducts };
            tempSelectedProducts[props.name] = {
              ...tempSelectedProducts[props.name],
              selected: !tempSelectedProducts[props.name].selected,
              default: false,
            };
            if (props.name === "Assignments") {
              if (!tempSelectedProducts[props.name].selected) {
                tempSelectedProducts["Custom Tests"].selected = false;
              } else {
                if (!tempSelectedProducts["Custom Tests"].default) {
                  tempSelectedProducts["Custom Tests"].selected = true;
                }
              }
            }
            _multiselectProducts[idx] = tempSelectedProducts;
            console.log(">>>MSP", _multiselectProducts);
            props.setSelectedProducts([..._multiselectProducts]);
          }
        }}
      >
        {props.selectedProducts[idx][props.name].selected ? (
          <></>
        ) : (
          <img
            src={props.name === "CRM" ? PadlockCircle : TickCircle}
            className="h-[20px] w-[20px] absolute -top-2.5 -right-2 z-9 pointer-events-none"
          />
        )}

        <div className={``}>
          <div className="flex justify-between">
            <img
              src={props.img}
              className="w-[calc(100vw*27/1440)] h-[calc(100vw*27/1440)] 2.5xl:h-[27px] 2.5xl:w-[27px]"
            />
            <div className="text-[calc(100vw*20/1440)]  2.5xl:text-[20px] font-[500] mt-1 h-fit">
              {props.name === "CRM" ||
              props.selectedProducts[idx][props.name].price === 0
                ? "Free"
                : `$${(props.selectedPlanType === 0
                    ? props.selectedProducts[idx][props.name].price
                    : props.selectedProducts[idx][props.name].price / 12
                  ).toFixed(0)}`}
              {props.name !== "CRM" &&
                props.selectedProducts[idx][props.name].price !== 0 && (
                  <span className="text-secondary-60 text-[12px] font-[500]">
                    /mo
                  </span>
                )}
            </div>
          </div>
          <div
            className={`text-[calc(100vw*16/1440)] 2.5xl:text-[16px]  pt-[4px] text-left font-semibold pointer-events-none text-[${props.color}]`}
            style={{ color: props.color }}
          >
            {props.name}
          </div>
        </div>
        <div className="flex flex-col justify-start gap-[4px]">
          <div className="text-[calc(100vw*12/1440)] 2.5xl:text-[12px] font-[500] text-left ">
            {props.title}
          </div>
          {props.priceList ? (
            <PriceSelectDropDown
              priceList={props.priceList}
              selectedPrice={props.selectedProducts[idx][props.name].value}
              onChange={handlePriceChange}
              disabled={!props.selectedProducts[idx][props.name].selected}
              selectedPlanType={props.selectedPlanType}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
