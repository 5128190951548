import React, { useState } from "react";
import sessionIcon from "../../../assets/calendarV2/session.svg";
import internalIcon from "../../../assets/calendarV2/internal.svg";
import consultationIcon from "../../../assets/calendarV2/consultation.svg";
import appointementIcon from "../../../assets/calendarV2/appointement.svg";

const eventTypeIcons = {
  "session": {
    image: sessionIcon,
    bgColor: "#EE5555",
    textColor: "#82848B",
  },
  "consultation": {
    image: internalIcon,
    bgColor: "#82848B",
    textColor: "#82848B",
  },
  Consultation: {
    image: consultationIcon,
    bgColor: "#559B9B",
    textColor: "#559B9B",
  },
  "externalappointment": {
    image: appointementIcon,
    bgColor: "#BB7ECA",
    textColor: "#BB7ECA",
  },
};

const SearchEventBar = ({ eventData }) => {


  const [isOpenEventPopup, setIsOpenEventPopup] = useState(false);

  const openEventDetails = ()=>{
    setIsOpenEventPopup(true);
    eventData.setSelectEventPopupId(eventData?._id);
  }

  console.log(eventData)


  return (
    <main
      id={eventData?._id}
      className=" flex  w-full py-[6px] items-center justify-center  gap-x-[6px] px-[12px]  hover:border-l-[2px] hover:border-l-[solid] hover:border-l-blue-70 hover:bg-secondary-2  cursor-pointer"
      onClick={openEventDetails}
    >
      {/* Icon and logo section */}

      <section
        className=" w-[32px] h-[32px] rounded-full  flex items-center justify-center"
        style={{backgroundColor: eventTypeIcons[`${eventData?.eventType?.toLowerCase()}`].bgColor}}
      >
        <img
          src={eventData?.eventType && eventTypeIcons[`${eventData?.eventType?.toLowerCase()}`].image}
          className=" max-w-full max-h-full "
          alt={eventData?.eventType}
        />
      </section>

      {/* Event Details Section */}

      <section className=" flex flex-1 justify-between flex-col md:flex-row items-start gap-y-[4px] md:items-center ">

        <div className="  flex items-center justify-between gap-[4px] w-fit ">
          <p className=" text-secondary-80 text-[14px] leading-[21px] font-normal ">
            {eventData?.eventName}
          </p>
          <span
            className=" text-[10px] leading-[10px] font-normal bg-[#F7F8F9] py-[5px] px-[8px] rounded-[24px] flex items-center justify-center "
            style={{color: eventTypeIcons[`${eventData?.eventType?.toLowerCase()}`]?.textColor}}
          >
            {eventData?.eventType}
          </span>
        </div>

        <div className="  flex w-fit gap-[6px] items-center justify-between" >
          <p className=" text-[10px] leading-[15px] text-secondary-80 ">{eventData?.eventDate}</p>
          <span className=" text-secondary-60 ">&bull;</span>
          <p className=" text-[10px] leading-[15px] text-secondary-80 ">{eventData?.eventStartTime}-{eventData?.eventEndTime}</p>
        </div>

      </section>
    </main>
  );
};

export default SearchEventBar;
