import React, { useEffect, useState } from "react";
import PaymentMethodCard from "./PaymentMethodCard";
import ApplyCouponContainer from "./ApplyCouponContainer";
import AddNewCard from "./AddNewCard";

const PaymentMethodContainer = ({
  paymentMethods,
  defaultPaymentMethodId,
  discountDetails,
  setDiscountDetails,
  discountAmount,
  setDiscountAmount,
  _addNewCard,
  stripeCustomerId,
}) => {
  const [addNewCard, setAddNewCard] = useState(false);

  useEffect(() => {
    if (_addNewCard) {
      setAddNewCard(_addNewCard);
    }
  }, [_addNewCard]);

  return (
    <div className="flex flex-col gap-[24px] h-full ">
      {" "}
      <div className="text-[18px]  text-[#3B476B] font-[500] text-left max-h-[30px]  flex gap-[6px] ">
        Card Details
      </div>
      {!addNewCard ? (
        <>
          <div className="grid gap-[16px] max-h-[178px] overflow-y-scroll pr-[4px]">
            {paymentMethods.map((item, index) => (
              <PaymentMethodCard
                key={index}
                card={item}
                isDefault={defaultPaymentMethodId === item?.id}
                stripeCustomerId={stripeCustomerId}
              />
            ))}
            <div
              className="text-right text-blue-70 text-[14px] leading-[14px] font-[400] cursor-pointer sticky bottom-[2px]"
              onClick={() => {
                setAddNewCard(true);
              }}
            >
              <span className=" border-b-[1.5px] border-blue-70">
                Add a new card
              </span>
            </div>
          </div>
          <div>
            <ApplyCouponContainer
              subscriptionCoupon={discountDetails}
              setSubscriptionCoupon={setDiscountDetails}
              discountAmount={discountAmount}
              setDiscountAmount={setDiscountAmount}
            />
          </div>
        </>
      ) : (
        <div className="flex items-start">
          <AddNewCard setAddNewCard={setAddNewCard} />
        </div>
      )}
    </div>
  );
};

export default PaymentMethodContainer;