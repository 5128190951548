export default function FilterIcon (props) {

	const {activeIcon} = props


	if(!activeIcon){
		return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20" height="20" rx="10" fill="#F7F8F9"/>
<g clip-path="url(#clip0_38585_21934)">
<path d="M4.5 6.37489H5.868C5.97532 6.76975 6.20959 7.11833 6.53466 7.36685C6.85974 7.61537 7.25756 7.75001 7.66675 7.75001C8.07594 7.75001 8.47376 7.61537 8.79884 7.36685C9.12391 7.11833 9.35818 6.76975 9.4655 6.37489H15.5C15.6326 6.37489 15.7598 6.32221 15.8536 6.22844C15.9473 6.13467 16 6.00749 16 5.87489C16 5.74228 15.9473 5.6151 15.8536 5.52133C15.7598 5.42756 15.6326 5.37489 15.5 5.37489H9.4655C9.35818 4.98002 9.12391 4.63144 8.79884 4.38292C8.47376 4.1344 8.07594 3.99976 7.66675 3.99976C7.25756 3.99976 6.85974 4.1344 6.53466 4.38292C6.20959 4.63144 5.97532 4.98002 5.868 5.37489H4.5C4.36739 5.37489 4.24021 5.42756 4.14645 5.52133C4.05268 5.6151 4 5.74228 4 5.87489C4 6.00749 4.05268 6.13467 4.14645 6.22844C4.24021 6.32221 4.36739 6.37489 4.5 6.37489ZM7.6665 4.99989C7.83956 4.99989 8.00873 5.0512 8.15262 5.14735C8.29652 5.2435 8.40867 5.38015 8.47489 5.54004C8.54112 5.69992 8.55845 5.87586 8.52469 6.04559C8.49093 6.21532 8.40759 6.37123 8.28522 6.4936C8.16285 6.61597 8.00694 6.69931 7.8372 6.73307C7.66747 6.76683 7.49154 6.74951 7.33165 6.68328C7.17177 6.61705 7.03511 6.5049 6.93896 6.36101C6.84282 6.21712 6.7915 6.04794 6.7915 5.87489C6.79176 5.6429 6.88404 5.4205 7.04807 5.25646C7.21211 5.09242 7.43452 5.00015 7.6665 4.99989Z" fill="#515C7B"/>
<path d="M15.5 9.49988H14.132C14.0249 9.10493 13.7907 8.75622 13.4656 8.5076C13.1406 8.25898 12.7427 8.12427 12.3335 8.12427C11.9243 8.12427 11.5264 8.25898 11.2014 8.5076C10.8763 8.75622 10.6421 9.10493 10.535 9.49988H4.5C4.36739 9.49988 4.24021 9.55256 4.14645 9.64633C4.05268 9.7401 4 9.86728 4 9.99988C4 10.1325 4.05268 10.2597 4.14645 10.3534C4.24021 10.4472 4.36739 10.4999 4.5 10.4999H10.535C10.6421 10.8948 10.8763 11.2435 11.2014 11.4922C11.5264 11.7408 11.9243 11.8755 12.3335 11.8755C12.7427 11.8755 13.1406 11.7408 13.4656 11.4922C13.7907 11.2435 14.0249 10.8948 14.132 10.4999H15.5C15.6326 10.4999 15.7598 10.4472 15.8536 10.3534C15.9473 10.2597 16 10.1325 16 9.99988C16 9.86728 15.9473 9.7401 15.8536 9.64633C15.7598 9.55256 15.6326 9.49988 15.5 9.49988ZM12.3335 10.8749C12.1604 10.8749 11.9913 10.8236 11.8474 10.7274C11.7035 10.6313 11.5913 10.4946 11.5251 10.3347C11.4589 10.1748 11.4416 9.99891 11.4753 9.82918C11.5091 9.65945 11.5924 9.50354 11.7148 9.38117C11.8372 9.2588 11.9931 9.17546 12.1628 9.1417C12.3325 9.10794 12.5085 9.12526 12.6683 9.19149C12.8282 9.25772 12.9649 9.36987 13.061 9.51376C13.1572 9.65765 13.2085 9.82683 13.2085 9.99988C13.2082 10.2319 13.116 10.4543 12.9519 10.6183C12.7879 10.7823 12.5655 10.8746 12.3335 10.8749Z" fill="#515C7B"/>
<path d="M15.5 13.6249H9.4655C9.35818 13.23 9.12391 12.8814 8.79884 12.6329C8.47376 12.3844 8.07594 12.2498 7.66675 12.2498C7.25756 12.2498 6.85974 12.3844 6.53466 12.6329C6.20959 12.8814 5.97532 13.23 5.868 13.6249H4.5C4.36739 13.6249 4.24021 13.6776 4.14645 13.7713C4.05268 13.8651 4 13.9923 4 14.1249C4 14.2575 4.05268 14.3847 4.14645 14.4784C4.24021 14.5722 4.36739 14.6249 4.5 14.6249H5.868C5.97532 15.0198 6.20959 15.3683 6.53466 15.6169C6.85974 15.8654 7.25756 16 7.66675 16C8.07594 16 8.47376 15.8654 8.79884 15.6169C9.12391 15.3683 9.35818 15.0198 9.4655 14.6249H15.5C15.6326 14.6249 15.7598 14.5722 15.8536 14.4784C15.9473 14.3847 16 14.2575 16 14.1249C16 13.9923 15.9473 13.8651 15.8536 13.7713C15.7598 13.6776 15.6326 13.6249 15.5 13.6249ZM7.6665 14.9999C7.49344 14.9999 7.32427 14.9486 7.18038 14.8524C7.03648 14.7563 6.92433 14.6196 6.85811 14.4597C6.79188 14.2998 6.77455 14.1239 6.80831 13.9542C6.84207 13.7844 6.92541 13.6285 7.04778 13.5062C7.17015 13.3838 7.32606 13.3005 7.4958 13.2667C7.66553 13.2329 7.84146 13.2503 8.00135 13.3165C8.16123 13.3827 8.29789 13.4949 8.39404 13.6388C8.49018 13.7827 8.5415 13.9518 8.5415 14.1249C8.5411 14.3568 8.44879 14.5792 8.28478 14.7432C8.12077 14.9072 7.89844 14.9995 7.6665 14.9999Z" fill="#515C7B"/>
</g>
<defs>
<clipPath id="clip0_38585_21934">
<rect width="12" height="12" fill="white" transform="translate(4 4)"/>
</clipPath>
</defs>
</svg>
		)
	} else {
		return (
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
				<rect width="20" height="20" rx="10" fill="#0671E0" fill-opacity="0.1"/>
				<g clip-path="url(#clip0_35374_51184)">
					<path d="M4.5 6.37501H5.868C5.97532 6.76987 6.20959 7.11845 6.53466 7.36697C6.85974 7.61549 7.25756 7.75014 7.66675 7.75014C8.07594 7.75014 8.47376 7.61549 8.79884 7.36697C9.12391 7.11845 9.35818 6.76987 9.4655 6.37501H15.5C15.6326 6.37501 15.7598 6.32233 15.8536 6.22856C15.9473 6.13479 16 6.00762 16 5.87501C16 5.7424 15.9473 5.61522 15.8536 5.52145C15.7598 5.42769 15.6326 5.37501 15.5 5.37501H9.4655C9.35818 4.98014 9.12391 4.63156 8.79884 4.38304C8.47376 4.13452 8.07594 3.99988 7.66675 3.99988C7.25756 3.99988 6.85974 4.13452 6.53466 4.38304C6.20959 4.63156 5.97532 4.98014 5.868 5.37501H4.5C4.36739 5.37501 4.24021 5.42769 4.14645 5.52145C4.05268 5.61522 4 5.7424 4 5.87501C4 6.00762 4.05268 6.13479 4.14645 6.22856C4.24021 6.32233 4.36739 6.37501 4.5 6.37501ZM7.6665 5.00001C7.83956 5.00001 8.00873 5.05133 8.15262 5.14747C8.29652 5.24362 8.40867 5.38027 8.47489 5.54016C8.54112 5.70004 8.55845 5.87598 8.52469 6.04571C8.49093 6.21544 8.40759 6.37135 8.28522 6.49373C8.16285 6.6161 8.00694 6.69943 7.8372 6.73319C7.66747 6.76696 7.49154 6.74963 7.33165 6.6834C7.17177 6.61718 7.03511 6.50502 6.93896 6.36113C6.84282 6.21724 6.7915 6.04807 6.7915 5.87501C6.79176 5.64302 6.88404 5.42062 7.04807 5.25658C7.21211 5.09254 7.43452 5.00027 7.6665 5.00001Z" fill="#0671E0"/>
					<path d="M15.5 9.50001H14.132C14.0249 9.10505 13.7907 8.75634 13.4656 8.50772C13.1406 8.2591 12.7427 8.12439 12.3335 8.12439C11.9243 8.12439 11.5264 8.2591 11.2014 8.50772C10.8763 8.75634 10.6421 9.10505 10.535 9.50001H4.5C4.36739 9.50001 4.24021 9.55269 4.14645 9.64645C4.05268 9.74022 4 9.8674 4 10C4 10.1326 4.05268 10.2598 4.14645 10.3536C4.24021 10.4473 4.36739 10.5 4.5 10.5H10.535C10.6421 10.895 10.8763 11.2437 11.2014 11.4923C11.5264 11.7409 11.9243 11.8756 12.3335 11.8756C12.7427 11.8756 13.1406 11.7409 13.4656 11.4923C13.7907 11.2437 14.0249 10.895 14.132 10.5H15.5C15.6326 10.5 15.7598 10.4473 15.8536 10.3536C15.9473 10.2598 16 10.1326 16 10C16 9.8674 15.9473 9.74022 15.8536 9.64645C15.7598 9.55269 15.6326 9.50001 15.5 9.50001ZM12.3335 10.875C12.1604 10.875 11.9913 10.8237 11.8474 10.7275C11.7035 10.6314 11.5913 10.4947 11.5251 10.3349C11.4589 10.175 11.4416 9.99904 11.4753 9.8293C11.5091 9.65957 11.5924 9.50366 11.7148 9.38129C11.8372 9.25892 11.9931 9.17558 12.1628 9.14182C12.3325 9.10806 12.5085 9.12539 12.6683 9.19161C12.8282 9.25784 12.9649 9.36999 13.061 9.51388C13.1572 9.65778 13.2085 9.82695 13.2085 10C13.2082 10.232 13.116 10.4544 12.9519 10.6184C12.7879 10.7825 12.5655 10.8747 12.3335 10.875Z" fill="#0671E0"/>
					<path d="M15.5 13.625H9.4655C9.35818 13.2301 9.12391 12.8816 8.79884 12.633C8.47376 12.3845 8.07594 12.2499 7.66675 12.2499C7.25756 12.2499 6.85974 12.3845 6.53466 12.633C6.20959 12.8816 5.97532 13.2301 5.868 13.625H4.5C4.36739 13.625 4.24021 13.6777 4.14645 13.7715C4.05268 13.8652 4 13.9924 4 14.125C4 14.2576 4.05268 14.3848 4.14645 14.4786C4.24021 14.5723 4.36739 14.625 4.5 14.625H5.868C5.97532 15.0199 6.20959 15.3685 6.53466 15.617C6.85974 15.8655 7.25756 16.0001 7.66675 16.0001C8.07594 16.0001 8.47376 15.8655 8.79884 15.617C9.12391 15.3685 9.35818 15.0199 9.4655 14.625H15.5C15.6326 14.625 15.7598 14.5723 15.8536 14.4786C15.9473 14.3848 16 14.2576 16 14.125C16 13.9924 15.9473 13.8652 15.8536 13.7715C15.7598 13.6777 15.6326 13.625 15.5 13.625ZM7.6665 15C7.49344 15 7.32427 14.9487 7.18038 14.8525C7.03648 14.7564 6.92433 14.6197 6.85811 14.4599C6.79188 14.3 6.77455 14.124 6.80831 13.9543C6.84207 13.7846 6.92541 13.6287 7.04778 13.5063C7.17015 13.3839 7.32606 13.3006 7.4958 13.2668C7.66553 13.2331 7.84146 13.2504 8.00135 13.3166C8.16123 13.3828 8.29789 13.495 8.39404 13.6389C8.49018 13.7828 8.5415 13.9519 8.5415 14.125C8.5411 14.357 8.44879 14.5793 8.28478 14.7433C8.12077 14.9073 7.89844 14.9996 7.6665 15Z" fill="#0671E0"/>
				</g>
				<defs>
					<clipPath id="clip0_35374_51184">
						<rect width="12" height="12" fill="white" transform="translate(4 4)"/>
					</clipPath>
				</defs>
			</svg>

		)
	}

}
