import React, { useState } from "react";
export default function AnnotationPopup({
	setAnnotations,
	index,
	annotations,
	sethovert,
	i,
	show_ann,
	setshow_ann,
	setIsEditing,
	isEditing,
	color,
	underline,
	setunderline,
	setcolor,
	onCancel,
  setslectedt ,
	setSelectedText ,
  selectedText
}) {
	const [highlightText, setHighlightText] = useState("");
	const [textAreaInput, setTextAreaInput] = useState("");
	const [showannotate, setshowannotate] = useState(false);

	const handleSave = () => {
		if (show_ann) {
			sethovert((prevAnnotations) => {
				const updatedAnnotations = [...prevAnnotations];
				updatedAnnotations[i - 1] = highlightText;
				return updatedAnnotations;
			});
			console.log(`Saved annotation: ${highlightText}`);
			setshowannotate(false);
			setIsEditing(false);
		} else {
			setshowannotate(true);
		}
	};

	const handleCancel = () => {
		setHighlightText("");
		setTextAreaInput("");
		setIsEditing(false);
    setshow_ann(false)
    setslectedt("")
	  setSelectedText("")

		if (onCancel) {
			onCancel();
		}
	};

	const handleInputChange = (e) => {
		setTextAreaInput(e.target.value);
	};

	return (
		<div className="popup absolute w-full h-full left-0 right-0 bottom-0 transform z-10  transition-transform duration-300">
			{/* {showannotate ? (
				<div className="flex z-40 absolute flex-col items-start rounded bottom-[65px] transition-all left-[20px] p-2">
					<p className="font-normal text-base text-red-600">
						Make a Selection First
					</p>
					<p className="text-gray-300 font-normal text-red-600 text-sm">
						Select some text and then press the save button
					</p>
				</div>
			) : null} */}
			{isEditing ? (
				<>
					<div className="popup-content bg-white shadow-lg rounded-lg w-full absolute bottom-0">
						<div className="popup-header w-[100%] bg-black text-white flex justify-center items-center py-4">
							<div className="flex justify-between items-center w-[70%]">
								<p className="text-[21px] font-bold">New Annotation: "{selectedText}"</p>
								<button
									className="text-white text-[21px] hover:text-red-800 focus:outline-none"
									onClick={handleCancel}
								>
									Close X
								</button>
							</div>
						</div>
						<div className="popup-body flex justify-center items-center py-7">
							<div className="w-[70%]">
								<div className="header-container flex mt-2">
									<div className="header-item relative flex items-center">
										<p className="header-text font-bold text-[20px]">Highlight Color:</p>
										<div
											onClick={() =>
												document.getElementById("hiddenColorPicker").click()
											}
											style={{
												width: "25px",
												height: "25px",
												borderRadius: "50%",
												backgroundColor: color,
												marginLeft: "5px",
												cursor: "pointer",
												border: "1px solid #000", // optional, in case you want a border
											}}
										></div>

										<input
											type="color"
											id="hiddenColorPicker"
											value={color}
											style={{ display: "none" }}
											onChange={(e) => setcolor(e.target.value)}
										/>
									</div>
									<div className="header-item flex">
										<p className="header-text font-bold ml-[50px] text-[20px]">
											Underline Style:
										</p>
										<p
											className={`underline-symbol transition-all font-bold ml-2 cursor-pointer px-2 ${
												underline === "underline" && " border border-black"
											}`}
											style={{ textDecoration: "underline", display : "none" }}
										>
											{` U`}
										</p>
									</div>
								</div>

								<textarea
									placeholder="Write your annotation here..."
									onChange={handleInputChange}
									onBlur={() => {
										setHighlightText(textAreaInput);
									}}
									style={{
										padding: "10px",
										marginTop: "10px",
										width: "50%",
										height: "150px",
										minWidth: "300px",
										border: "1px solid",
									}}
								/>
								<div className="popup-actions mt-2 flex gap-20">
									<button
										className="bg-blue-700 hover:bg-blue-600 text-white px-6 py-2 rounded-full mr-2"
										onClick={handleSave}
									>
										Save
									</button>
									<button
										className="bg-none text-black font-bold px-4 py-2 rounded-lg"
										onClick={handleCancel}
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</div>
	);
}
