import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useMemo, useState } from 'react';
import MoreInfoIcon from '../MoreInfoIcon/MoreInfoIcon'
import SearchFieldV2 from '../SearchFieldV2/SearchFieldV2';
import SearchIcon from '../SearchIcon/SearchIcon';
import StubbyLeftArrow from '../StubbyLeftArrow/StubbyLeftArrow'
import Tooltip from '../Tooltip/Tooltip'
import ActionArea from './ActionArea/ActionArea';
import styles from './LeavesVacation.module.css'
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider';
import { get12HrTimeFromDate, getFormattedDateV2 } from '../../utils/dateUtils';
import ArrowUpIcon from '../ArrowIcons/ArrowUpIcon';
import ArrowDownIcon from '../ArrowIcons/ArrowDownIcon';
import PageIndicatorV2 from '../PaginationV2/PageIndicatorV2';
import PaginationV2 from '../PaginationV2/PaginationV2';
import CalIconVariant2 from '../CalendarSideIcons/CalIconVariant2';
import ClockIcon from '../CalendarSideIcons/ClockIcon';
import RefreshIcon from '../CalendarSideIcons/RefreshIcon';
import VacationOperationPopUp from './VacationOperationPopUp/VacationOperationPopUp';
import DeleteLeaveVacationPopUp from './DeleteLeaveVacationPopUp/DeleteLeaveVacationPopUp';
import { triggerToast } from '../../utils/toastController';
import { deleteTimeOffInstance, ediTimeOffInstance, fetchTimeOffData } from '../../utils/timeOffService';
import { useSelector } from 'react-redux';
import getRequiredUserIdsBasedOnRole from '../../utils/commonServices';
import generateXLFromTimeOffData from '../../utils/xlCreator';
import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

function masterSort(sortStates, key){
	switch (key) {
		case "userName" :  return ((prev,curr) => {
			const prevComp = [prev.user.firstName, prev.user.lastName].join(" ")
			const currComp = [curr.user.firstName, curr.user.lastName].join(" ")
			return prevComp > currComp && sortStates[key] === "ASC" ? 1 : -1
		})
		case "startDate" :  return ((prev,curr) => {
			return prev.leave.startDate.getTime() > curr.leave.startDate.getTime() && sortStates[key] === "ASC" ? 1 : -1
		})   
		case "endDate" :  return ((prev,curr) => {
			return prev.leave.endDate.getTime() > curr.leave.endDate.getTime() && sortStates[key] === "ASC" ? 1 : -1
		})   
	}

}

function filterResults(val, idx, searchString, startDate, endDate){

	const startOfDayStartDate = startDate?.setHours(0,0,0)
	const endOfDayEndDate = endDate?.setHours(23,59,59)

	const tempArr = [val.user.firstName.toLowerCase(), val.user.lastName.toLowerCase()]

	const searchStringConditions = val.user.firstName.toLowerCase().includes(searchString?.toLowerCase()) || val.user.lastName.toLowerCase().includes(searchString.toLowerCase()) || tempArr.join(" ").includes(searchString.toLowerCase()) || tempArr.reverse().join(" ").includes(searchString.toLowerCase())
	const dateConditions = (val.leave.startDate.getTime() <= endOfDayEndDate) && (val.leave.startDate.getTime() >= startOfDayStartDate)

	return searchStringConditions && dateConditions
}

const dummyLeaveDetails = [
	{
		user : {
			firstName : "Ethan",
			lastName : "Hunt",
			awsLink : "https://evallo-test.s3.amazonaws.com/",
			profilePhoto : "profilePhoto-1723193919936.avif"
		},
		leave : {
			startDate : new Date(),
			endDate : new Date((new Date()).getTime() + (24 * 3 * 1000 * 3600)),
			reason : "Taking time off for professional certification exams or training. TEstinjsdfkjsdakl;fjd;s", 
			isRecurring : true,
			recurringEndDate : new Date((new Date()).getTime() + (24 * 90 * 1000 * 3600)),
			repeatFrequency : "monthly"
		}
	},
	{
		user : {
			firstName : "Ethan",
			lastName : "Hunt",
			awsLink : "https://evallo-test.s3.amazonaws.com/",
			profilePhoto : "profilePhoto-1723193919936.avif"
		},
		leave : {
			startDate : new Date(),
			endDate : new Date(),
			reason : "Some odd reason"
		}
	},
	{
		user : {
			firstName : "Ethan",
			lastName : "Hunt",
			awsLink : "https://evallo-test.s3.amazonaws.com/",
		},
		leave : {
			startDate : new Date(),
			endDate : new Date(),
			reason : "Some odd reason"
		}
	}

]


function getSubheader(trigger, screenWidth, searchFieldProps, role) {
	const isSearchShownForRole = ["parent","admin"].includes(role)

	if (trigger && screenWidth < 992 && isSearchShownForRole){
		return <SearchFieldV2 {...searchFieldProps} />
	} else {
		return (<>
			<h2>Time Off</h2>
			<Tooltip> <MoreInfoIcon/> </Tooltip>
		</>)
	}
}


function DetailsHeader({headerItems, onSortClick, sortedStates, role}){
	return (
		<div className={`${styles.detailsHeader} ${styles[role]}`}>
			{
				headerItems.map((item, idx)=>(
					<div className={styles.item}>
						{item.displayValue}
						{
							item.isSortable ? 
								<button onClick={()=>onSortClick(item.key)}>
									{sortedStates[item.key] === "ASC" ? 
										<ArrowUpIcon /> : <ArrowDownIcon />
									}
								</button> 
								: <></>
						}
						
					</div>
				))
			}
		</div>
	)
}


function ButtonsRenderer({user, role, onClicks, leaveDetails, screenWidth}){
	const { id } = useSelector(state=>state.user)
	switch(role){
		case "admin" : 
				return(
					<>
						<button className={styles.blueButton} onClick={()=>onClicks.edit(leaveDetails, user)}>Edit</button>
						<button className={styles.redButton} onClick={()=>{console.log("LD", leaveDetails);onClicks.delete(leaveDetails, user)}}>Delete</button>
					</>
				)
		default : return(
			<>
				<button className={styles.blueButton} onClick={()=>onClicks.edit(leaveDetails, user)}>Edit</button>
				<button className={styles.redButton} onClick={()=>onClicks.delete(leaveDetails, user)}>Delete</button>
			</>
		)
	}
}

function LeaveDetails({user, leave, role, clickHandlers}){

	const viewer = useSelector(state=>state.user)
	const {organization} = useSelector(state=>state.organization)

	const viewingTimezone = useMemo(()=>{
		if(viewer && organization){
			return viewer.userDefaults?.timeZone ? viewer.userDefaults?.timeZone : organization.settings.timeZone
		}
	},[viewer, organization])

	const [screenWidth, setScreenWidth] = useState()

	const imgUrl = user.awsLink + user.photo
	const fullName = [user.firstName, user.lastName].join(" ")
	
	const startDateString = getFormattedDateV2(leave.startDate.toISOString(), "dd-mm-yyyy")
	const endDateString = getFormattedDateV2(leave.endDate.toISOString(), "dd-mm-yyyy")


	const isSameDayEvent = startDateString === endDateString

	let startTime = "-"
	let endTime = "-"

	if(!leave.isLockedFullDay){
		startTime = get12HrTimeFromDate(leave.startDate)
		endTime = get12HrTimeFromDate(leave.endDate)
	}

	let recurringEndDateString = null
	if(leave.isRecurring){
			recurringEndDateString = getFormattedDateV2(leave.recurringEndDate.toISOString(), "mm dd")
	}


	useEffect(()=>{
		setScreenWidth(window.innerWidth)
		window.addEventListener("resize", ()=> {
			setScreenWidth(window.innerWidth)
		})

		return ()=>{
			window.removeEventListener("resize", ()=>{
				setScreenWidth(window.innerWidth)
			})
		}
	},[])
	
	if(screenWidth > 992) {

		return (
			<>
				<div className={`${styles.udContainer} ${styles[role]}`}>
					<div className={styles.profileDetails}>
						{ 
							user.photo ? 
								<img src={imgUrl} /> : 
								<div className={styles.placeholder}>
									{user.firstName[0]}{user.lastName[0]}
								</div>
						}
						<span>{fullName}</span>
					</div>
					<div>{startDateString}</div>
					<div>{startTime}</div>
					<div>{endDateString}</div>
					<div>{endTime}</div>
					<div>{leave.reason}</div>
					<div className={styles.buttonContainer}>
						<ButtonsRenderer role={role} onClicks={clickHandlers} leaveDetails={leave} user={user}/>
					</div>
				</div>
				<div className={styles.horizontalRule}><HorizontalDivider /></ div>
			</>
		)

	} else {
	
		return(
			<>
				<div className={styles.udContainerMobile}>
					<div className={styles.profileDetails}>
						{ 
							user.profilePhoto ? 
								<img src={imgUrl} /> : 
								<div className={styles.placeholder}>
									{user.firstName[0]}{user.lastName[0]}
								</div>
						}
						<span>{fullName}</span>
					</div>
					<div className={styles.horizontalRule}><HorizontalDivider /></ div>
					<div className={styles.item}>
						<CalIconVariant2 currentColor={true}/>
						{startDateString}
						{
							isSameDayEvent ? 
							  <div className={styles.inner}>
									<span style={{fontSize : "20px"}}>&middot;</span>
									<span>{startTime}</span>
									-
									<span>{endTime}</span>
							  </div>
							: <></>
						}
					</div>
						{
							isSameDayEvent ? <></> 
							: <div className={styles.item}>
									<ClockIcon />
									<span>{startTime}</span>
									-
									<span>{endTime}</span>
							  </div>
						}
					<div className={styles.item}>
						<span>
							<MoreInfoIcon currentColor={true} height={"16px"} width={"16px"}/>
						</span>
						<span>{leave.reason}</span>
					</div>
					{
						leave.isRecurring ?
							<div className={styles.item}>
								<span>
									<RefreshIcon />									
								</span>
								<span>
									Repeats {leave.repeatFrequency} till {recurringEndDateString}
								</span>
							</div>
						: <></>	
					}
					<div className={styles.buttonContainer}>
						<ButtonsRenderer role={role} screenWidth={screenWidth} onClicks={clickHandlers} leaveDetails={leave} user={user}/>				
					</div>
				</div>
			</>		
		)

	}
}



function LeavesVacation({handleBackClick, leaveDetails, setLeaveDetails}) {

	const [popupOperationMode,setPopupOperationMode] = useState("add")
	const [isLoading, setIsLoading] = useState(false)
	const [vacationPopUpTrigger, setVacationPopUpTrigger] = useState(false)
	const [deleteVacationPopUpTrigger, setDeleteVacationPopUpTrigger] = useState(false)

	const [defaultLeaveDetails, setDefaultLeaveDetails] = useState(null)
	const [targetUser, setTargetUser] = useState(null)

	const [searchString, setSearchString] = useState("")
	const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false)

	const [screenWidth, setScreenWidth] = useState()
	const [sortedStates, setSortedStates] = useState({
		userName : "ASC",
		startDate : "ASC",
		endDate : "ASC",
	})
	const [tempLeaveDetails, setTempLeaveDetails] = useState([])
	const [currentPage, setCurrentPage] = useState(1)


	const {organization} = useSelector((state)=>state.organization)
	const user = useSelector((state)=>state.user)
	const { role } = useSelector((state)=>state.user)
	//useSelector((state)=>state.user)
	//const {role} = { role : "student"}
	
	const [filterStartDate, setFilterStartDate] = useState(dayjs().startOf("month").toDate())
	const [filterEndDate, setFilterEndDate] = useState(dayjs().endOf("month").toDate())

	const headerItems = [
		{
			displayValue : "User Name",
			isSortable : true,
			key : "userName",
		},
		{
			displayValue : "Start Date",
			isSortable : true,
			key : "startDate",
		},
		{
			displayValue : "Start Time",
			isSortable : false
		},
		{
			displayValue : "End Date",
			isSortable : true,
			key : "endDate",
		},
		{
			displayValue : "End Time",
			isSortable : false
		},
		{
			displayValue : "Description",
			isSortable : false
		}
	]


	const searchFieldPropsMobile = {
		onCrossClick : () => {setIsMobileSearchOpen(false)},
		isCrossButtonVisible : true,
		placeholder:"Search by name",
		textFieldValue : searchString,
		onChange : (e) => {setSearchString(e.target.value)},
	}

	const searchFieldPropsDesktop = {
		placeholder:"Search by name",
		onChange : (e) => {setSearchString(e.target.value)},
		textFieldValue : searchString
	}
	
	const clickHandlers = {
		details : (leaveDetails, user) => {setTargetUser(user);setDefaultLeaveDetails(leaveDetails); setPopupOperationMode("view");setVacationPopUpTrigger(true)},
		edit : (leaveDetails, user) => {setTargetUser(user);setDefaultLeaveDetails(leaveDetails); setPopupOperationMode("edit");setVacationPopUpTrigger(true)},
		delete : (leaveDetails, user) => {setTargetUser(user);setDefaultLeaveDetails(leaveDetails); setPopupOperationMode("add");setDeleteVacationPopUpTrigger(true)}
	}


	const _tempLeaveDetails = tempLeaveDetails
								.filter((val,idx)=>filterResults(val,idx, searchString, filterStartDate, filterEndDate))
								.sort(masterSort(sortedStates, "userName"))
								.sort(masterSort(sortedStates, "startDate"))
								.sort(masterSort(sortedStates, "endDate"))

	function handleSortClick(key) {
		setSortedStates((prev)=>({...prev, [key] : prev[key]==="ASC"? "DESC" : "ASC"}))
	}

	function refetchTimeOff(){
			fetchTimeOffData(getRequiredUserIdsBasedOnRole(user),setLeaveDetails, user)
	}

	async function handleDeleteClick(leaveId, userId) {
		try {
			console.log(leaveId)
			setIsLoading(true)
			await deleteTimeOffInstance(organization._id, userId, leaveId)
			refetchTimeOff()
		}catch(err){
			triggerToast("Some error occured","error",{autoClose : 3000})
		}finally {
			setIsLoading(false)
			setDeleteVacationPopUpTrigger(false)
		}
	}


	function handleDownloadButtonClick(){
		let cleanedData = []
		_tempLeaveDetails.forEach(({user, leave})=>{
			const userName = [user.firstName, user.lastName].join(" ")
			cleanedData.push({
				"User Name" : userName,
				"Start Date" : getFormattedDateV2(leave.startDate.toISOString(), "dd-mm-yyyy"),
				"Start Time" : get12HrTimeFromDate(leave.startDate),
				"End Date" : getFormattedDateV2(leave.endDate.toISOString(), "dd-mm-yyyy"),
				"End Time" : get12HrTimeFromDate(leave.endDate),
				"Description" : leave.reason
			})
		})
		generateXLFromTimeOffData(cleanedData)
	}

	

	useEffect(()=>{
		setScreenWidth(window.innerWidth)
		window.addEventListener("resize",()=>{
			setScreenWidth(window.innerWidth)
		})
	},[])


	useEffect(()=>{
		if(leaveDetails)
			setTempLeaveDetails(leaveDetails);
		else 
			setTempLeaveDetails([]);
	},[leaveDetails])

	useEffect(()=>{
		console.log(tempLeaveDetails)	
		console.log(tempLeaveDetails.length)	
	},[tempLeaveDetails])


	useEffect(()=>{
		console.log("FSD")
		console.log("FSD",filterStartDate)
		console.log("FSDE",filterEndDate)
	},[filterStartDate, filterEndDate])
	
	useEffect(()=>{
		if(!vacationPopUpTrigger){
			setDefaultLeaveDetails(null)
		}
	},[vacationPopUpTrigger])


	return (
		<div className={styles.container}>
			<button className={styles.backButton} onClick={(e)=>handleBackClick(e)}><ChevronLeft/> Back</button>
			<div className={styles.heading}>
				<div className={styles.header}>
					<button className={styles.desktopBackButton} onClick={(e)=>handleBackClick(e)}><StubbyLeftArrow /></button>
					<div className={styles.subHeader}>
						{getSubheader(isMobileSearchOpen, screenWidth, searchFieldPropsMobile, role)}					
					</div>
					<button className={styles.addNewButton} onClick={(e)=>{setPopupOperationMode("add");setVacationPopUpTrigger(true)}}>
						<AddIcon style={{ height: "16px", width: "16px" }} /> Add
					</button>
					<button className={styles.searchButton} onClick={(e)=>setIsMobileSearchOpen(true)} style={{display : isMobileSearchOpen && !(["parent","admin"].includes(role)) ? "none" : ""}}>
						<SearchIcon height={"20px"} width={"20px"}/>	
					</button>
				</div>
				<p className={styles.desc}>
					Add irregular unavailabilities and manage existing unavailabilities.
				</p>
			</div>

			<ActionArea role={user?.role} searchFieldProps={searchFieldPropsDesktop} startDate={filterStartDate} endDate={filterEndDate} setStartDate={setFilterStartDate} setEndDate={setFilterEndDate} handleDownloadButtonClick={handleDownloadButtonClick}/>

			<div className={styles.detailsGrid}>
				<DetailsHeader headerItems={headerItems} onSortClick={handleSortClick} sortedStates={sortedStates} role={role}/>
				{
						_tempLeaveDetails?.slice((currentPage - 1) * 10, ((currentPage - 1) * 10) + 10).map((details,idx)=>(
						<LeaveDetails {...details} role={role} clickHandlers={clickHandlers}/>
					))
								
				}
			</div>
			<div className={styles.paginationContainer}>
				<PageIndicatorV2 
					recordStartNo={(currentPage === 1 ? currentPage : (currentPage - 1) * 10)}  
					recordEndNo={_tempLeaveDetails.length < (currentPage * 10 ) ? _tempLeaveDetails.length : currentPage * 10 } 
					totalRecordsCount={_tempLeaveDetails.length} />
				<PaginationV2 
                    pageSize={10}
                    totalDataCount={_tempLeaveDetails.length}
                    previousButton={{
                      content: (
                        <>
                          <ChevronLeft />
                        </>
                      ),
                      className:
                        "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                    }}
                    nextButton={{
                      content: (
                        <>
                          <ChevronRight />
                        </>
                      ),
                      className:
                        "px-[6px] py-[6px] bg-blue-70 bg-blue-10 rounded-[4px] text-blue-70 disabled:text-secondary-60 disabled:bg-secondary-5",
                    }}
                    helperButtonClassName={
                      "text-secondary-60 font-[400] font-[calc(100vw*16/1440)] "
                    }
                    selectedHelperButtonClassName={
                      "text-blue-70 font-[600] font-[calc(100vw*16/1440)]"
                    }
                    selectedPage={currentPage}
                    setSelectedPage={setCurrentPage}

				/>
			</div>
			<VacationOperationPopUp trigger={vacationPopUpTrigger} setTrigger={setVacationPopUpTrigger} defaultLeaveDetails={defaultLeaveDetails} mode={popupOperationMode} inheritedUser={targetUser} refetchTimeOff={refetchTimeOff}/>
			<DeleteLeaveVacationPopUp trigger={deleteVacationPopUpTrigger} setTrigger={setDeleteVacationPopUpTrigger} onDeleteClick={handleDeleteClick} isLoading={isLoading}leaveDetails={defaultLeaveDetails}/>
		</div>
	)

}


export default LeavesVacation
