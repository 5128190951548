import { useSelector } from "react-redux";
import { getFormattedDate } from "../../utils/utils";
import { useEffect } from "react";
import { useState } from "react";
import { useLazyGetTutorDetailsQuery, useLazyGetUserDetailQuery } from "../../app/services/users";
import { getMonthName } from "../../utils/utils";
import styles from "./LatestSignUpTableItem.module.css";
const LatestSignUpTableItem = ({ item, onClick }) => {
  const [ dateFormat,setDateFormat ] = useState("dd/mm/yy")
  const { organization: organization2 } = useSelector((state) => state.organization)
  const [getUserDetail, userDetailResp] = useLazyGetTutorDetailsQuery();
  const [getUserDetail2, userDetailResp2] = useLazyGetUserDetailQuery();
  const [tutor,setTutor]=useState([])
  const [leadStatus, setleadStatus] = useState('')
  
  useEffect(()=>{
    if(organization2&&organization2?.settings){
      setDateFormat(organization2?.settings?.dateFormat)
    }
  },[organization2])
  const handleClick = () => {
    onClick?.redirect(item);
  };
  useEffect(()=>{
    setTutor([])
    if(!item?.assiginedTutors||item?.assiginedTutors?.length===0)
    return
    item?.assiginedTutors?.map((it,idd)=>{
      let userId=it
      getUserDetail({ id: userId }).then((res) => {
        // console.log("response",userId, res?.data);
        if(!res?.data)return 
        const {firstName,lastName}=res.data.data.user
         let fullName=firstName+" "+lastName+", "
        
        setTutor((prev)=>{
  
          if(tutor?.includes(fullName))
          return [...prev]
         return   [...prev,fullName]});
      
      })
    })
    
  },[item]);

  useEffect(() => {
    getUserDetail2({ id: item._id }).then((res) => {
      if(!res?.data)return 
      console.log("response", res?.data.data);
       setleadStatus(res.data.data?.userdetails?.leadStatus)
    })
  }, [item])
   //  format monthName date, year
   function formatDate(inputDate) {
    const months = [
      "January", "February", "March", "April",
      "May", "June", "July", "August",
      "September", "October", "November", "December"
    ];
  
    const dateObject = new Date(inputDate);
    const month = months[dateObject.getUTCMonth()];
    const year = dateObject.getUTCFullYear();
  
    const formattedDate = `${month.substring(0,3)} ${dateObject.getUTCDate()}, ${year}`;
    return formattedDate;
  }
  return (
    <tr className=" leading-8  border-separate border-spacing-2.5 !h-[50px] bg-white rounded-[5px] border-[1.25px] border-white" style={{ boxShadow:' 0px 0px 2px 0px rgba(0, 0, 0, 0.25)'}}>
      <td className={`text-[17.5px] !pl-[16px] min-w-10  text-left ${styles.firtsRadius} !py-0`} >
        <span
          className="inline-block cursor-pointer pl-4 "
          onClick={handleClick}
        >
          {item.firstName + " " + item.lastName}
        </span>
      </td>
      <td className=" text-[17.5px] max-w-[140px] ">
        <div className={`flex flex-col max-w-[140px] capitalize`}>
          {item.email?.length > 19 ? (
            <>
              <p>{item.role?.slice(0, 19)}</p>
              <p>{item.role?.slice(19, item?.role?.length)}</p>
            </>
          ) : (
            <p>{item.role}</p>
          )}
        </div>
      </td>
      <td className=" text-[17.5px]   max-w-[190px] text-left">
        {/* <p className="max-w-[207px] text-ellipsis overflow-hidden whitespace-normal ">{item.email}</p> */}
           {/* <p className="whitespace-normal break-words">
           {item.email}
           </p> */}
         <p className="max-w-[207px] text-ellipsis">{item.email?.length>19?item.email?.slice(0,19)+"...":item.email}</p>
      </td>
      <td className=" text-[17.5px] !px-[16px]  min-w-14 ">
        <div>{item.phone}</div>
      </td>
      <td className=" text-[17.5px] max-w-[180px] ">
        <div className="max-w-[180px] custom-scroller-2  min-w-[100px]">
          {tutor && tutor?.length > 0 ? (
            <div className="flex flex-col max-w-[200px]">
               <>
                {tutor.map((item)=><p>{item}</p>)}
              </>
            </div>
          ) : (
            "NA"
          )}
        </div>
      </td>
      <td className=" text-[17.5px] !px-[16px]  min-w-14 ">
        <div>{leadStatus ? leadStatus : '-'}</div>
      </td>
      <td className=" text-[17.5px] !px-[16px]  min-w-12 ">
        {item.tutorStatus ? item.tutorStatus : '-'}
      </td>
      <td className=" text-[17.5px] !px-[16px]  min-w-14 ">
        <div>
          {item?.specialization?.length > 0 ? item.specialization : "NA"}
        </div>
      </td>
      <td className=" text-[17.5px]  min-w-12  rounded-r-5">
        <div>
          {/* {new Date(item.lastSignUp).toDateString()} */}
          {formatDate(item.createdAt)}
        </div>
      </td>
    </tr>
  );
};

export default LatestSignUpTableItem