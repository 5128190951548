export default function CautionIcon (){

	return(
		<svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.44904 1.1457L0.740177 16.2288C0.582864 16.5012 0.500029 16.8102 0.5 17.1247C0.499971 17.4392 0.582749 17.7483 0.740011 18.0207C0.897273 18.2931 1.12348 18.5193 1.39588 18.6765C1.66829 18.8338 1.9773 18.9166 2.29184 18.9166H19.7082C20.0227 18.9166 20.3317 18.8338 20.6041 18.6765C20.8765 18.5193 21.1027 18.2931 21.26 18.0207C21.4173 17.7483 21.5 17.4392 21.5 17.1247C21.5 16.8102 21.4171 16.5012 21.2598 16.2288L12.5519 1.1457C12.3947 0.873369 12.1685 0.647227 11.8962 0.49C11.6238 0.332773 11.3149 0.25 11.0005 0.25C10.686 0.25 10.3771 0.332773 10.1048 0.49C9.83242 0.647227 9.60627 0.873369 9.44904 1.1457Z" fill="#FFC56D"/>
			<path d="M11.1149 5.79688H10.8877C10.3284 5.79688 9.875 6.25028 9.875 6.80958V11.6501C9.875 12.2095 10.3284 12.6629 10.8877 12.6629H11.1149C11.6742 12.6629 12.1276 12.2095 12.1276 11.6501V6.80958C12.1276 6.25028 11.6742 5.79688 11.1149 5.79688Z" fill="#25335A"/>
			<path d="M11.0013 16.5026C11.6233 16.5026 12.1276 15.9983 12.1276 15.3763C12.1276 14.7543 11.6233 14.25 11.0013 14.25C10.3793 14.25 9.875 14.7543 9.875 15.3763C9.875 15.9983 10.3793 16.5026 11.0013 16.5026Z" fill="#25335A"/>
		</svg>

	)

}
