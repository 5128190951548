import React, { useEffect, useState } from 'react'
import TooltipIcon from '../../../assets/icons/SettingsIcon/TooltipIcon'
import { Link } from 'react-router-dom'
import EditPen from '../../../assets/icons/SettingsIcon/EditPen'
import AnnouncementCard from '../../../components/AnnouncementCard/AnnouncementCard'
import WarningIcon from '../../../assets/icons/SettingsIcon/WarningIcon'
import Tooltip from '../../../components/Tooltip/Tooltip'
import CreateAnnouncement from './Modals/CreateAnnouncement'
import CatIcon from '../../../assets/icons/SettingsIcon/CatIcon'
import CatMobileIcon from '../../../assets/icons/SettingsIcon/CatMobileIcon'
import { useLazyGetSettingsQuery } from '../../../app/services/session'
import { useSelector } from 'react-redux'
import EditAnnouncement from './Modals/EditAnnouncement'

const Announcements = () => {

    const [openAnnouncementModal, setOpenAnnouncementModal] = useState(false)
    const [openEditAnnouncementModal, setOpenEditAnnouncementModal] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const [announcements, setAnnouncements] = useState([])
    const [announcementsLimits, setAnnouncementsLimites] = useState(0)
    const [settingsData, setSettingsData] = useState({});
    const [fetchedPermissions, setThePermission] = useState([]);
    const { organization } = useSelector((state) => state.organization);
    const { subscriptionV2 } = useSelector((state) => state.subscription)
    const { awsLink } = useSelector((state) => state.user);
    const [getSettings, getSettingsResp] = useLazyGetSettingsQuery();

    const fetchSettings = () => {
        getSettings().then((res) => {
            setSettingsData(organization?.settings);
            setThePermission(organization?.settings?.permissions);
        });

    };

    useEffect(() => {
        fetchSettings();
        console.log(settingsData)
    }, [organization]);

    useEffect(() => {
        setAnnouncements(organization?.settings?.offerImages)
        setAnnouncementsLimites(subscriptionV2?.subscription?.settings?.announcements?.limit)
        console.log(organization)
        console.log(subscriptionV2)
    }, [fetchedPermissions])

    return (
        <div className='h-full'>
            <div className='p-4 xl:px-8 xl:py-9'>
                <div className='flex flex-col gap-3 xl:flex-row xl:justify-between items-start'>
                    <div className=''>
                        <div className='flex items-center gap-[6px]'>
                            <h1 className='font-semibold text-20 leading-[30px] text-secondary'>Announcements</h1>
                            <div className='hidden xl:block'> <Tooltip anchorComponent={<TooltipIcon />} tooltipStyleClass={"bg-white w-[240px] shadow-[0px_4px_24px_0px_#11167D26] p-3 rounded-[12px] font-normal text-[12px] leading-[18px] text-secondary-70"} tooltipContent={"Annoucements can be used to announce important aspects of your business. Displayed on the top-left corner in Parent and Student Dashboards, these can be used to highlight your services, offers, referral incentives, webinars, events, proctored tests, tutorial videos, and pretty much anything you want your clients to see as soon as they log into their Evallo dashboard."} /></div>
                            <div className='xl:hidden'> <Tooltip anchorComponent={<TooltipIcon />} tooltipStyleClass={"bg-white min-w-[160px] max-w-[240px] shadow-[0px_4px_24px_0px_#11167D26] p-3 rounded-[12px] font-normal text-[12px] leading-[18px] text-secondary-70"} tooltipContent={"Announcements appear in the top-left corner of Parent and Student Dashboards to highlight services, offers, events, and more."} /></div>
                        </div>
                        <div className='font-normal text-base text-secondary-60'>Highlight your services, offers, events, webinars and more to your customers. <Link className='font-medium text-blue-60 '>Learn more.</Link> </div>
                        <div className={`${announcements?.length >= announcementsLimits ? "flex" : "hidden"} font-normal text-[14px] leading-[17px] xl:leading-[14px] mt-3 px-2 py-1 bg-red-50 text-secondary-60 items-center gap-[6px]`}> <div><WarningIcon /></div> You’ve reached the limits of your plan. 
							{
								!subscriptionV2?.subscription?.basePackage.name.includes("Premium") ? 
								" Upgrade your subscription plan to unlock more announcements."
								: ""
							} 
						</div>
                    </div>
                    <div>
                        {
                            !announcements?.length < 1 && <button disabled={announcements?.length >= announcementsLimits} className=' rounded-md px-5 py-[10px] disabled:bg-blue-70/30 bg-blue-70 text-[16px] leading-[20px] text-white' onClick={() => setOpenAnnouncementModal(true)}>+ Create new</button>
                        }
                    </div>
                </div>
                {
                    announcements?.length > 0 ?
                        <div className='w-full xl:w-4/5 grid grid-cols-1 gap-6 lg:grid-cols-2 justify-start lg:gap-9 pt-6'>
                            {
                                announcements?.map((el, i) => {
                                    return <AnnouncementCard header={el?.header} subText={el?.subText} count={i + 1} limit={announcementsLimits} key={el._id} id={el._id} cta={el.buttonText} link={el?.link} image={awsLink + el?.image} setOpenEditAnnouncementModal={setOpenEditAnnouncementModal} setCurrentId={setCurrentId} />
                                })
                            }
                        </div>
                        :
                        <div className='flex justify-center items-center w-full h-full mt-4'>
                            <div className='flex flex-col text-center items-center gap-6 p-6'>
                                <div className='hidden xl:block'><CatIcon /></div>
                                <div className='xl:hidden'><CatMobileIcon /></div>
                                <div>
                                    <h1 className='font-bold text-[28px] leading-[42px] xl:text-[32px] xl:leading-[48px] text-center text-primary-blue'>It’s lonely here!</h1>
                                    <div className='font-normal pt-3 xl:text-base text-secondary-80'>You haven’t created an announcement yet. Create your first announcement now.</div>
                                </div>
                                <button className='px-6 py-[10px] rounded-md bg-blue-70 hover:bg-blue-80 font-semibold text-[16px] leading-[20px] text-white' onClick={() => setOpenAnnouncementModal(true)}>Create new</button>
                            </div>
                        </div>
                }


            </div>
            {openAnnouncementModal && <CreateAnnouncement setOpenAnnouncementModal={setOpenAnnouncementModal} orgId={organization._id} />}
            {openEditAnnouncementModal && <EditAnnouncement setOpenEditAnnouncementModal={setOpenEditAnnouncementModal} orgId={organization._id} data={announcements.find(obj => obj._id === currentId)} awsLink={awsLink} currentId={currentId} />}
        </div>
    )
}

export default Announcements
