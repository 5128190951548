import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import React, { useEffect, useState } from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css"; // Import the CSS
import AnnotationPopup from "./Annotationpopup";
import Calculator from "./Calculator";
import "./questions.css";
import Bookmark from "../../assets/icons/bookmark";

export default function Que(props) {
	const {
		ques,
		op,
		para,
		setAllQuestions_data,
		initialSeconds,
		setInitialSeconds,
		countDown,
		showtextbox,
		answerimagecheck,
		setshowtextbox,
		showannotate,
		setshowannotate,
		setAnswers,
		quesImg,
		quesT,
		answers,
		siz,
		index,
		Setmark,
		mark,
		cal,
		setCal,
		seq,
		cutanswers,
		cutanswer,
		showcutcheck,
		cutcheck,
		markreview,
		markre,
		annotation_check,
		calculator_check,
		cross_O_check,
		show_ann,
		setshow_ann,
	} = props;
	const s = {
		height: "58.2vh",
	};
	let markit = () => {
		const arr = [...mark];
		arr[index] = !arr[index];
		Setmark(arr);
	};
	const [underline, setunderline] = useState("underline");

	const [check, setcheck] = useState(false);
	const [color, setcolor] = useState("yellow");
	const [selectedt, setslectedt] = useState("");
	const [selectedText, setSelectedText] = useState("");
	const [annotations, setAnnotations] = useState(
		Array(siz)
			.fill(null)
			.map(() => [])
	);
	const [hovert, sethovert] = useState(Array(siz).fill());

	useEffect(() => {
		setslectedt(); 
		let allAnnotate = document.querySelectorAll('.annotate_one');
 		if (allAnnotate.length > 0) {
		  Array.from(allAnnotate).map(it => {
			console.log(it);
			it.style.display = 'none';
		  });
		}
	  }, [index]);
	  
	useEffect(() => {
		const handleTextSelection = () => {
			const selectedText = window.getSelection().toString();
			if (selectedText) {
				setshow_ann(true);
				setSelectedText(selectedText);
				// console.log('jihn',selectedText,window.getSelection().getRangeAt(0));
				setslectedt(window.getSelection().getRangeAt(0));
			}
		};
		document.addEventListener("mouseup", handleTextSelection);

		// Cleanup listener when the component is unmounted
		return () => {
			document.removeEventListener("mouseup", handleTextSelection);
		};
	}, []);
	useEffect(() => {
		// console.log("asdasd", showannotate, show_ann);
		console.log("Selected Text:", selectedt);

		if (!show_ann && showannotate) {
			setshow_ann(true);
			// console.log("Selected Text:", selectedt);
		}
	}, [selectedt]);

	useEffect(() => {
		console.log(showannotate, show_ann);
		if (!showannotate && show_ann) {
			const span = document.createElement("span");
			span.classList.add('annotate_one')
			span.style.backgroundColor = color;
			span.style.position = "relative";
			span.style.fontFamily = "'Times New Roman', Times, serif";
			const range = selectedt;
			// console.log("select", selectedt);
			try {
				range.surroundContents(span);

				// Add a tooltip to the highlighted text
				const tooltip = document.createElement("div");
				tooltip.className = "hover-float";
				tooltip.innerText = hovert[index - 1];

				span.appendChild(tooltip);

				tooltip.style.display = "none";
				tooltip.style.fontFamily = "'Times New Roman', Times, serif";
				tooltip.style.zIndex = "999";

				// Add a hover event listener to show/hide the tooltip
				span.addEventListener("mouseenter", () => {
					tooltip.style.display = "block";
				});

				span.addEventListener("mouseleave", () => {
					tooltip.style.display = "none";
				});
			} catch (error) {
				console.log(error);
			}
			// console.log(selectedt, hovert[index - 1]);
			setshow_ann(false);
		}
		// console.log("fin");
	}, [showannotate]);
	function getCheckedString(inputString) {
		// Use regular expression to split the string and capture the symbols
		const splitString = inputString.split(
			/(br<\/br>|#|\\begin\{align\*\}|\\end\{align\*\})/
		);

		// Filter out empty strings from the split
		const filteredSplit = splitString.filter((str) => str.trim() !== "");

		// Log the result
		return filteredSplit;
	}
	useEffect(() => {
		tippy("mark", {
			content: hovert[index - 1],
		});
	}, [quesT]);
	function addBackslash(string) {
		// Initialize an empty string to store the result
		let result = "";
		// Split the string by spaces and loop through each word
		for (let word of string.split(" ")) {
			// Add a backslash and the word to the result
			result += " \\ " + word + " ";
		}
		// console.log("string--", string);
		// console.log("result--", result);
		// Trim the trailing space and return the result
		return result.trim();
	}
	function extractboldtext(val){
		const t= val.match(/textbf{([^}]*)}/)
		return t[1];
	}
	function extractunderlinetext(val){
		const t= val.match(/underline{([^}]*)}/)
		return t[1];
	}
	function extractitalictext(val){
		const t= val.match(/textit{([^}]*)}/)
		return t[1];
	}
	const config = {
		loader: { load: ["input/asciimath"] },
		asciimath: {
			displaystyle: true,
			delimiters: [
				["$", "$"],
				["`", "`"],
			],
		},
	};
	const containsHashSymbols = (str) => {
		// Check if the string contains ## at both ends
		if (!str || str?.trim()?.length < 3) return false;

		return str.startsWith("#") && str.endsWith("#");
	};
	const removeHashSymbols = (str) => {
		// Remove ## from the start and end of the string
		if (!str) return "";
		return `${str} `;
		// return `${str.slice(1, -1)} `;
	};

	const QuestionComp = ({ key, it }) => {
		return (
			<span className="dsat-latex">
				<MathJaxContext key={key} config={config}>
					<MathJax inline dynamic>
						`{addBackslash(removeHashSymbols(it))} `
					</MathJax>
				</MathJaxContext>
			</span>
		);
	};
	console.log(selectedt,show_ann);
	return (
		<>
			{showannotate ? (
				<AnnotationPopup
					show_ann={show_ann}
					index={index}
					annotations={annotations}
					setAnnotations={setAnnotations}
					setshow_ann={setshow_ann}
					setIsEditing={setshowannotate}
					isEditing={showannotate}
					color={color}
					i={index}
					underline={underline}
					sethovert={sethovert}
					setunderline={setunderline}
					setcolor={setcolor}
					setslectedt={setslectedt}
					setSelectedText={setSelectedText}
					selectedText={selectedText}
				/>
			) : null}
			<div
				className={`max-h-[620px] bg-white overflow-y-auto py-[50px] px-20 gap-[80px] relative flex flex-row items-start min-h-[440px] ${
					props.check && "bg-white"
				} ${
					!para ? "justify-center" : "justify-between"
				} self-stretch dsat-test-page`}
				// style={s}
			>
				{para ? (
					<>
					<div className="w-1/2 flex-1 dsat-font">
						<div
							style={{ wordWrap: "break-word", display: "inline-block" }}
							className="w-full"
						>
							<div
								contentEditable="false"
								className="ck-content"
								dangerouslySetInnerHTML={{ __html: quesT[index - 1].text }}
							/>
						</div>
					</div>
					<div className="h-[100vh] sticky top-[0px] left-[50%] w-[3px] bg-[#93939368]">
						&nbsp;
					</div>
					</>
				) : null}
				<div
					className={` ${
						props.check && "hidden"
					} ${
						!para ? "flex min-w-[661px] max-w-[800px] flex-col flex-1" : "w-[661px] flex-1"
					}`}
				>
					<div className="bg-slate-200  text-center relative text-[20px] flex items-center border-dashed border-b-3 border-black">
						<span className=" bg-black text-white py-2 px-2">{index}</span>
						{
							markreview.length > 0
							? markreview[index - 1]?.review
							?
									<FontAwesomeIcon
										onClick={() => {
											markre(index);
										}}
										icon={faBookmark}
										color={
											markreview.length > 0
												? markreview[index - 1]?.review ? "yellow" : "#fff"
												: "#fff"
										}
										className={`cursor-pointer relative ml-6 mr-2 py-2`}
									/> : <span className={`cursor-pointer relative ml-6 mr-2 py-2`} onClick={() => {
										markre(index);
									}}>
										<Bookmark color={
											markreview.length > 0
												? markreview[index - 1]?.review ? "yellow" : "#fff"
												: "#fff"
										} />
									</span> : <span className={`cursor-pointer relative ml-6 mr-2 py-2`} onClick={() => {
										markre(index);
									}}>
									<Bookmark color={
										markreview.length > 0
											? markreview[index - 1]?.review ? "yellow" : "#fff"
											: "#fff"
									} />
								</span>
						}
						
						{/* <FontAwesomeIcon
							onClick={() => {
								markre(index);
							}}
							icon={faBookmark}
							color={
								markreview.length > 0
									? markreview[index - 1]?.review ? "yellow"	: "#fff"
									: "#fff"
							}
							className={`cursor-pointer relative ml-6 mr-2 py-2`}
						/> */}
						<h3 className="relative text-gray-600 leading-normal py-2">
							Mark for review
						</h3>
						{cross_O_check ? (
							<div
								className={`py-1 absolute line-through right-2 cursor-pointer border border-black px-2 rounded ${
									cutcheck && "bg-blue-400"
								}`}
								onClick={() => {
									showcutcheck();
								}}
							>
								<p>ABC</p>
							</div>
						) : null}
					</div>
					{/* <hr className=" border border-black" /> */}
					<div className="mathjax-box flex my-4 flex-col items-start justify-center dsat-font">
						{ques != null && ques?.length > 0 ? (
							<p className="dsat-font text-black">
								{ques?.split("#")?.map((it, id) => {
									if (id % 2 !== 0) {
										return (
											<>
												{it.includes('textbf')?
													(<strong>{extractboldtext(it)}</strong>)
													:
													it.includes('underline')?
													(<span className="underline ">{' '+extractunderlinetext(it)+' '}</span>):
													it.includes('textit')?
													(<i>{extractitalictext(it)}</i>):
													getCheckedString(it).map((val) =>
													val === "br</br>" ? (
														<br key={val} />
													)
													:
													(
														<QuestionComp key={id} it={val} />
													)
												)}
											</>
										);
									}
									return (
										<>
											<>
												{getCheckedString(it).map((val) =>
													val === "br</br>" ? <br key={val} /> : val
												)}
											</>
										</>
									);
								})}
							</p>
						) : null}
						{quesImg != "" && quesImg !== "no" ? (
							<div className="flex justify-center items-center w-full my-[8px]">
								<img className="max-w-[100%]" style={{objectFit : "contain"}} src={quesImg} />
							</div>
						) : null}
					</div>
					{answers[index - 1].QuestionType == "Grid-in" ? (
						<div className="dsat-font" id="inputinans">
							<input
								placeholder="Enter The Answer"
								type="text"
								className="bg-transparent mt-4 border-gray-600 border rounded px-2 py-4 dsat-font"
								value={answers[index - 1].ResponseAnswer}
								onChange={(e) => {
									let ans = e.target.value;
									const timeTaken = initialSeconds - countDown;
									setInitialSeconds(countDown);

									const updatedanswer = answers.map((q) =>
										q.QuestionNumber === index
											? {
													...q,
													ResponseAnswer: ans,
													responseTime:
														q.responseTime > 0
															? q.responseTime + timeTaken
															: timeTaken,
											  }
											: q
									);
									setAnswers(updatedanswer);
								}}
							/>
							<p className="text-xl font-medium mt-4 font-bold dsat-font">
								Answer Preview : {answers[index - 1].ResponseAnswer}
							</p>
						</div>
					) : (
						op?.map((e, i) => {
							let text = `${e.text}`;
							return (
								<div
									className={`dsat-font flex flex-row w-full cursor-pointer border-[3px] rounded-xl my-3 px-2 items-center ${
										answers[index - 1].ResponseAnswer == e.label
											? "border-blue-400"
											: null
									} `}

								>
									{" "}
									<span
										className={` font-semibold text-sm mr-4 border-[3px] rounded-full px-2 py-1 ml-2  ${
											answers[index - 1].ResponseAnswer == e.label
												? "bg-blue-400 text-white"
												: "text-black"
										}`}
										onClick={() => {
											props.MarkAnswer(index, i);
										}}
									>
										{e.label}
									</span>{" "}
									<li className="dsat-font relative flex w-full items-center text-black list-none rounded-lg">
										<div className="flex justify-between w-full items-center">
											<div
												className="dsat-font flex flex-wrap justify-start w-[90%] py-2 relative items-center"
												onClick={() => {
													props.MarkAnswer(index, i);
												}}
											>
												<p className="dsat-font">
													{" "}
													{text &&
														text?.length > 0 &&
														text?.split("#")?.map((it, id) => {
															if (id % 2 !== 0) {
																return (
																	<>
																		{it.includes('textbf')?
													(<strong>{extractboldtext(it)}</strong>)
													:
													it.includes('underline')?
													(<span className="underline ">{' '+extractunderlinetext(it)+' '}</span>):
													it.includes('textit')?
													(<i>{extractitalictext(it)}</i>):
													getCheckedString(it).map((val) =>
													val === "br</br>" ? (
														<br key={val} />
													)
													:
													(
														<QuestionComp key={id} it={val} />
													)
												)}
																	</>
																);
															}
															return (
																<>
																	{getCheckedString(it).map((val) =>
																		val === "br</br>" ? <br key={val} /> : val
																	)}
																</>
															);
														})}
												</p>
												{e.image ? (
													<img
														className="ml-6 max-w-[100%]"
														style={{objectFit : "contain"}}
														src={e.image}
													/>
												) : null}
												{cutanswer[index - 1].markcut[i] == 1 && cutcheck ? (
													<div className="flex w-full h-full bg-gray-300 absolute top-[0] left-[0] opacity-40 justify-center items-center">
														<div className="h-[3px] bg-gray-900 absolute w-full"></div>
													</div>
												) : null}
											</div>
											{cutcheck ? (
												<p
													className={`text-gray-600 font-semibold text-sm border border-gray-800 px-2 py-1 ml-2 rounded-full cursor-pointer line-through ${
														cutanswer[index - 1].markcut == i && "bg-gray-300"
													}`}
													onClick={() => {
														cutanswers(index, i);
													}}
												>
													{e.label}
												</p>
											) : null}
										</div>
									</li>
								</div>
							);
						})
					)}
				</div>
				{cal && <Calculator setCal={setCal}/>}
			</div>
		</>
	);
}
