import CalendarPermissions from "../../components/ProductSettings/CalendarPermissions/CalendarPermissions";
import Integrations from "../../components/ProductSettings/Integrations/Integrations";
import Permissions from "../../components/ProductSettings/Permissions/Permissions";
import ServicesTopics from "../../components/ProductSettings/ServicesTopics/ServicesTopics";
import SessionTags from "../../components/ProductSettings/SessionTags/SessionTags";
import UserManagement from "../../components/ProductSettings/UserManagement/UserManagement";


export default function SelectedTab ({selectedTab, currentPage,setSearchParams}) {
    switch(currentPage){
        case "crm" :
                switch (selectedTab) {
                    case 0 : return <UserManagement />;
                    case 1 : return <Permissions />;
                    default : return "Wrong selection";
                } ;break
        case "calendar" :
                switch(selectedTab) {
                    case 0 : return <SessionTags />
                    case 1 : return <CalendarPermissions />
					case 2 : return <Integrations />
                } ;break
    }
}

