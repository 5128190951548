import React from 'react'

const SupportIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29123_7613)">
                <path d="M8 0.5C4.20012 0.5 1.125 3.57512 1.125 7.375V10.875C1.125 11.9089 1.96613 12.75 3 12.75C4.03387 12.75 4.875 11.9089 4.875 10.875V8.625C4.875 7.59113 4.03387 6.75 3 6.75C2.79009 6.75 2.58837 6.78519 2.39978 6.84909C2.66584 3.99316 5.07572 1.75 8 1.75C10.9243 1.75 13.3342 3.99316 13.6002 6.84909C13.4116 6.78519 13.2099 6.75 13 6.75C11.9661 6.75 11.125 7.59113 11.125 8.625V10.875C11.125 11.9089 11.9661 12.75 13 12.75C13.1892 12.75 13.3719 12.7216 13.5442 12.6692C13.3104 13.4385 12.5947 14 11.75 14H9.7675C9.5095 13.2726 8.81478 12.75 8 12.75C6.96613 12.75 6.125 13.5911 6.125 14.625C6.125 15.6589 6.96613 16.5 8 16.5C8.81478 16.5 9.50947 15.9774 9.7675 15.25H11.75C13.4731 15.25 14.875 13.8481 14.875 12.125V7.375C14.875 3.57512 11.7999 0.5 8 0.5ZM3 8C3.34462 8 3.625 8.28038 3.625 8.625V10.875C3.625 11.2196 3.34462 11.5 3 11.5C2.65538 11.5 2.375 11.2196 2.375 10.875V8.625C2.375 8.28038 2.65538 8 3 8ZM8 15.25C7.65538 15.25 7.375 14.9696 7.375 14.625C7.375 14.2804 7.65538 14 8 14C8.34462 14 8.625 14.2804 8.625 14.625C8.625 14.9696 8.34462 15.25 8 15.25ZM13 11.5C12.6554 11.5 12.375 11.2196 12.375 10.875V8.625C12.375 8.28038 12.6554 8 13 8C13.3446 8 13.625 8.28038 13.625 8.625V10.875C13.625 11.2196 13.3446 11.5 13 11.5Z" fill="#515C7B" />
                <path d="M8 0.5C4.20012 0.5 1.125 3.57512 1.125 7.375V10.875C1.125 11.9089 1.96613 12.75 3 12.75C4.03387 12.75 4.875 11.9089 4.875 10.875V8.625C4.875 7.59113 4.03387 6.75 3 6.75C2.79009 6.75 2.58837 6.78519 2.39978 6.84909C2.66584 3.99316 5.07572 1.75 8 1.75C10.9243 1.75 13.3342 3.99316 13.6002 6.84909C13.4116 6.78519 13.2099 6.75 13 6.75C11.9661 6.75 11.125 7.59113 11.125 8.625V10.875C11.125 11.9089 11.9661 12.75 13 12.75C13.1892 12.75 13.3719 12.7216 13.5442 12.6692C13.3104 13.4385 12.5947 14 11.75 14H9.7675C9.5095 13.2726 8.81478 12.75 8 12.75C6.96613 12.75 6.125 13.5911 6.125 14.625C6.125 15.6589 6.96613 16.5 8 16.5C8.81478 16.5 9.50947 15.9774 9.7675 15.25H11.75C13.4731 15.25 14.875 13.8481 14.875 12.125V7.375C14.875 3.57512 11.7999 0.5 8 0.5ZM3 8C3.34462 8 3.625 8.28038 3.625 8.625V10.875C3.625 11.2196 3.34462 11.5 3 11.5C2.65538 11.5 2.375 11.2196 2.375 10.875V8.625C2.375 8.28038 2.65538 8 3 8ZM8 15.25C7.65538 15.25 7.375 14.9696 7.375 14.625C7.375 14.2804 7.65538 14 8 14C8.34462 14 8.625 14.2804 8.625 14.625C8.625 14.9696 8.34462 15.25 8 15.25ZM13 11.5C12.6554 11.5 12.375 11.2196 12.375 10.875V8.625C12.375 8.28038 12.6554 8 13 8C13.3446 8 13.625 8.28038 13.625 8.625V10.875C13.625 11.2196 13.3446 11.5 13 11.5Z" fill="black" fill-opacity="0.2" />
                <path d="M8 0.5C4.20012 0.5 1.125 3.57512 1.125 7.375V10.875C1.125 11.9089 1.96613 12.75 3 12.75C4.03387 12.75 4.875 11.9089 4.875 10.875V8.625C4.875 7.59113 4.03387 6.75 3 6.75C2.79009 6.75 2.58837 6.78519 2.39978 6.84909C2.66584 3.99316 5.07572 1.75 8 1.75C10.9243 1.75 13.3342 3.99316 13.6002 6.84909C13.4116 6.78519 13.2099 6.75 13 6.75C11.9661 6.75 11.125 7.59113 11.125 8.625V10.875C11.125 11.9089 11.9661 12.75 13 12.75C13.1892 12.75 13.3719 12.7216 13.5442 12.6692C13.3104 13.4385 12.5947 14 11.75 14H9.7675C9.5095 13.2726 8.81478 12.75 8 12.75C6.96613 12.75 6.125 13.5911 6.125 14.625C6.125 15.6589 6.96613 16.5 8 16.5C8.81478 16.5 9.50947 15.9774 9.7675 15.25H11.75C13.4731 15.25 14.875 13.8481 14.875 12.125V7.375C14.875 3.57512 11.7999 0.5 8 0.5ZM3 8C3.34462 8 3.625 8.28038 3.625 8.625V10.875C3.625 11.2196 3.34462 11.5 3 11.5C2.65538 11.5 2.375 11.2196 2.375 10.875V8.625C2.375 8.28038 2.65538 8 3 8ZM8 15.25C7.65538 15.25 7.375 14.9696 7.375 14.625C7.375 14.2804 7.65538 14 8 14C8.34462 14 8.625 14.2804 8.625 14.625C8.625 14.9696 8.34462 15.25 8 15.25ZM13 11.5C12.6554 11.5 12.375 11.2196 12.375 10.875V8.625C12.375 8.28038 12.6554 8 13 8C13.3446 8 13.625 8.28038 13.625 8.625V10.875C13.625 11.2196 13.3446 11.5 13 11.5Z" fill="black" fill-opacity="0.2" />
                <path d="M8 0.5C4.20012 0.5 1.125 3.57512 1.125 7.375V10.875C1.125 11.9089 1.96613 12.75 3 12.75C4.03387 12.75 4.875 11.9089 4.875 10.875V8.625C4.875 7.59113 4.03387 6.75 3 6.75C2.79009 6.75 2.58837 6.78519 2.39978 6.84909C2.66584 3.99316 5.07572 1.75 8 1.75C10.9243 1.75 13.3342 3.99316 13.6002 6.84909C13.4116 6.78519 13.2099 6.75 13 6.75C11.9661 6.75 11.125 7.59113 11.125 8.625V10.875C11.125 11.9089 11.9661 12.75 13 12.75C13.1892 12.75 13.3719 12.7216 13.5442 12.6692C13.3104 13.4385 12.5947 14 11.75 14H9.7675C9.5095 13.2726 8.81478 12.75 8 12.75C6.96613 12.75 6.125 13.5911 6.125 14.625C6.125 15.6589 6.96613 16.5 8 16.5C8.81478 16.5 9.50947 15.9774 9.7675 15.25H11.75C13.4731 15.25 14.875 13.8481 14.875 12.125V7.375C14.875 3.57512 11.7999 0.5 8 0.5ZM3 8C3.34462 8 3.625 8.28038 3.625 8.625V10.875C3.625 11.2196 3.34462 11.5 3 11.5C2.65538 11.5 2.375 11.2196 2.375 10.875V8.625C2.375 8.28038 2.65538 8 3 8ZM8 15.25C7.65538 15.25 7.375 14.9696 7.375 14.625C7.375 14.2804 7.65538 14 8 14C8.34462 14 8.625 14.2804 8.625 14.625C8.625 14.9696 8.34462 15.25 8 15.25ZM13 11.5C12.6554 11.5 12.375 11.2196 12.375 10.875V8.625C12.375 8.28038 12.6554 8 13 8C13.3446 8 13.625 8.28038 13.625 8.625V10.875C13.625 11.2196 13.3446 11.5 13 11.5Z" fill="black" fill-opacity="0.2" />
            </g>
            <defs>
                <clipPath id="clip0_29123_7613">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default SupportIcon