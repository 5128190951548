import React, { useEffect } from "react";
// import CrossIcon from "../../assets/Modal/cross.svg";
// import primaryCancelIcon from "../../assets/icons/Groupcancel bt.svg";
import styles from "./modal.module.css";
import cancel_btn from "../../assets/icons/cancelBtn.svg";

import { useRef } from "react";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
// import SecondaryButton from "../Buttons/SecondaryButton";
import Loader from "../Loader";
import CancelBtn from '../../assets/icons/cancel_btn_red.svg'
import SecondaryButton2 from "../Buttons/SecondaryButton2";

export default function Modal({
  title,
  closeIconClass,
  titleClassName,
  modalSize,
  canceliconvisible,
  title_p_class,
  titleclass,
  body,
  cancelBtn,
  cancelBtnClassName,
  primaryBtn,
  titleInvite,
  handleClose,
  hiddenCancelBtn,
  addheight,
  CrossIcon,
  classname,
  SaveUser,
  primaryCancel,
  otherBt,
  cancelBtnStyle,
  underline,
  crossBtn,
  crossBtn2,
  toEdit,
  alignBtn,
  wrapperClassName,
  btnClassName,
  buttonParentClassName,
  cancelIconState,
  parentClass,
  titlebox,
  topClass,
  crossIconDisplay,
  closebuttonClass,
  lineMB,
  underlineMargin,
  showcloseIcon,
  crossBtnClassName,
  bodyScrollbarHidden,
  notscrolltotop,
  disableCloseOnBgClick,
  contentModal,
  customCrossBtnSrc,
  crossBtnClassName2,
  studentSettings,
  changeButtonPosition
}) {
  const selectRef = useRef();
  const bodyRef = useRef();
  // console.log(selectRef)
  useOutsideAlerter(selectRef, () => {
    document.body.style.overflow = "unset";
    selectRef.current = null;
    if (handleClose && !disableCloseOnBgClick) {
      handleClose();
    }
  });

  useEffect(() => {
    if (notscrolltotop !== true) {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: Use smooth scrolling behavior
      });
    }
    if (bodyScrollbarHidden) {
      document.body.style.overflow = "hidden";
    }

    else {
      document.body.style.overflow = "unset";
    };
    if (addheight) {
      var element = document.getElementById('bodyy');
      const va = document.getElementById('bodyy').style.transform

      const scaleValue = parseFloat(va.match(/scale\(([^)]+)\)/)[1]);

      var h = window.innerHeight / scaleValue
      console.log(va, window.innerHeight, `${h}px`, 'asca');
      element.style.height = `${h}px`;
    }
    if (notscrolltotop) {
      setTimeout(() => {
        const va = document.getElementById('bodyy').style.transform
        const scaleValue = parseFloat(va.match(/scale\(([^)]+)\)/)[1]);
        var element = document.getElementById('bodyy');
        var rect = (element.getBoundingClientRect().top / scaleValue < 0 ? ((element.getBoundingClientRect().top / scaleValue * -1)) + 'px' : '0px')
        var mainelement = document.querySelector('.bosyy')
        mainelement.style.top = rect;
        mainelement.style.display = 'unset'
        console.log('body', rect, mainelement)

        if (bodyRef?.current && notscrolltotop !== true) {
          var element = bodyRef?.current;
          var headerOffset = -150;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.scrollY + headerOffset;
          console.log("elementPosition", elementPosition);

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
      }, 400);
    }

    console.log('bodyss', notscrolltotop)
    return () => {
      document.body.style.overflow = "unset";
    }
  }, [bodyScrollbarHidden]);


  const handleClick = (e) =>{
    console.log("CLICKED MODAL",e.target.id)
    if(e.target.id === "outerContainer"){
      handleClose()
    }
  }


  return (
    <>
      {
        <div className={`${styles.modalContainer}   ${topClass ?? ""}`} id="outerContainer" onClick={(e)=>handleClick(e)}>
          <div className={`bosyy min-h-[100vh] ${notscrolltotop == true ? 'relative hidden' : null} ${parentClass ?? ""} ` } ref={bodyRef} id="innerContainer">
            <div
              ref={handleClose ? selectRef : null}
              className={` ${modalSize ? modalSize : "w-full"
                } bg-white pt-[28px] pb-[33.34px] px-[33.33px]  rounded-lg relative ${classname ? classname : ""
                }`}
            >
              {/* cancel buttons */}
              {showcloseIcon && <div
                onClick={handleClose}
                className={`absolute top-[30px] right-[50px] cursor-pointer ${crossBtn2 === true ? "hidden" : ""
                  }
                            ${crossBtnClassName}
                `}
              ><span className="cursor-pointer ">
                  <img src={cancel_btn} alt="cancel_btn" />
                </span>

              </div>

              }
              {/* title */}
              <div className={wrapperClassName ? wrapperClassName : ""}>
                <p
                  className={`font-semibold text-xl text-[21px] text-left text-[#26435F] 
               ${titleClassName ? titleClassName : "mb-[18px]"}  ${studentSettings ? 'mb-0' : ' '} `}
                >
                  <div className={`flex justify-between items-center ${titlebox}`}>
                    <p className={`${title === "Bulk Upload" ? 'w-full ' : ''} ${titleclass} ${title_p_class}`}>{title}</p>
                    {canceliconvisible === false ? null :
                      <img src={customCrossBtnSrc ? customCrossBtnSrc : CancelBtn} className={`w-[28px] h-[28px] mb-2 cursor-pointer ${crossBtnClassName2}`} onClick={handleClose} />
                    }

                  </div>
                  {titleInvite && "Are You Sure You Want to Invite "}
                  {titleInvite && (
                    <span className="text-[#FFA28D]">{titleInvite}</span>
                  )}

                  {titleInvite && " Users To Join Evallo?"}
                </p>


                {/*  title underline */}
                {underline ? (
                  ""
                ) : (
                  <div
                    className={`h-[1.33px] w-full bg-[rgba(0,0,0,0.20)] mb-[20px] ${underlineMargin ? underlineMargin : "mb-[20px]"
                      }`}
                  ></div>
                )}

                {/*  modal body */}
                <div className="w-full">{body}</div>

                {/* cancel */}
                <div className={`flex ${changeButtonPosition ? "flex-row-reverse gap-[27px]":""} justify-center mt-5 ${buttonParentClassName}`} onClick={(e)=>handleClick(e)} id="modalContainer">
                  {cancelBtn && (

                    <SecondaryButton2
                      onClick={handleClose}
                      children="Cancel"
                      className={`${cancelBtnClassName} rounded-5 text-[#26435F] font-[500]`}
                      type="button"
                    />
                  )}
                  {SaveUser && SaveUser}
                  {otherBt ? otherBt : <></>}
                  {primaryBtn && (
                    <button
                      onClick={primaryBtn.onClick ? primaryBtn.onClick : null}
                      form={primaryBtn.form ? primaryBtn.form : null}
                      type={primaryBtn.type ? primaryBtn.type : "button"}
                      className={`w-[120px ${primaryBtn.bgDanger ? "bg-[#FFA28D]" : "bg-[#FFA28D]"
                        } relative disabled:opacity-75 rounded-lg font-medium text-white cursor-pointer   ${primaryBtn.className ? primaryBtn.className : ""
                        }`}
                      disabled={
                        primaryBtn?.loading === true
                          ? true
                          : primaryBtn.disabled
                      }
                      loading={primaryBtn.loading}
                    >
                      <div className="flex w-full items-center  gap-2 justify-center">
                        <p className={`text-center pt-3 pb-3 pr-1 ${contentModal && 'flex items-center'}`}>{primaryBtn.text}</p>
                        {primaryBtn.icon && (
                          <p className={`${contentModal && ' flex items-center bg-transparent border-none box-content drop-shadow-none shadow-none mt-0'}`}>
                            {primaryBtn.icon && primaryBtn?.loading === false
                              ? primaryBtn.icon
                              : ""}
                          </p>
                        )}
                      </div>

                      {primaryBtn !== undefined &&
                        primaryBtn?.loading === true && <Loader />}
                    </button>
                  )}
                </div>

                {/* {crossBtn ? (
                  ""
                ) : (
                  <button
                    className={`absolute top-0 right-0 py-[28px] mr-[36px]`}
                    style={cancelBtnStyle}
                    onClick={handleClose}
                  >
                    <div className="w-full h-full flex justify-center items-center relative ">
                      <img
                        className={`${cancelIconState ? "block" : "hidden"}`}
                        src={
                          primaryCancel ? primaryCancelIcon : primaryCancelIcon
                        }
                        alt="close-btn"
                        onClick={handleClose}
                      />
                    </div>
                  </button>
                )} */}
              </div>
            </div>
            {/* <div className={styles.modalOverlay}></div> */}
          </div>
        </div>
      }
    </>
  );
}