
import styles from './UpdateSubConfirmPopUp.module.css'

const UpdateSubConfirmPopUp = (props) => {
    return(
        <>
        <div className={styles.container}>
            <div className={styles.title}>Confirm your Changes</div>
            <div className='flex flex-col w-full items-center justify-start gap-[16px]'>
                <div className={styles.subtitle}>Go Ahead With The Updates?</div>
                <div className={styles.content}>Are you sure you want to proceed with changes? <br /> Your saved payment method will be charged in case of a plan upgrade."</div>
            </div>
            <div className='flex w-full items-center justify-center gap-[12px]'>
                <button className={styles.goBackButton} onClick={(e)=>props.setPopUpTriger(false)}>Go Back</button>
                <button className={styles.continueButton} onClick={(e)=>props.handleContinueClick(e)}>
                    Continue
                </button>
            </div>
        </div>
        </>
    )
}

export default UpdateSubConfirmPopUp