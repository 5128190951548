import { useEffect, useState, useRef } from "react";
import styles from "./CompletionIndicator.module.css";

const CompletionIndicator = ({
  height,
  width,
  innerStrokeWidth = "8px",
  outerStrokeWidth = "3px",
  outerCircleColor,
  innerCircleColor,
  percentage,
  helperText,
  mainText,
}) => {
  const [radius, setRadius] = useState();
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setRadius(ref.current.r.baseVal.value);
    }
  }, []);
  return (
    <div className={styles.container}>
      <svg
        width={height}
        height={width}
        viewBox="0 0 100 120"
        fill="red"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50%"
          cy="50%"
          r="50%"
          fill="none"
          stroke={outerCircleColor}
          strokeWidth={outerStrokeWidth}
        />
        <circle
          cx="50%"
          cy="50%"
          r="48%"
          fill="none"
          strokeLinecap="butt"
          strokeWidth={innerStrokeWidth}
          strokeDasharray={`${(percentage * (Math.PI * radius * 2)) / 100} ${
            Math.PI * radius * 2
          }`}
          stroke={innerCircleColor}
          transform="rotate(90 50 60) scale(-1 1) translate(-100 0)"
          ref={ref}
        />
      </svg>
      <div className={styles.textContainer}>
        <div className={styles.mainText}>{mainText}</div>
        <div className={styles.helperText}>{helperText}</div>
      </div>
    </div>
  );
};

export default CompletionIndicator;
