import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import frozenEventStatus from "../../../utils/eventStatus"
import frozenEventTypes from "../../../utils/eventTypes";


dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

const COMPATIBLE_REP_FREQ_MAPPING = {
	"daily" : "Daily",
	"weekly" : "Weekly",
	"biWeekly" : "Bi-weekly",
	"bi-weekly" : "Bi-weekly",
	"monthly" : "Monthly",
	"default" : "Weekly"
}


function setEventData(eventData,setters){


	console.log(">>>ED",eventData)
	
	//Set date & times
	const date = dayjs(eventData.date).tz(eventData.timeZone)
	const startTime = [eventData.time.start.time, eventData.time.start.timeType].join(" ")
	const endTime = [eventData.time.end.time, eventData.time.end.timeType].join(" ")
	const timeZone = eventData.timeZone
	
	//Setting the states
	setters.setSelectedDate(date)
	setters.setSelectedStartTime(startTime)
	setters.setSelectedEndTime(endTime)
	setters.setSelectedTimeZone(timeZone)
	if(eventData.recurring) { 
		setters.setEndDate(dayjs(eventData.endDate).tz(eventData.timeZone))
		setters.setIsRecurring(eventData.recurring)
		setters.setFrequency(COMPATIBLE_REP_FREQ_MAPPING[eventData.repeatFrequency ?? "default"])
		switch(COMPATIBLE_REP_FREQ_MAPPING[eventData.repeatFrequency ?? "default"]){
			case COMPATIBLE_REP_FREQ_MAPPING.daily :
				setters.setFrequency(COMPATIBLE_REP_FREQ_MAPPING.daily);
				break;
			case COMPATIBLE_REP_FREQ_MAPPING["bi-weekly"]:
			case COMPATIBLE_REP_FREQ_MAPPING.biWeekly : 
			case COMPATIBLE_REP_FREQ_MAPPING.default : 
			case COMPATIBLE_REP_FREQ_MAPPING.weekly : 
				console.log("DAYS_TEST",eventData.day, eventData.requiredDays)
				const days = eventData.day?.length > 0 ? eventData.day : eventData.requiredDays.map(val=>val[0].toUpperCase() + val.slice(1))
				console.log("DAYS_DG",days)
				setters.setSelectedDay(days);
				break;
			case COMPATIBLE_REP_FREQ_MAPPING.monthly:
				setters.setSelectedMonthlyRecurringDate(eventData.selectedDate)
				break;		
		}
		
	}

	//Setting rescheduled & partial session checkboxes
	if(eventData.resheduling){
		setters.setIsRescheduled(true)	
	}
	if(eventData.partialSession){
		setters.setIsPartialSession(true)
	}

	//Reminder state setting
	if(eventData.reminder){
		const reminderDuration = Math.round(eventData.reminder.timeDelta / (1000 *3600))
		if(eventData.reminder.isEnabled === false){
			setters.setIsRemainder("None")
		} else {
			switch(reminderDuration) {
				case 0 : setters.setIsRemainder("30 minutes"); break;
				default : setters.setIsRemainder(`${reminderDuration} hour${reminderDuration > 1 ? "s" : ""}`);break;
			}
		}
		const reminderDetails = {}
		const requiredUserTypes = [
				"student",
				"parent",
				"tutor",
				"attendees"
		]
		requiredUserTypes.forEach((userType)=>{
			reminderDetails[userType] = eventData.reminder.targetUserTypes.includes(userType)
		})
		setters.setSendRemindersTo(reminderDetails)
	}

	//Set attendees & students
	if(eventData.studentIds){
		setters.setSelectedStudents(eventData.studentIds.map(student=>({...student, name : [student.firstName, student.lastName].join(" ")})))
	}
	if(eventData.tutorId){
		setters.setSelectedTutor(eventData.tutorId)
	}
	if(eventData.attendeeDetails){
		setters.setIsSelectedUser(eventData.attendeeDetails.map(val=>val.id))
	}
	if(eventData.studentId){
		setters.setSelectedStudents([{_id : eventData.studentId, name : eventData.studentName}])
	}
	if(eventData.sessionFor){
		setters.setSelectedOption(eventData.sessionFor === "students" ? "student" : "studentGroup")
		if(eventData.sessionFor === "group") {
			setters.setSelectedGroup(eventData.groupId)
			setters.setSelectedGroupStudents(eventData.studentIds)
		}
	}else {
		if(eventData.groupId){
			setters.setSelectedOption("studentGroup")
			setters.setSelectedGroupStudents(eventData.studentIds.map(student=>({...student, name : [student.firstName, student.lastName].join(" ")})))
		} else {
			setters.setSelectedOption("student")
		}
	}

	//Set services & topics & location & meeting link & whiteboarding link & sessionTags
	if(eventData.location){
		setters.setLocation(eventData.location)
	}
	if(eventData.service){
		setters.setService(eventData.service)
	}
	if(eventData.topic){
		setters.setTopic(eventData.topic)
	}
	if(eventData.meetingLinkDetails){
		setters.setMeetingLink(eventData.meetingLinkDetails.link)
	}
	if(eventData.whiteboardLink){
		setters.setWhiteboardLink(eventData.whiteboardLink)
	}
	if(eventData.sessionTags){
		setters.setSessionTags(eventData.sessionTags)
	}
	if(eventData.additionalInfo){
		setters.setInfoWork(eventData.additionalInfo)
	}

	//Setters for student status mapping & attendee status mapping TODO
	if(eventData.studentStatusMapping && eventData.studentStatusMapping.length > 0) {
		const tempStudentStatusMapping = {}
		eventData.studentStatusMapping.forEach((statusMapping, idx)=>{
			tempStudentStatusMapping[statusMapping.id] = statusMapping.status?.toLowerCase().includes("cancel") ? "cancelled" : (statusMapping.status?.toLowerCase().includes("no") && statusMapping.status?.toLowerCase().includes("show") ? "no-show" : "none")
		})
		setters.setStudentStatusMapping(tempStudentStatusMapping)
	}

	//Attendee Status Mapping
	if(eventData.attendeeDetails && eventData.attendeeDetails.length > 0) {
		const tempAttendeeStatusMapping = {}
		eventData.attendeeDetails.forEach((statusMapping, idx)=>{
			tempAttendeeStatusMapping[statusMapping.id] = statusMapping.status?.toLowerCase().includes("no") ?"no" : statusMapping.status?.toLowerCase().includes("yes")? "yes": statusMapping.status?.toLowerCase().includes("maybe")? "maybe":"pending"
		})
		setters.setAttendeeStatusMapping(tempAttendeeStatusMapping)
	}
	
	//Setters for session status, notes, hourly cost, session cost.
	if(eventData.sessionStatus && typeof(eventData.sessionStatus) === "string"){
		const tempSessionStatus = eventData.sessionStatus.toLowerCase()
		if(tempSessionStatus.includes("reschedule") || tempSessionStatus.includes("rsch")){
			setters.setSelectedStatus("RESCH")
		} else if(tempSessionStatus.includes("schedule") || tempSessionStatus.includes("sch")){
			setters.setSelectedStatus("SCH")
		} else if(tempSessionStatus.includes("complete")  ){
			setters.setSelectedStatus("COMPLETED")
		} else if(tempSessionStatus.includes("miss") || (tempSessionStatus.includes("no") && tempSessionStatus.includes("show"))){
			setters.setSelectedStatus("NOSHOW")
		} else if(tempSessionStatus.includes("cancel")){
			setters.setSelectedStatus("CANCEL")
		} else if(tempSessionStatus.includes("tentative")){
			setters.setSelectedStatus("TENTATIVE")
		}
	}
	if(eventData.agendaNotes || eventData.clientNotes || eventData.internalNotes){
		setters.setSessionNotes({
			clientNotes : eventData.clientNotes,
			internalNotes : eventData.internalNotes
		})
		setters.setAgendaNotes(eventData.agendaNotes)
	}
	if(eventData.studentNotes){
		const tempObj = {}
		eventData.studentNotes.forEach(element => {
			tempObj[element.id] = element.notes
		});
		setters.setStudentNotes(tempObj)
	}
	if(eventData.invoiceDetails){
		setters.setHourlyCost(eventData.invoiceDetails.hourlyPrice)
		setters.setSessionCost(eventData.invoiceDetails.tutorCost)
	}

	


}


function scheduleMapperSetter(eventData,setters){

	eventData.setSelectedStartTime(setters.scheduleSubmitData?.startTime);
	eventData.setSelectedEndTime(setters.scheduleSubmitData?.endTime);
	eventData.setSelectedTimeZone(setters.scheduleSubmitData?.timeZone);

	console.log(eventData.selectedDate ,  dayjs(setters.scheduleSubmitData?.startDate));
	eventData.setSelectedDate(dayjs(setters.scheduleSubmitData?.startDate));

	if(setters.scheduleSubmitData?.selectedMembers?.length > 1 && setters.scheduleSubmitData?.selectedStudents?.length === 0){
		setters.setSelectedEventType(frozenEventTypes.INTMEETING.eventType)
		eventData.setIsSelectedUser(setters.scheduleSubmitData?.selectedMembers)
	}
	else{
		setters.setSelectedEventType(frozenEventTypes.SESSION.eventType)
		let tempData= [];

		setters.scheduleSubmitData.selectedStudents?.forEach((id)=>{
			const studentData = setters.studentList && setters.studentList?.find((stu)=>stu._id === id);
			if(studentData) tempData.push(studentData);

		});
		eventData.setSelectedStudents(tempData);
		eventData.setSelectedTutor(setters.scheduleSubmitData?.selectedMembers.length> 0 && setters.scheduleSubmitData?.selectedMembers[0]);
	}
	
	setters.setScheduleSubmitData({
		startDate: new Date(),
		endDate :new Date(),
		startTime:"",
		endTime:"",
		timeZone:"",
		selectedMembers:[],
		selectedStudents:[],
	});
	setters.setMembersData({available:[], unavailable:[]});
	setters.setStudentsData({available:[], unavailable:[]});
	setters.setIsScheduleTrigger(false);


}



 async function studentParentEventSetter(eventData, setters){


	if(eventData.user?.role === "student"){
		const studentFind = eventData?.studentList && eventData?.studentList?.find((student)=> student._id === eventData?.user?.id); 
		if(studentFind) setters?.setSelectedStudents([studentFind]);

		if(studentFind?.associatedTutors?.length > 0){

			let tempTeachers = [];

			studentFind?.associatedTutors?.forEach((tutorId)=>{
				const tutorFind = eventData?.tutorList && eventData?.tutorList.find((tutor)=> tutor._id == tutorId);
				if(tutorFind) tempTeachers.push(tutorFind);
			})
			setters?.setCustomTutorList(tempTeachers);
		}

		setters?.setIsSelectedUser([studentFind?._id])
	}

	if(eventData.user?.role === "parent"){
		setters?.setParentStudentList(eventData?.studentList)

		const tempTutors = new Set();

		eventData?.studentList?.forEach((student) => {
		student?.associatedTutors.forEach((tutorId) => {
			const tutorFind = eventData?.allUserList?.find((user) => user._id === tutorId);
			if (tutorFind) tempTutors.add(tutorFind);
		});
		});

		setters?.setCustomTutorList(Array.from(tempTutors));
		setters?.setAttendeeList(eventData?.studentList)
	}
}


export {
	setEventData,
	scheduleMapperSetter,
	studentParentEventSetter
}
