import React, { useState } from "react";

const IconCompBinding = ({ component: Component, icon: Icon, _props, height, flexItem }) => {
  const [active, setActive] = useState(false);


  console.log("HDDD",{alignItems : flexItem})

  return (
    <main className="w-full flex items-start justify-between gap-[12px] md:gap-[24px]" style={{...(flexItem) && {alignItems : flexItem}}}>

      <div className="h-[36px] flex items-center justify-center" style={{...(height) && {height : height } }}>
        <Icon width="24" height="24" inherit={active ? "#0671E0" : null} />
      </div>

      <section className="w-full flex-1" style={{...(height) && {height : height } }}>
        <Component setActive={setActive}  {..._props} />
      </section>
    </main>
  );
};

export default IconCompBinding;
