import { useEffect, useMemo, useRef, useState } from "react"
import { EvChevronDownIcon, EvChevronUpIcon } from "../../assets/icons/ChevronIcons"
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import AddNewIcon from "../CalendarSideIcons/AddNewIcon"
import MoreInfoIcon from "../MoreInfoIcon/MoreInfoIcon"
import TabSelector from "../TabSelector/TabSelector"
import styles from "./NotesEditor.module.css"


const internalUsers = ["admin","tutor"]
const externalUsers = ["student","parent"]


function ProfilePhoto({link}){
	return(
		<img src={link} className="h-[24px] w-[24px] object-cover rounded-full"/>
	)
}

function NamePlaceholder({firstName, lastName, bgColor}){
	return (
		<div className="min-h-[24px] min-w-[24px] rounded-full flex items-center justify-center text-white font-[400] text-[12px] leading-[12px] uppercase" style={{backgroundColor : bgColor}} >
			{firstName[0]}	
		</div>
	)
}

function PersonalInfoButton({student, onClick}){

	return (
		<button className="w-full max-w-full flex items-center justify-start gap-[8px] py-[4px] px-[4px] hover:bg-secondary-5 hover:text-secondary overflow-break" onClick={onClick}>
			{student.photo ? <ProfilePhoto link={student.awsLink + student.photo}/> : <NamePlaceholder {...student} />}		
			<div className="flex flex-col items-start justify-start gap-[2px]">
				<div className="text-[12px] leading-[12px] font-[400] text-secondary-80 text-left">
					{student.name}
				</div>
				<div className="text-[10px] leading-[10px] font-[400] text-secondary-60 text-left break-all">
					{student.email}
				</div>
			</div>
		</button>
	)

}


function StudentSelectorForNotes({selectedStudents, currentStudent, setCurrentStudent, user, studentList,selectedGroupStudents, selectedOption }){

	const ddRef = useRef()
	const [isDDOpen , setIsDDOpen] = useState(false)

	useOutsideAlerter(ddRef, ()=>{
		setIsDDOpen(false)
	})

	return (
		<div className="cursor-pointer w-full flex items-center justify-end gap-[6px]">
			<MoreInfoIcon />
			<div className="relative w-[180px]" ref={ddRef}>
				<div className="flex items-center justify-between rounded-[6px] px-[12px] py-[5px] border-[1px] border-solid border-secondary-10 text-secondary-80 font-[400] text-[12px] leading-[18px]" onClick={()=>setIsDDOpen(prev => !prev)}>
					{
						currentStudent === "Everyone" ? 
							"Everyone"
							: 
							currentStudent?.name
					}
					{
						isDDOpen ? <EvChevronUpIcon /> : <EvChevronDownIcon />
					}
				</div>
				{
					isDDOpen &&
						<div className="absolute top-[calc(100%+10px)] w-full left-[0px] bg-white z-[10] max-h-[300px] rounded-[6px] shadow-[0px_0px_12px_0px_#11167D14] flex flex-col items-start justify-start gap-[6px] py-[4px] overflow-y-scroll">
							<button className="text-left w-full px-[4px] py-[4px] text-secondary-80 hover:bg-secondary-5"  onClick={()=>setCurrentStudent("Everyone")}> Everyone </button>
							{
								selectedOption === "student" && selectedStudents.map(student=>(
									<PersonalInfoButton 
									{...{ onClick : (e)=>{ setCurrentStudent(student); setIsDDOpen(false)}}} 
									{...{student : {...student, ...studentList.find(val=>val._id === student._id)}}} />
								))
							}
							{
								selectedOption=== "studentGroup" && selectedGroupStudents.map(student=>(
									<PersonalInfoButton 
									{...{ onClick : (e)=>{ setCurrentStudent(student); setIsDDOpen(false)}}} 
									{...{student : {...student, ...studentList.find(val=>val._id === student._id)}}} />
								))
							}
						</div>
				}
			</div>
		</div>
	)

}

function NotesEditor({sessionNotes : tempSessionNotes, setSessionNotes : setTempSessionNotes, alwaysShowNotes = false, user, selectedStudents, setStudentNotes, studentNotes, selectedEventType, studentList, selectedGroupStudents, setSelectedGroupStudents, selectedOption}){
	
	const [showEditor, setShowEditor] = useState(false)
	const [selectedTab, setSelectedTab]	= useState(0)

	const [currentStudent, setCurrentStudent] = useState("Everyone")
	
	const MAX_WORD_COUNT_NOTES = 500

	const items = useMemo(()=>{
	
		if(externalUsers.includes(user?.role)){
			return ["Notes"]
		} else if(internalUsers.includes(user?.role)){
			return [
				"Internal Notes",
				"Shared Notes"
			]
		}

	},[user?.role])

	const showStudentSelector = useMemo(()=>{
		return internalUsers.includes(user?.role) && (selectedEventType?.toLowerCase() ===  "session")
	},[user?.role])

	const onTabClick = (idx) =>{
		setSelectedTab(idx)	
	}


	useEffect(()=>{
		const tempStudenNotes = {...studentNotes}
		const newStudentNotes = {}
		if(selectedOption === "student"){
			for(const student of selectedStudents){
				if(!tempStudenNotes[student._id]){
					newStudentNotes[student._id] = ""
				} else{
					newStudentNotes[student._id] = tempStudenNotes[student._id]
				}
			}
		}else if(selectedOption === "studentGroup"){
			for(const student of selectedGroupStudents){
				if(!tempStudenNotes[student._id]){
					newStudentNotes[student._id] = ""
				} else{
					newStudentNotes[student._id] = tempStudenNotes[student._id]
				}
			}
		}
		setStudentNotes(newStudentNotes)
	},[selectedStudents, selectedGroupStudents])


	function handleOnChangeStudentNotes(e, student){
		if (e.target.value && e.target.value.split(/\s+/).length <= MAX_WORD_COUNT_NOTES) {
			setStudentNotes(prev=>({...prev, [student._id] : e.target.value}))	
		}
	}

	useEffect(()=>{
		console.log("ST",studentNotes)
	},[studentNotes])

	useEffect(()=>{
		if((tempSessionNotes && (tempSessionNotes.clientNotes?.note || tempSessionNotes.internalNotes?.note)) || externalUsers.includes(user?.role) ){
			setShowEditor(true)
		}
	},[tempSessionNotes])

	if(showEditor || alwaysShowNotes){
		return (
			<>
			<div className="w-full flex items-center justify-between">
					<TabSelector 
						items={items} 
						onClick={onTabClick} 
						selectedIdx={selectedTab} 
						showDividerPill={false}
						customClass="!bg-white !border-b-secondary-10 !h-[28px] !p-[0px]"
						tabItemSelectedClass={"!text-secondary !font-[500] !text-[14px] !leading-[21px] !font-[lexend]"}
						tabItemClass={"!text-secondary-70 !font-[400] !text-[14px] !leading-[21px] !font-[lexend]"}

					/>
					{
						showStudentSelector && (selectedTab === 1) && 
							<StudentSelectorForNotes selectedStudents={selectedStudents} currentStudent={currentStudent} setCurrentStudent={setCurrentStudent} studentList={studentList} selectedGroupStudents={selectedGroupStudents} selectedOption={selectedOption}/>
					}
			</div>
	

				{
					externalUsers.includes(user?.role)  &&
					<>
					<p className={styles.studentNote}>{tempSessionNotes?.clientNotes?.note}</p>
					{
							user?.role === "student" && 
								<>{
									studentNotes[user?.id] ? 
										<div className="flex flex-col items-start justify-start gap-[12px]">
											<div className="font-[500] text-[12px] leading-[18px] text-secondary">
												Personal Notes :
											</div>
											{studentNotes[user?.id]}
										</div>
										: <></>
								}
								</>
					}
					{
						user?.role === "parent" &&
						<>
							{
								selectedStudents.filter(student=> user?.associatedStudents.includes(student._id) && Object.keys(studentNotes).includes(student._id)).map(student=>(
										<div className="flex flex-col items-start justify-start gap-[12px]">
											<div className="font-[500] text-[12px] leading-[18px] text-secondary">
												Personal Notes for {student.name}:
											</div>
											{studentNotes[student._id]}
										</div>
								))
							}
						</>
					}
					</>
				}

				{
					internalUsers.includes(user?.role) && 
						<div className={styles.descriptionContainer}>
							<textarea 
								placeholder={selectedTab === 0 ? "Session notes added here will ONLY be visible to tutors and admins, but NOT to parents and students. If you want to add notes for everyone, please use Shared Notes." : "Session notes that are added in this box will be visible to clients (parents and students). If you want to add notes that are hidden from them, please use Internal Notes."}

								
								value={selectedTab === 0 ? 
										tempSessionNotes.internalNotes.note : 
										currentStudent === "Everyone" ? 
											tempSessionNotes.clientNotes.note : 
											studentNotes[currentStudent._id]
									} 

								onChange={
									(e)=>{
									if((selectedTab === 0) || (selectedTab === 1 && currentStudent === "Everyone")){
										setTempSessionNotes((prev)=>{
											return ({...prev,  ...( (!e.target.value) || (e.target.value && e.target.value.split(/\s+/).length <= MAX_WORD_COUNT_NOTES)) && { [selectedTab === 0 ? "internalNotes" : "clientNotes"] :   {note : e.target.value, date : new Date()}} })															
										})
									} else {
										handleOnChangeStudentNotes(e,currentStudent)
									}
								}
			
								}
							/>
							<span className={styles.wordCount}>{`${(tempSessionNotes[selectedTab === 0 ? "internalNotes" : "clientNotes"].note) ? (MAX_WORD_COUNT_NOTES -  tempSessionNotes[selectedTab === 0 ? "internalNotes" : "clientNotes"].note.split(/\s+/).length) : "500"} Words Remaining`}</span>
						</div>
				}

			</>
		)
	}else {

		return (
			<button className='font-[lexend] flex items-center justify-start text-blue-70 gap-[4px] font-[400] text-[14px] leading-[14px]' onClick={()=>setShowEditor(true)}>
				<AddNewIcon /><span>Add Session Notes</span>	
			</button>
		)
	}
	
}


export default NotesEditor
