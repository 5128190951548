import React, { useEffect ,useRef } from "react";
import { useSelector } from "react-redux";

export default function Footer({myRef,scaleState}) {
  const footer = useRef()
  const { role: persona } = useSelector((state) => state.user);
  return (
    <div style={{transformOrigin:"bottom left"}} ref={myRef} className="">
      {
        persona !== 'tutor' ? (
          <div className="h-[0px] w-[1920px] bg-transparent" >

        </div>
        ) : (<div className="h-[0px] w-[1920px] bg-transparent" >

        </div>)
      }
    <footer style={{bottom:`-${((1-scaleState)*67)}px`}} className={`bg-red-500 text-transparent pt-[0px pb-[0px] w-[1920px] bottom-[0] absolute design:ml-[calc(50vw-960px)] pl-[92px] pr-[54px] !h-[0px] `} ref={footer}>
      <div className="flex text-[16px] leading-[19.2px] font-medium justify-between">
        <p className="">
        </p>
        <div className="flex mr-[45px] gap-[47px] text-[16px] leading-[19.2px]">
          <a href="http://evallo.org/tou" className="text-[#FFFFFF] hover:text-[#FFFFFF] pointer-events-none"></a>
          <a href="http://evallo.org/privacy-policy" className="text-[#FFFFFF] hover:text-[#FFFFFF] pointer-events-none">

          </a>
        </div>
      </div>
    </footer>
    </div>
  );
}
