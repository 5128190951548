export default function AssignmentIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_32082_45891)">
        <path
          d="M7.5625 15.375C7.5625 15.7202 7.28271 16 6.9375 16H3.34375C1.96521 16 0.84375 14.8785 0.84375 13.5V2.5C0.84375 1.12146 1.96521 0 3.34375 0H11.0278C12.4062 0 13.5278 1.12146 13.5278 2.5V9.125C13.5278 9.47021 13.2479 9.75 12.9028 9.75C12.5576 9.75 12.2778 9.47021 12.2778 9.125V2.5C12.2778 1.81079 11.717 1.25 11.0278 1.25H3.34375C2.65454 1.25 2.09375 1.81079 2.09375 2.5V13.5C2.09375 14.1892 2.65454 14.75 3.34375 14.75H6.9375C7.28271 14.75 7.5625 15.0298 7.5625 15.375ZM14.9263 10.1793C14.6456 9.97839 14.2552 10.0431 14.0543 10.3236L10.9736 14.6268C10.8895 14.7244 10.7841 14.7454 10.7277 14.7491C10.6693 14.7529 10.5565 14.7456 10.4597 14.6534L8.46533 12.7384C8.21643 12.4994 7.8208 12.5073 7.58167 12.7563C7.34253 13.0054 7.35059 13.401 7.59961 13.64L9.59595 15.557C9.89502 15.8416 10.2941 16 10.7047 16C10.74 16 10.7754 15.9988 10.8108 15.9965C11.2581 15.9667 11.6757 15.7491 11.9565 15.3995C11.9637 15.3906 11.9708 15.3813 11.9775 15.3719L15.0707 11.0513C15.2716 10.7706 15.207 10.3802 14.9263 10.1793ZM10.4028 3.75H3.96533C3.62012 3.75 3.34033 4.02979 3.34033 4.375C3.34033 4.72021 3.62012 5 3.96533 5H10.4028C10.7479 5 11.0278 4.72021 11.0278 4.375C11.0278 4.02979 10.7479 3.75 10.4028 3.75ZM11.0278 6.875C11.0278 6.52979 10.7479 6.25 10.4028 6.25H3.96533C3.62012 6.25 3.34033 6.52979 3.34033 6.875C3.34033 7.22021 3.62012 7.5 3.96533 7.5H10.4028C10.7479 7.5 11.0278 7.22021 11.0278 6.875ZM3.96533 8.75C3.62012 8.75 3.34033 9.02979 3.34033 9.375C3.34033 9.72021 3.62012 10 3.96533 10H7.875C8.22021 10 8.5 9.72021 8.5 9.375C8.5 9.02979 8.22021 8.75 7.875 8.75H3.96533Z"
          fill="#8D54D7"
        />
      </g>
      <defs>
        <clipPath id="clip0_32082_45891">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
