import CalendarTopBar from "../CalendarTopBar/CalendarTopBar"
import FullCalendarV2 from "../FullCalendarV2/FullCalendarV2"

function CalendarV2Home(props) {

	return (
		<>
            <CalendarTopBar {...props}/>
			<div className="">
				<FullCalendarV2 {...props}/>
			</div>
		</>
	)	
		
}


export default CalendarV2Home
