import { fetchLookupKeyForId, fetchPriceForId } from "../../../pages/ManageSubscription/Utils/fetchers"
import getCorrespondingBasePackage from "../../../pages/ManageSubscription/Utils/getCorrespondingBasePackage"
import { getFormattedDateV2 } from "../../../utils/dateUtils"
import { isSiMappingEqual } from "../../../utils/subscriptionService"
import { getPriceForAdditionalTutorLicenses } from "../../../utils/utils"
import getConvertedPlanType from "../../ProductCard/planTypeConverter"

function getBasePackageDetails(productPrices, planId, selectedPlanType) {
    const basePackageLookupKey = fetchLookupKeyForId(productPrices, planId, selectedPlanType, "BasePackageV2")
    const basePackageName = basePackageLookupKey.split("_")[1]
    const basePackagePrice = fetchPriceForId(productPrices, planId, selectedPlanType, "BasePackageV2")
    return [basePackageLookupKey, basePackageName, basePackagePrice]
}


function getFreeBasePackage(productPrices) {
    const basePackagesAnnual = productPrices.find((product) => product.product.name.includes("BasePackageV2")).prices["annual"]
    const basePackageFreeAnnual = basePackagesAnnual.find((price) => price.lookup_key.includes("Free"))
    return basePackageFreeAnnual
}


function isParameterSelectionToBeEnabled(freeBasePackageId, productPrices, currentPlanId, selectedPlanId, subscriptionPopUpOperationMode) {
    if (selectedPlanId && productPrices) {
        let tempIsParameterSelectionAreaEnabled = true
        if (subscriptionPopUpOperationMode === "new-subscription" || subscriptionPopUpOperationMode === "update-from-free") {
            tempIsParameterSelectionAreaEnabled = (selectedPlanId !== freeBasePackageId)
        } else {
            if (selectedPlanId) {
                tempIsParameterSelectionAreaEnabled = (selectedPlanId !== freeBasePackageId)
            } else {
                tempIsParameterSelectionAreaEnabled = (currentPlanId !== freeBasePackageId)
            }
        }
        return tempIsParameterSelectionAreaEnabled
    }
}

function getCorrespondingProductSelection(selectedProducts, productPrices, selectedPlanType, selectedPlanId,setSelectedPlanId) {
    const oldBillingCycle = selectedPlanType === 0 ? "annual" : "monthly"
    const newBillingCycle = selectedPlanType === 1 ? "annual" : "monthly"
    const newBasePackage = getCorrespondingBasePackage(productPrices, oldBillingCycle, newBillingCycle, selectedPlanId)
    const newBasePackageId = newBasePackage.id
    setSelectedPlanId(newBasePackageId)

    let tempSelectedProducts = { ...selectedProducts }
    const tempConvertedPlanType = getConvertedPlanType(productPrices, selectedPlanType, tempSelectedProducts)
    Object.keys(tempSelectedProducts).forEach((key) => {
        tempSelectedProducts[key] = tempConvertedPlanType[key]
    })
    return tempSelectedProducts
}


function getCurrentSelectedPlanId(currentPlanId, selectedPlanId, subscriptionPopUpOperationMode) {

    let currentSelectedPlanId = null
    if (subscriptionPopUpOperationMode === "new-subscription" || subscriptionPopUpOperationMode === "update-from-free") {
        currentSelectedPlanId = selectedPlanId
    } else {
        if (selectedPlanId) {
            currentSelectedPlanId = selectedPlanId
        } else {
            currentSelectedPlanId = currentPlanId
        }
    }
    return currentSelectedPlanId

}


function getPriceDetails(productPrices, tempAdditionalTutors, selectedProducts, selectedFreeProducts, freeBasePackageId, selectedPlanId, currentPlanId, selectedPlanType, discountDetails, subscriptionPopUpOperationMode, previewProrationRes, setDiscountAmount, subscriptionV2, siMapping,requiresFreeTrial=false) {

	console.log("RFT", requiresFreeTrial)

    const tempPriceDetails = {
        normalDetails: [],
        finalAmountDetails: {},
        subCostDetails : {}
    }

    //Seed temp selected products according to the subscription operation mode
    let tempSelectedProducts = []
    if (subscriptionPopUpOperationMode === "new-subscription" || subscriptionPopUpOperationMode === "update-from-free") {
        tempSelectedProducts = selectedProducts
    } else {
        if (selectedPlanId === freeBasePackageId) {
            tempSelectedProducts = selectedFreeProducts
        } else {
            tempSelectedProducts = selectedProducts
        }
    }

    //For plan type
    const tempSelectedPlanType = selectedPlanType === 1 ? "annual" : "monthly"

    //Selected plan Id to be forwarded depending on the mode selected
    let currentSelectedPlanId = getCurrentSelectedPlanId(currentPlanId, selectedPlanId, subscriptionPopUpOperationMode)

    //For base package
    const [basePackageLookupKey, basePackagePlanName, basePackagePrice] = getBasePackageDetails(productPrices, currentSelectedPlanId, tempSelectedPlanType)

    //For tutors
    let tutorLicensePrice = null
    let tutorLicenseAmount = 0
    if (tempAdditionalTutors > 0) {
        tutorLicensePrice = getPriceForAdditionalTutorLicenses(productPrices, tempSelectedPlanType, basePackagePlanName)
        if (tutorLicensePrice) {
            if (selectedPlanId) {
                tutorLicenseAmount = (tutorLicensePrice.unit_amount) / 100
            }
        }
    }

    let totalPricePackageWithTutorsForCalc = (parseFloat(basePackagePrice) + (tutorLicensePrice ? parseFloat(tutorLicenseAmount * tempAdditionalTutors) : 0))
    let totalPricePackageWithTutors = (parseFloat(basePackagePrice) + (tutorLicensePrice ? parseFloat(tutorLicenseAmount * tempAdditionalTutors) : 0))

    if (selectedPlanType === 1) {
        totalPricePackageWithTutors = (totalPricePackageWithTutors / 12).toFixed(1)
    }


    tempPriceDetails.normalDetails.push({
        heading: `${basePackagePlanName} ${tempAdditionalTutors > 0 ? `+ ${tempAdditionalTutors} Tutor Licenses` : ""}`,
        price: "$" + totalPricePackageWithTutors,
        priceHelper: `/mo ${selectedPlanType === 1 ? "x 12" : ""}`

    })

    //For products
    let totalProductPrices = 0
    Object.keys(tempSelectedProducts).forEach((key, idx) => {
        if (idx !== 0 && key !== "tutors") {
            console.log(key,tempSelectedProducts[key],tempSelectedProducts[key].price, parseInt(tempSelectedProducts[key].price))
            totalProductPrices += parseInt(tempSelectedProducts[key].price)
        }
    })
    tempPriceDetails.normalDetails.push({
        heading: "Products",
        price: "$" + (totalProductPrices / (selectedPlanType === 1 ? 12 : 1)).toFixed(1),
        priceHelper: `/mo ${selectedPlanType === 1 ? "x 12" : ""}`
    })

    //For prices
    let totalPricePackage = 0
    if (subscriptionPopUpOperationMode === "new-subscription" || subscriptionPopUpOperationMode === "update-from-free") {
        totalPricePackage = ((totalPricePackageWithTutorsForCalc + totalProductPrices))
    } else {
        totalPricePackage = ((totalPricePackageWithTutorsForCalc + totalProductPrices))
    }
    let discountAmountToApply = 0
    console.log(discountDetails)
    //For discounts
    if (discountDetails && discountDetails.type) {
        if (discountDetails.amountOff) {
            discountAmountToApply = (discountDetails.amountOff / (100))
            totalPricePackage -= discountAmountToApply
        }
        if (discountDetails.percentOff) {
            discountAmountToApply = totalPricePackage * discountDetails.percentOff / 100
            totalPricePackage -= discountAmountToApply
        }

        setDiscountAmount((discountAmountToApply))

        tempPriceDetails.normalDetails.push({
            isDiscount: true,
            heading: `DISCOUNT (${discountDetails.name})`,
            price: "$" + (discountAmountToApply / (selectedPlanType === 1 ? 12 : 1)).toFixed(1),
            priceHelper: `/mo ${selectedPlanType === 1 ? "x 12" : ""}`
        })
    }

    //To check if subscription is trialling
    let daysBetweenSubscriptionStartDateAndEndDate = 0
    if(subscriptionPopUpOperationMode === "update-subscription") {
        daysBetweenSubscriptionStartDateAndEndDate = Math.floor(
            Math.abs(
                new Date(subscriptionV2?.subscription.startDate).getTime() -
                new Date(subscriptionV2?.subscription.endDate).getTime()
            ) /
                (24 * 60 * 60 * 1000)
            );
    }
    const isTrialling = daysBetweenSubscriptionStartDateAndEndDate === 14


    console.log(subscriptionV2?.subscription?.endDate)

    //For subscription cost
    tempPriceDetails.subCostDetails = {
        currentPrice: "$" + totalPricePackage.toFixed(1),
        discountedPrice: (selectedPlanType === 1 ? "$" : "") + (selectedPlanType === 1 ? ((totalPricePackage) / (0.85)).toFixed(1) : ""),
        billingPeriod: selectedPlanType === 1 ? "year" : "month",
        priceHelper: selectedPlanType === 1 ? "billed annually" : "billed monthly",
		requiresFreeTrial : requiresFreeTrial,
        ...(subscriptionPopUpOperationMode === "update-subscription") && {invoiceDate : getFormattedDateV2((new Date(subscriptionV2?.subscription.endDate)).toISOString(),"dd mm, yyyy")},
		...(subscriptionPopUpOperationMode === "update-from-free" && !requiresFreeTrial) && {invoiceDate :  getFormattedDateV2( (new Date()).toISOString(), "dd mm, yyyy") }
    }



    //For Amount Due
    if(subscriptionPopUpOperationMode === "new-subscription" || (subscriptionPopUpOperationMode === "update-from-free" && requiresFreeTrial) ) {
        tempPriceDetails.finalAmountDetails = {
            currentPrice: "$0.00"
        }
    } else {
        tempPriceDetails.finalAmountDetails  = {
			currentPrice : isTrialling ?  "$0.00": subscriptionPopUpOperationMode === "update-from-free" ? requiresFreeTrial? "$0.00" : "$" + totalPricePackage.toFixed(1) : isSiMappingEqual(subscriptionV2?.siMapping, siMapping) ? "$0.00" :  "$" + (previewProrationRes.amountDue/100).toFixed(1),
            headingHelper : isTrialling ? "14-day Free Trial" : "Prorated"
        }
    }


    return tempPriceDetails
}

function getStagingSiMapping(productPrices, tempSiMapping, freeBasePackageId, selectedPlanId, selectedPlanType, selectedProducts, selectedFreeProducts, subscriptionV2, tempAdditionalTutors) {
    const oldType = subscriptionV2?.subscription.__t === "AnnualSubscription" ? "annual" : "monthly"
    const newType = selectedPlanType === 1 ? "annual" : "monthly"
    const tempSelectedProducts = selectedPlanId === freeBasePackageId ? selectedFreeProducts : selectedProducts
    let stagingSiMapping = tempSiMapping.map((val) => ({ ...val }))
    console.log(stagingSiMapping)

    //For base package
    const _basePackageLookupKey = selectedPlanId === freeBasePackageId ? fetchLookupKeyForId(productPrices, selectedPlanId, "annual", "BasePackage") : fetchLookupKeyForId(productPrices, selectedPlanId, newType, "BasePackage")
    const basePackageName = _basePackageLookupKey.split("_")[1]
    stagingSiMapping.find((val, idx) => {
        if (val.lookupKey.includes("BasicPackage")) {
            if (stagingSiMapping[idx].priceId !== selectedPlanId) {
                stagingSiMapping[idx].newPriceId = selectedPlanId
                console.log("NEW BASE PACKAGE")
            } else {
                stagingSiMapping[idx].newPriceId = null
            }
        }
    })

    console.log(stagingSiMapping)

    //For products other than base package
    const requiredProducts = {
        "CustomContent": "Custom Tests",
        "Assignments": "Assignments",
        "Calendar": "Calendar",
        "Tutor": "tutors"
    }
    Object.keys(requiredProducts).forEach((key, idx) => {
        stagingSiMapping.find((val, idx) => {
            if (val.lookupKey.includes(key) && !val.lookupKey.includes("PAYG")) {
                if (stagingSiMapping[idx].priceId !== tempSelectedProducts[requiredProducts[key]].value) {
                    stagingSiMapping[idx].newPriceId = tempSelectedProducts[requiredProducts[key]].value
                } else {
                    stagingSiMapping[idx].newPriceId = null
                }
            }
        })
    })
    //For tutors
    stagingSiMapping.find((val, idx) => {
        if (val.lookupKey.includes("Tutor")) {
            console.log("TUTOR")
            
            let additionalTutorLicensesPrice = null
            if(selectedPlanId === freeBasePackageId)
                additionalTutorLicensesPrice = getPriceForAdditionalTutorLicenses(productPrices,"annual","Starter");
            else
                additionalTutorLicensesPrice = getPriceForAdditionalTutorLicenses(productPrices,newType,basePackageName);
            
            if (stagingSiMapping[idx].quantity !== tempAdditionalTutors) {
                stagingSiMapping[idx].quantity = tempAdditionalTutors
            }
            if (stagingSiMapping[idx].priceId !== additionalTutorLicensesPrice.id) {
                stagingSiMapping[idx].newPriceId = additionalTutorLicensesPrice.id
            }
            if (selectedPlanId === freeBasePackageId) {
                stagingSiMapping[idx].quantity = 0
            }
            console.log(stagingSiMapping[idx])
        }
    })

    return stagingSiMapping
}


function isPrimaryButtonToBeEnabled(productPrices,subscriptionSelectionStage, tempSiMapping, subscriptionV2, selectedPlanId, currentPlanId, selectedPlanType, paymentMethods, subscriptionPopUpOperationMode) {
    if (subscriptionSelectionStage === "subscription-select") {
        if (subscriptionV2 && tempSiMapping) {
            return (!isSiMappingEqual(subscriptionV2?.siMapping, tempSiMapping))
        } else if(subscriptionV2 && !tempSiMapping && subscriptionPopUpOperationMode === "update-from-free"){
        	let currentSelectedPlanId = getCurrentSelectedPlanId(currentPlanId, selectedPlanId, subscriptionPopUpOperationMode)
        	const tempSelectedPlanType = selectedPlanType === 1 ? "annual" : "monthly"
		const [basePackageLookupKey, basePackagePlanName] = getBasePackageDetails(productPrices, currentSelectedPlanId, tempSelectedPlanType)
	    	return !basePackagePlanName.includes("Free")	
	}

    } else if (subscriptionSelectionStage === "review-pay") {
        //Selected plan Id to be forwarded depending on the mode selected
        let currentSelectedPlanId = getCurrentSelectedPlanId(currentPlanId, selectedPlanId, subscriptionPopUpOperationMode)
        const tempSelectedPlanType = selectedPlanType === 1 ? "annual" : "monthly"
        //For base package & extract to a function
        const [basePackageLookupKey, basePackagePlanName] = getBasePackageDetails(productPrices, currentSelectedPlanId, tempSelectedPlanType)
        if (!basePackagePlanName.includes("Free")) {
            if (paymentMethods?.length === 0) {
                return (false)

            } else {
                return (true)
            }
        } else {
            if (paymentMethods?.length === 0) {
                return (false)

            } else {
                return (true)
            }
        }
    }
}

function getSelectedProductsAndBasePackageFromSiMapping(productPrices,selectedProducts,subscriptionV2) {

    //Temp SI Mapping
    const _tempSiMapping = subscriptionV2.siMapping

    //Seeding SI Mapping
    const currentBasePackageId = subscriptionV2.siMapping.find((val) => val.lookupKey.includes("BasicPackage")).priceId
    
    //For the selected billing cycle
    const planType = subscriptionV2.__t === "AnnualSubscription" ?  "annual":"monthly"

    //Seed selected products
    const tempSelectedProducts = { ...selectedProducts }
    const requiredProducts = {
        "CustomContent": "Custom Tests",
        "Assignments": "Assignments",
        "Calendar": "Calendar",
        "Tutor": "tutors"
    }
    Object.keys(requiredProducts).forEach((key, idx) => {
        const subscriptionItem = _tempSiMapping.find((val) => val.lookupKey.includes(key) && !val.lookupKey.includes("PAYG"))
        if (key === "Tutor") {
            tempSelectedProducts[requiredProducts[key]] = { price: "", value: "", selected: false, default: true }
            tempSelectedProducts[requiredProducts[key]].value = subscriptionItem.priceId
            tempSelectedProducts[requiredProducts[key]].quantity = subscriptionItem.quantity
        } else {
            tempSelectedProducts[requiredProducts[key]].value = subscriptionItem.priceId
            tempSelectedProducts[requiredProducts[key]].price = fetchPriceForId(productPrices,subscriptionItem.priceId,planType,key)
        }
    })
    return [currentBasePackageId, tempSelectedProducts, subscriptionV2.siMapping]
}

export {
    getBasePackageDetails,
    getFreeBasePackage,
    isParameterSelectionToBeEnabled,
    getCorrespondingProductSelection,
    getPriceDetails,
    getStagingSiMapping,
    isPrimaryButtonToBeEnabled,
    getCurrentSelectedPlanId,
    getSelectedProductsAndBasePackageFromSiMapping
}
