import React, { useState, useEffect } from "react";
import { useLazyGetAllUsersQuery } from "../../../../app/services/users";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import UserDropdown from "../../../../components/UserDropdown/UserDropdown";

const ChangeUserPopUp = ({ open, setOpen, onUserChange }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchUsers] = useLazyGetAllUsersQuery();

  const { firstName, lastName, email, id, scheduleDetails, role } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    const tutorsQuery = `?role=tutor`;
    const studentsQuery = `?role=student`;

    Promise.all([fetchUsers(tutorsQuery), fetchUsers(studentsQuery)])
      .then(([tutorsRes, studentsRes]) => {
        const tutorsData = tutorsRes.data.data.user.map((item) => ({
          _id: item._id,
          value: `${item.firstName.trim()} ${item.lastName.trim()}`,
          email: item.email,
          role: item.role,
          scheduleDetails: item.scheduleDetails
            ? item.scheduleDetails?.preferences
            : null,
        }));

        const studentsData = studentsRes.data.data.user.map((item) => ({
          _id: item._id,
          value: `${item.firstName.trim()} ${item.lastName.trim()}`,
          email: item.email,
          role: item.role,
          scheduleDetails: item.scheduleDetails
            ? item.scheduleDetails?.preferences
            : null,
        }));

        const adminData = [
          {
            _id: id,
            value: `${firstName} ${lastName}`,
            email: email,
            role: role,
            scheduleDetails: scheduleDetails
              ? scheduleDetails?.preferences
              : null,
          },
        ];

        setUsers([...tutorsData, ...studentsData, ...adminData]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [fetchUsers]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setOpenDropdown(false);
  };

  const handleSubmit = () => {
    if (selectedUser) {
      onUserChange(selectedUser);
      setOpen(false);
    }
  };

  console.log("-----", users);

  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[100%] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Change User</div>
        <button onClick={() => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>
      <div>
        <div className="text-secondary text-sm font-medium">User Name*</div>
        {loading ? (
          <LoaderIcon />
        ) : (
          <>
            <div
              className="relative px-4 py-3 rounded-md flex justify-between border text-base font-normal text-gray-600 border-gray-300 w-full outline-none cursor-pointer"
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              {selectedUser ? selectedUser.value : "Select User"}
            </div>

            {openDropdown && (
              <div className="mt-2">
                <UserDropdown users={users} onUserSelect={handleUserSelect} />
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex justify-end">
        <button
          className={`${
            selectedUser === null
              ? "pointer-events-none bg-blue-70/20 cursor-not-allowed"
              : "bg-blue-70 cursor-pointer"
          }md:flex text-[14px] text-white cursor-pointer w-fit text-center px-[18px] py-[2px] gap-[4px] items-center font-[400] rounded-md justify-center`}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </PopupContainer>
  );
};

export default ChangeUserPopUp;
