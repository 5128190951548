import React, { useEffect, useRef, useState } from "react";
import SeacrchIcon from "../../assets/icons/search.svg";
import dropdownIcon from "../../assets/icons/dropDownIcon.svg";
import down from "../../assets/YIcons/Group33.svg";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import CCheckbox from "../CCheckbox/CCheckbox";
import styles from "../InputSelect/style.module.css";
import Icon_forcalendar from "../../assets/calendar/down-arraow-calendar.svg";
import questionMark from "../../assets/images/question-mark.svg";
import newStyles from "./InputSearch.module.css";
import GreenTickIcon from "../QuestionPopUp/GreenTickIcon";

export default function InputSearch({
  parentClassName,
  inputContainerClassName,
  Icon,
  rightIcon_associate,
  rightIcon,
  value,
  IconRight_forcalendar,
  placeholder,
  label,
  labelClassname,
  IconRight,
  inputClassName,
  inputLeftField,
  onChange,
  type,
  right,
  required,
  optionData,
  optionClassName,
  onOptionClick,
  optionPrefix,
  checkbox,
  disabled,
  IconSearch,
  onOptionClose,
  dropDownIconStatus,
  onBlur,
  rightIcon_associate_iconClass,
  Tooltip,
  questionMarkMargin,
  questionMarkIcon,
  viewOptionDisplay,
  selectedOption,
  showGreenTickIcon,
  checkedFunction,
  defaultValue,
  showClearButton,
}) {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const inputRef = useRef();
  const handleClose = () => {
    setOptionsVisible(false);
  };
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (!optionsVisible) {
      onBlur && onBlur();
    }
  }, [optionsVisible]);
  useEffect(() => {
    if (optionsVisible === false && onOptionClose) {
      onOptionClose();
    }
  }, [optionsVisible]);

  useOutsideAlerter(inputRef, handleClose);

  useEffect(() => {
    if (value === "") console.log("EMPTY VALUE");
  }, [value]);

  return (
    <div className={`${parentClassName && parentClassName}`}>
      {showTooltip === true ? (
        <div className={`${newStyles.tooltip}`}>
          <h2>Cannot Unmap Student</h2>
          <div>
            The student is mapped to a group that is already mapped to the
            tutor.
            <br />
            To unmap the student from the tutor, you can either <br />
            <ul>
              <li>remove the student from the group </li>
              <li>OR unmap the group from the tutor’s profile.</li>
            </ul>
          </div>
        </div>
      ) : (
        <></>
      )}
      {React.isValidElement(label) ? (
        label
      ) : (
        <div className="flex items-center">
          <label
            className={`inline-block font-semibold bg-pink-400 ${labelClassname} w-2/3`}
          >
            {label}
            {required && (
              <span className="text-primaryRed inline-block pl-1">*</span>
            )}
          </label>
          {label == "Default Time Zone" && (
            <div className="group relative">
              <img
                className={`${
                  questionMarkMargin ? questionMarkMargin : `ml-3`
                } cursor-pointer`}
                questionMarkIcon
                src={questionMarkIcon ? questionMarkIcon : questionMark}
                alt=""
              ></img>
              {Tooltip}
            </div>
          )}
        </div>
      )}

      <div
        ref={inputRef}
        className={`py-3 px-6 flex relative items-center rounded-5 ${
          inputContainerClassName ? inputContainerClassName : ""
        }`}
      >
        {Icon && (
          <img
            onClick={() => setOptionsVisible(!optionsVisible)}
            className="mr-4 cursor-pointer"
            src={Icon}
          />
        )}
        {IconRight && (
          <img
            onClick={() => setOptionsVisible(!optionsVisible)}
            className="mr-4 cursor-pointer"
            src={IconRight}
            alt="search"
          />
        )}
        {IconRight_forcalendar && (
          <img
            className="absolute right-6 cursor-pointer"
            src={Icon_forcalendar}
            alt="sssearch"
          />
        )}
        {inputLeftField && inputLeftField}
        <input
          disabled={disabled}
          className={`outline-0 w-full ${
            inputClassName ? inputClassName : ""
          } ${styles["input"]} `}
          placeholder={placeholder}
          value={value}
          type={type ? type : "text"}
          onChange={(e) => (onChange !== undefined ? onChange(e) : "")}
          onFocus={() => setOptionsVisible(true)}
          {...(defaultValue &&
            value === "" &&
            !optionsVisible && { value: defaultValue, key: "def_val" })}
          // onBlur={onBlur ? onBlur : () => {}}
          // onBlur={()=> setOptionsVisible(false)}
        />
        {showClearButton && optionsVisible ? (
          <button
            className="text-[12px] absolute text-secondary underline left-[75%]"
            onClick={() => onChange({ target: { value: "" } })}
          >
            Clear
          </button>
        ) : (
          <></>
        )}
        {IconSearch ? (
          <img
            src={SeacrchIcon}
            onClick={() => setOptionsVisible(!optionsVisible)}
            className="ml-4 cursor-pointer"
            alt="SeacrchIcon"
          />
        ) : (
          ""
        )}

        {dropDownIconStatus && (
          <img src={dropdownIcon} alt="dropDown" className="mr-[2px] cursor-pointer" 
				onClick={() => setOptionsVisible(!optionsVisible)}
			/>
        )}

        {right && right}
        {rightIcon_associate && (
          <img
            onClick={() => setOptionsVisible(!optionsVisible)}
            className={`cursor-pointer z-[50] relative ${
              rightIcon_associate_iconClass ?? ""
            }`}
            src={down}
            alt="drop"
          />
        )}
        {rightIcon && (
          <img
            onClick={() => setOptionsVisible(!optionsVisible)}
            className="cursor-pointer z-[50] relative"
            src={rightIcon}
            alt="drop"
          />
        )}
        {optionsVisible && (
          <div
            className={`${styles.options} custom-scroller scrollbar-vertical shadow-xl rounded-t-none ${optionClassName}`}
          >
            {optionData?.map((option, idx) => {
              return (
                <div
                  className="outline-0  border-0 py-3 px-4 flex justify-between items-center relative"
                  key={idx}
                  onClick={() => {
                    if (option.isPartOfGroup === true) {
                      return;
                    }
                    return checkbox
                      ? onOptionClick(option)
                      : (onOptionClick(option), handleClose());
                  }}
                  onMouseEnter={(e) => {
                    if (option.isPartOfGroup === true) {
                      setShowTooltip(true);
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (option.isPartOfGroup === true) {
                      setShowTooltip(false);
                    }
                  }}
                >
                  <p className="flex items-center justify-start gap-[8px]">
                    {viewOptionDisplay ? option.display : option.value}
                    {showGreenTickIcon &&
                    selectedOption?._id === option?._id ? (
                      <GreenTickIcon />
                    ) : (
                      ""
                    )}
                  </p>
                  <p
                    className={`text-sm ${
                      option.firstName ? "opacity-80" : "opacity-60"
                    } !mt-0 ${checkbox ? "mr-auto ml-4" : ""}`}
                  >
                    {option.firstName && option.lastName
                      ? `${option.firstName} ${option.lastName}`
                      : option._id && optionPrefix
                      ? `${optionPrefix}${option._id.slice(-5)}`
                      : option._id && option._id.slice(-5)}
                  </p>
                  {checkbox && (
                    <div className="flex">
                      <CCheckbox
                        checked={
                          checkbox.checkedFunction
                            ? checkbox.checkedFunction(option)
                            : option &&
                              option?._id &&
                              checkbox?.match?.includes(option?._id)
                            ? true
                            : false
                        }
                        name="student"
                        // onChange={() =>
                        //    setData({
                        //       ...data,
                        //       recurring: !data.recurring,
                        //    })}
                        disabled={option.isPartOfGroup === true}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
