import React, { useEffect, useState } from 'react'
import PhoneNumberInputV2 from '../../../components/PhoneNumberInputV2/PhoneNumberInputV2';
import LockIcon from '../../../assets/icons/SettingsIcon/LockIcon';
import CrossIcon from '../../../assets/icons/SettingsIcon/CrossIcon';
import { useSelector } from 'react-redux';
import { useLazyGetPersonalDetailQuery, useUpdateUserAccountMutation } from '../../../app/services/users';
import countryCodes from '../../../utils/countryCode.json'
import { augumentPhoneNumber, getPhoneCodeForDialCode } from '../../../utils/phoneNumberUtils';
import LoaderIcon from '../../../components/LoaderIcon/LoaderIcon';
import { triggerToast } from '../../../utils/toastController';

const EditInfo = ({ setOpenEditPopup, fetchAndSetPersonalDetails, tempUserDetails, setTempUserDetails }) => {
    const [isPhoneNotValid, setIsPhoneNotValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDetails, userDetailsStatus] = useLazyGetPersonalDetailQuery();
    const [updateAccount, updateAccountStatus] = useUpdateUserAccountMutation();
    const [fetchedData, setFetchedData] = useState({});
    const user = useSelector((state) => state.user);

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        phoneCode: "",
        company: "",
        role: "",
        userId: "",
        registrationAs: "Company",
        about: user.about ? user.about : '',
        orgName: "",
        companyType: "",
        website: "",
        address: "",
        country: "",
        state: "",
        zip: "",
        city: "",

        activeStudents: "",
        activeTutors: "",
        services: [],
    });

    const isEmail = (val) => {
        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return false;
        } else {
            return true;
        }
    };

    const checkName = (inputString) => {
        const letterRegex = /^[a-zA-Z]+$/;
        return letterRegex.test(inputString);
    }

    const checkPhoneNumber = (inputString) => {
        const numberRegex = /^[0-9]+$/;
        return numberRegex.test(inputString) && (inputString.length === 10);
    }

    const handleDataUpdate = async () => {
        const arr = ["email", "firstName", "lastName", "phone", "phoneCode"];
        let boo = true;
        let ff = null;
        console.log("0900",tempUserDetails)
        arr.forEach((it) => {
            if (boo && (!tempUserDetails[it] || tempUserDetails[it]?.trim() === "")) {
                boo = false;
                alert(it + " can't be empty.");
                return;
            }
        });
        if (!boo) {
            return;
        }
        if (!isEmail(tempUserDetails?.email)) {
            alert("Enter valid email!");
            return;
        }
        if (!checkName(tempUserDetails?.firstName)) {
            alert("Enter valid First Name!");
            return;
        }
        if (!checkName(tempUserDetails?.lastName)) {
            alert("Enter valid Last Name!");
            return;
        }
        if (!checkPhoneNumber(tempUserDetails?.phone)) {
            alert("Enter valid Phone Number!");
            return;
        }
        if (
            tempUserDetails?.email?.trim() === "" ||
            tempUserDetails?.firstName?.trim() === "" ||
            tempUserDetails?.lastName?.trim() === "" ||
            tempUserDetails?.phone?.trim() === "" ||
            tempUserDetails?.phoneCode?.trim() === ""
        ) {
            alert("Please fill all the fields to update your account!");
            return;
        }
        setLoading(true);
        const updateUserAccount = async () => {
            try {
                let { firstName, lastName, role, phoneCode, phone, email } = { ...tempUserDetails };
                let reqBody = { firstName, lastName, role, phoneCode, phone, email }
                reqBody.onSaveButtonClicked = true
                delete reqBody["_id"];
                delete reqBody["email"];
                updateAccount(reqBody)
                    .then((res) => {
                        setLoading(false);
                        setOpenEditPopup(false)
                        if (fetchedData?.email !== tempUserDetails.email) {
                            const result = handleEmailUpdate(tempUserDetails.email)
                            if (result.error) {
                                return
                            }
                        }
                        if (res?.error) {
                            triggerToast("Somthing went wrong!", "error", { autoClose: 3000 });
                        }
                        if (res?.data) {
                            triggerToast("Info updated successfully", "success", { autoClose: 3000 });
                        }
                        console.log(res);
                        fetchAndSetPersonalDetails("user")

                    })
                    .catch((err) => {
                        setLoading(false);
                        console.log(err?.message);
                    });
                updateDetails({ id: user.id, fields: { about: tempUserDetails.about } })
            } catch (e) {
                console.error(e?.response?.data?.message);
            }
        };


        updateUserAccount();
        console.log({ fetchedData, tempUserDetails });
    };

    useEffect(() => {
        userDetails()
            .then((res) => {
                // console.log("userDetails");
                // console.log(res);
                setValues({
                    ...res?.data.data.user,
                    flagCode: countryCodes.find((val) => val.dial_code === res?.data.data.user.phoneCode)?.code
                });
                setFetchedData({
                    ...res?.data.data.user,

                });
            })
            .catch((err) => {
                console.log(err);
            });


    }, []);

    useEffect(() => {
        console.log("09001",tempUserDetails)
    }, [tempUserDetails])

    return (
        <div className='w-full h-full bg-secondary/70 fixed top-0 left-0'>
            <div className=' flex flex-col gap-9 m-auto bg-white w-[90%] lg:w-8/12 relative top-1/2 -translate-y-1/2 p-6 rounded-xl'>
                <div>
                    <div className='flex justify-between items-center py-[2px]'>
                        <h1 className='font-medium text-2xl leading-[36px] text-blue-90'>Basic Info</h1>
                        <div className='cursor-pointer' onClick={(e) => setOpenEditPopup(false)}><CrossIcon /></div>
                    </div>
                    <div className='border-b border-blue-50 pt-2'></div>
                </div>
                <div className='flex flex-col gap-4'>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">
                        <div>
                            <label className='float-left font-medium text-sm leading-[17.5px] text-secondary pb-1' htmlFor="first_name">First Name</label>
                            <input
                                className="px-[1rem] py-[14px] rounded-md  border border-[#BEC2CE] focus:border-[#0671E0] w-full outline-none "
                                type="text"
                                placeholder="John"
                                id='first_name'
                                value={tempUserDetails.firstName}
                                onChange={(e) => {
                                    setTempUserDetails((values) => ({
                                        ...values,
                                        firstName: e.target.value,
                                    }));
                                }}
                            ></input>
                        </div>
                        <div>
                            <label className='float-left font-medium text-sm leading-[17.5px] text-secondary pb-1' htmlFor="last_name">Last Name</label>
                            <input
                                className="px-[1rem] py-[14px] rounded-md  border border-[#BEC2CE] focus:border-blue-70 w-full outline-none"
                                type="text"
                                placeholder="Doe"
                                id='last_name'
                                value={tempUserDetails.lastName}
                                onChange={(e) => {
                                    setTempUserDetails((values) => ({
                                        ...values,
                                        lastName: e.target.value,
                                    }));
                                }}
                            ></input>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 '>
                        <div className='relative'>
                            <label className='float-left font-medium text-sm leading-[17.5px] text-secondary pb-1' htmlFor="email">Email</label>
                            <input
                                className="px-[1rem] py-[0.8125rem] rounded-md bg-white  border border-[#BEC2CE] focus:border-[#0671E0] w-full outline-none text-secondary"
                                type="text"
                                placeholder="johndoe@company.com"
                                id='email'
                                disabled={values.role === "admin" ? true : false}
                                value={tempUserDetails.email}
                                onChange={(e) => {
                                    setTempUserDetails((values) => ({
                                        ...values,
                                        email: e.target.value,
                                    }));
                                }}
                            ></input>
                            {values.role === "admin" && <div className='absolute top-[55%]  right-4'> <LockIcon /> </div>}
                        </div>
                        <div className=''>
                            <label className='float-left font-medium text-sm leading-[17.5px] text-secondary pb-1' htmlFor="phone">Phone</label>
                            <PhoneNumberInputV2
                                placeholderText="Phone number"
                                className="phone-code-dropdown p-0"
                                setIsNotValid={setIsPhoneNotValid}
                                inputStyleClass={"!text-secondary-80"}
                                countryCodeStyleClass={"!text-secondary-80"}
                                selectedCountryCode={{ dial_code: tempUserDetails.phoneCode?.trim() === "" ? "+1" : tempUserDetails.phoneCode?.trim(), code: getPhoneCodeForDialCode(tempUserDetails.phoneCode) }}
                                setSelectedCountryCode={(countryCode) => { setTempUserDetails((prev) => ({ ...prev, phoneCode: countryCode.dial_code, flagCode: countryCode.code })) }} defaultValue={augumentPhoneNumber(tempUserDetails.phone)}
                                setPhoneNumberOutside={(phoneNumber) => {
                                    const regex = /^[0-9 ]*$/;
                                    const isValid = regex.test(phoneNumber);
                                    if (isValid && phoneNumber?.length < 11)
                                        setTempUserDetails((prev) => ({ ...prev, phone: phoneNumber }));
                                }}
                            />
                            {
                                isPhoneNotValid ? <p className='text-error-40 font-[400]'>Please enter a valid phone number</p>: <></>
                            }

                        </div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 '>
                        <div className='relative'>
                            <label className='float-left font-medium text-sm leading-[17.5px] text-secondary pb-1' htmlFor="email">Access Level</label>
                            <input
                                className="px-[1rem] capitalize py-[0.8125rem] rounded-md border border-[#BEC2CE] bg-white focus:border-[#0671E0] w-full outline-none text-secondary"
                                type="text"
                                placeholder="Access Level"
                                id='role'
                                disabled
                                value={tempUserDetails.role}
                                onChange={(e) => {
                                    setTempUserDetails((values) => ({
                                        ...values,
                                        role: e.target.value,
                                    }));
                                }}
                            ></input>
                            <div className='absolute top-[55%]  right-4'>
                                <LockIcon />
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <div className=' w-full lg:w-[360px] h-12 grid grid-cols-2 gap-3 float-right'>
                        <button
                            className='border-[2px] w-full h-full text-center outline-none box-border border-secondary-60 rounded-md px-6 py-[8px] font-semibold text-base leading-[20px] text-secondary-80'
                            onClick={(e) => setOpenEditPopup(false)}
                        >Cancel</button>
                        <button
                            className='rounded-md w-full h-full text-center px-6 py-[10px] font-semibold text-base leading-[20px] text-white bg-blue-70 hover:bg-blue-80 flex justify-center items-center'
                            onClick={handleDataUpdate}
                            disabled={isPhoneNotValid}
                        >{loading ? <LoaderIcon /> : "Save"}</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditInfo