import SummaryItem from '../SummaryItem/SummaryItem'
import styles from './SummarySection.module.css'

//Summary Object Format
/* ?? is optional text
 [
    {   
        type : ["add-ons","top-ups","tutors",""] //Optional
        sectionTitle : "",
        children : [
            {
                title : {},
                helperText : {
                    old : ?,
                    new : ,
                    oldStyle : ??,
                    newStyle : ??
                },
                priceText : {
                    main : ,
                    helper : ??,
                    mainFormat : ??
                    helperFormat : ??,
                    type : [upg,dwg]//upgrade & downgrade,
                    billingPeriod : ?? 
                },
                metadata : {
                    idForAction : //will be sent back in the event in case of onclick
                }
                onDeleteClick : callback with event argument
            }
        ]
    }
]
 */
const SummarySection = ({sectionTitle,type,children}) => {
    return(
        <div className={styles.container}>
            <div className={styles.title}>{sectionTitle}</div>
            {
                children.map((child,idx)=>(
                    <SummaryItem {...child} type={type} hasBorder={idx < children.length - 1}/>
                ))
            }
        </div>
    )
}

export default SummarySection