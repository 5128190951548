import React from 'react'

const CalendarIcon = (props) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_28226_11100)">
                <path d="M8 26.6667H56H8ZM18.6667 8V13.3333V8ZM45.3333 8V13.3333V8ZM16.5333 56H47.4667C50.4536 56 51.9472 56 53.088 55.4187C54.0915 54.9075 54.9075 54.0915 55.4187 53.088C56 51.9472 56 50.4536 56 47.4667V21.8667C56 18.8797 56 17.3862 55.4187 16.2454C54.9075 15.2418 54.0915 14.4259 53.088 13.9146C51.9472 13.3333 50.4536 13.3333 47.4667 13.3333H16.5333C13.5464 13.3333 12.0529 13.3333 10.9121 13.9146C9.90851 14.4259 9.09261 15.2418 8.58131 16.2454C8 17.3862 8 18.8797 8 21.8667V47.4667C8 50.4536 8 51.9472 8.58131 53.088C9.09261 54.0915 9.90851 54.9075 10.9121 55.4187C12.0529 56 13.5464 56 16.5333 56Z" fill="white" />
                <path d="M8 26.6667H56M18.6667 8V13.3333M45.3333 8V13.3333M16.5333 56H47.4667C50.4536 56 51.9472 56 53.088 55.4187C54.0915 54.9075 54.9075 54.0915 55.4187 53.088C56 51.9472 56 50.4536 56 47.4667V21.8667C56 18.8797 56 17.3862 55.4187 16.2454C54.9075 15.2418 54.0915 14.4259 53.088 13.9146C51.9472 13.3333 50.4536 13.3333 47.4667 13.3333H16.5333C13.5464 13.3333 12.0529 13.3333 10.9121 13.9146C9.90851 14.4259 9.09261 15.2418 8.58131 16.2454C8 17.3862 8 18.8797 8 21.8667V47.4667C8 50.4536 8 51.9472 8.58131 53.088C9.09261 54.0915 9.90851 54.9075 10.9121 55.4187C12.0529 56 13.5464 56 16.5333 56Z" stroke="#EE778C" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_28226_11100">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CalendarIcon