import React, { useEffect, useState } from "react";
import "./CustomElements.css";
import TimeZonePopover from "../../../components/TimeZonePopOver/TimeZonePopover";
import Dateformat from "../../../components/DateFormat/Dateformat";
import { useMediaQuery, useTheme } from "@mui/material";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateOrgSettingMutation } from "../../../app/services/settings";
import { updateOrganizationSettings } from "../../../app/slices/organization";
import { updateDateFormat, updateUserDetails } from "../../../app/slices/user";
import Tooltip from "../../../components/Tooltip/Tooltip";
import MoreInfoIcon from "../../../components/MoreInfoIcon/MoreInfoIcon";
import { getTooltipStyleClass } from "../../../utils/commonlyUsedTooltipStyles";
import TooltipIcon from "../../../assets/icons/SettingsIcon/TooltipIcon";

const DateTimeSettings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.organization);
  const [updateSetting, updateSettingResp] = useUpdateOrgSettingMutation();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isDateFormatrVisible, setIsDateFormatrVisible] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState();
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  const togglePopover = () => {
    setIsPopoverVisible((prev) => !prev);
    setIsDateFormatrVisible(false);
  };
  const closePopover = () => {
    setIsPopoverVisible(false);
    setIsDateFormatrVisible(false);
  };
  const toggleDatePopover = () => {
    setIsDateFormatrVisible((prev) => !prev);
  };
  const handleTimeZoneSelection = (timezone) => {
    setSelectedTimeZone(timezone);
    setHasChanges(true);

    closePopover();
  };
  const closeDatePopover = () => {
    setIsDateFormatrVisible(false);
  };
  const handleDateFormatSelection = (format) => {
    // const lowercaseFormat = format.toLowerCase();
    setSelectedFormat(format.toUpperCase());
    setHasChanges(true);
    closeDatePopover();
  };

  const handleSave = () => {
    const settings = {
      ...organization?.settings,
      timeZone: selectedTimeZone,
      dateFormat: selectedFormat.toLowerCase(),
    };

    const body = { settings };
    console.log("settings", settings);
    console.log("body", body);
    updateSetting(body)
      .then((res) => {
        console.log("res data", res.data.data.updatedOrg.settings);
        dispatch(updateOrganizationSettings(res.data.data.updatedOrg.settings));
        dispatch(
          updateDateFormat({ dateFormat: selectedFormat.toLowerCase() })
        );
      })
      .catch((err) => {
        console.log("err", err);
      });

    setHasChanges(false);
  };
  useEffect(() => {
    if (organization?.settings) {
      setSelectedTimeZone(organization.settings.timeZone);
      setSelectedFormat(organization.settings.dateFormat?.toUpperCase() || "");
    }
  }, [organization]);

  // console.log("selectedFormat", selectedFormat);
  return (
    <div className="custom-elements">
      <div className="frame">
        <div className="div">
          <div className="frame-2">
            <div className="frame-3">
              <div className="text-wrapper flex items-center justify-start gap-[6px]">
                Time and Date
                <Tooltip tooltipStyles={getTooltipStyleClass({"width" : "220px", textWrap : "wrap"})} tooltipContent={"Manage your time zone and date format preferences. Note that these changes reflect the defaults across all users in your organization."}>
                  <TooltipIcon />
                </Tooltip> 
              </div>
            </div>
          </div>
          {/* <button className="button">
            <span className="verify-email">Save</span>
          </button> */}
          <button
            onClick={handleSave}
            disabled={!hasChanges}
            className="saveButton"
          >
            Save
          </button>{" "}
        </div>
        <div className="frame-4">
          <div className="basic-info">
            <div className="sub-section-header">
              <div className="frame-5">
                <div className="lead-statuses-tutors">Default Time Zone</div>
              </div>
              <div className="edit-icon-wrapper" onClick={togglePopover}>
                <img
                  className="edit-icon"
                  src="/images/Vector.svg"
                  alt="Edit Icon"
                />
              </div>
            </div>
            <div className="frame-6">
              <p className="p">
                This is the time zone that will be applied for all of {organization?.company}’s users while scheduling sessions on
                Calendar.
              </p>
              <div className="frame-7">
                <p className="text-wrapper-2">
                  Set the default time zone for {organization?.company} 
                </p>
                <div className="input-field" onClick={togglePopover}>
                  <p className="text-wrapper-3">{selectedTimeZone} </p>
                  <img
                    className="img"
                    src="/images/chevron-down.svg"
                    alt="Dropdown Icon"
                  />
                  <TimeZonePopover
                    isVisible={isPopoverVisible}
                    onClose={closePopover}
                    onSelectTimeZone={handleTimeZoneSelection}
                    selectedTimeZone={selectedTimeZone}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="basic-info">
            <div className="sub-section-header">
              <div className="frame-5">
                <div className="lead-statuses-tutors">Default Date Format</div>
              </div>
              <div className="edit-icon-wrapper" onClick={toggleDatePopover}>
                <img
                  className="edit-icon"
                  src="/images/Vector.svg"
                  alt="Edit Icon"
                />
              </div>
            </div>
            <div className="frame-6">
              <p className="p">
                This is the date format that all of {organization?.company}
                ’s users will see throughout the platform.
              </p>
              <div className="frame-7">
                <p className="text-wrapper-2">
                  Set the default date format for {organization?.company}
                </p>
                <div className="input-field" onClick={toggleDatePopover}>
                  <div className="text-wrapper-3"> {selectedFormat}</div>
                  <img
                    className="img"
                    src="/images/chevron-down.svg"
                    alt="Dropdown Icon"
                  />
                  <Dateformat
                    isVisible={isDateFormatrVisible}
                    onClose={closeDatePopover}
                    onSelectDateFormat={handleDateFormatSelection}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimeSettings;
