import { useContext, useEffect, useState } from 'react'
import InputPasswordV2 from '../../InputPasswordV2/InputPasswordV2'
import TickMarkIcon from '../../TickMarkIcon/TickMarkIcon'
import styles from './SetPasswordScreen.module.css'
import CrossIcon from '../../CrossIcon/CrossIcon'
import ButtonV2 from '../../ButtonV2/ButtonV2'
import ArrowIconRightV2 from '../../ArrowIconRightV2/ArrowIconRightV2'
import verifiedLogo from './VerifiedLogo.svg'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useCreateNewOrgMutation } from '../../../app/services/orgSignup'
import { useSignupAdminMutation } from '../../../app/services/admin'
import LoaderIcon from '../../LoaderIcon/LoaderIcon'
import { toast } from 'react-toastify'
import OrgSignupContext from '../../../context/OrgSignupContext'
import { useDispatch } from 'react-redux'
import { updateIsLoggedIn } from '../../../app/slices/user'

const SetPasswordScreen = (props) => {

    const navigate=useNavigate()
    
    const {setSignupStage} = useOutletContext()
    const [passwordValidityChecklist, setPasswordValidityChecklist] = useState([false, false, false, false])
    const [mainPassword, setMainPassword] = useState(null)
    const [secondaryPassword, setSecondaryPassword] = useState(null)
    const [isValidEntry, setIsValidEntry] = useState(false)
    const [isPasswordMatching, setIsPasswordMatching] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const {userEmail, registerationAs} = useContext(OrgSignupContext)
    const dispatch = useDispatch()

    //RTK toolkit methods to call the REST API
    const [createOrg, _createOrgResp] = useCreateNewOrgMutation()
    const [createAdmin, createAdminResp] = useSignupAdminMutation()

    function validatePassword(password) {
        const upperCaseRegex = /[A-Z]/
        const lowerCaseRegex = /[a-z]/
        const specialCharacterRegex = /[^A-Za-z]/
        let tempList = passwordValidityChecklist

        tempList[0] = password.length >= 8
        tempList[1] = upperCaseRegex.test(password)
        tempList[2] = lowerCaseRegex.test(password)
        tempList[3] = specialCharacterRegex.test(password)

        setPasswordValidityChecklist(tempList)
    }

    //Turn on after testing
    useEffect(()=>{
        setSignupStage("setPassword")
        const token = window.localStorage.getItem("set_password_token")
        if(!token) {
            toast.error("Unauthorized access, please follow the correct steps.", { position: "top-center", autoClose: 3000, onClose: () => { navigate('/signup/org') } })
        }else {
            setIsValidEntry(true)
        }
    },[])

    useEffect(() => {
        setIsPasswordMatching(mainPassword === secondaryPassword)
    }, [mainPassword, secondaryPassword])

    const handlePasswordChange = (e) => {
        switch (e.target.id) {
            case "mainPassword": setMainPassword(e.target.value); validatePassword(e.target.value); break;
            case "secondaryPassword": setSecondaryPassword(e.target.value); break;
        }
    }

    const handleSetPasswordButtonClick = async (e) => {
        setIsLoading(true)
        try {
            const createOrgResp = await createOrg()
            if(createOrgResp.error){
                toast.warn(createOrgResp.error.data.message)
            } else {
                const orgId = createOrgResp.data.data.org._id
                const createAdminResp = await createAdmin({
                    email : userEmail,
                    password : mainPassword,
                    orgId : orgId,
                    registrationAs : registerationAs
                })
                if(createAdminResp.error){
                    toast.warn(createAdminResp.error.data.message)
                } else {
                    window.localStorage.removeItem("set_password_token")
                    window.localStorage.setItem("evalloToken", createAdminResp.data.data.token);
                    window.localStorage.setItem("role", "admin");
                    window.localStorage.setItem("userId", createAdminResp.data.data.admin._id);
                    window.localStorage.setItem("orgId", orgId)
                    toast.success("Welcome to evallo")
                    dispatch(updateIsLoggedIn(true))
                    navigate("/signup/org-details")
                }
            }
        }catch(err){
            console.log(err)
            toast.warn("Some unkown error occured")
        }
        setIsLoading(false)
    }

    return (
        <div className={styles.container}>
            {
                isValidEntry ?
                    <div className={styles.verifiedMessage}>
                        <img src={verifiedLogo} />
                        <div>You have successfully verified your email.</div>
                    </div>
                    : <></>
            }
            <h3>Set your password</h3>
            <div className={styles.passwordInputContainer}>
                <InputPasswordV2 placeholderText={"Enter your password"} id="mainPassword" onChange={handlePasswordChange} noMatch={secondaryPassword && !isPasswordMatching}/>
                <InputPasswordV2 placeholderText={"Confirm your password"} id="secondaryPassword" onChange={handlePasswordChange} noMatch={secondaryPassword && !isPasswordMatching}/>
                {
                    secondaryPassword ? !isPasswordMatching ? <div className={styles.mismatchingPasswords}>The passwords don't match. Please recheck.</div> : <></> : <></>
                }

            </div>
            <div className={styles.passwordValidator}>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[0] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[0] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[0] ? styles.itemText : styles.itemTextIncorrect}>At least 8 characters</div>
                </div>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[1] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[1] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[1] ? styles.itemText : styles.itemTextIncorrect}>One uppercase character</div>
                </div>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[2] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[2] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[2] ? styles.itemText : styles.itemTextIncorrect}>One lowercase character</div>
                </div>
                <div className={styles.passwordIndicator}>
                    <div className={!mainPassword ? styles.itemImageNeutral : passwordValidityChecklist[3] ? styles.itemImageCorrect : styles.itemImageIncorrect}>
                        {!mainPassword ? <TickMarkIcon /> : passwordValidityChecklist[3] ? <TickMarkIcon /> : <CrossIcon />}
                    </div>
                    <div className={!mainPassword ? styles.itemText : passwordValidityChecklist[3] ? styles.itemText : styles.itemTextIncorrect}>One number, symbol or space character</div>
                </div>
            </div>
            <ButtonV2 disabled={!(isPasswordMatching && passwordValidityChecklist.reduce((prev, curr) => prev || curr, false))} onClick={(e)=>handleSetPasswordButtonClick(e)}>
                {
                    !isLoading ?
                    <div className={styles.buttonContent}>
                        <div>Continue</div>
                        <ArrowIconRightV2 />
                    </div>
                    :
                    <div className='w-full flex justify-center items-center'>
                        <LoaderIcon />
                    </div>
                }

            </ButtonV2>
        </div>
    )
}

export default SetPasswordScreen