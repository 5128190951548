export default function ClockIconStatus(props){

	return(
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M7 0C3.10435 0 0 3.10435 0 7C0 10.8957 3.10435 14 7 14C10.8957 14 14 10.8957 14 7C14 3.10435 10.8957 0 7 0ZM9.43478 8.76522C9.25217 9.00869 8.82609 9.06956 8.58261 8.82609L6.63478 7.24348C6.51304 7.12174 6.3913 6.93913 6.3913 6.75652V3.34783C6.3913 2.98261 6.69565 2.73913 7 2.73913C7.36522 2.73913 7.6087 2.98261 7.6087 3.34783V6.51304L9.31304 7.91304C9.61739 8.15652 9.61739 8.52174 9.43478 8.76522Z" fill="#2792CD"/>
		</svg>

	)

}
