import CrossIcon from '../CrossIcon/CrossIcon'
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider'
import styles from './MobileNavDrawer.module.css'
import evIconV2 from '../../assets/icons/navbar-icons/version2/Ev_logo_v2_full_ blue.svg'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import AssignmentIcon from "../../assets/icons/navbar-icons/version2/AssignmentIcon";
import ContentIcon from "../../assets/icons/navbar-icons/version2/ContentIcon";
import DownArrow from "../../assets/icons/navbar-icons/version2/DownArrow";
import SettingsIcon from "../../assets/icons/navbar-icons/version2/SettingsIcon";
import InvoiceIcon from "../../assets/icons/navbar-icons/version2/InvoiceIcon";
import DashboardIcon from "../../assets/icons/navbar-icons/version2/DashboardIcon";
import UsersIcon from "../../assets/icons/navbar-icons/version2/UsersIcon";
import CalendarIcon from "../../assets/icons/navbar-icons/version2/CalendarIcon";
import ProfileIcon from "../../assets/icons/navbar-icons/version2/ProfileIcon";
import StudentIcon from "../../assets/icons/navbar-icons/version2/StudentIcon";
import UsersIconDisabled from "../../assets/icons/navbar-icons/crm_grey.svg";
import ScheduleDisabled from "../../assets/icons/navbar-icons/Calendar_grey.svg";
import AssignmentDisabled from "../../assets/icons/navbar-icons/Assignments_grey.svg";
import ContentDisabled from "../../assets/icons/navbar-icons/contents_grey.svg";
import InvoiceDisabled from "../../assets/images/invoice-logo-grey.svg";
import { style } from '@mui/system'
import { Link } from 'react-router-dom'
import { isWhiteLabellingComponentAllowed } from '../../utils/restrictionsService'

let tempnavdata = [
    // {
    //    icon: Dashboard,
    //    activeIcon: Dashboard1,
    //    disabledIcon: DashboardDisabled,
    //    path: "/",
    //    excludes: ["student", "parent", "tutor"],
    //    tooltip: "Dashboard",
    //    isDisabled: false,
    // },
    {
      icon: <UsersIcon />,
      activeIcon: <UsersIcon type={"active"} />,
      disabledIcon: UsersIconDisabled,
      path: "/",
      tooltip: "Users",
      isDisabled: false,
    },
    {
      icon: <CalendarIcon />,
      activeIcon: <CalendarIcon type={"active"} />,
      disabledIcon: ScheduleDisabled,
      path: "/calendar",
      tooltip: "Calendar",
      isDisabled: false,
    },
    {
      icon: <AssignmentIcon />,
      activeIcon: <AssignmentIcon type={"active"} />,
      disabledIcon: AssignmentDisabled,
      path: "/assigned-tests",
      tooltip: "Assignments",
      isDisabled: false,
    },
    {
      icon: <ContentIcon />,
      activeIcon: <ContentIcon type={"active"} />,
      disabledIcon: ContentDisabled,
      path: "/all-tests",
      tooltip: "Content",
      isDisabled: false,
    },
    // {
    //    icon: Invoice,
    //    activeIcon: Invoice2,
    //    disabledIcon: InvoiceDisabled,
    //    path: "/invoice",
    //    tooltip: "Invoicing",
    //    isDisabled: false,
    // },
    // {
    //    icon: Settings,
    //    activeIcon: Settings1,
    //    path: "/settings",
    //    excludes: ["student", "parent", "tutor"],
    //    tooltip: "Settings",
    //    isDisabled: false,
    // },
  ];

const parentNav = [
    {
      icon: <DashboardIcon />,
      activeIcon: <DashboardIcon type={"active"} />,
      path: "/",
      tooltip: "Dashboard",
    },
  
    {
      icon: <AssignmentIcon />,
      activeIcon: <AssignmentIcon type={"active"} />,
      path: "/all-tests",
      tooltip: "Assignments",
    },
    {
      icon: <CalendarIcon />,
      activeIcon: <ContentIcon type={"active"} />,
      path: "/calendar",
      tooltip: "Calendar",
    },
  
    {
      icon: <ProfileIcon />,
      activeIcon: <ProfileIcon type={"active"} />,
      path: "/profile",
      tooltip: "Profile",
    },
    // {
    //    icon: <SettingsIcon />,
    //    activeIcon: <SettingsIcon type={"active"} />,
    //    path: "/settings",
    //    excludes: ["student", "tutor", "admin", "superAdmin"],
    //    tooltip: "Settings",
    // },
  ];
  const studentNav = [
    {
      icon: <DashboardIcon />,
      activeIcon: <DashboardIcon type={"active"} />,
      path: "/",
      tooltip: "Dashboard",
    },
  
    {
      icon: <AssignmentIcon />,
      activeIcon: <AssignmentIcon type={"active"} />,
      path: "/all-tests",
      tooltip: "Assignments",
    },
    {
      icon: <CalendarIcon />,
      activeIcon: <ContentIcon type={"active"} />,
      path: "/calendar",
      tooltip: "Calendar",
    },
    {
      icon: <ProfileIcon />,
      activeIcon: <ProfileIcon type={"active"} />,
      path: "/profile",
      tooltip: "Profile",
    },
    // {
    //    icon: <SettingsIcon />,
    //    activeIcon: <SettingsIcon type={"active"} />,
    //    path: "/settings",
    //    excludes: ["parent", "tutor", "admin", "superAdmin"],
    //    tooltip: "Settings",
    // },
  ];
  const tutorNav = [
    {
      icon: <DashboardIcon />,
      activeIcon: <DashboardIcon type={"active"} />,
      path: "/",
      tooltip: "Dashboard",
    },
    // {
    //    icon: <ProfileIcon />,
    //    activeIcon: <ProfileIcon type={"active"} />,
    //    path: "/profile",
    //    tooltip: "Profile",
    // },
    {
      icon: <CalendarIcon />,
      activeIcon: <ContentIcon type={"active"} />,
      path: "/calendar",
      tooltip: "Calendar",
    },
    {
      icon: <AssignmentIcon />,
      activeIcon: <AssignmentIcon type={"active"} />,
      path: "/assigned-tests",
      tooltip: "Assignments",
    },
    {
      icon: <StudentIcon />,
      activeIcon: <StudentIcon type={"active"} />,
      path: "/assigned-students",
      tooltip: "Students",
    },
    // {
    //    icon: <SettingsIcon />,
    //    activeIcon: <SettingsIcon type={"active"} />,
    //    path: "/settings",
    //    excludes: ["parent", "student", "admin", "superAdmin"],
    //    tooltip: "Settings",
    // },
  ];
  const supAdminNavData = [
    {
      icon: <DashboardIcon />,
      activeIcon: <DashboardIcon type={"active"} />,
      path: "/",
      excludes: ["student", "parent", "tutor"],
      tooltip: "Dashboard",
    },
    {
      icon: <ProfileIcon />,
      activeIcon: <ProfileIcon type={"active"} />,
      path: "/all-orgs",
      excludes: ["student", "parent", "tutor"],
      tooltip: "All Orgs",
    },
    {
      icon: <SettingsIcon />,
      activeIcon: <SettingsIcon type={"active"} />,
      path: "/settings",
      excludes: ["student", "parent", "tutor"],
      tooltip: "Settings",
    },
  ];
  
  const contributorNavdata = [
    {
      icon: <UsersIcon />,
      activeIcon: <UsersIcon type={"active"} />,
      disabledIcon: UsersIconDisabled,
      path: "/users",
      tooltip: "Users",
      isDisabled: false,
    },
    // {
    //    icon: <DashboardIcon />,
    //    activeIcon: <DashboardIcon type={"active"} />,
    //    path: "/",
    //    tooltip: "Dashboard",
    // },
    {
      icon: <ContentIcon />,
      activeIcon: <ContentIcon type={"active"} />,
      path: "/",
      tooltip: "Content",
    },
    {
      icon: <SettingsIcon />,
      activeIcon: <SettingsIcon type={"active"} />,
      path: "/settings",
      excludes: ["student", "parent", "tutor"],
      tooltip: "Settings",
    },
  ];
  
  const managerNavData = [
    {
      icon: <DashboardIcon />,
      activeIcon: <DashboardIcon type={"active"} />,
      path: "/",
      excludes: ["student", "parent", "tutor"],
      tooltip: "Dashboard",
    },
    {
      icon: <ProfileIcon />,
      activeIcon: <ProfileIcon type={"active"} />,
      path: "/all-orgs",
      excludes: ["student", "parent", "tutor"],
      tooltip: "All Orgs",
    },
    {
      icon: <SettingsIcon />,
      activeIcon: <SettingsIcon type={"active"} />,
      path: "/settings",
      excludes: ["student", "parent", "tutor"],
      tooltip: "Settings",
    },
  ];
  
const forStroke = ["Calendar", "Content" ]
const forFill = ["Assignments", "Users", "Profile", "Dashboard", "Students"]

const  MobileNavDrawer = (props) => {

    const {role : persona} = useSelector((state)=>state.user)
    const [navData, setNavData] = useState([])
    const {organization} = useSelector((state)=>state.organization)
    const {subscriptionV2} = useSelector((state)=>state.subscription)
    
    useEffect(() => {
        if (persona === "student") {
          setNavData(studentNav);
        } else if (persona === "tutor") {
          setNavData(tutorNav);
        } else if (persona === "parent") {
          setNavData(parentNav);
        } else if (persona === "admin") {
          setNavData(tempnavdata);
        } else if (persona === "superAdmin") {
          setNavData(supAdminNavData);
        } else if (persona === "contributor") {
          setNavData(contributorNavdata);
        } else if (persona === "manager") {
          setNavData(managerNavData);
        } else {
          setNavData([]);
        }
      }, [persona]);

    if(props.trigger === true) {
        return(
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.header}>
                        {
                          subscriptionV2 && isWhiteLabellingComponentAllowed(subscriptionV2?.subscription, "brandLogo")  && organization?.isEnabledOrgBusinessLogo === true ?
                          <img src = {organization.orgBussinessLogo} style={{height : "40px",maxHeight : "40px", objectFit : "contain"}} />
                          : 
                          <img src={evIconV2} />
                        }
                        <button onClick={(e)=>props.setTrigger(false)}><CrossIcon /></button>
                    </div>
                    <HorizontalDivider />
                    <div className={styles.navContainer}>
                        {
                            navData.map((item,idx)=>(
                                <>
                                <Link className={`${styles.navItem} ${forFill.includes(item.tooltip) ? styles.navItemFill : forStroke.includes(item.tooltip) ? styles.navItemStroke : ""}`} to={item.path} onClick={(e)=>props.setTrigger(false)}>
                                    <>{item.icon}</>
                                    <>{item.tooltip}</>
                                </Link>
                                {
                                    idx < navData.length -1 ? <HorizontalDivider /> : <></>
                                }
                                </>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return(<>
        </>)
    }

}


export default MobileNavDrawer