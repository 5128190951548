import React, { useEffect, useRef, useState } from 'react'
import CrossIcon from '../../../../assets/icons/SettingsIcon/CrossIcon'
import TooltipIcon from '../../../../assets/icons/SettingsIcon/TooltipIcon'
import PictureIcon from '../../../../assets/icons/SettingsIcon/PictureIcon'
import DustbinIcon from '../../../../assets/icons/SettingsIcon/DustbinIcon'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import ArrowIconLeftV2 from '../../../../components/ArrowIconLeftV2/ArrowIconLeftV2'
import imagePriview from '../../../../assets/icons/SettingsIcon/imagePriview.svg'
import AnnouncementCard from '../../../../components/AnnouncementCard/AnnouncementCard'
import LoaderIcon from '../../../../components/LoaderIcon/LoaderIcon'
import { createNewAnnouncement } from '../../../../utils/announcement'
import { triggerToast } from '../../../../utils/toastController'
import CustomDropDown from '../../YourBusiness/EditPopUps/components/CustomDropDown'
import { fetchAndSetOrgDetails } from '../../../../utils/orgService'

// const [picture, setPicture] = useState(null)

const EditAnnouncement = ({ setOpenEditAnnouncementModal, data, awsLink, orgId, currentId }) => {

    const textAreaRef2 = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const [details, setDetails] = useState({
        "file": null,
        "image": data.image,
        "header": data.header,
        "subText": data.subText,
        "link": data.link,
        "buttonText": data.buttonText,
        "op": "",
        "announcementId": currentId
    })

    async function handleSubmit(op) {
        if (op !== "delete" && details.file.size < 500000 && (details.file.type.split("/")[1] === "png" || details.file.type.split("/")[1] === "jpg" || details.file.type.split("/")[1] === "jpeg")) {
            details.op = op
            setIsLoading(true)
            let formData = new FormData()
            for (let item in details) {
                formData.append(item, details[item])
            }

            console.log(formData)
            await createNewAnnouncement(formData, orgId)
            fetchAndSetOrgDetails(orgId)
            setIsLoading(false)
            setOpenEditAnnouncementModal(false)
            triggerToast("Announcement updated successfully", "success", { autoClose: 3000 })
            return

        }
        else if (op === "delete") {
            details.op = op
            setIsLoading(true)
            let formData = new FormData()
            for (let item in details) {
                formData.append(item, details[item])
            }

            console.log(formData)
            await createNewAnnouncement(formData, orgId)
            fetchAndSetOrgDetails(orgId)

            setIsLoading(false)
            setOpenEditAnnouncementModal(false)
            triggerToast("Announcement Deleted successfully", "error", { autoClose: 3000 })
        }
        else {
            triggerToast("Image should be less than 500KB and type PNG/JPG/JPEG", "error", { autoClose: 3000 })
            return
        }

        details.op = op
        setIsLoading(true)
        let formData = new FormData()
        for (let item in details) {
            formData.append(item, details[item])
        }

        console.log(formData)
        await createNewAnnouncement(formData, orgId)
        fetchAndSetOrgDetails(orgId)
        console.log("Ahsaan")

        setIsLoading(false)
        setOpenEditAnnouncementModal(false)
        triggerToast("Announcement Deleted successfully", "error", { autoClose: 3000 })

    }
    useEffect(() => {
        if (textAreaRef2.current) {
            textAreaRef2.current.style.height = 'auto';
            textAreaRef2.current.style.height = textAreaRef2.current.scrollHeight + 'px';
        }
    }, [details]);


    return (
        <div className='w-full h-[160%] xl:h-full bg-secondary/30 top-0 left-0 absolute xl:fixed z-[2147483640]'>
            <div className='max-w-[960px] flex flex-col gap-6 xl:gap-9 p-4 xl:p-6 xl:rounded-xl m-auto relative top-0 xl:top-1/2 xl:-translate-y-1/2 w-full bg-white'>
                <div className='hidden xl:block'>
                    <div className='flex justify-between items-center py-[2px]'>
                        <h2 className='font-medium text-[20px] leading-[30px] xl:text-2xl xl:leading-[36px] text-blue-90'>Edit Announcement</h2>
                        <div className='cursor-pointer' onClick={() => setOpenEditAnnouncementModal(false)} ><CrossIcon /></div>
                    </div>
                    <div className='border-b border-blue-50 pt-2'></div>
                </div>
                <div className='font-medium text-[16px] leading-[16px] text-blue-70 flex items-center gap-1 cursor-pointer xl:hidden' onClick={() => setOpenEditAnnouncementModal(false)}>
                    <ArrowIconLeftV2 /> Back
                </div>
                <div className='xl:hidden'>
                    <h2 className='font-semibold text-[20px] leading-[30px] xl:text-2xl xl:leading-[36px] text-blue-90'>Edit Announcement</h2>
                </div>
                <div className='flex justify-between gap-4 flex-col xl:gap-0 xl:flex-row'>
                    <div className='max-w-[420px] w-full flex flex-col gap-4'>
                        <div>
                            <div className='font-medium text-[14px] leading-[17.5px] text-secondary flex gap-1 items-center'>
                                Announcement Image*
                                <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"This is the banner image that users will see on the announcement."} tooltipStyleClass={"bg-white w-[128px] xl:w-[240px] rounded-[12px] p-3 font-normal text-[12px] leading-[18px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"} />
                            </div>
                            <div className='mt-3'>
                                <input type="file" accept='image/png, image/jpg, image/jpeg' required id='chooseFile' hidden onChange={(e) => setDetails({ ...details, file: e.target.files[0] })} />
                                <div className={`font-normal text-[14px] leading-[17.5px] ${details?.file !== null ? "hidden" : "flex"} gap-3 xl:gap-6 items-start xl:items-end flex-col xl:flex-row`}>
                                    <label
                                        htmlFor="chooseFile"
                                        className='px-3 py-[6px] rounded bg-blue-70 hover:bg-blue-80 cursor-pointer text-white'
                                    >+ Upload Image</label>
                                    <span className='text-secondary-60'>JPG or PNG only. Max 500 kB.</span>
                                </div>
                                <div className={`${details?.file !== null ? "flex" : "hidden"} items-center gap-4`}>
                                    <div className='flex items-center gap-2 font-normal text-base text-secondary-80'>
                                        <PictureIcon />
                                        {details?.file?.name}
                                    </div>
                                    <div onClick={(e) => setDetails({ ...details, file: null })}>
                                        <DustbinIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label className='flex pb-1 gap-1 items-center font-medium text-[14px] leading-[17.5px] text-secondary' htmlFor=""> Header* <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"Add a header for the announcement. Max 30 characters."} tooltipStyleClass={"bg-white w-[150px] xl:w-[240px] rounded-[12px] p-3 font-normal text-[12px] leading-[18px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"} /> </label>
                            <input
                                type="text"
                                required
                                value={details.header}
                                placeholder='Enter header. Max 30 characters.'
                                className='px-4 py-[14px] rounded-md outline-none border border-secondary-30 w-full focus:border-blue-50 font-normal text-base leading-[20px] placeholder:text-secondary-60 text-secondary'
                                onChange={(e) => {
                                    if (e.target.value.length > 30) return;
                                    console.log(e.target.value.length)
                                    setDetails({ ...details, header: e.target.value })
                                }}
                            />
                        </div>
                        <div>
                            <label className='flex pb-1 gap-1 items-center font-medium text-[14px] leading-[17.5px] text-secondary' htmlFor=""> Sub-text <span className='text-secondary-80'>(optional)</span> <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"Add a sub-text (optional) for the announcement. Max 120 characters."} tooltipStyleClass={"bg-white w-[160px] xl:w-[240px] rounded-[12px] p-3 font-normal text-[12px] leading-[18px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"} /> </label>
                            <textarea
                                ref={textAreaRef2}
                                rows={1}
                                type="text"
                                placeholder='Max 120 characters.'
                                className='px-4 py-[15px] rounded-md outline-none border border-secondary-30 w-full focus:border-blue-50 font-normal text-base leading-[20px] placeholder:text-secondary-60 text-secondary resize-none overflow-y-hidden'
                                onChange={(e) => {
                                    if (e.target.value.length > 120) return
                                    console.log(e.target.value.length)
                                    setDetails({ ...details, subText: e.target.value })
                                }}
                                value={details.subText}
                            ></textarea>
                            <div className={`${details.subText?.length >= 120 ? "block" : "hidden"} text-red-400`}>Max 120 characters allowed.</div>
                        </div>
                        <div>
                            <label className='flex pb-1 gap-1 items-center font-medium text-[14px] leading-[17.5px] text-secondary' htmlFor=""> Hyperlink* <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"This is the URL of the webpage to which your clients will be directed to when they click on the button in the announcement card."} tooltipStyleClass={"bg-white w-[200px] xl:w-[240px] rounded-[12px] p-3 font-normal text-[12px] leading-[18px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"} /> </label>
                            <input
                                type="text"
                                value={details.link}
                                required
                                placeholder='Enter URL'
                                className='px-4 py-[14px] rounded-md outline-none border border-secondary-30 w-full focus:border-blue-50 font-normal text-base leading-[20px] placeholder:text-secondary-60 text-secondary'
                                onChange={(e) => setDetails({ ...details, link: e.target.value })}
                            />
                        </div>
                        <div>
                            <label className='flex pb-1 gap-1 items-center font-medium text-[14px] leading-[17.5px] text-secondary' htmlFor=""> Button Call-to-Action (CTA) <Tooltip anchorComponent={<TooltipIcon />} tooltipContent={"This is the Call-to-Action (CTA) text that is shown on the button."} tooltipStyleClass={"bg-white w-[100px] xl:w-[240px] rounded-[12px] p-3 font-normal text-[12px] leading-[18px] text-secondary-70 shadow-[0px_4px_24px_0px_#11167D26]"} /> </label>
                            <CustomDropDown
                                list={["None", "View", "Learn More", "Enroll", "Register", "Join Us", "Try Now"]}
                                placeholder={"CTA Button"}
                                listIsArray={true}
                                selectedValue={details.buttonText}
                                setSelectedValue={(val) => {
                                    setDetails({ ...details, buttonText: val })
                                }}
                                maxHeight={"max-h-[100px]"}
                            />
                        </div>
                    </div>
                    <div className='border-l min-h-full hidden xl:block'></div>
                    <div className='max-w-[420px] w-full'>
                        <h2 className='font-semibold text-[15px] leading-[22.5px] text-secondary'>Preview</h2>
                        <div className='font-normal text-sm text-secondary-80 mb-3'>This shows how the announcement will appear to parents and students on their dashboard.</div>
                        <div className='relative h-[190px] overflow-hidden mt-3'>
                            <img className='w-full h-full object-cover' src={details.file ? URL.createObjectURL(details.file) : data.image ? awsLink + data.image : imagePriview} alt="" />
                            {/* <img className='w-full h-full object-cover' src={data.image ? awsLink + data.image : details.file ? URL.createObjectURL(details.file) : imagePriview} alt="" /> */}
                            <div className='absolute top-0 left-0 w-full h-full flex flex-col justify-between'>
                                <div className='px-3 pt-3' style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%)", height: "100%" }}>
                                    <h1 className='font-semibold text-20 leading-[25px] text-white break-words'>{details.header ? details.header : "Header"}</h1>
                                    <div className='font-normal text-[16px] leading-[19px] text-white break-words'>{details.subText ? details.subText : "This is a sample sub-text, which will be shown beneath the header."}</div>
                                </div>
                                <div className='px-3 pb-3'>
                                    <button className='rounded-md px-5 py-[10px] bg-blue-70 text-white font-medium text-[16px] leading-[20px]'>{details.buttonText ? details.buttonText : "Button"}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='flex flex-col-reverse xl:flex-row gap-9 justify-between items-center'>
                    <div>
                        <button className='text-red-400 underline font-medium text-base underline-offset-2 cursor-pointer' onClick={() => handleSubmit("delete")}>Delete Announcement</button>
                    </div>
                    <div className='flex items-center justify-center gap-3'>
                        <button className='px-6 py-[8px] w-[173px] text-center border-2 border-secondary-60 rounded-md font-semibold text-[16px] leading-[20px] text-secondary-60' onClick={() => setOpenEditAnnouncementModal(false)}>Cancel</button>
                        <button disabled={isLoading} className='px-6 py-[10px] w-[173px] text-center rounded-md font-semibold text-[16px] leading-[20px] text-white bg-blue-70 flex justify-center items-center' onClick={(e) => { handleSubmit("update") }}>
                            {isLoading ? <LoaderIcon /> : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAnnouncement