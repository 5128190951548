import HorizontalDivider from "../HorizontalDivider/HorizontalDivider";
import styles from "./SubscriptionTable.module.css";

const SubscriptionTable = ({ header, body, columns, gridConfigClass,gridHorizontalDividerClass }) => {
  return (
    <div className={`${styles.container} ${gridConfigClass}`}>
      {header?.map((data, idx) => (
        <>
          <div className={styles.cell}>
            <div className={`${styles.header} ${data.format}`}>{data.text}</div>
          </div>
        </>
      ))}
      <div className={`${gridHorizontalDividerClass}`}>
        <HorizontalDivider />
      </div>
      {body?.map((row, idx) => (
        <>
          {row.data.map((val, idx) => (
            <div className={`${styles.cell} `}>
              <div
                className={`${styles.body} ${
                  !row.status && idx > 0 ? styles.inactive : ""
                } ${idx === 0 ? "!text-left" : ""}`}
              >
                {val}
              </div>
            </div>
          ))}
          {idx < body.length - 1 ? (
            <div className={`${gridHorizontalDividerClass}`}>
              <HorizontalDivider />
            </div>
          ) : (
            <></>
          )}
        </>
      ))}
    </div>
  );
};

export default SubscriptionTable;
