import { useEffect, useRef, useState } from "react";
import {
  EvChevronDownIcon,
  EvChevronUpIcon,
} from "../../assets/icons/ChevronIcons";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import SearchFieldV2 from "../SearchFieldV2/SearchFieldV2";
import styles from "./RoundedDropdown.module.css";

function DropdownArea({
  children,
  trigger,
  setTrigger,
  isSearchable,
  filterText,
  setFilterText,
}) {
  console.log(
    "rounded dropdown",
    children,
    trigger,
    setTrigger,
    isSearchable,
    filterText,
    setFilterText
  );
  return (
    <>
      {trigger && (
        <div className={styles.dropdownContainer}>
          {isSearchable && (
            <div className="sticky top-[-6px] bg-white w-full">
              <SearchFieldV2
                textFieldValue={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />{" "}
            </div>
          )}
          {children}
        </div>
      )}
    </>
  );
}

//type=["with-count","without-count"]
function RoundedDropdown({
  defaultLabelText,
  selectedValue,
  type,
  count,
  children,
  filterText,
  setFilterText,
  isSearchable,
  extDropdownTrigger,
  setExtDropdownTrigger,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef(null);

  useOutsideAlerter(ref, () => {
    setIsDropdownOpen(false);
    if (setExtDropdownTrigger) {
      setExtDropdownTrigger(false);
    }
  });

  function onDDArrowTriggerClick() {
    if (setExtDropdownTrigger) {
      setExtDropdownTrigger((prev) => !prev);
    } else {
      setIsDropdownOpen((prev) => !prev);
    }
  }

  useEffect(() => {
    if (extDropdownTrigger === false) {
      setIsDropdownOpen(false);
    } else if (extDropdownTrigger === true) {
      setIsDropdownOpen(true);
    }
  }, [extDropdownTrigger]);
  console.log(
    "roundeddropdown",
    defaultLabelText,
    selectedValue,
    type,
    count,
    children,
    filterText,
    setFilterText,
    isSearchable,
    extDropdownTrigger,
    setExtDropdownTrigger
  );
  return (
    <div className="cursor-pointer relative" ref={ref}>
      <div
        className={`${styles.container} ${styles[type]} ${
          type === "without-count"
            ? styles[selectedValue ? "selected" : "normal"]
            : ""
        } ${
          type === "with-count" ? styles[count > 0 ? "selected" : "normal"] : ""
        }`}
        onClick={() => onDDArrowTriggerClick()}
      >
        {type === "with-count" && (
          <div className={styles.countDisplay}>{count}</div>
        )}
        <span>
          {type === "with-count"
            ? defaultLabelText
            : selectedValue
            ? selectedValue
            : defaultLabelText}
        </span>
        <button>
          {isDropdownOpen ? <EvChevronUpIcon /> : <EvChevronDownIcon />}
        </button>
      </div>
      <DropdownArea
        trigger={extDropdownTrigger ?? isDropdownOpen}
        setTrigger={setExtDropdownTrigger ?? setIsDropdownOpen}
        filterText={filterText}
        setFilterText={setFilterText}
        isSearchable={isSearchable}
      >
        {children}
      </DropdownArea>
    </div>
  );
}

export default RoundedDropdown;
