import CrossIcon from '../CrossIcon/CrossIcon'
import styles from './PillContainer.module.css'
import { forwardRef } from 'react'

const PillContainer = forwardRef(function ({customClass,items, isCrossButtonVisible, onCrossButtonClick, withoutBorder,children}, ref) {
    return(
        <div className={`${styles.pillContainer} ${customClass}`} style={{border : withoutBorder ? "none" : "", padding : withoutBorder ? "0px" : ""}} ref={ref}>
            {
                items?.map((item,idx)=>(
                    <div className={styles.pill}>
                        {item.displayTitle}
                        {
                            isCrossButtonVisible && onCrossButtonClick ? 
                                <button onClick={(e)=>onCrossButtonClick(item,idx)}>
                                    <CrossIcon height="14px" width="14px"/>
                                </button>
                            : <></> 
                        }
                    </div>
                ))
            }
            {
                children && 
                <div className={styles.childrenContainer}>
                    {children}
                </div>
            }
        </div>
    )
})



export default PillContainer