import { deleteSessions,deleteConsultation, deleteBookedMeeting, deleteInternalMeeting,deleteOfficeHours } from "../../../utils/eventService";

import frozenEventTypes from "../../../utils/eventTypes";
import { triggerToast } from "../../../utils/toastController";
//If these get too big extract out to a different file

async function sessionDeleter(targetSessionId, deleteFutureSessions) {
  try {
    const res = await deleteSessions(targetSessionId, deleteFutureSessions);
    triggerToast("Session(s) deleted successfully.", "success", {
      autoClose: 3000,
    });
  } catch (err) {
    triggerToast("Some error occured while deleting the session(s).", "error", {
      autoClose: 3000,
    });
  }
}

async function consultationDeleter(targetSessionId, deleteFutureSessions) {
  try {
    const response = await deleteConsultation(
      targetSessionId,
      deleteFutureSessions
    );
    triggerToast("Consultation(s) deleted successfully.", "success", {
      autoClose: 3000,
    });
  } catch (error) {
    console.error("Error deleting consultation:", error);
    triggerToast(
      "Some error occured while deleting the consultation(s).",
      "error",
      { autoClose: 3000 }
    );
  }
}

async function internalMeetingDeleter(targetSessionId, deleteFutureSessions) {
  try {
    const response = await deleteInternalMeeting(
      targetSessionId,
      deleteFutureSessions
    );
    triggerToast("Internal meeting(s) deleted successfully.", "success", {
      autoClose: 3000,
    });
  } catch (error) {
    console.error("Error deleting internal meeting:", error);
    triggerToast(
      "Some error occurred while deleting the internal meeting(s).",
      "error",
      { autoClose: 3000 }
    );
  }
  

}

function officeHoursDeleter(targetSessionId, deleteFutureSessions) { }


async function bookedMeetingDeleter(targetId) {

  try {
    const res = await deleteBookedMeeting(targetId);
    triggerToast("Booked meeting deleted successfully.", "success", {
      autoClose: 3000,
    });
  } catch (err) {
    triggerToast("Some error occured while deleting the booked meeting", "error", {
      autoClose: 3000,
    });
  }

}

async function deleteOfficeWork(targetId) {
  console.log(">>>>>>>>", "DELETE One")

  try {
    const res = await deleteOfficeHours(targetId);
    triggerToast("Office work deleted successfully.", "success", {
      autoClose: 3000,
    });
  } catch (err) {
    triggerToast("Some error occured while deleting the Office work", "error", {
      autoClose: 3000,
    });
  }

}

function deleteController(eventType) {
  switch (eventType.toLowerCase()) {
    case frozenEventTypes.SESSION.eventType.toLowerCase():
      return sessionDeleter;
    case frozenEventTypes.INTMEETING.eventType.toLowerCase():
      return internalMeetingDeleter;
    case frozenEventTypes.OFFCWORK.eventType.toLowerCase():
      return deleteOfficeWork;
    case frozenEventTypes.CONSULTATION.eventType.toLowerCase():
      return consultationDeleter;
    case frozenEventTypes.EXTMEETING.eventType.toLowerCase():
      return bookedMeetingDeleter
  }
}

export default deleteController;
