import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import DownArrow2 from "../../assets/YIcons/Vectordrop.svg";
import DownArrow from "../../assets/icons/down-chevron.svg";
import UpArrow from "../../assets/icons/chevron-up-solid (1).svg";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import CCheckbox from "../CCheckbox/CCheckbox";
import questionMark from "../../assets/images/question-mark.svg";
import newStyles from "./InputSelect.module.css";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import miniLock from "./assets/miniLock.svg";
import caution from "./assets/caution.svg";
import Tooltip2 from "../Tooltip/Tooltip";

export default function InputSelect({
  tooltipText,
  parentClassName,
  disableFunction,
  disableClass,
  Icon,
  hideRight,
  value,
  downArrow22,
  placeholder,
  placeholderClass,
  label,
  labelClassname,
  optionData,
  inputContainerClassName,
  onChange,
  noAsteric,
  radio,
  checkbox,
  optionClassName,
  optionType,
  disabled,
  required,
  optionListClassName,
  IconRight,
  IconLeft,
  DateSelect,
  IconSearch,
  tableDropdown,
  customArrow,
  customArrowClassName,
  downArrowClassName,
  questionMarkIcon,
  Tooltip,
  questionMarkMargin,
  hideDropdownIcon,
  dropDownClass,
  singleOptionClassName,
  bgColor,
  keepOpen,
  fetchData,
  isTutorForGroupOps,
  isTimerEnabled,
  scrollToTime
}) {
  const [selected, setSelected] = useState(false);
  const selectRef = useRef();
  // console.log(selectRef)
  const [scrollToLetter, setScrollToLetter] = useState(null);
  const optionsRef = useRef(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (scrollToLetter) {
      const regex = new RegExp(`^${scrollToLetter}`, "i");
      let index = 0;
      const element = optionData?.find((item, idx) => {
        if (optionType === "object") {
          if (regex.test(item.name)) {
            index = idx;
          }
          return regex.test(item.name);
        } else {
          if (regex.test(item)) {
            index = idx;
          }
          return regex.test(item);
        }
      });
      if (element && optionsRef.current) {
        const itemHeight = 35.93;
        optionsRef.current.scrollTop = itemHeight * index;
      }
      setScrollToLetter(null); // Reset scrollToLetter
    }
  }, [scrollToLetter, optionData]);

  //Scroll to time for time input
  const _optionsRef = useCallback((node)=>{
    if(node){
      let _idxOfTime = null
      optionData.forEach((val,idx)=>{
        if(val === scrollToTime){
          _idxOfTime = idx
        }
      })
      console.log("IDX TIME",_idxOfTime)

      if(_idxOfTime && node) {
        const itemHeight = 35.93
        node.scrollTop = itemHeight * _idxOfTime
      }
    }
  })


  //Scroll to time for time input
  // useEffect(()=>{
  //   if(scrollToTime && optionsRef.current){
  //     let _idxOfTime = null
  //     optionData.forEach((val,idx)=>{
  //       if(val === scrollToTime){
  //         _idxOfTime = idx
  //       }
  //     })
  //     console.log("IDX TIME",_idxOfTime)

  //     if(_idxOfTime && optionsRef.current) {
  //       const itemHeight = 35.93
  //       optionsRef.current.scrollTop = itemHeight * _idxOfTime
  //     }
      
  //   }
  // },[scrollToTime, optionData])


  useEffect(() => {
    const handleKeyPress = (e) => {
      setScrollToLetter(e.key);
    };
    if (selected) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("keydown", handleKeyPress);
    }
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [selected]);

  useOutsideAlerter(selectRef, () => {
    setSelected(false);
  });

  useEffect(() => {
    if (fetchData) {
      if ((selected === false) & (isUpdated === true)) fetchData();
    }
  }, [selected, isUpdated]);

  const handleOption = () => {
    console.log(">>>HANDLE OPTION");
    setSelected(!selected);
  };

  const handleToggleSelected = (e) => {
    if (keepOpen && !e.target.id.startsWith("main")) return;
    setSelected(!selected);
    if (selected === true && isUpdated) fetchData();
  };

  useEffect(() => {
    if (!checkbox) setSelected(false);
  }, [value]);

  const handleChange = (optionType, option, idx) => {
    onChange(optionType === "object" ? option : option, idx);
    setIsUpdated(true);
  };

  return (
    <div
      ref={selectRef}
      className={`${selected && "relative z-5000"}  ${
        parentClassName ? parentClassName : ""
      } ${disabled === true ? " pointer-events-none text-secondary-30 cursor-not-allowed" : ""} `}
      onClick={(e) => handleToggleSelected(e)}
      id="main_div"
    >
      {isTutorForGroupOps === true && showTooltip === true ? (
        <div className={`${newStyles.tooltip}`}>
          <h2>Read Only Tutor Operations</h2>
          <div>
            To unmap the tutor from the assigned groups, please use the tutor
            mapping option, placed above in the same page. <br />
          </div>
        </div>
      ) : (
        <></>
      )}

      {placeholder === "Time Zones" || (
        <div className="flex items-center ">
          {label && (
            <div className="flex justify-evenly ">
              <label
                className={`font-medium ${
                  label == "User Type" && "text-sm"
                } text-[#26435F] inline-block  ${labelClassname} ${
                  isTimerEnabled ? "text-secondary-30" : ""
                }`}
              >
                {label}

                {required && !noAsteric && (
                  <span className="text-primaryRed inline-block pl-1">*</span>
                )}
              </label>
              {isTimerEnabled && (
                <Tooltip2
                  anchorComponent={
                    <span className="flex items-center justify-center w-[30px] -m-t-1">
                      <img src={miniLock} />
                    </span>
                  }
                  tooltipContent={
                    <>
                      <div className="flex justify-start p-2">
                        <img src={caution} />{" "}
                        <span className="font-[500] px-2">
                          Upgrade Your Plan
                        </span>
                      </div>
                      <div className="text-[12px] p-2 text-[#636D89] font-[400]">
                        {tooltipText}
                      </div>
                    </>
                  }
                  tooltipStyleClass={
                    "bg-white mt-[40px] -ml-[60px] w-[350px] px-[6px] py-[6px] rounded-[6px] z-[1000000] shadow-md text-[16px] text-[#25335A]"
                  }
                  tooltipTriangleStyling={
                    "absolute top-0 left-0 -mt-[18px] ml-[38px] w-0 h-0 border-l-[8px] border-l-transparent border-r-[8px] border-r-transparent border-b-[8px] border-white"
                  }
                />
              )}
            </div>
          )}

          {label == "Default Time Zone" && (
            <div className="group relative" >
              <img
                className={`${
                  questionMarkMargin ? questionMarkMargin : `ml-3`
                } cursor-pointer  `}
                questionMarkIcon
                src={questionMarkIcon ? questionMarkIcon : questionMark}
                alt=""
              ></img>
              {Tooltip}
            </div>
          )}
        </div>
      )}

      <div
        onClick={() => {
          setSelected(true);
        }}
        className={`py-[13px] px-[21px]  bg-[${
          bgColor ? bgColor : ""
        }] flex items-center radius5 relative cursor-pointer z-50 ${
          inputContainerClassName ? inputContainerClassName : ""
        } ${disableClass ? "overflow-y-visible" : ""} ${
          isTimerEnabled ? "pointer-events-none cursor-not-allowed" : ""
        }`}
      >
        {Icon && (
          <img
            src={Icon}
            className={`mr-5  w-[28px]}`}
            alt="icon"
            id="main_img"
          />
        )}
        {IconLeft && (
          <img
            src={IconLeft}
            className={`mr-5 ${
              customArrowClassName ? "!h-[18.75px] !max-w-[18.75px]" : ""
            }}`}
            alt="IconLeft"
            id="main_img"
          />
        )}
        {IconSearch && (
          <img
            src={IconSearch}
            className={`mt-[3px] ${customArrowClassName}`}
            alt="IconLeft"
            id="main_img"
          />
        )}
        {!hideDropdownIcon && (
          <>
            {selected
              ? IconRight
                ? IconRight
                : !IconLeft &&
                  !hideRight && (
                    <img
                      src={customArrow ? customArrow : DownArrow}
                      className={`${
                        customArrow
                          ? `w-[20px] h-[20px] rotate-180 ${customArrowClassName}`
                          : `w-[16.273px] h-[9.373px] ${
                              dropDownClass ? "w-[29px] h-[29px]" : null
                            }  ${
                              selected ? "!rotate-180 !translate-y-[-4px]" : ""
                            }`
                      }   ${styles.downArrow} ${downArrowClassName}`}
                      alt="down-arrow"
                      id="main_img"
                    />
                  )
              : IconRight
              ? IconRight
              : !IconLeft &&
                !hideRight && (
                  <img
                    src={customArrow ? customArrow : DownArrow}
                    className={`${
                      customArrow
                        ? `w-[20px] h-[20px] ${customArrowClassName}  ${dropDownClass}`
                        : `w-[16.273px] h-[9.373px] ${
                            dropDownClass ? "w-[29px] h-[29px]" : null
                          }`
                    }   ${styles.downArrow} ${downArrowClassName}`}
                    alt="down-arrow"
                    id="main_img"
                  />
                )}
          </>
        )}
        {!isTimerEnabled ? (
          <>
            <div
              className={`outline-0 w-full  relative ${
                optionClassName ? optionClassName : ""
              }`}
              name={label}
            >
              {value === "" || !value ? (
                <span
                  className={`text-secondary-60 text-[17.5px] whitespace-nowrap ${
                    tableDropdown ? "mr-0" : "mr-10"
                  }  ${placeholderClass}`}
                  id="main_span"
                >
                  {" "}
                  {placeholder}{" "}
                </span>
              ) : (
                <div
                  className={`mr-10 text-[17.5px] whitespace-nowrap  ${
                    tableDropdown ? "mr-0" : "mr-10"
                  }  ${placeholderClass} ${disabled === true ? "text-secondary-30" :"" } `}
                  id="main_span"
                >
                  {value}
                </div>
              )}
            </div>
            {selected && (
              <div
                id="dropdown_container_ipsel"
                onClick={handleOption}
                ref={scrollToTime ? _optionsRef : optionsRef}
                className={`shadow-[0px_0px_3px_0px_#00000040] ${styles.options}`}
                style={{
                  overflow: disableClass ? "visible" : "",
                  minHeight: disableClass ? "220px" : "",
                }}
              >
                {DateSelect && DateSelect}
                {optionData?.map((option, idx) => {
                  return (
                    <div
                      className={`outline-0 border-0 text-start text-[17.5px] py-2 px-4 flex items-center justify-between text-base-15`}
                      key={idx}
                      onClick={() => {
                        if (disableFunction && disableFunction(idx)) return;
                        if (isTutorForGroupOps === true) return;
                        handleChange(optionType, option, idx);
                      }}
                      id={"option" + idx}
                      onMouseEnter={(e) => {
                        if (isTutorForGroupOps === true) {
                          setShowTooltip(true);
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (isTutorForGroupOps === true) {
                          setShowTooltip(false);
                        }
                      }}
                    >
                      <div className={`${optionListClassName}`}>
                        {idx > 0 && disableClass ? (
                          <Tooltip2
                            anchorComponent={
                              <div>
                                {optionType === "object" ? option.name : option}
                              </div>
                            }
                            tooltipContent={<div>{tooltipText}</div>}
                            tooltipStyleClass={
                              "bg-white px-[6px] py-[6px] rounded-[6px] z-[1000000] w-[250px] shadow-md text-[16px] text-[#25335A]"
                            }
                          />
                        ) : optionType === "object" ? (
                          option.name
                        ) : (
                          option
                        )}
                      </div>
                      {radio && (
                        <input
                          type="radio"
                          name="name"
                          checked={option === value ? true : false}
                        />
                      )}
                      {checkbox && (
                        <div className="flex">
                          <CCheckbox
                            checked={
                                checkbox.checkedFunction ? checkbox.checkedFunction(option) : (option &&
                                option?._id &&
                                checkbox?.match?.includes(option?._id))
                                ? true
                                : false
                            }
                            name="student"
                            disabled={isTutorForGroupOps}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        ) : (
          <div className="pointer-events-none cursor-not-allowed">Regular</div>
        )}
      </div>
    </div>
  );
}
