export const studentsServed = [
  {
    text: "Early Childhood Education",
    checked: false,
  },
  {
    text: "Elementary / Primary School",
    checked: false,
  },
  {
    text: "Middle School",
    checked: false,
  },
  {
    text: "Secondary /High School",
    checked: false,
  },
  {
    text: "Undergraduate Students",
    checked: false,
  },
  {
    text: "Graduate Students",
    checked: false,
  },
  {
    text: "Ph.D.  Students",
    checked: false,
  },
];

export const instructionFormat = [
  {
    text: "Online",
    checked: false,
  },
  {
    text: "In person",
    checked: false,
  },
  {
    text: "Classroom",
    checked: false,
  },
  {
    text: "1-on-1",
    checked: false,
  },
];
