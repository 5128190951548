import React from 'react'

const ExitIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6667 1.83203H8.66667C8.48986 1.83203 8.32029 1.90227 8.19526 2.02729C8.07024 2.15232 8 2.32189 8 2.4987C8 2.67551 8.07024 2.84508 8.19526 2.9701C8.32029 3.09513 8.48986 3.16536 8.66667 3.16536H12.6667C12.8435 3.16536 13.013 3.2356 13.1381 3.36063C13.2631 3.48565 13.3333 3.65522 13.3333 3.83203V13.1654C13.3333 13.3422 13.2631 13.5117 13.1381 13.6368C13.013 13.7618 12.8435 13.832 12.6667 13.832H8.66667C8.48986 13.832 8.32029 13.9023 8.19526 14.0273C8.07024 14.1523 8 14.3219 8 14.4987C8 14.6755 8.07024 14.8451 8.19526 14.9701C8.32029 15.0951 8.48986 15.1654 8.66667 15.1654H12.6667C13.1971 15.1654 13.7058 14.9546 14.0809 14.5796C14.456 14.2045 14.6667 13.6958 14.6667 13.1654V3.83203C14.6667 3.3016 14.456 2.79289 14.0809 2.41782C13.7058 2.04274 13.1971 1.83203 12.6667 1.83203Z" fill="#F04438" />
            <path d="M5.13851 10.6931C5.20218 10.7546 5.25297 10.8282 5.28791 10.9095C5.32285 10.9908 5.34124 11.0783 5.34201 11.1668C5.34278 11.2554 5.32591 11.3431 5.29239 11.4251C5.25887 11.507 5.20937 11.5814 5.14677 11.644C5.08418 11.7066 5.00974 11.7561 4.92781 11.7897C4.84588 11.8232 4.75809 11.84 4.66957 11.8393C4.58105 11.8385 4.49358 11.8201 4.41224 11.7852C4.3309 11.7502 4.25734 11.6994 4.19584 11.6358L1.52918 8.96911C1.40419 8.84409 1.33398 8.67455 1.33398 8.49778C1.33398 8.321 1.40419 8.15146 1.52918 8.02644L4.19584 5.35978C4.25734 5.2961 4.3309 5.24531 4.41224 5.21037C4.49358 5.17544 4.58105 5.15704 4.66957 5.15628C4.75809 5.15551 4.84588 5.17237 4.92781 5.20589C5.00974 5.23941 5.08418 5.28892 5.14677 5.35151C5.20937 5.41411 5.25887 5.48854 5.29239 5.57047C5.32591 5.6524 5.34278 5.74019 5.34201 5.82871C5.34124 5.91723 5.32285 6.00471 5.28791 6.08605C5.25297 6.16738 5.20218 6.24094 5.13851 6.30244L3.60984 7.83111H11.3338C11.5107 7.83111 11.6802 7.90135 11.8052 8.02637C11.9303 8.1514 12.0005 8.32096 12.0005 8.49778C12.0005 8.67459 11.9303 8.84416 11.8052 8.96918C11.6802 9.0942 11.5107 9.16444 11.3338 9.16444H3.60984L5.13851 10.6931Z" fill="#F04438" />
        </svg>

    )
}

export default ExitIcon