import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptionPopUpTrigger : false,
    subscriptionPopUpOperationMode : "new-subscription",
    isSignupProcessComplete : false,
    initialTeamSize : 1
}

const subscriptionOperation = createSlice({
    name : "subscriptionOperation",
    initialState : initialState,
    reducers : {
        toggleSubscriptionPopUpTrigger : (state,{payload}) => {
            state.subscriptionPopUpTrigger = payload 
        },
        updateSubscriptionOperationMode : (state, {payload}) => {
            state.subscriptionPopUpOperationMode = payload
        },
        updateIsSignupProcessComplete : (state, {payload}) => {
            state.isSignupProcessComplete = payload
        }, 
        updateInitialTeamSize : (state,{payload})=>{
            if(payload <=0 ){
                state.initialTeamSize = 1
            } else {
                state.initialTeamSize = payload
            }
        }
    }
})

export const {toggleSubscriptionPopUpTrigger, updateSubscriptionOperationMode, updateIsSignupProcessComplete, updateInitialTeamSize} = subscriptionOperation.actions
export default subscriptionOperation.reducer;