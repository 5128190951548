import React, { useState } from "react";
import "./formStepper.css";
import FinishCircle from "../StepsIndicators/FinishCircle";
import ActiveCircleWithBar from "../StepsIndicators/ActiveCircleWithBar";
import CircleWithBar from "../StepsIndicators/CircleWithBar";
import ActiveCircle from "../StepsIndicators/ActiveCircle";
import FinishedCircleBar from "../StepsIndicators/FinishedCircleBar";

const FormStepper = ({ stage }) => {
  const [atStep, setAtStep] = useState(stage);

  const stepNames = [
    "Create Account",
    "Business Info",
    "Choose Plan",
    "Review & Pay",
  ];

  function stepSelector(i, step) {
    if (i + 1 == 1) {
      return null;
    } else if (step == i + 1) {
      return <ActiveCircleWithBar />;
    } else if (step > i + 1) {
      return <FinishedCircleBar />;
    } else {
      return <CircleWithBar />;
    }
  }

  return (
    <div className="w-full h-full">
      <div className="w-fit m-auto h-20 relative ">
        <div className="flex items-center">
          {atStep == 1 ? <ActiveCircle /> : <FinishCircle />}
          {stepNames.map((el, i) => {
            return stepSelector(i, atStep);
          })}
        </div>
        <div className="flex justify-between mt-2 w-[130%] absolute left-1/2 -translate-x-1/2">
          {stepNames.map((el, i) => {
            return (
              <span
                className={`w-full font-normal text-[16px] leading-6 text-center ${
                  atStep == i + 1
                    ? "text-[#0671E0] font-extrabold text-20 leading-8"
                    : atStep > i + 1
                    ? "text-[#0671E0]"
                    : "text-[#7C859C]"
                }`}
              >
                {el}
              </span>
            );
          })}
        </div>
      </div>
      {/* <button className='bg-green-500 px-4 py-2 ml-2' onClick={() => checkLimits(atStep, "decrement")}>Back</button>
            <button className='bg-orange-500 px-4 py-2 ml-2' onClick={() => checkLimits(atStep, "increment")}>Next</button>
         */}
    </div>
  );
};

export default FormStepper;
