import HorizontalDivider from '../../HorizontalDivider/HorizontalDivider'
import PermissionsEditor from './Components/PermissionsEditor'
import styles from './Permissions.module.css'


const Permissions = (props) => {
    return(
        <div className={styles.container}>
            <h4>Set default states and values for these options:</h4>
            <div className={styles.horizontalRule}>
                <HorizontalDivider />
            </div>
            <PermissionsEditor />
        </div>
    )
}

export default Permissions