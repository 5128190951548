import React from "react";
import infoIcon from "../../assets/calendar/infoModal.svg";
import closeIcon from "../../assets/calendar/close.svg";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

const CalenderModal = ({ children, setActive, heading, dividerColor,selectedTimezone, setSelectedTimezone }) => {
  return (
    <main className="flex items-center justify-center md:fixed z-20 absolute  top-0 left-0 w-full h-full ">
      {/* Background transparent color section */}
      <section
        className="hidden md:block fixed top-0 left-0 bg-[rgba(37,51,90,0.3)] w-full h-full"
        onClick={() => setActive(false)}
      />

      {/* Main modal area section */}

      {/* For Desktop version */}
      <section className=" hidden w-fit z-10 p-[24px] md:w-fit md:min-w-[580px] rounded-[12px] md:flex flex-col gap-[24px] bg-white ">
        <div className={`flex justify-between items-center  border-b-[1px] ${dividerColor ? dividerColor : "border-b-[#45A0FF]"} pb-[8px]`}>
          <div className=" flex justify-start gap-[6px] items-center ">
            <h1 className="text-[#00468E] text-[20px] font-medium leading-[30px] ">
              {heading}
            </h1>
            <img src={infoIcon} alt="" className=" w-[16px] h-[16px] " />
          </div>

          <img
            src={closeIcon}
            alt=""
            onClick={() => setActive(false)}
            className=" cursor-pointer"
          />
        </div>

        {children}
      </section>

      {/* /* For Mobile version */}
      <section className="w-full  h-full flex-1 z-10 p-[16px] flex md:hidden flex-col gap-[24px] bg-white ">
        <h1 className=" text-[#0671E0] w-fit text-[16px] leading-[16px] font-semibold  flex items-center justify-start " onClick={() => setActive(false)}>
          <ChevronLeft className="w-[16px] h-[16px] " /> Back
        </h1>

        <div className=" flex justify-start gap-[6px] items-center ">
          <h1 className="text-[#25335A] text-[20px] font-semibold leading-[30px] ">
            {heading}
          </h1>
          <img src={infoIcon} alt="" className=" w-[16px] h-[16px] " />
        </div>

        <div className=" h-[calc(100%-100px)]">
          {children}
        </div>
      </section>
    </main>
  );
};

export default CalenderModal;
