import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import PopupContainer from '../../../../PopupContainer/PopupContainer'
import styles from './ServicesOperationPopUp.module.css'
import CrossIcon from '../../../../CrossIcon/CrossIcon'
import PillContainer from '../../../../PillContainer/PillContainer';
import LoaderIcon from '../../../../LoaderIcon/LoaderIcon';
import AddTextInput from '../../../../AddTextInput/AddTextInput';
import strLengthConfig from '../../../../../utils/strLengthConfig.json'


const ServicesOperationPopUp = ({ trigger, setTrigger, serviceData, handleSaveClick, popUpOperationMode }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [heading, setHeading] = useState("Add Service")
    const [operationMode, setOperationMode] = useState("add")
    const [tempServiceData, setTempServiceData] = useState(null)
    const [tempServiceName, setTempServiceName] = useState(null)

    useEffect(() => {
        if (popUpOperationMode === "edit") {
            setHeading("Edit Service")
        } else {
            setHeading("Add Service")
        }
    }, [popUpOperationMode])

    useEffect(() => {
        if (serviceData) {
            setTempServiceData({ ...serviceData })
            setTempServiceName(serviceData.service)
        } else {
            setTempServiceData({
                service: "",
                specialization: []
            })
            setTempServiceName("")
        }


    }, [serviceData, trigger])

    useEffect(()=>{
        if(tempServiceName){
            setTempServiceData((prev) => ({ ...prev, service: tempServiceName }))
        }
    },[tempServiceName])

    return (
        <PopupContainer trigger={trigger} setTrigger={setTrigger} customCloseButton={true}>
            <div className={styles.container}>
                <div className={styles.headingContainer}>
                    <>{heading}</>
                    <button onClick={(e) => {setTrigger(false)}}><CrossIcon /></button>
                </div>
                <div className={styles.inputGroup}>
                    <label>Service Name*</label>
                    <input type="text" className={styles.insertInput} placeholder="Enter service name"  value={tempServiceName} onChange={(e)=>{if(e.target.value.length <= strLengthConfig.services){setTempServiceName(e.target.value)}}}  />
                </div>
                <div className={styles.inputGroup}>
                    <label>Add Topics</label>
                    <AddTextInput allowSpaces={true} validationFunction={(targetValue) => {return targetValue.length <= strLengthConfig.topics}} values={tempServiceData?.specialization} handleAddToInput={(targetValue)=>setTempServiceData((prev)=>({...prev, specialization : [targetValue, ...prev.specialization]}))}/>
                </div>

                <PillContainer customClass={styles.pillContainerClass} items={tempServiceData?.specialization.map(val => ({ displayTitle: val }))} withoutBorder={true} isCrossButtonVisible={true} onCrossButtonClick={(item, idx) => { setTempServiceData((prev) => ({ ...prev, specialization: prev.specialization.filter((val, _idx) => _idx !== idx) })) }} />

                <div className={styles.actionArea}>
                    <button className={styles.cancelButton} onClick={(e) => setTrigger(false)}>Cancel</button>
                    {
                        isLoading ? 
                            <div className={styles.saveButton}><LoaderIcon /> </div>
                            :
                            <button className={styles.saveButton} onClick={(e) => handleSaveClick(tempServiceData?.idx, tempServiceData, setIsLoading)} disabled={tempServiceName?.trim() === ""}>Save</button>

                    }
                </div>
            </div>

        </PopupContainer>
    )
}


export default ServicesOperationPopUp