const CustomTestsIcon = (props) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_28465_32975)">
                <path d="M49.9191 8.5H42.2391C39.4114 8.5 37.1191 10.7923 37.1191 13.62V21.3C37.1191 24.1277 39.4114 26.42 42.2391 26.42H49.9191C52.7469 26.42 55.0391 24.1277 55.0391 21.3V13.62C55.0391 10.7923 52.7469 8.5 49.9191 8.5Z" fill="white" stroke="#17A289" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.7609 8.5H14.0809C11.2532 8.5 8.96094 10.7923 8.96094 13.62V21.3C8.96094 24.1277 11.2532 26.42 14.0809 26.42H21.7609C24.5886 26.42 26.8809 24.1277 26.8809 21.3V13.62C26.8809 10.7923 24.5886 8.5 21.7609 8.5Z" stroke="#17A289" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M49.9191 36.6602H42.2391C39.4114 36.6602 37.1191 38.9524 37.1191 41.7802V49.4602C37.1191 52.2879 39.4114 54.5802 42.2391 54.5802H49.9191C52.7469 54.5802 55.0391 52.2879 55.0391 49.4602V41.7802C55.0391 38.9524 52.7469 36.6602 49.9191 36.6602Z" stroke="#17A289" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21.7609 36.6602H14.0809C11.2532 36.6602 8.96094 38.9524 8.96094 41.7802V49.4602C8.96094 52.2879 11.2532 54.5802 14.0809 54.5802H21.7609C24.5886 54.5802 26.8809 52.2879 26.8809 49.4602V41.7802C26.8809 38.9524 24.5886 36.6602 21.7609 36.6602Z" stroke="#17A289" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_28465_32975">
                    <rect width="64" height="64" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CustomTestsIcon