import React from "react";
import ButtonV2 from "../../ButtonV2/ButtonV2";
import styles from "./DisablePayGPopUp.module.css";

const DisablePayGPopUp = ({ limitExhaused,_setIsEnabledPAYG, setPopUpTriger}) => {

  return (
    <div className={styles.container}>
      <div className={styles.title}>Disable Pay As You Go</div>
      <div className="flex flex-col items-center justify-start gap-[16px]">
        <div className={`${styles.title} ${styles.mod}`}>
          Are you sure you want to disable Pay As You Go feature?
        </div>
        <div className={`${styles.helper}`}>
          You have exhausted your monthly limits on one or more products. If PAYG is disabled, you will lose access to them until this billing cycle ends and your subscription renews.
        </div>
      </div>
      <div className="flex justify-center items-center gap-[12px]">
        <ButtonV2
          className={`${
            limitExhaused
              ? styles.confirmButton
              : styles.confirmButtonNonExhausted
          }`}
          onClick={(e)=>{_setIsEnabledPAYG(false);setPopUpTriger(false)}}
        >
          Yes, confirm
        </ButtonV2>
        <ButtonV2
          className={`${
            limitExhaused ? styles.backButton : styles.backButtonNonExhausted
          }`}
        >
          Back
        </ButtonV2>
      </div>
    </div>
  );
};

export default DisablePayGPopUp;
