import { useNavigate } from 'react-router-dom';
import ArrowIconLeftV2 from '../ArrowIconLeftV2/ArrowIconLeftV2'
import styles from './GlobalBackButton.module.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


export default function GlobalBackButton (props) {
    
    const navigate = useNavigate()

    const handleBackButtonClick = (e) => {
        window.history.back()
    }

    return(
        <div className={styles.container} onClick={(e)=>handleBackButtonClick(e)}>
            <ChevronLeftIcon />
            <button className={styles.backButton}>Back</button>
        </div>
    )
}