import React, { useEffect } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const CalendarChevronNav = ({date, setDate, selectedView="week", calRef, modifyDateOnClick }) => {

  function modifyDate(mode) {
		setDate(date.add( mode === "prev" ? -1 : 1 ,selectedView))
  }

  useEffect(()=>{
		if(calRef.current) {
			const calAPI = calRef.current.getApi()
            calAPI.gotoDate(date.toDate());
		}

  },[date])

  return (
    <div className="flex gap-[10px]">
      <button className="border border-secondary-20 text-secondary-80 rounded-full  flex items-center justify-center" onClick={(e)=>modifyDateOnClick("prev")}>
        <NavigateBeforeIcon />
      </button>
      <button className="border border-secondary-20 text-secondary-80 rounded-full  flex items-center justify-center" onClick={(e)=>modifyDateOnClick("next")}>
        <NavigateNextIcon />
      </button>
    </div>
  );
};

export default CalendarChevronNav;
