import React from "react";
import EmptyStateImg from "./empty.png";
import Empty_assignment from "./Empty_latestassignment.svg";
import styles from "./style.module.css";
import parentdashboard_emptystate from "./parentdashboard_emptystate.svg";
import { Link, useNavigate } from "react-router-dom";
import Studentdashboardd from "./Studentdashboard.svg";
import { useSelector } from "react-redux";
import lock from "../../../../assets/YIcons/lock.svg";
import ButtonV2 from "../../../../components/ButtonV2/ButtonV2";
import RoundLockedIcon from "../../../../components/RoundLockedIcon/RoundLockedIcon";
export default function AssignmentEmptyState(props) {
  const navigate = useNavigate();
  const { role: persona } = useSelector((state) => state.user);
  const { subscriptionV2 } = useSelector((state) => state.subscription);

  const handleClick = () => {
    if (persona === "admin" || persona === "admin") {
      navigate(`/assigned-tests`);
    } else {
      navigate(`/all-tests`);
    }
  };

  if (props.origin) {
    switch (props.origin) {
      case "concept":
        if (!subscriptionV2?.subscription.conceptualStatistics.isEnabled) {
          return (
            <div>Please upgrade your package to activate this feature </div>
          );
        }
      case "score-prog":
        if (!subscriptionV2?.subscription.progressTracking.isEnabled) {
          return (
            <div>Please upgrade your package to activate this feature </div>
          );
        }
    }
  }

  if (props.isEnabled) {
    return (
      <div className={`h-full relative`}>
        <Link
          to={
            persona === "admin" || persona === "admin"
              ? "/assigned-tests"
              : "/all-tests"
          }
          className={` ${props.latestassi == true
            ? "w-[240px] left-[681px] top-[276px] h-[40px] absolute"
            : props.parentdashboard == true
              ? "w-[176px] h-[40px] absolute top-[217px] left-[52px]"
              : props.studentdashboard == true
                ? " w-[176px] h-[40px] absolute top-[175px] left-[51px]"
                : "w-[176px] h-[40px] absolute top-[219px] left-[325px]"
            } z-[1000000]`}
        ></Link>
        <img
          src={
            props.latestassi == true
              ? Empty_assignment
              : props.parentdashboard == true
                ? parentdashboard_emptystate
                : props.studentdashboard == true
                  ? Studentdashboardd
                  : EmptyStateImg
          }
          className={`${props.parentdashboard == true ? "!h-full" : ""} ${props.studentdashboard == true ? "w-[1076.250px]" : ""
            }`}
        />
      </div>
    )
  }

  return (
    <div className={`h-full relative`}>
      <Link
        to={
          persona === "admin" || persona === "admin"
            ? "/assigned-tests"
            : "/all-tests"
        }
        className={` ${props.latestassi == true
          ? "w-[240px] left-[681px] top-[276px] h-[40px] absolute"
          : props.parentdashboard == true
            ? "w-[176px] h-[40px] absolute top-[217px] left-[52px]"
            : props.studentdashboard == true
              ? " w-[176px] h-[40px] absolute top-[175px] left-[51px]"
              : "w-[176px] h-[40px] absolute top-[219px] left-[325px]"
          } z-[1000000]`}
      ></Link>
      <img
        src={
          props.latestassi == true
            ? Empty_assignment
            : props.parentdashboard == true
              ? parentdashboard_emptystate
              : props.studentdashboard == true
                ? Studentdashboardd
                : EmptyStateImg
        }
        className={`${props.parentdashboard == true ? "!h-full" : ""} ${props.studentdashboard == true ? "w-[1076.250px]" : ""
          }`}
      />
      {
        persona === "admin" ?
          <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col items-center justify-center gap-[24px] bg-white h-full ${styles.lockedState}`}>
            <RoundLockedIcon />
            <div className="text-[24px] text-secondary font-[500]">
              Upgrade to a paid plan to unlock this section.
            </div>
            <div>
              <ButtonV2
                className={styles.manageSubBtn}
                onClick={(e) => {
                  triggerUpdateSubscriptionPopUp()
                }}
              >
                Manage Subscription
              </ButtonV2>
            </div>
          </div>
          :
          <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col items-center justify-center gap-[24px] bg-white h-full ${styles.lockedState}`}>
            <RoundLockedIcon />
            <div className="text-[24px] text-secondary font-[500]">
              This feature is locked.
            </div>
            <div className="text-secondary font-[400] text-[18px] text-center -mt-[12px]">
              This feature is not available with the current subscription plan of the organisation.
            </div>
          </div>
      }
    </div>
  );
}
