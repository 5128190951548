import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";

const DisablePayGPopUp = ({ _setIsEnabledPAYG, setOpen, open }) => {
  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[98%] md:w-[640px] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Disable Pay As You Go</div>
        <button onClick={(e) => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="grid gap-[12px]">
        <div className="text-[20px] text-secondary-80 font-[500] text-center">
          Are you sure you want to disable Pay As You Go feature?
        </div>
        <div className="text-secondary-60 text-[16px] font-[400] text-center">
          You have exhausted your monthly limits on one or more products. If
          PAYG is disabled, you will lose access to them until this billing
          cycle ends and your subscription renews.
        </div>
      </div>
      <div className="flex gap-[12px] justify-center">
        <div
          className="text-secondary-60 basis-1/2 md:basis-3/12 text-center border-[2px] border-secondary-60 px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] cursor-pointer justify-center"
          onClick={(e) => setOpen(false)}
        >
          Cancel
        </div>
        <div
          className={`${"bg-blue-70 text-white cursor-pointer"} basis-1/2 md:basis-3/12  text-center  px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
          onClick={() => {
            _setIsEnabledPAYG(false);
            setOpen(false);
          }}
        >
          Yes Confirm
        </div>
      </div>
    </PopupContainer>
  );
};

export default DisablePayGPopUp;
