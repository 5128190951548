import PlusIcon from "../../assets/icons/SettingsIcon/PlusIcon"
import LockIcon from "../../assets/Settings/LockIcon"
import styles from "./TabSelector.module.css"

const TabItem = ({ tabTitle, isSelected, idx, onClick, tabItemSelectedClass, tabItemClass,selectedLineHeight }) => {
    return (
        <button className={isSelected ? `${styles.tabItemSelected} ${tabItemSelectedClass}` : `${styles.tabItem} ${tabItemClass}`} {...{ onClick: () => onClick(idx) }}>
            {tabTitle}
            {
                isSelected ? <div className={`${styles.selectedLine} ${selectedLineHeight}`} /> : <></>
            }
        </button>
    )
}


const DividerPill = () => {
    return (
        <div className={styles.dividerPill}>
            &nbsp;
        </div>
    )
}


// const TabSelector = ({ items, selectedIdx, onClick, customClass }) => {
//     return (
//         <div className={`${styles.container} ${customClass}`}>
//             {
//                 items?.map((tabTitle, idx) => (
//                     <>
//                         <TabItem {...{ tabTitle, isSelected: idx === selectedIdx, idx, onClick }} />
//                         {
//                             idx < items.length - 1 ? <DividerPill /> : <></>
//                         }
//                     </>
//                 ))
//             }
//         </div>
//     )
// }

const TabSelector = ({ items, selectedIdx, onClick, customClass, componentName, planName, tabItemSelectedClass, tabItemClass,selectedLineHeight,showDividerPill = true }) => {
    return (
        <div className={`${styles.container} ${customClass}`}>
            {
                items?.map((tabTitle, idx) => (
                    <>
                        <div className="flex items-center gap-1 h-full">
                            {componentName === "customSignup" && idx === 2 && <PlusIcon />}
                            <TabItem {...{ tabTitle, isSelected: idx === selectedIdx, idx, onClick, tabItemSelectedClass, tabItemClass,selectedLineHeight }} />
                            {planName !== "Premium" && componentName === "customSignup" && idx === 2 && <div className="pl-2"><LockIcon /></div>}
                        </div>
                        {
                           showDividerPill && (idx < items.length - 1 ? <DividerPill /> : <></>)
                        }

                    </>
                ))
            }
        </div>
    )
}

export default TabSelector
