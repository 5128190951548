import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import InputSelect from "./InputSeletDateNew";
import { calculateDateRange, getModifiedDate } from "./utils";
import styles from "./rangeDate.module.css";
import downR from "../../assets/YIcons/downR.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getFormattedDate, getMonthName } from "../../utils/utils";
import DateIcon from "../../assets/icons/solar_calendar-date-outline.svg";

const RangeDate = (props) => {
  const {
    removeUnderline,
    allorg,
    handleRangeData,
    optionClassName,
    className,
    manualHide,
    inputContainerClassName,
    iconRightClass,
    icon,
    underline,
    pageName,
    hideunderline,
    defaultDays,
  } = props;
  const { dateFormat } = useSelector((state) => state.user);
  const { organization: organization2 } = useSelector(
    (state) => state.organization
  );

  useEffect(() => {
    if (defaultDays === 30) {
      handleQuickOptions(null, { name: "Last 30 Days", days: 30 }, null);
    } else if (defaultDays === 1000) {
      handleQuickOptions(null, { name: "Lifetime", days: 1000 }, null);
    }
  }, [defaultDays]);

  const [startDate, setStartDate] = useState(() => calculateDateRange()[0]);
  const [displayDate, setDisplayDate] = useState(null);
  const [selectDate, setSelectedDate] = useState({
    sDate: "2023-12-02",
    eDate: "2023-12-02",
  });
  const [selectedDaysNumber, setSelectedDaysNumber] = useState(0);
  const [redDate, setRedDate] = useState(null);
  const handleLocalDate = (e, value) => {
    setRedDate(e);
    setSelectedDate({
      ...selectDate,
      [value]: e,
    });
  };
  const handleStartDate = () => {
    const requiredDate = getModifiedDate(selectDate, dateFormat);
    const requiredDate2 = getModifiedDate(selectDate);
    // console.log('selectDate--', selectDate);
    // console.log('requiredDate--', requiredDate);

    setStartDate(requiredDate);
    handleRangeData(requiredDate2);
    setDisplayDate(requiredDate);
    setSelectedDate({ sDate: "", eDate: "" });
    setSelectedDaysNumber(-1);
  };
  const handleQuickOptions = (optionType, option, id) => {
    const now = new Date();
    let startDate, endDate;
    if (option.days === 0) {
      startDate = now.toISOString().split("T")[0];
      endDate = now.toISOString().split("T")[0];
      setSelectedDaysNumber(0);
    } else if (option.days === 7) {
      startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0];
      endDate = now.toISOString().split("T")[0];
      setSelectedDaysNumber(7);
    } else if (option.days === 30) {
      startDate = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0];
      endDate = now.toISOString().split("T")[0];
      setSelectedDaysNumber(30);
    } else if (option.days === 31) {
      startDate = new Date(now.getFullYear(), now.getMonth(), 2)
        .toISOString()
        .split("T")[0];
      endDate = now.toISOString().split("T")[0];
      setSelectedDaysNumber(31);
    } else if (option.days === 60) {
      startDate = new Date(now.getTime() - 60 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0];
      endDate = new Date(now.getFullYear(), now.getMonth(), 1)
        .toISOString()
        .split("T")[0];
      setSelectedDaysNumber(60);
    } else if (option.days === 365) {
      startDate = new Date(now.getFullYear(), 0, 2).toISOString().split("T")[0];
      endDate = new Date().toISOString().split("T")[0];
      setSelectedDaysNumber(365);
    } else if (option.days === 700) {
      startDate = new Date(now.getFullYear() - 1, 0, 2)
        .toISOString()
        .split("T")[0];
      endDate = new Date(now.getFullYear(), 0, 1).toISOString().split("T")[0];
      setSelectedDaysNumber(700);
    } else if (option.days === 1000) {
      let year = 2000;
      let month = 0;
      let date = 2;
      if (organization2.createdAt) {
        let createdAtDate = new Date(organization2.createdAt);
        year = createdAtDate.getFullYear();
        month = createdAtDate.getMonth();
        date = createdAtDate.getDate();
      }
      startDate = new Date(year, month, date).toISOString().split("T")[0];
      endDate = new Date().toISOString().split("T")[0];
      setSelectedDaysNumber(1000);
    }
    const selectDate = {
      sDate: startDate.toString(),
      eDate: endDate.toString(),
    };
    const requiredDate = getModifiedDate(selectDate, dateFormat);
    const requiredDate2 = getModifiedDate(selectDate);
    // console.log({ startDate })
    // console.log({ endDate })
    setDisplayDate(requiredDate);
    setStartDate(requiredDate);
    handleRangeData(requiredDate2, { startDate, endDate });
  };

  useEffect(() => {
    if (startDate) {
      if (defaultDays) {
      } else {
        handleRangeData(startDate);
      }
    }
  }, [defaultDays]);
  let temp = startDate.split("-");

  //   console.log('displayDate--', displayDate);
  // console.log('temp--', temp);

  let newDateformat = temp[0];
  temp = temp[1].split(" ");
  let firsYear = newDateformat.split(" ");
  if (false && firsYear[2] === temp[3]) {
    newDateformat = firsYear[0] + " " + firsYear[1];
  }
  newDateformat +=
    " - " + temp[0] + " " + temp[1] + " " + temp[2] + ", " + temp[3];
  // console.log(temp[0])
  const parts = newDateformat.split(" - ");
  const firstPart = getFormattedDate(parts[1], dateFormat);
  const secondPart = getFormattedDate(parts[0], dateFormat);
  const latestDateFormat = secondPart + " - " + firstPart;

  let [startFull, endFull] = latestDateFormat.split(" - ");

  const [, , endYear] = startFull.split("-");

  const [startMonth, startDay] = startFull.split("-");

  const formatPrentDashboardDate = (value, dateType) => {
    const [year, month, day] = value.split("-");
    const monthName = getMonthName(day - 1);
    let updatedDate1 = "";
    if (dateFormat === "dd/mm/yy") {
      updatedDate1 = `${day} ${monthName?.substring(0, 3)} `;
    } else if (dateFormat === "mm/dd/yy") {
      updatedDate1 = `${monthName?.substring(0, 3)} ${day} `;
    } else {
      updatedDate1 = `${monthName?.substring(0, 3)} ${day} `;
    }
    const formattedDate =
      dateType === "start"
        ? pageName === "ParentDashboard"
          ? updatedDate1
          : `${year} ${monthName?.substring(0, 3)} `
        : `${month} ${monthName} , ${year}`;
    return formattedDate;
  };

  const formatDate = (value, dateType) => {
    const [month, day, year] = value.split("-");
    const monthName = getMonthName(day - 1);
    let updatedDate1 = "";
    if (dateFormat === "dd/mm/yy") {
      updatedDate1 = `${day} ${monthName?.substring(0, 3)} `;
    } else if (dateFormat === "mm/dd/yy") {
      updatedDate1 = `${monthName?.substring(0, 3)} ${day} `;
    } else {
      updatedDate1 = `${monthName?.substring(0, 3)} ${day} `;
    }
    // console.log('value', value);
    const formattedDate =
      dateType === "start"
        ? pageName === "AdminDashboard"
          ? updatedDate1
          : `${year} ${monthName?.substring(0, 3)} `
        : `${year} ${monthName?.substring(0, 3)} , ${month}`;
    return formattedDate;
  };

  // console.log({ latestDateFormat });
  // console.log({startDay});
  // console.log({endYear});
  const formattedStartDate =
    pageName === "ParentDashboard"
      ? formatPrentDashboardDate(
          `${startMonth}-${startDay}-${endYear}`,
          "start"
        )
      : formatDate(`${startMonth}-${startDay}-${endYear}`, "start");
  endFull =
    pageName === "ParentDashboard"
      ? formatPrentDashboardDate(endFull)
      : formatDate(endFull);
  const formattedDateRange = `${formattedStartDate}  -  ${endFull}`;

  const formatDateStr = (dateStr, format) => {
    let result = dateStr;
    if (format === "yy/mm/dd") {
      const [year, month, day] = dateStr.split("-");
      result = `${year.slice(-2)}/${month}/${day}`;
    } else if (format === "yyyy/mm/dd") {
      result = dateStr.replace(/-/g, "/");
    } else if (format === "mm/dd/yy") {
      const [year, month, day] = dateStr.split("-");
      result = `${month}/${day}/${year.slice(-2)}`;
    }
    return result;
  };

  const selectDateStart = formatDateStr(selectDate.sDate, dateFormat);
  const selectDateEnd = formatDateStr(selectDate.eDate, dateFormat);

  return (
    <div className={`flex  text-[15px] ${styles.container} ${className}`}>
      <p className="font-semibold text-[#24A3D9]"> </p>
      <InputSelect
        placeholder="Select"
        valueClassName={`${
          removeUnderline ? "" : "font-semibold text-[15px]"
        } ${
          allorg ? "!text-gray-500 " : "text-[#24A3D9]"
        } cursor-pointer items-center ${hideunderline ? "!no-underline" : ""}`}
        parentClassName="border-none text-xs text-[#26435F] w-fit relative z-[500] text-[17.5px] items-center"
        labelClassname="text-sm text-[17.5px]"
        inputContainerClassName={`border-none text-[17.5px] whitespace-nowrap font-normal ${
          allorg ? "" : "text-[#24A3D9]"
        } text-[#24A3D9] ${inputContainerClassName}  ${styles["text"]} `}
        inputClassName={`placeholder:uppercase border-none w-fit bg-transparent font-semibold ${
          allorg ? "" : "text-[#24A3D9]"
        } text-[17.5px]`}
        value={displayDate ? displayDate : formattedDateRange}
        optionListClassName="text-[#517CA8] underline underline-offset-[3px] text-[15px]"
        optionClassName={`${optionClassName} relative text-[17.5px] `}
        optionContainerClassName={`${
          allorg ? "translate-x-[30px]" : ""
        } !rounded-5  border-[#24A3D9] border-[1px]  !py-[10px] text-left scrollbar-content !h-[265px] overflow-x-scroll`}
        optionPadding="!py-1 mb-[5px]"
        optionData={[
          { name: "Lifetime", days: 1000 },
          { name: "Last 7 Days", days: 7 },
          { name: "Last 30 Days", days: 30 },
          // {name:"Current Month",days: 31 },
          { name: "This Year", days: 365 },

          // {name:"Last Year",days: 700},
          // {name:"Life Time",days:1000}
        ]}
        selectedDaysNumber={selectedDaysNumber}
        valueClass={"mr-0"}
        optionType={"object"}
        setSelectedDate={setSelectedDate}
        onChange={handleQuickOptions}
        IconRight={
          allorg
            ? icon === null || icon === undefined
              ? DateIcon
              : icon
            : downR
        }
        IconRightClass={`${
          allorg
            ? iconRightClass ?? "w-[50px] h-[20px] ml-[-10px] fill-[24A3D9]"
            : ""
        }`}
        DateSelect={
          !manualHide && (
            <div
              className={`flex relative flex-col hover:bg-white  pt-[13px] z-5000`}
            >
              <div className="text-[9px] text-[#517CA8] w-full pl-[26px] leading-none">
                <label htmlFor="sdate">Start Date</label>
              </div>
              <div className="flex flex-col">
                <div
                  className={`relative ${styles.inputDateContainer} flex items-center`}
                >
                  {selectDate.sDate === "" && (
                    <p className="absolute top-[0px] left-[26px] w-[110px] text-[#24A3D9] cursor-pointer bg-white text-[14px]">
                      {dateFormat === "yy/mm/dd"
                        ? "YYYY-MM-DD"
                        : dateFormat === "mm/dd/yy"
                        ? "MM-DD-YYYY"
                        : dateFormat === "dd/mm/yy"
                        ? "DD-MM-YYYY"
                        : "DD-MM-YYYY"}
                    </p>
                  )}
                  {selectDate.sDate !== "" && (
                    <p className="absolute top-[0px] left-[26px] w-[110px] text-[#24A3D9] cursor-pointer bg-white text-[14px] leading-none">
                      {selectDateStart}
                    </p>
                  )}
                  <input
                    type="date"
                    name="sdate"
                    className="rounded-md cursor-pointer text-[#24A3D9] pl-[26px] uppercase !text-[14px]"
                    placeholder=""
                    //value={selectDate.sDate}
                    max={selectDate.eDate}
                    onChange={(e) => handleLocalDate(e.target.value, "sDate")}
                  />
                </div>
                <div className="text-[9px] text-[#517CA8] w-full pl-[26px] pt-[5px] leading-none">
                  <label htmlFor="edate">End Date</label>
                </div>
                <div
                  className={`relative ${styles.inputDateContainer} flex items-center`}
                >
                  {selectDate.eDate === "" && (
                    <p className="absolute top-[0px] left-[26px] w-[110px] text-[#24A3D9] cursor-pointer bg-white text-[14px]">
                      {dateFormat === "yy/mm/dd"
                        ? "YYYY-MM-DD"
                        : dateFormat === "mm/dd/yy"
                        ? "MM-DD-YYYY"
                        : dateFormat === "dd/mm/yy"
                        ? "DD-MM-YYYY"
                        : "DD-MM-YYYY"}
                    </p>
                  )}
                  {selectDate.eDate !== "" && (
                    <p className="absolute top-[0px] left-[26px] w-[110px] text-[#24A3D9] cursor-pointer bg-white text-[14px] leading-none">
                      {selectDateEnd}
                    </p>
                  )}
                  <input
                    type="date"
                    min={selectDate.sDate}
                    name="edate"
                    id="edate"
                    className="rounded-md cursor-pointer !text-[#24A3D9] pl-[26px] uppercase !text-[14px] "
                    value={selectDate.eDate}
                    placeholder=""
                    onChange={(e) => handleLocalDate(e.target.value, "eDate")}
                  />
                </div>
              </div>
              <div className="w-full flex pl-[26px]">
                <p className="ml-[0px]">
                  <button
                    disabled={!selectDate.eDate || !selectDate.sDate}
                    className={`${
                      !selectDate.eDate || !selectDate.sDate
                        ? "opacity-75 font-normal"
                        : ""
                    } rounded-[4px] bg-[#24A3D9] mt-[15px] text-center  text-white hover:shadow-sm hover:scale-105  cursor-pointer h-[20px] w-[60px] text-[12px] font-normal`}
                    onClick={handleStartDate}
                  >
                    Submit
                  </button>
                </p>
              </div>
            </div>
          )
        }
      />
      <p></p>
    </div>
  );
};

export default RangeDate;
