function InvoiceDetailsInput({placeholder, value, setValue}){
	
	return(
		<div className='grid rounded-[6px] px-[12px] py-[7.5px] bg-[#F6F9FA] grid-cols-[auto_1fr_auto] justify-between w-[240px] text-secondary-80 font-[lexend] items-center gap-[6px]'>
			<span className='text-[14px] leading-[21px] font-[500]'>$</span>
			<input type="number" placeholder={placeholder} className="text-[14px] leading-[21px] placeholder:text-secondary-60 text-secondary-80 font-[500] bg-transparent w-[145px] focus:outline-none" value={value} onChange={(e)=>setValue(e.target.value)}/>
			<span className='font-[400] text-[12px] leading-[18px] text-nowrap'>per hour</span>
		</div>
	)
}


export default InvoiceDetailsInput
