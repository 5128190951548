import React, { useState, useRef, useEffect } from "react";
import ChevronDown from "./assets/ChevronDown.svg";
import ChevronUp from "./assets/ChevronUp.svg";

const SummaryPriceSelectDropdown = ({
  priceList,
  selectedPrice,
  onChange,
  disabled,
  selectedPlanType,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedPriceObj, setSelectedPriceObj] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const foundPrice = priceList.find((price) => price.value === selectedPrice);
    setSelectedPriceObj(foundPrice || { name: "Select Price", unit_amount: 0 });
  }, [selectedPrice, priceList]);

  const sortedPriceList = [...priceList].sort(
    (a, b) => a.unit_amount - b.unit_amount
  );

  const getAdjustedName = (name) => {
    const numberMatch = name?.match(/\d+/);
    if (numberMatch) {
      const number = parseInt(numberMatch[0], 10);
      return selectedPlanType !== 0 ? (number / 12).toFixed(0) : number;
    }
    return name;
  };

  return (
    <div
      ref={dropdownRef}
      className={`relative w-full font-medium text-left text-[14px] ${
        disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
      }`}
    >
      <div
        className="flex justify-between items-center h-[16px]"
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <div className="overflow-hidden text-ellipsis whitespace-nowrap flex-grow text-[14px] font-normal text-secondary-60">
          {getAdjustedName(selectedPriceObj?.name?.split(" ")[0]) + " " + title}

          {/* {selectedPriceObj?.name.split(" ")[0]} for{" "}
          <span className="font-[600]">
            {selectedPriceObj?.unit_amount !== 0
              ? selectedPlanType === 0
                ? `$${(selectedPriceObj?.unit_amount / 100).toFixed(0)}`
                : `$${(selectedPriceObj?.unit_amount / 100 / 12).toFixed(0)}`
              : "Free"}
          </span> */}
        </div>
        <span className="ml-2">
          {isOpen ? <img src={ChevronUp} /> : <img src={ChevronDown} />}
        </span>
      </div>
      {isOpen && (
        <div className="absolute cursor-pointer w-[200px] border text-[14px] rounded-md bg-white z-10 h-fit overflow-y-auto mt-1 shadow-md">
          {sortedPriceList?.map((price) => (
            <div
              key={price.value}
              className={`py-[6px] px-[12px] transition-colors duration-200  font-[400] ${
                price.value === selectedPrice
                  ? "bg-[#F4F5F7]"
                  : "hover:bg-[#F4F5F7]"
              }`}
              onClick={() => {
                onChange(price.value);
                setIsOpen(false);
              }}
            >
              <span className="text-secondary ">
                {getAdjustedName(price.name.split(" ")[0])}
              </span>{" "}
              for{" "}
              <span className="font-[600] text-secondary">
                {price.unit_amount !== 0
                  ? selectedPlanType === 0
                    ? `$${(price.unit_amount / 100).toFixed(0)}`
                    : `$${(price.unit_amount / 100 / 12).toFixed(0)}`
                  : "Free"}
              </span>
              {price.unit_amount !== 0 && (
                <span>{selectedPlanType === 0 ? "/mo" : "/mo"}</span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SummaryPriceSelectDropdown;
