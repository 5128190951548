import React from "react";
import { useSelector } from "react-redux";
import { triggerToast } from "../../../../utils/toastController";

const EditCardPopUp = ({
  makeDefault,
  isDefault,
  setOpenDelete,
  setDeleteThisCard,
}) => {
  const { paymentMethods, subscriptionV2 } = useSelector(
    (state) => state.subscription
  );

  console.log(
    "condition",
    new Date(subscriptionV2?.subscription.endDate) > new Date(),
    subscriptionV2?.subscription.endDate
  );
  console.log(
    "condition",

    paymentMethods.length === 1
  );

  console.log(
    "condition",

    !subscriptionV2?.subscription?.basePackage?.name?.includes("Free")
  );
  return (
    <div className="w-[180px] absolute flex flex-col -right-[1px] top-[45px] md:top-[40px] justify-center rounded-[6px]  py-[8px] bg-white shadow-md gap-[4px] z-10">
      {!isDefault && (
        <div
          className="text-[16px] leading-[16px] text-secondary hover:bg-secondary-10 rounded-[6px] px-[10px] py-[8px]"
          onClick={() => {
            makeDefault();
          }}
        >
          Set as default
        </div>
      )}

      {paymentMethods.length === 1 &&
      !subscriptionV2?.subscription?.basePackage?.name?.includes("Free") ? (
        ""
      ) : (
        <div
          className="text-[16px] leading-[16px] text-error hover:bg-secondary-10 rounded-[6px] px-[10px] py-[8px]"
          onClick={() => {
            if (
              new Date(subscriptionV2?.subscription.endDate) > new Date() &&
              paymentMethods.length === 1 &&
              !subscriptionV2?.subscription?.basePackage?.name?.includes("Free")
            ) {
              triggerToast(
                <div>
                  Unable to remove payment info as you have pending charges on
                  your account. To view the billing details, please visit the{" "}
                  <Link to="/manage-subscription">Manage Subscription</Link>{" "}
                  page
                </div>,
                "error",
                { autoClose: false }
              );
              return;
            }
            setOpenDelete(true);
            setDeleteThisCard();
          }}
        >
          Delete card
        </div>
      )}
    </div>
  );
};

export default EditCardPopUp;
