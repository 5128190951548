import styles from './PageIndicator.module.css'


const PageIndicatorV2 = ({recordStartNo, recordEndNo, totalRecordsCount}) => {

    return (
        <div className={styles.container}>
            Showing {recordStartNo} - {recordEndNo} of {totalRecordsCount}
        </div>
    )

}


export default PageIndicatorV2 