import AppRoutes from "./navigation/AppRoutes";
import "./App.css";
// import "./pages/responsive.css"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import {
  updateAwsLink,
  updateDateFormat,
  updateIsLoggedIn,
  updateUserDetails,
} from "./app/slices/user";
import {
  useLazyGetOrganizationQuery,
  useLazyGetPersonalDetailQuery,
  useLazyGetTutorDetailsQuery,
} from "./app/services/users";
import { updateOrganization } from "./app/slices/organization";
import { useLazyGetLogoutQuery } from "./app/services/superAdmin";
import Loader from "./components/Loader";
import "react-toastify/dist/ReactToastify.css";
import Layout2 from "./pages/Layout/Layout2";
import { hotjar } from "react-hotjar";
import { ToastContainer, toast } from "react-toastify";
import {
  checkForOldSubscriptions,
  fetchAndSetSubscription,
  fetchArchivedSubscriptionsForOrg,
} from "./utils/subscriptionService";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLazySubscriptionInsightsQuery } from "./app/services/subscription";
import testStyle from "./components/GlobalTestingComponent/GlobalTestingComponent.module.css";
import { triggerToast } from "./utils/toastController";
import {
  setAlertPreferencesOnLogin,
  setAnnualShowAlertOn100Usage,
  setAnnualShowAlertOn90Usage,
  setShowAlertOn50Usage,
  setShowAlertOnD_PAYG100Usage,
  setShowAlertOnPAYG90Usage,
  showAlertOn50Usage,
  showAlertOnCancellationDowngrade,
  showAlertOnD_PAYG100Usage,
  showAlertOnPAYG90Usage,
  showAlertOnPaymentFailedDowngrade,
  showAnnualAlertOn100Usage,
  showAnnualAlertOn90Usage,
  showPAYGStartedAlert,
  showSubscriptionRenewedAlert,
} from "./utils/alertService";
import { useUpdateOrgDataMutation } from "./app/services/admin";
import SmallDevicesBlockerScreen from "./components/SmallDevicesBlockerScreen/SmallDevicesBlockerScreen";
import SubscriptionOperationPopUp from "./components/SubscriptionOperationPopup/SubscriptionOperationPopUp";
import { toggleSubscriptionPopUpTrigger, updateIsSignupProcessComplete } from "./app/slices/subscriptionOperation";
import { updateIcons } from "./components/BrandPopup/BrandLogo";

function App() {
  const [loading, setLoading] = useState(true);
  const [fetchPersonalDetails, personalDetailsResp] =
    useLazyGetPersonalDetailQuery();
  const [fetchOrganization, fetchOrganizationResp] =
    useLazyGetOrganizationQuery();
  const dispatch = useDispatch();
  const [logOutApi, setLogOutApi] = useLazyGetLogoutQuery();
  const [getTutorDetail] = useLazyGetTutorDetailsQuery();
  const [getSubscriptionInsights] = useLazySubscriptionInsightsQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const { subscriptionV2, oldSubscriptionExists } = useSelector(
    (state) => state.subscription
  );
  const { organization } = useSelector((state) => state.organization);

  const [otherDetails, setOtheDetails] = useState(null);

  //For checking the required sub-domain & redirection
  useEffect(() => {
    const tempToken = searchParams.get("ltoken");
    const role = searchParams.get("role");
    const userId = searchParams.get("userId");
    const orgId = searchParams.get("orgId");
    const requiredStorage = searchParams.get("rememberMe")
      ? "localStorage"
      : "sessionStorage";
    if (tempToken) {
      setSearchParams({});
      window[requiredStorage].setItem("evalloToken", tempToken);
      window[requiredStorage].setItem("role", role);
      window[requiredStorage].setItem("userId", userId);
      window[requiredStorage].setItem("orgId", orgId);
      dispatch(updateIsLoggedIn(true));
    }
  }, [searchParams]);

  //For subscription pop-up
  const subscriptionOperationInfo = useSelector((state) => state.subscriptionOperation)
  const { isLoggedIn, fetchData } = useSelector((state) => state.user);
  const { role } = useSelector((state) => state.user);

  const user = useSelector((state) => state.user);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [updateOrgRequest] = useUpdateOrgDataMutation();
  const [showSmallScreenBlocker, setShowSmallScreenBlocker] = useState(false);

  const getOrganizationDetail = (id) => {
    console.log(id);
    if (id === undefined) return;
    fetchOrganization(id).then((res) => {
      if (res.error) {
        console.log("error fetching organization", res.error);
        return;
      }
      console.log("res.data", res.data);
      dispatch(updateOrganization(res.data.organisation));
      dispatch(
        updateDateFormat({
          dateFormat: res.data.organisation.settings.dateFormat,
        })
      );
      // checkForOldSubscriptions(res?.data?.organisation)
    });
  };

  //Keep track of first render
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(1);

    return () => {
      window.localStorage.removeItem("ev_link");
    };
  }, []);


  //For favicon
  useEffect(() => {
    if (
      subscriptionV2 &&
      organization &&
      subscriptionV2.subscription.basePackage.name.includes("Premium") &&
      organization.isEnabledOrgFavicon
    ) {

      updateIcons(organization.orgBrowserFavicon, organization.orgBrowserFavicon);
    } else {
      updateIcons(process.env.PUBLIC_URL + "/images/ev_logo.svg", process.env.PUBLIC_URL + "/images/ev_logo.svg");
    }
  }, [organization, subscriptionV2]);



  //To keep track of the location
  const location = useLocation();
  useEffect(() => {
    if (count === 0) return;
    window.localStorage.setItem("ev_link", "true");
    console.log("PNAME@", window.location.pathname, location.state);
  }, [location]);

  useEffect(() => {
    setLoading(true);
    console.log(">>>APP");
    if (
      sessionStorage.getItem("evalloToken") ||
      localStorage.getItem("evalloToken") ||
      fetchData
    ) {
      fetchPersonalDetails().then((res) => {
        console.log("personal details 22", res);
        if (res.error) {
          dispatch(updateIsLoggedIn(false));
          setLoading(false);
          sessionStorage.clear();
          localStorage.clear();
          return;
        }
        const {
          firstName,
          lastName,
          _id,
          amountToPay,
          credits,
          role,
          email,
          phone,
          phoneCode,
          about,
          associatedOrg,
          photo,
          company,
          lastUpdateDatePassword,
          scheduleDetails,
          userDefaults,
        } = res.data.data.user;
        dispatch(updateAwsLink(res.data.data.baseLink));
        let timeZone = "";
        let dateFormat = "";
        if (res.data.data.userdetails) {
          timeZone = res.data.data.userdetails.timeZone;
          dateFormat = res.data.data.userdetails.dateFormat;
        }
        sessionStorage.setItem("role", role);
        setLoading(false);
        dispatch(updateIsLoggedIn(true));
        getOrganizationDetail(associatedOrg);

        if (role === "tutor") {
          getTutorDetail({ id: _id }).then((res) => {
            console.log("tutor details--", res?.data);
            if (res.error) {
              dispatch(
                updateUserDetails({
                  firstName,
                  lastName,
                  id: _id,
                  amountToPay,
                  credits,
                  role,
                  photo,
                  timeZone: "",
                  email,
                  phone,
                  phoneCode,
                  associatedOrg,
                  about: about ? about : "",
                  company,
                  lastUpdateDatePassword,
                  // dateFormat: dateFormat ? dateFormat : 'dd/mm/yy',
                  scheduleDetails,
                  userDefaults
                })
              );
              return;
            }
            dispatch(
              updateUserDetails({
                firstName,
                lastName,
                id: _id,
                amountToPay,
                credits,
                role,
                photo,
                timeZone: res.data?.data?.details?.timeZone
                  ? res.data?.data?.details?.timeZone
                  : "",
                email,
                phone,
                phoneCode,
                associatedOrg,
                about: about ? about : "",
                company,
                lastUpdateDatePassword,
                // dateFormat: dateFormat ? dateFormat : 'dd/mm/yy',
                scheduleDetails,
                userDefaults
              })
            );
          });
        } else {
          console.log("PARENT", res.data?.data?.user?.assiginedStudents, {
            firstName,
            lastName,
            id: _id,
            amountToPay,
            credits,
            role,
            photo,
            timeZone: timeZone ? timeZone : "",
            email,
            phone,
            phoneCode,
            associatedOrg,
            about: about ? about : "",
            company,
            lastUpdateDatePassword,
            ...(res.data?.data?.user?.assiginedStudents) && { associatedStudents: res.data?.data?.user?.assiginedStudents }
            // dateFormat: dateFormat ? dateFormat : 'dd/mm/yy',
          })
          dispatch(
            updateUserDetails({
              firstName,
              lastName,
              id: _id,
              amountToPay,
              credits,
              role,
              photo,
              timeZone: timeZone ? timeZone : "",
              email,
              phone,
              phoneCode,
              associatedOrg,
              about: about ? about : "",
              company,
              lastUpdateDatePassword,
              ...(res.data?.data?.user?.assiginedStudents) && { associatedStudents: res.data?.data?.user?.assiginedStudents },
              scheduleDetails,
              // dateFormat: dateFormat ? dateFormat : 'dd/mm/yy',
              userDefaults
            })
          );
        }
      });
    } else {
      setLoading(false);
    }
  }, [isLoggedIn, fetchData]);

  // useEffect(() => {
  //   const handleTabClose = (event) => {
  //     event.preventDefault();
  //     console.log("beforeunload event triggered");
  //   };
  //   window.addEventListener("beforeunload", handleTabClose);
  //   return () => {
  //     // logOutApi().then(() => {
  //     //   console.log("Successfully logged out");
  //     // });
  //     window.removeEventListener("beforeunload", handleTabClose);
  //   };
  // }, []);

  useEffect(() => {
    if (isLoggedIn && role === "admin") {
      if (!window.localStorage.getItem("alertPreferences"))
        setAlertPreferencesOnLogin();
    }
  }, [isLoggedIn, role]);

  useEffect(() => {
    if (
      sessionStorage.getItem("evalloToken") ||
      localStorage.getItem("evalloToken") ||
      fetchData ||
      organization.id
    ) {
      if (role !== "superAdmin" && role !== "manager" && !subscriptionV2) {
        fetchAndSetSubscription(organization);
      }
    }
  }, [isLoggedIn, fetchData, organization]);

  //Fetch subscription insights to show alerts for usage
  useEffect(() => {
    if (role !== "admin") return;
    if (
      isLoggedIn &&
      subscriptionV2 &&
      JSON.stringify(organization.settings) !== "{}" &&
      notificationsCount === 0
    ) {
      setNotificationsCount(notificationsCount + 1);

      if (!window.localStorage.getItem("alertPreferences")) return;

      getSubscriptionInsights()
        .then((res) => {
          //Extract to a function outside
          const subscriptionInsights = res.data.data;
          const assignmentUsagePercentage =
            subscriptionInsights.usage.assignments.base !== 0
              ? Math.ceil(
                (subscriptionInsights.usage.assignments.base /
                  subscriptionInsights.limits.assignments.base) *
                100
              )
              : 0;
          const sessionsUsagePercentage =
            subscriptionInsights.usage.sessions.base !== 0
              ? Math.ceil(
                (subscriptionInsights.usage.sessions.base /
                  subscriptionInsights.limits.sessions.base) *
                100
              )
              : 0;
          const customContentUSagePercentage =
            subscriptionInsights.usage.customContent.base !== 0
              ? Math.ceil(
                (subscriptionInsights.usage.customContent.base /
                  subscriptionInsights.limits.customContent.base) *
                100
              )
              : 0;

          console.log(">>>ORGS", organization.settings);
          showSubscriptionRenewedAlert(
            subscriptionV2,
            organization,
            subscriptionInsights,
            updateOrgRequest
          );
          showPAYGStartedAlert(
            subscriptionV2,
            organization,
            subscriptionInsights,
            updateOrgRequest
          );

          console.log("AUP", assignmentUsagePercentage);
          let firstSetOfNotifsShown = showAlertOnPAYG90Usage(
            assignmentUsagePercentage,
            sessionsUsagePercentage,
            customContentUSagePercentage,
            subscriptionInsights,
            subscriptionV2,
            organization,
            0,
            0
          );
          let firstSetOfNotifsShownAnnual = showAnnualAlertOn100Usage(
            assignmentUsagePercentage,
            sessionsUsagePercentage,
            customContentUSagePercentage,
            subscriptionInsights,
            subscriptionV2,
            organization,
            firstSetOfNotifsShown,
            0
          );

          firstSetOfNotifsShownAnnual = showAnnualAlertOn90Usage(
            assignmentUsagePercentage,
            sessionsUsagePercentage,
            customContentUSagePercentage,
            subscriptionInsights,
            subscriptionV2,
            organization,
            firstSetOfNotifsShown,
            firstSetOfNotifsShownAnnual
          );

          showAlertOnD_PAYG100Usage(
            assignmentUsagePercentage,
            sessionsUsagePercentage,
            customContentUSagePercentage,
            subscriptionInsights,
            subscriptionV2,
            organization,
            firstSetOfNotifsShown,
            firstSetOfNotifsShownAnnual
          );
          showAlertOn50Usage(
            assignmentUsagePercentage,
            sessionsUsagePercentage,
            customContentUSagePercentage,
            subscriptionInsights,
            subscriptionV2,
            organization,
            firstSetOfNotifsShown,
            firstSetOfNotifsShownAnnual
          );

          showAlertOnPaymentFailedDowngrade();
          showAlertOnCancellationDowngrade();
        })
        .catch((err) => {
          console.log("insights error", err);
        });
    }
  }, [isLoggedIn, subscriptionV2, organization, role]);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (role === "admin") {
      console.log("INTERCOM ADDED");
      Intercom({
        app_id: "fnycoofm",
        user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user.firstName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
    }
  }, [isLoggedIn, role]);

  useEffect(() => {
    console.log("ORG", organization);
  }, [organization]);

  useEffect(() => {
    if (!isLoggedIn && window.location.pathname === "/") {
      setShowSmallScreenBlocker(false);
      return;
    } else if (
      isLoggedIn &&
      (window.location.pathname.startsWith("/signup") ||
        window.location.pathname.startsWith("/settings"))
    ) {
      setShowSmallScreenBlocker(false);
      return;
    }
    // else if((isLoggedIn && window.location.pathname.startsWith('/signup'))){
    //   setShowSmallScreenBlocker(true)
    //   return
    // }

    if (
      !window.location.pathname.startsWith("/signup") &&
      !window.location.pathname.startsWith("/signin")
    ) {
      setShowSmallScreenBlocker(true);
    } else {
      setShowSmallScreenBlocker(false);
    }
  }, [window.location.pathname, isLoggedIn]);

  //To triggger the pop-up for subscription
  useEffect(() => {
    console.log(window.location.pathname);
    if (isLoggedIn) {
      if (!oldSubscriptionExists && !subscriptionV2) {
        if (
          !window.location.pathname.startsWith("/signup") &&
          !window.location.pathname.startsWith("/signin")
        ) {
          dispatch(toggleSubscriptionPopUpTrigger(true));
          dispatch(updateIsSignupProcessComplete(true));
        }
      } else if (oldSubscriptionExists || subscriptionV2) {
        dispatch(toggleSubscriptionPopUpTrigger(false));
        dispatch(updateIsSignupProcessComplete(true));
      }
    }
  }, [
    isLoggedIn,
    subscriptionV2,
    oldSubscriptionExists,
    window.location.pathname,
  ]);

  useEffect(() => {
    console.log(subscriptionOperationInfo);
  }, [subscriptionOperationInfo]);

  if (loading) return <Loader />;

  return (
    <>
      
	 { showSmallScreenBlocker ? <SmallDevicesBlockerScreen isLoggedIn={isLoggedIn}/> : null }
      <div className={showSmallScreenBlocker ? "appContainer" : ""}>
        {/* <Layout2> */}
        <ToastContainer style={{ zIndex: 2147483647 }} />
        <SubscriptionOperationPopUp trigger={subscriptionOperationInfo?.subscriptionPopUpTrigger && role === "admin"} teamSize={5} />
        <AppRoutes  />
        {/* </Layout2> */}
      </div>
    </>
  );
}

export default App;
