import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'

const colors = [
  "#0BA5DC",
  "#E8AB11",
  "#573030",
  "#183943",
  "#E81160",
  "#4937C7",
  "#094513",
  "#717577",
  "#82B915",
  "#099870",
];

const AvailableSelectorInput = ({type, userData, submitData, setSubmitData, customPopupMessage}) => {

    const [checked, setChecked] = useState(false)
    const user = useSelector((state) => state.user);

    const assignedColor = useMemo(() => {
      let hash = 0;
      for (let i = 0; i < userData?._id.length; i++) {
        hash = userData?._id.charCodeAt(i) + ((hash << 5) - hash);
      }
      const index = Math.abs(hash) % colors.length;
      return colors[index];
    }, [userData?._id, colors]);


    const addRemoveUser = ()=>{

      if(type === "members"){
        const userExists = submitData.selectedMembers.some(member => member === userData._id);

        if(submitData.selectedStudents.length >0 && submitData.selectedMembers.length>0 && !userExists){
          customPopupMessage("You can only select one Team Member (Tutor, Admin, or Contributor) if you have also selected a Student.");
        }
        else{
          setSubmitData((prev)=>({
            ...prev,
            selectedMembers:userExists ? prev.selectedMembers.filter(member => member !== userData._id) : [...prev.selectedMembers, userData._id],
           }))
        }
      }
      else{
        if( submitData.selectedMembers.length>1){
          customPopupMessage("You can't select Student, if you have already selected multiple Team Members (Tutor, Admin, or Contributor).");
        }
        else{
          const userExists = submitData.selectedStudents.some(member => member === userData._id);
          console.log(type, userExists)
          setSubmitData((prev)=>({
            ...prev,
            selectedStudents:userExists ? prev.selectedStudents.filter(member => member !== userData._id) : [...prev.selectedStudents, userData._id],
           }))
        }
      }

    }


    useEffect(()=>{
      if(submitData){
        if(type === "members"){
          const userExists = submitData.selectedMembers.some(member => member === userData._id);
          if(userExists) setChecked(true);
          else setChecked(false);
        }
        else{
          const userExists = submitData.selectedStudents.some(member => member === userData._id);
          if(userExists) setChecked(true);
          else setChecked(false);
        }
      }
    },[submitData])

  return (
    <main id={userData?._id} className=' w-full gap-[6px]  justify-start flex items-center p-[4px] rounded-[4px] hover:bg-[#F7F8F9] cursor-pointer ' onClick={addRemoveUser} >
        
        <section className='flex items-center  justify-center w-fit h-fit relative z-10'>
          <input type="checkbox" checked={checked} className=' !inline-block w-[12px] h-[12px] md:w-[15px] md:h-[15px] '/>
          <div className='bg-transparent  cursor-pointer z-10 absolute top-0 left-0 w-full h-full' />
        </section>

        {userData?.photo ? 
        <img src={user?.awsLink + userData.photo} alt=""  className="w-[20px] md:w-[24px]   h-[20px] md:h-[24px]  rounded-full " /> :

        <div
        className="text-[12px] leading-[12px] text-white w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full flex items-center justify-center"
        style={{ backgroundColor: assignedColor }}
      >
        {userData?.firstName.charAt(0).toUpperCase()}
      </div>
         }

        <section className=' flex flex-col gap-[2px] flex-1 '>
            <h4 className=' text-[10px] md:text-[12px] leading-[10px] md:leading-[12px] font-medium text-secondary-80'> {userData?.firstName} {userData?.lastName}</h4>
            <p className=' text-[8px] md:text-[10px] leading-[8px] md:leading-[10px] font-normal text-secondary-60  break-all  overflow-hidden  w-full max-w-full '>{userData?.email}</p>
        </section>


    </main>
  )
}

export default AvailableSelectorInput