import React from 'react'

const EditPen = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_32453_65465)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0653 1.5798C9.78398 1.2986 9.40251 1.14062 9.00477 1.14062C8.60702 1.14062 8.22556 1.2986 7.94427 1.5798L7.59077 1.9338L10.0658 4.4088L10.4188 4.0553C10.5581 3.91601 10.6686 3.75064 10.744 3.56864C10.8194 3.38663 10.8582 3.19156 10.8582 2.99455C10.8582 2.79755 10.8194 2.60248 10.744 2.42047C10.6686 2.23847 10.5581 2.0731 10.4188 1.9338L10.0653 1.5798ZM9.35827 5.1158L6.88327 2.6408L2.33827 7.18631C2.23881 7.28578 2.16933 7.41122 2.13777 7.5483L1.62327 9.7758C1.6041 9.8585 1.6063 9.94471 1.62966 10.0263C1.65302 10.1079 1.69677 10.1823 1.7568 10.2423C1.81682 10.3023 1.89114 10.3461 1.97275 10.3694C2.05436 10.3928 2.14057 10.395 2.22327 10.3758L4.45127 9.8618C4.58817 9.83016 4.71343 9.76068 4.81277 9.6613L9.35827 5.1158Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_32453_65465">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default EditPen