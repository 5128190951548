import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SuccessIcon from "../../ProductIcons/SuccessIcon";

const PaymentSuccess = ({closeAfterSuccess}) => {
  const { subscriptionPopUpOperationMode } = useSelector(
    (state) => state.subscriptionOperation
  );

  useEffect(()=>{
    console.log("PSUCCESS")
    if(closeAfterSuccess){
      setTimeout(()=>closeAfterSuccess(),4000)
    }
  },[closeAfterSuccess])

  return (
    <div className="h-full w-full flex items-center justify-center bg-white absolute left-0 top-0 z-[199] rounded-[12px]">
      <div className=" text-secondary w-full flex flex-col justify-center items-center gap-[36px]">
        <SuccessIcon />
        <div className="grid gap-[16px] text-center">
          <div className="text-[20px] font-[500]">
            {subscriptionPopUpOperationMode === "new-subscription"
              ? "Payment Successful!"
              : "Updates Saved Successfully!"}
          </div>
          <div className="text-[16px] font-[400] text-secondary-80">
            {subscriptionPopUpOperationMode === "new-subscription" ? (
              <>
                <div>Congrats, your subscription purchase is successful.</div>{" "}
                <div> Routing you back to home...</div>
              </>
            ) : (
              <>
                <div>You’ve successfully updated your subscription.</div>
                <div> Routing you back to home...</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
