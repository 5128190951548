import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';

const colors = [
  "#0BA5DC",
  "#E8AB11",
  "#573030",
  "#183943",
  "#E81160",
  "#4937C7",
  "#094513",
  "#717577",
  "#82B915",
  "#099870",
];

const UnavailableDisaply = ({userData}) => {

  const user = useSelector((state) => state.user);
  const assignedColor = useMemo(() => {
    let hash = 0;
    for (let i = 0; i < userData?._id.length; i++) {
      hash = userData?._id.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  }, [userData?._id, colors]);

  return (
    <main id={userData?._id} className=' w-full gap-[6px]  justify-start flex items-center p-[4px] rounded-[4px] hover:bg-[#F7F8F9]  opacity-40 cursor-default '>

    {userData?.photo ? 
        <img src={user?.awsLink + userData.photo} alt=""  className="w-[20px] md:w-[24px]   h-[20px] md:h-[24px]  rounded-full " /> :

        <div
        className="text-[12px] leading-[12px] text-white w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full flex items-center justify-center"
        style={{ backgroundColor: assignedColor }}
      >
        {userData?.firstName.charAt(0).toUpperCase()}
      </div>
        }

    <section className=' flex flex-col gap-[2px] flex-1 '>
        <h4 className=' text-[10px] md:text-[12px] leading-[10px] md:leading-[12px] font-medium text-secondary-80'> {userData?.firstName} {userData?.lastName}</h4>
        <p className=' text-[8px] md:text-[10px] leading-[8px] md:leading-[10px] font-normal text-secondary-60  break-all  overflow-hidden  w-full max-w-full '>{userData?.email}</p>
    </section>


</main>
  )
}

export default UnavailableDisaply