import React, { useEffect, useRef, useState } from "react";
import {
  deleteOrgCustomBrandsLogo,
} from "../../utils/orgService";
import styles from "./BrandLogoPopup.module.css";
import { Close } from "@mui/icons-material";
import { fetchAndSetOrgDetails, updateOrgCustomBrands } from "../../utils/orgService";
import { triggerToast } from "../../utils/toastController";
import LoaderNew from "../Loader/LoaderNew";
import LoaderIcon from "../LoaderIcon/LoaderIcon";


export const updateIcons = (faviconUrl, appleTouchIconUrl) => {
  // Update favicon
  let faviconLink = document.querySelector("link[rel*='icon']") || document.createElement("link");
  faviconLink.type = "image/x-icon";
  faviconLink.rel = "shortcut icon";
  faviconLink.href = faviconUrl;
  document.getElementsByTagName("head")[0].appendChild(faviconLink);

  // Update apple-touch-icon
	// import {
	//   deleteOrgCustomBrandsLogo,
	//     fetchAndSetOrgDetails,
	//       updateOrgCustomBrands,
	//       } from "../../utils/orgService";
  let appleTouchIconLink = document.querySelector("link[rel*='apple-touch-icon']") || document.createElement("link");
  appleTouchIconLink.rel = "apple-touch-icon";
  appleTouchIconLink.href = appleTouchIconUrl;
  document.getElementsByTagName("head")[0].appendChild(appleTouchIconLink);
};

const BrandLogoPopup = ({
  field,
  organization,
  title,
  selectedFile,
  onCancel,
  onSave,
  note,
  isEnabledField
}) => {
  const [file, setFile] = useState(selectedFile);
  const [deleteFile, setDeleteFile] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(!!selectedFile);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancel();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //   console.log("data", field, organization, title, selectedFile);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log("selectedFile", selectedFile);
    setFile(selectedFile);
    setIsFileSelected(!!selectedFile);
  };
  const handleDeleteImage = () => {
    setFile(null); // Clear selected file
    setIsFileSelected(false);
	setDeleteFile(true)
  };
  const handleSaveClick = async () => {
	if (deleteFile) {
      setLoading(true);

      try {
        await deleteOrgCustomBrandsLogo(organization._id, field);
        triggerToast("Deleted Successfully", "success");
		fetchAndSetOrgDetails(organization._id)
        onSave(null);
        onCancel(); 
      } catch (error) {
        console.error("Error deleting image:", error);
        triggerToast("Error deleting image", "error");
      } finally {
        setLoading(false);
      }
      return; 
    }
    if (file) {
      setLoading(true);

      const fields = {
        [field]: file,
        [isEnabledField] : true
      };
      try {
        const res = await updateOrgCustomBrands(organization._id, fields);
        fetchAndSetOrgDetails(organization._id)
        console.log("res", res);
        let updatedField;
		let faviconUrl;


        switch (field) {
          case "orgBussinessLogo":
            updatedField = res.data.data.org.orgBussinessLogo;
            break;
		case "orgBrowserFavicon":
            updatedField = res.data.data.org.orgBrowserFavicon;
            faviconUrl = URL.createObjectURL(file);
            break;
          case "orgWhiteLogo":
            updatedField = res.data.data.org.orgWhiteLogo;
            break;
          default:
            updatedField = null;
            break;
        }
        if (updatedField) {
          triggerToast("Added Successfully", "success", onSave(file));
          onSave(updatedField);
			if (faviconUrl) {
				updateIcons(faviconUrl,faviconUrl);
			}
        } else {
          triggerToast("Error: Invalid field", "error");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error saving image:", error);
        triggerToast("Error saving image", "error");
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    setIsFileSelected(!!file);
  }, [file]);
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalUpdate} ref={modalRef}>
        <div className={styles.modalHeader}>
          <div className={styles.frame}>
            <div className={styles.addReferral}>
              {title == "Add Image" ? "Add Favicon" : title}
            </div>
            <Close className={styles.closeIcon} onClick={onCancel} />
          </div>
          <img className={styles.line} src="img/line-323.svg" alt="Line" />
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.div}>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {file && (
              <>
                <div className={styles.imagePreview}>
                  <img
                    src={
                      typeof file === "object"
                        ? URL.createObjectURL(file)
                        : file
                    }
                    alt="Preview"
                    //   className={styles.previewImage}
                  />
                </div>
                <div className={styles.imageText}>{file.name}</div>{" "}
                <button
                  className={styles.deleteButton}
                  onClick={handleDeleteImage}
                >
                  <div className={styles.deleteText}>Delete</div>
                </button>
              </>
            )}
            {!file && (
              <>
                <button className={styles.button} onClick={handleButtonClick}>
                  <img
                    className={styles.inputFieldIcon}
                    src="/images/saveicon.svg"
                    alt="Upload"
                  />
                  <div className={styles.verifyEmail}>Upload Image</div>{" "}
                </button>

                <p className={styles.JPGorPNG}>
                  JPG or PNG only. Max height 500 px. <br />
                  Max size 1 MB.
                </p>
              </>
            )}
          </div>
        </div>
        <p className={styles.note}>
          <span className={styles.textWrapper}>Note: </span>
          <span className={styles.span}>{note}</span>
        </p>
        <div className={styles.frame2}>
          <button className={styles.verifyEmailWrapper} onClick={onCancel}>
            <div className={styles.verifyEmailText}>Cancel</div>
          </button>
          <button
						className={`${styles.divWrapper} ${
file || deleteFile ? styles.enabled : styles.disabled
}`}
						onClick={handleSaveClick}
						disabled={!file && !deleteFile}
		   >
            <div className={styles.verifyEmailSave}>
              {loading ? <LoaderIcon color={"white"} /> : "Save"}
            </div>{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BrandLogoPopup;
