import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import AttendeeSelector from "../AttendeeSelector";
import MobileAttendeeSelector from "../../MobileAttendeeSelector/MobileAttendeeSelector";

const MainAttendeeSelector = (props) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>{!isMobile ? <AttendeeSelector {...props}/> : <MobileAttendeeSelector {...props}/>}</div>
  );
};

export default MainAttendeeSelector;
