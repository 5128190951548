const packageComponentMapping = {
    "Free" : {
        brandLogo : false,
        brandLogoWhite : false,
        subDomain : false,
        orgName : false,
        favicon : false,
        website : false,
		session : true,
		consultation : false,
		internalMeeting : false,
		officeHours : false,
		customColor : false
    },
    "Starter" : {
        brandLogo : true,
        brandLogoWhite : false,
        subDomain : false,
        orgName : true,
        favicon : false,
        website : true,
		customColor : false
    }, 
    "Professional" : {
        brandLogo : true,
        brandLogoWhite : true,
        subDomain : false,
        orgName : true,
        favicon : false,
        website : true,
		session : true,
		consultation : true,
		internalMeeting : true,
		officeHours : false,
		customColor : false
    },
    "Premium" : {
        brandLogo : true,
        brandLogoWhite : true,
        subDomain : true,
        orgName : true,
        favicon : true,
        website : true,
		session : true,
		consultation : true,
		internalMeeting : true,
		officeHours : true,
		customColor : true
    }
}

function isWhiteLabellingComponentAllowed(subscription, component){
	

    let planName = subscription.basePackage.name
    planName = planName.split("_")[1]
    console.log("PN",planName)
    return packageComponentMapping[planName][component]
}


export {
    isWhiteLabellingComponentAllowed
}
