import React from "react";
import { useState } from "react";
import ConceptSection from "../../components/ConceptSection/ConceptSection";
import ParentDashboardHeader from "../../components/ParentDashboardHeader/ParentDashboardHeader";
import styles from "./ParentDashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedStudent } from "../../app/slices/user";

const ParentDashboard = () => {
    // const [selectedStudent, setSelectedStudent] = useState(null);
    const { selectedStudent } = useSelector(state => state.user)
const dispatch = useDispatch()

    const setSelectedStudent = (student) => {
        dispatch(updateSelectedStudent(student))
    }
    return (
        <div
            id={styles.ParentDashboardContainer}
            className="flex-auto overflow-x-hiden mx-auto w-[1601px] mt-[-25px] pb-[50px]"
        >
            <ParentDashboardHeader selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} />
            <ConceptSection selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} />
        </div>
    );
};

export default ParentDashboard;
