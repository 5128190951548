import { DateRange } from "react-date-range"
import DateRangeButton from "../DateRangeButton/DateRangeButton"
import styles from "./DateRangeSelector.module.css"
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays, format } from 'date-fns';
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import MiniCalendar from "../CalendarV2/MiniCalendar/MiniCalendar";
import { forwardRef, useEffect, useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

function getTargetDate(baseDate,selectedDate){

		const [targetDate,targetMonth, targetYear] = [selectedDate.getDate(), selectedDate.getMonth(), selectedDate.getFullYear()]
		baseDate.setDate(targetDate)
		baseDate.setMonth(targetMonth)
		baseDate.setFullYear(targetYear)
		return baseDate
}

const DateRangeSelector = forwardRef(function DateRangeSelector({startDate = new Date(), endDate = new Date(), setStartDate, setEndDate, timeZone}, ref) {

	const [state, setState] = useState({})
	const [count, setCount] = useState(0)

	useEffect(()=>{
		setCount((prev)=>prev+1)
	},[])

	useEffect(()=>{
		if(count === 0)
			setState({
				from : startDate,
				to : endDate
			});
	}, [startDate, endDate])

	
	useEffect(()=>{
		console.log("ST",state)
	},[state])



	useEffect(()=>{
		if(!state.from && !state.to) return;
		console.log("ST_DATE", getTargetDate(startDate, state?.from))
		console.log("ST_DATE_END", getTargetDate(endDate, state?.to))
		setStartDate(new Date(getTargetDate(startDate, state?.from)))
		setEndDate(new Date(getTargetDate(endDate, state?.to)))
	},[state?.from, state?.to])

	function handleSelect(selected, triggerDate, modifiers, e){
		const datePartStart = dayjs(state.from).format("YYYY-MM-DD")
		const datePartEnd = dayjs(state.to).format("YYYY-MM-DD")
		if(datePartStart !== datePartEnd){
			setState({from : triggerDate, to : triggerDate})
			return
		}
		if(modifiers.selected){
			return
		}
		setState(selected)
	}

	return (
		<div className={styles.container} ref={ref}>
			<h3>Select Date Range</h3>
			<DateRangeButton {...{startDate, endDate}} timeZone={timeZone}/>

			<div className="flex flex-row items-center w-full  justify-start">
				<DayPicker 
					mode="range" 
					modifiers={{
						isStartDateSelected : state?.from,
						isEndDateSelected : state?.to
					}}
					numberOfMonths={2}
					captionLayout="dropdown-years"
					selected={state}
					onSelect={handleSelect}
					weekStartsOn={1}
					classNames={{
						day_button : styles.calButton,
						months : styles.calMonth,
						weekday : styles.calWeekday,
						day : styles.calGridItem,
						month_caption : styles.calMonthCaption,
						nav : styles.calNav,
						range_middle : styles.calRangeMiddle,
						range_start : styles.calRangeStart,
						range_end : styles.calRangeEnd,
						month_grid : styles.calTableMonth,
						dropdowns : styles.calDropdowns,
						button_previous : styles.calNextBtn,
						button_next : styles.calPrevBtn,
						weekdays : styles.calWeekdayRow
					}}
					formatters={{
						formatWeekdayName : (date, options) => format(date,"eeeee",options)
					}}
				/>
			</div>
		</div>
	)

})



export default DateRangeSelector
