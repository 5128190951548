import InfoIcon from '../../../../components/ProductIcons/InfoIcon'
import styles from './DomainIntegration.module.css'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import SubDomainOperationPopUp from '../../../../components/CustomSettings/SubDomainOperationPopUp/SubDomainOperationPopUp';
import { useSelector } from 'react-redux';
import GreenTickIcon from '../../../../components/QuestionPopUp/GreenTickIcon';
import { fetchAndSetOrgDetails, updateOrg } from '../../../../utils/orgService';
import { getFormattedDateV2 } from '../../../../utils/dateUtils';
import DomainIntegrationEmptyState from '../../../../components/ProductSettings/DomainIntegration/DomainIntegrationEmptyState/DomainIntegrationEmptyState';
import { triggerToast } from '../../../../utils/toastController';
import { toast } from 'react-toastify';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import { getTooltipStyleClass } from '../../../../utils/commonlyUsedTooltipStyles';
import TooltipIcon from '../../../../assets/icons/SettingsIcon/TooltipIcon';
import LockedStateOverlayVariant2 from '../../../../components/LockedStateOverlayVariant2/LockedStateOverlayVariant2';
import { height } from '@mui/system';
import { isWhiteLabellingComponentAllowed } from '../../../../utils/restrictionsService';

const requiredFieldsMapping = {
    "registeredSubDomain": "Sub-domain",
    "isActive": "Status",
    "addedOn": "Added On"
}

function getValue(obj, targetKey) {

    switch (targetKey) {
        case "registeredSubDomain":
            return obj[targetKey] ? (obj[targetKey] + ".evallo.org") : "-"
        case "isActive":
            return obj[targetKey] ? "Active" : "Inactive"
        case "addedOn":
            return obj[targetKey] ? getFormattedDateV2(obj[targetKey], "dd mm, yyyy") : "-"
    }

}


function rediectToValidURL(customSubDomain, mode = "evallo-domain") {
    console.log(customSubDomain, mode)
    if (customSubDomain.registeredSubDomain && ((mode === "evallo-domain" && (customSubDomain.registeredSubDomain + '.evallo.org' === window.location.host)) || (mode === "custom-domain"))) {
        const requiredStorage = window.localStorage.getItem("evalloToken") ? "localStorage" : "sessionStorage"
        const orgId = window[requiredStorage].getItem("orgId")
        const token = window[requiredStorage].getItem("evalloToken")
        const userId = window[requiredStorage].getItem("userId")
        const role = window[requiredStorage].getItem("role")
        const rememeberUser = requiredStorage === "localStorage" ? true : false
        const redirectURL = mode === "evallo-domain" ? `${process.env.REACT_APP_FE_URL}/settings/white-labeling/custom-domain?ltoken=${token}&orgId=${orgId}&userId=${userId}&role=${role}&` + (rememeberUser ? "" : "&rememberUser=true")
            :
            `https://${customSubDomain.registeredSubDomain}.evallo.org/settings/white-labeling/custom-domain?ltoken=${token}&orgId=${orgId}&userId=${userId}&role=${role}&`
        window.location.replace(redirectURL)
    }
}


const DomainIntegration = (props) => {

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [subDomainOperationPopUpTrigger, setSubDomainOperationPopUpTrigger] = useState(false)
    const { organization } = useSelector((state) => state.organization)
    const [isLoading, setIsLoading] = useState(false)
    const { subscriptionV2 } = useSelector((state) => state.subscription)


    async function handleSaveClick(targetRegisteredSubdomain, setIsLoading) {
        try {
            setIsLoading(true)
            await updateOrg(organization._id, { "targetSubDomain": targetRegisteredSubdomain }, "update-subdomain")
            await fetchAndSetOrgDetails(organization._id)
            setTimeout(() => {
                rediectToValidURL({ registeredSubDomain: targetRegisteredSubdomain }, "custom-domain")
            }, 120000)
            setTimeout(() => {
                triggerToast("Logging you out to reflect changes to subdomain.")
            }, 118000)
        } catch (err) {
            console.error(err)
            triggerToast(err.response.data.message, "error", { autoClose: 3000 })
        } finally {
            setIsLoading(false)
            setSubDomainOperationPopUpTrigger(false)
        }
    }

    async function handleToggleActive(targetValue) {
        const id = toast.loading("Disconnecting the domain ...")
        try {
            setIsLoading(true)
            const tempCustomSubDomain = organization.customSubDomain
            await updateOrg(organization._id, {}, "delete-subdomain")
            fetchAndSetOrgDetails(organization._id)
            toast.update(id, { render: "Disconnection successfull", isLoading: false, autoClose: 3000, type: "success" })
            rediectToValidURL(tempCustomSubDomain)
        } catch (err) {
            console.error(err)
            toast.update(id, { render: err.response.data.message?.startsWith("LIMIT") ? err.response.data.message?.split(":")[1] : "Disconnection failed", isLoading: false, autoClose: 3000, type: "error" })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <h3>Custom Sub-domain</h3>
                <Tooltip tooltipStyles={getTooltipStyleClass({ "width": "320px" })} tooltipContent={`Here, you can create your own custom subdomain for hosting your white-labeled platform. The subdomain will allow you to personalize the experience for all your users, giving you a unique sign-up and login screen that you can associate with your company website. Just click on "Connect Now" and type the subdomain you want. It might take a couple minutes before your account gets associated with the subdomain.`}>
                    <TooltipIcon />
                </Tooltip>
            </div>
            <p className={styles.description}>Create your own unique subdomain and have your users login from an exclusive page dedicated for your business!</p>

            {
                !organization?.customSubDomain?.registeredSubDomain ?
                    <DomainIntegrationEmptyState onClick={() => { setSubDomainOperationPopUpTrigger(!subDomainOperationPopUpTrigger) }} >
                        {
                            subscriptionV2 && isWhiteLabellingComponentAllowed(subscriptionV2?.subscription, "subDomain") ? <></> :  <LockedStateOverlayVariant2 />
                        }
                    </DomainIntegrationEmptyState>
                    :
                    <>
                        <div className={styles.mainContainer}>
                            {
                                subscriptionV2 && isWhiteLabellingComponentAllowed(subscriptionV2?.subscription, "subDomain") ? <></> :  <LockedStateOverlayVariant2 outerContainerStyle={{height : "432px"}}/>
                            }
                            {
                                Object.keys(requiredFieldsMapping).map((key, idx) => (
                                    <div className={styles.item}>
                                        <h5>{requiredFieldsMapping[key]}</h5>
                                        <div className={styles.detail}>
                                            {/* <p>{organization?.customSubDomain[key] ? (organization?.customSubDomain[key] + (key === "registeredSubDomain" ? ".evallo.org" : ""  )): "-"}</p> */}
                                            <p>{getValue(organization.customSubDomain, key)}</p>
                                            {
                                                key === "isActive" ? organization?.customSubDomain?.isActive ? <GreenTickIcon /> : <></> : <></>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                <div className={styles.moreContainer}>
                                    <button className={styles.moreButton} onClick={(e) => setIsMenuOpen(!isMenuOpen)}><MoreHorizIcon /></button>
                                    {
                                        isMenuOpen ?
                                            <div className={styles.moreMenu}>
                                                {
                                                    !organization?.customSubDomain?.isActive ?

                                                        <div className={styles.enableButton} onClick={(e) => { handleToggleActive(true) }}>Enable</div>

                                                        :

                                                        <div className={styles.disconnectButton} onClick={(e) => { handleToggleActive(false) }}>Disconnect</div>
                                                }
                                            </div>
                                            : <></>
                                    }
                                </div>
                            }
                        </div>
                        {
                            organization?.customSubDomain?.registeredSubDomain ? <></> :
                                <button className={styles.connect} onClick={(e) => { setSubDomainOperationPopUpTrigger(!subDomainOperationPopUpTrigger) }}>
                                    <AddIcon /><>Connect Sub-Domain</>
                                </button>
                        }
                    </>
            }
            {
                subDomainOperationPopUpTrigger ?
                    <SubDomainOperationPopUp subDomain={organization?.customSubDomain?.registeredSubDomain ? organization?.customSubDomain?.registeredSubDomain : organization?.company.split(" ").join("").toLowerCase()} trigger={subDomainOperationPopUpTrigger} setTrigger={setSubDomainOperationPopUpTrigger} handleSaveClick={handleSaveClick} registeredSubDomain={organization?.customSubDomain?.registeredSubDomain} />
                    : <></>
            }
        </div>
    )
}


export default DomainIntegration
