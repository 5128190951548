import React, { useState, useRef, useEffect } from "react";
import NotesEditor from "../../NotesEditor/NotesEditor";

const Consultation = (props) => {
  return (
    <div className="flex flex-col gap-[36px] p-2 h-full ">
      <div className="grid gap-[40px] w-full ">
        <NotesEditor alwaysShowNotes={true} {...props} />
      </div>
    </div>
  );
};

export default Consultation;
