import React, { useEffect, useRef, useState } from 'react'
import InputTypeController from './InputTypeController'
import { MoreVert } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import LockIcon from '../../assets/Settings/LockIcon'
import NotePadIcon from '../../assets/icons/SettingsIcon/NotePadIcon'
import { triggerUpdateSubscriptionPopUp } from '../../utils/subscriptionPopUpService'
import Tooltip from '../Tooltip/Tooltip'
// import NotePadIcon from '../../assets/icons/SettingsIcon/notePadIcon'


const SectionThree = ({ setCustomFields, customFields, activeIndex, setActiveIndex, packageName, totalQuestion }) => {

    const [openSectionMenu, setOpenSectionMenu] = useState(false)
    const sectionMenuRef = useRef(null)
    const closeOpenMenus = (e) => {
        if (openSectionMenu && !sectionMenuRef.current?.contains(e.target)) {
            setOpenSectionMenu(false)
        }
    }

    document.addEventListener("mousedown", closeOpenMenus)

    useEffect(() => {
        console.log(packageName)

    }, [activeIndex])

    const deleteSection = () => {
        setCustomFields((prev) => {
            prev["sectionThree"] = []
            return { ...prev }
        })
    }

    return (


        packageName !== "Premium" ?


            <div className='flex justify-center items-center flex-col h-[90%] gap-8 px-6 xl:px-0 m-auto xl:max-w-[420px]'>
                <div>
                    <NotePadIcon />
                </div>
                <div className='flex flex-col gap-6 items-center justify-center text-center'>
                    <div className='h-9 w-9 rounded-full bg-white shadow-[0px_3px_10px_0px_#00000033] flex justify-center items-center'>
                        <LockIcon />
                    </div>
                    <div className='text-secondary-80 font-normal text-base'>
                        Upgrade to Premium plan to unlock additional section and unlimited questions.
                    </div>
                    <div>
                        <button className='font-medium text-base text-blue-70' onClick={(e) => triggerUpdateSubscriptionPopUp()}>Manage Subscription</button>
                    </div>
                </div>
            </div>
            :
            <div className={`p-4 flex flex-col gap-5 ${customFields && customFields?.sectionThree.length === 0 && "justify-center items-center"} bg-blue-2-50 h-full`}>
                <div className={`flex justify-between items-center ${customFields && customFields?.sectionThree.length === 0 ? "hidden" : "flex"} `}>
                    <div className='font-medium text-base text-secondary w-[70%] border-b border-secondary-80'>More Details</div>
                    <div className='relative' ref={sectionMenuRef}>
                        <MoreVert onClick={() => setOpenSectionMenu(true)} cursor={"pointer"} />
                        {
                            openSectionMenu && <div className='py-3 shadow-[0px_4.05px_26.98px_0px_#000AFF29] rounded-md absolute right-0 top-[30px] bg-white w-[180px]'>
                                <div className='font-normal text-[16px] leading-[16px] text-red-400 p-3 hover:bg-secondary-5' onClick={() => { deleteSection() }}>Delete section</div>
                            </div>
                        }
                    </div>
                </div>
                {
                    customFields && customFields?.sectionThree?.map((item, i) => {
                        console.log("xyz")
                        return (
                            <InputTypeController idx={i} key={i} isThisActive={activeIndex == i} setActiveIndex={setActiveIndex} answerType={"Single-Line"} setCustomFields={setCustomFields} customFields={customFields} section={"sectionThree"} packageName={packageName} totalQuestion={totalQuestion} />
                        )
                    })
                }
                <div className='flex items-center gap-3'>
                    <button className='px-5 py-[10px] rounded-md bg-blue-70 font-medium text-[16px] leading-[20px] text-white disabled:bg-blue-70/40' onClick={() => {
                        setCustomFields((prev) => {
                            const temp = { ...prev }
                            temp?.sectionThree.push({
                                "questionText": "",
                                "Values": [],
                                "dataType": "Single-line",
                                "required": true,
                                "disabled": false,
                                "desc": ""
                            })
                            return temp
                        })
                    }}
                    >+ Add Question</button>
                </div>
            </div>

    )
}

export default SectionThree