import React, { useEffect, useState } from "react";
import {
  getDate,
  getFormattedDate,
  getScore,
  getScoreStr,
} from "../../../utils/utils";
import downloadImage2 from "../../../assets/icons/download.png";
import downloadImage from "../../../assets/icons/download2.svg";
import { useSelector } from "react-redux";
import { useLazyGetTestResponseQuery } from "../../../app/services/test";
import { useNavigate } from "react-router-dom";

export default function ParentTest({
  styles,
  assignedTestId,
  testId,
  testName,
  studentId,
  dueDate,
  isCompleted,
  isStarted,
  pdfLink,
}) {
  const { dateFormat } = useSelector((state) => state.user);
  const [score, setScore] = useState("-");
  const { role: persona } = useSelector((state) => state.user);
  const [getTestResponse, getTestResponseResp] = useLazyGetTestResponseQuery();
  const navigate = useNavigate();
  const { awsLink } = useSelector((state) => state.user);

  useEffect(() => {
    if (isCompleted === true) {
      let params = {};
      let url = `/api/test/getresponse/${assignedTestId}`;
      if (persona !== "student") {
        url = `/api/test/admin/getresponse/${assignedTestId}`;
      }

      getTestResponse({ url, params: params }).then((res) => {
        if (res.error) {
          console.log("resp err", res.error);
          return;
        }
        // console.log('Resp score', res.data.data.response);
        let responseData = res.data.data.response;
        let score = getScoreStr(
          responseData.testType,
          responseData.score,
          responseData.subjects,
          responseData.subjects.length
        );
        // let scr = getScore(res.data.data.response.testType, res.data.data.response.subjects)
        setScore(`${score.cumulative} ${score.right}`);
      });
    }
  }, []);

  const handleReportNavigate = () => {
    if (pdfLink) {
      const anchor = document.createElement("a");
      anchor.href = `${awsLink}${pdfLink}`;
      anchor.target = "_blank";
      anchor.download = `${pdfLink}.pdf`; // Replace with the desired file name and extension
      anchor.click();
    } else {
      alert("The PDF file is no longer available.");
    }
  };

  const handleNavigate = () => {
    navigate(
      `/assigned-tests/${testId}/${assignedTestId}/report/${studentId._id}`
    );
  };

  return (
    <div className="flex items-center justify-between mb-6">
      <div className="w-[55%]">
        {/* <div className={`${styles.listedDataItem} text-ellipsis w-full`}> */}
        <p className="!text-secondary-80 !font-medium text-[20px] max-w-[85%] overflow-hidden text-ellipsis whitespace-nowrap">
          {" "}
          {testName}{" "}
        </p>
        <div className="flex mr-2" style={{ gap: "6px" }}>
          <p className=" !font-normal opacity-50 !text-secondary-80 text-base-15 !text-[15px] font-['Inter'] ">
            Due Date:{" "}
          </p>
          <p className="mt-0 opacity-60  font-normal !text-secondary-80 text-base-15 !text-[15px] font-['Inter']">
            {getFormattedDate(dueDate, dateFormat)}
          </p>
        </div>
        {/* </div> */}
      </div>
      <div className="w-[15%]">
        {pdfLink && (
          <img
            src={downloadImage}
            alt="download-icon"
            onClick={handleReportNavigate}
            className="h-[35px] w-[35px] cursor-pointer"
          />
        )}
      </div>
      {isCompleted ? (
        <div className="w-[30%]">
          <div
            className="flex items-center justify-center"
            style={{ gap: "10px" }}
          >
            <div
              className="button !text-blue-70  !font-semibold rounded-[6px] w-[190px] text-center !text-[17.5px] !leading-[21px] !bg-white  cursor-pointer"
              onClick={handleNavigate}
            >
              {/* {score} */}
              View Report
            </div>
          </div>
        </div>
      ) : isStarted ? (
        <div className="w-[30%]">
          <div
            className="flex items-center justify-center"
            style={{ gap: "10px" }}
          >
            <div className="button  !text-secondary-40  !text-center  rounded-[6px] w-[190px] !text-[17.5px] !leading-[21px] !bg-white font-semibold">
              Started
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[30%]">
          <div
            className="flex items-center justify-center"
            style={{ gap: "10px" }}
          >
            <div className="button  !text-[#FF6961] rounded-[6px] w-[190px] text-center !text-[17.5px] !leading-[21px] !bg-white font-normal">
              Not Started
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
