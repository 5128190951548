import { useEffect, useState } from 'react'
import CautionIcon from '../CautionIcon/CautionIcon'
import CrossIcon from '../CrossIcon/CrossIcon'
import PopupContainer from '../PopupContainer/PopupContainer'
import RadioButtonV2 from '../RadioButtonV2/RadioButtonV2'
import Tooltip from '../Tooltip/Tooltip'
import styles from './SubscriptionOperationPopUp.module.css'
import SubscriptionFolder from './SubscriptionFolder/SubscriptionFolder'
import { fetchLookupKeyForId, fetchPriceForId, fetchTutorPriceForPackage } from '../../pages/ManageSubscription/Utils/fetchers'
import { useCreateNewSubscriptionMutation, useLazyGetPricesV2Query, useCancelSubscriptionV2Mutation } from '../../app/services/subscription'
import { createFreeSubscription, createSubscriptionItems, deleteSubscription, fetchAndSetSubscription, getBasePackageTutorLimit, isSiMappingEqual, updateSubscription, updateTutorLicenses } from '../../utils/subscriptionService'
import { getPriceForAdditionalTutorLicenses, loadFreeTier } from '../../utils/utils'
import getRecommendedPackageWithProducts from '../../utils/package_recommender'
import getCorrespondingPriceId from '../../pages/ManageSubscription/Utils/getCorrespondingProducts'
import getCorrespondingPrice from '../../pages/ManageSubscription/Utils/getCorrespondingBasePackage'
import getConvertedPlanType from '../ProductCard/planTypeConverter'
import getCorrespondingBasePackage from '../../pages/ManageSubscription/Utils/getCorrespondingBasePackage'
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider'
import ActionArea from './ActionArea/ActionArea'
import PriceSummary from './PriceSummary/PriceSummary'
import ReviewAndPayContainer from './ReviewAndPayContainer/ReviewAndPayContainer'
import { triggerToast } from '../../utils/toastController'
import { useDispatch, useSelector } from 'react-redux'
import { updateFetchData } from '../../app/slices/user'
import { useUpdateOrgDataMutation } from '../../app/services/admin'
import { toggleSubscriptionPopUpTrigger } from '../../app/slices/subscriptionOperation'
import { fetchInvoicePreviewForUpdate, formUpdateRequest } from '../../pages/ManageSubscription/Utils/updateRequestForSub'
import formSummaryObject from '../../pages/ManageSubscription/Utils/formSummaryObject'
import UnsavedChanges from './UnsavedChanges/UnsavedChanges'
import PaymentSuccess from './PaymentSuccess/PaymentSuccess'
import { getBasePackageDetails, getCorrespondingProductSelection, getCurrentSelectedPlanId, getFreeBasePackage, getPriceDetails, getSelectedProductsAndBasePackageFromSiMapping, getStagingSiMapping, isParameterSelectionToBeEnabled, isPrimaryButtonToBeEnabled } from './Utils/helpers'
import PaymentConfirmation from './PaymentConfirmation/PaymentConfimation'
import { useLazyGetAllUsersQuery } from '../../app/services/users'
import { updateOrg } from '../../utils/orgService'
import { formSummaryObjectFromSubscription } from '../../pages/ManageSubscription/Utils/formSummaryObjectFree'
const _ = require("lodash")

const SubscriptionOperationPopUp = ({ trigger, teamSize }) => {

    const dispatch = useDispatch()
    const [productPrices, setProductPrices] = useState(null)
    const [getPricesQuery,] = useLazyGetPricesV2Query()
    const [selectedPlanType, setSelectedPlanType] = useState(1)
    const [isParameterSelectionAreaEnabled, setIsParameterSelectionAreaEnabled] = useState(true)
    const [isProductsVisited, setIsProductsVisited] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0) //0 for plan, 1 for products
    const [tempTeamSize, setTempTeamSize] = useState(1)
    const [tempAdditionalTutors, setTempAdditionalTutors] = useState(0)
    const [selectedPlanId, setSelectedPlanId] = useState() // Load this with free annual by default
    const [freeAnnualBasePackageId, setFreeAnnualBasePackageId] = useState(null)
    const [selectedFreeProducts, setSelectedFreeProducts] = useState({
        "CRM": { price: 0, value: "", selected: true },
        "Assignments": { price: "", value: "", selected: false, default: true },
        "Custom Tests": { price: "", value: "", selected: false, default: true },
        "Calendar": { price: "", value: "", selected: false, default: true }
    })
    const [selectedProducts, setSelectedProducts] = useState({
        "CRM": { price: 0, value: "", selected: true },
        "Assignments": { price: "", value: "", selected: false, default: true },
        "Custom Tests": { price: "", value: "", selected: false, default: true },
        "Calendar": { price: "", value: "", selected: false, default: true }
    })
    const [recommendedBasePackage, setRecommendedBasePackage] = useState()
    const [isPlanTypeChangedManually, setIsPlanTypeChangedManually] = useState(false)
    const [subscriptionSelectionStage, setSubscriptionSelectionStage] = useState("subscription-select")

    const [priceDetailsForPriceSummary, setPriceDetailsForPriceSummary] = useState(null)
    const [discountDetails, setDiscountDetails] = useState({
        type: null
    })
    const [discountAmount, setDiscountAmount] = useState(null);

    const [popUpHeading, setPopUpHeading] = useState("")

    const [createNewSubscription,] = useCreateNewSubscriptionMutation()
    const [updateOrgRequest,] = useUpdateOrgDataMutation()
	const [cancelSubV2Req] = useCancelSubscriptionV2Mutation()

    const [isFreeLoading, setIsFreeLoading] = useState(false)
    const [proceedButtonClicked, setProceedButtonClicked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasRecommended, setHasRecommended] = useState(false)

    const { initialTeamSize, subscriptionPopUpOperationMode, subscriptionPopUpTrigger } = useSelector((state) => state.subscriptionOperation)
    const { subscriptionV2 } = useSelector((state) => state.subscription)

    //Payment success states
    const [isPaymentSuccessfull, setIsPaymentSuccessfull] = useState(false)

    //For operation in update mode
    const [tempSiMapping, setTempSiMapping] = useState(null)
    const [currentPlanId, setCurrentPlanId] = useState(null)
    const [summaryObject, setSummaryObject] = useState(null)
    const [isPriceLoading, setIsPriceLoading] = useState(false)
    const { organization } = useSelector((state) => state.organization)
    const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false)
    const { paymentMethods, defaultPaymentMethodId } = useSelector(
        (state) => state.subscription
    );
    const [addNewCard, setAddNewCard] = useState(false)
    const [confirmCloseTrigger, setConfirmCloseTrigger] = useState(false)
    const [paymentConfirmationTrigger, setPaymentConfirmatinTrigger] = useState(false)
    const [fetchAllUsersQuery, ] = useLazyGetAllUsersQuery()
    const [isFreeTrial, setIsFreeTrial] = useState(false)



    //Scroll to top for the subcription pop-up when the payment confirmation trigger is true
    useEffect(()=>{
        if(paymentConfirmationTrigger){
            const innerPopUpContainer = document.getElementById("inside")
            innerPopUpContainer.scroll({
                top : 0,
                behavior : "smooth"
            })
        }
    },[paymentConfirmationTrigger])

    //Set free trial or not for the payment confirmation pop-up
    useEffect(()=>{
        if(subscriptionV2 && subscriptionPopUpOperationMode === "update-subscription"){
            const daysBetweenStartAndEnd = Math.floor(
                Math.abs(
                  new Date(subscriptionV2?.subscription.startDate).getTime() -
                  new Date(subscriptionV2?.subscription.endDate).getTime()
                ) /
                (24 * 60 * 60 * 1000)
              );
            if(daysBetweenStartAndEnd === 14){
                setIsFreeTrial(true)
            } else {
                setIsFreeTrial(false)
            }
        }
    },[subscriptionV2, subscriptionPopUpOperationMode])

    console.log(subscriptionV2)

    //Seed the siMapping & selected products with current subscriptions
    useEffect(() => {
        if (subscriptionV2 && subscriptionPopUpOperationMode === "update-subscription" && productPrices) {
            const [currentBasePackageId, tempSelectedProducts, _tempSiMapping] = getSelectedProductsAndBasePackageFromSiMapping(productPrices, selectedProducts, subscriptionV2)
            setTempSiMapping(_tempSiMapping)
            setSelectedPlanId(currentBasePackageId)
            setCurrentPlanId(currentBasePackageId)
            setSelectedProducts(tempSelectedProducts)
            setSelectedPlanType(subscriptionV2?.subscription.__t === "AnnualSubscription" ? 1 : 0)
        }
    }, [subscriptionPopUpOperationMode, subscriptionV2, productPrices])

    //For heading on the pop-up
    useEffect(() => {
        let heading = null
        switch (subscriptionSelectionStage) {
            case "subscription-select": heading = subscriptionPopUpOperationMode === "new-subscription" ? "Choose Your Subscription Plan" : "Manage Subscription"; break;
            case "review-pay": heading = "Review and Pay"; break;
        }
        setPopUpHeading(heading)
    }, [subscriptionSelectionStage, subscriptionPopUpOperationMode])

    //For loading free base package annual
    useEffect(() => {
        if (productPrices) {
            const basePackageFreeAnnual = getFreeBasePackage(productPrices)
            setFreeAnnualBasePackageId(basePackageFreeAnnual.id)
        }
    }, productPrices)

    //Check if the current base package is free & disable some parts
    useEffect(() => {
        if (selectedPlanId && productPrices) {
            setIsParameterSelectionAreaEnabled(isParameterSelectionToBeEnabled(freeAnnualBasePackageId, productPrices, currentPlanId, selectedPlanId, subscriptionPopUpOperationMode))
        }
    }, [selectedPlanId, productPrices, freeAnnualBasePackageId, subscriptionPopUpOperationMode, currentPlanId])

    //Fetch product prices
    useEffect(() => {
        (async () => {
            try {
                const res = await getPricesQuery()
                const _productPrices = res.data.data.prices
                setProductPrices(_productPrices)
            } catch (err) {
                console.log(err)
            }
        })()
    }, [])

    //Get correspongind products & base package only when the base selected plan type is changed manually
    useEffect(() => {
        if (isPlanTypeChangedManually && productPrices) {
            setSelectedProducts(getCorrespondingProductSelection(selectedProducts, productPrices, selectedPlanType, selectedPlanId, setSelectedPlanId))
        }
    }, [selectedPlanType, productPrices, isPlanTypeChangedManually])


    //To load free tier by default for base package & other products as well
    useEffect(() => {
        if (productPrices) {
            const basePackageFreeAnnual = getFreeBasePackage(productPrices)
            setSelectedPlanId(basePackageFreeAnnual.id)

            let tempSelectedFreeProducts = { ...selectedFreeProducts }
            tempSelectedFreeProducts = loadFreeTier(productPrices, 1, tempSelectedFreeProducts)
            //Loading tutor price mapped to starter package tutor price with 0 quantity for free package
            const requiredTutorPrice = fetchTutorPriceForPackage(productPrices, "annual", "Starter")
            tempSelectedFreeProducts["tutors"] = {
                value: requiredTutorPrice.id,
                selected: true,
                name: "Tutors",
                quantity: 0
            }
            setSelectedFreeProducts(tempSelectedFreeProducts)
        }
    }, [productPrices])


    //To change recommnended base package when no of tutors chagnes
    useEffect(() => {
        if (!subscriptionPopUpOperationMode || subscriptionPopUpOperationMode === "update-subscription") return;
        if (productPrices && tempTeamSize) {
            const tempRecommendedProducts = getRecommendedPackageWithProducts(tempTeamSize, selectedPlanType, productPrices)
            setRecommendedBasePackage(tempRecommendedProducts["basePackage"])
            setHasRecommended(true)
        }
    }, [tempTeamSize, selectedPlanType, subscriptionPopUpOperationMode])

    //Seeding team size
    useEffect(() => {
        let _initalTeamSize = subscriptionPopUpOperationMode === "new-subscription" || subscriptionPopUpOperationMode === "update-from-free" ? window.localStorage.getItem("noOfTutors") ?? initialTeamSize : initialTeamSize
        if (_initalTeamSize) {
            //Custom logic for getting the additonal tutors & included tutors goes here.
            setTempTeamSize(_initalTeamSize)
            setTempAdditionalTutors(0)
            //Seed the selected packages with recommended packages when not in update-subscription mode
            if (!subscriptionPopUpOperationMode || subscriptionPopUpOperationMode === "update-subscription") return;
            if (productPrices) {
                const tempSelectedProducts = { ...selectedProducts }
                const tempRecommendedProducts = getRecommendedPackageWithProducts(_initalTeamSize, selectedPlanType, productPrices)
                console.log("TRP", tempRecommendedProducts)
                Object.keys(tempSelectedProducts).forEach((key, idx) => {
                    tempSelectedProducts[key] = tempRecommendedProducts[key]
                })
                setSelectedProducts(tempSelectedProducts)
                setRecommendedBasePackage(tempRecommendedProducts["basePackage"])
                setHasRecommended(true)
            }
        }
    }, [productPrices, initialTeamSize, subscriptionPopUpOperationMode])



    //For changing the number of additional number of tutors when the selected plan is changed
    useEffect(() => {
        if (selectedPlanId && productPrices) {
            const lookupKeyForSelectedPlanId = fetchLookupKeyForId(productPrices, selectedPlanId, selectedPlanType === 0 ? "monthly" : "annual", "BasePackageV2")
            if (!lookupKeyForSelectedPlanId.includes("Free")) {
                const basePackageTutorLimit = getBasePackageTutorLimit(lookupKeyForSelectedPlanId)
                const totalTeamSize = tempTeamSize
                const additionalTutorLicenses = basePackageTutorLimit < totalTeamSize ? totalTeamSize - basePackageTutorLimit : 0
                setTempAdditionalTutors(additionalTutorLicenses)
            }
        }
    }, [selectedPlanId, productPrices])


    //To construct pricedetails to be sent to price summary, later extract out to a function
    useEffect(() => {
        if (selectedProducts && productPrices && (freeAnnualBasePackageId !== selectedPlanId) && selectedPlanId && (subscriptionPopUpOperationMode === "new-subscription" || subscriptionPopUpOperationMode === "update-from-free")) {
				//To from summary object in case of upgrade from free
			if(subscriptionPopUpOperationMode === "update-from-free"){
			    const _summaryObject = formSummaryObjectFromSubscription(subscriptionV2,productPrices, selectedPlanType,selectedProducts, selectedPlanId, tempAdditionalTutors)	
				setSummaryObject(_summaryObject)
			}
            const tempPriceDetails = getPriceDetails(productPrices, tempAdditionalTutors, selectedProducts, selectedFreeProducts, freeAnnualBasePackageId, selectedPlanId, currentPlanId, selectedPlanType, discountDetails, subscriptionPopUpOperationMode, null, setDiscountAmount, null, null,subscriptionV2?.subscription?.__t === "FreeSubscription"  && !organization?.hasUsedFreeTrial)
            setPriceDetailsForPriceSummary(tempPriceDetails)
        }
    }, [selectedProducts, selectedPlanId, productPrices, tempAdditionalTutors, discountDetails, subscriptionPopUpOperationMode])

    //To create a free subscription when close is clicked or I'll stay on Free is clicked
    const handleFreeClick = async (e) => {
        try {

            if(isFreeLoading) return ;
            await createFreeSubscription(freeAnnualBasePackageId, selectedFreeProducts, updateOrgRequest, setIsFreeLoading, createNewSubscription)
        } catch (err) {
            console.log(err)
        }
    }

    //To handle the proceed to pay click
    const handleProceedToPayClick = async (e) => {
        if (!productPrices) return;
        setProceedButtonClicked(!proceedButtonClicked)
    }

    //Debugging
    useEffect(() => {
        console.log(selectedPlanId)
    }, [selectedPlanId])

    //Generate a temporary SI Mapping for downstream uses
    useEffect(() => {
        if (subscriptionPopUpOperationMode === "update-subscription" && tempSiMapping && productPrices && freeAnnualBasePackageId) {
            const stagingSiMapping = getStagingSiMapping(productPrices, tempSiMapping, freeAnnualBasePackageId, selectedPlanId, selectedPlanType, selectedProducts, selectedFreeProducts, subscriptionV2, tempAdditionalTutors)
            setTempSiMapping(stagingSiMapping)
        }

    }, [subscriptionPopUpOperationMode, selectedProducts, currentPlanId, selectedPlanId, selectedFreeProducts, selectedPlanType, productPrices, tempAdditionalTutors, tempTeamSize])


    //For summary corner & update requests & price section
    useEffect(() => {
        (async () => {
            try {
                if (tempSiMapping && productPrices && subscriptionV2) {
                    setIsPriceLoading(true)
                    const _summaryObject = formSummaryObject(tempSiMapping, setTempSiMapping, subscriptionV2, productPrices, selectedPlanType, setSelectedPlanType, [])
                    setSummaryObject(_summaryObject)
                    const oldType = subscriptionV2?.subscription.__t === "AnnualSubscription" ? "annual" : "monthly"
                    const newType = selectedPlanType === 1 ? "annual" : "monthly"

                    //To add coupons after the pricing section is done
                    const couponId = discountDetails?.couponId
                    const updateRequest = formUpdateRequest(oldType, newType, productPrices, tempSiMapping, [], couponId)
                    const previewProrationRes = await fetchInvoicePreviewForUpdate(updateRequest)
                    const tempPriceDetails = getPriceDetails(productPrices, tempAdditionalTutors, selectedProducts, selectedFreeProducts, freeAnnualBasePackageId, selectedPlanId, currentPlanId, selectedPlanType, discountDetails, subscriptionPopUpOperationMode, previewProrationRes, setDiscountAmount, subscriptionV2, tempSiMapping)
                    setPriceDetailsForPriceSummary(tempPriceDetails)
                    setIsPriceLoading(false)
                }
            } catch (err) {
                console.log(err)
                setIsPriceLoading(false)
            }
        })()
    }, [tempSiMapping, productPrices, subscriptionV2, selectedPlanType, discountDetails])

    //Handle tutor nos change from input field directly
    const handleTutorNosChange = (e) => {
        if (e.target.value) {
            const value = parseInt(e.target.value)
            if (value >= 1 && value <= 999) {
                setTempTeamSize(value)
                if (productPrices && selectedPlanId) {
                    const lookupKeyForSelectedPlanId = fetchLookupKeyForId(productPrices, selectedPlanId, selectedPlanType === 0 ? "monthly" : "annual", "BasePackageV2")
                    if (!lookupKeyForSelectedPlanId.includes("Free")) {
                        const basePackageTutorLimit = getBasePackageTutorLimit(lookupKeyForSelectedPlanId)
                        const totalTeamSize = value
                        if (totalTeamSize + tempAdditionalTutors <= basePackageTutorLimit) {
                            setTempTeamSize(value)
                            setTempAdditionalTutors(0)
                        } else {
                            setTempTeamSize(value)
                            setTempAdditionalTutors(totalTeamSize - basePackageTutorLimit)
                        }
                    }
                }
            }
        } else {
			setTempTeamSize(e.target.value)
		}
    }

    //Handle continue/update subscription button
    const handleUpdateSubscriptionClick = async (e, setIsLoading) => {
        try {
	    
	    //CASE UPDATE FROM FREE
			if(subscriptionV2 && subscriptionV2.subscription.__t === "FreeSubscription"){
				setIsLoading(true)
				//Step 1 -> Delete existing free subscription
				await deleteSubscription()
				//Step 2 -> Create an entirely new subscription
				const oldType = subscriptionV2?.subscription.__t === "AnnualSubscription" ? "annual" : "monthly"
				const newType = selectedPlanType === 1 ? "annual" : "monthly"
				const couponId = discountDetails?.couponId
				let body = {
					items: [],
					isFreeTrial: !organization.hasUsedFreeTrial,
					planType: newType,
					isEnabledPAYG: true,
					couponId: couponId,
				};
				console.log("BODY SUB", selectedPlanId, selectedPlanType)
				body.items = createSubscriptionItems(selectedProducts,selectedPlanId,productPrices,newType,tempAdditionalTutors)
				console.log("BODY SUB", body)
				let subscription = await createNewSubscription(body)
				setIsPaymentSuccessfull(true)
				setIsLoading(false)
				setPaymentConfirmatinTrigger(false)
				updateOrg(organization._id, {hasUsedFreeTrial : true})	
				return
			}
		

	    
	    //CASE UPDATE FROM NORMAL
            if (subscriptionV2 && productPrices && tempSiMapping) {
                setIsLoading(true)
				if(freeAnnualBasePackageId === selectedPlanId){
					await cancelSubV2Req({data : [{op : "cancel"}]})
					setIsPaymentSuccessfull(true)
					setIsLoading(false)
					setPaymentConfirmatinTrigger(false)
					updateTutorLicenses(subscriptionV2, fetchAllUsersQuery)
					return 
				}	

                const oldType = subscriptionV2?.subscription.__t === "AnnualSubscription" ? "annual" : "monthly"
                const newType = selectedPlanType === 1 ? "annual" : "monthly"
                const couponId = discountDetails?.couponId
                const updateRequest = formUpdateRequest(oldType, newType, productPrices, tempSiMapping, [], couponId)
                await updateSubscription(updateRequest, updateOrgRequest, {
                    _id: organization._id
                })
                setIsPaymentSuccessfull(true)
                setIsLoading(false)
                setPaymentConfirmatinTrigger(false)
            }
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }

    }

    //Enable or disable continue/proceed to pay button
    useEffect(() => {
        if (subscriptionPopUpOperationMode === "new-subscription" ) return;
        console.log(tempAdditionalTutors)
        const tempIsContinueButtonEnabled = isPrimaryButtonToBeEnabled(productPrices, subscriptionSelectionStage, tempSiMapping, subscriptionV2, selectedPlanId, currentPlanId, selectedPlanType, paymentMethods, subscriptionPopUpOperationMode)
        setIsContinueButtonEnabled(tempIsContinueButtonEnabled)
        if (subscriptionSelectionStage === "review-pay")
            setAddNewCard(!tempIsContinueButtonEnabled);

    }, [subscriptionSelectionStage, selectedPlanId, paymentMethods, subscriptionV2, tempSiMapping, subscriptionPopUpOperationMode])

    //To handle popup closing
    const handlePopUpClose = async (e) => {

        if (subscriptionPopUpOperationMode === "new-subscription") {
            try {
                if(isFreeLoading) return;
                await createFreeSubscription(freeAnnualBasePackageId, selectedFreeProducts, updateOrgRequest, setIsFreeLoading, createNewSubscription)
            } catch (err) {
                console.log(err)
            }
        }

	if(subscriptionPopUpOperationMode === "update-from-free"){
		setConfirmCloseTrigger(true)
		setSubscriptionSelectionStage("subscription-select")
		return
	
	}

        if (subscriptionV2 && tempSiMapping && subscriptionPopUpOperationMode === "update-subscription") {
            if (!isSiMappingEqual(subscriptionV2.siMapping, tempSiMapping)) {
                setConfirmCloseTrigger(true)
                setSubscriptionSelectionStage("subscription-select")
            } else {
                dispatch(toggleSubscriptionPopUpTrigger(false))
                setConfirmCloseTrigger(false)
                setSubscriptionSelectionStage("subscription-select")
            }
        }
    }

    //To close popup after success payment
    const closeAfterSuccess = async () => {
        dispatch(toggleSubscriptionPopUpTrigger(false))
        setIsPaymentSuccessfull(false)
        setSubscriptionSelectionStage("subscription-select")
        await updateTutorLicenses(organization, fetchAllUsersQuery)
        window.location.reload()
    }


    return (
        <PopupContainer customCloseButton={true} customInnerContainer={styles.popUpContainer} trigger={trigger} {...(subscriptionPopUpOperationMode === "update-subscription")} innerContainerStyle={{overflowY : paymentConfirmationTrigger ? "hidden" : "auto"}}>
            {   
                confirmCloseTrigger ? <UnsavedChanges setConfirmCloseTrigger={setConfirmCloseTrigger} /> :
                    <>
                        {paymentConfirmationTrigger ? <PaymentConfirmation setTrigger={setPaymentConfirmatinTrigger} handleUpdateSubscriptionClick={handleUpdateSubscriptionClick} subscriptionV2={subscriptionV2} 
							 requiresFreeTrial={subscriptionV2?.subscription.__t ==="FreeSubscription" && !organization?.hasUsedFreeTrial}/> : <></>}
                        <div className={styles.headingContainer}>
                            <div>
                                {popUpHeading}
                            </div>
                            <button onClick={(e) => handlePopUpClose(e)}><CrossIcon /></button>
                        </div>
                        {
                            subscriptionSelectionStage === "subscription-select" && productPrices && ((subscriptionPopUpOperationMode === "new-subscription" && hasRecommended) || subscriptionPopUpOperationMode === "update-subscription" || subscriptionPopUpOperationMode === "update-from-free") ?
                                <>
                                    <div className={styles.parameterSelectionContainer}>
                                        <div className={styles.teamInputArea}>
                                            {
                                                isParameterSelectionAreaEnabled ?
                                                    <></> :
                                                    <Tooltip anchorComponent={<div className={styles.cautionIcon}><CautionIcon exclamationColour={"#25335A"} /></div>}
                                                        tooltipContent={"Additional Tutor Licenses are not available in Explorer plan. To access extra Licenses, upgrade your plan."}
                                                        tooltipStyleClass={styles.tooltipStyleClass}
                                                        tooltipTriangleStyling={styles.tooltipTriangleClass} />
                                            }

                                            <div className={isParameterSelectionAreaEnabled ? styles.inputArea : styles.inputAreaDisabled} >
                                                <label>Enter Your Team Size :</label>
                                                {
                                                    !isParameterSelectionAreaEnabled ?
                                                        <div className="h-[36px] w-[72px] text-secondary-60 font-[400] text-[18px] bg-secondary-5 border-[2px] border-secodnary-30 text-right rounded-[6px] px-[12px] py-[6.5px]">1</div>
                                                        :
                                                        <input type="number" className={styles.textInput} disabled={!isParameterSelectionAreaEnabled} value={tempTeamSize} onChange={(e) => handleTutorNosChange(e)} />
                                                }

                                            </div>
                                        </div>
                                        <div className={styles.billingSelectionArea}>
                                            <div className={isParameterSelectionAreaEnabled ? styles.heading : styles.headingDisabled}>Billing:</div>
                                            <div className={styles.planSelection}>
                                                <div className={`${isParameterSelectionAreaEnabled ? styles.selectionButton : styles.selectionButtonDisabled} ${selectedPlanType === 1 ? styles.selectionButtonSelected : ""}`} style={{pointerEvents : selectedPlanType === 1 ? "none" : ""}}>
                                                    <RadioButtonV2 disabled={!isParameterSelectionAreaEnabled} selected={selectedPlanType === 1} onClick={(e) => { setSelectedPlanType(1); setIsPlanTypeChangedManually(true) }} /> <label> Yearly <span className='text-secondary-60'>(Save 15%)</span></label>
                                                </div>
                                                {
                                                    subscriptionPopUpOperationMode === "update-subscription" ?
                                                        subscriptionV2 && subscriptionV2?.subscription.__t === "AnnualSubscription" && !subscriptionV2?.subscription.basePackage.name.includes("Free") ?
                                                            <></>
                                                            : <div className={`${isParameterSelectionAreaEnabled ? styles.selectionButton : styles.selectionButtonDisabled} ${selectedPlanType === 0 ? styles.selectionButtonSelected : ""}`} style={{pointerEvents : selectedPlanType === 0 ? "none" : ""}}>
                                                                <RadioButtonV2 disabled={!isParameterSelectionAreaEnabled} selected={selectedPlanType === 0} onClick={(e) => { setSelectedPlanType(0); setIsPlanTypeChangedManually(true) }} /> <label> Monthly</label>
                                                            </div>
                                                        :
                                                        <div className={`${isParameterSelectionAreaEnabled ? styles.selectionButton : styles.selectionButtonDisabled} ${selectedPlanType === 0 ? styles.selectionButtonSelected : ""}`} style={{pointerEvents : selectedPlanType === 0 ? "none" : ""}}>
                                                            <RadioButtonV2 disabled={!isParameterSelectionAreaEnabled} selected={selectedPlanType === 0} onClick={(e) => { setSelectedPlanType(0); setIsPlanTypeChangedManually(true) }} /> <label> Monthly</label>
                                                        </div>
                                                }

                                            </div>
                                        </div>

                                    </div>
                                    <SubscriptionFolder {...{ isProductsVisited, setIsProductsVisited, selectedTab, setSelectedTab, selectedPlanType, setSelectedPlanType, tempAdditionalTutors, setTempAdditionalTutors, selectedPlanId, setSelectedPlanId, productPrices, tempTotalTeamSize: tempTeamSize, setTempTotalTeamSize: setTempTeamSize, recommendedBasePackage, freeAnnualBasePackageId, selectedFreeProducts, selectedProducts, setSelectedProducts, currentPlanId }} /></>
                                : <></>
                        }
                        {
                            subscriptionSelectionStage === "review-pay" && productPrices ?
                                <>
                                    {isPaymentSuccessfull ? <PaymentSuccess closeAfterSuccess={closeAfterSuccess} /> : <></>}
                                    <ReviewAndPayContainer
                                        discountDetails={discountDetails}
                                        setDiscountDetails={setDiscountDetails}
                                        discountAmount={discountAmount}
                                        setDiscountAmount={setDiscountAmount}
                                        selectedProducts={selectedProducts}
                                        selectedBasePackageId={selectedPlanId}
                                        proceedButtonClicked={proceedButtonClicked}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        productPrices={productPrices}
                                        selectedPlanType={selectedPlanType === 1 ? "annual" : "monthly"}
                                        additionalTutorNos={tempAdditionalTutors}
                                        summaryObject={summaryObject}
                                        _addNewCard={addNewCard}
                                        setIsPaymentSuccessfull={setIsPaymentSuccessfull}
                                    />
                                </>
                                : <></>
                        }

                        <HorizontalDivider style={{ borderColor: "#515C7B" }} />
                        <PriceSummary isSelectedFreePlan={!isParameterSelectionAreaEnabled} priceDetails={priceDetailsForPriceSummary} isPriceLoading={isPriceLoading} />
                        <ActionArea subscriptionSelectionStage={subscriptionSelectionStage} setSubscriptionSelectionStage={setSubscriptionSelectionStage} isFreePlanSelected={!isParameterSelectionAreaEnabled} isFreeLoading={isFreeLoading} handleFreeClick={handleFreeClick} handleProceedToPayClick={handleProceedToPayClick} isPaidSubscriptionLoading={isLoading} subscriptionPopUpOperationMode={subscriptionPopUpOperationMode}
                            isContinueButtonEnabled={isContinueButtonEnabled} handleUpdateSubscriptionClick={handleUpdateSubscriptionClick} isSubscriptionUpdating={isLoading}
                            isProceedButtonEnabled={isContinueButtonEnabled}
                            handleCancelUpdateSubscriptionClick={handlePopUpClose}
                            setPaymentConfirmatinTrigger={setPaymentConfirmatinTrigger}
                            isFreeTrial={isFreeTrial}
                            priceDetails={priceDetailsForPriceSummary}
                            setIsSubscriptionUpdating={setIsLoading}
							requiresFreeTrial = {subscriptionV2?.subscription?.__t === "FreeSubscription" && !organization?.hasUsedFreeTrial}
                        />
                    </>
            }

        </PopupContainer>
    )
}

export default SubscriptionOperationPopUp
