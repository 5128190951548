//Start of session based alerts
//dPAYG is disabled PAYG
//usage is exhausated
//Configurator
import { Link } from "react-router-dom"
import { getFormattedDateV2 } from "./dateUtils"
import { triggerToast } from "./toastController"
import { fetchArchivedSubscriptionsForOrg } from "./subscriptionService"
import subscription from "../app/slices/subscription"
import { triggerUpdateSubscriptionPopUp } from "./subscriptionPopUpService"

function setAlertPreferencesOnLogin() {
    const alertPreferences = {
        "monthly_show_alert_on_PAYG_90_usage": true,
        "show_alert_on_50_usage": true,
        "monthly_show_alert_on_dPAYG_100_usage": true,
        "annual_show_alert_on_90_usage": true,
        "annual_show_alert_on_100_usage": true,
        "show_alert_on_cancel_expiry": true,
        "show_alert_on_downgrade_payment_failed": true,
        "show_alert_on_payment_failed_downgrade": true,
        "show_alert_on_cancellation_downgrade": true
    }

    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))

}

//Setters
function setShowAlertOnPAYG90Usage() {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    alertPreferences["monthly_show_alert_on_PAYG_90_usage"] = false
    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))
}

function setShowAlertOn50Usage() {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    alertPreferences["show_alert_on_50_usage"] = false
    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))
}

function setShowAlertOnD_PAYG100Usage() {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    alertPreferences["monthly_show_alert_on_dPAYG_100_usage"] = false
    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))
}

function setAnnualShowAlertOn100Usage() {
    console.log("MDFY HERE")
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    alertPreferences["annual_show_alert_on_100_usage"] = false
    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))
}

function setAnnualShowAlertOn90Usage() {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    alertPreferences["annual_show_alert_on_90_usage"] = false
    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))
}

function setShowAlertOnPaymentFailedDowngrade() {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    alertPreferences["show_alert_on_payment_failed_downgrade"] = false
    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))
}

function setShowAlertOnCancellationDowngrade() {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    alertPreferences["show_alert_on_cancellation_downgrade"] = false
    window.localStorage.setItem("alertPreferences", JSON.stringify(alertPreferences))
}


//Actions
function showAlertOnPAYG90Usage(assignmentUsagePercentage, sessionsUsagePercentage, customContentUSagePercentage, subscriptionInsights, subscriptionV2, organization, firstSetOfNotifsShown, firstSetOfNotifsShownAnnual) {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    if (alertPreferences["monthly_show_alert_on_PAYG_90_usage"]) {
        if (assignmentUsagePercentage >= 90 && organization.isEnabledPAYG && subscriptionV2?.subscription.__t === "MonthlySubscription") {
            triggerToast(`Warning: You have exhausted 90% of your ${"Assignments"} ${subscriptionInsights.limits.assignments.base} included with your plan. PAYG (pay as you go) is enabled in case the usage limit is exceeded.`, "warn", { autoClose: false, onClose: () => { setShowAlertOnPAYG90Usage() } })
            firstSetOfNotifsShown += 1
        }
        if (sessionsUsagePercentage >= 90 && organization.isEnabledPAYG && subscriptionV2?.subscription.__t === "MonthlySubscription") {
            triggerToast(`Warning: You have exhausted 90% of your ${"Calendar"} ${subscriptionInsights.limits.sessions.base} included with your plan. PAYG (pay as you go) is enabled in case the usage limit is exceeded.`, "warn", { autoClose: false, onClose: () => { setShowAlertOnPAYG90Usage() } })
            firstSetOfNotifsShown += 1
        }
        if (customContentUSagePercentage >= 90 && organization.isEnabledPAYG && subscriptionV2?.subscription.__t === "MonthlySubscription") {
            triggerToast(`Warning: You have exhausted 90% of your ${"Custom Tests"} ${subscriptionInsights.limits.customContent.base} included with your plan. PAYG (pay as you go) is enabled in case the usage limit is exceeded.`, "warn", { autoClose: false, onClose: () => { setShowAlertOnPAYG90Usage() } })
            firstSetOfNotifsShown += 1
        }
    }
    return firstSetOfNotifsShown
}


function showAnnualAlertOn90Usage(assignmentUsagePercentage, sessionsUsagePercentage, customContentUSagePercentage, subscriptionInsights, subscriptionV2, organization, firstSetOfNotifsShown, firstSetOfNotifsShownAnnual) {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    if (alertPreferences["annual_show_alert_on_90_usage"] && firstSetOfNotifsShownAnnual === 0) {
        if (assignmentUsagePercentage >= 90 && subscriptionV2?.subscription.__t === "AnnualSubscription") {
            triggerToast(<div>You have exhausted 90% of {"Assignments"} {subscriptionInsights.limits.assignments.base} included with your plan.</div>, "warn", { autoClose: false, onClose: () => { setAnnualShowAlertOn90Usage() } })
            firstSetOfNotifsShownAnnual += 1
        }
        if (sessionsUsagePercentage >= 90 && subscriptionV2?.subscription.__t === "AnnualSubscription") {
            triggerToast(<div>You have exhausted 90% of {"Calendar"} {subscriptionInsights.limits.sessions.base} included with your plan.</div>, "warn", { autoClose: false, onClose: () => { setAnnualShowAlertOn90Usage() } })
            firstSetOfNotifsShownAnnual += 1
        }
        if (customContentUSagePercentage >= 90 && subscriptionV2?.subscription.__t === "AnnualSubscription") {
            triggerToast(<div>You have exhausted 90% of {"Custom Tests"} {subscriptionInsights.limits.customContent.base} included with your plan.</div>, "warn", { autoClose: false, onClose: () => { setAnnualShowAlertOn90Usage() } })
            firstSetOfNotifsShownAnnual += 1
        }
    }
    return firstSetOfNotifsShownAnnual
}

function showAlertOn50Usage(assignmentUsagePercentage, sessionsUsagePercentage, customContentUSagePercentage, subscriptionInsights, subscriptionV2, organization, firstSetOfNotifsShown, firstSetOfNotifsShownAnnual) {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    if (alertPreferences["show_alert_on_50_usage"] && firstSetOfNotifsShown == 0 && firstSetOfNotifsShownAnnual === 0) {
        if (assignmentUsagePercentage >= 50) {
            triggerToast(`Warning: You have exhausted 50% of your ${"Assignments"} ${subscriptionInsights.limits.assignments.base} included with your plan.`, "warn", { autoClose: false, onClose: () => { setShowAlertOn50Usage() } })
        }
        if (sessionsUsagePercentage >= 50) {
            triggerToast(`Warning: You have exhausted 50% of your ${"Calendar"} ${subscriptionInsights.limits.sessions.base} included with your plan.`, "warn", { autoClose: false, onClose: () => { setShowAlertOn50Usage() } })

        }
        if (customContentUSagePercentage >= 50) {
            triggerToast(`Warning: You have exhausted 50% of your ${"Custom Tests"} ${subscriptionInsights.limits.customContent.base} included with your plan.`, "warn", { autoClose: false, onClose: () => { setShowAlertOn50Usage() } })
        }
    }
}

function showAlertOnD_PAYG100Usage(assignmentUsagePercentage, sessionsUsagePercentage, customContentUSagePercentage, subscriptionInsights, subscriptionV2, organization, firstSetOfNotifsShown, firstSetOfNotifsShownAnnual) {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
    if (alertPreferences["monthly_show_alert_on_dPAYG_100_usage"]) {
        if (assignmentUsagePercentage === 100 && !organization.isEnabledPAYG && subscriptionV2?.subscription.__t === "MonthlySubscription") {
            triggerToast(<div>You have exhausted 100% of {"Assignments"} {subscriptionInsights.limits.assignments.base} included with your plan. Enable Pay As You Go under <Link onClick={(e) => triggerUpdateSubscriptionPopUp()}>Manage Subscription</Link> for additional usage-based credits.</div>, "warn", { autoClose: false, onClose: () => { setShowAlertOnD_PAYG100Usage() } })

        }
        if (sessionsUsagePercentage === 100 && !organization.isEnabledPAYG && subscriptionV2?.subscription.__t === "MonthlySubscription") {
            triggerToast(<div>You have exhausted 100% of {"Calendar"} {subscriptionInsights.limits.sessions.base} included with your plan. Enable Pay As You Go under <Link onClick={(e) => triggerUpdateSubscriptionPopUp()}>Manage Subscription</Link> for additional usage-based credits.</div>, "warn", { autoClose: false, onClose: () => { setShowAlertOnD_PAYG100Usage() } })

        }
        if (customContentUSagePercentage === 100 && !organization.isEnabledPAYG && subscriptionV2?.subscription.__t === "MonthlySubscription") {
            triggerToast(<div>You have exhausted 100% of {"Custom Tests"} {subscriptionInsights.limits.customContent.base} included with your plan. Enable Pay As You Go under <Link onClick={(e) => triggerUpdateSubscriptionPopUp()}>Manage Subscription</Link> for additional usage-based credits.</div>, "warn", { autoClose: false, onClose: () => { setShowAlertOnD_PAYG100Usage() } })

        }
    }

}

function showAnnualAlertOn100Usage(assignmentUsagePercentage, sessionsUsagePercentage, customContentUSagePercentage, subscriptionInsights, subscriptionV2, organization, firstSetOfNotifsShown, firstSetOfNotifsShownAnnual) {
    const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))

    if (alertPreferences["annual_show_alert_on_100_usage"]) {
        if (assignmentUsagePercentage === 100 && subscriptionV2?.subscription.__t === "AnnualSubscription") {
            triggerToast(<div>You have exhausted 100% of {"Assignments"} {subscriptionInsights.limits.assignments.base} included with your plan.</div>, "warn", { autoClose: false, onClose: () => { setAnnualShowAlertOn100Usage() } })
            firstSetOfNotifsShownAnnual += 1
        }
        if (sessionsUsagePercentage === 100 && subscriptionV2?.subscription.__t === "AnnualSubscription") {
            triggerToast(<div>You have exhausted 100% of {"Calendar"} {subscriptionInsights.limits.sessions.base} included with your plan.</div>, "warn", { autoClose: false, onClose: () => { setAnnualShowAlertOn100Usage() } })
            firstSetOfNotifsShownAnnual += 1
        }
        if (customContentUSagePercentage === 100 && subscriptionV2?.subscription.__t === "AnnualSubscription") {
            triggerToast(<div>You have exhausted 100% of {"Custom Tests"} {subscriptionInsights.limits.customContent.base} included with your plan.</div>, "warn", { autoClose: false, onClose: () => { setAnnualShowAlertOn100Usage() } })
            firstSetOfNotifsShownAnnual += 1
        }
    }
    return firstSetOfNotifsShownAnnual
}


async function showAlertOnPaymentFailedDowngrade() {
    try {
        const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
        const data = await fetchArchivedSubscriptionsForOrg()
        if (!data) return;
        const { archivedSubscriptions } = data
        console.log(alertPreferences["show_alert_on_payment_failed_downgrade"])
        if (archivedSubscriptions.length > 0 && alertPreferences["show_alert_on_payment_failed_downgrade"]) {
            const latestArchivedSubscription = archivedSubscriptions.pop()
            if (latestArchivedSubscription && latestArchivedSubscription.downgradeReason === "payment_failed") {
                triggerToast(<div>We encountered an issue with payment for {latestArchivedSubscription.basePackage.name.split("_")[1]} plan renewal. Your account has automatically downgraded to the Free plan. Please check your payment info and <Link to="/settings?tab=3">renew your subscription</Link> to access advanced features.</div>, "error", { autoClose: false, onClose: () => { setShowAlertOnPaymentFailedDowngrade() } })
            }
        }
    } catch (err) {
        console.log(err)
    }
}

async function showAlertOnCancellationDowngrade() {
    try {
        const alertPreferences = JSON.parse(window.localStorage.getItem("alertPreferences"))
        const data = await fetchArchivedSubscriptionsForOrg()
        if (!data) return;
        const { archivedSubscriptions } = data
        console.log(alertPreferences["show_alert_on_cancellation_downgrade"])
        if (archivedSubscriptions.length > 0 && alertPreferences["show_alert_on_cancellation_downgrade"]) {
            const latestArchivedSubscription = archivedSubscriptions.pop()
            if (latestArchivedSubscription && latestArchivedSubscription.downgradeReason === "cancellation") {

                triggerToast(<div>Your {latestArchivedSubscription.basePackage.name.split("_")[1]} plan expired on {getFormattedDateV2(latestArchivedSubscription?.endDate, "dd mm, yyyy")}. Your account has automatically downgraded to the Free plan. <Link to="/settings?tab=3">Renew your subscription</Link> today to access advanced features.</div>, "error", { autoClose: false, onClose: () => { setShowAlertOnCancellationDowngrade() } })
            }
        }
    } catch (err) {
        console.log(err)
    }
}
//End of session based alerts


//Start of global alerts
function showPAYGStartedAlert(subscriptionV2, organization, subscriptionInsights, updateOrganization) {
    if (organization.settings.notificationPreferences.subscriptionCycleBased.showPAYGStartAlert.isEnabled) {
        if (organization.isEnabledPAYG) {
            if (subscriptionInsights.usage.assignments.metered > 0) {
                triggerToast(<div>Your {"Assignments"} usage limit has been reached. Pay As You Go is now active for this billing cycle. See usage details on the <Link onClick={(e) => triggerUpdateSubscriptionPopUp()}>Manage Subscription</Link>  page.</div>, "success", { autoClose: false, onClose: () => { updateOrganization({ op: "update", orgId: organization._id, fields: { "settings.notificationPreferences.subscriptionCycleBased.showPAYGStartAlert.isEnabled": false } }) } })
            }
            if (subscriptionInsights.usage.sessions.metered > 0) {
                triggerToast(<div>Your {"Calendar"} usage limit has been reached. Pay As You Go is now active for this billing cycle. See usage details on the <Link onClick={(e) => triggerUpdateSubscriptionPopUp()}>Manage Subscription</Link> page.</div>, "success", { autoClose: false, onClose: () => { updateOrganization({ op: "update", orgId: organization._id, fields: { "settings.notificationPreferences.subscriptionCycleBased.showPAYGStartAlert.isEnabled": false } }) } })
            }
        }
    }

}

function showSubscriptionRenewedAlert(subscriptionV2, organization, subscriptionInsights, updateOrganization) {
    if (organization.settings.notificationPreferences.subscriptionCycleBased.showSubscriptionRenewalAlert.isEnabled) {
        triggerToast(`Your subscription was successfully renewed on ${getFormattedDateV2(subscriptionV2.subscription.startDate, "dd mm, yyyy")}.`, "success", { autoClose: false, onClose: () => { updateOrganization({ op: "update", orgId: organization._id, fields: { "settings.notificationPreferences.subscriptionCycleBased.showSubscriptionRenewalAlert.isEnabled": false } }) } })
    }
}
//End of global alerts



export {
    setAlertPreferencesOnLogin,
    setShowAlertOnPAYG90Usage,
    setShowAlertOn50Usage,
    setShowAlertOnD_PAYG100Usage,
    setAnnualShowAlertOn100Usage,
    setAnnualShowAlertOn90Usage,
    showAlertOnPaymentFailedDowngrade,
    showAlertOnCancellationDowngrade,

    showAlertOn50Usage,
    showAlertOnPAYG90Usage,
    showAlertOnD_PAYG100Usage,
    showAnnualAlertOn100Usage,
    showAnnualAlertOn90Usage,


    showSubscriptionRenewedAlert,
    showPAYGStartedAlert
}