function getCorrespondingBasePackage (productPrices, oldBillingCycle,newBillingCycle, currentPackagePriceId){
    const oldBasePackages = productPrices.find((val)=>val.product.name.includes("BasePackageV2")).prices[oldBillingCycle]
    const oldBasePackage  = oldBasePackages.find((val)=>val.id === currentPackagePriceId)
    const oldPackageLookupKey = oldBasePackage.lookup_key
    const oldBasePackageName = oldPackageLookupKey.split("_")[1]
    const requiredPeriodBasePackages  = productPrices.find((val)=>val.product.name.includes("BasePackageV2")).prices[newBillingCycle]
    const requiredBasePackage = requiredPeriodBasePackages.find((val)=>val.lookup_key.includes(oldBasePackageName))
    return requiredBasePackage 
}

export default getCorrespondingBasePackage