import React from 'react'

const ProfileIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="#515C7B" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29254_13161)">
                <g clip-path="url(#clip1_29254_13161)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3 9.2C9.9772 9.2 11.5025 9.6858 12.6246 10.3704C13.1846 10.712 13.6634 11.1152 14.0092 11.5534C14.3494 11.9839 14.6 12.4991 14.6 13.05C14.6 13.6415 14.3123 14.1077 13.8979 14.4402C13.5059 14.7552 12.9886 14.9638 12.4391 15.1094C11.3345 15.4013 9.8603 15.5 8.3 15.5C6.7397 15.5 5.2655 15.402 4.1609 15.1094C3.6114 14.9638 3.0941 14.7552 2.7021 14.4402C2.287 14.107 2 13.6415 2 13.05C2 12.4991 2.2506 11.9839 2.5908 11.5527C2.9366 11.1152 3.4147 10.7127 3.9754 10.3697C5.0975 9.6865 6.6235 9.2 8.3 9.2ZM8.3 10.6C6.8839 10.6 5.6099 11.013 4.7048 11.5653C4.2519 11.8411 3.9103 12.1407 3.6898 12.4207C3.463 12.7077 3.4 12.9247 3.4 13.05C3.4 13.1354 3.4259 13.2257 3.5785 13.3482C3.7528 13.4882 4.0559 13.6331 4.5193 13.7556C5.4419 14.0006 6.7677 14.1 8.3 14.1C9.833 14.1 11.1581 13.9999 12.08 13.7556C12.5441 13.6331 12.8472 13.4882 13.0215 13.3482C13.1741 13.2257 13.2 13.1354 13.2 13.05C13.2 12.924 13.137 12.7077 12.9109 12.4207C12.6897 12.1407 12.3481 11.8411 11.8952 11.5653C10.9901 11.0123 9.7161 10.6 8.3 10.6ZM8.3 1.5C9.22826 1.5 10.1185 1.86875 10.7749 2.52513C11.4313 3.1815 11.8 4.07174 11.8 5C11.8 5.92826 11.4313 6.8185 10.7749 7.47487C10.1185 8.13125 9.22826 8.5 8.3 8.5C7.37174 8.5 6.4815 8.13125 5.82513 7.47487C5.16875 6.8185 4.8 5.92826 4.8 5C4.8 4.07174 5.16875 3.1815 5.82513 2.52513C6.4815 1.86875 7.37174 1.5 8.3 1.5ZM8.3 2.9C8.02422 2.9 7.75115 2.95432 7.49637 3.05985C7.24158 3.16539 7.01008 3.32007 6.81508 3.51508C6.62007 3.71008 6.46539 3.94158 6.35985 4.19636C6.25432 4.45115 6.2 4.72422 6.2 5C6.2 5.27578 6.25432 5.54885 6.35985 5.80364C6.46539 6.05842 6.62007 6.28992 6.81508 6.48492C7.01008 6.67993 7.24158 6.83461 7.49637 6.94015C7.75115 7.04568 8.02422 7.1 8.3 7.1C8.85696 7.1 9.3911 6.87875 9.78493 6.48492C10.1788 6.0911 10.4 5.55695 10.4 5C10.4 4.44305 10.1788 3.9089 9.78493 3.51508C9.3911 3.12125 8.85696 2.9 8.3 2.9Z" fill="#515C7B" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3 9.2C9.9772 9.2 11.5025 9.6858 12.6246 10.3704C13.1846 10.712 13.6634 11.1152 14.0092 11.5534C14.3494 11.9839 14.6 12.4991 14.6 13.05C14.6 13.6415 14.3123 14.1077 13.8979 14.4402C13.5059 14.7552 12.9886 14.9638 12.4391 15.1094C11.3345 15.4013 9.8603 15.5 8.3 15.5C6.7397 15.5 5.2655 15.402 4.1609 15.1094C3.6114 14.9638 3.0941 14.7552 2.7021 14.4402C2.287 14.107 2 13.6415 2 13.05C2 12.4991 2.2506 11.9839 2.5908 11.5527C2.9366 11.1152 3.4147 10.7127 3.9754 10.3697C5.0975 9.6865 6.6235 9.2 8.3 9.2ZM8.3 10.6C6.8839 10.6 5.6099 11.013 4.7048 11.5653C4.2519 11.8411 3.9103 12.1407 3.6898 12.4207C3.463 12.7077 3.4 12.9247 3.4 13.05C3.4 13.1354 3.4259 13.2257 3.5785 13.3482C3.7528 13.4882 4.0559 13.6331 4.5193 13.7556C5.4419 14.0006 6.7677 14.1 8.3 14.1C9.833 14.1 11.1581 13.9999 12.08 13.7556C12.5441 13.6331 12.8472 13.4882 13.0215 13.3482C13.1741 13.2257 13.2 13.1354 13.2 13.05C13.2 12.924 13.137 12.7077 12.9109 12.4207C12.6897 12.1407 12.3481 11.8411 11.8952 11.5653C10.9901 11.0123 9.7161 10.6 8.3 10.6ZM8.3 1.5C9.22826 1.5 10.1185 1.86875 10.7749 2.52513C11.4313 3.1815 11.8 4.07174 11.8 5C11.8 5.92826 11.4313 6.8185 10.7749 7.47487C10.1185 8.13125 9.22826 8.5 8.3 8.5C7.37174 8.5 6.4815 8.13125 5.82513 7.47487C5.16875 6.8185 4.8 5.92826 4.8 5C4.8 4.07174 5.16875 3.1815 5.82513 2.52513C6.4815 1.86875 7.37174 1.5 8.3 1.5ZM8.3 2.9C8.02422 2.9 7.75115 2.95432 7.49637 3.05985C7.24158 3.16539 7.01008 3.32007 6.81508 3.51508C6.62007 3.71008 6.46539 3.94158 6.35985 4.19636C6.25432 4.45115 6.2 4.72422 6.2 5C6.2 5.27578 6.25432 5.54885 6.35985 5.80364C6.46539 6.05842 6.62007 6.28992 6.81508 6.48492C7.01008 6.67993 7.24158 6.83461 7.49637 6.94015C7.75115 7.04568 8.02422 7.1 8.3 7.1C8.85696 7.1 9.3911 6.87875 9.78493 6.48492C10.1788 6.0911 10.4 5.55695 10.4 5C10.4 4.44305 10.1788 3.9089 9.78493 3.51508C9.3911 3.12125 8.85696 2.9 8.3 2.9Z" fill="black" fill-opacity="0.2" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3 9.2C9.9772 9.2 11.5025 9.6858 12.6246 10.3704C13.1846 10.712 13.6634 11.1152 14.0092 11.5534C14.3494 11.9839 14.6 12.4991 14.6 13.05C14.6 13.6415 14.3123 14.1077 13.8979 14.4402C13.5059 14.7552 12.9886 14.9638 12.4391 15.1094C11.3345 15.4013 9.8603 15.5 8.3 15.5C6.7397 15.5 5.2655 15.402 4.1609 15.1094C3.6114 14.9638 3.0941 14.7552 2.7021 14.4402C2.287 14.107 2 13.6415 2 13.05C2 12.4991 2.2506 11.9839 2.5908 11.5527C2.9366 11.1152 3.4147 10.7127 3.9754 10.3697C5.0975 9.6865 6.6235 9.2 8.3 9.2ZM8.3 10.6C6.8839 10.6 5.6099 11.013 4.7048 11.5653C4.2519 11.8411 3.9103 12.1407 3.6898 12.4207C3.463 12.7077 3.4 12.9247 3.4 13.05C3.4 13.1354 3.4259 13.2257 3.5785 13.3482C3.7528 13.4882 4.0559 13.6331 4.5193 13.7556C5.4419 14.0006 6.7677 14.1 8.3 14.1C9.833 14.1 11.1581 13.9999 12.08 13.7556C12.5441 13.6331 12.8472 13.4882 13.0215 13.3482C13.1741 13.2257 13.2 13.1354 13.2 13.05C13.2 12.924 13.137 12.7077 12.9109 12.4207C12.6897 12.1407 12.3481 11.8411 11.8952 11.5653C10.9901 11.0123 9.7161 10.6 8.3 10.6ZM8.3 1.5C9.22826 1.5 10.1185 1.86875 10.7749 2.52513C11.4313 3.1815 11.8 4.07174 11.8 5C11.8 5.92826 11.4313 6.8185 10.7749 7.47487C10.1185 8.13125 9.22826 8.5 8.3 8.5C7.37174 8.5 6.4815 8.13125 5.82513 7.47487C5.16875 6.8185 4.8 5.92826 4.8 5C4.8 4.07174 5.16875 3.1815 5.82513 2.52513C6.4815 1.86875 7.37174 1.5 8.3 1.5ZM8.3 2.9C8.02422 2.9 7.75115 2.95432 7.49637 3.05985C7.24158 3.16539 7.01008 3.32007 6.81508 3.51508C6.62007 3.71008 6.46539 3.94158 6.35985 4.19636C6.25432 4.45115 6.2 4.72422 6.2 5C6.2 5.27578 6.25432 5.54885 6.35985 5.80364C6.46539 6.05842 6.62007 6.28992 6.81508 6.48492C7.01008 6.67993 7.24158 6.83461 7.49637 6.94015C7.75115 7.04568 8.02422 7.1 8.3 7.1C8.85696 7.1 9.3911 6.87875 9.78493 6.48492C10.1788 6.0911 10.4 5.55695 10.4 5C10.4 4.44305 10.1788 3.9089 9.78493 3.51508C9.3911 3.12125 8.85696 2.9 8.3 2.9Z" fill="black" fill-opacity="0.2" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.3 9.2C9.9772 9.2 11.5025 9.6858 12.6246 10.3704C13.1846 10.712 13.6634 11.1152 14.0092 11.5534C14.3494 11.9839 14.6 12.4991 14.6 13.05C14.6 13.6415 14.3123 14.1077 13.8979 14.4402C13.5059 14.7552 12.9886 14.9638 12.4391 15.1094C11.3345 15.4013 9.8603 15.5 8.3 15.5C6.7397 15.5 5.2655 15.402 4.1609 15.1094C3.6114 14.9638 3.0941 14.7552 2.7021 14.4402C2.287 14.107 2 13.6415 2 13.05C2 12.4991 2.2506 11.9839 2.5908 11.5527C2.9366 11.1152 3.4147 10.7127 3.9754 10.3697C5.0975 9.6865 6.6235 9.2 8.3 9.2ZM8.3 10.6C6.8839 10.6 5.6099 11.013 4.7048 11.5653C4.2519 11.8411 3.9103 12.1407 3.6898 12.4207C3.463 12.7077 3.4 12.9247 3.4 13.05C3.4 13.1354 3.4259 13.2257 3.5785 13.3482C3.7528 13.4882 4.0559 13.6331 4.5193 13.7556C5.4419 14.0006 6.7677 14.1 8.3 14.1C9.833 14.1 11.1581 13.9999 12.08 13.7556C12.5441 13.6331 12.8472 13.4882 13.0215 13.3482C13.1741 13.2257 13.2 13.1354 13.2 13.05C13.2 12.924 13.137 12.7077 12.9109 12.4207C12.6897 12.1407 12.3481 11.8411 11.8952 11.5653C10.9901 11.0123 9.7161 10.6 8.3 10.6ZM8.3 1.5C9.22826 1.5 10.1185 1.86875 10.7749 2.52513C11.4313 3.1815 11.8 4.07174 11.8 5C11.8 5.92826 11.4313 6.8185 10.7749 7.47487C10.1185 8.13125 9.22826 8.5 8.3 8.5C7.37174 8.5 6.4815 8.13125 5.82513 7.47487C5.16875 6.8185 4.8 5.92826 4.8 5C4.8 4.07174 5.16875 3.1815 5.82513 2.52513C6.4815 1.86875 7.37174 1.5 8.3 1.5ZM8.3 2.9C8.02422 2.9 7.75115 2.95432 7.49637 3.05985C7.24158 3.16539 7.01008 3.32007 6.81508 3.51508C6.62007 3.71008 6.46539 3.94158 6.35985 4.19636C6.25432 4.45115 6.2 4.72422 6.2 5C6.2 5.27578 6.25432 5.54885 6.35985 5.80364C6.46539 6.05842 6.62007 6.28992 6.81508 6.48492C7.01008 6.67993 7.24158 6.83461 7.49637 6.94015C7.75115 7.04568 8.02422 7.1 8.3 7.1C8.85696 7.1 9.3911 6.87875 9.78493 6.48492C10.1788 6.0911 10.4 5.55695 10.4 5C10.4 4.44305 10.1788 3.9089 9.78493 3.51508C9.3911 3.12125 8.85696 2.9 8.3 2.9Z" fill="black" fill-opacity="0.2" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_29254_13161">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
                <clipPath id="clip1_29254_13161">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default ProfileIcon