import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import loginStyles from "../Login/Login.module.css";
import { ToastContainer } from "react-toastify";
import styles from './Layout2.module.css'
import AlertContainer from "../../components/AlertContainer/AlertContainer";
import MobileNavDrawer from "../../components/MobileNavDrawer/MobileNavDrawer";
import AccountDropdownCard from "../../components/AccountDropdownCard/AccountDropdownCard";

// const Layout2 = ({ setNavData, children, setLoading }) => {
// 	const navRef = useRef(null);
// 	const footerRef = useRef(null);
// 	const layoutRef = useRef(null);
// 	const layoutParentRef = useRef(null);
// 	const [layoutParentHeight, setLayoutParentHeight] = useState(null);
// 	const [scaleState, setScaleState] = useState(1);
// 	const { isLoggedIn } = useSelector((state) => state.user);

// 	const adjustScale = () => {
// 		const screenWidth = document.body.clientWidth;
// 		const scale = screenWidth > 0 ? screenWidth / 1920 : 0;

// 		if (navRef.current && layoutRef.current && footerRef.current) {
// 			navRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
// 			layoutRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
// 			footerRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
// 			// const reverseScale = 1920>screenWidth? 0: 1920/screenWidth;
// 			// console.log({reverseScale});
// 			// layoutRef.current.style.marginTop = `-${72*(scale>1?0:(1-scale))}px`;
// 		}
// 	};

// 	const checkHeight = () => {
// 		const screenWidth = document.body.clientWidth;
// 		const windowHeight = window.innerHeight;
// 		const scale = screenWidth > 0 ? screenWidth / 1920 : 0;
// 		setScaleState(scale);
// 		if (layoutRef.current) {
// 			const layoutRealHeightAfterScale =
// 				layoutRef.current?.clientHeight * (scale > 1 ? 1 : scale);
// 			if (layoutRealHeightAfterScale + 72 + 67 < windowHeight) {
// 				const heightForFullScreenLayout = windowHeight - 67;
// 				layoutRef.current.style.marginTop = `-${
// 					85 * (scale > 1 ? 0 : 1 - scale)
// 				}px`;
// 				// console.warn({windowHeight,layoutRealHeightAfterScale,heightForFullScreenLayout});
// 				setLayoutParentHeight(heightForFullScreenLayout);
// 				// footerRef.current.style.position = 'absolute';
// 			} else {
// 				// footerRef.current.style.position = 'static';
// 				// layoutRef.current.style.marginTop = `-${130*(scale>1?0:(1-scale))}px`;
// 				layoutRef.current.style.marginTop = `-${
// 					145 * (scale > 1 ? 0 : 1 - scale)
// 				}px`;
// 				setLayoutParentHeight(layoutRealHeightAfterScale);
// 			}
// 		}
// 		// if (navRef.current && layoutParentRef.current) {
// 		//   const rect1 = navRef.current.getBoundingClientRect();
// 		//   const rect2 = layoutParentRef.current.getBoundingClientRect();
// 		//   const distance = rect2.top - rect1.height;
// 		//   console.warn({rect1,rect2,distance});
// 		//   if(distance>1){
// 		//     layoutParentRef.current.style.marginTop = `-${distance}px`
// 		//   }
// 		// }
// 	};
// 	useEffect(() => {
// 		adjustScale();
// 		const intervalId = setInterval(checkHeight, 100);
// 		window.addEventListener("resize", adjustScale);
// 		return () => {
// 			clearInterval(intervalId);
// 			window.removeEventListener("resize", adjustScale);
// 		};
// 	}, []);

// 	// useEffect(() => {
// 	//   if (footerRef.current) {
// 	//     footerRef.current.style.marginTop = 0;
// 	//   }
// 	// }, [location]);
// 	return (
// 		<>
// 			<div
// 				style={{ backgroundRepeat: "repeat" }}
// 				className={`relative h-full min-h-screen ${
// 					isLoggedIn === false ? loginStyles.bg : ""
// 				}`}
// 			>
// 				<Navbar
// 					myRef={navRef}
// 					setNavData={setNavData}
// 					setLoading={setLoading}
// 				/>
// 				<div
// 					ref={layoutParentRef}
// 					style={{
// 						height:
// 							layoutParentHeight !== null ? `${layoutParentHeight}px` : "",
// 					}}
// 					className="min-h-[calc(100vh-67px)]"
// 				>
// 					{/* <div className="flex-grow"> */}
// 					<div
// 						style={{ transformOrigin: "top left" }}
// 						ref={layoutRef}
// 						className="w-[1920px] design:ml-[calc(50vw-960px)] static"
// 					>
// 						{children}
// 						{/* </div> */}
// 					</div>
// 				</div>
// 				<Footer scaleState={scaleState} myRef={footerRef} />
// 			</div>
// 		</>
// 	);
// };

// export default Layout2;

// ... (existing imports)

const Layout2 = ({ setNavData, children, setLoading }) => {

	const navRef = useRef(null);
	const footerRef = useRef(null);
	const layoutRef = useRef(null);
	const layoutParentRef = useRef(null);
	const [layoutParentHeight, setLayoutParentHeight] = useState(null);
	const [scaleState, setScaleState] = useState(1);
	const { isLoggedIn, role } = useSelector((state) => state.user);
	const { subscriptionV2, oldSubscriptionExists } = useSelector((state) => state.subscription)
	const { isSignupProcessComplete } = useSelector((state) => state.subscriptionOperation)

	const [mobileNavTrigger, setMobileNavTrigger] = useState(false)
	const [showMobileProfile, setShowMobileProfile] = useState(true)


	const adjustScale = () => {
		const screenWidth = document.body.clientWidth;
		const scale = screenWidth > 0 ? screenWidth / 1920 : 0;

		if (navRef.current && layoutRef.current && footerRef.current) {
			navRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
			layoutRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
			footerRef.current.style.transform = `scale(${scale > 1 ? 1 : scale})`;
			document.documentElement.style.setProperty('--layout-scale-factor', scale > 1 ? 1 : scale)
			document.documentElement.style.setProperty('--layout-margin-top', navRef.current.clientHeight * (scale > 1 ? 0 : 1 - scale))
			navRef.current.style.padding = "0px 95px"
			// Adjust marginTop for the layout after the navbar
			layoutRef.current.style.marginTop = `-${navRef.current.clientHeight * (scale > 1 ? 0 : 1 - scale)}px`;
		}
	};

	const checkHeight = () => {
		const screenWidth = document.body.clientWidth;
		const windowHeight = window.innerHeight;
		const scale = screenWidth > 0 ? screenWidth / 1920 : 0;
		setScaleState(scale);

		if (layoutRef.current) {
			const layoutRealHeightAfterScale = layoutRef.current.clientHeight * (scale > 1 ? 1 : scale);

			if (layoutRealHeightAfterScale + navRef.current.clientHeight < windowHeight) {
				const heightForFullScreenLayout = windowHeight - navRef.current.clientHeight
				layoutRef.current.style.marginTop = `-${navRef.current.clientHeight * (scale > 1 ? 0 : 1 - scale)}px`;
				setLayoutParentHeight(heightForFullScreenLayout);
			} else {
				layoutRef.current.style.marginTop = `-${navRef.current.clientHeight * (scale > 1 ? 0 : 1 - scale)}px`;
				setLayoutParentHeight(layoutRealHeightAfterScale);
			}
		}
	};

	useEffect(() => {
		adjustScale();
		const intervalId = setInterval(checkHeight, 100);
		window.addEventListener("resize", adjustScale);

		return () => {
			clearInterval(intervalId);
			window.removeEventListener("resize", adjustScale);
		};
	}, []);

	useEffect(() => {
		if(window.location.pathname.startsWith("/settings"))
			setShowMobileProfile(false);
		adjustScale();
	}, [isLoggedIn, subscriptionV2, oldSubscriptionExists, window.location.pathname, isSignupProcessComplete])

	useEffect(() => {
		console.log(window.location.pathname)
	}, [window.location.pathname])


	useEffect(()=>{
		console.log("CHANGED MNT")
	},[mobileNavTrigger])

	if (role !== "superAdmin" && role !== "manager") {
		if (!isLoggedIn || ((isLoggedIn && (!subscriptionV2 && !oldSubscriptionExists && !isSignupProcessComplete)))) {
			console.log(">>>HERE")
			return (
				<>{
					children
				}
				</>
			)
		}
	}


	if(window.location.pathname.startsWith("/signup/user")){
		return(
			<>{children}</>
		)
	}


	if(window.location.pathname.startsWith("/calendar/bookings")){
		return (<>{children}</>)
	}


	if ((window.location.pathname.startsWith("/calendar")) || (window.location.pathname.startsWith("/settings") && role === "admin")) {
		if ((window.location.pathname.startsWith("/calendar")) || (window.location.pathname.startsWith("/settings") && (role === "admin"))) {
			return (
				<div className={styles.stageContainer}>
					<div className={styles.navbarContainer} >
						<Navbar setNavData={setNavData} setLoading={setLoading} fromStage={true} setMobileNavTrigger={setMobileNavTrigger} mobileNavTrigger={mobileNavTrigger} {...{showMobileProfile, setShowMobileProfile}}/>
					</div>
					<div className={styles.contentContainer}>
						<AlertContainer fromStage={true} />
						{children}
						<MobileNavDrawer trigger={mobileNavTrigger} setTrigger={setMobileNavTrigger} />
						<AccountDropdownCard outsideclassName={"md:hidden"} showMobileProfile={showMobileProfile} setShowMobileProfile={setShowMobileProfile}/>
					</div>
				</div>

			)
		}
	}




		return (
			<>
				<div
					style={{ backgroundRepeat: "repeat" }}
					className={`relative h-full min-h-screen ${isLoggedIn === false ? loginStyles.bg : ""
						}`}
					id="layout22"

				>
					<Navbar myRef={navRef} setNavData={setNavData} setLoading={setLoading} {...{showMobileProfile, setShowMobileProfile}}/>
					<div
						ref={layoutParentRef}
						style={{
							height: layoutParentHeight !== null ? `${layoutParentHeight}px` : "",
						}}
						className="min-h-[100vh]"
					>
						<div
							style={{ transformOrigin: "top left" }}
							ref={layoutRef}
							className="w-[1920px] design:ml-[calc(50vw-960px)] static bg-white"
							id='bodyy'
						>

							<AlertContainer />
							{children}
						</div>
					</div>
					<Footer scaleState={scaleState} myRef={footerRef} />
				</div>
			</>
		);
	};

	export default Layout2
