import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import CalendarDateTimeSelector from "../CalendarDateTimeSelector/CalendarDateTimeSelector";
import MobileCalendarDateTime from "../MobileViewCalendarDateSelector/MobileCalendarDateTime";

const MainCalendarDateSelection = (props) => {
  const theme = useTheme();

  useEffect(()=>{
    props.setActive && props.setActive(true);
  },[])
  
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      {!isMobile ? <CalendarDateTimeSelector {...props} /> : <MobileCalendarDateTime {...props} />}
    </div>
  );
};

export default MainCalendarDateSelection;
