import { Link } from 'react-router-dom'
import styles from './TutorLicensePopUp.module.css'
import ButtonV2 from '../../ButtonV2/ButtonV2'
import HorizontalDivider from '../../HorizontalDivider/HorizontalDivider'
import { useEffect, useState } from 'react'
import { triggerToast } from '../../../utils/toastController'

const tutorLicensesMapping = {
    "BasicPackage_Free" : 1,
    "BasicPackage_Starter" : 1,
    "BasicPackage_Professional" : 10,
    "BasicPackage_Premium" : 30
}

const TutorLicensePopUp = (props) => {

    const {productPrices,selectedBillingCycle,subscription,siMapping,setSiMapping,onChange,setPopUpTriger} = props

    const [pricePerTutor, setPricePerTutor] = useState()
    const [period, setPeriod] = useState()
    const [oldPrice, setOldPrice] = useState()
    const [oldPriceHelper, setOldPriceHelper] = useState()
    const [newPrice, setNewPrice] = useState()
    const [newPriceHelper, setNewPriceHelper] = useState()
    const [extraTutors, setExtraTutors] = useState()
    const [baseTutors, setBaseTutors] = useState(0)
    const [tempSiMaping, setTempSiMapping] = useState(siMapping)

    useEffect(()=>{
        const siMap = tempSiMaping.find((val)=>val.lookupKey.includes("Tutor"))
        if(siMap.newPriceid || siMap.quantity !== subscription.subscription.tutors.addOns){
            console.log("----",tempSiMaping)
            const quantity = siMap.quantity
            setExtraTutors(quantity)
        } else {
            setExtraTutors(subscription.subscription.tutors.addOns)
        }
    },[])

    //Setup base package limits
    useEffect(()=>{
        const type = selectedBillingCycle === 0 ? "monthly" : "annual"
        console.log("TYPE",type)

        const basePackageSI = tempSiMaping.find((val)=>val.lookupKey.includes("BasicPackage"))
        const requiredBasePackageId = basePackageSI.newPriceId ? basePackageSI.newPriceId : basePackageSI.priceId

        const basePackageProducts = productPrices.find((product)=>product.product.name.includes("BasePackage")).prices[type]

        const requiredBasePackagePrice = basePackageProducts.find((val)=>val.id === requiredBasePackageId)
        console.log(requiredBasePackagePrice)
        console.log(basePackageProducts)

        const includedTutors = tutorLicensesMapping[Object.keys(tutorLicensesMapping).find((key)=>requiredBasePackagePrice.lookup_key.includes(key))]

        setBaseTutors(includedTutors)
    },[])

    //Same tutor packages limits
    useEffect(()=>{
        const type = selectedBillingCycle === 0 ? "monthly" : "annual"
        const prevType = selectedBillingCycle !== 0 ? "monthly" : "annual"

        const siMap = tempSiMaping.find((val)=>val.lookupKey.includes("Tutor"))
        console.log("----",tempSiMaping)
        const quantity = siMap.quantity

        const newTutorPriceId = tempSiMaping.find((val)=>val.lookupKey.includes("Tutor")).newPriceId
        const oldTutorPriceId = tempSiMaping.find((val)=>val.lookupKey.includes("Tutor")).priceId

        const tutorProducts = productPrices.find((product)=>product.product.name.includes("Tutor")).prices[type]
        const prevTutorProduct = productPrices.find((product)=>product.product.name.includes("Tutor")).prices[prevType]

        const newTutorPrice = tutorProducts.find((val)=>val.id === newTutorPriceId)
        let oldTutorPrice = tutorProducts.find((val)=>val.id === oldTutorPriceId)

        if(!oldTutorPrice){
            oldTutorPrice = prevTutorProduct.find((val)=>val.id === oldTutorPriceId)
        }

        console.log("::>>",siMap.quantity)

        setOldPrice(subscription.subscription.tutors.addOns * oldTutorPrice.unit_amount/100)
        
        setPricePerTutor(oldTutorPrice.unit_amount/100)
        setOldPriceHelper(`to ${subscription.subscription.tutors.addOns} Tutor Licenses`)

        if(newTutorPrice){
            setNewPrice(extraTutors * newTutorPrice.unit_amount/100)
            setPricePerTutor(newTutorPrice.unit_amount/100)
            setNewPriceHelper(`to ${extraTutors} Tutor Licenses`)
        } else {
            if(quantity !== extraTutors){
                setNewPrice(extraTutors * oldTutorPrice.unit_amount/100)
                setNewPriceHelper(`to ${extraTutors} Tutor Licenses`)
            }
        }
        setPeriod(type === "monthly" ? "mo" : "year")

    },[extraTutors])

    useEffect(()=>{console.log("><><",extraTutors)},[extraTutors])

    const handleContinueClick = (e) => {
        const siMap = tempSiMaping.find((val)=>val.lookupKey.includes("Tutor"))
        const quantity = siMap.quantity
        if(quantity !== extraTutors) {
            setSiMapping(siMapping.map((val)=>{
                const temp = {...val}
                if(temp.priceId === siMap.priceId){
                    temp.quantity = parseInt(extraTutors)
                }
                return temp
            }))
            triggerToast("Tutor licences updated. Please confirm changes in the Summary below.","info",{autoClose : 3000})
        }
       setPopUpTriger(false)
    }

    const handleChange = (e) => {
        console.log("^^^",e.target.value)
        setExtraTutors(e.target.value)
    }

    return(
        <div className={styles.container}>
            <div className={styles.title}>Additional Tutor Licenses</div>
            <div className={styles.contentContainer}>

                <div className={styles.mainText}>Tutor licenses included in your plan</div>
                <div className={styles.helper}>{baseTutors}</div>

                <div className={styles.mainText}>Additional tutor licenses</div>
                <input type="number" className={`${styles.helper} rounded-[6px] border-[1px] border-[##BEC2CE] border-solid px-[12px] py-[8px] !w-[100px]`}  onChange={(e)=>{handleChange(e)}} defaultValue={extraTutors}/>

                <div className={styles.mainText}>Total no. of tutor licenses</div>
                <div className={styles.helper}>{parseInt(baseTutors) + parseInt(extraTutors)}</div>

                <div className={styles.mainText}>Charge(s) per additional license</div>
                <div className={styles.helper}>
                    <div>${pricePerTutor}/<span className={styles.period}>{period}</span></div>
                </div>


                <div className='col-span-2'><HorizontalDivider /></div>

                {
                    newPrice  || subscription.subscription.tutors.addOns !== extraTutors ? 
                    <>
                    <div className={styles.old}>
                        Previous Cost
                    </div>
                    <div className={`${styles.old} ${styles.right}`}>
                        <span>{oldPrice}</span>/<span>{period}</span>
                    </div>
                    <div className={`col-span-2 ${styles.oldHelper}`}>{oldPriceHelper}</div>
    
                    <div className={styles.new}>
                        New Cost
                    </div>
                    <div className={`${styles.new} ${styles.right}`}>
                            <span>{newPrice}</span>/<span>{period}</span>
                    </div>
                    <div className={styles.newHelper}>
                        {newPriceHelper}
                    </div></>
                    : null
                }

            </div>

            {/* <div className={styles.helper}>
                Note: If the no. of licenses exceeds the default for your plan, your plan will be updated with additional licenses, which will incur extra charges. To change your plan,<Link>&nbsp;click here.</Link> 
            </div> */}
            <div className={styles.actionArea}>
                <ButtonV2 className={styles.continueButton} onClick={(e)=>handleContinueClick(e)}>Continue</ButtonV2>
                <ButtonV2 className={styles.cancelButton} onClick={(e)=>setPopUpTriger(false)}>Cancel</ButtonV2>
            </div>
        </div>
    )
}

export default TutorLicensePopUp