import { Link } from "react-router-dom"

function Div({children,className,style, onClick}){

	return <div className={className} style={{...style, position : "relative"}} onClick={onClick}>
		{children}
	</div>

}

function SidebarItem({children,isLink, className, style, onClick,to}){
		
		const RenderedComp = isLink ? Link : Div
		
		return(
			<RenderedComp {...{className, style, onClick, ...(isLink) && {to}}}>
			{children}
			</RenderedComp>
		)
}

export default SidebarItem
