import React, { useState } from "react";
import styles from "./Month.module.css";

const generateDaysArray = () => {
  const daysArray = [];
  for (let i = 1; i <= 31; i++) {
    daysArray.push(i);
  }
  return daysArray;
};

const Month = ({selectedMonthlyRecurringDate, setSelectedMonthlyRecurringDate}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const daysArray = generateDaysArray();

  // Split days array into weeks
  const weeks = [];
  for (let i = 0; i < daysArray.length; i += 7) {
    weeks.push(daysArray.slice(i, i + 7));
  }
  const handleDateClick = (day) => {
    setSelectedMonthlyRecurringDate(day);
  };
  return (
    <div className={styles.miniCalendarMonth}>
      <div className={styles.frame}>
        {weeks.map((week, weekIndex) => (
          <div key={weekIndex} className={styles.div}>
            {week.map((day) => (
              <div
                key={day}
                className={`${styles.calendarSmallDate} ${
                  day === selectedMonthlyRecurringDate ? styles.selected : ""
                }`}
                onClick={() => handleDateClick(day)}
              >
                <div
                  className={styles.element}
                  style={{
                    color:
                      day === selectedMonthlyRecurringDate ? "white" : "rgba(37, 51, 90, 1)",
                  }}
                >
                  {day}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Month;
