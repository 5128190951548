import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import { fetchAndSetAllEventRequests } from "../../pages/CalendarV2/helpers/fetchers"
import { deleteEventRequest, fetchAllEventRequests, updateAttendeeStatus, updateEventRequest } from "../../utils/eventService"
import frozenEventTypes from "../../utils/eventTypes"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);



async function updateActionStatus(eventRequestId, action, setter=()=>{}){
	try {

		const res = await updateEventRequest(eventRequestId, {
			"targetUserDetails.actionStatus" : "frozen"
		})
		fetchAndSetAllEventRequests(fetchAllEventRequests,setter)
		setRequestPopupInfo({
			trigger : false,
			eventRequestId : ""
		})

	}catch(err){
		triggerToast("Error occured while updating the status.")
	}
}

const nonSessionEvents = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase() ]
function handleDetailsClick(eventData, props){

	if(eventData.isEventRequest){
		if(["admin","tutor"].includes(props.user?.role)){
			props.setIsActive(true)
			props.setOperationMode("create-requested")
			if(nonSessionEvents.includes(eventData.eventType.toLowerCase())){
				props.setOperationMode("edit")
			}
			props.setSelectedEventType(eventData.eventType === "officehours" ? frozenEventTypes.OFFCWORK.eventType : eventData.eventType )
			props.setRequiredEventData({
				date : eventData.date,
				time : eventData.time,
				timeZone : eventData.timeZone,
				studentIds : eventData.studentIds,
				attendeeDetails : eventData.attendeeDetails,
				attendees : eventData.attendees,
				tutorId : eventData.tutorId,
				clientNotes : { note : eventData.additionalInfo},
				internalNotes : { note : ""},
				isEditingRequest : true,
				eventRequestId : eventData.eventRequestId,
				eventId : eventData.eventId,
				eventRequestor : eventData.requestor,
			}
			)
		} else if(["student", "parent"].includes(props.user?.role)){
			props.setSelectedEventType(eventData.eventType)
			props.setIsActiveRequestEventPopUp(true)
			props.setRequiredEventRequestData({
				isEditing : true,
				eventRequestId : eventData.eventRequestId,
				eventRequest : eventData.eventRequest
			})
		}
	} else {
		props.setIsActive(true)
		props.setOperationMode("edit")
		props.setSelectedEventType(eventData.eventType)
		props.setRequiredEventData(eventData)
	}

}


function handleEditActionCTAClick(eventData, props){

	const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
	if(eventData.isEventRequest){
		if(["admin","tutor"].includes(props.user?.role)){
			if(nonSessionTypes.includes(eventData.eventType.toLowerCase())){
				console.log("EVDT", eventData)
				updateActionStatus(eventData.eventRequestId,"frozen", props.setAllEventRequests)		
				updateAttendeeStatus(eventData.eventId, props.user?.id, "yes")
				return
			}
			props.setIsActive(true)
			props.setOperationMode("create-requested")
			props.setSelectedEventType(eventData.eventType)
			if(eventData.eventType.toLowerCase() === frozenEventTypes.EXTMEETING.eventType.toLowerCase()){
				props.setTempEventType(eventData.eventType)
			}else {
				props.setTempEventType("")
			}
			props.setRequiredEventData({
				date : eventData.date,
				time : eventData.time,
				timeZone : eventData.timeZone,
				studentIds : eventData.studentIds,
				attendeeDetails : eventData.attendeeDetails,
				attendees : eventData.attendees,
				tutorId : eventData.tutorId,
				clientNotes : { note : eventData.additionalInfo},
				internalNotes : { note : ""},
				isEditingRequest : true,
				eventRequestId : eventData.eventRequestId
			}
		)
		} else if(["student", "parent"].includes(props.user?.role)){
			props.setSelectedEventType(eventData.eventType)
			props.setIsActiveRequestEventPopUp(true)
			props.setRequiredEventRequestData({
				isEditing : true,
				eventRequestId : eventData.eventRequestId,
				eventRequest : eventData.eventRequest
			})
		}
	} else {
		props.setIsActive(true)
		props.setOperationMode("edit")
		props.setSelectedEventType(eventData.eventType)
		props.setRequiredEventData(eventData)
	}

}

function handleDeclineClick(eventRequestId, setRequestPopupInfo){
	setRequestPopupInfo({trigger : true, eventRequestId : eventRequestId, purpose : "confirm-decline"})
}

function handleExternalUsersRequestClick(eventData,props,requestFor, setRequestInfoDetails ){
	setRequestInfoDetails({
		type : requestFor,
		trigger : true,
		eventId : eventData._id,
		eventType : eventData.eventType.toLowerCase(),
	})
}

async function handleDeleteRequestClick(eventData,props){
		try {
			
			const res = await deleteEventRequest(eventData.eventRequestId)
			fetchAndSetAllEventRequests(fetchAllEventRequests,props.setAllEventRequests)

		}catch(err){
			triggerToast("Error occured while deleting the request.")
		}
}


export {
	handleDetailsClick,
	handleEditActionCTAClick,
	handleDeclineClick,
	handleExternalUsersRequestClick,
	handleDeleteRequestClick
}
