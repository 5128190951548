import { Cancel, ExpandMore } from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from "react";
import CalendarSidebar from "../../components/CalendarSidebar/CalendarSidebar";
import CalRenderer from "../../components/CalendarV2/CalRenderer/CalRenderer";
import {
  elLinks,
  sections,
} from "../../components/CalendarV2/Sidebar/Config/sections";
import Section1 from "../../components/CalendarV2/Sidebar/Sections/Section1";
import Section2 from "../../components/CalendarV2/Sidebar/Sections/Section2";
import Section3 from "../../components/CalendarV2/Sidebar/Sections/Section3";
import FilterIcon from "../../components/FilterIcon/FilterIcon";
import NextArrowRoundIcon from "../../components/NextArrowRoundIcon/NextArrowRoundIcon";
import SearchIcon from "../../components/SearchIcon/SearchIcon";
import TimeIcon from "../../components/TimeIcon/TimeIcon";
import styles from "./CalendarV2.module.css";
import dayjs from "dayjs";
import EventOperator from "../../components/EventOperator/EventOperator";
import ScheduleMapper from "../../components/ScheduleMapper/ScheduleMapper";
import CalendarTopBar from "../../components/CalendarTopBar/CalendarTopBar";
import { fetchTimeOffData } from "../../utils/timeOffService";
import { useSelector } from "react-redux";
import getRequiredUserIdsBasedOnRole from "../../utils/commonServices";
import frozenEventTypes from "../../utils/eventTypes";
import {
  useLazyGetAllOrgUsersQuery,
  useLazyGetAllStudentGroupsForOrgQuery,
  useLazyGetAllUsersQuery,
} from "../../app/services/users";
import {
  fetchAllUsersList,
  fetchAndSetAllEventRequests,
  fetchAndSetAllEvents,
  fetchStudentGroups,
  fetchStudentList,
  fetchTutorList,
} from "./helpers/fetchers";
import FullCalendarV2 from "../../components/FullCalendarV2/FullCalendarV2";
import CalendarV2Home from "../../components/CalendarV2Home/CalendarV2Home";
import EventRequests from "../../components/EventRequests/EventRequests";
import LeavesVacation from "../../components/LeavesVacation/LeavesVacation";
import CalendarAnalytics from "../../components/CalendarAnalytics/CalendarAnalytics";
import { useNavigate } from "react-router-dom";
import Availability from "./Availability/Availability";
import CalendarFilterPopup from "../../components/CalendarFilterPopup/CalendarFilterPopup";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import CalendarSearchPage from "../../components/CalendarSearchPage/CalendarSearchPage";
import { filterUser } from "./helpers/utils";
import { getAllEvents } from "../../utils/eventService";
import { transformEvents } from "../../utils/eventUtils";
import { fetchAllEventRequests } from "../../utils/eventService";
import EventRequestPopup from "../../components/StudentRequestPopup/EventRequestPopup";
import PopupContainer from "../../components/PopupContainer/PopupContainer";
import { Tooltip } from "@mui/material";
import CautionIcon from "../../components/CautionIcon/CautionIcon";
const _ = require("lodash");



const filterOptions = [
  { id: "service", label: "Service", selected: false },
  { id: "students", label: "Students", selected: true }, // Default selected
  { id: "tutors", label: "Tutors", selected: false },
  { id: "parents", label: "Parents", selected: false },
  { id: "groups", label: "Groups", selected: false },
  { id: "sessionStatuses", label: "Session Statuses", selected: false },
  { id: "sessionNotes", label: "Session Notes", selected: false },
  { id: "hourlyRate", label: "Hourly Rate", selected: false },
  { id: "location", label: "Location", selected: false },
];



function CalendarV2(props) {

  const navigate = useNavigate();
  const [allEvents, setAllEvents] = useState([]);
  const [allEventRequests, setAllEventRequests] = useState([]);
  const filteredData = useSelector((state) => state.filters.filteredData);

  const [selectedTimezone, setSelectedTimezone] = useState("Asia/Kolkata");

  const userTypesAllowedToViewInvoiceDetails = ["admin"];
  const user = useSelector((state) => state.user);
  const {organization} = useSelector(state=>state.organization);
  const [studentList, setStudentList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [allUserList, setAllUsersList] = useState([]);
  const [selectedDateFromCalendar, setSelectedDateFromCalendar] =
    useState(dayjs());

  const [selectEventPopupId, setSelectEventPopupId] = useState(null);

  //Shared states for all components
  const [allowedEventTypes, setAllowedEventTypes] = useState(Object.keys(frozenEventTypes))
  const [operationMode, setOperationMode] = useState("create");
  const [selectedEventType, setSelectedEventType] = useState(
    frozenEventTypes[allowedEventTypes[0]].eventType
  );
  const [tempEventType, setTempEventType] = useState("")

  // State for schedule Mapper
  const [scheduleSubmitData, setScheduleSubmitData] = useState({
    startDate: new Date(),
    endDate: new Date(),
    startTime: "",
    endTime: "",
    timeZone: "",
    selectedMembers: [],
    selectedStudents: [],
  });


  useEffect(()=>{
	if(operationMode === "create"){
		setSelectedEventType(frozenEventTypes[allowedEventTypes[0]].eventType)
	}
  },[allowedEventTypes, operationMode])

  const [eventDataSelectedToBeEdited, setEventDataSelectedToBeEdited] =
    useState({});
  const [eventRequestDataSelectedToBeEdited, setEventRequestDataSelectedToBeEdited] = useState({})

  //Fetchers
  const [getAllUsers] = useLazyGetAllUsersQuery();
  const [getAllOrgUsers] = useLazyGetAllOrgUsersQuery();
  const [getAllStudentGroupsForOrg, getAllStudentGroupsForOrgResp] =
    useLazyGetAllStudentGroupsForOrgQuery();

  //State required for selected components
  const [selectedSubSectionItem, setSelectedSubSectionItem] = useState({
    elLink: elLinks.CAL,
  });
  const [date, setDate] = useState(
    dayjs(new Date().getTime())
  );
  const [showScheduleMapper, setShowScheduleMapper] = useState(false);

  // State required for event popup
  const [isActiveEventPopUp, setIsActiveEventPopUp] = useState(false);
  const [isActiveRequestEventPopUp, setIsActiveRequestEventPopUp] =
    useState(false);

  //State for calendar view
  const [calendarView, setCalendarView] = useState("week");

  //State for time off data
  const [timeOffData, setTimeOffData] = useState();

  //Calendar Ref
  const calRef = useRef(null);


  useEffect(()=>{
		if(organization?.settings){
			const calendarApi = calRef.current?.getApi()
			if(organization?.settings.calendarDefaultView?.toLowerCase().includes("week")){
				setCalendarView("week")
				calendarApi?.changeView("timeGridWeek")
			} else if(organization?.settings.calendarDefaultView?.toLowerCase().includes("month")){
				setCalendarView("month")
				calendarApi?.changeView("dayGridMonth")
			} else if(organization?.settings.calendarDefaultView?.toLowerCase().includes("year")){
				setCalendarView("year")
				calendarApi?.changeView("multiMonth")
			}
		}
  },[organization?.settings, calRef.current])


  //For filters
  const filterRef = useRef(null);
  const filterRefParent = useRef(null);
  const [showFilters, setShowFilters] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [showSearchUserDD, setShowSearchUserDD] = useState(false);
  const [selectedUserFromSearch, setSelectedUserFromSearch] = useState({});
	const [appliedFilters, setAppliedFilters] = useState({
		service: {},
		students: {},
		tutors: {},
		parents: {},
		groups: {},
		sessionStatuses: {},
		sessionNotes: {},
		hourlyRate: {},
		location: {},
	});
	const [filters, setFilters] = useState(filterOptions);
	const [selectedFilter, setSelectedFilter] = useState("students");
	const [selectedItems, setSelectedItems] = useState({
		service: {},
		students: {},
		tutors: {},
		parents: {},
		groups: {},
		sessionStatuses: {},
		sessionNotes: {},
		hourlyRate: {},
		location: {},
	});
	const [settings, setSettings] = useState({
		service: [],
		students: [],
		tutors: [],
		parents: [],
		groups: [],
		sessionStatuses: [],
		sessionNotes: [],
		hourlyRate: [],
		location: [],
	});


  const [membersData, setMembersData] = useState({
    available: [],
    unavailable: [],
  });
  const [studentsData, setStudentsData] = useState({
    available: [],
    unavailable: [],
  });
  const [isScheduleTrigger, setIsScheduleTrigger] = useState(false);

  //To hide stuff
  const hideSectionItems = useMemo(() => {
    let subPathsToNotHide = ["", elLinks.CAL, elLinks.SRCH];
    const fullPathsToNotHide = subPathsToNotHide.map(
      (val, idx) => "/calendar" + (val !== "" ? "/" + val : "")
    );
    if (
      subPathsToNotHide.includes(selectedSubSectionItem.elLink) ||
      !selectedSubSectionItem.elLink ||
      fullPathsToNotHide.includes(window.location.pathname)
    ) {
      if (window.location.pathname === "/calendar") {
        setSelectedSubSectionItem({
          elLink: elLinks.CAL,
        });
      }
      return false;
    }
    return true;
  }, [selectedSubSectionItem?.elLink, window.location.pathname, user?.role]);

  //For paths to be rendered via routes
  const pathRenderingMapping = {
    [elLinks.CAL]: CalendarV2Home,
    [elLinks.EVREQ]: EventRequests,
    [elLinks.LVVAC]: LeavesVacation,
    [elLinks.CALTICS]: CalendarAnalytics,
    [elLinks.AVLB]: Availability,
    [elLinks.SRCH]: CalendarSearchPage,
  };

  //All data fetching
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      const transformedFilteredData = transformEvents(filteredData);
      setAllEvents(transformedFilteredData);
      return;
    }
    if (user && user.role) {
      fetchTimeOffData(
        getRequiredUserIdsBasedOnRole(user),
        setTimeOffData,
        user
      );
      fetchStudentList(getAllUsers, setStudentList, user);
      fetchTutorList(getAllUsers, setTutorList, user);
      fetchStudentGroups(getAllStudentGroupsForOrg, setGroupList, user);
      fetchAllUsersList(["admin"].includes(user?.role) ? getAllOrgUsers : getAllUsers, setAllUsersList, user);
      fetchAndSetAllEvents(getAllEvents, setAllEvents, user);
	  fetchAndSetAllEventRequests(fetchAllEventRequests, setAllEventRequests)
    }
  }, [user, filteredData]);

  console.log(eventDataSelectedToBeEdited, allEvents, selectedEventType);

  useEffect(() => {
    const subRoutes = window.location.pathname.split("/");
    if (subRoutes.length > 2) {
      const requiredSubRoute = subRoutes[2];
      if (Object.keys(pathRenderingMapping).includes(requiredSubRoute)) {
        console.log("WLP", subRoutes);
        setSelectedSubSectionItem({
          elLink: requiredSubRoute,
        });
      }
    }
  }, [window.location.pathname]);


  useEffect(()=>{

    if(selectEventPopupId && selectEventPopupId.trim() != ""){
        const findEvent = allEvents?.find((event)=> event.extendedProps._id  ===selectEventPopupId);
        console.log(findEvent?.extendedProps)
        setEventDataSelectedToBeEdited(findEvent?.extendedProps);
        setOperationMode("edit");
        setIsActiveEventPopUp(true);
        setSelectedEventType(findEvent?.extendedProps?.eventType)
    }
  }, [selectEventPopupId])


  useEffect(()=>{
	 if(isActiveRequestEventPopUp === false){
		setEventRequestDataSelectedToBeEdited({})
		setTempEventType("")
	 }
  },[isActiveRequestEventPopUp])

  //Props for elements
  const clickHandlers = {
    section1: () => {},
    section2: (idx, item) => {
      if (item.displayName === "Schedule Mapper") setShowScheduleMapper(true);
      else setSelectedSubSectionItem(item);
    },
    section3: (idx, item) => {
      setSelectedSubSectionItem(item);
    },
  };

  // console.log(selectedSubSectionItem);

  const section1ComponentProps = [
    {
      main: {
        value: date,
        onChange: (newValue) => {
          calRef.current.getApi().gotoDate(newValue.toDate());
          setDate(newValue);
        },
        setValue: setDate,
      },
    },
    {
      main: {
        placeholder: "Search",
        isTextField: true,
        textfieldValue: searchUser,
        onChange: (e) => {
          setSearchUser(e.target.value);
        },
        dropdownTrigger: showSearchUserDD,
        setDropdownTrigger: setShowSearchUserDD,
        auxTitle: "",
        buttonIcons: {
          openIcon: <></>,
          closeIcon: <NextArrowRoundIcon />,
        },
        FieldIcon: (props) => <SearchIcon {...props} />,
        setActiveLinkExternally: true,
        onClick: () => {
          console.log("Clicked");
          navigate(elLinks.SRCH);
          setSelectedSubSectionItem({
            elLink: elLinks.SRCH,
          });
        },
        containerStyles: { padding: "10px 8px" },
        isVisible: !hideSectionItems,
        onKeyDown: (e) => {
          if (e.key === "Enter") {
            navigate("search");
          }
        },
      },
      child: {
        dropdownTrigger: showSearchUserDD,
        setDropdownTrigger: setShowSearchUserDD,
        allUserList: allUserList.filter((val, idx) =>
          filterUser(val, idx, searchUser)
        ),
        isItemLink: true,
        itemLink: elLinks.SRCH,
        onClick: (e, item) => {
          setSelectedUserFromSearch(item);
        },
      },
    },
    {
      main: {
        title: "Filters",
        auxTitle: "applied",
        buttonIcons: {
          openIcon: <ExpandMore style={{ height: "16px", width: "16px" }} />,
          closeIcon: <Cancel style={{ height: "16px", width: "16px" }} />,
        },
        FieldIcon: (props) => <FilterIcon {...props} />,
        externalTrigger: showFilters,
        onClick: (e) => setShowFilters((prev) => !prev),
        ref: filterRefParent,
        isVisible: !hideSectionItems,
		selectedFilters : Object.keys(selectedItems).filter(key=>Object.keys(selectedItems[key]).length > 0),
		isActive : Object.keys(selectedItems).filter(key=>Object.keys(selectedItems[key]).length > 0).length > 0,
		setIsActive : setShowFilters,
		setActiveLinkExternally : true
      },
      child: {
		appliedFilters, setAppliedFilters,
		filters, setFilters,selectedFilter, setSelectedFilter, selectedItems, setSelectedItems,settings, setSettings,
        showFilters,
        setShowFilters,
        ref: filterRef,
        externalContainerStyles: {
          left: "calc(100% + 10px)",
          top: "calc(-100% - 100px)",
        },
      },
    },
  ];

  useOutsideAlerter(
    filterRef,
    () => {
      setShowFilters(false);
    },
    filterRefParent
  );

  const section2ComponentProps = [
    {
      main: {
        title: "Time Blocks",
        auxTitle: "users",
        buttonIcons: {
          openIcon: <ExpandMore style={{ height: "16px", width: "16px" }} />,
          closeIcon: <Cancel style={{ height: "16px", width: "16px" }} />,
        },
        FieldIcon: (props) => (
              <TimeIcon {...props} />
        ),
        isVisible: !hideSectionItems,
        hideForRole: user?.role !== "admin",
      },
      child: {
        
        // Additional child props here
      },
    },
    {},
    {
      main: {
        hideForRole: user?.role !== "admin",
        hideCompletely: user?.role !== "admin",
      },
    },
  ];

  const section3ComponentProps = [
    {
      main: {
        activeEventRequests: allEventRequests?.length,
      },
    },
  ];

  const calendarSideBarProps = {
    sections: [
      <Section1
        config={sections}
        activeLink={selectedSubSectionItem?.elLink}
        onElementClick={clickHandlers.section1}
        componentProps={section1ComponentProps}
      />,
      <Section2
        config={sections}
        activeLink={selectedSubSectionItem?.elLink}
        onElementClick={clickHandlers.section2}
        componentProps={section2ComponentProps}
      />,
      <Section3
        config={sections}
        activeLink={selectedSubSectionItem?.elLink}
        onElementClick={clickHandlers.section3}
        componentProps={section3ComponentProps}
      />,
    ],
  };

  function handleBackClick() {
    navigate("/calendar");
    setSelectedSubSectionItem({
      elLink: elLinks.CAL,
    });
  }

  function modifyDateOnClick(mode) {
    setDate(date.add(mode === "prev" ? -1 : 1, calendarView));
    if (calRef.current) {
      const calAPI = calRef.current.getApi();
      if (mode === "prev") calAPI.prev();
      else calAPI.next();
    }
  }

  console.log(selectedEventType)
  
  useEffect(()=>{
	if(isActiveEventPopUp === false){
      fetchAndSetAllEvents(getAllEvents, setAllEvents, user);
	}
  },[isActiveEventPopUp])

  return (
    <>
      {showScheduleMapper && (
        <ScheduleMapper
          user = {user}
          setActive={setShowScheduleMapper}
          submitData={scheduleSubmitData}
          setSubmitData={setScheduleSubmitData}
          membersData={membersData}
          setMembersData={setMembersData}
          studentsData={studentsData}
          setStudentsData={setStudentsData}
          setIsScheduleTrigger={setIsScheduleTrigger}
          setIsActiveEventPopUp={setIsActiveEventPopUp}
		      setOperationMode={setOperationMode}
        />
      )}

      <div className={styles.container}>
        <div className={styles.sub1}>
          <CalendarSidebar
            {...calendarSideBarProps}
            hideSection2Divider={hideSectionItems}
            hideSection2DividerForRole={user?.role !== "admin"}
          />
        </div>
        <div className={styles.sub2}>
          <CalRenderer
            activeLink={selectedSubSectionItem?.elLink}
            elLinks={elLinks}
            setSelectedSubSectionItem={setSelectedSubSectionItem}
            leaveDetails={timeOffData}
            setLeaveDetails={setTimeOffData}
            {...{
              studentList,
              groupList,
              tutorList,
              allUserList,
              setIsActive: setIsActiveEventPopUp,
              isActive: isActiveEventPopUp,
              view: calendarView,
              setView: setCalendarView,
              date: date,
              setDate: setDate,
              setOperationMode: setOperationMode,
              operationMode: operationMode,
              pathRenderingMapping,
              setSelectEventPopupId,
              handleBackClick,
              calRef,
              modifyDateOnClick,
              user,
              searchUser,
              setSearchUser,
              allEvents,
              setAllEvents,
              selectedDate: selectedDateFromCalendar,
              setSelectedDate: setSelectedDateFromCalendar,
              selectedEventType,
              setSelectedEventType,
              setRequiredEventData: setEventDataSelectedToBeEdited,
              selectedTimezone,
              setSelectedTimezone,
              isActiveRequestEventPopUp,
              setIsActiveRequestEventPopUp,
              allEventRequests,
              setAllEventRequests,
			  setRequiredEventRequestData : setEventRequestDataSelectedToBeEdited,
			  organization,
			  tempEventType,
			  setTempEventType,
			  appliedFilters, setAppliedFilters,
			  filters, setFilters,selectedFilter, setSelectedFilter, selectedItems, setSelectedItems,settings, setSettings,
            }}
          />

          {isActiveRequestEventPopUp ? (
            <PopupContainer
              trigger={isActiveRequestEventPopUp}
              setTrigger={setIsActiveRequestEventPopUp}
              customCloseButton={true}
            >
              <EventRequestPopup
                {...{
                  setTrigger: setIsActiveRequestEventPopUp,
                  user: user,
				  organization,
                  userTypesAllowedToViewInvoiceDetails:
                    userTypesAllowedToViewInvoiceDetails,
                  studentList,
                  tutorList,
                  groupList,
                  allUserList,
                  isScheduleTrigger,
                  setIsScheduleTrigger,
                  scheduleSubmitData,
                  setScheduleSubmitData,
                  setMembersData,
                  setStudentsData,
                  selectedEventType,
                  setSelectedEventType,
                  eventType: selectedEventType,
                  operationMode,
                  setOperationMode,
                  handleBackClick,
                  searchUser,
                  setSearchUser,
                  allEvents,
                  setAllEvents,
                  selectedDateFromCalendar,
                  setSelectedDateFromCalendar,
                  ...(operationMode === "edit" && {
                    requiredEventType: selectedEventType,
                    requiredEventData: eventDataSelectedToBeEdited,
                  }),
                  allEventRequests,
                  setAllEventRequests,
				  ...(eventRequestDataSelectedToBeEdited && eventRequestDataSelectedToBeEdited.isEditing) && {
						eventRequest : eventRequestDataSelectedToBeEdited.eventRequest,
						eventRequestId : eventRequestDataSelectedToBeEdited.eventRequestId,
						isEditing : true,
						tempEventType,
						setTempEventType
					}
                }}
              />
            </PopupContainer>
          ) : (
            <></>
          )}

          {isActiveEventPopUp ? (
            <EventOperator
              {...{
                setTrigger: setIsActiveEventPopUp,
                user: user,
				        organization,
                userTypesAllowedToViewInvoiceDetails:
                  userTypesAllowedToViewInvoiceDetails,
                studentList,
                tutorList,
                groupList,
                allUserList,
                isScheduleTrigger,
                setIsScheduleTrigger,
                scheduleSubmitData,
                setScheduleSubmitData,
                setMembersData,
                setStudentsData,
                selectedEventType,
                setSelectedEventType,
                eventType: selectedEventType,
                operationMode,
                setOperationMode,
                setEventDataSelectedToBeEdited,
                handleBackClick,
                searchUser,
                setSearchUser,
                allEvents,
                setAllEvents,
                selectedDateFromCalendar,
                setSelectedDateFromCalendar,
                ...((operationMode === "edit" || operationMode === "create-requested") && {
                  requiredEventType: selectedEventType,
                  requiredEventData: eventDataSelectedToBeEdited,
                }),
                allEventRequests,
                setAllEventRequests,
                allowedEventTypes,
                setAllowedEventTypes,
				tempEventType,
				setTempEventType,
			    appliedFilters, setAppliedFilters,
			    filters, setFilters,selectedFilter, setSelectedFilter, selectedItems, setSelectedItems,settings, setSettings,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default CalendarV2;
