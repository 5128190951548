import React, { useEffect } from "react";
import styles from "./StudentList.module.css";
import { Avatar } from "@mui/material";
import "../../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import CancelFilled from "../../../assets/calendarV2/CancelFilled";
import FilledNoShow from "../../../assets/calendarV2/FilledNoshow";
import NoFillNoShow from "../../../assets/calendarV2/NoFillNoShow";
import CancelCall from "../../../assets/calendarV2/CancelCall";

const studentsData = [
  {
    name: "Alex Stannis",
    email: "alexsample@gmail.com",
    color: "#8B4513",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },

  {
    name: "Stanford Bennett",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Stan Anderson",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },
  {
    name: "Maria Stanhope",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/noshow.svg",
    missedcall: "/images/missed_call.svg",
    action: "Cancel",
  },

  {
    name: "John Stanbury",
    email: "alexsample@gmail.com",
    color: "#8B4513",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/skipped.svg",
    action: "Do not invite",
  },
  {
    name: "Jessica Stanmore",
    email: "alexsample@gmail.com",
    color: "#8B4513",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Mark Stancliff",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
  {
    name: "Kristen Stanton",
    email: "alexsample@gmail.com",
    color: "#006400",
    cancel: "/images/cancel_call.svg",
    missedcall: "/images/missed_call.svg",
    action: "No show",
  },
];

const StudentList = ({ selectedStudents, studentData , studentStatusMapping, setStudentStatusMapping}) => {


  function handleStatusChangeClick(studentId, targetStatus) {
		setStudentStatusMapping((prev)=>({
			...prev,
			[studentId] : prev[studentId] === targetStatus ? "none" : targetStatus
	 	}))	
  }

  console.log("SSM", studentStatusMapping)

  return (
    <div className={styles.viewAllStudents}>
      <div className={styles.searchDropdown}>
        <div className={styles.textWrapper}>
          Students ({selectedStudents.length})
        </div>
        <div className={styles.line}></div>
        <div className={styles.frame}>
          {selectedStudents.map((student, index) => (
            <div key={index} className={styles.studentContainer}>
              <div className={styles.person}>
                <Avatar
                  alt={student.name.toUpperCase()}
                  src={student.awsLink+student.photo}
                  sx={{
                    width: 24,
                    height: 24,
                    bgcolor: student.color,
                    fontSize: "0.6rem",
                  }}
                  className={styles.profileImage}
                />{" "}
                <div className={styles.studentDetails}>
                  <div className={styles.studentName}>{student.name}</div>
                  <div className={styles.studentEmail}>{student.email}</div>
                </div>
              </div>
              <div
                // className={styles.studentsActionWrapper}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
				<button onClick={()=>handleStatusChangeClick(student._id, "cancelled")}>
									{ 
										studentStatusMapping[student._id] === "cancelled" ? 
											<CancelFilled />
										: 
											<CancelCall />
									}		
				</button>
				<button onClick={()=>handleStatusChangeClick(student._id, "no-show")}>
									{ 
										studentStatusMapping[student._id] === "no-show" ? 
											<FilledNoShow />
											: 
											<NoFillNoShow />
									}
				</button>
                <div className={styles.actionText}>{student.action}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default StudentList;
