//Refactor this
const frozenEventTypes = Object.freeze( {
	SESSION : {
		eventType :  "session",
		displayValue : "Session"
	},
	CONSULTATION : {
		eventType :  "consultation",
		displayValue : "Consultation"
	},
	INTMEETING : {
		eventType :  "internalMeeting",
		displayValue : "Internal Meeting"
	},
	OFFCWORK  : {
		eventType :  "officeWork",
		displayValue : "Office Work"
	},
	OFFCHOURS  : {
		eventType :  "officeHours",
		displayValue : "Office Work"
	},
	EXTMEETING : {
		eventType : "externalAppointment",
		displayValue : "Booked Meeting"
	}
})


export default frozenEventTypes
