import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import DateTimeSettings from "../../Preferences/Datetime/DateTimeSettings";
import MobileViewDateTimeSetting from "../../Preferences/Datetime/MobileViewDateTimeSetting";

const DateTime = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("isMobile", isMobile);
  return (
    <div>
      {!isMobile ? <DateTimeSettings /> : <MobileViewDateTimeSetting />}
    </div>
  );
};

export default DateTime;
