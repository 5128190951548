export function calculateDateRange(startD) {
  var currentDate = new Date(); // Current date
  var startDate = new Date(2023, 0, 1); // Start of the current month
  var dateRanges = [];
// console.log('startD----', startD);
  var rangeStart = new Date(startDate); // Start of the range
  var rangeEnd = new Date(currentDate); // End of the range
   let ndate={
    sDate:rangeStart,
    eDate:rangeEnd
   }
  dateRanges.push(getModifiedDate(ndate));
  return dateRanges.slice(0,100);
 
}

export const getModifiedDate = (date, dateFormat) => {
 
  let startDate = new Date(date.sDate).toDateString().split(' ')
  let endDate = new Date(date.eDate).toDateString().split(' ')
  if(startDate[3] === endDate[3]){
    const year = endDate[3]
    if (dateFormat === "dd/mm/yy") {
      startDate = startDate[2]+' '+startDate[1]
      endDate = endDate[1]+' '+endDate[2]
    }else if (dateFormat === "mm/dd/yy") {
      startDate = startDate[1]+' '+startDate[2]
      endDate = endDate[1]+' '+endDate[2]
    }else if(dateFormat === "yy/mm/dd"){
      startDate = startDate[1]+' '+startDate[2]
      endDate = endDate[1]+' '+endDate[2]
      return year + ", " +startDate + " - " + endDate
    }else{
      startDate = startDate[2]+' '+startDate[1]
      endDate = endDate[1]+' '+endDate[2]
    }

    return startDate + " - " + endDate + ", " + year
  }else{
    if (dateFormat === "dd/mm/yy") {
      startDate = startDate[2]+' '+startDate[1]+ ' '+startDate[3]
    endDate = endDate[2]+' '+endDate[1]+ ' '+endDate[3]
    }else if (dateFormat === "mm/dd/yy") {
      startDate = startDate[1]+' '+startDate[2]+ ' '+startDate[3]
    endDate = endDate[1]+' '+endDate[2]+ ' '+endDate[3]
  }else if(dateFormat === "yy/mm/dd"){
      startDate = startDate[3]+' '+startDate[1]+ ' '+startDate[2]
      endDate = endDate[3]+' '+endDate[1]+ ' '+endDate[2]
    }else{
      startDate = startDate[2]+' '+startDate[1]+ ' '+startDate[3]
      endDate = endDate[1]+' '+endDate[2]+ ' '+endDate[3]
    }
    return startDate + " - " + endDate;
  }

}

function formatDate(date) {
  var options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
}
