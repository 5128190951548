import { useEffect } from 'react'
import ApplyCoupon from '../ApplyCouponInput/ApplyCoupon'
import ButtonV2 from '../ButtonV2/ButtonV2'
import HorizontalDivider from '../HorizontalDivider/HorizontalDivider'
import AccountingSection from './AccountingSection/AccountingSection'
import styles from './SummaryCornerSub.module.css'
import SummarySection from './SummarySection/SummarySection'
import { useState } from 'react'
import { useLazyCouponValidityQuery } from '../../app/services/subscription'
import { toast } from 'react-toastify'
import axios from 'axios'
import { getAuthHeader } from '../../app/constants/constants'
import { fetchPaymentMethodsForCustomer } from '../../utils/subscriptionService'
import { useSelector } from 'react-redux'
import LoaderIcon from '../LoaderIcon/LoaderIcon'
import { triggerToast } from '../../utils/toastController'
import { useUpdateOrgDataMutation } from '../../app/services/admin'
import { fetchLookupKeyForId } from '../../pages/ManageSubscription/Utils/fetchers'

//Summary Object Format
/* ?? is optional text
 [
    {   
        type : ["add-ons","top-ups","tutors",""] //Optional
        sectionTitle : "",
        children : [
            {
                title : "",
                helperText : {
                    old : ?,
                    new : ,
                    oldStyle : ??,
                    newStyle : ??
                },
                priceText : {
                    main : ,
                    helper : ??,
                    mainFormat : ??
                    helperFormat : ??,
                    type : [upg,dwg]//upgrade & downgrade,
                    billingPeriod : ?? 
                },
                metadata : {
                    idForAction : //will be sent back in the event in case of onclick
                }
                onDeleteClick : callback with event argument
            }
        ]
    }
]
 */

const _summaryObject = [
    {
        sectionTitle: "Product Add-ons",
        type : "add-ons",
        children: [
            {
                title: "Calendar",
                helperText: {
                    old: "100 sessions",
                    new: "400 sessions",
                    billing : "New rates apply from next billing cycle"
                },
                priceText: {
                    old : "$2.00",
                    main: "$3.00",
                    billingPeriod: "/mo",
                    helper: "Upgrade",
                    type: "upg"
                },
                metadata: {
                    idForAction: "Some odd Id"
                },
                onDeleteClick: (e) => { console.log("Heelo", e.target.id) }
            },
            {
                title: "Assignment",
                helperText: {
                    old: "500 credits",
                    new: "100 credits",
                    billing : "New rates apply from next billing cycle"
                },
                priceText: {
                    old : "$2.00",
                    main: "$0.00",
                    billingPeriod: "/mo",
                    helper: "Downgrade",
                    type: "dwg"
                },
                metadata: {
                    idForAction: "Some odd Id"
                },
                onDeleteClick: (e) => { console.log("Heenlo", e.target.id) }
            }
        ]
    },
    {
        sectionTitle: "Top Ups",
        type : "top-ups",
        children: [
            {
                title: "Calendar : 2 x 200 sessions",
                helperText: {
                    new: "Lifetime validity"
                },
                priceText: {
                    main: "$7.50"
                },
                metadata: {
                    idForAction: "Some odd Id"
                },
                onDeleteClick: (e) => { console.log("Other", e.target.id) }
            },
            {
                title: "Assignments : 1 x 500 credits",
                helperText: {
                    new: "Lifetime validity"
                },
                priceText: {
                    main: "$18.50"
                },
                metadata: {
                    idForAction: "Some odd Id"
                },
                onDeleteClick: (e) => { console.log("Other", e.target.id) }
            }
        ]
    }
]

const _accountingItems = [
    {
        mainText: "Item Total",
        amountText: "$29.00"
    },
    {
        mainText: "Discount",
        amountText: "-$3.80",
        isDiscount: false
    },
    {
        mainText: "Amount Due (pro-rated)",
        amountText: "$25.20",
        isFinalPrice: true
    }
]
const SummaryCornerSub = (props) => {


    const { summaryObject, invoiceData,coupon, setCoupon, validCoupon, setValidCoupon, subscriptionCoupon, setSubscriptionCoupon,updateRequest,setUpdateButtonClicked } = props
    const [accountingItems,setAccountingItems] = useState([])
    const [checkCouponValidity, respCheckCouponValidity] = useLazyCouponValidityQuery();
    const [discountAmount, setDiscountAmount] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const {organization} = useSelector((state)=>state.organization)
    const [updateOrgRequest, ] = useUpdateOrgDataMutation()
    
    //Setup
    useEffect(()=>{
        console.log("IVD",invoiceData)
        if(invoiceData) {
            setAccountingItems([
                {
                    mainText: "Item Total",
                    amountText : "$ " + invoiceData.totalAmount
                },
                {
                    mainText : "Amount Due (pro-rated)",
                    amountText :  `\$ ${(invoiceData.amountDue/100).toFixed(2)}`,
                    isFinalPrice : true
                }
            ])
            if(validCoupon){
                setAccountingItems([
                    {
                        mainText: "Item Total",
                        amountText : "$ " + invoiceData.totalAmount
                    },
                    {   
                        showDiscount : validCoupon,
                        mainText : `Discount (${coupon})`,
                        amountText : `- $ ${((invoiceData.totalAmount) - parseFloat(invoiceData.amountDue/100).toFixed(2))}` ,
                        isDiscount : true
                    },
                    {
                        mainText : "Amount Due (pro-rated)",
                        amountText :  `\$ ${(invoiceData.amountDue/100).toFixed(2)}`,
                        isFinalPrice : true
                    }
                ])
            }
        }
    },[invoiceData,summaryObject])

    //Check coupon codes
    const applyCouponToSubscription = async () => {
        console.log(">>>CPN",coupon)
        const response = await checkCouponValidity({
          couponName: coupon,
        });
        console.log("___",response)
        if (response?.data.coupon.valid) {
          setValidCoupon(true);
          let amount_off = response.data.coupon.amount_off
            ? response.data.coupon.amount_off
            : null;
          let percent_off = response.data.coupon.percent_off
            ? response.data.coupon.percent_off
            : null;
          setSubscriptionCoupon({
            couponId: response.data.coupon.id,
            name: response.data.coupon.name,
            amountOff: amount_off,
            percentOff: percent_off,
          });
        }
    
        if (response.error) {
          toast.error("Invalid coupon code");
          console.log(response)
          return;
        }
      };

      const removeCoupon = () => {
        setValidCoupon(false)
      }
    //update the subscription request handler on click
    const handleUpdateSusbscriptionClick = async(e) => {
        props.setPopUpTriger(true)
        props.setPopUpType("update-sub")
        return
    }


    useEffect(()=>{
        if(props.updateSubContinueClicked === true){
            (async ()=>{console.log(">>>UPDT_RQ",updateRequest)
            setIsLoading(true)
            try {
    
                const paymentMethods = await fetchPaymentMethodsForCustomer(organization)
                if(paymentMethods.data.length === 0) {
                    props.setPopUpTriger(true)
                    props.setPopUpType("add-new-card")
                    setIsLoading(false)
                    return
                }
                const response = await axios ({
                    method : "PATCH",
                    url : process.env.REACT_APP_BASE_URL + "api/stripe/subscriptions/v2",
                    data : {
                        data : updateRequest
                    },
                    headers : getAuthHeader()
                })
                triggerToast("Your subscription has updated successfully.","success",{autoClose : 3000})
                updateOrgRequest({
                    orgId : organization._id,
                    op : "update",
                    fields : {
                        isEnabledPAYG : false
                    }
                }).then((res)=>{
                    if(res.error){
                        console.log(res.error)
                    }else {
                        console.log(res)
                    }
                })
                if(setUpdateButtonClicked){
                    setUpdateButtonClicked(true)
                }
                window.location.replace('/manage-subscription')
                setIsLoading(false)
            }catch(err){
                toast.error(err.response.data.message)
                setIsLoading(false)
            }}) ()
        } 
    },[props.updateSubContinueClicked])

    return (
        <div className='flex flex-col items-start justify-start gap-[1.5rem] w-full'>
            <div className={`${styles.heading} sticky -top-[2px] !bg-white w-full !pt-[6px]`}>Summary</div>
            {
                !summaryObject ?
                    <>
                        <div className={`${styles.container} !py-[89px] !items-center`}>
                            <div className={styles.mainText}>No recent changes</div>
                            <div className={`${styles.helperText} !text-[#7C859C] !text-center`}>You haven’t made any changes to your subscription.</div>
                        </div>
                    </>

                    :
                    <>
                        <div className={styles.container}>
                            {
                                summaryObject?.map((obj, idx) => (
                                    <>
                                        <SummarySection {...obj} />
                                        {
                                            idx < _summaryObject.length - 1 ?
                                                <HorizontalDivider /> : <></>
                                        }
                                    </>
                                ))
                            }
                        </div>
                        <ApplyCoupon {...{setCoupon,coupon,valid : validCoupon,setValid : setValidCoupon,subscriptionCoupon : subscriptionCoupon, discountAmount :  (invoiceData?.totalAmount) - parseFloat(invoiceData?.amountDue/100),applyCoupon : applyCouponToSubscription, removeCoupon : removeCoupon }}/>
                        <AccountingSection items={accountingItems}/>
                        <div className={styles.actionArea}>
                            <ButtonV2 className="w-full" onClick={(e)=>handleUpdateSusbscriptionClick(e)}>{isLoading ? <div className='w-full flex items-center justify-center'><LoaderIcon /></div> : <>Update subscription</> }</ButtonV2>
                            <div className={styles.helperText}>Amount due, if any, will be charged to your existing card and changes will be effective immediately.</div>
                        </div>

                    </>
            }


        </div >
    )

}


export default SummaryCornerSub