import styles from './PopupContainer.module.css'
import closeIcon from './CloseIcon.svg'
import { useEffect, useState } from 'react'

const PopupContainer = (props) => {

    const { trigger, setTrigger } = props

    const handleOutSideClick = (e) => {
        if (e.target.id !== "inside" && e.target.id === "outside") {
            setTrigger(false)
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })

        if (trigger) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }

        return () => {
            document.body.style.overflow = "auto"
        }
    }, [trigger])

    if (trigger) {
        return (
            <div className={`${styles.outerContainer} ${props.customOuterContainer}`} onClick={(e) => handleOutSideClick(e)} id={"outside"}>
                <div className={`${styles.innerContainer} ${props.customInnerContainer}`} id={"inside"} onClick={(e) => handleOutSideClick(e)} style={props.innerContainerStyle}>
                    {props.children}
                    {
                        !props.customCloseButton ?
                            <button onClick={(e) => setTrigger(false)} className={styles.closeButton}><img src={closeIcon} /></button>
                            : <></>

                    }
                </div>
            </div>
        )
    }

}

export default PopupContainer
