const AssignmentIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_30565_11935)">
                <path d="M15.125 30.75C15.125 31.4404 14.5654 32 13.875 32H6.6875C3.93042 32 1.6875 29.7571 1.6875 27V5C1.6875 2.24292 3.93042 0 6.6875 0H22.0557C24.8125 0 27.0557 2.24292 27.0557 5V18.25C27.0557 18.9404 26.4958 19.5 25.8057 19.5C25.1152 19.5 24.5557 18.9404 24.5557 18.25V5C24.5557 3.62158 23.4341 2.5 22.0557 2.5H6.6875C5.30908 2.5 4.1875 3.62158 4.1875 5V27C4.1875 28.3784 5.30908 29.5 6.6875 29.5H13.875C14.5654 29.5 15.125 30.0596 15.125 30.75ZM29.8525 20.3586C29.2913 19.9568 28.5105 20.0862 28.1086 20.6472L21.9473 29.2537C21.7791 29.4487 21.5681 29.4907 21.4553 29.4983C21.3386 29.5059 21.113 29.4912 20.9194 29.3069L16.9307 25.4768C16.4329 24.9988 15.6416 25.0146 15.1633 25.5127C14.6851 26.0107 14.7012 26.802 15.1992 27.28L19.1919 31.114C19.79 31.6831 20.5881 32 21.4094 32C21.48 32 21.5508 31.9976 21.6216 31.9929C22.5161 31.9333 23.3513 31.4983 23.9131 30.7991C23.9275 30.7812 23.9417 30.7627 23.9551 30.7439L30.1414 22.1025C30.5432 21.5413 30.4141 20.7605 29.8525 20.3586ZM20.8057 7.5H7.93066C7.24023 7.5 6.68066 8.05957 6.68066 8.75C6.68066 9.44043 7.24023 10 7.93066 10H20.8057C21.4958 10 22.0557 9.44043 22.0557 8.75C22.0557 8.05957 21.4958 7.5 20.8057 7.5ZM22.0557 13.75C22.0557 13.0596 21.4958 12.5 20.8057 12.5H7.93066C7.24023 12.5 6.68066 13.0596 6.68066 13.75C6.68066 14.4404 7.24023 15 7.93066 15H20.8057C21.4958 15 22.0557 14.4404 22.0557 13.75ZM7.93066 17.5C7.24023 17.5 6.68066 18.0596 6.68066 18.75C6.68066 19.4404 7.24023 20 7.93066 20H15.75C16.4404 20 17 19.4404 17 18.75C17 18.0596 16.4404 17.5 15.75 17.5H7.93066Z" fill="#8D54D7" />
            </g>
            <defs>
                <clipPath id="clip0_30565_11935">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default AssignmentIcon