import { useEffect, useState } from "react"
import { filterUser } from "../../pages/CalendarV2/helpers/utils"
import HorizontalDivider from "../HorizontalDivider/HorizontalDivider"
import MoreInfoIcon from "../MoreInfoIcon/MoreInfoIcon"
import SearchEvents from "../SearchEvents/SearchEvents"
import SearchFieldV2 from "../SearchFieldV2/SearchFieldV2"
import StubbyLeftArrow from "../StubbyLeftArrow/StubbyLeftArrow"
import Tooltip from "../Tooltip/Tooltip"
import styles from "./CalendarSearchPage.module.css"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);


function UserDisplayImage({profile, name, bgColor}){
	return (
		<>
			{ 
			profile ? 
			<img src={profile} className="h-[32px] w-[32px] rounded-full object-cover"/>
			: 
			<div className="text-uppercase min-h-[32px] min-w-[32px] rounded-full flex items-center justify-center text-white font-[lexend] font-[400] text-[14px] leading-[21px]" style={{backgroundColor : bgColor}}>{name[0]}</div>
			}
		</>
	)
}

function UserDetailsPart({name, email, role}) {
	return (
		<div className="flex flex-col gap-[2px] items-start justify-start max-w-full text-wrap overflow-wrap break-all w-full">
			<div className="flex gap-[4px] items-center justify-start">
				<span className="capitalize font-[lexend] text-[14px] leading-[21px] font-[400] text-secondary-80 max-w-full">{name}</span>
				<span className="px-[8px] py-[5px] rounded-[24px] bg-secondary-3 text-secondary-80 font-[400] text-[10px] leading-[10px] capitalize">{role}</span>
			</div>
			<span className="font-[lexend] font-[400] text-[10px] leading-[15px] text-secondary-60 max-w-full">{email}</span>
		</div>
	)
}


function UserDetailsSection({allUserList}) {

	const [showAll, setShowAll] = useState(false)

	return (
		<div className="flex flex-col gap-[20px] items-start justify-start w-full">
			<h3 className="pl-[12px] uppercase font-[lexend] font-[400] text-[12px] leading-[12px] text-secondary-60">People</h3>
			<div className="w-full flex flex-col items-start gap-[12px]" style={{...(showAll) && {maxHeight : "576px", overflowY : "auto"}}}>
				{
					allUserList?.slice(0,showAll ? allUserList.length : 5).map((_user, idx)=>(
						<div className="font-[lexend] flex gap-[6px] items-center justify-start hover:border-l-[2px] hover:border-l-[solid] hover:border-l-blue-70 hover:bg-secondary-2 w-full px-[12px] py-[8px] rounded-[0px_6px_6px_0px] cursor-pointer">
								<UserDisplayImage {..._user} />
								<UserDetailsPart {..._user} />
						</div>
					))
				}
			</div>
			{
				allUserList?.length > 5 ?
					<button className="text-blue-70 font-[400] text-[14px] leading-[14px] pl-[12px]" onClick={()=> setShowAll(prev=>!prev)}>
						{
							showAll ? "View Less": "View All"
						}
					</button>
				:
				<></>
			}
		</div>
	)
}


function formatName(fullName){
	
	const nameInParts = fullName.split(" ")
	return `${nameInParts[0]} ${nameInParts[1][0]}.`

}


function CalendarSearchPage(props) {


	console.log(props.setSelectEventPopupId)
	
	
	const {handleBackClick, searchUser, setSearchUser, allUserList, user, allEvents} = props
	const [_searchUser, _setSearchUser] = useState("")
	
	useEffect(()=>{
		if(searchUser){
			_setSearchUser(searchUser)
		}
	},[searchUser])


	function filterAllEvents(val){
		const allStudentNames = val.extendedProps.studentIds?.map(val=>val.firstName + val.lastName)
		const allAttendeeNames = val.extendedProps.attendees?.map(val=>val.firstName + val.lastName)
		const tutorName = val.extendedProps.tutorName
		const studentName = val.extendedProps.studentName

		const largeCorpus = [tutorName, studentName]
		allStudentNames?.forEach(element => {
			largeCorpus.push(element)	
		});
		allAttendeeNames?.forEach(element=>{
			largeCorpus.push(element)
		})

		
		return largeCorpus.some(val => val?.toLowerCase().includes(_searchUser?.replace(/\s+/g, "").toLowerCase()))
	}

	return (
		<div className={styles.container}>
			<div className={styles.heading}>
				<div className={styles.header}>
					<button className={styles.desktopBackButton} onClick={(e)=>handleBackClick(e)}><StubbyLeftArrow /></button>
					<div className={styles.subHeader}>
						<h2>Search</h2>
					</div>
				</div>
			</div>
			<div className={styles.subContainer}>
				<SearchFieldV2 
					isCrossButtonVisible={_searchUser.trim() !== ""} 
					placeholder={"Search"} 
					textFieldValue={_searchUser}
					onChange={(e)=>{_setSearchUser(e.target.value)}}
					onCrossClick={(e)=>_setSearchUser("")}
				/>
				<UserDetailsSection allUserList={allUserList.filter((val,idx)=>filterUser(val,idx,_searchUser))}/>
				<HorizontalDivider />
				{/*SEARCH HERE RIGHT NOW, LATER MOVE TO DB*/}
				<SearchEvents 
					_eventData={allEvents?.filter(filterAllEvents).map(val=>{
						const timeZone = user?.userDefaults?.timeZone ? user?.userDefaults?.timeZone : dayjs.tz.guess()
						const studentName = 
							(val.extendedProps.__t === "Session" || !val.extendedProps.__t) ? 
								val.extendedProps.studentName ? 
									val.extendedProps.studentName : 
										val.extendedProps.studentIds[0].firstName + " " + val.extendedProps.studentIds[0].lastName :
										[val.extendedProps.attendees.find(user => ["student"].includes(user.role))?.firstName,val.extendedProps.attendees.find(user => ["student"].includes(user.role))?.lastName].join(" ")
						const tutorName = val.extendedProps.tutorName ? val.extendedProps.tutorName : [val.extendedProps.attendees.find(user => ["admin", "tutor"].includes(user.role))?.firstName,val.extendedProps.attendees.find(user => ["admin", "tutor"].includes(user.role))?.lastName].join(" ")
						const eventType = val.extendedProps.__t ? val.extendedProps.__t : "Session"
						const eventDate = dayjs(val.extendedProps.date).tz(timeZone).format("MMMM DD, YYYY")
						const eventStartTime = [val.extendedProps.time.start.time,val.extendedProps.time.start.timeType].join("").toLowerCase()
						const eventEndTime = [val.extendedProps.time.end.time,val.extendedProps.time.end.timeType].join("").toLowerCase()
						const eventName = `${formatName(studentName)} / ${formatName(tutorName)} `
						
						return({
							_id : val.extendedProps._id,
							eventName : eventName,
							eventType : eventType,
							eventDate : eventDate,
							eventStartTime : eventStartTime,
							eventEndTime : eventEndTime,
							setSelectEventPopupId: props.setSelectEventPopupId,
						})

					})}/>
			</div>
		</div>
	)

}


export default CalendarSearchPage
