import { useState } from "react";
import { getFormattedDateV2 } from "../../utils/dateUtils";
import SortArrowDownIcon from "../SortArrowIcons/SortArrowDownIcon";
import SortArrowUpIcon from "../SortArrowIcons/SortArrowUpIcon";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function getSortArrowIconDependingOnState(tutorSortedStatesData, key) {
  return tutorSortedStatesData[key] === -1 ? (
    <SortArrowDownIcon className="pointer-events-none" />
  ) : (
    <SortArrowUpIcon className="pointer-events-none" />
  );
}

const ResponsiveAssignTutorLicensesTable = ({
  tutorData,
  onActivateClicked = () => {},
  onDeactivateClicked = () => {},
  setParentViewTutorSortedStatesData,
}) => {
  const [tutorSortedStatesData, setTutorSortedStatesData] = useState({
    tutorName: -1,
    studentCount: -1,
    dateAdded: -1,
    lastLogin: -1,
    licenseStatus: -1,
  });

  const [visibleIndex, setVisibleIndex] = useState(0); // Track the starting index of the visible columns
  const allColumns = [
    "S.No",
    "Tutor Name",
    "Students",
    "Date Added",
    "Last Login",
    "License Status",
  ];

  const handleSortArrowClicked = (key) => {
    const tempTutorSortedStatesData = { ...tutorSortedStatesData };
    tempTutorSortedStatesData[key] =
      tempTutorSortedStatesData[key] === -1 ? 1 : -1;

    // Reset other sorting states to -1 except the current one
    Object.keys(tempTutorSortedStatesData).forEach((stateKey) => {
      if (stateKey !== key) {
        tempTutorSortedStatesData[stateKey] = -1;
      }
    });

    setTutorSortedStatesData(tempTutorSortedStatesData);
    setParentViewTutorSortedStatesData(tempTutorSortedStatesData);
  };

  const sortedTutorData = () => {
    // Sort tutorData based on tutorSortedStatesData
    const { tutorName, studentCount, dateAdded, lastLogin, licenseStatus } =
      tutorSortedStatesData;

    const sortedData = [...tutorData].sort((a, b) => {
      switch (true) {
        case tutorName !== 0:
          return tutorName === -1
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        case studentCount !== 0:
          return studentCount === -1
            ? a.studentCount - b.studentCount
            : b.studentCount - a.studentCount;
        case dateAdded !== 0:
          return dateAdded === -1
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt);
        case lastLogin !== 0:
          return lastLogin === -1
            ? new Date(a.lastLogin) - new Date(b.lastLogin)
            : new Date(b.lastLogin) - new Date(a.lastLogin);
        case licenseStatus !== 0:
          return licenseStatus === -1
            ? a.isActive - b.isActive
            : b.isActive - a.isActive;
        default:
          return 0;
      }
    });

    return sortedData;
  };

  const handleScrollRight = () => {
    if (visibleIndex < allColumns.length - 2) {
      setVisibleIndex(visibleIndex + 1);
    }
  };

  const handleScrollLeft = () => {
    if (visibleIndex > 0) {
      setVisibleIndex(visibleIndex - 1);
    }
  };

  return (
    <div className="relative flex items-center justify-center w-full overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="bg-secondary-10 text-secondary rounded-md text-center font-lexend text-[17.5px] font-medium leading-[25px]">
            {allColumns
              .slice(visibleIndex, visibleIndex + 2)
              .map((column, index) => (
                <th key={index} className="p-4">
                  <div className="flex gap-2 items-center justify-center">
                    <span>{column}</span>
                    {column !== "S.No" && (
                      <button
                        onClick={() => handleSortArrowClicked(column)}
                        className="focus:outline-none"
                      >
                        {getSortArrowIconDependingOnState(
                          tutorSortedStatesData,
                          column
                        )}
                      </button>
                    )}
                  </div>
                </th>
              ))}
            <th className="p-4 w-8">
              <div className="flex gap-2 items-center justify-center">
                {visibleIndex > 0 && (
                  <button className="" onClick={handleScrollLeft}>
                    <ChevronLeftIcon />
                  </button>
                )}
                {visibleIndex < allColumns.length - 2 && (
                  <button className="" onClick={handleScrollRight}>
                    <ChevronRightIcon />
                  </button>
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedTutorData()?.map((val, idx) => (
            <tr
              className={`text-center bg-white rounded-md shadow-sm text-secondary-80 ${
                val.isActive ? "text-secondary" : "text-secondary-60"
              }`}
              key={"asTutorLicense_" + idx}
            >
              {allColumns
                .slice(visibleIndex, visibleIndex + 2)
                .map((column, index) => (
                  <td key={index} className="p-2">
                    {column === "S.No" &&
                      (idx + 1 < 10 ? "0" + (idx + 1) : idx + 1)}
                    {column === "Tutor Name" && val.name}
                    {column === "Students" && val.studentCount}
                    {column === "Date Added" &&
                      getFormattedDateV2(val.createdAt, "dd mm, yyyy")}
                    {column === "Last Login" &&
                      (val.lastLogin
                        ? getFormattedDateV2(val.lastLogin, "dd mm, yyyy")
                        : "-")}
                    {column === "License Status" && (
                      <div className="flex gap-2">
                        <div
                          className={`flex gap-2 items-center justify-center w-full ${
                            val.isActive ? "text-success" : "text-secondary-20"
                          }`}
                        >
                          {val.isActive ? (
                            <div className="px-6 py-2 rounded-full bg-green-10 font-medium border border-green-70 text-[15px]">
                              Active
                            </div>
                          ) : (
                            <div className="px-4 py-2 rounded-full bg-white border text-[15px] font-medium border-secondary-20">
                              Inactive
                            </div>
                          )}
                          {val.isActive ? (
                            <button
                              className="bg-transparent underline text-blue-70"
                              onClick={(e) => onDeactivateClicked(e)}
                              id={val._id}
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="bg-transparent underline text-blue-70"
                              onClick={(e) => onActivateClicked(e)}
                              id={val._id}
                            >
                              Activate
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                ))}
              <td className="p-4"></td>{" "}
              {/* Empty cell to match header layout */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResponsiveAssignTutorLicensesTable;
