export default function ArrowDownIcon(props) {
	
	return (
		<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{transform : "rotate(180deg)"}}>
			<path d="M11.1024 4.4487L6.57486 9.25924C6.26361 9.58995 5.73805 9.58995 5.4268 9.25924L0.899197 4.4487C0.559034 4.08724 0.815272 3.49414 1.31162 3.49414H10.69C11.1863 3.49414 11.4426 4.08724 11.1024 4.4487Z" fill="currentColor"/>
		</svg>

	)

}
