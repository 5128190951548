import { useEffect, useState } from 'react';
import styles from './AddTextInput.module.css'
import AddIcon from '@mui/icons-material/Add';

const AddTextInput = ({values, handleAddToInput, allowSpaces, validationFunction}) => {
    
    const [isAddButtonVisisble, setIsAddButtonVisible] = useState(false)     
    const [inputText, setInputText] = useState("")
    const [tempValues, setTempValues] = useState([])

    useEffect(()=>{
        if(values){
            setTempValues(values)
        }else {
            setTempValues([])
        }
    },[values])



    useEffect(()=>{
        setIsAddButtonVisible(inputText.trim() !== "")
    },[inputText])

    const handleTextChange = (e) => {
        if(validationFunction){
            if(!validationFunction(e.target.value)){
                return;
            }
        }
        if (allowSpaces) {
            setInputText(e.target.value)
        } else {
            if (!e.target.value.trim().includes(" "))
                setInputText(e.target.value);
            setIsAddButtonVisible(e.target.value.trim() !== "" && !e.target.value.trim().includes(" "))
        }
    }


    const handleKeyDown = (e) =>{
        if(e.key === "Enter"){
            setTempValues((prev)=>[inputText.trim(),...e.target.value])
            handleAddToInput(e.target.value)
            setInputText("")
            e.target.value = ""
        }
    }

    const handleAddButtonClick = (e) => {
       setTempValues((prev)=>[inputText.trim(),...prev])
       handleAddToInput(inputText)
       setInputText("")

    }

    return (
        <div className={styles.insertInput}>
            <input type="text" id="lead_status_ip" placeholder='Enter status and press enter' onChange={(e) => handleTextChange(e)} onKeyDown={(e) => handleKeyDown(e)} value={inputText} />
            {
                isAddButtonVisisble ? <button onClick={(e) => handleAddButtonClick()}><AddIcon /></button> : <></>
            }
        </div>

    )
}

export default AddTextInput