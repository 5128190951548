import { Cancel, ExpandMore } from "@mui/icons-material"
import AvailibilityIcon from "../../../AvailibilityIcon/AvailibilityIcon"
import CalendarAnalyticIcon from "../../../CalendarAnalyticsIcon/CalendarAnalyticsIcon"
import EventRequestsIcon from "../../../EventRequestsIcon/EventRequestsIcon"
import LeaveIcon from "../../../LeaveIcon/LeaveIcon"
import PhoneNumberInputV2 from "../../../PhoneNumberInputV2/PhoneNumberInputV2"
import ScheduleMapIcon from "../../../ScheduleMapIcon/ScheduleMapIcon"
import TimeIcon from "../../../TimeIcon/TimeIcon"
import FilterInputController from "../FilterInputController/FilterInputController"
import MiniCalendar from "../../MiniCalendar/MiniCalendar"
import CalendarFilterPopup from "../../../CalendarFilterPopup/CalendarFilterPopup"
import CustomDropdownV2 from "../../../CustomDropdownV2/CustomDropdownV2"
import UserSelectDropdown from "../../../CalendarSearchPage/UserSelectDropdown/UserSelectDropdown"
import CalIconVariant2 from "../../../CalendarSideIcons/CalIconVariant2"
import { useSelector } from "react-redux"




const elLinks = Object.freeze({
	CAL : "/calendar",
	SRCH : "search",
	FILTERS : "filters",
	TMBLK : "time-block",
	SCHMPR : "schedule-Mapper",
	CALTICS : "analytics",
	EVREQ :  "event-requests",
	AVLB :  "availability",
	LVVAC :  "time-off"

})

const sections = {

	section1 : [
		{
			displayName : null,
			icon : null,
			component : (props) => 
				( <MiniCalendar {...props} />),
			elLink : elLinks.CAL,
			childComponent : null,
			isLink : false
		},
		{
			displayName : null,
			icon : null,
			component : (props) => <FilterInputController {...props}/>,//Change to timeblock component
			elLink : elLinks.SRCH,
			childComponent : (props)=>{
				return (
					<> 
						{props.dropdownTrigger && <UserSelectDropdown {...props}/>}
					</>
				)
			},
			isLink : false

		},
		{
			displayName : null,
			icon : null,
			component : (props) => <FilterInputController {...props}/>,//Change to timeblock component
			elLink : elLinks.FILTERS,
			childComponent : (props)=>{
				return (<>
					{props.showFilters && <CalendarFilterPopup {...props}/>}
				</>)
			},
			isLink : false

		}

	],

	section2 : [
		{
			displayName : null,
			icon : null,
			component : (props) => <FilterInputController {...props}/>,//Change to timeblock component
			elLink : elLinks.TMBLK,
			childComponent : null,
			isLink : false

		},
		{
			displayName : "Calendar Home",
			icon : <CalIconVariant2 currentColor={true}/>,
			component : null,
			elLink : elLinks.CAL,
			isLink : true
		},
		{
			displayName : "Schedule Mapper",
			icon : <ScheduleMapIcon /> ,
			component : null,
			elLink : elLinks.SCHMPR,
			isLink : false

		},
		{
			displayName : "Calendar Analytics",
			icon : <CalendarAnalyticIcon />,
			component : null,
			elLink : elLinks.CALTICS,
			isLink : true

		}
	],


	section3 : [
		{
			displayName : "Event Requests",
			icon : <EventRequestsIcon />,
			component : (props)=><>{props.activeEventRequests === 0 ? <></> : " (" + props.activeEventRequests +")"}</>,
			elLink : elLinks.EVREQ,
			isLink : true

		},
		{
			displayName : "Availability",
			icon : <AvailibilityIcon />,
			component : null,
			elLink : elLinks.AVLB,
			isLink : true

		},
		{
			displayName : "Time Off",
			icon : <LeaveIcon />,
			component : null,
			elLink : elLinks.LVVAC,
			isLink : true

		}
	]
}

/*		{
			displayName : "Calendar Analytics",
			icon : <CalendarAnalyticIcon />,
			component : null,
			elLink : elLinks.CALTICS,
			isLink : true

		}
*/

export {
	sections,
	elLinks
}
