import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import frozenEventTypes from "../../../utils/eventTypes"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);



function handleDateClick(data,props){

	const timeZone = props.user?.userDefaults?.timeZone ? props.user?.userDefaults?.timeZone : dayjs.tz.guess()
	if(["admin","tutor"].includes(props.user?.role)){
		props.setSelectedDate(dayjs(data.dateStr).tz(timeZone))
		props.setIsActive(true)
		props.setOperationMode("create")
	} else if(["student", "parent"].includes(props.user?.role)){
		props.setSelectedDate(dayjs(data.dateStr).tz(timeZone))
		props.setIsActiveRequestEventPopUp(true)
		props.setOperationMode("create")
	}


}


const nonSessionEvents = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase() ]
function handleEventClick(eventInfo,props){

	const { extendedProps : eventData }	 = eventInfo.event._def

	if(eventInfo.event._def.ui.display === "background"){
		return 
	}
		
	if(eventData.isEventRequest){
		if(["admin","tutor"].includes(props.user?.role)){
			props.setIsActive(true)
			props.setSelectedEventType(eventData.eventType === "officehours" ? frozenEventTypes.OFFCWORK.eventType : eventData.eventType )
			if(eventData.eventType.toLowerCase() === frozenEventTypes.EXTMEETING.eventType.toLowerCase()){
				props.setOperationMode("create-requested")
				props.setTempEventType(eventData.eventType)
			}else if(nonSessionEvents.includes(eventData.eventType.toLowerCase())){
				props.setOperationMode("edit")
				props.setTempEventType("")
			} else {
				props.setTempEventType("")
				props.setOperationMode("create")
			}
			props.setRequiredEventData({
					date : eventData.date,
					time : eventData.time,
					timeZone : eventData.timeZone,
					studentIds : eventData.studentIds,
					attendeeDetails : eventData.attendeeDetails,
					attendees : eventData.attendees,
					tutorId : eventData.tutorId,
					clientNotes : { note : eventData.additionalInfo},
					internalNotes : { note : ""},
					isEditingRequest : true,
					eventRequestId : eventData.eventRequestId,
					eventId : eventData.eventId,
					eventRequestor : eventData.requestor,
					additionalInfo : eventData.additionalInfo,
					...(eventData.eventType.toLowerCase() === frozenEventTypes.EXTMEETING.eventType.toLowerCase()) && {
						studentIds : (eventData.studentIds ?? []).concat(eventData.attendees.filter(val=>val.role === "student")),
						tutorId : (eventData.attendees.find(val => val.role === "admin" || val.role === "tutor")?._id)
					}
				}
			)
		} else if(["student", "parent"].includes(props.user?.role)){
			props.setSelectedEventType(eventData.eventType)
			props.setIsActiveRequestEventPopUp(true)
			props.setRequiredEventRequestData({
				isEditing : true,
				eventRequestId : eventData.eventRequestId,
				eventRequest : eventData.eventRequest
			})
		}
	} else {
		props.setIsActive(true)
		props.setOperationMode("edit")
		props.setSelectedEventType(eventData.eventType)
		props.setRequiredEventData(eventData)
	}

	console.log("E_I", eventData)
	console.log("E>>>I",  (eventData.studentIds ?? []).concat(eventData.attendees.filter(val=>val.role === "student")))
						
	console.log("NORM TZ E_I", eventData.timeZone, eventData.time.start.time, eventData.time.start.timeType, new Date(eventData.date).toISOString())
	console.log("CONVERTED E_I", eventInfo.event.start )
}

export {
	handleDateClick,
	handleEventClick
}
