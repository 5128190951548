import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  useCreateIntentMutation,
  useFinishSetupMutation,
} from "../../../app/services/subscription";
import { toast } from "react-toastify";
import { triggerToast } from "../../../utils/toastController";
const CardDetailsInput = ({
  addPaymentMethod,
  stripeCustomerId,
  setLoading,
  subscription,
  setPopUpTriger,
  setUpdateSubContinueClicked
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [createIntentForPayment, createIntentForPaymentResp] =
    useCreateIntentMutation();
  const [finishSetupForPayment, finishSetupForPaymentResp] =
    useFinishSetupMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const handlePaymentMethod = async () => {
    if (addPaymentMethod) {
      console.log("inside set up payment");
      console.log(stripeCustomerId);
      if (
        !stripe ||
        stripeCustomerId === undefined ||
        stripeCustomerId === "" ||
        stripeCustomerId === null
      ) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setLoading(true);

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setErrorMessage(submitError.message);
        setLoading(false);
        return;
      }

      // Create the SetupIntent and obtain clientSecret
      const res = await createIntentForPayment({
        customer_id: stripeCustomerId,
      });

      console.log("add new payment");

      console.log("response from create_intent");
      console.log(res);

      const { client_secret: clientSecret } = res.data;
      // const { client_secret: clientSecret } = await res.json();
      console.log(clientSecret);
      // Confirm the SetupIntent using the details collected by the Payment Element
      const { setupIntent, error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          return_url: "http://localhost:3000/settings?tab=3",
        },
      });

      console.log("setupIntent");
      console.log(setupIntent);

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the setup. Show the error to your customer (for example, payment details incomplete)
        triggerToast("Oops! Seems like the card info you added has some issue. Please check and try again.","error",{autoClose : 5000})
        handleError(error);
        setUpdateSubContinueClicked(false)
      } else {
        const post = await finishSetupForPayment({
          setupintent: setupIntent?.id,
          // subscriptions: [
          //   chosenSubscriptionObjectFromAPI,
          //   [...chosenExtentionObjectsFromAPI],
          // ],
        });

        console.log("response from finish_setup");
        console.log(post);

        if (post) {
          toast.success("Payment method added successfully")
          if(setPopUpTriger){
            setPopUpTriger(false)
          }
          setLoading(false);
          if(setUpdateSubContinueClicked){
            setUpdateSubContinueClicked(false)
          }
        }
      }
    }
  };

  useEffect(() => {
    if (addPaymentMethod) {
      handlePaymentMethod();
    }
    console.log(addPaymentMethod);
  }, [addPaymentMethod]);

  return (
    <div className="w-[100%]">
      <PaymentElement />
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
};

export default CardDetailsInput;
