import { useCancelSubscriptionV2Mutation } from "../../../app/services/subscription";
import { triggerToast } from "../../../utils/toastController";
import ButtonV2 from "../../ButtonV2/ButtonV2";
import styles from "./CancelSubPopUp.module.css";

const CancelSubPopUp = (props) => {
  const [cancelSubV2Req] = useCancelSubscriptionV2Mutation();

  async function handleConfirmClick(e) {
    try {
      const res = await cancelSubV2Req({ data: [{ op: "cancel" }] });
      if (!res.error) {
        triggerToast(
          "Subscription cancelled successfully. You have access till the current billing cycle, after which you will not be charged.",
          "error",
          { autoClose: 3000 }
        );
        props.setPopUpTriger(false);
      } else {
        console.log(res.error);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Cancel subscription</div>
      <div className="flex flex-col items-center justify-start gap-[16px]">
        <div className={`${styles.title} ${styles.mod}`}>
          Are you sure you want to cancel your existing subscription?
        </div>
        <div className={`${styles.helper}`}>
          Note: If you cancel your subscription, your next payment will be
          auto-cancelled. You can continue to enjoy the benefits until the end
          of this billing cycle.
        </div>
      </div>
      <div className="flex justify-center items-center gap-[12px]">
        <ButtonV2
          className={styles.confirmButton}
          onClick={(e) => handleConfirmClick(e)}
        >
          Yes, confirm
        </ButtonV2>
        <ButtonV2
          className={styles.backButton}
          onClick={(e) => props.setPopUpTriger(false)}
        >
          Back
        </ButtonV2>
      </div>
    </div>
  );
};

export default CancelSubPopUp;
