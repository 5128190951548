import React, { useEffect, useState } from 'react'
import CrossIcon from '../../../assets/icons/SettingsIcon/CrossIcon'
import TabSelector from '../../../components/TabSelector/TabSelector'
import SectionOne from '../../../components/CustomSignup/SectionOne'
import SectionTwo from '../../../components/CustomSignup/SectionTwo'
import ArrowIconLeftV2 from '../../../components/ArrowIconLeftV2/ArrowIconLeftV2'
import SectionThree from '../../../components/CustomSignup/SectionThree'
import { useSelector } from 'react-redux'
import { useUpdateOrgDataMutation } from '../../../app/services/admin'
import { fetchAndSetOrgDetails } from '../../../utils/orgService'
import { triggerToast } from '../../../utils/toastController'
import LoaderIcon from '../../../components/LoaderIcon/LoaderIcon'
const items = ["Section 1", "Section 2", "Section 3"]
const CustomiseSignupForm = ({ openQuestioPopup, setOpenQuestionPopup }) => {
    // const [openQuestioPopup, setOpenQuestionPopup] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [sectionPage, setSectionPage] = useState(null)
    const [customFields, setCustomFields] = useState(null)
    const [updateOrgRequest,] = useUpdateOrgDataMutation()
    const [isLoading, setIsLoading] = useState(false)
    const [sectionThreeActiveIndex, setSectionThreeActiveIndex] = useState(null)
    const [sectionTwoActiveIndex, setSectionTwoActiveIndex] = useState(null)
    const [packageName, setPackageName] = useState("")
    const [totalQuestion, setTotalQuestion] = useState(0)

    const { subscriptionV2 } = useSelector((state) => state.subscription)
    const { organization } = useSelector((state) => state.organization);

    function SwitchTab({ selectedTab, data }) {
        switch (selectedTab) {
            case 0: return <SectionOne setActiveIndex={setSectionTwoActiveIndex} activeIndex={sectionTwoActiveIndex} {...data} />;
            case 1: return <SectionTwo setActiveIndex={setSectionTwoActiveIndex} activeIndex={sectionTwoActiveIndex} {...data} />;
            case 2: return <SectionThree activeIndex={sectionThreeActiveIndex} setActiveIndex={setSectionThreeActiveIndex} {...data} />;
            default: return "Wrong selection";
        }
    }

    useEffect(() => {
        // setPackageName(subscriptionV2.subscription.basePackage.name.split("_")[1])
        customFields && setTotalQuestion(customFields.sectionTwo.length + customFields.sectionThree.length - 4)
    }, [customFields])

    useEffect(() => {
        if (!organization?.settings?.customFieldsV2) return;
        console.log(organization?.settings?.customFieldsV2)
        const tempCustomFiels = {}
        Object.entries(organization?.settings?.customFieldsV2).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                tempCustomFiels[key] = value.map((val) => {
                    return { ...val, Values: val.Values }
                })
            } else {
                tempCustomFiels[key] = { ...value }
            }

        });
        setCustomFields(tempCustomFiels)
        setPackageName(subscriptionV2.subscription.basePackage.name.split("_")[1])
        // setThePermission(customFields);
        // console.log(customFields)
    }, [organization?.settings?.customFieldsV2]);

    const handleSaveClick = (e) => {
        setIsLoading(true)
        updateOrgRequest({
            op: "update",
            orgId: organization._id,
            fields: {
                "settings.customFieldsV2": customFields,
            }
        }).then((res) => {
            if (!res.error) {
                fetchAndSetOrgDetails(organization._id)
                triggerToast("Form updated successfully", "success", { autoClose: 3000 })
            } else {
                triggerToast("Oops some error occured", "error", { autoClose: 3000 })
            }
            setIsLoading(false)
        })
    }

    return (

        <div className='h-full w-full bg-secondary/30 fixed top-0 left-0 z-[2147483640] flex justify-center items-center'>
            <div className='w-full xl:w-[712px] h-full xl:max-h-[680px] xl:rounded-xl flex flex-col  bg-white p-4 xl:px-9 xl:py-6'>
                <div>
                    <div className='xl:flex hidden justify-between items-center pb-1 border-b border-blue-50'>
                        <div className='font-medium text-[24px] leading-[36px] text-blue-90'>Customise Your Signup Form</div>
                        <div className='cursor-pointer' onClick={(e) => setOpenQuestionPopup(false)}> <CrossIcon /> </div>
                    </div>
                    <div className='font-medium text-[16px] leading-[16px] text-blue-70  xl:hidden'>
                        <div className='flex items-center gap-1 cursor-pointer' onClick={(e) => setOpenQuestionPopup(false)}>
                            <ArrowIconLeftV2 /> Back
                        </div>
                    </div>
                    <div className='xl:hidden mt-6'>
                        <h2 className='font-semibold text-[20px] leading-[30px] xl:text-2xl xl:leading-[36px] text-secondary'>Customise Your Signup Form</h2>
                    </div>
                </div>
                <div className='bg-blue-2-50 relative h-full overflow-y-auto rounded-t-xl border mt-6 z-[6]'>
                    <TabSelector items={items} selectedIdx={selectedTab} customClass={"sticky top-0 bg-secondary-30"} componentName={"customSignup"} planName={packageName} onClick={(idx) => { setSelectedTab(idx) }} />
                    <SwitchTab selectedTab={selectedTab} data={{ setCustomFields, customFields, packageName, totalQuestion }} />
                </div>
                <div className='pt-4 bg-white border-secondary-10'>
                    <div className='flex justify-end items-center gap-3'>
                        <button className='w-[174px] rounded-[6px] border-[2px] px-6 py-[8px] border-secondary-60 text-secondary-60 font-semibold text-[16px] leading-[20px]' onClick={(e) => setOpenQuestionPopup(false)}>Cancel</button>
                        <button className='w-[174px] rounded-[6px] bg-blue-70 hover:bg-blue-80 px-6 py-[10px] text-white font-semibold text-[16px] leading-[20px] flex items-center justify-center' onClick={(e) => handleSaveClick(e)}>{isLoading ? <LoaderIcon /> : "Save"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomiseSignupForm
