import { createBookedMeeting, fetchAllEventRequests, updateEventRequest } from "../../../utils/eventService";
import { triggerToast } from "../../../utils/toastController";
import { fetchAndSetAllEventRequests } from "./fetchers";
import { getTimeInFormat } from "./utils"


function getTimedelta(reminderString) {
  if (
    !reminderString ||
    reminderString.trim() === "" ||
    reminderString.trim().toLowerCase() === "none"
  ) {
    return 0;
  }
  const reminderStringPart = reminderString.trim().split(" ");
  const seconds = reminderStringPart[1].toLowerCase().includes("minutes")
    ? 60
    : 3600;
  return parseInt(reminderStringPart[0]) * seconds * 1000;
}

async function bookedMeetingScheduler({
  infoWork,
  selectedDay,
  frequency,
  selectedDate,
  endDate,
  selectedStartTime,
  selectedEndTime,
  isRecurring,
  selectedTimeZone,
  selectedStatus,
  sessionNotes,
  meetingLink,
  location,
  isRemainder,
  sendRemindersTo,
  isSelectedUser,
  selectedMonthlyRecurringDate,
  isEditingRequest, eventRequestId, setAllEventRequests
}){
	
	const data = {
		date: selectedDate.toISOString(),
		time: {
			start: getTimeInFormat(selectedStartTime),
			end: getTimeInFormat(selectedEndTime),
		},
		timeZone: selectedTimeZone,
		reminder: {
			isRemainder,
			isEnabled:
			isRemainder.trim() !== "" &&
				isRemainder.trim().toLowerCase() !== "none",
			targetUserTypes: Object.keys(sendRemindersTo).filter(
				(key) => sendRemindersTo[key] === true && key !== "attendees"
			),
			timeDelta: getTimedelta(isRemainder),
		},
		additionalInfo : infoWork,
		location: location,
		...(meetingLink && {
			meetingLinkDetails: {
				meetinglink: meetingLink,
				provider: "any",
			},
		}),
		attendeeDetails: isSelectedUser.map((user) => ({
			id: user,
		})),
	}		
	try {
		await createBookedMeeting(data);
		triggerToast("Booked meeting scheduled successfully", "success", {
			autoClose: 3000,
		});
		if(isEditingRequest){
			const res = await updateEventRequest(eventRequestId, {
				"targetUserDetails.actionStatus" : "frozen"
			})
			fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)
		}
	} catch (error) {
    console.error("Error scheduling consultation:", error);
    triggerToast("Some error occurred", "error", { autoClose: 3000 });
  }

}


export {
	bookedMeetingScheduler
}
