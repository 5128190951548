export default function TripleDotIcon(props) {

	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_35616_43393)">
				<path d="M0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0C13.9174 0 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 0 13.9174 0 11Z" fill="#7C859C"/>
				<path d="M20.4275 11.0001C20.4275 9.76193 20.1836 8.53587 19.7097 7.39195C19.2359 6.24802 18.5414 5.20862 17.6659 4.3331C16.7904 3.45757 15.751 2.76307 14.607 2.28924C13.4631 1.81541 12.2371 1.57153 10.9989 1.57153C9.76071 1.57153 8.53465 1.81541 7.39073 2.28924C6.2468 2.76307 5.2074 3.45757 4.33188 4.3331C3.45635 5.20862 2.76185 6.24802 2.28802 7.39195C1.81419 8.53587 1.57031 9.76193 1.57031 11.0001C1.57031 13.5007 2.56368 15.8989 4.33188 17.6671C6.10008 19.4353 8.49827 20.4287 10.9989 20.4287C13.4995 20.4287 15.8977 19.4353 17.6659 17.6671C19.4341 15.8989 20.4275 13.5007 20.4275 11.0001Z" fill="white"/>
				<path d="M11.0011 12.5713C11.869 12.5713 12.5725 11.8678 12.5725 10.9999C12.5725 10.132 11.869 9.42847 11.0011 9.42847C10.1332 9.42847 9.42969 10.132 9.42969 10.9999C9.42969 11.8678 10.1332 12.5713 11.0011 12.5713Z" fill="#7C859C"/>
				<path d="M15.716 12.5713C16.5838 12.5713 17.2874 11.8678 17.2874 10.9999C17.2874 10.132 16.5838 9.42847 15.716 9.42847C14.8481 9.42847 14.1445 10.132 14.1445 10.9999C14.1445 11.8678 14.8481 12.5713 15.716 12.5713Z" fill="#7C859C"/>
				<path d="M6.28627 12.5713C7.15415 12.5713 7.8577 11.8678 7.8577 10.9999C7.8577 10.132 7.15415 9.42847 6.28627 9.42847C5.4184 9.42847 4.71484 10.132 4.71484 10.9999C4.71484 11.8678 5.4184 12.5713 6.28627 12.5713Z" fill="#7C859C"/>
			</g>
			<defs>
				<clipPath id="clip0_35616_43393">
					<rect width="22" height="22" fill="white"/>
				</clipPath>
			</defs>
		</svg>

	)

}
