import { useEffect, useRef, useState } from 'react'
import DividerPill from '../DividerPill/DividerPill'
import EditIcon from '../EditIcon/EditIcon'
import PillContainer from '../PillContainer/PillContainer'
import ToggleButtonV2 from '../ToggleButtonV2/ToggleButtonV2'
import styles from './SettingsEditor.module.css'
import LoaderIcon from '../LoaderIcon/LoaderIcon'


const SettingsEditor = ({ heading, subHeader, items, isEnabled, handleToggleClick, handleEditClick, idx, setEditPopUpTrigger, setEditPopUpMode, handleEditButtonClick, customClassPillContainer, showMoreMode, h4CustomStyle }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isOverflowing, setIsOverflowing] = useState(false)
    const pillsContainerRef = useRef()
    const pillAreaRef = useRef()

    function detectOverflow(parentRef, childRef) {
        console.log("RESIZE DETECTED")
        if (parentRef  && parentRef.current ) {
            if (parentRef.current?.clientHeight < parentRef.current?.scrollHeight) {
                console.log("Overflowing DETECTED")
                setIsOverflowing(true)
            } else {
                setIsOverflowing(false)
            }
        }

        console.log("REF DETECTED",parentRef?.current.clientHeight) 
    }

    useEffect(() => {
        detectOverflow(pillAreaRef, pillsContainerRef)
        window.addEventListener("resize", ()=>{detectOverflow(pillAreaRef)})

        return () => {
            window.removeEventListener("resize", () => { })
        }
    }, [])


    return (
        <div className={styles.container}>
            <div className={styles.headingRow}>
                <h4 style={h4CustomStyle}>{heading}</h4>
                <div className={styles.actionArea}>
                    {
                        isLoading ? <LoaderIcon />
                            :
                            <ToggleButtonV2 isEnabled={isEnabled} setIsEnabled={(targetValue) => { handleToggleClick(idx, targetValue, setIsLoading) }} />
                    }
                    <DividerPill />
                    <button onClick={(e) => { setEditPopUpTrigger(true); setEditPopUpMode("edit"); handleEditButtonClick(idx) }}><EditIcon /></button>
                </div>
            </div>
            <div className={styles.mainArea}>
                <p className={styles.header}>{subHeader}</p>
                <div className={`${styles.pillArea} `} ref={pillAreaRef} style={{ overflow: isOverflowing ? "hidden" : "" }}>
                    <PillContainer items={items} withoutBorder={true} ref={pillsContainerRef} customClass={customClassPillContainer} />
                    {
                        isOverflowing ?
                            <div className={styles.showMoreText}>... more</div>
                            : <></>
                    }
                </div>
                {
                    showMoreMode ? 
                        isOverflowing?
                            <div className={styles.showMoreText2}>
                                ... more
                            </div>
                            : <div className={styles.dummyShowMore}>&nbsp;</div>
                        : <></>
                }
            </div>

        </div>
    )
}

export default SettingsEditor
