import React, { useState } from 'react'
import SearchIcon from '../../SearchIcon/SearchIcon';
import TutorDropDownList from './TutorDropDownList';

const TutorDropdown = ({tutors, selectedTutor, setSelectedTutor, setIsDropdownOpen, selectedStudents, userRole}) => {
    const [isSearchText, setIsSearchText] = useState("");
    console.log(tutors)

    return (

      userRole === "parent" && selectedStudents?.length  === 0 ) ? <></> :
       
      <main  className=" absolute top-[110%] left-0 w-full  max-h-[320px] cursor-default  z-10 rounded-[6px]  py-[12px] px-[8px]  flex flex-col  gap-[6px] bg-white shadow-[0px_4px_18px_0px_#11167D1A] overflow-y-auto ">
        <section className="  py-[12px] px-[8px] border-[1px] border-secondary-10  rounded-[6px]  w-full flex justify-between items-center gap-[8px] ">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search"
            className=" flex-1  text-[12px] leading-[12px]  text-secondary-60 outline-none "
            onChange={(e) => setIsSearchText(e.target.value)}
          />
        </section>
  
        {tutors &&
          tutors.map((tutor) => {

            if(userRole === "parent"){
              for(const student of selectedStudents){
                
                const findStudentMapped = tutor.assiginedStudents.find((studentId)=> studentId === student._id);
                if( !findStudentMapped ) return null;
              }
            }
            if (
              (tutor.firstName && tutor.firstName.toLowerCase().includes(isSearchText.toLowerCase())) ||
              (tutor.lastName && tutor.lastName.toLowerCase().includes(isSearchText.toLowerCase())) ||
              (tutor.email && tutor.email.toLowerCase().includes(isSearchText.toLowerCase()))) {
              return (
                <TutorDropDownList
                  setIsDropdownOpen={setIsDropdownOpen}
                  key={tutor._id}
                  email={tutor.email}
                  firstName={tutor.firstName}
                  lastName={tutor.lastName}
                  id={tutor._id}
                  bgColor={tutor.bgColor}
                  profile={tutor.profile}
                  selectedtutor={selectedTutor}
                  setSelectedtutor={setSelectedTutor}
                />
              );
            } else {
              return null;
            }
          })}
      </main>
}

export default TutorDropdown