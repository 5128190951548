import React, { useEffect, useState } from "react";
import ConnectButtons from "../ConnectButtons/ConnectButtons";
import LoaderIcon from "../../../../LoaderIcon/LoaderIcon";
import connected from "../../../../../assets/calendar/connected.svg";
import sync from "../../../../../assets/calendar/sync.svg";
import { Tooltip } from "@mui/material";

const IntegrationApps = ({ id, name, description, image, toolTipContent }) => {
  const [loading, SetLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  return (
    <Tooltip
      title={
        <div className="bg-white  w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26] font-[lexend]">
          <div className="font-normal text-[12px] leading-[18px] text-secondary-70 cursor-default">
            {toolTipContent}
          </div>
        </div>
      }
      componentsProps={{ tooltip: { sx: { bgcolor: "transparent" } } }}
    >
      <main className=" w-full p-[20px] border rounded-[12px] border-[#E9EBEF] bg-white hover:bg-[#FBFBFC]">
        <div className="w-full flex flex-col  items-start justify-between gap-[20px]  h-full  relative ">
          <section className="flex flex-col items-start gap-[20px]">
            <img src={image} alt="app" className=" h-[48px] max-h-[48px] " />

            <section className=" flex flex-col gap-[4px] ">
              <div className="text-[18px] leading-[27px] flex items-center justify-start font-semibold gap-1 text-[#25335A] ">
                {name} {isConnected && <img src={connected} alt="" />}
              </div>
              <p className=" text-[14px] leading-[21px] font-normal text-[#7C859C] ">
                {description}
              </p>
            </section>
          </section>

          <ConnectButtons key={id} disabled={true} isConnected={isConnected} />

          {/* Connecting and connected state elements */}

          {loading && (
            <section className=" absolute top-0 right-0  flex   rounded-full  px-[8px] py-[4px]  bg-[#0671E01A]  text-[12px] leading-[15px] font-normal items-center justify-center gap-[6px] text-[#0671E0] ">
              <LoaderIcon className="w-[13.33px] h-[13.33px]" /> Connecting
            </section>
          )}
          {isConnected && (
            <section className=" absolute top-0 right-0  flex   rounded-full  px-[8px] py-[4px]  bg-[#0671E01A]  text-[12px] leading-[15px] font-normal items-center justify-center gap-[6px] text-[#0671E0] ">
              <img src={sync} alt="" />
              Two-Way Sync
            </section>
          )}
        </div>
      </main>
    </Tooltip>
  );
};

export default IntegrationApps;
