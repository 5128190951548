import axios from "axios";
import { triggerToast } from "./toastController";
import { getAuthHeader } from "../app/constants/constants";

function fetchTutorsForTable(tutorSortStatesData, tutorSearchKeyWords,setAllTutorsOfOrg,getTutorsReq){
        //nameSort,studentCountSort,dateAddedSort,lastLoginSort,licenseStatusSort
        const queryStringArray = []
        Object.keys(tutorSortStatesData).forEach((key) => {
          switch (key) {
            case "tutorName": queryStringArray.push(`nameSort=${tutorSortStatesData[key]}`); break;
            case "studentCount": queryStringArray.push(`studentCountSort=${tutorSortStatesData[key]}`); break;
            case "dateAdded": queryStringArray.push(`dateAddedSort=${tutorSortStatesData[key]}`); break;
            case "lastLogin": queryStringArray.push(`lastLoginSort=${tutorSortStatesData[key]}`); break;
            case "licenseStatus": queryStringArray.push(`licenseStatusSort=${tutorSortStatesData[key]}`); break;
          }
        })
    
        if (tutorSearchKeyWords)
          queryStringArray.push(`nameSearchKeywords=${tutorSearchKeyWords}`)
    
        const queryString = queryStringArray.join("&")
    
        getTutorsReq(queryString)
          .then((res) => {
            if (res.error) {
    
            } else {
              console.log("###", res.data.data.tutors)
              setAllTutorsOfOrg(res.data?.data?.tutors)
            }
          })
}


async function changeTutorStatus(tutorIds,targetStatus){
  try {

    const res = await axios({
      method : "POST",
      url : process.env.REACT_APP_BASE_URL + "api/user/bulkChangeTutorStatus",
      data : {
        users : tutorIds,
        tutorStatus : targetStatus,
        mode : "user-status"
      },
      headers : getAuthHeader()
    })

  }catch(err){
    console.log(err)
    triggerToast("Oops an error occured","error",{autoClose : 3000})
  }
}


export {
    fetchTutorsForTable,
    changeTutorStatus
}