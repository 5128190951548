import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom'
import styles from './ConfirmPasswordResetScreen.module.css'
import successLogo from './SuccessLogo.svg'
import { useEffect, useState } from 'react'
import FormCard from '../../FormCard/FormCard'

const ConfirmPasswordResetScreen = (props) => {


    const {setSignupStage} = useOutletContext()
    const [searchParams, setSearchParams] = useSearchParams()
    const [state, setState] = useState(false)
    const navigate = useNavigate()

    const _state = searchParams.get("state")

    useEffect(()=>{setSignupStage(null)},[])

    useEffect(()=>{
        if(_state === "success") {
            setState(true);
            setTimeout(()=>{navigate('/signin')},1500)
        } 
    },[_state])

    if(state===true){
        return(
            <div className={styles.container}>
                <img src={successLogo} className={styles.successLogo} />
                <div className={styles.messageContainer}>
                    <h3>Password reset successful</h3>
                    <div>Please login with your new password.</div>
                </div>
            </div>
        )
    }


}


export default ConfirmPasswordResetScreen