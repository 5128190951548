import {
    scheduleInternalMeeting, 
    updateEventRequest,
    fetchAllEventRequests,
  } from "../../../utils/eventService";
  import { triggerToast } from "../../../utils/toastController";
  import { fetchAndSetAllEventRequests } from "./fetchers";
  import { getTimeInFormat } from "./utils";
  
  function getTimedelta(reminderString) {
    if (
      !reminderString ||
      reminderString.trim() === "" ||
      reminderString.trim().toLowerCase() === "none"
    ) {
      return 0;
    }
    const reminderStringPart = reminderString.trim().split(" ");
    const seconds = reminderStringPart[1].toLowerCase().includes("minutes")
      ? 60
      : 3600;
    return parseInt(reminderStringPart[0]) * seconds * 1000;
  }
  
  async function handleScheduleInternalMeeting({
    selectedDay,
    frequency,
    selectedDate,
    endDate,
    selectedStartTime,
    selectedEndTime,
    isRecurring,
    selectedTimeZone,
    selectedStatus,
    meetingLink,
    location,
    isRemainder,
    sendRemindersTo,
    isSelectedUser,
    agendaNotes,
    selectedMonthlyRecurringDate,
    isEditingRequest, eventRequestId, setAllEventRequests
  }) {
    const dateRange = [selectedDate.toISOString()];
  
    if (isRecurring) {
      dateRange.push(endDate.toISOString());
    }
  
    let data = {
      time: {
        start: getTimeInFormat(selectedStartTime),
        end: getTimeInFormat(selectedEndTime),
      },
      timeZone: selectedTimeZone,
      reminder: {
        isRemainder,
        isEnabled:
          isRemainder.trim() !== "" &&
          isRemainder.trim().toLowerCase() !== "none",
        targetUserTypes: Object.keys(sendRemindersTo).filter(
          (key) => sendRemindersTo[key] === true && key !== "attendees"
        ),
        timeDelta: getTimedelta(isRemainder),
      },
      requestDetails: {
        staffActionStatus: "pending-action",
        statusRequested: selectedStatus,
      },
      attendeeDetails: isSelectedUser.map((user) => ({
        id: user,
      })),
      location: location,
      ...(meetingLink && {
        meetingLinkDetails: {
          link: meetingLink,
          provider: "any",
        },
      }),
      agendaNotes: agendaNotes,
      isRecurring: isRecurring,
      ...(isRecurring && {
        repeatFrequency: frequency.toLowerCase(),
        ...((frequency.toLowerCase() === "weekly" ||
          frequency.toLowerCase() === "bi-weekly") && {
          selectedDays: selectedDay.map((val) => val.toLowerCase()),
        }),
        ...(frequency.toLowerCase() === "monthly" && {
          selectedDate: parseInt(selectedMonthlyRecurringDate),
        }),
      }),
      dateRange,
      date: selectedDate.toISOString(),
	  ...(isRecurring) && {endDate : endDate.toISOString()}
	  
    };

    console.log(data);
  
      try {
          await scheduleInternalMeeting(data);
          triggerToast("Internal Meeting scheduled successfully", "success", {
              autoClose: 3000,
          });
          if(isEditingRequest){
              const res = await updateEventRequest(eventRequestId, {
                  "targetUserDetails.actionStatus" : "frozen"
              })
              fetchAndSetAllEventRequests(fetchAllEventRequests,setAllEventRequests)
          }
      } catch (error) {
      console.error("Error scheduling Internal Meeting:", error);
      triggerToast("Some error occurred", "error", { autoClose: 3000 });
    }
  }
  
  export default handleScheduleInternalMeeting;
  
