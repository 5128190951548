import React from 'react'

const FinishedCircleBar = () => {
    return (
        <svg width="172" height="12" viewBox="0 0 172 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="5" width="160" height="2" rx="1" fill="#0671E0" />
            <circle cx="166" cy="6" r="6" fill="#0671E0" />
        </svg>

    )
}

export default FinishedCircleBar