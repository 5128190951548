import styles from "./ColorSelector.module.css"
import './CSReactColorful.css'
import { HexColorPicker, HexColorInput, RgbaStringColorPicker } from "react-colorful";
import ButtonV2 from "../../../../../components/ButtonV2/ButtonV2";
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef } from "react";
import { colord, extend } from "colord";
import namesPlugin from "colord/plugins/names";
import useOutsideAlerter from "../../../../../hooks/useOutsideAlerter";
extend([namesPlugin]);

function ColorSelector({color, setColor, onAddClick, setTrigger=null, containerClass}) {
	
	const ref = useRef()
	
	useOutsideAlerter(ref, ()=>{
		const screenWidth = window.innerWidth;
		if(setTrigger && screenWidth >= 992) {
			setTrigger(false)
		}
	})
	
	return(
		<div className={`${styles.container} ${containerClass}`} ref={ref} >
			<RgbaStringColorPicker color={color.startsWith("rgba") ? color : colord(color).toRgbString()} onChange={setColor}/>
			<div className={styles.inputContainer}>
				<div className={styles.inputGroup}>
					<label>Hex</label>
					<div className={styles.innerGroup}>
						<div className={styles.colorIndicator} style={{backgroundColor: color}}>&nbsp;</div>
						<HexColorInput color={color} onChange={setColor} alpha prefixed/>
					</div>
				</div>
			</div>
			<div className={styles.buttonContainer}>
				<ButtonV2 onClick={()=>{onAddClick()}}>Add to collection</ButtonV2>
			</div>
		</div>
	)

}


export default ColorSelector
