import React, { useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { triggerToast } from "../../../../utils/toastController";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import {
  undoSubscriptionDownGrades,
  fetchAndSetSubscription,
} from "../../../../utils/subscriptionService";

const UndoSubscriptionChangesPopUp = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);

  const handleUndoOperation = async () => {
    try {
      setLoading(true);
      const res = await undoSubscriptionDownGrades();
      if (!res.error) {
        fetchAndSetSubscription();
        setLoading(false);
        triggerToast("Subscription modifications undone!", "success", {
          autoClose: 2000,
        });
        setOpen(false);
      } else {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[98%] md:w-[640px] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Undo Subscription Changes</div>
        <button onClick={(e) => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="grid gap-[12px]">
        <div className="text-[20px] text-secondary-80 font-[500] text-center">
          Do you want to undo the scheduled changes?
        </div>
        <div className="text-secondary-60 text-[16px] font-[400] text-center">
          Note: If you continue, your scheduled changes will be discarded and
          your current subscription plan and all associated products will
          continue as is.
        </div>
      </div>
      <div className="flex gap-[12px] justify-center">
        <div
          className="text-secondary-60 basis-1/2 md:basis-3/12 text-center border-[2px] border-secondary-60 px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] cursor-pointer justify-center"
          onClick={(e) => setOpen(false)}
        >
          Cancel
        </div>
        <div
          className={`${"bg-blue-70 text-white cursor-pointer"} basis-1/2 md:basis-3/12  text-center  px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
          onClick={(e) => {
            handleUndoOperation();
          }}
        >
          {loading ? <LoaderIcon /> : "Continue"}
        </div>
      </div>
    </PopupContainer>
  );
};

export default UndoSubscriptionChangesPopUp;
