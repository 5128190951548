import { forwardRef } from "react"
import { getFormattedDateV2 } from "../../utils/dateUtils"
import CalIconVariant2 from "../CalendarSideIcons/CalIconVariant2"
import styles from "./DateRangeButton.module.css"
import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

const DateRangeButton = forwardRef(function DateRangeButton({startDate = new Date(), endDate = new Date(), onClick, buttonStyle, timeZone}, ref){

	return(
		<button className={styles.dateRangeOpenerButton} {...(onClick) && {onClick : (e)=>onClick()}} style={buttonStyle} ref={ref}>
					<>{getFormattedDateV2(startDate.toISOString(), "dd-m-yyyy")}&nbsp;&nbsp;-&nbsp;&nbsp;{getFormattedDateV2(endDate.toISOString(), "dd-m-yyyy")}</>
				<CalIconVariant2 />
		</button>
	)

}
)


export default DateRangeButton

