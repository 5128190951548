import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, getAuthHeader } from "../constants/constants";
export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),

  endpoints: (builder) => ({
    addSubscriptions: builder.mutation({
      query: (body) => ({
        url: `api/stripe/add_subscriptions`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    createIntent: builder.mutation({
      query: (body) => ({
        url: `/api/stripe/create-intent`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    finishSetup: builder.mutation({
      query: (body) => ({
        url: `/api/stripe/finish_setup`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    applyCoupon: builder.query({
      query: (body) => ({
        url: `api/stripe/applyCoupon?couponName=${body.couponName}&subscriptionPrice=${body.subscriptionPrice}`,
        method: "GET",
        headers: getAuthHeader(),
        mode: "cors",
      }),
    }),

    pastTransactions: builder.query({
      query: (body) => ({
        url: `/api/stripe/invoices?customerId=${body.id}`,
        method: "GET",
        headers: getAuthHeader(),
      }),
    }),
    cancelSubscription: builder.mutation({
      query: (subscriptionId) => ({
        url: `api/stripe/cancel/${subscriptionId}`,
        method: "DELETE",
        header: getAuthHeader(),
      }),
    }),
    changeSubscriptions: builder.mutation({
      query: (body) => ({
        url: `api/stripe/changeplan`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    enableAutoRenewal: builder.mutation({
      query: (body) => ({
        url: `api/stripe/enable-auto-renew`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    renewProduct: builder.mutation({
      query: (body) => ({
        url: `api/stripe/renew`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    makeDefaultPayment: builder.mutation({
      query: (body) => ({
        url: `api/stripe/make-default`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    getPricesV2: builder.query({
      query: () => ({
        url: `api/stripe/prices/v2`,
        method: "GET",
        headers: getAuthHeader(),
      }),
    }),
    getBasePackageDetailsV2: builder.query({
      query: () => ({
        url: `api/stripe/prices/v2/basePackageDetails`,
        method: "GET",
        headers: getAuthHeader(),
      }),
    }),
    createNewSubscription: builder.mutation({
      query: (body) => ({
        url: `api/stripe/subscriptions/v2`,
        method: "POST",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
    couponValidity: builder.query({
      query: (body) => ({
        url: `api/stripe/subscriptions/v2/couponValidity?couponName=${body.couponName}`,
        method: "GET",
        headers: getAuthHeader(),
      }),
    }),

    subscriptionInsights: builder.query({
      query: (body) => ({
        url: `api/stripe/subscriptions/v2/insights`,
        method: "GET",
        headers: getAuthHeader(),
      }),
    }),
    getSubscriptionOrg : builder.query({
      query: (body)=> ({
        url: `api/orgs/signupSubscription?org=${body.org}`,
        method:"GET",
      })
    }),
    cancelSubscriptionV2: builder.mutation({
      query: (body) => ({
        url: `api/stripe/subscriptions/v2`,
        method: "PATCH",
        body: body,
        headers: getAuthHeader(),
      }),
    }),
  }),
});

export const {

  useAddSubscriptionsMutation,
  useCreateIntentMutation,
  useFinishSetupMutation,
  useApplyCouponQuery,
  usePastTransactionsQuery,
  useLazyPastTransactionsQuery,
  useLazyApplyCouponQuery,
  useCancelSubscriptionMutation,
  useChangeSubscriptionsMutation,
  useEnableAutoRenewalMutation,
  useRenewProductMutation,
  useMakeDefaultPaymentMutation,
  useLazyGetPricesV2Query,
  useLazyGetBasePackageDetailsV2Query,
  useCreateNewSubscriptionMutation,
  useLazyCouponValidityQuery,
  useLazySubscriptionInsightsQuery,
  useLazyGetSubscriptionOrgQuery,
  useCancelSubscriptionV2Mutation,
} = subscriptionApi;
