import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { toast } from "react-toastify";
import frozenEventTypes from "../../utils/eventTypes";

const timeSlots = [
  "None",
  "30 minutes",
  "1 hour",
  "6 hours",
  "12 hours",
  "24 hours",
];

const userTypes = [
  { id: "student", value: "Student" },
  { id: "parent", value: "Parent" },
  { id: "tutor", value: "Tutor" },
];

const ReaminderSelector = ({
  eventType,
  setActive,
  isRemainder,
  setIsRemainder,
  sendRemindersTo,
  setSendRemindersTo,
  user,
  selectedEventType,
  requiredEventData,
  operationMode
}) => {
  const [isOpenRemainderSelector, setIsOpenRemainderSelector] = useState(false);
  const remainderRef = useRef();

  const [boundayCross, setBoundaryCross] = useState(false);
  useOutsideAlerter(remainderRef, () => setIsOpenRemainderSelector(false));

  const ref = useCallback((node) => {
    console.log(node);
    if (node) {
      const dropdownRect = node?.getBoundingClientRect();
      if (dropdownRect.bottom + 220 > window.innerHeight) {
        setBoundaryCross(true);
      } else {
        setBoundaryCross(false);
      }
    }
  });

  useEffect(() => {
    if (isRemainder && isRemainder.trim() !== "") setActive(true);
    else setActive(false);
  }, [isRemainder]);

	//To Enable or disable editing based on the role
	const isEnabledEditing = useMemo(()=>{
		const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
		if(operationMode === "edit" && requiredEventData && nonSessionTypes.includes(selectedEventType?.toLowerCase())){
			if(requiredEventData.isEditingRequest){
				return requiredEventData.eventRequestor._id === user?.id
			}
			return requiredEventData.initiatorDetails.id === user?.id
		}
		return ["admin","tutor"].includes(user?.role)
	},[user?.role])

  return (
    <main className="flex flex-col items-start justify-center w-full gap-[12px] md:gap-[8px]" style={{ pointerEvents : isEnabledEditing ? "" : "none"}}>
      {/*Remainder time slot selection */}
      <section
        ref={remainderRef}
        className="w-[162px] md:w-[240px] rounded-[6px] bg-[#F6F9FA] cursor-pointer"
        onClick={() => setIsOpenRemainderSelector(!isOpenRemainderSelector)}
      >
        <div
          ref={ref}
          className="flex items-center justify-between w-full relative"
        >
          <span
            className={`pl-[12px] py-[7.5px] ${
              !isRemainder ? "text-secondary-60" : ""
            }`}
          >
            {isRemainder.trim() !== "" ? isRemainder : "Select"}
          </span>
          <button className="pr-[12px] py-[7.5px]" style={{display : isEnabledEditing ?  "" : "none"}}>
            {isOpenRemainderSelector ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </button>

          {isOpenRemainderSelector && (
            <div
              className={` absolute flex flex-col w-full gap-[4px] max-h-[220px] overflow-y-auto bg-white rounded-[4px] z-5000 py-[6px] ${
                boundayCross === true && "bottom-[100%]"
              } ${
                boundayCross === false && "top-[calc(100%+5px)]"
              }   left-0 scroll_cal_effect shadow-[0px_4px_18px_0px_#11167D1A]`}
              style={{ boxShadow: "0px 4px 18px 0px #11167D1A" }}
            >
              {timeSlots.map((time) => (
                <div
                  key={time}
                  className="flex h-[32px] items-center gap-[12px] px-[8px] py-[12px] w-full bg-white hover:bg-zinc-100 cursor-pointer"
                  onClick={() => setIsRemainder(time)}
                >
                  <div className="text-[14px] leading-[14px]">{time}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* Sending remainder email user selection section */}
      {eventType === "session" ? (
        <section className="w-full flex flex-col md:flex-row items-start justify-start gap-[12px] md:gap-[24px]" style={{display : !isEnabledEditing ? "none" : "" }}>
          <p className="text-[14px] leading-[21px] text-secondary-80 whitespace-nowrap">
            Send reminder email to:
          </p>
          <div className="flex items-center justify-start gap-[14px]">
            {userTypes.map((user) => (
              <div
                key={user.id}
                className="flex items-center justify-start gap-[4px] text-[14px] leading-[21px] text-secondary-80"
              >
                <input
                  type="checkbox"
                  checked={sendRemindersTo[user.id]}
                  id={user.id}
                  name={user.value}
                  className="cursor-pointer"
                  onChange={(e) => {
                    setSendRemindersTo((prev) => ({
                      ...prev,
                      [user.id]: !prev[user.id],
                    }));
                  }}
				  style={{display : "inline"}}
                />
                <label htmlFor={user.id}>{user.value}</label>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <section  style={{display : !isEnabledEditing ? "none" : "" }}>
          <div className="flex items-center justify-start gap-[4px] text-[14px] leading-[21px] text-secondary-80">
            <input
              type="checkbox"
              checked={sendRemindersTo.attendees}
              className="cursor-pointer"
              onChange={(e) => {
                setSendRemindersTo((prev) => ({
                  ...prev,
                  attendees: !prev.attendees,
                }));
              }}
              id="attendees"
            />
            <label htmlFor="attendees">Send reminder email to attendees</label>
          </div>
        </section>
      )}
    </main>
  );
};

export default ReaminderSelector;
