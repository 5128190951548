export default function InfoIcon2 (props) {

	return(
		<svg width={props.width? props.width :"16"} height={props.height? props.width :"16"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clip-path="url(#clip0_35085_73657)">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 1.9974C4.68564 1.9974 1.99935 4.68369 1.99935 7.9974C1.99935 11.3111 4.68564 13.9974 7.99935 13.9974C11.3131 13.9974 13.9993 11.3111 13.9993 7.9974C13.9993 4.68369 11.3131 1.9974 7.99935 1.9974ZM0.666016 7.9974C0.666016 3.94731 3.94926 0.664062 7.99935 0.664062C12.0494 0.664062 15.3327 3.94731 15.3327 7.9974C15.3327 12.0475 12.0494 15.3307 7.99935 15.3307C3.94926 15.3307 0.666016 12.0475 0.666016 7.9974ZM7.99935 6.66406C8.36755 6.66406 8.66602 6.96253 8.66602 7.33073V11.3307C8.66602 11.6989 8.36755 11.9974 7.99935 11.9974C7.63115 11.9974 7.33268 11.6989 7.33268 11.3307V7.33073C7.33268 6.96253 7.63115 6.66406 7.99935 6.66406ZM7.99935 5.33073C8.36755 5.33073 8.66602 5.03225 8.66602 4.66406C8.66602 4.29588 8.36755 3.9974 7.99935 3.9974C7.63115 3.9974 7.33268 4.29588 7.33268 4.66406C7.33268 5.03225 7.63115 5.33073 7.99935 5.33073Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
			</g>
			<defs>
				<clipPath id="clip0_35085_73657">
					<rect width="16" height="16" fill="white"/>
				</clipPath>
			</defs>
		</svg>

	)

}
