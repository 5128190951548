import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCardDetails from "./AddCardDetails";
import { triggerToast } from "../../../utils/toastController";
import { useDispatch, useSelector } from "react-redux";
import ApplyCouponContainer from "./ApplyCouponContainer";
import VerticalThickLine from "../../ProductIcons/VerticalThickLine";
import PaymentMethodContainer from "./PaymentMethodContainer";
import SummarySection from "../../SummaryCornerSub/SummarySection/SummarySection";
import HorizontalDivider from "../../HorizontalDivider/HorizontalDivider";

const ReviewAndPayContainer = ({
  discountDetails,
  setDiscountDetails,
  discountAmount,
  setDiscountAmount,
  isLoading,
  setIsLoading,
  selectedBasePackageId,
  selectedProducts,
  proceedButtonClicked,
  productPrices,
  additionalTutorNos,
  selectedPlanType,
  summaryObject,
  _addNewCard,
  setIsPaymentSuccessfull,
}) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const options = {
    mode: "setup",
    currency: "usd",
  };

  const { paymentMethods, defaultPaymentMethodId, stripeCustomerId } =
    useSelector((state) => state.subscription);

  const { subscriptionPopUpOperationMode } = useSelector(
    (state) => state.subscriptionOperation
  );

  return (
    <div className="flex  gap-[36px] w-full">
      {subscriptionPopUpOperationMode === "new-subscription" ? (
        <>
          <div className="w-[45%]">
            <Elements stripe={stripePromise} options={options}>
              <AddCardDetails
                subscriptionCoupon={discountDetails}
                setIsLoading={setIsLoading}
                submitted={proceedButtonClicked}
                {...{
                  selectedBasePackageId,
                  selectedProducts,
                  productPrices,
                  additionalTutorNos,
                  selectedPlanType,
                  setIsPaymentSuccessfull,
                }}
              />
            </Elements>
          </div>
          <VerticalThickLine />
          <div className="w-[45%]">
            <ApplyCouponContainer
              subscriptionCoupon={discountDetails}
              setSubscriptionCoupon={setDiscountDetails}
              discountAmount={discountAmount}
              setDiscountAmount={setDiscountAmount}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      {subscriptionPopUpOperationMode === "update-subscription" || subscriptionPopUpOperationMode === "update-from-free"  ? (
        <div className="max-h-[428px] w-full flex gap-[36px] items-start justify-center overflow-y-auto">
          <div className="w-[50%] ">
            <Elements stripe={stripePromise} options={options}>
              <PaymentMethodContainer
                paymentMethods={paymentMethods}
                defaultPaymentMethodId={defaultPaymentMethodId}
                discountDetails={discountDetails}
                setDiscountDetails={setDiscountDetails}
                discountAmount={discountAmount}
                setDiscountAmount={setDiscountAmount}
                _addNewCard={_addNewCard}
                stripeCustomerId={stripeCustomerId}
              />
            </Elements>
          </div>
          <div className="h-full w-[1px] bg-secondary-20 sticky top-[0px]">
            &nbsp;
          </div>
          <div className="sticky top-[0px] w-[50%] max-h-[424px] overflow-y-scroll flex flex-col items-start justify-start gap-[12px] pr-[12px]">
            {summaryObject?.map((obj, idx) => (
              <>
                <SummarySection {...obj} />
                {idx < summaryObject.length - 1 ? <HorizontalDivider /> : <></>}
              </>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReviewAndPayContainer;
