export default function VerticalThickLine(props) {
  return (
    <svg
      width="1"
      height="244"
      {...props}
      viewBox="0 0 1 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="0.5" y1="2.18557e-08" x2="0.499989" y2="244" stroke="#CACEDA" />
    </svg>
  );
}
