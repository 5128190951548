import { Cancel, ExpandMore } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import ClockIconStatus from "../../../assets/calendarV2/ClockIconStatus";
import CrossIcon from "../../../assets/calendarV2/CrossIcon";
import GreenTickIconStatus from "../../../assets/calendarV2/GreenTickIconStatus";
import NoShowIconStatus from "../../../assets/calendarV2/NoShowIconStatus";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import frozenEventStatus from "../../../utils/eventStatus";
import frozenEventTypes from "../../../utils/eventTypes";
import CalendarFilterPopup from "../../CalendarFilterPopup/CalendarFilterPopup";
import FilterInputController from "../../CalendarV2/Sidebar/FilterInputController/FilterInputController";
import DateRangeButton from "../../DateRangeButton/DateRangeButton";
import DateRangeSelector from "../../DateRangeSelector/DateRangeSelector";
import DownloadIcon from "../../DownloadIcon/DownloadIcon";
import FilterIcon from "../../FilterIcon/FilterIcon";
import RoundedDropdown from "../../RoundedDropdown/RoundedDropdown";
import VerticalDivider from "../../VerticalDivider/VerticalDivider";
import styles from "./SectionOne.module.css";
import { handleFilters } from "../../../utils/filterService";
import { useDispatch, useSelector } from "react-redux";
import {
  setEndDateSelector,
  setStartDateSelector,
} from "../../../app/slices/dateRangeSlice";
import {
  setDateEventFilteredData,
  setEventFilteredData,
} from "../../../app/slices/eventSlice";
import { transformEvents } from "../../../utils/eventUtils";

export const sessionStatusMapping = {
  scheduled: ["scheduled", "SCH", "Scheduled", "SCHEDULED", "sch"].map((val) =>
    val.toLowerCase()
  ),
  completed: ["COMPLETED", "completed"].map((val) => val.toLowerCase()),
  noShow: ["NOSHOW", "no-show", "noshow", "noShow", "Noshow"].map((val) =>
    val.toLowerCase()
  ),
  cancelled: ["CANCEL", "cancel", "cancelled", "canceled"].map((val) =>
    val.toLowerCase()
  ),
};

const DUMMY_DATA = [
  {
    status: frozenEventStatus.SCH.status,
    totalSessions: 166,
    totalHours: 114,
    auxiliaryInformation: "+12%",
    comparisonText: "vs previous 30 days",
    auxiliaryInformationType: "incr",
  },
  {
    status: frozenEventStatus.COMPLETED.status,
    totalSessions: 166,
    totalHours: 114,
    auxiliaryInformation: "+12%",
    comparisonText: "vs previous 30 days",
    auxiliaryInformationType: "incr",
  },
  {
    status: frozenEventStatus.NOSHOW.status,
    totalSessions: 166,
    totalHours: 114,
    auxiliaryInformation: "+12%",
    comparisonText: "vs previous 30 days",
    auxiliaryInformationType: "decr",
  },
  {
    status: frozenEventStatus.CANCEL.status,
    totalSessions: 166,
    totalHours: 114,
    auxiliaryInformation: "+12%",
    comparisonText: "vs previous 30 days",
    auxiliaryInformationType: "incr",
  },
];

const statusDisplayMapping = {
  [frozenEventStatus.SCH.status]: {
    icon: <ClockIconStatus />,
    text: frozenEventStatus.SCH.displayValue,
  },
  [frozenEventStatus.COMPLETED.status]: {
    icon: <GreenTickIconStatus />,
    text: frozenEventStatus.COMPLETED.displayValue,
  },
  [frozenEventStatus.NOSHOW.status]: {
    icon: <NoShowIconStatus />,
    text: frozenEventStatus.NOSHOW.displayValue,
  },
  [frozenEventStatus.CANCEL.status]: {
    icon: <CrossIcon color={"#7C859C"} />,
    text: frozenEventStatus.CANCEL.displayValue,
  },
};

function filterFunction(val, searchString) {
  const tempArr = [val.firstName.toLowerCase(), val.lastName.toLowerCase()];
  return (
    val.firstName.toLowerCase().includes(searchString?.toLowerCase()) ||
    val.lastName.toLowerCase().includes(searchString.toLowerCase()) ||
    tempArr.join(" ").includes(searchString.toLowerCase()) ||
    tempArr.reverse().join(" ").includes(searchString.toLowerCase())
  );
}

function AnalyticsCard({
  //   status,
  //   totalSessions,
  //   totalHours,
  //   auxiliaryInformation,
  //   comparisonText,
  //   auxiliaryInformationType,
  customStyle,
  filteredData,
  data,
  targetStatus,
}) {
  const tempStatus = targetStatus.includes("cancel")
    ? "CANCEL"
    : targetStatus.includes("scheduled")
    ? "SCH"
    : targetStatus.includes("noshow")
    ? "NOSHOW"
    : targetStatus.includes("completed")
    ? "COMPLETED"
    : "";

  const sessionStatus = frozenEventStatus[tempStatus]?.status;
  console.log("SSS Tatus", sessionStatus, targetStatus);
  console.log("analytics card", data);
  console.log("analytics filteredData", filteredData);
  function convertTo24HourFormat(time, timeType) {
    let [hours, minutes] = time.split(":").map(Number);

    if (timeType === "PM" && hours < 12) {
      hours += 12;
    } else if (timeType === "AM" && hours === 12) {
      hours = 0;
    }

    return { hours, minutes };
  }

  function calculateSessionDuration(startTime, startType, endTime, endType) {
    const start = convertTo24HourFormat(startTime, startType);
    const end = convertTo24HourFormat(endTime, endType);

    const startInMinutes = start.hours * 60 + start.minutes;
    const endInMinutes = end.hours * 60 + end.minutes;

    let durationInMinutes = endInMinutes - startInMinutes;

    if (durationInMinutes < 0) {
      durationInMinutes += 24 * 60;
    }

    return durationInMinutes / 60;
  }

  function calculateTotalHours(filteredData) {
    console.log("TSS", targetStatus);
    console.log("TSS", filteredData);
    return filteredData
      .filter((session) =>
        targetStatus.includes(session.extendedProps.status?.toLowerCase())
      )
      .reduce((totalHours, session) => {
        if (
          !session.extendedProps.time.start ||
          !session.extendedProps.time.end
        )
          return totalHours + 0;
        const { start, end } = session.extendedProps.time;
        const sessionHours = calculateSessionDuration(
          start.time,
          start.timeType,
          end.time,
          end.timeType
        );
        return totalHours + sessionHours;
      }, 0);
  }
  const calculateHours = (start, end) => {
    if (!start || !end) return 0;
    const to24HourTime = (time, timeType) => {
      const [hours, minutes] = time.split(":").map(Number);
      if (timeType === "PM" && hours !== 12) return hours + 12;
      if (timeType === "AM" && hours === 12) return 0;
      return hours;
    };

    // Convert start and end times to 24-hour format
    const start24Hour = to24HourTime(start.time, start.timeType);
    const end24Hour = to24HourTime(end.time, end.timeType);

    // Calculate difference in hours
    const startDate = new Date();
    startDate.setHours(start24Hour, start.time.split(":")[1]);
    const endDate = new Date();
    endDate.setHours(end24Hour, end.time.split(":")[1]);

    // Handle end time being on the next day
    if (endDate < startDate) endDate.setDate(endDate.getDate() + 1);

    const hoursDifference = (endDate - startDate) / 1000 / 60 / 60;
    return hoursDifference;
  };

  const hoursForSessions = filteredData.map((session) =>
    calculateHours(
      session.extendedProps.time.start,
      session.extendedProps.time.end
    )
  );

  // Calculate total hours
  const totalHours = calculateTotalHours(filteredData);

  console.log("hoursForSessions:", hoursForSessions); // Output: [1, 1]
  console.log("Total hours:", calculateTotalHours(filteredData));

  return (
    <div className={styles.analyticsCard} style={customStyle}>
      <div className={styles.indicator}>
        {statusDisplayMapping[sessionStatus]?.icon}
        <span className={styles.heading}>
          {statusDisplayMapping[sessionStatus]?.text}
        </span>
      </div>
      <div className={styles.qty}>
        {
          filteredData.filter((session) =>
            targetStatus.includes(session.extendedProps.status?.toLowerCase())
          ).length
        }{" "}
        Sessions
      </div>
      <div
        style={{
          minHeight: "4px",
          minWidth: "100%",
          gridRow: "2/3",
          gridColumn: "-1/1",
        }}
      />
      <div className={styles.hoursIndicator}>
        <h1>{totalHours}</h1>
        <span>hours</span>
      </div>
      {/* <div className={styles.compContainer}>
        <span className={styles[auxiliaryInformationType]}>
          {auxiliaryInformation}
        </span>
        <p>{comparisonText}</p>
      </div> */}
    </div>
  );
}

function ProfileSelectContainer({
  _id,
  name,
  firstName,
  lastName,
  email,
  bgColor,
  profile,
  isSelected,
  onClick,
}) {
  return (
    <div
      className="w-full flex items-center justify-start cursor-pointer gap-[8.5px] p-[4px] px-[8px] hover:bg-secondary-5"
      onClick={() => {
        onClick(_id);
      }}
    >
      <input
        type="checkbox"
        className="cursor-pointer h-[15px] w-[15px]"
        checked={isSelected}
        style={{ display: "inline" }}
      />
      <div className="flex items-center justify-start gap-[6px] pointer-events-none">
        {profile ? (
          <img
            src={profile}
            alt={`Profile image of ${name}`}
            className="h-[15px] w-[15px] rounded-full object-cover"
          />
        ) : (
          <div
            className="flex items-center justify-center min-w-[24px] min-h-[24px] rounded-full text-white font-[600]"
            style={{ backgroundColor: bgColor }}
          >
            {name[0]}
          </div>
        )}
        <div className="flex flex-col items-start justify-start gap-[2px]">
          <h5 className="font-[lexend] font-[400] text-[12px] leading-[12px] text-secondary-80">
            {name}
          </h5>
          <p className="font-[lexend] font-[400] text-[10px] leading-[10px] text-secondary-60">
            {email}
          </p>
        </div>
      </div>
    </div>
  );
}

function SectionOne(props) {
  const {
    selectedEventKey,
    setSelectedEventKey,
    studentList,
    groupList,
    tutorList,
    selectedStudentIds,
    setSelectedStudentIds,
    selectedTutorIds,
    setSelectedTutorIds,
    selectedStatusKeys,
    setSelectedStatusKeys,
    showFilters,
    setShowFilters,
    filterRef,
    filterRefParent,
  } = props;
  const [isDRSOpen, setIsDRSOpen] = useState(false);
  const refParentDRS = useRef(null);
  const refDRS = useRef(null);
  const [isSessionDDOpen, setIsSessionDDOpen] = useState(false);
  const dispatch = useDispatch();

	const user = useSelector(state => state.user)


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [studentSearchText, setStudentSearchText] = useState("");
  const [tutorSearchText, setTutorSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataDuplicate, setFilteredDataDuplicate] = useState([]);

  function onSessionClick(key) {
    setSelectedEventKey(key);
  }

  const twStyles = {
    dropdownButton:
      "flex items-center justify-start gap-[6px] font-[lexend] text-[14px] leading-[18px] text-secondary-80 py-[7px] px-[8px] hover:bg-secondary-5 w-full",
  };

  function onStudentClick(_id) {
    setSelectedStudentIds((prev) =>
      prev.includes(_id) ? prev.filter((id) => id !== _id) : [...prev, _id]
    );
  }

  function onTutorClick(_id) {
    setSelectedTutorIds((prev) =>
      prev.includes(_id) ? prev.filter((id) => id !== _id) : [...prev, _id]
    );
  }

  useOutsideAlerter(
    refDRS,
    () => {
      setIsDRSOpen(false);
    },
    refParentDRS
  );

  // useEffect(() => {
  //   const sessionStatusMapping = {
  //     scheduled: "SCH",
  //     completed: "COMPLETED",
  //     noShow: "NOSHOW",
  //     cancelled: "CANCEL",
  //   };
  //   const formattedFilters = {};
  //   handleFilters(formattedFilters)
  //     .then((data) => {
  //       console.log("Filtered data scetion one:", data);
  //       const eventFilteredData = data.data.allEvents.filter((session) =>
  //         selectedStatusKeys.includes(
  //           Object.keys(sessionStatusMapping).find(
  //             (key) => sessionStatusMapping[key] === session.sessionStatus
  //           )
  //         )
  //       );
  //       setFilteredData(eventFilteredData);
  //       const uniqueSessionStatuses = new Set();
  //       const uniqueFilteredData = eventFilteredData.filter((session) => {
  //         const isUnique = !uniqueSessionStatuses.has(session.sessionStatus);
  //         uniqueSessionStatuses.add(session.sessionStatus);
  //         return isUnique;
  //       });
  //       setFilteredDataDuplicate(uniqueFilteredData);
  //       console.log("Filtered data after status filtering:", eventFilteredData);
  //       console.log("duplicateData", uniqueFilteredData);
  //     })

  //     .catch((error) => {
  //       console.error("Error fetching filtered data:", error);
  //     });
  // }, [selectedStatusKeys]);

  useEffect(() => {
    const sessionStatusMapping = {
      scheduled: ["scheduled", "SCH", "Scheduled", "SCHEDULED", "sch"].map(
        (val) => val.toLowerCase()
      ),
      completed: ["COMPLETED", "completed"].map((val) => val.toLowerCase()),
      noShow: ["NOSHOW", "no-show", "noshow", "noShow", "Noshow"].map((val) =>
        val.toLowerCase()
      ),
      cancelled: ["CANCEL", "cancel", "cancelled", "canceled"].map((val) =>
        val.toLowerCase()
      ),
    };
	
    const formattedFilters = {};
		let requiredUserIds = []
		if(user && ["tutor","parent","student"].includes(user.role)){
			if(user.role === "parent"){
				requiredUserIds = [...user.associatedStudents]		
			}
			requiredUserIds.push(user.id)
		}
    handleFilters(formattedFilters, requiredUserIds)
      .then((data) => {
        // console.log("Filtered data section one:", data);

        const effectiveStatusKeys =
          selectedStatusKeys.length > 0
            ? selectedStatusKeys
			: Object.keys(sessionStatusMapping);


        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        const isSameDateRange =
          startDateObj.toDateString() === today.toDateString() &&
          endDateObj.toDateString() === today.toDateString();
        console.log("ALLEV L", transformEvents(data.data.allEvents).length);
        const eventFilteredData = transformEvents(data.data.allEvents).filter(
          (session) => {
            console.log("ALLEV", session);
            const isStatusValid = effectiveStatusKeys.includes(
              Object.keys(sessionStatusMapping).find((key) =>
                sessionStatusMapping[key].includes(
                  session.extendedProps.status.toLowerCase()
                )
              )
            );

            const sessionDate = new Date(session.extendedProps.date);

            const isDateValid =
              isSameDateRange ||
              (sessionDate >= startDateObj && sessionDate <= endDateObj);
            return (
              isStatusValid &&
              isDateValid &&
              session.extendedProps.eventType.toLowerCase() ===
                frozenEventTypes[selectedEventKey].eventType.toLowerCase()
            );
          }
        );

        console.log("eventFilteredData", eventFilteredData.length);
        setFilteredData(eventFilteredData);
        dispatch(setEventFilteredData(eventFilteredData));

        const uniqueSessionStatuses = new Set();
        const uniqueFilteredData = eventFilteredData.filter((session) => {
          const statusKey = Object.keys(sessionStatusMapping).find((key) =>
            sessionStatusMapping[key].includes(
              session.extendedProps.status.toLowerCase()
            )
          );

          const isValidSession = !!statusKey;
          const isUnique =
            isValidSession && !uniqueSessionStatuses.has(statusKey);

          if (isUnique) {
            uniqueSessionStatuses.add(statusKey);
          }

          return isUnique;
        });

        setFilteredDataDuplicate(uniqueFilteredData);

        console.log("Filtered data after status filtering:", eventFilteredData);
        console.log("duplicateData", uniqueFilteredData);

        dispatch(setStartDateSelector(startDate.toISOString()));
        dispatch(setEndDateSelector(endDate.toISOString()));
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });
  }, [selectedStatusKeys, startDate, endDate, selectedEventKey]);

  console.log("EDstatskeys", Object.keys(sessionStatusMapping).filter(key=> selectedStatusKeys.map(val=>val.toLowerCase()).includes(key.toLowerCase())).map(val=>sessionStatusMapping[val])	)
  console.log("FDD", filteredDataDuplicate)
  return (
    <div className={styles.container}>
      <div className={styles.row1}>
        <div className={styles.inner}>
          <div className="">
            <RoundedDropdown
              type="without-count"
              defaultLabelText={"Event Type"}
              selectedValue={frozenEventTypes[selectedEventKey].displayValue}
              extDropdownTrigger={isSessionDDOpen}
              setExtDropdownTrigger={setIsSessionDDOpen}
            >
              {Object.keys(frozenEventTypes).filter(key => key !== "OFFCHOURS").map((key, idx) => (
                <button
                  className={`${twStyles.dropdownButton} ${
                    key === selectedEventKey ? "!text-secondary" : ""
                  }`}
                  onClick={(e) => {
                    onSessionClick(key);
                    setIsSessionDDOpen(false);
                  }}
                >
                  {frozenEventTypes[key].displayValue}
                </button>
              ))}
            </RoundedDropdown>
          </div>
          <VerticalDivider />
          <div className="w-240px relative">
            <DateRangeButton
              startDate={new Date(startDate)}
              endDate={new Date(endDate)}
              onClick={() => {
                setIsDRSOpen((prev) => !prev);
              }}
              ref={refParentDRS}
              buttonStyle={{ height: "36px" }}
            />
            {isDRSOpen && (
              <DateRangeSelector
                startDate={new Date(startDate)}
                endDate={new Date(endDate)}
                setStartDate={(val) => setStartDate(val)}
                setEndDate={(val) => setEndDate(val)}
                ref={refDRS}
              />
            )}
          </div>
          <div className="w-[198px] relative">
            <FilterInputController
              title={"All Filters"}
              auxTitle={"applied"}
              buttonIcons={{
                openIcon: (
                  <ExpandMore style={{ height: "16px", width: "16px" }} />
                ),
                closeIcon: <Cancel style={{ height: "16px", width: "16px" }} />,
              }}
              FieldIcon={(props) => <FilterIcon activeIcon={true} />}
              externalTrigger={showFilters}
              onClick={(e) => setShowFilters((prev) => !prev)}
              containerStyles={{
                borderColor: "rgba(233, 235, 239, 1)",
                height: "36px",
              }}
              ref={filterRefParent}
            />
            {showFilters && (
              <CalendarFilterPopup
                {...{ showFilters, setShowFilters }}
                {...props}
                ref={filterRef}
              />
            )}
          </div>
          <RoundedDropdown
            type={
              selectedStatusKeys.length === 0 ? "without-count" : "with-count"
            }
            defaultLabelText={"Event Status	"}
            count={selectedStatusKeys.length}
          >
            {Object.entries(statusDisplayMapping).map(([key, val], idx) => (
              <button
                className={`${twStyles.dropdownButton}`}
                onClick={() =>
                  setSelectedStatusKeys((prev) =>
                    prev.includes(key)
                      ? prev.filter((_key) => _key !== key)
                      : [...prev, key]
                  )
                }
              >
                <input
                  type="checkbox"
                  checked={selectedStatusKeys.includes(key)}
                  style={{ display: "inline" }}
                />
                <span className={styles.normalSvg}>{val.icon}</span>
                <span>{val.text}</span>
              </button>
            ))}
          </RoundedDropdown>
          <RoundedDropdown
            type={
              selectedStudentIds.length === 0 ? "without-count" : "with-count"
            }
            defaultLabelText={"Student"}
            count={selectedStudentIds.length}
            isSearchable={true}
            setFilterText={setStudentSearchText}
            filterText={studentSearchText}
          >
            {studentList
              .filter((val, idx) => filterFunction(val, studentSearchText))
              .map((val, idx) => (
                <ProfileSelectContainer
                  {...val}
                  isSelected={selectedStudentIds.includes(val._id)}
                  onClick={onStudentClick}
                />
              ))}
          </RoundedDropdown>
          <RoundedDropdown
            type={
              selectedTutorIds.length === 0 ? "without-count" : "with-count"
            }
            defaultLabelText={"Tutor"}
            count={selectedTutorIds.length}
            isSearchable={true}
            setFilterText={setTutorSearchText}
            filterText={tutorSearchText}
          >
            {tutorList
              .filter((val, idx) => filterFunction(val, tutorSearchText))
              .map((val, idx) => (
                <ProfileSelectContainer
                  {...val}
                  isSelected={selectedTutorIds.includes(val._id)}
                  onClick={onTutorClick}
                />
              ))}
          </RoundedDropdown>
        </div>
        <button className={styles.downloadButton}>
          <DownloadIcon />
          <span className={styles.content}>Download</span>
        </button>
      </div>
      <div className={styles.row2}>
        {filteredDataDuplicate.filter(val => selectedStatusKeys.length === 0 ? true : selectedStatusKeys.map(val=>val.toLowerCase()).includes(val.extendedProps.status.toLowerCase())).map((data, idx) =>
          frozenEventTypes[selectedEventKey].eventType !==
            frozenEventTypes.SESSION.eventType && idx > 0 ? (
            <></>
          ) : (
            <AnalyticsCard
              {...data}
              data={data}
              filteredData={filteredData}
			  targetStatus={selectedStatusKeys.length === 0 ?  
									Object.entries(sessionStatusMapping)[idx][1]
									: 
									Object.keys(sessionStatusMapping).filter(key=> selectedStatusKeys.map(val=>val.toLowerCase()).includes(key.toLowerCase())).map(val=>sessionStatusMapping[val])[idx]
								}
              {...{
                ...(idx === 0 &&
                  frozenEventTypes[selectedEventKey].eventType !==
                    frozenEventTypes.SESSION.eventType && {
                    customStyle: { width: "calc(25vw - 16px)" },
                  }),
              }}
            />
          )
        )}
      </div>
    </div>
  );
}
//Object.entries(sessionStatusMapping).filter(([key])=>selectedStatusKeys.map(val=>val.toLowerCase()).includes(key.toLowerCase()))[idx][1]
export default SectionOne;
