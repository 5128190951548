import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { triggerToast } from "../../../../utils/toastController";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import CustomDropDown from "./components/CustomDropDown";
import { updateOrganization } from "../../../../app/slices/organization";
import { useCheckCompanyNameUniqueMutation } from "../../../../app/services/organization";

const companyTypes = [
  "Sole proprietorship",
  "Partnership",
  "Limited liability company (LLC)",
  "C Corporation",
  "S Corporation",
  "B Corporation",
  "Close corporation",
  "Nonprofit corporation",
  "Cooperative",
  "Private Limited Company",
  "Public",
  "Other",
];

const CompanyInfoPopUp = ({ open, setOpen, updateCompany, subscriptionV2 }) => {
  const dispatch = useDispatch();
  const {
    company,
    supportEmail,
    website,
    companyType,
    privacyPolicy,
    termsAndConditions,
  } = useSelector((state) => state.organization?.organization);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    company: company ? company : null,
    supportEmail: supportEmail ? supportEmail : null,
    website: website ? website : null,
    companyType: companyType ? companyType : null,
    privacyPolicy: privacyPolicy ? privacyPolicy : null,
    termsAndConditions: termsAndConditions ? termsAndConditions : null,
  });
  const [checkCompanyName, checkCompanyNameStatus] =
    useCheckCompanyNameUniqueMutation();

  const checkCompanyNameUnique = async () => {
    if (company !== values?.company.trim()) {
      try {
        let data = {
          company: values.company.trim(),
        };
        let res = await checkCompanyName(data);
        console.log(res);
        if (res?.error) {
          triggerToast(<div>{res.error.data.message}</div>, "error", {
            autoClose: 3000,
          });
          setLoading(false);
          return;
        } else if (res?.data) {
          return true;
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      return true;
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      let nameUnique = await checkCompanyNameUnique();
      if (nameUnique) {
        const res = await updateCompany({
          ...values,
          company: values.company.trim(),
          onSaveButtonClicked: true,
        });

        if (res?.error) {
          triggerToast(<div>{res.error.message}</div>, "error", {
            autoClose: 3000,
          });
          setLoading(false);
        } else if (res?.data) {
          triggerToast(
            <div>Company Info Updated Successfully!</div>,
            "success",
            {
              autoClose: 2000,
            }
          );
          dispatch(updateOrganization(res.data.orgDetails));
          setLoading(false);
          setOpen(false);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[90%] md:w-[65%] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Company Info</div>
        <button onClick={(e) => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>

      <div className="grid gap-[24px]  w-full">
        <div className="grid md:flex w-full  gap-[24px]">
          <div className="md:basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="company_name"
            >
              Company Name*
            </label>
            <input
              className="px-[16px] py-[14px] rounded-[6px] border border-[#BEC2CE] focus:border-blue-70 w-full outline-none"
              type="text"
              placeholder="Company Name"
              id="company_Name"
              value={values.company}
              onChange={(e) => {
                setValues((values) => ({
                  ...values,
                  company: e.target.value,
                }));
              }}
            ></input>
          </div>
          <div className="md:basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="company_type"
            >
              Company Type
            </label>
            <CustomDropDown
              list={companyTypes}
              placeholder={"Company Type"}
              listIsArray={true}
              selectedValue={values.companyType}
              setSelectedValue={(val) => {
                setValues((prev) => ({
                  ...prev,
                  companyType: val,
                }));
              }}
            />
          </div>
        </div>

        <div className="grid md:flex w-full gap-[24px]">
          <div className="md:basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="website_link"
            >
              Website
            </label>
            <input
              className={`${
                subscriptionV2?.subscription?.basePackage?.name.includes("Free")
                  ? "pointer-events-none"
                  : ""
              } px-[16px] py-[14px] rounded-[6px] border border-[#BEC2CE] focus:border-blue-70 w-full outline-none`}
              type="text"
              placeholder="Website Link"
              id="website_link"
              value={values.website}
              onChange={(e) => {
                setValues((values) => ({
                  ...values,
                  website: e.target.value,
                }));
              }}
            ></input>
          </div>
          <div className="md:basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="support_email"
            >
              Support Email
            </label>
            <input
              className="px-[16px] py-[14px] rounded-[6px] border border-[#BEC2CE] focus:border-blue-70 w-full outline-none"
              type="text"
              placeholder="support@email.com"
              id="support_email"
              value={values.supportEmail}
              onChange={(e) => {
                setValues((values) => ({
                  ...values,
                  supportEmail: e.target.value,
                }));
              }}
            ></input>
          </div>
        </div>

        <div className="grid md:flex w-full gap-[24px]">
          <div className="md:basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="termsAndCondition_link"
            >
              Terms & Conditions (URL)
            </label>
            <input
              className="px-[16px] py-[14px] rounded-[6px] border border-[#BEC2CE] focus:border-blue-70 w-full outline-none"
              type="email"
              placeholder="Terms & Conditions Page Link"
              id="termsAndCondition_link"
              value={values.termsAndConditions}
              onChange={(e) => {
                setValues((values) => ({
                  ...values,
                  termsAndConditions: e.target.value,
                }));
              }}
            ></input>
          </div>
          <div className="md:basis-1/2">
            <label
              className="float-left font-medium text-sm leading-[17.5px] text-secondary pb-1"
              htmlFor="privacy_policy"
            >
              Privacy Policy
            </label>
            <input
              className="px-[16px] py-[14px] rounded-[6px] border border-[#BEC2CE] focus:border-blue-70 w-full outline-none"
              type="text"
              placeholder="Privacy Policy Link"
              id="privacy_policy"
              value={values.privacyPolicy}
              onChange={(e) => {
                setValues((values) => ({
                  ...values,
                  privacyPolicy: e.target.value,
                }));
              }}
            ></input>
          </div>
        </div>
      </div>

      <div className="flex gap-[12px] md:justify-end">
        <div
          className="text-secondary-60 basis-1/2 md:basis-1/5 text-center border-[2px] border-secondary-60 px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] cursor-pointer justify-center"
          onClick={(e) => setOpen(false)}
        >
          Cancel
        </div>
        <div
          className={` ${
            !values.company.trim()
              ? "bg-blue-70/40 text-white/40 cursor-not-allowed"
              : "bg-blue-70 text-white  cursor-pointer "
          } basis-1/2 md:basis-1/5  cursor-pointer  text-center  px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
          onClick={() => {
            if (!values.company.trim()) {
              return;
            }
            handleSave();
          }}
        >
          {loading ? <LoaderIcon /> : "Save"}
        </div>
      </div>
    </PopupContainer>
  );
};

export default CompanyInfoPopUp;
