import React, { useState, useEffect } from "react";
import StarIcon from "../../assets/calendarV2/StarIcon";
import {
  fetchSessionRating,
  createSessionRating,
  updateSessionRating,
} from "../../utils/eventService";
import { triggerToast } from "../../utils/toastController";

const SessionRatingEventCard = (props) => {
  const [sessionRating, setSessionRating] = useState({
    mode: "create",
    rating: null,
  });
  const [studentFeedback, setStudentFeedback] = useState(null);
  const [hover, setHover] = useState(null);
  const [totalStars, setTotalStars] = useState(5);

  const getSessionRating = async () => {
    try {
      const res = await fetchSessionRating(props._id);
      console.log(res);
      if (res.data && res.data.feedback) {
        setStudentFeedback(res.data.feedback);
        setSessionRating({
          mode: "edit", // Set mode to edit if feedback exists
          rating: res.data.feedback.rating,
        });
      } 
    } catch (error) {
      console.error("Error fetching session rating:", error);
    }
  };

  const handleSetRating = (currentRating) => {
    if (!studentFeedback) {
      // No feedback exists, create new
      setSessionRating({
        mode: "create",
        rating: currentRating,
      });
      handleStudentSessionFeedback(currentRating);
    } else if (studentFeedback) {
      // Feedback exists, update only if the rating is different
      if (currentRating !== studentFeedback.rating) {
        setSessionRating({
          mode: "edit",
          rating: currentRating,
        });
        handleStudentSessionFeedback(currentRating);
      }
    }
  };

  const createStudentSessionFeedback = async (currentRating) => {
    try {
      console.log("in create feedback");
      const res = await createSessionRating({
        tutorId: props.tutorId,
        sessionId: props._id,
        rating: currentRating,
      });
      console.log(res);
      triggerToast("Session feedback successful!", "success", {
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error creating session rating:", error);
    }
  };

  const updateStudentSessionFeedback = async (currentRating) => {
    try {
      console.log("in update feedback");
      const res = await updateSessionRating({
        feedbackID: studentFeedback?._id,
        rating: currentRating,
      });
      console.log(res);
      triggerToast("Session feedback updated!", "success", {
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error updating session rating:", error);
    }
  };

  const handleStudentSessionFeedback = (currentRating) => {
    console.log("in feedback action");

    if (sessionRating.mode === "create") {
      createStudentSessionFeedback(currentRating);
    } else if (sessionRating.mode === "edit") {
      updateStudentSessionFeedback(currentRating);
    }
  };

  useEffect(() => {
    getSessionRating();
  }, [props._id]);

  return (
    <div className="flex gap-3">
      <div className="font-normal md:text-[12px] text-[10px] md:leading-[18px] leading-[15px] text-secondary-80">
        Rate This Session
      </div>
      <div className="flex items-center gap-1">
        {[...Array(totalStars)].map((star, idx) => {
          const currentRating = idx + 1;
          return (
            <div
              key={idx}
              onClick={() => handleSetRating(currentRating)}
              className={`${
                currentRating <= (hover || sessionRating.rating)
                  ? "text-[#F2CB00]"
                  : "text-[#E9EBEF]"
              } cursor-pointer`}
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
            >
              <StarIcon size={16} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SessionRatingEventCard;
