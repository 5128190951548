import React, { useEffect, useState } from "react";
import ProductSelector from "../ProductSelector/ProductSelector";
import CrmIcon from "../../ProductIcons/CrmIcon";
import CalendarIcon from "../../ProductIcons/CalendarIcon";
import AssignmentIcon from "../../ProductIcons/AssignmentIcon";
import CustomTestIcon from "../../ProductIcons/CustomTestIcon";
import HorizontalThickLine from "../../ProductIcons/HorizontalThickLine";

const ProductSelectionContainer = ({
  productPrices,
  selectedPlanType = 0,
  selectedPlanId,
  freeAnnualBasePackageId,
  selectedProducts,
  selectedFreeProducts,
  setSelectedProducts,
}) => {
  const [siMapping, setSiMapping] = useState(null);
  const [selectedBillingCycle, setSelectedBillingCycle] = useState(0);
  const [augmentedProducts, setAugmentedProducts] = useState(null);

  function getProductIcon(productName) {
    switch (true) {
      case productName.includes("CRM"):
        return <CrmIcon />;
      case productName.includes("Calendar"):
        return <CalendarIcon />;
      case productName.includes("Assignments"):
        return <AssignmentIcon />;
      case productName.includes("Custom"):
        return <CustomTestIcon />;
    }
  }

  let Products = [
    {
      name: "CRM",
      title: "Unlimited",
      priceList: null,
      //img: CRM,
      // selected: true,
      // setSelected: () => {
      //   console.log(">>CRM TRIGGERED");
      // },
      // selectedPrice: 0,
      // setSelectedPrice: () => {},
      color: "#2793cd",
    },
    {
      name: "Calendar",
      title: selectedPlanType === 0 ? "Sessions" : "Sessions",
      priceList: [
        { name: "1500 nos.- $30", value: 30 },
        { name: "1500 nos.- $30", value: 30 },
      ],
      // img: Calendar,
      // selected: selectedCalendar,
      // setSelected: setSelectedCalendar,
      // selectedPrice: selectedPriceCalendar,
      // setSelectedPrice: setSelectedPriceCalendar,
      color: "#EE778C",
    },
    {
      name: "Assignments",
      title: selectedPlanType === 0 ? "Credits" : "Credits",
      priceList: [{ name: "15 nos.- $0", value: 0 }],
      // img: Assignment,
      // selected: selectedAssignment,
      // setSelected: setSelectedAssignment,
      // selectedPrice: selectedPriceAssignment,
      // setSelectedPrice: setSelectedPriceAssignment,
      color: "#8D54D7",
    },
    {
      name: "Custom Tests",
      title: selectedPlanType === 0 ? "Custom Tests" : "Custom Tests",
      priceList: [{ name: "Unlimited - $8", value: 8 }],
      // img: CustomTest,
      // selected: selectedCustomTest,
      // setSelected: setSelectedCustomTest,
      // selectedPrice: selectedPriceCustomTest,
      // setSelectedPrice: setSelectedPriceCustomTest,
      color: "#17A289",
    },
  ];

  useEffect(() => {
    console.log("selected id", selectedPlanId);
    console.log("selected", selectedProducts);
    console.log("selected id free", freeAnnualBasePackageId);
    console.log("selected free", selectedFreeProducts);
    if (!productPrices) return;

    const tempProductPrices = [...productPrices];
    const requiredProductsMapping = {
      Calendar: { productName: "CalendarV2" },
      "Custom Tests": { productName: "CustomContentV2" },
      Assignments: { productName: "AssignmentsV2" },
    };
    console.log(">>>PRODUCT PRICES", productPrices);

    Products.forEach((product, _idx) => {
      if (_idx === 0) return;
      const requiredProduct = tempProductPrices.find(
        (_product, __idx) =>
          _product.product.name ===
          requiredProductsMapping[product.name].productName
      );
      const tempPriceList = {};
      for (const planType in requiredProduct.prices) {
        tempPriceList[planType] = [];
        const prices = requiredProduct.prices[planType];
        const tempPrices = [];
        prices.forEach((price) => {
          const tempPrice = {
            ...price,
            name: `${
              price.metadata.limit === "-1" ? "Unlimited" : price.metadata.limit
            } nos.- \$${Math.floor(price.unit_amount / 100)}`,
            value: price.id,
          };
          tempPrices.push(tempPrice);
        });
        tempPriceList[planType] = tempPrices;
      }
      let tempSelectedPlanType = null;
      if (selectedPlanId === freeAnnualBasePackageId) {
        tempSelectedPlanType = "annual";
      } else {
        tempSelectedPlanType = selectedPlanType === 0 ? "monthly" : "annual";
      }
      product.priceList = tempPriceList[tempSelectedPlanType].filter(
        (price, __idx) => !price.lookup_key.includes("PAYG")
      );
    });

    const tempProducts = [...Products];
    setAugmentedProducts([...tempProducts]);
  }, [productPrices, selectedPlanType, selectedPlanId]);

  return (
    <div className="w-full h-full grid gap-[16px]">
      <div className="grid gap-[12px]">
        <div className="flex justify-between items-center text-secondary font-[500] text-[16px]">
          {" "}
          <div className="w-[30%]">Product</div>
          <div className="w-[30%]">Quantity</div>
          <div className="w-[30%] mr-[5%]  text-right">Cost</div>
        </div>
        <HorizontalThickLine color={"#CACEDA"} />
        <div className="w-full grid gap-[12px] ">
          {augmentedProducts?.map((item, index) => (
            <ProductSelector
              product={item}
              key={index}
              idx={index}
              siMapping={siMapping}
              setSiMapping={setSelectedProducts}
              selectedPlanType={selectedPlanType}
              selectedProducts={
                selectedPlanId === freeAnnualBasePackageId
                  ? selectedFreeProducts
                  : selectedProducts
              }
              productIcon={getProductIcon(item.name)}
              selectionDisabled={selectedPlanId === freeAnnualBasePackageId}
            />
          ))}
        </div>
        <HorizontalThickLine color={"#E9EBEF"} />
      </div>
      <div className="text-secondary-80 leading-[21px] text-[14px]">
        <span className="font-[600]">Note: </span>For annual plans, product
        quantities shown above will be added cumulatively for the entire year.
      </div>
    </div>
  );
};

export default ProductSelectionContainer;
