export default function AttachmentIcon  (props)  {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_38301_151874)">
        <path
          d="M6.07212 11.4255C5.14576 12.3862 3.63714 12.4748 2.60472 11.6292C1.98986 11.0968 1.67579 10.2964 1.76456 9.48793C1.84144 8.89189 2.12002 8.3401 2.55396 7.92432L4.23195 6.24518C4.57359 5.90343 4.57359 5.34945 4.23195 5.0077C3.8902 4.66605 3.33622 4.66605 2.99447 5.0077L1.35209 6.65068C0.603439 7.37261 0.131315 8.33395 0.0177354 9.36774C-0.19441 11.6962 1.52116 13.7557 3.84959 13.9679C5.1 14.0818 6.3365 13.6348 7.22498 12.7476L8.97999 10.9932C9.32163 10.6515 9.32163 10.0975 8.97999 9.75574C8.63824 9.41409 8.08426 9.41409 7.74251 9.75574L6.07212 11.4255Z"
          fill="#7C560D"
        />
        <path
          d="M12.3439 0.855808C10.6016 -0.424284 8.18215 -0.223597 6.67457 1.32607L5.02283 2.97838C4.68119 3.32013 4.68119 3.87411 5.02283 4.21586C5.36458 4.55751 5.91856 4.55751 6.26031 4.21586L7.9383 2.53962C8.84443 1.60246 10.3105 1.49689 11.3415 2.29458C12.4027 3.16476 12.5576 4.73046 11.6874 5.79168C11.6365 5.85379 11.5826 5.91338 11.5259 5.97027L9.77087 7.72527C9.42923 8.06702 9.42923 8.621 9.77087 8.96275C10.1126 9.3044 10.6666 9.3044 11.0084 8.96275L12.7634 7.20775C14.4156 5.55024 14.4113 2.86718 12.7538 1.21495C12.625 1.08658 12.4881 0.966625 12.3439 0.855808Z"
          fill="#7C560D"
        />
        <path
          d="M5.02294 7.72672L7.7421 5.00755C8.08388 4.66578 8.63799 4.66578 8.97977 5.00755C9.32155 5.34933 9.32155 5.90345 8.97977 6.24523L6.26061 8.96439C5.91883 9.30617 5.36471 9.30617 5.02294 8.96439C4.68116 8.62261 4.68116 8.0685 5.02294 7.72672Z"
          fill="#7C560D"
        />
      </g>
      <defs>
        <clipPath id="clip0_38301_151874">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
