import React, { useEffect, useState } from "react";
import CalenderModal from "../CalendarModal/CalenderModal";
import DateTImezoneSelector from "./DateTImezoneComp/DateTImezoneSelector";
import MemberStudentSelector from "./MemberStudentComp/MemberStudentSelector"
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getAvailableUnavailableScheduleMapper } from "../../utils/eventService";


const ScheduleMapper = ({ setOperationMode ,setActive , submitData, setSubmitData, membersData, setMembersData, studentsData, setStudentsData, setIsScheduleTrigger, setIsActiveEventPopUp, user }) => {
  const [submitDisable, setSubmitDisable] = useState(true);
  const [scheduleDisable, setScheduleDisable] = useState(true);
  const [customPopup, setCustomPopup] = useState(null);
  const { organization } = useSelector((state) => state.organization);
  const[isSubmitLoading, setisSubmitLoading] = useState(false);
  
  useEffect(() => {
    if (organization?.settings) {
      setSubmitData((prev)=>({...prev, timeZone:organization.settings.timeZone}));
    }
  }, [organization]);

  useEffect(()=>{

    if(user && user?.userDefaults?.timeZone !== ""){

      setSubmitData((prev)=>({
        ...prev,
        timeZone :user?.userDefaults?.timeZone,
      }))
    }

  },[user])


  useEffect(()=>{
    if(submitData.startDate  && submitData.endDate  && submitData.startTime.trim() != ""  && submitData.endTime.trim() != "" && submitData.timeZone.trim() != "" ){
      setSubmitDisable(false);

      if((submitData.selectedMembers.length>0 && submitData.selectedStudents.length>0) || submitData.selectedMembers.length>1|| submitData.selectedStudents.length > 1 ) setScheduleDisable(false);
      else setScheduleDisable(true);
      
    }
    else{
      setSubmitDisable(true);
      setScheduleDisable(true);
    }

  },[submitData]);


  // Reset the customPopup to null after 2 seconds
  const customPopupMessage = (message)=>{
    setCustomPopup(message);
    setTimeout(() => {
      setCustomPopup(null);
    }, 2000);
  }

  const requestSubmit = async()=>{

    const sendingData ={
      startTime: submitData.startTime,
      endTime: submitData.endTime,
      startDate: submitData.startDate,
      endDate:submitData.endDate,
      timeZone:submitData.timeZone,
    }

    setisSubmitLoading(true);

    await getAvailableUnavailableScheduleMapper(sendingData).then((res)=>{  

      if(!res.data && !res.data.data){
        toast.error("Something went wrong !");
      }
      setStudentsData({available:res.data?.data?.availableStudents, unavailable:res.data?.data?.unavailableStudents});
      setMembersData({available:res.data?.data?.availableMembers, unavailable:res.data?.data?.unavailableMembers});

      }).catch((err)=>{
        toast.error(err);
      })

    setisSubmitLoading(false);  
  }



  return (
    <CalenderModal setActive={setActive} heading={"Schedule Mapper"}>
      
      {/* Children of calendar Modal */}

      <main className=" flex flex-col md:h-auto  flex-1 h-full w-full justify-between items-start gap-[24px] overflow-auto md:overflow-hidden   ">
       
        <div className="w-full flex-col  gap-[24px] flex  ">
          {/* Date time and timezone selector */}
          <DateTImezoneSelector submitDisable={submitDisable} submitData={submitData} setSubmitData={setSubmitData} requestTrigger={requestSubmit} isSubmitLoading={isSubmitLoading}  />

          {/* Team Members and Student Selection section */}
          <MemberStudentSelector submitData={submitData} setSubmitData={setSubmitData} customPopupMessage={customPopupMessage}  studentsData={studentsData} membersData={membersData}  />
        </div>

        {/* Submit button section */}
        <div className=" w-full sticky bottom-0 left-0 bg-white items-end flex flex-col justify-center gap-2  z-20 ">

          {customPopup && 
          <div className=" flex items-start justify-start gap-2  w-full md:w-[532px] break-words ">
            <p className="text-[12px]  leading-[18px] m-0 ">⚠️</p>
            <p className="text-[#B42318CC] text-[12px] leading-[18px] m-0 font-normal ">{customPopup}</p>
          </div> }

          <button
            disabled={scheduleDisable}
            onClick={()=> {setIsScheduleTrigger(true); setActive(false); setIsActiveEventPopUp(true); setOperationMode("create-scheduled")}}
            className={` w-full md:w-fit ${scheduleDisable ? "" : "hover:bg-[#0671E0] hover:text-white"} disabled:text-[#0671E033] disabled:border-[#0671E033] px-[20px] py-[10px] border-[1.6px] rounded-[6px]  border-[#0671E0] text-[#0671E0] text-[16px] leading-[20px] font-medium `}>
            Schedule Event 
          </button>
        </div>

      </main>
    </CalenderModal>
  );
};

export default ScheduleMapper;
