import axios from "axios"
import { getAuthHeader } from "../app/constants/constants"
import { triggerToast } from "./toastController"

import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

const colors = [
    "#0BA5DC",
    "#E8AB11",
    "#573030",
    "#183943",
    "#E81160",
    "#4937C7",
    "#094513",
    "#717577",
    "#82B915",
    "#099870",
];

async function addTimeOff(timeOffData) {
	try {
		const res = await axios({
			url : process.env.REACT_APP_BASE_URL + "api/users/v2/time-off",
			method : "POST",
			data : {
				timeOffData : timeOffData
			},
			headers : getAuthHeader()
		})
		return res
	}catch(err){
		console.error(err)
		triggerToast(err.response.data.message,"error")
		throw err
	}

}

async function fetchTimeOffData(userIds, setData, fetchingUser, organization){
	try {
		let params=""	
		if (userIds && userIds.length > 0){
			params = userIds.map(val => `userIds[]=${val}`).join("&")
		}
		const res = await axios({
			url : process.env.REACT_APP_BASE_URL + "api/users/v2/time-off?" + params,
			method : "GET",
			headers : getAuthHeader()
		})
		console.log(res.data.data.timeOffs)
		if(setData){
			const tempArr = []
			res.data.data.timeOffs.forEach((val,idx)=>{
				const user = {
					_id : val._id,
					firstName : val.firstName,
					lastName : val.lastName,
					email : val.email,
					photo : val.photo,
					awsLink : fetchingUser.awsLink
				}
				if(val.scheduleDetails){
					val.scheduleDetails.lockedSlots.forEach((_val, idx)=>{
						let startDateStr = dayjs(_val.startDate).tz(_val.timeZone).format("YYYY-MM-DD hh:mm A")
						let endDateStr = dayjs(_val.endDate).tz(_val.timeZone).format("YYYY-MM-DD hh:mm A")
						let recurDateStr = dayjs(_val.recurringEndDate).tz(_val.timeZone).format("YYYY-MM-DD hh:mm A")
						tempArr.push({
							start : dayjs(_val.startDate).tz(_val.timeZone).toISOString(),
							end : dayjs(_val.endDate).tz(_val.timeZone).toISOString(),
							user : user,
							display : "background",
							allDay : _val.isLockedFullDay,
							leave : {
								..._val,
								startDate : new Date(startDateStr),
								endDate : new Date(endDateStr),
								recurringEndDate : new Date(recurDateStr)
							}
						})
					})
				} 
			})
			console.log("TARR", tempArr)
			setData(tempArr)
		}
		return res

	}catch(err){
		console.error(err)
		triggerToast(err.response.data.message,"error")
		throw err
	}
}


async function deleteTimeOffInstance(orgId, userId, leaveId){

	try {
		const res = await axios({
			url : process.env.REACT_APP_BASE_URL + `api/users/v2/time-off/${orgId}/${userId}/${leaveId}` ,
			method : "DELETE",
			headers : getAuthHeader()
		})
	}catch(err){
		console.error(err)
		triggerToast(err.response.data.message,"error")
		throw err
	}

}

async function ediTimeOffInstance(leaveId, userId, updateData){

	try {
		const res = await axios({
			url : process.env.REACT_APP_BASE_URL + `api/users/v2/time-off` ,
			method : "PATCH",
			headers : getAuthHeader(),
			data : {
				userId : userId	,
				leaveId : leaveId,
				updateData : updateData
			}
		})
	}catch(err){
		console.error(err)
		triggerToast(err.response.data.message,"error")
		throw err
	}

}


export {
	addTimeOff,
	fetchTimeOffData,
	deleteTimeOffInstance,
	ediTimeOffInstance
}
