import styles from './ToggleButtonV2.module.css'

const ToggleButtonV2 = ({ isEnabled, setIsEnabled, customSwitch, customContainer }) => {

    return (
        <div className={`${styles.container} ${isEnabled ? styles.on : styles.off} ${customContainer}`} onClick={(e) => setIsEnabled(!isEnabled)}>
            <button className={`${styles.switch} ${customSwitch}`} ></button>
        </div>
    )
}

export default ToggleButtonV2