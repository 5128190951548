import React from "react";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TimeSelect from "../../../../components/TimeSelect/TimeSelect";

const iconStyle = {
  width: 20, height: 20, fontSize: 20 
};

const inputCheckboxStyling = { display: "block" };

const DayAvailability = ({
  day,
  availability,
  checked,
  onCheck,
  onAddSlot,
  onDeleteSlot,
  onTimeChange,
  onCopySlot,
  resetSlotsForDay,
}) => {
  // Handle checkbox change
  const handleCheckChange = () => {
    if (checked) {
      onCheck(day, false);
      resetSlotsForDay(day);
    } else {
      onCheck(day, true);
    }
  };

  // Disabling icons and controls based on checked status
  const isDisabled = !checked;

  return (
    <div className="flex flex-col w-full gap-[16px] md:px-[16px]">
      <div className="w-full flex justify-between gap-[30px] md:gap-[48px]">
        <div className=" w-[15%] md:w-[30%] flex items-center gap-[6px] md:gap-2">
          <input
            style={inputCheckboxStyling}
            id={`day_${day}`}
            type="checkbox"
            checked={checked}
            onChange={handleCheckChange}
            className="w-[15px] h-[15px] text-blue-70 ring-secondary-60 cursor-pointer"
          />
          <label
            className={`${
              isDisabled ? "opacity-50" : ""
            } hidden md:block text-secondary font-[400] text-[16px] leading-[24px]`}
            htmlFor={`day_${day}`}
          >
            {day}
          </label>
          <label
            className={`${
              isDisabled ? "opacity-50" : ""
            } md:hidden text-secondary font-[400] text-[16px] leading-[24px]`}
            htmlFor={`day_${day}`}
          >
            {day.substring(0, 3)}
          </label>
        </div>

        <div
          className={`flex  items-center justify-between gap-[6px] md:gap-[24px] w-[70%] md:w-[60%] ${
            isDisabled ? "pointer-events-none opacity-50" : ""
          }`}
        >
          <div className="w-[40%]">
            <TimeSelect
              value={availability[0]?.startTime || "10:00 AM"}
              onChange={(item) => onTimeChange(day, 0, "startTime", item)}
              disabled={isDisabled}
            />
          </div>
          <div>-</div>
          <div className="w-[40%]">
            <TimeSelect
              value={availability[0]?.endTime || "05:00 PM"}
              onChange={(item) => onTimeChange(day, 0, "endTime", item)}
              disabled={isDisabled}
            />
          </div>
        </div>

        <div className="w-[15%]  flex gap-[10px] md:gap-[8px] items-center">
          <AddIcon
            className={`cursor-pointer ${isDisabled ? "opacity-50" : ""}`}
            style={iconStyle}
            onClick={() => !isDisabled && onAddSlot(day)}
          />
          <ContentCopyIcon
            className={`cursor-pointer ${isDisabled ? "opacity-50" : ""}`}
            style={iconStyle}
            onClick={() => !isDisabled && onCopySlot(day)}
          />
        </div>
      </div>
      {availability.length > 1 && (
        <div className="w-full flex flex-col gap-[16px]">
          {availability.slice(1).map((slot, index) => (
            <div
              key={index + 1}
              className="w-full flex justify-between gap-[30px] md:gap-[48px]"
            >
              <div className=" w-[15%]  md:w-[30%] flex items-center gap-[6px] md:gap-2">
                <input
                  style={inputCheckboxStyling}
                  id={`day_${day}`}
                  type="checkbox"
                  checked={checked}
                  onChange={handleCheckChange}
                  className="invisible w-[20px] h-[20px] text-blue-70 ring-secondary-60 cursor-pointer"
                />{" "}
                <label
                  className="hidden md:inivisible text-secondary font-[400] text-[16px] leading-[24px]"
                  htmlFor={`day_${day}`}
                >
                  {day}
                </label>
                <label
                  className="invisible md:hidden text-secondary font-[400] text-[16px] leading-[24px]"
                  htmlFor={`day_${day}`}
                >
                  {day.substring(0, 3)}
                </label>
              </div>
              <div
                className={`flex  items-center justify-between gap-[6px] md:gap-[24px] w-[70%] md:w-[60%]`}
              >
                <div className="w-[40%]">
                  <TimeSelect
                    value={slot.startTime}
                    onChange={(item) =>
                      onTimeChange(day, index + 1, "startTime", item)
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div>-</div>
                <div className="w-[40%]">
                  <TimeSelect
                    value={slot.endTime}
                    onChange={(item) =>
                      onTimeChange(day, index + 1, "endTime", item)
                    }
                    disabled={isDisabled}
                  />
                </div>
              </div>
              <div className="w-[15%]   flex gap-[6px] md:gap-[10px] items-center">
                <DeleteOutlineOutlinedIcon
                  className={`cursor-pointer ${isDisabled ? "opacity-50" : ""}`}
                  style={iconStyle}
                  onClick={() => !isDisabled && onDeleteSlot(day, index + 1)}
                />
                <div className="invisible">
                  <DeleteOutlineOutlinedIcon
                    className={`cursor-pointer ${
                      isDisabled ? "opacity-50" : ""
                    }`}
                    style={iconStyle}
                    onClick={() => !isDisabled && onDeleteSlot(day, index + 1)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DayAvailability;
