import { PickersCalendarHeader } from "@mui/x-date-pickers";
import CustomMonthHeader from "../CustomMonthHeader/CustomMonthHeader";

export default function CustomHeader(props) {
  if (props.view === "day") {
    return <PickersCalendarHeader {...props} />;
  } else {
    return <CustomMonthHeader {...props} />;
  }
}
