import React from "react";
import EditPen from "../../../assets/icons/SettingsIcon/EditPen";
const InfoHeader = ({ heading, editable = false, onEditClick }) => {
  return (
    <div className="bg-secondary-5 flex gap-[48px] justify-between px-[16px] py-[8px] rounded-t-[12px]">
      <label className="text-[15px] font-[500] leading-[22.5px]">
        {heading}
      </label>
      {editable && (
        <div
          className=" text-blue-70 bg-white cursor-pointer flex justify-center items-center rounded-full h-6 w-6"
          onClick={(e) => onEditClick()}
        >
          <EditPen />
        </div>
      )}
    </div>
  );
};

export default InfoHeader;
