import React from 'react'

const ManageIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29123_7585)">
                <path d="M15.3333 13.1667H13.558C13.2693 12.206 12.3867 11.5 11.3333 11.5C10.28 11.5 9.398 12.206 9.10869 13.1667H0.666656C0.298656 13.1667 0 13.4653 0 13.8333C0 14.2013 0.298656 14.5 0.666656 14.5H9.10866C9.39731 15.4607 10.28 16.1667 11.3333 16.1667C12.3866 16.1667 13.2687 15.4607 13.558 14.5H15.3333C15.702 14.5 16 14.2013 16 13.8333C16 13.4653 15.702 13.1667 15.3333 13.1667ZM11.3333 14.8333C10.782 14.8333 10.3333 14.3847 10.3333 13.8333C10.3333 13.282 10.782 12.8333 11.3333 12.8333C11.8847 12.8333 12.3333 13.282 12.3333 13.8333C12.3333 14.3847 11.8847 14.8333 11.3333 14.8333Z" fill="#515C7B" />
                <path d="M15.3333 13.1667H13.558C13.2693 12.206 12.3867 11.5 11.3333 11.5C10.28 11.5 9.398 12.206 9.10869 13.1667H0.666656C0.298656 13.1667 0 13.4653 0 13.8333C0 14.2013 0.298656 14.5 0.666656 14.5H9.10866C9.39731 15.4607 10.28 16.1667 11.3333 16.1667C12.3866 16.1667 13.2687 15.4607 13.558 14.5H15.3333C15.702 14.5 16 14.2013 16 13.8333C16 13.4653 15.702 13.1667 15.3333 13.1667ZM11.3333 14.8333C10.782 14.8333 10.3333 14.3847 10.3333 13.8333C10.3333 13.282 10.782 12.8333 11.3333 12.8333C11.8847 12.8333 12.3333 13.282 12.3333 13.8333C12.3333 14.3847 11.8847 14.8333 11.3333 14.8333Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 13.1667H13.558C13.2693 12.206 12.3867 11.5 11.3333 11.5C10.28 11.5 9.398 12.206 9.10869 13.1667H0.666656C0.298656 13.1667 0 13.4653 0 13.8333C0 14.2013 0.298656 14.5 0.666656 14.5H9.10866C9.39731 15.4607 10.28 16.1667 11.3333 16.1667C12.3866 16.1667 13.2687 15.4607 13.558 14.5H15.3333C15.702 14.5 16 14.2013 16 13.8333C16 13.4653 15.702 13.1667 15.3333 13.1667ZM11.3333 14.8333C10.782 14.8333 10.3333 14.3847 10.3333 13.8333C10.3333 13.282 10.782 12.8333 11.3333 12.8333C11.8847 12.8333 12.3333 13.282 12.3333 13.8333C12.3333 14.3847 11.8847 14.8333 11.3333 14.8333Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 13.1667H13.558C13.2693 12.206 12.3867 11.5 11.3333 11.5C10.28 11.5 9.398 12.206 9.10869 13.1667H0.666656C0.298656 13.1667 0 13.4653 0 13.8333C0 14.2013 0.298656 14.5 0.666656 14.5H9.10866C9.39731 15.4607 10.28 16.1667 11.3333 16.1667C12.3866 16.1667 13.2687 15.4607 13.558 14.5H15.3333C15.702 14.5 16 14.2013 16 13.8333C16 13.4653 15.702 13.1667 15.3333 13.1667ZM11.3333 14.8333C10.782 14.8333 10.3333 14.3847 10.3333 13.8333C10.3333 13.282 10.782 12.8333 11.3333 12.8333C11.8847 12.8333 12.3333 13.282 12.3333 13.8333C12.3333 14.3847 11.8847 14.8333 11.3333 14.8333Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 2.49869H13.558C13.2687 1.53803 12.3867 0.832031 11.3333 0.832031C10.28 0.832031 9.398 1.53803 9.10866 2.49869H0.666656C0.298656 2.49869 0 2.79734 0 3.16534C0 3.53334 0.298656 3.832 0.666656 3.832H9.10866C9.398 4.79269 10.28 5.49869 11.3333 5.49869C12.3867 5.49869 13.2687 4.79269 13.558 3.83203H15.3333C15.702 3.83203 16 3.53338 16 3.16537C16 2.79737 15.702 2.49869 15.3333 2.49869ZM11.3333 4.16534C10.782 4.16534 10.3333 3.71669 10.3333 3.16534C10.3333 2.614 10.782 2.16534 11.3333 2.16534C11.8847 2.16534 12.3333 2.614 12.3333 3.16534C12.3333 3.71669 11.8847 4.16534 11.3333 4.16534Z" fill="#515C7B" />
                <path d="M15.3333 2.49869H13.558C13.2687 1.53803 12.3867 0.832031 11.3333 0.832031C10.28 0.832031 9.398 1.53803 9.10866 2.49869H0.666656C0.298656 2.49869 0 2.79734 0 3.16534C0 3.53334 0.298656 3.832 0.666656 3.832H9.10866C9.398 4.79269 10.28 5.49869 11.3333 5.49869C12.3867 5.49869 13.2687 4.79269 13.558 3.83203H15.3333C15.702 3.83203 16 3.53338 16 3.16537C16 2.79737 15.702 2.49869 15.3333 2.49869ZM11.3333 4.16534C10.782 4.16534 10.3333 3.71669 10.3333 3.16534C10.3333 2.614 10.782 2.16534 11.3333 2.16534C11.8847 2.16534 12.3333 2.614 12.3333 3.16534C12.3333 3.71669 11.8847 4.16534 11.3333 4.16534Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 2.49869H13.558C13.2687 1.53803 12.3867 0.832031 11.3333 0.832031C10.28 0.832031 9.398 1.53803 9.10866 2.49869H0.666656C0.298656 2.49869 0 2.79734 0 3.16534C0 3.53334 0.298656 3.832 0.666656 3.832H9.10866C9.398 4.79269 10.28 5.49869 11.3333 5.49869C12.3867 5.49869 13.2687 4.79269 13.558 3.83203H15.3333C15.702 3.83203 16 3.53338 16 3.16537C16 2.79737 15.702 2.49869 15.3333 2.49869ZM11.3333 4.16534C10.782 4.16534 10.3333 3.71669 10.3333 3.16534C10.3333 2.614 10.782 2.16534 11.3333 2.16534C11.8847 2.16534 12.3333 2.614 12.3333 3.16534C12.3333 3.71669 11.8847 4.16534 11.3333 4.16534Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 2.49869H13.558C13.2687 1.53803 12.3867 0.832031 11.3333 0.832031C10.28 0.832031 9.398 1.53803 9.10866 2.49869H0.666656C0.298656 2.49869 0 2.79734 0 3.16534C0 3.53334 0.298656 3.832 0.666656 3.832H9.10866C9.398 4.79269 10.28 5.49869 11.3333 5.49869C12.3867 5.49869 13.2687 4.79269 13.558 3.83203H15.3333C15.702 3.83203 16 3.53338 16 3.16537C16 2.79737 15.702 2.49869 15.3333 2.49869ZM11.3333 4.16534C10.782 4.16534 10.3333 3.71669 10.3333 3.16534C10.3333 2.614 10.782 2.16534 11.3333 2.16534C11.8847 2.16534 12.3333 2.614 12.3333 3.16534C12.3333 3.71669 11.8847 4.16534 11.3333 4.16534Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 7.83463H6.89134C6.602 6.87397 5.72 6.16797 4.66669 6.16797C3.61337 6.16797 2.73134 6.87397 2.44203 7.83463H0.666656C0.298656 7.83463 0 8.13328 0 8.50128C0 8.86928 0.298656 9.16794 0.666656 9.16794H2.442C2.73134 10.1286 3.61334 10.8346 4.66666 10.8346C5.71997 10.8346 6.602 10.1286 6.89131 9.16794H15.3333C15.702 9.16794 16 8.86928 16 8.50128C16 8.13328 15.702 7.83463 15.3333 7.83463ZM4.66666 9.50128C4.11531 9.50128 3.66666 9.05262 3.66666 8.50128C3.66666 7.94994 4.11531 7.50128 4.66666 7.50128C5.218 7.50128 5.66666 7.94994 5.66666 8.50128C5.66666 9.05262 5.218 9.50128 4.66666 9.50128Z" fill="#515C7B" />
                <path d="M15.3333 7.83463H6.89134C6.602 6.87397 5.72 6.16797 4.66669 6.16797C3.61337 6.16797 2.73134 6.87397 2.44203 7.83463H0.666656C0.298656 7.83463 0 8.13328 0 8.50128C0 8.86928 0.298656 9.16794 0.666656 9.16794H2.442C2.73134 10.1286 3.61334 10.8346 4.66666 10.8346C5.71997 10.8346 6.602 10.1286 6.89131 9.16794H15.3333C15.702 9.16794 16 8.86928 16 8.50128C16 8.13328 15.702 7.83463 15.3333 7.83463ZM4.66666 9.50128C4.11531 9.50128 3.66666 9.05262 3.66666 8.50128C3.66666 7.94994 4.11531 7.50128 4.66666 7.50128C5.218 7.50128 5.66666 7.94994 5.66666 8.50128C5.66666 9.05262 5.218 9.50128 4.66666 9.50128Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 7.83463H6.89134C6.602 6.87397 5.72 6.16797 4.66669 6.16797C3.61337 6.16797 2.73134 6.87397 2.44203 7.83463H0.666656C0.298656 7.83463 0 8.13328 0 8.50128C0 8.86928 0.298656 9.16794 0.666656 9.16794H2.442C2.73134 10.1286 3.61334 10.8346 4.66666 10.8346C5.71997 10.8346 6.602 10.1286 6.89131 9.16794H15.3333C15.702 9.16794 16 8.86928 16 8.50128C16 8.13328 15.702 7.83463 15.3333 7.83463ZM4.66666 9.50128C4.11531 9.50128 3.66666 9.05262 3.66666 8.50128C3.66666 7.94994 4.11531 7.50128 4.66666 7.50128C5.218 7.50128 5.66666 7.94994 5.66666 8.50128C5.66666 9.05262 5.218 9.50128 4.66666 9.50128Z" fill="black" fill-opacity="0.2" />
                <path d="M15.3333 7.83463H6.89134C6.602 6.87397 5.72 6.16797 4.66669 6.16797C3.61337 6.16797 2.73134 6.87397 2.44203 7.83463H0.666656C0.298656 7.83463 0 8.13328 0 8.50128C0 8.86928 0.298656 9.16794 0.666656 9.16794H2.442C2.73134 10.1286 3.61334 10.8346 4.66666 10.8346C5.71997 10.8346 6.602 10.1286 6.89131 9.16794H15.3333C15.702 9.16794 16 8.86928 16 8.50128C16 8.13328 15.702 7.83463 15.3333 7.83463ZM4.66666 9.50128C4.11531 9.50128 3.66666 9.05262 3.66666 8.50128C3.66666 7.94994 4.11531 7.50128 4.66666 7.50128C5.218 7.50128 5.66666 7.94994 5.66666 8.50128C5.66666 9.05262 5.218 9.50128 4.66666 9.50128Z" fill="black" fill-opacity="0.2" />
            </g>
            <defs>
                <clipPath id="clip0_29123_7585">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default ManageIcon