import SidebarItem from "../SidebarItem/SidebarItem"
import styles from "./SectionStyles.module.css"


function Section1 ({activeLink, onElementClick, config, componentProps}) {
	
	return (
		<div className={styles.section1Container}>
			{
				config?.section1.slice(0,1).map((item,idx)=>(
					<SidebarItem className={`${styles.sectionItem} ${activeLink === item.elLink ? styles.sectionItemActive : ""}`} onClick = {(e)=>{onElementClick(idx, item, e)}} style={{padding : "0px",visibility : componentProps[0]?.main?.isVisible === false? "hidden" : "visible"}} isLink={item.isLink} >

						{item.icon}
						{item.displayName}
						{item.component && item.component(componentProps[0].main)}
						{item.childComponent && item.childComponent(componentProps[0].child)}
					</SidebarItem>
				))
			}
			<div className={styles.subContainer}>
				{
					config.section1.slice(1).map((item, idx)=>(
					<SidebarItem className={`${styles.sectionItem} ${activeLink === item.elLink ? styles.sectionItemActive : ""}`} onClick = {(e)=>{onElementClick(idx, item,e)}} style={{padding : "0px",visibility : componentProps[idx+1]?.main?.isVisible === false? "hidden" : "visible"}} isLink={item.isLink}>

						{item.icon}
						{item.displayName}
						{item.component && item.component(componentProps[idx + 1].main)}
						{item.childComponent && item.childComponent(componentProps[idx + 1].child)}
					</SidebarItem>
					))
				}
			</div>
		
		</div>
	)

}


export default Section1
