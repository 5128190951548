import { LogLevel } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "9817f6d1-acf8-4be2-a98f-a35468226df0", // Application (client) ID from Azure AD
    authority:
      "https://login.microsoftonline.com/ee8323a2-0bec-4a37-bc27-4afb0baeb078", // Directory (tenant) ID
    redirectUri: "http://localhost:3000", // Redirect URI, can be your application's URL
  },
  cache: {
    cacheLocation: "localStorage", // This can be "sessionStorage" or "localStorage"
    storeAuthStateInCookie: false, // Set to true for IE 11 or other scenarios where cookies are necessary
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            break;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

export default msalConfig;

export const loginRequest = {
  scopes: [
    "User.Read",
    // "Calendars.ReadWrite.Shared",
    // "OnlineMeetings.ReadWrite",
  ],
};
