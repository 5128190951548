import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: null,
  endDate: null,
};

const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    setStartDateSelector(state, action) {
      state.startDate = action.payload;
    },
    setEndDateSelector(state, action) {
      state.endDate = action.payload;
    },
    setDateRange(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
  },
});

export const { setStartDateSelector, setEndDateSelector, setDateRange } =
  dateRangeSlice.actions;
export default dateRangeSlice.reducer;
