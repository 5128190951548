import HorizontalDivider from "../../HorizontalDivider/HorizontalDivider";
import PackageSelectorItem from "../../PackageSelectorItem/PackageSelectorItem";
import styles from "./BasePackageChangePopUp.module.css";
import explorerIcon from "./ExplorerIcon.svg";
import premiumIcon from "./PremiumIcon.svg";
import professionalIcon from "./ProfessionalIcon.svg";
import starterIcon from "./StarterIcon.svg";
import { Link } from "react-router-dom";
import ButtonV2 from "../../ButtonV2/ButtonV2";
import { useEffect, useState } from "react";
import getCorrespondingPriceId from "../../../pages/ManageSubscription/Utils/getCorrespondingProducts";
import { fetchLookupKeyForId, fetchPriceForId } from "../../../pages/ManageSubscription/Utils/fetchers";
import { triggerToast } from "../../../utils/toastController";
import Tooltip from "../../Tooltip/Tooltip";
import InfoIcon from "../../InfoIcon/InfoIcon";
import { loadFreeTier, loadFreeTierOnPackageChange } from "../../../utils/utils";

const tutorLicensesMapping = {
  BasicPackage_Free: 1,
  BasicPackage_Starter: 1,
  BasicPackage_Professional: 10,
  BasicPackage_Premium: 30,
};

const defaultPackages = [
  {
    img: <img src={explorerIcon} className={styles.imgPackage} />,
    packageName: "Explorer",
    helperText: "Lifetime Free",
    isSelected: false,
    isCurrentPlan: true,
    priceId: 0,
  },
  {
    img: <img src={starterIcon} className={styles.imgPackage} />,
    packageName: "Starter",
    helperText: "Starting at $10.00 per month",
    isSelected: true,
    priceId: 1,
  },
  {
    img: <img src={professionalIcon} className={styles.imgPackage} />,
    packageName: "Starter",
    helperText: "Starting at $19.00 per month",
    isSelected: false,
    priceId: 2,
  },
  {
    img: <img src={premiumIcon} className={styles.imgPackage} />,
    packageName: "Starter",
    helperText: "Starting at $29.00 per month",
    isSelected: false,
    priceId: 3,
  },
];

const BasePackageChangePopUp = ({
  setPopUpTriger,
  productPrices,
  subscription,
  basePackages,
  selectedBillingCycle,
  setSelectedBillingCycle,
  siMapping,
  setSiMapping,
  setBillingCycleChangedFromOutside
}) => {
  const currentBasePackageId = subscription.subscription.basePackage.priceId;
  const [stagingSiMapping, setStagingSiMapping] = useState(siMapping);
  const [tutors, setNoOfTutors] = useState(0); //Realtive to base package tutors
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [disableTutorInput , setDisableTutorInput] = useState(false)
  const [selectedBasePackage, setSelectedBasePackage] = useState(null);
  const [count, setCount] = useState(0);
  const [tutorChangesManually, setTutorChangedManually] = useState(false)
  const [extraPricePerTutor, setExtraPricePerTutor] = useState(null);
  const [tempSelectedBillingCycle, setTempSelectedBillingCycle] =
    useState(selectedBillingCycle);

    useEffect(()=>{
      setSelectedBasePackage(
        basePackages[selectedBillingCycle === 0 ? "monthly" : "annual"].find(
          (val) =>
            val.id ===
            siMapping.find((val) => val.lookupKey.includes("BasicPackage"))
              .newPriceId
        ) ??
        basePackages[selectedBillingCycle === 0 ? "monthly" : "annual"].find(
          (val) => val.id === currentBasePackageId
        ) ??
        basePackages[selectedBillingCycle !== 0 ? "monthly" : "annual"].find(
          (val) =>
            val.id ===
            siMapping.find((val) => val.lookupKey.includes("BasicPackage"))
              .newPriceId
        )
      )
    },[])

    useEffect(()=>{
      const currentlySelectedBasePackage = stagingSiMapping.find((val)=>val.lookupKey.includes("BasicPackage"))
      const currentlySelectedBasePackageId = currentlySelectedBasePackage.newPriceId ?? currentlySelectedBasePackage.priceId
      const basePackageLookupKey = fetchLookupKeyForId(productPrices,currentlySelectedBasePackageId,tempSelectedBillingCycle === 0 ? "monthly" : "annual","BasePackage")
      const basePackageTier = basePackageLookupKey.split("_")[1]
      const basePackageTutorLimit = tutorLicensesMapping[Object.keys(tutorLicensesMapping).find((val)=>basePackageLookupKey.includes(val))]
      const extraTutors = siMapping.find((val)=>val.lookupKey.includes("Tutor")).quantity
      setNoOfTutors(basePackageTutorLimit+extraTutors)

    },[])

  useEffect(()=>{console.log("TESTING",disableTutorInput)},[disableTutorInput])

  useEffect(() => {
    let tempStagingMapping = Object.assign([],stagingSiMapping)
    // if(!selectedPriceId ) return
    const currentlySelectedBasePackage = stagingSiMapping.find((val)=>val.lookupKey.includes("BasicPackage"))
    const currentlySelectedBasePackageId = currentlySelectedBasePackage.newPriceId ?? currentlySelectedBasePackage.priceId
    const basePackageLookupKey = fetchLookupKeyForId(productPrices,currentlySelectedBasePackageId,tempSelectedBillingCycle === 0 ? "monthly" : "annual","BasePackage")
    const basePackageTier = basePackageLookupKey.split("_")[1]
    console.log("BPT",basePackageTier,selectedPriceId)
    if(basePackageTier === "Free" && selectedPriceId !== subscription?.subscription?.basePackage.priceId){
      setDisableTutorInput(true)
      setNoOfTutors(1)
      console.log("FREE")
      tempStagingMapping.forEach((val,idx)=>{
        const tempVal = {...tempStagingMapping[idx]}
        if(val.lookupKey.includes("Tutor")){
          tempVal.newPriceId = null
          tempVal.quantity = 0
        } 
        else if(!val.lookupKey.includes("PAYG") && !val.lookupKey.includes("BasicPackage") && !val.lookupKey.includes("Tutor")){
          const productName = val.lookupKey.includes("Calendar") ? "Calendar" : val.lookupKey.includes("Assignments") ? "Assignments" : val.lookupKey.includes("CustomContent") ? "Custom Tests" : ""
          console.log({...val, name : productName})
          const freeTierPriceId = loadFreeTierOnPackageChange(productPrices,tempSelectedBillingCycle,productName,val)
          tempVal.newPriceId = freeTierPriceId
        }
        tempStagingMapping[idx] = tempVal
      })
      setStagingSiMapping(tempStagingMapping);
      return
    }

    const basePackageTutorLimit = tutorLicensesMapping[Object.keys(tutorLicensesMapping).find((val)=>basePackageLookupKey.includes(val))]
    const extraTutorToConsider = siMapping.find((val)=>val.lookupKey.includes("Tutor")).newPriceId ? siMapping.find((val)=>val.lookupKey.includes("Tutor")).quantity : Math.abs(basePackageTutorLimit - subscription?.subscription.tutors.limit)
    const extraTutors = !tutorChangesManually && selectedBasePackage?.id === subscription?.subscription.basePackage.priceId ? siMapping.find((val)=>val.lookupKey.includes("Tutor")).quantity : basePackageTutorLimit < tutors ? Math.abs(basePackageTutorLimit - tutors) : 0
    const requiredTutorPrice = productPrices.find((val)=>val.product.name.includes("Tutor")).prices[tempSelectedBillingCycle === 0 ? "monthly" : "annual"].find((_val)=>_val.lookup_key.includes(basePackageTier))

    tempStagingMapping.forEach((val,idx)=>{
      const tempVal = {...tempStagingMapping[idx]}
      if(val.lookupKey.includes("Tutor")){
        tempVal.newPriceId = requiredTutorPrice?.id
        tempVal.quantity = extraTutors
      }
      tempStagingMapping[idx] = tempVal
    })
    setDisableTutorInput(false)
    setStagingSiMapping(tempStagingMapping);
  }, [selectedPriceId, tutors]);

  //Set additional number of tutors
  useEffect(() => {
    const type = tempSelectedBillingCycle === 0 ? "monthly" : "annual";
  }, []);

  useEffect(() => {
    console.log(">>>TSG##", stagingSiMapping);
  }, [stagingSiMapping]);

  useEffect(() => {
    const type = tempSelectedBillingCycle === 0 ? "monthly" : "annual";
    const previousType = tempSelectedBillingCycle !== 0 ? "monthly" : "annual";
    if (count === 0) {
      console.log("FRST");
      return;
    }
    if (selectedPriceId === null) {
      if(previousType === "monthly" && type === "annual"){
        setBillingCycleChangedFromOutside(true)
      }
      
      const newBasePackage = basePackages[type].find(
        (val) => val.id !== currentBasePackageId
      );
      setSelectedBasePackage(newBasePackage);
      setSelectedPriceId(newBasePackage.id);
      console.log(
        basePackages[type].find((val) => val.id !== currentBasePackageId).id
      );
    } else {
      if (type == "annual" && previousType === "monthly") {
        setBillingCycleChangedFromOutside(true)
        const previousLookupKey = selectedBasePackage.lookup_key;
        const newBasePackage = basePackages[type].find(
          (val) => val.lookup_key === previousLookupKey + "_Annual"
        );
        const tempStaging = stagingSiMapping.map((val) => {
          const temp = { ...val };
          if (val.newPriceId === selectedPriceId) {
            temp.newPriceId = newBasePackage.id;
          }
          return temp;
        });
        setStagingSiMapping(tempStaging);
        setSelectedBasePackage(newBasePackage);
        setSelectedPriceId(newBasePackage.id);
        console.log(">>LKP_1", newBasePackage.lookup_key);
      } else {
        setBillingCycleChangedFromOutside(true)
        const previousLookupKey = selectedBasePackage.lookup_key;
        const newBasePackage = basePackages[type].find(
          (val) => val.lookup_key + "_Annual" === previousLookupKey
        );
        const tempStaging = stagingSiMapping.map((val) => {
          const temp = { ...val };
          if (val.newPriceId === selectedPriceId) {
            temp.newPriceId = newBasePackage.id;
          }
          return temp;
        });
        setStagingSiMapping(tempStaging);
        setSelectedBasePackage(newBasePackage);
        setSelectedPriceId(newBasePackage.id);
        console.log(">>LKP_2", newBasePackage.lookup_key);
      }
    }
  }, [tempSelectedBillingCycle]);

  //Update siMapping with whole products when
  useEffect(() => {
    if (!siMapping || !subscription) return;

    const oldSubscriptionType =
      subscription.subscription.__t === "MonthlySubscription"
        ? "monthly"
        : "annual";
    const newSubscriptionType =
      tempSelectedBillingCycle === 0 ? "monthly" : "annual";

    const previousUserSelectedBillingCycle =
      selectedBillingCycle === 0 ? "monthly" : "annual";

    if (oldSubscriptionType !== newSubscriptionType) {
      let tempSiMapping = stagingSiMapping.map((val) => {
        const temp = { ...val };
        if (
          temp.lookupKey.includes("BasicPackage") ||
          temp.lookupKey.includes("Tutor") ||
          temp.lookupKey.includes("PAYG")
        )
          return temp;
        temp.newPriceId = getCorrespondingPriceId(
          oldSubscriptionType,
          newSubscriptionType,
          productPrices,
          val.lookupKey.split("_")[0],
          temp.priceId
        );
        return temp;
      });
      if (_.isEqual(tempSiMapping, stagingSiMapping)) return;
      setStagingSiMapping(tempSiMapping);
    } else {
      let tempSiMapping = stagingSiMapping.map((val) => {
        const temp = { ...val };
        return temp;
      });
      if (_.isEqual(tempSiMapping, siMapping)) return;
      setStagingSiMapping(tempSiMapping);
    }
  }, [tempSelectedBillingCycle]);

  const handlePackageSelected = (e) => {
    const type = tempSelectedBillingCycle === 0 ? "monthly" : "annual";
    console.log(currentBasePackageId,siMapping);
    const currentSubscriptionItemid = siMapping.find(
      (val) => val.priceId === currentBasePackageId
    ).stripeSubscriptionItemId;

    if (e.target.id === currentBasePackageId) {
      const tempStaging = stagingSiMapping.map((val) => {
        const temp = { ...val };
        if (val.stripeSubscriptionItemId === currentSubscriptionItemid) {
          if (temp.newPriceId) delete temp.newPriceId;
        }
        return temp;
      });
      console.log(">>TSG", tempStaging);
      setStagingSiMapping(tempStaging);
      setSelectedPriceId(null);
      setSelectedBasePackage(
        basePackages[type].find((val) => val.id === currentBasePackageId)
      );
      return;
    }

    const tempStaging = stagingSiMapping.map((val) => {
      const temp = { ...val };
      if (val.stripeSubscriptionItemId === currentSubscriptionItemid) {
        temp.newPriceId = e.target.id;
        temp.name = basePackages[type].find(
          (val) => val.id === e.target.id
        ).lookup_key;
      }
      return temp;
    });

    setSelectedPriceId(e.target.id);
    setSelectedBasePackage(
      basePackages[type].find((val) => val.id === e.target.id)
    );
    console.log(">>TSG", tempStaging);
    setStagingSiMapping(tempStaging);
  };

  useEffect(()=>{
    console.log("STAGING SI MAPPING",stagingSiMapping)
  },[stagingSiMapping])

  useEffect(() => {
    setCount(1);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Change Subscription Plan</div>
      <div className={styles.contentContainer}>
        <div className="flex items-center gap-[24px]">
          <div className={`${styles.helperText}`}>Billing Cycle : </div>
          <div className="flex items-center justify-start gap-[12px]">
            <div className={`cursor-pointer ${tempSelectedBillingCycle === 0
                ? "text-blue-70"
                : ""
              }`}
              onClick={() => {
                setTempSelectedBillingCycle(0);
              }}
            >
              <div className="font-semibold text-[16px] text-secondary flex items-center gap-[6px]" style={{display : `${subscription?.subscription.__t === "AnnualSubscription" ? subscription?.subscription?.basePackage?.name?.includes("Free") ? "": "none" : ""}`}}>
                <input type="radio" className={`accent-blue-70 h-[18px] w-[18px]`} checked={tempSelectedBillingCycle === 0} />
                <label className={`${tempSelectedBillingCycle === 0
                    ? "text-blue-70"
                    : ""
                  }`}>Monthly</label>
              </div>
            </div>
            <div
              className={`cursor-pointer ${tempSelectedBillingCycle === 1
                  ? "text-blue-70"
                  : ""
                }`}
              onClick={() => {
                setTempSelectedBillingCycle(1);
              }}
            >
              <div className="flex gap-[6px] ">
                <div className="font-semibold text-[16px] text-secondary flex items-center gap-[6px]">
                  <input type="radio" className={`accent-blue-70 h-[18px] w-[18px]`} checked={tempSelectedBillingCycle === 1} />
                  <label className={`${tempSelectedBillingCycle === 1
                      ? "text-blue-70"
                      : ""
                    }`}>Yearly <span className="text-secondary-60">(Save 15%)</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HorizontalDivider />

        <div className="flex flex-col gap-[16px] w-full">
          <div className="w-full flex justify-between items-center">
            <div className={styles.helperText}>Select new plan</div>
            <a className="underline" href="https://evallo.org/pricing" target="_blank">Compare all plans</a>
          </div>
          <div className="flex items-start justify-start w-full gap-[24px]">
            <div className={styles.packageContainer}>
              {basePackages[
                tempSelectedBillingCycle === 0 ? "monthly" : "annual"
              ].slice(tempSelectedBillingCycle === 0 ? 1 : 0).map((val, idx) => (
                <PackageSelectorItem
                  {...val}
                  isSelected={stagingSiMapping.find(
                    (si) => si.newPriceId === val.id
                  )}
                  onClick={handlePackageSelected}
                  priceId={val.id}
                />
              ))}
            </div>
            <div className={styles.tutorDetailsContainer}>
              <div className="flex items-center gap-[4px] justify-start"><>Total number of tutors</> <Tooltip anchorComponent={<InfoIcon className="text-secondary-60 w-[16px] h-[16px]"/>} tooltipStyleClass={"tooltipStyleClass !w-[350px]"} tooltipContent={<>The total number of tutors (not including the Admin) you have in your team.</>}/></div> <div className="w-full flex items-center justify-end"><input type="number" className="text-right rounded-[5px] border-[1px] border-secondary-60 text-secondary-80 px-[12px] py-[8px] w-[72px]" defaultValue={tutors} onChange={(e)=>{setNoOfTutors(e.target.value);setTutorChangedManually(true)}} disabled={disableTutorInput} key={selectedBasePackage?.id}></input> </div>
              <div className="flex items-center gap-[4px] justify-start"><>Tutor licenses included in plan</> <Tooltip anchorComponent={<InfoIcon className="text-secondary-60 w-[16px] h-[16px]"/>} tooltipStyleClass={"tooltipStyleClass !w-[350px]"} tooltipContent={<>Additional licenses that you can purchase to increase your tutoring team size.</>}/> </div> <div className="text-right text-secondary">{tutorLicensesMapping[Object.keys(tutorLicensesMapping).find((key) => selectedBasePackage?.lookup_key?.includes(key))]}</div>
              <div className="font-[600]">Additional tutor licenses</div> <div className="text-right font-[600] text-secondary">{stagingSiMapping.find((val)=>val?.lookupKey?.includes("Tutor"))?.quantity}</div>
              {
                subscription?.subscription.basePackage.name.includes("Free") && selectedBasePackage?.lookup_key?.includes("Free")? <><div>Charge per additional license</div> <div className="text-right text-secondary"> 0 / <span className="text-secondary-80">{tempSelectedBillingCycle === 0 ? "mo" : "year"}</span></div></> :
                productPrices ? <><div>Charge per additional license</div> <div className="text-right text-secondary">$ {fetchPriceForId(productPrices,stagingSiMapping.find((val)=>val.lookupKey.includes("Tutor"))?.newPriceId ?? stagingSiMapping.find((val)=>val.lookupKey.includes("Tutor"))?.priceId,tempSelectedBillingCycle === 0 ? "monthly" : "annual","Tutor")} / <span className="text-secondary-80">{tempSelectedBillingCycle === 0 ? "mo" : "year"}</span></div></> : <></>
              }
            </div>

          </div>

        </div>
      </div>
      <div className={styles.actionArea}>
        <ButtonV2
          className={styles.continueButton}
          onClick={(e) => {
            console.log("SMS",stagingSiMapping)
            if(!_.isEqual(stagingSiMapping,siMapping)) {
              triggerToast("Plan updated. Please confirm changes in the Summary below.","info",{autoClose : 3000})
            }
            setSiMapping(stagingSiMapping);
            setSelectedBillingCycle(tempSelectedBillingCycle);
            
            setPopUpTriger(false);
          }}
        >
          Save
        </ButtonV2>
        <ButtonV2
          className={styles.cancelButton}
          onClick={(e) => setPopUpTriger(false)}
        >
          Cancel
        </ButtonV2>
      </div>
    </div>
  );
};

export default BasePackageChangePopUp;
