export default function CampaignIcon(props) {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_32520_73087)">
                <g clip-path="url(#clip1_32520_73087)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.61607 2.73938C4.49361 2.85053 4.3643 3.16555 4.5285 3.8225L4.52966 3.82716C4.73221 4.66889 5.34293 5.77607 6.28878 6.83952C7.24282 7.90396 8.27009 8.63404 9.07737 8.92416L9.08228 8.92592L9.08715 8.92778C9.66061 9.14656 9.97997 9.09512 10.126 9.00673L10.1746 8.96366C10.2463 8.89999 10.32 8.77671 10.3358 8.5326C10.3518 8.28543 10.3038 7.9553 10.1704 7.55642C9.90438 6.76073 9.33281 5.79282 8.50145 4.86528C7.66923 3.93674 6.77252 3.26015 6.01293 2.90552C5.63218 2.72777 5.31024 2.64227 5.06298 2.62974C4.82025 2.61745 4.68874 2.67548 4.61607 2.73938ZM6.4685 1.92974C7.38881 2.35943 8.39824 3.13665 9.30339 4.14652C10.2094 5.15737 10.8691 6.24994 11.1917 7.21496C11.3527 7.69646 11.4384 8.17176 11.4104 8.60234C11.3879 8.95078 11.2878 9.29487 11.0706 9.57542V9.61358L10.8919 9.77408C10.307 10.2992 9.45561 10.2199 8.70821 9.93583C7.67896 9.56452 6.51179 8.70202 5.48616 7.55746L5.48482 7.55599C4.46471 6.40928 3.74061 5.14863 3.48319 4.08139C3.28961 3.30504 3.31747 2.45813 3.8977 1.93714L3.8999 1.93513C4.2439 1.6297 4.68396 1.53227 5.11747 1.55423C5.54776 1.57603 6.00927 1.71533 6.4685 1.92974Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.24716 2.19091C4.51875 2.31209 4.64066 2.63048 4.51948 2.90204L1.15242 10.4474L1.15191 10.4486C0.871655 11.0728 1.46619 11.7531 2.13649 11.545L2.13735 11.5447L10.0181 9.05411C10.3017 8.96451 10.6042 9.12174 10.6938 9.40526C10.7834 9.68883 10.6262 9.99135 10.3427 10.0809L2.45983 12.5721L2.4578 12.5728C0.917462 13.0529 -0.497377 11.4941 0.169213 10.0081C0.169118 10.0083 0.169308 10.0079 0.169213 10.0081L3.53607 2.4632C3.65724 2.19164 3.97561 2.06973 4.24716 2.19091Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.61627 10.2094C6.9 10.1204 7.20218 10.2783 7.29117 10.5621L7.67145 11.7746C7.67136 11.7744 7.67149 11.7747 7.67145 11.7746C7.96862 12.7182 7.43845 13.739 6.49415 14.0366L5.28084 14.4171C5.28071 14.4172 5.28101 14.4171 5.28084 14.4171C4.33718 14.7143 3.31646 14.1841 3.01885 13.2398L2.6381 12.0258C2.54911 11.742 2.70699 11.4399 2.99077 11.3509C3.27451 11.2619 3.57664 11.4198 3.66563 11.7036L4.0459 12.9161C4.04586 12.916 4.04595 12.9162 4.0459 12.9161C4.16475 13.2924 4.58004 13.5089 4.95712 13.39L6.17039 13.0096C6.17026 13.0096 6.17052 13.0095 6.17039 13.0096C6.54674 12.8907 6.7632 12.4754 6.64435 12.0983L6.26361 10.8843C6.17461 10.6005 6.33253 10.2984 6.61627 10.2094Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2226 1.32353C13.4341 1.53261 13.436 1.87353 13.2269 2.08499L12.5952 2.72396C12.3861 2.93542 12.0452 2.93735 11.8337 2.72826C11.6222 2.51918 11.6203 2.17826 11.8294 1.9668L12.4611 1.32784C12.6702 1.11637 13.0112 1.11444 13.2226 1.32353Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.80759 0.548582C10.0782 0.671864 10.1976 0.991184 10.0744 1.2618L9.78002 1.90793C9.65674 2.17854 9.33742 2.29798 9.06682 2.1747C8.79618 2.05142 8.67677 1.7321 8.80005 1.46148L9.09439 0.815354C9.21767 0.544736 9.53699 0.4253 9.80759 0.548582Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9361 4.66124C14.0765 4.92336 13.9778 5.2497 13.7157 5.39013L13.1126 5.71324C12.8505 5.85366 12.5242 5.75498 12.3838 5.49286C12.2433 5.23075 12.342 4.90441 12.6041 4.76398L13.2071 4.44087C13.4693 4.30044 13.7956 4.39913 13.9361 4.66124Z" fill="currentColor" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_32520_73087">
                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                </clipPath>
                <clipPath id="clip1_32520_73087">
                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}