import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import SCheckbox from "../CCheckbox/SCheckbox";
export function TableHeaderNew({
  header,
  checkedHeader,
  dataFor,
  Handler,
  noArrow,
  wrapperClassName,
}) {
  const [flag, setFlag] = useState(
    header?.className ? header.className.includes("no-arrow") : false
  );
  const handleCheckboxChange = (c) => {
    Handler(c);
  };
  const downArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
	  style = {{cursor : "pointer"}}
    >
      <path
        d="M9.35616 13.4537C9.1998 13.4538 9.04693 13.4991 8.91662 13.5839C8.78632 13.6687 8.68435 13.7893 8.62344 13.9306C8.56253 14.0719 8.54537 14.2277 8.57411 14.3785C8.60285 14.5293 8.67621 14.6685 8.78504 14.7787L13.0313 19.0756C13.1055 19.1505 13.1943 19.2101 13.2924 19.2508C13.3905 19.2915 13.4959 19.3125 13.6024 19.3125C13.709 19.3125 13.8144 19.2915 13.9125 19.2508C14.0106 19.2101 14.0994 19.1505 14.1736 19.0756L18.4199 14.7787C18.5287 14.6685 18.602 14.5293 18.6308 14.3785C18.6595 14.2277 18.6424 14.0719 18.5815 13.9306C18.5205 13.7893 18.4186 13.6687 18.2883 13.5839C18.158 13.4991 18.0051 13.4538 17.8487 13.4537H14.3986L14.3986 6.03182C14.3986 5.82462 14.3147 5.62591 14.1654 5.47939C14.0161 5.33288 13.8136 5.25057 13.6024 5.25057C13.3913 5.25057 13.1888 5.33288 13.0395 5.47939C12.8902 5.62591 12.8063 5.82462 12.8063 6.03182L12.8063 13.4537H9.35616Z"
        fill="currentColor"
      />
    </svg>
  );
  const upArrow = (
    <svg
      style={{ rotate: "180deg", cursor : "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        d="M9.35616 13.4537C9.1998 13.4538 9.04693 13.4991 8.91662 13.5839C8.78632 13.6687 8.68435 13.7893 8.62344 13.9306C8.56253 14.0719 8.54537 14.2277 8.57411 14.3785C8.60285 14.5293 8.67621 14.6685 8.78504 14.7787L13.0313 19.0756C13.1055 19.1505 13.1943 19.2101 13.2924 19.2508C13.3905 19.2915 13.4959 19.3125 13.6024 19.3125C13.709 19.3125 13.8144 19.2915 13.9125 19.2508C14.0106 19.2101 14.0994 19.1505 14.1736 19.0756L18.4199 14.7787C18.5287 14.6685 18.602 14.5293 18.6308 14.3785C18.6595 14.2277 18.6424 14.0719 18.5815 13.9306C18.5205 13.7893 18.4186 13.6687 18.2883 13.5839C18.158 13.4991 18.0051 13.4538 17.8487 13.4537H14.3986L14.3986 6.03182C14.3986 5.82462 14.3147 5.62591 14.1654 5.47939C14.0161 5.33288 13.8136 5.25057 13.6024 5.25057C13.3913 5.25057 13.1888 5.33288 13.0395 5.47939C12.8902 5.62591 12.8063 5.82462 12.8063 6.03182L12.8063 13.4537H9.35616Z"
        fill="currentColor"
      />
    </svg>
  );

  return dataFor === "assignedTests" ? (
    <th
      className={`min-w-[110px] first:rounded-l-[5.333px] last:rounded-r-[5.333px] bg-secondary-10 text-secondary text-[17.5px] leading-3 font-medium  ${
        header.text === "Completion" ? "text-left pl-[28px]" : " "
      } ${header.text === "Student Name" ? "pl-[80px] text-left " : ""} ${
        header.text === "Assignment Name" ? "text-left pl-[28px] " : ""
      } ${header.text === "Assigned On" ? "text-left pl-[28px]" : ""} ${
        header.text === "Due On" ? "text-left pl-3" : ""
      } ${header.text === "Tutor" ? "text-left pl-[28px]" : ""} ${
        header.text === "Score" ? "text-center" : ""
      }`}
    >
      <div
        onClick={() => header.onCick && header.onCick()}
        className={`flex items-center gap-0 ${
          header.text === "Total Assignments" &&
          "justify-center  last:rounded-r-[5.333px]"
        } ${header.text === "Score" && "justify-center"}`}
      >
        <p
          className={`${header.text === "Type" ? "pl-11" : ""} ${
            header.text === "Student Name" ? "pl-[0px]" : ""
          } ${header.text === "Duration" ? "!pl-2" : ""}`}
        >
          {header.text}
        </p>
        {header.noArrow
          ? ""
          : header.willDisplayDownArrow === null || header.text.lenght === 0
          ? ""
          : header.willDisplayDownArrow ||
            header.willDisplayDownArrow === undefined
          ? downArrow
          : upArrow}
      </div>
    </th>
  ) : dataFor === "allUsers" ? (
    <th
      className={`first:rounded-l-[5.333px] last:rounded-r-[5.333px]  py-[20px] text-secondary text-[17.5px] leading-3 font-medium !bg-secondary-10 ${
        header.text === "Completion" ? "text-left pl-[28px]" : " "
      } ${header.text === "Student Name" ? "pl-[80px] text-left" : ""} ${
        header.text === "Assignment Name" ? "text-left pl-[66px]" : ""
      } ${header.text === "Assigned On" ? "text-left pl-[28px]" : ""} ${
        header.text === "Due On" ? "text-left pl-3" : ""
      } ${header.text === "Tutor" ? "text-left pl-[28px]" : ""} ${
        header.text === "Score" ? "text-center" : ""
      }`}
    >
      <div
        onClick={() => header.onCick && header.onCick()}
        className={`flex items-center gap-0 
      ${header.text === "Total Assignments" && "justify-center"} 
      ${header.text === "Score" && "justify-center"} 
      ${header.text === "Time Taken" && "justify-center"} 
      ${header.className ?? header.className}`}
      >
        {header.text === "Full Name" && dataFor === "allUsers" ? (
          <SCheckbox
            checked={checkedHeader}
            onChange={handleCheckboxChange}
            checkClass={"mr-[25px] "}
          />
        ) : (
          ""
        )}

        <p
          className={`${header.text === "Type" ? "pl-11" : ""} ${
            header.text === "Q No." ? "pl-[0px]" : ""
          } `}
        >
          {header.text}
        </p>

        {header.noArrow
          ? ""
          : header.willDisplayDownArrow === null || header.text.lenght === 0
          ? ""
          : header.willDisplayDownArrow ||
            header.willDisplayDownArrow === undefined
          ? downArrow
          : upArrow}
      </div>
    </th>
  ) : dataFor === "allTests" ? (
    <th
      className={`pt-[26.25px] pb-[24.25px] bg-secondary-10 first:rounded-l-[5.333px] last:rounded-r-[5.333px] text-secondary text-[17.5px] leading-[25px] font-medium !py-[0] h-[62.5px] ${
        header.text === "Assignment Name"
          ? "text-left pl-[66px]"
          : header.text === "Total Assignments"
          ? "text-center"
          : " text-left"
      }`}
    >
      <div
        onClick={() => header.onCick && header.onCick()}
        className={`flex items-center gap-0 ${
          header.text === "Total Assignments" && "justify-center"
        }`}
      >
        <p
          className={`${header.text === "Type" ? "pl-[55px]" : ""} ${
            header.text === "Created On" ? "pl-[8px]" : ""
          } ${header.text === "Total Assignments" ? "pl-2" : ""} ${
            header.text === "Last Modified" ? "pl-0" : ""
          } ${header.text === "Student Name" ? "pl-[100px]" : ""} `}
        >
          {header.text}
        </p>
        {header.noArrow
          ? ""
          : header.willDisplayDownArrow === null || header.text.lenght === 0
          ? ""
          : header.willDisplayDownArrow ||
            header.willDisplayDownArrow === undefined
          ? downArrow
          : upArrow}
      </div>
    </th>
  ) : dataFor === "popularServices" ? (
    <th className="first:rounded-l-[5.333px] last:rounded-r-[5.333px]  bg-secondary-10 text-center py-[18.75px] text-[17.5px] font-medium leading-[21px] text-secondary">
      <div
        onClick={() => header.onCick && header.onCick()}
        className={`flex items-center ${
          header.text === "Service" && "w-[205.6px] pl-[21.25px] text-left"
        }
${
  header.text === "Actively Using" && "w-[168.5px] pr-[11.5px] text-left"
} flex gap-0
`}
      >
        <p>{header.text}</p>
        {header.noArrow
          ? ""
          : header.willDisplayDownArrow === null || header.text.lenght === 0
          ? ""
          : header.willDisplayDownArrow ||
            header.willDisplayDownArrow === undefined
          ? downArrow
          : upArrow}
      </div>
    </th>
  ) : dataFor === "allTests" ? (
    <th
      className={`pt-[26.25px] pb-[24.25px] bg-secondary-10 first:rounded-l-[5.333px] last:rounded-r-[5.333px] text-secondary text-[17.5px] leading-[25px] font-medium !py-[0] h-[62.5px] ${
        header.text === "Assignment Name"
          ? "text-left pl-[66px]"
          : header.text === "Total Assignments"
          ? "text-center"
          : " text-left"
      }`}
    >
      <div
        onClick={() => header.onCick && header.onCick()}
        className={`flex items-center gap-0 ${
          header.text === "Total Assignments" && "justify-center"
        }`}
      >
        <p
          className={`${header.text === "Type" ? "pl-[55px]" : ""} ${
            header.text === "Created On" ? "pl-[8px]" : ""
          } ${header.text === "Total Assignments" ? "pl-2" : ""} ${
            header.text === "Last Modified" ? "pl-0" : ""
          } ${header.text === "Student Name" ? "pl-[100px]" : ""} `}
        >
          {header.text}
        </p>
        {header.noArrow
          ? ""
          : header.willDisplayDownArrow === null || header.text.lenght === 0
          ? ""
          : header.willDisplayDownArrow ||
            header.willDisplayDownArrow === undefined
          ? downArrow
          : upArrow}
      </div>
    </th>
  ) : dataFor === "popularServices" ? (
    <th className="first:rounded-l-[5.333px] last:rounded-r-[5.333px]  bg-secondary-10 text-center py-[18.75px] text-[17.5px] font-medium leading-[21px] text-secondary">
      <div
        onClick={() => header.onCick && header.onCick()}
        className={`flex items-center ${
          header.text === "Service" && "w-[205.6px] pl-[21.25px] text-left"
        }
${
  header.text === "Actively Using" && "w-[168.5px] pr-[11.5px] text-left"
} flex gap-0
`}
      >
        <p>{header.text}</p>
        {header.noArrow
          ? ""
          : header.willDisplayDownArrow === null || header.text.lenght === 0
          ? ""
          : header.willDisplayDownArrow ||
            header.willDisplayDownArrow === undefined
          ? downArrow
          : upArrow}
      </div>
    </th>
  ) : dataFor === "assignedStudents" ? (
    <th className="text-center py-[18.75px] bg-secondary-10 text-[17.5px] font-medium leading-[21px] text-secondary first:rounded-l-[5.333px] last:rounded-r-[5.333px]">
      <div
        onClick={() => header.onCick && header.onCick()}
        className={`${
          header.text === "Student Name"
            ? "pl-[60px] text-left md:w-[278px]"
            : header.text === "Email"
            ? "md:w-[288px]"
            : header.text === "Phone"
            ? "md:w-[168px]"
            : header.text === "Service(s)"
            ? "md:w-[178px]"
            : header.text === "Topic(s)"
            ? "md:w-[198px]"
            : header.text === "Status"
            ? "md:w-[168px]"
            : ""
        }
        ${header.text === "Date Assigned" && "text-center"}
        ${header.text === "Status" && "text-center ps-[20px]"}
${
  header.text === "Actively Using" && "w-[168.5px] pr-[11.5px] text-left"
} flex gap-0
`}
      >
        <p>{header.text}</p>
        {header.noArrow
          ? ""
          : header.willDisplayDownArrow === null || header.text.lenght === 0
          ? ""
          : header.willDisplayDownArrow ||
            header.willDisplayDownArrow === undefined
          ? downArrow
          : upArrow}
      </div>
    </th>
  ) : dataFor === "tutorFeedback" ? (
    <th
      className={` pt-[15px] pb-[14px] font-normal text-secondary bg-secondary-10 leading-[21px]
                ${
                  header.text === "Student Name"
                    ? "!pl-[43px] !pr-[0px] rounded-l-[5px]"
                    : ""
                }  
                ${header.text === "Service" ? "!pl-[02px] !pr-[20px]" : ""} 
                ${
                  header.text === "Rating"
                    ? "!pl-[13px] !pr-[0px] text-center"
                    : ""
                }
                ${
                  header.text === "Session Date"
                    ? "!pl-[31px] !pr-[35px] !rounded-r-[5px]"
                    : ""
                } 
                !box-border
                !h-[50.01px]
                `}
    >
      <div
        className={`flex
                ${
                  dataFor === "tutorFeedback" && header.text === "Service"
                    ? "!justify-start"
                    : ""
                }
        ${
          header.text === "Student Name"
            ? "ml-[0px] text-left !justify-start"
            : ""
        } 
        ${header.text === "Assignment Name" ? "ml-[12px] !pl-[66px]" : ""} 
        ${
          header.text === "Email"
            ? "text-left !pl-0 !ml-0 px-0"
            : header.text === "Phone" || header.text === "Full Name"
            ? "pl-[0.8rem]"
            : header.text === "Assignment Name" || header.text === "Assignment"
            ? `justify-start ${
                header.text === "Assignment"
                  ? "ps-6 overflow-hidden !pl-[66px]"
                  : ""
              }`
            : "justify-center"
        } items-center ${
          noArrow || header.noArrow
            ? ""
            : `${
                header.willDisplayDownArrow ||
                header.willDisplayDownArrow === undefined
                  ? styles.marker
                  : header.willDisplayDownArrow === null
                  ? ""
                  : styles.upArrow
              }`
        } ${wrapperClassName ?? wrapperClassName} `}
        onClick={() =>
          header.text === "Full Name" && dataFor === "allUsers"
            ? null
            : header.onCick && header.onCick()
        }
      >
        {header.text === "Full Name" && dataFor === "allUsers" ? (
          <SCheckbox
            checked={checkedHeader}
            onChange={handleCheckboxChange}
            checkbox_Class={"pl-[20px] mr-[30px]"}
          />
        ) : (
          ""
        )}
        {header.text === "Full Name" && dataFor === "allUsers" ? (
          <div onClick={() => header.onCick && header.onCick()}>
            <span className="text-center text-[17.5px]">{header.text}</span>
          </div>
        ) : header.text === "Assignment Name" &&
          dataFor === "assignedTestsStudents" ? (
          <div className="text-left text-[17.5px] font-medium ml-[39px] !pl-[66px]">
            {header.text}
          </div>
        ) : (
          <div
            className={` text-[17.5px] ${
              header.text === "Score" ? "text-center" : "text-left"
            }`}
          >
            {header.text}
          </div>
        )}
      </div>
    </th>
  ) : dataFor === "serviceRates" ? (
    <th
      className={`px-[1.2rem] !h-[50px] font-normal whitespace-nowrap text-secondary text-center cursor-pointer first:rounded-l-[5.333px] last:rounded-r-[5.333px] ${
        header.className ? header.className : ""
      } ${header.text === "Email" ? "text-left !pl-[0.5rem] !ml-0 px-0" : ""} ${
        flag ? styles["no-arrow"] : ""
      } bg-secondary-10 ${
        header.text === "% of Business" ? " rounded-r-[5.333px]" : ""
      } ${
        header.text == "Service"
          ? "!pl-0"
          : header.text == "Hourly Rate"
          ? "!pl-[7rem] !text-right !pr-0"
          : null
      } `}
    >
      <div
        className={`flex  
                            ${header.text === "Service" ? "!pl-[27px]" : ""}
                            ${
                              header.text === "Hourly Rate"
                                ? "justify-end !pr-[18px]"
                                : ""
                            }
                items-center ${
                  noArrow || header.noArrow
                    ? ""
                    : `${
                        header.willDisplayDownArrow ||
                        header.willDisplayDownArrow === undefined
                          ? styles.marker
                          : header.willDisplayDownArrow === null
                          ? ""
                          : styles.upArrow
                      }`
                } ${wrapperClassName ?? wrapperClassName} `}
        onClick={() =>
          header.text === "Full Name" && dataFor === "allUsers"
            ? null
            : header.onCick && header.onCick()
        }
      >
        {header.text === "Full Name" && dataFor === "allUsers" ? (
          <SCheckbox
            checked={checkedHeader}
            onChange={handleCheckboxChange}
            checkbox_Class={"pl-[20px] mr-[30px]"}
          />
        ) : (
          ""
        )}
        {header.text === "Full Name" && dataFor === "allUsers" ? (
          <div onClick={() => header.onCick && header.onCick()}>
            <span className="text-center text-[17.5px]">{header.text}</span>
          </div>
        ) : header.text === "Assignment Name" &&
          dataFor === "assignedTestsStudents" ? (
          <div className="text-left text-[17.5px] font-medium ml-[39px] !pl-[66px]">
            {header.text}
          </div>
        ) : (
          <div
            className={` text-[17.5px] ${
              header.text === "Score" ? "text-center" : "text-left"
            }`}
          >
            {header.text}
          </div>
        )}
      </div>
    </th>
  ) : (
    <th
      className={`px-[1.2rem]  py-[20px] font-normal whitespace-nowrap text-secondary text-center cursor-pointer first:rounded-l-[5.333px] last:rounded-r-[5.333px] ${
        header.className ? header.className : ""
      } ${header.text === "Email" ? "text-left !pl-[0.5rem] !ml-0 px-0" : ""} ${
        flag ? styles["no-arrow"] : ""
      } bg-secondary-10 ${
        header.text === "% of Business" ? " rounded-r-[5.333px]" : ""
      } ${
        header.text == "Service"
          ? "!pl-0"
          : header.text == "Hourly Rate"
          ? "!pl-[7rem] !text-right !pr-0"
          : null
      } `}
    >
      <div
        className={`flex  
        ${header.text === "Student Name" ? "ml-[40px] text-left " : ""} 
        ${header.text === "Assignment Name" ? "ml-[12px]" : ""} 
        ${
          header.text === "Email"
            ? "text-left !pl-0 !ml-0 px-0 min-w-[180px]"
            : // ${header.text === "Assignment Name" ? "ml-[12px] !pl-[66px]" : ""}
            // ${header.text === "Email" ? "text-left !pl-0 !ml-0 px-0":
            header.text === "Phone" || header.text === "Full Name"
            ? "pl-[0.8rem]"
            : header.text === "Assignment Name" || header.text === "Assignment"
            ? `justify-start ${
                header.text === "Assignment"
                  ? "ps-6 overflow-hidden !pl-[66px]"
                  : ""
              }`
            : "justify-center"
        } items-center ${
          noArrow || header.noArrow
            ? ""
            : `${
                header.willDisplayDownArrow ||
                header.willDisplayDownArrow === undefined
                  ? styles.marker
                  : header.willDisplayDownArrow === null
                  ? ""
                  : styles.upArrow
              }`
        } ${wrapperClassName ?? wrapperClassName} `}
        onClick={() =>
          header.text === "Full Name" && dataFor === "allUsers"
            ? null
            : header.onCick && header.onCick()
        }
      >
        {header.text === "Full Name" && dataFor === "allUsers" ? (
          <SCheckbox
            checked={checkedHeader}
            onChange={handleCheckboxChange}
            checkbox_Class={"pl-[20px] mr-[30px]"}
          />
        ) : (
          ""
        )}
        {header.text === "Full Name" && dataFor === "allUsers" ? (
          <div onClick={() => header.onCick && header.onCick()}>
            <span className="text-center text-[17.5px]">{header.text}</span>
          </div>
        ) : header.text === "Assignment Name" &&
          dataFor === "assignedTestsStudents" ? (
          <div className="text-left text-[17.5px] font-medium ml-[39px] ">
            {header.text}
          </div>
        ) : (
          <div
            className={` text-[17.5px] ${
              header.text === "Score" ? "text-center" : "text-left"
            }`}
          >
            {header.text}
          </div>
        )}
      </div>
    </th>
  );
}
