import styles from './Tooltip.module.css'
import TooltipTriangle from './TooltipTriangle'
import { useEffect, useState } from 'react'

const Tooltip = ({ anchorComponent, tooltipContent, open, tooltipStyleClass, full, tooltipTriangleStyling, children,tooltipStyles, tooltipTriangleStyles, parentStyles, parentClassName }) => {

    const [_open, _setOpen] = useState(false)


    return (
        <div className={`${styles.parent} ${full ? styles.w100 : ""} ${parentClassName}`} onMouseEnter={(e) => _setOpen(true)} onMouseLeave={(e) => _setOpen(false)} >
            {anchorComponent}
            {children}
            {
                _open ?
                    <div className={`${styles.tooltipComponent} ${tooltipStyleClass}`} style={{...tooltipStyles}}>
                        {tooltipContent}
                        <TooltipTriangle className={`${styles.tooltipTriangle} ${tooltipTriangleStyling}`}  style={{...tooltipTriangleStyles}}/>
                    </div> :
                    <></>
            }
        </div>
    )
}

export default Tooltip
