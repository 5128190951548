import React, { useRef, useState } from "react";
import TooltipIcon from "../../assets/icons/SettingsIcon/TooltipIcon";
import ArrowIcon from "../../assets/icons/SettingsIcon/ArrowIcon";
import CopyClipboard from "../../assets/icons/SettingsIcon/CopyClipboard";
import CustomiseSignupForm from "./Popups/CustomiseSignupForm";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Tooltip from "../../components/Tooltip/Tooltip";
import MoreInfoIcon from "../../components/MoreInfoIcon/MoreInfoIcon";
import { getTooltipStyleClass } from "../../utils/commonlyUsedTooltipStyles";
import Loader from "../../components/Loader";

const CustomSignup = () => {
  const urlRef = useRef();
  const [openQuestioPopup, setOpenQuestionPopup] = useState(false);
  const { company, customSubDomain } = useSelector(
    (state) => state.organization?.organization
  );
  const [previewLoading, setPreviewLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rightIconDisabled, setRightIconDisabled] = useState(false);
  const [leftIconDisabled, setLeftIconDisabled] = useState(true);
  console.log(page);

  const PreviewPageManage = (move) => {
    if (move == "left") {
      if (page > 1) setPage(page - 1), setRightIconDisabled(false), setPreviewLoading(true);
      if (page == 2) {
        setLeftIconDisabled(true);
      }
    } else if (move == "right") {
      if (page < 3) setPage(page + 1), setLeftIconDisabled(false), setPreviewLoading(true);
      if (page == 2) setRightIconDisabled(true);
    }
  };

  return (
    <>
      <div className="px-4 py-5 xl:p-9">
        <div>
          <div>
            <div className="flex items-center gap-[6px]">
              {" "}
              <h2 className="font-semibold text-[20px] leading-[30px] text-secondary">
				Student/Parent Signup              
			  </h2>{" "}
              <Tooltip
                tooltipStyles={getTooltipStyleClass({ width: "320px" })}
                tooltipContent={
                  "Take control of what information needs to be collected from a parent or student when they are signing up with your organization on Evallo. Advanced customizations are available for each question to make sure you capture all information about your clients as per your needs."
                }
              >
                <MoreInfoIcon />
              </Tooltip>
            </div>
            <div className="font-normal text-base text-secondary-80">
              Add single-select, multi-select or free text questions to the sign
              up form which your clients see during signup. Limits apply as per
              plan.
            </div>
          </div>

          <div className="font-normal text-base flex gap-1 flex-col xl:flex-row mt-6 xl:mt-3">
            {" "}
            <div className="xl:text-secondary-80 xl:leading-[24px] leading-[17.5px] text-secondary">
              Form link (public):
            </div>{" "}
            <div className="flex items-center gap-3 border xl:border-none border-secondary-30 px-4 xl:px-0 py-[14px] xl:py-0 rounded-[6px]">
              <a
                ref={urlRef}
                className="text-secondary-60 xl:text-secondary"
                href={`${customSubDomain?.registeredSubDomain ? `https://${customSubDomain?.registeredSubDomain}.evallo.org/signup/user` : `${process.env.REACT_APP_FE_URL}/signup/user?orgName=${company}`}`}
                target="_blank"
              >
                {new URL(customSubDomain?.registeredSubDomain ? `https://${customSubDomain?.registeredSubDomain}.evallo.org/signup/user` : `${process.env.REACT_APP_FE_URL}/signup/user?orgName=${company}`).href}
              </a>

              <div
                className="cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                   new URL(`${customSubDomain?.registeredSubDomain ? `https://${customSubDomain?.registeredSubDomain}.evallo.org/signup/user` : `${process.env.REACT_APP_FE_URL}/signup/user?orgName=${company}`}`).href
                  );

                  toast.success("Link copied to clipboard.");
                }}
              >
                <CopyClipboard />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full max-w-[848px] mt-6">
          <div className="flex justify-between items-center">
            <div className="flex gap-3">
              <div
                className= { `h-6 w-6 rounded-full flex justify-center items-center border-[1.5px] cursor-pointer ${leftIconDisabled ? "pointer-events-none border-secondary-20" : "border-secondary-60 " }`} 
                onClick={() => {
                  PreviewPageManage("left");
                }}
              >
                <ArrowIcon />
              </div>
              <div
                className= {`h-6 w-6 rounded-full flex justify-center items-center border-[1.5px]  cursor-pointer rotate-180 ${rightIconDisabled ?" pointer-events-none border-secondary-20 " :" border-secondary-60 "} `}
                onClick={() => PreviewPageManage("right")}
              >
                <ArrowIcon />
              </div>

              <p className=" text-secondary-60 ">{page} of 3 </p>
            </div>
            <button
              className="px-5 py-[10px] rounded-[6px] bg-blue-70 text-white"
              onClick={(e) => setOpenQuestionPopup(true)}
            >
              Edit Form
            </button>
          </div>
          <div
            className="mt-4 shadow-[0px_4.05px_26.98px_0px_#000AFF29] "
            style={{ width: "100%", height: "530px", overflow: "hidden" }}
          >
            {previewLoading && (
              <div className=" w-full h-full flex items-center justify-center  gap-3">
                {" "}
                Loading Preview{" "}
                <div className=" border-2 border-t-black animate-spin w-4 h-4 rounded-xl ">
                  {" "}
                </div>{" "}
              </div>
            )}
            <iframe
              style={{
                width: "200%",
                height: "200%",
                transform: "scale(0.5)",
                transformOrigin: "0 0",
              }}
              onLoad={() => setPreviewLoading(false)}
              src={`${customSubDomain?.registeredSubDomain ? `https://${customSubDomain?.registeredSubDomain}.evallo.org/signup/user?` : `${process.env.REACT_APP_FE_URL}/signup/user?orgName=${company}&`}view=preview&page=${page}`}
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
      {openQuestioPopup && (
        <CustomiseSignupForm
          openQuestioPopup={openQuestioPopup}
          setOpenQuestionPopup={setOpenQuestionPopup}
        />
      )}
    </>
  );
};

export default CustomSignup;
