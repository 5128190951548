/** @jsxImportSource @emotion/react */
import styles from './RequestResetPasswordScreen.module.css'
import { useEffect,useState } from 'react'
import ArrowIconLeftV2 from '../../ArrowIconLeftV2/ArrowIconLeftV2'
import ButtonV2 from '../../ButtonV2/ButtonV2'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useForgotPasswordMutation } from '../../../app/services/auth'
import { toast } from 'react-toastify'
import LoaderIcon from '../../LoaderIcon/LoaderIcon'
import { css } from '@emotion/react'

const RequestResetPasswordScreen = (props) =>{

    const navigate = useNavigate()

    const [email, setEmail] = useState(null)
    const [isButtonEnabled, setIsButtonEnabled] = useState(false)
    const {setSignupStage, primaryCTAStyles} = useOutletContext()
    const [forgotPasswordReq, forgotPasswordReqResp] = useForgotPasswordMutation()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{setSignupStage("resetPassword")},[])

    useEffect(()=>{
        const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        setIsButtonEnabled(emailFormat.test(email))
    },[email])


    const handleFormSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        const email = e.target.email.value.trim()
        forgotPasswordReq({
            email : email
        }).then((res)=>{
            if(res.error){
                toast.error(res.error.data.message)
            } else {
                toast.success("Password reset link sent successfully",{position : "top-center", autoClose : 1000, onClose : ()=>{navigate(`../request-success?email=${email}`)}})
            }
            setIsLoading(false)
        })
    }


    console.log(primaryCTAStyles)

    return(
        <div className={styles.container}>
            <h3>Reset Password</h3>
            <div className='text-center'>Please enter the email address registered with your Evallo account, and we'll send a link to reset your password.</div>
            <form className={styles.resetForm} onSubmit={(e)=>handleFormSubmit(e)}>
                <input type="email" className={styles.emailInput} name="email" placeholder="Enter your email address" id="email" onChange={(e)=>setEmail(e.target.value.trim())}/>
                <ButtonV2 disabled={!isButtonEnabled} className="flex flex-row justify-center !text-[16px]" {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.bg})}}>
                    {!isLoading ? "Reset your password" : <div className='flex justify-center items-center w-full'><LoaderIcon /></div>}
                </ButtonV2>
            </form>
            <div className={styles.signupCTA}>
                Don't have an account? <Link to="/signup" {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.text})}}>Sign up</Link>
            </div>
            <Link className={styles.backButton} to="/signin">
                <div className={styles.content} {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.text})}}>
                    <div><ArrowIconLeftV2 /></div>
                    <div {...(primaryCTAStyles) && {css : css({...primaryCTAStyles.text})}}>Back to login</div>
                </div>
            </Link>
        </div>
    )
}

export default RequestResetPasswordScreen
