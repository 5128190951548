import SidebarItem from "../SidebarItem/SidebarItem"
import styles from "./SectionStyles.module.css"

//Extract section Item into another component
function Section2 ({activeLink, onElementClick, config, componentProps}) {
	


	return (
		<div className={styles.section2container}>
			{
				config?.section2.slice(0,1).map((item,idx)=>(
					
					<SidebarItem className={`${styles.sectionItem} ${activeLink === item.elLink ? styles.sectionItemActive : ""}`} onClick = {()=>{onElementClick(idx, item)}} 
						style={{padding : "0px",
							visibility : componentProps[0].main.hideForRole ? "hidden" : componentProps[0]?.main?.isVisible === false? "hidden" : "visible"
						}} 
						isLink={item.isLink} to={item.elLink}>

						{item.icon}
						{item.displayName}
						{item.component && item.component(componentProps[0].main)}
						{item.childComponent && item.childComponent(componentProps[0].child)}
					</SidebarItem>
				))
			}
			<div className={styles.subContainer}>
				{
					config.section2.slice(1).map((item, idx)=>(
					<SidebarItem 
							className={`${styles.sectionItem} ${activeLink === item.elLink ? styles.sectionItemActive : ""}`} 
							onClick = {()=>{onElementClick(idx, item)}} 
							isLink={item.isLink} to={item.elLink} 
							style={{
									display : componentProps[idx+1]?.main?.hideCompletely ? "none" : "" ,
									visibility : componentProps[idx+1]?.main?.hideForRole ? "hidden" : componentProps[idx+1]?.main?.isVisible === false? "hidden" : "visible"
							}}
					>

						{item.icon}
						{item.displayName}
						{item.component && item.component(componentProps[idx+1].main)}
						{item.childComponent && item.childComponent(componentProps[idx+1].child)}
					</SidebarItem>
					))
				}
			</div>
		</div>
	)

}


export default Section2
