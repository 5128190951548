import { forwardRef, useEffect, useState } from "react"
import styles from "./FilterInputController.module.css"
import { Tooltip } from "@mui/material";
import CautionIcon from "../../../CautionIcon/CautionIcon";

function MiddleField({isActive, setIsActive, title ,auxTitle ,selectedFilters, placeholder, isTextField, onChange, textFieldValue, dropdownTrigger, setDropdownTrigger, onKeyDown,onClick}) {
	if(!isTextField){
		return (
			<div className={styles.middleField} onClick={(e)=>onClick(e)}>
				<span style={{textWrap : "nowrap"}}>{title}</span>	
				{
					isActive ?
						<>  
							<span style={{backgroundColor : "rgba(202, 206, 218, 1)", width : "1px", height : "12px"}}>&nbsp;</span>
							<span className={styles.auxTitle}>
								{selectedFilters?.length}
								&nbsp;
								{auxTitle}
							</span>
						</>
						: <></>
				}
			</div>
		)	
	} else {
		return (
		<div className={styles.middleField}>
			<input type="text" value={textFieldValue} onChange={(e)=>onChange(e)} placeholder={placeholder} onFocus={(e)=>{setIsActive(true); if(setDropdownTrigger){setDropdownTrigger(true)}}} onBlur={(e)=>{setTimeout(()=>{setIsActive(false);if(setDropdownTrigger){setDropdownTrigger(false)}},500)}} {...(onKeyDown) && {onKeyDown}}/>
		</div>
		)
	}
}



//fieldIcons : {activeIcon, inactiveIcon},
const FilterInputController = forwardRef(function FilterInputController(
	{
	  FieldIcon,
	  onClick,
	  buttonIcons: { closeIcon, openIcon },
	  isMenuOpen,
	  selectedFilters,
	  title,
	  auxTitle,
	  activeLink,
	  elLink,
	  containerStyles,
	  placeholder,
	  isTextField,
	  onChange,
	  textFieldValue,
	  externalTrigger,
	  setExternalTrigger,
	  setActiveLinkExternally = false,
	  dropdownTrigger,
	  setDropdownTrigger,
	  onKeyDown
	},
	ref
  ) {
	const [isActive, setIsActive] = useState(false);
  
	useEffect(() => {
	  if (setActiveLinkExternally) return;
	  if (activeLink) setIsActive(elLink === activeLink);
	}, [activeLink]);
  
	useEffect(() => {
	  if (selectedFilters) {
		setIsActive(selectedFilters.length > 0);
	  }
	}, [selectedFilters]);
  
	const shouldShowTooltip = title === "Time Blocks"; 
  
	const containerContent = (
	  <div
		className={`${styles.container} ${
		  isActive && !isTextField ? styles.containerActive : ""
		} ${isActive && isTextField ? styles.containerActiveTextfield : ""}`}
		style={containerStyles}
		ref={ref}
	  >
		<div className="cursor-pointer" onClick={(e) => onClick(e)}>
		  <FieldIcon activeIcon={isActive} />
		</div>
		<MiddleField
		  {...{
			isActive,
			title,
			auxTitle,
			selectedFilters,
			placeholder,
			isTextField,
			onChange,
			textFieldValue,
			setIsActive,
			dropdownTrigger,
			setDropdownTrigger,
			onKeyDown,
			onClick,
		  }}
		/>
		<button
		  onClick={(e) => {
			console.log("FIC Button Clicked");
			onClick(e);
		  }}
		>
		  {externalTrigger ?? isActive ? closeIcon : openIcon}
		</button>
	  </div>
	);
  
	return shouldShowTooltip ? (
	  <Tooltip
		componentsProps={{
			tooltip: {
				sx: {
				  bgcolor: "white",
				  color: "black",  
				  fontFamily: "Lexend", 
				  padding: 0,       
				},
			  },
			  arrow: {
				sx: {
				  color: "white",  
				},
			  },
			}}
		arrow	
		placement="right"
		title={
		  <div className="bg-white w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26]">
			<div className="flex items-center gap-1">
			  <h2 className="font-medium text-[14px] leading-[21px] text-secondary-solid flex items-center justify-start gap-[4px]">
				<CautionIcon
				  className="text-[#FFC56D] h-[14px] w-[14px]"
				  exclamationColour="#25335A"
				/>
				Not Available
			  </h2>
			</div>
			<div className="font-normal text-[12px] leading-[18px] text-secondary-70">
			  Will be soon available in next patch update.
			</div>
		  </div>
		}
	  >
		{containerContent}
	  </Tooltip>
	) : (
	  containerContent
	);
  });
  
  export default FilterInputController;
  
