import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TuneIcon from '@mui/icons-material/Tune';
import CrmIcon from '../../../components/ProductIcons/CrmIcon';
import CalendarIcon from '../../../components/ProductIcons/CalendarIcon';
import AssignmentIcon from '../../../components/ProductIcons/AssignmentIcon';
import LeafIcon from '../../../components/LeafIcon/LeafIcon';
import CampaignIcon from '../../../components/CampaignIcon/CampaignIcon';
import DiamondIcon from '@mui/icons-material/Diamond';
import PaymentIcon from '@mui/icons-material/Payment';
import ReferralIcon from '../../../components/ReferralIcon/ReferralIcon';

const iconStyles = {height : "14px", width : "14px"}

const sidebarContent = [
    {
        sectionTitle : "GENERAL SETTINGS",
        children : [
            {
                title : "Account",
                children : [],
                link : "account",
                icon : <PersonOutlineIcon style={iconStyles}/>
            },
            {
                title : "Your Business",
                children : [],
                link : "business",
                icon : <StorefrontIcon style={iconStyles}/>
            },
            {
                title : "Preferences",
                children : [
                    { 
                        title : "Time and Date",
                        link : "preferences/time-date"
                    },
					{
						title : "Services & Topics",
						link : "preferences/services-topics"
					}
                ],
                link : null,
                icon : <TuneIcon style={iconStyles}/>,
                refLink : "preferences"
            },
            {
                title : "Referral Management",
                children : [],
                link : "referral-management",
                icon : <ReferralIcon style={iconStyles}/>
            },
        ]
    },
    {
        sectionTitle : "PRODUCT SETTINGS",
        children : [
            {
                title : "CRM",
                link : "crm",
                icon : <CrmIcon style={iconStyles}/>,
                children : [],
                toFill : true
            },
            {
                title : "Calendar",
                link : "calendar",
                icon : <CalendarIcon style={iconStyles}/>,
                children : [],
                toStroke : true
            },
            {
                title : "Assignments",
                link : "assignments",
                icon : <AssignmentIcon style={iconStyles}/>,
                children : [],
                toFill : true
            }
        ]
    },
    {
        sectionTitle : "BRAND HIGHLIGHT",
        children : [
            {
                title : "White-Labeling",
                link : null,
                refLink : "white-labeling",
                icon : <LeafIcon style={iconStyles}/>,
                children : [
                    {
                        title : "Brand Elements",
                        link : "white-labeling/brand-elements"
                    },
					{
                        title : "Color Theme",
                        link : "white-labeling/color-theme"
                    },
                    {
                        title : "Domain Integration",
                        link : "white-labeling/domain-integration"
                    },
                    {
                        title : "Student/Parent Sign-up",
                        link : "white-labeling/student-parent-signup"
                    }
                ]
            },
            {
                title : "Announcements",
                link : "announcements",
                icon : <CampaignIcon style={iconStyles}/>,
                children : []
            }
        ]
    },
    {
        sectionTitle : "SUBSCRIPTION & PAYMENT",
        children : [
            {
                title : "Manage Subscription",
                icon : <DiamondIcon style={iconStyles}/>,
                link : "manage-subscription",
                children : []
            },
            {
                title : "Payment Methods",
                icon : <PaymentIcon style={iconStyles}/>,
                link : "payment-methods",
                children : []
            }

        ],
        link : null,
    }
]


export {
    sidebarContent
}
