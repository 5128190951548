import axios from "axios";
import { getAuthHeader } from "../app/constants/constants";
import { store } from "../app/store";
import { updateOrganization } from "../app/slices/organization";

async function fetchAndSetOrgDetails(orgId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/user/org/details/${orgId}`,
      { headers: getAuthHeader() }
    );
    store.dispatch(updateOrganization(response.data.organisation));
  } catch (error) {
    console.error(
      `Error fetching organization details for ID ${orgId}:`,
      error
    );
  }
}

async function updateOrg(orgId, fields, op = null) {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/orgs`,
      {
        orgId: orgId,
        op: op ?? "update",
        fields: fields,
      },
      { headers: getAuthHeader() }
    );
    console.log(`Organization ${orgId} updated successfully:`, response.data);
  } catch (error) {
    console.error(`Error updating organization ${orgId}:`, error);
    throw error; // Optional: rethrow to propagate error to caller
  }
}
async function updateOrgSubsriptionCode(orgId, fields) {
  console.log("orgId, fields", orgId, fields);
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/orgs/settings/subscriptionCode`,
      {
        orgId: orgId,
        op: "update",
        fields: {
          settings: {
            subscriptionCode: fields,
          },
        },
      },
      { headers: getAuthHeader() }
    );
    return response;
    console.log(`Organization ${orgId} updated successfully:`, response.data);
  } catch (error) {
    console.error(`Error updating organization ${orgId}:`, error);
    throw error; // Optional: rethrow to propagate error to caller
  }
}
async function updateOrgCustomBrands(orgId, fields) {
  console.log("orgId, fields", orgId, fields);
  const formData = new FormData();
  formData.append("orgId", orgId);
  for (const key in fields) {
    formData.append(key, fields[key]);
  }
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/orgs/settings/customElements/upload`,
      formData,
      { headers: getAuthHeader() }
    );
    // store.dispatch(updateOrganization(response.data.organisation));

    console.log(`Organization ${orgId} updated successfully:`, response.data);
    return response;
  } catch (error) {
    console.error(`Error updating organization ${orgId}:`, error);
    throw error; // Optional: rethrow to propagate error to caller
  }
}

async function checkUniqueSubdomain(targetSubDomain) {
  try {
    const response = await axios({
      method: "GET",
      url:
        process.env.REACT_APP_BASE_URL +
        "api/orgs?checkUniqueSubDomain=true&targetSubDomain=" +
        targetSubDomain,
      headers: getAuthHeader(),
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

async function deleteOrgCustomBrandsLogo(orgId, fields) {
  console.log("orgId, fieldsssss", orgId, fields);
  if (!orgId || !fields) {
    throw new Error("Both orgId and field must be provided");
  }
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/orgs/settings/customElements/delete`,
      { orgId, fields },
      { headers: getAuthHeader() }
    );
    // store.dispatch(updateOrganization(response.data.organisation));

    console.log(`Organization ${orgId} updated successfully:`, response.data);
    return response;
  } catch (error) {
    console.error(`Error updating organization ${orgId}:`, error);
    throw error; // Optional: rethrow to propagate error to caller
  }
}

export {updateOrgCustomBrands, fetchAndSetOrgDetails, updateOrg, updateOrgSubsriptionCode, checkUniqueSubdomain, deleteOrgCustomBrandsLogo };
