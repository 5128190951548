import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "../../../components/Tooltip/Tooltip";
import InfoIcon from "../../../components/ProductIcons/InfoIcon";
import InfoHeader from "./InfoHeader";
import InfoDisplayCard from "./InfoDisplayCard";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { studentsServed, instructionFormat } from "./BusinessServiceData";
import {
  useUpdateOrgLogoMutation,
  useDeleteOrgLogoMutation,
  useUpdateUserOrganizationMutation,
} from "../../../app/services/organization";
import { updateOrganization } from "../../../app/slices/organization";
import AddLogoPopUp from "./EditPopUps/AddLogoPopUp";
import BusinessAddressPopUp from "./EditPopUps/BusinessAddressPopUp";
import CompanyInfoPopUp from "./EditPopUps/CompanyInfoPopUp";
import LoaderIcon from "../../../components/LoaderIcon/LoaderIcon";
import { triggerToast } from "../../../utils/toastController";
import { getTooltipStyleClass } from "../../../utils/commonlyUsedTooltipStyles";
import TooltipIcon from "../../../assets/icons/SettingsIcon/TooltipIcon";
import LockedStateOverlayVariant2 from "../../../components/LockedStateOverlayVariant2/LockedStateOverlayVariant2";
import { updateOrg, fetchAndSetOrgDetails } from "../../../utils/orgService";
import AddressDisplayCard from "./AddressDisplayCard";
const inputCheckboxStyling = { display: "block" };
import AddIcon from "@mui/icons-material/Add";

const YourBusiness = () => {
  const dispatch = useDispatch();
  const [logoUrl, setLogoUrl] = useState(null);
  const [loadingRemoveLogo, setLoadingRemoveLogo] = useState(false);
  const [loadingFormatOfInstruction, setLoadingFormatOfInstruction] =
    useState(false);
  const [loadingStudentsServed, setLoadingStudentsServed] = useState(false);
  const [openAddLogoPopUp, setOpenAddLogoPopUp] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [isEditingAddresses, setIsEditingAddresses] = useState(false);
  const [allAddresses, setAllAddresses] = useState();
  const [openBusinessAddressPopUp, setOpenBusinessAddressPopUp] =
    useState(false);
  const [openCompanyInfoPopUp, setOpenCompanyInfoPopUp] = useState(false);
  const [updateOrgLogo, updateOrgLogoStatus] = useUpdateOrgLogoMutation();
  const [deleteOrgLogo, deleteOrgLogoStatus] = useDeleteOrgLogoMutation();
  const [updateUserOrg, updateUserOrgStatus] =
    useUpdateUserOrganizationMutation();

  const { subscriptionV2 } = useSelector((state) => state.subscription);

  const {
    company,
    supportEmail,
    website,
    companyType,
    addresses, // Updated to be an array of addresses
    orgBussinessLogo,
    formatOfInstruction,
    studentServed,
    _id,
    privacyPolicy,
    termsAndConditions,
  } = useSelector((state) => state.organization?.organization);

  const [instructions, setInstructions] = useState(instructionFormat);
  const [students, setStudents] = useState(studentsServed);
  const [initialFormatOfInstruction, setInitialFormatOfInstruction] = useState(
    []
  );
  const [initialStudentsServed, setInitialStudentsServed] = useState([]);
  const [values, setValues] = useState({
    formatOfInstruction: formatOfInstruction ? formatOfInstruction : [],
    studentServed: studentServed ? studentServed : [],
  });

  useEffect(() => {
    setLogoUrl(orgBussinessLogo ? orgBussinessLogo : null);
  }, [orgBussinessLogo]);

  useEffect(() => {
    setValues({ ...values });

    let formatArray = instructions;
    formatOfInstruction?.forEach((element) => {
      formatArray = formatArray.map((topic) => {
        return topic.text === element
          ? { ...topic, checked: true }
          : { ...topic };
      });
    });

    setInstructions(formatArray);
    setInitialFormatOfInstruction(formatOfInstruction || []);

    let studentArray = students;
    studentServed?.forEach((element) => {
      studentArray = studentArray.map((topic) => {
        return topic.text === element
          ? { ...topic, checked: true }
          : { ...topic };
      });
    });

    setStudents(studentArray);
    setInitialStudentsServed(studentServed || []);
  }, [formatOfInstruction, studentServed]);

  const handleRemoveLogo = () => {
    setLoadingRemoveLogo(true);
    deleteOrgLogo({ updatefieldName: "orgBussinessLogo", id: _id }).then(
      (res) => {
        setLoadingRemoveLogo(false);
        if (res.error) {
          triggerToast(<div>{res.error.message}</div>, "error", {
            autoClose: 3000,
          });
          return;
        }
        setLogoUrl(null);
        triggerToast(<div>Logo removed Successfully!</div>, "success", {
          autoClose: 3000,
        });
      }
    );
  };

  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const handleCheckboxChange = (text, arr, setBoxValue, name) => {
    const temp = arr.map((topic) => {
      return topic.text === text
        ? { ...topic, checked: !topic.checked }
        : { ...topic };
    });

    let selectedData = temp
      .filter((topic) => topic.checked)
      .map((topic) => topic.text);

    setValues((prevValues) => ({
      ...prevValues,
      [name]: selectedData,
    }));

    setBoxValue(temp);
  };

  const handleFormatInstructionSave = async () => {
    setLoadingFormatOfInstruction(true);
    try {
      const res = await updateUserOrg({
        formatOfInstruction: values.formatOfInstruction,
        onSaveButtonClicked: true,
      });

      if (res?.error) {
        triggerToast(<div>{res.error.message}</div>, "error", {
          autoClose: 3000,
        });
        setLoadingFormatOfInstruction(false);
      } else if (res?.data) {
        setLoadingFormatOfInstruction(false);
        triggerToast(
          <div>Format of Instructions Updated Successfully!</div>,
          "success",
          { autoClose: 2000 }
        );
        dispatch(updateOrganization(res.data.orgDetails));
      }
    } catch (err) {
      console.log(err);
      setLoadingFormatOfInstruction(false);
    }
  };

  const handleStudentsServedSave = async () => {
    setLoadingStudentsServed(true);
    try {
      const res = await updateUserOrg({
        studentServed: values.studentServed,
        onSaveButtonClicked: true,
      });

      if (res?.error) {
        triggerToast(<div>{res.error.message}</div>, "error", {
          autoClose: 3000,
        });
        setLoadingStudentsServed(false);
      } else if (res?.data) {
        setLoadingStudentsServed(false);
        triggerToast(
          <div>Type of Students Served Updated Successfully!</div>,
          "success",
          { autoClose: 2000 }
        );

        dispatch(updateOrganization(res.data.orgDetails));
      }
    } catch (err) {
      console.log(err);
      setLoadingStudentsServed(false);
    }
  };

  const saveAddressChanges = async (updatedAddresses) => {
    try {
      const fields = { addresses: updatedAddresses };
      await updateOrg(_id, fields, "update");
      triggerToast(
        <div>Business Addresses Updated Successfully!</div>,
        "success",
        { autoClose: 2000 }
      );
      await fetchAndSetOrgDetails(_id);
      setIsEditingAddresses(false);
    } catch (err) {
      console.log(err);
      triggerToast(err.response.data.message, "error", { autoClose: 3000 });
    }
  };

  const handleDeleteAddress = (index) => {
    const updatedAddresses = allAddresses.filter((_, i) => i !== index);
    setAllAddresses(updatedAddresses);
    setSelectedAddressIndex(null);
    saveAddressChanges(updatedAddresses);
  };

  // Function to open the popup for adding a new address
  const handleAddNewAddress = () => {
    setSelectedAddressIndex(-1);
    setOpenBusinessAddressPopUp(true);
  };

  // Function to open the popup for editing an existing address
  const handleEditAddress = (index) => {
    setSelectedAddressIndex(index);
    setOpenBusinessAddressPopUp(true);
  };

  //reordering so that primary address is at index 0
  useEffect(() => {
    if (addresses) {
      const reorderedAddresses = [...addresses].sort(
        (a, b) => b.isPrimary - a.isPrimary
      );
      setAllAddresses(reorderedAddresses);
    }
  }, [addresses]);

  return (
    <div className="grid gap-[24px] text-secondary md:w-[80%]  px-4 md:px-8 md:py-9 ">
      <div className="flex gap-[6px] font-[600] text-[20px] leading-[30px] items-center ">
        Your Business{" "}
        <Tooltip
          anchorComponent={
            <div className="mt-[2px]">
              <TooltipIcon />
            </div>
          }
          tooltipContent={
            "Add details about your business to help personalize your platform experience"
          }
          tooltipStyles={getTooltipStyleClass({ width: "220px" })}
          tooltipTriangleStyling="mt-[2px] ml-[1px]"
        />
      </div>

      <div className="grid gap-[24px] md:gap-[36px] ">
        <div className="grid gap-[12px]">
          <InfoHeader
            heading={"Brand Logo"}
            editable={
              subscriptionV2?.subscription?.basePackage?.name.includes("Free")
                ? false
                : true
            }
            onEditClick={() => {
              setOpenAddLogoPopUp(true);
            }}
          />
          {subscriptionV2?.subscription?.basePackage?.name.includes("Free") ? (
            <div className="flex justify-center items-center  relative min-h-[200px] ">
              <LockedStateOverlayVariant2
                outerContainerStyle={{ height: "200px" }}
              />
            </div>
          ) : (
            <div className="grid px-[16px] py-[12px] gap-[12px]">
              <label className="text-secondary-80 font-[400] text-[14px] leading-[21px]">
                Upload your organization’s logo to make it easier for your
                customers and employees to recognize you.{" "}
              </label>

              <div className="grid gap-[12px]">
                {logoUrl ? (
                  <>
                    <img
                      src={logoUrl}
                      className="h-[100px] max-w-[358px] object-contain"
                      alt="orgDefaultLogo"
                    />
                    <div>
                      <div className="flex justify-start md:justify-end  gap-[8px]">
                        <div
                          className="text-blue-70 border border-blue-70 w-fit px-[12px] py-[4px] flex gap-[4px] items-center  rounded-[4px] cursor-pointer"
                          onClick={() => {
                            setOpenAddLogoPopUp(true);
                          }}
                        >
                          Change
                        </div>
                        <div
                          className="text-secondary-60 border border-secondary-60 w-fit min-w-[79px] flex justify-center  px-[12px] py-[4px]  gap-[4px] items-center  rounded-[4px] cursor-pointer"
                          onClick={() => {
                            handleRemoveLogo();
                          }}
                        >
                          {loadingRemoveLogo ? <LoaderIcon /> : "Remove"}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="bg-blue-70 w-fit px-[12px] py-[4px] flex gap-[4px] items-center text-white rounded-[4px] cursor-pointer"
                      onClick={() => {
                        setOpenAddLogoPopUp(true);
                      }}
                    >
                      <FileUploadOutlinedIcon
                        style={{
                          color: "#FFFFFF",
                          height: "14px",
                          width: "14px",
                        }}
                      />
                      <div>Upload Image</div>
                    </div>
                    <label className="text-secondary-60 font-[400] text-[12px] leading-[12px] ">
                      PNG or JPG. Max height: 512 px. Max size 2 MB.
                    </label>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="grid gap-[12px] ">
          <InfoHeader
            heading={"Company Info"}
            editable={true}
            onEditClick={() => {
              setOpenCompanyInfoPopUp(true);
            }}
          />
          <div className="grid px-[16px] py-[12px] gap-[12px] ">
            <div className="grid md:flex gap-[24px] w-full ">
              <div className="md:basis-1/2 max-w-[100%] md:max-w-[50%]">
                <InfoDisplayCard title={"Company Name"} info={company} />
              </div>

              <div className="border-b-[1px] border-secondary-10 md:border-none "></div>

              <div className="md:basis-1/2 max-w-[100%] md:max-w-[50%]">
                <InfoDisplayCard
                  title={"Company Type"}
                  info={companyType ? companyType : ""}
                />
              </div>
            </div>
            <div className="border-b-[1px] border-secondary-10 "></div>
            <div className="grid md:flex gap-[24px]  ">
              <div className="md:basis-1/2 max-w-[100%] md:max-w-[50%]">
                <InfoDisplayCard
                  title={"Website"}
                  info={website ? website : ""}
                  link={website ? website : null}
                />
              </div>{" "}
              <div className="border-b-[1px] border-secondary-10 md:border-none "></div>
              <div className="md:basis-1/2 max-w-[100%] md:max-w-[50%]">
                <InfoDisplayCard
                  title={"Support Email"}
                  info={supportEmail ? supportEmail : ""}
                  link={supportEmail ? supportEmail : null}
                  tooltipContent={
                    "Your clients (parents & students) will see this email in all automated communication as well as notifications. In case of any difficulties, your team will be their first point of contact, so please ensure that this email is active and you have access to the inbox. When parents receive reminders, updates, or any other communication from Evallo, this email will be shown to them in case they want to reach out regarding any inquiries. This can be a general contact email, or an inquiry email that you use for all operational communication with your clients."
                  }
                />
              </div>
            </div>
            <div className="border-b-[1px] border-secondary-10 "></div>
            <div className="grid md:flex gap-[24px] w-full ">
              <div className="md:basis-1/2 max-w-[100%] md:max-w-[50%]">
                <InfoDisplayCard
                  title={"Terms & Conditions (URL)"}
                  info={termsAndConditions ? termsAndConditions : ""}
                  link={termsAndConditions}
                  tooltipContent={
                    "If you have a Terms & Conditions policy document, provide a link to it in this section"
                  }
                />
              </div>{" "}
              <div className="border-b-[1px] border-secondary-10 md:border-none "></div>
              <div className="md:basis-1/2 max-w-[100%] md:max-w-[50%]">
                <InfoDisplayCard
                  title={"Privacy Policy (URL)"}
                  info={privacyPolicy ? privacyPolicy : ""}
                  link={privacyPolicy}
                  tooltipContent={
                    "If you have a Privacy Policy document, provide a link to it in this section"
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid gap-[12px]">
          <InfoHeader
            heading={"Business Address"}
            editable={true}
            onEditClick={() => {
              if (allAddresses.length > 0) {
                setIsEditingAddresses((prev) => !prev);
              }
            }}
          />
          <div>
            {allAddresses && allAddresses.length > 0
              ? allAddresses.map((address, index) => (
                  <AddressDisplayCard
                    key={index}
                    address={address}
                    isEditing={isEditingAddresses}
                    onEdit={() => {
                      handleEditAddress(index);
                    }}
                    onDelete={() => handleDeleteAddress(index)}
                  />
                ))
              : ""}
          </div>

          <div
            className="flex text-blue-70 gap-[2px] font-[500] cursor-pointer items-center px-[10px]"
            onClick={() => {
              handleAddNewAddress();
            }}
          >
            <AddIcon />
            Add Another Location
          </div>
        </div>
        <div className="grid gap-[12px] ">
          <InfoHeader heading={"Format of Instruction"} />
          <div className="grid px-[16px] py-[12px] gap-[12px]">
            <label className="text-secondary-80 font-[400] text-[14px] leading-[21px]">
              Select all the instruction modes your organization provides.
            </label>

            <div className="flex justify-between">
              <div className="grid gap-[12px] w-[90%] ">
                {instructions.map((item, index) => (
                  <div className="flex gap-[8px] items-center " key={index}>
                    <input
                      style={inputCheckboxStyling}
                      id={`instructionFormat_${index}`}
                      type="checkbox"
                      checked={item.checked}
                      onChange={() =>
                        handleCheckboxChange(
                          item.text,
                          instructions,
                          setInstructions,
                          "formatOfInstruction"
                        )
                      }
                      className="w-4 h-4 text-blue-70 ring-secondary-60 cursor-pointer "
                    />
                    <label
                      className="cursor-pointer text-secondary-80  text-[14px] font-[400] leading-[21px]"
                      onClick={() =>
                        handleCheckboxChange(
                          item.text,
                          instructions,
                          setInstructions,
                          "formatOfInstruction"
                        )
                      }
                    >
                      {item.text}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex flex-col justify-end">
                <button
                  className={`${
                    arraysEqual(
                      initialFormatOfInstruction,
                      values.formatOfInstruction
                    )
                      ? "bg-blue-70/40 text-white/40 cursor-not-allowed"
                      : "bg-blue-70 text-white  cursor-pointer "
                  } w-fit min-w-[58px] flex justify-center px-[12px] py-[4px]  gap-[4px] items-center rounded-[4px]`}
                  onClick={handleFormatInstructionSave}
                  disabled={arraysEqual(
                    initialFormatOfInstruction,
                    values.formatOfInstruction
                  )}
                >
                  {loadingFormatOfInstruction ? <LoaderIcon /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-[12px]">
          <InfoHeader heading={"Students Served"} />
          <div className="grid px-[16px] py-[12px] gap-[12px]">
            <label className="text-secondary-80 font-[400] text-[14px] leading-[21px]">
              Select the type of learners your organization caters to.
            </label>

            <div className="flex justify-between">
              <div className="flex flex-col gap-[12px] w-[95%] md:h-[120px] md:flex-wrap ">
                {students.map((item, index) => (
                  <div className="flex gap-[8px] items-center " key={index}>
                    <input
                      style={inputCheckboxStyling}
                      id={`studentsServed_${index}`}
                      type="checkbox"
                      checked={item.checked}
                      onChange={() =>
                        handleCheckboxChange(
                          item.text,
                          students,
                          setStudents,
                          "studentServed"
                        )
                      }
                      className="w-4 h-4 text-blue-70 ring-secondary-60 cursor-pointer "
                    />
                    <label
                      className="cursor-pointer text-secondary-80  text-[14px] font-[400] leading-[21px]"
                      onClick={() =>
                        handleCheckboxChange(
                          item.text,
                          students,
                          setStudents,
                          "studentServed"
                        )
                      }
                    >
                      {item.text}
                    </label>
                  </div>
                ))}
              </div>
              <div className="flex flex-col justify-end">
                <button
                  className={`${
                    arraysEqual(initialStudentsServed, values.studentServed)
                      ? "bg-blue-70/40 text-white/40 cursor-not-allowed"
                      : "bg-blue-70 text-white  cursor-pointer "
                  } w-fit min-w-[58px] flex justify-center px-[12px] py-[4px]  gap-[4px] items-center rounded-[4px]`}
                  onClick={handleStudentsServedSave}
                  disabled={arraysEqual(
                    initialStudentsServed,
                    values.studentServed
                  )}
                >
                  {loadingStudentsServed ? <LoaderIcon /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openAddLogoPopUp && (
        <AddLogoPopUp
          open={openAddLogoPopUp}
          setOpen={setOpenAddLogoPopUp}
          orgId={_id}
          setLogoUrl={setLogoUrl}
          updateLogo={updateOrgLogo}
        />
      )}
      {openBusinessAddressPopUp && selectedAddressIndex !== null && (
        <BusinessAddressPopUp
          open={openBusinessAddressPopUp}
          setOpen={setOpenBusinessAddressPopUp}
          editIndex={selectedAddressIndex}
          setAllAddresses={setAllAddresses}
          allAddresses={allAddresses}
          setIsEditingAddresses={setIsEditingAddresses}
          saveAddressChanges={saveAddressChanges}
        />
      )}

      {openBusinessAddressPopUp && selectedAddressIndex === null && (
        <BusinessAddressPopUp
          open={openBusinessAddressPopUp}
          setOpen={setOpenBusinessAddressPopUp}
          editIndex={selectedAddressIndex}
          setAllAddresses={setAllAddresses}
          allAddresses={allAddresses}
          saveAddressChanges={saveAddressChanges}
        />
      )}

      {openCompanyInfoPopUp && (
        <CompanyInfoPopUp
          open={openCompanyInfoPopUp}
          setOpen={setOpenCompanyInfoPopUp}
          updateCompany={updateUserOrg}
          subscriptionV2={subscriptionV2}
        />
      )}
    </div>
  );
};

export default YourBusiness;
