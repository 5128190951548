export default function PersonIcon(props) {
  return (
    <svg
      width={props.width ? props.width : "16"}
      height={props.height ? props.width : "16"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4945 0.950684C8.0029 0.950684 5.14681 3.80881 5.14681 7.30038C5.14681 9.43532 6.21782 11.3259 7.84603 12.4761C4.05587 13.9956 1.36752 17.7026 1.36752 22.0269C1.33726 23.3902 3.39778 23.3902 3.36752 22.0269C3.36752 17.4369 7.06847 13.7378 11.6585 13.7378C13.2608 13.7378 14.7481 14.1945 16.014 14.978C17.152 15.6759 18.1989 13.969 17.0609 13.2711C16.4909 12.9184 15.8733 12.6434 15.2367 12.4058C16.8084 11.2496 17.8363 9.39288 17.8363 7.30038C17.8363 3.80881 14.986 0.950684 11.4945 0.950684ZM11.4945 2.95268C13.9052 2.95268 15.8363 4.88968 15.8363 7.30038C15.8363 9.71107 13.9052 11.6401 11.4945 11.6401C9.08378 11.6401 7.14681 9.71107 7.14681 7.30038C7.14681 4.88968 9.08378 2.95268 11.4945 2.95268ZM17.6292 19.9683V21.9683C17.6582 23.2724 19.6003 23.2724 19.6292 21.9683V19.9683H21.6312C22.9658 19.9683 22.9658 17.9664 21.6312 17.9664H19.6292V15.9664C19.6357 15.4057 19.1802 14.9491 18.6195 14.9546C18.0664 14.9584 17.6228 15.4134 17.6292 15.9664V17.9664H15.5814C14.1989 18.0303 14.2946 20.0321 15.6292 19.9683H17.6292Z"
        fill={props.inherit ? props.inherit : "#7C859C"}
      />
    </svg>
  );
}
