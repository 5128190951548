import React, { useMemo } from "react";

const colors = [
  "#0BA5DC",
  "#E8AB11",
  "#573030",
  "#183943",
  "#E81160",
  "#4937C7",
  "#094513",
  "#717577",
  "#82B915",
  "#099870",
];

const GroupDropDownList = ({
  id,
  name,
  bgColor,
  selectedgroup,
  setSelectedgroup,
  setIsDropdownOpen,
}) => {
  const assignedColor = useMemo(() => {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  }, [id, colors]);

  return (
    <main
      id={id}
      className={` w-full gap-[6px] justify-start flex items-center p-[4px] rounded-[4px]   ${
        selectedgroup === id ? "bg-[#f1f6fb]" : "hover:bg-[#F7F8F9] bg-white"
      }  cursor-pointer `}
      onClick={() => {
        setSelectedgroup(id);
        setIsDropdownOpen(false);
      }}
    >
      <div
        className="text-[12px] leading-[12px] text-white w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full flex items-center justify-center"
        style={{ backgroundColor: assignedColor }}
      >
        {name && name.charAt(0).toUpperCase()}
      </div>

      <h4 className="text-[12px]  leading-[12px]  font-medium text-secondary-80">
        {name && name}
      </h4>
    </main>
  );
};

export default GroupDropDownList;
