import axios from "axios";
import { getAuthHeader } from "../app/constants/constants";

async function handleFilters(formattedFilters, requiredUserIds) {
  try {

    let url = `${process.env.REACT_APP_BASE_URL}api/events/filters`;
    if (requiredUserIds && requiredUserIds.length > 0) {
      let searchQueryParts = requiredUserIds.map(
        (userId) => `userIds[]=${userId}`
      );
      url += `?${searchQueryParts.join("&")}`;
    }

    const response = await axios.post(
	  url,
      formattedFilters,
      { headers: getAuthHeader() }
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching filtered events:`, error);
  }
}

export { handleFilters };
