import { useSelector } from 'react-redux'
import HorizontalDivider from '../../../../HorizontalDivider/HorizontalDivider'
import RadioButtonV2 from '../../../../RadioButtonV2/RadioButtonV2'
import styles from './PermissionsEditor.module.css'
import DropdownV2 from '../../../../DropdownV2/DropdownV2'
import ToggleButtonV2 from '../../../../ToggleButtonV2/ToggleButtonV2'
import { fetchAndSetOrgDetails, updateOrg } from '../../../../../utils/orgService'
import { useEffect, useState } from 'react'
import LoaderIcon from '../../../../LoaderIcon/LoaderIcon'
import { toast } from 'react-toastify'


const requiredSettings = {
    "calendarDefaultView": {
        inputType: "radio",
        description: "Default calendar view",
        values: ["weekly", "monthly", "yearly"]
    }
}

const requiredPermissions = {
    "tutDelSession": {
        description: "When enabled, tutors will be able to delete sessions after scheduling them on the Calendar."
    },
    "tutCreateSession": {
        description: "Enable this option to allow tutors to create new sessions directly on their Calendar."
    },
	"tutCreateConsultations" : {
		description : "Enable this option to allow tutors to schedule consultation events directly with students & parents."
	},
	"stuParentViewSessionTags": {
		description : "Enable this option to allow students & parents to view session tags associated with their sessions."
	},
    "notifyParentBefSession": {
        inputType: "dropdown",
        description: "Select when you would like to send reminder emails to parents for scheduled sessions."
    },
    "notifyStudentBefSession": {
        inputType: "dropdown",
        description: "Select when you would like to send reminder emails to students for scheduled sessions."
    },
}

function getModifiedString(ipStr, targetIdx) {
    const tempArr = ipStr.split(" ")
    tempArr[targetIdx] = <strong>{tempArr[targetIdx]}</strong>
    return tempArr
}

const PermissionsEditor = (props) => {

    const { settings } = useSelector((state) => state.organization.organization)
    const { subscriptionV2 } = useSelector((state)=> state.subscription)
    const {organization} = useSelector((state) => state.organization)
    const [isLoading, setIsLoading] = useState({
        "tutDelSession": false,
        "tutCreateSession": false,
		"tutCreateConsultations" : false,
		"stuParentViewSessionTags" : false,
        "notifyParentBefSession": false,
        "notifyStudentBefSession": false,
        "calendarDefaultView" : false 
    })
    

    useEffect(()=>{
         if(subscriptionV2 && settings && organization){
            if(subscriptionV2.subscription.multiCalendarView.isEnabled === false){
                updateOrg(organization._id,{"settings.calendarDefaultView" : "weekly"})
                    .then((res)=>{
                        fetchAndSetOrgDetails(organization._id)
                    })
            }
        }   
        
    },[subscriptionV2, settings, organization])

    const showLoadingToast = (id=null,message = null) => {
        if(!id){
            const id = toast.loading(message ?? "Updating permissions")            
            return id
        }else {
            toast.update(id,{render :"Updated successfully", isLoading : false, autoClose : 3000, type : "success" })
        }
    }


    const handleToggle = async (idx, key, targetValue) => {
        try {
            setIsLoading((prev)=>{
                prev[key] = true
                return {...prev}
            })
            const id = showLoadingToast(null,null)
            const requiredField = `settings.permissions.${idx}.choosedValue` 
            const fields = {}
            fields[requiredField] = targetValue
            await updateOrg(organization._id,fields)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading((prev)=>{
                prev[key] = false
                return {...prev}
            })
            showLoadingToast(id)
        }catch(err){
            setIsLoading((prev)=>{
                prev[key] = false
                return {...prev}
            })
            showLoadingToast(id)
            console.log(err)
        }
    }


    const handleSettingsUpdate = async (key,targetValue) => {
        try {
            setIsLoading((prev)=>{
                prev[key] = true
                return {...prev}
            })
            const id = showLoadingToast(null, "Updating settings")
            const fields = {} 
            fields[`settings.${key}`] = targetValue
            await updateOrg(organization._id,fields)
            await fetchAndSetOrgDetails(organization._id)
            setIsLoading((prev)=>{
                prev[key] = false
                return {...prev}
            })
            showLoadingToast(id)
        }catch(err){
            console.log(err)
            setIsLoading((prev)=>{
                prev[key] = false
                return {...prev}
            })
            showLoadingToast(id)

        }
    }


    return (
        <div className={styles.editor}>
            {
                Object.keys(requiredSettings).map((key, idx) => (
                    <>
                        <div className={styles.item}>
                            <h5>{requiredSettings[key].description}</h5>

                        </div>
                        {
                            requiredSettings[key].inputType === "radio" ?
                                <div className={styles.radioInput}>
                                    {
                                        isLoading[key] ? <LoaderIcon />:
                                        requiredSettings[key].values.map((val, idx) => (
                                            <RadioButtonV2 selected={val === (settings && settings[key])} labelText={val} customSelectedMappingType={styles.customSelectedRadioButton} customUnselectedMappingType={styles.customUnselectedRadioButton} onClick={()=>handleSettingsUpdate(key,val)}/>
                                        ))
                                    }
                                </div>
                                : <></>
                        }
                        <div className={styles.horizontalDivider}><HorizontalDivider /></div>
                    </>
                ))
            }
            {
                settings && settings.permissions && Object.keys(requiredPermissions).map((key, idx) => {
                    const requiredIdx = settings.permissions.findIndex((val, idx) => val.permissionActionName === key)
                    const requiredPermission = settings.permissions[requiredIdx]
                    if (!requiredIdx || !requiredPermission) {
                        return null
                    }
                    return (
                        <>
                            <div className={styles.item}>
                                <h5>{getModifiedString(requiredPermission.name, idx <= 2 ? 3 : idx === 3 ? 5 : 1 ).map((val, _idx)=><>{val}&nbsp;</>)}</h5>
                                <p>{requiredPermissions[key].description}</p>

                            </div>
                            <div className={styles[requiredPermissions[key].inputType === "dropdown" ? "dropdown" : "toggle"]}>
                                {
                                    isLoading[key] ? <LoaderIcon /> :
                                    requiredPermissions[key].inputType === "dropdown" ?
                                        <DropdownV2  dropdownMenuItems={[{displayTitle : "Don't send"},...requiredPermission.values.map((val) => ({ displayTitle: val === "Dont't send" ? val : val + " hours" }))]} defaultValue={requiredPermission.choosedValue === "Don't send" ? requiredPermission.choosedValue : requiredPermission.choosedValue + " hours"} checkIsSelected={(item) => item.displayTitle === requiredPermission.choosedValue} onDropdownItemClick={(item)=>handleToggle(requiredIdx,requiredPermission.permissionActionName,item.displayTitle === "Don't send" ? item.displayTitle : item.displayTitle.split(" ")[0])}/>
                                        :
                                        <ToggleButtonV2 isEnabled={requiredPermission.choosedValue} setIsEnabled={(targetValue) => handleToggle(requiredIdx, requiredPermission.permissionActionName, targetValue)}/>

                                }
                            </div>
                            <div className={styles.horizontalDivider}><HorizontalDivider /></div>

                        </>
                    )
                })
            }
        </div>
    )
}



export default PermissionsEditor
