import React, { useEffect, useState, useMemo } from "react";

const colors = [
    "#0BA5DC",
    "#E8AB11",
    "#573030",
    "#183943",
    "#E81160",
    "#4937C7",
    "#094513",
    "#717577",
    "#82B915",
    "#099870",
  ];

const DropDownList = ({
  email,
  firstName,
  lastName,
  id,
  profile,
  selectedUser,
  setSelectedUser,
}) => {
  const [isChecked, setIsChecked] = useState(false);


  const assignedColor = useMemo(() => {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  }, [id, colors]);

  const addRemoveUser = () => {
    const userExists = selectedUser.some((member) => member === id);
    setSelectedUser((prev) =>
      userExists ? prev.filter((member) => member !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    if (selectedUser) {
      const userExists = selectedUser.some((member) => member === id);
      setIsChecked(userExists);
    }
  }, [selectedUser, id]);

  return (
    <main
      id={id}
      className="w-full gap-[6px] justify-start flex items-center p-[4px] rounded-[4px] hover:bg-[#F7F8F9] cursor-pointer"
      onClick={addRemoveUser}
    >
      <section className="flex items-center justify-center w-fit h-fit relative z-10">
        <input
          type="checkbox"
          checked={isChecked}
          readOnly
          className= " !inline-block w-[12px] h-[12px] md:w-[15px] md:h-[15px]"
        />
        <div className="bg-transparent cursor-pointer z-10 absolute top-0 left-0 w-full h-full" />
      </section>

      {profile ? (
        <img
          src={profile}
          alt="profile"
          className="w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full"
        />
      ) : (
        <div
          className="text-[12px] leading-[12px] text-white w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full flex items-center justify-center"
          style={{ backgroundColor: assignedColor }}
        >
          {firstName.charAt(0).toUpperCase()}
        </div>
      )}

      <section className="flex flex-col gap-[3px] flex-1">
        <h4 className="text-[10px] md:text-[12px] leading-[10px] md:leading-[12px] font-medium text-secondary-80">
          {firstName} {lastName}
        </h4>
        <p className="text-[8px] md:text-[10px] leading-[8px] md:leading-[10px] font-normal text-secondary-60 break-all overflow-hidden w-full max-w-full">
          {email}
        </p>
      </section>
    </main>
  );
};

export default DropDownList;
