import React, { useEffect, useRef, useState } from "react";
import styles from "./PopupModelV2.module.css";
import { Close } from "@mui/icons-material";
import { ReferralSelectContainer } from "./ReferralSelectContainer";
import {
  Popover,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import down from "../../assets/icons/down-arrow.svg";
import InputSearch from "../../components/InputSearch/InputSearch";
import { BASE_URL, getAuthHeader } from "../../app/constants/constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetSettingsQuery } from "../../app/services/session";
import { useUpdateOrgSettingMutation } from "../../app/services/settings";
import { updateOrganizationSettings } from "../../app/slices/organization";
import {
  useLazyGetAllOrgUsersQuery,
  useLazyGetAllUsersQuery,
} from "../../app/services/users";
import {
  fetchAndSetOrgDetails,
  updateOrg,
  updateOrgSubsriptionCode,
} from "../../utils/orgService";
import user from "../../app/slices/user";
import { triggerToast } from "../../utils/toastController";

const PopupModelV2 = ({ isOpen, onClose, updateSettingsData, data }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.organization);
  const { subscriptionV2 } = useSelector((state) => state.subscription);
  const [getSettings, getSettingsResp] = useLazyGetSettingsQuery();

  const [subModalData, setSubModalData] = useState({
    code: data?.code || "",
    expiry: data?.expiry || 4,
    tests: data?.tests || [],
    editing: data ? true : false,
    _id: data?._id || "",
    user: data?.user || "",
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showReferralSelect, setShowReferralSelect] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTests, setSelectedTests] = useState();
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [searchedTest, setSearchedTest] = useState("");
  const [searchedUser, setSearchedUser] = useState("");
  const [filteredTests, setFilteredTests] = useState([]);
  const [allTestData, setAllTestData] = useState([]);
  const [settingsData, setSettingsData] = useState({});
  const [referralCode, setReferralCode] = useState("");
  const [updateSetting, updateSettingResp] = useUpdateOrgSettingMutation();
  const [fetchUsers, fetchUsersResp] = useLazyGetAllUsersQuery();
  const [getAllUsers, setAllUsers] = useLazyGetAllOrgUsersQuery();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isOpen) return null;
  // console.log("organization", organization);
  const fetchTests = () => {
    axios
      .get(`${BASE_URL}api/test`, { headers: getAuthHeader() })
      .then((res) => {
        if (res.data.data.test) {
          let arr = res.data.data.test.map((item) => {
            return {
              _id: item._id,
              value: item.testName,
            };
          });
          setAllTestData(arr);
          setFilteredTests(arr);
        }
      });
  };

  const fetchAssociatedUsersList = () => {
    const currentUserId = sessionStorage.getItem("userId");

    getAllUsers().then((res) => {
      let result = res?.data?.data?.user;
      const filtered = result.filter((user) => user._id !== currentUserId);
      setFilteredUsers(filtered);
    });
  };
  const handleSelectClick = () => {
    setShowReferralSelect(!showReferralSelect);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };
  // console.log("data", data);
  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };
  const fetchSettings = () => {
    getSettings().then((res) => {
      setSettingsData(organization?.settings);
    });
  };

  const handleConfirmDelete = (id) => {
    console.log("id", id);
    let updated = settingsData.subscriptionCode.filter(
      (item) => item._id !== id
    );
    let updatedSetting = {
      subscriptionCode: updated,
    };
    console.log("updatedSetting", updatedSetting);
    // //console.log(updatedSetting);
    const settings = {
      ...settingsData,
      ...updatedSetting,
    };
    const body = {
      settings,
    };
    updateSetting(body)
      .then((res) => {
        setSettingsData(res.data.data.updatedOrg.settings);
        dispatch(updateOrganizationSettings(res.data.data.updatedOrg.settings));
        // alert("Succesfully deleted!");
        triggerToast("Succesfully deleted!", "success");
      })

      .catch((err) => {
        triggerToast("Error Occured!", "error");

        // alert("Error Occured!");

        //console.log("err", err);
      });
    onClose();
  };
  const handleAssignmentChange = (assignment) => {
    setSelectedAssignments((prev) =>
      prev.includes(assignment)
        ? prev.filter((item) => item !== assignment)
        : [...prev, assignment]
    );
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const selectAssignmentOnblur = () => {
    console.log("sleect blur assignment");
    // if (searchedTest.length === 0) {
    if (subModalData.tests.length > 0) {
      try {
        const test = filteredTests.find(
          (test) => test._id === subModalData.tests[0]
        );
        console.log("test", test);
        if (test) {
          setSearchedTest(test.value);
        }
      } catch (error) { }
    }
    // }
  };
  const selectUserOnBlur = () => {
    console.log("select blur assignment");

    if (subModalData.user !== "") {
      const user = filteredUsers.find((user) => user._id === subModalData.user);
      // console.log("user", user);
      if (user) {
        setSearchedUser(`${user.firstName} ${user.lastName}`);
      }
    }
  };

  const handleTestChange2 = (item) => {
    // console.log("items", item);
    if (subModalData?.tests?.includes(item._id)) {
      let updated = subModalData.tests.filter((test) => test !== item._id);
      setSubModalData((prev) => ({
        ...prev,
        tests: updated,
      }));
      console.log();
    } else {
      setSubModalData((prev) => ({
        ...prev,
        tests: [...subModalData.tests, item._id],
      }));
    }
  };
  const handleTestUserChange = (item) => {
    const userId = item._id;

    // If current user ID is already selected, remove it
    if (subModalData.user === userId) {
      setSubModalData((prev) => ({
        ...prev,
        user: "", // Reset to empty string or null if needed
      }));
    } else {
      // Otherwise, set the new user ID
      setSubModalData((prev) => ({
        ...prev,
        user: userId,
      }));
    }
  };
  // console.log("subModalData", subModalData);
  const handleTestChangeV2 = (item) => {
    console.log("items", item);
    if (subModalData?.tests?.includes(item._id)) {
      let updated = subModalData.tests.filter((test) => test !== item._id);
      setSubModalData((prev) => ({
        ...prev,
        tests: updated,
      }));
      console.log();
    }
  };
  // console.log("filteredUsers", filteredUsers);
  useEffect(() => {
    fetchTests();
    fetchSettings();
    fetchAssociatedUsersList();
    // fetchAndSetOrgDetails(organization._id);
    setSelectedTests(
      allTestData.filter((test) => subModalData.tests.includes(test._id))
    );
  }, [subModalData.tests, subModalData.user]);

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    if (subModalData?.code?.trim() == "") {
      triggerToast("Please enter referral code", "error", { autoClose: 3000 });
      return;
    }
    console.log("subModalData", subModalData);
    if (subModalData.editing === true) {
      let updated = settingsData.subscriptionCode.map((subscription) => {
        if (subscription._id === subModalData._id) {
          console.log("inside if");
          return { ...subModalData };
        } else {
          console.log("inside else");
          return { ...subscription };
        }
      });
      console.log("updated", updated);

      let updatedSetting = {
        subscriptionCode: updated,
      };
      console.log("updatedSetting in if", updatedSetting);

      updateAndFetchsettingsNew2(updatedSetting);
    } else {
      const testIds = selectedTests.map((test) => test._id);
      let updated = [
        ...settingsData.subscriptionCode,
        {
          code: subModalData?.code,
          expiry: subModalData.expiry,
          pause: false,
          tests: testIds,
          user: subModalData.user,
        },
      ];
      console.log("updated", updated);
      let updatedSetting = {
        subscriptionCode: updated,
      };
      console.log("updatedSetting", updatedSetting);

      updateAndFetchsettingsNew2(updatedSetting);
    }
  };
  const updateAndFetchsettingsNew2 = async (updatedSetting) => {
    if (!organization || !settingsData || !updatedSetting) return;

    const settings = {
      ...settingsData,
      ...updatedSetting,
    };
    console.log("settings", settings);
    const body = {
      settings,
    };
    console.log("body", body);
    const res = await updateOrgSubsriptionCode(
      organization?._id,
      body?.settings?.subscriptionCode
    );
    // updateSetting(body)
    //   .then((res) => {
    //     console.log("updated", res.data.data);
    //     if (res.error) {
    //       return alert("Error Occured!");
    //     }
    // alert("Succesfully Added!");
    triggerToast("Referral Code Added!", "success");

    // updateSettingsData(res?.data?.data?.updatedOrg?.settings);
    setSettingsData(res?.data?.data?.updatedOrg?.settings);
    dispatch(updateOrganizationSettings(res?.data?.data?.updatedOrg?.settings));
    fetchAndSetOrgDetails(organization?._id);
    onClose();
    //   })
    //   .catch((err) => {
    //     alert("Error Occured!");
    //     console.log("err", err);
    //   });
  };
  // console.log("filteredUsers", filteredUsers);
  useEffect(() => {
    if (allTestData.length === 0) return;
    const regex2 = new RegExp(`${searchedTest.toLowerCase()}`, "i");

    let tempdata = allTestData.filter((test) => test.value.match(regex2));
    setFilteredTests(tempdata);

    if (searchedUser.trim().length === 0) {
      fetchAssociatedUsersList();
    } else {
      const regex = new RegExp(searchedUser.trim(), "i");
      const filtered = filteredUsers.filter((user) =>
        `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.match(
          regex
        )
      );
      setFilteredUsers(filtered);
    }
  }, [searchedTest, searchedUser]);

  console.log("is enalbed", isEnabled);
  return (
    <>
      {showDeleteConfirmation ? (
        <div className={styles.overlay}>
          <div className={styles.popup}>
            <div className={styles.header}>
              <h2>Delete Referral </h2>
              <button className={styles.closeButton} onClick={onClose}>
                <Close sx={{ color: "#25335A" }} />
              </button>
            </div>
            <img
              className={styles.line}
              src="/images/line_line.svg"
              alt="line"
            />

            <div
              style={{
                marginBottom: "20px",
                textAlign: isMobile ? "" : "center",
                marginTop: isMobile ? "30px" : "100px",
              }}
            >
              <p
                style={{
                  fontFamily: "Lexend",
                  fontSize: isMobile ? "16px" : "20px",
                  fontWeight: "500",
                  lineHeight: "30px",
                  textAlign: "center",
                  color: "#515C7B",
                }}
              >
                Are you sure you want to delete this referral?
              </p>
              <p
                style={{
                  fontFamily: "Lexend",
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#636D89",
                  textAlign: "center",
                }}
              >
                Note: If any of your existing customers are using this referral,
                they will lose this benefit. Please check before proceeding.
              </p>
              <div className={styles.confirmationButtons}>
                <button
                  type="button"
                  className={styles.cancelButton}
                  onClick={handleCancelDelete}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  // className={styles.deleteButton}
                  style={{
                    padding: "10px 20px",
                    borderRadius: "6px",
                    cursor: "pointer",
                    fontSize: "16px",
                    background: " #f97066",
                    color: "white",
                    border: "none",
                    width: "99px",
                  }}
                  onClick={() => handleConfirmDelete(data?._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.overlay}>
          <div className={styles.popup} ref={modalRef}>
            <div className={styles.header}>
              <h2>Referral Code</h2>
              <button className={styles.closeButton} onClick={onClose}>
                <Close sx={{ color: "#25335A" }} />
              </button>
            </div>
            <img
              className={styles.line}
              src="/images/line_line.svg"
              alt="line"
            />

            <form
              onSubmit={(e) => {
                handleCodeSubmit(e);
              }}
              className={styles.form}
            >
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="referralCode">Referral Code*</label>
                  <input
                    type="text"
                    id="referralCode"
                    maxLength="18"
                    style={{ height: "48px" }}
                    className="focus:outline-none focus:border-gray-300 border"
                    placeholder="Enter code (max 18 characters)"
                    value={subModalData.code}
                    // onChange={(e) => setReferralCode(e.target.value)}
                    onChange={(e) => {
                      if (!e.target.value?.includes(" "))
                        setSubModalData({
                          ...subModalData,
                          code: e.target.value,
                        });
                    }}
                  />
                </div>
                <div className={styles.formGroup}>
                  <InputSearch
                    label={
                      <span
                        style={{
                          color: "#25335A",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "17.5px",
                        }}
                      >
                        Associated User{" "}
                        <span style={{ color: "#515c7b" }}>(optional)</span>
                      </span>
                    }
                    labelClassname="text-secondary mb-[8px] font-[500] font-['Lexend'] leading-none"
                    placeholder="Select"
                    placeholderClass="!text-[16px] !font-[400] !font-['Lexend']"
                    parentClassName="text-base-17-5 py-0 w-full mb-[5.67px]"
                    inputContainerClassName="text-base-17-5 border-0 pt-3.5 pb-3.5 pl-1.5 !W-[276px] !h-[48px] mt-1 shadow-[0px_0px_2px_rgba(0,0,0,0.25)]"
                    inputClassName="!placeholder:text-secondary-60"
                    type="text"
                    value={searchedUser}
                    checkbox={{
                      visible: true,
                      name: "test",
                      match: subModalData.user,
                    }}
                    optionListClassName="text-base-17-5"
                    onBlur={selectUserOnBlur}
                    onChange={(e) => setSearchedUser(e.target.value)}
                    optionClassName="text-base-17-5"
                    optionData={filteredUsers}
                    rightIcon={down}
                    onOptionClick={(item) => {
                      handleTestUserChange(item);
                      // console.log("item", item);
                      // onChange(item);
                    }}
                  />
                </div>
              </div>

              <div className={styles.inputGroup}>
                <InputSearch
                  label={
                    <span
                      style={{
                        color: "#25335A",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "17.5px",
                      }}
                    >
                      Select Assignments{" "}
                      <span style={{ color: "#515c7b" }}>(optional)</span>
                    </span>
                  }
                  labelClassname="text-[18.67px] text-secondary mb-[8px] font-[500] font-['Lexend'] leading-none "
                  placeholder="Search Assignment"
                  placeholderClass="!text-[16px] !font-[400] !font-['Lexend']"
                  parentClassName=" text-base-17-5 py-0 w-full mb-[5.67px]"
                  inputContainerClassName="text-base-17-5  border-0 pt-3.5 pb-3.5 pl-3.5 !W-[600px] !h-[53px] shadow-[0px_0px_2px_rgba(0,0,0,0.25)]"
                  inputClassName="!placeholder:text-secondary-60"
                  type="text"
                  value={searchedTest}
                  checkbox={{
                    visible: true,
                    name: "test",
                    match: subModalData.tests,
                  }}
                  // onBlur={selectAssignmentOnblur}
                  onChange={(e) => setSearchedTest(e.target.value)}
                  optionListClassName="text-base-17-5"
                  optionClassName="text-base-17-5"
                  optionData={filteredTests}
                  rightIcon={down}
                  onOptionClick={(item) => {
                    handleTestChange2(item);
                    // setStudent(item.value);
                    // handleStudentsChange(item)
                    // setCurrentToEdit({ ...currentToEdit, students: [... item._id] });
                  }}
                />
                {data &&
                  data.tests.length == 0 &&
                  selectedTests &&
                  selectedTests?.length > 0 && (
                    <div
                      className={`${styles.testContainer} flex items-center flex-wrap gap-2`}
                    >
                      {selectedTests.map((test, idx) => (
                        <div key={idx} className={styles.testItemContainer}>
                          <p className={styles.testItem}>
                            {test.value}
                            <Close
                              sx={{
                                color: "#25335A",
                                fontSize: 14,
                                cursor: "pointer",
                                ml: 2,
                              }}
                              onClick={() => handleTestChange2(test)}
                            />
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                {!data && selectedTests && selectedTests?.length > 0 && (
                  <div
                    className={`${styles.testContainer} flex items-center flex-wrap gap-2`}
                  >
                    {selectedTests.map((test, idx) => (
                      <div key={idx} className={styles.testItemContainer}>
                        <p className={styles.testItem}>
                          {test.value}
                          <Close
                            sx={{
                              color: "#25335A",
                              fontSize: 14,
                              cursor: "pointer",
                              ml: 2,
                            }}
                            onClick={() => handleTestChange2(test)}
                          />
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                {data && data?.tests?.length > 0 && (
                  <div
                    className={`${styles.testContainer} flex items-center flex-wrap gap-2`}
                  >
                    {allTestData
                      .filter((test) => subModalData.tests.includes(test._id))
                      .map((test, idx) => {
                        // console.log("testerrr", test);
                        return (
                          <div key={idx} className={styles.testItemContainer}>
                            <p className={styles.testItem}>
                              {test.value}
                              <Close
                                sx={{
                                  color: "#25335A",
                                  fontSize: 14,
                                  cursor: "pointer",
                                  ml: 2,
                                }}
                                onClick={() => handleTestChangeV2(test)}
                              />
                            </p>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              <p className={styles.note}>
                Note:{" "}
                <span className={styles.noteSpan}>
                  Only alphabets and numbers are allowed for referral codes.
                </span>
              </p>
              {!isMobile && (
                <div className={styles.buttonContainer}>
                  <button
                    type="button"
                    className={styles.deleteButton}
                    onClick={handleDeleteClick}
                  >
                    Delete Referral
                  </button>
                  <div>
                    <button
                      type="button"
                      className={styles.cancelButton}
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      // onClick={handleCodeSubmit}
                      type="submit"
                      className={styles.saveButton}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
              {isMobile && (
                <>
                  <div className={styles.buttonWrapperWrapper}>
                    <button
                      className={styles.cancelButtonWrapperMobile}
                      onClick={onClose}
                    >
                      <div className={styles.cancelButtonMobile}>Cancel</div>
                    </button>
                    <button
                      type="submit"
                      disabled={!isEnabled}
                      className={styles.saveButtonWrapperMobile}
                    >
                      <div className={styles.saveButtonMobile}>Save</div>
                    </button>
                  </div>
                  <div className={styles.deleteButtonWrapper}>
                    <div
                      onClick={handleDeleteClick}
                      className={styles.deleteButtonFrame}
                    >
                      <div className={styles.deleteButtonTypo}>
                        Delete Referral
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupModelV2;

