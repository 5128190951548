import { MoreVert } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import InputTypeController from './InputTypeController'
import LockIcon from '../../assets/Settings/LockIcon';
import Tooltip from '../Tooltip/Tooltip';
import { triggerUpdateSubscriptionPopUp } from '../../utils/subscriptionPopUpService';

const SectionTwo = ({ setCustomFields, customFields, packageName, totalQuestion, activeIndex, setActiveIndex }) => {
    // const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        console.log(packageName, totalQuestion)
    }, [])

    return (
        <div className=''>
            <div className='p-4 flex flex-col gap-5'>
                <div className='flex justify-between items-center'>
                    <div className='font-normal text-base text-secondary w-[70%] border-b border-secondary-80'>Further Info</div>

                </div>
                {
                    customFields && customFields?.sectionTwo?.map((item, i) => {
                        return <InputTypeController isThisActive={activeIndex == i} setActiveIndex={setActiveIndex} customFields={customFields} setCustomFields={setCustomFields} idx={i} packageName={packageName} section={"sectionTwo"} />
                    })
                }
                <div className='flex items-center gap-3'>
                    <button className='px-5 py-[10px] rounded-md bg-blue-70 font-medium text-[16px] leading-[20px] text-white disabled:bg-blue-70/40' onClick={() => {
                        setCustomFields((prev) => {
                            const temp = { ...prev }
                            temp?.sectionTwo.push({
                                "questionText": "",
                                "Values": [],
                                "dataType": "Single-line",
                                "required": false,
                                "disabled": false,
                                "desc": ""
                            })
                            return temp
                        })
                        console.log(customFields)
                    }}
                        disabled={(packageName === "Starter" && totalQuestion === 3) || (packageName === "Professional" && totalQuestion === 10) || (packageName === "Free") ? true : false}
                    >+ Add Question</button>
                    {(packageName === "Starter" && totalQuestion === 3) || (packageName === "Professional" && totalQuestion === 10) || (packageName === "Free") ?
                        <Tooltip anchorComponent={<LockIcon />}

                            tooltipContent={
                                <>
                                    <div className='font-medium text-[14px] leading-[21px] text-secondary'>Upgrade to a paid plan!</div>
                                    <div className='font-normal text-[12px] leading-[18px] text-secondary-70'>Custom signup questions are not available on explorer plan. Upgrade to a paid plan to unlock this feature.</div>
                                    <button onClick={(e) => triggerUpdateSubscriptionPopUp()} className='font-normal text-[12px] leading-[18px] text-blue-70'>Manage Subscription</button>
                                </>
                            }
                            tooltipStyleClass={"xl:w-[320px] w-[240px] bg-white rounded-[12px] shadow-[0px_4px_24px_0px_#11167D26] p-3 flex flex-col items-start gap-[6px] !-top-[710%] xl:!-left-[100%] !-left-[350%]"}
                            tooltipTriangleStyling={"!top-[100%] xl:!left-[25px] !left-[75px] -rotate-90 "}
                        />
                        :
                        ""
                    }
                </div>
            </div>
        </div>
    )
}

export default SectionTwo
