import { Outlet, useSearchParams } from "react-router-dom";
import AuthTopbar from "../../components/AuthTopbar/AuthTopbar";
import OrgDetailsV2 from "../../components/OrgSignupV2/OrgDetailsV2/OrgDetailsV2";
import styles from "./Stage.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLazyOrganizationDetailsQuery } from "../../app/services/organization";
import { updateIcons } from "../../components/BrandPopup/BrandLogo";

const Stage = (props) => {
  const [signupStage, setSignupStage] = useState("initial");
  const { isLoggedIn, role } = useSelector((state) => state.user);
  const { subscriptionV2, oldSubscriptionExists } = useSelector(
    (state) => state.subscription
  );
  const navigate = useNavigate();
  const params = useSearchParams();

  const [orgData] = useLazyOrganizationDetailsQuery();

  const [OrganizationData, setOrganisationData] = useState();
  const [dummySubscription, setDummySubscription] = useState(null)

  const [primaryCTAStyles, setPrimaryCTAStyles] = useState(null)

  //Remove after testing
  useEffect(() => {
    if (isLoggedIn) {
      if (subscriptionV2 || oldSubscriptionExists) {
        navigate("/");
      } else if (role === "superAdmin" || role === "manager") {
        navigate("/");
      }
    } else {
      if (/^\/admin-portal\?userId=.*&token=.*$/.test(window.location.pathname))
        return;
      if (window.location.pathname === "/signin/reset-password/") return;
      if (window.location.pathname === "/signin") return;
      navigate("/signup");
    }
  }, [isLoggedIn, role, oldSubscriptionExists]);

  console.log(props.customSubDomain)

  // Org Find with sub-domain name
  useEffect(() => {
    const { hostname } = new URL(window.location.href);
    const subdomain = hostname.split(".")[0];
    orgData({ subdomain: subdomain, page:"signin" }).then((res) => {
      if (res.error) {
        console.log(res.error);
        return;
      }

      if (!res.data.data.OrgData) setOrganisationData("none");
      else {
            setOrganisationData(res.data.data.OrgData);
            setDummySubscription(res.data.data.subscription);

			if(res.data.data.OrgData && res.data.data.subscription){
				const orgData = res.data.data.OrgData
				//Setting document title if there is subdomain
				if(orgData && res.data.data.subscription.basePackage.name.includes("Premium")){
					window.document.title = orgData.company
				}
				const sub = res.data.data.subscription
				if(orgData.customColorScheme.primary){
					setPrimaryCTAStyles({
						bg : {
							backgroundColor : orgData.customColorScheme.primary,
							'&:disabled' : {
								backgroundColor	 : "#e5ecf4"
							},
							'&:enabled:hover' : {
										backgroundColor : orgData.customColorScheme.primary.slice(0,5) + ((parseInt(orgData.customColorScheme.primary.slice(5,7), 16) + 25 > 255 ? parseInt(orgData.customColorScheme.primary.slice(5,7), 16) + 25 : parseInt(orgData.customColorScheme.primary.slice(5,7), 16) - 25).toString(16))
							}
						},
						text : {
							color : orgData.customColorScheme.primary + " !important",
							textDecorationColor : orgData.customColorScheme.primary + " !important"
						},
						borderBottom : {
							borderBottom : `1px solid ${orgData.customColorScheme.primary} !important` 
						},
						border:{
							'&:focus,:checked':{
								border:`solid 1px ${orgData.customColorScheme.primary}`
							}
						},
						accent:{
							accentColor:orgData.customColorScheme.primary,
						}
					})
				}	
			}
      } 
    });
  }, [window.location.href]);


	useEffect(()=>{
		if (
			dummySubscription &&
				OrganizationData &&
				dummySubscription.basePackage.name.includes("Premium") &&
				OrganizationData.isEnabledOrgFavicon
		){

			updateIcons(OrganizationData.orgBrowserFavicon,OrganizationData.orgBrowserFavicon);
		} else {
			updateIcons(process.env.PUBLIC_URL + "/images/ev_logo.svg" ,process.env.PUBLIC_URL + "/images/ev_logo.svg" );
		}
	}, [dummySubscription,OrganizationData])

  return (


    <div className="flex items-center flex-col min-h-screen h-auto justify-between">
      <div className={styles.stageContainer}>
        <div className={styles.topBarContainer}>
          <AuthTopbar
            signupStage={signupStage}
            OrganizationData={OrganizationData}
            subscription={dummySubscription}
			primaryCTAStyles={primaryCTAStyles}
          />
        </div>
        <div className={styles.contentContainer}>
          <Outlet context={{ setSignupStage, OrganizationData : OrganizationData, dummySubscription : dummySubscription, primaryCTAStyles : primaryCTAStyles }} />
        </div>
      </div>

      <section className=" w-full flex items-center justify-center py-[10px] border-t border-secondary-10">
        <p className=" text-[14px] font-normal leading-[21px] text-secondary-60 ">
          Powered by Evallo <span className=" text-[20px] "> &reg;</span>
        </p>
      </section>
    </div>
  );
};

export default Stage;
