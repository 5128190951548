import React from 'react'

const TutorialIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29123_7620)">
                <path d="M3.14062 14H12.8594C13.8932 14 14.7344 13.1589 14.7344 12.125V2.375C14.7344 1.34113 13.8932 0.5 12.8594 0.5H3.14062C2.10675 0.5 1.26562 1.34113 1.26562 2.375V14.625C1.26562 15.6589 2.10675 16.5 3.14062 16.5H12.2344H12.8594C13.8932 16.5 14.7344 15.6589 14.7344 14.625V14.6234C14.2117 15.0167 13.5623 15.25 12.8594 15.25H12.2344H3.14062C2.796 15.25 2.51562 14.9696 2.51562 14.625C2.51562 14.2804 2.796 14 3.14062 14ZM2.51562 2.375C2.51562 2.03038 2.796 1.75 3.14062 1.75H12.8594C13.204 1.75 13.4844 2.03038 13.4844 2.375V12.125C13.4844 12.4696 13.204 12.75 12.8594 12.75H3.14062C2.92159 12.75 2.71119 12.7878 2.51562 12.8571V2.375ZM6.95312 5.76813H5.70312C5.70312 5.12694 5.97794 4.51322 6.45713 4.08425C6.94259 3.64963 7.59566 3.44187 8.249 3.51444C9.29566 3.63059 10.1385 4.47122 10.253 5.51331C10.3575 6.46375 9.80013 7.14575 9.37178 7.57606C9.26153 7.68681 9.15978 7.78384 9.07 7.8695C8.66159 8.259 8.60956 8.3245 8.60956 8.59363V8.625H7.35956V8.59359C7.35956 7.77338 7.75244 7.39872 8.20734 6.96488C8.29572 6.88059 8.38713 6.79344 8.48588 6.69422C8.88953 6.28869 9.04644 5.97638 9.01056 5.64988C8.95938 5.18456 8.58116 4.80897 8.11116 4.75681C7.80659 4.72312 7.51509 4.81488 7.29087 5.01562C7.07306 5.21056 6.95312 5.47781 6.95312 5.76813ZM7.35938 9.5625H8.60938V10.8125H7.35938V9.5625Z" fill="#515C7B" />
                <path d="M3.14062 14H12.8594C13.8932 14 14.7344 13.1589 14.7344 12.125V2.375C14.7344 1.34113 13.8932 0.5 12.8594 0.5H3.14062C2.10675 0.5 1.26562 1.34113 1.26562 2.375V14.625C1.26562 15.6589 2.10675 16.5 3.14062 16.5H12.2344H12.8594C13.8932 16.5 14.7344 15.6589 14.7344 14.625V14.6234C14.2117 15.0167 13.5623 15.25 12.8594 15.25H12.2344H3.14062C2.796 15.25 2.51562 14.9696 2.51562 14.625C2.51562 14.2804 2.796 14 3.14062 14ZM2.51562 2.375C2.51562 2.03038 2.796 1.75 3.14062 1.75H12.8594C13.204 1.75 13.4844 2.03038 13.4844 2.375V12.125C13.4844 12.4696 13.204 12.75 12.8594 12.75H3.14062C2.92159 12.75 2.71119 12.7878 2.51562 12.8571V2.375ZM6.95312 5.76813H5.70312C5.70312 5.12694 5.97794 4.51322 6.45713 4.08425C6.94259 3.64963 7.59566 3.44187 8.249 3.51444C9.29566 3.63059 10.1385 4.47122 10.253 5.51331C10.3575 6.46375 9.80013 7.14575 9.37178 7.57606C9.26153 7.68681 9.15978 7.78384 9.07 7.8695C8.66159 8.259 8.60956 8.3245 8.60956 8.59363V8.625H7.35956V8.59359C7.35956 7.77338 7.75244 7.39872 8.20734 6.96488C8.29572 6.88059 8.38713 6.79344 8.48588 6.69422C8.88953 6.28869 9.04644 5.97638 9.01056 5.64988C8.95938 5.18456 8.58116 4.80897 8.11116 4.75681C7.80659 4.72312 7.51509 4.81488 7.29087 5.01562C7.07306 5.21056 6.95312 5.47781 6.95312 5.76813ZM7.35938 9.5625H8.60938V10.8125H7.35938V9.5625Z" fill="black" fill-opacity="0.2" />
                <path d="M3.14062 14H12.8594C13.8932 14 14.7344 13.1589 14.7344 12.125V2.375C14.7344 1.34113 13.8932 0.5 12.8594 0.5H3.14062C2.10675 0.5 1.26562 1.34113 1.26562 2.375V14.625C1.26562 15.6589 2.10675 16.5 3.14062 16.5H12.2344H12.8594C13.8932 16.5 14.7344 15.6589 14.7344 14.625V14.6234C14.2117 15.0167 13.5623 15.25 12.8594 15.25H12.2344H3.14062C2.796 15.25 2.51562 14.9696 2.51562 14.625C2.51562 14.2804 2.796 14 3.14062 14ZM2.51562 2.375C2.51562 2.03038 2.796 1.75 3.14062 1.75H12.8594C13.204 1.75 13.4844 2.03038 13.4844 2.375V12.125C13.4844 12.4696 13.204 12.75 12.8594 12.75H3.14062C2.92159 12.75 2.71119 12.7878 2.51562 12.8571V2.375ZM6.95312 5.76813H5.70312C5.70312 5.12694 5.97794 4.51322 6.45713 4.08425C6.94259 3.64963 7.59566 3.44187 8.249 3.51444C9.29566 3.63059 10.1385 4.47122 10.253 5.51331C10.3575 6.46375 9.80013 7.14575 9.37178 7.57606C9.26153 7.68681 9.15978 7.78384 9.07 7.8695C8.66159 8.259 8.60956 8.3245 8.60956 8.59363V8.625H7.35956V8.59359C7.35956 7.77338 7.75244 7.39872 8.20734 6.96488C8.29572 6.88059 8.38713 6.79344 8.48588 6.69422C8.88953 6.28869 9.04644 5.97638 9.01056 5.64988C8.95938 5.18456 8.58116 4.80897 8.11116 4.75681C7.80659 4.72312 7.51509 4.81488 7.29087 5.01562C7.07306 5.21056 6.95312 5.47781 6.95312 5.76813ZM7.35938 9.5625H8.60938V10.8125H7.35938V9.5625Z" fill="black" fill-opacity="0.2" />
                <path d="M3.14062 14H12.8594C13.8932 14 14.7344 13.1589 14.7344 12.125V2.375C14.7344 1.34113 13.8932 0.5 12.8594 0.5H3.14062C2.10675 0.5 1.26562 1.34113 1.26562 2.375V14.625C1.26562 15.6589 2.10675 16.5 3.14062 16.5H12.2344H12.8594C13.8932 16.5 14.7344 15.6589 14.7344 14.625V14.6234C14.2117 15.0167 13.5623 15.25 12.8594 15.25H12.2344H3.14062C2.796 15.25 2.51562 14.9696 2.51562 14.625C2.51562 14.2804 2.796 14 3.14062 14ZM2.51562 2.375C2.51562 2.03038 2.796 1.75 3.14062 1.75H12.8594C13.204 1.75 13.4844 2.03038 13.4844 2.375V12.125C13.4844 12.4696 13.204 12.75 12.8594 12.75H3.14062C2.92159 12.75 2.71119 12.7878 2.51562 12.8571V2.375ZM6.95312 5.76813H5.70312C5.70312 5.12694 5.97794 4.51322 6.45713 4.08425C6.94259 3.64963 7.59566 3.44187 8.249 3.51444C9.29566 3.63059 10.1385 4.47122 10.253 5.51331C10.3575 6.46375 9.80013 7.14575 9.37178 7.57606C9.26153 7.68681 9.15978 7.78384 9.07 7.8695C8.66159 8.259 8.60956 8.3245 8.60956 8.59363V8.625H7.35956V8.59359C7.35956 7.77338 7.75244 7.39872 8.20734 6.96488C8.29572 6.88059 8.38713 6.79344 8.48588 6.69422C8.88953 6.28869 9.04644 5.97638 9.01056 5.64988C8.95938 5.18456 8.58116 4.80897 8.11116 4.75681C7.80659 4.72312 7.51509 4.81488 7.29087 5.01562C7.07306 5.21056 6.95312 5.47781 6.95312 5.76813ZM7.35938 9.5625H8.60938V10.8125H7.35938V9.5625Z" fill="black" fill-opacity="0.2" />
            </g>
            <defs>
                <clipPath id="clip0_29123_7620">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TutorialIcon