export default function VidCamIcon (props) {

	return (
		<svg width={props.width? props.width :"16"} height={props.height? props.width :"16"}   viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M23.0521 5.20848C22.7685 5.07041 22.4514 5.01607 22.1379 5.05181C21.8245 5.08756 21.5277 5.21192 21.2825 5.41031L18.4244 7.69629V7.20676C18.4899 6.64177 18.4268 6.0693 18.2398 5.53215C18.0528 4.99501 17.7468 4.50709 17.3446 4.10492C16.9424 3.70274 16.4545 3.39671 15.9174 3.20971C15.3802 3.02272 14.8077 2.9596 14.2428 3.02509H4.20676C3.64177 2.9596 3.0693 3.02272 2.53215 3.20971C1.99501 3.39671 1.50709 3.70274 1.10492 4.10492C0.70274 4.50709 0.396708 4.99501 0.209714 5.53215C0.0227192 6.0693 -0.0403968 6.64177 0.0250871 7.20676V16.1276C-0.0403968 16.6926 0.0227192 17.2651 0.209714 17.8023C0.396708 18.3394 0.70274 18.8273 1.10492 19.2295C1.50709 19.6317 1.99501 19.9377 2.53215 20.1247C3.0693 20.3117 3.64177 20.3748 4.20676 20.3093H14.2428C14.8077 20.3748 15.3802 20.3117 15.9174 20.1247C16.4545 19.9377 16.9424 19.6317 17.3446 19.2295C17.7468 18.8273 18.0528 18.3394 18.2398 17.8023C18.4268 17.2651 18.4899 16.6926 18.4244 16.1276V15.6381L21.2825 17.9241C21.5773 18.1623 21.9449 18.2921 22.324 18.2921C22.576 18.2917 22.8249 18.2349 23.0521 18.1259C23.337 17.9903 23.5774 17.7764 23.7452 17.5092C23.9131 17.2421 24.0014 16.9327 24 16.6172V6.71722C24.0014 6.40172 23.9131 6.09232 23.7452 5.82516C23.5774 5.558 23.337 5.34411 23.0521 5.20848ZM16.7518 16.1276C16.7518 17.8862 16.0013 18.6366 14.2428 18.6366H4.20676C2.44822 18.6366 1.69775 17.8862 1.69775 16.1276V7.20676C1.69775 5.44822 2.44822 4.69775 4.20676 4.69775H14.2428C16.0013 4.69775 16.7518 5.44822 16.7518 7.20676V16.1276ZM22.3273 16.6183L18.4244 13.496V9.83842L22.3273 6.71611V16.6183Z" fill={props.inherit ? props.inherit  : "#7C859C"}/>
		</svg>


	)

}
