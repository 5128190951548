import { triggerToast } from "../../../utils/toastController";
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015

const _ = require("lodash")
const colors = [
    "#0BA5DC",
    "#E8AB11",
    "#573030",
    "#183943",
    "#E81160",
    "#4937C7",
    "#094513",
    "#717577",
    "#82B915",
    "#099870",
];

function fetchStudentList(studentListFetcher, setStudentList, user) {
	let params = ""
	if(user.role === "admin"){
		params = "?role=student"
	} else if(user.role === "tutor"){
		params = `?role=student&assiginedTutors=${user.id}`
	} else if(user.role === "parent"){
		params =  `?role=student&associatedParent=${user.id}`
	}
	studentListFetcher(params)
		.then((res)=>{
			if(res.error) {
				console.error(res.error)
			}else {
				if(res.data){
					let tempData = res.data.data.user
					tempData = tempData.map((student, idx)=>({
						..._.pick(student, ["_id","firstName","lastName","email","photo"]),
						name : [student.firstName, student.lastName].join(" "),
						awsLink : user.awsLink,
						associatedTutors:student.assiginedTutors,
						cancel : "/images/cancel_call.svg",
						missedcall: "/images/missed_call.svg",
						color : colors[idx % colors.length],
						bgColor : colors[idx % colors.length], 
						...(student.photo) && {profile : user.awsLink+student.photo},
					}))
					console.log(tempData)
					setStudentList(tempData)
				}
			}
		})
}
	
	

function fetchTutorList(tutorListFetcher, setTutorList, user){
	let tutorParams = ""
	if(user.role === "admin"){
		tutorParams = "?role=tutor&role=admin"
	} else if (user.role === "student"){
		tutorParams = `?role=tutor&role=admin&assiginedStudents=${user.id}`
	} else if(user.role === "parent"){
		tutorParams = "?role=tutor&role=admin"
	}
	tutorListFetcher(tutorParams)
		.then((res)=>{
			if(res.error) {
				console.error(res.error)
			} else {
				if(res.data){
					let tempData = res.data.data.user
					tempData = tempData.map((tutor, idx)=>({
						..._.pick(tutor,["_id","firstName","lastName","email"]),
						name : [tutor.firstName, tutor.lastName].join(" "),
						...(tutor.photo) && {profile : user.awsLink+tutor.photo},
						bgColor : colors[idx % colors.length]
					}))
					setTutorList(tempData)
				}
			}
		})

}


function fetchStudentGroups(studentGroupFetcher, setStudentGroups, user) {
				studentGroupFetcher({orgId : user.associatedOrg})
					.then((res)=>{
						if(res.error) {
							console.error(res.error)
						} else {
							let _studentGroups = res?.data?.data?.groups;
							setStudentGroups(_studentGroups)
						}
					})

}


function fetchAllUsersList(allUsersFetcher, setAllUsersList, user){
	let params = ""
	if(user?.role === "admin"){
		params = ""
	} else if(user.role === "student"){
		params = `?assiginedStudents=${user.id}`
	} else if(user.role === "parent"){
		const paramsArr =  [`?associatedParent=${user.id}`, ...user?.associatedStudents.map(id=>`?assiginedStudents=${id}`)]
		Promise.all(
			paramsArr.map((param)=>(
				allUsersFetcher(param)
					.then((res)=>{
						console.log("RED",res)
						if(res.error) {
							console.error(res.error)
						} else {
							if(res.data){
								let tempData = res.data.data.user
								tempData = tempData.map((_user, idx)=>({
									..._.pick(_user,["_id","firstName","lastName","email", "role", "assiginedStudents"]),
									name : [_user.firstName, _user.lastName].join(" "),
									...(_user.photo) && {profile : user.awsLink+_user.photo},
									bgColor : colors[idx % colors.length]
								}))
								return(tempData)
							}
						}
					})
			))
		).then(val=>{
			let tempArr = []
			for(const arr of val){
				if(arr){
					for(const user of arr){
						const tempArrIds = tempArr.map(user=>user._id)
						if(!tempArrIds.includes(user._id)){
							tempArr.push(user)
						}
					}
				}
			}
			setAllUsersList(tempArr)
		})

		return
	} else if(user.role === "tutor"){
		params = `?assiginedTutors=${user.id}`
		allUsersFetcher(params)
			.then((res)=>{
				console.log("RED",res)
				if(res.error) {
					console.error(res.error)
				} else {
					if(res.data){
						let tempData = res.data.data.user
						tempData = tempData.map((_user, idx)=>({
							..._.pick(_user,["_id","firstName","lastName","email", "role", "assiginedStudents","associatedParent"]),
							name : [_user.firstName, _user.lastName].join(" "),
							...(_user.photo) && {profile : user.awsLink+_user.photo},
							bgColor : colors[idx % colors.length]
						}))
						setAllUsersList(tempData)
						Promise.all(
							tempData.filter(user => user.associatedParent).map(val=>(
								allUsersFetcher(`?associatedParent=${val.associatedParent}`)
									.then((res)=>{
										if(res.data){
											let tempData = res.data.data.user
											tempData = tempData.map((_user, idx)=>({
												..._.pick(_user,["_id","firstName","lastName","email", "role", "assiginedStudents","associatedParent"]),
												name : [_user.firstName, _user.lastName].join(" "),
												...(_user.photo) && {profile : user.awsLink+_user.photo},
												bgColor : colors[idx % colors.length]
											}))
											return tempData[0]
										}
									})
							))	
						).then(val=>{
							setAllUsersList(prev=>{
								const temp = [...prev]
								for(const user of val){
									const tempIds = temp.map(_val => _val._id)
									if(!tempIds.includes(user._id)){
										temp.push(user)
									}	
								}
								return temp
							})
						})
					}
				}
			})
		return	
	}

	allUsersFetcher(params)
		.then((res)=>{
			console.log("RED",res)
			if(res.error) {
				console.error(res.error)
			} else {
				if(res.data){
					let tempData = res.data.data.user
					tempData = tempData.map((_user, idx)=>({
						..._.pick(_user,["_id","firstName","lastName","email", "role", "assiginedStudents"]),
						name : [_user.firstName, _user.lastName].join(" "),
						...(_user.photo) && {profile : user.awsLink+_user.photo},
						bgColor : colors[idx % colors.length]
					}))
					setAllUsersList(tempData)
				}
			}
		})
	
}


async function fetchAndSetAllEvents(getAllEvents, setAllEvents, user){
	try {
		let requiredUserIds = []
		if(user && ["tutor","parent","student"].includes(user.role)){
			if(user.role === "parent"){
				requiredUserIds = [...user.associatedStudents]		
			}
			requiredUserIds.push(user.id)
		}
		const allEvents = await getAllEvents(requiredUserIds, user)
		setAllEvents(allEvents)
	}catch(err){
		console.error(err)
	}
}


async function fetchAndSetAllEventRequests(fetchAllEventRequests, setAllEventRequests){
	
	try {
		 
		let eventRequests = await fetchAllEventRequests()
		eventRequests = eventRequests.filter((eventRequest)=>(eventRequest.requestType === "schedule") || (eventRequest.requestType !== "schedule" && eventRequest.eventDetails)).map((eventRequest)=>{
			

			if(!["schedule", "invite"].includes(eventRequest.requestType)){
				return eventRequest
			}	

			const eventRequestTimeZone = eventRequest.requestedEventDetails.timeZone

			const _startDateString = dayjs(eventRequest.requestedEventDetails.date).tz(eventRequestTimeZone).format("YYYY-MM-DD")
			const _startTimeString = eventRequest.requestedEventDetails.time.start.time + " "  + eventRequest.requestedEventDetails.time.start.timeType

			const _endTimeString = eventRequest.requestedEventDetails.time.end?.time + " " + eventRequest.requestedEventDetails.time.end?.timeType
			const fullStartDate = dayjs.tz([_startDateString, _startTimeString].join(" "), "YYYY-MM-DD hh:mm A" , eventRequestTimeZone).toISOString()
			const fullEndDate = dayjs.tz([_startDateString, _endTimeString].join(" "), "YYYY-MM-DD hh:mm A" , eventRequestTimeZone).toISOString()

			return({
			...eventRequest,
			...(eventRequest.requestType === "schedule" || eventRequest.requestType === "invite") && {
				start : fullStartDate,
				end : fullEndDate,
				showOnCalendar : true,
				extendedProps : {
						eventRequestId : eventRequest._id,
						isEventRequest : true,
						eventType : eventRequest.requestedEventDetails.eventType,
						status : "pending",	
						time : eventRequest.requestedEventDetails.time,
						date : eventRequest.requestedEventDetails.date,
						timeZone : eventRequest.requestedEventDetails.timeZone,
						additionalInfo : eventRequest.additionalInfo,
						...(eventRequest.requestedEventDetails.eventType === "session") && {
							studentIds : eventRequest.requestedEventDetails.studentIds.map(val=>({
								...val,
								name : [val.firstName,val.lastName].join(" ")
							})),
							students : eventRequest.requestedEventDetails.studentIds.map(val=>(
								[val.firstName,val.lastName].join(" ")
							)),
							tutor : {
								...eventRequest.requestedEventDetails._tutor[0],
							},
							tutorName :  [eventRequest.requestedEventDetails._tutor[0]?.firstName, eventRequest.requestedEventDetails._tutor[0]?.lastName].join(" ")
							,
							tutorId : eventRequest.requestedEventDetails.tutorId
						},
						...(eventRequest.requestedEventDetails.eventType !== "session" && eventRequest.requestType === "schedule") && {
							attendeeDetails : eventRequest.requestedEventDetails.attendeeDetails,
							attendees : eventRequest.requestedEventDetails.attendees,
						},
						...(eventRequest.requestedEventDetails.eventType !== "session" && eventRequest.requestType === "invite") && {
							attendeeDetails : eventRequest.eventDetails.attendeeDetails,
							attendees : eventRequest.eventDetails.attendees,
						},
						eventRequest : eventRequest
				}

			}
		})})
		setAllEventRequests(eventRequests)

	}catch(err){
		triggerToast("Some error occured while fetchingg event requests")
	}

}


export {
	fetchStudentGroups,
	fetchStudentList,
	fetchTutorList,
	fetchAllUsersList,
	fetchAndSetAllEvents,
	fetchAndSetAllEventRequests
}
