import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  useCRMBulkdeleteMutation,
  useCRMBulkmarkcompletedMutation,
  useCRMBulkresentMutation,
} from "../../app/services/admin";
import {
  useLazyGetVerifiedStudentsByNameQuery,
  useLazyGetVerifiedTutorStudentsByNameQuery,
} from "../../app/services/session";
import {
  useAssignTestMutation,
  useLazyDeleteTestQuery,
  useLazyGetAllAssignedTestQuery,
  useLazyGetTestsByNameQuery,
  useLazyGetTutorAssignedTestsQuery,
  useLazyGetCanAssignTestPermissionQuery,
} from "../../app/services/test";
import SearchIcon from "../../assets/icons/Search_shade.svg";
import AddIcon from "../../assets/icons/add.svg";
import DeleteIcon from "../../assets/icons/ic_outline-delete-black.svg";
import ResendIcon from "../../assets/icons/resend.svg";
import AssignedTestIndicator from "../../components/AssignedTestIndicator/AssignedTestIndicator";
import SCheckbox from "../../components/CCheckbox/SCheckbox";
import FilterItems from "../../components/FilterItems/filterItems";
import InputField from "../../components/InputField/inputField";
import InputSearch from "../../components/InputSearch/InputSearch";
import InputSelect from "../../components/InputSelect/InputSelect";
import AssignmentTestModal from "../../components/Modal/AssignmentTestModal.js";
import Modal from "../../components/Modal/Modal";
import Table from "../../components/Table/Table";
import { getDuration, getFormattedDate } from "../../utils/utils";
import crossIconRedOutLine from "./../../assets/icons/cross_icon_red_outline.svg";
import { tempTableData } from "./tempData";
const optionData = ["1", "2", "3", "4", "5"];
const timeLimits = ["Regular", "1.1x", "1.25x", , "1.5x", "Unlimited"];
const testData = ["SAT", "ACT"];
import styles from "./styles.module.css";
const initialState = {
  name: "",
  limit: "Regular",
  date: "",
  test: "",
  testId: "",
  studentId: "",
  instruction: "",
};
import {
  useLazyGetAllGroupsOfOrgQuery,
  useLazyGetAllTutorMappedGroupsQuery,
} from "../../app/services/users.js";
import { multiplyDuration } from "@fullcalendar/react";
import RadioButtonV2 from "../../components/RadioButtonV2/RadioButtonV2";
import { toast } from "react-toastify";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb.js";
import { triggerToast } from "../../utils/toastController.js";
const SORT_STATES = {
  ASCENDING_ORDER: "ASCENDING_ORDER",
  DESCENDING_ORDER: "DESCENDING_ORDER",
  UNSORTED: "UNSORTED",
};
import padlock from "./assets/padlock.svg";
import CautionIcon from "../../components/CautionIcon/CautionIcon.js";
import Tooltip from "../../components/Tooltip/Tooltip.js";
import LockIcon from "../../assets/Settings/LockIcon.jsx";
import { triggerUpdateSubscriptionPopUp } from "../../utils/subscriptionPopUpService.js";

export default function AssignedTests() {
  const emptydata = [
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
    {
      assignedBy: "",
      assignedOn: "",
      assignTestId: "",
      createdAt: "",
      dueDate: "",
      duration: "",
      pdfLink: "",
      endTestTime: "",
      scores: "",
      studentId: "",
      studentName: "",
      testId: "",
      testName: "",
    },
  ];

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const navigate = useNavigate();
  const [testNameOptions, setTestNameOptions] = useState([]);
  const [studentNameOptions, setStudentNameOptions] = useState([]);
  const [allAssignedTests, setAllAssignedTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [newAssignmnetRadioSelection, setNewAssignmentRadioSelection] =
    useState("Student");
  const [isRadioChecked, setIsRadioChecked] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [studentNameSortState, setStudentNameSortState] = useState(
    SORT_STATES.UNSORTED
  );
  const [testNameSortState, setTestNameSortState] = useState(
    SORT_STATES.UNSORTED
  );
  const [assignedOnSortState, setAssignedOnSortState] = useState(
    SORT_STATES.UNSORTED
  );
  const [dueOnSortState, setDueOnSortState] = useState(SORT_STATES.UNSORTED);
  const [assignedBySortState, setAssignedBySortState] = useState(
    SORT_STATES.UNSORTED
  );
  const [statusSortState, setStatusSortState] = useState(SORT_STATES.UNSORTED);
  const [durationSortState, setDurationSortState] = useState(
    SORT_STATES.UNSORTED
  );
  const [scoreSortState, setScoreSortState] = useState(SORT_STATES.UNSORTED);
  const { dateFormat } = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const sortByName = () => {
    setAllAssignedTests((prev) => {
      let arr = [...prev];
      arr = arr.sort(function (a, b) {
        if (a.studentName < b.studentName) {
          return -1;
        }
        if (a.studentName > b.studentName) {
          return 1;
        }
        return 0;
      });
      return arr;
    });

    setFilteredTests((prev) => {
      let arr = [...prev];
      console.log("arr", arr);
      arr = arr.sort(function (a, b) {
        if (a.studentName < b.studentName) {
          return -1;
        }
        if (a.studentName > b.studentName) {
          return 1;
        }
        return 0;
      });
      return arr;
    });
  };

  const sortByStudentName = () => {
    if (studentNameSortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.studentName < b.studentName) {
            return -1;
          }
          if (a.studentName > b.studentName) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.studentName < b.studentName) {
            return -1;
          }
          if (a.studentName > b.studentName) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setStudentNameSortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      studentNameSortState === SORT_STATES.UNSORTED ||
      studentNameSortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.studentName < b.studentName) {
            return 1;
          }
          if (a.studentName > b.studentName) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.studentName < b.studentName) {
            return 1;
          }
          if (a.studentName > b.studentName) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setStudentNameSortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const sortByTestName = () => {
    if (testNameSortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.testName < b.testName) {
            return -1;
          }
          if (a.testName > b.testName) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.testName < b.testName) {
            return -1;
          }
          if (a.testName > b.testName) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setTestNameSortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      testNameSortState === SORT_STATES.UNSORTED ||
      testNameSortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.testName < b.testName) {
            return 1;
          }
          if (a.testName > b.testName) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.testName < b.testName) {
            return 1;
          }
          if (a.testName > b.testName) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setTestNameSortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const sortByAssignedDate = () => {
    if (assignedOnSortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.assignedOn) < new Date(b.assignedOn)) {
            return -1;
          }
          if (new Date(a.assignedOn) > new Date(b.assignedOn)) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.assignedOn) < new Date(b.assignedOn)) {
            return -1;
          }
          if (new Date(a.assignedOn) > new Date(b.assignedOn)) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setAssignedOnSortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      assignedOnSortState === SORT_STATES.UNSORTED ||
      assignedOnSortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.assignedOn) < new Date(b.assignedOn)) {
            return 1;
          }
          if (new Date(a.assignedOn) > new Date(b.assignedOn)) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.assignedOn) < new Date(b.assignedOn)) {
            return 1;
          }
          if (new Date(a.assignedOn) > new Date(b.assignedOn)) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setAssignedOnSortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const sortByDueDate = () => {
    if (dueOnSortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.dueDate) < new Date(b.dueDate)) {
            return -1;
          }
          if (new Date(a.dueDate) > new Date(b.dueDate)) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.dueDate) < new Date(b.dueDate)) {
            return -1;
          }
          if (new Date(a.dueDate) > new Date(b.dueDate)) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setDueOnSortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      dueOnSortState === SORT_STATES.UNSORTED ||
      dueOnSortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.dueDate) < new Date(b.dueDate)) {
            return 1;
          }
          if (new Date(a.dueDate) > new Date(b.dueDate)) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        //console.log("arr", arr);
        arr = arr.sort(function (a, b) {
          if (new Date(a.dueDate) < new Date(b.dueDate)) {
            return 1;
          }
          if (new Date(a.dueDate) > new Date(b.dueDate)) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setDueOnSortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const sortByAssignedBy = () => {
    if (assignedBySortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.assignedBy < b.assignedBy) {
            return -1;
          }
          if (a.assignedBy > b.assignedBy) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.assignedBy < b.assignedBy) {
            return -1;
          }
          if (a.assignedBy > b.assignedBy) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setAssignedBySortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      assignedBySortState === SORT_STATES.UNSORTED ||
      assignedBySortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.assignedBy < b.assignedBy) {
            return 1;
          }
          if (a.assignedBy > b.assignedBy) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.assignedBy < b.assignedBy) {
            return 1;
          }
          if (a.assignedBy > b.assignedBy) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setAssignedBySortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const sortByStatus = () => {
    if (statusSortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setStatusSortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      statusSortState === SORT_STATES.UNSORTED ||
      statusSortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.status < b.status) {
            return 1;
          }
          if (a.status > b.status) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.status < b.status) {
            return 1;
          }
          if (a.status > b.status) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setStatusSortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const sortByDuration = () => {
    if (durationSortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.duration < b.duration) {
            return -1;
          }
          if (a.duration > b.duration) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.duration < b.duration) {
            return -1;
          }
          if (a.duration > b.duration) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setDurationSortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      durationSortState === SORT_STATES.UNSORTED ||
      durationSortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.duration < b.duration) {
            return 1;
          }
          if (a.duration > b.duration) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (a.duration < b.duration) {
            return 1;
          }
          if (a.duration > b.duration) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setDurationSortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const sortByScore = () => {
    /* console.log("sortByScore");
      console.log(filteredTests); */
    const hasProperScoresProperty = (item) => {
      if (
        item.scores === "-" ||
        item.scores === undefined ||
        item.scores === null ||
        Object.keys(item).length === 0
      ) {
        return false;
      }

      if (
        item.scores.cumulative === undefined ||
        item.scores.cumulative === null
      ) {
        return false;
      }

      return true;
    };

    if (scoreSortState === SORT_STATES.DESCENDING_ORDER) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (!hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return -1;
          }

          if (!hasProperScoresProperty(a) && hasProperScoresProperty(b)) {
            return -1;
          }

          if (hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return 1;
          }

          if (a.scores.cumulative < b.scores.cumulative) {
            return -1;
          }
          if (a.scores.cumulative > b.scores.cumulative) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (!hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return -1;
          }

          if (!hasProperScoresProperty(a) && hasProperScoresProperty(b)) {
            return -1;
          }

          if (hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return 1;
          }

          if (a.scores.cumulative < b.scores.cumulative) {
            return -1;
          }
          if (a.scores.cumulative > b.scores.cumulative) {
            return 1;
          }
          return 0;
        });
        return arr;
      });

      setScoreSortState(SORT_STATES.ASCENDING_ORDER);
    } else if (
      scoreSortState === SORT_STATES.UNSORTED ||
      scoreSortState === SORT_STATES.ASCENDING_ORDER
    ) {
      setAllAssignedTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (!hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return -1;
          }

          if (!hasProperScoresProperty(a) && hasProperScoresProperty(b)) {
            return 1;
          }

          if (hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return -1;
          }

          if (a.scores.cumulative < b.scores.cumulative) {
            return 1;
          }
          if (a.scores.cumulative > b.scores.cumulative) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setFilteredTests((prev) => {
        let arr = [...prev];
        arr = arr.sort(function (a, b) {
          if (!hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return -1;
          }

          if (!hasProperScoresProperty(a) && hasProperScoresProperty(b)) {
            return 1;
          }

          if (hasProperScoresProperty(a) && !hasProperScoresProperty(b)) {
            return -1;
          }

          if (a.scores.cumulative < b.scores.cumulative) {
            return 1;
          }
          if (a.scores.cumulative > b.scores.cumulative) {
            return -1;
          }
          return 0;
        });
        return arr;
      });

      setScoreSortState(SORT_STATES.DESCENDING_ORDER);
    }
  };

  const tempTableHeaders = [
    // setFilteredTests
    {
      id: 1,
      text: "Student Name", // studentName
      // className: "no-arrow",
      noArrow: false,
      onCick: sortByStudentName,
      willDisplayDownArrow:
        studentNameSortState !== SORT_STATES.DESCENDING_ORDER,
    },
    {
      id: 2,
      // className: "no-arrow",
      text: "Assignment Name", // testName
      onCick: sortByTestName,
      willDisplayDownArrow: testNameSortState !== SORT_STATES.DESCENDING_ORDER,
    },
    {
      id: 3,
      text: "Assigned On", // createdAt , assignedOn
      // className: "text-left pl-6 no-arrow",
      onCick: sortByAssignedDate,
      willDisplayDownArrow:
        assignedOnSortState !== SORT_STATES.DESCENDING_ORDER,
    },
    {
      id: 4,
      text: "Due On", // dueDate
      // className: "text-left pl-6 no-arrow",
      onCick: sortByDueDate,
      willDisplayDownArrow: dueOnSortState !== SORT_STATES.DESCENDING_ORDER,
    },
    {
      id: 5,
      // className: "no-arrow",
      text: "Tutor", // assignedBy
      onCick: sortByAssignedBy,
      willDisplayDownArrow:
        assignedBySortState !== SORT_STATES.DESCENDING_ORDER,
    },
    {
      id: 6,
      // className: "no-arrow",
      text: "Completion", // status
      onCick: sortByStatus,
      willDisplayDownArrow: statusSortState !== SORT_STATES.DESCENDING_ORDER,
    },

    {
      id: 7,
      // className: "no-arrow",
      text: "Duration", // duration
      onCick: sortByDuration,
      willDisplayDownArrow: durationSortState !== SORT_STATES.DESCENDING_ORDER,
    },
    {
      id: 8,
      text: "Score", // scores
      onCick: sortByScore,
      willDisplayDownArrow: scoreSortState !== SORT_STATES.DESCENDING_ORDER,
    },
    {
      id: 9,
      // className: "no-arrow",
      text: "",
      noArrow: true,
    },
  ];

  const [assignTestModalActive, setAssignTestModalActive] = useState(false);
  const [resendModalActive, setResendModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const [testToResend, setTestToResend] = useState({});
  const [testToDelete, setTestToDelete] = useState({});

  const { role: persona, id } = useSelector((state) => state.user);
  const handleClose = () => {
	setModalData(initialState)
    setStudentMultiple([]);
	setTestsToBeAssigned([])
    setAssignTestModalActive(false);
  };
  const { organization } = useSelector((state) => state.organization);
  const { subscriptionV2, oldSubscriptionExists, activeSubscriptionInfo } =
    useSelector((state) => state.subscription);
  const [filterData, setFilterData] = useState({
    studentName: "",
    testName: [],
    assignedBy: [],
    status: [],
  });

  const [assignTest, assignTestResp] = useAssignTestMutation();

  const [modalData, setModalData] = useState(initialState);

  const [fetchStudents, studentResponse] =
    useLazyGetVerifiedStudentsByNameQuery();

  const [getAllGroupsOfOrg, groupResponse] = useLazyGetAllGroupsOfOrgQuery();
  const [getAllTutorMappedGroups, tutorGroupResponse] =
    useLazyGetAllTutorMappedGroupsQuery();
  const [getCanAssignTestPermission, canAssignTestPermissionResp] =
    useLazyGetCanAssignTestPermissionQuery();

  const [fetchAssignedTests, assignedTestsResp] =
    useLazyGetAllAssignedTestQuery();
  const [fetchTutorAssignedTests, fetchTutorAssignedTestsResp] =
    useLazyGetTutorAssignedTestsQuery();
  const [fetchTests, fetchTestsResp] = useLazyGetTestsByNameQuery();
  const [deleteAssignedTest, deleteAssignedTestResp] = useLazyDeleteTestQuery();
  const [fetchTutorStudents, tutorStudentsResp] =
    useLazyGetVerifiedTutorStudentsByNameQuery();
  const [assignedBys, setAssignedBys] = useState([]);
  const [currentUser, setCurrentUser] = useState({ name: "english" });
  const [students, setStudents] = useState([]);
  const { firstName, lastName } = useSelector((state) => state.user);
  const [testsData, setTestsData] = useState([]);
  const [maxPageSize, setMaxPageSize] = useState(10);
  const [validData, setValidData] = useState(true);
  const [alldata, setalldata] = useState([]);
  const [selectedstudent, setslecetedstudent] = useState([]);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [studentMultiple, setStudentMultiple] = useState([]);

  //Multiselect assignments
  const [testsToBeAssigned, setTestsToBeAssigned] = useState([])

  useEffect(() => {
    setValidData(
      modalData.name &&
      modalData.limit &&
      modalData.date &&
      modalData.test === "" &&
      modalData.instruction === ""
    );
  }, [modalData.name, modalData.limit, modalData.date, modalData.test]);

  const handleMultipleStudent = (student) => {
    let bool = studentMultiple?.find(
      (student1) => student1?._id === student?._id
    );

    if (bool) {
      //if already exists remove
      let updated = studentMultiple.filter((test) => test?._id !== student._id);
      //set state after removing
      setStudentMultiple(updated);

      let updated2 = selectedstudent.filter(
        (test) => test?._id !== student._id
      );
      setslecetedstudent(updated2);
    } else {
      //does not exists already.. add
      setStudentMultiple((prev) => {
        return [...prev, { _id: student?._id, value: student?.value }];
      });
      const foundObject = alldata.find((obj) => obj._id === student?._id);
      const updated2 = selectedstudent;
      updated2.push(foundObject);
      setslecetedstudent(updated2);
    }
    console.log(studentMultiple);
    console.log(selectedstudent);
    //checkTextWidth();
  };


  console.log(studentMultiple, selectedstudent)
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };
  const handleOptionCLose = () => {
    return;
  };

  useEffect(() => {
    //modalData.name.trim() === "" ||
    //modalData.studentId.trim() === ""

    console.log("modal--->", modalData);
    console.log("modal", studentMultiple);

    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (
      studentMultiple?.length === 0 ||
      modalData.limit.trim() === "" ||
      modalData.date === "" ||
      testsToBeAssigned.length === 0 ||
      modalData.date === ""
    ) {
      setSubmitBtnDisabled(true);
    } else {
      setSubmitBtnDisabled(false);
    }
  }, [
    studentMultiple,
    modalData.name,
    modalData.limit,
    modalData.date,
    modalData.testId,
    modalData.studentId,
  ]);
  const handleNavigate = (role, id) => {
    console.log("clicked");
    navigate(`/profile/${role}/${id}`);
  };

  useEffect(() => {
    if (newAssignmnetRadioSelection === "Student") {
      if (modalData.name.length >= 0) {
        if (persona === "admin") {
          fetchStudents(modalData.name).then((res) => {
            console.log("res", res);
            if (res.error) {
              console.log("res", res);
              return;
            }

            setalldata(res.data.students);
            let tempData = res.data.data.students.map((student) => {
              return {
                _id: student._id,
                value: `${student.firstName} ${student.lastName}`,
              };
            });
            setStudents(tempData);
          });
        } else {
          fetchTutorStudents(modalData.name).then((res) => {
            if (res.error) {
              return;
            }
            const results = res.data.data.students;
            const uniqueStudents = results.filter(
              (student, index, self) =>
                index === self.findIndex((s) => s._id === student._id)
            );
            setalldata(uniqueStudents);
            let tempData = uniqueStudents.map((student) => {
              return {
                _id: student._id,
                value: `${student.firstName} ${student.lastName}`,
              };
            });

            setStudents(tempData);
          });
        }
      }
    } else if (newAssignmnetRadioSelection === "Group") {
      if (persona === "admin") {
        getAllGroupsOfOrg().then((res) => {
          console.log("all groups of org-------->", res);
          if (res.error) {
            console.log("res", res);
            alert(res.error.data?.message);
            return;
          }
          setalldata(res.data.data.groups);
          let tempData = res.data.data.groups.map((group) => {
            return {
              _id: group._id,
              value: group.name,
            };
          });
          setStudents(tempData);
        });
      } else {
        console.log("in tutor persona");
        console.log(id);
        getAllTutorMappedGroups({ id }).then((res) => {
          console.log("res", res);
          if (res.error) {
            console.log("res", res);
            alert(res.error.data?.message);
            return;
          }
          setalldata(res.data.data.groups);
          let tempData = res.data.data.groups.map((group) => {
            return {
              _id: group._id,
              value: group.name,
            };
          });
          setStudents(tempData);
        });
      }
    }
  }, [modalData.name, newAssignmnetRadioSelection]);

  useEffect(() => {
    if (modalData.test.length >= 0) {
      fetchTests(modalData.test).then((res) => {
        let tests = [];
        if (persona === "admin" || persona === "tutor") {
          tests = res.data.data.test
            .map((test) => {
              if (
                (test.addBySuperAdmin || test.addByManager) &&
                (subscriptionV2 ||
                  activeSubscriptionInfo ||
                  (!subscriptionV2 && oldSubscriptionExists))
              ) {
                if (
                  (!subscriptionV2 && oldSubscriptionExists) ||
                  (test.availableFor?.includes("trial") &&
                    activeSubscriptionInfo?.status === "trialing") ||
                  test.availableFor?.includes(
                    subscriptionV2?.subscription.basePackage.name
                      .split("_")[1]
                      .toLowerCase()
                  ) ||
                  (subscriptionV2?.subscription?.basePackage?.name.includes(
                    "Free"
                  ) &&
                    (test.availableFor?.includes("trial") || test.availableFor?.includes("free")))
                ) {
                  console.log("OSE", oldSubscriptionExists);
                  return {
                    _id: test._id,
                    value: test.testName,
                    testType: test.testType,
                  };
                }
                if (
                  (!subscriptionV2 && oldSubscriptionExists) ||
                  test.availableFor?.includes(
                    activeSubscriptionInfo?.planName?.toLowerCase()
                  ) ||
                  test.availableFor?.includes(
                    subscriptionV2?.subscription.basePackage.name
                      .split("_")[1]
                      .toLowerCase()
                  )
                ) {
                  console.log("!!####");
                  return {
                    _id: test._id,
                    value: test.testName,
                    testType: test.testType,
                  };
                } else {
                  return null;
                }
              } else {
                return {
                  _id: test._id,
                  value: test.testName,
                  testType: test.testType,
                };
              }
            })
            .filter((test) => test !== null);

          console.log("tests", tests);

          setTestsData(tests);
        }

        // let tempData = res.data.data.test.map((test) => {
        //   return {
        //     _id: test._id,
        //     value: test.testName,
        //     testType: test.testType,
        //   };
        // });
        // setTestsData(tempData);
      });
    }
  }, [modalData.test]);

  const fetchAllAssignedTests = () => {
    fetchAssignedTests().then((res) => {
      if (res.error) return console.log(res.error);
      console.log("assigned res", res.data.data);

      let allAssignedBys = [];
      let data = res.data.data.test.map((item) => {
        const {
          createdAt,
          studentId,
          testId,
          dueDate,
          multiple,
          timeLimit,
          isCompleted,
          isStarted,
          assignedBy,
          endTestTime,
        } = item;
        const assignedByName = `${assignedBy?.firstName ? assignedBy?.firstName : "-"
          } ${assignedBy?.lastName ? assignedBy?.lastName : ""}`;
        if (assignedBy) {
          if (!allAssignedBys.includes(assignedByName)) {
            allAssignedBys.push(assignedByName);
          }
        }
        return {
          studentName: studentId
            ? `${studentId.firstName} ${studentId.lastName}`
            : "-",
          studentId: studentId ? studentId._id : "-",
          assignedOn: createdAt,
          assignedBy: assignedBy
            ? `${assignedBy?.firstName} ${assignedBy?.lastName}`
            : "-",
          testName: testId ? testId.testName : "-",
          testId: testId ? testId._id : null,
          pdfLink: testId ? testId.pdf : null,
          scores: "-",
          duration: getDuration(multiple),
          dueDate: dueDate,
          status:
            isCompleted === true
              ? "completed"
              : isStarted
                ? "started"
                : "notStarted",
          createdAt,
          assignedTestId: item._id,
          endTestTime: endTestTime ? new Date(endTestTime) : null,
        };
      });
      setAssignedBys(allAssignedBys);
      let sortedArr = data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      if (persona === "admin")
        setFilterData({
          studentName: "",
          testName: [],
          assignedBy: [],
          status: [],
        });
      setAllAssignedTests(sortedArr);
      setFilteredTests(sortedArr);
    });
  };

  const fetchTutorTests = () => {
    fetchTutorAssignedTests(id).then((res) => {
      if (res.error) return console.log("tutor assignedtest", res.error);
      console.log("tutor assignedtest", res.data);
      let allAssignedBys = [];

      let data = res.data.data.test.map((item) => {
        const {
          createdAt,
          studentId,
          dueDate,
          testId,
          multiple,
          timeLimit,
          isCompleted,
          isStarted,
          assignedBy,
        } = item;
        const assignedByName = `${assignedBy?.firstName ? assignedBy?.firstName : "-"
          } ${assignedBy?.lastName ? assignedBy?.lastName : ""}`;
        if (assignedBy) {
          if (!allAssignedBys.includes(assignedByName)) {
            allAssignedBys.push(assignedByName);
          }
        }
        return {
          studentName: studentId
            ? `${studentId.firstName} ${studentId.lastName}`
            : "-",
          studentId: studentId ? studentId._id : "-",
          assignedOn: getFormattedDate(createdAt),
          assignedBy: assignedBy
            ? `${assignedBy?.firstName ? assignedBy?.firstName : "-"} ${assignedBy?.lastName ? assignedBy?.lastName : ""
            }`
            : "-",
          testName: testId ? testId.testName : "-",
          testId: testId ? testId._id : null,
          pdfLink: testId ? testId.pdf : null,
          scores: "-",
          duration: getDuration(multiple),
          status:
            isCompleted === true
              ? "completed"
              : isStarted
                ? "started"
                : "notStarted",
          createdAt,
          dueDate: getFormattedDate(dueDate),
          assignedTestId: item._id,
        };
      });
      console.log("allAssignedBys", allAssignedBys);
      setAssignedBys(allAssignedBys);
      let sortedArr = data.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      if (persona === "tutor")
        setFilterData({
          studentName: "",
          testName: [],
          assignedBy: [],
          status: [],
        });
      setAllAssignedTests(sortedArr);
      setFilteredTests(sortedArr);
    });
  };

  const getTimeLimit = (val) => {
    if (val === "Regular") return 1;
    if (val === "1.1x") return 1.1;
    if (val === "1.25x") return 1.25;
    if (val === "1.5x") return 1.5;
    if (val === "Unlimited") return 0;
    return 1;
  };

  const fetch = () => {
    if (persona === "admin") {
      fetchAllAssignedTests();
    } else if (persona === "tutor") {
      fetchTutorTests();
    }
  };
  useEffect(() => {
    fetch();
  }, [id, persona]);

  const handleResend = (item) => {
    setTestToResend(item);
    setResendModalActive(true);
  };

  const handleResendTestSubmit = (item) => {
    setResendLoading(true);
    const body = {
      studentId: testToResend.studentId,
      testId: testToResend.testId,
      dueDate: testToResend.dueDate,
      timeLimit: getTimeLimit(testToResend.duration),
    };
    assignTest(body).then((res) => {
      if (res.error) {
        return alert("Something went wrong");
      }
      alert("Assignment Resent");
      setResendModalActive(false);
      setResendLoading(false);
      // console.log(res.data.data.assign);
      setAssignTestModalActive(false);
      fetch();
    });
  };

  const handleAssignTestSubmit = async () => {
    try {
      if (newAssignmnetRadioSelection === "Student") {
        if (studentMultiple) {
          setLoading(true);
          for (const it of studentMultiple) {
            for (const testId of testsToBeAssigned) {
              const body = {
                studentId: it?._id,
                testId: testId,
                name: it?._value,
                dueDate: new Date(modalData.date),
                instruction: modalData.instruction,
                timeLimit: getTimeLimit(modalData.limit),
                groupAssign: false,
              };
              const res = await assignTest(body)
              if (res.error) {
                throw new Error(res.error?.data?.message)
              }
            }
          }
          setLoading(false)
          triggerToast("All assignments sent successfully", "success", { autoClose: 3000 })
          setModalData(initialState);
          // console.log("test assigned", res.data.data.assign);
          //alert("Test Assigned!");
          setAssignTestModalActive(false);
		  setTestsToBeAssigned([]);
          setStudentMultiple([]);
          setslecetedstudent([]);
          selselectedtext("");
          fetch();

        }

      } else {
        const id = toast.loading("Assigning assignments");
        setLoading(true);
        for (const it of selectedstudent) {
          for (const ele of it?.students) {
            for (const testId of testsToBeAssigned) {
              const body = {
                studentId: ele?._id,
                testId: testId,
                name: ele.firstName + " " + ele.lastName,
                dueDate: new Date(modalData.date),
                instruction: modalData.instruction,
                timeLimit: getTimeLimit(modalData.limit),
                groupAssign: true,
              };
              const res = await assignTest(body);
              if (res.error) {
                throw new Error(res?.error?.data?.message)
              }
            }
          }
        }
        setLoading(false)
        setModalData(initialState);
        // console.log("test assigned", res.data.data.assign);
        //alert("Test Assigned!");
        setAssignTestModalActive(false);
		setTestsToBeAssigned([])
        setStudentMultiple([]);
        setslecetedstudent([]);
        selselectedtext("");
        fetch();
        toast.update(id, {
          isLoading: false,
          render: "",
          autoClose: 1,
          type: "success",
        });
        triggerToast("Assignment(s) sent successfully.", "success", {
          autoClose: 3000,
        });
      }
    } catch (err) {
      setLoading(false)
      triggerToast(err.message ?? "Error please try again", "error", { autoClose: 3000 })
      toast.update(id, {
        isLoading: false,
        render: "",
        autoClose: 1,
        type: "error",
      });
    }

  };

  useEffect(() => {
    let tempdata = [...allAssignedTests];

    if (filterData.studentName !== "") {
      const escapedStudentName = filterData.studentName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex2 = new RegExp(`${escapedStudentName.toLowerCase()}`, "i");
      tempdata = tempdata.filter((test) => test.studentName.match(regex2));
    } else {
      tempdata = tempdata.filter((test) => test.studentName !== "");
    }

    // if (filterData.testName !== "") {
    //   const regex2 = new RegExp(`${filterData.testName.toLowerCase()}`, "i");
    //   tempdata = tempdata.filter((test) => test.testName.match(regex2));
    // } else {
    //   tempdata = tempdata.filter((test) => test.testName !== "");
    // }

    if (filterData.testName.length > 0) {
      const allTestNamesInLowerCase = filterData.testName.map((val) => val?.toLowerCase())
      tempdata = tempdata.filter((test) => allTestNamesInLowerCase.includes(test?.testName?.toLowerCase()))
    }


    // if (filterData.status !== "") {
    //   const selectedStatus = getStatus(filterData.status);
    //   tempdata = tempdata.filter((user) => user.status === selectedStatus);
    // } else {
    //   tempdata = tempdata.filter((user) => user.status !== "");
    // }

    if (filterData.status.length > 0) {
      const allSelectedStatuses = filterData.status.map((val) => getStatus(val))
      tempdata = tempdata.filter((user) => allSelectedStatuses.includes(user.status))
    }

    // if (filterData.assignedBy !== "") {
    //   tempdata = tempdata.filter(
    //     (user) => user.assignedBy === filterData.assignedBy
    //   );
    // } else {
    //   tempdata = tempdata.filter((user) => user.assignedBy !== "");
    // }

    if (filterData.assignedBy.length > 0) {
      tempdata = tempdata.filter((user) => filterData.assignedBy.includes(user.assignedBy))
    }

    setFilteredTests(tempdata);
  }, [filterData]);

  const removeFilter = (key) => {
    let tempFilterData = { ...filterData };
    if (key === "studentName") {
      tempFilterData[key] = "";
    } else {
      const requiredKey = key.split("_")[0]
      const indexToRemove = parseInt(key.split("_").pop())
      tempFilterData[requiredKey] = tempFilterData[requiredKey].filter((val, idx) => idx !== indexToRemove)
    }

    setFilterData(tempFilterData);
  };

  const getStatus = (status) => {
    if (status === "Completed") return "completed";
    if (status === "Started") return "started";
    if (status === "Not Started") return "notStarted";
  };

  useEffect(() => {

    let arr = Object.keys(filterData)
      .map((key) => {
        if (filterData[key] !== "" && key === "studentName") {
          return {
            text: Array.isArray(filterData[key]) ? filterData[key].join(", ") : filterData[key],
            type: key,
            removeFilter: (key) => removeFilter(key),
          };
        }
      })
      .filter((item) => item !== undefined);

    Object.keys(filterData).map((key, idx) => {
      if (key !== "studentName" && key !== "testNameSearch") {
        filterData[key].forEach((val, idx) => {
          arr.push({
            text: val,
            type: key + "_" + idx,
            removeFilter: (key) => removeFilter(key)
          })
        })
      }
    })

    setFilterItems(arr);
  }, [filterData]);

  const onRemoveFilter = (item) => item.removeFilter(item.type);

  useEffect(() => {
    setTableData(tempTableData);
    setTableHeaders(tempTableHeaders);
  }, []);

  const deleteTest = () => {
    console.log("deleteTest", testToDelete);
    setDeleteLoading(true);
    deleteAssignedTest({ id: testToDelete.assignedTestId }).then((res) => {
      setDeleteLoading(false);
      if (res.error) {
        console.log("delete err", res.error.data);
        if (res.error.data.message) {
          alert(res?.error?.data?.message);
          setDeleteModalActive(false);
        }
        setDeleteModalActive(false);
        return;
      }
      fetch();
      alert("Assignment Deleted");
      setDeleteModalActive(false);
      console.log("delete test res", res.data);
    });
  };

  const handleDelete = (item) => {
    setTestToDelete(item);
    setDeleteModalActive(true);
  };

  const handleCurrentUser = (item) => {
    setCurrentUser({
      name: item.text.toLowerCase(),
    });
  };
  const status = [
    {
      text: "Completed",
      color: "#0671E0",
    },
    {
      text: "Not Started",
      color: "#F97066",
    },
    {
      text: "Started",
      color: "#A8ADBD",
    },
  ];
  const [numberChecked, setnumberChecked] = useState(0);
  useEffect(() => {
    if (!allAssignedTests) return;
    let testNames = [];
    let studentNames = [];
    allAssignedTests.forEach((item) => {
      if (!testNames.includes(item.testName)) {
        testNames.push(item.testName);
      }
      if (!studentNames.includes(item.studentName)) {
        studentNames.push(item.studentName);
      }
    });
    setTestNameOptions(testNames);
    setStudentNameOptions(studentNames);
  }, [allAssignedTests]);

  const handleStatus = (val) => {
    console.log("STATUS CHANGE")
    let presentStatuses = filterData.status
    if (presentStatuses.includes(val)) {
      presentStatuses = presentStatuses.filter((status) => status !== val)
    } else {
      presentStatuses.push(val)
    }
    setFilterData({ ...filterData, status: presentStatuses })
  };

  const handleTutorChange = (val) => {
    let presentTutorNames = filterData.assignedBy

    if (presentTutorNames.includes(val)) {
      presentTutorNames = presentTutorNames.filter((tutorName) => tutorName !== val)
    } else {
      presentTutorNames.push(val)
    }

    console.log(val, presentTutorNames)
    setFilterData({ ...filterData, assignedBy: presentTutorNames })
  };

  const handleCheckboxChange = () => {
    setCurrentPage(currentPage);
    if (!isChecked) {
      let data = filteredTests;
      // console.log('datacccc',currentPage);
      let f = (currentPage - 1) * 10;
      data = data?.slice(f, f + maxPageSize);
      setSelectedId([...data]);
    } else {
      setSelectedId([]);
    }
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    setIsChecked(false);
    setSelectedId([]);
  }, [filteredTests]);

  const [filterOptions, setFilterOptions] = useState(false);

  const handleOptionData = (val) => {
    let data = [];

    testNameOptions?.map((it, id) => {
      if (it?.toLowerCase()?.includes(val?.toLowerCase()))
        data.push({
          _id: id.toString(),
          value: it,
        });
    });
    setFilterOptions(data);
    return;
  };

  useEffect(() => {
    handleOptionData("");
  }, [testNameOptions]);
  const [selectedId, setSelectedId] = useState([]);

  const [addDeleteUser, slsdu] = useCRMBulkdeleteMutation();
  const [addMark, slmr] = useCRMBulkmarkcompletedMutation();
  const [addResend, slrsn] = useCRMBulkresentMutation();
  const bulkSelectDelete = () => {
    let assignmentIds = selectedId?.map((ii) => ii?.assignedTestId);
    if (!assignmentIds || assignmentIds?.length === 0) return;
    setDeleteSelectLoading(true);
    addDeleteUser({ assignmentIds }).then((res) => {
      console.log("successDelete", res, assignmentIds);
      if (res?.data) alert("Assignments deleted successfully!");
      setDeleteSelectLoading(false);
      setDeleteBulkModalActive(false);
      fetch();
    });
  };
  const markSelectDelete = () => {
    let assignmentIds = selectedId?.map((ii) => ii?.assignedTestId);
    if (!assignmentIds || assignmentIds?.length === 0) return;
    setMarkSelectLoading(true);
    addMark({ assignmentIds }).then((res) => {
      console.log("successMark", res, assignmentIds);
      if (res?.data) alert("Selected Assignments marked completed!");
      setMarkSelectLoading(false);
      setMarkBulkModalActive(false);
      fetch();
    });
  };
  const resendSelectDelete = () => {
    let assignmentIds = selectedId?.map((ii) => ii?.assignedTestId);
    if (!assignmentIds || assignmentIds?.length === 0) return;
    setResendSelectLoading(true);
    addResend({ assignmentIds }).then((res) => {
      console.log("successResend", res, assignmentIds);
      if (res?.data) alert("Assignments resent!");
      setResendSelectLoading(false);
      setResendBulkModalActive(false);
      fetch();
    });
  };
  const [deleteBulkModalActive, setDeleteBulkModalActive] = useState(false);
  const [deleteSelectLoading, setDeleteSelectLoading] = useState(false);

  const [markBulkModalActive, setMarkBulkModalActive] = useState(false);
  const [markSelectLoading, setMarkSelectLoading] = useState(false);
  const [resendBulkModalActive, setResendBulkModalActive] = useState(false);
  const [resendSelectLoading, setResendSelectLoading] = useState(false);
  const [selectedtext, selselectedtext] = useState("");
  const checkTextWidth = () => {
    const container = document.querySelector(".student-name-container");
    const text = document.querySelector(".text-container");
    console.log(text.innerHTML.length, container);

    if (text && container) {
      const limit22 = 3.4;
      const maxStringLength =
        Math.floor((container.offsetWidth - 100) / limit22) - 20;
      let stri = "";

      let f = false;
      let tot = 0;
      // console.log(selectedstudent);
      for (const student of selectedstudent) {
        console.log(stri.length + student?.firstName?.length, maxStringLength);
        if (stri.length + student?.firstName?.length < maxStringLength) {
          if (f) {
            stri += ", " + student?.firstName;
          } else {
            f = true;
            stri += student?.firstName;
          }
        } else {
          stri += ` ... total ${studentMultiple?.length} selected`;
          break;
        }

        tot += student?.firstName?.length;
      }

      console.log("Text has covered the whole width. Needs to be cropped.");
      console.log("Cropped Text:", stri);
      selselectedtext(stri);
    }
  };

  return (
    <>
      <div className="w-[1920px]  flex justify-center items-center">
        <div className="w-[1600px] h-auto flex flex-col justify-center items-center">
          <div className=" w-full">
            {/* pathName */}
            <div className="flex justify-between items-center ">
              <div className="mt-[50px] mb-[41.23px]">
                <BreadCrumb />
              </div>

              {persona === "parent" && (
                <div className="flex justify-around whitespace-nowrap items-center gap-6">
                  <InputField
                    IconRight={SearchIcon}
                    value={filterData.studentName}
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        studentName: e.target.value,
                      })
                    }
                    placeholder="Search Student"
                    inputClassName="text-[17.5px] pl-4 text-[#667085] placeholder:text-base-15"
                    parentClassName="w-[400px] h-[50px]"
                    inputContainerClassName="text-[17.5px]  mt-1 rows-box-shadow rounded-[7.5px] border-white bg-white   mb-1"
                    type="text"
                  />
                  <div className="flex items-end justify-end gap-[20px] mt-[10px] h-[45px]">
                    {status.map(({ text, color }, idx) => (
                      <AssignedTestIndicator
                        key={idx}
                        text={text}
                        color={color}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

            {(persona === "admin" || persona === "tutor") && (
              <>
                <div className="flex items-start">
                  {persona === "student" ? (
                    <p className={`font-bold text-4xl text-primary-dark`}>
                      Assigned Tests
                    </p>
                  ) : (
                    <></>
                  )}

                  {/* search Input */}
                  <InputField
                    IconRight={SearchIcon}
                    value={filterData.studentName}
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        studentName: e.target.value,
                      })
                    }
                    placeholder="Search Student"
                    inputClassName="w-full text-[17.5px] text-[#667085] placeholder:font-[400] placeholder:text-[17.5px] placeholder:text-[#667085] pl-[18px] placeholder:font-normal"
                    parentClassName="w-[400px] h-[50px] text-md me-[30px]"
                    inputContainerClassName="rows-box-shadow rounded-[7.5px] border-white bg-white !py-0 h-full"
                    type="text"
                  />
                  {/* search Assignment */}
                  <InputSearch
                    IconRight={SearchIcon}
                    placeholderClass="text-[17.5px] text-[#667085] font-normal"
                    optionListClassName="text-[17.5px] text-[#667085]"
                    inputClassName="placeholder:text-[#667085] text-[17.5px] text-[#667085]"
                    labelClassname={"hidden"}
                    dropDownIconStatus={true}
                    inputContainerClassName="rows-box-shadow rounded-[7.5px] border-white bg-white text-[#667085] !pr-[14.34px]"
                    placeholder="Search Assignment"
                    parentClassName="w-[400px] text-[17.5px] text-[#667085] h-[50px] me-[30px] ml-[30px]"
                    type="select"
                    value={filterData.testNameSearch}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        testNameSearch: e.target.value,
                      });
                      handleOptionData(e.target.value);
                    }}
                    optionData={filterOptions}
                    onOptionClick={(item) => {
                      console.log(item?.value)
                      let presentTestNames = [...filterData.testName]
                      if (presentTestNames.includes(item?.value)) {
                        presentTestNames = presentTestNames.filter((testName) => testName !== item?.value)
                      } else {
                        presentTestNames.push(item?.value)
                      }
                      console.log(presentTestNames)
                      setFilterData({ ...filterData, testName: presentTestNames });
                      console.log(filterOptions)
                    }}
                    checkbox={{
                      visible: true,
                      name: "test",
                      checkedFunction: (option) => {
                        return filterData.testName.includes(option?.value)
                      }
                    }}
                  />
                  {/*  */}
                  <InputSelect
                    value={""}
                    onChange={(val) => handleStatus(val)}
                    optionListClassName="text-[17.5px] text-[#667085]"
                    placeholderClass="text-[17.5px] font-normal"
                    optionData={["Started", "Not Started", "Completed"]}
                    inputClassName="text-[17.5px] !py-3"
                    inputContainerClassName="rows-box-shadow rounded-[7.5px]  bg-white !py-3 h-[50px]"
                    optionClassName=""
                    placeholder="Completion"
                    parentClassName="w-[230px] text-[17.5px] text-[#667085] me-[30px] ml-[30px]"
                    type="select"
                    checkbox={{
                      visible: true,
                      checkedFunction: (status) => {
                        return filterData.status.includes(status)
                      }
                    }}
                  />

                  {persona === "tutor" && (
                    <InputSelect
                      value={""}
                      onChange={(val) => { handleTutorChange(val) }}
                      optionListClassName="text-[17.5px] text-[#667085]"
                      parentClassName="w-[230px] text-[17.5px] mr-[57px] ml-[30px]"
                      inputClassName="text-[17.5px] py-3"
                      inputContainerClassName="rows-box-shadow rounded-[7.5px] bg-white h-[50px]"
                      placeholderClass="text-[17.5px] font-normal"
                      optionData={assignedBys}
                      placeholder="Tutor"
                      type="text"
                      checkbox={{
                        visible: true,
                        checkedFunction: (tutorName) => {
                          return filterData.assignedBy.includes(tutorName)
                        }
                      }}
                    />
                  )}

                  {persona === "tutor" ? (
                    <div className="flex flex-row items-center justify-end gap-[19.2px]">
                      <div className="flex justify-end">
                        <div>
                          <button
                            className={`bg-blue-70 text-[15px] justify-center flex py-[7px] pl-1 items-center text-white font-bold rounded-[7.5px] text-base-15 w-[193px] h-[50px] px-[10px] ${(!organization.isEnabledPAYG &&
                              subscriptionV2?.subscription.assignments
                                .limit <=
                              subscriptionV2?.subscription.usage.assignments
                                .base) ||
                              (!subscriptionV2 && !oldSubscriptionExists)
                              ? "pointer-events-none opacity-[0.2]"
                              : ""
                              }`}
                            onClick={() => setAssignTestModalActive(true)}
                          >
                            New Assignment
                            <img
                              src={AddIcon}
                              className="ms-[10px]"
                              alt="new test"
                            />
                          </button>
                        </div>
                      </div>
                      {(!organization.isEnabledPAYG &&
                        subscriptionV2?.subscription.assignments.limit <=
                        subscriptionV2?.subscription.usage.assignments
                          .base) ||
                        (!subscriptionV2 && !oldSubscriptionExists) ? (
                        <Tooltip
                          tooltipStyleClass={
                            "absolute !-left-[220px] !top-[50px]"
                          }
                          anchorComponent={<LockIcon />}
                          tooltipContent={
                            <div className="bg-white  w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26]">
                              <div className="flex items-center gap-1">
                                <h2 className="font-medium text-[14px] leading-[21px] text-secondary-solid flex items-center justify-start gap-[4px]">
                                  <CautionIcon
                                    className="text-[#FFC56D] h-[14px] w-[14px]"
                                    exclamationColour="#25335A"
                                  />
                                  Enable Pay As You Go billing.
                                </h2>
                              </div>
                              <div className="font-normal text-[12px] leading-[18px] text-secondary-70 ">
                                You've exhausted your assignment credits for
                                this month. To add further assignments before
                                the next refill, enable Pay As You Go in your
                                subscription settings.
                              </div>
                              <Link
                                className="font-normal text-[12px] leading-[18px]"
                                onClick={(e) => triggerUpdateSubscriptionPopUp()}
                              >
                                Manage Subscription
                              </Link>
                            </div>
                          }
                          tooltipTriangleStyling={styles.tooltipTriangleStyling}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <InputSelect
                      value={""}
                      onChange={(val) => handleTutorChange(val)}
                      optionListClassName="text-[17.5px] text-[#667085]"
                      parentClassName="w-[230px] text-[17.5px] ml-[30px] mr-[57px]"
                      inputClassName="text-[17.5px] py-3"
                      inputContainerClassName="rows-box-shadow rounded-[7.5px] bg-white h-[50px]"
                      placeholderClass="text-[17.5px]"
                      downArrowClassName="!right-[10.34px]"
                      optionData={assignedBys}
                      placeholder="Tutor"
                      type="text"
                      checkbox={{
                        visible: true,
                        checkedFunction: (tutorName) => {
                          return filterData.assignedBy.includes(tutorName)
                        }
                      }}
                    />
                  )}
                  {persona !== "parent" &&
                    persona !== "student" &&
                    persona !== "tutor" && (
                      <div className="flex flex-row items-center gap-[19.2px]">
                        <div className="flex justify-end">
                          <div>
                            <button
                              className={`bg-blue-70 text-[15px] justify-center flex py-[7px] pl-1 items-center text-white font-bold rounded-[7.5px] text-base-15 w-[193px] h-[50px] px-[10px] ${(!organization.isEnabledPAYG &&
                                subscriptionV2?.subscription.assignments
                                  .limit <=
                                subscriptionV2?.subscription.usage
                                  .assignments.base) ||
                                (!subscriptionV2 && !oldSubscriptionExists)
                                ? "pointer-events-none opacity-[0.2]"
                                : ""
                                }`}
                              onClick={() => setAssignTestModalActive(true)}
                            >
                              New Assignment
                              <img
                                src={AddIcon}
                                className="ms-[10px]"
                                alt="new test"
                              />
                            </button>
                          </div>
                        </div>
                        {(!organization.isEnabledPAYG &&
                          subscriptionV2?.subscription.assignments.limit <=
                          subscriptionV2?.subscription.usage.assignments
                            .base) ||
                          (!subscriptionV2 && !oldSubscriptionExists) ? (
                          <Tooltip
                            tooltipStyleClass={
                              "absolute !-left-[220px] !top-[50px]"
                            }
                            anchorComponent={<LockIcon />}
                            tooltipContent={
                              <div className="bg-white  w-[280px] rounded-[12px] p-3 flex flex-col gap-[6px] shadow-[0px_4px_24px_0px_#11167D26]">
                                <div className="flex items-center gap-1">
                                  <h2 className="font-medium text-[14px] leading-[21px] text-secondary-solid flex items-center justify-start gap-[4px]">
                                    <CautionIcon
                                      className="text-[#FFC56D] h-[14px] w-[14px]"
                                      exclamationColour="#25335A"
                                    />
                                    Enable Pay As You Go billing.
                                  </h2>
                                </div>
                                <div className="font-normal text-[12px] leading-[18px] text-secondary-70 ">
                                  You've exhausted your assignment credits for
                                  this month. To add further assignments before
                                  the next refill, enable Pay As You Go in your
                                  subscription settings.
                                </div>
                                <Link
                                  className="font-normal text-[12px] leading-[18px]"
                                  onClick={(e) => triggerUpdateSubscriptionPopUp()}
                                >
                                  Manage Subscription
                                </Link>
                              </div>
                            }
                            tooltipTriangleStyling={
                              styles.tooltipTriangleStyling
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                </div>

                <div className="mt-[25px] mb-[25px]">
                  <FilterItems
                    items={filterItems}
                    setData={setFilterItems}
                    onRemoveFilter={onRemoveFilter}
                  />
                </div>

                {/* filter options */}
                <div className="flex items-center  justify-between gap-[20px]">
                  <div className="flex text-[#26435F] items-center text-[17.5px]">
                    <div className="ml-[33px] flex items-center">
                      <SCheckbox
                        stopM={true}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        assignTestCheckBox={true}
                      />
                      <span
                        className="inline-block text-[17.5px] ml-[23.75px] font-medium"
                        style={{ opacity: "0.8" }}
                      >
                        {selectedId?.length} Selected
                      </span>
                    </div>
                    {(persona === "admin" ||
                      (persona === "tutor" &&
                        organization?.settings?.permissions &&
                        organization?.settings?.permissions[0]
                          ?.choosedValue)) && (
                        <div
                          onClick={() =>
                            selectedId?.length > 0 &&
                            setDeleteBulkModalActive(true)
                          }
                          className="cursor-pointer gap-x-[5px] px-1 font-normal w-[114px] py-[9px] bg-secondary-5 rounded-5 ml-10 flex items-center justify-center text-base-17-5"
                        >
                          <p className="mr-[5px]">Delete</p>
                          <p className="!mt-0">
                            <img
                              className=""
                              src={DeleteIcon}
                              alt="delete-icon"
                              style={{ width: "22.5px", height: "22.5px" }}
                            />
                          </p>
                        </div>
                      )}
                    <div
                      onClick={() =>
                        selectedId?.length > 0 && setResendBulkModalActive(true)
                      }
                      className="cursor-pointer bg-secondary-5 rounded-5 ml-[25px] flex w-[114px] h-[44px] items-center justify-center text-[17.5px] font-normal"
                    >
                      <p className="mr-[5px]">Resend</p>
                      <img src={ResendIcon} className="ml-1" alt="" />
                    </div>
                    <div
                      onClick={() =>
                        selectedId?.length > 0 && setMarkBulkModalActive(true)
                      }
                      className="cursor-pointer bg-secondary-5 rounded-5 ml-[25px] w-[172px] h-[44px] text-center flex justify-center items-center font-normal text-[17.5px]"
                    >
                      Mark Completed
                    </div>
                  </div>
                  <div className="flex items-end justify-between gap-x-[20px] text-[17.5px] h-[45px]">
                    {status.map(({ text, color }, idx) => (
                      <AssignedTestIndicator
                        key={idx}
                        text={text}
                        color={color}
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
            <div className="mt-[17.25px] mb-[133px] min-h-[500px]">
              <Table
                dummyrowsneeded={true}
                dummyRowStarClients={[]}
                showonlyrightpaginationpart="true"
                widthFullTable={true}
                selectedId2={selectedId}
                setSelectedId2={setSelectedId}
                onClick={{ handleResend, handleDelete, handleNavigate }}
                dataFor="assignedTests"
                data={filteredTests}
                headerObject={true}
                setCurrentPageNo={setCurrentPage}
                excludes={["createdAt", "assignedTestId", "pdf"]}
                tableHeaders={tempTableHeaders}
                maxPageSize={maxPageSize}
                setMaxPageSize={setMaxPageSize}
                setAllAssignedTests={setAllAssignedTests}
                setFilteredTests={setFilteredTests}
                theadWidth={"w-[1601.5px]"}
                noArrow={false}
                belowBox={false}
				showPageIndicator = {true}
              />
            </div>
          </div>
        </div>
        {assignTestModalActive && (
          <Modal
            title="New Assignment"
            bodyScrollbarHidden={true}
            primaryBtnClass={"!m-0"}
            parentClass="mt-[30vh]"
            buttonParentClassName="justify-center !gap-[26.67px]"
            titleClassName="mb-[22.67px]"
            titleclass="text-[21.33px]"
            modalSize={`w-[667px] h-[${testsToBeAssigned.length >0 ? "635px" : "585px"}] mx-auto mt-[-90px] !pt-[28px]`}
            cancelBtn={true}
            underlineMargin={"mt-[22.67px] mb-[20px]"}
            cancelBtnClassName="w-[146.67px] h-[46.667px] !mb-0 !mt-0 !text-[#26435F] text-[16px] font-[500] font-['Lexend'] !border-transparent !bg-[#E9ECEF] rounded-[8px]"
            closebuttonClass="text-[#FF6961] rounded-full px-[6px]"
            primaryBtn={{
              text: "Assign",
              className:
                "w-[140px] pl-8 pr-8 bg-blue-70 !text-white !text-[16px] !font-[500] !font-['Lexend']",
              onClick: () => handleAssignTestSubmit(),
              disabled: submitBtnDisabled,

              loading: loading,
            }}
            handleClose={handleClose}
            customCrossBtnSrc={crossIconRedOutLine}
            crossBtnClassName2="!w-[21.33px] !h-[21.33px]"
            body={
              <>
                <div className="h-[250px] grid-cols-1 relative md:grid-cols-2  gap-x-[36px] gap-y-[50px] flex-wrap !flex" style={{height : ((testsToBeAssigned?.length > 0 ) || (studentMultiple?.length > 0))? "250px" : "200px", rowGap : ((testsToBeAssigned?.length > 0 ) || (studentMultiple?.length > 0))? "50px" : "36px"}}>
                  <div className=" student-name-container  h-[72px]">
                    <InputSearch
                      label={
                        <div
                          className={`row-span-1 col-span-2 flex items-center justify-start gap-x-2 mt-3.5 mb-0.5`}
                        >
                          <RadioButtonV2
                            labelText={"Student"}
                            selected={isRadioChecked === 0}
                            onClick={(e) => {
                              setIsRadioChecked(0);
                              setNewAssignmentRadioSelection("Student");
                            }}
                          />
                          <RadioButtonV2
                            labelText={"Groups"}
                            selected={isRadioChecked === 1}
                            onClick={(e) => {
                              setIsRadioChecked(1);
                              setNewAssignmentRadioSelection("Group");
                            }}
                          />
                        </div>
                      }
                      labelClassname="text-[18.6px] text-[#26435F] mb-[8px] !font-medium leading-[1]"
                      placeholder={`Select ${newAssignmnetRadioSelection} Name`}
                      placeholderClass="text-[16px] "
                      parentClassName=" text-[16px] py-0 w-[280px]  "
                      inputContainerClassName="text-[16px] bg-[#F3F5F7] border-0 h-[53.33px] !pl-[13.33px] pt-[10.5px] pb-[13.33]"
                      inputClassName="bg-[#F3F5F7] font-normal p-0  placeholder:text-[16px] placeholder:font-[400] placeholder:text-[#667085] placeholder:font-['Lexend']"
                      type="text"
                      value={
                        modalData.name
                          ? modalData.name
                          : studentMultiple.length > 0
                            ? modalData.name
                            : ""
                      }
                      checkbox={{
                        visible: true,
                        name: "student",
                        match: studentMultiple?.map((itt) => itt?._id),
                      }}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          name: e.target.value,
                        })
                      }
                      onOptionClose={handleOptionCLose}
                      optionListClassName="text-[16px]"
                      optionClassName="text-[16px]"
                      optionData={students}
                      // right={<img className="" src={down} />}
                      onOptionClick={(item) => {
                        handleMultipleStudent(item);
                      }}
                    />

                    <div className="flex flex-row items-center absolute">
                      <p className="font-normal max-w-[280px] max-h-[40px] overflow-y-auto text-wrap  text-[14px]  mt-3 text-[#667085]">
                      {
                        studentMultiple?.map((val,index)=>(
                          <>
                          <span className="text-secondary" id={val._id} >{val.value}</span>
                          {index < studentMultiple?.length - 1 ? ", " : ""}
                          </>
                        ))
                      }
                      </p>
                    </div>
                   
                  </div>
                  <div className="relative">
                    <InputSearch
                      placeholderClass="text-[16px]"
                      optionData={testsData}
                      value={modalData.test}
                      checkbox={{
                        visible: true,
                        checkedFunction: (option) => {
                          return testsToBeAssigned.includes(option._id)
                        }
                      }}
                      onChange={(e) =>
                        setModalData({
                          ...modalData,
                          test: e.target.value,
                        })
                      }
                      onOptionClick={(item) => {
                        setModalData({
                          ...modalData,
                          // test: item.value,
                          // testId: item._id,
                        });
                        if (testsToBeAssigned.includes(item._id)) {
                          setTestsToBeAssigned(testsToBeAssigned.filter((val) => val !== item._id))
                        } else {
                          setTestsToBeAssigned([...testsToBeAssigned, item._id])
                        }
                      }}
                      label="Assignment"
                      placeholder="Select Assignment Name"
                      parentClassName=" text-[16px] py-0 w-[280px]  "
                      inputContainerClassName="text-[16px] bg-[#F3F5F7] border-0 h-[53.33px] !pl-[13.33px] pt-[10.5px] pb-[13.33]  "
                      inputClassName="bg-[#F3F5F7] font-normal p-0  placeholder:text-[16px] placeholder:font-[400] placeholder:text-[#667085] placeholder:font-['Lexend']"
                      // parentClassName=" w-[280px] h-[53.33px]"
                      labelClassname=" !font-medium text-[#26435F] text-[18.6px] ml-2 mb-[8px] text-[#26435F] leading-[1] mt-[10px]"
                      type="select"
                    />
                     <div className="flex flex-row items-center absolute">
                     <p className="font-normal max-w-[280px] max-h-[40px] overflow-y-auto text-wrap  text-[14px]  mt-3 text-[#667085]">
                      {
                        testsData?.filter((val)=>testsToBeAssigned?.includes(val._id))?.map((item,idx)=>(
                          <span className="text-secondary">{item.value}{idx < testsData?.filter((val)=>testsToBeAssigned?.includes(val._id)).length - 1 ? ", " : ""}</span>
                        ))
                      }
                      </p>
                    </div>
                  </div>
                  <div>
                    <InputSelect
                      label="Time Limit"
                      value={modalData.limit}
                      onChange={(val) =>
                        setModalData({ ...modalData, limit: val })
                      }
                      placeholderClass="text-[16px]"
                      optionData={timeLimits}
                      parentClassName="w-[280px] h-[53.33px]"
                      labelClassname=" !font-medium text-[#26435F] text-[18.6px] mb-[8px] text-[#26435F] leading-[1]"
                      inputContainerClassName="px-5 text-[16px] py-3.5 bg-primary-50 border-0 h-[53.3px]"
                      inputClassName="text-[16px] bg-transparent font-normal !placeholder:font-normal"
                      placeholder="Select"
                      type="select"
                      hideDropdownIcon={
                        (subscriptionV2 &&
                          !subscriptionV2?.subscription.timerControl
                            .isEnabled) ||
                        oldSubscriptionExists
                      }
                      {...(subscriptionV2 &&
                        !subscriptionV2?.subscription.timerControl
                          .isEnabled && {
                        tooltipText:
                          "Timer control is not available on your plan. Upgrade to a paid plan to access this feature.",
                        disableClass: (idx) => {
                          if (idx > 0) {
                            return !subscriptionV2?.subscription.timerControl
                              .isEnabled
                              ? "text-secondary-80 "
                              : "";
                          }
                        },
                        disableFunction: (idx) => {
                          if (idx > 0) return true;
                        },
                        isTimerEnabled:
                          (subscriptionV2 &&
                            !subscriptionV2?.subscription.timerControl
                              .isEnabled) ||
                          oldSubscriptionExists,
                      })}
                    />
                  </div>
                  <div>
                    <InputField
                      label="Due Date"
                      iconSize="medium"
                      labelClassname="!font-medium text-[#26435F] text-[18.6px] ml-2 mb-[8px] text-[#26435F] leading-[1]"
                      min={getCurrentDate()}
                      value={modalData.date}
                      onChange={(val) =>
                        setModalData({
                          ...modalData,
                          date: val.target.value,
                        })
                      }
                      parentClassName="w-[280px] h-[53.33px]"
                      inputContainerClassName="w-full h-full px-5 bg-primary-50 border-0 py-3.5"
                      inputClassName="text-[16px] w-full h-full bg-transparent font-normal font-normal !placeholder:text-[#667085] text-[#667085] !placeholder:font-normal"
                      placeholderClass="text-[16px] text-[#667085] !placeholder:text-[#667085]"
                      optionData={optionData}
                      placeholder="Date"
                      type="date"
                    />
                  </div>
                </div>
                <div className="relative mx-1 mt-[15px]">
                  <p
                    className={` text-[18.667px] text-[#26435F] mb-1 font-medium ${subscriptionV2?.subscription.testSpecificInstructions
                      .isEnabled || oldSubscriptionExists
                      ? ""
                      : "text-secondary-80"
                      }`}
                  >
                    Assignment Instructions{" "}
                    <span
                      className={`text-[#667085] ${subscriptionV2?.subscription.testSpecificInstructions
                        .isEnabled || oldSubscriptionExists
                        ? ""
                        : "text-secondary-80"
                        }`}
                    >
                      (optional)
                    </span>
                  </p>

                  {subscriptionV2?.subscription.testSpecificInstructions
                    .isEnabled || oldSubscriptionExists ? (
                    <textarea
                      rows="2"
                      value={modalData.instruction}
                      onChange={(val) =>
                        setModalData({
                          ...modalData,
                          instruction: val.target.value,
                        })
                      }
                      className="leading-0 mt-[2px] block mb-[18px] resize-none focus:!ring-blue-500 !pl-[15px] !pr-[15px] pt-[12.67px] focus:!border-blue-500 placeholder:text-[#B3BDC7] text-[17.5px] placeholder:text-[17.5px] !placeholder:font-[400] placeholder:font-['Lexend'] w-full h-[150px] rows-box-shadow rounded-[5px] !font-normal"
                      placeholder={
                        subscriptionV2?.subscription.testSpecificInstructions
                          .isEnabled || oldSubscriptionExists
                          ? "Please add any custom instructions related to the test here. These will be visible to the students before they start a section during the assignment."
                          : "Please upgrade your package to add test specific instructions."
                      }
                      disabled={
                        !subscriptionV2?.subscription.testSpecificInstructions
                          .isEnabled || oldSubscriptionExists
                      }
                    ></textarea>
                  ) : (
                    <div className="w-full h-[150px] rows-box-shadow rounded-[5px] !font-normal border-1 border-[#CACEDA] flex flex-col justify-center items-center gap-[12px]">
                      <div className="h-[36px] w-[36px] bg-white rounded-full shadow-md flex justify-center items-center">
                        <img src={padlock} className="" />{" "}
                      </div>
                      <div className="text-secondary-80 text-[16px] font-[500]">
                        Upgrade to a paid plan to unlock this feature.
                      </div>
                      <button className="text-blue-70 font-[600] text-[16px]" onClick={(e)=>triggerUpdateSubscriptionPopUp()}>
                        Manage Subscription
                      </button>
                    </div>
                  )}
                </div>
                {/* <InputField
                  label="Instruction From Tutor"
                  type="text"
                  iconSize="medium"

                  value={modalData.instruction}
                  onChange={(val) =>
                    setModalData({
                      ...modalData,
                      instruction: val.target.value,
                    })
                  }
                  parentClassName="w-full mr-4"
                  labelClassname=" !font-medium text-[#26435F] ml-2 mb-0.5"
                  
                  inputContainerClassName="px-5 py-3.5 bg-primary-50 border-0 mb-5"
                  inputClassName="text-[17.5px] bg-transparent text-[17.5px]"
                  placeholderClass="text-[17.5px]"
                  optionData={optionData}
                  placeholder="Instruction"
                /> */}
              </>
            }
          />
        )}
        {resendModalActive && (
          <Modal
            title={
              <span className="leading-10 capitalize text-[21.332px]">
                Do you want to resend the assignments via email?
              </span>
            }
            crossBtn2={false}
            titleClassName="mb-[22.87px] leading-10"
            cancelBtn={true}
            closeIconClass={"!hidden"}
            cancelBtnClassName="w-[146.67px] h-[46.67px] !border-transparent"
            primaryBtn={{
              text: "Send Email",
              className:
                "w-[146.667px] h-[46.67px] flex justify-center items-center",
              onClick: () => handleResendTestSubmit(),
              loading: resendLoading,
            }}
            handleClose={() => setResendModalActive(false)}
            classname={"w-[666px] h-[194.667px] mx-auto"}
            closebuttonClass="!right-[25px] !top-[10px]"
            buttonParentClassName={"!gap-[26.67px] text-[16px]"}
            bodyScrollbarHidden={true}
            primaryBtnClass={"!m-0"}
            parentClass={"pt-[20rem]"}
          />
        )}
        {deleteModalActive && (
          <AssignmentTestModal
            title={
              <span className="leading-10 capitalize">
                Are you sure you want to delete the assigned test ?
              </span>
            }
            lineMB="!mb-[32px]"
            closebuttonClass="!right-[25px] !top-[10px]"
            titleClassName="mb-[22.87px] leading-10 text-center"
            cancelBtn={true}
            cancelBtnClassName="w-[146.667px] h-[46.67px] font-medium"
            primaryBtn={{
              text: "Delete",
              className: "w-[146.667px] h-[46.67px]",
              onClick: () => deleteTest(),
              bgDanger: true,
              loading: deleteLoading,
            }}
            handleClose={() => setDeleteModalActive(false)}
            classname={"w-[666.667px] h-[194.667px] mx-auto"}
            modalSize={"w-[666.667px] h-[194.667px]"}
          />
        )}
        {deleteBulkModalActive && (
          <AssignmentTestModal
            title={
              <div className="w-full leading-10 capitalize text-center  ">
                Are you sure you want to Delete the Assignments?
              </div>
            }
            lineMB="!mb-[32px]"
            closebuttonClass="!right-[25px] !top-[10px]"
            titleClassName="mb-[16px] leading-10 text-center"
            cancelBtn={true}
            crossBtn={true}
            crossBtn2={true}
            closeIconClass={"!hidden"}
            cancelBtnClassName="w-[146.667px] h-[46.67px] !bg-[#26435F1A] px-3 !text-[#26435F] !rounded-md !border-transparent"
            primaryBtn={{
              text: "Delete",
              className: "w-[146.667px] h-[46.67px] !bg-[#FF7979] text-white",
              onClick: () => bulkSelectDelete(),
              bgDanger: true,
              loading: deleteSelectLoading,
            }}
            handleClose={() => setDeleteBulkModalActive(false)}
            className={"w-[666px] h-[194.667px] mx-auto"}
            modalSize={"w-[666px] h-[194.667px]"}
            buttonParentClassName={"!gap-[26.67px] text-[16px]"}
            bodyScrollbarHidden={true}
            primaryBtnClass={"!m-0"}
            parentClass="pt-[20rem]"
          />
        )}

        {markBulkModalActive && (
          <AssignmentTestModal
            title={
              <span className="leading-10 whitespace-nowrap capitalize">
                Do you want to mark the Assignments As Completed?
              </span>
            }
            titleClassName="mb-5 leading-10 text-center"
            cancelBtn={true}
            crossBtn={true}
            crossBtn2={true}
            closeIconClass={"!hidden"}
            cancelBtnClassName="w-[146.667px] h-[46.67px] !bg-[#26435F1A] px-4  !text-[#26435F] rounded-md !border-transparent"
            primaryBtn={{
              text: "Confirm",
              className: "w-[146.667px] h-[46.667px] !bg-[#32D583] text-white ",
              onClick: () => markSelectDelete(),
              bgDanger: true,
              loading: markSelectLoading,
            }}
            closebuttonClass="!right-[25px] !top-[10px] "
            body={
              <>
                <p className="text-[16px] mt-[-5px] text-[#667085] mb-6 !font-normal tracking-[-0.2px] text-left">
                  <span className="inline-block bottom-[3.5px] relative">
                    ⚠️
                  </span>
                  <span className="!font-bold mr-1">
                    <div className="!scale-[0.8] mr-[-4px] inline-block"></div>{" "}
                    Note:
                  </span>
                  Once the assignments are marked as “Complete”, the students
                  will not be able to attempt any remaining sections and will be
                  able to access the score report. Read detailed documentation
                  in Evallo’s{" "}
                  <a
                    className="text-[#24A3D9] !font-medium border-b border-b-[#24A3D9] cursor-pointer"
                    href={process.env.REACT_APP_SUPPORT_LINK}
                    target="_blank"
                  >
                    {" "}
                    knowledge base.
                  </a>
                </p>
              </>
            }
            handleClose={() => setMarkBulkModalActive(false)}
            classname={"w-[667px] mx-auto h-[280px]"}
            modalSize={"w-[667px] h-[280px]"}
            buttonParentClassName={"!gap-[26.67px] text-[16px]"}
            bodyScrollbarHidden={true}
            primaryBtnClass={"!m-0"}
            parentClass={"pt-[17rem]"}
          />
        )}
        {resendBulkModalActive && (
          <AssignmentTestModal
            title={
              <span className="leading-10 whitespace-nowrap capitalize !font-semibold text-center">
                Do you want to resend the assignments via email?
              </span>
            }
            titleClassName="mb-4 leading-10 text-center"
            cancelBtn={true}
            crossBtn={true}
            crossBtn2={true}
            closeIconClass={"!hidden"}
            cancelBtnClassName=" !bg-[#26435F1A]  !text-[#26435F] rounded-md w-[146.667px] h-[46.667px] !border-transparent"
            closebuttonClass="!right-[25px] !top-[10px]"
            primaryBtn={{
              text: "Resend",
              className:
                "w-[146.667px] h-[46.67px]  pl-4 px-4 !bg-blue-70 text-white",
              onClick: () => resendSelectDelete(),
              bgDanger: true,
              loading: resendSelectLoading,
            }}
            body={
              <>
                <p className="text-[16px] mt-[-5px] text-[#667085] mb-6 !font-normal tracking-[-0.2px] text-left">
                  <span className="inline-block bottom-[3.5px] relative">
                    ⚠️
                  </span>
                  <span className="!font-bold mr-1">Note:</span>
                  This will NOT create another assignment for the students.
                  Instead, it will resend the email with the PDF file
                  (containing the assignment content) attached to it. Read
                  detailed documentation in Evallo’s{" "}
                  <a
                    className="text-[#24A3D9] cursor-pointer  !font-medium border-b border-b-[#24A3D9]"
                    href={process.env.REACT_APP_SUPPORT_LINK}
                    target="_blank"
                  >
                    {" "}
                    knowledge base.
                  </a>
                </p>
              </>
            }
            handleClose={() => setResendBulkModalActive(false)}
            classname={"w-[667px]  mx-auto h-[280px]"}
            modalSize={"w-[667px] h-[280px]"}
            buttonParentClassName={"!gap-[26.67px] text-[16px]"}
            bodyScrollbarHidden={true}
            primaryBtnClass={"!m-0"}
            parentClass={"pt-[17rem]"}
          />
        )}
      </div>
    </>
  );
}
