import React from 'react'

const TutorDropDownList = ({id, email,firstName, lastName, profile, bgColor, selectedtutor, setSelectedtutor, setIsDropdownOpen }) => {

  return (
    <main
    id={id}
    className= {` w-full gap-[6px] justify-start flex items-center p-[4px] rounded-[4px]   ${selectedtutor === id ? "bg-[#f1f6fb]": "hover:bg-[#F7F8F9] bg-white" }  cursor-pointer `}
    onClick={()=> {setSelectedtutor(id); setIsDropdownOpen(false)}}
  >

    {profile ? (
      <img
        src={profile}
        alt="profile"
        className="w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full"
      />
    ) : (
      <div
        className="text-[12px] leading-[12px] text-white w-[20px] md:w-[24px] h-[20px] md:h-[24px] rounded-full flex items-center justify-center"
        style={{ backgroundColor: bgColor }}
      >
        {firstName.charAt(0).toUpperCase()}
      </div>
    )}

    <section className="flex flex-col gap-[3px] flex-1">
      <h4 className="text-[10px] md:text-[12px] leading-[10px] md:leading-[12px] font-medium text-secondary-80">
        {firstName} {lastName}
      </h4>
      <p className="text-[8px] md:text-[10px] leading-[8px] md:leading-[10px] font-normal text-secondary-60 break-all overflow-hidden w-full max-w-full">
        {email}
      </p>
    </section>
  </main>
  )
}

export default TutorDropDownList