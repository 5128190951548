import React, { useEffect, useRef, useState } from 'react'
import styles from './timeZoneSelector.module.css';
import moment from "moment-timezone";
import { EvChevronDownIcon } from '../../assets/icons/ChevronIcons';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';

export const timeZones = moment.tz.names();
const TimeZoneSelector = ({ setTimeZone, timeZone, setActive, origin }) => {
    const [isTimeZonePickerOpen, setIsTimeZonePickerOpen] = useState(false);
    const [selectedTimeZone, setSelectedTimeZone] = useState(
        "(GMT + 1:30) Eastern Time - US & Canada"
    );
    const [searchTerm, setSearchTerm] = useState("");
    const [isRepeatsOpen, setIsRepeatsOpen] = useState(false);
    const timezoneRef = useRef();

    
    useOutsideAlerter(timezoneRef, () => setIsTimeZonePickerOpen(false));

    const filteredTimeZones = timeZones.filter((zone) =>
        zone.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const handleTimeZoneChange = (timeZone) => {
        setTimeZone(timeZone);
        setIsTimeZonePickerOpen(false);
    };

    // const { values, setValues } = props
    // useEffect(() => {
    //     // console.log(selectedTimeZone)
    //     // console.log(props)
    //     setValues((prev) => {
    //         const temp = { ...prev }
    //         temp["timezone"] = selectedTimeZone
    //         prev = temp
    //         return { ...prev }
    //     })
    //     console.log(values)
    // }, [selectedTimeZone])

    useEffect(() => {
        if (timeZone && timeZone.trim() != "") setActive(true);
        else setActive(false);
    }, [timeZone])


    return (
        <div className='relative' ref={timezoneRef}>
            <div
                className={styles.timeZone}
                onClick={() => {
                    console.log("isTimeZonePickerOpen", isTimeZonePickerOpen);
                    setIsTimeZonePickerOpen(!isTimeZonePickerOpen);
                    console.log("isTimeZonePickerOpen", isTimeZonePickerOpen);
                }}
            >   
                <p className={`${styles.GMTEastern} text-secondary`}>{timeZone? timeZone : "Select Timezone"}</p>
                <img
                    className={styles.inputFieldIcon2}
                    src={timeZone !== "" ? "/images/down-arrow-dark.svg" : "/images/down-arrow.svg"}
                    alt="input field icon"
                // onClick={() => setIsTimeZonePickerOpen(!isTimeZonePickerOpen)}
                />
            </div>
            {isTimeZonePickerOpen && (
                <div
                
                    className={
                        isRepeatsOpen
                            ? styles.timeZoneDropdown
                            : styles.timeZoneDropdownV2
                    }
                    style={{width: origin && origin== "setting" && "100%", top:origin && origin =="setting" && "110%" }}
                >
                    <div className="search-box-container">
                        <img
                            style={{ marginLeft: 5 }}
                            src="/images/search.svg"
                            alt="search"
                        />
                        <input
                            type="text"
                            className="search-box"
                            placeholder="Search by place"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {filteredTimeZones.map((timeZone) => (
                        <div
                            key={timeZone}
                            className={styles.timeZoneOption}
                            onClick={() => handleTimeZoneChange(timeZone)}
                        >
                            {timeZone}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default TimeZoneSelector
