import React, { useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useCancelSubscriptionV2Mutation } from "../../../../app/services/subscription";
import { triggerToast } from "../../../../utils/toastController";
import PopupContainer from "../../../../components/PopupContainer/PopupContainer";
import LoaderIcon from "../../../../components/LoaderIcon/LoaderIcon";
import { fetchAndSetSubscription } from "../../../../utils/subscriptionService";

const AddLogoPopUp = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);

  const [cancelSubV2Req] = useCancelSubscriptionV2Mutation();

  async function handleConfirmClick(e) {
    try {
      setLoading(true);
      const res = await cancelSubV2Req({ data: [{ op: "cancel" }] });
      if (!res.error) {
        fetchAndSetSubscription();
        setLoading(false);
        triggerToast(
          "Subscription cancelled successfully! You have access till the current billing cycle, after which you will not be charged.",
          "success",
          { autoClose: 3000 }
        );
        setOpen(false);
      } else {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PopupContainer
      trigger={open}
      setTrigger={setOpen}
      customCloseButton={true}
      customInnerContainer="w-[98%] md:w-[640px] grid gap-[36px] p-[24px]"
    >
      <div className="w-[100%] flex items-center justify-between pb-[10px] border-b-[1px] border-blue-50 text-blue-90 text-[24px] leading-[36px] font-[500]">
        <div>Cancel Subscription</div>
        <button onClick={(e) => setOpen(false)}>
          <CloseIcon />
        </button>
      </div>
      <div className="grid gap-[12px]">
        <div className="text-[20px] text-secondary-80 font-[500] text-center">
          Are you sure you want to cancel your existing subscription?
        </div>
        <div className="text-secondary-60 text-[16px] font-[400] text-center">
          Note: If you cancel your subscription, your next payment will be
          auto-cancelled. You can continue to enjoy the benefits until the end
          of this billing cycle.
        </div>
      </div>
      <div className="flex gap-[12px] justify-center">
        <div
          className="text-secondary-60 basis-1/2 md:basis-3/12 text-center border-[2px] border-secondary-60 px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px] cursor-pointer justify-center"
          onClick={(e) => setOpen(false)}
        >
          Cancel
        </div>
        <div
          className={`${"bg-red-400 text-white cursor-pointer"} basis-1/2 md:basis-3/12  text-center  px-[24px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
          onClick={(e) => handleConfirmClick(e)}
        >
          {loading ? <LoaderIcon /> : "Yes Confirm"}
        </div>
      </div>
    </PopupContainer>
  );
};

export default AddLogoPopUp;
