const CautionIcon = (props) => {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.96602 0.597131L0.160118 10.6525C0.0552427 10.8341 1.94102e-05 11.0401 5.11586e-09 11.2498C-1.93999e-05 11.4595 0.0551658 11.6655 0.160007 11.8471C0.264849 12.0287 0.415652 12.1795 0.597256 12.2844C0.77886 12.3892 0.984865 12.4444 1.19456 12.4444H12.8054C13.0151 12.4444 13.2211 12.3892 13.4027 12.2844C13.5843 12.1795 13.7352 12.0287 13.84 11.8471C13.9448 11.6655 14 11.4595 14 11.2498C14 11.0401 13.9448 10.8341 13.8399 10.6525L8.0346 0.597131C7.92977 0.415579 7.77901 0.264818 7.59745 0.16C7.4159 0.0551821 7.20995 0 7.00031 0C6.79067 0 6.58472 0.0551821 6.40317 0.16C6.22161 0.264818 6.07085 0.415579 5.96602 0.597131Z" fill={props.fillColor ?? "currentColor"} />


            <path d="M7.07659 3.69824H6.92514C6.55227 3.69824 6.25 4.00051 6.25 4.37338V7.60042C6.25 7.97329 6.55227 8.27556 6.92514 8.27556H7.07659C7.44946 8.27556 7.75173 7.97329 7.75173 7.60042V4.37338C7.75173 4.00051 7.44946 3.69824 7.07659 3.69824Z" fill={props.exclamationColour ?? "white"} />
            <path d="M7.00086 10.8347C7.41555 10.8347 7.75173 10.4986 7.75173 10.0839C7.75173 9.66918 7.41555 9.33301 7.00086 9.33301C6.58617 9.33301 6.25 9.66918 6.25 10.0839C6.25 10.4986 6.58617 10.8347 7.00086 10.8347Z" fill={props.exclamationColour ?? "white"} />

        </svg>

    )
}



export default CautionIcon