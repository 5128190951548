import * as XLSX from 'xlsx/xlsx.mjs';

export default function generateXLFromTimeOffData(data){
	
	const worksheet = XLSX.utils.json_to_sheet(data)
	const workbook = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
	XLSX.writeFile(workbook, `${(Date.now())}.xlsx`)

}
