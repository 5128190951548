import React, { useEffect, useMemo, useRef, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import styles from "./CalendarDateTimeSelector.module.css";
import "../../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
// import "../CalendarFilterPopup/CalendarFilterPopup.styleguide.css";
import moment from "moment-timezone";
import Weekly from "../../CalendarRecurringComponent/Weekly/Weekly";
import Month from "../../CalendarRecurringComponent/Month/Month";
import DatePickerEndsOn from "../../CalendarRecurringComponent/Date/DatePicker";
import frozenEventTypes from "../../../utils/eventTypes";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

const eventTypesToShowAdditionalControls = [frozenEventTypes.SESSION.eventType];
const eventTypesToHideRecurring = [frozenEventTypes.OFFCWORK.eventType, frozenEventTypes.EXTMEETING.eventType, frozenEventTypes.EXTMEETING.eventType.toLowerCase()];

export const generateTimeOptions = () => {
  const times = [];
  let currentTime = 0;

  while (currentTime < 24 * 60) {
    const hours = Math.floor(currentTime / 60);
    const minutes = currentTime % 60;
    const ampm = hours >= 12 ? "PM" : "AM";
    const displayHours = (hours % 12 || 12).toString().padStart(2, "0");
    const displayMinutes =
      minutes === 0 ? "00" : minutes.toString().padStart(2, "0");
    const timeString = `${displayHours}:${displayMinutes} ${ampm}`;

    times.push(timeString);
    currentTime += 15;
  }

  return times;
};
export const timeZones = moment.tz.names();

export const timeOptions = generateTimeOptions();

export const frequencyOptions = [
  "Daily",
  "Weekly",
  "Bi-weekly",
  "Monthly",
];

export const generateDays = (selectedDays) => {
  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
  return daysOfWeek.map((day, index) => (
    <div
      key={day}
      className={`${styles.repeatDivWrapper} ${selectedDays.includes(day) ? styles.selected : ""
        }`}
    >
      <div className={styles.repeatTextWrapper5}>{day}</div>
    </div>
  ));
};

export const generateDates = () => {
  const dates = [];
  for (let i = 1; i <= 31; i++) {
    dates.push(
      <div key={i} className={styles.repeatDivWrapper}>
        <div className={styles.repeatTextWrapper5}>{i}</div>
      </div>
    );
  }
  return dates;
};

const CalendarDateTimeSelector = ({
  eventType,
  selectedDay,
  setSelectedDay,
  frequency,
  setFrequency,
  selectedDays,
  setSelectedDays,
  selectedDate,
  setSelectedDate,
  selectedMonthlyRecurringDate,
  setSelectedMonthlyRecurringDate,
  endDate,
  setEndDate,
  selectedStartTime,
  setSelectedStartTime,
  selectedEndTime,
  setSelectedEndTime,
  isRecurring,
  setIsRecurring,
  isRescheduled,
  setIsRescheduled,
  isPartialSession,
  setIsPartialSession,
  selectedTimeZone,
  setSelectedTimeZone,
  user,
  origin,
  operationMode,
  requiredEventData,
  selectedEventType
}) => {
  const [currentMiniCalendarView, setCurrentMiniCalendarView] = useState("day");
  const [searchTerm, setSearchTerm] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isEndsOnDatePickerOpen, setIsEndsOnDatePickerOpen] = useState(false);
  const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
  const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);
  const [isTimeZonePickerOpen, setIsTimeZonePickerOpen] = useState(false);
  const [isRepeatsOpen, setIsRepeatsOpen] = useState(false);
  const timeZoneRef = useRef(null);
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);

  const startTimeRef = useRef();
  const lastTimeRef = useRef();
  const calendarRef = useRef();
  const timezoneRef = useRef();
  const repeatsRef = useRef();
  const endsOnRef = useRef();
  const endShowRef = useRef();

  useOutsideAlerter(startTimeRef, () => setIsStartTimePickerOpen(false));
  useOutsideAlerter(lastTimeRef, () => setIsEndTimePickerOpen(false));
  useOutsideAlerter(timezoneRef, () => setIsTimeZonePickerOpen(false));
  useOutsideAlerter(calendarRef, () => setIsDatePickerOpen(false));
  useOutsideAlerter(repeatsRef, () => setIsRepeatsOpen(false));
  useOutsideAlerter(endsOnRef, () => setIsEndsOnDatePickerOpen(false));

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsStartTimePickerOpen(false);
    }
  };
  const handleClickOutside2 = (event) => {
    if (modalRef2.current && !modalRef2.current.contains(event.target)) {
      setIsEndTimePickerOpen(false);
    }
  };

  const caledarRefOutside = (event) => {
    console.log(event.target);
  };
  // const endsOnRefOutside = (event) => {
  //   console.log(endsOnRef, event)
  //   if (endsOnRef.current &&  !endsOnRef.current.contains(event.target)  ) {
  //     setIsEndTimePickerOpen(false);
  //   }
  // };

  const handleDayClick = (day, item) => {
    const shortDay = item.shortDay;
    setSelectedDay((prev) =>
      prev.includes(shortDay)
        ? prev.filter((val) => val !== shortDay)
        : [...prev, shortDay]
    );
  };

  useEffect(() => {
    console.log(selectedDay);
  }, [selectedDay]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (currentMiniCalendarView === "day") setIsDatePickerOpen(false);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setIsEndsOnDatePickerOpen(false);
  };


  const filterTimeOptions = (options) => {
    if (selectedStartTime) {
      const startTimeIndex = options.indexOf(selectedStartTime);
      return options.slice(startTimeIndex + 1);
    }
    return options;
  };
  const submitTime = (event, time, type) => {
    if (type === "start") {
      // Check if endTime needs to be cleared
      if (selectedEndTime && selectedEndTime.trim() != "" && isTimeEarlier(time, selectedEndTime)) {
        setSelectedStartTime((prev) => time);
        const startTimeIndex = timeOptions.indexOf(time);
        const newEndTime = timeOptions[startTimeIndex + 4] ? timeOptions[startTimeIndex + 4] : timeOptions[0];
        setSelectedEndTime(newEndTime)
        setIsStartTimePickerOpen(false);
      } else {
        setSelectedStartTime(time);
        setIsStartTimePickerOpen(false);
      }
    } else {
      setSelectedEndTime(time);
      setIsEndTimePickerOpen(false);
    }
  };

  const isTimeEarlier = (time1, time2) => {
    const [hours1, minutes1, period1] = parseTime(time1);
    const [hours2, minutes2, period2] = parseTime(time2);
    console.log(hours1, minutes1, period1, hours2, minutes2, period2);

    // Convert to 24-hour format
    const time1InMinutes = (hours1 % 12 + (period1 === "PM" ? 12 : 0)) * 60 + minutes1;
    const time2InMinutes = (hours2 % 12 + (period2 === "PM" ? 12 : 0)) * 60 + minutes2;

    console.log(time1InMinutes, time2InMinutes)

    return time1InMinutes >= time2InMinutes;
  };

  const parseTime = (time) => {
    const [timePart, period] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);
    return [hours, minutes, period];
  };


  const handleCheckboxChange = (event, checkboxType) => {
    if (checkboxType === "recurring") {
      setIsRecurring(event.target.checked);
      setIsRescheduled(false);
      setIsPartialSession(false);
    } else if (checkboxType === "rescheduled") {
      setIsRescheduled(event.target.checked);
      setIsPartialSession(false);
      setIsRecurring(false);
    } else if (checkboxType === "partialSession") {
      setIsPartialSession(event.target.checked);
      setIsRecurring(false);
      setIsRescheduled(false);
    }
  };
  const filteredTimeZones = timeZones.filter((zone) =>
    zone.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTimeZoneChange = (timeZone) => {
    setSelectedTimeZone(timeZone);
    setIsTimeZonePickerOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", caledarRefOutside);
    return () => {
      document.removeEventListener("mousedown", caledarRefOutside);
    };
  }, []);

  console.log("REEDV", requiredEventData)
  //To Enable or disable editing based on the role
  const isEnabledEditing = useMemo(() => {
		const nonSessionTypes = [frozenEventTypes.INTMEETING.eventType.toLowerCase(), frozenEventTypes.OFFCWORK.eventType.toLowerCase(), frozenEventTypes.OFFCHOURS.eventType.toLowerCase()]
		if(((operationMode === "edit") && requiredEventData && nonSessionTypes.includes(selectedEventType?.toLowerCase()))){
			if(requiredEventData.isEditingRequest){
				return requiredEventData.eventRequestor._id === user?.id
			}
			return requiredEventData.initiatorDetails.id === user?.id
		}
		return (
			["admin", "tutor"].includes(user?.role) ||
				(origin && origin === "schedule")
		);
  }, [user?.role]);

  useEffect(() => {
    if (isEnabledEditing === true && operationMode && ["create"].includes(operationMode) && user?.userDefaults?.timeZone !== "") setSelectedTimeZone(user?.userDefaults?.timeZone)

  }, [user, isEnabledEditing])



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={styles.dateAndTime}
        style={{ pointerEvents: isEnabledEditing ? "" : "none" }}
      >
        <div className={styles.div}>
          <div className={styles.frame2}>
            <div ref={calendarRef}>
              <div
                className={styles.inputField}
                onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
              >
                <div className={styles.selectTutor}>
                  {selectedDate?.format("MMM D, YYYY")}
                </div>
                <img
                  className={styles.inputFieldIcon}
                  src="/images/calendar.svg"
                  alt="input field icon"
                  style={{ display: isEnabledEditing ? "" : "none" }}
                />
              </div>
              {isDatePickerOpen && (
                <div className={styles.datePickerContainer}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={handleDateChange}
                    onClose={() => setIsDatePickerOpen(false)}
                    //   {...props}
                    showDaysOutsideCurrentMonth={true}
                    disableHighlightToday={true}
                    views={["month", "day"]}
                    onViewChange={(view) => {
                      setCurrentMiniCalendarView(view);
                    }}
                    sx={{
                      bgcolor: "white",
                      height: "199px",
                      width: "190px",
                      borderRadius: 6,
                      fontFamily: "Lexend",
                      padding: "0px",
                      "& .MuiDayCalendar-weekDayLabel": {
                        margin: "0px",
                        fontFamily: "Lexend",
                        height: "15px",
                        padding: "0px",
                        color: "#7C859C",
                        fontWeight: 400,
                        fontSize: "10px",
                      },
                      "& .MuiDayCalendar-header": {
                        paddingBottom: "10px",
                      },
                      "& .MuiMonthCalendar-root": {
                        width: "190px",
                        height: "151px",
                        padding: "0px",
                      },
                    }}
                    //   slots={{
                    //     calendarHeader: CustomHeader,
                    //   }}
                    slotProps={{
                      monthButton: {
                        sx: {
                          fontWeight: 400,
                          fontFamily: "Lexend",
                          fontSize: "14px",
                          color: "rgba(81, 92, 123, 1)",
                          margin: "0px",
                          height: "32px",
                          width: "58px",
                          "&.Mui-selected": {
                            fontWeight: 600,
                            bgcolor: "white",
                            color: "#0671E0",
                            textDecoration: "underline",
                            textUnderlineOffset: "5px",
                            lineHeight: "21px",
                            "&:hover": {
                              fontWeight: 600,
                              bgcolor: "white",
                              color: "#0671E0",
                              textDecoration: "underline",
                              lineHeight: "21px",
                            },
                            "&:focus": {
                              fontWeight: 600,
                              bgcolor: "white",
                              color: "#0671E0",
                              textDecoration: "underline",
                              lineHeight: "21px",
                            },
                          },
                        },
                      },
                      calendarHeader: {
                        //   ...props,
                        sx: {
                          padding: "0px 0px 0px 6px",
                          margin: "0px",
                          minHeight: "fit-content",
                          "& .MuiPickersCalendarHeader-label": {
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "#25335A",
                            margin: "0px",
                            fontFamily: "Lexend",
                          },
                          "& .MuiSvgIcon-root": {
                            height: "1rem",
                            width: "1rem",
                            color: "#515C7B",
                          },
                          "& .MuiPickersCalendarHeader-switchViewButton": {
                            padding: "0px",
                            color: "#25335A",
                          },
                          "& .MuiPickersCalendarHeader-switchViewIcon": {
                            color: "#25335A",
                          },
                        },
                      },
                      day: {
                        disableHighlightToday: false,
                        today: true,
                        sx: {
                          height: "22px",
                          width: "22px",
                          padding: "6px 2px",
                          fontSize: "10px",
                          fontFamily: "Lexend",
                          fontWeight: 400,
                          "&:hover": {
                            bgcolor: "rgba(233, 235, 239, 1)",
                          },
                          "&:focus": {
                            bgcolor: "rgba(6, 113, 224, 0.1)",
                            color: "rgba(6, 113, 224, 1)",
                          },
                          "&.MuiPickersDay-today": {
                            bgcolor: "#0671E0",
                            border: "none",
                            color: "white",
                          },
                          "&.Mui-selected": {
                            bgcolor: "rgba(6, 113, 224, 0.1)",
                            color: "rgba(6, 113, 224, 1)",
                            "&:focus": {
                              bgcolor: "rgba(6, 113, 224, 0.1)",
                              color: "rgba(6, 113, 224, 1)",
                            },
                            "&:hover": {
                              bgcolor: "rgba(6, 113, 224, 0.1)",
                              color: "rgba(6, 113, 224, 1)",
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>

            <div className={styles.textWrapper}>from</div>
            <div className={styles.time}>
              <div
                ref={startTimeRef}
                className={styles.inputFieldV}
                onClick={() => setIsStartTimePickerOpen(!isStartTimePickerOpen)}
              >
                <div className={styles.selectTutor2}>{selectedStartTime}</div>
                {
                  selectedStartTime ?
                    <img
                      className={styles.inputFieldIcon2}
                      src="/images/down-arrow-dark.svg"
                      alt="input field icon"
                      style={{ display: isEnabledEditing ? "" : "none" }}
                    />
                    :
                    <img
                      className={styles.inputFieldIcon2}
                      src="/images/down-arrow.svg"
                      alt="input field icon"
                      style={{ display: isEnabledEditing ? "" : "none" }}
                    />
                }

                {isStartTimePickerOpen && (
                  <div
                    className={`${styles.searchDropdown} ${styles.startDropdown}`}
                  >
                    {timeOptions.map((time) => (
                      <div
                        key={time}
                        className={styles.dropdownOption}
                        onClick={(event) =>
                          submitTime(event, time, "start")
                        }
                      >
                        <div className={styles.elementSessionsMo}>{time}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.textWrapper}>-</div>
              <div
                ref={lastTimeRef}
                // ref={modalRef2}
                className={styles.inputFieldV}
                onClick={() => setIsEndTimePickerOpen(!isEndTimePickerOpen)}
              >
                <div className={styles.selectTutor2}>{selectedEndTime}</div>
                {
                  selectedEndTime ?
                    <img
                      className={styles.inputFieldIcon2}
                      src="/images/down-arrow-dark.svg"
                      alt="input field icon"
                      style={{ display: isEnabledEditing ? "" : "none" }}
                    />
                    :
                    <img
                      className={styles.inputFieldIcon2}
                      src="/images/down-arrow.svg"
                      alt="input field icon"
                      style={{ display: isEnabledEditing ? "" : "none" }}
                    />
                }

                {isEndTimePickerOpen && (
                  <div
                    className={`${styles.searchDropdown} ${styles.endDropdown}`}
                  >
                    {filterTimeOptions(timeOptions).map((time) => (
                      <div
                        key={time}
                        className={styles.dropdownOption}
                        onClick={(event) =>
                          submitTime(event, time, "end")
                        }
                      >
                        <div className={styles.elementSessionsMo}>{time}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.timeZone} ref={timezoneRef}>
            <div
              className=" w-full flex items-center justify-between !py-[4px]  "
              onClick={() => {
                setIsTimeZonePickerOpen(!isTimeZonePickerOpen);
              }}
            >
              <p className={styles.GMTEastern}>{selectedTimeZone}</p>
              {
                selectedTimeZone ?
                  <img
                    className={styles.inputFieldIcon2}
                    src="/images/down-arrow-dark.svg"
                    alt="input field icon"
                    style={{ display: isEnabledEditing ? "" : "none" }}
                  />
                  :
                  <img
                    className={styles.inputFieldIcon2}
                    src="/images/down-arrow.svg"
                    alt="input field icon"
                    style={{ display: isEnabledEditing ? "" : "none" }}
                  />
              }
            </div>

            {isTimeZonePickerOpen && (
              <div
                className={
                  isRepeatsOpen
                    ? styles.timeZoneDropdown
                    : styles.timeZoneDropdownV2
                }
              >
                <div className="search-box-container  ">
                  <img
                    style={{ marginLeft: 5 }}
                    src="/images/search.svg"
                    alt="search"
                  />
                  <input
                    type="text"
                    className="search-box"
                    placeholder="Search by place"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                {filteredTimeZones.map((timeZone) => (
                  <div
                    key={timeZone}
                    className={styles.timeZoneOption}
                    onClick={() => handleTimeZoneChange(timeZone)}
                  >
                    {timeZone}
                  </div>
                ))}
              </div>
            )}
          </div>

          {user && user?.role != "student" && user?.role != "parent" && (
            <div className={`${styles.frame3} `}>
              {!eventTypesToHideRecurring.includes(eventType) && (
                <div className={styles.recurring}>
                  <div className={styles.checkboxFill2}>
                    <input
                      className={styles.checkbox}
                      type="checkbox"
                      checked={isRecurring}
                      onChange={(event) =>
                        handleCheckboxChange(event, "recurring")
                      }
                    />
                    <div className={styles.textWrapper}>Recurring</div>
                  </div>
                </div>
              )}

              <div className={styles.frame4}>
                {eventTypesToShowAdditionalControls.includes(eventType) && (
                  <div className={styles.checkboxFill2}>
                    <input
                      className={styles.checkbox}
                      type="checkbox"
                      checked={isRescheduled}
                      onChange={(event) =>
                        handleCheckboxChange(event, "rescheduled")
                      }
                    />
                    <div className={styles.textWrapper}>Rescheduled</div>
                  </div>
                )}
                {eventTypesToShowAdditionalControls.includes(eventType) && (
                  <div className={styles.checkboxFill2}>
                    <input
                      className={styles.checkbox}
                      type="checkbox"
                      checked={isPartialSession}
                      onChange={(event) =>
                        handleCheckboxChange(event, "partialSession")
                      }
                    />
                    <div className={styles.textWrapper}>Partial Session</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {isRecurring && (
            <div
              className={styles.repeatframe}
              style={{ height: frequency == "Daily" ? "85px" : "165px" }}
            >
              <div
                className={
                  frequency == "Daily"
                    ? styles.repeatDivDaily
                    : styles.repeatDiv
                }
              >
                <div className={styles.repeatDiv2}>
                  <div className={styles.repeatTextWrapper}>Repeats</div>

                  <div ref={repeatsRef}>
                    <div
                      className={styles.repeatDisabledUser}
                      onClick={() => setIsRepeatsOpen(!isRepeatsOpen)}
                    >
                      <div className={styles.repeatTextWrapper2}>
                        {frequency}
                      </div>
                      <img
                        className={styles.repeatInputFieldIcon}
                        src="/images/down-arrow.svg"
                        alt="Dropdown Icon"
                      />
                    </div>
                    {isRepeatsOpen && (
                      <div
                        className={`${styles.repeatDropdown} ${styles.repeatStartDropdown}`}
                      >
                        {frequencyOptions.map((time) => (
                          <div
                            key={time}
                            className={styles.dropdownOption}
                            onClick={() => {
                              setFrequency(time);
                              setIsRepeatsOpen(!isRepeatsOpen);
                            }}
                          >
                            <div className={styles.elementSessionsMo}>
                              {time}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={styles.repeatInputFieldHeader}
                  style={{ marginTop: frequency == "Daily" ? "3px" : "7px" }}
                >
                  <div className={styles.repeatDiv5}>
                    <div className={styles.repeatTextWrapper8}>Ends on*</div>
                  </div>
                  <div
                    ref={endShowRef}
                    className={styles.repeatInputField}
                    onClick={() =>
                      setIsEndsOnDatePickerOpen(!isEndsOnDatePickerOpen)
                    }
                  >
                    <div className={styles.repeatTextWrapper9}>
                      {" "}
                      {endDate ? endDate?.format("MMM D, YYYY") : "End date"}
                    </div>
                    <img
                      className={styles.repeatImg}
                      src="/images/calendar.svg"
                      alt="Input Field Icon"
                    />
                  </div>
                </div>
              </div>

              {(frequency == "Weekly" || frequency === "Bi-weekly") && (
                <Weekly
                  selectedDay={selectedDay}
                  handleDayClick={handleDayClick}
                />
              )}
              {frequency == "Monthly" && (
                <Month
                  {...{
                    selectedMonthlyRecurringDate,
                    setSelectedMonthlyRecurringDate,
                  }}
                />
              )}
              {isEndsOnDatePickerOpen && (
                <div>
                  <DatePickerEndsOn
                    isEndsOnDatePickerOpen={isEndsOnDatePickerOpen}
                    setIsEndsOnDatePickerOpen={setIsEndsOnDatePickerOpen}
                    endDate={endDate}
                    setSelectedDate={setSelectedDate}
                    handleEndDateChange={handleEndDateChange}
                    frequency={frequency}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default CalendarDateTimeSelector;
