import { Route, Routes } from "react-router-dom";
import Availability from "../../../pages/CalendarV2/Availability/Availability";
import CalendarAnalytics from "../../CalendarAnalytics/CalendarAnalytics";
import CalendarV2Home from "../../CalendarV2Home/CalendarV2Home";
import EventRequests from "../../EventRequests/EventRequests";
import LeavesVacation from "../../LeavesVacation/LeavesVacation";

function CalRenderer(props) {
	
	const {activeLink, elLinks, pathRenderingMapping}	= props

	console.log("CLR",props)
	
		
	
	if(Object.keys(pathRenderingMapping).includes(activeLink)) {
		return (
			<Routes>
				<Route index element={<CalendarV2Home {...props}/>}/>
				{
					Object.entries(pathRenderingMapping).map(([path,Component],idx)=>(
						<Route path={path} element={<Component {...props}/>}/>
					))
				}
			</Routes>
		)
	}

	switch(activeLink) {
		case elLinks.CAL : 
			return <CalendarV2Home {...props}/>;
		case elLinks.AVLB : 
			return <Availability {...props}/>;
		case elLinks.SRCH : 
			return <>Search</>;
		case elLinks.EVREQ : 
			return <EventRequests {...props}/>;
		case elLinks.LVVAC : 
			return <LeavesVacation {...props}/>;
		case elLinks.TMBLK : 
			return <>Time blocks</>;
		case elLinks.CALTICS : 
			return <CalendarAnalytics {...props}/>;
		case elLinks.FILTERS : 
			return <>Filters</>;
		default :
			return <></>
	}

}

export default CalRenderer;
