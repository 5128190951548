import React from 'react'

const TagIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_29123_7607)">
                <path d="M6.93752 15.9922C6.93715 15.9922 6.93669 15.9922 6.93623 15.9922C6.46684 15.9918 6.02547 15.8087 5.69368 15.4764L1.02046 10.7965C0.336929 10.1117 0.336929 8.99738 1.02046 8.31265L7.32002 2.00101C7.96922 1.35052 8.83302 0.992188 9.75221 0.992188H13.7489C14.7181 0.992188 15.5068 1.78064 15.5068 2.75V6.73447C15.5068 7.65302 15.1488 8.51645 14.4989 9.16556L8.17979 15.4781C7.84782 15.8096 7.40663 15.9922 6.93752 15.9922ZM9.75221 2.16406C9.1465 2.16406 8.57732 2.40018 8.14949 2.82883L1.84984 9.14047C1.62196 9.3688 1.62196 9.74014 1.84984 9.96848L6.52306 14.6484C6.63356 14.7591 6.78059 14.8202 6.93715 14.8203H6.93761C7.09398 14.8203 7.24101 14.7594 7.35161 14.649L13.6708 8.33655C14.099 7.90881 14.3349 7.33972 14.3349 6.73447V2.75C14.3349 2.42691 14.072 2.16406 13.7489 2.16406H9.75221ZM11.2586 6.93945C10.2894 6.93945 9.50081 6.151 9.50081 5.18164C9.50081 4.21228 10.2894 3.42383 11.2586 3.42383C12.2279 3.42383 13.0164 4.21228 13.0164 5.18164C13.0164 6.151 12.2279 6.93945 11.2586 6.93945ZM11.2586 4.5957C10.9355 4.5957 10.6727 4.85855 10.6727 5.18164C10.6727 5.50473 10.9355 5.76758 11.2586 5.76758C11.5818 5.76758 11.8446 5.50473 11.8446 5.18164C11.8446 4.85855 11.5818 4.5957 11.2586 4.5957Z" fill="#515C7B" />
                <path d="M6.93752 15.9922C6.93715 15.9922 6.93669 15.9922 6.93623 15.9922C6.46684 15.9918 6.02547 15.8087 5.69368 15.4764L1.02046 10.7965C0.336929 10.1117 0.336929 8.99738 1.02046 8.31265L7.32002 2.00101C7.96922 1.35052 8.83302 0.992188 9.75221 0.992188H13.7489C14.7181 0.992188 15.5068 1.78064 15.5068 2.75V6.73447C15.5068 7.65302 15.1488 8.51645 14.4989 9.16556L8.17979 15.4781C7.84782 15.8096 7.40663 15.9922 6.93752 15.9922ZM9.75221 2.16406C9.1465 2.16406 8.57732 2.40018 8.14949 2.82883L1.84984 9.14047C1.62196 9.3688 1.62196 9.74014 1.84984 9.96848L6.52306 14.6484C6.63356 14.7591 6.78059 14.8202 6.93715 14.8203H6.93761C7.09398 14.8203 7.24101 14.7594 7.35161 14.649L13.6708 8.33655C14.099 7.90881 14.3349 7.33972 14.3349 6.73447V2.75C14.3349 2.42691 14.072 2.16406 13.7489 2.16406H9.75221ZM11.2586 6.93945C10.2894 6.93945 9.50081 6.151 9.50081 5.18164C9.50081 4.21228 10.2894 3.42383 11.2586 3.42383C12.2279 3.42383 13.0164 4.21228 13.0164 5.18164C13.0164 6.151 12.2279 6.93945 11.2586 6.93945ZM11.2586 4.5957C10.9355 4.5957 10.6727 4.85855 10.6727 5.18164C10.6727 5.50473 10.9355 5.76758 11.2586 5.76758C11.5818 5.76758 11.8446 5.50473 11.8446 5.18164C11.8446 4.85855 11.5818 4.5957 11.2586 4.5957Z" fill="black" fill-opacity="0.2" />
                <path d="M6.93752 15.9922C6.93715 15.9922 6.93669 15.9922 6.93623 15.9922C6.46684 15.9918 6.02547 15.8087 5.69368 15.4764L1.02046 10.7965C0.336929 10.1117 0.336929 8.99738 1.02046 8.31265L7.32002 2.00101C7.96922 1.35052 8.83302 0.992188 9.75221 0.992188H13.7489C14.7181 0.992188 15.5068 1.78064 15.5068 2.75V6.73447C15.5068 7.65302 15.1488 8.51645 14.4989 9.16556L8.17979 15.4781C7.84782 15.8096 7.40663 15.9922 6.93752 15.9922ZM9.75221 2.16406C9.1465 2.16406 8.57732 2.40018 8.14949 2.82883L1.84984 9.14047C1.62196 9.3688 1.62196 9.74014 1.84984 9.96848L6.52306 14.6484C6.63356 14.7591 6.78059 14.8202 6.93715 14.8203H6.93761C7.09398 14.8203 7.24101 14.7594 7.35161 14.649L13.6708 8.33655C14.099 7.90881 14.3349 7.33972 14.3349 6.73447V2.75C14.3349 2.42691 14.072 2.16406 13.7489 2.16406H9.75221ZM11.2586 6.93945C10.2894 6.93945 9.50081 6.151 9.50081 5.18164C9.50081 4.21228 10.2894 3.42383 11.2586 3.42383C12.2279 3.42383 13.0164 4.21228 13.0164 5.18164C13.0164 6.151 12.2279 6.93945 11.2586 6.93945ZM11.2586 4.5957C10.9355 4.5957 10.6727 4.85855 10.6727 5.18164C10.6727 5.50473 10.9355 5.76758 11.2586 5.76758C11.5818 5.76758 11.8446 5.50473 11.8446 5.18164C11.8446 4.85855 11.5818 4.5957 11.2586 4.5957Z" fill="black" fill-opacity="0.2" />
                <path d="M6.93752 15.9922C6.93715 15.9922 6.93669 15.9922 6.93623 15.9922C6.46684 15.9918 6.02547 15.8087 5.69368 15.4764L1.02046 10.7965C0.336929 10.1117 0.336929 8.99738 1.02046 8.31265L7.32002 2.00101C7.96922 1.35052 8.83302 0.992188 9.75221 0.992188H13.7489C14.7181 0.992188 15.5068 1.78064 15.5068 2.75V6.73447C15.5068 7.65302 15.1488 8.51645 14.4989 9.16556L8.17979 15.4781C7.84782 15.8096 7.40663 15.9922 6.93752 15.9922ZM9.75221 2.16406C9.1465 2.16406 8.57732 2.40018 8.14949 2.82883L1.84984 9.14047C1.62196 9.3688 1.62196 9.74014 1.84984 9.96848L6.52306 14.6484C6.63356 14.7591 6.78059 14.8202 6.93715 14.8203H6.93761C7.09398 14.8203 7.24101 14.7594 7.35161 14.649L13.6708 8.33655C14.099 7.90881 14.3349 7.33972 14.3349 6.73447V2.75C14.3349 2.42691 14.072 2.16406 13.7489 2.16406H9.75221ZM11.2586 6.93945C10.2894 6.93945 9.50081 6.151 9.50081 5.18164C9.50081 4.21228 10.2894 3.42383 11.2586 3.42383C12.2279 3.42383 13.0164 4.21228 13.0164 5.18164C13.0164 6.151 12.2279 6.93945 11.2586 6.93945ZM11.2586 4.5957C10.9355 4.5957 10.6727 4.85855 10.6727 5.18164C10.6727 5.50473 10.9355 5.76758 11.2586 5.76758C11.5818 5.76758 11.8446 5.50473 11.8446 5.18164C11.8446 4.85855 11.5818 4.5957 11.2586 4.5957Z" fill="black" fill-opacity="0.2" />
            </g>
            <defs>
                <clipPath id="clip0_29123_7607">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default TagIcon