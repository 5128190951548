function filterUser(val,idx, searchString){
	const tempArr = [val.firstName.toLowerCase(), val.lastName.toLowerCase()]
	return val.firstName.toLowerCase().includes(searchString?.toLowerCase()) || val.lastName.toLowerCase().includes(searchString.toLowerCase()) || tempArr.join(" ").includes(searchString.toLowerCase()) || tempArr.reverse().join(" ").includes(searchString.toLowerCase())

}

function getTimeInFormat(time){
	return ({
		time : time.split(" ")[0],
		timeType : time.split(" ")[1]
	})
}


function updateStudentStatusMapping(studentStatusMapping){

		const requiredKeys = selectedStudents.map(student=>student._id)
		const tempStatusMapping = {}
		requiredKeys.map((key,idx)=>{
			if(studentStatusMapping[id]) {
				tempStatusMapping[id] = studentStatusMapping[id]
			} else {
				tempStatusMapping[id] = "none"
			}
		})
		return tempStatusMapping
}




export {
	filterUser,
	getTimeInFormat,
	updateStudentStatusMapping
}
