function EvChevronDownIcon(props) {

	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
			<path d="M4 6L8 10L12 6" stroke={props.inherit ? "currentColor" : "#515C7B"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	)

}


function EvChevronUpIcon(props) {

	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} style={{transform : "rotate(180deg)"}}>
			<path d="M4 6L8 10L12 6" stroke={props.inherit ? "currentColor" : "#515C7B"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>

	)

}


export {
	EvChevronUpIcon,
	EvChevronDownIcon
}
