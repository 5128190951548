import Subscription from "./Subscription";
import UsageMetrics from "./UsageMetrics";
import TutorLicenses from "./TutorLicenses";

export default function SelectedTab({ selectedTab, setSearchParams }) {
  switch (selectedTab) {
    case 0:
      return <Subscription />;
    case 1:
      return <UsageMetrics />;
    case 2:
      return <TutorLicenses />;
    default:
      return "Wrong selection";
  }
}
