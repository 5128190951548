import React from 'react'

const CircleArrowIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.42773" y="0.75" width="14.5" height="14.5" rx="7.25" fill="white" />
            <rect x="1.42773" y="0.75" width="14.5" height="14.5" rx="7.25" stroke="#515C7B" stroke-width="1.5" />
            <path d="M5.67773 6.5L8.67773 9.5L11.6777 6.5" stroke="#515C7B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default CircleArrowIcon