export default function SuccessIcon(props) {
  return (
    <svg
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_31234_36786)">
        <rect width="84" height="84" rx="42" fill="#40C98F" />
        <ellipse cx="61.5" cy="61" rx="48.5" ry="49" fill="#32B27C" />
        <g clip-path="url(#clip1_31234_36786)">
          <path
            d="M36.0005 49.6332L29.4231 43.0558C28.449 42.0817 26.8701 42.0802 25.893 43.0504C24.9111 44.0254 24.907 45.6139 25.886 46.5929L34.2325 54.9395C35.2089 55.9158 36.7918 55.9158 37.7681 54.9395L58.9435 33.7641C59.9177 32.7899 59.9177 31.2105 58.9435 30.2363C57.9697 29.2624 56.3909 29.2621 55.4166 30.2355L36.0005 49.6332Z"
            fill="white"
            stroke="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_31234_36786">
          <rect width="84" height="84" rx="42" fill="white" />
        </clipPath>
        <clipPath id="clip1_31234_36786">
          <rect x="18" y="18" width="48" height="48" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
