import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NoPaymentMethod from "./components/NoPaymentMethod";
import CardContainer from "./components/CardContainer";
import AddIcon from "@mui/icons-material/Add";
import AddNewCardPopUp from "./components/AddNewCardPopUp";
import DeleteCardPopUp from "./components/DeleteCardPopUp";

const PaymentMethods = () => {
  const { paymentMethods } = useSelector((state) => state.subscription);
  const [openAddNewCard, setOpenAddNewCard] = useState(false);
  const [openDeleteCard, setOpenDeleteCard] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteThisCard, setDeleteThisCard] = useState(null);

  return (
    <div className="grid gap-[24px] text-secondary px-4 md:px-8 md:py-9 relative">
      <div className="flex justify-between">
        <div className="grid gap-[4px]">
          <div className="flex gap-[6px] font-[600] text-[20px] leading-[30px] items-center">
            Payment Methods
          </div>
          <div className="text-[16px] leading-[24px] font-[400] text-secondary-60">
            Read more documentation about payment methods on Evallo’s &nbsp;
            <span className="text-blue-70 font-[500]">knowledge base.</span>
          </div>
        </div>
        <div className="hidden md:block">
          {paymentMethods.length > 0 && !openAddNewCard ? (
            <div
              className={`bg-blue-70 text-white cursor-pointer  text-center  px-[20px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
              onClick={() => {
                setOpenAddNewCard(true);
              }}
            >
              <AddIcon /> Add new card
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {!paymentMethods?.length > 0  ? (
        <NoPaymentMethod
          openAddNewCard={() => {
            setOpenAddNewCard(true);
          }}
        />
      ) : (
        <>
          <div className="text-[16px] leading-[24px] font-[500] text-secondary">
            Your Saved Cards{" "}
          </div>
          <div className="grid gap-[6px]">
            {paymentMethods.map((item, index) => (
              <CardContainer
                item={item}
                key={index}
                setOpenDelete={(val) => {
                  setOpenDeleteCard(val);
                }}
                setDeleteThisCard={(val) => {
                  setDeleteThisCard(val);
                }}
              />
            ))}
          </div>
        </>
      )}

      <div className=" md:hidden absolute top-[76vh] right-[30px]">
        {paymentMethods.length > 0 && !openAddNewCard ? (
          <div
            className={`bg-blue-70 text-white cursor-pointer  text-center  px-[20px] py-[10px] flex gap-[8px] items-center font-[600] rounded-[6px]  justify-center`}
            onClick={() => {
              setOpenAddNewCard(true);
            }}
          >
            <AddIcon /> Add new card
          </div>
        ) : (
          ""
        )}
      </div>

      {openAddNewCard && (
        <AddNewCardPopUp open={openAddNewCard} setOpen={setOpenAddNewCard} />
      )}

      {openDeleteCard && (
        <DeleteCardPopUp
          open={openDeleteCard}
          setOpen={setOpenDeleteCard}
          deleteThisCard={deleteThisCard}
        />
      )}
    </div>
  );
};

export default PaymentMethods;
