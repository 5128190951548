import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat' // ES 2015
import frozenEventStatus from "./eventStatus"
import frozenEventTypes from "./eventTypes"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat);

const REQD_EVENT_TYPES = {
	"Session" : frozenEventTypes.SESSION.eventType,
	"Consultation" : frozenEventTypes.CONSULTATION.eventType,
	"InternalMeeting" : frozenEventTypes.INTMEETING.eventType,
	"OfficeHours" : frozenEventTypes.OFFCWORK.eventType,
	"ExternalAppointment" : frozenEventTypes.EXTMEETING.eventType,
}


const USER_ACTION_STATUS_MAPPING = {
	"pending-action" : frozenEventStatus.PENDING.status,
	"yes" : frozenEventStatus.SCH.status,
	"maybe" : frozenEventStatus.SCH.status,
	"no" : frozenEventStatus.CANCEL.status
}

function transformEvents(allEvents, user) {

	const tempEvents = allEvents.map((event,idx)=>{
		
		if(!event.date) return
		if((!event.__t || event.__t === "Session") && (!event.sessionStatus || (!event.studentName && (!event.studentIds || event.studentIds.length === 0)))) return;

		//Add timezone support to this later
		let eventTimezone = null
		if(event.timeZone?.trim() === "(GMT + 1:30) Eastern Time - US & Canada"){
			eventTimezone = "America/New_York"
			return
		} else {
			eventTimezone = event.timeZone
		}
		
		const _startDateString = dayjs(event.date).tz(eventTimezone).format("YYYY-MM-DD")
		const _startTimeString = event.time.start.time + " "  + event.time.start.timeType

		if(!event.time.end) return;
		const _endTimeString = event.time.end?.time + " " + event.time.end?.timeType
		const fullStartDate = dayjs.tz([_startDateString, _startTimeString].join(" "), "YYYY-MM-DD hh:mm A" , eventTimezone).toISOString()
		const fullEndDate = dayjs.tz([_startDateString, _endTimeString].join(" "), "YYYY-MM-DD hh:mm A" ,eventTimezone).toISOString()

		return ({
			start : fullStartDate,
			end : fullEndDate,
			extendedProps : {
				eventType : event.__t ? REQD_EVENT_TYPES[event.__t] : frozenEventTypes.SESSION.eventType,
				status : (!event.__t || event.__t === "Session") ? 
					event.sessionStatus?.toLowerCase() ?? frozenEventStatus.SCH.status 
					:  (new Date(fullEndDate).getTime() < Date.now()) ? frozenEventStatus.COMPLETED.status : frozenEventStatus.SCH.status,
				location : event.location,
				meetingLink : event.meetingLinkDetails?.link,
				service : event.service,
				...event
			},
		})

	})
	console.log("TEMP_EVS", tempEvents.filter(event=>event && !event.extendedProps.status))
	return tempEvents.filter(event => event)
}


export {
	transformEvents,
	USER_ACTION_STATUS_MAPPING
}
