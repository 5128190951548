import { getFormattedDateV2 } from '../../utils/dateUtils'
import styles from './MiniSubscriptionSummary.module.css'

const MiniSubscriptionSummary = ({subscriptionStartDate, subscriptionEndDate, subscriptionBasePrice, calendarPrice, calendarPAYGPrice, assignmentsPrice,assignmentsPAYGPrice, customContentPrice}) => {
    return(
        <div className={styles.container}>
            <div className='flex items-center justify-start gap-[8px]'>
                <div className='text-secondary-80 font-[500]'>Billing cycle : </div>
                <div>{getFormattedDateV2(subscriptionStartDate, "dd mm, yyyy")}</div>
                <div>to</div>
                <div>{getFormattedDateV2(subscriptionEndDate, "dd mm, yyyy")}</div>
            </div>
            <div className='flex items-center justify-start gap-[8px]'>
                <div className='text-secondary-80 font-[500]'>Subscription base price :</div>
                <div>${subscriptionBasePrice}</div>
            </div>
            <div className='flex items-center justify-start gap-[8px]'>
                <div className='text-secondary-80 font-[500]'>Calendar :</div>
                <div>${calendarPrice}</div>
            </div>
            {
                calendarPAYGPrice ? 
                <div className='flex items-center justify-start gap-[8px]'>
                    <div className='text-secondary-80 font-[500]'>Calendar PAYG :</div>
                    <div>${calendarPAYGPrice}</div>
                </div>
                : <></>    
            }
            <div className='flex items-center justify-start gap-[8px]'>
                <div className='text-secondary-80 font-[500]'>Assignments :</div>
                <div>${assignmentsPrice}</div>
            </div>
            {
                assignmentsPAYGPrice ? 
                <div className='flex items-center justify-start gap-[8px]'>
                    <div className='text-secondary-80 font-[500]'> Assignments PAYG : </div>
                    <div>${assignmentsPAYGPrice}</div>
                </div>
                : <></>
            }
            <div className='flex items-center justify-start gap-[8px]'>
                <div className='text-secondary-80 font-[500]'>Custom Tests :</div>
                <div>${customContentPrice}</div>
            </div>
            <div className='flex items-center justify-start gap-[8px]'></div>
        </div>
    )
}

export default MiniSubscriptionSummary