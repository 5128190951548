import React, { useState, useContext, useEffect } from "react";
import SliderSelectV2 from "../../SliderSelectV2/SliderSelectV2";
import PhoneNumberInputV2 from "../../PhoneNumberInputV2/PhoneNumberInputV2";
import FormStepper from "../../FormStepper/FormStepper";
import { useSignupUserMutation } from "../../../app/services/auth";
import {
  useLazyGetOrganizationQuery,
  useLazyGetPersonalDetailQuery,
} from "../../../app/services/users";
import {
  useUpdateOrgDataMutation,
  useUpateAdminDataMutation,
  useCreateStripeCustomerMutation,
} from "../../../app/services/admin";
import OrgSignupContext from "../../../context/OrgSignupContext";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderIcon from "../../LoaderIcon/LoaderIcon";
import { useDispatch } from "react-redux";
import { updateIsSignupProcessComplete } from "../../../app/slices/subscriptionOperation";

const augumentPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return "";
  }
  const newPhoneNumber = new Array(12);
  phoneNumber.split("").forEach((ch, idx) => {
    if (idx <= 2) {
      newPhoneNumber[idx] = ch;
    } else if (idx >= 3 && idx <= 5) {
      newPhoneNumber[idx + 1] = ch;
    } else {
      newPhoneNumber[idx + 2] = ch;
    }
  });
  console.log(newPhoneNumber);
  newPhoneNumber[3] = "-";
  newPhoneNumber[7] = "-";

  return newPhoneNumber.join("");
};

const OrgDetailsV2 = () => {
  const navigate = useNavigate();
  const { noOfTutors, setNoOfTutors, registerationAs, setStripeCustId } =
    useContext(OrgSignupContext);

  const { formDetails, setFormDetails } = useContext(OrgSignupContext);
  const [getOrgDetails, getOrgDetailsResp] = useLazyGetOrganizationQuery();
  const [getUserDetails, getUserDetailsResp] = useLazyGetPersonalDetailQuery();
  const { setSignupStage } = useOutletContext();
  const [moreThan30Tutors, setMoreThan30Tutors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const minTutors = 1;
  const maxTutors = 30;
  const [updateOrg, updateOrgResp] = useUpdateOrgDataMutation();
  const [updateAdmin, updateAdminResp] = useUpateAdminDataMutation();
  const [createStripeCustomer, createrStripeCustomerResp] =
    useCreateStripeCustomerMutation();
  const [orgCompanyName, setOrgCompanyName] = useState();
  const [userDetails, setUserDetails] = useState();
  const [stripeCustIdFromOrg, setStripeCustIdFromOrg] = useState();
  const [isPhoneNotValid, setIsPhoneNotValid] = useState(true);
  const dispatch = useDispatch();

  const isFormValid = () => {
    console.log(">>>FD", formDetails);
    if (
      !formDetails.firstName ||
      !formDetails.lastName ||
      !formDetails.company ||
      !formDetails.phone
    ) {
      return false;
    } else {
      if (typeof isPhoneNotValid === "boolean") {
        return !isPhoneNotValid;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    setSignupStage("infoCollection");
  }, []);

  useEffect(() => {
    const getOrg = async () => {
      const orgDetails = await getOrgDetails(
        window.localStorage.getItem("orgId") ??
          window.sessionStorage.getItem("orgId")
      );

      console.log("org---------", orgDetails);
      setStripeCustIdFromOrg(
        orgDetails?.data.organisation?.stripeCustomerId
          ? orgDetails?.data.organisation?.stripeCustomerId
          : null
      );

      const company = orgDetails?.data.organisation.company;
      setOrgCompanyName(company);

      const companyType = orgDetails?.data.registrationAs;
      setNoOfTutors(
        window.localStorage.getItem("noOfTutors")
          ? window.localStorage.getItem("noOfTutors")
          : companyType === "Individual"
          ? 1
          : 6
      );

      if (!company.includes("placeholder")) {
        setFormDetails((prevDetails) => ({
          ...prevDetails,
          company: company,
        }));
      }
    };

    const getUser = async () => {
      const userDetails = await getUserDetails();
      console.log("user-----------", userDetails);
      if (userDetails.error) {
        toast.error(userDetails.error.data.message);
        return;
      } else {
        const user = userDetails.data.data.user;

        setUserDetails({
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
        });

        setFormDetails((prevDetails) => ({
          ...prevDetails,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
        }));
      }
    };
    getOrg();
    getUser();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid()) {
      let resAdminUpdate;
      let resStripeCust;
      let resOrgUpdate;

      if (
        orgCompanyName?.includes("placeholder") ||
        orgCompanyName !== formDetails.company
      ) {
        let reqBodyOrg = {
          op: "update",
          orgId:
            window.localStorage.getItem("orgId") ??
            window.sessionStorage.getItem("orgId"),
          fields: {
            company: formDetails.company,
            registration_as: registerationAs,
          },
        };
        setIsLoading(true);
        resOrgUpdate = await updateOrg(reqBodyOrg);
        if (resOrgUpdate?.error) {
          setIsLoading(false);
          toast.error(resOrgUpdate?.error?.data?.message);
          return;
        } else {
          console.log("org updated!!");
          console.log(resOrgUpdate);
        }
      }

      if (
        userDetails.firstName !== formDetails.firstName ||
        userDetails.lastName !== formDetails.lastName ||
        userDetails.phone !== formDetails.phone
      ) {
        let reqBodyAdmin = {
          op: "update",
          userId:
            window.localStorage.getItem("userId") ??
            window.sessionStorage.getItem("userId"),
          fields: {
            firstName: formDetails.firstName,
            lastName: formDetails.lastName,
            phone: formDetails.phone,
            phoneCode : formDetails.phoneCode?.dial_code,
            company: formDetails.company,
            registration_as: registerationAs,
          },
        };
        setIsLoading(true);
        resAdminUpdate = await updateAdmin(reqBodyAdmin);
        if (resAdminUpdate.error) {
          setIsLoading(false);
          toast.error(resAdminUpdate?.error?.data?.message);
          return;
        } else {
          console.log("admin updated!!");
          console.log(resAdminUpdate);
        }
      }
      if (!stripeCustIdFromOrg) {
        resStripeCust = await createStripeCustomer();
        console.log("stripe customer created!!");
        console.log(resStripeCust);
        if (resStripeCust) {
          let custId = resStripeCust.data.data.stripeCustomer?.id;
          setStripeCustId(custId);
          setIsLoading(false);
        }
      }
      setStripeCustId(stripeCustIdFromOrg);
      dispatch(updateIsSignupProcessComplete(true));
      navigate("/users");
    }
  };
  useEffect(() => {
    console.log("phoneValid", isPhoneNotValid);
  }, [isPhoneNotValid]);
  return (
    <div className="flex flex-col justify-center items-center w-full h-[80vh] md:h-[75vh] ">
      {/* <FormStepper stage={2} /> */}
      <div className="grid gap-[24px] md:gap-[36px] ">
        <div className="text-left md:text-center text-secondary text-[24px] md:text-[48px] font-semibold flex  leading-[36px] ">
          Congrats, your account is ready!
        </div>
        <div className="flex justify-center items-center cursor-default  ">
          <div className="md:w-[420px] h-fit md:h-fit text-[#3B476B] text-left  text-[16px] ">
            <form className="grid gap-9" onSubmit={(e) => handleFormSubmit(e)}>
              <div className="grid gap-4 ">
                <div className="text-[20px] text-secondary font-[600]">
                  Just a few final details:
                </div>
                <div className="flex justify-between ">
                  <input
                    className="px-[1rem] py-[0.8125rem] rounded-md  border border-[#BEC2CE] focus:border-[#0671E0] w-[48%] md:w-[204px] outline-none "
                    type="text"
                    placeholder="First name*"
                    value={formDetails.firstName}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        firstName: e.target.value,
                      })
                    }
                    defaultValue={formDetails.firstName}
                  ></input>
                  <input
                    className="px-[1rem] py-[0.8125rem] rounded-md  border border-[#BEC2CE] focus:border-[#0671E0] w-[48%] md:w-[204px] outline-none"
                    type="text"
                    placeholder="Last name*"
                    value={formDetails.lastName}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        lastName: e.target.value,
                      })
                    }
                    defaultValue={formDetails.lastName}
                  ></input>
                </div>
                <div className="">
                  <PhoneNumberInputV2
                    placeholderText="Phone number*"
                    selectedCountryCode={formDetails.phoneCode}
                    setSelectedCountryCode={(val) => {
                      setFormDetails({
                        ...formDetails,
                        phoneCode: val,
                      });
                    }}
                    // onChange={(e) => {
                    //   setFormDetails({
                    //     ...formDetails,
                    //     phone: e.target.value,
                    //   });
                    // }}
                    setPhoneNumberOutside={(phoneNumber) => {
                      setFormDetails({
                        ...formDetails,
                        phone: phoneNumber,
                      });
                    }}
                    defaultValue={augumentPhoneNumber(formDetails.phone)}
                    isNotValid={isPhoneNotValid}
                    setIsNotValid={setIsPhoneNotValid}
                    className="phone-code-dropdown p-0"
                  />
                  {isPhoneNotValid && (
                    <div className="text-[0.875rem] font-[400] leading-[17.5px] text-[#f04438]">
                      Please enter a valid 10-digit phone number.
                    </div>
                  )}
                </div>
                <div className="">
                  <input
                    className="px-[1rem] py-[0.8125rem] rounded-md  border w-full border-[#BEC2CE] focus:border-[#0671E0] outline-none"
                    type="text"
                    placeholder="Your company or business name*"
                    value={formDetails.company}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        company: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
              <div className="grid gap-4 ">
                {/* <div className="text-[20px] font-[600] text-secondary-80">
                Now, let’s learn a bit about you:
              </div> */}

                <div className="grid gap-[16px]">
                  <div className={`flex flex-col h-fit min-h-[76px]`}>
                    <div className="font-medium text-secondary ">
                      How many tutors (including you) are in your team?
                    </div>
                    {noOfTutors ? (
                      <SliderSelectV2
                        min={minTutors}
                        max={maxTutors}
                        value={noOfTutors}
                        setValue={setNoOfTutors}
                        disabled={moreThan30Tutors}
                      />
                    ) : (
                      <div className="flex justify-center items-center w-full mt-2">
                        <LoaderIcon />
                      </div>
                    )}
                  </div>
                  <div className="flex justify-between h-[30px] items-center ">
                    <div
                      className={`flex ${
                        moreThan30Tutors ? "w-4/5" : "w-full"
                      } items-center gap-[4px]`}
                    >
                      <input
                        className="h-[18px] w-[18px] cursor-pointer rounded-[2px] outline-secondary-60 outline-[1.5px]"
                        type="checkbox"
                        onClick={() => {
                          setMoreThan30Tutors(!moreThan30Tutors);
                        }}
                      />
                      <div className="font-normal px-[4px] py-0.5 text-secondary">
                        We have more than <span className="font-bold">30</span>{" "}
                        tutors.
                      </div>
                    </div>
                    <div className="flex justify-end">
                      {moreThan30Tutors ? (
                        <input
                          type="number"
                          className="p-0.5 w-3/5 rounded-md text-right py-2  border border-[#BEC2CE] focus:border-[#0671E0]  outline-none"
                          onChange={(e) => {
                            setNoOfTutors(e.target.value);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className={`w-full py-[10px] px-[24px] h-[48px] rounded-md font-[600]  ${
                  !isFormValid()
                    ? "bg-secondary-10 text-[#7c859c]"
                    : "bg-[#0671E0] text-white"
                }`}
                disabled={!isFormValid()}
              >
                {!isLoading ? (
                  "Start using Evallo"
                ) : (
                  <div className="flex justify-center items-center w-full">
                    <LoaderIcon />
                  </div>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgDetailsV2;
